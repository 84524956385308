// se queda pendiente de hacer!! falta configurar intervalosTemas cambiarlos por props busquedaTemas

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './css/generadorTestTemas.css';

import Submit from '../../../Inputs/Submit';
import Input from '../../../Inputs/Input';

class TestPorClases extends Component {
  static propTypes = {
    submitText: PropTypes.string.isRequired
  };
constructor(props){
  super(props);
  this.state = {
    intervalosTemas : this.props.busquedaTemas
  };
  this.handleOnClick = this.handleOnClick.bind(this)
  this.onChangeInput = this.onChangeInput.bind(this)
}
handleOnClick(e){
  if (e.target.id.split('-')[0] == 'borrarIntervalo'){
    let idTema = e.target.id.split('-')[1]
    let numInt = e.target.id.split('-')[2]
    if(this.state.intervalosTemas[idTema].intervalos.length > 1){
      let temas = this.state.intervalosTemas
      let inter = temas[idTema].intervalos.filter(i=> i.numInter != numInt)
      for (var i = 0; i < inter.length; i++) {
        if(inter[i].numInter > numInt){
          inter[i].numInter = inter[i].numInter - 1
        }
      }
      temas[idTema].intervalos = inter
      this.setState({ intervalosTemas: temas})
      // recargar datos
      setTimeout(
          function() {
            for (var i = 0; i < inter.length; i++) {
              this.refs['ref_siglasLeyExt-'+idTema+'-'+i].refs['siglasLeyExt-'+idTema+'-'+i].value = temas[idTema].intervalos[i].ley == undefined ? "" : this.state.busquedaLeyes.find(l=> l.id_ley == temas[idTema].intervalos[i].ley).siglas_ley;

              this.refs['ref_artInicioExt-'+idTema+'-'+i].refs['artInicioExt-'+idTema+'-'+i].value = temas[idTema].intervalos[i].artInicio == undefined ? "" : temas[idTema].intervalos[i].artInicio;

              this.refs['ref_artFinExt-'+idTema+'-'+i].refs['artFinExt-'+idTema+'-'+i].value = temas[idTema].intervalos[i].artFin == undefined ? "" : temas[idTema].intervalos[i].artFin;

            }
          }.bind(this),100);

    } else{
      swal('Error: Es necesario un intervalo como minimo.','','error')
    }
  } else if (e.target.id.split('-')[0] == 'borrarTema'){
    let idTema = e.target.id.split('-')[1]
    let temasNew = []

    if(this.state.intervalosTemas.length > 1){
      for (var i = 0; i < this.state.intervalosTemas.length; i++) {
          if(i != idTema){
            temasNew.push(this.state.intervalosTemas[i])
          }
      }

      this.setState({ intervalosTemas: temasNew})
      setTimeout(
        function() {
          for (var i = 0; i < temasNew.length; i++) {
            this.refs['ref_temaSelecionado-'+i].refs['temaSelecionado-'+i].value = temasNew[i].tituloTema
            for (var j = 0; j < temasNew[i].intervalos.length; j++) {
              this.refs['ref_siglasLeyExt-'+i+'-'+j].refs['siglasLeyExt-'+i+'-'+j].value = temasNew[i].intervalos[j].ley == undefined ? "" : this.state.busquedaLeyes.find(l=> l.id_ley == temasNew[i].intervalos[j].ley).siglas_ley;

              this.refs['ref_artInicioExt-'+i+'-'+j].refs['artInicioExt-'+i+'-'+j].value = temasNew[i].intervalos[j].artInicio == undefined ? "" : temasNew[i].intervalos[j].artInicio;

              this.refs['ref_artFinExt-'+i+'-'+j].refs['artFinExt-'+i+'-'+j].value = temasNew[i].intervalos[j].artFin == undefined ? "" : temasNew[i].intervalos[j].artFin;

              this.refs['ref_numPreg-'+i+'-'+j].refs['numPreg-'+i+'-'+j].value = temasNew[i].intervalos[j].numPreg == undefined ? "" : temasNew[i].intervalos[j].numPreg;
            }
          }
        }.bind(this),100);
    } else{
      swal('Error: Es necesario un tema como minimo.','','error')
    }
  } else if (e.target.id == 'genTestClasesTemas'){
    console.log(intervalosTemas)
    this.props.onClick(e, this.state.intervalosTemas);
  }

}

onChangeInput(e){

  if(e.target.name.split('-')[0] == 'siglasLeyExt'){
    let idTema = e.target.name.split('-')[1]
    let id = e.target.name.split('-')[2]
    let artInter = this.state.intervalosTemas

    if(this.state.busquedaLeyes.filter(l=> l.siglas_ley == e.target.value).length != 0){
      artInter[idTema].intervalos[id].idLey = this.state.busquedaLeyes.find(l=> l.siglas_ley == e.target.value).id_ley
      this.setState({intervalosTemas: artInter})
    }
  } else if(e.target.name.split('-')[0] == 'numPreg'){
    let idTema = e.target.name.split('-')[1]
    let id = e.target.name.split('-')[2]
    let artInter = this.state.intervalosTemas

    artInter[idTema].intervalos[id].numPreg = parseInt(e.target.value)
    this.setState({intervalosTemas: artInter})
  } else if(e.target.name.split('-')[0] == 'artInicioExt'){
    let idTema = e.target.name.split('-')[1]
    let id = e.target.name.split('-')[2]
    let artInter = this.state.intervalosTemas

    artInter[idTema].intervalos[id].artInicio = e.target.value
    this.setState({intervalosTemas: artInter})
  } else if(e.target.name.split('-')[0] == 'artFinExt'){
    let idTema = e.target.name.split('-')[1]
    let id = e.target.name.split('-')[2]
    let artInter = this.state.intervalosTemas

    artInter[idTema].intervalos[id].artFin = e.target.value
    this.setState({intervalosTemas: artInter})
  }

}
componentDidMount(){
  fetch('https://oposiciones-justicia.es/api/leyes/consulta')
    .then(res => res.json())
      .then(leyes => this.setState({
          busquedaLeyes: leyes
      }))
    .catch(error => console.log('Object fecth failed', error))

}

  render() {
    const { intervalosLeyes, intervalosTemas, busquedaLeyes } = this.state
    const { submitText, busquedaTemas } = this.props

    return (
      <div className="bodyGT">

        <div className="intervalosBlockGT" id='intervalosBlockExtra' title='intervalosBlockExtra'>
          <i id='intervalosBlockExtra' title='intervalosBlockExtra' className="fas fa-edit"></i>
            Intervalo de Temas
          <i id='intervalosBlockExtra' title='intervalosBlockExtra' className="fas fa-arrow-alt-circle-down"></i>
        </div>

        <div className="intervalosArticulosGT" >
          <div className="intervalosArticulosGTBody" id='celdaBlockPRGExtra'>
            <div className='celdaBlockGT'>

            {busquedaTemas && busquedaTemas.map((intervalosTemas, key) =>

              <div className="intervaloTemasPre">

              <div className="datoInfoBPRGPR">
                <div className="titleInputArticulosPR"> {intervalosTemas.tipoTema == 0 ? ('Tema de la semana'):('Tema Repaso')} </div>

                <Input size="18" type="text" name={"temaSelecionado-"+key} placeholder="Indicar Tema" icon="fas fa-search" ref={"ref_temaSelecionado-"+key}  onChange={this.onChangeInput} />

                  <div style={{width: '175px'}}>
                    <Submit id={'borrarTema-'+key} icon2="fas fa-trash" texto="Borrar Tema" onClick={this.handleOnClick}/>
                  </div>

              </div>
              { busquedaTemas.intervalos && busquedaTemas.intervalos.map((tema, key3) =>
                <div className="intervaloUnitarioPR" key={key3}>

                  <div className="datoInfoBPRG">
                    <div className="titleInputArticulos">Ley</div>
                    <Input size="10" type="text" name={"siglasLeyExt-"+key+"-"+key3} placeholder="Indicar Ley" icon="fas fa-search" datalist="busquedaLeyes" ref={"ref_siglasLeyExt-"+key+"-"+key3}  onChange={this.onChangeInput} />
                      <datalist id="busquedaLeyes">{
                        busquedaLeyes && busquedaLeyes.map((ley, key5) =>
                          <option key={key5} value={ley.siglas_ley}>{ley.nombre_ley}</option>
                        )
                      }</datalist>
                  </div>

                  <div className="datoInfoBPRG">
                    <div className="titleInputArticulos">Artículo Inicio</div>
                    <Input size="8" type="text" name={"artInicioExt-"+key+"-"+key3} ref={"ref_artInicioExt-"+key+"-"+key3} placeholder="Art. Inicio" icon="fas fa-pen" onChange={this.onChangeInput} />
                  </div>

                  <div className="datoInfoBPRG">
                    <div className="titleInputArticulos">Artículo Fin</div>
                    <Input size="8" type="text" name={"artFinExt-"+key+"-"+key3} ref={"ref_artFinExt-"+key+"-"+key3}  placeholder="Art. Fin" icon="fas fa-pen" onChange={this.onChangeInput} />
                  </div>

                  <div className="datoInfoBPRG">
                    <div className="titleInputArticulos">Núm Preguntas</div>
                    <Input size="8" type="text" name={"numPreg-"+key+"-"+key3} ref={"ref_numPreg-"+key+"-"+key3}  placeholder="10,20,..." icon="fas fa-sort-numeric-up" onChange={this.onChangeInput} />
                  </div>


                  <div className="borrarIntervalo">
                    <Submit id={'borrarIntervalo-'+key+"-"+key3} icon2="fas fa-trash" texto="Borrar" onClick={this.handleOnClick}/>
                  </div>

                </div>
              )}

              </div>
            )}

            <div className="borrarIntervalo">
            <Submit id={'genTestClasesTemas'} icon1="fas fa-plus" icon2="fas fa-undo" texto={submitText} onClick={this.handleOnClick}/>
            </div>
            </div>
          </div>
        </div>

      </div>


    );
        }
 }
export default TestPorClases;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class DuplicarTestJusticia extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
  	this.state = {
			displayTestToCopy: 'none',
			displayOpoToCopy: 'none',
			displaySubmit: 'none'
		};
		// this.closePdf = this.closePdf.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e){
		if(e.target.id == 'oposicion'){
			let compruebaOpo = this.state.busquedaOpo.find(o=> o.nombre == e.target.value)

			if(compruebaOpo != undefined){

				fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreCasoPractico')
				.then(res => res.json())
					.then(test => {
						let testFilter = test.filter(c=>c.id_oposicion == compruebaOpo.id_oposicion)

						this.setState({
							busquedaTestPreCasoPractico: testFilter,
							oposicionSelected: compruebaOpo.id_oposicion, displayTestToCopy: 'flex'
					})
					}).catch(error => console.log('Object fecth failed', error))
			} else {
				this.setState({oposicionSelected: undefined, displayTestToCopy: 'none'})
			}
		} else if (e.target.id == 'oposicionTestSelected'){
			let compruebaTest = this.state.busquedaTestPreCasoPractico.find(o=> o.nombre_cp == e.target.value)
			if(compruebaTest != undefined){
				this.setState({testSelected: compruebaTest.id_caso_practico, displayOpoToCopy: 'flex'})

			} else {
				this.setState({testSelected: undefined, displayOpoToCopy: 'none'})
			}
		} else if(e.target.id == 'oposicionSelectedToCopy'){
			let compruebaOpo = this.state.busquedaOpo.find(o=> o.nombre == e.target.value)
			if(compruebaOpo != undefined){
				this.setState({oposicionSelectedToCopy: compruebaOpo.id_oposicion, displaySubmit: 'flex'})
			} else {
				this.setState({oposicionSelectedToCopy: undefined, displaySubmit: 'none'})
			}
		}
	}
	async handleOnClick(e){
		if(e.target.id == 'duplicarTestJusticia'){
			let {oposicionSelected, testSelected, oposicionSelectedToCopy} = this.state
			if(oposicionSelected != undefined && testSelected != undefined && oposicionSelectedToCopy != undefined){
				// sacar test template y duplicar tanto nombre como preguntas con nueva idOpo
				//console.log(this.state.busquedaTestPre.find( t => t.id_oposicion == oposicionSelected && t.id_caso_practico == testSelected))

				try{

					let data = { idTest: testSelected}
					let response1 = await fetch("https://oposiciones-justicia.es/api/informatica/selectPreguntasTemplateCP", {
						method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
						if (!response1.ok) {
							throw Error(response1.statusText);
						}
					let json1 = await response1.json();
					let cls1 = await json1;
					let testTemplateCargado = await cls1

					let testPreparadoAcopiar = {
						idOpo: oposicionSelectedToCopy,
						nombreTest: this.state.busquedaTestPreCasoPractico.find( t => t.id_oposicion == oposicionSelected && t.id_caso_practico == testSelected).nombre_cp,
						idTestTemplate: undefined,
						casoPracticoText: this.state.busquedaTestPreCasoPractico.find( t => t.id_oposicion == oposicionSelected && t.id_caso_practico == testSelected).texto_cp,
						preguntas: []
					}
					for (var i = 0; i < testTemplateCargado.length; i++) {
						testPreparadoAcopiar.preguntas.push({
							idPregunta: testTemplateCargado[i].idPregunta,
							numPreg: testTemplateCargado[i].numPreg,
							idRespuesta1: testTemplateCargado[i].idR1,
							idRespuesta2: testTemplateCargado[i].idR2,
							idRespuesta3: testTemplateCargado[i].idR3,
							idRespuesta4: testTemplateCargado[i].idR4,
							insertar: 1
						})
					}
					let data2 = { idOposicion:oposicionSelectedToCopy , nombreTest: testPreparadoAcopiar.nombreTest, casoPracticoText: testPreparadoAcopiar.casoPracticoText }

					let response2 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateJusticaCP", {
						method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
						if (!response2.ok) {
							throw Error(response2.statusText);
						}
					let json2 = await response2.json();
					let cls2 = await json2;
					if(cls2 != undefined){
						 testPreparadoAcopiar.idTestTemplate = cls2.insertId
						 let data3 = { idTest: cls2.insertId, preguntas: testPreparadoAcopiar.preguntas}
						 let response3 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticiaCP", {
						 	method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
						 	if (!response3.ok) {
						 		throw Error(response3.statusText);
						 	}
						 let json3 = await response3.json();
						 let cls3 = await json3;

						 if(cls3 != undefined){
							 swal('¡Caso P. clonado correctamente!','','success')
							 this.setState({oposicionSelected: undefined, testSelected: undefined, oposicionSelectedToCopy: undefined, displayTestToCopy: 'none', displayOpoToCopy: 'none', displaySubmit: 'none'})
						 }

					}
					//Insertamos test template nueva

					// Insertamos todas las preguntas y respuestas template
				} catch (e){
					console.log(e)
				}
			} else {
				 swal('Error falta algun dato','','error')
			}
		}
	}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));


	}
	render() {
		const { displayTestToCopy, busquedaOpo, busquedaTestPreCasoPractico, displayOpoToCopy, displaySubmit } = this.state;
		return (
			<div className= "bodyLoadTest">
				<div className="opocionesLoadTest">
					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							¿Qué oposicion buscamos?
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion" id="oposicion" name="oposicion" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="oposcionesData">{
							busquedaOpo && busquedaOpo.map((cls, key) =>
								<option key={key} value={cls.nombre}></option>
							)
						}</datalist>
					</div>

					<div className="inputLoadTest" style={{display: displayTestToCopy}}>
						<h4>
							<i className="fas fa-info-circle"></i>
							Seleccione Test a clonar
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" datalist="busquedaTestPreCasoPractico" placeholder="Test a copiar" id="oposicionTestSelected" name="oposicionTestSelected" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="busquedaTestPreCasoPractico">{
							busquedaTestPreCasoPractico && busquedaTestPreCasoPractico.map((test, key) =>
							<option key={key} value={test.nombre_cp}></option>
								)
						}</datalist>
					</div>

					<div className="inputLoadTest" style={{display: displayOpoToCopy}}>
					<h4>
						<i className="fas fa-info-circle"></i>
						¿A que oposicion lo clonamos?
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion donde clonar" id="oposicionSelectedToCopy" name="oposicionSelectedToCopy" icon="fas fa-search" onChange={this.onChange}/>
					<datalist id="oposcionesData">{
						busquedaOpo && busquedaOpo.map((cls, key) =>
							<option key={key} value={cls.nombre}></option>
						)
					}</datalist>

					</div>

				</div>

				<div className="inputLoadTest" style={{display: displaySubmit}}>

					<Submit id="duplicarTestJusticia" icon1="fas fa-upload" icon2="fas fa-database" texto="Duplicar Test" onClick={this.handleOnClick}/>

				</div>



			</div>
		);
	}
}

export default DuplicarTestJusticia;

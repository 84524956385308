// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './css/generadorTest.css';

import Submit from '../../../Inputs/Submit';
import Input from '../../../Inputs/Input';

class TestPorLeyes extends Component {
  static propTypes = {
    submitText: PropTypes.string.isRequired
  };
constructor(props){
  super(props);
  this.state = {
    intervalosLeyes : [{
      numInter: 0,
      artInicio: 0,
      artFin: 999999,
      idLey: undefined,
      numPreg: undefined
    }]
  };
  this.handleOnClick = this.handleOnClick.bind(this)
  this.onChangeInput = this.onChangeInput.bind(this)
}
handleOnClick(e){
  if(e.target.id == 'addIntervalo'){
    if(this.state.intervalosLeyes.length > 0){
      let inter = this.state.intervalosLeyes
      let numIntervalos = this.state.intervalosLeyes.length
      inter.push({numInter: numIntervalos, artInicio: 0, artFin: 999999, idLey: undefined, numPreg: undefined})
      this.setState({ intervalosLeyes: inter})
    } else {
      let inter = []
      inter.push({numInter: numIntervalos, artInicio: 0, artFin: 999999, idLey: undefined, numPreg: undefined})
      this.setState({ intervalosLeyes: inter})
    }
  } else if (e.target.id.split('-')[0] == 'borrarIntervalo'){
    if(this.state.intervalosLeyes.length > 1){
      let numInt = e.target.id.split('-')[1]
      let inter = this.state.intervalosLeyes.filter(i=> i.numInter != numInt)
      for (var i = 0; i < inter.length; i++) {
        if(inter[i].numInter > numInt){
          inter[i].numInter = inter[i].numInter - 1
        }
      }
      this.setState({ intervalosLeyes: inter})
      // recargar datos
      setTimeout(
          function() {
            for (var i = 0; i < inter.length; i++) {
                this.refs['ref_siglasLeyExt-'+i].refs['siglasLeyExt-'+i].value = this.state.intervalosLeyes[i].idLey == undefined ? "" : this.state.busquedaLeyes.find(l=> l.id_ley == this.state.intervalosLeyes[i].idLey).siglas_ley;

                this.refs['ref_numPreg-'+i].refs['numPreg-'+i].value = this.state.intervalosLeyes[i].numPreg == undefined ? "" : this.state.intervalosLeyes[i].numPreg;

            }
          }.bind(this),100);

    } else{
      swal('Error: Es necesario una ley como minimo','','warning')
    }
  } else if (e.target.id == 'genTestLeyes'){
    this.props.onClick(e, this.state.intervalosLeyes);
  }

}

onChangeInput(e){
  if(e.target.name.split('-')[0] == 'siglasLeyExt'){
    let id = e.target.name.split('-')[1]
    let artInter = this.state.intervalosLeyes
    if(this.state.busquedaLeyes.filter(l=> l.siglas_ley == e.target.value).length != 0){
      artInter[id].idLey = this.state.busquedaLeyes.find(l=> l.siglas_ley == e.target.value).id_ley
      this.setState({intervalosLeyes: artInter})
    }
  } else if(e.target.name.split('-')[0] == 'numPreg'){
    let id = e.target.name.split('-')[1]
    let artInter = this.state.intervalosLeyes
    artInter[id].numPreg = parseInt(e.target.value)
    this.setState({intervalosLeyes: artInter})
  }
}
componentDidMount(){
  fetch('https://oposiciones-justicia.es/api/leyes/consulta')
    .then(res => res.json())
      .then(leyes => this.setState({
          busquedaLeyes: leyes
      }))
    .catch(error => console.log('Object fecth failed', error))
}

  render() {
    const { intervalosLeyes, busquedaLeyes } = this.state
    const { submitText } = this.props
    return (
      <div className="bodyGT">

        <div className="intervalosBlockGT" id='intervalosBlockExtra' title='intervalosBlockExtra'>
          <i id='intervalosBlockExtra' title='intervalosBlockExtra' className="fas fa-edit"></i>
            Leyes
          <i id='intervalosBlockExtra' title='intervalosBlockExtra' className="fas fa-arrow-alt-circle-down"></i>
        </div>

        <div className="intervalosArticulosGT" >
          <div className="intervalosArticulosGTBody" id='celdaBlockPRGExtra'>
            <div className='celdaBlockGT'>
            <div style={{width: 'auto'}}>
              <Submit id={'addIntervalo'} icon1="fas fa-plus" icon2="fas fa-plus" texto="Añadir Ley" onClick={this.handleOnClick}/>
            </div>

            {intervalosLeyes && intervalosLeyes.map((intervalosLeyes, key) =>
              <div className="intervaloUnitario" key={key}>

                <div className="datoInfoBPRG">
                  <div className="titleInputArticulos">Ley</div>
                  <Input size="10" type="text" name={"siglasLeyExt-"+key} placeholder="Indicar Ley" icon="fas fa-search" datalist="busquedaLeyes" ref={"ref_siglasLeyExt-"+key}  onChange={this.onChangeInput} />
                    <datalist id="busquedaLeyes">{
                      busquedaLeyes && busquedaLeyes.map((ley, key2) =>
                        <option key={key2} value={ley.siglas_ley}>{ley.nombre_ley}</option>
                      )
                    }</datalist>
                </div>

                <div className="datoInfoBPRG">
                  <div className="titleInputArticulos">Núm Preguntas</div>
                  <Input size="10" type="number" name={"numPreg-"+key} ref={"ref_numPreg-"+key}  placeholder="10,20,..." icon="fas fa-sort-numeric-up" onChange={this.onChangeInput} />
                </div>

                <div className="borrarIntervalo">
                  <Submit id={'borrarIntervalo-'+key} icon2="fas fa-trash" texto="Borrar" onClick={this.handleOnClick}/>
                </div>

              </div>
            )}
            <div className="borrarIntervalo">
            <Submit id={'genTestLeyes'} icon1="fas fa-plus" icon2="fas fa-undo" texto={submitText} onClick={this.handleOnClick}/>
            </div>
            </div>
          </div>
        </div>

      </div>


    );
        }
 }
export default TestPorLeyes;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';

//Assets
import './css/expedienteUser.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { borrarTestUser, loadTestUser, loadTestJusticia, loadCasoPractico, puntosRes, puntosResCP, borrarCP, loadCPUser } from '../../actions/userAction';
// Utils respuestaSbr
import { isDefined } from '../../lib/utils/is';
import { comprobarRespuestas, respuestaSbr, corregirTest, selectPreguntasPorIdTest, respuestasTestId, mezclarRespuestasJusticia, selectPreguntasPorIdTestCP, respuestasTestIdCP } from '../../lib/utils/cargarTestJusticia';
import { } from '../../lib/utils/cargarTestJusticia'
// Components
import MenuAdmin from './components/MenuAdmin';

import InputSelect from '../../components/Inputs/InputSelect';
import Submit from '../../components/Inputs/Submit';
import Boton from '../../components/Inputs/Boton';

import EstadisticasUser from '../EstadisticasUser'

import Input2 from '../../components/Inputs/Input2';
import swal from 'sweetalert';

class ExpedienteUser extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      verTestJusticia: true,
      verCasoPractico: false,
      verExamGes: false,
      valueInput: null,
      diaSemanalOtro: null,
      otroCursoEstadisticas: null,
      valueInput1: null,
      valueInput2: null,
      valueInput3: null,
      valueInput4: null,
      numViewTest: 0,
      navTestIni: 0,
      navTestFin: 10,
      ejerLoadIdExp: null,
      leySelectd: 0,
      fechaInicio: null,
      fechaFin: null,
      idCursoBuscador: null,
      idPrgBuscador: null,
      infoAlumnoBuscado: null,
      clasesAlumno: null
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.bienMalBlanco = this.bienMalBlanco.bind(this)
    this.bienMalBlancoCP = this.bienMalBlancoCP.bind(this)
    this.onChangeInputNew = this.onChangeInputNew.bind(this)
    this.listadoArtErrores = this.listadoArtErrores.bind(this)
    this.downloadPdf = this.downloadPdf.bind(this)
  }



  downloadPdf(e) {
    if (e.target.id == 'downloadSolucion') {
      let expediente = this.state.examGesHistorial.find(c => c.id_exp == this.state.ejerLoadIdExp)

      if (isDefined(expediente)) {
        let pathFile = expediente.linkSolucion
        if (pathFile != undefined) {
          swal('Iniciando descarga, espere.', 'Si en 30 segundos, no se inicia la descarga vuelva a intentarlo.', 'info')
          let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
          let nameFile = pathFile.split('/').pop().split('-,-')[0];
          fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob'
          }).then(res => {
            if (res.status >= 400) { throw new Error("Bad res from server"); }
            return res.blob();
          }).then(blob => {
            saveAs(blob, nameFile);
            swal.close();
          }).catch(function (err) {
            console.log(err)
            alert("Link no valido para descarga, reportelo, gracias.")
          });
        }
      }
    } else if (e.target.id == 'downloadMiExamen') {
      let expediente = this.state.examGesHistorial.find(c => c.id_exp == this.state.ejerLoadIdExp)

      if (isDefined(expediente)) {
        let pathFile = expediente.linkArchivoAlumno
        if (pathFile != undefined) {
          swal('Iniciando descarga, espere.', 'Si en 30 segundos, no se inicia la descarga vuelva a intentarlo.', 'info')
          let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
          let nameFile = pathFile.split('/').pop().split('-,-')[0];
          fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob'
          }).then(res => {
            if (res.status >= 400) { throw new Error("Bad res from server"); }
            return res.blob();
          }).then(blob => {
            saveAs(blob, nameFile);
            swal.close();
          }).catch(function (err) {
            console.log(err)
            alert("Link no valido para descarga, reportelo, gracias.")
          });
        }
      }
    }


  }
  async onChangeInputNew(e) {

    if (e.target.id == "curso") {
      let filtroCurso = this.state.cursos.filter(c => c.diaSemanal == e.target.value)

      if (e.target.value != '' && isDefined(filtroCurso) && filtroCurso.length > 0) {
        this.setState({ otroCursoEstadisticas: filtroCurso[0].idClase, diaSemanalOtro: filtroCurso[0].diaSemanal, valueInput: true })
      } else {
        this.setState({ otroCursoEstadisticas: null, diaSemanalOtro: null, valueInput: null })
      }
    } else if (e.target.id == "fechaInicio") {
      if (this.state.verTestJusticia == true) {
        let save = this.state.testJusticiaHistorialSave
        let final
        let value = e.target.value

        if (value != "" && value != undefined) {
          if (this.state.idCursoBuscador != null) {
            if (this.state.idPrgBuscador != null) {
              final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador)
            }

          } else {
            final = save.filter(a => new Date(a.fecha) >= new Date(value))
          }


          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: true, fechaInicio: value })
        } else {

          if (this.state.idCursoBuscador != null) {
            if (this.state.idPrgBuscador != null) {
              final = save.filter(a => a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
            } else {
              final = save.filter(a => a.idClase == this.state.idCursoBuscador)
            }
          } else {
            final = save
          }

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: null, fechaInicio: null })
        }


      } else if (this.state.verCasoPractico == true) {


        let save = this.state.casoPracticoHistorialSave
        let final
        let value = e.target.value

        if (value != "" && value != undefined) {
          if (this.state.idCursoBuscador != null) {
            if (this.state.idPrgBuscador != null) {
              final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador)
            }

          } else {
            final = save.filter(a => new Date(a.fecha) >= new Date(value))
          }


          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestPractico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestPractico.push({
              num: index23 + 1
            })
          }

          this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: true, fechaInicio: value })
        } else {

          if (this.state.idCursoBuscador != null) {
            if (this.state.idPrgBuscador != null) {
              final = save.filter(a => a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
            } else {
              final = save.filter(a => a.idClase == this.state.idCursoBuscador)
            }
          } else {
            final = save
          }

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestPractico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestPractico.push({
              num: index23 + 1
            })
          }

          this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: null, fechaInicio: null })
        }



      }
    } else if (e.target.id == "fechaFin") {
      if (this.state.verTestJusticia == true) {

        let save = this.state.testJusticiaHistorialSave
        let final
        let value = e.target.value
        if (value != "" && value != undefined) {

          if (new Date(this.state.fechaInicio) < new Date(value)) {
            if (this.state.idCursoBuscador != null) {

              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value) && a.idClase == this.state.idCursoBuscador)
              }

            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value))
            }


            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput2: true, fechaFin: value })
          } else {
            swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
          }



        } else {

          if (this.state.fechaInicio != null) {
            if (this.state.idCursoBuscador != null) {

              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador)
              }
            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio))
            }
            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: true, valueInput2: null, fechaFin: null })
          } else {
            if (this.state.idCursoBuscador != null) {
              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => a.idClase == this.state.idCursoBuscador)
              }

            } else {
              final = save
            }

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput2: null, fechaFin: null })
          }

        }


      } else if (this.state.verCasoPractico == true) {


        let save = this.state.casoPracticoHistorialSave
        let final
        let value = e.target.value
        if (value != "" && value != undefined) {

          if (new Date(this.state.fechaInicio) < new Date(value)) {
            if (this.state.idCursoBuscador != null) {

              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value) && a.idClase == this.state.idCursoBuscador)
              }

            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(value))
            }


            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput2: true, fechaFin: value })
          } else {
            swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
          }



        } else {

          if (this.state.fechaInicio != null) {
            if (this.state.idCursoBuscador != null) {

              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador)
              }
            } else {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio))
            }
            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: true, valueInput2: null, fechaFin: null })
          } else {
            if (this.state.idCursoBuscador != null) {
              if (this.state.idPrgBuscador != null) {
                final = save.filter(a => a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
              } else {
                final = save.filter(a => a.idClase == this.state.idCursoBuscador)
              }

            } else {
              final = save
            }

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput2: null, fechaFin: null })
          }

        }



      }
    } else if (e.target.id == "cursoBuscador") {
      if (this.state.verTestJusticia == true) {

        let save = this.state.testJusticiaHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos

        if (value != "" && value != undefined && cursos.filter(c => c.diaSemanal == value).length > 0) {
          let idCurso = cursos.filter(c => c.diaSemanal == value)[0].idClase

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == idCurso)

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestTeorico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestTeorico.push({
                  num: index23 + 1
                })
              }
              let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
              this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == idCurso)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == idCurso)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })
          }




        } else {

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin))

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestTeorico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestTeorico.push({
                  num: index23 + 1
                })
              }

              this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio))

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {

            let maxValue = Math.ceil(save.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: save, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })
          }

        }


      } else if (this.state.verCasoPractico == true) {


        let save = this.state.casoPracticoHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos

        if (value != "" && value != undefined && cursos.filter(c => c.diaSemanal == value).length > 0) {
          let idCurso = cursos.filter(c => c.diaSemanal == value)[0].idClase

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == idCurso)

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestPractico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestPractico.push({
                  num: index23 + 1
                })
              }
              let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
              this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == idCurso)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == idCurso)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })
          }




        } else {

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin))

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestPractico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestPractico.push({
                  num: index23 + 1
                })
              }

              this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio))

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {

            let maxValue = Math.ceil(save.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: save, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })
          }

        }



      } else if (this.state.verExamGes == true) {


        let save = this.state.examGesHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos

        if (value != "" && value != undefined && cursos.filter(c => c.diaSemanal == value).length > 0) {
          let idCurso = cursos.filter(c => c.diaSemanal == value)[0].idClase

          final = save.filter(a => a.idClase == idCurso)

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavExamDesarrollo = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavExamDesarrollo.push({
              num: index23 + 1
            })
          }
          let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
          this.setState({ examGesHistorial: final, listadoNavExamDesarrollo: listadoNavExamDesarrollo, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: true, idCursoBuscador: idCurso, infoSemanasCurso: infoSemanasCurso, idPrgBuscador: null, valueInput4: null })





        } else {

          let maxValue = Math.ceil(save.length / 10)
          let listadoNavExamDesarrollo = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavExamDesarrollo.push({
              num: index23 + 1
            })
          }

          this.setState({ examGesHistorial: save, listadoNavExamDesarrollo: listadoNavExamDesarrollo, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput3: null, idCursoBuscador: null, infoSemanasCurso: null, idPrgBuscador: null, valueInput4: null })


        }



      }
    } else if (e.target.id == "cursoClaseBuscador") {


      if (this.state.verTestJusticia == true) {

        let save = this.state.testJusticiaHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos
        let infoSemanas = this.state.infoSemanasCurso

        if (value != "" && value != undefined && infoSemanas.filter(c => c.numero_semana_clase == value).length > 0) {
          let idCurso = this.state.idCursoBuscador
          let idPrg = infoSemanas.filter(c => c.numero_semana_clase == value)[0].id_programa

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {

            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == idCurso && a.idPrg == idPrg)

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestTeorico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestTeorico.push({
                  num: index23 + 1
                })
              }

              this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }

          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == idCurso && a.idPrg == idPrg)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == idCurso && a.idPrg == idPrg)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })
          }




        } else {

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {

              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == this.state.idCursoBuscador)


              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestTeorico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestTeorico.push({
                  num: index23 + 1
                })
              }

              this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == this.state.idCursoBuscador)
            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestTeorico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestTeorico.push({
                num: index23 + 1
              })
            }

            this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })
          }

        }


      } else if (this.state.verCasoPractico == true) {


        // casoPracticoHistorial
        // casoPracticoHistorialSave
        // listadoNavTestPractico

        let save = this.state.casoPracticoHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos
        let infoSemanas = this.state.infoSemanasCurso

        if (value != "" && value != undefined && infoSemanas.filter(c => c.numero_semana_clase == value).length > 0) {
          let idCurso = this.state.idCursoBuscador
          let idPrg = infoSemanas.filter(c => c.numero_semana_clase == value)[0].id_programa

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {

            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {
              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == idCurso && a.idPrg == idPrg)

              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestPractico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestPractico.push({
                  num: index23 + 1
                })
              }

              this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }

          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == idCurso && a.idPrg == idPrg)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == idCurso && a.idPrg == idPrg)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }
            let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })
          }




        } else {

          if (this.state.fechaInicio != null && this.state.fechaFin != null) {
            if (new Date(this.state.fechaInicio) < new Date(this.state.fechaFin)) {

              final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && new Date(a.fecha) <= new Date(this.state.fechaFin) && a.idClase == this.state.idCursoBuscador)


              let maxValue = Math.ceil(final.length / 10)
              let listadoNavTestPractico = []
              for (let index23 = 0; index23 < maxValue; index23++) {
                listadoNavTestPractico.push({
                  num: index23 + 1
                })
              }

              this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })
            } else {
              swal('Error, fecha no valida.', 'Debe escoger una fecha mayor a la fecha de inicio: ' + moment(this.state.fechaInicio).format('DD-MM-YY') + '.', 'error')
            }
          } else if (this.state.fechaInicio != null && this.state.fechaFin == null) {

            final = save.filter(a => new Date(a.fecha) >= new Date(this.state.fechaInicio) && a.idClase == this.state.idCursoBuscador)

            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })

          } else if (this.state.fechaInicio == null && this.state.fechaFin == null) {
            final = save.filter(a => a.idClase == this.state.idCursoBuscador)
            let maxValue = Math.ceil(final.length / 10)
            let listadoNavTestPractico = []
            for (let index23 = 0; index23 < maxValue; index23++) {
              listadoNavTestPractico.push({
                num: index23 + 1
              })
            }

            this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })
          }

        }


      } else if (this.state.verExamGes == true) {

        let save = this.state.examGesHistorialSave
        let final
        let value = e.target.value
        let cursos = this.state.cursos
        let infoSemanas = this.state.infoSemanasCurso

        if (value != "" && value != undefined && infoSemanas.filter(c => c.numero_semana_clase == value).length > 0) {
          let idCurso = this.state.idCursoBuscador
          let idPrg = infoSemanas.filter(c => c.numero_semana_clase == value)[0].id_programa

          final = save.filter(a => a.idClase == idCurso && a.idPrg == idPrg)

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavExamDesarrollo = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavExamDesarrollo.push({
              num: index23 + 1
            })
          }
          let infoSemanasCurso = this.state.infoSemanal.filter(c => c.id_clase == idCurso)
          this.setState({ examGesHistorial: final, listadoNavExamDesarrollo: listadoNavExamDesarrollo, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: true, idPrgBuscador: idPrg })





        } else {

          final = save.filter(a => a.idClase == this.state.idCursoBuscador)
          let maxValue = Math.ceil(final.length / 10)
          let listadoNavExamDesarrollo = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavExamDesarrollo.push({
              num: index23 + 1
            })
          }

          this.setState({ examGesHistorial: final, listadoNavExamDesarrollo: listadoNavExamDesarrollo, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput4: null, idPrgBuscador: null })


        }


      }
    } else if (e.target.id == 'clasePreparador') {
      let value = e.target.value
      let clasesAlumno = this.state.clasesAlumno

      let valueBus = clasesAlumno.find(c => c.nombreClase == value)
      if (value != '' && valueBus != undefined) {

        this.setState({ otroCursoEstadisticas: valueBus.idClase, diaSemanalOtro: valueBus.nombreClase, valueInput: true })


      } else {
        this.setState({ otroCursoEstadisticas: null, diaSemanalOtro: null, valueInput: null })
      }
      // activa : 0 diaSemanalOnline : "viernes" dia_presencial : "miercoles" dia_semanal : "Miercoles-Presencial" horaClaseOnline : "17:00:00" hora_fin : "21:00:00" hora_inicio : "17:00:00" id_clase : 8 id_oposicion : 4 id_preparador : 15 inicioCursoWord2010 : null maximo_alumnos : 30 minCasoPractico : 50 minTestJusticia :  50 tipoClase : "PRESENCIAL" total_alumnos : 0
    } else if (e.target.id == 'alumnoPreparador') {

      let value = e.target.value
      let busquedaAlumnos = this.state.busquedaAlumnos

      let valueBus = busquedaAlumnos.find(c => c.dni == value)
      if (value != '' && valueBus != undefined) {

        try {
          let data2 = { idUser: valueBus.id_usuario }

          let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response0.ok) {
            throw Error(response0.statusText);
          }
          let json0 = await response0.json();
          let cls0 = await json0;
          await this.setState({ infoSemanal: cls0 });
          let response00 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoBloques", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response00.ok) {
            throw Error(response00.statusText);
          }
          let json00 = await response00.json();
          let cls00 = await json00;
          await this.setState({ infoBloques: cls00 });


          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteTestUser", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          let testPuntuaciones = []
          for (var i = 0; i < await cls2.length; i++) {
            testPuntuaciones.push({
              tipo: 'testJusticia',
              idExp: cls2[i].id_test_expediente == undefined ? ('') : (cls2[i].id_test_expediente),
              idTest: cls2[i].id_test == undefined ? ('') : (cls2[i].id_test),
              idClase: cls2[i].id_clase == undefined ? ('') : (cls2[i].id_clase),
              idPrg: cls2[i].id_prg == undefined ? ('') : (cls2[i].id_prg),
              idBloque: cls2[i].id_bloque == undefined ? ('') : (cls2[i].id_bloque),
              idTemaPrg: cls2[i].id_tema_prg == undefined ? ('') : (cls2[i].id_tema_prg),
              idTema: cls2[i].idTema == undefined ? ('') : (cls2[i].idTema),
              correctas: cls2[i].correctas == undefined ? ('') : (cls2[i].correctas),
              erroneas: cls2[i].erroneas == undefined ? ('') : (cls2[i].erroneas),
              enBlanco: cls2[i].enBlanco == undefined ? ('') : (cls2[i].enBlanco),
              puntuacion: cls2[i].puntuacion == undefined ? ('') : (cls2[i].puntuacion),
              puntuacionReal: cls2[i].puntuacionReal == undefined ? ('') : (cls2[i].puntuacionReal),
              minutos: cls2[i].minutos == undefined ? ('') : (cls2[i].minutos),
              segundos: cls2[i].segundos == undefined ? ('') : (cls2[i].segundos),
              fecha: cls2[i].fecha == undefined ? ('') : (cls2[i].fecha),
              intento: undefined,
              numBloque: cls2[i].numBloque == undefined ? ('') : (cls2[i].numBloque),
              numTestBloque: cls2[i].numTestBloque == undefined ? ('') : (cls2[i].numTestBloque)
            })
          }
          await testPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
          let maxValue = Math.ceil(testPuntuaciones.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }
          for (var z = 0; z < testPuntuaciones.length; z++) {
            let busquedaMismoTest = testPuntuaciones.filter(t => t.idExp != testPuntuaciones[z].idExp && t.idExp < testPuntuaciones[z].idExp && t.idTest == testPuntuaciones[z].idTest && t.idPrg == testPuntuaciones[z].idPrg && t.idBloque == testPuntuaciones[z].idBloque)
            if (busquedaMismoTest && busquedaMismoTest.length > 0) {
              testPuntuaciones[z].intento = busquedaMismoTest.length + 1
            } else {
              testPuntuaciones[z].intento = 1
            }
          }
          await this.setState({ testJusticiaHistorial: testPuntuaciones, testJusticiaHistorialSave: testPuntuaciones, listadoNavTestTeorico: listadoNavTestTeorico });

          let response22 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteCasoPracticoUser", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response22.ok) {
            throw Error(response22.statusText);
          }
          let json22 = await response22.json();
          let cls22 = await json22;
          let casoPracticoPuntuaciones = []
          for (var i = 0; i < await cls22.length; i++) {
            casoPracticoPuntuaciones.push({
              tipo: 'casoPractico',
              idExp: cls22[i].id_test_expediente == undefined ? ('') : (cls22[i].id_test_expediente),
              idTest: cls22[i].id_caso_practico == undefined ? ('') : (cls22[i].id_caso_practico),
              idClase: cls22[i].id_clase == undefined ? ('') : (cls22[i].id_clase),
              idPrg: cls22[i].id_prg == undefined ? ('') : (cls22[i].id_prg),
              idBloque: cls22[i].id_bloque == undefined ? ('') : (cls22[i].id_bloque),
              idTemaPrg: cls22[i].id_tema_prg == undefined ? ('') : (cls22[i].id_tema_prg),
              idTema: cls22[i].idTema == undefined ? ('') : (cls22[i].idTema),
              correctas: cls22[i].correctas == undefined ? ('') : (cls22[i].correctas),
              erroneas: cls22[i].erroneas == undefined ? ('') : (cls22[i].erroneas),
              enBlanco: cls22[i].enBlanco == undefined ? ('') : (cls22[i].enBlanco),
              puntuacion: cls22[i].puntuacion == undefined ? ('') : (cls22[i].puntuacion),
              puntuacionReal: cls22[i].puntuacionReal == undefined ? ('') : (cls22[i].puntuacionReal),
              minutos: cls22[i].minutos == undefined ? ('') : (cls22[i].minutos),
              segundos: cls22[i].segundos == undefined ? ('') : (cls22[i].segundos),
              fecha: cls22[i].fecha == undefined ? ('') : (cls22[i].fecha),
              intento: undefined,
              numBloque: cls22[i].numBloque == undefined ? ('') : (cls22[i].numBloque),
              numTestBloque: cls22[i].numTestBloque == undefined ? ('') : (cls22[i].numTestBloque)
            })
          }
          let todosLosExpedientesCP = await casoPracticoPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))

          let maxValue2 = Math.ceil(todosLosExpedientesCP.length / 10)
          let listadoNavTestPractico = []
          for (let index23t = 0; index23t < maxValue2; index23t++) {
            listadoNavTestPractico.push({
              num: index23t + 1
            })
          }

          for (var z = 0; z < todosLosExpedientesCP.length; z++) {
            let busquedaMismoTest = todosLosExpedientesCP.filter(t => t.idExp != todosLosExpedientesCP[z].idExp && t.idExp < todosLosExpedientesCP[z].idExp && t.idTest == todosLosExpedientesCP[z].idTest && t.idPrg == todosLosExpedientesCP[z].idPrg && t.idBloque == todosLosExpedientesCP[z].idBloque)
            if (busquedaMismoTest && busquedaMismoTest.length > 0) {
              todosLosExpedientesCP[z].intento = busquedaMismoTest.length + 1
            } else {
              todosLosExpedientesCP[z].intento = 1
            }
          }

          await this.setState({ casoPracticoHistorial: todosLosExpedientesCP, casoPracticoHistorialSave: todosLosExpedientesCP, listadoNavTestPractico: listadoNavTestPractico });

          // sacar examGesHistorial
          let response2212 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2212.ok) {
            throw Error(response2212.statusText);
          }
          let json2212 = await response2212.json();
          let cls2212 = await json2212;
          let examGesHistorial = []

          for (var i = 0; i < cls2212.length; i++) {
            //if(cls2212[i].puntuacion != null){
            examGesHistorial.push(cls2212[i])
            //}
          }

          let examGesHistorialOrder = await examGesHistorial.sort((a, b) => b.id_exp - a.id_exp)
          let maxValue3 = Math.ceil(examGesHistorialOrder.length / 10)
          let listadoNavExamDesarrollo = []
          for (let index23t2 = 0; index23t2 < maxValue3; index23t2++) {
            listadoNavExamDesarrollo.push({
              num: index23t2 + 1
            })
          }

          await this.setState({ examGesHistorial: examGesHistorialOrder, examGesHistorialSave: examGesHistorialOrder, listadoNavExamDesarrollo: listadoNavExamDesarrollo });


          let data2x = { id_usuario: valueBus.id_usuario }

          let response2x = await fetch("https://oposiciones-justicia.es/api/oficina/infoAlumnoClase", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2x)
          });
          if (!response2x.ok) {
            throw Error(response2x.statusText);
          }
          let json2x = await response2x.json();
          let cls2x = await json2x;
          let clasesAlumno = []

          for (let i = 0; i < cls2x.length; i++) {
            clasesAlumno.push({
              idClase: cls2x[i].idClase,
              nombreClase: cls2x[i].nombreClase
            })
          }


          this.setState({ valueInput: null, infoAlumnoBuscado: valueBus, clasesAlumno: clasesAlumno })



        } catch (e) { console.log(e) }


      } else {
        this.setState({ infoSemanal: null });
        this.setState({ infoBloques: null });
        this.setState({ testJusticiaHistorial: null, testJusticiaHistorialSave: null, listadoNavTestTeorico: null });
        this.setState({ casoPracticoHistorial: null, casoPracticoHistorialSave: null, listadoNavTestPractico: null });
        this.setState({ examGesHistorial: null, examGesHistorialSave: null, listadoNavExamDesarrollo: null });
        this.setState({ otroCursoEstadisticas: null, diaSemanalOtro: null, valueInput: null, infoAlumnoBuscado: null, clasesAlumno: null })


      }

    } else if (e.target.id == "temaLoadBus") {
      if (this.state.verTestJusticia == true) {
        let save = this.state.testJusticiaHistorialSave
        let final
        let value = e.target.value

        let temasBus = this.props.temasFacturados.find(t => t.titulo_tema == value)

        if (temasBus != undefined) {
          final = save.filter(a => a.temasPrg.length > 0 && a.temasPrg.find(t => t.id_tema == temasBus.idTema) != undefined)

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0 })

        } else {

          let final = save
          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ testJusticiaHistorial: final, listadoNavTestTeorico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0 })
        }



      } else if (this.state.verCasoPractico == true) {

        let save = this.state.casoPracticoHistorialSave
        let final
        let value = e.target.value

        let temasBus = this.props.temasFacturados.find(t => t.titulo_tema == value)

        if (temasBus != undefined) {
          final = save.filter(a => a.temasPrg.length > 0 && a.temasPrg.find(t => t.id_tema == temasBus.idTema) != undefined)

          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0 })

        } else {

          let final = save
          let maxValue = Math.ceil(final.length / 10)
          let listadoNavTestTeorico = []
          for (let index23 = 0; index23 < maxValue; index23++) {
            listadoNavTestTeorico.push({
              num: index23 + 1
            })
          }

          this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestTeorico, navTestIni: 0, navTestFin: 10, numViewTest: 0 })
        }


        // let save = this.state.casoPracticoHistorialSave
        // let final
        // let value = e.target.value

        // if (value != "" && value != undefined) {
        //   if (this.state.idCursoBuscador != null) {
        //     if (this.state.idPrgBuscador != null) {
        //       final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
        //     } else {
        //       final = save.filter(a => new Date(a.fecha) >= new Date(value) && a.idClase == this.state.idCursoBuscador)
        //     }

        //   } else {
        //     final = save.filter(a => new Date(a.fecha) >= new Date(value))
        //   }


        //   let maxValue = Math.ceil(final.length / 10)
        //   let listadoNavTestPractico = []
        //   for (let index23 = 0; index23 < maxValue; index23++) {
        //     listadoNavTestPractico.push({
        //       num: index23 + 1
        //     })
        //   }

        //   this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: true, fechaInicio: value })
        // } else {

        //   if (this.state.idCursoBuscador != null) {
        //     if (this.state.idPrgBuscador != null) {
        //       final = save.filter(a => a.idClase == this.state.idCursoBuscador && a.idPrg == this.state.idPrgBuscador)
        //     } else {
        //       final = save.filter(a => a.idClase == this.state.idCursoBuscador)
        //     }
        //   } else {
        //     final = save
        //   }

        //   let maxValue = Math.ceil(final.length / 10)
        //   let listadoNavTestPractico = []
        //   for (let index23 = 0; index23 < maxValue; index23++) {
        //     listadoNavTestPractico.push({
        //       num: index23 + 1
        //     })
        //   }

        //   this.setState({ casoPracticoHistorial: final, listadoNavTestPractico: listadoNavTestPractico, navTestIni: 0, navTestFin: 10, numViewTest: 0, valueInput1: null, fechaInicio: null })
        // }



      }
    }



  }

  async listadoArtErrores(test) {
    try {
      let listadoErrores = []
      for (var i = 0; i < test.length; i++) {
        let pregunta = test[i]
        let pregTest = await corregirTest(pregunta)
        if (pregTest == 2) {
          listadoErrores.push(pregunta)
        } else if (pregTest == 0) {
          listadoErrores.push(pregunta)
        }
      }
      return listadoErrores
    } catch (e) { console.log(e) }
  }

  async handleOnSubmit(e) {
    if (e.target.id == 'verTestJusticiaHistorial') {
      let maxValue = Math.ceil(this.state.testJusticiaHistorialSave.length / 10)
      let listadoNavTestTeorico = []
      for (let index = 0; index < maxValue; index++) {
        listadoNavTestTeorico.push({
          num: index + 1
        })
      }

      this.setState({ verTestJusticia: true, verCasoPractico: false, verExamGes: false, numViewTest: 0, navTestIni: 0, navTestFin: 10, ejerLoadIdExp: null, fechaInicio: null, fechaFin: null, idCursoBuscador: null, idPrgBuscador: null, valueInput1: null, valueInput2: null, valueInput3: null, valueInput4: null, testJusticiaHistorial: this.state.testJusticiaHistorialSave, listadoNavTestTeorico: listadoNavTestTeorico })

    } else if (e.target.id == 'verCPHistorial') {
      let maxValue = Math.ceil(this.state.casoPracticoHistorialSave.length / 10)
      let listadoNavTestPractico = []
      for (let index = 0; index < maxValue; index++) {
        listadoNavTestPractico.push({
          num: index + 1
        })
      }

      this.setState({ verTestJusticia: false, verCasoPractico: true, verExamGes: false, numViewTest: 0, navTestIni: 0, navTestFin: 10, ejerLoadIdExp: null, fechaInicio: null, fechaFin: null, idCursoBuscador: null, idPrgBuscador: null, valueInput1: null, valueInput2: null, valueInput3: null, valueInput4: null, casoPracticoHistorial: this.state.casoPracticoHistorialSave, listadoNavTestPractico: listadoNavTestPractico })
    } else if (e.target.id == "cerrarEjerLoadView") {
      this.setState({ ejerLoadIdExp: null })
    } else if (e.target.id == "atras") {
      let actual = this.state.numViewTest
      // let ini = this.state.navTestIni
      // let fin = this.state.navTestFin

      let next = actual - 1
      if (this.state.verTestJusticia == true && this.state.testJusticiaHistorial && this.state.testJusticiaHistorial.length > 0) {
        let maxValue = Math.ceil(this.state.testJusticiaHistorial.length / 10)

        if (next >= 0 && next < maxValue) {
          this.setState({ numViewTest: next })

          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navTestIni: 0, navTestFin: maxValue })
            }
          }
        }
      } else if (this.state.verCasoPractico == true && this.state.casoPracticoHistorial && this.state.casoPracticoHistorial.length > 0) {
        let maxValue = Math.ceil(this.state.casoPracticoHistorial.length / 10)

        if (next >= 0 && next < maxValue) {
          this.setState({ numViewTest: next })

          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navTestIni: 0, navTestFin: maxValue })
            }
          }
        }
      } else if (this.state.verExamGes == true && this.state.examGesHistorial && this.state.examGesHistorial.length > 0) {
        let maxValue = Math.ceil(this.state.examGesHistorial.length / 10)

        if (next >= 0 && next < maxValue) {
          this.setState({ numViewTest: next })

          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navTestIni: 0, navTestFin: maxValue })
            }
          }
        }
      }

    } else if (e.target.id == "adelante") {
      let actual = this.state.numViewTest

      if (this.state.verTestJusticia == true) {

        let next = actual + 1
        if (this.state.verTestJusticia == true && this.state.testJusticiaHistorial && this.state.testJusticiaHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.testJusticiaHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }
      } else if (this.state.verCasoPractico == true) {
        let next = actual + 1
        if (this.state.verCasoPractico == true && this.state.casoPracticoHistorial && this.state.casoPracticoHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.casoPracticoHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }
      } else if (this.state.verExamGes == true) {
        let next = actual + 1
        if (this.state.verExamGes == true && this.state.examGesHistorial && this.state.examGesHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.examGesHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }
      }

    } else if (e.target.id == 'verTest') {

      let testPuntuaciones = this.state.testJusticiaHistorial
      let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      try {
        let testNew = []
        let data1 = { idTest: idTest, idExp: idExp }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          for (var i = 0; i < await cls1.length; i++) {
            testNew.push({
              dudosa: cls1[i].dudosa,
              idTest: cls1[i].idTest, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
              pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
              idLey: cls1[i].idLey,
              siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
              nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
              idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
              idClase: cls1[i].idClase != null ? (cls1[i].idClase) : (""),
              idPrg: cls1[i].idPrg != null ? (cls1[i].idPrg) : (""),
              idBloque: cls1[i].idBloque != null ? (cls1[i].idBloque) : (""),
              numBloque: cls1[i].numBloque != null ? (cls1[i].numBloque) : (""),
              numTestBloque: cls1[i].numTestBloque != null ? (cls1[i].numTestBloque) : (""),
              idTema: cls1[i].idTema != null ? (cls1[i].idTema) : (""),
              minutos: cls1[i].minutos,
              segundos: cls1[i].segundos,
              fecha: cls1[i].fecha,
              puntuacion: cls1[i].puntuacion,
              respuestas: []
            })
            let r1Sbr = cls1[i].R1
            if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
              r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
            }

            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR1,
              respuesta: r1Sbr,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUser: cls1[i].r1ValorUser
            })
            let r2Sbr = cls1[i].R2
            if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
              r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR2,
              respuesta: r2Sbr,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUser: cls1[i].r2ValorUser
            })
            let r3Sbr = cls1[i].R3
            if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
              r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR3,
              respuesta: r3Sbr,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUser: cls1[i].r3ValorUser
            })
            let r4Sbr = cls1[i].R4
            if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
              r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR4,
              respuesta: r4Sbr,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUser: cls1[i].r4ValorUser
            })
          }
          if (testNew != undefined) {
            let testRespuestasBien = await comprobarRespuestas(testNew)
            await this.props.loadTestJusticia(undefined)
            await this.props.loadTestJusticia(testRespuestasBien)
            await this.props.history.push('/revisionTest');
          }
        }
      } catch (e) { console.log(e) }
    } else if (e.target.id == 'verCasoPractico') {
      try {

        let testPuntuaciones = this.state.casoPracticoHistorial
        let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp

        let testNew = []
        let htmlText = undefined
        let data1 = { idTest: idTest, idExp: idExp }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        if (cls1 != undefined && cls1.length > 0) {
          htmlText = cls1[0].textHtml
          for (var i = 0; i < await cls1.length; i++) {
            testNew.push({
              dudosa: cls1[i].dudosa,
              idTest: cls1[i].idTest, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
              pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
              idLey: cls1[i].idLey,
              siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
              nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
              idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
              idClase: cls1[i].idClase != null ? (cls1[i].idClase) : (""),
              idPrg: cls1[i].idPrg != null ? (cls1[i].idPrg) : (""),
              idBloque: cls1[i].idBloque != null ? (cls1[i].idBloque) : (""),
              numBloque: cls1[i].numBloque != null ? (cls1[i].numBloque) : (""),
              numTestBloque: cls1[i].numTestBloque != null ? (cls1[i].numTestBloque) : (""),
              idTema: cls1[i].idTema != null ? (cls1[i].idTema) : (""),
              minutos: cls1[i].minutos,
              segundos: cls1[i].segundos,
              fecha: cls1[i].fecha,
              puntuacion: cls1[i].puntuacion,
              respuestas: []
            })
            let r1Sbr = cls1[i].R1
            if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
              r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
            }

            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR1,
              respuesta: r1Sbr,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUser: cls1[i].r1ValorUser
            })
            let r2Sbr = cls1[i].R2
            if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
              r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR2,
              respuesta: r2Sbr,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUser: cls1[i].r2ValorUser
            })
            let r3Sbr = cls1[i].R3
            if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
              r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR3,
              respuesta: r3Sbr,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUser: cls1[i].r3ValorUser
            })
            let r4Sbr = cls1[i].R4
            if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
              r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
            }
            testNew[testNew.length - 1].respuestas.push({
              idR: cls1[i].idR4,
              respuesta: r4Sbr,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUser: cls1[i].r4ValorUser
            })
          }
          if (testNew != undefined && htmlText != undefined) {
            let testRespuestasBien = await comprobarRespuestas(testNew)
            //console.log(testRespuestasBien)
            // borrar caso y test load!
            await this.props.loadCasoPractico(undefined, 'htmlText')
            await this.props.loadCasoPractico(testRespuestasBien, htmlText)
            await this.props.history.push('/revisionTestPractico');
          }
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id == 'revisarPuntuacion') {

      // sacar resultado test.

      let testPuntuaciones = this.state.testJusticiaHistorial
      let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      let idClase = await testPuntuaciones[numExpediente].idClase
      let testNew = []
      let name = "¿Esta seguro de Revisar la Puntuación del Test?";
      let content = document.createElement('div');
      content.innerHTML = `Se realizara una comprobación de la puntuación del test y se actualizara la puntuación.`
      content.style.textAlign = "left";

      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cancelar",
          catch: {
            text: "Recalcular Puntuación!",
            value: "catch",
          },
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        switch (value) {

          case "catch":
            let data1 = { idTest: idTest, idExp: idExp }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                testNew.push({
                  dudosa: cls1[i].dudosa,
                  idTest: cls1[i].idTest, idClase: idClase, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                  nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: []
                })
                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
              }

            }


            let test = await testNew;
            let resultadosAds = await this.bienMalBlanco(test)
            let data = {
              correcta: resultadosAds.correcta,
              mal: resultadosAds.mal,
              enBlanco: resultadosAds.enBlanco,
              puntuacion: resultadosAds.puntuacion,
              puntuacionReal: resultadosAds.puntuacionReal,
              idTestExp: idExp
            }

            let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/UpdateTestPuntuacion", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;
            if (cls2 != undefined) {
              let content = document.createElement('div');
              content.innerHTML = `
            <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
            Puntuación: <b>`+ data.puntuacion + `</b>.</br>
            <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
            Correctas: <b>`+ data.correcta + `</b>.</br>
            <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
            Erroneas: <b>`+ data.mal + `.</b></br>
            <i style="color: Dodgerblue;" class="far fa-circle"></i>
            En Blanco: <b>`+ data.enBlanco + `</b>. </br></br>
            <b>Se recargara la web en 3 segundos para visualizar los cambios.</b>
            `
              content.style.textAlign = "left";
              swal({
                title: 'Puntuación actualizada',
                content: content,
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })
              setTimeout(
                function () {
                  window.location.reload();
                }.bind(this), 3000);
            }

            break;

          default:

        }
      });


    } else if (e.target.id == 'revisarPuntuacionCP') {

      // sacar resultado test.
      let testPuntuaciones = this.state.casoPracticoHistorial
      let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      let idClase = await testPuntuaciones[numExpediente].idClase
      let testNew = []
      let name = "¿Esta seguro de Revisar la Puntuación del Test?";
      let content = document.createElement('div');
      content.innerHTML = `Se realizara una comprobación de la puntuación del test y se actualizara la puntuación.`
      content.style.textAlign = "left";

      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cancelar",
          catch: {
            text: "Recalcular Puntuación!",
            value: "catch",
          },
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        switch (value) {

          case "catch":
            let data1 = { idTest: idTest, idExp: idExp }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                testNew.push({
                  idTest: cls1[i].idTest, idClase: idClase, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                  nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: []
                })
                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
              }

            }


            let test = await testNew;
            let resultadosAds = await this.bienMalBlancoCP(test)
            let data = {
              correcta: resultadosAds.correcta,
              mal: resultadosAds.mal,
              enBlanco: resultadosAds.enBlanco,
              puntuacion: resultadosAds.puntuacion,
              puntuacionReal: resultadosAds.puntuacionReal,
              idTestExp: idExp
            }

            let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/UpdateTestPuntuacionCP", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;
            if (cls2 != undefined) {
              let content = document.createElement('div');
              content.innerHTML = `
            <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
            Puntuación: <b>`+ data.puntuacion + `</b>.</br>
            <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
            Correctas: <b>`+ data.correcta + `</b>.</br>
            <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
            Erroneas: <b>`+ data.mal + `.</b></br>
            <i style="color: Dodgerblue;" class="far fa-circle"></i>
            En Blanco: <b>`+ data.enBlanco + `</b>. </br></br>
            <b>Se recargara la web en 3 segundos para visualizar los cambios.</b>
            `
              content.style.textAlign = "left";
              swal({
                title: 'Puntuación actualizada',
                content: content,
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })
              setTimeout(
                function () {
                  window.location.reload();
                }.bind(this), 3000);
            }

            break;

          default:

        }
      });


    } else if (e.target.id == 'borrarTest') {

      let testPuntuaciones = this.state.testJusticiaHistorial
      let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)

      let idExp = await testPuntuaciones[numExpediente].idExp
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            let data = { idExp: idExp }
            fetch("https://oposiciones-justicia.es/api/usuarios/delTestExp", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                let content = document.createElement('div');
                content.innerHTML = `Se recargara la web en 3 segundos para visualizar los cambios.`
                content.style.textAlign = "left";
                swal({
                  title: 'Test Teórico Eliminado',
                  content: content,
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })
                setTimeout(
                  function () {
                    window.location.reload();
                  }.bind(this), 3000);
              }
            }).catch(function (err) { console.log(err) });

          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });
    } else if (e.target.id == 'borrarCP') {


      let testPuntuaciones = this.state.casoPracticoHistorial
      let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)

      let idExp = await testPuntuaciones[numExpediente].idExp

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            let data = { idExp: idExp }
            fetch("https://oposiciones-justicia.es/api/usuarios/delCPExp", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                let content = document.createElement('div');
                content.innerHTML = `Se recargara la web en 3 segundos para visualizar los cambios.`
                content.style.textAlign = "left";
                swal({
                  title: 'Test Práctico Eliminado',
                  content: content,
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })
                setTimeout(
                  function () {
                    window.location.reload();
                  }.bind(this), 3000);
              }
            }).catch(function (err) { console.log(err) });

          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });

    } else if (e.target.id == 'repetirTestJus') {

      try {

        let testPuntuaciones = this.state.testJusticiaHistorial
        let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp

        let idClase = testPuntuaciones[numExpediente].idClase != "" ? (await testPuntuaciones[numExpediente].idClase) : ("")
        let idBloque = testPuntuaciones[numExpediente].idBloque != "" ? (await testPuntuaciones[numExpediente].idBloque) : ("")
        let numBloque = testPuntuaciones[numExpediente].numBloque != "" ? (await testPuntuaciones[numExpediente].numBloque) : ("")
        let numTestBloque = testPuntuaciones[numExpediente].numTestBloque != "" ? (await testPuntuaciones[numExpediente].numTestBloque) : ("")
        let idPrg = testPuntuaciones[numExpediente].idPrg != "" ? (await testPuntuaciones[numExpediente].idPrg) : ("")
        let idTema = testPuntuaciones[numExpediente].idTema != "" ? (await testPuntuaciones[numExpediente].idTema) : ("")




        let testConRespuestas = await selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
        let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))

        if (this.props.testJusticiaUserLoad.length > 0) {
          //Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
          let name = "¡Ojo! - Test sin finalizar";
          let content = document.createElement('div');
          content.innerHTML = `
            Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
            Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
            `
          content.style.textAlign = "left";

          swal({
            title: name,
            content: content,
            icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
            buttons: {
              cancel: "Borrar Test",
              catch: {
                text: "Ir a finalizar test",
                value: "catch",
              },
              defeat: false,
            },
            closeOnEsc: false,
            closeOnClickOutside: false,
          }).then(async (value) => {
            switch (value) {

              case "catch":
                await this.props.history.push('/hacerTestJusticia');
                break;

              default:
                await this.props.borrarTestUser()
                await this.props.loadTestUser(testPregResMezcla)
                await this.props.history.push('/hacerTestJusticia');
            }
          });
        } else {
          await this.props.loadTestUser(testPregResMezcla)
          await this.props.history.push('/hacerTestJusticia');
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id.split('-')[0] == 'repetirTestCP') {

      try {

        let testPuntuaciones = this.state.casoPracticoHistorial
        let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp

        let idClase = testPuntuaciones[numExpediente].idClase != "" ? (await testPuntuaciones[numExpediente].idClase) : ("")
        let idBloque = testPuntuaciones[numExpediente].idBloque != "" ? (await testPuntuaciones[numExpediente].idBloque) : ("")
        let numBloque = testPuntuaciones[numExpediente].numBloque != "" ? (await testPuntuaciones[numExpediente].numBloque) : ("")
        let numTestBloque = testPuntuaciones[numExpediente].numTestBloque != "" ? (await testPuntuaciones[numExpediente].numTestBloque) : ("")
        let idPrg = testPuntuaciones[numExpediente].idPrg != "" ? (await testPuntuaciones[numExpediente].idPrg) : ("")
        let idTema = testPuntuaciones[numExpediente].idTema != "" ? (await testPuntuaciones[numExpediente].idTema) : ("")



        let testConRespuestas = await selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
        // let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))
        let testPregResMezcla = await comprobarRespuestas(await testConRespuestas)

        let htmlText = undefined
        let data134 = { idTest: idTest }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoTextCasoPractico", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data134)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        htmlText = await cls1[0].textHtml
        if (this.props.casoPracticoUserLoad.length > 0) {
          //Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
          let name = "¡Ojo! - Test sin finalizar";
          let content = document.createElement('div');
          content.innerHTML = `
            Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
            Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
            `
          content.style.textAlign = "left";

          swal({
            title: name,
            content: content,
            icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
            buttons: {
              cancel: "Borrar Test",
              catch: {
                text: "Ir a finalizar test",
                value: "catch",
              },
              defeat: false,
            },
            closeOnEsc: false,
            closeOnClickOutside: false,
          }).then(async (value) => {
            switch (value) {

              case "catch":
                await this.props.history.push('/casoPracticoJusticia');
                break;

              default:
                await this.props.borrarCP()
                await this.props.loadCPUser(testPregResMezcla, htmlText)
                await this.props.history.push('/casoPracticoJusticia');
            }
          });
        } else {
          await this.props.loadCPUser(testPregResMezcla, htmlText)
          await this.props.history.push('/casoPracticoJusticia');
        }

      } catch (e) { console.log(e) }



    } else if (e.target.id.split('-')[0] == 'verExamGesHis') {
      let maxValue = Math.ceil(this.state.examGesHistorialSave.length / 10)
      let listadoNavExamDesarrollo = []
      for (let index = 0; index < maxValue; index++) {
        listadoNavExamDesarrollo.push({
          num: index + 1
        })
      }

      this.setState({ verTestJusticia: false, verCasoPractico: false, verExamGes: true, numViewTest: 0, navTestIni: 0, navTestFin: 10, ejerLoadIdExp: null, fechaInicio: null, fechaFin: null, idCursoBuscador: null, idPrgBuscador: null, valueInput1: null, valueInput2: null, valueInput3: null, valueInput4: null, examGesHistorial: this.state.examGesHistorialSave, listadoNavExamDesarrollo: listadoNavExamDesarrollo })
    } else if (e.target.id.split('-')[0] == 'downloadMiExamen') {
      let loca = e.target.id.split('-')[1]
      let pathFile = this.state.examGesHistorial.find(t => t.id_exp == this.state.examGesHistorial[loca].id_exp).linkArchivoAlumno
      if (pathFile != undefined) {
        swal('Iniciando descarga, espere.', 'Si en 20 segundos no se inicia vuelva a darle clic', 'info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga, reportelo, gracias.")
        });
      }
    } else if (e.target.id.split('-')[0] == 'downloadExamenResuelto') {
      let loca = e.target.id.split('-')[1]
      let examen = this.state.examGesHistorial.find(t => t.id_exp == this.state.examGesHistorial[loca].id_exp)
      let idExamGes = this.state.infoBloques.find(b => b.id_semana == examen.idPrg && b.id_bloque == examen.idBloque).idExamenGestion
      let pathFile = this.state.examenGestion.find(e => e.id == idExamGes).linkSolucion
      if (pathFile != undefined) {
        swal('Iniciando descarga, espere.', 'Si en 20 segundos no se inicia vuelva a darle clic', 'info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga, reportelo, gracias.")
        });
      }
    } else if (e.target.id.split('-')[0] == 'infoTest') {
      let key = e.target.id.split('-')[1]
      // $('#infoTestH-'+key).fadeToggle(700).css("display", "flex");
      // $('#infoTestH3-'+key).fadeToggle(700).css("display", "flex");

      if (this.state.verTestJusticia == true) {
        let numExpediente = this.state.testJusticiaHistorial.findIndex(c => c.idExp == this.state.testJusticiaHistorial.slice(10 * this.state.numViewTest, this.state.numViewTest == 0 ? (10) : (10 *
          (this.state.numViewTest + 1)))[key].idExp)
        let testPuntuaciones = this.state.testJusticiaHistorial
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp
        let idClase = await testPuntuaciones[numExpediente].idClase
        let testNew = []

        let listadoErroresExiste = testPuntuaciones[numExpediente].listadoErrores
        if (listadoErroresExiste == undefined) {

          let data1 = { idTest: idTest, idExp: idExp }

          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              testNew.push({
                dudosa: cls1[i].dudosa,
                idTest: cls1[i].idTest, idClase: idClase, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUsuario: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUsuario: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUsuario: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUsuario: cls1[i].r4ValorUser
              })
            }

          }

          let test = await testNew;

          let resultadosAds = await this.listadoArtErrores(test)

          let filtroErrores = []
          for (var i = 0; i < resultadosAds.length; i++) {
            let pregunta = resultadosAds[i]

            if (filtroErrores.length > 0) {

              let locaSiglas = await filtroErrores.findIndex(c => c.siglasLey == pregunta.siglasLey)
              if (locaSiglas != -1) {
                let art = pregunta.articulo

                if (art.split('.y.').length > 1) {
                  let artRevisado = ""
                  for (let rty = 0; rty < art.split('.y.').length; rty++) {
                    if (rty == 0) {
                      artRevisado = art.split('.y.')[rty] + ""
                    } else if (rty < art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                    } else if (rty == art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                    }
                  }
                  art = artRevisado
                }

                filtroErrores[locaSiglas].articulos.push({
                  art: art,
                  numPreg: pregunta.numPreg
                })
              } else {
                let art = pregunta.articulo

                if (art.split('.y.').length > 1) {
                  let artRevisado = ""
                  for (let rty = 0; rty < art.split('.y.').length; rty++) {
                    if (rty == 0) {
                      artRevisado = art.split('.y.')[rty] + ""
                    } else if (rty < art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                    } else if (rty == art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                    }
                  }
                  art = artRevisado
                }

                filtroErrores.push({
                  siglasLey: pregunta.siglasLey,
                  nombreLey: pregunta.nombreLey,
                  articulos: [{ art: art, numPreg: pregunta.numPreg }]
                })
              }

            } else {
              let art = pregunta.articulo

              if (art.split('.y.').length > 1) {
                let artRevisado = ""
                for (let rty = 0; rty < art.split('.y.').length; rty++) {
                  if (rty == 0) {
                    artRevisado = art.split('.y.')[rty] + ""
                  } else if (rty < art.split('.y.').length - 1) {
                    artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                  } else if (rty == art.split('.y.').length - 1) {
                    artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                  }
                }
                art = artRevisado
              }

              filtroErrores.push({
                siglasLey: pregunta.siglasLey,
                nombreLey: pregunta.nombreLey,
                articulos: [{ art: art, numPreg: pregunta.numPreg }]
              })
            }

          }

          for (var j = 0; j < filtroErrores.length; j++) {
            filtroErrores[j].articulos = filtroErrores[j].articulos.sort((a, b) => a.art.split('.')[0] - b.art.split('.')[0])
          }

          testPuntuaciones[numExpediente].listadoErrores = filtroErrores
          await this.setState({ testJusticiaHistorial: testPuntuaciones, ejerLoadIdExp: idExp })
          //$('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        } else {
          //$('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
          this.setState({ ejerLoadIdExp: idExp })
        }

      } else if (this.state.verCasoPractico == true) {

        let numExpediente = this.state.casoPracticoHistorial.findIndex(c => c.idExp == this.state.casoPracticoHistorial.slice(1 * this.state.numViewTest, this.state.numViewTest == 0 ? (10) : (10 * this.state.numViewTest))[key].idExp)

        let testPuntuaciones = this.state.casoPracticoHistorial
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp
        let idClase = await testPuntuaciones[numExpediente].idClase
        let testNew = []

        let listadoErroresExiste = testPuntuaciones[numExpediente].listadoErrores
        if (listadoErroresExiste == undefined) {

          let data1 = { idTest: idTest, idExp: idExp }
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              testNew.push({
                idTest: cls1[i].idTest, idClase: idClase, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUsuario: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUsuario: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUsuario: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUsuario: cls1[i].r4ValorUser
              })
            }

          }

          let test = await testNew;
          let resultadosAds = await this.listadoArtErrores(test)


          let filtroErrores = []
          for (var i = 0; i < resultadosAds.length; i++) {
            let pregunta = resultadosAds[i]

            if (filtroErrores.length > 0) {

              let locaSiglas = await filtroErrores.findIndex(c => c.siglasLey == pregunta.siglasLey)
              if (locaSiglas != -1) {
                let art = pregunta.articulo

                if (art.split('.y.').length > 1) {
                  let artRevisado = ""
                  for (let rty = 0; rty < art.split('.y.').length; rty++) {
                    if (rty == 0) {
                      artRevisado = art.split('.y.')[rty] + ""
                    } else if (rty < art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                    } else if (rty == art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                    }
                  }
                  art = artRevisado
                }
                filtroErrores[locaSiglas].articulos.push({
                  art: art,
                  numPreg: pregunta.numPreg
                })
              } else {
                let art = pregunta.articulo

                if (art.split('.y.').length > 1) {
                  let artRevisado = ""
                  for (let rty = 0; rty < art.split('.y.').length; rty++) {
                    if (rty == 0) {
                      artRevisado = art.split('.y.')[rty] + ""
                    } else if (rty < art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                    } else if (rty == art.split('.y.').length - 1) {
                      artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                    }
                  }
                  art = artRevisado
                }
                filtroErrores.push({
                  siglasLey: pregunta.siglasLey,
                  nombreLey: pregunta.nombreLey,
                  articulos: [{ art: art, numPreg: pregunta.numPreg }]
                })
              }

            } else {
              let art = pregunta.articulo

              if (art.split('.y.').length > 1) {
                let artRevisado = ""
                for (let rty = 0; rty < art.split('.y.').length; rty++) {
                  if (rty == 0) {
                    artRevisado = art.split('.y.')[rty] + ""
                  } else if (rty < art.split('.y.').length - 1) {
                    artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
                  } else if (rty == art.split('.y.').length - 1) {
                    artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
                  }
                }
                art = artRevisado
              }
              filtroErrores.push({
                siglasLey: pregunta.siglasLey,
                nombreLey: pregunta.nombreLey,
                articulos: [{ art: art, numPreg: pregunta.numPreg }]
              })
            }

          }

          for (var j = 0; j < filtroErrores.length; j++) {
            filtroErrores[j].articulos = filtroErrores[j].articulos.sort((a, b) => a.art.split('.')[0] - b.art.split('.')[0])
          }

          testPuntuaciones[numExpediente].listadoErrores = filtroErrores
          await this.setState({ casoPracticoHistorial: testPuntuaciones, ejerLoadIdExp: idExp })
          //$('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        } else {
          //$('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
          this.setState({ ejerLoadIdExp: idExp })
        }

      } else if (this.state.verExamGes == true) {

        let numExpediente = this.state.examGesHistorial.findIndex(c => c.id_exp == this.state.examGesHistorial.slice(1 * this.state.numViewTest, this.state.numViewTest == 0 ? (10) : (10 * this.state.numViewTest))[key].id_exp)

        let idExp = await this.state.examGesHistorial[numExpediente].id_exp


        this.setState({ ejerLoadIdExp: idExp })


      }

    } else if (e.target.id.split('-')[0] == "clicNavDirect") {
      if (this.state.verTestJusticia == true) {

        let locaClic = e.target.id.split('-')[1]
        let ini = this.state.navTestIni
        let fin = this.state.navTestFin
        let next = this.state.listadoNavTestTeorico.slice(ini, fin)[locaClic].num - 1
        if (this.state.verTestJusticia == true && this.state.testJusticiaHistorial && this.state.testJusticiaHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.testJusticiaHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }


      } else if (this.state.verCasoPractico == true) {

        let locaClic = e.target.id.split('-')[1]
        let ini = this.state.navTestIni
        let fin = this.state.navTestFin
        let next = this.state.listadoNavTestPractico.slice(ini, fin)[locaClic].num - 1
        if (this.state.verCasoPractico == true && this.state.casoPracticoHistorial && this.state.casoPracticoHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.casoPracticoHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }

      } else if (this.state.verExamGes == true) {

        let locaClic = e.target.id.split('-')[1]
        let ini = this.state.navTestIni
        let fin = this.state.navTestFin
        let next = this.state.listadoNavExamDesarrollo.slice(ini, fin)[locaClic].num - 1
        if (this.state.verExamGes == true && this.state.examGesHistorial && this.state.examGesHistorial.length > 0) {
          let maxValue = Math.ceil(this.state.examGesHistorial.length / 10)

          if (next >= 0 && next < maxValue) {
            this.setState({ numViewTest: next })

            //  vemos inicio
            if (next - 5 >= 0) {

              if (next + 5 <= maxValue) {
                this.setState({ navTestIni: (next - 5), navTestFin: (next + 5) })
              } else {
                let sumarCompleteNav = (next + 5) - maxValue
                let iniRecalt = (next - 5) - (sumarCompleteNav)
                if (iniRecalt >= 0) {
                  this.setState({ navTestIni: iniRecalt, navTestFin: maxValue })
                } else {
                  this.setState({ navTestIni: 0, navTestFin: maxValue })
                }

              }

            } else {
              let sumarCompleteNav = (next - 5) * -1
              if ((next + sumarCompleteNav + 5) <= maxValue) {
                this.setState({ navTestIni: 0, navTestFin: (next + sumarCompleteNav + 5) })
              } else {
                this.setState({ navTestIni: 0, navTestFin: maxValue })
              }
            }


          }
        }

      }


    } else if (e.target.id.split('-')[0] == 'sigla') {
      let locaLey = e.target.id.split('-')[1]
      this.setState({ leySelectd: locaLey })
    } else if (e.target.id.split('-')[0] == 'openLey') {

      if (this.state.verTestJusticia == true) {
        let leySelectd = e.target.id.split('-')[1]

        let siglasLey = this.state.testJusticiaHistorial.find(t => t.idExp == this.state.ejerLoadIdExp).listadoErrores[leySelectd].siglasLey
        let nombreLey = this.state.testJusticiaHistorial.find(t => t.idExp == this.state.ejerLoadIdExp).listadoErrores[leySelectd].nombreLey
        let urlLey = this.state.busquedaLeyes.find(l => l.siglas_ley == siglasLey && l.nombre_ley == nombreLey).link_ley
        if (isDefined(urlLey)) {
          window.open(urlLey)
        } else {
          swal('Link no valido', 'Será arreglado lo antes posible', 'error')
        }
      } else if (this.state.verCasoPractico == true) {
        let leySelectd = e.target.id.split('-')[1]

        let siglasLey = this.state.casoPracticoHistorial.find(t => t.idExp == this.state.ejerLoadIdExp).listadoErrores[leySelectd].siglasLey
        let nombreLey = this.state.casoPracticoHistorial.find(t => t.idExp == this.state.ejerLoadIdExp).listadoErrores[leySelectd].nombreLey
        let urlLey = this.state.busquedaLeyes.find(l => l.siglas_ley == siglasLey && l.nombre_ley == nombreLey).link_ley
        if (isDefined(urlLey)) {
          window.open(urlLey)
        } else {
          swal('Link no valido', 'Será arreglado lo antes posible', 'error')
        }
      }


    }
  }
  async bienMalBlancoCP(test) {
    try {
      let correcta = 0
      let mal = 0
      let enBlanco = 0

      for (var i = 0; i < test.length; i++) {
        let pregunta = test[i]
        let pregTest = await corregirTest(pregunta)
        if (pregTest == 1) {
          correcta = correcta + 1
          let numPreg = pregunta.numPreg
          let value = 1
          this.props.puntosResCP(value, numPreg)
        } else if (pregTest == 2) {
          mal = mal + 1
          let numPreg = pregunta.numPreg
          let value = 2
          this.props.puntosResCP(value, numPreg)
        } else if (pregTest == 0) {
          enBlanco = enBlanco + 1
          let numPreg = pregunta.numPreg
          let value = 0
          this.props.puntosResCP(value, numPreg)
        }
      }
      let restamos = 0.50;
      let totalPreg = 20
      let restamosBlanco = 0;
      let puntuacionRealPorcentaje = 20
      let aciertoCP = 1
      if (test[0].idClase && test[0].idClase != '') {
        let data = { idClase: test[0].idClase }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malCasoPractico", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malCasoP;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoCP = await cls1[0].aciertoSuma;
      } else {
        let data = { idClase: 1 }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malCasoPractico", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malCasoP;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoCP = await cls1[0].aciertoSuma;
      }


      let multiploMalF = parseFloat(parseFloat(totalPreg / test.length) * restamos)
      let multiploMalBlanco = parseFloat(parseFloat(totalPreg / test.length) * restamosBlanco)
      let multiploAcierto = parseFloat(parseFloat(totalPreg / test.length) * aciertoCP)

      let correctaF = parseFloat(correcta)
      let malF = await parseFloat(parseFloat(mal) * multiploMalF).toFixed(2)
      let enBlancoFF = await parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
      let puntuacion = await parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
      let puntuacionReal = await parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 20)).toFixed(2)
      let todo = { correcta: correcta, mal: mal, enBlanco: enBlanco, puntuacion: puntuacion, puntuacionReal: puntuacionReal }
      return todo
    } catch (e) { console.log(e) }
  }

  async bienMalBlanco(test) {
    try {
      let correcta = 0
      let mal = 0
      let enBlanco = 0

      for (var i = 0; i < test.length; i++) {
        let pregunta = test[i]
        let pregTest = await corregirTest(pregunta)
        if (pregTest == 1) {
          correcta = correcta + 1
          let numPreg = pregunta.numPreg
          let value = 1
          this.props.puntosRes(value, numPreg)
        } else if (pregTest == 2) {
          mal = mal + 1
          let numPreg = pregunta.numPreg
          let value = 2
          this.props.puntosRes(value, numPreg)
        } else if (pregTest == 0) {
          enBlanco = enBlanco + 1
          let numPreg = pregunta.numPreg
          let value = 0
          this.props.puntosRes(value, numPreg)
        }
      }

      let restamos = 0.25;
      let totalPreg = 100
      let restamosBlanco = 0;
      let puntuacionRealPorcentaje = 60
      let aciertoJus = 1
      if (test[0].idClase && test[0].idClase != '') {
        let data = { idClase: test[0].idClase }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malJusticia;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoJus = await cls1[0].aciertoSuma;
      } else {
        let data = { idClase: 1 }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malJusticia;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoJus = await cls1[0].aciertoSuma;
      }


      let multiploMalF = parseFloat(parseFloat(totalPreg / test.length) * restamos)
      let multiploMalBlanco = parseFloat(parseFloat(totalPreg / test.length) * restamosBlanco)
      let multiploAcierto = parseFloat(parseFloat(totalPreg / test.length) * aciertoJus)

      let correctaF = parseFloat(correcta)
      let malF = await parseFloat(parseFloat(mal) * multiploMalF).toFixed(2)
      let enBlancoFF = await parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
      let puntuacion = await parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
      let puntuacionReal = await parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 100)).toFixed(2)

      let todo = { correcta: correcta, mal: mal, enBlanco: enBlanco, puntuacion: puntuacion, puntuacionReal: puntuacionReal }
      return todo
    } catch (e) { console.log(e) }
  }

  async componentDidMount() {

    let response1xyz = await fetch("https://oposiciones-justicia.es/api/temas/allTemas");
    if (!response1xyz.ok) {
      throw Error(response1xyz.statusText);
    }
    let json1xyz = await response1xyz.json();
    let cls1xyz = await json1xyz;
    this.setState({ temasFacturados2: cls1xyz })

    if (1 == 2 && this.props.tipoUser >= 58) {
      // cargar clases preparador

      if (this.props.tipoUser < 98) {

        let data2 = { idPreparador: this.props.idUser }
        let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        if (cls2 != undefined && cls2.length > 0) {
          await this.setState({ busquedaClasesPreparador: cls2 });
        } else {
          await this.setState({ busquedaClasesPreparador: undefined });
        }


      } else {

        let data2 = { idPreparador: this.props.idUser }
        let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        if (cls2 != undefined && cls2.length > 0) {
          await this.setState({ busquedaClasesPreparador: cls2 });
        } else {
          await this.setState({ busquedaClasesPreparador: undefined });
        }

      }


    } else {


      let data345oi = { idUser: this.props.idUser }
      fetch("https://oposiciones-justicia.es/api/usuarios/datosUser", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data345oi)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(cls => {
        this.setState({
          nombre: cls[0].nombre == undefined ? ('') : (cls[0].nombre),
          apellidos: cls[0].apellidos == undefined ? ('') : (cls[0].apellidos)
        });
      }).catch(error => console.log('Object fecth failed', error))
      let data22ft = { idUser: this.props.idUser }
      fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data22ft)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(data345 => {
        if (data345.length > 0) {
          this.setState({ cursos: data345 })
        }

      }).catch(function (err) { });
      try {

        let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        await this.setState({ idUser: cls1[0].id_usuario });

        let data2 = { idUser: cls1[0].id_usuario }

        let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoSemanas", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response0.ok) {
          throw Error(response0.statusText);
        }
        let json0 = await response0.json();
        let cls0 = await json0;
        await this.setState({ infoSemanal: cls0 });
        let response00 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoBloques", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response00.ok) {
          throw Error(response00.statusText);
        }
        let json00 = await response00.json();
        let cls00 = await json00;
        await this.setState({ infoBloques: cls00 });

        let data2XRTY = {}
        let response2XRTY = await fetch("https://oposiciones-justicia.es/api/usuarios/temasPrgLoad", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2XRTY)
        });
        if (!response2XRTY.ok) {
          throw Error(response2XRTY.statusText);
        }
        let json2XRTY = await response2XRTY.json();
        let cls2XRTY = await json2XRTY;

        let temasPrgLoad = await cls2XRTY

        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteTestUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        let testPuntuaciones = []
        for (var i = 0; i < await cls2.length; i++) {
          testPuntuaciones.push({
            tipo: 'testJusticia',
            idExp: cls2[i].id_test_expediente == undefined ? ('') : (cls2[i].id_test_expediente),
            idTest: cls2[i].id_test == undefined ? ('') : (cls2[i].id_test),
            idClase: cls2[i].id_clase == undefined ? ('') : (cls2[i].id_clase),
            idPrg: cls2[i].id_prg == undefined ? ('') : (cls2[i].id_prg),
            idBloque: cls2[i].id_bloque == undefined ? ('') : (cls2[i].id_bloque),
            temasPrg: cls2[i].id_prg != undefined && cls2[i].id_bloque != undefined ? (
              temasPrgLoad.filter(p => p.id_programa == cls2[i].id_prg && p.id_bloque == cls2[i].id_bloque)
            ) : (''),
            idTemaPrg: cls2[i].id_tema_prg == undefined ? ('') : (cls2[i].id_tema_prg),
            idTema: cls2[i].idTema == undefined ? ('') : (cls2[i].idTema),
            correctas: cls2[i].correctas == undefined ? ('') : (cls2[i].correctas),
            erroneas: cls2[i].erroneas == undefined ? ('') : (cls2[i].erroneas),
            enBlanco: cls2[i].enBlanco == undefined ? ('') : (cls2[i].enBlanco),
            puntuacion: cls2[i].puntuacion == undefined ? ('') : (cls2[i].puntuacion),
            puntuacionReal: cls2[i].puntuacionReal == undefined ? ('') : (cls2[i].puntuacionReal),
            minutos: cls2[i].minutos == undefined ? ('') : (cls2[i].minutos),
            segundos: cls2[i].segundos == undefined ? ('') : (cls2[i].segundos),
            fecha: cls2[i].fecha == undefined ? ('') : (cls2[i].fecha),
            intento: undefined,
            numBloque: cls2[i].numBloque == undefined ? ('') : (cls2[i].numBloque),
            numTestBloque: cls2[i].numTestBloque == undefined ? ('') : (cls2[i].numTestBloque)
          })
        }
        await testPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
        let maxValue = Math.ceil(testPuntuaciones.length / 10)
        let listadoNavTestTeorico = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNavTestTeorico.push({
            num: index23 + 1
          })
        }
        for (var z = 0; z < testPuntuaciones.length; z++) {
          let busquedaMismoTest = testPuntuaciones.filter(t => t.idExp != testPuntuaciones[z].idExp && t.idExp < testPuntuaciones[z].idExp && t.idTest == testPuntuaciones[z].idTest && t.idPrg == testPuntuaciones[z].idPrg && t.idBloque == testPuntuaciones[z].idBloque)
          if (busquedaMismoTest && busquedaMismoTest.length > 0) {
            testPuntuaciones[z].intento = busquedaMismoTest.length + 1
          } else {
            testPuntuaciones[z].intento = 1
          }
        }
        await this.setState({ testJusticiaHistorial: testPuntuaciones, testJusticiaHistorialSave: testPuntuaciones, listadoNavTestTeorico: listadoNavTestTeorico });

        let response22 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteCasoPracticoUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response22.ok) {
          throw Error(response22.statusText);
        }
        let json22 = await response22.json();
        let cls22 = await json22;
        let casoPracticoPuntuaciones = []
        for (var i = 0; i < await cls22.length; i++) {
          casoPracticoPuntuaciones.push({
            tipo: 'casoPractico',
            idExp: cls22[i].id_test_expediente == undefined ? ('') : (cls22[i].id_test_expediente),
            idTest: cls22[i].id_caso_practico == undefined ? ('') : (cls22[i].id_caso_practico),
            idClase: cls22[i].id_clase == undefined ? ('') : (cls22[i].id_clase),
            idPrg: cls22[i].id_prg == undefined ? ('') : (cls22[i].id_prg),
            idBloque: cls22[i].id_bloque == undefined ? ('') : (cls22[i].id_bloque),
            temasPrg: cls22[i].id_prg != undefined && cls22[i].id_bloque != undefined ? (
              temasPrgLoad.filter(p => p.id_programa == cls22[i].id_prg && p.id_bloque == cls22[i].id_bloque)
            ) : (''),
            idTemaPrg: cls22[i].id_tema_prg == undefined ? ('') : (cls22[i].id_tema_prg),
            idTema: cls22[i].idTema == undefined ? ('') : (cls22[i].idTema),
            correctas: cls22[i].correctas == undefined ? ('') : (cls22[i].correctas),
            erroneas: cls22[i].erroneas == undefined ? ('') : (cls22[i].erroneas),
            enBlanco: cls22[i].enBlanco == undefined ? ('') : (cls22[i].enBlanco),
            puntuacion: cls22[i].puntuacion == undefined ? ('') : (cls22[i].puntuacion),
            puntuacionReal: cls22[i].puntuacionReal == undefined ? ('') : (cls22[i].puntuacionReal),
            minutos: cls22[i].minutos == undefined ? ('') : (cls22[i].minutos),
            segundos: cls22[i].segundos == undefined ? ('') : (cls22[i].segundos),
            fecha: cls22[i].fecha == undefined ? ('') : (cls22[i].fecha),
            intento: undefined,
            numBloque: cls22[i].numBloque == undefined ? ('') : (cls22[i].numBloque),
            numTestBloque: cls22[i].numTestBloque == undefined ? ('') : (cls22[i].numTestBloque)
          })
        }
        let todosLosExpedientesCP = await casoPracticoPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))

        let maxValue2 = Math.ceil(todosLosExpedientesCP.length / 10)
        let listadoNavTestPractico = []
        for (let index23t = 0; index23t < maxValue2; index23t++) {
          listadoNavTestPractico.push({
            num: index23t + 1
          })
        }

        for (var z = 0; z < todosLosExpedientesCP.length; z++) {
          let busquedaMismoTest = todosLosExpedientesCP.filter(t => t.idExp != todosLosExpedientesCP[z].idExp && t.idExp < todosLosExpedientesCP[z].idExp && t.idTest == todosLosExpedientesCP[z].idTest && t.idPrg == todosLosExpedientesCP[z].idPrg && t.idBloque == todosLosExpedientesCP[z].idBloque)
          if (busquedaMismoTest && busquedaMismoTest.length > 0) {
            todosLosExpedientesCP[z].intento = busquedaMismoTest.length + 1
          } else {
            todosLosExpedientesCP[z].intento = 1
          }
        }

        await this.setState({ casoPracticoHistorial: todosLosExpedientesCP, casoPracticoHistorialSave: todosLosExpedientesCP, listadoNavTestPractico: listadoNavTestPractico });

        // sacar examGesHistorial
        let response2212 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2212.ok) {
          throw Error(response2212.statusText);
        }
        let json2212 = await response2212.json();
        let cls2212 = await json2212;
        let examGesHistorial = []

        for (var i = 0; i < cls2212.length; i++) {
          //if(cls2212[i].puntuacion != null){
          examGesHistorial.push(cls2212[i])
          //}
        }

        let examGesHistorialOrder = await examGesHistorial.sort((a, b) => b.id_exp - a.id_exp)
        let maxValue3 = Math.ceil(examGesHistorialOrder.length / 10)
        let listadoNavExamDesarrollo = []
        for (let index23t2 = 0; index23t2 < maxValue3; index23t2++) {
          listadoNavExamDesarrollo.push({
            num: index23t2 + 1
          })
        }

        await this.setState({ examGesHistorial: examGesHistorialOrder, examGesHistorialSave: examGesHistorialOrder, listadoNavExamDesarrollo: listadoNavExamDesarrollo });



        let response3 = await fetch("https://oposiciones-justicia.es/api/clases/todasClases");
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
        let json3 = await response3.json();
        let cls3 = await json3;
        await this.setState({ infoAllClases: cls3 });
        let response4 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;
        await this.setState({ busquedaLeyes: cls4 });

        let response41 = await fetch("https://oposiciones-justicia.es/api/usuarios/examenGestion");
        if (!response41.ok) {
          throw Error(response41.statusText);
        }
        let json41 = await response41.json();
        let cls41 = await json41;
        await this.setState({ examenGestion: cls41 });

        fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
          .then(res => res.json())
          .then(alumnos => this.setState({
            busquedaAlumnos: alumnos
          })).catch(error => console.log('Object fecth failed', error));


      } catch (e) { console.log(e) }

    }
  }
  render() {

    const { isLogged, nombreUser, tipoUser, fotoPerfil, semanasFacturadas, idClase, temasFacturados } = this.props;
    const { cursos, otroCursoEstadisticas, diaSemanalOtro, nombre, apellidos, valueInput, valueInput1, valueInput2, valueInput3, valueInput4, testJusticiaHistorial, casoPracticoHistorial, infoAllClases, verTestJusticia, verCasoPractico, infoSemanal, infoBloques, verExamGes, examGesHistorial, numViewTest, ejerLoadIdExp,
      listadoNavTestTeorico,
      listadoNavTestPractico,
      listadoNavExamDesarrollo, navTestIni, navTestFin, leySelectd, infoSemanasCurso, categoriasJusticia, temasFacturados2, alumnosBusqueda, infoAlumnoBuscado, busquedaAlumnos, clasesAlumno } = this.state



    // console.log('testJusticiaHistorial')
    // console.log(testJusticiaHistorial)

    // console.log('casoPracticoHistorial')
    // console.log(casoPracticoHistorial)

    // console.log('infoSemanal')
    // console.log(infoSemanal)

    // console.log('temasFacturados')
    // console.log(temasFacturados)


    const nivelRequired = 1;
    if (isLogged && (nivelRequired >= tipoUser || tipoUser >= 1) && (semanasFacturadas != null || tipoUser >= 58 || idClase != null)) {
      return (
        <div className="inBodyMenu">
          <div className="inBody">
            <div className="bodyExpediente">

              <div className="BE-Header">
                <div className="BE-H-imagen">
                  <img src="../img/expedienteHeader.jpg" />
                </div>
                <div className="BE-H-content">
                  <div className="BE-CL-left">
                    <div>
                      <img className="BE-CL-left-img" src={
                        infoAlumnoBuscado != null ? (infoAlumnoBuscado.link_foto_perfil) : (fotoPerfil)
                      } />
                    </div>
                    <div className="BE-CL-left-nameAlumno">

                      {infoAlumnoBuscado != null ? (
                        infoAlumnoBuscado.nombre + ', ' + infoAlumnoBuscado.apellidos + '.'
                      ) : (
                        nombre + ', ' + apellidos + '.'
                      )}
                    </div>
                  </div>
                  {
                    tipoUser && tipoUser > 50 ? null : (
                      <div className="BE-CL-right">
                        <p> Seleccione un curso para filtrar las estadísticas. </p>
                        <div className="BE-CL-right-cursosList">
                          <Input2 type="text" id="curso" name="curso" placeholder="Curso" icon="fas fa-search" datalist="cursosAlumno" ref="ref_curso" deletedMode={valueInput != null ? (true) : (false)} onChange={this.onChangeInputNew} />

                          <datalist id="cursosAlumno">
                            {cursos && cursos.map((cls, key1) =>
                              <option key={key1} value={cls.diaSemanal}></option>
                            )}
                          </datalist>
                        </div>


                      </div>
                    )
                  }


                </div>
              </div>


              {tipoUser && tipoUser > 50 ? (
                <div className='BE-CL-right-row' style={{ margin: '10px' }}>

                  <div className="BE-CL-right" style={{ margin: '10px' }}>
                    <p> Seleccione un alumno para cargar su expediente. </p>
                    <div className="BE-CL-right-cursosList">
                      <Input2 type="text" id="alumnoPreparador" name="alumnoPreparador" placeholder="Alumno" icon="fas fa-search" datalist="alumnosPrepadorLoad" ref="ref_alumnoLoad" onChange={this.onChangeInputNew} />

                      <datalist id="alumnosPrepadorLoad">
                        {busquedaAlumnos && busquedaAlumnos.map((cls, key1) =>
                          <option key={key1} value={cls.dni}>{
                            cls.nombre + ', ' + cls.apellidos + ', idUser: ' + cls.id_usuario
                          }</option>
                        )}
                      </datalist>
                    </div>

                  </div>

                  {
                    clasesAlumno != null ? (

                      <div className="BE-CL-right">
                        <p> Seleccione un curso para cargar estadísticas. </p>
                        <div className="BE-CL-right-cursosList">
                          <Input2 type="text" id="clasePreparador" name="clasePreparador" placeholder="Curso" icon="fas fa-search" datalist="todosCursos" ref="ref_cursos" onChange={this.onChangeInputNew} />

                          <datalist id="todosCursos">
                            {clasesAlumno && clasesAlumno.map((cls, key1) =>
                              <option key={key1} value={cls.nombreClase}></option>
                            )}
                          </datalist>
                        </div>

                      </div>

                    ) : null
                  }

                </div>
              ) : null}

              {valueInput != null ? (
                <div className="BE-estadisticas">
                  {infoAlumnoBuscado != null ? (
                    <EstadisticasUser otroIdUser={infoAlumnoBuscado.id_usuario} otroCurso={otroCursoEstadisticas} diaSemanalOtro={diaSemanalOtro} />
                  ) : (
                    <EstadisticasUser otroCurso={otroCursoEstadisticas} diaSemanalOtro={diaSemanalOtro} />
                  )}

                </div>
              ) : null
              }



              <div className="BE-contentLoad">

                <div className="BE-CL-nav">
                  <div className="BE-CL-nav-item" id={"verTestJusticiaHistorial"} onClick={this.handleOnSubmit}
                    style={verTestJusticia && verTestJusticia == true ? ({ fontWeight: 'bold', color: '#3469b7' }) : ({})}
                  >
                    Test Teoría
                  </div>
                  <div className="BE-CL-nav-item" id={"verCPHistorial"} onClick={this.handleOnSubmit}
                    style={verCasoPractico && verCasoPractico == true ? ({ fontWeight: 'bold', color: '#3469b7' }) : ({})}
                  >
                    Test Prácticos
                  </div>
                  <div className="BE-CL-nav-item" id={"verExamGesHis"} onClick={this.handleOnSubmit}
                    style={verExamGes && verExamGes == true ? ({ fontWeight: 'bold', color: '#3469b7' }) : ({})}
                  >
                    Examen Desarrollo
                  </div>
                </div>

                {verTestJusticia && verTestJusticia == true && testJusticiaHistorial ? (
                  <React.Fragment >
                    <div className="BE-CL-buscador" style={!isDefined(ejerLoadIdExp) ? ({}) : ({ display: 'none' })}>
                      <b>Buscador de Test</b>

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Buscar por Tema
                        </p>
                        <Input2 type="text" id="temaLoadBus" name="temaLoadBus" placeholder="Tema" icon="fas fa-search" datalist="temasFacturadosAlum" ref="ref_temaLoadBus" onChange={this.onChangeInputNew} />
                      </div>
                      <datalist id="temasFacturadosAlum">
                        {temasFacturados && temasFacturados.sort((a, b) => b.numeroTema - a.numeroTema).map((cls, key4) =>
                          <option key={key4} value={cls.titulo_tema}>{cls.titulo_tema + ' (' + cls.nombre_tema + ')'}</option>
                        )}
                      </datalist>

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Fecha inicio
                        </p>
                        <Input2 type="date" id="fechaInicio" name="fechaInicio" placeholder="Fecha Inicio" icon="fas fa-search" datalist="" ref="ref_fechaInicio" deletedMode={valueInput1 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                      </div>

                      {
                        valueInput1 != null ? (
                          <div className="BE-CL-buscador-input">
                            <p className="BE-CL-buscador-input-VOLADOR">
                              Fecha fin
                            </p>
                            <Input2 type="date" id="fechaFin" name="fechaFin" placeholder="Fecha Fin" icon="fas fa-search" datalist="" ref="ref_fechaFin" deletedMode={valueInput2 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                          </div>
                        ) : null
                      }

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Curso
                        </p>
                        <Input2 type="text" id="cursoBuscador" name="cursoBuscador" placeholder="Curso" icon="fas fa-search" datalist="cursosAlumno" ref="ref_cursoBuscador" deletedMode={valueInput3 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                      </div>

                      {
                        valueInput3 != null ? (
                          <div className="BE-CL-buscador-input">
                            <p className="BE-CL-buscador-input-VOLADOR">
                              Clase
                            </p>
                            <Input2 type="text" id="cursoClaseBuscador" name="cursoClaseBuscador" placeholder="Nº Clase" icon="fas fa-search" datalist="infoSemanasCursoData" ref="ref_cursoClaseBuscador" deletedMode={valueInput4 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                          </div>
                        ) : null
                      }

                      <datalist id="cursosAlumno">
                        {cursos && cursos.map((cls, key1) =>
                          <option key={key1} value={cls.diaSemanal}></option>
                        )}
                      </datalist>
                      <datalist id="infoSemanasCursoData">
                        {infoSemanasCurso && infoSemanasCurso.map((clsrt, key1rt) =>
                          <option key={key1rt} value={clsrt.numero_semana_clase}>
                            Clase Nº {clsrt.numero_semana_clase}, {
                              isDefined(clsrt.nombre_semana) ? (clsrt.nombre_semana) : null
                            }. {
                              categoriasJusticia && isDefined(clsrt.categoria) ? (
                                categoriasJusticia.find(f => f.id_categoria == clsrt.categoria).nombre
                              ) : null

                            }</option>
                        )}
                      </datalist>
                    </div>

                    {!isDefined(ejerLoadIdExp) ? (
                      <div className="BE-CL-content">
                        {testJusticiaHistorial && testJusticiaHistorial.length > 0 ? (
                          <div className="BE-CL-C-nav">
                            <i id={"atras"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-left" />

                            {listadoNavTestTeorico.slice(navTestIni, navTestFin).map((data, key345) =>

                              <div className="navClic" id={"clicNavDirect-" + key345} onClick={this.handleOnSubmit} key={key345}
                                style={(numViewTest + 1) == data.num ? ({ color: '#3469b7', fontWeight: 'bold' }) : ({})}>
                                {data.num}
                              </div>

                            )}

                            <i id={"adelante"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-right" />
                          </div>
                        ) : null
                        }


                        <div className="BE-CL-C-rowsExp">
                          {testJusticiaHistorial && testJusticiaHistorial.length == 0 ? (
                            <div className="noDataHistorialInfo">No existe ningun ejercicio con la busqueda actual</div>
                          ) : null
                          }
                          {testJusticiaHistorial && testJusticiaHistorial.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).slice(10 * numViewTest, numViewTest == 0 ? (10) : (10 * (numViewTest + 1))).map((test, key) =>
                            <div className="BE-CL-RE-rowTest" id={"infoTest-" + key} onClick={this.handleOnSubmit}>
                              <div className="BE-RT-celdaTest" id={"infoTest-" + key}>
                                <i className="far fa-calendar-alt" id={"infoTest-" + key} />
                                {moment(test.fecha).format('DD-MM-YY')}
                              </div>
                              <div className="BE-RT-celdaTest2" id={"infoTest-" + key}>
                                <i className="fas fa-flag" id={"infoTest-" + key} />
                                {test.puntuacion}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-check-circle" id={"infoTest-" + key}></i>
                                {test.correctas}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-times-circle" id={"infoTest-" + key}></i>
                                {test.erroneas}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-circle" id={"infoTest-" + key}></i>
                                {test.enBlanco}
                              </div>
                              <div className="BE-RT-celdaTest4" id={"infoTest-" + key}>
                                <i className="fas fa-info-circle" id={"infoTest-" + key}></i>
                                {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined
                                  ? (
                                    'Clase Nº ' + infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).numero_semana_clase
                                  ) : null
                                }

                                {test.idPrg == '' && test.idTema == ''
                                  ? (
                                    'Test auto'
                                  ) : null
                                }

                                {test.idPrg == '' && test.idTema != ''
                                  ? (
                                    'Test curso'
                                  ) : null
                                }

                              </div>

                              {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined && test.temasPrg && test.temasPrg.length > 0 && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).nombre_semana != null
                                ? (
                                  <div className="BE-RT-celdaTest4" id={"infoTest-" + key}>
                                    <i className="fa-solid fa-file-circle-info" id={"infoTest-" + key}></i>
                                    {
                                      'Clase: ' + infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).nombre_semana
                                    }
                                  </div>
                                ) : null}

                              {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined && test.temasPrg && test.temasPrg.length > 0
                                ? (
                                  <div className="BE-RT-celdaTest4" id={"infoTest-" + key} style={{
                                    display: 'flex',
                                    flexFlow: 'row wrap',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start'
                                  }}>
                                    <i className="fa-solid fa-book" id={"infoTest-" + key}></i>
                                    {
                                      test.temasPrg[0].titulo_tema + ' (' + test.temasPrg[0].nombre_tema + ') '
                                    }
                                    {
                                      test.temasPrg.length > 1 ? (
                                        test.temasPrg.length == 2 ? (
                                          <p style={{ marginLeft: '8px' }}>
                                            1 tema más en el bloque.
                                          </p>
                                        ) : (
                                          <p style={{ marginLeft: '8px' }}>
                                            tiene más temas en el bloque.
                                          </p>

                                        )



                                      ) : null
                                    }
                                  </div>
                                ) : null}


                            </div>
                          )}
                        </div>

                      </div>

                    ) : (
                      <div className="BE-CL-C-ejerLoadInfo">

                        <div className="BE-CL-C-eLI-rowTest">

                          <i className="fas fa-times-circle BECLCELIii" title="Cerrar ejercicio" id={"cerrarEjerLoadView"} onClick={this.handleOnSubmit}></i>
                          <div className="BE-CL-RE-rowTest" style={{ background: '#DDE6F3', borderRadius: '38px' }}>
                            <div className="BE-RT-celdaTest">
                              <i className="far fa-calendar-alt" />
                              {moment(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).fecha).format('DD-MM-YY')}
                            </div>
                            <div className="BE-RT-celdaTest2">
                              <i className="fas fa-flag" />
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).puntuacion}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-check-circle"></i>
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).correctas}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-times-circle"></i>
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).erroneas}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-circle"></i>
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).enBlanco}
                            </div>
                            <div className="BE-RT-celdaTest4">
                              <i className="fas fa-info-circle"></i>
                              {
                                testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' &&
                                  infoSemanal.find(t => t.id_clase == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase && t.id_programa == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg) != undefined
                                  ? (
                                    'Clase Nº ' + infoSemanal.find(t => t.id_clase == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase && t.id_programa == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg).numero_semana_clase
                                  ) : null
                              }
                              {
                                testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque == '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema == ''
                                  ? (
                                    'Test auto'
                                  ) : null
                              }
                              {
                                testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' ? (
                                  'Test curso'
                                ) : null
                              }
                            </div>
                          </div>

                        </div>

                        <div className="BE-CL-C-eLI-botones">
                          <Boton id={'verTest'} icon1="" icon2="" texto="Ver Test" onClick={this.handleOnSubmit} />


                          {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' ? (
                            <Boton id={'repetirTestJus'} icon1="" icon2="" texto="Repetir" onClick={this.handleOnSubmit} />
                          ) : null
                          }

                          {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' ? (
                            <Boton id={'repetirTestJus'} icon1="" icon2="" texto="Repetir" onClick={this.handleOnSubmit} />
                          ) : null
                          }


                          <Boton title="Revisar Puntuación" id={'revisarPuntuacion'} icon1="" icon2="" texto="Recalcular" onClick={this.handleOnSubmit} />
                          {/* <Boton id={'borrarTest'} icon1="" icon2="" texto="Borrar" onClick={this.handleOnSubmit} /> */}
                        </div>

                        <div className="BE-CL-C-eLI-infoYIMG">
                          <div className="BE-CL-C-eLI-iYI-content" style={{ justifyContent: 'flex-start' }}>
                            <div className="BE-CL-C-eLI-iYI-CCC">
                              <div className="iYI-CCC">
                                <div className="iYI-CCC-row" title="Puntuación Convocatoria">
                                  <i className="fas fa-tag"></i>
                                  P. Convocatoría {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).puntuacionReal}
                                </div>

                                <div className="iYI-CCC-row" title="Tiempo transcurrido en minutos.">
                                  <i className="fas fa-stopwatch"></i>
                                  {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).segundos > 9 ?
                                    ('' + testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).minutos + ':' + testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).segundos + ' m.') :
                                    ('' + testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).minutos + ':0' + testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).segundos + ' m.')}
                                </div>

                              </div>


                              <div className="iYI-CCC">
                                {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque != "" && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque != "" ? (
                                  <React.Fragment>
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      Bloque Nº {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}
                                    </div>

                                    <div className="iYI-CCC-row" title="Nº del Test dentro del Bloquee.">
                                      <i className="fas fa-tag"></i>
                                      Test Nº {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque}
                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' ? (
                                  <React.Fragment>
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      {temasFacturados2.find(t => t.id_tema == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema) != undefined ? (
                                        temasFacturados2.find(t => t.id_tema == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema).titulo_tema
                                      ) : ('Tema no disponible, curso cargado diferente al del ejercicio.')}
                                    </div>
                                    <div className="iYI-CCC-row" title="Nº del Test dentro del Bloquee.">
                                      <i className="fas fa-tag"></i>

                                      Vuelta Nº {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}

                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque == "" && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque == "" ? (

                                  testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != undefined && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' ? (
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      Bloque Nº {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}
                                      {parseInt(infoBloques.filter(b => b.id_semana == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg).sort((a, b) => a.id_bloque - b.id_bloque).findIndex(t => t.id_bloque == testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque) + 1)}
                                    </div>
                                  ) : (
                                    <div className="iYI-CCC-row" title="Test Autogenerado por Alumno.">
                                      <i className="fas fa-tag"></i>

                                      {
                                        testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' ? (
                                          'Test curso'
                                        ) : (
                                          'Test Autogenerado'
                                        )
                                      }
                                    </div>
                                  )
                                ) : null
                                }

                                <div className="iYI-CCC-row" title="Intento">
                                  <i className="fas fa-tag"></i>
                                  Intento Nº {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).intento}
                                </div>

                              </div>


                            </div>



                            <div style={{ maxWidth: '360px' }}>

                              <div className="bloqueLeyes2ert">
                                <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Legislación a repasar </div>
                                <div className="blLeyes2ert">

                                  {isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                    testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0 && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.map((ley234, key234frrggd2) =>

                                      <div title={ley234.nombreLey} className={leySelectd == key234frrggd2 ? ("leySeleted") : ("leyNormalice")} id={"sigla-" + key234frrggd2} onClick={this.handleOnSubmit}>
                                        {ley234.siglasLey}
                                      </div>

                                    )}
                                  <div className="blLeyesArtTT2ert">
                                    <p>{isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                      testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0 ? (testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].nombreLey) : null}</p>
                                  </div>
                                </div>
                                <div className="blarticulosMain2ert">

                                  {

                                    isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                    testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0


                                    && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos.length > 0 && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos.map((intA, key234frrggd3) =>
                                      0 == key234frrggd3 ? (
                                        isDefined(intA.art) && intA.art != '-' && intA.art != '–' ? (
                                          <div className="blartInd2ertF">
                                            {intA.art}
                                          </div>
                                        ) : null
                                      ) : (
                                        isDefined(intA.art) && intA.art != '-' && intA.art != '–' ? (
                                          <div className="blartInd2ert">
                                            {intA.art}
                                          </div>
                                        ) : null

                                      )


                                    )}



                                </div>
                                <div className="irAley2ert">
                                  <Boton id={"openLey-" + leySelectd} icon1="" icon2="" texto="Abrir Ley" onClick={this.handleOnSubmit} />
                                </div>
                              </div>

                            </div>



                          </div>

                          <div className="BE-CL-C-eLI-iYI-img">
                            <div className='ColCenterStart' style={{ alignSelf: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', paddingLeft: '15px' }}>
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.length > 0 ? (
                                <h4 style={{ color: '#3469b7', marginBottom: '15px' }}> Temas del test </h4>
                              ) : null}
                              {testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg != '' && testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.length > 0 ? (

                                testJusticiaHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.map((tem, keyTem) =>

                                  <p key={keyTem} style={{ padding: '5px', fontSize: '16px', lineHeight: 'unset' }}>
                                    {tem.titulo_tema + ' - ' + tem.nombre_tema}
                                  </p>
                                )


                              ) : null}
                            </div>

                            <img src="../img/BE-CL-C-eLI-iYI-img.png" />
                          </div>
                        </div>


                      </div>
                    )}

                  </React.Fragment>
                ) : null}

                {verCasoPractico && verCasoPractico == true && casoPracticoHistorial ? (
                  <React.Fragment >
                    <div className="BE-CL-buscador" style={!isDefined(ejerLoadIdExp) ? ({}) : ({ display: 'none' })}>
                      <b>Buscador de Test</b>

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Buscar por Tema
                        </p>
                        <Input2 type="text" id="temaLoadBus" name="temaLoadBus" placeholder="Tema" icon="fas fa-search" datalist="temasFacturadosAlum" ref="ref_temaLoadBus" onChange={this.onChangeInputNew} />
                      </div>
                      <datalist id="temasFacturadosAlum">
                        {temasFacturados && temasFacturados.sort((a, b) => b.numeroTema - a.numeroTema).map((cls, key4) =>
                          <option key={key4} value={cls.titulo_tema}>{cls.titulo_tema + ' (' + cls.nombre_tema + ')'}</option>
                        )}
                      </datalist>

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Fecha inicio
                        </p>
                        <Input2 type="date" id="fechaInicio" name="fechaInicio" placeholder="Fecha Inicio" icon="fas fa-search" datalist="" ref="ref_fechaInicio" deletedMode={valueInput1 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                      </div>

                      {
                        valueInput1 != null ? (
                          <div className="BE-CL-buscador-input">
                            <p className="BE-CL-buscador-input-VOLADOR">
                              Fecha fin
                            </p>
                            <Input2 type="date" id="fechaFin" name="fechaFin" placeholder="Fecha Fin" icon="fas fa-search" datalist="" ref="ref_fechaFin" deletedMode={valueInput2 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                          </div>
                        ) : null
                      }

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Curso
                        </p>
                        <Input2 type="text" id="cursoBuscador" name="cursoBuscador" placeholder="Curso" icon="fas fa-search" datalist="cursosAlumno" ref="ref_cursoBuscador" deletedMode={valueInput3 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                      </div>

                      {
                        valueInput3 != null ? (
                          <div className="BE-CL-buscador-input">
                            <p className="BE-CL-buscador-input-VOLADOR">
                              Clase
                            </p>
                            <Input2 type="text" id="cursoClaseBuscador" name="cursoClaseBuscador" placeholder="Nº Clase" icon="fas fa-search" datalist="infoSemanasCursoData" ref="ref_cursoClaseBuscador" deletedMode={valueInput4 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                          </div>
                        ) : null
                      }

                      <datalist id="cursosAlumno">
                        {cursos && cursos.map((cls, key1) =>
                          <option key={key1} value={cls.diaSemanal}></option>
                        )}
                      </datalist>
                      <datalist id="infoSemanasCursoData">
                        {infoSemanasCurso && infoSemanasCurso.map((clsrt, key1rt) =>
                          <option key={key1rt} value={clsrt.numero_semana_clase}>
                            Clase Nº {clsrt.numero_semana_clase}, {
                              isDefined(clsrt.nombre_semana) ? (clsrt.nombre_semana) : null
                            }. {
                              categoriasJusticia && isDefined(clsrt.categoria) ? (
                                categoriasJusticia.find(f => f.id_categoria == clsrt.categoria).nombre
                              ) : null

                            }</option>
                        )}
                      </datalist>
                    </div>

                    {!isDefined(ejerLoadIdExp) ? (
                      <div className="BE-CL-content">
                        {casoPracticoHistorial && casoPracticoHistorial.length > 0 ? (
                          <div className="BE-CL-C-nav">
                            <i id={"atras"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-left" />

                            {listadoNavTestPractico.slice(navTestIni, navTestFin).map((data, key345) =>

                              <div className="navClic" id={"clicNavDirect-" + key345} onClick={this.handleOnSubmit} key={key345}
                                style={(numViewTest + 1) == data.num ? ({ color: '#3469b7', fontWeight: 'bold' }) : ({})}>
                                {data.num}
                              </div>

                            )}

                            <i id={"adelante"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-right" />
                          </div>
                        ) : null
                        }


                        <div className="BE-CL-C-rowsExp">
                          {casoPracticoHistorial && casoPracticoHistorial.length == 0 ? (
                            <div className="noDataHistorialInfo">No existe ningun ejercicio con la busqueda actual</div>
                          ) : null
                          }
                          {casoPracticoHistorial && casoPracticoHistorial.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).slice(10 * numViewTest, numViewTest == 0 ? (10) : (10 * (numViewTest + 1))).map((test, key) =>
                            <div className="BE-CL-RE-rowTest" id={"infoTest-" + key} onClick={this.handleOnSubmit}>
                              <div className="BE-RT-celdaTest" id={"infoTest-" + key}>
                                <i className="far fa-calendar-alt" id={"infoTest-" + key} />
                                {moment(test.fecha).format('DD-MM-YY')}
                              </div>
                              <div className="BE-RT-celdaTest2" id={"infoTest-" + key}>
                                <i className="fas fa-flag" id={"infoTest-" + key} />
                                {test.puntuacion}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-check-circle" id={"infoTest-" + key}></i>
                                {test.correctas}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-times-circle" id={"infoTest-" + key}></i>
                                {test.erroneas}
                              </div>
                              <div className="BE-RT-celdaTest3" id={"infoTest-" + key}>
                                <i className="far fa-circle" id={"infoTest-" + key}></i>
                                {test.enBlanco}
                              </div>
                              <div className="BE-RT-celdaTest4" id={"infoTest-" + key}>
                                <i className="fas fa-info-circle" id={"infoTest-" + key}></i>
                                {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' &&
                                  infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined
                                  ? (
                                    'Clase Nº ' + infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).numero_semana_clase
                                  ) : null
                                }

                                {test.idPrg == '' && test.idTema == ''
                                  ? (
                                    'Test auto'
                                  ) : null
                                }

                                {test.idPrg == '' && test.idTema != ''
                                  ? (
                                    'Test curso'
                                  ) : null
                                }
                              </div>

                              {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined && test.temasPrg && test.temasPrg.length > 0 && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).nombre_semana != null
                                ? (
                                  <div className="BE-RT-celdaTest4" id={"infoTest-" + key}>
                                    <i className="fa-solid fa-file-circle-info" id={"infoTest-" + key}></i>
                                    {
                                      'Clase: ' + infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).nombre_semana
                                    }
                                  </div>
                                ) : null}

                              {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined && test.temasPrg && test.temasPrg.length > 0
                                ? (
                                  <div className="BE-RT-celdaTest4" id={"infoTest-" + key} style={{
                                    display: 'flex',
                                    flexFlow: 'row wrap',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start'
                                  }}>
                                    <i className="fa-solid fa-book" id={"infoTest-" + key}></i>
                                    {
                                      test.temasPrg[0].titulo_tema + ' (' + test.temasPrg[0].nombre_tema + ') '
                                    }
                                    {
                                      test.temasPrg.length > 1 ? (
                                        test.temasPrg.length == 2 ? (
                                          <p style={{ marginLeft: '8px' }}>
                                            1 tema más en el bloque.
                                          </p>
                                        ) : (
                                          <p style={{ marginLeft: '8px' }}>
                                            tiene más temas en el bloque.
                                          </p>

                                        )



                                      ) : null
                                    }
                                  </div>
                                ) : null}
                            </div>
                          )}
                        </div>

                      </div>

                    ) : (
                      <div className="BE-CL-C-ejerLoadInfo">

                        <div className="BE-CL-C-eLI-rowTest">

                          <i className="fas fa-times-circle BECLCELIii" title="Cerrar ejercicio" id={"cerrarEjerLoadView"} onClick={this.handleOnSubmit}></i>
                          <div className="BE-CL-RE-rowTest" style={{ background: '#DDE6F3', borderRadius: '38px' }}>
                            <div className="BE-RT-celdaTest">
                              <i className="far fa-calendar-alt" />
                              {moment(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).fecha).format('DD-MM-YY')}
                            </div>
                            <div className="BE-RT-celdaTest2">
                              <i className="fas fa-flag" />
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).puntuacion}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-check-circle"></i>
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).correctas}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-times-circle"></i>
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).erroneas}
                            </div>
                            <div className="BE-RT-celdaTest3">
                              <i className="far fa-circle"></i>
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).enBlanco}
                            </div>
                            <div className="BE-RT-celdaTest4">
                              <i className="fas fa-info-circle"></i>
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' && infoSemanal.find(t => t.id_clase == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase && t.id_programa == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg) != undefined ? (
                                'Clase Nº ' + infoSemanal.find(t => t.id_clase == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase && t.id_programa == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg).numero_semana_clase
                              ) : null
                              }
                              {
                                casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque == '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema == ''
                                  ? (
                                    'Test auto'
                                  ) : null
                              }
                              {
                                casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' ? (
                                  'Test curso'
                                ) : null
                              }
                            </div>
                          </div>

                        </div>

                        <div className="BE-CL-C-eLI-botones">
                          <Boton id={'verCasoPractico'} icon1="" icon2="" texto="Ver Test" onClick={this.handleOnSubmit} />
                          <Boton id={'repetirTestCP'} icon1="" icon2="" texto="Repetir" onClick={this.handleOnSubmit} />
                          <Boton title="Revisar Puntuación" id={'revisarPuntuacionCP'} icon1="" icon2="" texto="Recalcular" onClick={this.handleOnSubmit} />
                          {/* <Boton id={'borrarCP'} icon1="" icon2="" texto="Borrar" onClick={this.handleOnSubmit} /> */}
                        </div>

                        <div className="BE-CL-C-eLI-infoYIMG">
                          <div className="BE-CL-C-eLI-iYI-content" style={{ justifyContent: 'flex-start' }}>
                            <div className="BE-CL-C-eLI-iYI-CCC">
                              <div className="iYI-CCC">
                                <div className="iYI-CCC-row" title="Puntuación Convocatoria">
                                  <i className="fas fa-tag"></i>
                                  P. Convocatoría {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).puntuacionReal}
                                </div>

                                <div className="iYI-CCC-row" title="Tiempo transcurrido en minutos.">
                                  <i className="fas fa-stopwatch"></i>
                                  {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).segundos > 9 ?
                                    ('' + casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).minutos + ':' + casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).segundos + ' m.') :
                                    ('' + casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).minutos + ':0' + casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).segundos + ' m.')}
                                </div>

                              </div>


                              <div className="iYI-CCC">
                                {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque != "" && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque != "" ? (
                                  <React.Fragment>
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      Bloque Nº {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}
                                    </div>

                                    <div className="iYI-CCC-row" title="Nº del Test dentro del Bloquee.">
                                      <i className="fas fa-tag"></i>
                                      Test Nº {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque}
                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' ? (
                                  <React.Fragment>
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      {temasFacturados2.find(t => t.id_tema == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema) != undefined ? (temasFacturados2.find(t => t.id_tema == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema).titulo_tema) : ('Tema no disponible, curso cargado distino al del ejercicio')}
                                    </div>
                                    <div className="iYI-CCC-row" title="Nº del Test dentro del Bloquee.">
                                      <i className="fas fa-tag"></i>

                                      Vuelta Nº {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}

                                    </div>
                                  </React.Fragment>
                                ) : null}

                                {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque == "" && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numTestBloque == "" ? (

                                  casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != undefined && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idClase != '' ? (
                                    <div className="iYI-CCC-row" title="Nº del Bloque">
                                      <i className="fas fa-tag"></i>
                                      Bloque Nº {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).numBloque}
                                      {parseInt(infoBloques.filter(b => b.id_semana == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg).sort((a, b) => a.id_bloque - b.id_bloque).findIndex(t => t.id_bloque == casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idBloque) + 1)}
                                    </div>
                                  ) : (
                                    <div className="iYI-CCC-row" title="Test Autogenerado por Alumno.">
                                      <i className="fas fa-tag"></i>
                                      {
                                        casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idTema != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).idPrg == '' ? (
                                          'Test curso'
                                        ) : (
                                          'Test Autogenerado'
                                        )
                                      }
                                    </div>
                                  )
                                ) : null
                                }

                                <div className="iYI-CCC-row" title="Intento">
                                  <i className="fas fa-tag"></i>
                                  Intento Nº {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).intento}
                                </div>

                              </div>


                            </div>



                            <div style={{ maxWidth: '360px' }}>

                              <div className="bloqueLeyes2ert">
                                <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Legislación a repasar </div>
                                <div className="blLeyes2ert">

                                  {isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                    casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0 && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.map((ley234, key234frrggd2) =>

                                      <div title={ley234.nombreLey} className={leySelectd == key234frrggd2 ? ("leySeleted") : ("leyNormalice")} id={"sigla-" + key234frrggd2} onClick={this.handleOnSubmit}>
                                        {ley234.siglasLey}
                                      </div>

                                    )}
                                  <div className="blLeyesArtTT2ert">
                                    <p>{isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                      casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0 ? (casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].nombreLey) : null}</p>
                                  </div>
                                </div>
                                <div className="blarticulosMain2ert">

                                  {

                                    isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp)) && isDefined(casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores) &&
                                    casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores.length > 0


                                    && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos.length > 0 && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).listadoErrores[leySelectd].articulos.map((intA, key234frrggd3) =>
                                      0 == key234frrggd3 ? (
                                        isDefined(intA.art) && intA.art != '-' && intA.art != '–' ? (
                                          <div className="blartInd2ertF">
                                            {intA.art}
                                          </div>
                                        ) : null
                                      ) : (
                                        isDefined(intA.art) && intA.art != '-' && intA.art != '–' ? (
                                          <div className="blartInd2ert">
                                            {intA.art}
                                          </div>
                                        ) : null

                                      )


                                    )}



                                </div>
                                <div className="irAley2ert">
                                  <Boton id={"openLey-" + leySelectd} icon1="" icon2="" texto="Abrir Ley" onClick={this.handleOnSubmit} />
                                </div>
                              </div>

                            </div>



                          </div>
                          {/* <div className="BE-CL-C-eLI-iYI-img">
                            <img src="../img/BE-CL-C-eLI-iYI-img.png" />
                          </div> */}


                          <div className="BE-CL-C-eLI-iYI-img">
                            <div className='ColCenterStart' style={{ alignSelf: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', paddingLeft: '15px' }}>
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.length > 0 ? (
                                <h4 style={{ color: '#3469b7', marginBottom: '15px' }}> Temas del test </h4>
                              ) : null}
                              {casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg != '' && casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.length > 0 ? (

                                casoPracticoHistorial.find(t => t.idExp == ejerLoadIdExp).temasPrg.map((tem, keyTem) =>

                                  <p key={keyTem} style={{ padding: '5px', fontSize: '16px', lineHeight: 'unset' }}>
                                    {tem.titulo_tema + ' - ' + tem.nombre_tema}
                                  </p>
                                )


                              ) : null}
                            </div>

                            <img src="../img/BE-CL-C-eLI-iYI-img.png" />
                          </div>
                        </div>


                      </div>
                    )}

                  </React.Fragment>
                ) : null}

                {verExamGes && verExamGes == true && examGesHistorial ? (
                  <React.Fragment >
                    <div className="BE-CL-buscador" style={!isDefined(ejerLoadIdExp) ? ({}) : ({ display: 'none' })}>
                      <b>Buscador</b>

                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Curso
                        </p>
                        <Input2 type="text" id="cursoBuscador" name="cursoBuscador" placeholder="Curso" icon="fas fa-search" datalist="cursosAlumno" ref="ref_cursoBuscador" deletedMode={valueInput3 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                      </div>

                      {
                        valueInput3 != null ? (
                          <div className="BE-CL-buscador-input">
                            <p className="BE-CL-buscador-input-VOLADOR">
                              Clase
                            </p>
                            <Input2 type="text" id="cursoClaseBuscador" name="cursoClaseBuscador" placeholder="Nº Clase" icon="fas fa-search" datalist="infoSemanasCursoData" ref="ref_cursoClaseBuscador" deletedMode={valueInput4 != null ? (true) : (false)} onChange={this.onChangeInputNew} />
                          </div>
                        ) : null
                      }

                      <datalist id="cursosAlumno">
                        {cursos && cursos.map((cls, key1) =>
                          <option key={key1} value={cls.diaSemanal}></option>
                        )}
                      </datalist>
                      <datalist id="infoSemanasCursoData">
                        {infoSemanasCurso && infoSemanasCurso.map((clsrt, key1rt) =>
                          <option key={key1rt} value={clsrt.numero_semana_clase}>
                            Clase Nº {clsrt.numero_semana_clase}, {
                              isDefined(clsrt.nombre_semana) ? (clsrt.nombre_semana) : null
                            }. {
                              categoriasJusticia && isDefined(clsrt.categoria) ? (
                                categoriasJusticia.find(f => f.id_categoria == clsrt.categoria).nombre
                              ) : null

                            }</option>
                        )}
                      </datalist>
                    </div>

                    {!isDefined(ejerLoadIdExp) ? (
                      <div className="BE-CL-content">
                        {examGesHistorial && examGesHistorial.length > 0 ? (
                          <div className="BE-CL-C-nav">
                            <i id={"atras"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-left" />

                            {listadoNavExamDesarrollo.slice(navTestIni, navTestFin).map((data, key345) =>

                              <div className="navClic" id={"clicNavDirect-" + key345} onClick={this.handleOnSubmit} key={key345}
                                style={(numViewTest + 1) == data.num ? ({ color: '#3469b7', fontWeight: 'bold' }) : ({})}>
                                {data.num}
                              </div>

                            )}

                            <i id={"adelante"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-right" />
                          </div>
                        ) : null
                        }


                        <div className="BE-CL-C-rowsExp">
                          {examGesHistorial && examGesHistorial.length == 0 ? (
                            <div className="noDataHistorialInfo">No existe ningun ejercicio con la busqueda actual</div>
                          ) : null
                          }
                          {examGesHistorial && examGesHistorial.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).slice(10 * numViewTest, numViewTest == 0 ? (10) : (10 * (numViewTest + 1))).map((test, key) =>
                            <div className="BE-CL-RE-rowTest" id={"infoTest-" + key} onClick={this.handleOnSubmit}>
                              <div className="BE-RT-celdaTest32" id={"infoTest-" + key}>
                                <i className="fas fa-tag" id={"infoTest-" + key}></i>
                                {test.nombre}
                              </div>

                              <div className="BE-RT-celdaTest22" id={"infoTest-" + key}>

                                {test.puntuacion && test.puntuacion != null ? (
                                  <React.Fragment>
                                    <i className="fas fa-flag" id={"infoTest-" + key} />
                                    {test.puntuacion}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <i className="fas fa-circle" id={"infoTest-" + key} style={{ color: 'red' }}></i>
                                    {'Pendiente'}
                                  </React.Fragment>
                                )}

                              </div>


                              <div className="BE-RT-celdaTest4" id={"infoTest-" + key}>
                                <i className="fas fa-info-circle" id={"infoTest-" + key}></i>
                                {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' && infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg) != undefined ? (
                                  'Clase Nº ' + infoSemanal.find(t => t.id_clase == test.idClase && t.id_programa == test.idPrg).numero_semana_clase
                                ) : null
                                }

                                {
                                  test.idPrg == null && test.idTema == null ? (
                                    'Ejer. auto'
                                  ) : null
                                }
                                {
                                  test.idPrg == null && test.idTema != null ? (
                                    'Ejer. curso'
                                  ) : null
                                }
                              </div>
                            </div>
                          )}
                        </div>

                      </div>

                    ) : (
                      <div className="BE-CL-C-ejerLoadInfo">

                        <div className="BE-CL-C-eLI-rowTest">

                          <i className="fas fa-times-circle BECLCELIii" title="Cerrar ejercicio" id={"cerrarEjerLoadView"} onClick={this.handleOnSubmit}></i>
                          <div className="BE-CL-RE-rowTest" style={{ background: '#DDE6F3', borderRadius: '38px' }}>
                            <div className="BE-RT-celdaTest32">
                              <i className="fas fa-tag"></i>
                              {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).nombre}
                            </div>
                            <div className="BE-RT-celdaTest22">

                              {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion != null ? (
                                <React.Fragment>
                                  <i className="fas fa-flag" />
                                  {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <i className="fas fa-circle" style={{ color: '#e14242' }}></i>
                                  {'Pendiente'}
                                </React.Fragment>
                              )}

                            </div>


                            <div className="BE-RT-celdaTest4">
                              <i className="fas fa-info-circle"></i>
                              {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idBloque != undefined && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg != undefined && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idClase != undefined && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idBloque != '' && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg != '' && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idClase != '' && infoSemanal.find(t => t.id_clase == examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idClase && t.id_programa == examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg) != undefined ? (
                                'Clase Nº ' + infoSemanal.find(t => t.id_clase == examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idClase && t.id_programa == examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg).numero_semana_clase
                              ) : null
                              }
                              {
                                examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg == null && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idTema == null ? (
                                  'Ejer. auto'
                                ) : null
                              }
                              {
                                examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg == null && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idTema != null ? (
                                  'Ejer. curso'
                                ) : null
                              }
                            </div>
                          </div>

                        </div>

                        <div className="BE-CL-C-eLI-botones-EXA">
                          {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion != null ? (
                            <React.Fragment>
                              <Boton id={'downloadMiExamen'} icon1="" icon2="" texto="Mi examen" onClick={this.downloadPdf} />
                              <Boton id={'downloadSolucion'} icon1="" icon2="" texto="Solución" onClick={this.downloadPdf} />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Boton id={'downloadMiExamen'} icon1="" icon2="" texto="Mi examen" onClick={this.downloadPdf} />
                              <Boton id={'downloadSolucion'} icon1="" icon2="" texto="Solución" onClick={this.downloadPdf} />
                            </React.Fragment>
                          )}

                        </div>

                        <div className="BE-CL-C-eLI-infoYIMG-EXA">
                          <div className="BE-CL-C-eLI-iYI-img-EXA">
                            {examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).puntuacion != null ? (
                              <React.Fragment>
                                <img src="../img/examDesarrolloCompleted.jpg" />
                                <p> {ReactHtmlParser(examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).anotaciones)}
                                  {

                                    examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idTema != null && examGesHistorial.find(t => t.id_exp == ejerLoadIdExp).idPrg == null ? (
                                      temasFacturados2.find(t => t.id_tema == examGesHistorial.find(t => t.idExp == ejerLoadIdExp).idTema) != undefined ? (
                                        +' Ejercicio del ' + temasFacturados2.find(t => t.id_tema == examGesHistorial.find(t => t.idExp == ejerLoadIdExp).idTema).titulo_tema
                                      ) : null
                                    ) : null

                                  }
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <img src="../img/examDesarrolloCompleted.jpg" />
                                <p> Puede descargar la solución, en la clase o tema le indicaremos como puntuarlo. </p>
                              </React.Fragment>
                            )}

                          </div>
                        </div>


                      </div>
                    )}

                  </React.Fragment>
                ) : null}

              </div>

            </div>

          </div>
        </div>
      );
    } else {
      if (isLogged && tipoUser < 58) {

        swal({
          title: 'Area restringida',
          text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {
          window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
        })
        return (null);
      } else {
        return (<Redirect to="/" />);
      }
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    idUser: state.user.idUser,
    tipoUser: state.user.tipoUser,
    fotoPerfil: state.user.fotoPerfil,
    testJusticiaUserLoad: state.user.testJusticiaUserLoad,
    casoPracticoUserLoad: state.user.casoPracticoUserLoad,
    semanasFacturadas: state.user.semanasFacturadas,
    idClase: state.user.idClase,
    temasFacturados: state.user.temasFacturados
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadTestJusticia,
    loadCasoPractico,
    puntosRes,
    puntosResCP,
    loadTestUser,
    borrarTestUser,
    borrarCP,
    loadCPUser
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpedienteUser);

// Actions
const CAMBIAR_CASO_PRACTICO = 'CAMBIAR_CASO_PRACTICO';
const ELIMINAR_CASO_PRACTICO = 'ELIMINAR_CASO_PRACTICO';
const ADD_PREGUNTA_NUEVA_INS_CP = 'ADD_PREGUNTA_NUEVA_INS_CP';
const DELETE_PREGUNTA_NUEVA_INS_CP = 'DELETE_PREGUNTA_NUEVA_INS_CP'
const DELETE_DESCENDER_PREGUNTA_NUEVA_INS_CP = 'DELETE_DESCENDER_PREGUNTA_NUEVA_INS_CP';
export function cambiarDatosTestJusticia(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_CASO_PRACTICO,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarTestJusticiaCache() {
    return {
      type: ELIMINAR_CASO_PRACTICO
    };
}
export function addPreguntaTestJusticiaInsert(numPreguntaNueva) {
    return {
	    type: ADD_PREGUNTA_NUEVA_INS_CP,
	   	 numPregNew: numPreguntaNueva
    };
}
export function deletePreguntaTestJusticiaInsert(numPreguntaBorrar) {
    return {
	    type: DELETE_PREGUNTA_NUEVA_INS_CP,
	   	 numPregDel: numPreguntaBorrar
    };
}
export function changeNumPreguntaTestJusticiaInsert(numPreguntaBorrar) {
    return {
	    type: DELETE_DESCENDER_PREGUNTA_NUEVA_INS_CP,
	   	 numPregChange: numPreguntaBorrar
    };
}

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';


class InputFileExamDesa extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          textInfoBtn: 'Seleccione : ',
        };
          this.onChangeInputFiles = this.onChangeInputFiles.bind(this);
    }
    onChangeInputFiles(e){
        this.props.onChange(e);


    }


  render() {
    const { name, texto, icon1 } = this.props;
    const { textInfoBtn } = this.state;
    var id = 'file'+name;
              return (
                <div>
                  <label htmlFor={id} className="boton">
                    <i></i>
                    {texto}
                    <i></i>
                  </label>
                  
                  <input type="file" className="myFiles" ref={name} name={name} id={id} onChange={this.onChangeInputFiles} multiple />
                </div>
              );

        }
 }

export default InputFileExamDesa;

// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Assets
import './css/css.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { reloadInfoPersonal, reloadNameUser } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
// Components
import Input2 from '../../components/Inputs/Input2';
import InputFileExamDesa from '../../components/Inputs/InputFileExamDesa';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';

import ReactHtmlParser from 'react-html-parser';


import swal from 'sweetalert';

class Word2010CompletaTestAdmin extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);

    let semanaWordLoadEdt
    if (this.props.semanaWordLoad == undefined || this.props.semanaWordLoad == null) {
      semanaWordLoadEdt = {
        id_semana: null,
        numClase: '',
        fechaClase: null,
        contenidoTarjeta: '',


        link_test: '',
        link_soluciones: '',
        id_test: null,
        num_preg_test: '',
        nombreEjercicio: '',

        puntuacionTest: null,
        proximaSemana: null,
        vueltaNumero: null
      }
    } else {
      semanaWordLoadEdt = Object.assign({}, this.props.semanaWordLoad)
    }

    this.state = {
      sectionSelecionada: 1,
      contra: 'bien',
      correctas: null,
      Erroneas: null,
      enBlanco: null,
      textHtmlMensaje: semanaWordLoadEdt.contenidoTarjeta,
      contentSelectTextEdt: '1',
      semanaWordLoadEdt: semanaWordLoadEdt,
      cambioRealizo: null
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickHandleS = this.onClickHandleS.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText(e, editor) {
    // AutoActualizar
    let textHtml = editor.getData();

    if (textHtml != '') {

      let { semanaWordLoadEdt, contentSelectTextEdt } = this.state
      if (contentSelectTextEdt == '1') {
        semanaWordLoadEdt.contenidoTarjeta = textHtml
      } else if (contentSelectTextEdt == '2') {
        semanaWordLoadEdt.atajosTarjeta = textHtml
      } else if (contentSelectTextEdt == '3') {
        semanaWordLoadEdt.texto_estudiar = textHtml
      } else if (contentSelectTextEdt == '4') {
        semanaWordLoadEdt.texto_ejercicios = textHtml
      }

      this.setState({ textHtmlMensaje: textHtml, semanaWordLoadEdt: semanaWordLoadEdt, cambioRealizo: true })
    }
  }
  async onChangeInput(e) {
    let { semanaWordLoadEdt } = this.state

    if (e.target.name == 'numSemana') {
      semanaWordLoadEdt.numClase = parseInt(e.target.value)
      this.setState({ semanaWordLoadEdt: semanaWordLoadEdt })
    } else if (e.target.name == 'nameTest') {
      semanaWordLoadEdt.nombreEjercicio = e.target.value
      this.setState({ semanaWordLoadEdt: semanaWordLoadEdt })
    } else if (e.target.name == 'totalPregTest') {
      semanaWordLoadEdt.num_preg_test = parseInt(e.target.value)
      this.setState({ semanaWordLoadEdt: semanaWordLoadEdt })
    }

  }

  async onClickHandleS(e) {
    // console.log('e.target')
    // console.log(e.target)

    // console.log('e.target.id')
    // console.log(e.target.id)

    if (e.target.id == "edtTestWord2010") {
      //subimos datos del test y reload página.
      let { semanaWordLoadEdt } = this.state
      
      if (semanaWordLoadEdt.id_semana == null) {
        swal('Insertando test word 2010', '', 'info')
        // crear semana!
        if (
          semanaWordLoadEdt.numClase != '' && semanaWordLoadEdt.numClase != null && semanaWordLoadEdt.numClase != undefined &&
          semanaWordLoadEdt.nombreEjercicio != '' && semanaWordLoadEdt.nombreEjercicio != null && semanaWordLoadEdt.nombreEjercicio != undefined &&
          semanaWordLoadEdt.contenidoTarjeta != '' && semanaWordLoadEdt.contenidoTarjeta != null && semanaWordLoadEdt.contenidoTarjeta != undefined &&
          semanaWordLoadEdt.num_preg_test != '' && semanaWordLoadEdt.num_preg_test != null && semanaWordLoadEdt.num_preg_test != undefined
        ) {
          let data = { semanaWordLoadEdt: this.state.semanaWordLoadEdt }
          // 1º ver si esta en alumnos word 2010 y si esta activo.

          let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/insertTestWord2010", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.affectedRows > 0) {

            semanaWordLoadEdt.id_semana = cls1.insertId
            this.setState({semanaWordLoadEdt: semanaWordLoadEdt})
            if (semanaWordLoadEdt.link_test == '' && semanaWordLoadEdt.link_soluciones == '') {
              swal({
                title: 'Test word insertado',
                text: '¡Ya puede subir los archivos pdf!',
                icon: "success",
              })
            } else if (semanaWordLoadEdt.link_test == '' && semanaWordLoadEdt.link_soluciones != '') {
              swal({
                title: 'Test word insertado',
                text: '¡Falta por subir el pdf del test sin respuestas!',
                icon: "success",
              })
            } else if (semanaWordLoadEdt.link_test != '' && semanaWordLoadEdt.link_soluciones == '') {
              swal({
                title: 'Test word insertado',
                text: '¡Falta por subir el pdf del test con respuestas!',
                icon: "success",
              })
            } else {
              swal({
                title: 'Test word insertado',
                text: '¡Recargando web!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2500,
              }).then(() => {
                window.location.reload()
              })
            }

          } else {
            swal('Test word no insertado', 'Intentelo de nuevo, no se realizo la conexión correctamente.', 'success')
          }
        } else {
          swal('Error faltan datos', 'Rellene numero de test, nombre del test, numero preguntas de test e informacion del test', 'warning')
        }


      } else {
        swal('Editando test word 2010', '', 'info')

        if (
          semanaWordLoadEdt.numClase != '' && semanaWordLoadEdt.numClase != null && semanaWordLoadEdt.numClase != undefined &&
          semanaWordLoadEdt.nombreEjercicio != '' && semanaWordLoadEdt.nombreEjercicio != null && semanaWordLoadEdt.nombreEjercicio != undefined &&
          semanaWordLoadEdt.contenidoTarjeta != '' && semanaWordLoadEdt.contenidoTarjeta != null && semanaWordLoadEdt.contenidoTarjeta != undefined &&
          semanaWordLoadEdt.num_preg_test != '' && semanaWordLoadEdt.num_preg_test != null && semanaWordLoadEdt.num_preg_test != undefined
        ) {
          let data = { semanaWordLoadEdt: this.state.semanaWordLoadEdt }
          // 1º ver si esta en alumnos word 2010 y si esta activo.

          let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/editTestWord2010", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.affectedRows > 0) {
            swal({
              title: 'Edición test word completada',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 2000,
            }).then(() => {
              window.location.reload()
            })
          } else {
            swal('Edición no completada', 'Intentelo de nuevo, no se realizo la conexión correctamente.', 'success')
          }
        } else {
          swal('Error faltan datos', 'Rellene numero de test, nombre del test, numero preguntas de test e informacion test.', 'warning')
        }
      }
    } else if (e.target.id == "changeSemana") {
      this.props.handleOnClicWord(e)
    } else if (e.target.id == "descargaTest") {

      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
      let pathFile = 'public' + this.props.semanaWordLoad.link_test.split('..')[1]

      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = this.props.semanaWordLoad.nombreEjercicio + '.pdf';
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });

    } else if (e.target.id == "descargaSolucion") {

      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
      let pathFile = 'public' + this.props.semanaWordLoad.link_soluciones.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = 'Respuestas - ' + this.props.semanaWordLoad.nombreEjercicio;
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });

    } else if (e.target.id == "cerrarEdtSemana") {
      this.props.handleOnClicWord(e)
    } else if (e.target.id == "contentTarjeta") {
      this.setState({ contentSelectTextEdt: '1', textHtmlMensaje: this.state.semanaWordLoadEdt.contenidoTarjeta })

    } else if (e.target.id == 'filesubirTest') {
      let files = e.target.files;
      if (this.state.semanaWordLoadEdt.id_semana != null && this.state.semanaWordLoadEdt.id_semana != undefined) {

        swal('Subiendo Test', '', 'info')
        if (this.state.semanaWordLoadEdt.link_test != null && this.state.semanaWordLoadEdt.link_test != undefined && this.state.semanaWordLoadEdt.link_test != '') {

          var filesLoad = [];
          for (var i = 0; i < files.length; i++) {
            filesLoad.push(files[i]);
          }

          if (filesLoad && filesLoad.length > 0) {
            var data2 = new FormData();
            for (var k = 0; k < filesLoad.length; k++) {
              data2.append('myFiles', filesLoad[k]);
            }
            let urlArchivo
            if (data2.getAll('myFiles') && data2.getAll('myFiles').length == 1) {
              let nombreArchivo = data2.getAll('myFiles')[0].name
              let tipoArchivo = nombreArchivo.split('.')[1]
              if (tipoArchivo && tipoArchivo == 'pdf' || tipoArchivo == 'PDF' || tipoArchivo == 'Pdf') {
                fetch("https://oposiciones-justicia.es/uploadmultiple", {
                  method: 'POST',
                  body: data2
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  for (var c = 0; c < data.length; c++) {
                    let path = data[c].path.split('src/public/')[1];
                    //let nameFile = data[i].originalname.split('.')[0];
                    urlArchivo = '../' + path;
                  }
                  let data3 = {
                    urlArchivo: urlArchivo,
                    idSemana: this.state.semanaWordLoadEdt.id_semana
                  }
                  return fetch("https://oposiciones-justicia.es/api/word2010/cambiarTestWord2010", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data3)
                  });
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  if (data.affectedRows > 0) {
                    // comprobar el borrar no va! pueden se rlos 2 puntos parece que no entra aqui se va abajo 
                    let data4 = { pathFile: '../public' + this.state.semanaWordLoadEdt.link_test.split('..')[1] }

                    return fetch("https://oposiciones-justicia.es/deleteFile", {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(data4)
                    });
                  }
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  swal('Test subido correctamente', 'Recarge la web para ver los cambios', 'success')

                }).catch(function (err) { console.log(err) });
              } else {
                swal('Error en nombre o formato de archivo', 'No puede contener ningun punto "el nombre del archivo", debe tener como formato de archivo PDF.', 'error')
              }
            } else {
              swal('Solo puede escoger un Test', '', 'error')
            }

          } else {
            swal('Error', 'Debe seleccionar un Test en PDF, para procesar el cambio.', 'error')
          }

        } else {
          // subimos no se borra
          var filesLoad = [];
          for (var i = 0; i < files.length; i++) {
            filesLoad.push(files[i]);
          }

          if (filesLoad && filesLoad.length > 0) {
            var data2 = new FormData();
            for (var k = 0; k < filesLoad.length; k++) {
              data2.append('myFiles', filesLoad[k]);
            }
            let urlArchivo
            if (data2.getAll('myFiles') && data2.getAll('myFiles').length == 1) {
              let nombreArchivo = data2.getAll('myFiles')[0].name
              let tipoArchivo = nombreArchivo.split('.')[1]
              if (tipoArchivo && tipoArchivo == 'pdf' || tipoArchivo == 'PDF' || tipoArchivo == 'Pdf') {
                fetch("https://oposiciones-justicia.es/uploadmultiple", {
                  method: 'POST',
                  body: data2
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  for (var c = 0; c < data.length; c++) {
                    let path = data[c].path.split('src/public/')[1];
                    //let nameFile = data[i].originalname.split('.')[0];
                    urlArchivo = '../' + path;
                  }
                  let data3 = {
                    urlArchivo: urlArchivo,
                    idSemana: this.state.semanaWordLoadEdt.id_semana
                  }
                  return fetch("https://oposiciones-justicia.es/api/word2010/cambiarTestWord2010", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data3)
                  });
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  swal('Test subido correctamente', 'Recarge la web para ver los cambios', 'success')

                }).catch(function (err) { console.log(err) });
              } else {
                swal('Error en nombre o formato de archivo', 'No puede contener ningun punto "el nombre del archivo", debe tener como formato de archivo PDF.', 'error')
              }
            } else {
              swal('Solo puede escoger un Test', '', 'error')
            }

          } else {
            swal('Error', 'Debe seleccionar un Test en PDF, para procesar el cambio.', 'error')
          }

        }
      } else {
        //
        swal('Inserte los datos del test antes que cualquier archivo pdf.', '', 'info')
      }
    } else if (e.target.id == 'filesubirRespuestas') {
      let files = e.target.files;
      if (this.state.semanaWordLoadEdt.id_semana != null && this.state.semanaWordLoadEdt.id_semana != undefined) {

        swal('Subiendo respuestas test', '', 'info')
        if (this.state.semanaWordLoadEdt.link_soluciones != null && this.state.semanaWordLoadEdt.link_soluciones != undefined && this.state.semanaWordLoadEdt.link_soluciones != '') {

          var filesLoad = [];
          for (var i = 0; i < files.length; i++) {
            filesLoad.push(files[i]);
          }

          if (filesLoad && filesLoad.length > 0) {
            var data2 = new FormData();
            for (var k = 0; k < filesLoad.length; k++) {
              data2.append('myFiles', filesLoad[k]);
            }
            let urlArchivo
            if (data2.getAll('myFiles') && data2.getAll('myFiles').length == 1) {
              let nombreArchivo = data2.getAll('myFiles')[0].name
              let tipoArchivo = nombreArchivo.split('.')[1]
              if (tipoArchivo && tipoArchivo == 'pdf' || tipoArchivo == 'PDF' || tipoArchivo == 'Pdf') {
                fetch("https://oposiciones-justicia.es/uploadmultiple", {
                  method: 'POST',
                  body: data2
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  for (var c = 0; c < data.length; c++) {
                    let path = data[c].path.split('src/public/')[1];
                    //let nameFile = data[i].originalname.split('.')[0];
                    urlArchivo = '../' + path;
                  }
                  let data3 = {
                    urlArchivo: urlArchivo,
                    idSemana: this.state.semanaWordLoadEdt.id_semana
                  }
                  return fetch("https://oposiciones-justicia.es/api/word2010/cambiarSolucionTestWord2010", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data3)
                  });
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  if (data.affectedRows > 0) {
                    // comprobar el borrar no va! pueden se rlos 2 puntos parece que no entra aqui se va abajo 
                    let data4 = { pathFile: '../public' + this.state.semanaWordLoadEdt.link_soluciones.split('..')[1] }

                    return fetch("https://oposiciones-justicia.es/deleteFile", {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(data4)
                    });
                  }
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  swal('Solución del test subido correctamente', 'Recarge la web para ver los cambios', 'success')

                }).catch(function (err) { console.log(err) });
              } else {
                swal('Error en nombre o formato de archivo', 'No puede contener ningun punto "el nombre del archivo", debe tener como formato de archivo PDF.', 'error')
              }
            } else {
              swal('Solo puede escoger un archivo pdf', '', 'error')
            }

          } else {
            swal('Error', 'Debe seleccionar un archivo en PDF, para procesar el cambio.', 'error')
          }

        } else {
          // subimos no se borra
          var filesLoad = [];
          for (var i = 0; i < files.length; i++) {
            filesLoad.push(files[i]);
          }

          if (filesLoad && filesLoad.length > 0) {
            var data2 = new FormData();
            for (var k = 0; k < filesLoad.length; k++) {
              data2.append('myFiles', filesLoad[k]);
            }
            let urlArchivo
            if (data2.getAll('myFiles') && data2.getAll('myFiles').length == 1) {
              let nombreArchivo = data2.getAll('myFiles')[0].name
              let tipoArchivo = nombreArchivo.split('.')[1]
              if (tipoArchivo && tipoArchivo == 'pdf' || tipoArchivo == 'PDF' || tipoArchivo == 'Pdf') {
                fetch("https://oposiciones-justicia.es/uploadmultiple", {
                  method: 'POST',
                  body: data2
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  for (var c = 0; c < data.length; c++) {
                    let path = data[c].path.split('src/public/')[1];
                    //let nameFile = data[i].originalname.split('.')[0];
                    urlArchivo = '../' + path;
                  }
                  let data3 = {
                    urlArchivo: urlArchivo,
                    idSemana: this.state.semanaWordLoadEdt.id_semana
                  }
                  return fetch("https://oposiciones-justicia.es/api/word2010/cambiarSolucionTestWord2010", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data3)
                  });
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  swal('Test subido correctamente', 'Recarge la web para ver los cambios', 'success')

                }).catch(function (err) { console.log(err) });
              } else {
                swal('Error en nombre o formato de archivo', 'No puede contener ningun punto "el nombre del archivo", debe tener como formato de archivo PDF.', 'error')
              }
            } else {
              swal('Solo puede escoger un Test', '', 'error')
            }

          } else {
            swal('Error', 'Debe seleccionar un Test en PDF, para procesar el cambio.', 'error')
          }

        }
      } else {
        //
        swal('Inserte los datos del test antes que cualquier archivo pdf.', '', 'info')
      }
    } else if (e.target.id == 'borrarSemana') {
      let data = { id_semana: this.state.semanaWordLoadEdt.id_semana }
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el Test de word 2010 será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            fetch("https://oposiciones-justicia.es/api/word2010/delTest", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                this.setState({ statusBarraProceso: "completado" });
                this.cleanForm();
              }
            }).catch(function (err) { console.log(err) });

            swal({
              title: 'Test word 2010 eliminado con exito!',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 2000,
            }).then(() => {
              window.location.reload()
            })
          } else {
            swal("Test Word 2010 no borrado", '', "success");
          }
        });
    }
  }



  async componentDidMount() {

  }

  componentWillUnmount() {
    this.setState({ semanaWordLoadEdt: null })
  }

  /*

  PARECE QUE ESTA LISTO

  */
  render() {

    const { isLogged, tipoUser, semanaWordLoad } = this.props;
    const { textHtmlMensaje, contentSelectTextEdt, semanaWordLoadEdt } = this.state
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      return (
        <div className="bodySemaEstudioW2010">
          {semanaWordLoadEdt && semanaWordLoadEdt != null ? (
            <React.Fragment>
              <div className="SEW2010-TOPBAR">
                {semanaWordLoadEdt.id_semana != null ? (
                  <div className='iMW-boton'>
                    <Boton id="changeSemana" icon1="" icon2="" texto="Cambiar test" onClick={this.onClickHandleS} />
                  </div>) : null}
                {this.props.tipoUser > 50 ? (
                  <div className='iMW-boton'>
                    <Boton2 id="cerrarEdtSemana" icon1="" icon2="" texto={semanaWordLoadEdt.id_semana == null ? ("Cerrar crear test") : ("Cerrar edición test")} onClick={this.onClickHandleS} />
                  </div>
                ) : null}
                {this.props.tipoUser > 50 && semanaWordLoadEdt.id_semana != null ? (
                  <div className='iMW-boton'>
                    <Boton2 id="borrarSemana" icon1="" icon2="" texto="Borrar test" onClick={this.onClickHandleS} />
                  </div>
                ) : null}
              </div>

              <div className="SEW2010-TOPinfoSemana">
                <div> Información Test Nº {semanaWordLoadEdt.numClase} - WORD 2010</div>
                <div className="SEW2010-TIS-descargas">
                  <div id="descargaTest" onClick={this.onClickHandleS}>Descargar test - </div>
                  <div id="descargaSolucion" onClick={this.onClickHandleS} style={{ marginLeft: '3px' }}>Descargar solución </div>
                </div>
              </div>

              <div className="SEW2010-contenido">

                <div className="SEW2010-C-estudio">

                  <div className="SEW2010-CEadm-top1">

                    <div>
                      <div className="celdaInputDAPFBN">
                        <Input2 value={semanaWordLoadEdt.numClase} autoComplete={"off"} type="text" name="numSemana" placeholder="Nº Semana" icon="fas fa-calendar" ref="ref_numSemana" onChange={this.onChangeInput} />
                        <p>Test Nº</p>
                      </div>
                    </div>

                    <div>
                      <div className="celdaInputDAPFBN">
                        <Input2 value={semanaWordLoadEdt.nombreEjercicio} autoComplete={"off"} type="text" name="nameTest" placeholder="Nombre test" icon="fas fa-tag" ref="ref_nameTest" onChange={this.onChangeInput} />
                        <p>Nombre test</p>
                      </div>
                      <div className="celdaInputDAPFBN">
                        <Input2 value={semanaWordLoadEdt.num_preg_test} autoComplete={"off"} type="text" name="totalPregTest" placeholder="Nº Preguntas test" icon="fas fa-tag" ref="ref_totalPregTest" onChange={this.onChangeInput} />
                        <p>Nº Preguntas</p>
                      </div>
                    </div>

                  </div>

                  <div className="SEW2010-CEadm-menuOptionsadm">
                    <div className="SEW2010-CEadm-menuOptionsadmMenu">
                      <div id="contentTarjeta" style={contentSelectTextEdt && contentSelectTextEdt == "1" ? ({ color: '#3469b7' }) : ({ color: '#bebebe' })} onClick={this.onClickHandleS}>
                        Información test
                      </div>
                    </div>

                    <div>
                      <div className='iMW-boton'>
                        <Boton id="edtTestWord2010" icon1="" icon2="" texto={semanaWordLoadEdt.id_semana == null ? ("Insertar") : ("Editar")} onClick={this.onClickHandleS} />
                      </div>
                    </div>
                  </div>

                  <div className="SEW2010-CEadm-contentMenuLoadAdm">
                    <div className="editorPlantearrDuda" style={{ paddingLeft: '10px' }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={textHtmlMensaje}
                        onInit={editor => { }}
                        config={{
                          // toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                        }}
                        onChange={this.onChangeText}
                      />
                    </div>
                  </div>
                  <div className="SEW2010-CEadm-descargas">
                    <div className='iMW-boton'>
                      {/* <Boton id="subirTest" name="myFiles" icon1="" icon2="" texto="Subir test" onClick={this.onClickHandleS} /> */}

                      <InputFileExamDesa onChange={this.onClickHandleS} id="subirTest" name="subirTest" texto="Subir test" />
                    </div>
                    <div className='iMW-boton'>
                      {/* <Boton id="subirRespuestas" icon1="" icon2="" texto="Subir solución" onClick={this.onClickHandleS} /> */}
                      <InputFileExamDesa onChange={this.onClickHandleS} id="subirRespuestas" name="subirRespuestas" texto="Subir solución" />
                    </div>

                  </div>


                </div>
                <div className="SEW2010-C-img">
                  <img src="../img/wavingHand.png" />
                </div>
              </div>

            </React.Fragment>
          ) : null}


        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    fotoPerfil: state.user.fotoPerfil
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Word2010CompletaTestAdmin);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Assets
import './css/barraProceso.css';

class BarraProceso extends Component {

  static propTypes = {
    estadoProceso: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
    }

  render() {
    const { estadoProceso } = this.props;
    if(estadoProceso == 'completar'){
              return (
                    <div className="barraProceso" id="bpCompletar">
                    <i className="fas fa-exclamation-triangle"></i>
                      Completar Datos
                      <i className="fas fa-comment"></i>
                    </div>
              );
        }else   if(estadoProceso == 'enviar'){
          return (
                <div className="barraProceso" id="bpEnviar">
                <i className="fas fa-exclamation-triangle"></i>
                  Revise y Envie
                  <i className="fas fa-comment"></i>
                </div>
          );
        }else   if(estadoProceso == 'completado'){
          return (
                <div className="barraProceso" id="bpCompletado">
                <i className="fas fa-exclamation-triangle"></i>
                  Proceso Completado
                  <i className="fas fa-comment"></i>
                </div>
          );
        } else   if(estadoProceso == 'error'){
            return (
                  <div className="barraProceso" id="bpError" id="bpError">
                  <i className="fas fa-exclamation-triangle"></i>
                    Error
                    <i className="fas fa-comment"></i>
                  </div>
            );
          } else {
            <div className="barraProceso" id="bpError" id="bpError">
            <i className="fas fa-exclamation-triangle"></i>
              Error
              <i className="fas fa-comment"></i>
            </div>
          }
        }
 }

export default BarraProceso;

// Dependencies
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
// Assets
import './css/ProgresoTest.css';
import { isDefined } from '../../lib/utils/is';
// Actions

class ProgresoTest extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    idOposicion: PropTypes.number
  };
  constructor(props) {
    super(props);
    this.state = {
      display: 'flex',
      estadisticaSeleted: 'teorico',
      otroCursoIsLoad: false
    };

    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(e) {

    if (e.target.id === 'teorico') {
      this.setState({ estadisticaSeleted: 'teorico' })
    } else if (e.target.id === 'practico') {
      this.setState({ estadisticaSeleted: 'practico' })
    } else if (e.target.id === 'desarrollo') {
      this.setState({ estadisticaSeleted: 'desarrollo' })
    } else if (e.target.id === 'curso') {
      this.setState({ estadisticaSeleted: 'curso' })
    }

  };
  async componentDidMount() {
    try {


      let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      await this.setState({ idUser: cls1[0].id_usuario });
      let data2 = { idUser: cls1[0].id_usuario }

      if(isDefined(this.props.otroIdUser)){
        data2 = { idUser: this.props.otroIdUser }
      }
      
      let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/mediaTestJusticiaUser", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;


      await this.setState({ mediaTestTeorico: cls2 });

      let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/mediaCasoPracticoUser", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;
      this.setState({ mediaTestPractico: cls3 });

      //Media ExamenGesDesarrollo
      let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/mediaExamDesarrolloUser2", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response4.ok) {
        throw Error(response4.statusText);
      }
      let json4 = await response4.json();
      let cls4 = await json4;
      this.setState({ mediaExaDesarrollo: cls4 });

      if (isDefined(this.props.otroCurso)) {

        let data123 = { idClase: this.props.otroCurso }
        let response123 = await fetch("https://oposiciones-justicia.es/api/oficina/totalClasesAplicadas", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
        });
        if (!response123.ok) {
          throw Error(response123.statusText);
        }
        let json123 = await response123.json();
        let cls123 = await json123;
        this.setState({ totalClasesAplicadas: cls123 });


        let facturas = []
        let semanasFacturadas = []

        let data5 = { idClase: this.props.otroCurso, idUser: isDefined(this.props.otroIdUser) ? this.props.otroIdUser : this.props.idUser }
        let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
        });
        if (!response5.ok) {
          throw Error(response5.statusText);
        }
        let json5 = await response5.json();
        let cls5 = await json5;
        for (var i = 0; i < cls5.length; i++) {
          facturas.push({
            idFactura: cls5[i].idFactura,
            fechaPago: cls5[i].fechaPago,
            fechaCaducidad: cls5[i].fechaCaducidad,
            tipo: cls5[i].tipo
          })
        }
        this.setState({ facturaAlumnoClase: facturas });

        for (var i = 0; i < facturas.length; i++) {
          let fechaC = moment(facturas[i].fechaCaducidad).add(7, 'd').format('YYYY-MM-DD HH:mm')
          let data25 = { idClase: this.props.otroCurso, idUser: isDefined(this.props.otroIdUser) ? this.props.otroIdUser : this.props.idUser, fechaPago: facturas[i].fechaPago, fechaCaducidad: fechaC }

          // Poner fecha caducidad --> mes siguientes día 7
          // clasificar semanas comprobar que no existe
          let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
          if (facturas[i].tipo == 3) {
            urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
          } else {
            urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
          }

          let response5 = await fetch(urlSemClasefAC, {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data25)
          });
          if (!response5.ok) {
            throw Error(response5.statusText);
          }
          let json25 = await response5.json();
          let cls25 = await json25;
          for (var f = 0; f < cls25.length; f++) {
            let fechaMostra = moment(cls25[f].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
            let ahora = moment().format('YYYY-MM-DD HH:mm')
            if (ahora >= fechaMostra) {
              // comprobar que no existe en cls25 la semana
              let idPrgComprobacion = cls25[f].idPrg;
              let existeIdPrg = semanasFacturadas.findIndex(s => s.idPrg == idPrgComprobacion);
              if (existeIdPrg == -1) {
                semanasFacturadas.push(cls25[f])
              }
            }

          }
        }

        this.setState({ semanasFacturadas: semanasFacturadas });


      } else {



        let data123 = { idClase: this.props.idClase }
        let response123 = await fetch("https://oposiciones-justicia.es/api/oficina/totalClasesAplicadas", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
        });
        if (!response123.ok) {
          throw Error(response123.statusText);
        }
        let json123 = await response123.json();
        let cls123 = await json123;
        this.setState({ totalClasesAplicadas: cls123 });




        let facturas = []
        let semanasFacturadas = []

        let data5 = { idClase: this.props.idClase, idUser: this.props.idUser }
        let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
        });
        if (!response5.ok) {
          throw Error(response5.statusText);
        }
        let json5 = await response5.json();
        let cls5 = await json5;
        for (var i = 0; i < cls5.length; i++) {
          facturas.push({
            idFactura: cls5[i].idFactura,
            fechaPago: cls5[i].fechaPago,
            fechaCaducidad: cls5[i].fechaCaducidad,
            tipo: cls5[i].tipo
          })
        }
        this.setState({ facturaAlumnoClase: facturas });

        for (var i = 0; i < facturas.length; i++) {
          let fechaC = moment(facturas[i].fechaCaducidad).add(7, 'd').format('YYYY-MM-DD HH:mm')
          let data25 = { idClase: this.props.idClase, idUser: this.props.idUser, fechaPago: facturas[i].fechaPago, fechaCaducidad: fechaC }

          // Poner fecha caducidad --> mes siguientes día 7
          // clasificar semanas comprobar que no existe
          let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            if(facturas[i].tipo == 3){
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
            } else {
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            }
          let response5 = await fetch(urlSemClasefAC, {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data25)
          });
          if (!response5.ok) {
            throw Error(response5.statusText);
          }
          let json25 = await response5.json();
          let cls25 = await json25;
          for (var f = 0; f < cls25.length; f++) {
            let fechaMostra = moment(cls25[f].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
            let ahora = moment().format('YYYY-MM-DD HH:mm')
            if (ahora >= fechaMostra) {
              // comprobar que no existe en cls25 la semana
              let idPrgComprobacion = cls25[f].idPrg;
              let existeIdPrg = semanasFacturadas.findIndex(s => s.idPrg == idPrgComprobacion);
              if (existeIdPrg == -1) {
                semanasFacturadas.push(cls25[f])
              }
            }

          }
        }

        this.setState({ semanasFacturadas: semanasFacturadas });
      }






    } catch (e) { console.log(e) }
  }

  async componentDidUpdate() {
    if ((isDefined(this.props.otroCurso) && this.props.idClase != this.props.otroCurso) && this.state.otroCursoIsLoad == false || this.state.otroCursoIsLoad != this.props.otroCurso) {
      this.setState({ otroCursoIsLoad: this.props.otroCurso })
      let data123 = { idClase: this.props.otroCurso }
      let response123 = await fetch("https://oposiciones-justicia.es/api/oficina/totalClasesAplicadas", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
      });
      if (!response123.ok) {
        throw Error(response123.statusText);
      }
      let json123 = await response123.json();
      let cls123 = await json123;
      this.setState({ totalClasesAplicadas: cls123 });




      let facturas = []
      let semanasFacturadas = []

      let data5 = { idClase: this.props.otroCurso, idUser: isDefined(this.props.otroIdUser) ? this.props.otroIdUser : this.props.idUser }
      let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
      });
      if (!response5.ok) {
        throw Error(response5.statusText);
      }
      let json5 = await response5.json();
      let cls5 = await json5;
      for (var i = 0; i < cls5.length; i++) {
        facturas.push({
          idFactura: cls5[i].idFactura,
          fechaPago: cls5[i].fechaPago,
          fechaCaducidad: cls5[i].fechaCaducidad,
          tipo: cls5[i].tipo
        })
      }
      this.setState({ facturaAlumnoClase: facturas });

      for (var i = 0; i < facturas.length; i++) {
        let fechaC = moment(facturas[i].fechaCaducidad).add(7, 'd').format('YYYY-MM-DD HH:mm')
        let data25 = { idClase: this.props.otroCurso, idUser: isDefined(this.props.otroIdUser) ? this.props.otroIdUser : this.props.idUser, fechaPago: facturas[i].fechaPago, fechaCaducidad: fechaC }

        // Poner fecha caducidad --> mes siguientes día 7
        // clasificar semanas comprobar que no existe
        let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            if(facturas[i].tipo == 3){
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
            } else {
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            }
        let response5 = await fetch(urlSemClasefAC, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data25)
        });
        if (!response5.ok) {
          throw Error(response5.statusText);
        }
        let json25 = await response5.json();
        let cls25 = await json25;
        for (var f = 0; f < cls25.length; f++) {
          let fechaMostra = moment(cls25[f].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
          let ahora = moment().format('YYYY-MM-DD HH:mm')
          if (ahora >= fechaMostra) {
            // comprobar que no existe en cls25 la semana
            let idPrgComprobacion = cls25[f].idPrg;
            let existeIdPrg = semanasFacturadas.findIndex(s => s.idPrg == idPrgComprobacion);
            if (existeIdPrg == -1) {
              semanasFacturadas.push(cls25[f])
            }
          }

        }
      }

      this.setState({ semanasFacturadas: semanasFacturadas });


    }
  }
  render() {
    const { isLogged, nombreUser, tipoUser, diaSemanalOtro } = this.props;
    const { display, mediaTestTeorico, estadisticaSeleted, mediaTestPractico, mediaExaDesarrollo, totalClasesAplicadas, semanasFacturadas } = this.state
    if (isLogged) {
      return (

        <div className="sectionProgTest">
          <div className="tituloSection">

            <div className="p_bar">
              Estadísticas
            </div>

          </div>

          <div className="p_1">

            <div className="barEsttop">
              <div className="navBarEstadisticas">
                <div onClick={this.handleClick} style={{ paddingLeft: '0px' }} className={estadisticaSeleted == 'teorico' ? ('estaSeletedOn') : ('estaSeletedOff')} id="teorico">
                  Test Teórico
                </div>

                <div onClick={this.handleClick} className={estadisticaSeleted == 'practico' ? ('estaSeletedOn') : ('estaSeletedOff')} id="practico">
                  Test Práctico
                </div>

                {mediaExaDesarrollo && mediaExaDesarrollo[0].totalExamRealiados > 0 ? (
                  <div onClick={this.handleClick} className={estadisticaSeleted == 'desarrollo' ? ('estaSeletedOn') : ('estaSeletedOff')} id="desarrollo">
                    Exa. Desarrollo
                  </div>
                ) : null}

                {semanasFacturadas && semanasFacturadas.length > 0 ? (
                  <div onClick={this.handleClick} style={{ border: '0px' }} className={estadisticaSeleted == 'curso' ? ('estaSeletedOn') : ('estaSeletedOff')} id="curso">
                    Curso
                  </div>
                ) : null}
              </div>

            </div>



            {mediaTestTeorico && estadisticaSeleted == 'teorico' && mediaTestTeorico[0].totalTestRealiados > 0 ? (
              <React.Fragment>
                <div className="barEstBottom1">
                  <div className="p_1_info">
                    <i className="fas fa-clipboard-list" />
                    Puntuación Media
                    <p>{mediaTestTeorico && mediaTestTeorico[0].puntuacionMedia}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-list" />
                    Test Realizados
                    <p>{mediaTestTeorico && mediaTestTeorico[0].totalTestRealiados}</p>
                  </div>
                </div>

                <div className="barEstBottom2">
                  <div className="p_1_info">
                    <i className="far fa-times-circle" />
                    Fallos

                    <p style={{ marginRight: '10px' }}>{mediaTestTeorico && parseFloat(mediaTestTeorico[0].totalErroneas * 100 / mediaTestTeorico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-check-circle" />
                    Aciertos
                    <p style={{ marginRight: '10px' }}>{mediaTestTeorico && parseFloat(mediaTestTeorico[0].totalCorrectas * 100 / mediaTestTeorico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="far fa-circle" />
                    En Blanco
                    <p>{mediaTestTeorico && parseFloat(mediaTestTeorico[0].totalEnBlanco * 100 / mediaTestTeorico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                </div>
              </React.Fragment>
            ) : (estadisticaSeleted == 'teorico' ? (<div>
              Aun no ha realizado ningun Test teórico.
            </div>) : null
            )}

            {mediaTestPractico && estadisticaSeleted == 'practico' && mediaTestPractico[0].totalTestRealiados > 0 ? (
              <React.Fragment>
                <div className="barEstBottom1">
                  <div className="p_1_info">
                    <i className="fas fa-clipboard-list" />
                    Puntuación Media
                    <p>{mediaTestPractico && mediaTestPractico[0].puntuacionMedia}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-list" />
                    Test Realizados
                    <p>{mediaTestPractico && mediaTestPractico[0].totalTestRealiados}</p>
                  </div>
                </div>

                <div className="barEstBottom2">
                  <div className="p_1_info">
                    <i className="far fa-times-circle" />
                    Fallos

                    <p style={{ marginRight: '10px' }}>{mediaTestPractico && parseFloat(mediaTestPractico[0].totalErroneas * 100 / mediaTestPractico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-check-circle" />
                    Aciertos
                    <p style={{ marginRight: '10px' }}>{mediaTestPractico && parseFloat(mediaTestPractico[0].totalCorrectas * 100 / mediaTestPractico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="far fa-circle" />
                    En Blanco
                    <p>{mediaTestPractico && parseFloat(mediaTestPractico[0].totalEnBlanco * 100 / mediaTestPractico[0].totalPreguntas).toFixed(2) + ' % '}</p>
                  </div>
                </div>
              </React.Fragment>
            ) : (estadisticaSeleted == 'practico' ? (<div>
              Aun no ha realizado ningun Test práctico.
            </div>) : null
            )}

            {mediaExaDesarrollo && estadisticaSeleted == 'desarrollo' && mediaExaDesarrollo[0].totalExamRealiados > 0 ? (
              <React.Fragment>
                <div className="barEstBottom1">
                  <div className="p_1_info">
                    <i className="fas fa-clipboard-list" />
                    Puntuación Media
                    <p>{mediaExaDesarrollo && mediaExaDesarrollo[0].puntuacionMedia}</p>
                  </div>



                </div>

                <div className="barEstBottom2">
                  <div className="p_1_info">
                    <i className="fas fa-check-circle" />
                    Examenes Corregidos
                    <p>{mediaExaDesarrollo && mediaExaDesarrollo[0].totalExamRealiados}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="far fa-question-circle" />
                    Examenes Pendientes
                    <p>{mediaExaDesarrollo && mediaExaDesarrollo[0].totalExamPendientesCo}</p>
                  </div>
                </div>
              </React.Fragment>
            ) : (estadisticaSeleted == 'desarrollo' ? (<div >
              Aun no ha realizado ningun Exámen de desarrollo.
            </div>) : null
            )}

            {totalClasesAplicadas && semanasFacturadas && estadisticaSeleted == 'curso' ? (
              <React.Fragment>
                <div className="barEstBottom1">
                  <div className="p_1_info">
                    <i className="fas fa-chalkboard-teacher" />
                    {
                      isDefined(diaSemanalOtro) && diaSemanalOtro != this.props.nombreClase ? (diaSemanalOtro) : (this.props.nombreClase)
                    }

                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-clipboard-list" />
                    Total Clases
                    <p>{totalClasesAplicadas[0].totalClases}</p>
                  </div>

                </div>

                <div className="barEstBottom2">
                  <div className="p_1_info">
                    <i className="fas fa-check-circle" />
                    Clases vistas
                    <p>{semanasFacturadas && semanasFacturadas.length}</p>
                  </div>
                  <div className="p_1_info">
                    <i className="fas fa-chart-line" />
                    Progreso
                    <p>{semanasFacturadas && totalClasesAplicadas[0].totalClases && parseFloat(semanasFacturadas.length * 100 / totalClasesAplicadas[0].totalClases).toFixed(0)}%</p>
                  </div>
                </div>
              </React.Fragment>
            ) : null}


          </div>
        </div>

      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    idClase: state.user.idClase,
    nombreClase: state.user.nombreClase
  }
}

export default connect(mapStateToProps, null)(ProgresoTest);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/InputTemaLeyesEdt.css';
import Input from '../Input';
import BotonMedio from '../BotonMedio';

class InputTemaLeyesEdt extends Component {

  static propTypes = {
    infoLeyes: PropTypes.array,
  };

constructor(props){
        super(props);
        this.state = {};
          this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(e){
        if(e.target.name == 'Ley'){
            let leyObject = this.state.busquedaLeyes.find(ley => ley.id_ley == e.target.value);
            if (leyObject != undefined){
              this.props.onChange(e);
            }
        } else {
          this.props.onChange(e);
        }
    }

    componentDidMount(){
			fetch('https://oposiciones-justicia.es/api/leyes/consulta')
				.then(res => res.json())
					.then(leyes => this.setState({
							busquedaLeyes: leyes
					}))
				.catch(error => console.log('Obejet fecth failed', error))
    };
  render() {
    const { infoLeyes } = this.props;
    const { intervalos, busquedaLeyes, iconLifeActive, iconLifeText} = this.state
    let num = 0;
    if(infoLeyes.length > 0 && infoLeyes != undefined){
              return (
                  <div>
                  {
                    infoLeyes && infoLeyes.map((ley, key) =>
                    <div className="bloqueLey" key={key}>
                          <div className="temas">
                                <div className="inputWithImgTema">
                                    <input id={key} type="text" className="textInputTipo" placeholder="Siglas Ley" value={ley.valueLey}
                                    name='Ley' ref='Ley' list="busquedaLeyes" title='Ley' onChange={this.onChangeInput} readOnly={ley.readOnly}/>
                                      <i className="fas fa-search"></i>
                                </div>
                          </div>
                          <div className="temas">
                              <div className="inputWithImgTema">
															<input id={key} type="text" className="textInputTipo" placeholder="Artículo Inicio" value={ley.art_ini}
															name='artInicio' ref='artInicio' title='Artículo Inicio' onChange={this.onChangeInput} />
																<i className="fas fa-arrow-circle-right"></i>
                              </div>
                            </div>
														<div className="temas">
	                              <div className="inputWithImgTema">
																<input id={key} type="text" className="textInputTipo" placeholder="Artículo Fin" value={ley.art_fin}
																name='artFin' ref='artFin' title='Artículo Fin' onChange={this.onChangeInput} />
																	<i className="fas fa-arrow-circle-right"></i>
	                              </div>
	                            </div>
                            <div className="temas">
                              <input type="radio" id={key} name={key+"editarBorrar"} value="1" onClick={this.onChangeInput}/>Editar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="2" onClick={this.onChangeInput}/>Borrar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="3" onClick={this.onChangeInput}/>Nada
                            </div>
                        </div>
                    )}
                        <datalist id="busquedaLeyes">{
                            busquedaLeyes && busquedaLeyes.map((ley, key) =>
                            <option key={key} value={ley.id_ley}>{ley.siglas_ley}</option>
                        )}</datalist>
                  </div>
              );
        }else{
          return (<div> </div>);
        }
        }
 }
// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default InputTemaLeyesEdt;

import moment from 'moment'

export async function crearCursoAlumno(info, infoReserva) {
 
  try {
    let tipoOpo = info.url3Oposicion
    let idClase = 21
    if (tipoOpo == "Aux-Tra-libre") {
      idClase = 21
    } else if (tipoOpo == "Gestion-libre") {
      idClase = 23
    } else if (tipoOpo == "Aux-Tra-interna") {
      idClase = 22
    } else if (tipoOpo == "Gestion-interna") {
      idClase = 24
    }

    let data2 = {
      activo: 1,
      dni: infoReserva.dni,
      nombreUsuario: infoReserva.nombreUsuario,
      nombre: infoReserva.nombre,
      apellidos: infoReserva.apellidos,
      pass: infoReserva.passHashB,
      email: infoReserva.email,
      telefono: infoReserva.telefono,
      codigoPostal: infoReserva.codigoPostal,
      direccion: infoReserva.direccion,
      ciudad: infoReserva.ciudad,
      tipoUsuario: 'Alumno',
      nivelUsuario: 1
    }

    let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoN", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;

    if (cls2.affectedRows > 0) {
      // añadir a clase
      let data3 = {
        id_clase: idClase,
        id_usuario: cls2.insertId,
        activado: 1
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClase", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = parseInt(info.duracionCurso.split(' ')[0])

        var dataFactura = {
          idClase: idClase,
          idAlumno: cls2.insertId,
          idCobrador: 1,
          totalPagado: info.ofertaPrecio,
          estado: 'Pendiente',
          // metodoPago: 'card,sepa_debit,afterpay_clearpay,klarna',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: '100% Online',
          concepto: ' Curso Online ' + info.nameOpo + ', Temario + ' + info.duracionCurso + ' de acceso a la plataforma.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().add(dur, 'M').format("YYYY-MM-DD HH:mm:ss"),
          tipo: 10
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {

          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: 'Inscripción curso - CEJ', // titulo real
            textoHtml: `
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en el curso.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Curso: `+info.tituloLeft+` de `+info.duracionCurso+`.</p>
              <p>Modalidad: `+info.tipoDeCurso+`.</p>
              <p>Una vez realice el pago en facturación tendrá acceso al 100% del curso y funcionalidades.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: 'Curso nueva inscripción - CEJ', // titulo real
              textoHtml: `
              <h4>Nueva inscripción en un curso,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en el curso.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Curso: `+info.tituloLeft+` de `+info.duracionCurso+`.</p>
              <p>Modalidad: `+info.tipoDeCurso+`.</p>
              <p>Saludos.</p>
            `
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;


          return true
        } else {
          return false
        }


      } else {
        return false
      }
    } else {
      return false
    }


  } catch (e) {
    console.log(e)
    return false
  }


}

export async function crearAlumnoPresencial(info, infoReserva, idClase) {
  
  // listaEsperaReserva: 0 1 2"
  // 0 --> no lista 1 --> lista espera 3 --> proceso reserva de plaza

  try {
   
    let data2 = {
      activo: 1,
      dni: infoReserva.dni,
      nombreUsuario: infoReserva.nombreUsuario,
      nombre: infoReserva.nombre,
      apellidos: infoReserva.apellidos,
      pass: infoReserva.passHashB,
      email: infoReserva.email,
      telefono: infoReserva.telefono,
      codigoPostal: infoReserva.codigoPostal,
      direccion: infoReserva.direccion,
      ciudad: infoReserva.ciudad,
      tipoUsuario: 'Alumno',
      nivelUsuario: 1
    }

    let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoN", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;

    if (cls2.affectedRows > 0) {
      let procesoReservaInfo = await info.infoClases.find(c=> c.idClase == idClase)

      let data3 = {
        id_clase: idClase,
        id_usuario: cls2.insertId,
        activado: 1,
        listaEsperaReserva: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
        (2):(1)
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClaseN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = 1

        var dataFactura = {
          idClase: idClase,
          idAlumno: cls2.insertId,
          idCobrador: 1,
          totalPagado: 0,
          estado: 'Pagado',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
          ('Reserva plaza'):('Lista espera'),
          concepto: ' Presencial ' + info.nameOpo + ', Reserva o lista espera en proceso.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().format("YYYY-MM-DD HH:mm:ss"),
          tipo: 0
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {
          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
            textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para terminar el proceso de reserva.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`</p>
              <p>La academia se pondrá en contacto con usted para confirmar su alta en la lista de espera y cuando tenga disponible su plaza.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono   al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            )
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
              textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Nueva reserva de plaza,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Nueva alta en la lista de espera,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una nueva alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `
            )
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;


          return true
        } else {
          return false
        }


      } else {
        return false
      }
    } else {
      return false
    }


  } catch (e) {
    console.log(e)
    return false
  }

 


}

export async function crearAlumnoVideoPresencial(info, infoReserva,idClase) {
 
  try {
    
    let data2 = {
      activo: 1,
      dni: infoReserva.dni,
      nombreUsuario: infoReserva.nombreUsuario,
      nombre: infoReserva.nombre,
      apellidos: infoReserva.apellidos,
      pass: infoReserva.passHashB,
      email: infoReserva.email,
      telefono: infoReserva.telefono,
      codigoPostal: infoReserva.codigoPostal,
      direccion: infoReserva.direccion,
      ciudad: infoReserva.ciudad,
      tipoUsuario: 'Alumno',
      nivelUsuario: 1
    }

    let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoN", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;

    if (cls2.affectedRows > 0) {
      // añadir a clase
      let procesoReservaInfo = await info.infoClases.find(c=> c.idClase == idClase)

      let data3 = {
        id_clase: idClase,
        id_usuario: cls2.insertId,
        activado: 1,
        listaEsperaReserva: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
        (2):(1) // reserva / lista espera
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClaseN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = 1

        var dataFactura = {
          idClase: idClase,
          idAlumno: cls2.insertId,
          idCobrador: 1,
          totalPagado: 0,
          estado: 'Pagado',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
          ('Reserva plaza'):('Lista espera'),
          concepto: ' Video Presencial ' + info.nameOpo + ', Reserva o lista espera en proceso.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().format("YYYY-MM-DD HH:mm:ss"),
          tipo: 0
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {
          // enviamos email con info!

          // procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (2):(1)

          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
            textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para terminar el proceso de reserva.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para confirmar su alta en la lista de espera y cuando tenga disponible su plaza.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono   al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            )
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
              textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Nueva reserva de plaza,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Nueva alta en la lista de espera,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una nueva alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `
            )
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;

          return true

        } else {
          return false
        }


      } else {
        return false
      }
    } else {
      return false
    }


  } catch (e) {
    console.log(e)
    return false
  }
}


export async function crearCursoAlumnoExiste(info, infoReserva, idUser) {
 
  try {
    let tipoOpo = info.url3Oposicion
    let idClase = 21
    if (tipoOpo == "Aux-Tra-libre") {
      idClase = 21
    } else if (tipoOpo == "Gestion-libre") {
      idClase = 23
    } else if (tipoOpo == "Aux-Tra-interna") {
      idClase = 22
    } else if (tipoOpo == "Gestion-interna") {
      idClase = 24
    }

    // let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoN", {
    //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    // });
    // if (!response2.ok) {
    //   throw Error(response2.statusText);
    // }
    // let json2 = await response2.json();
    // let cls2 = await json2;

      // añadir a clase
      let data3 = {
        id_clase: idClase,
        id_usuario: idUser,
        activado: 1
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClase", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = parseInt(info.duracionCurso.split(' ')[0])

        var dataFactura = {
          idClase: idClase,
          idAlumno: idUser,
          idCobrador: 1,
          totalPagado: info.ofertaPrecio,
          estado: 'Pendiente',
          // metodoPago: 'card,sepa_debit,afterpay_clearpay,klarna',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: '100% Online',
          concepto: ' Curso Online ' + info.nameOpo + ', Temario + ' + info.duracionCurso + ' de acceso a la plataforma.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().add(dur, 'M').format("YYYY-MM-DD HH:mm:ss"),
          tipo: 10
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {

          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: 'Inscripción curso - CEJ', // titulo real
            textoHtml: `
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en el curso.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Curso: `+info.tituloLeft+` de `+info.duracionCurso+`.</p>
              <p>Modalidad: `+info.tipoDeCurso+`.</p>
              <p>Una vez realice el pago en facturación tendrá acceso al 100% del curso y funcionalidades.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: 'Curso nueva inscripción - CEJ', // titulo real
              textoHtml: `
              <h4>Nueva inscripción en un curso,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en el curso, es un alumno que ya estaba registrado.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Curso: `+info.tituloLeft+` de `+info.duracionCurso+`.</p>
              <p>Modalidad: `+info.tipoDeCurso+`.</p>
              <p>Saludos.</p>
            `
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;


          return true
        } else {
          return false
        }


      } else {
        return false
      }
    


  } catch (e) {
    console.log(e)
    return false
  }


}

export async function crearAlumnoPresencialExiste(info, infoReserva, idClase, idUser) {
  
  // listaEsperaReserva: 0 1 2"
  // 0 --> no lista 1 --> lista espera 3 --> proceso reserva de plaza

  try {
   
    
      let procesoReservaInfo = await info.infoClases.find(c=> c.idClase == idClase)

      let data3 = {
        id_clase: idClase,
        id_usuario: idUser,
        activado: 1,
        listaEsperaReserva: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
        (2):(1)
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClaseN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = 1

        var dataFactura = {
          idClase: idClase,
          idAlumno: idUser,
          idCobrador: 1,
          totalPagado: 0,
          estado: 'Pagado',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
          ('Reserva plaza'):('Lista espera'),
          concepto: ' Presencial ' + info.nameOpo + ', Reserva o lista espera en proceso.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().format("YYYY-MM-DD HH:mm:ss"),
          tipo: 0
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {
          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
            textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para terminar el proceso de reserva.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para confirmar su alta en la lista de espera y cuando tenga disponible su plaza.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono   al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            )
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
              textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Nueva reserva de plaza,<br/> es un alumno que ya esta dado de alta: `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Nueva alta en la lista de espera,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una nueva alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `
            )
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;


          return true
        } else {
          return false
        }


      } else {
        return false
      }
    


  } catch (e) {
    console.log(e)
    return false
  }

 


}

export async function crearAlumnoVideoPresencialExiste(info, infoReserva,idClase, idUser) {
 
  try {
      // añadir a clase
      let procesoReservaInfo = await info.infoClases.find(c=> c.idClase == idClase)

      let data3 = {
        id_clase: idClase,
        id_usuario: idUser,
        activado: 1,
        listaEsperaReserva: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
        (2):(1) // reserva / lista espera
      }

      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClaseN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      if (cls3.affectedRows > 0) {
        // add factura
        let dur = 1

        var dataFactura = {
          idClase: idClase,
          idAlumno: idUser,
          idCobrador: 1,
          totalPagado: 0,
          estado: 'Pagado',
          metodoPago: 'card',
          datosActivados: 1,
          sucripcion: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? 
          ('Reserva plaza'):('Lista espera'),
          concepto: ' Video Presencial ' + info.nameOpo + ', Reserva o lista espera en proceso.',
          fechaFactura: moment().format("YYYY-MM-DD HH:mm:ss"),
          fechaCaducidad: moment().format("YYYY-MM-DD HH:mm:ss"),
          tipo: 0
        }

        let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/addFacturaN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataFactura)
        });
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
        let json4 = await response4.json();
        let cls4 = await json4;

        if (cls4.affectedRows > 0) {
          // enviamos email con info!

          // procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (2):(1)

          let data = {
            emailToEnviar: infoReserva.email,
            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
            segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
            textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para terminar el proceso de reserva.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Hola,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado correctamente su alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>La academia se pondrá en contacto con usted para confirmar su alta en la lista de espera y cuando tenga disponible su plaza.</p>
              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono   al +34 688 98 80 99.</p>
              <p>Gracias por confiar en CEJ.</p>
              <p>Saludos.</p>
            `
            )
            }
          
            let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            let data6 = {
              emailToEnviar: 'centrodeestudiosjudiciales@gmail.com',
              titulo: 'CEJ - Oposiciones justicia', // quien lo envia
              segundoTitulo: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? ('Reserva de plaza - CEJ'):('Lista de espera - CEJ'), // titulo real
              textoHtml: procesoReservaInfo.totalAlumnosActivos < procesoReservaInfo.maxAlumnos ? (`
              <h4>Nueva reserva de plaza,<br/> Alumno ya dado de alta: `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una reserva de plaza.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `):(`
              <h4>Nueva alta en la lista de espera,<br/> `+infoReserva.nombre + `, `+ infoReserva.apellidos +`.</h4>
              <p>Se ha tramitado una nueva alta en la lista de espera.</p>
              <p>Oposición: `+info.nameOpo+`.</p>
              <p>Grupo: `+procesoReservaInfo.nombreClase+`.</p>
              <p>Puede gestionarlo desde OFICINA / FACTURACION / Reservas y listas de espera.</p>
              <p>Saludos.</p>
            `
            )
              }
            
              let response6 = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
              });
              if (!response6.ok) {
              throw Error(response6.statusText);
              }
              let json6 = await response6.json();
              let cls6 = await json;

          return true

        } else {
          return false
        }


      } else {
        return false
      }
    


  } catch (e) {
    console.log(e)
    return false
  }
}


// 
// 
// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
// Actions
import { iniciarSesion } from '../../../actions/userAction';

// Assets
import './css/css.css';

import Footer from '../../../components/Global/Footer/Footer';
import Boton from '../../../components/Inputs/Boton';
import Input2 from '../../../components/Inputs/Input2';
import Tarjeta3mode11 from './components/Tarjeta3mode11';
import Tarjeta3mode22 from './components/Tarjeta3mode22';

class ResumenInscripcion extends Component {
  static propTypes = {
    iniciarSesion: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string,
    tipoUser: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      viewPrepaHome: 1,
      preparacion: {
        locaActual: 'selectOpo',
        modePrepa: 'normal',
        tipoPreparacion: null,
        oposiciones: [
          {
            infoText: 'Auxilio y Tramitacion LIBRE',
            tipoPreparacionOpo: 'Auxilio-y-Tramitacion-LIBRE'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Gestión Interna',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Tramitacion Interna',
            tipoPreparacionOpo: ''
          }

        ]
      }
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    console.log('entro')
    if (e.target.id.split('-')[0] == 'opoSelected') {
      // infoText: 'Gestion Libre',
      // tipoPreparacionOpo: 'Gestion-libre',
      // urlOpoSelectd: 'Online_Video_Presencial'


      let idLoca = e.target.id.split('-')[1]
      let prevUrlPrepa = this.state.preparacion.oposiciones[idLoca].urlOpoSelectd
      let tipoPreparacionOpo = this.state.preparacion.oposiciones[idLoca].tipoPreparacionOpo
      this.props.history.push('/Preparacion/' + prevUrlPrepa + '/' + tipoPreparacionOpo);




    } else if (e.target.id == 'atras') {
      this.props.history.push('/Preparacion/'
        + this.state.preparacionInfoRes.url2TipoPreparacion + '/' + this.state.preparacionInfoRes.url3Oposicion);
    } else if (e.target.id == 'help') {
      swal('Si necesita ayuda','Puede llamarnos al: 688 98 80 99','info')
    }
  }
  onChangeInput(e) {

    if (e.target.name == 'dniInput') {
      this.setState({ checkedDni: undefined })
      this.setState({ dniUser: e.target.value });
    } else if (e.target.name == "passwordInput") {
      this.setState({ checkedPass: undefined })
      this.setState({ passwordUserInput: e.target.value });
    }

  }
  async componentDidMount() {
    let url = location.href;
    let url1Inicio = url.split('/')[url.split('/').length - 4]
    let url2TipoPreparacion = url.split('/')[url.split('/').length - 3]
    let url3Oposicion = url.split('/')[url.split('/').length - 2]
    let url4TipoCursoReserva = url.split('/')[url.split('/').length - 1]



    if (url1Inicio == 'Preparacion' && (url2TipoPreparacion != 'Cursos_Online' && url2TipoPreparacion != 'Presencial' && url2TipoPreparacion != 'Online_Video_Presencial')) {
      // Url no valida redigrig atras!
      this.props.history.push('/Preparacion')
    } else {

      if (url3Oposicion != 'Aux-Tra-libre' && url3Oposicion != 'Gestion-libre' && url3Oposicion != 'Aux-Tra-interna' && url3Oposicion != 'Gestion-interna') {
        this.props.history.push('/Preparacion/' + url2TipoPreparacion)
      } else {

        let oposiciones = [
          {
            url2TipoPreparacion: url2TipoPreparacion,
            url3Oposicion: url3Oposicion,
            url4TipoCurReversva: url4TipoCursoReserva
          }
        ]
        let data2 = {}
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoAllClases", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let infoClases = await json2;

        if (
          url2TipoPreparacion == 'Cursos_Online'
          && (url3Oposicion == 'Aux-Tra-libre' || url3Oposicion == 'Gestion-libre' || url3Oposicion == 'Aux-Tra-interna' || url3Oposicion == 'Gestion-interna')) {
          if (url3Oposicion == 'Aux-Tra-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion
            let precio
            let ofertaPrecio
            if (tipoCursoReserva == 'SuperIntensivo') {
              duracion = '3 meses'
              precio = '99.67€'
              ofertaPrecio = '299€'
            } else if (tipoCursoReserva == 'Intensivo') {
              duracion = '6 meses'
              precio = '83.17€'
              ofertaPrecio = '499€'
            } else if (tipoCursoReserva == 'Ordinario') {
              duracion = '12 meses'
              precio = '58.25€'
              ofertaPrecio = '799€'
            } else {
              // SuperIntensivo
              duracion = null
              precio = '-€'
              ofertaPrecio = '-€'
            }
            // para presencial = 'Reserva-Plaza-Presencial'
            // para presencial online = 'Reserva-Plaza-Online-Presencial'
            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Curso Online a tu ritmo',
              infoTemas1Parte: 'Temas 1 al 15',
              infoTemas2Parte: 'Temas 16 a 26',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,

            }
            // AQUI LO DEJO QUEDA METER TODO LOS TIPOS DE PREPARACION AQUI ! Y LUEGO METER DESPUES DEL RESERVAR PLAZA/COMENZAR PRUEBA METER LAS NORMAS Y ACEPTAR O NO.

            // LUEGO CREAR LA GENERACION DE CURSOS Y RESERVAS

            // LUEGO EN OFICINA LA GESTION DE RESERVAS

            // EN ESCRITORIO CREAR EL INFORME DE RESERVA!

            // LUEGO EDITAR OFCINA ENTERA, NUEVA FACTURACION/RESERVAS/CITAS/EDITAR ALUMNO/EDITAR CLASE!
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion
            let precio
            let ofertaPrecio
            if (tipoCursoReserva == 'SuperIntensivo') {
              duracion = '3 meses'
              precio = '99.67€'
              ofertaPrecio = '299€'
            } else if (tipoCursoReserva == 'Intensivo') {
              duracion = '6 meses'
              precio = '91.50€'
              ofertaPrecio = '549€'
            } else if (tipoCursoReserva == 'Ordinario') {
              duracion = '12 meses'
              precio = '70.75€'
              ofertaPrecio = '849€'
            } else {
              duracion = null
              precio = '-€'
              ofertaPrecio = '-€'
            }
            // para presencial = 'Reserva-Plaza-Presencial'
            // para presencial online = 'Reserva-Plaza-Online-Presencial'
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Curso Online a tu ritmo',
              infoTemas1Parte: 'Temas 1 al 15',
              infoTemas2Parte: 'Temas 16 a 26',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,

            }
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else if (url3Oposicion == 'Aux-Tra-interna') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion
            let precio
            let ofertaPrecio
            if (tipoCursoReserva == 'SuperIntensivo') {
              duracion = '3 meses'
              precio = '99.67€'
              ofertaPrecio = '299€'
            } else if (tipoCursoReserva == 'Intensivo') {
              duracion = '6 meses'
              precio = '83.17€'
              ofertaPrecio = '499€'
            } else if (tipoCursoReserva == 'Ordinario') {
              duracion = '12 meses'
              precio = '58.25€'
              ofertaPrecio = '799€'
            } else {
              duracion = null
              precio = '-€'
              ofertaPrecio = '-€'
            }
            // para presencial = 'Reserva-Plaza-Presencial'
            // para presencial online = 'Reserva-Plaza-Online-Presencial'
            let slctPreparacionInfo = {
              nameOpo: 'Tramitación Interna',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Curso Online a tu ritmo',
              infoTemas1Parte: 'Temas 1 al 15',
              infoTemas2Parte: 'Temas 16 a 26',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,

            }
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-interna') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion
            let precio
            let ofertaPrecio
            if (tipoCursoReserva == 'SuperIntensivo') {
              duracion = '3 meses'
              precio = '99.67€'
              ofertaPrecio = '299€'
            } else if (tipoCursoReserva == 'Intensivo') {
              duracion = '6 meses'
              precio = '91.50€'
              ofertaPrecio = '549€'
            } else if (tipoCursoReserva == 'Ordinario') {
              duracion = '12 meses'
              precio = '70.75€'
              ofertaPrecio = '849€'
            } else {
              duracion = null
              precio = '-€'
              ofertaPrecio = '-€'
            }
            // para presencial = 'Reserva-Plaza-Presencial'
            // para presencial online = 'Reserva-Plaza-Online-Presencial'
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Interna',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Curso Online a tu ritmo',
              infoTemas1Parte: 'Temas 1 al 15',
              infoTemas2Parte: 'Temas 16 a 26',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,

            }
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else {
            this.props.history.push('/Preparacion/' + url2TipoPreparacion)
          }

          let preparacion = this.state
          preparacion.oposiciones = oposiciones
          this.setState({ preparacion: preparacion })

        } else if (url2TipoPreparacion == 'Presencial'
          && (url3Oposicion == 'Aux-Tra-libre' || url3Oposicion == 'Gestion-libre')) {
          if (url3Oposicion == 'Aux-Tra-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion = null
            let precio = '130€'
            let ofertaPrecio = '110€'
            // obtener clases disponibles y total de alumnos inscritos
            // api/usuarios/infoAllClases


            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Presencial',
              infoTemas1Parte: '',
              infoTemas2Parte: '',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,
              infoClases: infoClases.filter(c => c.opo == '4' && c.tipoClase == 'PRESENCIAL')

            }

            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion = null
            let precio = '150€'
            let ofertaPrecio = '135€'

            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Presencial',
              infoTemas1Parte: '',
              infoTemas2Parte: '',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,
              infoClases: infoClases.filter(c => c.opo == '3' && c.tipoClase == 'PRESENCIAL')

            }
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          }
        } else if (url2TipoPreparacion == 'Online_Video_Presencial'
          && (url3Oposicion == 'Aux-Tra-libre' || url3Oposicion == 'Gestion-libre')) {
          if (url3Oposicion == 'Aux-Tra-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion = null
            let precio = '110€'
            let ofertaPrecio = '90€'

            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Presencial Online',
              infoTemas1Parte: '',
              infoTemas2Parte: '',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,
              infoClases: infoClases.filter(c => c.opo == '4' && c.tipoClase == 'ONLINE')

            }

            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let tipoCursoReserva = url4TipoCursoReserva
            let duracion = null
            let precio = '140€'
            let ofertaPrecio = '110€'

            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              url4TipoCurReversva: url4TipoCursoReserva,
              tituloLeft: 'Presencial Online',
              infoTemas1Parte: '',
              infoTemas2Parte: '',
              tipoDeCurso: tipoCursoReserva,
              duracionCurso: duracion,
              precio: precio,
              ofertaPrecio: ofertaPrecio,
              infoClases: infoClases.filter(c => c.opo == '3' && c.tipoClase == 'ONLINE')

            }
            this.setState({ preparacionInfoRes: slctPreparacionInfo })
          }
        }



      }




    }


    // console.log('this.props.match.params')
    // console.log(this.props.match.params)
    // console.log('url')
    // console.log(url)



  }



  render() {
    let { preparacionInfoRes, viewVideo } = this.state
    let { isLogged } = this.props
    console.log('preparacionInfoRes')
    console.log(preparacionInfoRes)
    return (
      <div className="CursosCejF3" style={isLogged && isLogged == true ? ({
        position: 'absolute',
        zIndex: '1000',
        top: '0',
        right: '0'
      }) : ({})}>
        <div className="CC-headerNavLeft22 CC-headerNavLeft22F4" style={{ position: 'relative' }}>

          <Tarjeta3mode11 info={preparacionInfoRes} />


          <div className='CC-HN-top'>

            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>

            <div className='infoStatusPrepaSelection'>
              Usted ha elegido,<br /><br />
              Preparación <b>{preparacionInfoRes && preparacionInfoRes.tituloLeft}
                {preparacionInfoRes && ' ' + preparacionInfoRes.nameOpo}

              </b>. Utimo paso, rellene los datos requeridos.


            </div>



          </div>
          <div className='divAtrasv'>
            <div className='CC-HN-medInfo22' style={{ fontWeight: '400', display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '385px' }}>
              <b style={{ color: '#b3b3b3', marginRight: '10px', marginBottom: '10px', fontWeight: '400' }}>
                RESUMEN
              </b>
              PREPARACIÓN

            </div>
          </div>
          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-headerNavLeft222 CC-headerNavLeft222F4">

          <div className='CC-HN-top2'>
            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>
            <div>
              <div className='CC-HN-NavPrepa'>
                <div className='infoStatusPrepaSelection ctrlWd334'>

                  Usted a elegido,<br /><br />
                  Preparación <b>{preparacionInfoRes && preparacionInfoRes.tituloLeft}
                    {preparacionInfoRes && ' ' + preparacionInfoRes.nameOpo}

                  </b>. <br />¿Cómo le gustaría prepararse?



                </div>
              </div>
            </div>



          </div>



          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-contentCur2 CC-asRafc3g">

          <Tarjeta3mode22 info={preparacionInfoRes} />


        </div>



        {/* <div className="bottomLogin">
            <Footer />
        </div> */}
      </div>
    );

  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    iniciarSesion
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ResumenInscripcion);


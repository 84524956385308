export async function selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema){
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.
  let testConPreguntas = []
  let data = { idTest: idTest }
  try {
    //https://oposiciones-justicia.es/api/usuarios/selectPregPorTestId
    const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompleto", {
      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      const json1 = await response1.json();
      const cls1 = await json1;
        for  (var j = 0; j < cls1.length; j++) {
          let articuloRevisado = ''
          if(cls1[j].articulo.split('.y.') != undefined && cls1[j].articulo.split('.y.').length > 0){
            let artDFG= cls1[j].articulo.split('.y.')
            for (var k = 0; k < artDFG.length; k++) {
              if(k != artDFG.length-1 && k != artDFG.length-2){
                articuloRevisado = articuloRevisado + artDFG[k] + ' , '
              } else if(k == artDFG.length-2){
                articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
              } else if(k == artDFG.length-1){
                articuloRevisado = articuloRevisado + artDFG[k]
              }
            }
          } else {
            articuloRevisado = cls1[j].articulo
          }


          testConPreguntas.push({
            idClase: idClase != undefined ? (idClase) : (0),
            idTema: idTema != undefined ? (idTema):(null),
            idTest: idTest,
            idPrg: idPrg,
            idBloque: idBloque == 0 ? (0) : (idBloque),
            numBloque: numBloque,
            numTestBloque: numTestBloque,
            idPregunta: cls1[j].idPregunta,
            pregunta: cls1[j].pregunta,
            numPreg: j+1,
            tipoPregunta: cls1[j].tipoPregunta ,
            idLey: cls1[j].idLey,
            siglasLey: cls1[j].siglasLey,
            nombreLey: cls1[j].nombreLey,
            articulo: articuloRevisado ,
            idR1: cls1[j].idR1,
            idR2: cls1[j].idR2,
            idR3: cls1[j].idR3,
            idR4: cls1[j].idR4,
            puntuacion: 0,
            dudosa: 0,
            respuestas: [{
              idR: cls1[j].idR1,
              // respuesta: cls1[j].R1,
              respuesta: cls1[j].R1.trim().endsWith('.') == false ? (cls1[j].R1.trim().concat('.')) : (cls1[j].R1.trim()),
              validez: cls1[j].validezR1,
              subrayado: cls1[j].subrayado1,
              nota: cls1[j].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR2,
              // respuesta: cls1[j].R2,
              respuesta: cls1[j].R2.trim().endsWith('.') == false ? (cls1[j].R2.trim().concat('.')) : (cls1[j].R2.trim()),
              validez: cls1[j].validezR2,
              subrayado: cls1[j].subrayado2,
              nota: cls1[j].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR3,
              // respuesta: cls1[j].R3,
              respuesta: cls1[j].R3.trim().endsWith('.') == false ? (cls1[j].R3.trim().concat('.')) : (cls1[j].R3.trim()),
              validez: cls1[j].validezR3,
              subrayado: cls1[j].subrayado3,
              nota: cls1[j].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR4,
              // respuesta: cls1[j].R4,
              respuesta: cls1[j].R4.trim().endsWith('.') == false ? (cls1[j].R4.trim().concat('.')) : (cls1[j].R4.trim()),
              validez: cls1[j].validezR4,
              subrayado: cls1[j].subrayado4,
              nota: cls1[j].nota4,
              valorUsuario: 0
            }]
          })
        }
      return testConPreguntas;

  } catch(error) {
    console.log(error);
    return error;
  }

}

export async function respuestaSbr(respuesta, sbr){
  try {
    // clasificar subr
    let respuestaRe = undefined
    let respuestaSplit = respuesta.split(" ");
    let sbrList = undefined
    let multiple = /,/
    let sbrVarias = /-/
    if(multiple.exec(sbr) != null){
      // vario subryados
      sbrList = sbr.split(',')
    }
    if(sbrList != undefined){
      //varias cosas a subrayar
      for (var i = 0; i < sbrList.length; i++) {
        if(sbrList[i] != ''){
          let location1
          let location2
          if(sbrVarias.exec(sbrList[i]) != null){
            // vario subryados
            location1 = sbrList[i].split('-')[0] -1
            respuestaSplit[location1] = '<u>'+respuestaSplit[location1]
            location2 = sbrList[i].split('-')[1] -1
            respuestaSplit[location2] = respuestaSplit[location2]+'</u>'
          } else {
            location1 = sbrList[i]-1
            respuestaSplit[location1] = '<u>'+respuestaSplit[location1]+'</u>'
          }
        }
      }
      respuestaRe = respuestaSplit.join(" ")
    } else {
      //una sola cosa a subrayar
      let location1
      let location2
      if(sbrVarias.exec(sbr) != null){
        // vario subryados
        location1 = sbr.split('-')[0] -1
        respuestaSplit[location1] = '<u>'+respuestaSplit[location1]
        location2 = sbr.split('-')[1] -1
        respuestaSplit[location2] = respuestaSplit[location2]+'</u>'
      } else {
        location1 = parseInt(sbr)-1
        respuestaSplit[location1] = await '<u>'+respuestaSplit[location1]+'</u>'
      }
      respuestaRe = respuestaSplit.join(" ")
    }
    return respuestaRe
  } catch(error) {
    console.log(error);
    return respuesta;
  }
}
export async function respuestasTestId(testConPreguntas){
  let testConRespuestas = testConPreguntas
  let data = { preguntas: testConRespuestas }
  try {
  const response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/respuestasTestJusticia", {
      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    const json2 = await response2.json();
    const cls2 = await json2;

    for (var j = 0; j < cls2.length/4; j++) {
      let indexPreg = await testConRespuestas.findIndex(p=> p.idPregunta == cls2[j*4].idP)
        let clsRes1 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR1 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes2 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR2 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes3 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR3 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes4 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR4 && p.idP == testConRespuestas[indexPreg].idPregunta)
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes1].idR,
            // respuesta: cls2[clsRes1].r,
            respuesta: cls2[clsRes1].r.trim().endsWith('.') == false ? (cls2[clsRes1].r.trim().concat('.')) : (cls2[clsRes1].r.trim()),
            validez: cls2[clsRes1].validez,
            subrayado: cls2[clsRes1].subrayado,
            nota: cls2[clsRes1].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes2].idR,
            // respuesta: cls2[clsRes2].r,
            respuesta: cls2[clsRes2].r.trim().endsWith('.') == false ? (cls2[clsRes2].r.trim().concat('.')) : (cls2[clsRes2].r.trim()),
            validez: cls2[clsRes2].validez,
            subrayado: cls2[clsRes2].subrayado,
            nota: cls2[clsRes2].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes3].idR,
            // respuesta: cls2[clsRes3].r,
            respuesta: cls2[clsRes3].r.trim().endsWith('.') == false ? (cls2[clsRes3].r.trim().concat('.')) : (cls2[clsRes3].r.trim()),
            validez: cls2[clsRes3].validez,
            subrayado: cls2[clsRes3].subrayado,
            nota: cls2[clsRes3].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes4].idR,
            // respuesta: cls2[clsRes4].r,
            respuesta: cls2[clsRes4].r.trim().endsWith('.') == false ? (cls2[clsRes4].r.trim().concat('.')) : (cls2[clsRes4].r.trim()),
            validez: cls2[clsRes4].validez,
            subrayado: cls2[clsRes4].subrayado,
            nota: cls2[clsRes4].nota,
            valorUsuario: 0
          })

    }
    return testConRespuestas
  } catch(error) {
    console.log(error);
    return error;
  }
}

export async function corregirTest(pregunta){
  let correccion = {
    puntuacion: 0.00,
    correcta:0,
    mal:0,
    enBlanco:0
  }
  try {
      switch (pregunta.tipoPregunta) {

        case 1:{

            let enBlanco = true;
            let mal = false;
            let correcta = false;
            for (var j = 0; j < pregunta.respuestas.length; j++) {
              if(pregunta.respuestas[j].valorUsuario == 1){
                enBlanco = false
                if(pregunta.respuestas[j].validez == 1){
                  correcta = true;
                } else {
                  mal = true
                }
              }
            }

            if(correcta == true){
              correccion.correcta = correccion.correcta + 1

            } else if(mal == true){
              correccion.mal = correccion.mal + 1

            } else if (enBlanco == true){
              correccion.enBlanco = correccion.enBlanco + 1

            }
        }break;
        case 2:{

            let enBlanco = true;
            let mal = false;
            let correcta = false;
            for (var j = 0; j < pregunta.respuestas.length; j++) {
              if(pregunta.respuestas[j].valorUsuario == 1){
                enBlanco = false
                if(
                  pregunta.respuestas[j].validez == 1 ||
                  pregunta.respuestas[j].validez == 11 ||
                  pregunta.respuestas[j].validez == 17
                ){
                  correcta = true;
                } else {
                  mal = true
                }
              }
            }
            if(correcta == true){
              correccion.correcta = correccion.correcta + 1

            } else if(mal == true){
              correccion.mal = correccion.mal + 1

            } else if (enBlanco == true){
              correccion.enBlanco = correccion.enBlanco + 1

            }
        }break;
        case 3:{

            let enBlanco = true;
            let mal = false;
            let correcta = false;
            for (var j = 0; j < pregunta.respuestas.length; j++) {
              if(pregunta.respuestas[j].valorUsuario == 1){
                enBlanco = false
                if(
                  pregunta.respuestas[j].validez == 1 ||
                  pregunta.respuestas[j].validez == 5 ||
                  pregunta.respuestas[j].validez == 11 ||
                  pregunta.respuestas[j].validez == 17
                ){
                  correcta = true;
                } else {
                  mal = true
                }
              }
            }
            if(correcta == true){
              correccion.correcta = correccion.correcta + 1

            } else if(mal == true){
              correccion.mal = correccion.mal + 1

            } else if (enBlanco == true){
              correccion.enBlanco = correccion.enBlanco + 1

            }
        }break;
        case 4:{

            let enBlanco = true;
            let mal = false;
            let correcta = false;
            for (var j = 0; j < pregunta.respuestas.length; j++) {
              if(pregunta.respuestas[j].valorUsuario == 1){
                enBlanco = false
                if(
                  pregunta.respuestas[j].validez == 1 ||
                  pregunta.respuestas[j].validez == 6 ||
                  pregunta.respuestas[j].validez == 9 ||
                  pregunta.respuestas[j].validez == 11 ||
                  pregunta.respuestas[j].validez == 12 ||
                  pregunta.respuestas[j].validez == 15 ||
                  pregunta.respuestas[j].validez == 17
                ){
                  correcta = true;
                } else {
                  mal = true
                }
              }
            }
            if(correcta == true){
              correccion.correcta = correccion.correcta + 1

            } else if(mal == true){
              correccion.mal = correccion.mal + 1

            } else if (enBlanco == true){
              correccion.enBlanco = correccion.enBlanco + 1

            }
        }break;
        case 5:{

            let enBlanco = true;
            let mal = false;
            let correcta = false;
            for (var j = 0; j < pregunta.respuestas.length; j++) {
              if(pregunta.respuestas[j].valorUsuario == 1){
                enBlanco = false
                if(
                  pregunta.respuestas[j].validez == 1 ||
                  pregunta.respuestas[j].validez == 11 ||
                  pregunta.respuestas[j].validez == 17 ||
                  pregunta.respuestas[j].validez == 20 ||
                  pregunta.respuestas[j].validez == 24
                ){
                  correcta = true;
                } else {
                  mal = true
                }
              }
            }
            if(correcta == true){
              correccion.correcta = correccion.correcta + 1

            } else if(mal == true){
              correccion.mal = correccion.mal + 1

            } else if (enBlanco == true){
              correccion.enBlanco = correccion.enBlanco + 1

            }
        }break;

      }
      let respuesta = undefined;
    if(correccion.correcta == 1){
      respuesta = 1
    } else if (correccion.mal == 1){
      respuesta = 2
    } else if (correccion.enBlanco == 1){
      respuesta = 0
    }

    return respuesta

  } catch(error) {
    console.log(error);
    return error;
  }

}


export async function mezclarRespuestasJusticia(testConRespuestas){
  let orderList2 = []
  for (var j = 0; j < testConRespuestas.length; j++) {
      orderList2.push(testConRespuestas[j])
  }

  let listaRandomIdPreg2 = await orderList2.sort(function() {return Math.random() - 0.5});
  listaRandomIdPreg2 = await listaRandomIdPreg2.sort(function() {return Math.random() - 0.5});

  let savePreg2 = []
  for (var k = 0; k < listaRandomIdPreg2.length; k++) {
    listaRandomIdPreg2[k].numPreg = k+1;
    await savePreg2.push(listaRandomIdPreg2[k])
  }
  testConRespuestas = await savePreg2;
  testConRespuestas = await testConRespuestas
  for (var i = 0; i < await testConRespuestas.length; i++) {
    testConRespuestas[i].numPreg = i+1
    let orderList = []
    for (var j = 0; j < 4; j++) {
      orderList.push(testConRespuestas[i].respuestas[j].idR)
    }

    let listaRandomIdPreg = await orderList.sort(function() {return Math.random() - 0.5});
    listaRandomIdPreg = await listaRandomIdPreg.sort(function() {return Math.random() - 0.5});
    listaRandomIdPreg = await listaRandomIdPreg.sort(function() {return Math.random() - 0.5});

    let savePreg = []
    for (var k = 0; k < await listaRandomIdPreg.length; k++) {
      
      savePreg.push({
        idR: testConRespuestas[i].respuestas.filter(r=> r.idR == listaRandomIdPreg[k])[0].idR,
        respuesta: testConRespuestas[i].respuestas.filter(r=> r.idR == listaRandomIdPreg[k])[0].respuesta,
        validez: testConRespuestas[i].respuestas.filter(r=> r.idR == listaRandomIdPreg[k])[0].validez,
        nota:  testConRespuestas[i].respuestas.filter(r=> r.idR == listaRandomIdPreg[k])[0].nota,
        subrayado: testConRespuestas[i].respuestas.filter(r=> r.idR == listaRandomIdPreg[k])[0].subrayado,
        valorUsuario: 0
      })

    }
    testConRespuestas[i].respuestas = await savePreg;
  }
return testConRespuestas
}

export async function comprobarRespuestas(test){
  if(test != undefined){
    for (var i = 0; i < await test.length; i++) {
      for (var j = 0; j < await test[i].respuestas.length; j++) {
          let resToChange = test[i].respuestas[j];
            if(test[i].tipoPregunta == 2 && resToChange.validez == 1){
              // Multiple CORRECTA
              let idRR = test[i].respuestas[j].idR // Respuesta valida 280
              let respuesta = test[i].respuestas[j].respuesta
              for (var k = 0; k < test[i].respuestas.length; k++) {
                let val = test[i].respuestas[k].validez

                if(val == 6 || val == 7) {

                  // RUV 1
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }

                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                  // FIN  Ruv 1
                } else if (val == 12 || val == 13){
                  // RUV 2
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if(val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19){

                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                }

                if(val == 2 || val == 10 || val == 11 || val == 16 || val == 17){
                  // hay que sacar la position j!
                  if ( k == 0 ) {

                    let letra = 'a)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 1) {
                    let letra = 'b)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 2){
                    let letra = 'c)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 3){
                    let letra = 'd)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  }
                  let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                  test[i].respuestas[position].respuesta = respuesta
                }
              }
              // Fin Multiple CORRECTA
            } else if(test[i].tipoPregunta == 3 && resToChange.validez == 3) {
              // Multiple Incorrecta
              let idRR = test[i].respuestas[j].idR
              let respuesta = test[i].respuestas[j].respuesta
              for (var k = 0; k < test[i].respuestas.length; k++) {
                let val = test[i].respuestas[k].validez
                if(val == 6 || val == 7) {

                  // RUV 1
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }

                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                  // FIN  Ruv 1
                } else if (val == 12 || val == 13){
                  // RUV 2
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if(val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19){

                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                }
                if(val == 4 || val == 5 || val == 10 || val == 11 || val == 16 || val == 17){

                  // hay que sacar la position j!
                  if ( k == 0 ){
                    let letra = 'a)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }

                  } else if ( k == 1) {
                    let letra = 'b)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 2){
                    let letra = 'c)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 3){
                    let letra = 'd)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  }
                  let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                  test[i].respuestas[position].respuesta = respuesta
                }
              }

              // Fin Multiple Incorrecta
            } else if(test[i].tipoPregunta == 4 && (resToChange.validez == 6 || resToChange.validez == 7)){

              if(resToChange.validez == 6 || resToChange.validez == 7){
                // RUV 1
                let idRR = test[i].respuestas[j].idR
                let respuesta = test[i].respuestas[j].respuesta
                for (var k = 0; k < test[i].respuestas.length; k++) {
                  let val = test[i].respuestas[k].validez


                  if(val == 3){

                    let idRR2 = test[i].respuestas[k].idR
                    let respuesta2 = test[i].respuestas[k].respuesta
                    for (var p = 0; p < test[i].respuestas.length; p++) {
                      let val2 = test[i].respuestas[p].validez
                      if(val2 == 4 || val2 == 5 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17){

                        // hay que sacar la position j!
                        if ( p == 0 ){
                          let letra = 'a)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }

                        } else if ( p == 1) {
                          let letra = 'b)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        } else if ( p == 2){
                          let letra = 'c)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        } else if ( p == 3){
                          let letra = 'd)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        }
                        let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                        test[i].respuestas[position].respuesta = respuesta2
                      }
                    }

                  }

                  if(val == 22){

                    let idRR2 = test[i].respuestas[k].idR
                    let respuesta2 = test[i].respuestas[k].respuesta
                    for (var p = 0; p < test[i].respuestas.length; p++) {
                      let val2 = test[i].respuestas[p].validez
                      if(val2 == 23 || val2 == 24 ||val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17){

                    // hay que sacar la position j!
                    if ( p == 0 ){
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }

                    } else if ( p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    } else if ( p == 2){
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    } else if ( p == 3){
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                      }
                    }

                  if(val == 8 || val == 9 || val == 10 || val == 11 || val == 18 || val == 19){

                    // hay que sacar la position j!
                    if ( k == 0 ){
                      let letra = 'a)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 1) {
                      let letra = 'b)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 2){
                      let letra = 'c)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 3){
                      let letra = 'd)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    }
                    let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                    test[i].respuestas[position].respuesta = respuesta
                  }
                }
                // FIN  Ruv 1
              }

            } else if(test[i].tipoPregunta == 4 && (resToChange.validez == 12 ||resToChange.validez == 13)){

              if (resToChange.validez == 12 || resToChange.validez == 13){
                // RUV 2
                let idRR = resToChange.idR
                let respuesta = resToChange.respuesta
                for (var k = 0; k < test[i].respuestas.length; k++) {
                  let val = test[i].respuestas[k].validez

                  if(val == 3){

                    let idRR2 = test[i].respuestas[k].idR
                    let respuesta2 = test[i].respuestas[k].respuesta
                    for (var p = 0; p < test[i].respuestas.length; p++) {
                      let val2 = test[i].respuestas[p].validez
                      if(val2 == 4 || val2 == 5 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17){

                        // hay que sacar la position j!
                        if ( p == 0 ){
                          let letra = 'a)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }

                        } else if ( p == 1) {
                          let letra = 'b)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        } else if ( p == 2){
                          let letra = 'c)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        } else if ( p == 3){
                          let letra = 'd)'

                          let res = respuesta2
                          let er1 = /ººº/
                          let test = er1.test(res);
                          if(test == true){
                            let res3 = res.split('ººº')[0]
                            res3 = res3 +'y '+letra+'. '+res.split('ººº')[1]
                            respuesta2 = res3
                          } else {
                            let res2 = res.split(':')[0]
                            let contenteExtra = res.split(': .')[1]
                            if(contenteExtra == undefined){
                              contenteExtra= ''
                            }
                            res2 = res2 +': '+letra+' ººº ' + contenteExtra
                             respuesta2 = res2
                          }
                        }
                        let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                        test[i].respuestas[position].respuesta = respuesta2
                      }
                    }

                  }

                  if(val == 22){

                    let idRR2 = test[i].respuestas[k].idR
                    let respuesta2 = test[i].respuestas[k].respuesta
                    for (var p = 0; p < test[i].respuestas.length; p++) {
                      let val2 = test[i].respuestas[p].validez
                      if(val2 == 23 || val2 == 24 ||val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17){

                    // hay que sacar la position j!
                    if ( p == 0 ){
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }

                    } else if ( p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    } else if ( p == 2){
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    } else if ( p == 3){
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('ººº')[0]
                        res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if(contenteExtra == undefined){
                          contenteExtra= ''
                        }
                        res2 = res2 +': '+letra+' ººº ' + contenteExtra
                         respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                      }
                    }


                  if(val == 14 || val == 15 || val == 16 || val == 17 || val == 18 || val == 19){

                    // hay que sacar la position j!
                    if ( k == 0 ){
                      let letra = 'a)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 1) {
                      let letra = 'b)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 2){
                      let letra = 'c)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    } else if ( k == 3){
                      let letra = 'd)'

                      let res = respuesta
                      let er1 = /Es correcta la ,/
                      let test = er1.test(res);
                      if(test == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                      let er2 = /Es incorrecta la ,/
                      let test2 = er2.test(res);
                      if(test2 == true){
                        let res3 = res.split('la ,')[0]
                        res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                        respuesta = res3
                      }
                    }
                    let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                    test[i].respuestas[position].respuesta = respuesta
                  }
                }
                // FIN  Ruv 2
              }
            } else if(test[i].tipoPregunta == 5 && (resToChange.validez == 20)){
              // MUV
              let idRR = test[i].respuestas[j].idR
              let respuesta = test[i].respuestas[j].respuesta
              for (var k = 0; k < test[i].respuestas.length; k++) {
                let val = test[i].respuestas[k].validez

                if(val == 6 || val == 7) {

                  // RUV 1
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }

                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                  // FIN  Ruv 1
                } else if (val == 12 || val == 13){
                  // RUV 2
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if(val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19){

                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                }
                if(val == 21 || val == 10 || val == 11 || val == 16 || val == 17){

                  // hay que sacar la position j!
                  if ( k == 0 ){
                    let letra = 'a)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }

                  } else if ( k == 1) {
                    let letra = 'b)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 2){
                    let letra = 'c)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 3){
                    let letra = 'd)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  }
                  let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                  test[i].respuestas[position].respuesta = respuesta
                }
              }

              // Fin MUV
            } else if(test[i].tipoPregunta == 5 && (resToChange.validez == 22)){
              // MUVF
              let idRR = test[i].respuestas[j].idR
              let respuesta = test[i].respuestas[j].respuesta
              for (var k = 0; k < test[i].respuestas.length; k++) {
                let val = test[i].respuestas[k].validez
                if(val == 6 || val == 7) {

                  // RUV 1
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }

                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                  // FIN  Ruv 1
                } else if (val == 12 || val == 13){
                  // RUV 2
                  let idRR2 = test[i].respuestas[k].idR
                  let respuesta2 = test[i].respuestas[k].respuesta
                  for (var p = 0; p < test[i].respuestas.length; p++) {
                    let val2 = test[i].respuestas[p].validez

                    if(val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19){

                      // hay que sacar la position j!
                      if ( p == 0 ){
                        let letra = 'a)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 1) {
                        let letra = 'b)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 2){
                        let letra = 'c)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      } else if ( p == 3){
                        let letra = 'd)'

                        let res = respuesta2
                        let er1 = /Es correcta la ,/
                        let test = er1.test(res);
                        if(test == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                        let er2 = /Es incorrecta la ,/
                        let test2 = er2.test(res);
                        if(test2 == true){
                          let res3 = res.split('la ,')[0]
                          res3 = res3 +'la '+letra+','+res.split('la ,')[1]
                          respuesta2 = res3
                        }
                      }
                      let position = test[i].respuestas.findIndex(r=> r.idR == idRR2)
                      test[i].respuestas[position].respuesta = respuesta2
                    }
                  }
                }
                if(val == 23 || val == 24 ||val == 10 || val == 11 || val == 16 || val == 17){

                  // hay que sacar la position j!
                  if ( k == 0 ){
                    let letra = 'a)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }

                  } else if ( k == 1) {
                    let letra = 'b)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 2){
                    let letra = 'c)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  } else if ( k == 3){
                    let letra = 'd)'

                    let res = respuesta
                    let er1 = /ººº/
                    let test = er1.test(res);
                    if(test == true){
                      let res3 = res.split('ººº')[0]
                      res3 = res3 +'o '+letra+'. '+res.split('ººº')[1]
                      respuesta = res3
                    } else {
                      let res2 = res.split(':')[0]
                      let contenteExtra = res.split(': .')[1]
                      if(contenteExtra == undefined){
                        contenteExtra= ''
                      }
                      res2 = res2 +': '+letra+' ººº ' + contenteExtra
                       respuesta = res2
                    }
                  }
                  let position = test[i].respuestas.findIndex(r=> r.idR == idRR)
                  test[i].respuestas[position].respuesta = respuesta
                }
              }

              // Fin MUV
            }


      }
    }

  }
  return await test
}

export async function selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema){
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.
  let testConPreguntas = []
  let data = { idTest: idTest }
  try {

    //selectTestCompletoCP
    //selectPregPorTestCP
    const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompletoCP", {
      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      const json1 = await response1.json();
      const cls1 = await json1;
        for  (var j = 0; j < cls1.length; j++) {

          let articuloRevisado = ''
          if(cls1[j].articulo.split('.y.') != undefined && cls1[j].articulo.split('.y.').length > 0){
            let artDFG= cls1[j].articulo.split('.y.')
            for (var k = 0; k < artDFG.length; k++) {
              if(k != artDFG.length-1 && k != artDFG.length-2){
                articuloRevisado = articuloRevisado + artDFG[k] + ' , '
              } else if(k == artDFG.length-2){
                articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
              } else if(k == artDFG.length-1){
                articuloRevisado = articuloRevisado + artDFG[k]
              }
            }
          } else {
            articuloRevisado = cls1[j].articulo
          }

          testConPreguntas.push({
            idClase: idClase != undefined ? (idClase) : (0),
            idTema: idTema != undefined ? (idTema):(null),
            idTest: idTest,
            idPrg: idPrg,
            idBloque: idBloque == 0 ? (0) : (idBloque),
            numBloque: numBloque,
            numTestBloque: numTestBloque,
            idPregunta: cls1[j].idPregunta,
            pregunta: cls1[j].pregunta,
            numPreg: j+1,
            tipoPregunta: cls1[j].tipoPregunta ,
            idLey: cls1[j].idLey,
            siglasLey: cls1[j].siglasLey,
            nombreLey: cls1[j].nombreLey,
            articulo: articuloRevisado ,
            idR1: cls1[j].idR1,
            idR2: cls1[j].idR2,
            idR3: cls1[j].idR3,
            idR4: cls1[j].idR4,
            puntuacion: 0,
            dudosa: 0,
            respuestas: [{
              idR: cls1[j].idR1,
              // respuesta: cls1[j].R1,
              respuesta: cls1[j].R1.trim().endsWith('.') == false ? (cls1[j].R1.trim().concat('.')) : (cls1[j].R1.trim()),
              validez: cls1[j].validezR1,
              subrayado: cls1[j].subrayado1,
              nota: cls1[j].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR2,
              // respuesta: cls1[j].R2,
              respuesta: cls1[j].R2.trim().endsWith('.') == false ? (cls1[j].R2.trim().concat('.')) : (cls1[j].R2.trim()),
              validez: cls1[j].validezR2,
              subrayado: cls1[j].subrayado2,
              nota: cls1[j].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR3,
              // respuesta: cls1[j].R3,
              respuesta: cls1[j].R3.trim().endsWith('.') == false ? (cls1[j].R3.trim().concat('.')) : (cls1[j].R3.trim()),
              validez: cls1[j].validezR3,
              subrayado: cls1[j].subrayado3,
              nota: cls1[j].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[j].idR4,
              // respuesta: cls1[j].R4,
              respuesta: cls1[j].R4.trim().endsWith('.') == false ? (cls1[j].R4.trim().concat('.')) : (cls1[j].R4.trim()),
              validez: cls1[j].validezR4,
              subrayado: cls1[j].subrayado4,
              nota: cls1[j].nota4,
              valorUsuario: 0
            }]
          })
        }
      return testConPreguntas;

  } catch(error) {
    console.log(error);
    return error;
  }

}

export async function respuestasTestIdCP(testConPreguntas){
  let testConRespuestas = testConPreguntas
  let data = { preguntas: testConRespuestas }
  try {
  const response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/respuestasCasoPractico", {
      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    const json2 = await response2.json();
    const cls2 = await json2;

    for (var j = 0; j < cls2.length/4; j++) {
      let indexPreg = await testConRespuestas.findIndex(p=> p.idPregunta == cls2[j*4].idP)
        let clsRes1 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR1 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes2 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR2 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes3 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR3 && p.idP == testConRespuestas[indexPreg].idPregunta)
        let clsRes4 = cls2.findIndex(p=> p.idR == testConRespuestas[indexPreg].idR4 && p.idP == testConRespuestas[indexPreg].idPregunta)
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes1].idR,
            // respuesta: cls2[clsRes1].r,
            respuesta: cls2[clsRes1].r.trim().endsWith('.') == false ? (cls2[clsRes1].r.trim().concat('.')) : (cls2[clsRes1].r.trim()),
            validez: cls2[clsRes1].validez,
            subrayado: cls2[clsRes1].subrayado,
            nota: cls2[clsRes1].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes2].idR,
            // respuesta: cls2[clsRes2].r,
            respuesta: cls2[clsRes2].r.trim().endsWith('.') == false ? (cls2[clsRes2].r.trim().concat('.')) : (cls2[clsRes2].r.trim()),
            validez: cls2[clsRes2].validez,
            subrayado: cls2[clsRes2].subrayado,
            nota: cls2[clsRes2].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes3].idR,
            // respuesta: cls2[clsRes3].r,
            respuesta: cls2[clsRes3].r.trim().endsWith('.') == false ? (cls2[clsRes3].r.trim().concat('.')) : (cls2[clsRes3].r.trim()),
            validez: cls2[clsRes3].validez,
            subrayado: cls2[clsRes3].subrayado,
            nota: cls2[clsRes3].nota,
            valorUsuario: 0
          })
          testConRespuestas[indexPreg].respuestas.push({
            idR: cls2[clsRes4].idR,
            // respuesta: cls2[clsRes4].r,
            respuesta: cls2[clsRes4].r.trim().endsWith('.') == false ? (cls2[clsRes4].r.trim().concat('.')) : (cls2[clsRes4].r.trim()),
            validez: cls2[clsRes4].validez,
            subrayado: cls2[clsRes4].subrayado,
            nota: cls2[clsRes4].nota,
            valorUsuario: 0
          })

    }
    return testConRespuestas
  } catch(error) {
    console.log(error);
    return error;
  }
}

export async function selectPreguntasPorArticulosInter(idLey, artInicio, artFin) {
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.

  let preguntasBusqueda = []

  let data = {
    idLey: idLey,
    artInicio: artInicio,
    artFin: artFin
  }

  let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectPregPorArticulosInter", {
    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
  });
  if (!response1.ok) {
    throw Error(response1.statusText);
  }
  let json1 = await response1.json();
  let cls1 = await json1;
 
  for (var j = 0; j < cls1.length; j++) {
    preguntasBusqueda.push({
      idPregunta: cls1[j].id_pregunta,
      pregunta: cls1[j].pregunta,
      tipoPregunta: cls1[j].tipo_respuesta,
      idLey: cls1[j].id_ley,
      siglasLey: cls1[j].siglas_ley,
      nombreLey: cls1[j].nombre_ley,
      articulo: cls1[j].articulo,
      respuestas: [],
      numPreg: preguntasBusqueda.length + 1,
      incluida: false,
      excluida: false
    })
  }


  return preguntasBusqueda
}

export async function selectPreguntasPorArticuloUnico(idLey, artUnico, len) {
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.

  let preguntasBusqueda = []

  let data = {
    idLey: idLey,
    art: artUnico,
    puntosLenght: len
  }

  let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectPregPorArticuloUnico", {
    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
  });
  if (!response1.ok) {
    throw Error(response1.statusText);
  }
  let json1 = await response1.json();
  let cls1 = await json1;

  for (var j = 0; j < cls1.length; j++) {
    preguntasBusqueda.push({
      idPregunta: cls1[j].id_pregunta,
      pregunta: cls1[j].pregunta,
      tipoPregunta: cls1[j].tipo_respuesta,
      idLey: cls1[j].id_ley,
      siglasLey: cls1[j].siglas_ley,
      nombreLey: cls1[j].nombre_ley,
      articulo: cls1[j].articulo,
      respuestas: [],
      numPreg: await preguntasBusqueda.length + 1,
      incluida: false,
      excluida: false
    })
  }

  return preguntasBusqueda
}




export async function selectPreguntasPorArticulos(interArt) {
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.

  let preguntasBusqueda = []
  for (var i = 0; i < interArt.length; i++) {
    let data = {
      interArt: interArt[i]
    }

    fetch("https://oposiciones-justicia.es/api/preparador/selectPregPorArticulos", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status >= 400) { throw new Error("Bad response from server"); }
      return response.json();
    }).then(cls => {
      for (var j = 0; j < cls.length; j++) {
        preguntasBusqueda.push({
          idPregunta: cls[j].idPregunta,
          pregunta: cls[j].pregunta,
          tipoPregunta: cls[j].tipoPregunta,
          idLey: cls[j].idLey,
          articulo: cls[j].articulo,
          respuestas: [],
          numPreg: preguntasBusqueda.length + 1
        })
      }
    }).catch(error => console.log('Object fecth failed', error))

  }

  return preguntasBusqueda

}

export async function respuestasPregJusticia(preguntasBuscadas) {
  let preguntasBusqueda = await preguntasBuscadas
  let preguntasConRespuesta = []

  let data = {
    preguntas: preguntasBusqueda
  }
  let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/selectRespuestasPregJusticia", {
    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
  });
  if (!response1.ok) {
    throw Error(response1.statusText);
  }
  let json1 = await response1.json();
  let cls1 = await json1;

  if (cls1 != undefined && cls1.length != 0) {
   
    for (let i = 0; i < await preguntasBusqueda.length; i++) {
      let respuestasPregunta = await cls1.filter(p => p.idP == preguntasBusqueda[i].idPregunta)
      
      preguntasConRespuesta.push(preguntasBusqueda[i])

      if (preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.length > 0) {

        preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.length = 0
      }

      await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
        idR: respuestasPregunta[0].idR,
        // r: respuestasPregunta[0].r,
        r: respuestasPregunta[0].r.trim().endsWith('.') == false ? (respuestasPregunta[0].r.trim().concat('.')) : (respuestasPregunta[0].r.trim()),
        v: respuestasPregunta[0].validez,
        nota: respuestasPregunta[0].nota,
        subrayado: respuestasPregunta[0].subrayado
      })
      await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
        idR: respuestasPregunta[1].idR,
        // r: respuestasPregunta[1].r,
        r: respuestasPregunta[1].r.trim().endsWith('.') == false ? (respuestasPregunta[1].r.trim().concat('.')) : (respuestasPregunta[1].r.trim()),
        v: respuestasPregunta[1].validez,
        nota: respuestasPregunta[1].nota,
        subrayado: respuestasPregunta[1].subrayado
      })
      await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
        idR: respuestasPregunta[2].idR,
        // r: respuestasPregunta[2].r,
        r: respuestasPregunta[2].r.trim().endsWith('.') == false ? (respuestasPregunta[2].r.trim().concat('.')) : (respuestasPregunta[2].r.trim()),
        v: respuestasPregunta[2].validez,
        nota: respuestasPregunta[2].nota,
        subrayado: respuestasPregunta[2].subrayado
      })
      await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
        idR: respuestasPregunta[3].idR,
        // r: respuestasPregunta[3].r,
        r: respuestasPregunta[3].r.trim().endsWith('.') == false ? (respuestasPregunta[3].r.trim().concat('.')) : (respuestasPregunta[3].r.trim()),
        v: respuestasPregunta[3].validez,
        nota: respuestasPregunta[3].nota,
        subrayado: respuestasPregunta[3].subrayado
      })


    }



    // for (var i = 0; i < cls1.length / 4; i++) {
    //   let preguntas = preguntasBuscadas.filter(preg => preg.idPregunta == cls1[i * 4].idP)

    //   let preguntas = preguntasBuscadas.filter(preg => preg.idPregunta == cls1[i * 4].idP)
    //   if (preguntasConRespuesta.filter(p => p.idPregunta == cls1[i * 4].idP).length == 0) {
    //     preguntasConRespuesta.push(preguntas[0])
    //     if (preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.length > 0) {

    //       preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.length = 0
    //     }
    //     await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
    //       idR: cls1[(i * 4)].idR,
    //       r: cls1[(i * 4)].r,
    //       v: cls1[(i * 4)].validez,
    //       nota: cls1[(i * 4)].nota,
    //       subrayado: cls1[(i * 4)].subrayado
    //     })
    //     await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
    //       idR: cls1[(i * 4) + 1].idR,
    //       r: cls1[(i * 4) + 1].r,
    //       v: cls1[(i * 4) + 1].validez,
    //       nota: cls1[(i * 4) + 1].nota,
    //       subrayado: cls1[(i * 4) + 1].subrayado
    //     })
    //     await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
    //       idR: cls1[(i * 4) + 2].idR,
    //       r: cls1[(i * 4) + 2].r,
    //       v: cls1[(i * 4) + 2].validez,
    //       nota: cls1[(i * 4) + 2].nota,
    //       subrayado: cls1[(i * 4) + 2].subrayado
    //     })
    //     await preguntasConRespuesta[preguntasConRespuesta.length - 1].respuestas.push({
    //       idR: cls1[(i * 4) + 3].idR,
    //       r: cls1[(i * 4) + 3].r,
    //       v: cls1[(i * 4) + 3].validez,
    //       nota: cls1[(i * 4) + 3].nota,
    //       subrayado: cls1[(i * 4) + 3].subrayado
    //     })


    // }

    // }
  }

  return preguntasConRespuesta

}

export async function mezclarRespuestasJusticia(preguntasBuscadas) {

  for (var i = 0; i < await preguntasBuscadas.length; i++) {
    let orderList = []
    for (var j = 0; j < 4; j++) {
      orderList.push(await preguntasBuscadas[i].respuestas[j].idR)
    }

    let listaRandomIdPreg = orderList.sort(function () { return Math.random() - 0.5 });
    listaRandomIdPreg = listaRandomIdPreg.sort(function () { return Math.random() - 0.5 });

    // mezclamos aqui
    let savePreg = []
    for (var k = 0; k < listaRandomIdPreg.length; k++) {
      savePreg.push({
        idR: await preguntasBuscadas[i].respuestas.filter(r => r.idR == listaRandomIdPreg[k])[0].idR,
        r: await preguntasBuscadas[i].respuestas.filter(r => r.idR == listaRandomIdPreg[k])[0].r,
        v: await preguntasBuscadas[i].respuestas.filter(r => r.idR == listaRandomIdPreg[k])[0].v,
        nota: await preguntasBuscadas[i].respuestas.filter(r => r.idR == listaRandomIdPreg[k])[0].nota,
        subrayado: await preguntasBuscadas[i].respuestas.filter(r => r.idR == listaRandomIdPreg[k])[0].subrayado
      })

    }
    preguntasBuscadas[i].respuestas = savePreg;
  }
  return preguntasBuscadas
}

export async function generarTestTemplate(preguntasBuscadas) {

  let data = {
    idOposicion: preguntasBuscadas.oposicion,
    nombreTest: preguntasBuscadas.nombreDelTest
  }

  fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateJustica", {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status >= 400) { throw new Error("Bad response from server"); }
    return response.json();
  }).then(cls => {
    let data2 = { idTest: cls.insertId, preguntas: preguntasBuscadas };

    return fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticiaPre", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data2)
    });
  }).then(response => {
    if (response.status >= 400) { throw new Error("Bad response from server"); }
    return response.json();
  }).then(cls => {
    swal("Test Template: " + preguntasBuscadas.nombreDelTest + " creado correctamente.", "", "success")
    window.location.reload();
    setTimeout(
      function () { // Insertamos Template Test
        window.location.reload();
      }.bind(this), 2500);
  }).catch(error => console.log('Object fecth failed', error))

}

export async function generarTestTemplateMultiple(preguntasBuscadas, infoGenTest) {

  for (let i = 0; i < infoGenTest.oposiciones.length; i++) {
    let data = {
      idOposicion: infoGenTest.oposiciones[i].id,
      nombreTest: infoGenTest.nombreTest
    }
    let response1 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateJustica", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
    });
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    if(cls1 != undefined){
      let data2 = { idTest: cls1.insertId, preguntas: preguntasBuscadas };
      let response2 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticiaPre", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      
  
    }

  }
  
}

export async function comprobarRespuestas(test) {
  if (test != undefined) {
    for (var i = 0; i < test.length; i++) {
      for (var j = 0; j < test[i].respuestas.length; j++) {
        let resToChange = test[i].respuestas[j];
        if (test[i].tipoPregunta == 2 && resToChange.validez == 1) {
          // Multiple CORRECTA
          let idRR = test[i].respuestas[j].idR // Respuesta valida 280
          let respuesta = test[i].respuestas[j].respuesta
          for (var k = 0; k < test[i].respuestas.length; k++) {
            let val = test[i].respuestas[k].validez

            if (val == 6 || val == 7) {

              // RUV 1
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }

                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
              // FIN  Ruv 1
            } else if (val == 12 || val == 13) {
              // RUV 2
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19) {

                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
            }

            if (val == 2 || val == 10 || val == 11 || val == 16 || val == 17) {
              // hay que sacar la position j!
              if (k == 0) {

                let letra = 'a)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 1) {
                let letra = 'b)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 2) {
                let letra = 'c)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 3) {
                let letra = 'd)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              }
              let position = test[i].respuestas.findIndex(r => r.idR == idRR)
              test[i].respuestas[position].respuesta = respuesta
            }
          }
          // Fin Multiple CORRECTA
        } else if (test[i].tipoPregunta == 3 && resToChange.validez == 3) {
          // Multiple Incorrecta
          let idRR = test[i].respuestas[j].idR
          let respuesta = test[i].respuestas[j].respuesta
          for (var k = 0; k < test[i].respuestas.length; k++) {
            let val = test[i].respuestas[k].validez
            if (val == 6 || val == 7) {

              // RUV 1
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }

                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
              // FIN  Ruv 1
            } else if (val == 12 || val == 13) {
              // RUV 2
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19) {

                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
            }
            if (val == 4 || val == 5 || val == 10 || val == 11 || val == 16 || val == 17) {

              // hay que sacar la position j!
              if (k == 0) {
                let letra = 'a)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }

              } else if (k == 1) {
                let letra = 'b)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 2) {
                let letra = 'c)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 3) {
                let letra = 'd)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              }
              let position = test[i].respuestas.findIndex(r => r.idR == idRR)
              test[i].respuestas[position].respuesta = respuesta
            }
          }

          // Fin Multiple Incorrecta
        } else if (test[i].tipoPregunta == 4 && (resToChange.validez == 6 || resToChange.validez == 7)) {

          if (resToChange.validez == 6 || resToChange.validez == 7) {
            // RUV 1
            let idRR = test[i].respuestas[j].idR
            let respuesta = test[i].respuestas[j].respuesta
            for (var k = 0; k < test[i].respuestas.length; k++) {
              let val = test[i].respuestas[k].validez


              if (val == 3) {

                let idRR2 = test[i].respuestas[k].idR
                let respuesta2 = test[i].respuestas[k].respuesta
                for (var p = 0; p < test[i].respuestas.length; p++) {
                  let val2 = test[i].respuestas[p].validez
                  if (val2 == 4 || val2 == 5 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17) {

                    // hay que sacar la position j!
                    if (p == 0) {
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }

                    } else if (p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 2) {
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 3) {
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                }

              }

              if (val == 22) {

                let idRR2 = test[i].respuestas[k].idR
                let respuesta2 = test[i].respuestas[k].respuesta
                for (var p = 0; p < test[i].respuestas.length; p++) {
                  let val2 = test[i].respuestas[p].validez
                  if (val2 == 23 || val2 == 24 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17) {

                    // hay que sacar la position j!
                    if (p == 0) {
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }

                    } else if (p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 2) {
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 3) {
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                }
              }

              if (val == 8 || val == 9 || val == 10 || val == 11 || val == 18 || val == 19) {

                // hay que sacar la position j!
                if (k == 0) {
                  let letra = 'a)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 1) {
                  let letra = 'b)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 2) {
                  let letra = 'c)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 3) {
                  let letra = 'd)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                }
                let position = test[i].respuestas.findIndex(r => r.idR == idRR)
                test[i].respuestas[position].respuesta = respuesta
              }
            }
            // FIN  Ruv 1
          }

        } else if (test[i].tipoPregunta == 4 && (resToChange.validez == 12 || resToChange.validez == 13)) {

          if (resToChange.validez == 12 || resToChange.validez == 13) {
            // RUV 2
            let idRR = resToChange.idR
            let respuesta = resToChange.respuesta
            for (var k = 0; k < test[i].respuestas.length; k++) {
              let val = test[i].respuestas[k].validez

              if (val == 3) {

                let idRR2 = test[i].respuestas[k].idR
                let respuesta2 = test[i].respuestas[k].respuesta
                for (var p = 0; p < test[i].respuestas.length; p++) {
                  let val2 = test[i].respuestas[p].validez
                  if (val2 == 4 || val2 == 5 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17) {

                    // hay que sacar la position j!
                    if (p == 0) {
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }

                    } else if (p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 2) {
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 3) {
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'y ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r => r.idR == idRR)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                }

              }

              if (val == 22) {

                let idRR2 = test[i].respuestas[k].idR
                let respuesta2 = test[i].respuestas[k].respuesta
                for (var p = 0; p < test[i].respuestas.length; p++) {
                  let val2 = test[i].respuestas[p].validez
                  if (val2 == 23 || val2 == 24 || val2 == 10 || val2 == 11 || val2 == 16 || val2 == 17) {

                    // hay que sacar la position j!
                    if (p == 0) {
                      let letra = 'a)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }

                    } else if (p == 1) {
                      let letra = 'b)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 2) {
                      let letra = 'c)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    } else if (p == 3) {
                      let letra = 'd)'

                      let res = respuesta2
                      let er1 = /ººº/
                      let test = er1.test(res);
                      if (test == true) {
                        let res3 = res.split('ººº')[0]
                        res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                        respuesta2 = res3
                      } else {
                        let res2 = res.split(':')[0]
                        let contenteExtra = res.split(': .')[1]
                        if (contenteExtra == undefined) {
                          contenteExtra = ''
                        }
                        res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                        respuesta2 = res2
                      }
                    }
                    let position = test[i].respuestas.findIndex(r => r.idR == idRR)
                    test[i].respuestas[position].respuesta = respuesta2
                  }
                }
              }


              if (val == 14 || val == 15 || val == 16 || val == 17 || val == 18 || val == 19) {

                // hay que sacar la position j!
                if (k == 0) {
                  let letra = 'a)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 1) {
                  let letra = 'b)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 2) {
                  let letra = 'c)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                } else if (k == 3) {
                  let letra = 'd)'

                  let res = respuesta
                  let er1 = /Es correcta la ,/
                  let test = er1.test(res);
                  if (test == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                  let er2 = /Es incorrecta la ,/
                  let test2 = er2.test(res);
                  if (test2 == true) {
                    let res3 = res.split('la ,')[0]
                    res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                    respuesta = res3
                  }
                }
                let position = test[i].respuestas.findIndex(r => r.idR == idRR)
                test[i].respuestas[position].respuesta = respuesta
              }
            }
            // FIN  Ruv 2
          }
        } else if (test[i].tipoPregunta == 5 && (resToChange.validez == 20)) {
          // MUV
          let idRR = test[i].respuestas[j].idR
          let respuesta = test[i].respuestas[j].respuesta
          for (var k = 0; k < test[i].respuestas.length; k++) {
            let val = test[i].respuestas[k].validez

            if (val == 6 || val == 7) {

              // RUV 1
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }

                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
              // FIN  Ruv 1
            } else if (val == 12 || val == 13) {
              // RUV 2
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19) {

                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
            }
            if (val == 21 || val == 10 || val == 11 || val == 16 || val == 17) {

              // hay que sacar la position j!
              if (k == 0) {
                let letra = 'a)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }

              } else if (k == 1) {
                let letra = 'b)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 2) {
                let letra = 'c)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 3) {
                let letra = 'd)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              }
              let position = test[i].respuestas.findIndex(r => r.idR == idRR)
              test[i].respuestas[position].respuesta = respuesta
            }
          }

          // Fin MUV
        } else if (test[i].tipoPregunta == 5 && (resToChange.validez == 22)) {
          // MUVF
          let idRR = test[i].respuestas[j].idR
          let respuesta = test[i].respuestas[j].respuesta
          for (var k = 0; k < test[i].respuestas.length; k++) {
            let val = test[i].respuestas[k].validez
            if (val == 6 || val == 7) {

              // RUV 1
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 10 || val2 == 11 || val2 == 18 || val2 == 19) {
                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }

                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
              // FIN  Ruv 1
            } else if (val == 12 || val == 13) {
              // RUV 2
              let idRR2 = test[i].respuestas[k].idR
              let respuesta2 = test[i].respuestas[k].respuesta
              for (var p = 0; p < test[i].respuestas.length; p++) {
                let val2 = test[i].respuestas[p].validez

                if (val2 == 14 || val2 == 15 || val2 == 16 || val2 == 17 || val2 == 18 || val2 == 19) {

                  // hay que sacar la position j!
                  if (p == 0) {
                    let letra = 'a)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 1) {
                    let letra = 'b)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 2) {
                    let letra = 'c)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  } else if (p == 3) {
                    let letra = 'd)'

                    let res = respuesta2
                    let er1 = /Es correcta la ,/
                    let test = er1.test(res);
                    if (test == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                    let er2 = /Es incorrecta la ,/
                    let test2 = er2.test(res);
                    if (test2 == true) {
                      let res3 = res.split('la ,')[0]
                      res3 = res3 + 'la ' + letra + ',' + res.split('la ,')[1]
                      respuesta2 = res3
                    }
                  }
                  let position = test[i].respuestas.findIndex(r => r.idR == idRR2)
                  test[i].respuestas[position].respuesta = respuesta2
                }
              }
            }
            if (val == 23 || val == 24 || val == 10 || val == 11 || val == 16 || val == 17) {

              // hay que sacar la position j!
              if (k == 0) {
                let letra = 'a)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }

              } else if (k == 1) {
                let letra = 'b)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 2) {
                let letra = 'c)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              } else if (k == 3) {
                let letra = 'd)'

                let res = respuesta
                let er1 = /ººº/
                let test = er1.test(res);
                if (test == true) {
                  let res3 = res.split('ººº')[0]
                  res3 = res3 + 'o ' + letra + '. ' + res.split('ººº')[1]
                  respuesta = res3
                } else {
                  let res2 = res.split(':')[0]
                  let contenteExtra = res.split(': .')[1]
                  if (contenteExtra == undefined) {
                    contenteExtra = ''
                  }
                  res2 = res2 + ': ' + letra + ' ººº ' + contenteExtra
                  respuesta = res2
                }
              }
              let position = test[i].respuestas.findIndex(r => r.idR == idRR)
              test[i].respuestas[position].respuesta = respuesta
            }
          }

          // Fin MUV
        }


      }
    }

  }
  return test
}


export async function prepararTestModoHacer(testGenerado, idClase) {
  // hacer un fetch que busque un numero de preguntas, entre en intervalos de art de la ley.

  try {
    let testConPreguntas = []
    let cls1 = await testGenerado;
    for (var j = 0; j < cls1.length; j++) {
      let articuloRevisado = ''
      if (cls1[j].articulo.split('.y.') != undefined && cls1[j].articulo.split('.y.').length > 0) {
        let artDFG = cls1[j].articulo.split('.y.')
        for (var k = 0; k < artDFG.length; k++) {
          if (k != artDFG.length - 1 && k != artDFG.length - 2) {
            articuloRevisado = articuloRevisado + artDFG[k] + ' , '
          } else if (k == artDFG.length - 2) {
            articuloRevisado = articuloRevisado + artDFG[k] + ' y '
          } else if (k == artDFG.length - 1) {
            articuloRevisado = articuloRevisado + artDFG[k]
          }
        }
      } else {
        articuloRevisado = cls1[j].articulo
      }


      testConPreguntas.push({
        idClase: await idClase,
        idTest: null,
        idPrg: null,
        idBloque: null,
        numBloque: null,
        numTestBloque: null,
        idPregunta: cls1[j].idPregunta,
        pregunta: cls1[j].pregunta,
        numPreg: j + 1,
        tipoPregunta: cls1[j].tipoPregunta,
        idLey: cls1[j].idLey,
        siglasLey: cls1[j].siglasLey,
        nombreLey: cls1[j].nombreLey,
        articulo: articuloRevisado,
        idR1: cls1[j].respuestas[0].idR,
        idR2: cls1[j].respuestas[1].idR,
        idR3: cls1[j].respuestas[2].idR,
        idR4: cls1[j].respuestas[3].idR,
        puntuacion: 0,
        dudosa: 0,
        respuestas: [{
          idR: cls1[j].respuestas[0].idR,
          // respuesta: cls1[j].respuestas[0].r,
          respuesta: cls1[j].respuestas[0].r.trim().endsWith('.') == false ? (cls1[j].respuestas[0].r.trim().concat('.')) : (cls1[j].respuestas[0].r.trim()),
          validez: cls1[j].respuestas[0].v,
          subrayado: cls1[j].respuestas[0].subrayado,
          nota: cls1[j].respuestas[0].nota,
          valorUsuario: 0
        },
        {
          idR: cls1[j].respuestas[1].idR,
          // respuesta: cls1[j].respuestas[1].r,
          respuesta: cls1[j].respuestas[1].r.trim().endsWith('.') == false ? (cls1[j].respuestas[1].r.trim().concat('.')) : (cls1[j].respuestas[1].r.trim()),
          validez: cls1[j].respuestas[1].v,
          subrayado: cls1[j].respuestas[1].subrayado,
          nota: cls1[j].respuestas[1].nota,
          valorUsuario: 0
        },
        {
          idR: cls1[j].respuestas[2].idR,
          // respuesta: cls1[j].respuestas[2].r,
          respuesta: cls1[j].respuestas[2].r.trim().endsWith('.') == false ? (cls1[j].respuestas[2].r.trim().concat('.')) : (cls1[j].respuestas[2].r.trim()),
          validez: cls1[j].respuestas[2].v,
          subrayado: cls1[j].respuestas[2].subrayado,
          nota: cls1[j].respuestas[2].nota,
          valorUsuario: 0
        },
        {
          idR: cls1[j].respuestas[3].idR,
          // respuesta: cls1[j].respuestas[3].r,
          respuesta: cls1[j].respuestas[3].r.trim().endsWith('.') == false ? (cls1[j].respuestas[3].r.trim().concat('.')) : (cls1[j].respuestas[3].r.trim()),
          validez: cls1[j].respuestas[3].v,
          subrayado: cls1[j].respuestas[3].subrayado,
          nota: cls1[j].respuestas[3].nota,
          valorUsuario: 0
        }]
      })
    }
    return testConPreguntas;

  } catch (error) {
    console.log(error);
    return error;
  }

}
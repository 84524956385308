// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// Assets
import './css/css.css';
import Input2 from '../Inputs/Input2';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadClaseCurso, filtrarClaseCurso } from '../../lib/utils/cargarSemanaClase.js';
import { isDefined } from '../../lib/utils/is';

import { loadTemaAula } from '../../actions/userAction'
import swal from 'sweetalert';

class CajaInfoTema extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		this.state = {};

		this.onHandleClick = this.onHandleClick.bind(this);
	}

	async onHandleClick(e) {
		//cargamos la clase en redux y redirect!
		// swal con timer
		if (isDefined(this.props.temasFacturados) && this.props.temasFacturados.length > 0) {
			// swal({
			// 	title: this.props.temaAulaLoad != null && this.props.temaAulaLoad == this.props.temaAulaLoad ? ('Recargando tema') : ('Cargando tema'),
			// 	text: 'Se abrirá el contenido del tema tras su carga.',
			// 	icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
			// 	buttons: false,
			// 	closeOnEsc: false,
			// 	closeOnClickOutside: false,
			// })

			// console.log(this.props.tema)
			await this.props.loadTemaAula(this.props.tema.idTema)

			// if (window.location.href != 'https://oposiciones-justicia.es/aulaVirtual' || window.location.href != 'https://www.oposiciones-justicia.es/aulaVirtual') {
			// 	window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/aulaVirtual') : ('https://oposiciones-justicia.es/aulaVirtual')
			// } else {
			// 	window.location.reload()
			// }
			
		} else {
			swal('Cargando contenido esencial', 'Vuelva a probar en unos segundos.', 'error')
		}


	}

	async componentDidMount() {
		fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
		.then(res => res.json())
		.then(cls2 => this.setState({
		  categorias: cls2
		}))

		let data = { idUser: this.props.idUser }

		let response = await fetch("https://oposiciones-justicia.es/api/usuarios/numeroVueltasTemas", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		let json = await response.json();
		let cls = await json;

		this.setState({ numVueltasTemas: cls })

		// sacar vueltas alumno! this.props.idUser BD --> vueltasTemarioCurso
	}
	render() {
		const { tema, temasFacturados } = this.props;
		const { numVueltasTemas, categorias } = this.state

		return (

			<div onClick={this.onHandleClick} className='celdaClaseLE' style={{margin: '15px'}}>

				<div className='divClaseload' title={'Todo el contenido del tema disponible.'}>
					<div className='textoDivCM'>
						<b >{temasFacturados.find(t=>t.idTema == tema.idTema).titulo_tema}</b><br />

						<b>Nombre</b><br />
						<p>{temasFacturados.find(t=>t.idTema == tema.idTema).nombre_tema}</p><br />

						<b>Categoría</b><br />
						<p>{categorias && categorias.find(c=> c.id_categoria == temasFacturados.find(t=>t.idTema == tema.idTema).categoria).nombre}</p>
					</div>
					<div className='semanaFechaDivCM'>
						<b>Vuelta 
							{numVueltasTemas && numVueltasTemas.length > 0 && numVueltasTemas.find(t => t.id_tema == temasFacturados.find(t=>t.idTema == tema.idTema).idTema) != undefined ? (
								': '+numVueltasTemas.find(t => t.id_tema == temasFacturados.find(t=>t.idTema == tema.idTema).idTema).num_vuelta
							) : (': 1')}
						</b>
					</div>
				</div>

			</div>


		);
	}
}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		temasFacturados: state.user.temasFacturados,
		temaAulaLoad: state.user.temaAulaLoad,
		tipoUser: state.user.tipoUser
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadTemaAula
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CajaInfoTema);

// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';

// Assets
import './css/Escritorio.css';

// Actions
import { loadNoticia, loadCurso, loadClase, loadTemasFacturados } from '../../actions/userAction'

// Components
import CajaInfoClase from '../../components/CajaInfoClase'
import CategoriasBuscador from '../../components/CategoriasBuscador'
import BuscarDeLeyes from '../../components/BuscarDeLeyes'
import EstadisticasUser from '../EstadisticasUser'
import Input2 from '../../components/Inputs/Input2'
import Boton from '../../components/Inputs/Boton'
//import NoticiasBloque from '../../components/NoticiasBloque'


class Escritorio extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      novedades: [],
      novedadesFichadas: [],
      paginaNovedades: 1,
      claseInfoLoad: [{}],
      displayClases: true,
      displaySemanas: false,
      procesoReserva: null
    };
    this.loadNewNovedades = this.loadNewNovedades.bind(this)
    this.onChangeInputNew = this.onChangeInputNew.bind(this)
  }
  async loadNewNovedades(e) {
    if (e.target.id == 'siguiente') {
      try {
        let pag = this.state.paginaNovedades + 1
        if (((pag * 3) - 2) <= this.state.novedades.length) {
          let cls1 = await this.state.novedades
          if ((pag * 3) - 2 <= cls1.length) {
            let novedadesDesde = await cls1.slice(((pag * 3) - 2) - 1, pag * 3)
            await this.setState({ novedadesFichadas: novedadesDesde });
            await this.setState({ paginaNovedades: pag });
          }

        } else {
          swal('No existen más noticias', '', 'info')
        }
      } catch (e) { console.log(e) }
    } else if (e.target.id == 'anterior') {
      try {
        let pag = this.state.paginaNovedades - 1
        if (((pag * 3) - 2) >= 1) {
          let cls1 = await this.state.novedades
          if ((pag * 3) - 2 <= cls1.length) {
            let novedadesDesde = await cls1.slice(((pag * 3) - 2) - 1, pag * 3)
            await this.setState({ novedadesFichadas: novedadesDesde });
            await this.setState({ paginaNovedades: pag });
          }
        } else {
          swal('No se puede retroceder más', '', 'info')
        }
      } catch (e) { console.log(e) }
    }
  }

  async onChangeInputNew(e) {
    var e = e;
    if (e.target.name == 'claseSelect') {
      try {
        // enviamos clasesAlumno "cursos" y this.state.idUser
        let objectClase = this.state.clasesAlumno.find(c => c.diaSemanal == e.target.value)
        // console.log('objectClase')
        // console.log(objectClase)

        if (objectClase != undefined && objectClase.listaEsperaReserva == 0) {
          if (objectClase.claseActivada != 0) {


            let facturas = []
            let semanasFacturadas = []
            let temasFacturados = []

            let data123 = { idUser: this.state.idUser, idClase: objectClase.idClase }
            let response123 = await fetch("https://oposiciones-justicia.es/api/usuarios/temasActivos", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
            });
            if (!response123.ok) {
              throw Error(response123.statusText);
            }
            let json123 = await response123.json();
            let cls123 = await json123;
            await this.setState({ userAccesoTemas: cls123[0].activoTemas });

            let data1 = { idClase: objectClase.idClase, idUser: this.state.idUser }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            for (var i = 0; i < cls1.length; i++) {
              facturas.push({
                idFactura: cls1[i].idFactura,
                fechaPago: cls1[i].fechaPago,
                fechaCaducidad: cls1[i].fechaCaducidad,
                tipo: cls1[i].tipo
              })
            }

            await this.setState({ facturaAlumnoClase: facturas });

            if (facturas[facturas.length - 1].tipo == 10) {

              await this.props.loadCurso(objectClase.idClase, objectClase.diaSemanal, objectClase.id_oposicion, 0)
              await this.props.loadClase(null, false, true)

              swal({
                title: 'Cargando curso',
                text: 'En breve podrá visualizar todo el contenido del curso.',
                icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })

            } else {



              let finFactura = facturas.length - 1

              let fechaC = moment(facturas[finFactura].fechaCaducidad).format('YYYY-MM-DD HH:mm')
              let data2 = { idClase: objectClase.idClase, idUser: this.state.idUser, fechaPago: moment(facturas[finFactura].fechaPago).subtract(7, 'days').format('YYYY-MM-DD HH:mm'), fechaCaducidad: fechaC }

              let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
              if (facturas[finFactura].tipo == 3) {
                urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
              } else {
                urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
              }
              let response2 = await fetch(urlSemClasefAC, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2New = await json2;

              // console.log('cls2New')
              // console.log('cls2New')
              // console.log(cls2New)
              let cls2 = []
              for (let kkt = 0; kkt < cls2New.length; kkt++) {
                let fechaMostra = moment(cls2New[kkt].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
                let ahora = moment().format('YYYY-MM-DD HH:mm')
                if (ahora >= fechaMostra) {
                  cls2.push(cls2New[kkt])
                }
              }


              if (cls2.length > 1) {
                cls2 = cls2.slice(cls2.length - 2)
              } else {
                if (facturas.length > 1) {
                  let finFactura2 = facturas.length - 2

                  let fechaC2 = moment(facturas[finFactura2].fechaCaducidad).format('YYYY-MM-DD HH:mm')
                  let data23los = { idClase: objectClase.idClase, idUser: this.state.idUser, fechaPago: moment(facturas[finFactura2].fechaPago).subtract(7, 'days').format('YYYY-MM-DD HH:mm'), fechaCaducidad: fechaC2 }
                  let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
                  if (facturas[finFactura2].tipo == 3) {
                    urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
                  } else {
                    urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
                  }
                  let response23los = await fetch(urlSemClasefAC, {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data23los)
                  });
                  if (!response23los.ok) {
                    throw Error(response23los.statusText);
                  }
                  let json23los = await response23los.json();
                  let cls23losNew = await json23los;

                  if (cls23losNew.length > 1) {
                    cls23losNew = cls23losNew.slice(cls23losNew.length - 2)
                  }


                  for (let kkt23 = 0; kkt23 < cls23losNew.length; kkt23++) {
                    let fechaMostra2 = moment(cls23losNew[kkt23].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
                    let ahora2 = moment().format('YYYY-MM-DD HH:mm')
                    if (ahora2 >= fechaMostra2 && cls2.findIndex(p => p.idPrg == cls23losNew[kkt23].idPrg && moment(p.fechaClase).format('YYYY-MM-DD HH:mm') == moment(cls23losNew[kkt23].fechaClase).format('YYYY-MM-DD HH:mm')) == -1 && cls2.length < 2) {
                      cls2.push(cls23losNew[kkt23])
                    }


                    // if (ahora2 >= fechaMostra2 && cls2.findIndex(p => p.idPrg == cls23losNew[kkt23].idPrg) == -1 && cls2.length < 2) {
                    //   cls2.push(cls23losNew[kkt23])
                    // }
                  }

                  // if (cls2.length == 1) {
                  //   console.log("solo una clase")
                  // }

                } else {
                  //Solo tenemos una clase desbloqueada
                  let finFactura2 = facturas.length - 1

                  let fechaC2 = moment(facturas[finFactura2].fechaCaducidad).format('YYYY-MM-DD HH:mm')
                  let data23los = { idClase: objectClase.idClase, idUser: this.state.idUser, fechaPago: moment(facturas[finFactura2].fechaPago).subtract(7, 'days').format('YYYY-MM-DD HH:mm'), fechaCaducidad: fechaC2 }
                  let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
                  if (facturas[finFactura2].tipo == 3) {
                    urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
                  } else {
                    urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
                  }
                  let response23los = await fetch(urlSemClasefAC, {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data23los)
                  });
                  if (!response23los.ok) {
                    throw Error(response23los.statusText);
                  }
                  let json23los = await response23los.json();
                  let cls23losNew = await json23los;

                  if (cls23losNew.length > 1) {
                    cls23losNew = cls23losNew.slice(cls23losNew.length - 2)
                  }
                  for (let kkt23 = 0; kkt23 < cls23losNew.length; kkt23++) {
                    let fechaMostra2 = moment(cls23losNew[kkt23].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
                    let ahora2 = moment().format('YYYY-MM-DD HH:mm')

                    if (ahora2 >= fechaMostra2 && cls2.findIndex(p => p.idPrg == cls23losNew[kkt23].idPrg && moment(p.fechaClase).format('YYYY-MM-DD HH:mm') == moment(cls23losNew[kkt23].fechaClase).format('YYYY-MM-DD HH:mm')) == -1 && cls2.length < 2) {
                      cls2.push(cls23losNew[kkt23])
                    }


                  }

                }

              }


              cls2 = cls2.sort((a, b) => new Date(a.fechaClase) - new Date(b.fechaClase))

              for (var f = 0; f < cls2.length; f++) {
                let fechaMostra = moment(cls2[f].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
                let ahora = moment().format('YYYY-MM-DD HH:mm')
                if (ahora >= fechaMostra) {


                  //comprobar que no esta duplicada la semana
                  let idPrgComprobacion = cls2[f].idPrg;
                  let existeIdPrg = semanasFacturadas.findIndex(s => s.idPrg == idPrgComprobacion && moment(s.fechaClase).format('YYYY-MM-DD HH:mm') == moment(cls2[f].fechaClase).format('YYYY-MM-DD HH:mm'));
                  if (existeIdPrg == -1) {
                    semanasFacturadas.push(cls2[f])
                  }
                }

              }

              await this.setState({ semanasFacturadas: semanasFacturadas });
              for (var i = 0; i < semanasFacturadas.length; i++) {
                semanasFacturadas[i].temas = []
              }
              for (var k = 0; k < semanasFacturadas.length; k++) {
                let data3 = { idPrg: semanasFacturadas[k].idPrg }


                let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;

                for (var z = 0; z < cls3.length; z++) {
                  // si son 2 o 3 asignar a todos!


                  let locaBloque = semanasFacturadas.map((elm, idx) => elm.idPrg == cls3[z].idPrg && elm.numClase == semanasFacturadas[k].numClase ? idx : '').filter(String)

                  if (locaBloque.length > 0) {
                    for (let rtx = 0; rtx < locaBloque.length; rtx++) {

                      let val = locaBloque[rtx]
                      if (semanasFacturadas[val].temas.findIndex(t => t.idTema == cls3[z].idTema) == -1) {
                        semanasFacturadas[val].temas.push({
                          idTema: cls3[z].idTema,
                          tituloTema: cls3[z].tituloTema
                        })
                      }

                    }
                  }


                }
              }

              $('.cuerpoEscritorio').css("align-self", "flex-start")

              await this.props.loadCurso(objectClase.idClase, objectClase.diaSemanal, objectClase.id_oposicion, this.state.userAccesoTemas)
              await this.props.loadClase(semanasFacturadas, false, true)

            }
          } else {
            swal('¡Acceso Denegado!', 'No tiene activado el acceso a esta clase.', 'error')
          }
        } else {
          if (objectClase.listaEsperaReserva != 0) {

            if (objectClase.listaEsperaReserva == 2) {
              this.setState({ procesoReserva: 'ReservaPendiente' })
            } else if (objectClase.listaEsperaReserva == 1) {
              this.setState({ procesoReserva: 'ListaEsperaPendiente' })
            } else if (objectClase.listaEsperaReserva == 3) {
              this.setState({ procesoReserva: 'ReservaConfirmada' })
            } else {
              this.setState({ procesoReserva: null })
            }
          } else {
            this.setState({ procesoReserva: null })
          }
          this.props.loadCurso(null, null, null, null)
          this.props.loadClase(null, true, false)
          this.props.loadTemasFacturados(null)
          //TEMAS FACTURADOS
          $('.cuerpoEscritorio').css("align-self", "center")

        }

      } catch (e) { console.log(e) }
    } else if (e.target.id == 'abrirAulaVirtual'){
      window.location.href = 'https://oposiciones-justicia.es/aulaVirtual'
    }
  }

  async componentDidMount() {
    let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
    fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status >= 400) { throw new Error("Bad response from server"); }
      return response.json();
    }).then(cls => {
      this.setState({ idUser: cls[0].id_usuario });
      let data2 = { idUser: cls[0].id_usuario }
      return fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data2)
      });
    }).then(response => {
      if (response.status >= 400) { throw new Error("Bad response from server"); }
      return response.json();
    }).then(data => {
      if (data.length > 0) {
        this.setState({ clasesAlumno: data, displayClases: true })
      } else {
        this.setState({ displayClases: true })
      }

    }).catch(function (err) { });

    /*if(this.state.novedades.length == 0){
      try{

        let pag = 1
        if(this.state.paginaNovedades != undefined){
          pag = this.state.paginaNovedades;
        }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/novedades");
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        if ((pag*3)-2 <= cls1.length){
          //console.log(cls1)
          let novedadesDesde = await cls1.slice(((pag*3)-2)-1, pag*3)
          await this.setState({ novedades: cls1 });
          await this.setState({ novedadesFichadas: novedadesDesde });
          //<NoticiasBloque novedades={novedadesFichadas} />
        }
      } catch(e){console.log(e)}
    }*/


  }



  render() {
    const { isLogged, semanasFacturadas, displaySemanas, displayClases } = this.props;
    const { clasesAlumno, procesoReserva } = this.state

    // console.log('Proceso reserva')
    // console.log(procesoReserva)

    // console.log('clasesAlumno')
    // console.log(clasesAlumno)

    // console.log('displayClases')
    // console.log(displayClases)
    if (isLogged) {
      return (
        procesoReserva == null ? (
          <div className="cuerpoEscritorio">

            {displayClases == true && clasesAlumno && clasesAlumno.length > 0 ? (
              <div className="cE1Estadisticas" style={{ marginTop: '20vh' }}>

                <div className="cE1EstadisticasR2">
                  <div>
                    <b> Bienvenido </b>
                  </div>
                  <div style={{ justifyContent: 'flex-end', flexFlow: 'column wrap' }} className="temarioTitleEscri2">
                    <p style={{ fontSize: '20px', textTransform: 'uppercase' }}>A su espacio personal</p>
                    <div className="botonClaseBodyEscr" >
                      <Input2 type="text" name="claseSelect" placeholder="Seleccionar Curso" icon="fas fa-search" datalist="clasesAlumno" ref="ref_claseSelect" onChange={this.onChangeInputNew} />
                    </div>
                  </div>


                  <div className="temarioTitleEscri2">
                    <p style={{ textTransform: 'unset' }}>Seleccione un curso para generar el temario de estudio.</p>
                  </div>

                </div>

                <div className="divImgTemario2"><img src="../img/Layer2.png" /></div>
                <datalist id="clasesAlumno">
                  {clasesAlumno && clasesAlumno.map((cls, key) =>
                    <option key={key} value={cls.diaSemanal}></option>
                  )}
                </datalist>
              </div>
            ) : null}

            {displayClases == true && clasesAlumno == undefined ? (
              <div className="cE1Estadisticas" style={{ marginTop: '20vh' }}>

                <div className="cE1EstadisticasR2">
                  <div>
                    <b> Bienvenido </b>
                  </div>
                  <div style={{ justifyContent: 'flex-end', flexFlow: 'column wrap' }} className="temarioTitleEscri2">
                    <p style={{ fontSize: '20px', textTransform: 'uppercase' }}>A su espacio personal</p>
                    <div className="botonClaseBodyEscr" >
                      <Boton icon1="" icon2="" texto="Ver cursos" onClick={() => window.open("https://oposiciones-justicia.es/preparacion", "_self")} />
                    </div>
                  </div>


                  <div className="temarioTitleEscri2">
                    <p style={{ textTransform: 'unset' }}>No esta dado de alta en ningun curso, puede darle clic en "Ver cursos" y accederá a los diferentes metodos de preparación.</p>
                  </div>

                </div>

                <div className="divImgTemario2"><img src="../img/Layer2.png" /></div>

              </div>
            ) : null}

            {displaySemanas == true ? (
              <div className="izquierdaCuEscri">
                <div className="buscarClaseEscritorio">

                  {displayClases == false && semanasFacturadas && semanasFacturadas.length > 0 ? (

                    <div className="clasesLoadEscritorio">



                      {semanasFacturadas && semanasFacturadas.map((cls, key) =>

                        <CajaInfoClase semanaFacturada={cls} keyProp={key} actuales={true} />

                      )}
                    </div>


                  ) : null}

                  {/* METER AQUI IR A AULA VIRTUAL Y FECHA FIN FACTURA CADUCADA: con un swal avisar y decir que no se carga el curso boton renovar mensualidad o cerrar*/}
                  {this.props.idClase == 21 || this.props.idClase == 22 || this.props.idClase == 23 || this.props.idClase == 24 ? (

                    <div className="clasesLoadEscritorio">

                      <p className='tipoTextoEscritoriosvs2'>
                      <div style={{ width: '180px', marginTop: '10px' }}>
                        <Boton id="abrirAulaVirtual" icon1="" icon2="" texto="Abrir Aula Virtual" onClick={this.onChangeInputNew} />
                      </div>
                      Desde Aula Virtual puede acceder al contenido y ejercicios de cada tema, para realizar un estudio a su propio ritmo o con recomendación de estudio. </p>
                      

                    </div>


                  ) : null}
                </div>

                <CategoriasBuscador />

              </div>
            ) : null}

            {displaySemanas == true ? (
              <div className="cE1EstadisticasTotal">



                <EstadisticasUser />
                <BuscarDeLeyes />

              </div>
            ) : null}


          </div>
        ) : (
          <div className="cuerpoEscritorio">

            {procesoReserva != null ? (
              <div className="cE1Estadisticas" style={{ marginTop: '0vh' }}>

                <div className="cE1EstadisticasR2">
                  <div>
                    <b> Bienvenido </b>
                  </div>
                  <div style={{ justifyContent: 'flex-end', flexFlow: 'column wrap' }} className="temarioTitleEscri2">
                    <p style={{ fontSize: '20px', textTransform: 'uppercase' }}>A su espacio personal</p>
                    <b style={{ fontSize: '25px', textTransform: 'uppercase' }}>

                      {procesoReserva != null && procesoReserva == 'ReservaPendiente' ? ('Procesando reserva') : null}
                      {procesoReserva != null && procesoReserva == 'ListaEsperaPendiente' ? ('Lista de espera') : null}
                      {procesoReserva != null && procesoReserva == 'ReservaConfirmada' ? ('Reserva confirmada') : null}

                    </b>
                    <div className="botonClaseBodyEscr" >

                    </div>
                  </div>


                  <div className="temarioTitleEscri2">
                    <p style={{ textTransform: 'unset' }}>
                      {procesoReserva != null && procesoReserva == 'ReservaPendiente' ? ('La academia se pondrá en contacto con usted para confirmar su reserva de plaza en los proximos días.  Gracias por confiar en nosotros.') : null}
                      {procesoReserva != null && procesoReserva == 'ListaEsperaPendiente' ? ('La academia se pondrá en contacto con usted cuando tengan una plaza disponible.  Gracias por confiar en nosotros.') : null}
                      {procesoReserva != null && procesoReserva == 'ReservaConfirmada' ? ('El proceso de reserva fue completado, pague su factura para ver el contenido de estudio.  Gracias por confiar en nosotros.') : null}
                    </p>
                  </div>

                </div>

                <div className="divImgTemario2"><img src="../img/Layer2.png" /></div>

              </div>
            ) : null}

          </div>
        )
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    semanasFacturadas: state.user.semanasFacturadas,
    displaySemanas: state.user.displaySemanas,
    displayClases: state.user.displayClases,
    idClase: state.user.idClase
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadNoticia,
    loadCurso,
    loadClase,
    loadTemasFacturados
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Escritorio);


// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../css/testJusticiaPre.css';

import moment from 'moment';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
import { selectPreguntasPorArticulos, respuestasPregJusticia, mezclarRespuestasJusticia, generarTestTemplate } from '../../../../../lib/utils/generadorTestJusticia';
//Componets
import Input from '../../../../../components/Inputs/Input';
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import TestPorArticulos from '../../../../../components/generadorTest/Justicia/TestPorArticulos';
import TestPorLeyes from '../../../../../components/generadorTest/Justicia/TestPorLeyes';
import TestPorTemas from '../../../../../components/generadorTest/Justicia/TestPorTemas';
import TestPorClases from '../../../../../components/generadorTest/Justicia/TestPorClases';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    nombreUser: PropTypes.string
  };
  constructor(props){
    super(props);
    this.state = {
  	   statusBarraProceso: 'completar',
  	   displayFecha: 'none',
       displayFecha2: 'none',
  	   programacionClaseSeleted: undefined,
  	   semanasAtrasarPrep: 0,
  	   semanasAdelantarPosponer: 0,
       displayTestPorTemas: 'none'
       //displayTestPorTemas: 'block'
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.onChangeArticulos = this.onChangeArticulos.bind(this);
  }
  cleanForm(){
    switch (this.props.selectedOption) {
  		case 'Editar_Programacion': {
  			this.setState({
  				oposicionSelecionada: undefined,
  				claseSelecionada: undefined
  			});
  			this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
  			this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
  		}break;
    }
  }
  onChangeInput(e){
    this.setState({[e.target.name]: e.target.value});
  	if (this.props.selectedOption == 'Temas_numPreg'){
      if(e.target.name == 'oposicion'){
        let opoTest = this.state.busquedaOpo.filter(opo=> opo.nombre == e.target.value)
        if(opoTest.length > 0){
          //fetch buscar temas de opo
          let data = { idOpo: parseInt(opoTest[0].id_oposicion) }

          fetch("https://oposiciones-justicia.es/api/preparador/temasPorOposicion", {
          	method: 'POST',
          	headers: {'Content-Type': 'application/json'},
          	body: JSON.stringify(data)
          }).then(response =>  {
          	if (response.status >= 400) { throw new Error("Bad response from server");}
          	return response.json();
          }).then(cls => {
          	if (cls.length != 0 || cls != undefined){
          		this.setState({displayTestPorTemas: 'block', busquedaTemas: cls});
            }
          }).catch(error => console.log('Object fecth failed', error))

        } else {
          this.setState({displayTestPorTemas: 'none', busquedaTemas: undefined, displayFecha: 'none', displayFecha2: 'none', temasClase: undefined, intervalosExtraClase: undefined});
        }
      }
    } /*else if (this.props.selectedOption == 'Temas_Programacion'){
      if(e.target.name == 'claseSeleccionada'){
  			let claseObject = this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
  			if(claseObject != undefined){
  				let data = { idClase: claseObject.idClase }
  				this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion});
  				fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
  					method: 'POST',
  					headers: {'Content-Type': 'application/json'},
  					body: JSON.stringify(data)
  				}).then(response =>  {
  					if (response.status >= 400) { throw new Error("Bad response from server");}
  					return response.json();
  				}).then(cls => {
  					if(cls != undefined){
  						this.setState({ busquedaClasesFecha: cls, displayFecha: 'block', displayFecha2: 'flex'});
  						this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = moment().format('MM-YY');
  					}
  				}).catch(function(err) {console.log(err)});

  			} else {
  				this.setState({ busquedaClasesFecha: undefined, displayFecha: 'none', displayFecha2: 'none', temasClase: undefined, intervalosExtraClase: undefined})
  				this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value='';
  			}
  		}

      if(e.target.name === 'fechaSeleccionada'){
  			let fechaClaseObject = this.state.busquedaClasesFecha.find(prg => moment(prg.fechaClase).format('DD-MM-YY HH:mm') == e.target.value);
  			if(fechaClaseObject != undefined){
  			// Generamos los 3 arrays
  			// ejerciciosClase temasClase intervalosExtraClase
  			// ejercicios
  				//Buscar y extraer los datos del dia de la programacion selecionada
  				let data = { fechaClase: moment(fechaClaseObject.fechaClase).format('YYYY-MM-DD HH:mm')}
  				fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaVirtual", {
  					method: 'POST',
  					headers: {'Content-Type': 'application/json'},
  					body: JSON.stringify(data)
  				}).then(response =>  {
  					if (response.status >= 400) { throw new Error("Bad response from server");}
  					return response.json();
  				}).then(cls => {
  					if(cls != undefined){
  						this.setState({ ejerciciosClase: cls});
  					}
  				}).catch(function(err) {console.log(err)});
  			// temas
  			fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaTemasVirtual", {
  				method: 'POST',
  				headers: {'Content-Type': 'application/json'},
  				body: JSON.stringify(data)
  			}).then(response =>  {
  				if (response.status >= 400) { throw new Error("Bad response from server");}
  				return response.json();
  			}).then(cls => {
  				if(cls != undefined){
  					this.setState({ temasClase: cls});
  				}
  			}).catch(function(err) {console.log(err)});

  			setTimeout(

  			    function() {
  				    fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaTemasInterVirtual", {
  	 	   				method: 'POST',
  	 	   				headers: {'Content-Type': 'application/json'},
  	 	   				body: JSON.stringify(data)
  					}).then(response =>  {
  	 	   				if (response.status >= 400) { throw new Error("Bad response from server");}
  	 	   				return response.json();
   	   				}).then(cls => {
  	 	   				if(cls != undefined){
  	 	   					let ejerciciosTemplete = this.state.temasClase
  	 	   					for (var j = 0; j < ejerciciosTemplete.length; j++) {
  	 	   						for (var i = 0; i < cls.length; i++) {
  	 	   							if (ejerciciosTemplete[j].idTemaPRG == cls[i].idTemaPRG && ejerciciosTemplete[j].idTema == cls[i].idTema ){
  	 	   								if(ejerciciosTemplete[j].intervalos == undefined){
  	 	   									ejerciciosTemplete[j].intervalos = [];
  	 	   								}
  	 	   								ejerciciosTemplete[j].intervalos.push({
  	 	   									ley: cls[i].ley,
  	 	   									nombreLey: cls[i].nombreLey,
  	 	   									siglasLey: cls[i].siglasLey,
  	 	   									artInicio: cls[i].artInicio,
  	 	   									artFin: cls[i].artFin,
                          numPreg: undefined,
                          numInter: i
  	 	   								})
  	 	   							}
  	 	   						}
  	 	   					}
  	 	   					for (var i = 0; i < ejerciciosTemplete.length; i++) {
  	 	   						for (var j = 0; j < ejerciciosTemplete[i].intervalos.length; j++) {
  	 	   							//let busqueda = ejerciciosTemplete[i].intervalosLeyes[j].indexOf(ejerciciosTemplete[i].intervalos[j].ley);
  	 	   							if(	ejerciciosTemplete[i].intervalosLeyes == undefined){
  	 	   								ejerciciosTemplete[i].intervalosLeyes = [];
  	 	   							}
  	 	   							let busqueda = ejerciciosTemplete[i].intervalosLeyes.findIndex(x => x.ley == ejerciciosTemplete[i].intervalos[j].ley)
  	 	   							if (busqueda != -1) {
  	 	   								  ejerciciosTemplete[i].intervalosLeyes[busqueda].intervalosArticulos.push({
  	 	   									  artInicio: ejerciciosTemplete[i].intervalos[j].artInicio,
  	 	   									  artFin: ejerciciosTemplete[i].intervalos[j].artFin
  	 	   								  })
  	 	   							} else {
  	 	   								if(	ejerciciosTemplete[i].intervalosLeyes == undefined){
  	 	   									ejerciciosTemplete[i].intervalosLeyes = [];
  	 	   								}
  	 	   								ejerciciosTemplete[i].intervalosLeyes.push({
  	 	   									ley: ejerciciosTemplete[i].intervalos[j].ley,
  	 	   									siglasLey: ejerciciosTemplete[i].intervalos[j].siglasLey,
  	 	   									nombreLey: ejerciciosTemplete[i].intervalos[j].nombreLey,
  	 	   									intervalosArticulos: [{
  	 	   										artInicio: ejerciciosTemplete[i].intervalos[j].artInicio,
  	 	   										artFin: ejerciciosTemplete[i].intervalos[j].artFin,
                            numPreg: undefined,
                            numInter: j
  	 	   									}]
  	 	   								})
  	 	   							}
  	 	   							}
  	 	   						}
  	 	   					this.setState({ temasClase: ejerciciosTemplete});
  	 	   				}
  	 	   			}).catch(function(err) {console.log(err)});
  			    }.bind(this), 1100 );

  			//articulosExtra
  			fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaArtExtraVirtual", {
  				method: 'POST',
  				headers: {'Content-Type': 'application/json'},
  				body: JSON.stringify(data)
  			}).then(response =>  {
  				if (response.status >= 400) { throw new Error("Bad response from server");}
  				return response.json();
  			}).then(cls => {
  				if(cls != undefined){
  					let templeteExtra = [{}];
  					for (var i = 0; i < cls.length; i++) {
  						for (var j = 0; j < templeteExtra.length; j++) {

  						if(	templeteExtra[j].intervalosArticulos == undefined){
  							templeteExtra[j].intervalosArticulos = [];
  						}
  						let busqueda = templeteExtra.findIndex(x => x.ley == cls[i].ley)
  						if (busqueda != -1) {
  							let busqueda2 = templeteExtra[busqueda].intervalosArticulos.findIndex(x => x.artInicio == cls[i].artInicio && x.artFin == cls[i].artFin )
  							if(busqueda2 == -1){
  								templeteExtra[busqueda].intervalosArticulos.push({
     								  artInicio: cls[i].artInicio,
     								  artFin: cls[i].artFin,
                      numPreg: undefined,
                      numInter: undefined
     							  })
  							}
  						} else {
  							if(	templeteExtra[0].ley == undefined && templeteExtra.length == 1){
  									templeteExtra[0].ley= cls[i].ley
  									templeteExtra[0].nombreLey= cls[i].nombreLey
  									templeteExtra[0].siglasLey= cls[i].siglasLey
  									templeteExtra[0].intervalosArticulos= [{
  										artInicio: cls[i].artInicio,
  										artFin: cls[i].artFin,
                      numPreg: undefined,
                      numInter: undefined
  									}]
  							} else {
  								templeteExtra.push({
  									ley: cls[i].ley,
  									nombreLey: cls[i].nombreLey,
  									siglasLey: cls[i].siglasLey,
  									intervalosArticulos: [{
  									artInicio: cls[i].artInicio,
  									artFin: cls[i].artFin,
                    numPreg: undefined,
                    numInter: undefined
  									}]
  								})
  							}
  						}
  					}
  				}
  				this.setState({ intervalosExtraClase: templeteExtra, displayTestPorTemas: 'block'});
          setTimeout(
            function() {

              for (var i = 0; i < this.state.temasClase.length; i++) {
                this.refs['ref_temaSelecionado-'+i].refs['temaSelecionado-'+i].value = this.state.temasClase[i].tituloTema
                for (var j = 0; j < this.state.temasClase[i].intervalos.length; j++) {
                  this.refs['ref_siglasLeyExt-'+i+'-'+j].refs['siglasLeyExt-'+i+'-'+j].value = this.state.temasClase[i].intervalos[j].ley == undefined ? "" : this.state.busquedaLeyes.find(l=> l.id_ley == this.state.temasClase[i].intervalos[j].ley).siglas_ley;

                  this.refs['ref_artInicioExt-'+i+'-'+j].refs['artInicioExt-'+i+'-'+j].value = this.state.temasClase[i].intervalos[j].artInicio == undefined ? "" : this.state.temasClase[i].intervalos[j].artInicio;

                  this.refs['ref_artFinExt-'+i+'-'+j].refs['artFinExt-'+i+'-'+j].value = this.state.temasClase[i].intervalos[j].artFin == undefined ? "" : this.state.temasClase[i].intervalos[j].artFin;

                  this.refs['ref_numPreg-'+i+'-'+j].refs['numPreg-'+i+'-'+j].value = this.state.temasClase[i].intervalos[j].numPreg == undefined ? "" : this.state.temasClase[i].intervalos[j].numPreg;
                }
              }

            }.bind(this),500);
  				}
  			}).catch(function(err) {console.log(err)});
        // GENERAR AQUI  BUSQUEDATEMAS--> BUSQUEDATEMASCLASE numPreg
          // que tenga temas y intervalos
          // Luego crear componente testPorClase
  		} else {
  			this.setState({ ejerciciosClase: undefined,temasClase: undefined,intervalosExtraClase: undefined, displayTestPorTemas: 'none'})
  		}
  	}

  } */

  }
  onChangeArticulos(intervalosArt){
    this.setState({intervalosArt: intervalosArt})
  }

  async handleSubmitForm(e, art = []){
  	switch (e.target.id) {

  		case 'genTest': {

      let datosValidos = true;
      let intervaloMal;
      let totalPreg = 0;
      art.oposicion = this.state.oposicion
      art.numPreguntasTotal = this.state.numPreguntasTestTotal
      art.nombreDelTest = this.state.nombreTest
      console.log(art)
      for (var i = 0; i < art.length; i++) {

        totalPreg = parseInt(totalPreg) + parseInt(art[i].numPreg)

        if( art[i].artInicio == undefined || art[i].artFin == undefined || art[i].idLey == undefined || art[i].numPreg == undefined){
          datosValidos = false;
          intervaloMal= i+1
        }

        let artFiltroLey = art.filter(int=> int.idLey == art[i].idLey && int.artInicio != art[i].artInicio && int.artFin != art[i].artFin)

        for (var j = 0; j < artFiltroLey.length; j++) {
          if(parseInt(art[i].artInicio.split('.')[0]) >= parseInt(artFiltroLey[j].artInicio.split('.')[0]) && parseInt(art[i].artInicio.split('.')[0]) <= parseInt(artFiltroLey[j].artFin.split('.')[0])){
            // error
            swal("El intervalo: "+i+" no es valido no puede pertenecer a ningun otro intervalo.","","error")
            datosValidos = false;
            intervaloMal= i+1;
            break
          }
          if(parseInt(art[i].artFin.split('.')[0]) >= parseInt(artFiltroLey[j].artInicio.split('.')[0]) && parseInt(art[i].artFin.split('.')[0]) <= parseInt(artFiltroLey[j].artFin.split('.')[0])){
            // error
            swal("El intervalo: "+i+" no es valido no puede pertenecer a ningun otro intervalo.","","error")
            datosValidos = false;
            intervaloMal= i+1;
            break
          }
        }
      }
      if(parseInt(totalPreg) == parseInt(this.state.numPreguntasTestTotal)){
        if(this.state.numPreguntasTestTotal != undefined && this.state.oposicion != undefined && this.state.nombreTest != undefined){

    			if(art.length > 0 && datosValidos == true){
            swal({
                icon: 'info',
                title: 'Preparando Test e Insertando Test Template',
                text: "Tardamos unos segundos",
                button: false,
                timer: 5500
            })
            // Buscamos Preg
            let preguntasBuscadas = selectPreguntasPorArticulos(art)
            console.log(preguntasBuscadas)

            /*
            do while! --> mientras el numero de preguntas no sea el total requerido hacer algo..-->
            preguntasBuscadas.filter(p=> p.idLey == 44 && parseInt(p.articulo.split('.')[0]) >= 1 && parseInt(p.articulo.split('.')[0]) <=6) nos devuelve el numero de pregunta encontradas del intervalo ley 44"tue" del art 1 al 6.

            comprobar se se encontraron todas las preguntas requeridas
            si falta 1 generar art nuevo quitando el intervalo que no encontro más y repartiendo las que le faltan a ese entre todas.

            setTimeout(function() { // Buscamos Res
              preguntasBuscadas = respuestasPregJusticia(preguntasBuscadas)
              setTimeout(function() {
                // valores test template
                let objectOpo = this.state.busquedaOpo.find(opo=> opo.nombre == this.state.oposicion)
                preguntasBuscadas.oposicion = objectOpo.id_oposicion
                preguntasBuscadas.numPreguntasTotal = this.state.numPreguntasTestTotal
                preguntasBuscadas.nombreDelTest = this.state.nombreTest
              }.bind(this),2500);
            }.bind(this),1250);

            setTimeout(
                function() { // Mezclamos Respuestas
                  preguntasBuscadas = mezclarRespuestasJusticia(preguntasBuscadas)
                }.bind(this),3850);

            setTimeout(
                function() { // Insertamos Template Test
                  generarTestTemplate(preguntasBuscadas)
                }.bind(this),4500);
            */
      			}else{
      				swal("El intervalo de articulos nº "+intervaloMal+' tiene un error.','Datos del intervalo erroneo --> Articulo Inicio: '+art[intervaloMal-1].artInicio+'. Articulo Fin: '+art[intervaloMal-1].artFin+'.',"error");
      			}
          } else {
            swal("Error",'Falta indicar la oposicion, nombre del test o el total de preguntas.',"error");
          }
        } else {
        swal("Error",'El numero total de preguntas: '+this.state.numPreguntasTestTotal+', no coincide con el total de preguntas de los intervalos: '+totalPreg+'.',"error");
        }
      }break;
      case 'genTestLeyes': {

              let datosValidos = true;
              let intervaloMal;
              let totalPreg = 0;
              art.oposicion = this.state.oposicion
              art.numPreguntasTotal = this.state.numPreguntasTestTotal
              art.nombreDelTest = this.state.nombreTest
              for (var i = 0; i < art.length; i++) {
                totalPreg = parseInt(totalPreg) + parseInt(art[i].numPreg)
                if( art[i].artInicio == undefined || art[i].artFin == undefined || art[i].idLey == undefined || art[i].numPreg == undefined){
                  datosValidos = false;
                  intervaloMal= i+1
                }
                let artFiltroLey = art.filter(int=> int.idLey == art[i].idLey)

                  if(artFiltroLey.length > 1){
                    // error
                    swal("La ley num: "+i+" esta duplicada.","Solo puede tener una unica ley","error")
                    datosValidos = false;
                    intervaloMal= i+1;
                    break
                  }

              }
              if(parseInt(totalPreg) == parseInt(this.state.numPreguntasTestTotal)){
                if(this.state.numPreguntasTestTotal != undefined && this.state.oposicion != undefined && this.state.nombreTest != undefined){

            			if(art.length > 0 && datosValidos == true){
                    swal({
                        icon: 'info',
                        title: 'Preparando Test e Insertando Test Template',
                        text: "Tardamos unos segundos",
                        button: false,
                        timer: 5500
                    })
                    // Buscamos Preg
                    let preguntasBuscadas = selectPreguntasPorArticulos(art)

                    setTimeout(function() { // Buscamos Res
                      preguntasBuscadas = respuestasPregJusticia(preguntasBuscadas)
                      setTimeout(function() {
                        // valores test template
                        let objectOpo = this.state.busquedaOpo.find(opo=> opo.nombre == this.state.oposicion)
                        preguntasBuscadas.oposicion = objectOpo.id_oposicion
                        preguntasBuscadas.numPreguntasTotal = this.state.numPreguntasTestTotal
                        preguntasBuscadas.nombreDelTest = this.state.nombreTest
                      }.bind(this),2500);
                    }.bind(this),1250);

                    setTimeout(
                        function() { // Mezclamos Respuestas
                          preguntasBuscadas = mezclarRespuestasJusticia(preguntasBuscadas)
                        }.bind(this),3850);

                    setTimeout(
                        function() { // Insertamos Template Test
                          generarTestTemplate(preguntasBuscadas)
                        }.bind(this),4500);

              			}else{
              				swal("Lay ley nº "+intervaloMal+" tiene un error.","Ley duplicada, solo puede usar una.","error");
              			}
                  } else {
                    swal("Error",'Falta indicar la oposicion, nombre del test o el total de preguntas.',"error");
                  }
                } else {
                swal("Error",'El numero total de preguntas: '+this.state.numPreguntasTestTotal+', no coincide con el total de preguntas de los intervalos: '+totalPreg+'.',"error");
                }
      }break;
      case 'genTestTemas': {
        let newArt = []
        for (var i = 0; i < art.length; i++) {
          for (var j = 0; j < art[i].intervalos.length; j++) {
            newArt.push(art[i].intervalos[j])
          }
        }
        for (var i = 0; i < newArt.length; i++) {
          newArt[i].numInter = i
        }
        art = newArt

        let datosValidos = true;
        let intervaloMal;
        let totalPreg = 0;
        art.oposicion = this.state.oposicion
        art.numPreguntasTotal = this.state.numPreguntasTestTotal
        art.nombreDelTest = this.state.nombreTest
        for (var i = 0; i < art.length; i++) {
          totalPreg = parseInt(totalPreg) + parseInt(art[i].numPreg)
          if( art[i].artInicio == undefined || art[i].artFin == undefined || art[i].idLey == undefined || art[i].numPreg == undefined){
            datosValidos = false;
            intervaloMal= i+1
          }
          let artFiltroLey = art.filter(int=> int.idLey == art[i].idLey && int.artInicio != art[i].artInicio && int.artFin != art[i].artFin)
          for (var j = 0; j < artFiltroLey.length; j++) {

            if(parseInt(art[i].artInicio.split('.')[0]) >= parseInt(artFiltroLey[j].artInicio.split('.')[0]) && parseInt(art[i].artInicio.split('.')[0]) <= parseInt(artFiltroLey[j].artFin.split('.')[0])){
              // error
              swal("El intervalo: "+i+" no es valido no puede pertenecer a ningun otro intervalo.","","error")
              datosValidos = false;
              intervaloMal= i+1;
              break
            }
            if(parseInt(art[i].artFin.split('.')[0]) >= parseInt(artFiltroLey[j].artInicio.split('.')[0]) && parseInt(art[i].artFin.split('.')[0]) <= parseInt(artFiltroLey[j].artFin.split('.')[0])){
              // error
              swal("El intervalo: "+i+" no es valido no puede pertenecer a ningun otro intervalo.","","error")
              datosValidos = false;
              intervaloMal= i+1;
              break
            }


          }
        }
        if(parseInt(totalPreg) == parseInt(this.state.numPreguntasTestTotal)){
          if(this.state.numPreguntasTestTotal != undefined && this.state.oposicion != undefined && this.state.nombreTest != undefined){

            if(art.length > 0 && datosValidos == true){
              swal({
                  icon: 'info',
                  title: 'Preparando Test e Insertando Test Template',
                  text: "Tardamos unos segundos",
                  button: false,
                  timer: 5500
              })
              // Buscamos Preg
              let preguntasBuscadas = selectPreguntasPorArticulos(art)

              setTimeout(function() { // Buscamos Res
                preguntasBuscadas = respuestasPregJusticia(preguntasBuscadas)
                setTimeout(function() {
                  // valores test template
                  let objectOpo = this.state.busquedaOpo.find(opo=> opo.nombre == this.state.oposicion)
                  preguntasBuscadas.oposicion = objectOpo.id_oposicion
                  preguntasBuscadas.numPreguntasTotal = this.state.numPreguntasTestTotal
                  preguntasBuscadas.nombreDelTest = this.state.nombreTest
                }.bind(this),2500);
              }.bind(this),1250);

              setTimeout(
                  function() { // Mezclamos Respuestas
                    preguntasBuscadas = mezclarRespuestasJusticia(preguntasBuscadas)
                  }.bind(this),3850);

              setTimeout(
                  function() { // Insertamos Template Test
                    generarTestTemplate(preguntasBuscadas)
                  }.bind(this),4500);

              }else{
                swal("El intervalo de articulos nº "+intervaloMal+' tiene un error.','Datos del intervalo erroneo --> Articulo Inicio: '+art[intervaloMal-1].artInicio+'. Articulo Fin: '+art[intervaloMal-1].artFin+'.',"error");
              }
            } else {
              swal("Error",'Falta indicar la oposicion, nombre del test o el total de preguntas.',"error");
            }
          } else {
          swal("Error",'El numero total de preguntas: '+this.state.numPreguntasTestTotal+', no coincide con el total de preguntas de los intervalos: '+totalPreg+'.',"error");
          }

        }break;


    }

  }
componentDidMount(){
  if(this.props.tipoUser >= 97){
    let data = {}
    fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
    	method: 'POST',
    	headers: {'Content-Type': 'application/json'},
    	body: JSON.stringify(data)
    }).then(response =>  {
    	if (response.status >= 400) { throw new Error("Bad response from server");}
    	return response.json();
    }).then(cls => {
    	if (cls.length != 0 || cls != undefined){
    		this.setState({busquedaClasesPreparador: cls})
    }
    }).catch(error => console.log('Object fecth failed', error))
  } else {
    var data1 = { nombreUsuario: this.props.nombreUser }
    fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data1)
    }).then(res => res.json())
       .then(cls => {
       var data2 = { idPreparador: cls[0].id_usuario }
       return fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
         method: 'POST',
         headers: {'Content-Type': 'application/json'},
         body: JSON.stringify(data2)
       });
       }).then(response =>  {
         if (response.status >= 400) { throw new Error("Bad response from server");}
         return response.json();
       }).then(cls => {
         if(cls != undefined){
           this.setState({busquedaClasesPreparador: cls})
      }}).catch(error => console.log('Object fetch failed', error));
  }


     fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
   	.then(res => res.json())
   	.then(oposiciones => this.setState({
   		busquedaOpo: oposiciones
   	})).catch(error => console.log('Object fecth failed', error));

   	fetch('https://oposiciones-justicia.es/api/leyes/consulta')
   	.then(res => res.json())
   	.then(leyes => this.setState({
   		busquedaLeyes: leyes
   	}))
   	.catch(error => console.log('Obejet fecth failed', error))

};

render() {
	const { selectedOption } = this.props;
  const { busquedaClasesPreparador, busquedaOpo, displayTestPorTemas, busquedaTemas, displayFecha,displayFecha2 , busquedaClasesFecha, busquedaTemasPrg , temasClase} = this.state;
	switch (selectedOption) {
		case 'Intervalos_Articulos': {
			return (
				<form className="formMeca" autoComplete="off">
          <h44>
            <i className="fas fa-info" />
            Generador de Test por Articulos
            <i className="fas fa-arrow-circle-down" />
          </h44>
          <div className="inputRow">

            <div className="inputBody">
            <h55> <i className="fas fa-info-circle" />Total Preguntas</h55>
            <Input size="20" type="number" name="numPreguntasTestTotal" placeholder="Nº Preguntas" icon="fas fa-sort-numeric-up" ref="ref_numPreguntasTestTotal" onChange={ this.onChangeInput}/>
            </div>
            <div className="inputBody">
            <h55 style={{width: '100px', alignSelf: 'center'}}> <i className="fas fa-info-circle" /> Oposición </h55>
            <Input type="text" name="oposicion" placeholder="Seleccionar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicion" onChange={ this.onChangeInput}/>
            <datalist id="busquedaOpo">{
    					busquedaOpo && busquedaOpo.map((cls, key) =>
    						<option key={key} value={cls.nombre}></option>
    					)
    				}</datalist>
            </div>
            <div className="inputBody">
            <h55 style={{ alignSelf: 'center'}}><i className="fas fa-info-circle" />  Nombre de Test </h55>
  					<Input autoComplete="size" type="text" name="nombreTest" placeholder="Nombre del Test" icon="fas fa-tag" ref="ref_nombreTest" onChange={ this.onChangeInput}/>
            </div>

          </div>

          <TestPorArticulos onClick={this.handleSubmitForm} submitText="Generar Test Justicia" />

				</form>
			);
		}break;
    case 'Leyes_numPreg': {
			return (
				<form className="formMeca" autoComplete="off">
          <h44>
            <i className="fas fa-info" />
            Generador de Test por Leyes
            <i className="fas fa-arrow-circle-down" />
          </h44>
          <div className="inputRow">

            <div className="inputBody">
            <h55> <i className="fas fa-info-circle" />Total Preguntas</h55>
            <Input size="20" type="number" name="numPreguntasTestTotal" placeholder="Nº Preguntas" icon="fas fa-sort-numeric-up" ref="ref_numPreguntasTestTotal" onChange={ this.onChangeInput}/>
            </div>
            <div className="inputBody">
            <h55 style={{width: '100px', alignSelf: 'center'}}> <i className="fas fa-info-circle" /> Oposición </h55>
            <Input type="text" name="oposicion" placeholder="Seleccionar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicion" onChange={ this.onChangeInput}/>
            <datalist id="busquedaOpo">{
    					busquedaOpo && busquedaOpo.map((cls, key) =>
    						<option key={key} value={cls.nombre}></option>
    					)
    				}</datalist>
            </div>
            <div className="inputBody">
            <h55 style={{ alignSelf: 'center'}}><i className="fas fa-info-circle" />  Nombre de Test </h55>
  					<Input autoComplete="size" type="text" name="nombreTest" placeholder="Nombre del Test" icon="fas fa-tag" ref="ref_nombreTest" onChange={ this.onChangeInput}/>
            </div>

          </div>

          <TestPorLeyes onClick={this.handleSubmitForm} submitText="Generar Test Justicia" />

				</form>
			);
		}break;
    case 'Temas_numPreg': {
			return (
				<form className="formMeca" autoComplete="off">
          <h44>
            <i className="fas fa-info" />
            Generador de Test por Temas
            <i className="fas fa-arrow-circle-down" />
          </h44>
          <div className="inputRow">

            <div className="inputBody">
            <h55> <i className="fas fa-info-circle" />Total Preguntas</h55>
            <Input size="20" type="number" name="numPreguntasTestTotal" placeholder="Nº Preguntas" icon="fas fa-sort-numeric-up" ref="ref_numPreguntasTestTotal" onChange={ this.onChangeInput}/>
            </div>
            <div className="inputBody">
            <h55 style={{width: '100px', alignSelf: 'center'}}> <i className="fas fa-info-circle" /> Oposición </h55>
            <Input type="text" name="oposicion" placeholder="Seleccionar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicion" onChange={ this.onChangeInput}/>
            <datalist id="busquedaOpo">{
    					busquedaOpo && busquedaOpo.map((cls, key) =>
    						<option key={key} value={cls.nombre}></option>
    					)
    				}</datalist>
            </div>
            <div className="inputBody">
            <h55 style={{ alignSelf: 'center'}}><i className="fas fa-info-circle" />  Nombre de Test </h55>
  					<Input autoComplete="size" type="text" name="nombreTest" placeholder="Nombre del Test" icon="fas fa-tag" ref="ref_nombreTest" onChange={ this.onChangeInput}/>
            </div>

          </div>

          <div style={{ display: displayTestPorTemas}}>
          <TestPorTemas onClick={this.handleSubmitForm} busquedaTemas={this.state.busquedaTemas} submitText="Generar Test Justicia" />
          </div>

				</form>
			);
		}break;
    /*case 'Temas_Programacion': {
      console.log(this.state.ejerciciosClase)
      console.log(this.state.temasClase)
      console.log(this.state.intervalosExtraClase)

			return (
				<form className="formMeca" autoComplete="off">
          <h44>
            <i className="fas fa-info" />
            Generador de Test por Temas de Programacion
            <i className="fas fa-arrow-circle-down" />
          </h44>
          <div className="inputRow">

            <div className="inputBody">
            <h55> <i className="fas fa-info-circle" />Total Preguntas< /h55>
            <Input size="20" type="number" name="numPreguntasTestTotal" placeholder="Nº Preguntas" icon="fas fa-sort-numeric-up" ref="ref_numPreguntasTestTotal" onChange={ this.onChangeInput}/>
            </div>

            <div className="inputBody">
              <h55 style={{width: '175px', alignSelf: 'center'}}> <i className="fas fa-info-circle" /> Clases Preparador </h55>
              <Input type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador"ref="ref_claseSeleccionada" onChange={ this.onChangeInput}/>
              <datalist id="busquedaClasesPreparador">{
    						busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
    							<option key={key} value={cls.diaSemanal}></option>
    						)
    					}</datalist>
            </div>
            <div className="inputBody" style={{ display: displayFecha2}}>
              <h55 style={{width: '175px', alignSelf: 'center'}}> <i className="fas fa-info-circle" /> Semana de Clase </h55>
    					<Input display={displayFecha} type="text" name="fechaSeleccionada" placeholder="Seleccionar Semana" icon="fas fa-calendar-alt" datalist="busquedaClasesFecha"ref="ref_fechaSeleccionada" onChange={this.onChangeInput}/>

    					<datalist id="busquedaClasesFecha">{
    						busquedaClasesFecha && busquedaClasesFecha.map((cls, key) =>
    							<option key={key} value={moment(cls.fechaClase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.semanaClase}</option>
    						)
    					}</datalist>
            </div>

            <div className="inputBody">
            <h55 style={{ alignSelf: 'center'}}><i className="fas fa-info-circle" />  Nombre de Test </h55>
  					<Input autoComplete="size" type="text" name="nombreTest" placeholder="Nombre del Test" icon="fas fa-tag" ref="ref_nombreTest" onChange={ this.onChangeInput}/>
            </div>

          </div>

          <div style={{ display: displayTestPorTemas}}>
          <TestPorClases onClick={this.handleSubmitForm} busquedaTemas={temasClase} submitText="Generar Test Justicia" />
          </div>

				</form>
			);
		}break;*/
		default: {
			return (
				<div style={{fontSize: '24px'}} >
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
						Selecciona una opción para generar un test template y poder asignarlo a una clase.
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
				</div>
			);
		}
	}
}
}
export default OptionBar;

// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
// Actions
import { iniciarSesion } from '../../../actions/userAction';

// Assets
import './css/css.css';

import Footer from '../../../components/Global/Footer/Footer';
import Boton from '../../../components/Inputs/Boton';
import Input2 from '../../../components/Inputs/Input2';
import Tarjeta3mode1 from './components/Tarjeta3mode1';
import Tarjeta3mode2 from './components/Tarjeta3mode2';

class PreOpoInfo extends Component {
  static propTypes = {
    iniciarSesion: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string,
    tipoUser: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      viewPrepaHome: 1,
      preparacion: {
        locaActual: 'selectOpo',
        modePrepa: 'normal',
        tipoPreparacion: null,
        oposiciones: [
          {
            infoText: 'Auxilio y Tramitacion LIBRE',
            tipoPreparacionOpo: 'Auxilio-y-Tramitacion-LIBRE'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Gestión Interna',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Tramitacion Interna',
            tipoPreparacionOpo: ''
          }

        ]
      }
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    console.log('entro')
    if (e.target.id.split('-')[0] == 'opoSelected') {
      // infoText: 'Gestion Libre',
      // tipoPreparacionOpo: 'Gestion-libre',
      // urlOpoSelectd: 'Online_Video_Presencial'


      let idLoca = e.target.id.split('-')[1]
      let prevUrlPrepa = this.state.preparacion.oposiciones[idLoca].urlOpoSelectd
      let tipoPreparacionOpo = this.state.preparacion.oposiciones[idLoca].tipoPreparacionOpo
      this.props.history.push('/Preparacion/' + prevUrlPrepa + '/' + tipoPreparacionOpo);




    } else if (e.target.id == 'atras') {
      this.props.history.push('/Preparacion/'
        + this.state.preparacionInfo.url2TipoPreparacion);
    } else if (e.target.id == 'help') {
      swal('Si necesita ayuda','Puede llamarnos al: 688 98 80 99','info')
    }
  }
  onChangeInput(e) {

    if (e.target.name == 'dniInput') {
      this.setState({ checkedDni: undefined })
      this.setState({ dniUser: e.target.value });
    } else if (e.target.name == "passwordInput") {
      this.setState({ checkedPass: undefined })
      this.setState({ passwordUserInput: e.target.value });
    }

  }
  componentDidMount() {
    let url = location.href;
    let url1Inicio = url.split('/')[url.split('/').length - 3]
    let url2TipoPreparacion = url.split('/')[url.split('/').length - 2]
    let url3Oposicion = url.split('/')[url.split('/').length - 1]

    if (url1Inicio == 'Preparacion' && (url2TipoPreparacion != 'Cursos_Online' && url2TipoPreparacion != 'Presencial' && url2TipoPreparacion != 'Online_Video_Presencial')) {
      // Url no valida redigrig atras!
      this.props.history.push('/Preparacion')
    } else {

      if (url3Oposicion != 'Aux-Tra-libre' && url3Oposicion != 'Gestion-libre' && url3Oposicion != 'Tramitacion-interna' && url3Oposicion != 'Gestion-interna') {
        this.props.history.push('/Preparacion/' + url2TipoPreparacion)
      } else {

        let oposiciones = [
          {
            url2TipoPreparacion: url2TipoPreparacion,
            url3Oposicion: url3Oposicion
          }
        ]


        if (
          url2TipoPreparacion == 'Cursos_Online' && (url3Oposicion == 'Aux-Tra-libre' || url3Oposicion == 'Gestion-libre' || url3Oposicion == 'Tramitacion-interna' || url3Oposicion == 'Gestion-interna')) {
          if (url3Oposicion == 'Aux-Tra-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Curso Online',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online</b> Auxilio y Tramitación Libre, mediante nuestros <b>cursos intensivos y ordinarios</b>. </p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio y Tramitación</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '',
              ofertaMensualidad: '',

              cursos: [
                // {
                //   titulo: 'SuperIntensivo',
                //   info: '3 MESES',
                //   oferta: '99.67€',
                //   precio: '299€',
                //   select: false
                // }
                // ,
                // {
                //   titulo: 'Intensivo',
                //   info: '6 MESES',
                //   oferta: '83.17€',
                //   precio: '499€',
                //   select: false
                // }
                // ,
                {
                  titulo: 'Ordinario',
                  info: '12 MESES de acceso a la plataforma, estudia a tu ritmo.',
                  oferta: '58.25€',
                  precio: '799€',
                  select: false
                }
                
              ]

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Curso Online',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online</b> Gestión Libre, mediante nuestros <b>cursos intensivos y ordinarios</b></p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio, Tramitación y Gestión</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '',
              ofertaMensualidad: '',

              cursos: [
                // {
                //   titulo: 'SuperIntensivo',
                //   info: '3 MESES',
                //   oferta: '99.67€',
                //   precio: '299€',
                //   select: false
                // }
                // ,
                // {
                //   titulo: 'Intensivo',
                //   info: '6 MESES',
                //   oferta: '83.17€',
                //   precio: '549€',
                //   select: false
                // }
                // ,
                {
                  titulo: 'Ordinario',
                  info: '12 MESES de acceso a la plataforma, estudia a tu ritmo.',
                  oferta: '75.75€',
                  precio: '849€',
                  select: false
                }
                
              ]

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else if (url3Oposicion == 'Tramitacion-interna') {
            let slctPreparacionInfo = {
              nameOpo: 'Tramitación Interna',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Curso Online',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online</b> Tramitación Interna, mediante nuestros <b>cursos intensivos y ordinarios</b></p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Tramitación y Gestión</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '',
              ofertaMensualidad: '',

              cursos: [
                // {
                //   titulo: 'SuperIntensivo',
                //   info: '3 MESES',
                //   oferta: '99.67€',
                //   precio: '299€',
                //   select: false
                // }
                // ,
                // {
                //   titulo: 'Intensivo',
                //   info: '6 MESES',
                //   oferta: '83.17€',
                //   precio: '499€',
                //   select: false
                // }
                // ,
                {
                  titulo: 'Ordinario',
                  info: '12 MESES de acceso a la plataforma, estudia a tu ritmo.',
                  oferta: '58.25€',
                  precio: '799€',
                  select: false
                }
                
              ]

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-interna') {
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Interna',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Curso Online',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online</b> Gestión Interna, mediante nuestros <b>cursos intensivos y ordinarios</b>.</p></div> ',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p> Preparación del <b>tercer ejercicio</b> de Gestión con <b>temario exclusivo.</b> </p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '',
              ofertaMensualidad: '',

              cursos: [
                // {
                //   titulo: 'SuperIntensivo',
                //   info: '3 MESES',
                //   oferta: '99.67€',
                //   precio: '299€',
                //   select: false
                // }
                // ,
                // {
                //   titulo: 'Intensivo',
                //   info: '6 MESES',
                //   oferta: '45.75€',
                //   precio: '549€',
                //   select: false
                // }
                // ,
                {
                  titulo: 'Ordinario',
                  info: '12 MESES de acceso a la plataforma, estudia a tu ritmo.',
                  oferta: '70.75€',
                  precio: '849€',
                  select: false
                }
                
              ]

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else {
            this.props.history.push('/Preparacion/' + url2TipoPreparacion)
          }

          let preparacion = this.state
          preparacion.oposiciones = oposiciones
          this.setState({ preparacion: preparacion })

        } else if (url2TipoPreparacion == 'Presencial') {
          if (url3Oposicion == 'Aux-Tra-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Presencial',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Preparate Auxilio y Tramitación</b> Libre, en <b>Málaga-Teatinos</b>. Mediante nuestro campus virtual y metodo propio.</p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio y Tramitación</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '130€',
              ofertaMensualidad: '115€',

              cursos: []

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'Presencial',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Preparate Gestión</b> Libre, en <b>Málaga-Teatinos</b>. Mediante nuestro campus virtual y metodo propio.</p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio, Tramitación y Gestión</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '150€',
              ofertaMensualidad: '135€',

              cursos: []

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else {
            this.props.history.push('/Preparacion/' + url2TipoPreparacion)
          }
        } else if (url2TipoPreparacion == 'Online_Video_Presencial') {
          console.log('url3Oposicion---------------------------')
          console.log(url3Oposicion)
          if (url3Oposicion == 'Aux-Tra-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Auxilio y Tramitación Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'ONLINE <div class="tipoGricj">VIDEO PRESENCIAL<div>',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online Auxilio y Tramitación Libre</b>, mediante nuestras clases a distancia con la misma calidad y ventajas que en clases presenciales.</p></div>',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio y Tramitación</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '110€',
              ofertaMensualidad: '90€',

              cursos: []

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else if (url3Oposicion == 'Gestion-libre') {
            let slctPreparacionInfo = {
              nameOpo: 'Gestión Libre',
              url2TipoPreparacion: url2TipoPreparacion,
              url3Oposicion: url3Oposicion,
              tituloLeft: 'ONLINE <div class="tipoGricj">VIDEO PRESENCIAL<div>',
              tituloRight1: '',
              tituloRight2: '',
              contentInfo1: '<div class="minHtTJGJHG"><p><b>Prepárese 100% online Gestión Libre</b>, mediante nuestras clases a distancia con la misma calidad y ventajas que en clases presenciales.</p></div> ',
              contentInfo2: [
                {
                  icono: 'fa-thin fa-user-graduate',
                  txt: '<p>Video <b>Clases Magistrales</b> impartidas por Fernando Díaz, Letrado de la Administración de Justicia</p>'
                },
                {
                  icono: 'fa-thin fa-file-certificate',
                  txt: '<p><b>Material en PDF</b>: legislación, temas resumidos y esquemas exclusivos</p>'
                },
                {
                  icono: 'fa-thin fa-memo-circle-check',
                  txt: '<p>Más de <b>15.000</b> preguntas <b>test</b> y 200 casos prácticos</p>'
                },
                {
                  icono: 'fa-thin fa-comments-question',
                  txt: '<p><b>Resolución de dudas</b>: en clase, tutorías, whatsapp o vía campus virtual</p>'
                },
                {
                  icono: 'fa-thin fa-chart-line-up',
                  txt: '<p>Prepárate <b>Auxilio, Tramitación y Gestión</b> al mismo tiempo</p>'
                }
              ],

              videoUrl: 'https://cejusticia.es/wp-content/uploads/2023/01/VIDEOCURSOSONLINE.mp4',

              precioMensualidad: '130€',
              ofertaMensualidad: '120€',

              cursos: []

            }

            this.setState({ preparacionInfo: slctPreparacionInfo })
          } else {
            this.props.history.push('/Preparacion/' + url2TipoPreparacion)
          }
        }



      }




    }


    console.log('this.props.match.params')
    console.log(this.props.match.params)
    console.log('url')
    console.log(url)



  }



  render() {
    let { preparacionInfo, viewVideo } = this.state
    let { isLogged } = this.props
    console.log('preparacionInfo')
    console.log(preparacionInfo)
    return (
      <div className="CursosCejF3" style={isLogged && isLogged == true ? ({
        position: 'absolute',
        zIndex: '1000',
        top: '0',
        right: '0'
      }) : ({})}>
        <div className="CC-headerNavLeft22 CC-headerNavLeft22F4" style={{ position: 'relative' }}>

          <Tarjeta3mode1 info={preparacionInfo} />


          <div className='CC-HN-top'>

            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>

            <div className='infoStatusPrepaSelection'>
              Usted ha elegido,<br /><br />
              Preparación <b>
                {preparacionInfo && preparacionInfo.url2TipoPreparacion != 'Online_Video_Presencial' ? (
                  preparacionInfo.tituloLeft
                ) : (
                  'online video presencial'
                )}
                {preparacionInfo && ' ' + preparacionInfo.nameOpo}

              </b>
              {preparacionInfo && preparacionInfo.url2TipoPreparacion == 'Cursos_Online' ? (

                '¿Cómo le gustaría prepararse?'

              ) : (
                ', ahora... vamos a reserva la plaza.'

              )}
            </div>



          </div>
          <div className='divAtrasv'>
            <div className='CC-HN-medInfo22' style={{ fontWeight: '400', display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              {preparacionInfo && preparacionInfo.url2TipoPreparacion == 'Cursos_Online' ? (
                <React.Fragment>
                  <b style={{ color: '#b3b3b3', marginRight: '10px', marginBottom: '10px', fontWeight: '400' }}>
                    CURSOS
                  </b>
                  OFERTADOS
                </React.Fragment>
              ) : (
                <React.Fragment>

                  UBICACIÓN
                  <b style={{ color: '#b3b3b3', marginRight: '10px', marginBottom: '10px', fontWeight: '400' }}>
                    MÁLAGA - TEATINOS
                  </b>
                </React.Fragment>
              )}



            </div>
          </div>
          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-headerNavLeft222 CC-headerNavLeft222F4">

          <div className='CC-HN-top2'>
            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>
            <div>
              <div className='CC-HN-NavPrepa'>
                {/* <div className='infoStatusPrepaSelection ctrlWd334'>

                  Usted a elegido,<br /><br />
                  Preparación <b>{preparacionInfo && preparacionInfo.tituloLeft}
                    {preparacionInfo && ' ' + preparacionInfo.nameOpo}

                  </b>. <br />¿Cómo le gustaría prepararse?



                </div> */}

                <div className='infoStatusPrepaSelection ctrlWd334'>
                  Usted ha elegido,<br /><br />
                  Preparación <b>
                    {preparacionInfo && preparacionInfo.url2TipoPreparacion != 'Online_Video_Presencial' ? (
                      preparacionInfo.tituloLeft
                    ) : (
                      'online video presencial'
                    )}
                    {preparacionInfo && ' ' + preparacionInfo.nameOpo}

                  </b>
                  {preparacionInfo && preparacionInfo.url2TipoPreparacion == 'Cursos_Online' ? (

                    '¿Cómo le gustaría prepararse?'

                  ) : (
                    ', ahora... vamos a reserva la plaza.'

                  )}
                </div>
              </div>
            </div>



          </div>



          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-contentCur2 CC-asRafc3g">

          <Tarjeta3mode2 info={preparacionInfo} />


        </div>



        {/* <div className="bottomLogin">
            <Footer />
        </div> */}
      </div>
    );

  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    iniciarSesion
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PreOpoInfo);


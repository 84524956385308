// Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Assets
import './css/css.css'
import Input2 from '../Inputs/Input2'
import Boton from '../Inputs/Boton'
import { isDefined } from '../../lib/utils/is'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openViewBusArt } from '../../actions/userAction'

class BuscarDeLeyes extends Component {
	static propTypes = {

	}

	constructor(props) {
		super(props)
		this.state = {
			leySeleted: null,
			valueInput: null
		}

	
		this.onChangeInput = this.onChangeInput.bind(this)
		this.onHandleClick = this.onHandleClick.bind(this)
	}
	
	async onChangeInput(e) {
		if (e.target.id == 'leySelected') {
			if (isDefined(e.target.value)) {
				this.setState({ valueInput: e.target.value })
			} else {
				this.setState({ valueInput: null, leySeleted: null })
			}

			let leyes = this.state.leyes
			let capturaSiglas = e.target.value
			let objectLeySelec = leyes.find(l => l.siglas_ley == capturaSiglas)
			if (isDefined(objectLeySelec)) {
				
				this.setState({ leySeleted: objectLeySelec.id_ley})
			}

		}
	}

	onHandleClick(e) {

		if (e.target.id.split('-')[0] == 'openLey') {
			let locaLey = e.target.id.split('-')[1]
			let urlLey = this.state.leyes.find(l => l.id_ley == locaLey).link_ley
			if (isDefined(urlLey)) {
				window.open(urlLey)
			} else {
				swal('Link no valido', 'Será arreglado lo antes posible', 'error')
			}

		} else if (e.target.id == 'openBusArt') {
			this.props.openViewBusArt(true)
		}

	}

	componentDidMount() {
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
			.then(leyes => this.setState({
				leyes: leyes
			}))
	}

	render() {
		const { leyes, leySeleted, valueInput } = this.state


	

		return (
			<div className="bodyBuscadorLeyes">
				<div className="topNavBusLey">
					<p>Legislación</p>
					<div style={{ width: '200px' }}>
						<Input2 deletedMode={valueInput != null ? (true) : (false)} type="text" id="leySelected" name="leySelected" placeholder="Buscar Ley" icon="fas fa-search" datalist="leyesLoad" ref="ref_leySelected" onChange={this.onChangeInput} />
					</div>
					<datalist id="leyesLoad">{
						leyes && leyes.map((cls, key4345) =>
							<option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
						)
					}</datalist>
				</div>
				
				{isDefined(leySeleted) && leySeleted != '' ? (
					<div className="contentBusLeyes">
						<div className="infoLeyeshj0">
							<div className="siglasILHJ0"> {leyes && leyes.find(l => l.id_ley == leySeleted).siglas_ley} </div>
							<div className="nombreILHJ0"> {leyes && leyes.find(l => l.id_ley == leySeleted).nombre_ley}</div>

							<div className="ia2lerthgd">
								<Boton id={"openLey-" + leySeleted} icon1="" icon2="" texto="Abrir Ley" onClick={this.onHandleClick} />
							</div>
						</div>
						<div className="backgroundLeyes">
							<img src="../img/backgroundLeyes.jpg" />
						</div>
					</div>
				) : (
					<div className="contentBusLeyes">
						<div className="infoLeyeshj0" style={{
							minWidth: 'unset',
							minHeight: 'unset',
							top: '0'
						}}>

							<div className='RowCenterCenter' style={{ padding: '0px', color: 'black' }}>

								<Boton id={"openBusArt"} icon1="" icon2="" texto="Buscar Artículos" onClick={this.onHandleClick} />

							</div>

						</div>
						<div className="backgroundLeyes">
							<img src="../img/backgroundLeyes.jpg" />
						</div>
					</div>
				)}

			</div>
		)
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		openViewBusArt
	}, dispatch)
}
export default connect(null, mapDispatchToProps)(BuscarDeLeyes);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class LoadTest extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
        	this.state = {
			displayNameTest: 'none',
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		    ]
		};
		// this.closePdf = this.closePdf.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e){
		this.props.onChange(e);
		if(e.target.id == 'testPredefinido'){
			if(e.target.value == 1){
				this.setState({displayNameTest: 'flex'});
			} else if (e.target.value == 0) {
				this.setState({displayNameTest: 'none'});
			}
		}
	}
	handleOnClick(e){
		this.props.onClick(e);
	}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		.then(res => res.json())
		.then(leyes => this.setState({
			busquedaLeyes: leyes
		}))
		.catch(error => console.log('Obejet fecth failed', error))

	}
	render() {
		const { id, width, height, urlPdf, toClose } = this.props;
		const { values, displayNameTest, busquedaOpo } = this.state;
		return (
			<div className= "bodyLoadTest">
				<div className="opocionesLoadTest">
					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							¿Creamos un Test predefinido?
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<InputSelect values={values} id="testPredefinido" name="testPredefinido" icon="fas fa-hand-pointer" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest" style={{display: displayNameTest}}>
						<h4>
							<i className="fas fa-info-circle"></i>
							Nombre para el Test
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" id="testPredefinidoName" name="testPredefinidoName" icon="fas fa-tag" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						¿A qué oposición pertenece?
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion" id="oposicionTest" name="oposicionTest" icon="fas fa-search" onChange={this.onChange}/>
					<datalist id="oposcionesData">{
						busquedaOpo && busquedaOpo.map((cls, key) =>
							<option key={key} value={cls.nombre}></option>
						)
					}</datalist>

					</div>
				</div>

				<div className="textAreaLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Pegar Test completo
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<InputTextArea id="infoTest" name="infoTest" valueDefault="¡Pegar test completo aquí!" width="100%" height="20vh" onChange={this.onChange}/>
					<Submit id="prepararTest" icon1="fas fa-upload" icon2="fas fa-database" texto="Preparar Test para Insertar" onClick={this.handleOnClick}/>
				</div>

			</div>
		);
	}
}

export default LoadTest;

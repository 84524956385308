// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/pdf.css';

class Pdf extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		width: PropTypes.string.isRequired,
		height: PropTypes.string.isRequired,
		urlPdf: PropTypes.string.isRequired,
		toClose: PropTypes.number.isRequired
  };

	constructor(props){
		super(props);
        	this.state = {};
		this.closePdf = this.closePdf.bind(this);
    }
closePdf(e){
	this.props.onClick(e);
}
	render() {
		const { id, width, height, urlPdf, toClose } = this.props;
		return (
			<div className= "bodyPdfs">
				<div id={toClose+'pdf'} className="closePdf" onClick={this.closePdf} hidden> Cerrar PDF </div>
				<object className="pdf" id={id} width={width} height={height} data={urlPdf+'#toolbar=0'} type="application/pdf" hidden>
						<embed src={urlPdf+'#toolbar=0'} type="application/pdf" />
				</object>
			</div>
		);
	}
}

export default Pdf;

import React, { Component } from 'react';
import './css/Footer.css';

import BotonFinolis from '../../Inputs/Boton3Finolis'

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewNormasAca: false,
      infoTipoCurso: 'presencial'
    }

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {

    if (e.target.id == 'normasAca') {
      this.setState({ viewNormasAca: this.state.viewNormasAca == true ? false : true })
    } else if (e.target.id == 'nP') {

      this.setState({ infoTipoCurso: this.state.infoTipoCurso == 'presencial' ? ('Cursos_Online') : ('presencial') })

    }


  }
  render() {

    const { viewNormasAca, infoTipoCurso } = this.state;
    return (
      <div className="footerLoginBar">
        <div id="normasAca" className='linkFo' onClick={this.handleOnClick}>
          Normas Academia
        </div>
        <div id="link">
          Aviso Legal
        </div>
        <div id="link">
          Política de privacidad
        </div>
        <div id="link">
          Ley de Cookies
        </div>
        <div>
          &copy; 2021 CEJ
        </div>

        {viewNormasAca == true ? (
          <div className='flyingad34' style={{width: '100%'}}>

            <div class="scrollbarTienda" id="style-2">

              <div className='tNCOA-nav'>
                <div>
                  <BotonFinolis texto={'Cerrar normas'} id="normasAca" onClick={this.handleOnClick} />
                </div>

              </div>

              <b className='tNCAtitles21'>Normas Academia</b>

              <div className='tNCOA-nav'>
                <div>
                  <BotonFinolis texto={infoTipoCurso != 'presencial' ? ('Ver Normas Prensecial/Online') : ('Ver Normas Cursos')} id="nP" onClick={this.handleOnClick} />
                </div>

              </div>
              {
                infoTipoCurso && infoTipoCurso != 'Cursos_Online' ? (
                  <div className='tNCA-infoTexto scrollbar' id="style-2" style={{ width: 'auto' }}>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Normas presencial / online por videoconferencia.
                      </p>
                    </div>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Los pagos son por mensualidades completas y se realizan del 1 al 5 del mes. Mediante Tarjeta, Google Pay, Transferencia SEPA através del campus virtual ó Bizum y pago en Metálico en la Academia. La cuota abonada no se devolverá si el alumno abandona antes de finalizar el mes.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        En caso de desear dejar de asistir a clases el mes siguiente, deberá comunicarlo con 5 días de antelación del
                        inicio del mes. De no hacerlo así, deberá abonar la mensualidad entera correspondiente.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La última semana del año y Semana Santa las clases son Online, la preparación se realiza con los materiales y
                        videos que tendrá disponibles en el Aula virtual. El mes de agosto las clases son Online por video conferencia
                        las dos primeras semanas.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Los festivos de ámbito Nacional o Local se consideran días no lectivos, la preparación se realiza con los
                        materiales y videos que tendrá disponibles en el Aula virtual.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
                        dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
                      </p>
                    </div>

                  </div>
                ) : (
                  <div className='tNCA-infoTexto scrollbar' id="style-2" style={{ width: 'auto' }}>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Normas Cursos Online
                      </p>
                    </div>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Las características principales de los bienes o servicios, en la medida adecuada al soporte utilizado y a los bienes o servicios de que se trate: Curso Online para preparación de oposiciones de justicia, mediante uso de plataforma virtual que contiene vídeos, resúmenes, test, casos prácticos, y en su caso planificación del estudio.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La identidad del empresario, incluido su nombre comercial: Bárbara Alaminos Castillo, con nombre comercial Centro de Estudios Judiciales Málaga.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La dirección completa del establecimiento del empresario, número de teléfono y dirección de correo electrónico: Calle Pirandello 16, 3ª planta, Oficina 6, Málaga, teléfono 688 98 80 99 y email centrodeestudiosjudiciales@gmail.com
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Una vez realizado el pago,tendrá acceso total a todo el contenido del curso. La cantidad abonada no se devolverá, aunque el alumno abandone antes de finalizar el curso.
                      </p>
                    </div>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        DESISTIMIENTO DEL PRESENTE CONTRATO DE PRODUCTOS O SERVICIOS EN SOPORTE DIGITAL.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />

                      <p>
                        Se comunica a los efectos oportunos que la posibilidad de desistimiento prevista en la Ley General para la Defensa de Consumidores y Usuarios no es aplicable al curso online que está contratando, por resultar aplicable el artículo 103.a de la citada Ley, una vez realice el primer acceso a la plataforma digital con sus claves personales, ya que implica el cumplimiento, ejecución, total del contrato por parte de CEJ.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Podrá realizar el pago através del campus virtual. Metodos de pago: Page en 3 plazos sin intereses o un unico pago con Tarjeta, Google Pay ó Transferencia SEPA.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Si desea realizar el pago presencialmente acudiendo a nuestra academia en Málaga Teatinos junto al palacio de justicia, tendrá las opciones de Bizum y pago en Metálico.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La preparación se realiza con los materiales y videos que tendrá disponibles en el Campus Virtual www.oposiciones-justicia-es.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
                        dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
                      </p>
                    </div>

                  </div>
                )}

            </div>


          </div>

        ) : null}

      </div>
    );
  }
}

export default Footer;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import bcrypt from 'bcryptjs';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets
import Input from '../../../../../components/Inputs/Input';
import InfoAlumno from '../../../../../components/InfoAlumno';

class OptionBar extends Component {

	static propTypes = {
		selectedOption: PropTypes.string.isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
			infoAlumnoTest: [],
			infoAlumnoTemario: [],
			displayInputClase: 'none',
			displayInputAlumnos: 'none',
			displayInfoAlumnos: false
		}
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	onChangeInput(e) {
		this.setState({ [e.target.name]: e.target.value });
		if (this.props.selectedOption == 'Buscar_Alumno') {
			if (e.target.name === 'oposicionSelecionada') {
				let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
				if (oposicionObject != undefined) {
					let data = { id_oposicion: oposicionObject.id_oposicion };
					fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(response => {
						if (response.status >= 400) { throw new Error("Bad response from server"); }
						return response.json();
					}).then(cls => this.setState({ busquedaClasesSemanas: cls, displayInputClase: 'block' }))
						.catch(error => console.log('Object fecth failed', error))
				} else {
					this.setState({ busquedaClasesSemanas: '', displayInputClase: 'none', displayInputAlumnos: 'none' });
				}
			} else if (e.target.name === 'claseSelecionada') {
				let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
				let idOposicion = oposicionObject.id_oposicion;
				let claseObject = this.state.busquedaSemClasesAll.find(clase => clase.dia_semanal == e.target.value && clase.id_oposicion == idOposicion);
				if (isDefined(idOposicion) && isDefined(claseObject)) {
					this.setState({ idClaseAlumno: claseObject.id_clase, idOposicion: idOposicion });
					let data = { idClase: claseObject.id_clase }

					fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(response => {
						if (response.status >= 400) { throw new Error("Bad response from server"); }
						return response.json();
					}).then(cls => {
						if (cls != undefined) {
							let alumnosClase = [];
							for (var i = 0; i < cls.length; i++) {
								alumnosClase.push({
									idAlumno: cls[i].idAlumno,
									nombreAlumno: cls[i].nombreAlumno,
									apellidosAlumno: cls[i].apellidosAlumno,
									linkFotoPerfil: cls[i].linkFotoPerfil
								})
							}
							this.setState({ alumnosClase: alumnosClase, displayInputAlumnos: 'block' })
						}
					}).catch(function (err) { console.log(err) });
				} else {
					this.setState({ alumnosClase: undefined, idClaseAlumno: undefined, idOposicion: undefined, displayInputAlumnos: 'none' })
				}
			} else if (e.target.name == 'alumnoSeleccionado') {

				let idAlumnoSelected = e.target.value;
				let posicionAlumno = this.state.alumnosClase.findIndex(alumno => alumno.idAlumno == idAlumnoSelected)
				if (posicionAlumno != -1 && isDefined(this.state.idClaseAlumno)) {
					let data = { idAlumno: idAlumnoSelected, idClase: this.state.idClaseAlumno, idOposicion: this.state.idOposicion }
					let alumnoSelectedInfo = [];
					alumnoSelectedInfo.push({
						idAlumno: this.state.alumnosClase[posicionAlumno].idAlumno,
						nombreAlumno: this.state.alumnosClase[posicionAlumno].nombreAlumno,
						apellidosAlumno: this.state.alumnosClase[posicionAlumno].apellidosAlumno,
						linkFotoPerfil: this.state.alumnosClase[posicionAlumno].linkFotoPerfil
					})
					this.setState({ alumnoSelectedInfo: alumnoSelectedInfo , displayInfoAlumnos: true })
					
				} else {
					this.setState({ infoAlumnoTest: undefined, infoAlumnoTemario: undefined, displayInfoAlumnos: false })
				}
			}
		}
	}

	componentDidMount() {
		fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
			.then(res => res.json())
			.then(clases => this.setState({
				busquedaSemClasesAll: clases
			})).catch(error => console.log('Object fecth failed', error));

		fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
			.then(res => res.json())
			.then(alumnos => this.setState({
				busquedaAlumnos: alumnos
			})).catch(error => console.log('Object fecth failed', error));

		fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
			.then(res => res.json())
			.then(meca => this.setState({
				busquedaMeca: meca
			}))
			.catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
			.then(res => res.json())
			.then(oposiciones => this.setState({
				busquedaOpo: oposiciones
			})).catch(error => console.log('Object fecth failed', error));


	};

	render() {
		const { selectedOption } = this.props;
		const { busquedaAlumnosPorClase, busquedaClasesSemanas, busquedaOpo, infoAlumnoTemario, infoAlumnoTest, displayInfoAlumnos, displayInputAlumnos, displayInputClase, alumnoSelectedInfo, alumnosClase } = this.state
		switch (selectedOption) {
			case 'Buscar_Alumno': {
				return (
					<form className="formMeca">
						<Input type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />
						<Input display={displayInputClase} type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />
						<Input display={displayInputAlumnos} type="text" name="alumnoSeleccionado" placeholder="Buscar Alumno" icon="fas fa-search" datalist="busquedaAlumnosPorClase" ref="ref_alumnoSeleccionado" onChange={this.onChangeInput} />

						{displayInfoAlumnos ? (
							<div className="displayInfoAlumnos">
								<InfoAlumno idAlumno={alumnoSelectedInfo[0].idAlumno} />
							</div>
						) : ('')}
						<datalist id="busquedaAlumnosPorClase">{
							alumnosClase && alumnosClase.map((cls, key) =>
								<option key={key} value={cls.idAlumno}>{cls.nombreAlumno + ' ' + cls.apellidosAlumno}</option>
							)}
						</datalist>
						<datalist id="busquedaClasesSemanas">{
							busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
								<option key={key} value={cls.dia_semanal}></option>
							)}
						</datalist>
						<datalist id="busquedaOpo">{
							busquedaOpo && busquedaOpo.map((opo, key) =>
								<option key={key} value={opo.nombre}></option>
							)}
						</datalist>
					</form>
				);
			} break;
			default: {
				return (
					<div style={{ fontSize: '24px' }} >
						<i style={{ color: '#00ddcb', margin: '0px 4px' }} className="fas fa-bullhorn"></i>
						Selecciona una opción para continuar.
						<i style={{ color: '#00ddcb', margin: '0px 4px' }} className="fas fa-hand-pointer"></i>
					</div>
				);
			}
		}
	}
}

export default OptionBar;

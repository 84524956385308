// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Utils
import { isDefined } from '../../../../../lib/utils/is';
import  { separarPreguntasTestJusticia } from '../utils/testJusticiaPreguntas';
//Assets
import '../css/testJusticia.css';
//Componets
import Input from '../../../../../components/Inputs/Input';
import InputSelect from '../../../../../components/Inputs/InputSelect';
import Submit from '../../../../../components/Inputs/Submit';


import LoadTest from '../../../../../components/testCasoPractico/LoadTest';

import LoadTestEdt from '../../../../../components/testCasoPractico/LoadTestEdt';
import InsertTest from '../../../../../components/testCasoPractico/InsertTest';
import EdtTest from '../../../../../components/testCasoPractico/EdtTest';
import DeleteTest from '../../../../../components/testCasoPractico/DeleteTest';
import BusquedaEditarPreguntas from '../../../../../components/testCasoPractico/BusquedaEditarPreguntas';
import EditarPreguntas from '../../../../../components/testCasoPractico/EditarPreguntas';
import DuplicarTestJusticia from '../../../../../components/testCasoPractico/DuplicarTestJusticia';

//Redux
import  {connect}  from 'react-redux';
import  {bindActionCreators}  from 'redux';
// Actions
import { cargarContenido } from '../actions/baroptionAction';
import { cargarTestParaInsertar, eliminarTestJusticiaCache, eliminarTestJusticiaEdtCache, cargarTestParaEditar, genPregToDeleteEdt } from '../actions/testCasoPraticoAction';
class OptionBar extends Component {

static propTypes = {
	selectedOption: PropTypes.string.isRequired
};
constructor(props){
	super(props);
	this.state = {
		busquedaOpo: 'flex',
		testCompleto : [{
		   testPredefinido: 1,
		   nombreTest: undefined,
		   oposicion: undefined,
			 casoPracticoText: undefined,
		   preguntas: []
	   }],
	   testTemplate : [{
		 testPredefinido: undefined,
		 nombreTest: undefined,
		 idTest: undefined,
		 oposicion: undefined,
		 casoPracticoText: undefined,
		 preguntas: []
	 }],
	 preguntasTestNuevo : [{
	    testPredefinido: undefined,
	    nombreTest: undefined,
	    idTest: undefined,
	    oposicion: undefined,
	    preguntas: [{
		    numPreg: 1,
		    pregunta: '',
		    idPregunta: '',
		    tipoPregunta: '',
		    ley: '',
		    idLey: '',
		    articulo: '',
		    r1: '',
		    r1_validez: '',
		    idRespuesta1: '',
		    r2: '',
		    idRespuesta2: '',
		    r2_validez: '',
		    r3: '',
		    idRespuesta3: '',
		    r3_validez: '',
		    r4: '',
		    idRespuesta4: '',
		    r4_validez: '',
		    notaA: '',
		    notaB: '',
		    notaC: '',
		    notaD: '',
		    subrayadoA: '',
		    subrayadoB: '',
		    subrayadoC: '',
		    subrayadoD: '',
		    preguntaCheck: 0,
		    insertar: 1
	    }]
    }]

	}
	this.onChangeInput = this.onChangeInput.bind(this);
	this.handleSubmitForm = this.handleSubmitForm.bind(this);
	this.cleanForm = this.cleanForm.bind(this);
}
cleanForm(){
	switch (this.props.selectedOption) {
		 case 'Insertar_Test_Justicia': {
			 this.setState({oposicion_add: ''});
			 this.refs.ref_oposicion_add.refs.oposicion_add.value='';
		 }break;
	 }
 }
onChangeInput(e, textHtml = ''){
	switch (this.props.selectedOption) {
		case 'Insertar_Caso_Practico': {
			if (textHtml != '' ){
				let testCompletoChange = this.state.testCompleto;
				testCompletoChange[0].casoPracticoText = textHtml;
				this.setState({testCompleto: testCompletoChange})
			} else if(e.target.name == 'infoTest'){
				this.setState({textAreaInfo: e.target.value})
			} else if (e.target.name == 'testPredefinidoName' ){
				let testCompletoChange = this.state.testCompleto;
				testCompletoChange[0].nombreTest = e.target.value;
				this.setState({testCompleto: testCompletoChange})
			} else if (e.target.name == 'oposicionTest' ){
				let objectOposicion = this.state.busquedaOpo.find(opo => opo.nombre == e.target.value)
				if(objectOposicion != undefined){
					let testCompletoChange = this.state.testCompleto;
					testCompletoChange[0].oposicion = objectOposicion.id_oposicion;
					this.setState({testCompleto: testCompletoChange})
				}
			}
		}break;
	}
}

  async handleSubmitForm(e, state2 = ''){
	  switch (e.target.id) {
			case 'prepararTest': {
				try{
			  let textArea = this.state.textAreaInfo
				if(textArea.split('"') && textArea.split('"').length > 1){
 				 throw new Error('Error uso de comillas dobles o simples');
 			 } else {
				 let textArea2 = separarPreguntasTestJusticia(textArea, this.state);
				  setTimeout(
				      function() {
						 if( textArea2 != false && textArea2 != undefined){
	     				  this.props.cargarTestParaInsertar(textArea2)
	     				  this.props.cargarContenido("Insertar_Caso_Practico_Cargado")
	     			  	}
				      }.bind(this),2000);
			 }

			}catch(e){
				if( e.message == "Error uso de comillas dobles o simples"){
					swal('Error en texto del caso practico','No puede contener comillas dobles, reemplacelas por simples','warning')
				}
			}
		  }break;

			case 'cargarTestMemoria': {
				this.props.cargarContenido("Insertar_Caso_Practico_Cargado")
			}break;

			case 'eliminarTestMemoria': {
			  swal({
		  	  title: "¿Estas segur@?",
		  	  text: "Si le das a ok, el test de la memoria será eliminado y podrá introducir uno nuevo.",
		  	  icon: "warning",
		  	  buttons: true,
		  	  dangerMode: true,
		  	})
		  	.then((willDelete) => {
		  	  if (willDelete) {
				  this.setState({textAreaInfo: undefined, testCompleto : [{
		  		   testPredefinido: undefined,
		  		   nombreTest: undefined,
		  		   oposicion: undefined,
		  		   preguntas: []
		  	   }]})
				  this.props.eliminarTestJusticiaCache();
		  	  } else {
		  	    swal("¡Test no borrado!",'',"success");
		  	  }
		  	});
		  }break;


			case 'prepararCasoPracticoEditar': {
			  let idOposicion = state2.idOposicion
			  let idTest = state2.idTest
			  let nombreTest = state2.nombreTest
				let textHtmlInit = state2.textHtmlInit
				let textHtml = state2.textHtml

			  if( idOposicion != undefined && idTest != undefined){
				  let { testTemplate } = this.state
				  let data = {
					  idOposicion: idOposicion,
					  idTest: idTest
				  }
					try{
						let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompletoCP", {
							method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
							if (!response1.ok) {
								throw Error(response1.statusText);
							}
						let json1 = await response1.json();
						let cls1 = await json1;

						let testTemplateSave = [{
							testPredefinido: undefined,
							nombreTest: undefined,
							idTest: undefined,
							oposicion: undefined,
							casoPracticoText: undefined,
							casoPracticoTextNew: undefined,
							preguntas: []
						}];
						testTemplateSave[0].idTest = idTest
						testTemplateSave[0].nombreTest = nombreTest
						testTemplateSave[0].oposicion = idOposicion
						testTemplateSave[0].casoPracticoText = textHtmlInit
						testTemplateSave[0].casoPracticoTextNew = textHtml

						for (var i = 0; i < cls1.length; i++) {

							let articuloRevisado = ''
							  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
							    let artDFG= cls1[i].articulo.split('.y.')
							    for (var k = 0; k < artDFG.length; k++) {
							      if(k != artDFG.length-1 && k != artDFG.length-2){
							        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
							      } else if(k == artDFG.length-2){
							        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
							      } else if(k == artDFG.length-1){
							        articuloRevisado = articuloRevisado + artDFG[k]
							      }
							    }
							  } else {
							    articuloRevisado = cls1[i].articulo
							  }

							testTemplateSave[0].preguntas.push({
							 editar: 0,
							 insertar: 0,
							 borrar: 0,
							 numPregReal: cls1[i].numPreg,
							 numPreg: i+1,
							 pregunta: cls1[i].pregunta,
							 idPregunta: cls1[i].idPregunta,
							 tipoPregunta: cls1[i].tipoPregunta,
							 ley: cls1[i].siglasLey,
							 idLey: cls1[i].idLey,
							 articulo: articuloRevisado,
							 r1: cls1[i].R1,
							 r1_validez: cls1[i].validezR1,
							 idRespuesta1: cls1[i].idR1,
							 r2: cls1[i].R2,
							 idRespuesta2: cls1[i].idR2,
							 r2_validez: cls1[i].validezR2,
							 r3: cls1[i].R3,
							 idRespuesta3: cls1[i].idR3,
							 r3_validez: cls1[i].validezR3,
							 r4: cls1[i].R4,
							 idRespuesta4: cls1[i].idR4,
							 r4_validez: cls1[i].validezR4,
							 notaA: cls1[i].nota1,
							 notaB: cls1[i].nota2,
							 notaC: cls1[i].nota3,
							 notaD: cls1[i].nota4,
							 subrayadoA: cls1[i].subrayado1,
							 subrayadoB: cls1[i].subrayado2,
							 subrayadoC: cls1[i].subrayado3,
							 subrayadoD: cls1[i].subrayado4,
							 preguntaCheck: 0
						 })
					 }
					 await this.setState({testTemplate: testTemplateSave})
					 await this.props.cargarTestParaEditar(testTemplateSave)
					 await this.props.cargarContenido("Editar_Caso_Practico_Cargado")
				} catch(e){console.log(e)}





			  }

		  }break;
			case 'cargarTestMemoriaEdt': {
			  this.props.cargarContenido("Editar_Caso_Practico_Cargado")
		  }break;
			case 'eliminarTestMemoriaEdt': {
			  swal({
		  	  title: "¿Estas segur@?",
		  	  text: "Si le das a ok, el Caso Practico de la memoria será eliminado y no se guardaran los datos editados.",
		  	  icon: "warning",
		  	  buttons: true,
		  	  dangerMode: true,
		  	})
		  	.then((willDelete) => {
		  	  if (willDelete) {
				  this.setState({ testTemplate : [{
		    		 testPredefinido: undefined,
		    		 nombreTest: undefined,
		    		 idTest: undefined,
		    		 oposicion: undefined,
						 casoPracticoText: undefined,
						 casoPracticoTextNew: undefined,
		    		 preguntas: []
		    	 }]})
				  this.props.eliminarTestJusticiaEdtCache();
		  	  } else {
		  	    swal("¡Test no borrado!",'',"success");
		  	  }
		  	});
		  }break;

		case 'loadEdtPregunta': {
			this.props.cargarContenido("Editar_Load_Pregunta_Caso_Practico")

		}break;
		case 'buscarPreguntasEdt': {
			this.props.cargarContenido("Select_Editar_Pregunta_Caso_Practico")
		}break;

		case 'deleteEdtPregunta': {
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, las preguntas seleccionadas para editar serán borradas.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {

				this.props.genPregToDeleteEdt(undefined);

				} else {
					swal("¡Preguntas cargadas no borradas!",'',"success");
				}
			});
		}break;

	  }
  }

componentDidMount(){
	fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
	.then(res => res.json())
	.then(oposiciones => this.setState({
		busquedaOpo: oposiciones
	})).catch(error => console.log('Object fecth failed', error));

	fetch('https://oposiciones-justicia.es/api/leyes/consulta')
	.then(res => res.json())
	.then(leyes => this.setState({
		busquedaLeyes: leyes
	}))
	.catch(error => console.log('Obejet fecth failed', error))
};

render() {
	const { selectedOption, nombreUser, datosCasoPractico, datosCasoPracticoEdt, pregToEdtCP } = this.props;
	switch (selectedOption) {
		case 'Insertar_Caso_Practico': {
			return (
				<div>
				{datosCasoPractico != undefined ? (
					<div className="bodyInsertarTJ">
						<h44> <i className="fas fa-exclamation-triangle"></i>Detectado Caso Práctico en memoria sin Insertar <i className="fas fa-exclamation-triangle"></i></h44>
						<Submit id="cargarTestMemoria" icon1="fas fa-recycle" icon2="fas fa-recycle" texto="Seguir con insercción del Caso P." onClick={this.handleSubmitForm}/>
						<Submit id="eliminarTestMemoria" icon1="fas fa-trash-alt" icon2="fas fa-trash-alt" texto="Eliminar Caso P. de memoria" onClick={this.handleSubmitForm}/>
					</div>
				):(<LoadTest onClick={this.handleSubmitForm} onChange={this.onChangeInput}/>)}
				</div>
			);
		}
		break;
		case 'Insertar_Caso_Practico_Cargado': {
			return (
				<div>
				{datosCasoPractico != undefined ? (
					<div className="bodyInsertarTJ">
						<InsertTest onClick={this.handleSubmitForm} />
					</div>
				):(<div> {this.props.cargarContenido("Insertar_Caso_Practico")} </div>)}
				</div>
			);
		}
		break;
		case 'Editar_Caso_Practico': {
			return (
				<div>
				{datosCasoPracticoEdt != undefined ? (
					<div className="bodyInsertarTJ">
						<h44> <i className="fas fa-exclamation-triangle"></i>Detectado Caso Práctico en memoria sin Finalizar<i className="fas fa-exclamation-triangle"></i></h44>
						<Submit id="cargarTestMemoriaEdt" icon1="fas fa-recycle" icon2="fas fa-recycle" texto="Seguir con edicción del Test" onClick={this.handleSubmitForm}/>
						<Submit id="eliminarTestMemoriaEdt" icon1="fas fa-trash-alt" icon2="fas fa-trash-alt" texto="Eliminar Test de memoria" onClick={this.handleSubmitForm}/>
					</div>
				):(<LoadTestEdt onClick={this.handleSubmitForm} onChange={this.onChangeInput}/>)}
				</div>
			);
		}
		break;
		case 'Editar_Caso_Practico_Cargado': {
			return (
				<div>
				{datosCasoPracticoEdt != undefined ? (
					<div className="bodyInsertarTJ">
						<EdtTest onClick={this.handleSubmitForm} />
					</div>
				):(<div> {this.props.cargarContenido("Editar_Caso_Practico",  window.location.reload())} </div>)}
				</div>
			);
		}
		break;
		case 'Borrar_Caso_Practico': {
			return (
				<div>
					<div className="bodyInsertarTJ">
						<DeleteTest onClick={this.handleSubmitForm} />
					</div>
				</div>
			);
		}
		break;
		case 'Editar_Pregunta_Caso_Practico': {
			return (
				<div>
					{pregToEdtCP && pregToEdtCP.length > 0 && pregToEdtCP != undefined ? (
						<div className="bodyInsertarTJ">
							<h44> <i className="fas fa-exclamation-triangle"></i>Preguntas en Memoria sin finalizar Edición. <i className="fas fa-exclamation-triangle"></i></h44>
							<Submit id="loadEdtPregunta" icon1="fas fa-recycle" icon2="fas fa-recycle" texto="Continuar editando" onClick={this.handleSubmitForm}/>
							<Submit id="deleteEdtPregunta" icon1="fas fa-trash-alt" icon2="fas fa-trash-alt" texto="Limpiar memoria" onClick={this.handleSubmitForm}/>
						</div>
					):(
						<div>
							<div className="bodyInsertarTJ">
								<Submit id="buscarPreguntasEdt" icon1="fas fa-search" icon2="fas fa-recycle" texto="Buscar Preguntas para Editar" onClick={this.handleSubmitForm}/>
							</div>
						</div>
					)}
				</div>
			);
		}
		break;
		case 'Select_Editar_Pregunta_Caso_Practico': {
			return (
						<div>
							<div className="bodyInsertarTJ">
								<BusquedaEditarPreguntas onClick={this.handleSubmitForm} />
							</div>
						</div>
			);
		}
		break;
		case 'Editar_Load_Pregunta_Caso_Practico': {
			return (
				<div>
				{ pregToEdtCP && pregToEdtCP.length > 0 && pregToEdtCP != undefined ? (
					<div className="bodyInsertarTJ">
						<EditarPreguntas onClick={this.handleSubmitForm} />
					</div>
				):(<div> {this.props.cargarContenido("Editar_Pregunta_Caso_Practico")} </div>)}
				</div>
			);
		}
		break;
		case 'Duplicar_Caso_Practico': {
			return (
				<div>
					<div className="bodyInsertarTJ">
						<DuplicarTestJusticia onClick={this.handleSubmitForm} />
					</div>
				</div>
			);
		}
		break;
		default: {
			return (
				<div style={{fontSize: '24px'}} >
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
					Selecciona una opción para continuar.
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
				</div>
			);
		}
	}
}

}

// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		datosCasoPractico: state.casoPractico.datosCasoPractico,
		datosCasoPracticoEdt: state.casoPractico.datosCasoPracticoEdt,
		pregToEdtCP: state.casoPractico.pregToEdtCP
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		cargarTestParaInsertar,
		eliminarTestJusticiaCache,
		cargarTestParaEditar,
		eliminarTestJusticiaEdtCache,
		cargarContenido,
		genPregToDeleteEdt
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionBar);

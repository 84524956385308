// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';

//Assets
import '../css/examGesPre.css';

// Utils
import { isDefined } from '../../../../../lib/utils/is';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


//Componets
import Input from '../../../../../components/Inputs/Input';
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import BotonMedio from '../../../../../components/Inputs/BotonMedio';
import InputTextArea from '../../../../../components/Inputs/InputTextArea';


class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    nombreUser: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      displayFecha: 'none',
      ejerciciosClase: undefined,
      temasClase: undefined,
      intervalosExtraClase: undefined,
      alumnoSelectedInfo: undefined,
      semanaPrg: undefined,
      alumnSelectExam: undefined
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.loadAlumno = this.loadAlumno.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);

  }


  async loadAlumno(e) {
    if (e.target.id.split('-')[0] == 'alumnCBCE') {
      let locaBloque = e.target.id.split('-')[1]
      let locaAlumno = e.target.id.split('-')[2]

      let alumnSelectExam = await this.state.semanaPrg[0].bloqueTemas[locaBloque].alumnos[locaAlumno].infoExamenGestion

      if (alumnSelectExam != undefined && alumnSelectExam.puntuacion == null) {

        alumnSelectExam.editar = 0
        await this.setState({ alumnSelectExam: alumnSelectExam })
      } else if (alumnSelectExam != undefined && alumnSelectExam.puntuacion != null) {

        alumnSelectExam.editar = 1
        await this.setState({ alumnSelectExam: alumnSelectExam })
        this.refs.ref_puntuacionExamAlumno.refs.puntuacionExamAlumno.value = alumnSelectExam.puntuacion
        if (alumnSelectExam.anotaciones != null) {
          this.refs.ref_aclaracionExam.refs.aclaracionExam.value = alumnSelectExam.anotaciones
        }


      } else if (alumnSelectExam == undefined) {
        swal('Examen no entregado', 'No se puede corregir porque el alumno no subio el examen aún.', 'success')
      }

    }
  }

  downloadPdf(e) {
    if (this.state.alumnSelectExam != undefined && this.state.alumnSelectExam.linkArchivoAlumno != undefined && this.state.alumnSelectExam.linkArchivoAlumno != '') {
      swal('La descargar se iniciara en breve', '', 'success')
      let pathFile = this.state.alumnSelectExam.linkArchivoAlumno
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = pathFile.split('/').pop().split('-,-')[0];
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    }
  }
  toggle(e) {

    if (e.target.id.split('-')[0] == 'bloq') {
      let locaBloque = e.target.id.split('-')[1]
      $('#contentBCE' + locaBloque).slideToggle(1000);
    }

  }

  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Corregir_Examen_Gestion') {

      if (e.target.name === 'claseSeleccionada') {
        var e = e
        let claseObject = await this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {
          try {
            let data1 = { idClase: claseObject.idClase }
            await this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });

            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined) {
              await this.setState({ busquedaClasesFecha: cls1 });
              //this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = moment().format('MM-YY');
            }

            var busquedaClasesFecha2 = await this.state.busquedaClasesFecha
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].idPrograma }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.idPrograma == cls3[z].idPrg && c.semanaClase == busquedaClasesFecha2[k].semanaClase);
                if (locaBloque != -1) {
                  busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema
                  })
                }
              }
            }

            await this.setState({ busquedaClasesFecha: busquedaClasesFecha2, displayFecha: 'block' });

          } catch (e) { console.log(e) }
        } else {
          this.setState({ semanaPrg: undefined, busquedaClasesFecha: undefined, displayFecha: 'none', ejerciciosClase: undefined, temasClase: undefined, intervalosExtraClase: undefined })
          this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = '';
        }
      } else if (e.target.name === 'fechaSeleccionada') {
        let fechaClaseObject = this.state.busquedaClasesFecha.find(prg => moment(prg.fechaClase).format('DD-MM-YY HH:mm') == e.target.value);
        if (fechaClaseObject != undefined) {

          try {
            // SACAR TODAA LA INFORMACION NUEVA! TEST JUSTICIA CASO PRACTICO NOTA TESTWORD EXAMENGESTION
            let data1 = { fechaClase: moment(fechaClaseObject.fechaClase).format('YYYY-MM-DD HH:mm'), idPrg: fechaClaseObject.idPrograma, idClase: this.state.idClasePreparador }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaVirtual", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            if (cls1 != undefined) {
              let inforClase = [{
                idPrg: cls1[0].idPrg,
                numeroSemanaClase: cls1[0].numeroSemanaClase,
                numeroSemanaProgramacion: cls1[0].numeroSemanaProgramacion,
                fechaClase: cls1[0].fechaClase,
                bloqueTemas: []
              }]
              await this.setState({ ejerciciosClase: inforClase })

            } else {
              await this.setState({ ejerciciosClase: undefined });
            }
            if (this.state.ejerciciosClase != undefined) {
              // sacar bloqueTemas -->
              let data2 = { idPrg: this.state.ejerciciosClase[0].idPrg, idClase: this.state.idClasePreparador }
              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/bloqueSemPrgPreCorregir", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              let ejerciciosClase = this.state.ejerciciosClase

              for (var j = 0; j < cls2.length; j++) {
                ejerciciosClase[0].bloqueTemas.push({
                  idBloque: cls2[j].id_bloque,
                  alumnos: []
                })
              }


              await this.setState({ ejerciciosClase: ejerciciosClase });

              let ejerciciosClase2 = this.state.ejerciciosClase

              for (var j = 0; j < ejerciciosClase2[0].bloqueTemas.length; j++) {

                let data33 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
                let response33 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
                });
                if (!response33.ok) {
                  throw Error(response33.statusText);
                }
                let json33 = await response33.json();
                let cls33 = await json33;
                //alumnos
                for (var l = 0; l < cls33.length; l++) {
                  if (cls33[l].activado == 1) {
                    ejerciciosClase2[0].bloqueTemas[j].alumnos.push({
                      idAlumno: cls33[l].idAlumno,
                      nombreAlumno: cls33[l].nombreAlumno,
                      apellidosAlumno: cls33[l].apellidosAlumno,
                      linkFotoPerfil: cls33[l].linkFotoPerfil,
                      infoExamenGestion: undefined
                    })
                  }
                }
                await this.setState({ ejerciciosClase: ejerciciosClase2 });
                for (var l = 0; l < ejerciciosClase2[0].bloqueTemas[j].alumnos.length; l++) {

                  let data41 = { idUser: ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno, idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idPrg: fechaClaseObject.idPrograma, idClase: this.state.idClasePreparador }
                  let response41 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestionPorBloque", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data41)
                  });
                  if (!response41.ok) {
                    throw Error(response41.statusText);
                  }
                  let json41 = await response41.json();
                  let cls41 = await json41;
                  if (cls41 != undefined) {
                    ejerciciosClase2[0].bloqueTemas[j].alumnos[l].infoExamenGestion = cls41[0]
                  } else {
                    ejerciciosClase2[0].bloqueTemas[j].alumnos[l].infoExamenGestion = undefined
                  }

                }

                await this.setState({ ejerciciosClase: ejerciciosClase2 });
                let alumnosOrder = []
                for (var p = 0; p < this.state.ejerciciosClase[0].bloqueTemas[j].alumnos.length; p++) {
                  if (this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p].infoExamenGestion != undefined && this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p].infoExamenGestion.puntuacion == null) {
                    alumnosOrder.push(this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p])
                  }
                }
                for (var p = 0; p < this.state.ejerciciosClase[0].bloqueTemas[j].alumnos.length; p++) {
                  if (this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p].infoExamenGestion != undefined && this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p].infoExamenGestion.puntuacion != null) {
                    alumnosOrder.push(this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p])
                  }
                }
                for (var p = 0; p < this.state.ejerciciosClase[0].bloqueTemas[j].alumnos.length; p++) {
                  if (this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p].infoExamenGestion == undefined) {
                    alumnosOrder.push(this.state.ejerciciosClase[0].bloqueTemas[j].alumnos[p])
                  }
                }

                ejerciciosClase2[0].bloqueTemas[j].alumnos = alumnosOrder
                await this.setState({ ejerciciosClase: ejerciciosClase2 });
              }

            }


            await this.setState({ semanaPrg: this.state.ejerciciosClase })

            /*if(this.state.semanaPrg.length > 0 && this.state.semanaPrg[0].bloqueTemas.length > 0){
              for (var i = 0; i < this.state.semanaPrg[0].bloqueTemas.length; i++) {
                if(this.state.semanaPrg[0].bloqueTemas[i].alumnos != undefined && this.state.semanaPrg[0].bloqueTemas[i].alumnos.length > 0){
                  for (var d = 0; d < this.state.semanaPrg[0].bloqueTemas[i].alumnos.length; d++) {
                    if(this.state.semanaPrg[0].bloqueTemas[i].alumnos[d].infoExamenGestion != undefined && this.state.semanaPrg[0].bloqueTemas[i].alumnos[d].infoExamenGestion.puntuacion != undefined){
                      $('#alumnCBCE-'+i+'-'+d).css("background", "green");
                    } else if (this.state.semanaPrg[0].bloqueTemas[i].alumnos[d].infoExamenGestion != undefined && this.state.semanaPrg[0].bloqueTemas[i].alumnos[d].infoExamenGestion.puntuacion == undefined){
                      $('#alumnCBCE-'+i+'-'+d).css("background", "red");
                    }
  
                  }
                }
  
              }
            }*/


          } catch (e) { console.log(e) }


        } else {
          this.setState({ semanaPrg: undefined, ejerciciosClase: undefined })
        }
      } else if (e.target.name == 'puntuacionExamAlumno') {
        if (e.target.value > 0 && e.target.value != '') {
          let alumnSelectExam = this.state.alumnSelectExam
          alumnSelectExam.puntuacion = e.target.value
          this.setState({ alumnSelectExam: alumnSelectExam })
        }
      } else if (e.target.name == 'aclaracionExam') {
        let alumnSelectExam = this.state.alumnSelectExam
        alumnSelectExam.anotaciones = e.target.value
        this.setState({ alumnSelectExam: alumnSelectExam })
      }
    }

  }
  async handleSubmitForm(e) {
    switch (e.target.id) {
      case 'salir': {
        this.setState({ alumnSelectExam: undefined })
      } break;
      case 'corregir': {
        let { alumnSelectExam } = this.state

        if (alumnSelectExam != undefined && alumnSelectExam.puntuacion != null) {
          try {
            let data1 = { alumnSelectExam: alumnSelectExam }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/corregirExamGes", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;



            let semanaPrg = await this.state.semanaPrg

            semanaPrg[0].bloqueTemas.find(b => b.idBloque == alumnSelectExam.idBloque).alumnos.find(a => a.idAlumno == alumnSelectExam.idAlumno).infoExamenGestion.puntuacion = alumnSelectExam.puntuacion
            if (alumnSelectExam.anotaciones != null) {
              semanaPrg[0].bloqueTemas.find(b => b.idBloque == alumnSelectExam.idBloque).alumnos.find(a => a.idAlumno == alumnSelectExam.idAlumno).infoExamenGestion.anotaciones = alumnSelectExam.anotaciones
            }


            for (var j = 0; j < this.state.semanaPrg[0].bloqueTemas.length; j++) {
              let alumnosOrder = []
              for (var p = 0; p < this.state.semanaPrg[0].bloqueTemas[j].alumnos.length; p++) {
                if (this.state.semanaPrg[0].bloqueTemas[j].alumnos[p].infoExamenGestion != undefined && this.state.semanaPrg[0].bloqueTemas[j].alumnos[p].infoExamenGestion.puntuacion == null) {
                  alumnosOrder.push(this.state.semanaPrg[0].bloqueTemas[j].alumnos[p])
                }
              }
              for (var p = 0; p < this.state.semanaPrg[0].bloqueTemas[j].alumnos.length; p++) {
                if (this.state.semanaPrg[0].bloqueTemas[j].alumnos[p].infoExamenGestion != undefined && this.state.semanaPrg[0].bloqueTemas[j].alumnos[p].infoExamenGestion.puntuacion != null) {
                  alumnosOrder.push(this.state.semanaPrg[0].bloqueTemas[j].alumnos[p])
                }
              }
              for (var p = 0; p < this.state.semanaPrg[0].bloqueTemas[j].alumnos.length; p++) {
                if (this.state.semanaPrg[0].bloqueTemas[j].alumnos[p].infoExamenGestion == undefined) {
                  alumnosOrder.push(this.state.semanaPrg[0].bloqueTemas[j].alumnos[p])
                }
              }

              semanaPrg[0].bloqueTemas[j].alumnos = alumnosOrder

            }

            swal('Correción realizada', '', 'success')
            await this.setState({ semanaPrg: semanaPrg })
            await this.setState({ alumnSelectExam: undefined })
          } catch (e) { console.log(e) }
        }
      } break;



    }
  }
  async componentDidMount() {
    try {
      if (this.props.tipoUser < 99) {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }
        }

      } else {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }
        }
      }

    } catch (e) {
      console.log(e)
    }
    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))



  };

  render() {
    const { selectedOption } = this.props;
    const { busquedaClasesPreparador, busquedaClasesFecha, ejerciciosClase, temasClase, intervalosExtraClase, displayFecha, alumnosClase, displayAlumnos, programacionClase, displayProgramacion, displayInputAlumnos, displayInfoAlumnos, infoAlumnoTemario, infoAlumnoTest, alumnoSelectedInfo, busquedaLeyes, semanaPrg, alumnSelectExam } = this.state;
    switch (selectedOption) {
      case 'Corregir_Examen_Gestion': {
        return (
          <form className="formMeca" autoComplete="off">
            <Input type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador" ref="ref_claseSeleccionada" onChange={this.onChangeInput} />
            <Input display={displayFecha} type="text" name="fechaSeleccionada" placeholder="Seleccionar Semana" icon="fas fa-calendar-alt" datalist="busquedaClasesFecha" ref="ref_fechaSeleccionada" onChange={this.onChangeInput} />

            {semanaPrg && semanaPrg != undefined && semanaPrg.length > 0 ? (
              <div className="bodyCorregirExamenes">

                <div className="alumnosBCE">

                  {semanaPrg && semanaPrg[0].bloqueTemas.map((bloque, key) =>
                    <div className="blockCE">

                      <div className="tituloBCE">
                        Bloque Nº {key + 1}
                        <i className="fas fa-caret-square-down" id={'bloq-' + key} onClick={this.toggle}></i>
                      </div>

                      <div className="contentBCE" id={"contentBCE" + key}>
                        {bloque && bloque.alumnos != undefined && bloque.alumnos.length > 0 && bloque.alumnos.map((alumno, key2) =>
                          <div className="alumnCBCE" id={"alumnCBCE-" + key + "-" + key2} onClick={this.loadAlumno}>
                            <div className="fotoACBCE" id={"alumnCBCE-" + key + "-" + key2}>
                              <img height="75px" src={alumno.linkFotoPerfil} id={"alumnCBCE-" + key + "-" + key2} />
                            </div>
                            <div className="nombreACBCE" id={"alumnCBCE-" + key + "-" + key2}>
                              <div className="nNACBCE" id={"alumnCBCE-" + key + "-" + key2}>
                                {alumno.nombreAlumno + ','}
                              </div>
                              <div className="aNACBCE" id={"alumnCBCE-" + key + "-" + key2}>
                                {alumno.apellidosAlumno}
                              </div>
                            </div>

                            {alumno.infoExamenGestion && alumno.infoExamenGestion != undefined && alumno.infoExamenGestion.puntuacion && alumno.infoExamenGestion.puntuacion != null && alumno.infoExamenGestion.puntuacion != undefined && alumno.infoExamenGestion.puntuacion != '' ? (
                              <div className="estadoExaACBCE" style={{ color: '#62dd7c' }} id={"alumnCBCE-" + key + "-" + key2}>
                                Corregido <i className="fas fa-user-check"></i>
                              </div>
                            ) : null}
                            {alumno.infoExamenGestion && alumno.infoExamenGestion != undefined && alumno.infoExamenGestion.puntuacion == null ? (
                              <div className="estadoExaACBCE" style={{ color: '#f13e3e' }} id={"alumnCBCE-" + key + "-" + key2}>
                                Pendiente <i className="fas fa-user-clock"></i>
                              </div>
                            ) : null}
                            {alumno.infoExamenGestion == undefined ? (
                              <div className="estadoExaACBCE" id={"alumnCBCE-" + key + "-" + key2}>
                                No hecho <i className="fas fa-user-times"></i>
                              </div>
                            ) : null}

                          </div>
                        )}
                      </div>

                    </div>
                  )}
                </div>
                {
                  alumnSelectExam && alumnSelectExam != undefined ? (
                    <div className="zonaCorrectorBCE">

                      <div className="contentZCBCE">
                        <BotonMedio id="salir" title={'Cerrar Ventana'} icon1={"fas fa-times-circle"} icon2={"fas fa-times-circle"} texto={'Cerrar Ventana'} onClick={this.handleSubmitForm} />
                      </div>

                      <div className="nameAlumZC">
                        <h44>
                          <i className="fas fa-user-tag"></i>
                          {semanaPrg[0].bloqueTemas.filter(t => t.idBloque == alumnSelectExam.idBloque) && semanaPrg[0].bloqueTemas.filter(t => t.idBloque == alumnSelectExam.idBloque)[0].alumnos.find(a => a.idAlumno == alumnSelectExam.idAlumno) != undefined ? (
                            semanaPrg[0].bloqueTemas.filter(t => t.idBloque == alumnSelectExam.idBloque)[0].alumnos.find(a => a.idAlumno == alumnSelectExam.idAlumno).nombreAlumno + ', ' + semanaPrg[0].bloqueTemas.filter(t => t.idBloque == alumnSelectExam.idBloque)[0].alumnos.find(a => a.idAlumno == alumnSelectExam.idAlumno).apellidosAlumno
                          ) : null}
                          <i className="fas fa-arrow-alt-circle-down"></i>
                        </h44>

                      </div>

                      <div className="contentZCBCE">
                        <BotonMedio title={'Examen de Alumno'} icon1={"fas fa-check-double"} icon2={"fas fa-file-pdf"} texto={'Descargar Examen Alumno'} onClick={this.downloadPdf} />
                      </div>

                      <div className="contentZCBCE">
                        <h44> <i className="fas fa-info-circle"> </i> Puntuación Examen <i className="fas fa-arrow-alt-circle-down"> </i></h44>
                        <Input type="number" step="0.01" name="puntuacionExamAlumno" placeholder="Ej: 8,75" icon="fas fa-check" ref="ref_puntuacionExamAlumno" onChange={this.onChangeInput} />
                      </div>

                      <div className="contentZCBCE2">
                        <h44> <i className="fas fa-info-circle"> </i> Aclaración <i className="fas fa-arrow-alt-circle-down"> </i></h44>
                        <InputTextArea id="aclaracionExam" name="aclaracionExam" ref="ref_aclaracionExam" valueDefault="Puede escribir una aclaración para el alumno aquí" width="90%" height="20vh" onChange={this.onChangeInput} />
                      </div>

                      <div className="contentZCBCE">
                        {alumnSelectExam && alumnSelectExam != undefined && alumnSelectExam.editar == 0 ? (
                          <BotonMedio id="corregir" title={'Corregir'} icon1={"fas fa-check-double"} icon2={"fas fa-check-double"} texto={'Corregir'} onClick={this.handleSubmitForm} />
                        ) : (
                          <BotonMedio id="corregir" title={'Editar Corrección'} icon1={"fas fa-edit"} icon2={"fas fa-check-double"} texto={'Editar Corrección'} onClick={this.handleSubmitForm} />
                        )}
                      </div>
                    </div>
                  ) : null
                }

              </div>
            ) : null}

            <datalist id="busquedaClasesFecha">{
              busquedaClasesFecha && busquedaClasesFecha.map((cls, key) =>
                <option key={key} value={moment(cls.fechaClase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.semanaClase}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + '.') : (a.tituloTema + ', ')
                )}</option>
              )
            }</datalist>
            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.filter(c => c.idOposicion == 3 || c.idOposicion == 5 || c.idOposicion == 6 || c.idOposicion == 7).map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>
          </form>
        );
      } break;

      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default connect(null, null)(OptionBar);

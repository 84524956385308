// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReactPlayer from 'react-player'
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
// Assets
import './css/css.css';
import Input2 from '../../../../components/Inputs/Input2';
import Boton from '../../../../components/Inputs/Boton';
import InputFileExamDesa from '../../../../components/Inputs/InputFileExamDesa';
import TemaSeleted from '../../../../components/TemaSeleted'
import { Ellipsis } from '../../../../components/react-css-spinners'
import Ranking from '../../../../components/Ranking'


import { selectPreguntasPorIdTest, respuestasTestId, mezclarRespuestasJusticia, comprobarRespuestas, selectPreguntasPorIdTestCP, respuestasTestIdCP } from '../../../../lib/utils/cargarTestJusticia'

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { loadTestUser, loadCPUser, borrarTestUser, borrarCP, cronoFunc, loadCurso, loadClase, loadTemasFacturados, loadAllSemFac, loadSemanaPrg, loadTemaAula } from '../../../../actions/userAction'

// import { loadClaseCurso, filtrarClaseCurso } from '../../lib/utils/cargarSemanaClase.js';
import { isDefined } from '../../../../lib/utils/is';


// import { loadSemanaPrg, semanaFacturadaLoad } from '../../actions/userAction'
// import swal from 'sweetalert';
// import ReactHtmlParser from 'react-html-parser';

class AulaVirtualTema extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		this.state = {
			vidActual: 0,
			temaLoad: null,
			testSelected: 0,
			tipoEjerSelected: null,
			ejerciciosVer: false,
			notaAlumnoAuto: null
		};

		this.onChangeBuscador = this.onChangeBuscador.bind(this);
		this.myFiles = this.myFiles.bind(this);
	}

	async myFiles(e) {
		if (e.target.name == 'examGest') {
			try {

				let files = e.target.files;
				let testSelected = this.state.testSelected

				var filesLoad = [];
				for (var i = 0; i < files.length; i++) {
					filesLoad.push(files[i]);
				}

				swal({
					title: "¿Estas segur@?",
					text: "Si le das a ok, el examen será enviado y no podra subir otro, tendrá disponible la solución. El sistema de correción es auto-evaluación por parte del alumno "
					,
					icon: "warning",
					buttons: true,
					dangerMode: true,
				})
					.then(async (willDelete) => {
						if (willDelete) {

							this.state.temaLoad.ejerDesarrollo[testSelected].examenToUpload = filesLoad

							if (this.state.temaLoad.ejerDesarrollo[testSelected].examenToUpload != undefined && this.state.temaLoad.ejerDesarrollo[testSelected].examenToUpload.length > 0) {
								swal({
									title: 'Subiendo Examen de Gestión',
									text: ' ',
									icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
									buttons: false,
									closeOnEsc: false,
									closeOnClickOutside: false,
								})

								let data1 = new FormData();
								data1.append('myFiles', this.state.temaLoad.ejerDesarrollo[testSelected].examenToUpload[0]);
								if (data1.getAll('myFiles') && data1.getAll('myFiles').length == 1) {

									let nombreArchivoOrig = data1.getAll('myFiles')[0].name
									// let tipoArchivo = nombreArchivo.split('.')[1]

									let nombreArchivo = '';
									let tipoArchivo = ''
									for (let i = 0; i < nombreArchivoOrig.split('.').length; i++) {
										if (i == nombreArchivoOrig.split('.').length - 1) {
											tipoArchivo = nombreArchivoOrig.split('.')[i]
										} else {

											if (i == nombreArchivoOrig.split('.').length - 2) {
												nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i]
											} else {
												nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i] + '.'
											}
										}

									}


									if (tipoArchivo && tipoArchivo == 'pdf') {
										let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
											method: 'POST', body: data1
										});
										if (!response1.ok) {
											throw Error(response1.statusText);
										}
										let json1 = await response1.json();
										let cls1 = await json1;
										if (cls1 != undefined) {
											let path = cls1[0].path.split('src/')[1];

											let data3 = {
												idAlumno: this.props.idUser,
												idExamGes: this.state.temaLoad.ejerDesarrollo[testSelected].idExamenGestion,
												idTema: this.state.temaLoad.id_tema,
												fecha: moment().format('YYYY-MM-DD HH:mm'),
												ruta: path
											}
											let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/addExamGesCompletoCurso", {
												method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
											});
											if (!response3.ok) {
												throw Error(response3.statusText);
											}
											let json3 = await response3.json();
											let cls3 = await json3;
											if (cls3 != undefined) {

												await swal({
													title: 'Examen subido correctamente',
													text: 'Ya puede descargar la solución y realizar la auto-evaluación. ¡Recargando!',
													icon: "success",
													closeOnEsc: false,
													closeOnClickOutside: false,
													timer: 2500,
												}).then(() => {
													window.location.reload()
												})



											}

										}

									} else {
										swal('Error en nombre de Archivo', 'No puede contener ningun punto "." y debe ser un archivo PDF', 'error')
									}


								} else {
									swal('Solo puede escoger un archivo en .pdf', '', 'error')
								}


							} else {
								swal('Tiene que introducir un archivo en .pdf', '', 'error')
							}
						} else {
							swal("¡Examen no subido!", '', "success");

							this.setState({ tipoEjerSelected: null, testSelected: 0 })
							this.setState({ tipoEjerSelected: 'testDesarrollo', testSelected: 0 })
						}
					});
			} catch (e) { console.log(e) }
		}
	}
	async onChangeBuscador(e) {

		if (e.target.id == 'tipoEjerLoadTeorico') {
			this.setState({ tipoEjerSelected: 'testTeorico', testSelected: 0 })

		} else if (e.target.id == 'tipoEjerLoadPractic') {
			this.setState({ tipoEjerSelected: 'testPractico', testSelected: 0 })

		} else if (e.target.id == 'tipoEjerLoadDesarrollo') {
			this.setState({ tipoEjerSelected: 'testDesarrollo', testSelected: 0 })

		} else if (e.target.id == 'verEjer') {


			let val = !this.state.ejerciciosVer

			$('.backGrodEjerClaseBloq').slideToggle(1000);
			setTimeout(
				function () {
					$('.SSFC-B-TL-bloqueNn2').css("visibility", val == true ? ("unset") : ("hidden"));
					$('.SSFC-B-TL-navEjeerNe').css("visibility", val == true ? ("unset") : ("hidden"));
					$('.SSFC-B-TL-ejerLoad').css("visibility", val == true ? ("unset") : ("hidden"));
				}.bind(this), 1010);
			this.setState({ ejerciciosVer: !val })

		} else if (e.target.id == 'realizarTestJus') {


			try {


				let locaTest = this.state.testSelected

				let idTest = await this.state.temaLoad.testTeorico[locaTest].idTestJusticia
				let idTema = await this.state.temaLoad.id_tema
				let idBloque = null
				let idClase = this.props.idClase
				let idPrg = null
				// NUEVAS VARIABLES PARA TITULO TEST
				// numBloque --- locaBloque + 1 es el numero del bloque
				let numBloque = this.state.temaLoad.vuelta
				let numTestBloque = null


				let testConPreguntas = await selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema) // idTema añdirlo y pasar si otros null se hace solo idTema pasarlo y añadirlo a todas
				let testConRespuestas = await comprobarRespuestas(await testConPreguntas)
				if (this.props.testJusticiaUserLoad.length > 0) {
					//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
					let name = "¡Ojo! - Test sin finalizar";
					let content = document.createElement('div');
					content.innerHTML = `
				 			 	Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  				Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  				`
					content.style.textAlign = "left";

					swal({
						title: name,
						content: content,
						icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
						buttons: {
							cancel: "Cargar el nuevo Test",
							catch: {
								text: "Ir a finalizar test",
								value: "catch",
							},
							defeat: false,
						},
						closeOnEsc: false,
						closeOnClickOutside: false,
					}).then(async (value) => {
						switch (value) {

							case "catch":
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
								break;

							default:
								await this.props.borrarTestUser()
								await this.props.loadTestUser(testConRespuestas)
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
						}
					});
				} else {
					await this.props.loadTestUser(testConRespuestas)
					// await this.props.history.push('/hacerTestJusticia');
					window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
				}
			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'repetirTestJus') {

			try {


				let locaTest = this.state.testSelected
				let idTest = await this.state.temaLoad.testTeorico[locaTest].idTestJusticia
				let idTema = await this.state.temaLoad.id_tema
				let idBloque = null
				let idClase = this.props.idClase
				let idPrg = null
				let numBloque = this.state.temaLoad.vuelta
				let numTestBloque = null

				let testConRespuestas = await selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
				let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))

				if (this.props.testJusticiaUserLoad.length > 0) {
					//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
					let name = "¡Ojo! - Test sin finalizar";
					let content = document.createElement('div');
					content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
					content.style.textAlign = "left";

					swal({
						title: name,
						content: content,
						icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
						buttons: {
							cancel: "Cargar el nuevo Test",
							catch: {
								text: "Ir a finalizar test",
								value: "catch",
							},
							defeat: false,
						},
						closeOnEsc: false,
						closeOnClickOutside: false,
					}).then(async (value) => {
						switch (value) {

							case "catch":
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
								break;

							default:
								await this.props.borrarTestUser()
								await this.props.loadTestUser(testPregResMezcla)
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
						}
					});
				} else {
					await this.props.loadTestUser(testPregResMezcla)
					// await this.props.history.push('/hacerTestJusticia');
					window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
				}
			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'ejerToLoad') {
			let value = e.target.id.split('-')[1]
			this.setState({ testSelected: value })
		} else if (e.target.id.split('-')[0] == 'realizarCasoPracticoBloque') {


			let locaTest = this.state.testSelected
			let idTest = await this.state.temaLoad.testPractico[locaTest].idCasoPractico
			let idTema = await this.state.temaLoad.id_tema

			let idBloque = null
			let idClase = this.props.idClase
			let idPrg = null
			let numBloque = this.state.temaLoad.vuelta
			let numTestBloque = null

			let testConPreguntas = await selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
			let testConRespuestas = await comprobarRespuestas(await testConPreguntas)

			if (this.props.casoPracticoUserLoad.length > 0) {
				//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
				let name = "¡Ojo! - Test sin finalizar";
				let content = document.createElement('div');
				content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
				content.style.textAlign = "left";

				swal({
					title: name,
					content: content,
					icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
					buttons: {
						cancel: "Cargar nuevo Test",
						catch: {
							text: "Ir a finalizar test",
							value: "catch",
						},
						defeat: false,
					},
					closeOnEsc: false,
					closeOnClickOutside: false,
				}).then(async (value) => {
					switch (value) {

						case "catch":
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
							break;

						default:
							await this.props.borrarCP()
							await this.props.loadCPUser(testConRespuestas, this.state.temaLoad.testPractico[locaTest].textHtml)
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
					}
				});
			} else {
				await this.props.loadCPUser(testConRespuestas, this.state.temaLoad.testPractico[locaTest].textHtml)
				// await this.props.history.push('/casoPracticoJusticia');
				window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
			}


		} else if (e.target.id.split('-')[0] == 'repetirCasoPracticoBloque') {

			let locaTest = this.state.testSelected
			let idTest = await this.state.temaLoad.testPractico[locaTest].idCasoPractico
			let idTema = await this.state.temaLoad.id_tema

			let idBloque = null
			let idClase = this.props.idClase
			let idPrg = null
			let numBloque = this.state.temaLoad.vuelta
			let numTestBloque = null

			let testConRespuestas = await selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
			let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))

			if (this.props.casoPracticoUserLoad.length > 0) {
				//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
				let name = "¡Ojo! - Test sin finalizar";
				let content = document.createElement('div');
				content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
				content.style.textAlign = "left";

				swal({
					title: name,
					content: content,
					icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
					buttons: {
						cancel: "Cargar nuevo Test",
						catch: {
							text: "Ir a finalizar test",
							value: "catch",
						},
						defeat: false,
					},
					closeOnEsc: false,
					closeOnClickOutside: false,
				}).then(async (value) => {
					switch (value) {

						case "catch":
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
							break;

						default:
							await this.props.borrarCP()
							await this.props.loadCPUser(testPregResMezcla, this.state.temaLoad.testPractico[locaTest].textHtml)
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
					}
				});
			} else {
				await this.props.loadCPUser(testPregResMezcla, this.state.temaLoad.testPractico[locaTest].textHtml)
				// await this.props.history.push('/casoPracticoJusticia');
				window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
			}

		} else if (e.target.id == 'descargarPdfExaGes') {

			let pathFile = this.state.temaLoad.ejerDesarrollo[this.state.testSelected].linkExamen
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}


		} else if (e.target.id == 'descargarPdfExaGesSolucion') {

			let pathFile = this.state.temaLoad.ejerDesarrollo[this.state.testSelected].linkSolucion
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}

		} else if (e.target.id == 'descargarPdfExaGesAlumn') {

			let pathFile = this.state.temaLoad.ejerDesarrollo[this.state.testSelected].linkExaAlumnoDes
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}

		} else if (e.target.id == 'puntuarMiExamen') {
			let { notaAlumnoAuto, testSelected } = this.state


			let textHtmlMensajeExamen = 'Examen auto-evaluado'
			if (textHtmlMensajeExamen != null && textHtmlMensajeExamen != '' && notaAlumnoAuto != null) {

				let data1 = {
					alumnSelectExam: {
						anotaciones: textHtmlMensajeExamen,
						puntuacion: notaAlumnoAuto,
						id_exp: this.state.temaLoad.ejerDesarrollo[testSelected].idExpExamDesa
					}
				}

				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/corregirExamGes", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				await swal({
					title: 'Examen auto-evaluado',
					text: '¡Recargando web!',
					icon: "success",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
					timer: 2500,
				}).then(() => {
					window.location.reload()
				})



			} else {
				swal('Error falta puntuación', 'Indica la puntuacion con una coma "Ejemplo: 8,25 ó 8,00" no con punto.', 'error')
			}
		} else if (e.target.id == 'puntuacionExamenGestion') {
			this.setState({ notaAlumnoAuto: e.target.value })
		} else if (e.target.id == 'completarVuelta') {

			//obtener vueltas del tema 
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, se completara una vuelta de estudio al tema.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {
						let data33 = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad }

						let response66 = await fetch("https://oposiciones-justicia.es/api/temas/temarioVueltasLoad", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
						});
						if (!response66.ok) {
							throw Error(response66.statusText);
						}
						let json66 = await response66.json();
						let cls66 = await json66;

						let vueltasTemario = cls66

						if (vueltasTemario.length > 0 && vueltasTemario.find(v => v.id_tema == this.props.temaAulaLoad) != undefined) {
							let numVuelta = vueltasTemario.find(v => v.id_tema == this.props.temaAulaLoad).num_vuelta + 1

							let data = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad, numVuelta: numVuelta }

							let response = await fetch("https://oposiciones-justicia.es/api/usuarios/completarVuelta", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;
							await swal({
								title: 'Vuelta completada',
								text: '¡Recargando!',
								icon: "success",
								closeOnEsc: false,
								closeOnClickOutside: false,
								timer: 1000,
							}).then(() => {
								window.location.reload()
							})

						} else {
							let numVuelta = 2

							let data = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad, numVuelta: numVuelta }

							let response = await fetch("https://oposiciones-justicia.es/api/usuarios/completarVuelta1", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							await swal({
								title: 'Vuelta completada',
								text: '¡Recargando!',
								icon: "success",
								closeOnEsc: false,
								closeOnClickOutside: false,
								timer: 1000,
							}).then(() => {
								window.location.reload()
							})

						}
					} else {
						swal("¡Vuelta no añadida!", '', "success");
					}
				});



		} else if (e.target.id == 'changeCurso') {
			this.props.loadCurso(null, null, null, null)
			this.props.loadClase(null, true, false)
			this.props.loadTemasFacturados(null)
			this.props.loadAllSemFac(null)
			this.props.loadSemanaPrg(null)
			this.props.loadTemaAula(null)
			window.location.reload()
		} else if (e.target.id == 'closeClaseOpen') {
			this.props.loadTemaAula(null)
		} else if (e.target.id.split('-')[0] == 'nextVideo') {
			let videoActual = this.state.vidActual
			let nextV = videoActual + 1
			let videos = this.state.temaLoad.videos
		
			if (nextV > videos.length - 1) {
			  nextV = 0
			  this.setState({ vidActual: 0 })
			  for (let i = 0; i < videos.length; i++) {
				if (i == videoActual) {
				  $('#recPlayVidDiv-' + i).css("z-index", "1");
	  
				} else if (i == nextV) {
				  $('#recPlayVidDiv-' + i).css("z-index", "" + videos.length);
				} else {
				  let indexActual = videos.length - i
				  $('#recPlayVidDiv-' + i).css("z-index", "" + indexActual);
				}
			  }
			} else {
			  this.setState({ vidActual: nextV })
			  for (let x = 0; x < videos.length; x++) {
				if (x == videoActual) {
				  $('#recPlayVidDiv-' + x).css("z-index", "1");
				  // margin-left: 0.2em;
				  // margin-top: 0.3em;
				} else if (x == nextV) {
				  $('#recPlayVidDiv-' + x).css("z-index", "" + videos.length);
	  
				} else {
				  let indexActual = parseInt($('#recPlayVidDiv-' + x).css("z-index")) + 1
				  $('#recPlayVidDiv-' + x).css("z-index", "" + indexActual);
				}
			  }
			}
	  
	  
	  
		  }

	}

	async componentDidMount() {
		swal({
			title: 'Cargando tema',
			text: 'En breve tendrá acceso a todo el contenido',
			icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
			buttons: false,
			closeOnEsc: false,
			closeOnClickOutside: false,
		})

		let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/consultaCategorias");
		if (!response1.ok) {
			throw Error(response1.statusText);
		}
		let json1 = await response1.json();
		let cls1 = await json1;
		this.setState({ categoriasJusticia: cls1 });

		let response2 = await fetch("https://oposiciones-justicia.es/api/temas/consultaopo");
		if (!response2.ok) {
			throw Error(response2.statusText);
		}
		let json2 = await response2.json();
		let cls2 = await json2;
		this.setState({ busquedaOpo: cls2 });


		let response3 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
		if (!response3.ok) {
			throw Error(response3.statusText);
		}
		let json3 = await response3.json();
		let cls3 = await json3;
		this.setState({ busquedaLeyes: cls3 });

		let data4 = {
			id_tema: this.props.temaAulaLoad
		}
		let response4 = await fetch("https://oposiciones-justicia.es/api/temas/infoTema", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
		});
		if (!response4.ok) {
			throw Error(response4.statusText);
		}
		let json4 = await response4.json();
		let cls4 = await json4;
		for (let gg = 0; gg < await cls4.length; gg++) {
			await cls4[gg].titulo_tema == null ? cls4[gg].titulo_tema = '' : null
			await cls4[gg].nombre_tema == null ? cls4[gg].nombre_tema = '' : null
			await cls4[gg].numeroTema == null ? cls4[gg].numeroTema = '' : null
			await cls4[gg].categoria == null ? cls4[gg].categoria = '' : null
			await cls4[gg].id_oposicion == null ? cls4[gg].id_oposicion = '' : null
			await cls4[gg].notaPreparador == null ? cls4[gg].notaPreparador = '' : null

			cls4[gg].nombreOpo = ''
			cls4[gg].nombreCategoria = ''
		}
		// this.setState({ infoTema: cls4 })






		// this.setState({ temaApuntes: cls, temaIntervalos: temaIntervalos })


		let infoTema = await cls4[0]
		infoTema.testTeorico = []
		infoTema.testPractico = []
		infoTema.ejerDesarrollo = []

		let data33 = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad }

		let response66 = await fetch("https://oposiciones-justicia.es/api/temas/temarioVueltasLoad", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
		});
		if (!response66.ok) {
			throw Error(response66.statusText);
		}
		let json66 = await response66.json();
		let cls66 = await json66;

		infoTema.vuelta = cls66.length > 0 && cls66[0].num_vuelta != null ? (cls66[0].num_vuelta) : (1)

		let data3 = {
			idTema: this.props.temaAulaLoad,
			idOpo: this.props.idOposicion
		}

		// cambiar y sacar solo los del tema casos y test
		let response5 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaLoad", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
		});
		if (!response5.ok) {
			throw Error(response5.statusText);
		}
		let json5 = await response5.json();
		let cls5 = await json5;
		// this.setState({ testTeoricoOpo: cls5 })

		if (cls5 && cls5 != undefined) {
			for (var v = 0; v < cls5.length; v++) {
				if (infoTema.vuelta >= cls5[v].vuelta) {
					infoTema.testTeorico.push({ idTestJusticia: cls5[v].idTest, ranking1: [], ranking2: [], vuelta: cls5[v].vuelta })
				}

			}
		}


		let response6 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaLoad", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
		});
		if (!response6.ok) {
			throw Error(response6.statusText);
		}
		let json6 = await response6.json();
		let cls6 = await json6;
		// this.setState({ testPracticoOpo: cls6 })
		if (cls6 && cls6 != undefined) {
			for (var v = 0; v < cls6.length; v++) {
				if (infoTema.vuelta >= cls6[v].vuelta) {
					infoTema.testPractico.push({ idCasoPractico: cls6[v].idTest, ranking1: [], ranking2: [], vuelta: cls6[v].vuelta, textHtml: cls6[v].texto_cp, nombreCasoPractico: cls6[v].nombre_cp })
				}
			}
		}

		let response7 = await fetch("https://oposiciones-justicia.es/api/temas/ejerDesarrolloTemaLoad", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
		});
		if (!response7.ok) {
			throw Error(response7.statusText);
		}
		let json7 = await response7.json();
		let cls7 = await json7;
		// this.setState({ testPracticoOpo: cls7 })
		if (cls7 && cls7 != undefined) {
			for (let x23 = 0; x23 < cls7.length; x23++) {
				if (infoTema.vuelta >= cls7[x23].vuelta) {
					infoTema.ejerDesarrollo.push({
						idExamenGestion: cls7[x23].idTest,
						examenToUpload: undefined,
						examenExiste: false,
						vuelta: cls7[x23].vuelta,
						linkSolucion: cls7[x23].linkSolucion,
						linkExamen: cls7[x23].linkExamen
					})
				}
			}
		}

		//ordenamos los mae nuevos son la utlima vuelta
		infoTema.testTeorico = infoTema.testTeorico.sort((a, b) => b.vuelta - a.vuelta)
		infoTema.testPractico = infoTema.testPractico.sort((a, b) => b.vuelta - a.vuelta)
		infoTema.ejerDesarrollo = infoTema.ejerDesarrollo.sort((a, b) => b.vuelta - a.vuelta)

		let data352 = { idUser: this.props.idUser }
		let response352 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data352)
		});
		if (!response352.ok) {
			throw Error(response352.statusText);
		}
		let json352 = await response352.json();
		let cls352 = await json352.filter(e => e.idTema != null && e.idTema == this.props.temaAulaLoad);

		if (cls352 != undefined && cls352.length > 0) {
			for (let j = 0; j < infoTema.ejerDesarrollo.length; j++) {

				for (var ñ = 0; ñ < cls352.length; ñ++) {
					if (infoTema.id_tema == cls352[ñ].idTema && cls352[ñ].linkArchivoAlumno != null && cls352[ñ].linkArchivoAlumno != '') {
						infoTema.ejerDesarrollo[j].examenExiste = true
						infoTema.ejerDesarrollo[j].linkExaAlumnoDes = cls352[ñ].linkArchivoAlumno
						infoTema.ejerDesarrollo[j].fechaExaEntregaA = cls352[ñ].fecha
						infoTema.ejerDesarrollo[j].idExpExamDesa = cls352[ñ].id_exp
						infoTema.ejerDesarrollo[j].linkSolucion = cls352[ñ].linkSolucion
						infoTema.ejerDesarrollo[j].linkExamen = cls352[ñ].linkExamen

						if (cls352[ñ].puntuacion != null) {
							infoTema.ejerDesarrollo[j].examenPuntuacion = cls352[ñ].puntuacion
							infoTema.ejerDesarrollo[j].notaExamenDesarollo = cls352[ñ].anotaciones

						} else {
							infoTema.ejerDesarrollo[j].examenPuntuacion = null
							infoTema.ejerDesarrollo[j].notaExamenDesarollo = null
						}

					} else {
						infoTema.ejerDesarrollo[j].linkExaAlumnoDes = null
					}

				}


			}
		}

		if (infoTema.testTeorico.length > 0) {
			for (let gh = 0; gh < infoTema.testTeorico.length; gh++) {

				let data10 = {
					idTest: infoTema.testTeorico[gh].idTestJusticia
				}
				let response10 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaLoadHistorial", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data10)
				});
				if (!response10.ok) {
					throw Error(response10.statusText);
				}
				let json10 = await response10.json();
				let cls10 = await json10;

				let filtroExpediente = await cls10.sort((a, b) => b.idExp - a.idExp).filter((object, key) => cls10.findIndex(a => a.idAlumno == object.idAlumno) == key)

				infoTema.testTeorico[gh].testJusticiaResultados = await filtroExpediente
				infoTema.testTeorico[gh].ranking1 = await filtroExpediente.filter(t => t.puntuacion >= 20).sort((a, b) => b.puntuacion - a.puntuacion)
				// Filtramos resualtado cogemos el ultima examen de cada alumno:
			}
		}
		if (infoTema.testPractico.length > 0) {
			for (let gh2 = 0; gh2 < infoTema.testPractico.length; gh2++) {

				let data11 = {
					idTest: infoTema.testPractico[gh2].idCasoPractico
				}
				let response11 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaLoadHistorial", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
				});
				if (!response11.ok) {
					throw Error(response11.statusText);
				}
				let json11 = await response11.json();
				let cls11 = await json11;

				let filtroExpediente = await cls11.sort((a, b) => b.idExp - a.idExp).filter((object, key) => cls11.findIndex(a => a.idAlumno == object.idAlumno) == key)

				infoTema.testPractico[gh2].casoPracticoResultados = await filtroExpediente
				infoTema.testPractico[gh2].ranking1 = await filtroExpediente.filter(t => t.puntuacion >= 5).sort((a, b) => b.puntuacion - a.puntuacion)
				// Filtramos resualtado cogemos el ultima examen de cada alumno:

			}
		}

		let response13 = await fetch("https://oposiciones-justicia.es/api/temas/loadVideosTemas");
		if (!response13.ok) {
			throw Error(response13.statusText);
		}
		let json13 = await response13.json();
		let cls13 = await json13;
		let filtroVideos = JSON.parse(JSON.stringify(cls13.filter(v => v.id_tema == this.props.temaAulaLoad)))

		infoTema.videos = await filtroVideos





		// añadir los ranking!

		this.setState({ temaLoad: infoTema })
		// console.log('infoTema')
		// console.log(infoTema)
		swal.close()
	}

	componentWillUnmount() {

	}
	render() {
		const { temaAulaLoad } = this.props;
		const { temaLoad, testSelected, tipoEjerSelected } = this.state;

		return (

			temaLoad != null ? (

				<React.Fragment>
					<div className="semSelFullContent">


						<div className="SSFC-top">
							<div className="SSFC-T-changCurClas">
								<div className="SSFC-T-CCC-left" style={{ marginRight: '0px' }}>
									<div className="SSFC-T-CCC-title">
										<b>{temaLoad.titulo_tema}</b>
										{temaLoad.nombre_tema}
									</div>
									<div className="SSFC-T-CCC-buttons">
										<div style={{ width: '140px', marginTop: '10px', marginRight: '10px' }}>
											<Boton id="changeCurso" icon1="" icon2="" texto="Cambiar Curso" onClick={this.onChangeBuscador} />
										</div>
										<div style={{ width: '140px', marginTop: '10px' }}>
											<Boton id="closeClaseOpen" icon1="" icon2="" texto="Cambiar tema" onClick={this.onChangeBuscador} />
										</div>
									</div>
								</div>

							</div>

							<div className="SSFC-T-videos">

								<div className="SSFC-T-V-videos" style={{ marginRight: '0px' }}>
									{temaLoad && temaLoad.videos.length > 0 ? (
										temaLoad.videos.map((video, key8) =>

											<div id={'recPlayVidDiv-' + key8} className="recPlayVidDiv" style={{ zIndex: '' + temaLoad.videos.length - key8 }} onContextMenu={e => e.preventDefault()}>
												<ReactPlayer
													url={video.link}
													className='react-player'
													playing={false}
													width='100%'
													height='100%'
													controls={true}
													onContextMenu={e => e.preventDefault()}
													config={{ file: { attributes: { controlsList: 'nodownload' } } }}
												/>

												<div onClick={this.onChangeBuscador} id={"nextVideo-" + key8} className="nextRectPlaerClass" style={{ zIndex: "" + (temaLoad.videos.length + 2) }} >
													<i id={"nextVideo-" + key8} className="fas fa-angle-right"></i>
												</div>
												<div className="nextRectPlaerClass2" style={{ zIndex: "" + (temaLoad.videos.length + 2) }} >
													VIDEO CLASE SEMANAL <b>{this.state.vidActual + 1}</b>/{temaLoad.videos.length}
												</div>
											</div>
										)
									) : (
										<div className="recPlayVidDivNovideo">
											Aún no esta el video de esta clase.
										</div>
									)

									}

								</div>

							</div>


						</div>


						<div className="SSFC-bot">
							<div className="SSFC-B-BloquesNav">
								<div className="SSFC-B-BN-titule">
									VUELTA {temaLoad.vuelta}
								</div>
								<div className="SSFC-B-BN-navCont">
									<div id={'completarVuelta'} className={"SSFC-B-BN-contnLink"} style={{ padding: '5px 15px' }} onClick={this.onChangeBuscador}>
										{'COMPLETAR VUELTA'}
									</div>

								</div>
							</div>

							<div className="SSFC-B-Temas">
								{temaLoad != null ? (
									<React.Fragment>

										<div className="SSFC-B-TemaLoad">

											<div className="SSFC-B-TL-navTemas">

												<div id={''}
													className={"SSFC-B-TL-ButonNav"}
													onClick={this.onChangeBuscador}
													style={{ borderLeft: 'none' }}
												>
													{temaLoad.titulo_tema}
													{temaLoad.nombre_tema != null && temaLoad.nombre_tema != '' ? (
														' (' + temaLoad.nombre_tema + ')'
													) : null}

												</div>

											</div>
											<div className="SSFC-B-TL-temaLoaddf">
												{isDefined(temaLoad) && temaLoad.id_tema != undefined && temaLoad.id_tema != null ?
													(

														<TemaSeleted idTema={temaLoad.id_tema} />

													) : null
												}

											</div>

											{/* {isDefined(temaLoad) && temaLoad.notaPreparador != null && temaLoad.notaPreparador != '' ? (

												<div className="SSFC-B-TL-notaTema">
													<b>Nota del preparador</b>
													<div className="SSFC-B-TL-notaTemaTexto">
														{

															temaLoad.notaPreparador != null && temaLoad.notaPreparador != '' ? (
																temaLoad.notaPreparador
															) : (null)
														}

													</div>
												</div>
											) : null
											} */}


										</div>

										<div className="SSFC-B-ejerLoad">

											<div className="SSFC-B-TL-bloqueNn2">


												{temaLoad.testTeorico.length > 0 ? (
													<div id={'tipoEjerLoadTeorico'}
														className={tipoEjerSelected == 'testTeorico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
														onClick={this.onChangeBuscador}
														style={{ borderLeft: 'none' }}
													>
														Test Teórico
													</div>
												) : null}

												{temaLoad.testPractico.length > 0 ? (
													<div id={'tipoEjerLoadPractic'}
														className={tipoEjerSelected == 'testPractico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
														onClick={this.onChangeBuscador}
														style={{ borderLeft: temaLoad.testTeorico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
													>
														Test Practico
													</div>
												) : null}

												{temaLoad.ejerDesarrollo.length > 0 && temaLoad.ejerDesarrollo[0].idExamenGestion != null && temaLoad.ejerDesarrollo[0].idExamenGestion > 1 ? (
													<div id={'tipoEjerLoadDesarrollo'}
														className={tipoEjerSelected == 'testDesarrollo' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
														onClick={this.onChangeBuscador}
														style={{ borderLeft: temaLoad.testTeorico.length > 0 || temaLoad.testPractico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
													>
														Examen Desarrollo
													</div>
												) : null}
											</div>


											<div className="SSFC-B-TL-navEjeerNe">

												{tipoEjerSelected == 'testTeorico' ? (
													temaLoad.testTeorico.map((test, key10) =>
														<div id={'ejerToLoad-' + key10}
															className={testSelected == key10 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: key10 > 0 ? ('1px solid #f1f1f1') : ('none') }}
														>
															Test {key10 + 1}
														</div>
													)
												) : null
												}

												{tipoEjerSelected == 'testPractico' ? (
													temaLoad.testPractico.map((test, key11) =>
														<div id={'ejerToLoad-' + key11}
															className={testSelected == key11 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: key11 > 0 ? ('1px solid #f1f1f1') : ('none') }}
														>
															Test {key11 + 1}
														</div>
													)
												) : null
												}

												{tipoEjerSelected == 'testDesarrollo' ? (
													<div id={''}
														className={"SSFC-B-TL-ButonNavS"}
														onClick={this.onChangeBuscador}
														style={{ borderLeft: 'none' }}
													>
														Examen Desarrollo
													</div>

												) : null
												}


											</div>


											<div className="SSFC-B-TL-ejerLoad">
												{tipoEjerSelected == null ? (
													<h3 style={{color: 'Black'}}> Seleccione el tipo de ejercicio que desea realizar </h3>

												) : null}

												{isDefined(testSelected) && tipoEjerSelected == 'testTeorico' ? (

													temaLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser) != undefined

														? (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																		<div className="SSFC-B-TL-eL-puntos">
																			{temaLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																		</div>

																		<div className="SSFC-B-TL-eL-resultados">
																			{temaLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																		</div>
																		<div className="SSFC-B-TL-eL-resultados">
																			{temaLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																		</div>
																		<div className="SSFC-B-TL-eL-resultados">
																			{temaLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																		</div>
																	</div>
																	<div>
																		<div className="SSFC-B-TL-eL-boton">
																			<Boton id="repetirTestJus" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																</div>

																<div className="SSFC-B-TL-eL-Ranking">
																	{isDefined(temaLoad.testTeorico[testSelected].ranking1) && temaLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																		<Ranking rank={temaLoad.testTeorico[testSelected].ranking1} />
																	) : (
																		<img src="../img/noRanking.jpg" />
																	)}
																</div>

															</React.Fragment>
														) : (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-boton">
																		<Boton id="realizarTestJus" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																	</div>
																</div>

																<div className="SSFC-B-TL-eL-Ranking">
																	{isDefined(temaLoad.testTeorico[testSelected].ranking1) && temaLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																		<Ranking rank={temaLoad.testTeorico[testSelected].ranking1} />
																	) : (
																		<img src="../img/noRanking.jpg" />
																	)}
																</div>

															</React.Fragment>
														)
												) : null

												}


												{isDefined(testSelected) && tipoEjerSelected == 'testPractico' ? (

													temaLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser) != undefined ? (
														<React.Fragment>
															<div className="SSFC-B-TL-eL-testInfo">
																<div className="SSFC-B-TL-eL-testInfoResultado">
																	<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																	<div className="SSFC-B-TL-eL-puntos">
																		{temaLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																	</div>

																	<div className="SSFC-B-TL-eL-resultados">
																		{temaLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																	</div>
																	<div className="SSFC-B-TL-eL-resultados">
																		{temaLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																	</div>
																	<div className="SSFC-B-TL-eL-resultados">
																		{temaLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																	</div>
																</div>
																<div>
																	<div className="SSFC-B-TL-eL-boton">
																		<Boton id="repetirCasoPracticoBloque" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																	</div>
																</div>

															</div>

															<div className="SSFC-B-TL-eL-Ranking">
																{isDefined(temaLoad.testPractico[testSelected].ranking1) && temaLoad.testPractico[testSelected].ranking1.length > 0 ? (
																	<Ranking rank={temaLoad.testPractico[testSelected].ranking1} />
																) : (
																	<img src="../img/noRanking.jpg" />
																)}
															</div>

														</React.Fragment>
													) : (
														<React.Fragment>
															<div className="SSFC-B-TL-eL-testInfo">
																<div className="SSFC-B-TL-eL-boton">
																	<Boton id="realizarCasoPracticoBloque" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																</div>
															</div>

															<div className="SSFC-B-TL-eL-Ranking">
																{isDefined(temaLoad.testPractico[testSelected].ranking1) && temaLoad.testPractico[testSelected].ranking1.length > 0 ? (
																	<Ranking rank={temaLoad.testPractico[testSelected].ranking1} />
																) : (
																	<img src="../img/noRanking.jpg" />
																)}
															</div>

														</React.Fragment>
													)
												) : null

												}

												{isDefined(testSelected) && tipoEjerSelected == 'testDesarrollo' ? (

													temaLoad.ejerDesarrollo[testSelected].examenExiste != false ? (

														isDefined(temaLoad.ejerDesarrollo[testSelected].examenPuntuacion) ? (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Resultado <b> {temaLoad.ejerDesarrollo[testSelected].examenPuntuacion}</b></div>

																	</div>
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.onChangeBuscador} />
																		</div>
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																</div>
																<div className="SSFC-B-TL-eL-Ranking">

																	{isDefined(temaLoad.ejerDesarrollo[testSelected].notaExamenDesarollo) ? (
																		<div className="SSFC-B-TL-eL-notaPrepa">
																			{ReactHtmlParser(temaLoad.ejerDesarrollo[testSelected].notaExamenDesarollo)}
																		</div>
																	) : null}

																	<img src="../img/examDesarrolloCompleted.jpg" />
																</div>
															</React.Fragment>
														) : (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">



																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title" style={{
																			marginRight: '0px',
																			alignItems: 'center',
																			justifyContent: 'center',
																			display: 'flex',
																			flexFlow: 'column nowrap'
																		}}>
																			<b style={{ fontWeight: '500' }}>Auto-Corrección</b>

																			<div className="SSFC-B-TL-eL-boton2" style={{ fontWeight: '500' }}>
																				<Boton id={"puntuarMiExamen"} icon1="" icon2="" texto="Puntuar" onClick={this.onChangeBuscador} />
																			</div>
																		</div>

																		<div id={'puntuacionExamenGestion'} className="celdaInputDAPFBN" style={{ width: '160px', maxWidth: '160px !important' }}>

																			<Input2 autoComplete={"off"} type="number" min={"0.00"} max={10000.00} step={0.01}
																				id={'puntuacionExamenGestion'}
																				name={'puntuacionExamenGestion'}
																				placeholder={'7,95'}
																				icon="fas fa-flag"
																				ref={'ref_puntuacionExamenGestion'}
																				onChange={this.onChangeBuscador}

																			/>

																			<p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>
																				Puntuación
																			</p>
																		</div>
																	</div>


																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.onChangeBuscador} />
																		</div>
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																</div>
																<div className="SSFC-B-TL-eL-Ranking">

																	<div className="SSFC-B-TL-eL-notaPrepa">
																		Puede descargar la solución, modo auto-evaluación.
																	</div>

																	<img src="../img/examDesarrolloCompleted.jpg" />
																</div>
															</React.Fragment>
														)



													) : (
														<React.Fragment>
															<div className="SSFC-B-TL-eL-testInfo">
																<div className="SSFC-B-TL-eL-testInfoResultado">
																	<div className="SSFC-B-TL-eL-title"> Subir examen en .pdf </div>

																</div>
																<div className="SSFC-B-TL-eL-testInfoResultado">
																	<div className="SSFC-B-TL-eL-boton2">
																		<Boton id="descargarPdfExaGes" icon1="" icon2="" texto="Descargar examen" onClick={this.onChangeBuscador} />
																	</div>
																	<div className="SSFC-B-TL-eL-boton2">

																		<InputFileExamDesa onChange={this.myFiles} name="examGest" texto="Subir examen" />
																	</div>
																</div>

															</div>

															<div className="SSFC-B-TL-eL-Ranking">
																<img src="../img/examDesarrollo.jpg" />
															</div>

														</React.Fragment>
													)
												) : null

												}
											</div>

											<div className="backGrodEjerClaseBloq" id="verEjer" onClick={this.onChangeBuscador}>
												<div className="botonEjeercicosBcl" id="verEjer">
													Ejercicios
												</div>
												<img src="../img/ejerBloque.jpg" id="verEjer" />
												<div className="color-overlay"></div>
											</div>


										</div>

									</React.Fragment>
								) : null

								}
							</div>
						</div>



					</div>
				</React.Fragment>

			) : null

		);
	}
}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		tipoUser: state.user.tipoUser,
		temaAulaLoad: state.user.temaAulaLoad,
		idOposicion: state.user.idOposicion,
		testJusticiaUserLoad: state.user.testJusticiaUserLoad,
		casoPracticoUserLoad: state.user.casoPracticoUserLoad,
		idClase: state.user.idClase

	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		borrarTestUser,
		loadTestUser,
		loadCPUser,
		borrarCP,
		loadCurso,
		loadClase,
		loadTemasFacturados,
		loadAllSemFac,
		loadSemanaPrg,
		loadTemaAula
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AulaVirtualTema);

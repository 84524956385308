// Actions
const CAMBIAR_TEST_JUSTICIA = 'CAMBIAR_TEST_JUSTICIA';
const ELIMINAR_TEST_JUSTICIA = 'ELIMINAR_TEST_JUSTICIA';
const ADD_PREGUNTA_NUEVA_INS = 'ADD_PREGUNTA_NUEVA_INS';
const DELETE_PREGUNTA_NUEVA_INS = 'DELETE_PREGUNTA_NUEVA_INS'
const DELETE_DESCENDER_PREGUNTA_NUEVA_INS = 'DELETE_DESCENDER_PREGUNTA_NUEVA_INS';
export function cambiarDatosTestJusticia(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_TEST_JUSTICIA,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarTestJusticiaCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA
    };
}
export function addPreguntaTestJusticiaInsert(numPreguntaNueva) {
    return {
	    type: ADD_PREGUNTA_NUEVA_INS,
	   	 numPregNew: numPreguntaNueva
    };
}
export function deletePreguntaTestJusticiaInsert(numPreguntaBorrar) {
    return {
	    type: DELETE_PREGUNTA_NUEVA_INS,
	   	 numPregDel: numPreguntaBorrar
    };
}
export function changeNumPreguntaTestJusticiaInsert(numPreguntaBorrar) {
    return {
	    type: DELETE_DESCENDER_PREGUNTA_NUEVA_INS,
	   	 numPregChange: numPreguntaBorrar
    };
}

// Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Assets
import './css/css.css'
import Input2 from '../../components/Inputs/Input2'
import Boton from '../../components/Inputs/Boton'
import { isDefined } from '../../lib/utils/is'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openViewBusArt, addIdLeyBusArt, addArtBusArt } from '../../actions/userAction'

import ReactHtmlParser from 'react-html-parser';


class BuscarDeArt extends Component {


	constructor(props) {
		super(props)

		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		this.state = {
			leySeleted: null,
			valueInput: null,
			artBusqueda: null,
			artCargado: null,
			valueInput2: null,
			isDragging: false,
			position: {
				x: 50,
				y: 50
			},
			offset: { x: 0, y: 0 }
		}

		this.onChangeInput = this.onChangeInput.bind(this)
		this.onHandleClick = this.onHandleClick.bind(this)
		this.handleMouseDown = this.handleMouseDown.bind(this)
		this.handleMouseUp = this.handleMouseUp.bind(this)
		this.handleMouseMove = this.handleMouseMove.bind(this)
	}




	async onChangeInput(e) {
		if (e.target.id == 'leySelected') {
			if (isDefined(e.target.value)) {
				this.setState({ valueInput: e.target.value })
			} else {
				this.setState({ valueInput: null, leySeleted: null, artBusqueda: null, artCargado: null, valueInput2: null })
			}

			let leyes = this.state.leyes
			let capturaSiglas = e.target.value
			let objectLeySelec = leyes.find(l => l.siglas_ley == capturaSiglas)
			if (isDefined(objectLeySelec)) {
				// buscar ARTICULOS

				let data1 = { idLey: objectLeySelec.id_ley }
				let response1 = await fetch('https://oposiciones-justicia.es/api/usuarios/artLey', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				})
				if (!response1.ok) {
					throw Error(response1.statusText)
				}
				let json1 = await response1.json()
				let cls1 = await json1

				this.setState({ leySeleted: objectLeySelec.id_ley, artBusqueda: cls1, artCargado: null, valueInput2: null })
				// guardar en redux la idLey y artCargado resetearlo
				this.props.addIdLeyBusArt(objectLeySelec.id_ley)
				this.props.addArtBusArt(null)
			}

		} else if (e.target.id == 'artBusqueda') {

			let val = e.target.value
			if (isDefined(e.target.value)) {
				this.setState({ valueInput2: e.target.value })
			} else {
				this.setState({ valueInput2: null })
			}

			let artBus = this.state.artBusqueda.filter(a => a.articulo == val)

			if (artBus.length > 0) {
				this.setState({ artCargado: artBus[0] })
				this.props.addArtBusArt(val)

				// guardar en redux el e.target.value
			} else {
				this.setState({ artCargado: null })
				this.props.addArtBusArt(null)
			}

		}
	}

	onHandleClick(e) {

		if (e.target.id == 'openBusArt') {

			this.props.openViewBusArt(this.props.viewBusArt == null ? true : null)

		}

	}

	handleMouseDown(e) {
		this.setState({
			isDragging: true,
			offset: {
				x: e.clientX - this.state.position.x,
				y: e.clientY - this.state.position.y
			}
		})
	}

	handleMouseUp() {
		this.setState({ isDragging: false })
	}

	handleMouseMove(e) {
		if (!this.state.isDragging) {
			return
		}
		this.setState({
			position: {
				x: e.clientX - this.state.offset.x,
				y: e.clientY - this.state.offset.y
			}
		})
	}


	async componentDidMount() {

		// fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		// 	.then(res => res.json())
		// 	.then(leyes => this.setState({
		// 		leyes: leyes
		// 	}))

		// console.log('this.props.idLey')
		// console.log(this.props.idLey)
		// console.log('this.props.artReceived')
		// console.log(this.props.artReceived)

		// hacer props con idLeyBuscadorArt y artBuscadorArt

		// SE LOS VAMOS A PASAR CON REDUX CON LOS PROPS ASÍ CUANDO EDITE O BUSQUE UN LEY SE LE GUARDA PARA QUE SE LE MANTENGA Y AL CLICAR SE CARGE auto no tenga que buscar el mismo art cada vez que cierre

		let response0 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta")
		if (!response0.ok) {
			throw Error(response0.statusText)
		}

		let json0 = await response0.json()
		let cls0 = await json0
		this.setState({ leyes: cls0 })

		let artReceived = this.props.artBuscadorArt
		// console.log('artReceived')
		// console.log(artReceived)
		let idLey = this.props.idLeyBuscadorArt

		if (idLey != undefined && artReceived != undefined && idLey != null && artReceived != null) {

			let leyPreLoad = await cls0.find(l => l.id_ley == idLey)

			if (leyPreLoad != undefined) {

				// console.log('leyPreLoad')
				// console.log(leyPreLoad)

				let data1 = { idLey: idLey }
				let response1 = await fetch('https://oposiciones-justicia.es/api/usuarios/artLey', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				})
				if (!response1.ok) {
					throw Error(response1.statusText)
				}
				let json1 = await response1.json()
				let cls1 = await json1


				if (cls1.length == 0) {
					this.setState({ valueInput: leyPreLoad.siglas_ley, leySeleted: leyPreLoad.id_ley, artBusqueda: cls1, artCargado: null, valueInput2: null })

					this.props.addArtBusArt(null)

					this.refs.leySelected.refs.leySelected.value = leyPreLoad.siglas_ley

					swal('Articulos no disponibles', 'Aun no hemos insertado los artículos de esta ley, en las proximas semanas estará disponible', 'info')
				} else {




					// let regex = /^(\d+)\.([a-zA-Z]+)\b/
					let regex = /^(\d+)\.([a-zA-Z]+)$/
					let esUnArtEspecial = regex.test(artReceived)
					let artBuscar

					// console.log('esUnArtEspecial')
					// console.log(esUnArtEspecial)

					if (esUnArtEspecial != false) {
						if (artReceived.split('.').length > 2) {

							artBuscar = artReceived.split('.')[0]

						} else {
							if (artReceived.split('.')[1] == 'y' || artReceived.split('.')[1] == 'pfo') {
								artBuscar = artReceived.split('.')[0]
							} else {
								artBuscar = artReceived.split('.')[0] + ' ' + artReceived.split('.')[1]
							}
						}


					} else {
						artBuscar = artReceived.split('.')[0]
					}
					// console.log('artBuscar')
					// console.log(artBuscar)

					let articulosBusqueda = cls1.find(a => a.articulo == artBuscar)

					// console.log('articulosBusqueda')
					// console.log(articulosBusqueda)

					if (articulosBusqueda != undefined) {
						this.setState({ valueInput: leyPreLoad.siglas_ley, leySeleted: leyPreLoad.id_ley, artBusqueda: cls1, artCargado: articulosBusqueda, valueInput2: articulosBusqueda.articulo })

						this.props.addArtBusArt(articulosBusqueda.articulo)

						this.refs.leySelected.refs.leySelected.value = leyPreLoad.siglas_ley
						this.refs.artBusqueda.refs.artBusqueda.value = articulosBusqueda.articulo


					} else {
						this.setState({ valueInput: leyPreLoad.siglas_ley, leySeleted: leyPreLoad.id_ley, artBusqueda: cls1, artCargado: null, valueInput2: null })

						this.props.addArtBusArt(null)

						this.refs.leySelected.refs.leySelected.value = leyPreLoad.siglas_ley



						swal('Error', 'El artículo no se encontrado de manera automática, busquelo manualmente', 'info')
					}

				}

			} else {
				swal('Ley no encontrada', 'Intente buscar el artículo manualmente', 'info')
				this.props.addIdLeyBusArt(null)
				this.props.addArtBusArt(null)

			}
		}
	}

	render() {
		const { leyes, leySeleted, valueInput, valueInput2, artBusqueda, artCargado, position } = this.state

		console.log('artBusqueda')
		console.log(artBusqueda)

		console.log('artCargado')
		console.log(artCargado)

		return (

			<div className='bodyBuscadorLeyes2EnTest movable-div'
				onMouseDown={this.handleMouseDown}
				onMouseUp={this.handleMouseUp}
				onMouseMove={this.handleMouseMove}
				style={{
					top: position.y,
					left: position.x
				}}
			>
				<i class="fa-light fa-circle-xmark" id="openBusArt" onClick={this.onHandleClick} style={{ cursor: 'pointer', color: '#3469b7', position: 'absolute', top: '10px', right: '10px', fontSize: '25px' }}></i>
				<div className='RowCenterCenter' style={{ alignSelf: 'center' }}>
					<div className='RowCenterCenter'>
						<div className='celdaInputDAPFBN'>
							<Input2 ref="leySelected" deletedMode={valueInput != null ? (true) : (false)} datalist='leyesLoad' autoComplete={'off'} id={'leySelected'} type='text' name={'leySelected'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={this.onChangeInput} />
							<p>Legislación</p>
						</div>
						<datalist id="leyesLoad">{
							leyes && leyes.map((cls, key4345) =>
								<option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
							)
						}</datalist>

					</div>
					{artBusqueda && artBusqueda != null && artBusqueda.length > 0 ? (
						<div className='RowCenterCenter'>
							<div className='celdaInputDAPFBN'>
								<Input2 ref="artBusqueda" deletedMode={valueInput2 != null ? (true) : (false)} datalist='artLoad' autoComplete={'off'} id={'artBusqueda'} type='text' name={'artBusqueda'} placeholder='Ej: 125 bis' icon='fa-light fa-gavel' onChange={this.onChangeInput} />
								<p>Buscar Artículo</p>
							</div>
							<datalist id="artLoad">{
								artBusqueda && artBusqueda.map((cls2, key2) =>
									<option key={key2} value={cls2.articulo}>{cls2.tipo + " " + cls2.articulo} </option>
								)
							}</datalist>

						</div>
					) : null}

				</div>


				{leySeleted != null && artCargado != null ? (
					<>
						<div style={{ color: '#3469b7', margin: '10px 0px' }} title={leyes && leyes.find(l => l.id_ley == leySeleted).nombre_ley} >
							{leyes && leyes.find(l => l.id_ley == leySeleted).siglas_ley}

							{" - " + artCargado.tipo + " " + artCargado.articulo}
						</div>
						{artCargado.nombreArticulo && artCargado.nombreArticulo != "" ? (
							<div style={{ color: '#3469b7', marginBottom: '5px' }}>
								{artCargado.nombreArticulo}
							</div>
						) : null}

						<div className="scrollbar" id="style-2" style={{ padding: '8px', height: 'unset', width: 'auto', direction: 'unset', maxHeight: '260px', lineHeight: '1.3' }}>
							{
								ReactHtmlParser(artCargado.textoArticulo.replace(/\n/g, "<br/> <br/> "))
							}
						</div>
					</>
				) : (
					<div style={{ color: '#3469b7', marginTop: '10px', fontSize: '20px', alignSelf: 'center', textAlign: 'center' }}>
						{
							leySeleted == null ? 'Seleccione una legislación' : null
						}

						{
							leySeleted != null && artBusqueda != null && artBusqueda.length == 0 ? 'Esta legislación aun no ha sido insertada en la base de datos de CEJ.' : null
						}

						{
							leySeleted != null && artBusqueda != null && artBusqueda.length > 0 && artCargado == null ? 'Seleccione un artículo' : null
						}
					</div>
				)}

			</div>



		)
	}
}
// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		viewBusArt: state.user.viewBusArt,
		idLeyBuscadorArt: state.user.idLeyBuscadorArt,
		artBuscadorArt: state.user.artBuscadorArt
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		openViewBusArt,
		addIdLeyBusArt,
		addArtBusArt,
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(BuscarDeArt)

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../css/reportesPREG.css';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
import { selectPreguntasPorArticulos, respuestasPregJusticia, mezclarRespuestasJusticia, generarTestTemplate } from '../../../../../lib/utils/generadorTestJusticia';
import {  comprobarRespuestas, respuestaSbr } from '../../../../../lib/utils/cargarTestJusticia';
import { genPregToDelete, addPregunta, delPregunta} from '../../../../../components/test/BusquedaEditarPreguntas/actions/testJusticiaAction';
import { genPregToDeleteCP} from '../../../../../components/testCasoPractico/BusquedaEditarPreguntas/actions/testCasoPracticoAction';
import { cargarContenido } from '../actions/baroptionAction';
import { genPregToDeleteEdt } from '../../../../Informatica/components/TestJusticia/actions/testJusticiaAction';
import { genPregToDeleteEdtCP } from '../../../../Informatica/components/CasoPractico/actions/testCasoPraticoAction';

//Componets
import Input from '../../../../../components/Inputs/Input';
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import InputTextArea from '../../../../../components/Inputs/InputTextArea';
import PreguntaJusticia from  '../../../../../components/test/preguntas/PreguntaJusticia';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    nombreUser: PropTypes.string
  };
  constructor(props){
    super(props);
    this.state = {
  	   statusBarraProceso: 'completar',
  	   displayFecha: 'none',
       displayFecha2: 'none',
  	   programacionClaseSeleted: undefined,
  	   semanasAtrasarPrep: 0,
  	   semanasAdelantarPosponer: 0,
       displayTestPorTemas: 'none'
       //displayTestPorTemas: 'block'
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.selectPregToEdt = this.selectPregToEdt.bind(this);

  }
  cleanForm(){
    switch (this.props.selectedOption) {
  		case 'Editar_Programacion': {
  			this.setState({
  				oposicionSelecionada: undefined,
  				claseSelecionada: undefined
  			});
  			this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
  			this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
  		}break;
    }
  }
  onToggleClick(e){
    if(e.target.id.split('-')[0] == 'toogleInfoPregunta'){
      let id = e.target.id.split('-')[1]
      $('#infoPregunta-'+id).slideToggle(1000);
    } else if(e.target.id.split('-')[0] == 'toogleReport'){
      let id = e.target.id.split('-')[1]
      $('#pregReport-'+id).fadeToggle(700).css("display", "flex");
    }



  }
  onChangeInput(e){
    this.setState({[e.target.name]: e.target.value});
  	if (this.props.selectedOption == 'Preguntas_Reportadas_Justicia'){
      if(e.target.name.split('-')[0] == 'infoResolveReport'){

        let id = e.target.name.split('-')[1]
        let reportJ = this.state.reportesJusticia
        reportJ[id].resolucion = e.target.value
        this.setState({reportesJusticia: reportJ })

      }
    } else if (this.props.selectedOption == 'Preguntas_Reportadas_CP'){
      if(e.target.name.split('-')[0] == 'infoResolveReportCP'){

        let id = e.target.name.split('-')[1]
        let reportJ = this.state.reportesCP
        reportJ[id].resolucion = e.target.value
        this.setState({reportesCP: reportJ })

      }
    }
  }

  async selectPregToEdt (e){
    var e = e
    let key = e.target.id.split('-')[1]
      try {
        if(e.target.id.split('-')[0] == 'edtPregJusticia'){
          await this.props.genPregToDeleteEdt(undefined);

          let preg = await this.state.reportesJusticia[key]
          let pregEdt = {
            numPreg: 1,
            editar: 1,
            insertar: 0,
            borrar: 0,
            preguntaCheck: 0,
            tipoPregunta: preg.tipoPregunta,
            ley: preg.siglasLey,
            selected: 0,
            idPregunta: preg.idPreg,
            idRespuesta1: preg.idR1,
            idRespuesta2: preg.idR2,
            idRespuesta3: preg.idR3,
            idRespuesta4: preg.idR4,
            preguntaTexto: preg.pregunta,
            pregunta: preg.pregunta,
            articulo: preg.articulo,
            idLey: preg.idLey,
            siglasLey: preg.siglasLey,
            r1: preg.respuestas.find(r => r.idR == preg.idR1).respuestaBse,
            r1_validez: preg.respuestas.find(r => r.idR == preg.idR1).validez,
            notaA: preg.respuestas.find(r => r.idR == preg.idR1).nota,
            subrayadoA: preg.respuestas.find(r => r.idR == preg.idR1).subrayado,
            r2: preg.respuestas.find(r => r.idR == preg.idR2).respuestaBse,
            r2_validez: preg.respuestas.find(r => r.idR == preg.idR2).validez,
            notaB: preg.respuestas.find(r => r.idR == preg.idR2).nota,
            subrayadoB: preg.respuestas.find(r => r.idR == preg.idR2).subrayado,
            r3: preg.respuestas.find(r => r.idR == preg.idR3).respuestaBse,
            r3_validez: preg.respuestas.find(r => r.idR == preg.idR3).validez,
            notaC: preg.respuestas.find(r => r.idR == preg.idR3).nota,
            subrayadoC: preg.respuestas.find(r => r.idR == preg.idR3).subrayado,
            r4: preg.respuestas.find(r => r.idR == preg.idR4).respuestaBse,
            r4_validez: preg.respuestas.find(r => r.idR == preg.idR4).validez,
            notaD: preg.respuestas.find(r => r.idR == preg.idR4).nota,
            subrayadoD: preg.respuestas.find(r => r.idR == preg.idR4).subrayado
          }
          await this.props.genPregToDelete(pregEdt, 1)
          await this.props.cargarContenido("Editar_Load_Pregunta_Test_Justicia")
          await this.props.history.push("/Informatica/Test-Justicia")
      } else if(e.target.id.split('-')[0] == 'edtPregCP'){
        await this.props.genPregToDeleteEdtCP(undefined);


        let preg = await this.state.reportesCP[key]
        let pregEdt = {
          numPreg: 1,
          editar: 1,
          insertar: 0,
          borrar: 0,
          preguntaCheck: 0,
          tipoPregunta: preg.tipoPregunta,
          ley: preg.siglasLey,
          selected: 0,
          idPregunta: preg.idPreg,
          idRespuesta1: preg.idR1,
          idRespuesta2: preg.idR2,
          idRespuesta3: preg.idR3,
          idRespuesta4: preg.idR4,
          preguntaTexto: preg.pregunta,
          pregunta: preg.pregunta,
          articulo: preg.articulo,
          idLey: preg.idLey,
          siglasLey: preg.siglasLey,
          r1: preg.respuestas.find(r => r.idR == preg.idR1).respuestaBse,
          r1_validez: preg.respuestas.find(r => r.idR == preg.idR1).validez,
          notaA: preg.respuestas.find(r => r.idR == preg.idR1).nota,
          subrayadoA: preg.respuestas.find(r => r.idR == preg.idR1).subrayado,
          r2: preg.respuestas.find(r => r.idR == preg.idR2).respuestaBse,
          r2_validez: preg.respuestas.find(r => r.idR == preg.idR2).validez,
          notaB: preg.respuestas.find(r => r.idR == preg.idR2).nota,
          subrayadoB: preg.respuestas.find(r => r.idR == preg.idR2).subrayado,
          r3: preg.respuestas.find(r => r.idR == preg.idR3).respuestaBse,
          r3_validez: preg.respuestas.find(r => r.idR == preg.idR3).validez,
          notaC: preg.respuestas.find(r => r.idR == preg.idR3).nota,
          subrayadoC: preg.respuestas.find(r => r.idR == preg.idR3).subrayado,
          r4: preg.respuestas.find(r => r.idR == preg.idR4).respuestaBse,
          r4_validez: preg.respuestas.find(r => r.idR == preg.idR4).validez,
          notaD: preg.respuestas.find(r => r.idR == preg.idR4).nota,
          subrayadoD: preg.respuestas.find(r => r.idR == preg.idR4).subrayado
        }
        await this.props.genPregToDeleteCP(pregEdt, 1)
        await this.props.cargarContenido("Editar_Load_Pregunta_Caso_Practico")
        await this.props.history.push("/Informatica/Caso-Practico")
    }
    }catch(e){console.log(e)}
  }

  async handleSubmitForm(e){
    let swData = e.target.id.split('-')[0]
    let key = e.target.id.split('-')[1]
  	switch (swData) {

  		case 'resolverReportb': {
        try{
          if(this.state.reportesJusticia[key].resolucion != '' && this.state.reportesJusticia[key].resolucion != undefined){
              let data = { idReport: this.state.reportesJusticia[key].idReport, resolucion: this.state.reportesJusticia[key].resolucion, estado: 1 }
              let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/reportJusticiaResolver", {
        				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
        				if (!response1.ok) {
        					throw Error(response1.statusText);
        				}
        			let json1 = await response1.json();
        			let cls1 = await json1;
        			if(await cls1 != undefined){
                swal('Reporte Completado','Se Recargará la pagina en 3 segundos, para ver los cambios.','info')
                setTimeout(
                function() {
                    window.location.reload();
                }.bind(this),3000);
              }

          } else {
            swal('Debe rellenar la explicación para resolver la duda/reporte','','error')
          }

        } catch(e){ console.log(e)}

      }break;

      case 'resolverReportbCP': {
        try{
          if(this.state.reportesCP[key].resolucion != '' && this.state.reportesCP[key].resolucion != undefined){
              let data = { idReport: this.state.reportesCP[key].idReport, resolucion: this.state.reportesCP[key].resolucion, estado: 1 }
              let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/reportCPResolver", {
        				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
        				if (!response1.ok) {
        					throw Error(response1.statusText);
        				}
        			let json1 = await response1.json();
        			let cls1 = await json1;
        			if(await cls1 != undefined){
                swal('Reporte Completado','Se Recargará la pagina en 3 segundos, para ver los cambios.','info')
                setTimeout(
                function() {
                    window.location.reload();
                }.bind(this),3000);
              }
          } else {
            swal('Debe rellenar la explicación para resolver la duda/reporte','','error')
          }

        } catch(e){ console.log(e)}

      }break;

      case 'deleteReport': {
        swal({
      	  title: "¿Estas segur@?",
      	  text: "Si le das a ok, la duda sera elimina.",
      	  icon: "warning",
      	  buttons: true,
      	  dangerMode: true,
      	})
      	.then((willDelete) => {
      	  if (willDelete) {
            let data = { idReport: this.state.reportesJusticia[key].idReport }
      		  fetch("https://oposiciones-justicia.es/api/preparador/delreport", {
      		    method: 'POST',
      		    headers: {'Content-Type': 'application/json'},
      		    body: JSON.stringify(data)
      		  }).then(response =>  {
      			 if (response.status >= 400) { throw new Error("Bad response from server");}
      		    return response.json();
      		  }).then(data => {
      			 if(data.affectedRows > 0){
      			    swal("¡Duda Eliminada!",'', "success");
                setTimeout(
                function() {
                    window.location.reload();
                }.bind(this),2000);
      			 }}).catch(function(err) {console.log(err)});

      	  } else {
      	    swal("¡Duda no Eliminada!",'',"success");
      	  }
      	});
      }break;

      case 'deleteReportCP': {
        swal({
      	  title: "¿Estas segur@?",
      	  text: "Si le das a ok, la duda sera elimina.",
      	  icon: "warning",
      	  buttons: true,
      	  dangerMode: true,
      	})
      	.then((willDelete) => {
      	  if (willDelete) {
            let data = { idReport: this.state.reportesCP[key].idReport }
      		  fetch("https://oposiciones-justicia.es/api/preparador/delreportCP", {
      		    method: 'POST',
      		    headers: {'Content-Type': 'application/json'},
      		    body: JSON.stringify(data)
      		  }).then(response =>  {
      			 if (response.status >= 400) { throw new Error("Bad response from server");}
      		    return response.json();
      		  }).then(data => {
      			 if(data.affectedRows > 0){
      			    swal("¡Duda Eliminada!",'', "success");
                setTimeout(
                function() {
                    window.location.reload();
                }.bind(this),2000);
      			 }}).catch(function(err) {console.log(err)});

      	  } else {
      	    swal("¡Duda no Eliminada!",'',"success");
      	  }
      	});
      }break;

    }

  }
async componentDidMount(){

    try{



    let data11 = { nombreUsuario: this.props.nombreUser }
    let response11 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data11)});
      if (!response11.ok) {
        throw Error(response11.statusText);
      }
    let json11 = await response11.json();
    let cls11 = await json11;
    await this.setState({ idPreparador: cls11[0].id_usuario })

    let response12 = await fetch("https://oposiciones-justicia.es/api/temas/consultaopo");
      if (!response12.ok) {
        throw Error(response12.statusText);
      }
    let json12 = await response12.json();
    let cls12 = await json12;
    await this.setState({ busquedaOpo: cls12 })

    let response123 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
      if (!response123.ok) {
        throw Error(response123.statusText);
      }
    let json123 = await response123.json();
    let cls123 = await json123;
    await this.setState({ busquedaLeyes: cls123 })


      // poner respuestas bien y sacar html respuestas

      let testNew = []
      let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/reportesJusticia");
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        if(cls1 != undefined && cls1.length > 0){
        for (var i = 0; i < await cls1.length; i++) {
           testNew.push({
             idReport: cls1[i].idReport,
             nombreUser: cls1[i].nombre,
             apellidosUser: cls1[i].apellidos,
             infoRoportUser: cls1[i].infoRoportUser,
             resolucion: cls1[i].resolucion,
             estadoReport: cls1[i].estadoReport,
             fecha: cls1[i].fecha,
            idPreg: cls1[i].idPregunta,
            pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
            idLey: cls1[i].idLey,
            siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
            nombreLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
            idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
            respuestas: []
          })
            let r1Sbr = cls1[i].R1
            if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
              r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
            }

            testNew[testNew.length-1].respuestas.push({
              idR: cls1[i].idR1,
              respuesta: r1Sbr,
              respuestaBse: cls1[i].R1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUser: cls1[i].r1ValorUser
            })
            let r2Sbr = cls1[i].R2
            if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
              r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
            }
            testNew[testNew.length-1].respuestas.push({
              idR: cls1[i].idR2,
              respuesta: r2Sbr,
              respuestaBse: cls1[i].R2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUser: cls1[i].r2ValorUser
            })
            let r3Sbr = cls1[i].R3
            if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
              r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
            }
            testNew[testNew.length-1].respuestas.push({
              idR: cls1[i].idR3,
              respuesta: r3Sbr,
              respuestaBse: cls1[i].R3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUser: cls1[i].r3ValorUser
            })
            let r4Sbr = cls1[i].R4
            if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
              r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
            }
            testNew[testNew.length-1].respuestas.push({
              idR: cls1[i].idR4,
              respuesta: r4Sbr,
              respuestaBse: cls1[i].R4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUser: cls1[i].r4ValorUser
            })
        }
        if(testNew != undefined){
          let testRespuestasBien = await comprobarRespuestas(testNew)
          await this.setState({ reportesJusticia: testRespuestasBien })

        }
        }

      let testNew2 = []
      let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/reportesCP");
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
        let json2 = await response2.json();
        let cls2 = await json2;

        if(cls2 != undefined && cls2.length > 0){

          for (var i = 0; i < await cls2.length; i++) {
            testNew2.push({
              idReport: cls2[i].idReport,
              nombreUser: cls2[i].nombre,
              apellidosUser: cls2[i].apellidos,
              infoRoportUser: cls2[i].infoRoportUser,
              resolucion: cls2[i].resolucion,
              estadoReport: cls2[i].estadoReport,
              fecha: cls2[i].fecha,
             idPreg: cls2[i].idPregunta,
             pregunta: cls2[i].pregunta, tipoPregunta: cls2[i].tipoPregunta, articulo: cls2[i].articulo,
             idLey: cls2[i].idLey,
             siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls2[i].idLey).siglas_ley,
             nombreLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls2[i].idLey).nombre_ley,
             idR1: cls2[i].idR1, idR2: cls2[i].idR2, idR3: cls2[i].idR3, idR4: cls2[i].idR4,
             respuestas: []
           })
             let r1Sbr = cls2[i].R1
             if(cls2[i].subrayado1 != '' && cls2[i].subrayado1 != undefined){
               r1Sbr = await respuestaSbr(cls2[i].R1,cls2[i].subrayado1)
             }

             testNew2[testNew2.length-1].respuestas.push({
               idR: cls2[i].idR1,
               respuesta: r1Sbr,
               respuestaBse: cls2[i].R1,
               validez: cls2[i].validezR1,
               subrayado: cls2[i].subrayado1,
               nota: cls2[i].nota1,
               valorUser: cls2[i].r1ValorUser
             })
             let r2Sbr = cls2[i].R2
             if(cls2[i].subrayado2 != '' && cls2[i].subrayado2 != undefined){
               r2Sbr = await respuestaSbr(cls2[i].R2,cls2[i].subrayado2)
             }
             testNew2[testNew2.length-1].respuestas.push({
               idR: cls2[i].idR2,
               respuesta: r2Sbr,
               respuestaBse: cls2[i].R2,
               validez: cls2[i].validezR2,
               subrayado: cls2[i].subrayado2,
               nota: cls2[i].nota2,
               valorUser: cls2[i].r2ValorUser
             })
             let r3Sbr = cls2[i].R3
             if(cls2[i].subrayado3 != '' && cls2[i].subrayado3 != undefined){
               r3Sbr = await respuestaSbr(cls2[i].R3,cls2[i].subrayado3)
             }
             testNew2[testNew2.length-1].respuestas.push({
               idR: cls2[i].idR3,
               respuesta: r3Sbr,
               respuestaBse: cls2[i].R3,
               validez: cls2[i].validezR3,
               subrayado: cls2[i].subrayado3,
               nota: cls2[i].nota3,
               valorUser: cls2[i].r3ValorUser
             })
             let r4Sbr = cls2[i].R4
             if(cls2[i].subrayado4 != '' && cls2[i].subrayado4 != undefined){
               r4Sbr = await respuestaSbr(cls2[i].R4,cls2[i].subrayado4)
             }
             testNew2[testNew2.length-1].respuestas.push({
               idR: cls2[i].idR4,
               respuesta: r4Sbr,
               respuestaBse: cls2[i].R4,
               validez: cls2[i].validezR4,
               subrayado: cls2[i].subrayado4,
               nota: cls2[i].nota4,
               valorUser: cls2[i].r4ValorUser
             })
          }
          if(testNew2 != undefined){
            let testRespuestasBien = await comprobarRespuestas(testNew2)
            console.log(testRespuestasBien)
            await this.setState({ reportesCP: testRespuestasBien })

          }

        }

}catch (e) { console.log(e)}


};

render() {
	const { selectedOption } = this.props;
  const { reportesJusticia, reportesCP } = this.state;

	switch (selectedOption) {
		case 'Preguntas_Reportadas_Justicia': {
			return (
				<div className="bodyReports">

        {
          reportesJusticia && reportesJusticia.map((pregunta, key) =>
          <div className="celdaReport" key={key}>


              <div className="infoReport">
                <div className="iRC"> <i className="fas fa-user-tag"></i> {pregunta.nombreUser + ' ' + pregunta.apellidosUser}  </div>
                <div className="iRC"> <i className="fas fa-clock"></i>{moment(pregunta.fecha).format('DD-MM-YY HH:mm')} </div>
                <div className={pregunta && pregunta.estadoReport == 0 ? ('iRCP'):('iRCR')}> <i className="far fa-question-circle"></i> { pregunta && pregunta.estadoReport == 0 ? ('Pendiente'):('Resuelto')} </div>
                <div className="iRCToggle">
                  <i className="fas fa-caret-down" id={'toogleReport-'+key} onClick={this.onToggleClick}></i>
                </div>
              </div>

            <div id={'pregReport-'+key}className="pregReport">

            <div className="resolverReport">
              <h44> <i className="fas fa-info-circle"></i> Mensaje Alumno </h44>
              <div className="reporteUserInfo"> {pregunta.infoRoportUser} </div>

              { pregunta && pregunta.estadoReport == 0 ? (
                <div className="erTOP">
                  <div> <Submit id={"deleteReport-"+key} icon1="fas fa-trash" icon2="fas fa-trash" texto="Eliminar Duda" onClick={this.handleSubmitForm}/> </div>
                  <div> <InputTextArea ref={"ref_infoResolveReport-"+key} id="infoResolveReport" name={"infoResolveReport-"+key} valueDefault="¡Escribir resolución de reporte o duda aquí!" width="100%" height="100px" onChange={this.onChangeInput}/> </div>
                  <div> <Submit id={"resolverReportb-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Finalizar Reporte/Duda" onClick={this.handleSubmitForm}/> </div>
                </div>
              ):(
                <div className="erTOP" >
                  <div> <Submit id={"deleteReport-"+key} icon1="fas fa-trash" icon2="fas fa-trash" texto="Eliminar Duda" onClick={this.handleSubmitForm}/> </div>
                  <h44> <i className="fas fa-info-circle"></i> Resolución Duda </h44>
                  <div className="reporteUserInfo"> {pregunta.resolucion} </div>
                  <h44> <i className="fas fa-info-circle"></i> Editar Respuesta </h44>
                  <div> <InputTextArea ref={"ref_infoResolveReport-"+key} id="infoResolveReport" name={"infoResolveReport-"+key} valueDefault="¡Escribir resolución de reporte o duda aquí!" width="100%" height="100px" onChange={this.onChangeInput}/> </div>
                  <div> <Submit id={"resolverReportb-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Reporte/Duda" onClick={this.handleSubmitForm}/> </div>
                </div>
              )}

            </div>


            <div className="infoPreguntaBodyPrgVPT">

              <div className={'resumenPreguntaVPT'}>

                <div className="infoCompressPreg" style={{width: '150px', background: '#ffb75a', marginRight: '3px',borderRadius: '5px 0px 0px 0px'}} title="Número de Pregunta">
                  <i className="fas fa-info-circle"></i>
                  Id Preg. {pregunta.idPreg}
                </div>

                <div className="infoCompressPregText" title="Pregunta acortada.">
                  {pregunta.pregunta.substring(0,30)+'...'}
                </div>

                <div className="infoCompressPreg" title={pregunta.nombreLey}>
                  <i className="fas fa-book"></i>
                  {pregunta.siglasLey}
                </div>

                <div className="infoCompressPreg" title={"Artículo de: "+pregunta.nombreLey}>
                  <i className="fas fa-bookmark"></i>
                  {pregunta.articulo}
                </div>

                <div className="infoCompressPregToogle">
                  <i className="fas fa-caret-down" id={'toogleInfoPregunta-'+key} onClick={this.onToggleClick}></i>
                </div>

              </div>


              <div className="infoPregunta" id={'infoPregunta-'+key}>
                <div className="infoPreguntaFlex">

                  <div className="PreguntaCompleta">

                    <i className="fas fa-question"></i>
                    <i className="fas fa-angle-right" style={{marginRight: '15px'}}></i>
                    <div className="textRespuesta">
                      {pregunta.pregunta}
                    </div>
                  </div>



                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta1')
                  }>
                    <letra> A </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR1).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota A) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta2')
                  }>
                    <letra> B </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR2).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota B) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta1')
                  }>
                    <letra> C </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR3).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota C) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 1 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 5 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 6 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 9 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 11 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 12 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 15 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 17 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 20 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta2')
                  }>
                    <letra> D </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR4).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota D) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota}
                      </div>
                    </div>
                  ):null}


                </div>
              </div>
              <div> <Submit id={"edtPregJusticia-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Pregunta" onClick={this.selectPregToEdt}/> </div>
            </div>

            </div>

          </div>
          )
        }


        </div>
			);
		}break;

    case 'Preguntas_Reportadas_CP': {
			return (
				<div className="bodyReports">

        {
          reportesCP && reportesCP.map((pregunta, key) =>
          <div className="celdaReport" key={key}>


              <div className="infoReport">
                <div className="iRC"> <i className="fas fa-user-tag"></i> {pregunta.nombreUser + ' ' + pregunta.apellidosUser}  </div>
                <div className="iRC"> <i className="fas fa-clock"></i>{moment(pregunta.fecha).format('DD-MM-YY HH:mm')} </div>
                <div className={pregunta && pregunta.estadoReport == 0 ? ('iRCP'):('iRCR')}> <i className="far fa-question-circle"></i> { pregunta && pregunta.estadoReport == 0 ? ('Pendiente'):('Resuelto')} </div>
                <div className="iRCToggle">
                  <i className="fas fa-caret-down" id={'toogleReport-'+key} onClick={this.onToggleClick}></i>
                </div>
              </div>

            <div id={'pregReport-'+key}className="pregReport">

            <div className="resolverReport">
              <h44> <i className="fas fa-info-circle"></i> Mensaje Alumno </h44>
              <div className="reporteUserInfo"> {pregunta.infoRoportUser} </div>


              { pregunta && pregunta.estadoReport == 0 ? (
                <div className="erTOP">
                  <div> <Submit id={"deleteReportCP-"+key} icon1="fas fa-trash" icon2="fas fa-trash" texto="Eliminar Duda" onClick={this.handleSubmitForm}/> </div>
                  <div> <InputTextArea ref={"ref_infoResolveReportCP-"+key} id="infoResolveReportCP" name={"infoResolveReportCP-"+key} valueDefault="¡Escribir resolución de reporte o duda aquí!" width="100%" height="100px" onChange={this.onChangeInput}/> </div>
                  <div> <Submit id={"resolverReportbCP-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Finalizar Reporte/Duda" onClick={this.handleSubmitForm}/> </div>
                </div>
              ):(
                <div className="erTOP">
                  <div> <Submit id={"deleteReportCP-"+key} icon1="fas fa-trash" icon2="fas fa-trash" texto="Eliminar Duda" onClick={this.handleSubmitForm}/> </div>
                  <h44> <i className="fas fa-info-circle"></i> Resolución Duda </h44>
                  <div className="reporteUserInfo"> {pregunta.resolucion} </div>
                  <h44> <i className="fas fa-info-circle"></i> Editar Respuesta </h44>
                  <div> <InputTextArea ref={"ref_infoResolveReportCP-"+key} id="infoResolveReportCP" name={"infoResolveReportCP-"+key} valueDefault="¡Escribir resolución de reporte o duda aquí!" width="100%" height="100px" onChange={this.onChangeInput}/> </div>
                  <div> <Submit id={"resolverReportbCP-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Reporte/Duda" onClick={this.handleSubmitForm}/> </div>
                </div>
              )}
            </div>


            <div className="infoPreguntaBodyPrgVPT">

              <div className={'resumenPreguntaVPT'}>

                <div className="infoCompressPreg" style={{width: '150px', background: '#ffb75a', marginRight: '3px',borderRadius: '5px 0px 0px 0px'}} title="Número de Pregunta">
                  <i className="fas fa-info-circle"></i>
                  Id Preg. {pregunta.idPreg}
                </div>

                <div className="infoCompressPregText" title="Pregunta acortada.">
                  {pregunta.pregunta.substring(0,30)+'...'}
                </div>

                <div className="infoCompressPreg" title={pregunta.nombreLey}>
                  <i className="fas fa-book"></i>
                  {pregunta.siglasLey}
                </div>

                <div className="infoCompressPreg" title={"Artículo de: "+pregunta.nombreLey}>
                  <i className="fas fa-bookmark"></i>
                  {pregunta.articulo}
                </div>

                <div className="infoCompressPregToogle">
                  <i className="fas fa-caret-down" id={'toogleInfoPregunta-'+key} onClick={this.onToggleClick}></i>
                </div>

              </div>


              <div className="infoPregunta" id={'infoPregunta-'+key}>
                <div className="infoPreguntaFlex">

                  <div className="PreguntaCompleta">

                    <i className="fas fa-question"></i>
                    <i className="fas fa-angle-right" style={{marginRight: '15px'}}></i>
                    <div className="textRespuesta">
                      {pregunta.pregunta}
                    </div>
                  </div>



                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta1')
                  }>
                    <letra> A </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR1).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota A) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta2')
                  }>
                    <letra> B </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR2).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota B) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 1 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 5 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 6 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 9 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 11 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 12 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 15 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 17 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 20 ||
                    pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta1')
                  }>
                    <letra> C </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR3).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota C) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota}
                      </div>
                    </div>
                  ):null}

                  <div className={
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 1 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 5 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 6 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 9 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 11 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 12 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 15 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 17 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 20 ||
                      pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 24 ?
                     ('respuestaCorrecta'):('respuesta2')
                  }>
                    <letra> D </letra>
                    <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR4).respuesta)}
                      </div>

                  </div>
                  {pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != undefined ? (
                    <div className='nota'>
                      <letra> Nota D) </letra>
                      <div className="textRespuestaNota">
                        {pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota}
                      </div>
                    </div>
                  ):null}


                </div>
              </div>
              <div> <Submit id={"edtPregCP-"+key} icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Pregunta" onClick={this.selectPregToEdt}/> </div>
            </div>
            </div>

          </div>
          )
        }


        </div>
			);
		}break;

		default: {
			return (
				<div style={{fontSize: '24px'}} >
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
						Selecciona una opción del submenú.
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
				</div>
			);
		}
	}
}
}


// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		pregToEdt: state.testJusticia.pregToEdt
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		genPregToDelete,
		addPregunta,
		delPregunta,
    genPregToDeleteEdt,
    cargarContenido,
    genPregToDeleteCP,
    genPregToDeleteEdtCP
	},dispatch)
}


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(OptionBar));

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import moment from 'moment';
import $ from 'jquery';

//Assets
import './css/dudasReportesUser.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions

// Utils
import { isDefined } from '../../lib/utils/is';
import { comprobarRespuestas, respuestaSbr } from '../../lib/utils/cargarTestJusticia';
// Components

import Input from '../../components/Inputs/Input';
import InputSelect from '../../components/Inputs/InputSelect';
import Submit from '../../components/Inputs/Submit';
import BotonMedio from '../../components/Inputs/BotonMedio';

import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';
import Input2 from '../../components/Inputs/Input2'
import { Ellipsis } from '../../components/react-css-spinners'

import Dudas from '../../components/Dudas';

class DudasYReportes extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      reportesLoad: undefined,
      tipoBusqueda: undefined,

      testDudas: false,
      articulosDudas: false,
      testTeoricoDudas: false,
      testPracticoDudas: false,
      valueInput1: null,
      valueInput2: null,
      valueInput11: null,
      valueInput22: null,
      leySelected: null,

      dudasLoad: null,
      cargandoDudas: false,
      tipoCargaDuda: "",
      textHtmlDudaArtAlumno: ""
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onToggleClick = this.onToggleClick.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
  }
  onChangeText(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      
      this.setState({ textHtmlDudaArtAlumno: textHtml.replaceAll('"',"'") })
    }

  }
  onToggleClick(e) {
    if (e.target.id.split('-')[0] == 'toogleInfoPregunta') {
      let id = e.target.id.split('-')[1]
      $('#infoPregunta-' + id).slideToggle(1000);
    } else if (e.target.id.split('-')[0] == 'toogleReport') {
      let id = e.target.id.split('-')[1]
      $('#reportResuelto-' + id).fadeToggle(700).css("display", "flex");
    }

  }
  async onChange(e) {
    try {
      if (e.target.name == "tipoBusqueda") {
        var e = e
        if (isDefined(this.state.leySelected)) {
          this.refs.ref_leySelected.refs.leySelected.value = ""
        }

        if (e.target.value == 1) {
          this.setState({ tipoBusqueda: e.target.value, leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })
          if (this.state.tipoBusquedaPersonal == 1) {
            //personal
            let reportesLoad = this.state.reportesJusticia.filter(p => p.idUser == this.state.idUser)
            if (reportesLoad != undefined && reportesLoad.length > 0) {
              this.setState({ reportesLoad: reportesLoad })
            }
          } else if (this.state.tipoBusquedaPersonal == 2) {
            // todos
            let reportesLoad = this.state.reportesJusticia
            if (reportesLoad != undefined && reportesLoad.length > 0) {
              this.setState({ reportesLoad: reportesLoad })
            }
          }

        } else if (e.target.value == 2) {
          this.setState({ tipoBusqueda: e.target.value, leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })
          if (this.state.tipoBusquedaPersonal == 1) {
            //personal
            let reportesLoad = this.state.reportesCP.filter(p => p.idUser == this.state.idUser)
            if (reportesLoad != undefined && reportesLoad.length > 0) {
              this.setState({ reportesLoad: reportesLoad })
            }
          } else if (this.state.tipoBusquedaPersonal == 2) {
            // todos
            let reportesLoad = this.state.reportesCP
            if (reportesLoad != undefined && reportesLoad.length > 0) {
              this.setState({ reportesLoad: reportesLoad })
            }
          }

        } else {
          this.setState({ tipoBusqueda: e.target.value, leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })
        }

      } else if (e.target.name == "leySelected") {
        var e = e
        var idLey = undefined
        if (this.state.busquedaLeyes.find(l => l.siglas_ley == e.target.value)) {
          idLey = this.state.busquedaLeyes.find(l => l.siglas_ley == e.target.value).id_ley
        }


        if (idLey != undefined) {
          this.setState({ idLeyLoad: idLey })
          if (this.state.artSelected != undefined) {
            this.refs.ref_artSelected.refs.artSelected.value = ""
          }

          if (this.state.tipoBusqueda == 1) {
            this.setState({ leySelected: e.target.value, artSelected: undefined, reportesLoad: undefined })
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal
              let reportesLoad = this.state.reportesJusticia.filter(p => p.idUser == this.state.idUser && p.idLey == idLey)
              this.setState({ reportesLoad: reportesLoad })
            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let reportesLoad = this.state.reportesJusticia.filter(p => p.idLey == idLey)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          } else if (this.state.tipoBusqueda == 2) {
            this.setState({ leySelected: e.target.value, artSelected: undefined, reportesLoad: undefined })
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal
              let reportesLoad = this.state.reportesCP.filter(p => p.idUser == this.state.idUser && p.idLey == idLey)
              this.setState({ reportesLoad: reportesLoad })
            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let reportesLoad = this.state.reportesCP.filter(p => p.idLey == idLey)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          }



        } else {
          if (this.state.artSelected != undefined) {
            this.refs.ref_artSelected.refs.artSelected.value = ""
          }
          this.setState({ leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })
          if (this.state.tipoBusqueda == 1) {
            this.setState({ artSelected: undefined, reportesLoad: undefined })
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal
              let reportesLoad = this.state.reportesJusticia.filter(p => p.idUser == this.state.idUser)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let reportesLoad = this.state.reportesJusticia
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          } else if (this.state.tipoBusqueda == 2) {
            this.setState({ artSelected: undefined, reportesLoad: undefined })
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal
              let reportesLoad = this.state.reportesCP.filter(p => p.idUser == this.state.idUser)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let reportesLoad = this.state.reportesCP
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          }
        }

      } else if (e.target.name == "artSelected") {
        if (e.target.value != '') {
          if (this.state.tipoBusqueda == 1) {
            this.setState({ artSelected: e.target.value, reportesLoad: undefined })
            var e = e
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal

              let eRArticulo = new RegExp("" + art + "");
              let reportesLoad = this.state.reportesJusticia.filter(
                p => p.idUser == this.state.idUser && p.idLey == idLey && eRArticulo.exec(p.articulo) != null && eRArticulo.exec(p.articulo).index == 0
              ).sort((a, b) => a.articulo.length - b.articulo.length)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }

            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let art = e.target.value
              let eRArticulo = new RegExp("" + art + "");
              let reportesLoad = this.state.reportesJusticia.filter(
                p => p.idLey == this.state.idLeyLoad && eRArticulo.exec(p.articulo) != null && eRArticulo.exec(p.articulo).index == 0
              ).sort((a, b) => a.articulo.length - b.articulo.length)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          } else if (this.state.tipoBusqueda == 2) {
            this.setState({ artSelected: e.target.value, reportesLoad: undefined })
            var e = e
            if (this.state.tipoBusquedaPersonal == 1) {
              //personal
              let art = e.target.value
              let eRArticulo = new RegExp("" + art + "");
              let reportesLoad = this.state.reportesCP.filter(
                p => p.idUser == this.state.idUser && p.idLey == this.state.idLeyLoad && eRArticulo.exec(p.articulo) != null && eRArticulo.exec(p.articulo).index == 0
              ).sort((a, b) => a.articulo.length - b.articulo.length)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }

            } else if (this.state.tipoBusquedaPersonal == 2) {
              // todos
              let art = e.target.value
              let eRArticulo = new RegExp("" + art + "");
              let reportesLoad = this.state.reportesCP.filter(
                p => p.idLey == this.state.idLeyLoad && eRArticulo.exec(p.articulo) != null && eRArticulo.exec(p.articulo).index == 0
              ).sort((a, b) => a.articulo.length - b.articulo.length)
              if (reportesLoad != undefined && reportesLoad.length > 0) {
                this.setState({ reportesLoad: reportesLoad })
              }
            }

          } else {
            this.setState({ artSelected: e.target.value, reportesLoad: undefined })
          }
        } else {
          this.setState({ artSelected: e.target.value, reportesLoad: undefined })

          var idLey = undefined
          if (this.state.idLeyLoad != undefined && this.state.idLeyLoad != '') {
            idLey = this.state.idLeyLoad
          }

          if (idLey != undefined) {
            if (this.state.artSelected != undefined) {
              this.refs.ref_artSelected.refs.artSelected.value = ""
            }

            if (this.state.tipoBusqueda == 1) {
              this.setState({ artSelected: undefined, reportesLoad: undefined })
              if (this.state.tipoBusquedaPersonal == 1) {
                //personal
                let reportesLoad = this.state.reportesJusticia.filter(p => p.idUser == this.state.idUser && p.idLey == idLey)
                this.setState({ reportesLoad: reportesLoad })
              } else if (this.state.tipoBusquedaPersonal == 2) {
                // todos
                let reportesLoad = this.state.reportesJusticia.filter(p => p.idLey == idLey)
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              }

            } else if (this.state.tipoBusqueda == 2) {
              this.setState({ artSelected: undefined, reportesLoad: undefined })
              if (this.state.tipoBusquedaPersonal == 1) {
                //personal
                let reportesLoad = this.state.reportesCP.filter(p => p.idUser == this.state.idUser && p.idLey == idLey)
                this.setState({ reportesLoad: reportesLoad })
              } else if (this.state.tipoBusquedaPersonal == 2) {
                // todos
                let reportesLoad = this.state.reportesCP.filter(p => p.idLey == idLey)
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              }

            }



          } else {
            if (this.state.artSelected != undefined) {
              this.refs.ref_artSelected.refs.artSelected.value = ""
            }
            this.setState({ leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })
            if (this.state.tipoBusqueda == 1) {
              this.setState({ artSelected: undefined, reportesLoad: undefined })
              if (this.state.tipoBusquedaPersonal == 1) {
                //personal
                let reportesLoad = this.state.reportesJusticia.filter(p => p.idUser == this.state.idUser)
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              } else if (this.state.tipoBusquedaPersonal == 2) {
                // todos
                let reportesLoad = this.state.reportesJusticia
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              }

            } else if (this.state.tipoBusqueda == 2) {
              this.setState({ artSelected: undefined, reportesLoad: undefined })
              if (this.state.tipoBusquedaPersonal == 1) {
                //personal
                let reportesLoad = this.state.reportesCP.filter(p => p.idUser == this.state.idUser)
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              } else if (this.state.tipoBusquedaPersonal == 2) {
                // todos
                let reportesLoad = this.state.reportesCP
                if (reportesLoad != undefined && reportesLoad.length > 0) {
                  this.setState({ reportesLoad: reportesLoad })
                }
              }

            }
          }


        }
      } else if (e.target.name == "tipoBusquedaPersonal") {
        if (this.state.tipoBusqueda != undefined) {
          this.refs.ref_tipoBusqueda.refs.tipoBusqueda.value = ""
        }
        this.setState({ tipoBusquedaPersonal: e.target.value, tipoBusqueda: undefined, leySelected: undefined, idLeyLoad: undefined, artSelected: undefined, reportesLoad: undefined })

      }
    } catch (error) { console.log(error) }
  }

  async handleOnSubmit(e) {
    if (e.target.id == "testDudas") {

      this.setState({ dudasLoad: null, cargandoDudas: false, testDudas: true, articulosDudas: false, testTeoricoDudas: false, testPracticoDudas: false, valueInput1: null, valueInput2: null, leySelected: null, tipoCargaDuda: "" })

    } else if (e.target.id == "articulosDudas") {

      if (this.refs.ref_leySelected != undefined) {
        if (this.state.articulosDudas == true) {
          this.refs.ref_leySelected.refs.leySelected3.value = ""
        } else {
          this.refs.ref_leySelected.refs.leySelected.value = ""
        }

      }
      this.setState({ dudasLoad: null, cargandoDudas: true, testDudas: false, articulosDudas: true, testTeoricoDudas: false, testPracticoDudas: false, valueInput1: null, valueInput2: null, leySelected: null, tipoCargaDuda: "" })

      let data = { ultimas: 250000 }
      let dudasLoadArticulos = []
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesArticulos", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArtN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      if (cls1 != undefined && cls1.length > 0) {
        for (var i = 0; i < await cls1.length; i++) {
          let textAlumno = cls1[i].infoRoportUser

          textAlumno = await textAlumno.replace(/&nbsp;/g, '');
          textAlumno = await textAlumno.replace(/<p>/g, '');
          textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
          let resolucionPreparador = cls1[i].resolucion
          if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
            resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
          }

          dudasLoadArticulos.push({
            idReport: cls1[i].idReport,
            idUser: cls1[i].usuarioReportID,
            infoRoportUser: textAlumno,
            fotoPerfil: cls1[i].fotoPerfil,
            resolucion: resolucionPreparador,
            estadoReport: cls1[i].estadoReport,
            fecha: cls1[i].fecha,
            articulo: cls1[i].articulo,
            idLey: cls1[i].idLey,
            siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
            nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
            alumnosDuda: []
          })
          //sacar todos los alumnos apuntados en duda
          let alumnosDuda = await cls2.filter(p => p.idDuda == cls1[i].idReport)
          dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await alumnosDuda
          dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
            idUser: cls1[i].usuarioReportID
          })
          // let data2 = {
          //   idDuda: cls1[i].idReport
          // }

          // let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          // });
          // if (!response2.ok) {
          //   throw Error(response2.statusText);
          // }
          // let json2 = await response2.json();
          // let cls2 = await json2;

          // dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
          // dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
          //   idUser: cls1[i].usuarioReportID
          // })


        }

        if (dudasLoadArticulos != undefined) {
          await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })
        }
      } else {
        this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
      }
    } else if (e.target.id == "testTeoricoDudas") {

      if (this.refs.ref_leySelected != undefined) {
        this.refs.ref_leySelected.refs.leySelected.value = ""
      }

      //sacar ultimas 20 dudas test teoria
      let data2 = {}
      let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      this.setState({ dudasLoad: null, cargandoDudas: true, testTeoricoDudas: true, testPracticoDudas: false, valueInput1: null, valueInput2: null, leySelected: null, tipoCargaDuda: "" })
      let data = { ultimas: 250000 }
      let testNew = []
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesJusUserExpediente", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      if (cls1 != undefined && cls1.length > 0) {
        for (var i = 0; i < await cls1.length; i++) {
          let textAlumno = cls1[i].infoRoportUser

          textAlumno = await textAlumno.replace(/&nbsp;/g, '');
          textAlumno = await textAlumno.replace(/<p>/g, '');
          textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
          let resolucionPreparador = cls1[i].resolucion
          if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
            resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
          }
          testNew.push({
            idReport: cls1[i].idReport,
            idUser: cls1[i].usuarioReportID,
            infoRoportUser: textAlumno,
            fotoPerfil: cls1[i].fotoPerfil,
            resolucion: resolucionPreparador,
            estadoReport: cls1[i].estadoReport,
            fecha: cls1[i].fecha,
            idPreg: cls1[i].idPregunta,
            pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
            idLey: cls1[i].idLey,
            siglasLey: cls1[i].siglasLey,
            nombreLey: cls1[i].nombreLey,
            idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
            respuestas: [],
            alumnosDuda: []
          })

          let r1Sbr = cls1[i].R1
          if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
            r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
          }

          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR1,
            respuesta: r1Sbr,
            respuestaBse: cls1[i].R1,
            validez: cls1[i].validezR1,
            subrayado: cls1[i].subrayado1,
            nota: cls1[i].nota1,
            valorUser: cls1[i].r1ValorUser
          })
          let r2Sbr = cls1[i].R2
          if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
            r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR2,
            respuesta: r2Sbr,
            respuestaBse: cls1[i].R2,
            validez: cls1[i].validezR2,
            subrayado: cls1[i].subrayado2,
            nota: cls1[i].nota2,
            valorUser: cls1[i].r2ValorUser
          })
          let r3Sbr = cls1[i].R3
          if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
            r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR3,
            respuesta: r3Sbr,
            respuestaBse: cls1[i].R3,
            validez: cls1[i].validezR3,
            subrayado: cls1[i].subrayado3,
            nota: cls1[i].nota3,
            valorUser: cls1[i].r3ValorUser
          })
          let r4Sbr = cls1[i].R4
          if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
            r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR4,
            respuesta: r4Sbr,
            respuestaBse: cls1[i].R4,
            validez: cls1[i].validezR4,
            subrayado: cls1[i].subrayado4,
            nota: cls1[i].nota4,
            valorUser: cls1[i].r4ValorUser
          })
          //sacar todos los alumnos apuntados en duda

          let alumnosDuda = await cls2.filter(p => p.idDuda == cls1[i].idReport)
          testNew[testNew.length - 1].alumnosDuda = await alumnosDuda
          testNew[testNew.length - 1].alumnosDuda.push({
            idUser: cls1[i].usuarioReportID
          })


          // let data2 = {
          //   idDuda: cls1[i].idReport
          // }

          // let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          // });
          // if (!response2.ok) {
          //   throw Error(response2.statusText);
          // }
          // let json2 = await response2.json();
          // let cls2 = await json2;

          // testNew[testNew.length - 1].alumnosDuda = await cls2
          // testNew[testNew.length - 1].alumnosDuda.push({
          //   idUser: cls1[i].usuarioReportID
          // })


        }

        if (testNew != undefined) {

          let dudasComprobadas = await comprobarRespuestas(testNew)
          await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })
        }
      } else {
        this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
      }





    } else if (e.target.id == "testPracticoDudas") {

      if (this.refs.ref_leySelected != undefined) {
        this.refs.ref_leySelected.refs.leySelected.value = ""
      }
      this.setState({ dudasLoad: null, cargandoDudas: true, testTeoricoDudas: false, testPracticoDudas: true, valueInput1: null, valueInput2: null, leySelected: null, tipoCargaDuda: "" })

      let data = { ultimas: 250000 }
      let testNew = []
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesCPUserExpediente", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;


      let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPracN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;
      if (cls1 != undefined && cls1.length > 0) {
        for (var i = 0; i < await cls1.length; i++) {
          let textAlumno = cls1[i].infoRoportUser

          textAlumno = await textAlumno.replace(/&nbsp;/g, '');
          textAlumno = await textAlumno.replace(/<p>/g, '');
          textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
          let resolucionPreparador = cls1[i].resolucion
          if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
            resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
            resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
          }
          testNew.push({
            idReport: cls1[i].idReport,
            idUser: cls1[i].usuarioReportID,
            infoRoportUser: textAlumno,
            fotoPerfil: cls1[i].fotoPerfil,
            resolucion: resolucionPreparador,
            estadoReport: cls1[i].estadoReport,
            fecha: cls1[i].fecha,
            idPreg: cls1[i].idPregunta,
            pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
            idLey: cls1[i].idLey,
            siglasLey: cls1[i].siglasLey,
            nombreLey: cls1[i].nombreLey,
            idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
            respuestas: [],
            alumnosDuda: [],
            idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
          })

          let r1Sbr = cls1[i].R1
          if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
            r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
          }

          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR1,
            respuesta: r1Sbr,
            respuestaBse: cls1[i].R1,
            validez: cls1[i].validezR1,
            subrayado: cls1[i].subrayado1,
            nota: cls1[i].nota1,
            valorUser: cls1[i].r1ValorUser
          })
          let r2Sbr = cls1[i].R2
          if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
            r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR2,
            respuesta: r2Sbr,
            respuestaBse: cls1[i].R2,
            validez: cls1[i].validezR2,
            subrayado: cls1[i].subrayado2,
            nota: cls1[i].nota2,
            valorUser: cls1[i].r2ValorUser
          })
          let r3Sbr = cls1[i].R3
          if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
            r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR3,
            respuesta: r3Sbr,
            respuestaBse: cls1[i].R3,
            validez: cls1[i].validezR3,
            subrayado: cls1[i].subrayado3,
            nota: cls1[i].nota3,
            valorUser: cls1[i].r3ValorUser
          })
          let r4Sbr = cls1[i].R4
          if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
            r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
          }
          testNew[testNew.length - 1].respuestas.push({
            idR: cls1[i].idR4,
            respuesta: r4Sbr,
            respuestaBse: cls1[i].R4,
            validez: cls1[i].validezR4,
            subrayado: cls1[i].subrayado4,
            nota: cls1[i].nota4,
            valorUser: cls1[i].r4ValorUser
          })
          //sacar todos los alumnos apuntados en duda
          // let data2 = {
          //   idDuda: cls1[i].idReport
          // }

          // let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          // });
          // if (!response2.ok) {
          //   throw Error(response2.statusText);
          // }
          // let json2 = await response2.json();
          // let cls2 = await json2;

          // testNew[testNew.length - 1].alumnosDuda = await cls2
          // testNew[testNew.length - 1].alumnosDuda.push({
          //   idUser: cls1[i].usuarioReportID
          // })

          let alumnosDuda = await cls2.filter(p => p.idDuda == cls1[i].idReport)
          testNew[testNew.length - 1].alumnosDuda = await alumnosDuda
          testNew[testNew.length - 1].alumnosDuda.push({
            idUser: cls1[i].usuarioReportID
          })

        }

        if (testNew != undefined) {

          let dudasComprobadas = await comprobarRespuestas(testNew)
          await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })

        }
      } else {
        this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
      }


    } else if (e.target.id == "leySelected") {

      let leyes = this.state.busquedaLeyes
      let busqueda = leyes.filter(l => l.siglas_ley == e.target.value)

      this.setState({ cargandoDudas: true, dudasLoad: null, tipoCargaDuda: "" })

      if (isDefined(e.target.value) && e.target.value != '' && e.target.value.length > 0 && busqueda && busqueda.length > 0) {
        //Realizamos busqueda por ley elminamos el articulos save!
        if (this.state.testTeoricoDudas == true) {

          let data = { idLey: busqueda[0].id_ley }
          let testNew = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLey", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: cls1[i].siglasLey,
                nombreLey: cls1[i].nombreLey,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: [],
                alumnosDuda: []
              })

              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls1[i].R1,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUser: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls1[i].R2,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUser: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls1[i].R3,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUser: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls1[i].R4,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUser: cls1[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }
        } else if (this.state.testPracticoDudas == true) {
          let data = { idLey: busqueda[0].id_ley }
          let testNew = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesCPUserFilterLey", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: cls1[i].siglasLey,
                nombreLey: cls1[i].nombreLey,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: [],
                alumnosDuda: [],
                idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
              })

              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls1[i].R1,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUser: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls1[i].R2,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUser: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls1[i].R3,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUser: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls1[i].R4,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUser: cls1[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }
        }

        this.setState({ valueInput1: true, leySelected: busqueda[0].id_ley })
      } else {
        this.setState({ valueInput1: null, leySelected: null })

        if (this.state.testTeoricoDudas == true) {

          let data = { ultimas: 250000 }
          let testNew = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesJusUserExpediente", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: cls1[i].siglasLey,
                nombreLey: cls1[i].nombreLey,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: [],
                alumnosDuda: []
              })

              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls1[i].R1,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUser: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls1[i].R2,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUser: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls1[i].R3,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUser: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls1[i].R4,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUser: cls1[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })

            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }
        } else if (this.state.testPracticoDudas == true) {
          let data = { ultimas: 250000 }
          let testNew = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesCPUserExpediente", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: cls1[i].siglasLey,
                nombreLey: cls1[i].nombreLey,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: [],
                alumnosDuda: [],
                idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
              })

              let r1Sbr = cls1[i].R1
              if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls1[i].R1,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUser: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls1[i].R2,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUser: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls1[i].R3,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUser: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls1[i].R4,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUser: cls1[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })

            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }
        } else if (this.state.articulosDudas == true) {
          let data = { ultimas: 250000 }
          let dudasLoadArticulos = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesArticulos", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              dudasLoadArticulos.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                alumnosDuda: []
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (dudasLoadArticulos != undefined) {
              await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })
            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }
        }


      }

    } else if (e.target.id == "artBusqueda") {

      let articuloBusqueda = e.target.value
      this.setState({ cargandoDudas: true, dudasLoad: null, tipoCargaDuda: "" })
      if (isDefined(articuloBusqueda) && articuloBusqueda != '' && articuloBusqueda.length > 0) {

        let articuloPuntos = articuloBusqueda.split('.')

        if (articuloPuntos.length > 0) {
          let puntosLenghtString = articuloPuntos.length

          if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {

            puntosLenghtString = puntosLenghtString - 1
          }
          if (this.state.testTeoricoDudas == true) {

            let data = { idLey: this.state.leySelected, art: articuloBusqueda, puntosLenght: puntosLenghtString }

            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLeyArticulo", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: []
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          } else if (this.state.testPracticoDudas == true) {
            let data = { idLey: this.state.leySelected, art: articuloBusqueda, puntosLenght: puntosLenghtString }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesCPUserFilterLeyArticulo", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: [],
                  idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          }
        }


        this.setState({ valueInput2: true, artBusqueda: articuloBusqueda })
      } else {
        this.setState({ valueInput2: null, artBusqueda: null })
        // Si ley tiene buscar ley sino normal
        if (this.state.leySelected != null) {

          if (this.state.testTeoricoDudas == true) {

            let data = { idLey: this.state.leySelected }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLey", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: []
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          } else if (this.state.testPracticoDudas == true) {
            let data = { idLey: this.state.leySelected }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesCPUserFilterLey", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: [],
                  idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          }

        } else {

          if (this.state.testTeoricoDudas == true) {

            let data = { ultimas: 250000 }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesJusUserExpediente", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: []
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          } else if (this.state.testPracticoDudas == true) {
            let data = { ultimas: 250000 }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesCPUserExpediente", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }
                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: [],
                  idCasoPractico: isDefined(cls1[i].idCasoPractico) ? (cls1[i].idCasoPractico) : (null)
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }

              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ dudasLoad: dudasComprobadas, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })

              }
            } else {
              this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
            }
          }

        }


      }

    } else if (e.target.id == "leySelected2") {
      let leyes = this.state.busquedaLeyes
      let busqueda = leyes.filter(l => l.siglas_ley == e.target.value)

      if (isDefined(e.target.value) && e.target.value != '' && e.target.value.length > 0 && busqueda && busqueda.length > 0) {
        let idLey = busqueda[0].id_ley

        this.setState({ valueInput11: true, idLeyLoadReportUser: busqueda[0].id_ley })
      } else {
        this.setState({ valueInput11: null, valueInput22: null, artLoadReportUser: null, idLeyLoadReportUser: null })
      }

    } else if (e.target.id == "artBusqueda2") {

      let articuloBusqueda = e.target.value
      if (isDefined(articuloBusqueda) && articuloBusqueda != '' && articuloBusqueda.length > 0) {

        this.setState({ valueInput22: true, artLoadReportUser: articuloBusqueda })
      } else {
        this.setState({ valueInput22: null, artLoadReportUser: null })
      }
    } else if (e.target.id == 'enviarDuda') {

      let textAlumno = this.state.textHtmlDudaArtAlumno
      if (isDefined(textAlumno) && textAlumno != '') {
        let data = {
          textoExplicacion: textAlumno,
          fecha: moment().format('YYYY-MM-DD HH:mm'),
          idUser: this.props.idUser,
          idLey: this.state.idLeyLoadReportUser,
          articulo: this.state.artLoadReportUser
        }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportDudaArticulos", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1.affectedRows > 0) {

          swal("Duda enviada", 'La resolveremos lo antes posible.', 'success');

          let data2 = {
            idUserRecibe: 15,
            idUserEnvia: this.props.idUser,
            fecha: moment().format('YYYY-MM-DD HH:mm'),
            titulo: 'Acaban de plantear una duda - Artículos',
            notificacionTexto: 'Se acaba detectar el envio de una duda. ' + moment().format('DD-MM-YY HH:mm') + '.',
            tipoPreparadores: 1,
            tipoOficina: 0,
            tipoDuda: 3,
            tipoUrgente: 0,
            tipoReformasLey: 0,
            idDuda: cls1.insertId
          }
          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          this.setState({ textHtmlDudaArtAlumno: '', valueInput11: null, valueInput22: null, artLoadReportUser: null, idLeyLoadReportUser: null })


        }

      } else {
        swal('Error falta texto', 'Describa su duda en el area de texto', 'error')
      }

    } else if (e.target.id == "leySelected3") {

      let leyes = this.state.busquedaLeyes
      let busqueda = leyes.filter(l => l.siglas_ley == e.target.value)

      this.setState({ cargandoDudas: true, dudasLoad: null, tipoCargaDuda: "" })

      if (isDefined(e.target.value) && e.target.value != '' && e.target.value.length > 0 && busqueda && busqueda.length > 0) {
        //Realizamos busqueda por ley elminamos el articulos save!

        let data = { idLey: busqueda[0].id_ley }
        let dudasLoadArticulos = []
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLeyArt2", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          for (var i = 0; i < await cls1.length; i++) {
            let textAlumno = cls1[i].infoRoportUser


            textAlumno = await textAlumno.replace(/&nbsp;/g, '');
            textAlumno = await textAlumno.replace(/<p>/g, '');
            textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
            let resolucionPreparador = cls1[i].resolucion
            if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
              resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
              resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
              resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
            }
            dudasLoadArticulos.push({
              idReport: cls1[i].idReport,
              idUser: cls1[i].usuarioReportID,
              infoRoportUser: textAlumno,
              fotoPerfil: cls1[i].fotoPerfil,
              resolucion: resolucionPreparador,
              estadoReport: cls1[i].estadoReport,
              fecha: cls1[i].fecha,
              articulo: cls1[i].articulo,
              idLey: cls1[i].idLey,
              siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
              nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
              alumnosDuda: []
            })
            //sacar todos los alumnos apuntados en duda
            let data2 = {
              idDuda: cls1[i].idReport
            }

            let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;

            dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
            dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
              idUser: cls1[i].usuarioReportID
            })


          }

          if (dudasLoadArticulos != undefined) {
            await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })
          }
        } else {
          this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
        }


        this.setState({ valueInput1: true, leySelected: busqueda[0].id_ley })
      } else {

        this.setState({ valueInput1: null, leySelected: null })


        let data = { ultimas: 250000 }
        let dudasLoadArticulos = []
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesArticulos", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        if (cls1 != undefined && cls1.length > 0) {
          for (var i = 0; i < await cls1.length; i++) {
            let textAlumno = cls1[i].infoRoportUser

            textAlumno = await textAlumno.replace(/&nbsp;/g, '');
            textAlumno = await textAlumno.replace(/<p>/g, '');
            textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
            let resolucionPreparador = cls1[i].resolucion
            if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
              resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
              resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
              resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
            }
            dudasLoadArticulos.push({
              idReport: cls1[i].idReport,
              idUser: cls1[i].usuarioReportID,
              infoRoportUser: textAlumno,
              fotoPerfil: cls1[i].fotoPerfil,
              resolucion: resolucionPreparador,
              estadoReport: cls1[i].estadoReport,
              fecha: cls1[i].fecha,
              articulo: cls1[i].articulo,
              idLey: cls1[i].idLey,
              siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
              nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
              alumnosDuda: []
            })
            //sacar todos los alumnos apuntados en duda
            let data2 = {
              idDuda: cls1[i].idReport
            }

            let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;

            dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
            dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
              idUser: cls1[i].usuarioReportID
            })


          }

          if (dudasLoadArticulos != undefined) {
            await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })
          }
        } else {
          this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
        }



      }

    } else if (e.target.id == "artBusqueda3") {

      let articuloBusqueda = e.target.value
      this.setState({ cargandoDudas: true, dudasLoad: null, tipoCargaDuda: "" })
      if (isDefined(articuloBusqueda) && articuloBusqueda != '' && articuloBusqueda.length > 0) {

        let articuloPuntos = articuloBusqueda.split('.')

        if (articuloPuntos.length > 0) {
          let puntosLenghtString = articuloPuntos.length

          if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {

            puntosLenghtString = puntosLenghtString - 1
          }

          let data = { idLey: this.state.leySelected, art: articuloBusqueda, puntosLenght: puntosLenghtString }

          let dudasLoadArticulos = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLeyArticuloArt2", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }


              dudasLoadArticulos.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                alumnosDuda: []
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (dudasLoadArticulos != undefined) {
              await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })
            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }

        }


        this.setState({ valueInput2: true, artBusqueda: articuloBusqueda })
      } else {
        this.setState({ valueInput2: null, artBusqueda: null })
        // Si ley tiene buscar ley sino normal
        if (this.state.leySelected != null) {

          let data = { idLey: this.state.leySelected }
          let dudasLoadArticulos = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesJusUserFilterLeyArt2", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              dudasLoadArticulos.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                alumnosDuda: []
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (dudasLoadArticulos != undefined) {
              await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Busqueda personalizada" })
            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }


        } else {

          let data = { ultimas: 250000 }
          let dudasLoadArticulos = []
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/ultimosReportesArticulos", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1 != undefined && cls1.length > 0) {
            for (var i = 0; i < await cls1.length; i++) {
              let textAlumno = cls1[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls1[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              dudasLoadArticulos.push({
                idReport: cls1[i].idReport,
                idUser: cls1[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls1[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls1[i].estadoReport,
                fecha: cls1[i].fecha,
                articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
                alumnosDuda: []
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls1[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
                idUser: cls1[i].usuarioReportID
              })


            }

            if (dudasLoadArticulos != undefined) {
              await this.setState({ dudasLoad: dudasLoadArticulos, cargandoDudas: false, tipoCargaDuda: "Dudas recientes" })
            }
          } else {
            this.setState({ cargandoDudas: false, dudasLoad: null, tipoCargaDuda: "" })
          }

        }


      }

    }




  }


  async componentDidMount() {

    let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
    try {

      let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;
      await this.setState({ idUser: cls3[0].id_usuario });


      let response4 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
      if (!response4.ok) {
        throw Error(response4.statusText);
      }
      let json4 = await response4.json();
      let cls4 = await json4;
      await this.setState({ busquedaLeyes: cls4 });

    } catch (e) { console.log(e) }
  }
  render() {

    const { isLogged, nombreUser, tipoUser, semanasFacturadas, temasFacturados } = this.props;
    const { values1, values2, tipoBusqueda, leySelected, tipoBusquedaPersonal, reportesLoad, reportesJusticiaPendientes, reportesCPPendientes,

      valueInput1, valueInput11, valueInput22, busquedaLeyes, valueInput2, articulosDudas, testDudas, testTeoricoDudas, testPracticoDudas,
      dudasLoad, cargandoDudas, tipoCargaDuda, textHtmlDudaArtAlumno
    } = this.state

    const nivelRequired = 1;
    if (isLogged && (nivelRequired >= tipoUser || tipoUser >= 1) && (semanasFacturadas != null || tipoUser >= 58 || temasFacturados != null)) {
      return (
        <div className="bodyDudas2">


          <div className="bD2-header">
            <div className="bD2-h-left">
              <div className="bD2-h-l-TextTop">
                Hola, sobre qué tienes dudas...
              </div>
              <div className="bD2-h-l-TextMiddle">
                Puedes elegir entre nuestras <b>dos opciones principales</b> dudas sobre <b>preguntas Test</b> o dudas sobre <b>artículos</b> en relación al temario...

              </div>

              <div className="bD2-h-l-navButtons">
                <div className="bD2-h-l-navButtons-bD" style={{ marginLeft: '40px' }}>
                  {testDudas && testDudas == true ? (
                    <Boton id="testDudas" icon1="" icon2="" texto="Tests" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                  ) : (
                    <Boton2 id="testDudas" icon1="" icon2="" texto="Tests" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                  )}
                </div>
                <div className="bD2-h-l-navButtons-bD">
                  {articulosDudas && articulosDudas == true ? (
                    <Boton id="articulosDudas" icon1="" icon2="" texto="Artículos" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                  ) : (
                    <Boton2 id="articulosDudas" icon1="" icon2="" texto="Artículos" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                  )}
                </div>
              </div>
              {testDudas && testDudas == true ? (
                <div className="bD2-h-l-navButtons" style={{ justifyContent: 'flex-start' }}>
                  <div className="bD2-h-l-navButtons-bD">
                    {testTeoricoDudas && testTeoricoDudas == true ? (
                      <Boton id="testTeoricoDudas" icon1="" icon2="" texto="Teórico" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                    ) : (
                      <Boton2 id="testTeoricoDudas" icon1="" icon2="" texto="Teórico" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                    )}
                  </div>
                  <div className="bD2-h-l-navButtons-bD">
                    {testPracticoDudas && testPracticoDudas == true ? (
                      <Boton id="testPracticoDudas" icon1="" icon2="" texto="Práctico" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                    ) : (
                      <Boton2 id="testPracticoDudas" icon1="" icon2="" texto="Práctico" onClick={cargandoDudas == false ? (this.handleOnSubmit) : (null)} />
                    )}

                  </div>
                </div>
              ) : null}




            </div>
            <div className="bD2-h-right">
              <img src={'../img/woman2-2.png'} />
            </div>
          </div>



          {testPracticoDudas && testPracticoDudas == true || testTeoricoDudas && testTeoricoDudas == true || articulosDudas && articulosDudas == true ? (
            <div className="bD2-Content">

              {articulosDudas && articulosDudas == true ? (
                <div className="plantearDudaPreg2">
                  <div className="plantearDudaPreg2Nav">
                    <div style={{ padding: '10px', lineHeight: '1.7' }}>
                      ¿En que podemos ayudarte? <br />
                      {(valueInput11 == null || valueInput22 == null) ? (
                        <React.Fragment>
                          <b> Aquí puede plantear su duda, ¿comenzamos?... </b>
                          <br />
                        </React.Fragment>
                      ) : null}
                      <br />
                      Por favor indique una <b style={valueInput11 == null ? ({ color: '#3469b7', fontSize: '21px', transition: 'all 800ms' }) : ({})}>Ley</b>
                      <b style={valueInput11 != null && valueInput22 == null ? ({ color: '#3469b7', fontSize: '21px', transition: 'all 800ms' }) : ({})}> y un Artículo.</b>
                      <br />

                      <b style={valueInput11 != null && valueInput22 != null ? ({ color: '#3469b7', fontSize: '19px', transition: 'all 800ms' }) : ({ display: 'none' })}> Plantee su duda, será resuelta lo antes posible.</b>

                    </div>

                    <div className="bD2-C-Buscador" style={{ borderBottom: '0px' }}>
                      <div className="BE-CL-buscador-input">
                        <p className="BE-CL-buscador-input-VOLADOR">
                          Ley
                        </p>
                        <Input2 type="text" id="leySelected2" name="leySelected2" placeholder="Legislación" icon="fas fa-search" datalist="leyesLoad" ref="ref_leySelected2" deletedMode={valueInput11 != null ? (true) : (false)} onChange={this.handleOnSubmit} />
                        <datalist id="leyesLoad">{
                          busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
                            <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
                          )
                        }</datalist>
                      </div>

                      {valueInput11 != null ? (
                        <div className="BE-CL-buscador-input">
                          <p className="BE-CL-buscador-input-VOLADOR">
                            Artículo
                          </p>
                          <Input2 type="text" id="artBusqueda2" name="artBusqueda2" placeholder="Artículo" icon="fas fa-search" datalist="" ref="ref_artBusqueda2" deletedMode={valueInput22 != null ? (true) : (false)} onChange={this.handleOnSubmit} />

                        </div>

                      ) : null}
                    </div>
                  </div>
                  {valueInput11 && valueInput22 && valueInput11 != null && valueInput22 != null ? (
                    <React.Fragment>
                      <div className="editorPlantearrDuda">
                        <CKEditor
                          editor={ClassicEditor}
                          data={textHtmlDudaArtAlumno}
                          onInit={editor => { }}
                          config={{
                            toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                            // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                          }}

                          onChange={this.onChangeText}
                        />
                      </div>

                      <div className="editorPlantearrDudaBotones" style={{ justifyContent: 'flex-end' }}>

                        <div className="epdb-boton">
                          <Boton id="enviarDuda" icon1="" icon2="" texto="Enviar duda" onClick={this.handleOnSubmit} />
                        </div>

                      </div>
                    </React.Fragment>
                  ) : null}


                  <img className="imagenRchuload imagenRchuload2" src={'../img/woman1-2.png'} />
                </div>
              ) : null

              }

              <div className="bD2-C-Buscador">
                <div className="BE-CL-buscador-input">
                  <p className="BE-CL-buscador-input-VOLADOR">
                    Ley
                  </p>
                  <Input2 type="text" id={articulosDudas && articulosDudas == true ? ("leySelected3") : ("leySelected")} name={articulosDudas && articulosDudas == true ? ("leySelected3") : ("leySelected")} placeholder="Legislación" icon="fas fa-search" datalist="leyesLoad" ref="ref_leySelected" deletedMode={valueInput1 != null ? (true) : (false)} onChange={this.handleOnSubmit} />
                  <datalist id="leyesLoad">{
                    busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
                      <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
                    )
                  }</datalist>
                </div>

                {valueInput1 != null ? (
                  <div className="BE-CL-buscador-input">
                    <p className="BE-CL-buscador-input-VOLADOR">
                      Artículo
                    </p>

                    <Input2 type="text" id={articulosDudas && articulosDudas == true ? ("artBusqueda3") : ("artBusqueda")} name={articulosDudas && articulosDudas == true ? ("artBusqueda3") : ("artBusqueda")} placeholder="Artículo" icon="fas fa-search" datalist="" ref="ref_artBusqueda" deletedMode={valueInput2 != null ? (true) : (false)} onChange={this.handleOnSubmit} />

                  </div>

                ) : null}


              </div>
              <div className="bD2-C-titleBusca">
                {tipoCargaDuda}
              </div>

              {dudasLoad && isDefined(dudasLoad) && dudasLoad != null && dudasLoad.length > 0 ? (
                <div style={{ width: '100%' }}>
                  {testTeoricoDudas == true ? (
                    <Dudas dudas={dudasLoad} tipoDuda="testTeoria" verPregunta={true} />
                  ) : null}
                  {testPracticoDudas == true ? (
                    <Dudas dudas={dudasLoad} tipoDuda="testPractico" verPregunta={true} />
                  ) : null}

                  {articulosDudas == true ? (
                    <Dudas dudas={dudasLoad} tipoDuda="Articulo" />
                  ) : null}

                </div>
              ) : (
                cargandoDudas && cargandoDudas == true ? (
                  <div className="cargandoDudasECG">
                    <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO DUDAS </div>
                    <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
                  </div>
                ) : (
                  <div className="avisoNodudas24f5t">
                    No existe ninguna duda con la busqueda establecida. <br /><br />
                    {articulosDudas && articulosDudas == true ? (
                      <p>
                        Puede plantear su duda en la parte superior de esta página.
                      </p>
                    ) : (
                      <p>
                        Para plantear una duda sobre una pregunta de un test. <br /><br />Debes abrir el test en cuestión desde expediente, e ir a la pregunta. Donde puedes dar clic en "Ver Dudas" y plantear su duda.
                      </p>
                    )}

                  </div>
                )
              )}

            </div>
          ) : null}


        </div>
      );
    } else {
      if (isLogged && tipoUser < 58) {

        swal({
          title: 'Area restringida',
          text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {
          window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
        })
        return (null);
      } else {
        return (<Redirect to="/" />);
      }
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    semanasFacturadas: state.user.semanasFacturadas,
    temasFacturados: state.user.temasFacturados
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DudasYReportes);

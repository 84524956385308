// Dependencies
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../lib/utils/is'
//Componets

import Input2 from '../../components/Inputs/Input2'
import Boton from '../../components/Inputs/Boton'

const EdtArts = () => {


	const [busquedaLeyes, setBusquedaLeyes] = useState(null)
	const [leySelected, setleySelected] = useState(null)

	const [artBusqueda, setartBusqueda] = useState(null)
	const [artSelected, setartSelected] = useState(null)

	const refTextAreaArt = useRef()
	const refTextAreaArtInfoExtra = useRef()
	const refInputTipoArt = useRef()
	const refInputArticulo = useRef()
	const refArtLoad = useRef()


	useEffect(() => {

		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
			.then(leyes =>
				setBusquedaLeyes(leyes)
			).catch(error => console.log('Obejet fecth failed', error))

	}, [])

	const onChangeInput = async (e) => {

		if (e.target.id == 'leySelected') {

			let val = e.target.value
			let busLey = busquedaLeyes.find(l => l.siglas_ley == val)

			if (busLey != undefined) {

				let data1 = { idLey: busLey.id_ley }
				let response1 = await fetch('https://oposiciones-justicia.es/api/usuarios/artLey', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				})

				if (!response1.ok) {
					throw Error(response1.statusText)
				}
				let json1 = await response1.json()
				let cls1 = await json1

				setleySelected(busLey)
				setartBusqueda(cls1)

			} else {
				setleySelected(null)
				setartBusqueda(null)
				setartSelected(null)
			}

		} else if (e.target.id == 'artBusqueda') {
			let val = e.target.value

			let busArt = await artBusqueda.find(a => a.articulo == val)

			setartSelected(busArt != undefined ? busArt : null)

			if (busArt != undefined) {

				refTextAreaArt.current.value = busArt.textoArticulo
				refTextAreaArtInfoExtra.current.value = busArt.nombreArticulo
				refInputTipoArt.current.refs.tipoArt.value = busArt.tipo
				refInputArticulo.current.refs.articuloT.value = busArt.articulo

			}

		}

	}

	const handleSubmit = (e) => {
		if (e.target.id == 'delArt') {
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el artículo/disposición será eliminado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then(async (willDelete) => {
				if (willDelete) {

					let data1 = {
						id: artSelected.id
					}

					let response1 = await fetch('https://oposiciones-justicia.es/api/preparador/delArtLey', {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
					});
					if (!response1.ok) {
						throw Error(response1.statusText);
					}
					let json1 = await response1.json();
					let cls1 = await json1;

					if (cls1.affectedRows > 0) {
						
						let data2 = { idLey: leySelected.id_ley }
						let response2 = await fetch('https://oposiciones-justicia.es/api/usuarios/artLey', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
						})

						if (!response2.ok) {
							throw Error(response2.statusText)
						}
						let json2 = await response2.json()
						let cls2 = await json2

						refArtLoad.current.refs.artBusqueda.value = ''
						setartSelected(null)
						setartBusqueda(cls2)

						swal('Eliminacion completada', 'Puede continuar editando artículos', 'info')
					} else {
						swal("¡Algo fallo!", 'Vuelva a intentarlo', "success");
					}
				} else {
					swal("¡Art. no eliminado!", '', "success");
				}
			});
		} else if (e.target.id == 'edtArt') {
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el artículo/disposición será editado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then(async (willDelete) => {
				if (willDelete) {

					let data1 = {
						id: artSelected.id,
						art: refInputArticulo.current.refs.articuloT.value.trim(),
						tipo: refInputTipoArt.current.refs.tipoArt.value.trim(),
						texto: refTextAreaArt.current.value.trim(),
						textoExtra: refTextAreaArtInfoExtra.current.value.trim()
					}
					let response1 = await fetch('https://oposiciones-justicia.es/api/preparador/edtArtLey', {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
					});
					if (!response1.ok) {
						throw Error(response1.statusText);
					}
					let json1 = await response1.json();
					let cls1 = await json1;

					if (cls1.affectedRows > 0) {

						let data2 = { idLey: leySelected.id_ley }
						let response2 = await fetch('https://oposiciones-justicia.es/api/usuarios/artLey', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
						})

						if (!response2.ok) {
							throw Error(response2.statusText)
						}
						let json2 = await response2.json()
						let cls2 = await json2

						refArtLoad.current.refs.artBusqueda.value = ''
						setartSelected(null)
						setartBusqueda(cls2)


						swal('Proceso completado', 'Puede continuar editando artículos', 'info')
					} else {
						swal("¡Algo fallo!", 'Vuelva a intentarlo', "success");
					}

				} else {
					swal("¡Art. no editado!", '', "success");
				}
			});
		}
	}

	return (

		<div className='ColCenterStart'>

			<div className='RowCenterCenter' style={{ alignSelf: 'center' }}>
				<div className='RowCenterCenter'>
					<div className='celdaInputDAPFBN'>
						<Input2 datalist='leyesLoad' autoComplete={'off'} id={'leySelected'} type='text' name={'leySelected'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={onChangeInput} />
						<p>Legislación</p>
					</div>
					<datalist id="leyesLoad">{
						busquedaLeyes && busquedaLeyes.map((cls, key1) =>
							<option key={key1} value={cls.siglas_ley}>{cls.nombre_ley}</option>
						)
					}</datalist>

				</div>
				{artBusqueda && artBusqueda != null && artBusqueda.length > 0 ? (
					<div className='RowCenterCenter'>
						<div className='celdaInputDAPFBN'>
							<Input2 ref={refArtLoad} datalist='artLoad' autoComplete={'off'} id={'artBusqueda'} type='text' name={'artBusqueda'} placeholder='Ej: 125 bis' icon='fa-light fa-gavel' onChange={onChangeInput} />
							<p>Buscar Artículo</p>
						</div>
						<datalist id="artLoad">{
							artBusqueda && artBusqueda.map((cls2, key2) =>
								<option key={key2} value={cls2.articulo}>{cls2.tipo + " " + cls2.articulo} </option>
							)
						}</datalist>

					</div>
				) : null}

			</div>

			{artSelected != null ? (


				<div className='ColCenterStart'>

					<div className='RowCenterCenter' style={{ margin: '20px 0px' }}>
						<div className='RowCenterCenter' style={{ margin: '10px' }}>
							<Boton id={'delArt'} onClick={handleSubmit} texto={'Borrar Artículo'} />
						</div>

						<div className='RowCenterCenter' style={{ margin: '10px' }}>
							<Boton id={'edtArt'} onClick={handleSubmit} texto={'Editar Artículo'} />
						</div>
					</div>

					<div className='RowCenterCenter'>

						<div className='celdaInputDAPFBN'>
							<Input2 ref={refInputTipoArt} datalist='dataLisTipoArt' autoComplete={'off'} id={'tipoArt'} type='text' name={'tipoArt'} placeholder='Tipo Artículo o Disposición' icon='fa-light fa-gavel' onChange={onChangeInput} />
							<p>Tipo: Art o Dis. </p>

							<datalist id="dataLisTipoArt">
								<option value={'Artículo'}></option>
								<option value={'Disposición'}></option>
							</datalist>
						</div>

						<div className='celdaInputDAPFBN'>
							<Input2 ref={refInputArticulo} autoComplete={'off'} id={'articuloT'} type='text' name={'articuloT'} placeholder='Ej: 2 bis' icon='fa-light fa-gavel' onChange={onChangeInput} />
							<p>Artículo</p>
						</div>


					</div>

					<p style={{ marginTop: '5px' }}> Info extra Artículo "Nombre del artículo"</p>
					<textarea style={{ marginTop: '5px' }} ref={refTextAreaArtInfoExtra} name="" id="" cols="75" rows="2" className="textarea2"></textarea>

					<p style={{ marginTop: '5px' }}> Texto del artículo</p>
					<textarea style={{ marginTop: '5px' }} ref={refTextAreaArt} name="" id="" cols="75" rows="10" className="textarea2"></textarea>
				</div>

			) : null}

		</div>
	)

}

// EdtArts.propTypes = {

// }



export default EdtArts
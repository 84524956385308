// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// Assets
import './css/css.css';
import Input2 from '../Inputs/Input2';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadClaseCurso, filtrarClaseCurso } from '../../lib/utils/cargarSemanaClase.js';
import { isDefined } from '../../lib/utils/is';

import { loadSemanaPrg, semanaFacturadaLoad } from '../../actions/userAction'
import swal from 'sweetalert';

class CajaInfoClase extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		this.state = {};

		this.onHandleClick = this.onHandleClick.bind(this);
	}

	async onHandleClick(e) {
		//cargamos la clase en redux y redirect!
		// swal con timer
		if (isDefined(this.props.temasFacturados) && this.props.temasFacturados.length > 0) {
			swal({
				title: this.props.semPrgLoadFactura == this.props.semanaFacturada ? ('Recargando clase') : ('Cargando clase'),
				text: 'Se abrirá el contenido de la clase tras su carga.',
				icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
				buttons: false,
				closeOnEsc: false,
				closeOnClickOutside: false,
			})

			let claseLoad = await loadClaseCurso(this.props.semanaFacturada, this.props.idUser, this.state.leyes)


			if (isDefined(claseLoad)) {
				let filtroClase = await filtrarClaseCurso(claseLoad, this.props.tipoUser, this.props.userAccesoTemas, this.props.temasFacturados)
				await this.props.loadSemanaPrg(filtroClase)
				await this.props.semanaFacturadaLoad(this.props.semanaFacturada)

				if (window.location.href != 'https://oposiciones-justicia.es/aulaVirtual' || window.location.href != 'https://www.oposiciones-justicia.es/aulaVirtual') {
					window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/aulaVirtual') : ('https://oposiciones-justicia.es/aulaVirtual')
				} else {
					window.location.reload()
				}

			} else {
				swal('Error al Cargar clase', '', 'error')
			}
		} else {
			swal('Cargando contenido esencial', 'Vuelva a probar en unos segundos.', 'error')
		}


	}

	componentDidMount() {
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
			.then(leyes => this.setState({
				leyes: leyes
			}))
	}
	render() {
		const { semanaFacturada, keyProp, actuales, semanasFacturadas, allSemFac } = this.props;


		return (


			<div id={keyProp} onClick={this.onHandleClick} className='celdaClaseLE' style={keyProp == 1 && actuales == true ? ({ marginRight: "0px" }) : null} >
				{actuales && actuales == true ? (
					<div className='tituleCCLE'>
						{semanasFacturadas && semanasFacturadas.length > 0 && semanasFacturadas.length == 1 ? (
							'Estudio Semanal'
						) : (
							keyProp == 0 ? ('Clase anterior') : ('Estudio Semanal')
						)}
						{ }
					</div>
				) : null

				}

				<div id={keyProp} className='divClaseload' title={
					moment().format('YYYY-MM-DD HH:mm') >= moment(semanaFacturada.fechaClase).format('YYYY-MM-DD HH:mm') ?
						('Test disponibles y todo el contenido.') : ('Vídeos y legislación a estudiar disponibles.')
				}
				>
					<div id={keyProp} className='textoDivCM'>
						<b id={keyProp} >Categoría</b><br />
						<p id={keyProp}>{semanaFacturada.nombreCategoria}</p><br />
						<b id={keyProp}>Nombre</b><br />
						<p id={keyProp}>{semanaFacturada.nombreSemana}</p><br />
						<b id={keyProp}>Tema principal</b><br />
						<p id={keyProp}>
							{semanaFacturada.temas && semanaFacturada.temas.length > 0 && semanaFacturada.temas.map((cls2, key2) =>
								key2 == semanaFacturada.temas.length - 1 ? (cls2.tituloTema + '.') : (cls2.tituloTema + ', ')
							)}
						</p>
					</div>
					<div id={keyProp} className='semanaFechaDivCM'>
						<b id={keyProp}>{moment(semanaFacturada.fechaClase).format('YYYY-MM-DD HH:mm')}</b>
						<p id={keyProp}>Clase Nº {semanaFacturada.numClase} -
							Vuelta {isDefined(allSemFac) && allSemFac != null && allSemFac.length > 0 ? (
								allSemFac.filter(s => s.numClase == semanaFacturada.numClase).length == 0 ? ('1') : (allSemFac.filter(s => s.numClase == semanaFacturada.numClase).length)
							) : null}</p>
					</div>
				</div>

			</div>


		);
	}
}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		semPrgLoadFactura: state.user.semPrgLoadFactura,
		temasFacturados: state.user.temasFacturados,
		tipoUser: state.user.tipoUser,
		userAccesoTemas: state.user.userAccesoTemas,
		semanasFacturadas: state.user.semanasFacturadas,
		allSemFac: state.user.allSemFac
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadSemanaPrg,
		semanaFacturadaLoad
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CajaInfoClase);

// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import moment from 'moment'
import { Ellipsis } from '../../components/react-css-spinners'
import ReactPlayer from 'react-player'
import $ from 'jquery'
//Assets
import './css/css.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { puntosRes, reloadInfoPersonal, reloadNameUser } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
// Components
import Input2 from '../../components/Inputs/Input2';
import InputFileIcono from '../../components/Inputs/InputFileIcono';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';

import CajaSemanaWordTest from '../CajaSemanaWordTest';
import Word2010CompletaTest from '../Word2010CompletaTest';
import Word2010CompletaTestAdmin from '../Word2010CompletaTestAdmin';
import InputFileExamDesa from '../../components/Inputs/InputFileExamDesa';



import swal from 'sweetalert';

class Word2010HomeTest extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      inicioNumeracion: 0,
      finNumeracion: 6,
      semanasWord: [],
      semanasWordTest: [],
      sectionSelecionada2: 1,
      cursoWordEstado: null,
      facturaEstado: null,
      semanaWord2010Elegida: null,
      semanasWord2: null,
      alumnoWord: null,
      semanaWord2010ElegidaEDT: null,
      isLoading: true,
      normalCompra: true,
      primeraVez: true,
      verMasInfo: null,
      mesSeleted: null
    };
    this.handleOnClic = this.handleOnClic.bind(this);
    this.handleOnClic2 = this.handleOnClic2.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }
  async onChangeInput(e) {
    if (e.target.id == 'fechaInicio') {
      this.setState({ fechaIniTestAlumn: e.target.value })

    } else if (e.target.id == 'changeCurso') {
      swal('Funcion en desarrollo', 'Esta funcion estará disponible en primavera 2022.', 'info')
    }
  }
  async handleOnClic2(e) {

    if (e.target.id == "changeSemana") {
      this.setState({ semanaWord2010Elegida: null, semanaWord2010ElegidaEDT: null })
    } else if (e.target.id == "editSemana") {
      if (this.state.semanaWord2010Elegida != null) {
        this.setState({ semanaWord2010ElegidaEDT: true })
      } else {
        swal('Entre en un semana para editarla', '', 'warning')
      }

    } else if (e.target.id == "cerrarEdtSemana") {
      this.setState({ semanaWord2010ElegidaEDT: null })
    } else if (e.target.id == "procesarFechaIni") {
      let { fechaIniTestAlumn } = this.state
      if (fechaIniTestAlumn != '' && fechaIniTestAlumn != undefined && fechaIniTestAlumn != null) {
        if (moment(fechaIniTestAlumn).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
          let value = fechaIniTestAlumn
          let fechaSelected = await moment(value).format('YYYY-MM-DD')
          let fechaVer = await moment(value).format('DD-MM-YY')
          let fechaEmpiezaSemana = await moment(value).subtract(7, "d").format('DD-MM-YY')
          let fechaEmpiezaSemanaFac = await moment(value).subtract(7, "d").format('YYYY-MM-DD')
          let fechaFin = await moment(value).add(64, 'd').format('YYYY-MM-DD')
          let fechaFinTestSemanal = await moment(value).add(84, 'd').format('YYYY-MM-DD')
          swal({
            title: "¿Estas segur@ de escoger el " + fechaVer + "?",
            text: "Si le das a ok. Se generara el temario de Word 2010, para que realize el primer test el día " + fechaVer + ", podrás ver la primera semana a partir del " + fechaEmpiezaSemana + ".",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then(async (willDelete) => {
              if (willDelete) {
                let data = {
                  fecha: fechaSelected,
                  idUser: this.props.idUser
                }


                let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/asignarSemanaEmpezarTemario", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;

                if (cls1.affectedRows > 0) {
                  // recalcular fecha factura solo de la 1º factura
                  if (this.state.ultimaFacturaSave.suscripcion == "Temario Word 2010 - Normal") {
                    //actualizamos fecha
                    let data2 = {
                      id_factura: this.state.ultimaFacturaSave.id_factura,
                      fecha_inicio: fechaEmpiezaSemanaFac,
                      fecha_fin: fechaFin
                    }
                    let response2 = await fetch("https://oposiciones-justicia.es/api/word2010/updateFACT", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;
                    // let data3 = {
                    //   id_factura: this.state.facturasSave[1].id_factura,
                    //   fecha_inicio: fechaFin,
                    //   fecha_fin: fechaFinTestSemanal
                    // }
                    // let response3 = await fetch("https://oposiciones-justicia.es/api/word2010/updateFACT", {
                    //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                    // });
                    // if (!response3.ok) {
                    //   throw Error(response3.statusText);
                    // }
                    // let json3 = await response3.json();
                    // let cls3 = await json3;


                  }
                  swal({
                    title: 'Fecha asignada, en breves vera su temario.',
                    text: '¡Recargando web!',
                    icon: "success",
                    buttons: false,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    timer: 2500,
                  }).then(() => {
                    window.location.reload()
                  })
                } else {
                  swal('Fecha no asignada para iniciar temario Word 2010', 'Intentelo de nuevo, no se realizo la conexión correctamente.', 'success')
                }

              } else {
                swal("Fecha no asignada para iniciar temario Word 2010!", '', "success");
              }
            });
        } else {
          swal('Error, fecha incorrecta', 'Seleccione una fecha superior o igual al: ' + moment().format('DD-MM-YY'), 'warning')
        }
      } else {
        swal('Error, fecha incorrecta', 'Seleccione una fecha.', 'warning')
      }

    }

  }
  async handleOnClic(e) {
    // console.log('e.target')
    // console.log(e.target)

    // console.log('e.target.id')
    // console.log(e.target.id)
    if (e.target.id == 'siguiente') {



      let ini = this.state.inicioNumeracion + 6
      let fin = this.state.finNumeracion + 6
      if (ini > 0) {

        if (fin <= this.state.semanasWordTest.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.state.semanasWordTest.length
          if ((ini + 6) - this.state.semanasWordTest.length > 6) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            ini = this.state.semanasWordTest.length - 6 > 0 ? (this.state.semanasWordTest.length - 6) : (0)
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        ini = 0
        fin = 6
        if (fin <= this.state.semanasWordTest.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.state.semanasWordTest.length
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        }
      }




    } else if (e.target.id == 'anterior') {


      let ini = this.state.inicioNumeracion - 6
      let fin = this.state.finNumeracion - 6
      if (ini > 0) {

        if (fin <= this.state.semanasWordTest.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.state.semanasWordTest.length
          if ((ini + 6) - this.state.semanasWordTest.length > 6) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            ini = this.state.semanasWordTest.length - 6 > 0 ? (this.state.semanasWordTest.length - 6) : (0)
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        ini = 0
        fin = 6
        if (fin <= this.state.semanasWordTest.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.state.semanasWordTest.length
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        }
      }


    } else if (e.target.id == "Crear") {
      this.setState({ semanaWord2010Elegida: null, semanaWord2010ElegidaEDT: 'nuevaSemana' })
    } else if (e.target.id.split('-')[0] == 'loadSemana') {

      let loca = e.target.id.split('-')[1]
      let semanasWordFiltroNumClase = this.state.semanasWordTest.sort((a, b) => a.numClase - b.numClase).slice(this.state.inicioNumeracion, this.state.finNumeracion)[loca].numClase
      this.setState({ semanaWord2010Elegida: semanasWordFiltroNumClase })
    } else if (e.target.id == 'comprarTemario') {
      let { intensivoCompra, normalCompra } = this.state
      let data = {
        idAlumno: this.props.idUser,
        fechaFactura: moment().format('YYYY-MM-DD HH:mm'),
        fechaCaducidad: normalCompra == true ? (moment().add(70, 'd').format('YYYY-MM-DD 23:59')) : (moment().add(3, 'M').format('YYYY-MM-DD 23:59')),
        precio: normalCompra == true ? (50) : (150),
        concepto: normalCompra == true ? ('Word 2010 - Temario Normal') : ('Word 2010 - Temario Intensivo')
      }

      let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/crearFAC", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      let data2 = {
        idAlumno: this.props.idUser
      }
      let urlFetch2 = normalCompra == true ? ('https://oposiciones-justicia.es/api/word2010/crearAlumno') : ('https://oposiciones-justicia.es/api/word2010/crearAlumnoIntensivo')
      let response2 = await fetch(urlFetch2, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      if (intensivoCompra == true) {
        let data1x = {
          idUser: this.props.idUser,
          fecha: moment().format('YYYY-MM-DD 09:00')
        }
        let response1x = await fetch("https://oposiciones-justicia.es/api/word2010/asignarSemanaEmpezarTemarioIntensivo", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1x)
        });
        if (!response1x.ok) {
          throw Error(response1x.statusText);
        }
        let json1x = await response1x.json();
        let cls1x = await json1x;
      }

      // let data3 = {
      //   idAlumno: this.props.idUser,
      //   fechaFactura: moment().add(70, 'd').format('YYYY-MM-DD HH:mm'),
      //   fechaCaducidad: moment().add(91, 'd').format('YYYY-MM-DD HH:mm')
      // }
      // let response3 = await fetch("https://oposiciones-justicia.es/api/word2010/crearFACMensualTest", {
      //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      // });
      // if (!response3.ok) {
      //   throw Error(response3.statusText);
      // }
      // let json3 = await response3.json();
      // let cls3 = await json3;


      swal({
        title: 'Factura creada.',
        text: 'Puede realizar el pago en facturación',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then(() => {
        window.location.reload()
      })
    } else if (e.target.id == 'normalCompra') {
      this.setState({ normalCompra: true, intensivoCompra: null, presencialCompra: null })
    } else if (e.target.id == 'intensivoCompra') {
      // swal('No disponible', 'Este curso aun no esta disponible', 'info')
      this.setState({ normalCompra: null, intensivoCompra: true, presencialCompra: null })
    } else if (e.target.id == 'presencialCompra') {
      swal('No disponible', 'Este curso aun no esta disponible', 'info')
      // this.setState({normalCompra: null, intensivoCompra: null, presencialCompra:true})
    } else if (e.target.id == 'irAFacturacion') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/facturacion') : ('https://oposiciones-justicia.es/facturacion')
    } else if (e.target.id == 'comprarPreparacionWord2010') {
      let mesSeleted = this.state.mesSeleted
      let fechaFac
      let fechaCad

      if (this.state.alumnoWord[0].intensivo == 1) {
        fechaFac = moment().format('YYYY-MM-DD 00:01');
        fechaCad = moment().add(1, 'M').format('YYYY-MM-DD 23:59');
      } else if (mesSeleted != null && mesSeleted == 0) {
        fechaFac = moment().startOf('month').format('YYYY-MM-DD 09:00');
        fechaCad = moment().add(2, 'months').endOf('month').format('YYYY-MM-DD 09:00');
      } else if (mesSeleted != null && mesSeleted == 1) {
        fechaFac = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD 09:00');
        fechaCad = moment().add(3, 'months').endOf('month').format('YYYY-MM-DD 09:00');
      }

      let data3 = {
        idAlumno: this.props.idUser,
        fechaFactura: fechaFac,
        fechaCaducidad: fechaCad
      }
      let urlF3 = this.state.alumnoWord[0].intensivo == 0 ? ("https://oposiciones-justicia.es/api/word2010/crearFACMensualTestNew3M") : ("https://oposiciones-justicia.es/api/word2010/crearFACMensualTestNew")

      let response3 = await fetch(urlF3, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;


      swal({
        title: 'Factura creada.',
        text: 'Puede realizar el pago en facturación',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then(() => {
        window.location.reload()
      })

    } else if (e.target.id == 'masInfo') {
      if (this.state.verMasInfo == null) {
        //$('#masInfoOmenos').css("maxHeight", "2000px");
        $('#masInfoOmenos').animate({ maxHeight: '1000px' }, 1000);
        this.setState({ verMasInfo: true })
      } else {
        //$('#masInfoOmenos').css("maxHeight", "400px");
        let sizePantalla = window.innerWidth
        // console.log('sizePantalla')
        // console.log(sizePantalla)
        if (sizePantalla <= 466) {
          $('#masInfoOmenos').animate({ maxHeight: '482px' }, 1000);
          this.setState({ verMasInfo: null })
        } else {
          $('#masInfoOmenos').animate({ maxHeight: '400px' }, 1000);
          this.setState({ verMasInfo: null })
        }

      }

    } else if (e.target.id == 'esteMes') {
      this.setState({ mesSeleted: 0 })
    } else if (e.target.id == 'siguienteMes') {
      this.setState({ mesSeleted: 1 })
    }





  }


  async componentDidMount() {
    // cargar imagenPerfil en propTypes

    let data = { idUser: this.props.idUser }

    // 1º ver si esta en alumnos word 2010 y si esta activo.

    let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/claseAlumno", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
    });
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    if (cls1 != undefined) {
      let response12 = await fetch("https://oposiciones-justicia.es/api/word2010/facturasAlumnoWord2010", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response12.ok) {
        throw Error(response12.statusText);
      }
      let json12 = await response12.json();
      let cls12 = await json12;



      cls12 = cls12.sort((a, b) => a.id_factura - b.id_factura)
      this.setState({ facturasSave: cls12 })
      //filtrar factura para que sea: 
      //si solo 1 fac --> activamos antes de tiempo
      let facturaActual = null
      // Si tiene más comprobar periodo y coger factura actual
      if (cls12.length == 1) {
        facturaActual = 0

      } else if (cls12.length > 1) {

        for (let f = 0; f < cls12.length; f++) {
          //buscar fecha actual factura entre fecha!
          if (cls12[f].suscripcion == 'Word 2010 - Temario Normal' && cls12[f].estado == 'Pendiente') {
            facturaActual = f
            break;
          }
          if (moment() >= moment(cls12[f].fecha_pago) && moment() > moment(cls12[f].fecha_caducidad) && f == cls12.length - 1) {
            //ultima factura caducada
            //console.log('CADUCADA!')
            facturaActual = cls12.length - 1
            break;
          }

          if (moment() >= moment(cls12[f].fecha_pago) && moment() <= moment(cls12[f].fecha_caducidad)) {
            facturaActual = f
          }

        }
      }

      let alumnoWord = await cls1.filter(a => a.id_usuario == this.props.idUser && a.activo == 1)


      if (facturaActual == 0 && alumnoWord[0].intensivo == 0) {
        this.setState({ primeraVez: true })
      } else {
        this.setState({ primeraVez: null })
      }

      let ultimaFactura = await cls12.length > 0 ? (cls12[facturaActual]) : (null)

      this.setState({ ultimaFacturaSave: ultimaFactura })


      this.setState({ alumnoWord: await cls1.filter(a => a.id_usuario == this.props.idUser) })

      if (alumnoWord.length > 0 && cls12.length > 0 && moment() <= moment(ultimaFactura.fecha_caducidad).add(3, 'd')) {

        // SACAR TEST WORD 2010 PREPARACION SEMANAL
        let response2 = await fetch("https://oposiciones-justicia.es/api/word2010/testSemanalesWord2010");
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;

        // Generar fechas para programacion alumno
        if (facturaActual != 0 && alumnoWord[0].intensivo == 0) {

          let semanasWordTest = []
          let proximaReady = false

          //expediente alumno test
          let data3 = {
            idUser: this.props.idUser
          }
          let response3 = await fetch("https://oposiciones-justicia.es/api/word2010/expedienteWord2010Test", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json3 = await response3.json();
          let cls3 = await json3;
          let facturasAlumno = cls12.filter(f => f.tipo == 2).sort((a, b) => a.id_factura - b.id_factura)

          for (let index = 0; index < facturasAlumno.length; index++) {
            let monday = moment(facturasAlumno[index].fecha_pago).startOf('month').day(1);

            var fecha1XF = moment(facturasAlumno[index].fecha_pago);
            var fecha2XF = moment(facturasAlumno[index].fecha_caducidad);
            let mesesFacturas = fecha2XF.diff(fecha1XF, "months")

            let mondayM2 = null
            let mondayM3 = null

            if (mesesFacturas > 0) {
              if (mesesFacturas == 2) {
                mondayM2 = moment(facturasAlumno[index].fecha_pago).add(1, 'months').startOf('month').day(1);
                mondayM3 = moment(facturasAlumno[index].fecha_pago).add(2, 'months').startOf('month').day(1);

              } else if (mesesFacturas == 1) {
                mondayM2 = moment(facturasAlumno[index].fecha_pago).add(1, 'months').startOf('month').day(1);
              }
            }

            let totalLunes = 0


            if (monday.format('DD') > 7) monday.add(7, 'd');
            var month = monday.month();
            while (month === monday.month()) {

              monday.add(7, 'd');
              totalLunes++
            }

            if (mondayM2 != null) {
              if (mondayM2.format('DD') > 7) mondayM2.add(7, 'd');
              var month2 = mondayM2.month();
              while (month2 === mondayM2.month()) {

                mondayM2.add(7, 'd');
                totalLunes++
              }
            }

            if (mondayM3 != null) {
              if (mondayM3.format('DD') > 7) mondayM3.add(7, 'd');
              var month3 = mondayM3.month();
              while (month3 === mondayM3.month()) {

                mondayM3.add(7, 'd');
                totalLunes++
              }
            }

            let inicio = semanasWordTest.length
            // let inicio = totalLunes * index


            let ini2 = 0
            if (inicio > cls2.length) {
              break;
            }
            for (let i = inicio; i < (inicio + totalLunes); i++) {
              if (i < cls2.length) {
                let busquedaTestSemanalExpWord = await cls3.filter(t => t.id_test_semanal == cls2[i].id_semana)
                let puntuacionW = null
                if (busquedaTestSemanalExpWord.length > 0) {
                  let ultimoTest = busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test)[0]

                  let correctas = ultimoTest.correctas
                  let erroneas = ultimoTest.erroneas
                  let enBlanco = ultimoTest.en_blanco
                  let numPregTest = ultimoTest.num_preg_test


                  let restamos = 0.33;
                  let totalPreg = 15
                  let restamosBlanco = 0;
                  let puntuacionRealPorcentaje = 100
                  let aciertoJus = 1.33

                  let multiploMalF = parseFloat(parseFloat(totalPreg / numPregTest) * restamos)
                  let multiploMalBlanco = parseFloat(parseFloat(totalPreg / numPregTest) * restamosBlanco)
                  let multiploAcierto = parseFloat(parseFloat(totalPreg / numPregTest) * aciertoJus)

                  let correctaF = parseFloat(correctas)
                  let malF = parseFloat(parseFloat(erroneas) * multiploMalF).toFixed(2)
                  let enBlancoFF = parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
                  let puntuacion = parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
                  let puntuacionReal = parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 100)).toFixed(2)

                  puntuacionW = puntuacionReal

                }
                // revisar semanasWord y actualizar test puntuacion al ultimo intento. Indicar cual es la semana que viene a estudiar y cual falta por completar test.

                semanasWordTest.push({
                  id_semana: cls2[i].id_semana,
                  id_test: cls2[i].id_semana,
                  numClase: (i + 1),
                  fechaClase: moment(facturasAlumno[index].fecha_pago).add(7 * ini2, 'd'),
                  contenidoTarjeta: cls2[i].contenido_texto_tarjeta,
                  intensivo: 0,

                  link_test: cls2[i].link_test,
                  link_soluciones: cls2[i].link_soluciones,
                  nombreEjercicio: cls2[i].nombre_ejercicio,
                  num_preg_test: cls2[i].num_preg_test,

                  puntuacionTest: puntuacionW,
                  proximaSemana: moment().format('YYYY-MM-DD HH:mm') < moment(facturasAlumno[index].fecha_pago).add(7 * ini2, 'd').format('YYYY-MM-DD HH:mm') && proximaReady == false ? (true) : (false),
                  completarTest:
                    moment().format('YYYY-MM-DD HH:mm') >= await moment(facturasAlumno[index].fecha_pago).add(7 * ini2, 'd').format('YYYY-MM-DD HH:mm')
                      ? (
                        busquedaTestSemanalExpWord && busquedaTestSemanalExpWord.length > 0 &&
                          busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test).length > 0
                          &&
                          moment(busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test)[0].fecha).format('YYYY-MM-DD HH:mm') >=
                          await moment(facturasAlumno[index].fecha_pago).add(7 * ini2, 'd').format('YYYY-MM-DD HH:mm')
                          ? (false) : (true)
                      ) : (false),
                  vueltaNumero: busquedaTestSemanalExpWord && busquedaTestSemanalExpWord.length > 0 && busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test).length > 0 ? (busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test).length) : (null)
                })

                if (moment().format('YYYY-MM-DD HH:mm') < await moment(facturasAlumno[index].fecha_pago).add(7 * ini2, 'd').format('YYYY-MM-DD HH:mm')) {
                  proximaReady = true
                }

                ini2 = ini2 + 1
              } else {
                break;
              }

            }
          }



          for (let j = 0; j < semanasWordTest.length; j++) {
            if (semanasWordTest.filter(t => t.proximaSemana == true).length > 1) {
              semanasWordTest[j].proximaSemana = false;
            }


          }


          this.setState({ semanasWordTest: semanasWordTest.sort((a, b) => a.numClase - b.numClase) })

        } else if (alumnoWord[0].intensivo == 1) {

          let semanasWordTest = []

          //expediente alumno test
          let data3 = {
            idUser: this.props.idUser
          }
          let response3 = await fetch("https://oposiciones-justicia.es/api/word2010/expedienteWord2010Test", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json3 = await response3.json();
          let cls3 = await json3;
          let facturasAlumno = cls12.filter(f => f.tipo == 2).sort((a, b) => a.id_factura - b.id_factura)

          for (let i = 0; i < cls2.length; i++) {

            let busquedaTestSemanalExpWord = await cls3.filter(t => t.id_test_semanal == cls2[i].id_semana)
            let puntuacionW = null
            if (busquedaTestSemanalExpWord.length > 0) {
              let ultimoTest = busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test)[0]

              let correctas = ultimoTest.correctas
              let erroneas = ultimoTest.erroneas
              let enBlanco = ultimoTest.en_blanco
              let numPregTest = ultimoTest.num_preg_test


              let restamos = 0.33;
              let totalPreg = 15
              let restamosBlanco = 0;
              let puntuacionRealPorcentaje = 100
              let aciertoJus = 1.33

              let multiploMalF = parseFloat(parseFloat(totalPreg / numPregTest) * restamos)
              let multiploMalBlanco = parseFloat(parseFloat(totalPreg / numPregTest) * restamosBlanco)
              let multiploAcierto = parseFloat(parseFloat(totalPreg / numPregTest) * aciertoJus)

              let correctaF = parseFloat(correctas)
              let malF = parseFloat(parseFloat(erroneas) * multiploMalF).toFixed(2)
              let enBlancoFF = parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
              let puntuacion = parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
              let puntuacionReal = parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 100)).toFixed(2)

              puntuacionW = puntuacionReal

            }
            // revisar semanasWord y actualizar test puntuacion al ultimo intento. Indicar cual es la semana que viene a estudiar y cual falta por completar test.

            semanasWordTest.push({
              id_semana: cls2[i].id_semana,
              id_test: cls2[i].id_semana,
              numClase: cls2[i].numero_semana,
              fechaClase: moment(ultimaFactura.fecha_pago),
              contenidoTarjeta: cls2[i].contenido_texto_tarjeta,
              intensivo: 1,

              link_test: cls2[i].link_test,
              link_soluciones: cls2[i].link_soluciones,
              nombreEjercicio: cls2[i].nombre_ejercicio,
              num_preg_test: cls2[i].num_preg_test,

              puntuacionTest: puntuacionW,
              proximaSemana: false,
              completarTest: false,
              vueltaNumero: busquedaTestSemanalExpWord && busquedaTestSemanalExpWord.length > 0 && busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test).length > 0 ? (busquedaTestSemanalExpWord.sort((a, b) => b.id_test - a.id_test).length) : (null)
            })

          }


          this.setState({ semanasWordTest: semanasWordTest.sort((a, b) => a.numClase - b.numClase) })

        }

        let alumnoWordTieneCurso = await cls1.filter(a => a.id_usuario == this.props.idUser)


        if (alumnoWordTieneCurso.length == 0) {
          this.setState({ cursoWordEstado: null, facturaEstado: null })
        } else if (ultimaFactura == null) {
          this.setState({ cursoWordEstado: true, facturaEstado: false })
        } else if (alumnoWordTieneCurso.length > 0 && ultimaFactura != null) {
          // Tiene curso y factura sin comprobar

          if (ultimaFactura.estado == 'Pendiente') {
            this.setState({ cursoWordEstado: true, facturaEstado: 'noPagado', ultimaFacturaS: ultimaFactura })

          } else if (moment().format('YYYY-MM-DD HH:mm') <= moment(ultimaFactura.fecha_caducidad).add(3, 'd').format('YYYY-MM-DD HH:mm') && ultimaFactura.estado == 'Pagado') {

            this.setState({ cursoWordEstado: true, facturaEstado: true })

          } else if (moment().format('YYYY-MM-DD HH:mm') >= moment(ultimaFactura.fecha_caducidad).add(3, 'd').format('YYYY-MM-DD HH:mm')) {
            //caducada factura
            this.setState({ cursoWordEstado: true, facturaEstado: 'caducada', ultimaFacturaS: ultimaFactura })

          }
        }

      } else {
        let alumnoWordTieneCurso = await cls1.filter(a => a.id_usuario == this.props.idUser)

        if (alumnoWordTieneCurso.length == 0) {
          this.setState({ cursoWordEstado: null, facturaEstado: null })
        } else if (ultimaFactura == null) {
          this.setState({ cursoWordEstado: true, facturaEstado: false })
        } else if (alumnoWordTieneCurso.length > 0 && ultimaFactura != null) {
          // Tiene curso y factura sin comprobar

          if (ultimaFactura.estado == 'Pendiente') {
            this.setState({ cursoWordEstado: true, facturaEstado: 'noPagado', ultimaFacturaS: ultimaFactura })

          } else if (moment().format('YYYY-MM-DD HH:mm') <= moment(ultimaFactura.fecha_caducidad).add(3, 'd').format('YYYY-MM-DD HH:mm') && ultimaFactura.estado == 'Pagado') {
            if ((alumnoWordTieneCurso[0].fecha_inicio_curso_normal == null
              || alumnoWordTieneCurso[0].fecha_inicio_curso_normal == ''
              || alumnoWordTieneCurso[0].fecha_inicio_curso_normal == undefined) && alumnoWordTieneCurso[0].intensivo == 0) {
              //No tiene puesta la fecha dar la opcion y rectificar factura fecha!
              this.setState({ cursoWordEstado: false, facturaEstado: true })

            } else {
              this.setState({ cursoWordEstado: true, facturaEstado: true })
            }



          } else if (moment().format('YYYY-MM-DD HH:mm') >= moment(ultimaFactura.fecha_caducidad).add(3, 'd').format('YYYY-MM-DD HH:mm')) {

            this.setState({ cursoWordEstado: true, facturaEstado: 'caducada', ultimaFacturaS: ultimaFactura })

          }
        }
      }


    } else {
      // no tiene curso ni nada
      this.setState({ cursoWordEstado: null, facturaEstado: null, semanasWord: [] })
    }

    setTimeout(
      function () {
        this.setState({ isLoading: false })
      }.bind(this), 1200);

  }
  render() {

    const { isLogged, nombreUser, tipoUser, fotoPerfil, modeWord } = this.props;
    const { semanasWord, semanasWordTest, inicioNumeracion, finNumeracion, sectionSelecionada2, cursoWordEstado, facturaEstado, semanaWord2010Elegida, semanaWord2010ElegidaEDT, ultimaFacturaS, isLoading, normalCompra, intensivoCompra, presencialCompra, primeraVez, verMasInfo, mesSeleted, alumnoWord } = this.state
    const nivelRequired = 1;


    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      return (
        <div className="bodyWHC">
          {isLoading == false && semanasWordTest && semanasWordTest.length > 0 && cursoWordEstado == true && facturaEstado == true && semanaWord2010Elegida == null && semanaWord2010ElegidaEDT == null && primeraVez == null ? (
            <React.Fragment>
              {this.props.tipoUser > 50 ? (
                <div className='iMW-boton frgf43'>
                  <Boton2 id="Crear" icon1="" icon2="" texto="Crear" onClick={this.handleOnClic} />
                </div>
              ) : null}

              {semanasWordTest && semanasWordTest.length > 0 && semanasWordTest[0].intensivo == 0 ?
                (
                  <div className="WHC-headerInfo">
                    <div className="WHC-hi-info">

                      {semanasWordTest && semanasWordTest.length > 0 && semanasWordTest.filter(a => a.completarTest == true).length > 0 ? (
                        <div className="WHC-hi-info-celda">
                          <div style={{ width: '10px', height: '10px', background: '#3469b7', marginRight: '10px', borderRadius: '150px' }}></div>
                          Complete el test:{' '}
                          {semanasWordTest && semanasWordTest.filter(a => a.completarTest == true).sort((a, b) => a.numClase - b.numClase).map((semanaWord, key1) =>
                            key1 == semanasWordTest.filter(a => a.completarTest == true).length - 1 ? (
                              semanaWord.numClase + '.'
                            ) : (
                              key1 == semanasWordTest.filter(a => a.completarTest == true).length - 2 ? (
                                semanaWord.numClase + ' y '
                              ) : (semanaWord.numClase + ', ')
                            )
                          )}
                        </div>) : null}
                      {semanasWordTest && semanasWordTest.length > 0 && semanasWordTest.filter(a => a.proximaSemana == true).length > 0 && primeraVez == null ? (
                        <div className="WHC-hi-info-celda">
                          <div style={{ width: '10px', height: '10px', background: '#CD7442', marginRight: '10px', borderRadius: '150px' }}></div>
                          Podrá realizar el test nº {semanasWordTest && semanasWordTest.filter(a => a.proximaSemana == true)[0].numClase}, a partir del {semanasWordTest && moment(semanasWordTest.filter(a => a.proximaSemana == true)[0].fechaClase).format('DD-MM-YY')}.
                        </div>
                      ) : null}


                    </div>

                  </div>
                ) : null}

              <div className="WHC-contentManual">
                {inicioNumeracion && inicioNumeracion > 0 ? (<i class="fas fa-angle-left flyPajarito2" id="anterior" onClick={this.handleOnClic}></i>) : null}
                {semanasWordTest && semanasWordTest.length > 0 && semanasWordTest.sort((a, b) => a.numClase - b.numClase).slice(inicioNumeracion, finNumeracion).map((semanaWord, key0) =>


                  semanaWord.proximaSemana && semanaWord.proximaSemana == true ? (
                    <div className={this.props.tipoUser && this.props.tipoUser < 80 ? ("bordeCajaSeWEstudiar desactivarSemana") : ("bordeCajaSeWEstudiar")}>
                      <CajaSemanaWordTest semanaWord2010={semanaWord} keyProp={key0} handleOnClicWord={this.handleOnClic} />
                    </div>
                  ) : (
                    semanaWord.completarTest && semanaWord.completarTest == true ? (
                      <div className="bordeCajaSeWHacerTest">
                        <CajaSemanaWordTest semanaWord2010={semanaWord} keyProp={key0} handleOnClicWord={this.handleOnClic} />
                      </div>
                    ) : (

                      semanasWordTest.filter(s => s.puntuacionTest == null).length > 0 && moment().format('YYYY-MM-DD HH:mm') < moment(semanaWord.fechaClase).format('YYYY-MM-DD HH:mm') && this.props.tipoUser < 80 && semanaWord.intensivo == 0 ? (
                        <div className="bordeCajaSeWHaTFNO desactivarSemana">
                          <CajaSemanaWordTest semanaWord2010={semanaWord} keyProp={key0} handleOnClicWord={this.handleOnClic} />
                        </div>
                      ) : (
                        <div className="bordeCajaSeWHaTFNO">
                          <CajaSemanaWordTest semanaWord2010={semanaWord} keyProp={key0} handleOnClicWord={this.handleOnClic} />
                        </div>
                      )
                    )
                  )


                )}
                {finNumeracion && finNumeracion != semanasWordTest.length && semanasWordTest.length > 6 ? (<i class="fas fa-angle-right flyPajarito" id="siguiente" onClick={this.handleOnClic}></i>) : null}
              </div>





            </React.Fragment>
          ) : null}

          {isLoading == false && cursoWordEstado == true && facturaEstado == true && semanaWord2010Elegida == null && semanaWord2010ElegidaEDT == null && primeraVez == true ? (
            <React.Fragment>
              <div className="iW10b-textHeader" style={{ maxWidth: '290px', borderBottom: '13px solid #3469b7b0' }}>
                <div className="iW10b-textHinfo">
                  NO DISPONIBLE
                </div>
              </div>
              <div className="bodyOptWord2010">



                <div className="OW2-content">


                  <div className="OW2-C-info">
                    <p style={{ maxWidth: '560px' }}>Para poder acceder a <b>Test word 2010</b>,
                      debe completar el <b>Manual word 2010</b>.</p>

                    <p> Con la suscripción de 3 meses <b>Test word 2010</b>, obtendrá: </p>

                    <p style={{ marginLeft: '10px' }}> <b>1 Test semanal Word 2010</b> tipo examen. </p>
                    <p style={{ marginLeft: '10px' }}> <b>Tutor</b> word 2010, para resolver cualquier duda.</p>
                    <p style={{ marginLeft: '10px' }}> Acceso a <b>Manual word 2010</b> ritmo propio y academia.</p>
                    <p style={{ marginLeft: '5px' }}> <b>*</b> Precio <b>30€</b></p>
                  </div>

                  {/* <div className="OW2-C-precio">
                    10€
                  </div> */}
                </div>



              </div>
            </React.Fragment>
          ) : null}


          {isLoading == false && semanasWordTest && semanasWordTest.length > 0 && cursoWordEstado == true && facturaEstado == true && semanaWord2010Elegida && semanaWord2010Elegida != null && semanaWord2010ElegidaEDT == null ? (
            // Cargamos semana!
            <div>
              <Word2010CompletaTest testWordLoad={semanasWordTest.sort((a, b) => a.numClase - b.numClase).slice(inicioNumeracion, finNumeracion).find(s => s.numClase == semanaWord2010Elegida)} handleOnClicWord={this.handleOnClic2} />
            </div>
          ) : null}

          {isLoading == false && semanasWordTest && semanasWordTest.length > 0 && cursoWordEstado == true && facturaEstado == true && semanaWord2010Elegida && semanaWord2010Elegida != null && semanaWord2010ElegidaEDT == true ? (
            // Cargamos semana!
            <div>
              <Word2010CompletaTestAdmin semanaWordLoad={semanasWordTest.sort((a, b) => a.numClase - b.numClase).slice(inicioNumeracion, finNumeracion).find(s => s.numClase == semanaWord2010Elegida)} handleOnClicWord={this.handleOnClic2} />
            </div>
          ) : null}

          {isLoading == false && semanasWordTest && semanasWordTest.length > 0 && cursoWordEstado == true && facturaEstado == true && semanaWord2010Elegida == null && semanaWord2010ElegidaEDT == 'nuevaSemana' ? (
            // Cargamos semana!
            <div>
              <Word2010CompletaTestAdmin handleOnClicWord={this.handleOnClic2} />
            </div>
          ) : null}

          {isLoading == false && cursoWordEstado == null && facturaEstado == null ? (
            // No tiene curso COMPRAR!
            <div className="bodyOptWord2010">

              <div id="masInfoOmenos" className="OW2-content">
                <div className="OW2-C-menu">

                  <div style={{ paddingLeft: '0px' }}> Preparación: </div>
                  <div id="normalCompra" onClick={this.handleOnClic} style={normalCompra && normalCompra == true ? ({ borderRight: '1px solid #bcbcbc', color: '#3469b7', fontWeight: '500' }) : ({ borderRight: '1px solid #bcbcbc' })}>
                    Normal </div>
                  <div id="intensivoCompra" onClick={this.handleOnClic} style={intensivoCompra && intensivoCompra == true ? ({ color: '#3469b7', fontWeight: '500' }) : ({})}>
                    Intensivo </div>
                  {/* <div id="presencialCompra" onClick={this.handleOnClic} style={presencialCompra && presencialCompra == true ? ({}) : ({ color: '#bcbcbc' })}>
                      Presencial </div> */}

                </div>
                <div className="recPlayVidDiv" onContextMenu={e => e.preventDefault()}
                  style={{
                    position: 'relative',
                    alignSelf: 'center',
                    marginTop: '0px',
                    marginBottom: '15px',
                    maxWidth: '450px'
                  }}>
                  <ReactPlayer
                    url={'../videos/word2010CEJ.mp4'}
                    className='react-player'
                    playing={false}
                    width='100%'
                    height='100%'
                    controls={true}
                    onContextMenu={e => e.preventDefault()}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />

                  <div className="nextRectPlaerClass2" >
                    Manual Word 2010
                  </div>
                </div>
                {
                  intensivoCompra && intensivoCompra == true ? (
                    <div className="OW2-C-info" style={{ maxWidth: '850px' }}>

                      <p> <b>Bienvenid@</b>.</p>

                      <p> <b>Manual Word 2010</b> en PDF. </p>

                      <p>Preparación del manual en modo <b>intensivo</b> 1 a 3 meses.</p>

                      <p>El alumno deberá ir estudiando una <b>parte del manual y atajos</b>, al ritmo que disponga diario de estudio.</p>
                      <p>Recomedamos dar 3 vueltas al temario con sus test y 2 a los Test Word 2010</p>
                      <p>Mientras va estudiando o repasando el temario puede acceder a la sección <b>Test de Word</b>, dispondrá de 20 test tipo examen.</p>

                      <p>El tiempo estimado diario sería de 1 a 6 horas aproximadamente, pudiendo dar 1 vuelta al temario en 1 o 2 semanas según experiencia en word 2010.</p>
                      <p><b>*</b> Precio curso intensivo 150€.</p>
                      <p><b>*</b> Tendrá acceso durante 3 meses.</p>


                    </div>
                  ) : null
                }

                {
                  normalCompra && normalCompra == true ? (
                    <div className="OW2-C-info" style={{ maxWidth: '850px' }}>

                      <p> <b>Bienvenid@</b>.</p>

                      <p> <b>Manual Word 2010</b> en PDF. </p>

                      <p>Preparación del manual en <b>10 semanas</b> </p>

                      <p>Cada semana el alumno deberá estudiar una <b>parte del manual y atajos</b> correspondientes.</p>

                      <p>Tras su estudio se habilitará la descarga del <b>Test de Word</b>, así como la semana siguiente de estudio.</p>

                      <p>El tiempo estimado diario sería de 10 a 30 minutos aproximadamente.</p>
                      <p><b>*</b> Precio manual 50€.</p>
                      <p><b>*</b> El contenido se ira debloqueando semanalmente. Para completar temario y test word 2010, se requiere un total de 6 a 8 meses.</p>
                      <p><b>*</b> Tras completar el manual word 2010, se habilatará la zona Test word 2010 tipo Examen, suscripción 30€ 3 meses.</p>

                      <p id="intensivoCompra" onClick={this.handleOnClic}><b>*</b> Para preparación exclusiva word 2010 puede optar por el curso intensivo, tendrá acceso a todo el contenido desde el primer día, la preparacion será de 1 a 3 meses segun el ritmo de estudio .</p>
                    </div>
                  ) : null
                }


                <div className="OW2-C-menu-botonesS34">
                  <div className='iMW-boton' style={{ alignSelf: 'flex-start', marginTop: '8px' }}>
                    {verMasInfo == null ? (
                      <Boton id="masInfo" icon1="" icon2="" texto="Ver más información" onClick={this.handleOnClic} />
                    ) : (
                      <Boton id="masInfo" icon1="" icon2="" texto="Cerrar información" onClick={this.handleOnClic} />
                    )}


                  </div>
                  <div className='iMW-boton' style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
                    <Boton id="comprarTemario" icon1="" icon2="" texto="Comprar" onClick={this.handleOnClic} />
                  </div>
                </div>


                <div className="OW2-C-precio">
                  {
                    intensivoCompra && intensivoCompra == true ? (
                      '150€'
                    ) : null
                  }

                  {
                    normalCompra && normalCompra == true ? (
                      '50€'
                    ) : null
                  }
                </div>
              </div>



            </div>
          ) : null}


          {isLoading == false && cursoWordEstado == true && facturaEstado == 'caducada' ? (
            // FAC CADICADA
            <React.Fragment>
              <div className="iW10b-textHeader" style={{ maxWidth: '290px', borderBottom: '13px solid #3469b7b0' }}>
                <div className="iW10b-textHinfo">
                  Factura caducada
                </div>
              </div>
              <div className="bodyOptWord2010">



                <div className="OW2-content" style={{ maxHeight: 'unset' }}>


                  {alumnoWord && alumnoWord.length > 0 && alumnoWord[0].intensivo == 0 ? (
                    <div className="OW2-C-info">
                      <p style={{ maxWidth: '650px' }}>Para poder seguir usando la plataforma de word 2010,<br /> debe suscribirse a <b>Test word 2010</b></p>
                      <p> Con la suscripcion de 3 meses <b>Test word 2010</b>, obtendrá: </p>
                      <p style={{ marginLeft: '10px' }}> <b>1 Test semanal Word 2010</b> tipo examen. </p>
                      <p style={{ marginLeft: '10px' }}> <b>Tutor</b> word 2010, para resolver cualquier duda.</p>
                      <p style={{ marginLeft: '10px' }}> Acceso a <b>Manual word 2010</b> ritmo propio y academia.</p>

                      <p>El tiempo estimado diario sería de 10 a 30 minutos aproximadamente.</p>

                      <p>Recomendamos el <b>repaso del manual</b> compaginándolo con 1 Test word 2010.</p>

                      <p><b>*</b> La suscripción a Test word 2010, son 30€.</p>
                    </div>
                  ) : (
                    <div className="OW2-C-info">
                      <p style={{ maxWidth: '650px' }}>Para poder seguir usando la plataforma,<br /> debe tener una <b>suscripción mensual</b></p>
                      <p> Con la suscripcion de 1 mes, obtendrá: </p>
                      <p style={{ marginLeft: '10px' }}> <b>Acceso a todo el contenido de word 2010</b></p>
                      <p style={{ marginLeft: '10px' }}> <b>Tutor</b> word 2010, para resolver cualquier duda.</p>
                    </div>
                  )}

                  {alumnoWord && alumnoWord.length > 0 && alumnoWord[0].intensivo == 0 ? (
                    <div className="cfdks3fjkgv">
                      <div style={{ fontSize: '17px', color: '#3469b7', marginBottom: '20px' }}>
                        {mesSeleted == null ? ('Seleccione el mes para iniciar la suscripción') : null}
                        {mesSeleted == 0 ? ('La suscripción iniciará desde ' + moment().format('MMMM')) : null}
                        {/* {mesSeleted == 1 ? ('La suscripcion será para ' + moment().add(1, 'months').format('MMMM')) : null} */}

                      </div>


                      <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='iMW-boton' style={{ marginTop: '8px', textTransform: 'capitalize' }}>

                          {mesSeleted == 0 ?
                            (<Boton id="esteMes" icon1="" icon2="" texto={moment().format('MMMM')} onClick={this.handleOnClic} />) :
                            (<Boton2 id="esteMes" icon1="" icon2="" texto={moment().format('MMMM')} onClick={this.handleOnClic} />)}

                        </div>
                        {/* <div className='iMW-boton' style={{ marginTop: '8px', textTransform: 'capitalize' }}>
                        {mesSeleted == 1 ?
                          (<Boton id="siguienteMes" icon1="" icon2="" texto={moment().add(1, 'months').format('MMMM')} onClick={this.handleOnClic} />) :
                          (<Boton2 id="siguienteMes" icon1="" icon2="" texto={moment().add(1, 'months').format('MMMM')} onClick={this.handleOnClic} />)}

                      </div> */}
                      </div>
                    </div>
                  ) : null}

                  {mesSeleted != null || alumnoWord[0].intensivo == 1 ? (
                    <div className='iMW-boton' style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
                      <Boton id="comprarPreparacionWord2010" icon1="" icon2="" texto="Comprar" onClick={this.handleOnClic} />
                    </div>
                  ) : null}


                  <div className="OW2-C-precio">
                    {alumnoWord && alumnoWord.length > 0 && alumnoWord[0].intensivo == 0 ? ('30€') : ('10€')}
                  </div>
                </div>



              </div>
            </React.Fragment>
          ) : null}

          {isLoading == false && cursoWordEstado == true && facturaEstado == 'noPagado' ? (
            // FACTURA PENDIENTE ---> 
            <div className="infoW10body">
              <div className="iW10b-textHeader">
                <div className="iW10b-textHinfo">
                  Factura pendiente
                </div>
              </div>

              <div className="iW10b-textinfo2">
                <b>{' ' + ultimaFacturaS.concepto}</b> <br /> <br />
                Por favor haga clic en el siguiente enlace para proceder al pago de la factura.
              </div>

              <div className='iMW-boton' style={{ marginTop: '8px' }}>
                <Boton id="irAFacturacion" icon1="" icon2="" texto="Ir a facturación" onClick={this.handleOnClic} />
              </div>

            </div>
          ) : null}


          {isLoading == true ? (
            // is loading ---> 
            <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
              <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO TEST WORD 2010 </div>
              <Ellipsis color="#3469b7" size={72} />
            </div>
          ) : null}

        </div>

      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    fotoPerfil: state.user.fotoPerfil
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Word2010HomeTest);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/insertTest.css';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cambiarDatosTestJusticia, eliminarTestJusticiaCache, addPreguntaTestJusticiaInsert, deletePreguntaTestJusticiaInsert, changeNumPreguntaTestJusticiaInsert } from './actions/testJusticiaAction';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class InsertTest extends Component {
	static propTypes = {
		datosTestJusticia: PropTypes.array.isRequired
	};
	constructor(props){
		super(props);
        	this.state = {
			displayNameTest: 'none',
			numPreg: 1,
			numeroPreguntaEspecifico: undefined,
			busquedaLeyes: undefined,
			displayInsertTest: 'flex',
			preguntasDuplicadas: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		   ],
		   values2: [
			   {
				   value: '',
				   name: '¿Unica o Multiple?',
				   disable: 'disabled',
				   selected: 'selected'
			  },{
				  value: '1',
				  name: 'Unica Respuesta',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '2',
				  name: 'Multi Respuesta',
				  disable: undefined,
				  selected: undefined
			 },
			 ,{
				  value: '3',
				  name: 'Multiple Falsa',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '4',
				  name: 'Con una Variable',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '5',
				  name: 'Multiple a ó b',
				  disable: undefined,
				  selected: undefined
			 }
		 ],
		 values3: [
			 {
				 value: '',
				 name: 'Res. Normal',
				 disable: 'disabled',
				 selected: 'selected'
			},{
				value: '0',
				name: 'Incorrecta',
				disable: undefined,
				selected: undefined
		    },{
				value: '1',
				name: 'Correcta',
				disable: undefined,
				selected: undefined
		    }
	    ],
	    values4: [
		    {
			    value: '',
			    name: 'Res. Multiple',
			    disable: 'disabled',
			    selected: 'selected'
		   },{
			   value: '0',
			   name: 'Incorrecta',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '1',
			   name: 'Mutl. Correcta',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '2',
			   name: 'Var. de Multiple',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '10',
			   name: 'Var. de Multiple + Var. RUV1',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '16',
			   name: 'Var. de Multiple + Var. RUV2',
			   disable: undefined,
			   selected: undefined
		  },{
    		    value: '7',
    		    name: 'RUV 1 --> Falsa',
    		    disable: undefined,
    		    selected: undefined
    	   	},{
  		  value: '13',
  		  name: 'RUV 2 --> Falsa',
  		  disable: undefined,
  		  selected: undefined
  	 	},{
  		    value: '8',
  		    name: 'Var. RUV 1',
  		    disable: undefined,
  		    selected: undefined
  	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   }
	  ],
	  values5: [
		  {
			  value: '',
			  name: 'Res. Multiple Falsa',
			  disable: 'disabled',
			  selected: 'selected'
		 },{
		 	 value: '1',
		 	 name: 'Correcta',
		 	 disable: undefined,
		 	 selected: undefined
		 },{
 			value: '5',
 			name: 'Correcta + Var. Mult. Falsa',
 			disable: undefined,
 			selected: undefined
 		},{
			 value: '0',
			 name: 'Incorrecta',
			 disable: undefined,
			 selected: undefined
	     },{
			 value: '3',
			 name: 'Mult. Falsa',
			 disable: undefined,
			 selected: undefined
	     },{
			 value: '4',
			 name: 'Var. Mult. Falsa',
			 disable: undefined,
			 selected: undefined
	     },{
  		    value: '7',
  		    name: 'RUV 1 --> Falsa',
  		    disable: undefined,
  		    selected: undefined
  	   	},{
		  value: '13',
		  name: 'RUV 2 --> Falsa',
		  disable: undefined,
		  selected: undefined
	 	},{
			 value: '10',
			 name: 'Var. de Multiple + Var. RUV1 + Falsa',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '16',
			 name: 'Var. de Multiple + Var. RUV2 + Falsa',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '11',
			 name: 'Var. de Multiple + Var. RUV1 + Correcta',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '17',
			 name: 'Var. de Multiple + Var. RUV2 + Correcta',
			 disable: undefined,
			 selected: undefined
		},{
  		    value: '8',
  		    name: 'Var. RUV 1',
  		    disable: undefined,
  		    selected: undefined
  	   },{
  		    value: '9',
  		    name: 'Var. RUV 1 "Falsa" + Correcta',
  		    disable: undefined,
  		    selected: undefined
  	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '15',
		    name: 'Var. RUV 2 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   }
	],
	values6: [
		{
			value: '',
			name: 'RUV - Unica Variable',
			disable: 'disabled',
			selected: 'selected'
	    },{
		    value: '1',
		    name: 'Correcta',
		    disable: undefined,
		    selected: undefined
	    },{
		    value: '0',
		    name: 'Incorrecta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '6',
		    name: 'RUV 1 --> Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '7',
		    name: 'RUV 1 --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '8',
		    name: 'Var. RUV 1',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '9',
		    name: 'Var. RUV 1 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '12',
		    name: 'RUV 2 --> Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '13',
		    name: 'RUV 2 --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '15',
		    name: 'Var. RUV 2 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '3',
		    name: 'Multiple --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '4',
		    name: 'Var. Multiple',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '5',
		    name: 'Var. Multiple + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '22',
		    name: 'MUV --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '23',
		    name: 'Var. MUV',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '24',
		    name: 'Var. MUV + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '10',
		    name: 'Var. de Multiple + Var. RUV1 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '16',
		    name: 'Var. de Multiple + Var. RUV2 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '11',
		    name: 'Var. de Multiple + Var. RUV1 + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '17',
		    name: 'Var. de Multiple + Var. RUV2 + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '18',
		    name: 'Var. RUV1 + Var. RUV2 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '19',
		    name: 'Var. RUV1 + Var. RUV2 + Correcta',
		    disable: undefined,
		    selected: undefined
	   }
    ],
    values7: [
	    {
		    value: '',
		    name: 'MUV - Mult. con a ó b',
		    disable: 'disabled',
		    selected: 'selected'
	   },{
		   value: '1',
		   name: 'Correcta',
		   disable: undefined,
		   selected: undefined
	   },{
		   value: '0',
		   name: 'Incorrecta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '20',
		   name: 'MUV --> Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '21',
		   name: 'Var. MUV',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '22',
		   name: 'MUV --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '23',
		   name: 'Var. MUV Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '24',
		   name: 'Var. MUV Falsa + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '10',
		   name: 'Var. MUV + Var. RUV1 + Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '16',
		   name: 'Var. MUV + Var. RUV2 + Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '11',
		   name: 'Var. MUV + Var. RUV1 + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '17',
		   name: 'Var. MUV + Var. RUV2 + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '7',
		   name: 'RUV 1 --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '13',
		   name: 'RUV 2 --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '8',
		   name: 'Var. RUV 1',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '14',
		   name: 'Var. RUV 2',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '18',
		   name: 'Var. RUV 1 + Var. RUV 2',
		   disable: undefined,
		   selected: undefined
	  }
   ]
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleRellenarDatos = this.handleRellenarDatos.bind(this);
		this.meterTestTemplate = this.meterTestTemplate.bind(this);
		this.borrarNumerosPrgInsert = this.borrarNumerosPrgInsert.bind(this);
		this.retrocederPreguntas = this.retrocederPreguntas.bind(this);
	}

	handleRellenarDatos(numPregPrev){
		let { datosTestJusticia } = this.props;
		let {numPreg , busquedaLeyes} = this.state;
		this.refs.ref_pregunta.refs.pregunta.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).pregunta
		this.refs.ref_subrayadoA.refs.subrayadoA.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoA
		this.refs.ref_subrayadoB.refs.subrayadoB.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoB
		this.refs.ref_subrayadoC.refs.subrayadoC.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoC
		this.refs.ref_subrayadoD.refs.subrayadoD.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoD
		this.refs.ref_notaA.refs.notaA.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).notaA
		this.refs.ref_notaB.refs.notaB.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).notaB
		this.refs.ref_notaC.refs.notaC.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).notaC
		this.refs.ref_notaD.refs.notaD.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).notaD
		this.refs.ref_articulo.refs.articulo.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).articulo

		let siglasLey = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).ley
		if(busquedaLeyes != undefined && siglasLey != ''){
			let objectLey = busquedaLeyes.find(opo => opo.siglas_ley == siglasLey)
			if( objectLey !== undefined){
				this.refs.ref_siglasLey.refs.siglasLey.value = siglasLey;
			} else {
				setTimeout(
				    function() {
				        swal('Auto relleno de Ley fallo. Lal ley: '+siglasLey+' no existe en la base de datos CEJ.','Si no la encuentra manualmente, avise para que introduzcan dicha LEY.','warning')
				   }.bind(this),2000);
				    this.refs.ref_siglasLey.refs.siglasLey.value = ""
			}
		}

		this.refs.ref_tipoRespuesta.refs.tipoRespuesta.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta
		this.refs.ref_respuesta1.refs.respuesta1.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r1
		this.refs.ref_respuesta2.refs.respuesta2.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r2
		this.refs.ref_respuesta3.refs.respuesta3.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r3
		this.refs.ref_respuesta4.refs.respuesta4.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r4
		if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != '' && datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != undefined){
		setTimeout( function() {
			    this.refs.ref_r1Validez.refs.r1Validez.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r1_validez
			    this.refs.ref_r2Validez.refs.r2Validez.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r2_validez
			    this.refs.ref_r3Validez.refs.r3Validez.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r3_validez
			    this.refs.ref_r4Validez.refs.r4Validez.value = datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).r4_validez
		    }.bind(this), 150);
	    }

	}
	onChange(e){
		let { datosTestJusticia } = this.props

		if (e.target.name == 'numeroPreguntaEspecifico'){
			if(e.target.value <= datosTestJusticia[0].preguntas.length && e.target.value > 0){
				this.setState({ numeroPreguntaEspecifico: e.target.value })
			}
		} else if (e.target.name == 'tipoRespuesta' || e.target.name == 'pregunta' || e.target.name == 'nota' || e.target.name == 'subrayadoA' || e.target.name == 'subrayadoB' || e.target.name == 'subrayadoC' || e.target.name == 'subrayadoD' || e.target.name == 'articulo' || e.target.name == 'respuesta1' || e.target.name == 'r1Validez' || e.target.name == 'respuesta2' || e.target.name == 'r2Validez' || e.target.name == 'respuesta3' || e.target.name == 'r3Validez' || e.target.name == 'respuesta4' || e.target.name == 'r4Validez'  || e.target.name == 'notaA' || e.target.name == 'notaB' || e.target.name == 'notaC' || e.target.name == 'notaD') {
			let id = e.target.id.split('-')[1];
			this.props.cambiarDatosTestJusticia(id, e.target.name, e.target.value)

		} else if(e.target.name == 'siglasLey'){

			if(this.state.busquedaLeyes.find( l => l.siglas_ley == e.target.value) != undefined){
				let id = e.target.id.split('-')[1];
				let idLey = this.state.busquedaLeyes.find( l => l.siglas_ley == e.target.value).id_ley
				this.props.cambiarDatosTestJusticia(id, e.target.name, e.target.value, idLey)
			}
		}

	}
	async handleOnClick(e){
		this.props.onClick(e);
		if(e.target.id == "preguntaSiguiente"){
			let { datosTestJusticia } = this.props
			if(this.state.numeroPreguntaEspecifico != undefined){
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if(numPregPrev <= datosTestJusticia[0].preguntas.length && numPregPrev > 0){
					this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
   						    noValidar: {
   						      text: "No validar",
   						      value: "noValidar",
						 },
						    validar: {
						      text: "Validar",
						      value: "validar",
						    }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticia(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}

				} else {
					swal('La pregunta: '+numPregPrev+' no existe, rango valido: 1 - '+datosTestJusticia[0].preguntas.length+'.','','warning');
				}

			} else {

				let numPregPrev = parseInt(this.state.numPreg + 1);
				if(numPregPrev <= datosTestJusticia[0].preguntas.length  && numPregPrev > 0){
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticia(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				}else{
					swal('¡No hay más preguntas, solo puede retroceder o finalizar la insercción!','','warning');
				}
			}
		} else if(e.target.id == "preguntaAnterior") {
			let { datosTestJusticia } = this.props
			if(this.state.numeroPreguntaEspecifico != undefined){
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if(numPregPrev <= datosTestJusticia[0].preguntas.length && numPregPrev > 0){
					this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticia(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				} else {
					swal('La pregunta: '+numPregPrev+' no existe, rango valido: 1 - '+datosTestJusticia[0].preguntas.length+'.','','warning');
				}
			} else {
				let numPregPrev = parseInt(this.state.numPreg - 1);
				if(numPregPrev<= datosTestJusticia[0].preguntas.length  && numPregPrev > 0){
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticia(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				}else{
					swal('No puede retroceder más.','','warning');
				}
			}
		}else if(e.target.id == "loadPregClick"){
			let numPregPrev = parseInt(e.target.innerText);
			let { datosTestJusticia } = this.props;
			if(numPregPrev <= datosTestJusticia[0].preguntas.length && numPregPrev > 0){
				this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
				this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
				this.handleRellenarDatos(numPregPrev);
				if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
					$("#iTScontainer2").addClass("iTScontainerValidada");
					$("#iTScontainer2").removeClass("iTScontainer2");
				} else {
					$("#iTScontainer2").addClass("iTScontainer2");
					$("#iTScontainer2").removeClass("iTScontainerValidada");
				}
				let numPregReal = this.state.numPreg
				if(datosTestJusticia[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
					// validamos pregunta
					swal("¿Validamos la pregunta "+numPregReal+"?","", {
					  buttons: {
						  noValidar: {
							 text: "No validar",
							 value: "noValidar",
						 },
						 validar: {
							  text: "Validar",
							  value: "validar",
						  }
					  },
					})
					.then((value) => {
					  switch (value) {
					    case "validar":

					   this.props.cambiarDatosTestJusticia(numPregReal, 'validarPregunta', 1)
						 break;
					    case "noValidar":
						 break;
					  }
					});
				}
			}
		} else if (e.target.id == "insertarTest"){
			let numPreguntaError = 0
			let respuestaError = ''
			try{
			let pregD = []
			let preg1 = ''
			let preg2 = ''

			if(this.props.datosTestJusticia[0].preguntas.filter(preg => preg.preguntaCheck === 0).length == 0){




				let duplicadasMismoTest = false
				for (var p = 0; p < this.props.datosTestJusticia[0].preguntas.length; p++) {

					numPreguntaError = this.props.datosTestJusticia[0].preguntas[p].numPreg
					let pregTesting = this.props.datosTestJusticia[0].preguntas[p].pregunta
		 			 if(pregTesting.split('"') != undefined && pregTesting.split('"').length > 1){
		 				 throw new Error('Error uso de comillas dobles Preg');
		 			 }
		 		   let r1Testing =  this.props.datosTestJusticia[0].preguntas[p].r1
		 			 if(r1Testing.split('"') != undefined && r1Testing.split('"').length > 1){
						 respuestaError = 'a)'
		 				 throw new Error('Error uso de comillas dobles');

		 			 }
		 		   let r2Testing =  this.props.datosTestJusticia[0].preguntas[p].r2
		 			 if(r2Testing.split('"') != undefined && r2Testing.split('"').length > 1){
						 respuestaError = 'b)'
		 				 throw new Error('Error uso de comillas dobles');
		 			 }
		 		   let r3Testing =  this.props.datosTestJusticia[0].preguntas[p].r3
		 			 if(r3Testing.split('"') != undefined && r3Testing.split('"').length > 1){
						 respuestaError = 'c)'
		 				 throw new Error('Error uso de comillas dobles');
		 			 }
		 		   let r4Testing =  this.props.datosTestJusticia[0].preguntas[p].r4
		 			 if(r4Testing.split('"') != undefined && r4Testing.split('"').length > 1){
						 respuestaError = 'd)'
		 				 throw new Error('Error uso de comillas dobles');
		 			 }



					let numPregRev = this.props.datosTestJusticia[0].preguntas[p].numPreg
					let preg = await this.props.datosTestJusticia[0].preguntas[p].pregunta
					let pregRev = this.props.datosTestJusticia[0].preguntas[p]
					let duplicada = await this.props.datosTestJusticia[0].preguntas.filter(p => p.pregunta == preg && p.numPreg != numPregRev)
					 if(duplicada && duplicada.length > 0){
						 let r1Dupl = false
						 let r2Dupl = false
						 let r3Dupl = false
						 let r4Dupl = false
						 	if(duplicada.r1 == pregRev.r1 || duplicada.r1 == pregRev.r2 || duplicada.r1 == pregRev.r3 || duplicada.r1 == pregRev.r4){
								r1Dupl = true
							}
							if(duplicada.r2 == pregRev.r1 || duplicada.r2 == pregRev.r2 || duplicada.r2 == pregRev.r3 || duplicada.r2 == pregRev.r4){
								r2Dupl = true
							}
							if(duplicada.r3 == pregRev.r1 || duplicada.r3 == pregRev.r2 || duplicada.r3 == pregRev.r3 || duplicada.r3 == pregRev.r4){
								r3Dupl = true
							}
							if(duplicada.r4 == pregRev.r1 || duplicada.r4 == pregRev.r2 || duplicada.r4 == pregRev.r3 || duplicada.r4 == pregRev.r4){
								r4Dupl = true
							}
							if(r1Dupl == true && r2Dupl == true && r3Dupl == true && r4Dupl == true){
								preg1 = numPregRev
	 						 	preg2 = duplicada[0].numPreg
	 						 	duplicadasMismoTest = true
							}

					 }
				}
				if(duplicadasMismoTest == false){

				let { datosTestJusticia } = this.props
				await this.setState({displayInsertTest: 'none'});
				for (var i = 0; i < await datosTestJusticia[0].preguntas.length; i++) {

					let numPregRedux = i+1;
					console.log(datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)))
					if(datosTestJusticia[0].preguntas.find(p=> p.insertar == 1) != undefined && datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).insertar == 1){

						let data = { idOposicion: datosTestJusticia[0].oposicion,
						idLey: datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).idLey,
						articulo: datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).articulo,
						pregunta: datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).pregunta,
						tipoPregunta: datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).tipoPregunta
						}
						let response1 = await fetch("https://oposiciones-justicia.es/api/informatica/addPreguntaJusticia", {
							method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
							if (!response1.ok) {
								throw Error(response1.statusText);
							}
						let json1 = await response1.json();
						let cls1 = await json1;

						if(cls1 != undefined){
							this.props.cambiarDatosTestJusticia(numPregRedux,'idPregunta',cls1.insertId)
							console.log(cls1)
							let data2 = {
							 idPregunta: cls1.insertId,
							 r1: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r1,
							 r1Validez: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r1_validez,
							 notaA: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).notaA,
							 subrayadoA: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).subrayadoA,
							 r2: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r2,
							 r2Validez: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r2_validez,
							 notaB: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).notaB,
							 subrayadoB: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).subrayadoB,
							 r3: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r3,
							 r3Validez: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r3_validez,
							 notaC: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).notaC,
							 subrayadoC: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).subrayadoC,
							 r4: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r4,
							 r4Validez: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).r4_validez,
							 notaD: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).notaD,
							 subrayadoD: datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).subrayadoD
						 }
						 	let response2 = await fetch("https://oposiciones-justicia.es/api/informatica/addRespuestasJusticia", {
 							method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
 							if (!response2.ok) {
 								throw Error(response2.statusText);
 							}
	 						let json2 = await response2.json();
	 						let cls2 = await json2;

							if(cls2 != undefined){
								console.log(cls2)
								for (var j = 0; j < cls2.affectedRows; j++) {
									let num= j+1;
									this.props.cambiarDatosTestJusticia(datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPregRedux).numPreg, 'idRespuesta'+num,parseInt(cls2.insertId + j))
								}
							}

						}

				} else if (datosTestJusticia[0].preguntas.find(p=> p.insertar == 2) != undefined && datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).insertar == 2 || datosTestJusticia[0].preguntas.find(p=> p.insertar == 0) != undefined && datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).insertar == 0) {
					pregD.push({
						pregunta: await datosTestJusticia[0].preguntas.find(p=> p.numPreg == (numPregRedux)).pregunta,
						numPreg: await numPregRedux
					})
				}
			}

		if(await datosTestJusticia[0].testPredefinido == 1) {
				await this.meterTestTemplate();
				if(pregD.length > 0){
					swal(`Inserción Completada, ${pregD.length} preguntas duplicadas.`,'Duplicadas añadidas al test predefinido.', 'success')
				} else {
					swal('Inserción Completada', '','success')
				}

 	   } else {
			 if(pregD.length > 0){
				 let preguntasNumeroLit = '';
				 for (var i = 0; i < pregD.length; i++) {
					 preguntasNumeroLit = preguntasNumeroLit+' '+pregD[i].numPreg
				 }
				 swal('Inserción Completada','Se encontraron '+pregD.length+' preguntas duplicadas no añadidas: '+preguntasNumeroLit+'.', 'success')

			 } else {
				 swal('Inserción Completada','', 'success')

			 }
		 }
		 await this.setState({ textAreaInfo: undefined, testCompleto : [{ testPredefinido: undefined, nombreTest: undefined, oposicion: undefined, preguntas: []}] })
		 //setTimeout(function() {
		 	await window.location.reload()
			 await this.props.eliminarTestJusticiaCache();

     //}.bind(this),5000);


	} else {
		swal('Pregunta Nº: '+preg1+' es la misma que la Nº: '+preg2+'.','Elimine una y vuelva a intentar insertar el test','warning')
	}
	} else {
		swal('No se puede insertar el test','Hay preguntas sin validar, echale un vistazo.','warning')
	}
	}catch(e){
		if(e.message == 'Error uso de comillas dobles Preg'){
			 swal('Error en texto de la pregunta: '+numPreguntaError+'.',"No puede contener comillas dobles cambiar por comillas simples --> ' ejemplo'.",'warning')
		} else if (e.message == 'Error uso de comillas dobles'){
			 swal('Error en la respuesta '+respuestaError+' de la pregunta: '+numPreguntaError+'.',"No puede contener comillas dobles, cambialas por comillas simples --> 'ejemplo'.",'warning')
		} else {
			console.log(e)
		}


	}
	} else if (e.target.id == "addPregunta") {
			let numPregNuevo = this.props.datosTestJusticia[0].preguntas.length + 1
			this.props.addPreguntaTestJusticiaInsert(numPregNuevo)
		} else if (e.target.id == "deletePregunta") {

			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, la pregunta será eliminada.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
					let numPregDel = this.state.numPreg
					this.borrarNumerosPrgInsert(numPregDel)
			  } else {
			    swal("¡Pregunta no borrada!",'',"success");
			  }
			  });

		}
	}

	borrarNumerosPrgInsert(numPreg){

	let numPregDel = numPreg
	//console.log(numPregDel)
	let numCarga = numPreg - 1
	if(numCarga > 0){
		this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numCarga;
		this.handleRellenarDatos(numCarga)
		this.setState({numPreg: numCarga})

		this.props.deletePreguntaTestJusticiaInsert(numPregDel)

		let numChance = this.props.datosTestJusticia[0].preguntas.filter(p => p.numPreg > numPregDel).length

		this.retrocederPreguntas(numPregDel, numChance)

		} else if (this.props.datosTestJusticia[0].preguntas.length > 1){
			let id = 1
			let value = this.props.datosTestJusticia[0].preguntas.filter(p=> p.numPreg == 2);

			this.props.cambiarDatosTestJusticiaAdd(id, 'validarPregunta', value[0].preguntaCheck)
			this.props.cambiarDatosTestJusticiaAdd(id, 'tipoRespuesta', value[0].tipoPregunta)
			this.props.cambiarDatosTestJusticiaAdd(id, 'pregunta', value[0].pregunta)
			this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoA', value[0].subrayadoA)
			this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoB', value[0].subrayadoB)

			this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoC', value[0].subrayadoC)
			this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoD', value[0].subrayadoD)
			this.props.cambiarDatosTestJusticiaAdd(id, 'articulo', value[0].articulo)
			this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta1', value[0].r1)
			this.props.cambiarDatosTestJusticiaAdd(id, 'r1Validez', value[0].r1_validez)

			this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta2', value[0].r2)
			this.props.cambiarDatosTestJusticiaAdd(id, 'r2Validez', value[0].r2_validez)
			this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta3', value[0].r3)
			this.props.cambiarDatosTestJusticiaAdd(id, 'r3Validez', value[0].r3_validez)
			this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta4', value[0].r4)

			this.props.cambiarDatosTestJusticiaAdd(id, 'r4Validez', value[0].r4_validez)
			this.props.cambiarDatosTestJusticiaAdd(id, 'notaA', value[0].notaA)
			this.props.cambiarDatosTestJusticiaAdd(id, 'notaB', value[0].notaB)
			this.props.cambiarDatosTestJusticiaAdd(id, 'notaC', value[0].notaC)
			this.props.cambiarDatosTestJusticiaAdd(id, 'notaD', value[0].notaD)

			this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', value[0].insertar)
			this.props.cambiarDatosTestJusticiaAdd(id, 'idPregunta', value[0].idPregunta)
			this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta1', value[0].idRespuesta1)
			this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta2', value[0].idRespuesta2)
			this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta3', value[0].idRespuesta3)
			this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta4', value[0].idRespuesta4)

			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= 1;
			this.handleRellenarDatos(1)
			this.setState({numPreg: 1})
			this.props.deletePreguntaTestJusticiaInsert(2)
			this.retrocederPreguntas(2,this.props.datosTestJusticia[0].preguntas.filter(p=> p.numPreg > 2).length)
		}
	}

	retrocederPreguntas(numPregDel, numChance){
		for (var i = 1; i <= numChance; i++) {
			let num = numPregDel + i
			this.props.changeNumPreguntaTestJusticiaInsert(num)
		}
	}

 async meterTestTemplate(){ // HACER ASYNC!!!

	 try{
		 let {datosTestJusticia} = this.props
		 let data = {
			 nombreTest: datosTestJusticia[0].nombreTest.toString(),
			 idOposicion: datosTestJusticia[0].oposicion
		 };

		 let response1 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateJustica", {
			 method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
			 if (!response1.ok) {
				 throw Error(response1.statusText);
			 }
		 let json1 = await response1.json();
		 let cls1 = await json1;
		 if(cls1 && cls1 != undefined){
			 let data2 = {idTest: cls1.insertId , preguntas: datosTestJusticia[0].preguntas};
			 console.log(data2)
			 let response2 = await fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticia", {
				 method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
				 if (!response2.ok) {
					 throw Error(response2.statusText);
				 }
			 let json2 = await response2.json();
			 let cls2 = await json2;
			 if(cls2 && cls2 != undefined){
				 await this.setState({textAreaInfo: undefined, testCompleto : [{
					 testPredefinido: undefined,
					 nombreTest: undefined,
					 oposicion: undefined,
					 preguntas: []
				 }]})

			 }
		 }

	 } catch(e){console.log(e)}

	 /*

		fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateJustica", {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(data)
		}).then(response =>  {
			if (response.status >= 400) { throw new Error("Bad response from server");}
			return response.json();
		}).then(cls => {

			let data2 = {idTest: cls.insertId , preguntas: datosTestJusticia[0].preguntas};
			console.log(data2)
		 return fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticia", {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(data2)
		});
		}).then(response =>  {
			if (response.status >= 400) { throw new Error("Bad response from server");}
			return response.json();
		}).then(cls => {
			this.setState({textAreaInfo: undefined, testCompleto : [{
			 testPredefinido: undefined,
			 nombreTest: undefined,
			 oposicion: undefined,
			 preguntas: []
		 }]})

			this.props.eliminarTestJusticiaCache();
		}).catch(error => console.log('Object fecth failed', error))
		*/

	}

	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		.then(res => res.json())
		.then(leyes => this.setState({
			busquedaLeyes: leyes
		}))
		.catch(error => console.log('Obejet fecth failed', error))

		if(this.props.datosTestJusticia[0].preguntas.filter(p => p.preguntaCheck == 0).find(p => p.preguntaCheck == 0) != undefined){
			let firstNumPregSinValidar = this.props.datosTestJusticia[0].preguntas.filter(p => p.preguntaCheck == 0).sort((a,b) => a.numPreg - b.numPreg).find(p => p.preguntaCheck == 0).numPreg
			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= firstNumPregSinValidar;
			this.refs.ref_siglasLey.refs.siglasLey.value = this.props.datosTestJusticia[0].preguntas.find(p=> p.numPreg == firstNumPregSinValidar).ley;
			this.handleRellenarDatos(firstNumPregSinValidar)
			this.setState({numPreg: firstNumPregSinValidar})
		}


	}
	render() {
		const { datosTestJusticia } = this.props;
		const { values, values2, values3, values4, values5, values6, values7, busquedaOpo, busquedaLeyes, numPreg, displayInsertTest } = this.state;
		return (
			<div className="insertTest">

			<div className="barraAccion5">
				<div className="barraAccion2">
					<div className="botonBA">
						<Submit id="insertarTest" display={displayInsertTest} icon1="fas fa-upload" icon2="fas fa-save" texto="Finalizar revisión y Insertar Test" onClick={this.handleOnClick}/>
					</div>
				</div>
				<div className="barraAccion6">
					<div className="preguntasSinValidar">
					{datosTestJusticia[0].preguntas.filter(preg => preg.preguntaCheck === 0).length > 0 ? (
						'Preguntas : '+datosTestJusticia[0].preguntas.length+', sin validar: '+datosTestJusticia[0].preguntas.filter(preg => preg.preguntaCheck === 0).length+', duplicadas: '+datosTestJusticia[0].preguntas.filter(preg => preg.insertar === 2 || preg.insertar === 0).length
					):(<p style={{color: '#55cf31', fontSize: '28px'}}> Todas las Preguntas Validadas</p>)}
					</div>
					<div className="preguntasSinValidar">
					{datosTestJusticia[0].preguntas.filter(preg => preg.insertar == 0 || preg.insertar == 2).length > 0 ? (
						'El Nº de las preguntas duplicadas son: '
					):('')}
					{datosTestJusticia[0].preguntas.filter(preg => preg.insertar == 0 || preg.insertar == 2).length > 0 ? (
						datosTestJusticia[0].preguntas.filter(preg => preg.insertar == 2 || preg.insertar ==  0).map((cls,key) =>
						<div className="numberDuplicate">
							{cls.numPreg}
						</div>
					)
					):('')}
					</div>

				</div>
				<div className="barraAccion3">
				{datosTestJusticia[0].preguntas.filter(preg => preg.preguntaCheck === 0 || preg.preguntaCheck === 1) && datosTestJusticia[0].preguntas.filter(preg => preg.preguntaCheck === 0 || preg.preguntaCheck === 1).sort((a,b) => a.numPreg - b.numPreg).map((pregunta, key) =>
					<div className="botonBA4" key={key}>
					{ pregunta.preguntaCheck == 0 ? (
						<div className="preguntaSinValidar" id="loadPregClick" onDoubleClick={this.handleOnClick}>
							{pregunta.numPreg}
						</div>
					):('')}
					{ pregunta.preguntaCheck == 1 && pregunta.insertar == 1 ? (
						<div className="preguntaValidada" id="loadPregClick" onDoubleClick={this.handleOnClick}>
							{pregunta.numPreg}
						</div>
					):('')}
					{ pregunta.insertar == 2 ? (
						<div className="preguntaDuplicadaV" id="loadPregClick" onDoubleClick={this.handleOnClick}>
							{pregunta.numPreg}
						</div>
					):('')}
					</div>
				)}
				</div>
			</div>

				<div className="iTSection">
				<div className="encimaPregunta">
						<div className="iTStitulo">
							<i className="fas fa-arrow-circle-right"></i>
							Pregunta
							{' '+numPreg}
							<div>
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).preguntaCheck == 1 && datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 1 ? (
									<div style={{color: '#29cb3d', marginLeft: '8px'}}> Validada</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).preguntaCheck == 0 && datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 1 ? (
									<div style={{color: '#cf1515', marginLeft: '15px'}}>No Validada</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 0 || datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 2 ? (
									<div style={{color: '#cf1515', marginLeft: '15px'}}>Duplicada</div>
								):('')}
							</div>
						</div>

						<div className="barraAccion">
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="preguntaAnterior" icon1="fas fa-caret-left" texto="Anterior" onClick={this.handleOnClick}/>
								</div>
								<div className="botonBA2">
									<Input type="number" min="0" id={'numeroPreguntaEspecifico'} name="numeroPreguntaEspecifico" ref="ref_numeroPreguntaEspecifico" icon="fas fa-sort-numeric-up" placeholder="1-99" onChange={this.onChange}/>
								</div>
								<div className="botonBA">
									<Submit id="preguntaSiguiente" icon2="fas fa-caret-right" texto="Siguiente" onClick={this.handleOnClick}/>
								</div>
							</div>
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="addPregunta" icon1="fas fa-plus" texto="Añadir" onClick={this.handleOnClick}/>
								</div>
								<div className="botonBA">
									<Submit id="deletePregunta" icon2="fas fa-minus" texto="Borrar" onClick={this.handleOnClick}/>
								</div>
							</div>
						</div>

				</div>
					<div className="iTScontainer2" id="iTScontainer2">

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Pregunta
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'pregunta-'+numPreg} name="pregunta" ref="ref_pregunta" valueDefault="¡Pegar pregunta aquí!" width="400px" height="70px" onChange={this.onChange}/>
								</div>
								<div className="iTSCSBInput2">

								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Nota Res. 1
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'notaA-'+numPreg} name="notaA" ref="ref_notaA" valueDefault="¡Nota respuesta 1 aquí!" width="90px" height="75px" onChange={this.onChange}/>
									</div>
								</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 2
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaB-'+numPreg} name="notaB" ref="ref_notaB" valueDefault="¡Nota respuesta 2 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 3
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaC-'+numPreg} name="notaC" ref="ref_notaC" valueDefault="¡Nota respuesta 3 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 4
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaD-'+numPreg} name="notaD" ref="ref_notaD" valueDefault="¡Nota respuesta 4 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Tipo de Respuesta
								</div>
								<div className="iTSCSBInput">
									<InputSelect values={values2} id={'tipoRespuesta-'+numPreg} name="tipoRespuesta" ref="ref_tipoRespuesta" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
								<div className="iTSCSBTitulo">
									Siglas Ley
								</div>
								<div className="iTSCSBInput">
									<Input datalist="leyesDataList" type="text" size ="16" id={'siglasLey-'+numPreg} name="siglasLey" ref="ref_siglasLey" icon="fas fa-search" placeholder="Ej: LEC" onChange={this.onChange}/>
									<datalist id="leyesDataList">
										{ busquedaLeyes && busquedaLeyes.map((cls, key) =>
											<option key={key} value={cls.siglas_ley}>{cls.nombre_ley}</option>
										)}
									</datalist>
								</div>
								<div className="iTSCSBTitulo">
									Artículo
								</div>
								<div className="iTSCSBInput">
									<Input type="text" size ="16" id={'articulo-'+numPreg} name="articulo" ref="ref_articulo" icon="fas fa-arrow-right" placeholder="Ej: 128.a" onChange={this.onChange}/>
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
						<div className="iTSCsubBody">
							<div className="iTSCSBTitulo">
								Respuesta 1
							</div>
							<div className="iTSCSBInput">
								<InputTextArea id={'respuesta1-'+numPreg} name="respuesta1" ref="ref_respuesta1" valueDefault="¡Pegar respuesta 1 aquí!" width="400px" height="80px" onChange={this.onChange}/>
							</div>
						</div>

						<div className="iTSCsubBody">
							<div className="iTSCSBTitulo">
								Validez de Respuesta
							</div>
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
								<div className="iTSCSBIinfoBasica">
									<i className="fas fa-info-circle"></i>
									Falta el tipo de Respuesta
								</div>
							):('')}
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values3} id={'r1Validez-'+numPreg} ref="ref_r1Validez" name="r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values4} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values5} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values6} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values7} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							<div className="iTSCSBInput3">
								<div className="iTSCSBTitulo">
									Subrayado Res. 1
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'subrayadoA-'+numPreg} name="subrayadoA" ref="ref_subrayadoA" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange}/>
								</div>
							</div>
						</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 2
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta2-'+numPreg} name="respuesta2" ref="ref_respuesta2" valueDefault="¡Pegar respuesta 2 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 2
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoB-'+numPreg} name="subrayadoB" ref="ref_subrayadoB" valueDefault="¡Pegar subrayado respuesta 2 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 3
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta3-'+numPreg} name="respuesta3" ref="ref_respuesta3" valueDefault="¡Pegar respuesta 3 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 3
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoC-'+numPreg} name="subrayadoC" ref="ref_subrayadoC" valueDefault="¡Pegar subrayado respuesta 3 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 4
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta4-'+numPreg} name="respuesta4" ref="ref_respuesta4" valueDefault="¡Pegar respuesta 4 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticia[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 4
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoD-'+numPreg} name="subrayadoD" ref="ref_subrayadoD" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


			</div>
		);
	}
}

// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		datosTestJusticia: state.testJusticia.datosTestJusticia
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		cambiarDatosTestJusticia,
		eliminarTestJusticiaCache,
		addPreguntaTestJusticiaInsert,
		deletePreguntaTestJusticiaInsert,
		changeNumPreguntaTestJusticiaInsert
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertTest);

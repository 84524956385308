// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';

import { isDefined } from '../../lib/utils/is';
// Assets
import './css/infoAlumno.css';

import Boton from '../Inputs/Boton';
import Input2 from '../Inputs/Input2';


class InfoAlumno extends Component {

  static propTypes = {
    idAlumno: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      editar: null
    };
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onChangeInput(e) {
    if (e.target.id == '') {

    }
  }

  onToggleClick(e) {
    if (e.target.id == 'edtDatos') {
      this.setState({editar: true})
    }
  }

  async componentDidMount() {

    if (isDefined(this.props.idAlumno)) {

      let data = { idUser: this.props.idAlumno }

      fetch("https://oposiciones-justicia.es/api/oficina/datosUser", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(cls => {
        this.setState({ infoAlumnoLoad: cls })
      }).catch(error => console.log('Object fecth failed', error))

    }

  }

  render() {
    const { infoAlumnoLoad, editar } = this.state;

    console.log('infoAlumnoLoad')
    console.log(infoAlumnoLoad)

    return (
      infoAlumnoLoad && isDefined(infoAlumnoLoad) ? (

        <div className="sectionInfoAlumno" >
          <div>
            <Boton id="edtDatos" icon1="" icon2="" texto="Abrir modo editar" onClick={this.onToggleClick} />
          </div>


          <div className='bodyDatosAlumno'>
            {editar == null ? (
              <div className='cajaInfoAlum'>
                <div className='cajaInfoAlum-title'>
                  Nombre
                </div>
                <div className='cajaInfoAlum-info'>
                  {infoAlumnoLoad[0].nombre}
                </div>
              </div>
            ) : (
              <div className="celdaInputDAPFBN">
                <Input2 value={infoAlumnoLoad[0].nombre} autoComplete={"off"} type="text" name="nombre" placeholder="Nombre" icon="fas fa-id-card" ref="ref_nombre" onChange={this.onChangeInput} />
                <p>Nombre</p>
              </div>
            )}

            {editar == null ? (
              <div className='cajaInfoAlum'>
                <div className='cajaInfoAlum-title'>
                  Nombre
                </div>
                <div className='cajaInfoAlum-info'>
                  {infoAlumnoLoad[0].nombre}
                </div>
              </div>
            ) : (
              <div className="celdaInputDAPFBN">
                <Input2 value={infoAlumnoLoad[0].nombre} autoComplete={"off"} type="text" name="nombre" placeholder="Nombre" icon="fas fa-id-card" ref="ref_nombre" onChange={this.onChangeInput} />
                <p>Nombre</p>
              </div>
            )}

          </div>


        </div>

      ) : null
    );

  }
}

export default InfoAlumno;

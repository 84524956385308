
import React, { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement
} from "@stripe/react-stripe-js";
import Boton from '../Inputs/Boton'
import BotonFinolis from '../Inputs/Boton3Finolis'
import Input2 from '../Inputs/Input2'
//import { Ellipsis } from '../react-css-spinners/src'
import { isDefined } from "../../lib/utils/is";
// Assets
import './css/css.css';
import swal from "sweetalert";

const stripePromise = loadStripe("pk_live_51J5JGPAyrS4qvQTpYXPFke9pRqy3Zlk2ldoWcWm1xFEYm1M4M2YGHQHrdBs7kkEwOnp9160uDEPh19SvYfcLoVeq000g7yTFw7");

const options = null;
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [nameCard, setNameCard] = useState('');
  const [emailCard, setEmailCard] = useState('');
  const [metPago, setmetPago] = useState('');
  useEffect(() => {

    let { infoFactura } = props

    //let plazos = infoFactura.factura.tipoDePago.split(',').filter(t=> t)
    let plazos = infoFactura.factura.tipoDePago.split(',').find(function (et) {
      return et == 'afterpay_clearpay'; // klarna
    });
    // console.log('plazos')
    // console.log(plazos)
    if (plazos != undefined && plazos != null) {
      setmetPago(3)
    }

  }, [])
  // 
  // payment_method_order
  // paymentMethodOrder
  const handleSubmit2 = async (e) => {
    if (e.target.id == 'PagoUnico') {
      // console.log('Ready')
    } else if (e.target.id == 'PagoPlazos') {
      // console.log('Ready')
    }

  }
  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {

      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://oposiciones-justicia.es/Facturacion',
      },

    });


    if (error) {

      //setErrorMessage(error.message);
      console.log('error.message')
      console.log(error.message)
      swal('Error', 'Vuelva a intentarlo, indique todos los campos correctamente.', 'warning')
      setLoading(false);
    } else {
      setLoading(true);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  // Pass the appearance object to the Elements instance
  const handleSubmit23 = async (e) => {
    e.preventDefault();
    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: e.target.id == 'PagoUnico' ? ('card') : ('card'),
      card: elements.getElement(CardElement),
      billing_details: {
        name: nameCard,
        email: emailCard
      }
      // ,livemode: true
    });
    setLoading(true);
    if (nameCard != '' && emailCard != '') {
      if (!error) {

        const { id } = paymentMethod;
        try {

          let data2 = {
            id,
            amount: props.infoFactura.precio * 100,
            name: nameCard,
            email: emailCard,
            moneda: props.infoFactura.moneda,
            descripcion: props.infoFactura.descripcion
          }

          let response2 = await fetch("https://oposiciones-justicia.es/api/checkout", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;

          if (cls2.message == 'Pago realizo correctamente') {
            let fac = props.infoFactura

            let data1 = { idFactura: fac.factura.idFactura, idUser: fac.idUser, idClase: fac.factura.idClase }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturaPagada", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data2 = {
              id_clase: fac.factura.idClase,
              id_usuario: fac.idUser,
              activado: 1,
              activarTemas: 0
            }
            let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;

            if (cls1 != undefined && cls2 != undefined) {
              await swal({
                title: 'Factura procesada',
                text: '¡Gracias por confiar en nosotros!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2000,
              }).then(() => {

                elements.getElement(CardElement).clear();
                window.location.reload()
              })
            }

            // console.log('Pago correcto')

          } else {
            console.log('Fallo el pago')
            elements.getElement(CardElement).clear();
          }

        } catch (error) {
          console.log('Fallo el pago')
          console.log(error);
        }
        setLoading(false);
      }
    } else {
      swal('Error', 'Introduzca el nombre y email.', 'error')
      setLoading(false);
    }


  };


  return (

    <form className="card" style={{ width: '100%' }}>


      {metPago && metPago != '' && metPago > 2 ?
        (<div className="pauMentStripeSelecion">
        
          <div>
            Con
            <b style={{ color: '#3469b7', cursor: 'pointer' }} onClick={() => window.open('https://www.clearpay.com/es-ES/terms', '_blank')}>Afterpay</b> o <b style={{ color: '#3469b7', cursor: 'pointer' }} onClick={() => window.open('https://www.clearpay.com/es-ES/terms', '_blank')}>Klarna</b> 
            page en 3 plazos con 0 % de intereses

            <i onClick={() => window.open('https://cdn.klarna.com/1.0/shared/content/legal/terms/0/es_es/paylaterin3', '_blank')} style={{ margin: '5px', color: '#3469b7', cursor: 'pointer' }} className="fa-light fa-circle-info"></i>

          </div>
          <p>Puede realizar un pago unico con tarjeta, SEPA o google pay</p>

        </div>) : (

          <div className="pauMentStripeSelecion">
            <div>
              Con
              <b style={{ color: '#3469b7', cursor: 'pointer' }} onClick={() => window.open('https://stripe.com/es', '_blank')}>Stripe</b> realizará el pago, de la manera más fiable y segura.
            </div>

            <p>
              Con tarjeta, google pay o transferencia SEPA.</p>

          </div>
        )}

      <PaymentElement />

      {loading && loading == true ? (
        <div className="cargandoDudasECG">
          <div style={{ color: '#3469b7', fontWeight: 'bold' }}> PROCESANDO PAGO </div>
          {/* <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} /> */}
        </div>
      ) : (

        <div style={{ margin: '20px' }}>
          {/* <Boton icon1="" icon2="" id="PagoUnico" texto="Procesar pago" onClick={handleSubmit} /> */}
          <BotonFinolis texto={'Procesar pago'} id="PagoUnico" onClick={handleSubmit} />
        </div>

      )}


    </form>
  );
};

function Stripe2(props) {
  const [options, setOptions] = useState(null);
  useEffect(() => {

    let { infoFactura } = props
    async function payment() {
      console.log('infoFactura en stripe 2')
      console.log(infoFactura)

      let data1 = {
        precio: infoFactura.precio,
        tipoDePago: infoFactura.factura.tipoDePago.split(','),//infoFactura.tipoDePago -['card', 'sepa_debit', 'klarna']
        descripcion: infoFactura.descripcion,
        idFac: infoFactura.factura.idFactura,
        email: infoFactura.factura.email,

      }
      let response1 = await fetch("https://oposiciones-justicia.es/create-payment-intent", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let cls1 = await response1.json();
      setOptions({
        clientSecret: cls1.clientSecret,
        //paymentMethodOrder: ['klarna','card'],
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: '#3469b7',
            fontFamily: 'Barlow,sans-serif',
          }
        },
      })
    }
    payment()

  }, [])

  return (
    options != null ? (


      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm infoFactura={props.infoFactura} />
      </Elements>
    ) :
      (
        <div style={{ fontSize: '25px', color: 'grey', textTransform: 'uppercase' }}>
          Preparando proceso de pago...
        </div>
      )
  );
}

export default Stripe2;
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../../../data/menuPreparador';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from './actions/baroptionAction';
// Components
import MenuAdmin from '../MenuAdmin';
import Option from './optionBar';

/*
Falta cambiar el componente generarTest porClase! ajustarlo al props.busquedaTemas que recarge informacion
no funciona el this.state.intervalosTemas!
<div className="botonSmall" onClick={() => this.props.cargarContenido("Temas_Programacion",this.forceUpdate())}>
	<i className="fas fa-plus"></i>Temas de Clase
</div>
*/


class PreguntasReportadasYDudas extends Component {
	static propTypes = {
		cargarContenido: PropTypes.func.isRequired,
		isLogged: PropTypes.bool.isRequired,
		nombreUser: PropTypes.string.isRequired,
		tipoUser: PropTypes.number.isRequired,
		optionSelected: PropTypes.string.isRequired
	};

	render() {
		const { isLogged, nombreUser, tipoUser, optionSelected, idUser } = this.props;
		const nivelRequired = 50;
		if (isLogged && nivelRequired <= tipoUser) {
			return (
				<div className="inBodyMenu">
					<div className="menuBody">
						<MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="PREPARADOR" />
					</div>
					<div className="inBody">
						<div className="sectionBody">
							<div className="optionselected">
								<Option nombreUser={nombreUser} tipoUser={tipoUser} selectedOption={optionSelected} idUsuario={idUser} />
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (<Redirect to="/" />);
		}
	}
}

// Estados del store
function mapStateToProps(state) {
	return {
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser: state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		idUser: state.user.idUser,
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		cargarContenido
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PreguntasReportadasYDudas);

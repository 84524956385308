// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Utils
// import { isDefined } from '../../../../../lib/utils/is';
//Componets
import Input2 from '../../components/Inputs/Input2';
import Alumnos from '../../components/Alumnos';

// import { Ellipsis } from '../../components/react-css-spinners/src'



class CursosInfo extends Component {

  static propTypes = {

  };
  constructor(props) {
    super(props);
    this.state = {
      alumnosClase: null,
      navS: 0
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);

  }
  async onToggleClick(e) {
    if (e.target.id.split('-')[0] == 'nav') {

      this.setState({ navS: e.target.id.split('-')[1] })

    }
  }
  async onChangeInput(e) {

  }

  async componentDidMount() {
    // cargar alumnos clase word 2010 asignar a  alumnosClase

    let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/alumnosCursos");
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    let alumnosWord2010 = await cls1

    for (let i = 0; i < alumnosWord2010.length; i++) {
      let data = { idUser: alumnosWord2010[i].id_usuario, idClase: alumnosWord2010[i].id_clase }
      let response2 = await fetch("https://oposiciones-justicia.es/api/word2010/facturasAlumnosCursos", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      alumnosWord2010[i].facturas = await cls2.sort((a, b) => new Date(a.fecha_pago) - new Date(b.fecha_pago));

      // POR FECHA Y ESTADO DE PAGO

      if (alumnosWord2010[i].facturas.length > 0 && alumnosWord2010[i].facturas.estado == 'Pendiente') {
        alumnosWord2010[i].estadoAlumnoWord = 'pendientePago'
        alumnosWord2010[i].totalFacturas = alumnosWord2010[i].facturas.length
      }
      else if (
        alumnosWord2010[i].facturas.length > 0 &&
        moment().format('YYYY-MM-DD') <= moment(alumnosWord2010[i].facturas[alumnosWord2010[i].facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD')
      ) {

        if (alumnosWord2010[i].facturas.estado == 'Pagado') {
          alumnosWord2010[i].estadoAlumnoWord = 'activo'
          alumnosWord2010[i].totalFacturas = alumnosWord2010[i].facturas.length
        }


      } else if (
        alumnosWord2010[i].facturas.length > 0 &&
        moment().format('YYYY-MM-DD') > moment(alumnosWord2010[i].facturas[alumnosWord2010[i].facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD')
      ) {

        alumnosWord2010[i].estadoAlumnoWord = 'caducado'
        alumnosWord2010[i].totalFacturas = alumnosWord2010[i].facturas.length

      }
     

    }
    let cursoTraAux = []
    let cursoGes = []
    let cursoTraPI = []
    let cursoGesPI = []

    for (let jhg = 0; jhg < alumnosWord2010.length; jhg++) {

      if (alumnosWord2010[jhg].dia_semanal == "Curso Auxilio & Tramitacion") {
        cursoTraAux.push(alumnosWord2010[jhg])
      }

      if (alumnosWord2010[jhg].dia_semanal == "Curso Gestion") {
        cursoGes.push(alumnosWord2010[jhg])
      }

      if (alumnosWord2010[jhg].dia_semanal == "Curso Gestion PI") {
        cursoGesPI.push(alumnosWord2010[jhg])
      }

      if (alumnosWord2010[jhg].dia_semanal == "Curso Tramitacion PI") {
        cursoTraPI.push(alumnosWord2010[jhg])
      }

    }
    this.setState({ alumnosClase: alumnosWord2010, cursoTraAux: cursoTraAux, cursoGes: cursoGes, cursoGesPI: cursoGesPI, cursoTraPI: cursoTraPI })
    // this.setState({ alumnosClase: null })

  };

  render() {
    const { } = this.props;
    const { navS, alumnosClase, cursoTraAux, cursoGes, cursoGesPI, cursoTraPI } = this.state
    console.log('alumnosClase')
    console.log(alumnosClase)

    console.log('cursoTraAux')
    console.log(cursoTraAux)

    console.log('cursoGes')
    console.log(cursoGes)

    console.log('cursoGesPI')
    console.log(cursoGesPI)

    console.log('cursoTraPI')
    console.log(cursoTraPI)

    return (
      <div>
        {
          alumnosClase == null ? (
            <div className="cE1EstadisticasR" style={{ width: 'unset', alignItems: 'center' }}>
              <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO CURSOS </div>
              {/* <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} /> */}
            </div>
          ) : (
            <div className='ColCenterStart alumnosDBody'>

              <div className='ColCenterStart adb-nav'>

                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                  <div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-0" onClick={this.onToggleClick}>
                    Auxilio y Tramitación
                    {cursoTraAux && cursoTraAux.length > 0 ? (' - ' + cursoTraAux.length) : null}
                  </div>

                  <div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-1" onClick={this.onToggleClick}>
                    Gestion
                    {cursoGes && cursoGes.length > 0 ? (' - ' + cursoGes.length) : null}
                  </div>

                  <div className={navS == 2 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-2" onClick={this.onToggleClick}>
                    Gestion PI
                    {cursoGesPI && cursoGesPI.length > 0 ? (' - ' + cursoGesPI.length) : null}
                  </div>

                  <div className={navS == 3 ? 'navSelected' : 'navSelected navNoSelected '} id="nav-3" onClick={this.onToggleClick}>
                    Tramitación PI
                    {cursoTraPI && cursoTraPI.length > 0 ? (' - ' + cursoTraPI.length) : null}
                  </div>

                </div>

              </div>

              {
                navS == 0 ? (
                  <div className='ColCenterStart'>

                    {
                      cursoTraAux && cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Activos

                          {' - ' + cursoTraAux.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraAux && cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      cursoTraAux && cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Caducado el tiempo del curso

                          {' - ' + cursoTraAux.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraAux && cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>


                    {
                      cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Pendientes de pago
                          {' - ' + cursoTraAux.filter(a =>
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraAux && cursoTraAux.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>





                  </div>

                ) : null
              }

              {
                navS == 1 ? (
                  <div className='ColCenterStart'>

                    {
                      cursoGes && cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Activos

                          {' - ' + cursoGes.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGes && cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      cursoGes && cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Caducado el tiempo del curso

                          {' - ' + cursoGes.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGes && cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>


                    {
                      cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Pendientes de pago
                          {' - ' + cursoGes.filter(a =>
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGes && cursoGes.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>





                  </div>

                ) : null
              }

              {
                navS == 2 ? (
                  <div className='ColCenterStart'>

                    {
                      cursoGesPI && cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Activos

                          {' - ' + cursoGesPI.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGesPI && cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      cursoGesPI && cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Caducado el tiempo del curso

                          {' - ' + cursoGesPI.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGesPI && cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>


                    {
                      cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Pendientes de pago
                          {' - ' + cursoGesPI.filter(a =>
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoGesPI && cursoGesPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>





                  </div>

                ) : null
              }

              {
                navS == 2 ? (
                  <div className='ColCenterStart'>

                    {
                      cursoTraPI && cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Activos

                          {' - ' + cursoTraPI.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraPI && cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      cursoTraPI && cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Caducado el tiempo del curso

                          {' - ' + cursoTraPI.filter(a =>
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraPI && cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>


                    {
                      cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}>

                          Pendientes de pago
                          {' - ' + cursoTraPI.filter(a =>
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {cursoTraPI && cursoTraPI.filter(a =>
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>

                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>





                  </div>

                ) : null
              }

            </div>
          )
        }

      </div>
    );
  }
}

export default CursosInfo;

// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import ReactPlayer from 'react-player'
//Assets
import './css/word2010.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { reloadInfoPersonal, reloadNameUser } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
// Components
import Input2 from '../../components/Inputs/Input2';
import InputFileIcono from '../../components/Inputs/InputFileIcono';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';

import CajaSemanaWord from '../CajaSemanaWord';
import Word2010HomeCurso from '../Word2010HomeCurso';
import Word2010HomeTest from '../Word2010HomeTest';



import swal from 'sweetalert';

class Word2010 extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      sectionSelecionada: 1,
      modeWord: 'normal',
      openVideo: null
    };
    this.myFiles = this.myFiles.bind(this);
    this.handleOnClic = this.handleOnClic.bind(this);
  }

  async myFiles(e) {
    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < files.length; i++) {
      filesLoad.push(files[i]);
    }
    await this.setState({ fileToUpload: filesLoad });
    let e2 = { target: { id: 'ActualizarImagenPerfil' } }
    await this.handleOnSubmit(e2)
  }


  async handleOnClic(e) {
    if (e.target.id == 'ejerSemanales') {
      this.setState({ sectionSelecionada: '2' })
    } else if (e.target.id == 'manualWord') {
      this.setState({ sectionSelecionada: '1' })
    } else if (e.target.id == "openVideo") {
      this.setState({ openVideo: this.state.openVideo == null || this.state.openVideo == false ? (true) : (false) })
    }
  }

  componentDidMount() {
    // cargar imagenPerfil en propTypes
    let data = { idUser: this.props.idUser, tipoUser: this.props.tipoUser }
    // ver si tiene pagado word 2010
  }
  render() {

    const { isLogged, nombreUser, tipoUser, fotoPerfil } = this.props;
    const { sectionSelecionada, modeWord, openVideo } = this.state
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      return (
        <div className="bodyPadreCEJ">

          {/* 
            <b className='titleWord2010'> WORD 2010 </b>
          */}
          {/* <div className="videoWww">
            <i title="Video explicación preparación Word 2010" className="fas fa-video" id="openVideo" onClick={this.handleOnClic} />

          </div> */}
          {

            // <div className={openVideo != null && openVideo == true ? ("tutorialVideoAtopePower tutorialVideoAtopePowerAnimate") : (openVideo != null && openVideo == false ? ("tutorialVideoAtopePower closeTutorial") : ("tutorialVideoAtopePower")

            // )}>
            //   {openVideo != null && openVideo == true ? (
            //     <div className="botonsicoOff animationNover" style={{ width: '140px', position: 'absolute', top: '25px', right: '25px', zIndex: '1999' }}>
            //       <Boton id="openVideo" icon1="" icon2="" texto={openVideo != null && openVideo == true ? ("Cerrar video") : ("Ver video")} onClick={this.handleOnClic} />
            //     </div>
            //   ) : null}



            //   <div className="recPlayVidDiv2" style={{
            //     display: 'flex',
            //     flexFlow: 'row wrap',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //     maxWidth: '1000px'
            //   }} onContextMenu={e => e.preventDefault()}>
            //     <ReactPlayer
            //       url={'../videos/word2010CEJ.mp4'}
            //       className='react-player'
            //       playing={false}
            //       width='100%'
            //       height='100%'
            //       controls={true}
            //       onContextMenu={e => e.preventDefault()}
            //       config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            //     />
            //   </div>
            // </div>

          }
          <div className='menuWord2010'>

            <div className='itemMenuWord2010' >
              <div className='itemssMenuWord2010'>

                <div className='iMW-title' id={'manualWord'} onClick={() => window.location.reload()} style={{
                  color: '#3469b7',
                  fontWeight: '500'
                }}>
                  Curso Windows 10 + Office 19
                </div>


              </div>

            </div>
          </div>

          <div>
            {/* Lo selecionado lo filtamos y cargamos componentes ahora */}
            {
              sectionSelecionada && sectionSelecionada == 1 ? (
                <Word2010HomeCurso modeWord={modeWord} />
              ) : null
            }

            {/* Lo selecionado lo filtamos y cargamos componentes ahora */}
            {
              sectionSelecionada && sectionSelecionada == 2 ? (
                <Word2010HomeTest modeWord={modeWord} />
              ) : null
            }


          </div>
          {
            sectionSelecionada && (sectionSelecionada == 1 || sectionSelecionada == 2) ? (
              <div className="OW2-imgFondo">
                <img src="../img/sghfncdxgy2.jpg" />
              </div>
            ) : null
          }
        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    fotoPerfil: state.user.fotoPerfil
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Word2010);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/inputfile.css';

class InputFile extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    icon1: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          textInfoBtn: 'Seleccione : ',
        };
          this.onChangeInputFiles = this.onChangeInputFiles.bind(this);
    }
    onChangeInputFiles(e){
        this.props.onChange(e);

        if(e.target.files.length >= 2){
          let t = e.target.files.length+' Archivos cargados ';
          this.setState({textInfoBtn: t});
        } else if (e.target.files.length == 1){
          let filename = e.target.value.split('\\').pop(' ');
          this.setState({textInfoBtn: filename+' '});
        } else {
          this.setState({textInfoBtn: 'Seleccione : '});
        }

    }


  render() {
    const { name, texto, icon1 } = this.props;
    const { textInfoBtn } = this.state;
    var id = 'file'+name;
              return (
                <div>
                  <label htmlFor={id} className="input-label">
                    <i className="fa fa-upload"></i>
                    {textInfoBtn + texto}
                    <i className={icon1}></i>
                  </label>
                  <input type="file" className="myFiles" ref={name} name={name} id={id} onChange={this.onChangeInputFiles} multiple />
                </div>
              );

        }
 }

export default InputFile;

// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Utils
import './css.css'

//Componets
import { Redirect } from 'react-router-dom';

import BotonFinolis from '../../components/Inputs/Boton3Finolis'

import moment from 'moment'

// Redux Imports
import { useSelector, useDispatch } from 'react-redux'
import { borrarTestUser, loadTestUserNUBE, cronoFunc } from '../../actions/userAction'


const TestPendientesFinalizar = (props) => {

  const dispatch = useDispatch()
  const idUser = useSelector(state => state.user.idUser)
  const idOposicion = useSelector(state => state.user.idOposicion)

  const testJusticiaUserLoad = useSelector(state => state.user.testJusticiaUserLoad)
  const tipoUser = useSelector(state => state.user.tipoUser)
  const isLogged = useSelector(state => state.user.isLogged)
  const idClase = useSelector(state => state.user.idClase)
  const temasFacturados = useSelector(state => state.user.temasFacturados)

  const [busquedaTestNube, setbusquedaTestNube] = useState(null)

  useEffect(() => {

    let data2x3 = {
      idUser: idUser
    }
    fetch("https://oposiciones-justicia.es/api/usuarios/testNube", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data2x3)
    }).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
      .then(info => {
        setbusquedaTestNube(info.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
      }).catch(error => console.log('Object fecth failed', error))
    // let response = await fetch('https://oposiciones-justicia.es/api/usuarios/testNube', {
    //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2x3)
    // });
    // if (!response.ok) {
    //   throw Error(response.statusText);
    // }
    // let json = await response.json();
    // let cls = await json;



    return () => {

    }
  }, [])

  const onHandleClick = async (e) => {
    if (e.target.id.split('-')[0] == 'renaudarTest') {
      let val = e.target.id.split('-')[1]

      let id = busquedaTestNube[val].id
      let min = busquedaTestNube[val].minutos
      let seg = busquedaTestNube[val].segundos

      let testJson = busquedaTestNube[val].jsonTest.replace(/\r?\n|\r/g, ' ')

      // testJson = testJson.replace(/\s+/g, '')

      let testConver = JSON.parse(testJson)
      // console.log('testConver')
      // console.log(testConver)

      if (testJusticiaUserLoad.length > 0) {
        //Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
        let name = "¡Ojo! - Test sin finalizar";
        let content = document.createElement('div');
        content.innerHTML = `
                  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
                  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardarlo para realizarlo más tarde o finalizarlo.
                  `
        content.style.textAlign = "left";

        swal({
          title: name,
          content: content,
          icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
          buttons: {
            cancel: "Cancelar",
            catch1: {
              text: "Cargar nuevo Test",
              value: "catch1",
            },
            catch: {
              text: "Ir a finalizar test",
              value: "catch",
            },
            defeat: false,
          },
          closeOnEsc: true,
          closeOnClickOutside: true,
        }).then(async (value) => {
          switch (value) {

            case "catch":
              window.location.href = "https://oposiciones-justicia.es/hacerTestJusticia";
              break;
            case "catch1":
              dispatch(borrarTestUser())
              dispatch(cronoFunc(min, seg))
              dispatch(loadTestUserNUBE(testConver))
              // borrar el test de nube
              let data = {
                id: id
              }
              let response = await fetch('https://oposiciones-justicia.es/api/usuarios/borrarTestNube', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              window.location.href = "https://oposiciones-justicia.es/hacerTestJusticia";
              break;
            default:

          }
        });
      } else {
        dispatch(cronoFunc(min, seg))

        dispatch(loadTestUserNUBE(testConver))

        let data = {
          id: id
        }
        let response = await fetch('https://oposiciones-justicia.es/api/usuarios/borrarTestNube', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        window.location.href = "https://oposiciones-justicia.es/hacerTestJusticia";




      }

    } else if (e.target.id.split('-')[0] == 'delTestSave') {

      let val = e.target.id.split('-')[1]

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado y no podrá finalizarlo.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let id = busquedaTestNube[val].id

            let data = {
              id: id
            }
            let response = await fetch('https://oposiciones-justicia.es/api/usuarios/borrarTestNube', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            await swal({
              title: 'Test eliminado',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 1500,
            }).then(() => {
              window.location.reload()
            })


          } else {
            swal("Test no eliminado", '', "success");
          }
        });



    }

  }


  // console.log('busquedaTestNube')
  // console.log(busquedaTestNube)

  const nivelRequired = 1
  if (isLogged && (nivelRequired == tipoUser || tipoUser >= nivelRequired)) {

    if (tipoUser < 50 && (idClase == null || temasFacturados == null)) {

      swal({
        title: 'Area restringida',
        text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
        icon: "info",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 2500,
      }).then(() => {
        window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
      })
      return (null);

    } else {

      return (
        <div className='ColCenterStart' style={{ padding: '15px', color: 'black' }}>

          <div className="reformas" style={{ marginBottom: '40px', width: '100%' }}>
            <img src="../img/nubeCEJ.png" style={{ maxWidth: '350px' }} />

            <div className='ColCenterStart reformasTitle' style={{ minWidth: '50%' }}>
              <h2 className='contoneoLetras ctrlH2reform' style={{ textTransform: 'uppercase', fontWeight: '800', fontSize: '35px' }}>
                EJERCICIOS
              </h2>
              <h2 className='contoneoLetras ' style={{ textTransform: 'uppercase', fontWeight: '800', marginTop: '15px', fontSize: '35px', textAlign: 'center' }}>
                PENDIENTES
              </h2>
            </div>

          </div>

          {busquedaTestNube == null || busquedaTestNube.length == 0 ? (
            <div className='ColCenterStart reformasTitle'>

              <h2 className='contoneoLetras ' style={{ textTransform: 'uppercase', fontWeight: '800', marginTop: '15px', fontSize: '25px', textAlign: 'center' }}> Ningun ejercicio guardado </h2>
            </div>
          ) : null}

          <div className='RowCenterCenter' style={{ maxWidth: '840px', marginBottom: '40px', justifyContent: 'space-evenly' }}>
            {

              busquedaTestNube && busquedaTestNube != null && busquedaTestNube.length > 0 &&
              busquedaTestNube.map((test, key0) =>
                <div className='ColCenterCenter' style={{ alignItems: 'flex-start', padding: '10px 10px 12px', boxShadow: 'rgb(211 226 247) 0px 0px 10px', borderRadius: '30px', margin: '15px', position: 'relative' }}>

                  <i id={"delTestSave-" + key0} title="Eliminar test" style={{ top: '-12px', right: '18px', fontSize: '25px' }} onClick={onHandleClick} className="fa-duotone fa-trash delInput"></i>

                  <div className='ColCenterStart' style={{ alignItems: 'flex-start', justifyContent: 'space-between', minHeight: '160px' }}>
                    <div className='ColCenterStart' style={{ alignItems: 'flex-start' }}>
                      <div title={test.nombre} className='RowCenterCenter rowReformaPreview' style={{ flexFlow: 'row nowrap' }}>
                        <i title={test.nombre} className="fa-light fa-file-signature"></i>
                        <div title={test.nombre} style={{ maxWidth: '180px', minWidth: '180px', fontSize: '15px' }}>
                          {test.nombre.substring(70, -1)}
                        </div>

                      </div>

                      <div className='RowCenterCenter' style={{ justifyContent: 'flex-start' }}>

                        <div title={'Este ejercicio se guardo el ' + moment(test.fecha).format('DD-MM-YY [a las] HH:mm')} className='RowCenterCenter rowReformaPreview'>
                          <i className="fa-light fa-calendar-day" title={'Este ejercicio se guardo el ' + moment(test.fecha).format('DD-MM-YY [a las] HH:mm')}></i>
                          {moment(test.fecha).format('DD-MM-YY [a las] HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div className='RowCenterCenter rowReformaPreview' style={{ alignSelf: 'center', maxWidth: '220px', marginTop: '8px' }}>
                      <BotonFinolis id={'renaudarTest-' + key0} onClick={onHandleClick} texto='CONTINUAR TEST' />
                    </div>

                  </div>

                </div>
              )

            }
          </div>



        </div >
      )
    }
  } else {
    return (<Redirect to="/" />);
  }

}

TestPendientesFinalizar.propTypes = {

}

export default TestPendientesFinalizar;
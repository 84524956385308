// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Boton from '../Inputs/Boton'
import { isDefined } from '../../lib/utils/is'
//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InputFileExamDesa from '../Inputs/InputFileExamDesa'
import CambioLeyTemaAdm from './components/CambioLeyTemaAdm'
import VideoViewTema from './components/VideoViewTema'
import NotaTema from './components/NotaTema'

import { openViewBusArt } from '../../actions/userAction';

// Assets
import './css/css23.css';
import swal from 'sweetalert';

class TemaSeleted extends Component {
  static propTypes = {
    idTema: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      leySelectd: 0,
      viewEdtLeyTema: null,
      viewEdtvidTema: null,
      viewNotaTema: null,
      notaPreparador: null
    };
    this.onHandleClickFfF = this.onHandleClickFfF.bind(this);
    this.myFiles = this.myFiles.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);


  }
  async onChangeInput(e) {


  }
  async myFiles(e) {
    // comprobar archivo y subir

    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < await files.length; i++) {
      filesLoad.push(files[i]);
    }
    this.setState({ fileToUpload: filesLoad });
    if (filesLoad.length > 0) {
      let e2 = { target: { id: 'subirApunteTema' } }
      this.onHandleClickFfF(e2)
    }


  }
  async onHandleClickFfF(e) {

    if (e.target.id == 'cerrarEdt') {
      this.setState({ viewEdtLeyTema: null })
      this.forceUpdate()
    } else if (e.target.id.split('-')[0] == 'sigla') {
      let locaLey = e.target.id.split('-')[1]
      this.setState({ leySelectd: locaLey })
    } else if (e.target.id.split('-')[0] == 'apunte') {
      let idApunte = e.target.id.split('-')[1]

      if (isDefined(this.state.temaApuntes) && this.props.temasFacturados.length > 0 && this.props.temasFacturados.filter(t => t.idTema == this.props.idTema).length > 0) {
        swal('Descarga en proceso', 'Si no se inicia la descarga en 20 segundos vuelva a intentarlo', 'info')
        let pathFile = this.state.temaApuntes[idApunte].link_archivo
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga, reportelo, gracias.")
        });
      } else {
        swal('No tiene facturado este tema', 'Solo puede descargar los temas facturados', 'error')
      }
    } else if (e.target.id.split('-')[0] == 'openLey') {
      let locaLey = e.target.id.split('-')[1]
      let urlLey = this.state.temaIntervalos[locaLey].linkLey
      if (isDefined(urlLey)) {
        window.open(urlLey)
      } else {
        swal('Link no valido', 'Será arreglado lo antes posible', 'error')
      }

    } else if (e.target.id == 'edtLey') {
      this.setState({ viewEdtLeyTema: true, leySelectd: 0 })

    } else if (e.target.id == 'subirApunteTema') {

      let dataArchivos = new FormData();
      for (var i = 0; i < this.state.fileToUpload.length; i++) {
        dataArchivos.append('myFiles', this.state.fileToUpload[i]);
      }
      // console.log('dataArchivos')
      // console.log(dataArchivos)


      // console.log('this.state.fileToUpload')
      // console.log(this.state.fileToUpload)

      let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
        method: 'POST', body: dataArchivos
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      let apuntes = [];
      for (var i = 0; i < cls.length; i++) {
        let path = cls[i].path.split('src/')[1];
        //let nameFile = data[i].originalname.split('.')[0];
        let nameFile = '';
        for (let ih = 0; ih < cls[i].originalname.split('.').length; ih++) {
          if (ih == cls[i].originalname.split('.').length - 1) {
            continue;
          } else {

            if (ih == cls[i].originalname.split('.').length - 2) {
              nameFile = nameFile + cls[i].originalname.split('.')[ih]
            } else {
              nameFile = nameFile + cls[i].originalname.split('.')[ih] + '.'
            }
          }

        }
        apuntes.push({ nameFile: nameFile, path: path });
      }
      let data2 = {
        infoFileUpload: apuntes,
        id_tema: this.props.idTema
      }


      let response2 = await fetch("https://oposiciones-justicia.es/api/temas/addApuntes", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;
      if (cls2.affectedRows > 0) {


        let data33 = { id_tema: this.props.idTema }
        let response33 = await fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
        });
        if (!response33.ok) {
          throw Error(response33.statusText);
        }
        let json33 = await response33.json();
        let cls3 = await json33;
        this.setState({ temaApuntes: cls3 })
      }





    } else if (e.target.id.split('-')[0] == 'borrarApunte') {
      let loca = e.target.id.split('-')[1]
      let temaApuntes = this.state.temaApuntes


      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del apunte",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { idTema: this.props.idTema, idApunte: temaApuntes[loca].id_apunte }


            let response1 = await fetch("https://oposiciones-justicia.es/api/temas/borrarApunteTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data44 = { pathFile: '../' + temaApuntes[loca].link_archivo }
            let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
            });
            if (!response44.ok) {
              // throw Error(response44.statusText);
              console.log(response44.statusText)
            }
            let json44 = await response44.json();
            let cls44 = await json44;

            if (cls1.affectedRows > 0) {


              let data33 = { id_tema: this.props.idTema }
              let response33 = await fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
              });
              if (!response33.ok) {
                throw Error(response33.statusText);
              }
              let json33 = await response33.json();
              let cls3 = await json33;
              this.setState({ temaApuntes: cls3 })
            }
          }
        });

    } else if (e.target.id == 'verVideos') {

      let response1 = await fetch("https://oposiciones-justicia.es/api/temas/loadVideosTemas");
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      let filtroTemas23 = cls1.filter(v => v.id_tema == this.props.idTema)
      if (filtroTemas23.length > 0) {
        if (this.props.temasFacturados.filter(p => p.idTema == this.props.idTema).length > 0) {
          this.setState({ temasVideoTSL: filtroTemas23, viewEdtvidTema: true })
        } else {
          swal('Videos bloqueados', 'Aun no ha desbloqueado/facturado este tema.', 'info')
        }

      } else {
        swal('Temas sin videos', 'Aun no tiene videos asignados', 'info')
      }
    } else if (e.target.id == 'cerrarVideo') {
      this.setState({ viewEdtvidTema: null })
      this.forceUpdate()
    } else if (e.target.id == 'verNota') {

      this.setState({ viewNotaTema: true })

    } else if (e.target.id == 'cerrarNota') {
      this.setState({ viewNotaTema: null })
      this.forceUpdate()
    } else if (e.target.id == 'viewArtBus') {
      this.props.openViewBusArt(this.props.viewBusArt == null ? true : null)
    }


  }
  async componentDidUpdate() {
    //Apuntes del tema consultaApuntesTemas

    if (this.state.temaApuntes && this.state.temaApuntes.length > 0 && this.state.temaApuntes[0].id_tema != this.props.idTema) {
      this.setState({ temaApuntes: null, temaIntervalos: null, leySelectd: 0 })

      let data = { id_tema: this.props.idTema }
      let response = await fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      let response2 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrg", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      let temaIntervalos = []
      for (let i = 0; i < cls2.length; i++) {
        if (temaIntervalos.findIndex(x => x.idLey == cls2[i].id_ley) == -1) {
          temaIntervalos.push({
            idLey: cls2[i].id_ley,
            nombreLey: this.state.busquedaLeyes.find(a => a.id_ley == cls2[i].id_ley).nombre_ley,
            siglasLey: this.state.busquedaLeyes.find(a => a.id_ley == cls2[i].id_ley).siglas_ley,
            linkLey: this.state.busquedaLeyes.find(a => a.id_ley == cls2[i].id_ley).link_ley,
            intervalosArt: [cls2[i]]
          })

        } else {
          let find = temaIntervalos.findIndex(z => z.idLey == cls2[i].id_ley)
          temaIntervalos[find].intervalosArt.push(
            cls2[i]
          )
        }

      }

      this.setState({ temaApuntes: cls, temaIntervalos: temaIntervalos })
      let data2 = { idTema: this.props.idTema }
      let response2x = await fetch("https://oposiciones-justicia.es/api/temas/infoNotaTema", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2x.ok) {
        throw Error(response2x.statusText);
      }
      let json2x = await response2x.json();
      let cls2x = await json2x;

      this.setState({
        notaPreparador: cls2x != undefined && cls2x.length > 0 ? cls2x[0].notaPreparador : null
      })
    }



  }
  async componentDidMount() {

    let response0 = await fetch('https://oposiciones-justicia.es/api/leyes/consulta')
    let json0 = await response0.json();
    let cls0 = await json0;
    this.setState({ busquedaLeyes: cls0 })
    let data = { id_tema: this.props.idTema }
    let response = await fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    let json = await response.json();
    let cls = await json;

    let response2 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrg", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;

    let temaIntervalos = []
    for (let i = 0; i < cls2.length; i++) {
      if (temaIntervalos.findIndex(x => x.idLey == cls2[i].id_ley) == -1) {
        temaIntervalos.push({
          idLey: cls2[i].id_ley,
          nombreLey: cls0.find(a => a.id_ley == cls2[i].id_ley).nombre_ley,
          siglasLey: cls0.find(a => a.id_ley == cls2[i].id_ley).siglas_ley,
          linkLey: cls0.find(a => a.id_ley == cls2[i].id_ley).link_ley,
          intervalosArt: [cls2[i]]
        })

      } else {
        let find = temaIntervalos.findIndex(z => z.idLey == cls2[i].id_ley)
        temaIntervalos[find].intervalosArt.push(
          cls2[i]
        )
      }

    }

    this.setState({ temaApuntes: cls, temaIntervalos: temaIntervalos })


    let data2 = { idTema: this.props.idTema }
    let response2x = await fetch("https://oposiciones-justicia.es/api/temas/infoNotaTema", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response2x.ok) {
      throw Error(response2x.statusText);
    }
    let json2x = await response2x.json();
    let cls2x = await json2x;

    this.setState({
      notaPreparador: cls2x != undefined && cls2x.length > 0 ? cls2x[0].notaPreparador : null
    })

  }
  render() {
    const { temaApuntes, temaIntervalos, leySelectd, viewEdtLeyTema, temasVideoTSL, viewEdtvidTema, notaPreparador, viewNotaTema } = this.state;
    const { tipoUser } = this.props;

    return (
      <div className="mainTema2ert">

        <div className="contenedorLeyes2ert">

          {leySelectd != null && temaIntervalos != undefined && temaIntervalos.length > 0 && temaIntervalos[parseInt(leySelectd)] != undefined ? (

            <div className="bloqueLeyes2ert">
              <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Legislación a estudiar </div>

              {tipoUser && tipoUser >= 58 ? (
                <div className='barTD2ert-navAdmin'>

                  <i title="Editar tema intervalos, legislación..." className="fa-light fa-pen-circle" onClick={this.onHandleClickFfF} id="edtLey"></i>
                  <i id="viewArtBus" className="fa-sharp fa-light fa-folder-magnifying-glass" onClick={this.onHandleClickFfF}></i>
                  {notaPreparador && notaPreparador != null ? (
                    <Boton id="verNota" title="Ver nota preparador" icon1="" icon2="" texto="Nota preparador" onClick={this.onHandleClickFfF} />
                  ) : (<p>No tiene nota preparador</p>)}
                </div>
              ) : (

                <div className='barTD2ert-navAdmin' style={{ justifyContent: 'center' }}>
                  <i id="viewArtBus" className="fa-sharp fa-light fa-folder-magnifying-glass" title="Buscar artículo" onClick={this.onHandleClickFfF}></i>
                  {notaPreparador && notaPreparador != null ? (
                    <Boton id="verNota" title="Ver nota preparador" icon1="" icon2="" texto="Nota preparador" onClick={this.onHandleClickFfF} />
                  ) : null}
                </div>

              )}


              <div className="blLeyes2ert">

                {temaIntervalos && temaIntervalos.length > 0 && temaIntervalos.map((ley234, key234frrggd2) =>

                  <div title={ley234.nombreLey} className={leySelectd == key234frrggd2 ? ("leySeleted") : ("leyNormalice")} id={"sigla-" + key234frrggd2} onClick={this.onHandleClickFfF}>
                    {ley234.siglasLey}
                  </div>

                )}
                <div className="blLeyesArtTT2ert">
                  <p>{temaIntervalos && temaIntervalos.length > 0 ? (temaIntervalos[leySelectd].nombreLey) : null}</p>
                </div>
              </div>
              <div className="blarticulosMain2ert">

                {temaIntervalos && temaIntervalos.length > 0 && temaIntervalos[leySelectd].intervalosArt && temaIntervalos[leySelectd].intervalosArt.length > 0 && temaIntervalos[leySelectd].intervalosArt.map((intA, key234frrggd3) =>
                  0 == key234frrggd3 ? (
                    isDefined(intA.art_inicio) && intA.art_inicio != '-' ? (
                      <div className="blartInd2ertF">
                        {isDefined(intA.art_fin) && intA.art_fin != '-' ? (intA.art_inicio + ' - ' + intA.art_fin) : (intA.art_inicio)}
                      </div>
                    ) : null
                  ) : (
                    isDefined(intA.art_inicio) && intA.art_inicio != '-' ? (
                      <div className="blartInd2ert">
                        {isDefined(intA.art_fin) && intA.art_fin != '-' ? (intA.art_inicio + ' - ' + intA.art_fin) : (intA.art_inicio)}
                      </div>
                    ) : null

                  )


                )}



              </div>
              <div className="irAley2ert">
                <Boton id={"openLey-" + leySelectd} icon1="" icon2="" texto="Abrir Ley" onClick={this.onHandleClickFfF} />
              </div>
            </div>

          ) : (
            temaIntervalos != undefined && temaIntervalos.length > 0 ? (
              <div className="bloqueLeyes2ert">
                <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Error recarge la web </div>

              </div>
            ) : (
              <div className="bloqueLeyes2ert">
                <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Legislación a estudiar </div>

                {tipoUser && tipoUser >= 58 && temaIntervalos != undefined ? (
                  <div className='barTD2ert-navAdmin'>

                    <i title="Editar tema intervalos, legislación..." className="fa-light fa-pen-circle" onClick={this.onHandleClickFfF} id="edtLey"></i>
                  </div>
                ) : null}
                <div className="blLeyes2ert">

                  {
                    temaIntervalos == undefined ? (
                      "Cargando"
                    ) : (
                      'No existe ningun intervalo de estudio'
                    )
                  }

                </div>
              </div>
            )

          )}

        </div>

        <div className="contenedorTemasDes2ert">
          <div className="barTD2ert"> <i className="fas fa-exclamation-circle"></i> Apuntes del Tema </div>
          {tipoUser && tipoUser >= 58 ? (
            <div className='barTD2ert-navAdmin' style={{ justifyContent: 'center' }}>
              <InputFileExamDesa texto="Subir apunte" name="myFiles" icon1="fa-light fa-circle-plus" onChange={this.myFiles} />
              <i title="Ver videos del tema" style={{ marginLeft: '7px' }} id="verVideos" className="fa-light fa-video-arrow-up-right barTD2ertVerVideo" onClick={this.onHandleClickFfF}></i>
            </div>
          ) : (
            <div className='barTD2ert-navAdmin' style={{ justifyContent: 'center' }}>



              <Boton id="verVideos" title="Ver videos del tema" icon1="" icon2="" texto="Ver video" onClick={this.onHandleClickFfF} />
              <i title="Ver videos del tema" style={{ marginLeft: '7px' }} id="verVideos" className="fa-light fa-video-arrow-up-right barTD2ertVerVideo" onClick={this.onHandleClickFfF}></i>
            </div>
          )}
          <div id="wrapper">
            <div class="scrollbar2" id="style-3">
              <div class="force-overflow2">
                {temaApuntes && temaApuntes.map((apunte234, key234frrggd) =>
                  <div className="cTDtema2ert" id={"apunte-" + key234frrggd} onClick={this.onHandleClickFfF}>

                    <div id={"apunte-" + key234frrggd} style={{ maxWidth: '170px', textAlign: 'left', paddingLeft: '2px' }}>{apunte234.titulo_archivo}</div>

                    <div className="iconDowodlo">
                      <i title={tipoUser && tipoUser >= 58 ? ("Borrar apunte") : ('Descargar apunte')} style={tipoUser && tipoUser >= 58 ? ({ fontSize: '18px' }) : ({})} id={tipoUser && tipoUser >= 58 ? ("borrarApunte-" + key234frrggd) : ("apunte-" + key234frrggd)} className={tipoUser && tipoUser >= 58 ? ("fa-light fa-circle-trash") : ("fas fa-download")}></i>
                    </div>

                  </div>
                )}

              </div>
            </div>
          </div>
        </div>


        {
          viewEdtLeyTema && viewEdtLeyTema != null ? (
            <CambioLeyTemaAdm idTema={this.props.idTema} temaIntervalos={temaIntervalos} onClick={this.onHandleClickFfF} onChange={this.onChangeInput} />
          ) : null

        }

        {
          viewEdtvidTema && viewEdtvidTema != null ? (
            <VideoViewTema videos={temasVideoTSL} onClick={this.onHandleClickFfF} />
          ) : null

        }

        {
          // Nota preparador
          viewNotaTema && viewNotaTema != null ? (
            <NotaTema nota={notaPreparador} onClick={this.onHandleClickFfF} />
          ) : null

        }



      </div>
    );
  }
}

// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    temasFacturados: state.user.temasFacturados,
    tipoUser: state.user.tipoUser,
    viewBusArt: state.user.viewBusArt
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openViewBusArt
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TemaSeleted);
// Dependencies
import React, { Component } from 'react';
// Assets
import './css/css.css';



class CajaSemanaWordTest extends Component {

	constructor(props) {
		super(props);
		this.state = {};

		this.onHandleClick = this.onHandleClick.bind(this);
	}

	async onHandleClick(e) {
		let { testWord } = this.props;
		console.log('testWord:', testWord)


		let linkDescarga1 = testWord.link_test
		let nameDes1 = 'Test '+testWord.numero_test + '.pdf'

		let linkDescarga2 = testWord.link_soluciones
		let nameDes2 = 'Respuestas-Test' + testWord.numero_test + '.pdf'

		swal('Descargas en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

		let pathFile = 'public' + linkDescarga1.split('..')[1]
		let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
		let nameFile = nameDes1
		fetch(url, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			responseType: 'blob'
		}).then(res => {
			if (res.status >= 400) { throw new Error("Bad res from server"); }
			return res.blob();
		}).then(blob => {
			saveAs(blob, nameFile);
		}).catch(function (err) {
			console.log(err)
			swal('Link de descarga no valido','Error en el archivo: '+nameDes1+' reportelo gracias.','info')
		});

		let pathFile2 = 'public' + linkDescarga2.split('..')[1]
		let url2 = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile2);
		let nameFile2 = nameDes2
		fetch(url2, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			responseType: 'blob'
		}).then(res => {
			if (res.status >= 400) { throw new Error("Bad res from server"); }
			return res.blob();
		}).then(blob => {
			saveAs(blob, nameFile2);
		}).catch(function (err) {
			console.log(err)
			swal('Link de descarga no valido','Error en el archivo: '+nameDes2+' reportelo gracias.','info')
		});

	}

	render() {
		const { testWord, keyProp } = this.props;

		return (


			<div id={'descargarTest-' + keyProp} onClick={this.onHandleClick} className='celdaClaseLE' style={{ marginRight: '0px', padding: '15px' }} title="Haga clic para descargar el test y solución">


				<div id={'descargarTest-' + keyProp} className='divClaseload' title="Haga clic para descargar el test y solución">
					<div id={'descargarTest-' + keyProp} className='textoDivCM'>
						<b style={{
							textAlign: 'center',
							fontSize: '30px',
							marginTop: '10px'
						}} id={'descargarTest-' + keyProp}>{'Test '+testWord.numero_test}</b>
					</div>

				</div>

			</div>


		);
	}
}

export default CajaSemanaWordTest;

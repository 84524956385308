// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/documentoTextRich.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ReactHtmlParser from 'react-html-parser';

class TextoRich extends Component {

  static propTypes = {
    valueTextHtml: PropTypes.string
  };

  constructor(props){
        super(props);
  }

  render() {
    const { valueTextHtml } = this.props;
    if(valueTextHtml != undefined){
      return (
        <div className="documentoTextRich">
          {ReactHtmlParser(valueTextHtml)}
        </div>

      );
    } else {
      return (
        <div>

        </div>
      );
    }
        }
 }

export default TextoRich;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../../../data/menuOficina';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from './actions/baroptionAction';
// Components
import MenuAdmin from '../MenuAdmin';
import Option from './optionBar';

class Alumnos extends Component {
  static propTypes = {
    cargarContenido: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    optionSelected: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = { idUser: undefined }
  }
  componentDidMount() {
    let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
    fetch("https://oposiciones-justicia.es/api/oficina/idOficinaPrepa", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status >= 400) { throw new Error("Bad response from server"); }
      return response.json();
    }).then(cls => {
      this.setState({ idUser: cls[0].id_usuario });
    }).catch(error => console.log('Object fecth failed', error))
  }
  render() {

    const { isLogged, nombreUser, tipoUser, optionSelected } = this.props;
    const { idUser } = this.state
    const nivelRequired = 98;

    if (isLogged && nivelRequired <= tipoUser) {
      return (
        <div className="inBodyMenu">
          
          <div className="menuBody">
            <MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="OFICINA" />
          </div>
          <div className="optionselectedNew">
            <Option selectedOption={optionSelected} idUsuario={idUser} />
          </div>
          
        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    optionSelected: state.baroption.optionSelected,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cargarContenido
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Alumnos);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
import ReactPlayer from 'react-player'
// Assets
import './css/prgEdtSelected.css';
import { respuestaSbr } from '../../lib/utils/cargarTestJusticia';
//Component
import Input from '../Inputs/Input';
import Alumnos from '../Alumnos';
import Pdf from '../Pdf';
import BarraProceso from '../Inputs/BarraProceso';
import Submit from '../Inputs/Submit';
import BotonMedio from '../Botones/BotonMedio';


class ClaseVirtualPreparador extends Component {

	static propTypes = {
		ejerciciosClase: PropTypes.array,
		temasClase: PropTypes.array,
		intervalosExtraClase: PropTypes.array,
		semanaPrg: PropTypes.array
	};

	constructor(props){
		super(props);
		this.state = { };
		this.onToggleClick = this.onToggleClick.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.downloadPdf = this.downloadPdf.bind(this);
		this.showPdf = this.showPdf.bind(this);
		this.closePdf = this.closePdf.bind(this);
	}
	downloadPdf(e){
//		wordTest && wordTest.find(t => t.id == bloqueTemas.idTestWord) && wordTest.find(t => t.id == bloqueTemas.idTestWord)

	if(e.target.id.split('-')[0] == 'descargarPdfTestExamen'){
		let locaBloque = e.target.id.split('-')[1];
		let pathFile = this.state.wordTest.find(t => t.id == this.props.semanaPrg[0].bloqueTemas[locaBloque].idWordTestPDF).linkTest
		if(pathFile != undefined){
			swal('Iniciando descarga, espere.','Si en 10 segundos no se inicia vuelva a darle clicl','info')
			let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
			let nameFile = pathFile.split('/').pop().split('-,-')[0];
			fetch(url, {
					method: 'GET',
					headers: {'Content-Type': 'application/json'},
					responseType: 'blob'
					}).then(res =>  {
							if (res.status >= 400) { throw new Error("Bad res from server");}
							return res.blob();
					}).then(blob => {
						saveAs(blob,nameFile);
					}).catch(function(err) {console.log(err)
						alert("Link no valido para descarga, reportelo, gracias.")
					});
		}
	} else if(e.target.id.split('-')[0] == 'descargarPdfTestRes'){
		let locaBloque = e.target.id.split('-')[1];
		let pathFile = this.state.wordTest.find(t => t.id == this.props.semanaPrg[0].bloqueTemas[locaBloque].idWordTestPDF).linkResolucion
		if(pathFile != undefined){
			swal('Iniciando descarga, espere.','Si en 10 segundos no se inicia vuelva a darle clicl','info')
			let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
			let nameFile = pathFile.split('/').pop().split('-,-')[0];
			fetch(url, {
					method: 'GET',
					headers: {'Content-Type': 'application/json'},
					responseType: 'blob'
					}).then(res =>  {
							if (res.status >= 400) { throw new Error("Bad res from server");}
							return res.blob();
					}).then(blob => {
						saveAs(blob,nameFile);
					}).catch(function(err) {console.log(err)
						alert("Link no valido para descarga, reportelo, gracias.")
					});
		}

	} else if(e.target.id.split('-')[0] == 'descargarPdfExaGes'){
		let locaBloque = e.target.id.split('-')[1];
		let pathFile = this.state.examGest.find(t => t.id == this.props.semanaPrg[0].bloqueTemas[locaBloque].idExamenGestion).linkExamen
		if(pathFile != undefined){
			swal('Iniciando descarga, espere.','Si en 10 segundos no se inicia vuelva a darle clicl','info')
			let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
			let nameFile = pathFile.split('/').pop().split('-,-')[0];
			fetch(url, {
					method: 'GET',
					headers: {'Content-Type': 'application/json'},
					responseType: 'blob'
					}).then(res =>  {
							if (res.status >= 400) { throw new Error("Bad res from server");}
							return res.blob();
					}).then(blob => {
						saveAs(blob,nameFile);
					}).catch(function(err) {console.log(err)
						alert("Link no valido para descarga, reportelo, gracias.")
					});
		}
	} else {
		let locaBloque = e.target.id.split('-')[1];
		let locaTema = e.target.id.split('-')[2];
		let locaApunte = e.target.id.split('-')[3];
		let pathFile = this.props.semanaPrg[0].bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].linkPdf
		swal('Iniciando descarga, espere.','Si en 10 segundos no se inicia vuelva a darle clicl','info')
		let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
		let nameFile = pathFile.split('/').pop().split('-,-')[0];
		fetch(url, {
				method: 'GET',
				headers: {'Content-Type': 'application/json'},
				responseType: 'blob'
				}).then(res =>  {
						if (res.status >= 400) { throw new Error("Bad res from server");}
						return res.blob();
				}).then(blob => {
					saveAs(blob,nameFile);
				}).catch(function(err) {console.log(err)
					alert("Link no valido para descarga, reportelo, gracias.")
				});
	}


	}
showPdf(e){
	let identificador = e.target.id.split('*')[0];
	let pathFile = e.target.id.split('*')[1];
	let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
	this.setState({pdfLoad: url})
	$('#'+identificador+'pdfLoad').slideToggle(1000);
	$('#'+identificador+'pdf').slideToggle(1000);
}
closePdf(e){
	let pM = e.target.id.split('pdf')[0];
	$('#'+pM+'pdfLoad').slideToggle(1000);
	$('#'+pM+'pdf').slideToggle(1000);
}

onToggleClick(e){
	if(e.target.title == 'ejercicios'){
		let id = e.target.id;
		$('#'+id+'infoEjercicios').slideToggle(1000);
	} else if(e.target.title == 'temas'){
			$('#infoTemas').slideToggle(1000);
	} else if(e.target.title= 'articulosExtra'){
		$('#infoArticulosExtra').slideToggle(1000);
	}


}
async handleOnClick(e){
	// HACER TODO ASYNC! SINO NO VA!! NECESITA EL
	// --> respuestaSbr(cls[i].respuesta, cls[i].subrayado)
	var e = e
	if(e.target.id.split('-')[0] == 'verTestJusticia'){
		// Sacamos el test, lo guardamos en props "reducer-action" y abrimos nueva ventana el verTestJusticia -->

		try{
			let { semanaPrg } = this.props
			let locaBloque = await e.target.id.split('-')[1]
			let data1 = { idTest: semanaPrg[0].idTestJusticia }
			const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompleto", {
				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				const json1 = await response1.json();
				const cls1 = await json1;

				let testNew = []

				for (var i = 0; i < cls1.length; i++) {
					let articuloRevisado = ''
					  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
					    let artDFG= cls1[i].articulo.split('.y.')
					    for (var k = 0; k < artDFG.length; k++) {
					      if(k != artDFG.length-1 && k != artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
					      } else if(k == artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
					      } else if(k == artDFG.length-1){
					        articuloRevisado = articuloRevisado + artDFG[k]
					      }
					    }
					  } else {
					    articuloRevisado = cls1[i].articulo
					  }

						let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)


					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}
				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verTestJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}
	} else if(e.target.id.split('-')[0] == 'verTestJusticiaExtra'){
		try {
			let { semanaPrg } = this.props
			let locaBloque = e.target.id.split('-')[1]
			let data1 = { idTest: semanaPrg[0].bloqueTemas[locaBloque].idTestExtra }
			const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompleto", {
				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				const json1 = await response1.json();
				const cls1 = await json1;
				let testNew = []

				for (var i = 0; i < cls1.length; i++) {
					let articuloRevisado = ''
					if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
				    let artDFG= cls1[i].articulo.split('.y.')
				    for (var k = 0; k < artDFG.length; k++) {
				      if(k != artDFG.length-1 && k != artDFG.length-2){
				        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
				      } else if(k == artDFG.length-2){
				        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
				      } else if(k == artDFG.length-1){
				        articuloRevisado = articuloRevisado + artDFG[k]
				      }
				    }
				  } else {
				    articuloRevisado = cls1[i].articulo
				  }
					let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
					let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
					let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
					let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}
				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verTestJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}


	} else if(e.target.id.split('-')[0] == 'verCasoPractico'){
		// Sacamos el test, lo guardamos en props "reducer-action" y abrimos nueva ventana el verTestJusticia -->

		try {
			let { semanaPrg } = this.props
			let locaBloque = e.target.id.split('-')[1]
			let data1 = { idTest: semanaPrg[0].idCasoPractico }

				const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompletoCP", {
		      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
		      if (!response1.ok) {
		        throw Error(response1.statusText);
		      }
		      const json1 = await response1.json();
		      const cls1 = await json1;

				let testNew = []

				for (var i = 0; i < cls1.length; i++) {

					let articuloRevisado = ''
					  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
					    let artDFG= cls1[i].articulo.split('.y.')
					    for (var k = 0; k < artDFG.length; k++) {
					      if(k != artDFG.length-1 && k != artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
					      } else if(k == artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
					      } else if(k == artDFG.length-1){
					        articuloRevisado = articuloRevisado + artDFG[k]
					      }
					    }
					  } else {
					    articuloRevisado = cls1[i].articulo
					  }
						let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						textHtml: cls1[i].textoCaso,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}
				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verCasoPracticoJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}

	} else if(e.target.id.split('-')[0] == 'verCasoPracticoExtra'){
		// Sacamos el test, lo guardamos en props "reducer-action" y abrimos nueva ventana el verTestJusticia -->
		try {
			let { semanaPrg } = this.props
			let locaBloque = e.target.id.split('-')[1]
			let data1 = { idTest: semanaPrg[0].bloqueTemas[locaBloque].idCasoExtra }

				const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompletoCP", {
		      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
		      if (!response1.ok) {
		        throw Error(response1.statusText);
		      }
		      const json1 = await response1.json();
		      const cls1 = await json1;

				let testNew = []

				for (var i = 0; i < cls1.length; i++) {

					let articuloRevisado = ''
					  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
					    let artDFG= cls1[i].articulo.split('.y.')
					    for (var k = 0; k < artDFG.length; k++) {
					      if(k != artDFG.length-1 && k != artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
					      } else if(k == artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
					      } else if(k == artDFG.length-1){
					        articuloRevisado = articuloRevisado + artDFG[k]
					      }
					    }
					  } else {
					    articuloRevisado = cls1[i].articulo
					  }
						let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						textHtml: cls1[i].textoCaso,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}



				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verCasoPracticoJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}

	} else if (e.target.id.split('-')[0] == 'verApuntes'){
		let locaBloque = e.target.id.split('-')[1]
		let locaTema = e.target.id.split('-')[2]

		$('#apunte-'+locaBloque+'-'+locaTema).slideToggle(1000);
	} else if (e.target.id.split('-')[0] == 'verNota'){
		let locaBloque = e.target.id.split('-')[1]
		let locaTema = e.target.id.split('-')[2]

		$('#nota-'+locaBloque+'-'+locaTema).slideToggle(1000);
	} else if (e.target.id.split('-')[0] == 'verLeyes'){
		let locaBloque = e.target.id.split('-')[1]
		let locaTema = e.target.id.split('-')[2]

		$('#leyes-'+locaBloque+'-'+locaTema).slideToggle(1000);

	} else if (e.target.id.split('-')[0] == 'bloqueTemas'){
		let locaBloque = e.target.id.split('-')[1]
		$('#'+locaBloque+'-temasBloque').slideToggle(1000);
	} else if(e.target.id.split('-')[0] == 'verTestsJusticia'){

		try{
			let { semanaPrg } = this.props
			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let data1 = { idTest: semanaPrg[0].bloqueTemas[locaBloque].testsJusticia[locaTest].idTestJusticia }
			const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompleto", {
				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				const json1 = await response1.json();
				const cls1 = await json1;

				let testNew = []

				for (var i = 0; i < cls1.length; i++) {
					let articuloRevisado = ''
					  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
					    let artDFG= cls1[i].articulo.split('.y.')
					    for (var k = 0; k < artDFG.length; k++) {
					      if(k != artDFG.length-1 && k != artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
					      } else if(k == artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
					      } else if(k == artDFG.length-1){
					        articuloRevisado = articuloRevisado + artDFG[k]
					      }
					    }
					  } else {
					    articuloRevisado = cls1[i].articulo
					  }

						let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)

					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}
				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verTestJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}
	} else if (e.target.id.split('-')[0] == 'verRanking'){
		let locaBloque = e.target.id.split('-')[1]
		let locaTest = await e.target.id.split('-')[2]
		$('#ranking-'+locaBloque+'-'+locaTest).fadeToggle(700).css("display", "flex");
	} else if (e.target.id.split('-')[0] == 'verRankingcp'){
		let locaBloque = e.target.id.split('-')[1]
		let locaTest = await e.target.id.split('-')[2]
		$('#rankingcp-'+locaBloque+'-'+locaTest).fadeToggle(700).css("display", "flex");
	} else if(e.target.id.split('-')[0] == 'verCasoPractic'){
		// Sacamos el test, lo guardamos en props "reducer-action" y abrimos nueva ventana el verTestJusticia -->
		try {
			let { semanaPrg } = this.props
			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let data1 = { idTest: semanaPrg[0].bloqueTemas[locaBloque].casosPractico[locaTest].idCasoPractico }

				const response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/selectTestCompletoCP", {
		      method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
		      if (!response1.ok) {
		        throw Error(response1.statusText);
		      }
		      const json1 = await response1.json();
		      const cls1 = await json1;

				let testNew = []

				for (var i = 0; i < cls1.length; i++) {

					let articuloRevisado = ''
					  if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
					    let artDFG= cls1[i].articulo.split('.y.')
					    for (var k = 0; k < artDFG.length; k++) {
					      if(k != artDFG.length-1 && k != artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] + ' , '
					      } else if(k == artDFG.length-2){
					        articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
					      } else if(k == artDFG.length-1){
					        articuloRevisado = articuloRevisado + artDFG[k]
					      }
					    }
					  } else {
					    articuloRevisado = cls1[i].articulo
					  }
						let hmtml1 = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						let hmtml2 = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						let hmtml3 = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						let hmtml4 = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
					testNew.push({
						idTest: cls1[i].idTest,
						numPreg: i+1,
						textHtml: cls1[i].textoCaso,
						idPreg: cls1[i].idPregunta,
						pregunta: cls1[i].pregunta,
						tipoPregunta: cls1[i].tipoPregunta,
						articulo: articuloRevisado,
						idLey: cls1[i].idLey,
						siglasLey: cls1[i].siglasLey,
						nombreLey: cls1[i].nombreLey,
						idR1: cls1[i].idR1,
						idR2: cls1[i].idR2,
						idR3: cls1[i].idR3,
						idR4: cls1[i].idR4,
						respuestas: [{
              idR: cls1[i].idR1,
              respuesta: cls1[i].R1,
							respuestaHtml: hmtml1,
              validez: cls1[i].validezR1,
              subrayado: cls1[i].subrayado1,
              nota: cls1[i].nota1,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR2,
              respuesta: cls1[i].R2,
							respuestaHtml: hmtml2,
              validez: cls1[i].validezR2,
              subrayado: cls1[i].subrayado2,
              nota: cls1[i].nota2,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR3,
              respuesta: cls1[i].R3,
							respuestaHtml: hmtml3,
              validez: cls1[i].validezR3,
              subrayado: cls1[i].subrayado3,
              nota: cls1[i].nota3,
              valorUsuario: 0
            },
            {
              idR: cls1[i].idR4,
              respuesta: cls1[i].R4,
							respuestaHtml: hmtml4,
              validez: cls1[i].validezR4,
              subrayado: cls1[i].subrayado4,
              nota: cls1[i].nota4,
              valorUsuario: 0
            }]
					})
				}



				await this.setState({testJusticiaLoad: testNew})
				let e2 = 'verCasoPracticoJusticia'
				await this.props.onClick(e2,testNew);

		} catch(e){console.log(e)}

	}

	//verCasoPractic




}
componentDidMount(){
	fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		.then(res => res.json())
			.then(leyes => this.setState({
					busquedaLeyes: leyes
			}))
		.catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/preparador/wordTest')
			.then(res => res.json())
				.then(er => this.setState({
						wordTest: er
				}))
			.catch(error => console.log('Object fecth failed', error));
			fetch('https://oposiciones-justicia.es/api/preparador/examGest')
				.then(res => res.json())
					.then(er => this.setState({
							examGest: er
					}))
				.catch(error => console.log('Object fecth failed', error));
}
	render() {
		const { semanaPrg, ejerciciosClase, temasClase, intervalosExtraClase } = this.props;
		const { wordTest, examGest} = this.state

		return (
			<div className="sectionEdtPrograma">

					<div className="blockPRG">
						{semanaPrg && semanaPrg[0].testJusticia != 'No asignado' ? (
							<div className="sidebarBlockPRG">
								<div> <i className="fas fa-info-circle"></i> </div>
								<div className ="sidebarTitleBPRG"> Ejercicios de la Semana </div>
								<div id={0} title="ejercicios" className="toggleBPRG">
									<i id={0} title="ejercicios" className="fas fa-arrow-alt-circle-down" onClick={this.onToggleClick}></i>
								</div>
							</div>
						): null }
						<div className="bodyBlockPRG" id={0+'infoEjercicios'}>
							<div className="displayItems">

							{semanaPrg && semanaPrg[0].testJusticia != 'No asignado' ? (
								<div className="item">
									<div className="titleItem">
										<i className="fas fa-info-circle"></i>
										Test Justicia
										<i className="fas fa-clipboard-check"></i>
									</div>
									<div className="bodyItem">
										<div className="celdaInfoBody">
											<div className="testAsignado">
												<i className="fas fa-clipboard-check"></i>
												{semanaPrg[0].testJusticia}
											</div>
										</div>
										<div>

											<BotonMedio id={'verTestJusticia-'+0} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Test Justicia"} onClick={this.handleOnClick}/>

										</div>

									</div>
								</div>
							):null}

								{semanaPrg && semanaPrg[0].casoPractico != 'No asignado' ? (
									<div className="item">
										<div className="titleItem">
											<i className="fas fa-info-circle"></i>
											Caso Práctico
											<i className="fas fa-clipboard-check"></i>
										</div>
										<div className="bodyItem">
											<div className="celdaInfoBody">
												<div className="testAsignado">
													<i className="fas fa-clipboard-check"></i>
													{semanaPrg[0].casoPractico}
												</div>
											</div>
											<div>

												<BotonMedio id={'verCasoPractico-'+0} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Caso Práctico"} onClick={this.handleOnClick}/>

											</div>

										</div>
									</div>
								):null}
							</div>
						</div>

					</div>
					{semanaPrg && semanaPrg[0].videosClase && semanaPrg[0].videosClase.length > 0 ? (
            <div className="blockPRG">
              <div className="sidebarBlockPRG">
                <div> <i className="fas fa-info-circle"></i> </div>
                <div className ="sidebarTitleBPRG"> Vídeo Clase Semanal </div>
                <div id={0} title="videosClaseBd" className="toggleBPRG">
                  <i id={0} title="videosClaseBd" className="fas fa-arrow-alt-circle-down" onClick={this.onToggleClick}></i>
                </div>
              </div>

              <div className="bodyBlockPRG" id={'videosClaseBd'}>

                <div className="displayItems">
                  {semanaPrg && semanaPrg[0].videosClase.map((video, key345) =>
                    <div className="item" key={key345}>
                      <div className="titleItem">
                        <i className="fas fa-info-circle"></i>
                          {'Video '+parseInt(key345+1)}
                        <i className="fas fa-clipboard-check"></i>
                      </div>

                      <div className="bodyItem">
                        <div style={{ padding: '10px',maxWidth:"280px"}} onContextMenu={e => e.preventDefault()}>
                          <ReactPlayer
                            url={video.link}
                            className='react-player'
                            playing={false}
                            width='100%'
                            height='100%'
                            controls={true}
                            onContextMenu={e => e.preventDefault()}
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                          />
                        </div>
												{video.nombre}
                      </div>
                    </div>
                  )}
                </div>

              </div>
            </div>
          ):null}

				<div className="blockPRGflexn">
				{ semanaPrg && semanaPrg[0].bloqueTemas.length > 0 && semanaPrg[0].bloqueTemas.map((bloqueTemas, key) =>

					<div className="blockPRG" key={key}>

							<div className="sidebarBlockPRG">
								<div> <i className="fas fa-info-circle"></i> </div>
								<div className ="sidebarTitleBPRG"> Bloque Nº {key+1}, tipo: {bloqueTemas && bloqueTemas.tipoBloque == 0 ? ('Semanal'):('Repaso')}</div>
								<div id={'bloqueTemas-'+key} title="Bloque de Temas" className="toggleBPRG">
									<i id={'bloqueTemas-'+key} title="Ver/Ocultar Temas de bloque" className="fas fa-arrow-alt-circle-down" onClick={this.handleOnClick}></i>
								</div>
							</div>


							<div className="bodyBlockPRG" id={key+'-temasBloque'}>

							<div className="displayItems">
								<div className="cmtAlumnos">
									<Alumnos id={key} alumnosClase={bloqueTemas.alumnos} OnBorrar={"0"}/>
								</div>
							</div>
							<div className="displayItems">

							{bloqueTemas && bloqueTemas.temas && bloqueTemas.temas.length > 0 && bloqueTemas.temas.map((tema, key2) =>
								<div className="item" key={key2}>

									<div className="titleItem">
										<i className="fas fa-info-circle"></i>
											{tema.nombreTema}
										<i className="fas fa-clipboard-check"></i>
									</div>
									<div className="bodyItem">

										<div className="apuntes">
										<div className="item">
											<div className="titleItem2" id={'verApuntes-'+key+'-'+key2} onClick={this.handleOnClick}>
												<i className="fas fa-book" ></i>
												Apuntes del Tema
												<i className="fas fa-arrow-alt-circle-down" id={'verApuntes-'+key+'-'+key2}></i>
											</div>
											<div className="bodyItem2" id={'apunte-'+key+'-'+key2} display={{display: 'none'}}>
											{bloqueTemas && bloqueTemas.temas && bloqueTemas.temas.length > 0 && bloqueTemas.temas[key2].apuntes.length > 0 && bloqueTemas.temas[key2].apuntes.map((apunte, key3) =>

												<div className="celdaApunte" key={key3}>

													<div>

														<BotonMedio title={apunte.nombreApunte} id={'descargarPdfApunte-'+key+'-'+key2+'-'+key3} icon1={"fas fa-check-double"} icon2={"fas fa-file-pdf"} texto={apunte.nombreApunte.substr(0,30)} onClick={this.downloadPdf}/>

													</div>

												</div>


											)}
											</div>
											</div>

										</div>

										<div className="apuntes">
										<div className="item">
											<div className="titleItem2" id={'verNota-'+key+'-'+key2} onClick={this.handleOnClick}>
												<i className="fas fa-book" ></i>
												Nota del Preparador
												<i className="fas fa-arrow-alt-circle-down" id={'verNota-'+key+'-'+key2}></i>
											</div>
											<div className="bodyItem2" id={'nota-'+key+'-'+key2} display={{display: 'none'}}>
											{bloqueTemas.temas[key2].nota && bloqueTemas.temas[key2].nota != null ? (
												<div className="celdaApunte">

													<div className="textoNota">

														{bloqueTemas.temas[key2].nota}

													</div>

												</div>
											):(
												<div className="celdaApunte">

													<div className="textoNota">

														{'No se escribio ninguna nota.'}

													</div>

												</div>
											)}



											</div>
											</div>

										</div>


										<div className="intervalos">
											<div className="item">
												<div className="titleItem2" id={'verLeyes-'+key+'-'+key2} onClick={this.handleOnClick}>
													<i className="fas fa-book" ></i>
													Legislación a estudiar
													<i className="fas fa-arrow-alt-circle-down" id={'verLeyes-'+key+'-'+key2}></i>
												</div>
												<div className="bodyItem2" id={'leyes-'+key+'-'+key2}>

												{bloqueTemas && bloqueTemas.temas && bloqueTemas.temas.length > 0 && bloqueTemas.temas[key2].intervalosLeyes.length > 0 && bloqueTemas.temas[key2].intervalosLeyes.map((info, key3) =>

													<div className="intervalosTabla">
														<div className="intervalosTablaTitulo"><i className="fas fa-gavel"></i>
																<div className="siglasLeyTabla" title={info.nombreLey}>{info.siglasLey}
																</div>
															</div>
														<div className="intervalosTablaEncabezado">
															<div className="iTEDatosTitulo"> Artículo Inicio</div>
															<div className="iTEDatosTitulo2"> Artículo Fin </div>
														</div>
														{info.intervalosArticulos && info.intervalosArticulos.map((info, key) =>
															<div className="intervalosTablaEncabezado">
																<div className="iTEDatos1">{info.artInicio}</div>
																<div className="iTEDatos2">{info.artFin}</div>
															</div>
														)}
													</div>
												)}

												</div>
											</div>
										</div>


										</div>
									</div>
								)}

							</div>

								<div className="displayItems">

									{bloqueTemas && bloqueTemas.testJusticia != 'No asignado' ? (
											<div className="item">
												<div className="titleItem">
													<i className="fas fa-info-circle"></i>
													Ranking Test Justícia
													<i className="fas fa-clipboard-check"></i>
												</div>
												<div className="bodyItem">
													<div className="bodyRanking">
													<div className="celdaRankingLeyenda">


															<div className="cRPosicion">
																<i className="fas fa-trophy"/> Posición
															</div>
															<div className="cRNombre">
															<i className="fas fa-tag"/> Alumno
															</div>
															<div className="cRDato">
																<i className="fas fa-calculator"/> Puntuación
															</div>
															<div className="cRDato">
																<i style={{ color: 'green'}} className="fas fa-check-circle"/> Correctas
															</div>
															<div className="cRDato">
																<i style={{ color: 'red'}} className="fas fa-times-circle"/> Erroneas
															</div>
															<div className="cRDato">
																<i className="far fa-circle"/> En blanco
															</div>

															<div className="cRDato">
																Total Preguntas: {
																	bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion) && bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion).length > 0 ? (
																	parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion)[0].testJusticiaRealizado.correctas) + parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion)[0].testJusticiaRealizado.erroneas) + parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion)[0].testJusticiaRealizado.enBlanco)
																):('Nadie lo hizo')
																}
															</div>

													</div>
														{bloqueTemas.alumnos.filter(a=> a.testJusticiaRealizado != undefined).sort((a, b) => b.testJusticiaRealizado.puntuacion - a.testJusticiaRealizado.puntuacion).map((alumno, key458) =>
															<div className="celdaRanking" key={key458}>


																	<div className="cRPosicion">
																		<i className="fas fa-trophy"/> {parseInt(key458+1)}
																	</div>
																	<div className="cRNombre">
																	<i className="fas fa-tag"/>{alumno.nombreAlumno +' '+alumno.apellidosAlumno.split(' ')[0]}
																	</div>
																	<div className="cRPuntuacion">
																		<i className="fas fa-calculator"/> {alumno.testJusticiaRealizado.puntuacion}
																	</div>
																	<div className="cRDato">
																		<i style={{ color: 'green'}} className="fas fa-check-circle"/> {alumno.testJusticiaRealizado.correctas}
																	</div>
																	<div className="cRDato">
																		<i style={{ color: 'red'}} className="fas fa-times-circle"/> {alumno.testJusticiaRealizado.erroneas}
																	</div>
																	<div className="cRDato">
																		<i className="far fa-circle"/> {alumno.testJusticiaRealizado.enBlanco}
																	</div>

															</div>
														)}
													</div>
												</div>
											</div>

									):null}

								{bloqueTemas && bloqueTemas.testJusticia != 'No asignado' ? (
									<div className="item">
										<div className="titleItem">
											<i className="fas fa-info-circle"></i>
											Test Justicia
											<i className="fas fa-clipboard-check"></i>
										</div>
										<div className="bodyItem">
											<div className="celdaInfoBody">
												<div className="testAsignado">
													<i className="fas fa-clipboard-check"></i>
													{bloqueTemas.testJusticia}
												</div>
											</div>
											<div>

												<BotonMedio id={'verTestJusticiaExtra-'+key} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Test Justicia"} onClick={this.handleOnClick}/>

											</div>

										</div>
									</div>
								):null}

								{bloqueTemas && bloqueTemas.testsJusticia != undefined && bloqueTemas.testsJusticia.length > 0 ? (
									bloqueTemas.testsJusticia.map((test, key89)=>
									<div className="itemDoble" key={key89}>
										<div className="item">
											<div className="titleItem">
												<i className="fas fa-info-circle"></i>
												Test Justicia
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="celdaInfoBody">
													<div className="testAsignado">
														<i className="fas fa-clipboard-check"></i>
														{'nombre'}
													</div>
												</div>
												<div>

													<BotonMedio id={'verTestsJusticia-'+key+'-'+key89} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Test Justicia"} onClick={this.handleOnClick}/>
													<BotonMedio id={'verRanking-'+key+'-'+key89} icon1={"fas fa-eye"} icon2={"fas fa-trophy"} texto={"Ver Ranking"} onClick={this.handleOnClick}/>

												</div>

											</div>
										</div>


										<div className="item" style={{display: 'none'}} id={'ranking-'+key+'-'+key89}>
											<div className="titleItem">
												<i className="fas fa-info-circle"></i>
												Ranking Test Justícia
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="bodyRanking">
												<div className="celdaRankingLeyenda">


														<div className="cRPosicion" key={key89}>
															<i className="fas fa-trophy"/> Posición
														</div>
														<div className="cRNombre" key={key89}>
														<i className="fas fa-tag"/> Alumno
														</div>
														<div className="cRDato" key={key89}>
															<i className="fas fa-calculator"/> Puntuación
														</div>
														<div className="cRDato" key={key89}>
															<i style={{ color: 'green'}} className="fas fa-check-circle"/> Correctas
														</div>
														<div className="cRDato" key={key89}>
															<i style={{ color: 'red'}} className="fas fa-times-circle"/> Erroneas
														</div>
														<div className="cRDato" key={key89}>
															<i className="far fa-circle"/> En blanco
														</div>

														<div className="cRDato" key={key89}>
															Total Preguntas: {

																bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 ) && bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 ).length > 0 &&	bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 )[0].testJusticiaResultados.filter(t=> t.idTest == test.idTestJusticia).length > 0 ? (
																parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 )[0].testJusticiaResultados.filter(t=> t.idTest == test.idTestJusticia)[0].correctas) + parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 )[0].testJusticiaResultados.filter(t=> t.idTest == test.idTestJusticia)[0].erroneas) + parseInt(bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 )[0].testJusticiaResultados.filter(t=> t.idTest == test.idTestJusticia)[0].enBlanco)
															):('Nadie lo hizo')
															}
														</div>

												</div>
													{

														bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 ).length > 0 && bloqueTemas.alumnos.filter(a=> a.testJusticiaResultados.length > 0 ).map((tA, key4582) =>
															<div key={key4582}>

																{tA.testJusticiaResultados.filter(to => to.idTest == test.idTestJusticia).length > 0 && tA.testJusticiaResultados.filter(to => to.idTest == test.idTestJusticia).sort((a, b) => b.puntuacion - a.puntuacion).map((infoTest, key4581) =>
																<div className="celdaRanking" key={key4581}>

																		<div className="cRPosicion" key={key4581}>
																			<i className="fas fa-trophy"/> {parseInt(key4581+1)}
																		</div>
																		<div className="cRNombre" key={key4581}>
																		<i className="fas fa-tag"/>{tA.nombreAlumno +' '+tA.apellidosAlumno.split(' ')[0]}
																		</div>
																		<div className="cRPuntuacion" key={key4581}>
																			<i className="fas fa-calculator"/> {infoTest.puntuacion}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i style={{ color: 'green'}} className="fas fa-check-circle"/> {infoTest.correctas}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i style={{ color: 'red'}} className="fas fa-times-circle"/> {infoTest.erroneas}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i className="far fa-circle"/> {infoTest.enBlanco}
																		</div>

																</div>
															)}


														</div>
														)

													}
												</div>
											</div>
										</div>


								</div>
									)

								):null}

								{bloqueTemas && bloqueTemas.casoPractico != 'No asignado' ? (
										<div className="item">
											<div className="titleItem">
												<i className="fas fa-info-circle"></i>
												Ranking Caso Práctico
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="bodyRanking">
												<div className="celdaRankingLeyenda">


														<div className="cRPosicion">
															<i className="fas fa-trophy"/> Posición
														</div>
														<div className="cRNombre">
														<i className="fas fa-tag"/> Alumno
														</div>
														<div className="cRDato">
															<i className="fas fa-calculator"/> Puntuación
														</div>
														<div className="cRDato">
															<i style={{ color: 'green'}} className="fas fa-check-circle"/> Correctas
														</div>
														<div className="cRDato">
															<i style={{ color: 'red'}} className="fas fa-times-circle"/> Erroneas
														</div>
														<div className="cRDato">
															<i className="far fa-circle"/> En blanco
														</div>
														<div className="cRDato">
															Total Preguntas: {
																	bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion) && bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion).length > 0 ? (
																parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion)[0].casoPracticoRealizado.correctas) + parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion)[0].casoPracticoRealizado.erroneas) + parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion)[0].casoPracticoRealizado.enBlanco)
															):('Nadie lo hizo')
															}
														</div>

												</div>
													{bloqueTemas.alumnos.filter(a=> a.casoPracticoRealizado != undefined).sort((a, b) => b.casoPracticoRealizado.puntuacion - a.casoPracticoRealizado.puntuacion).map((alumno, key458) =>
														<div className="celdaRanking" key={key458}>


																<div className="cRPosicion">
																	<i className="fas fa-trophy"/> {parseInt(key458+1)}
																</div>
																<div className="cRNombre">
																<i className="fas fa-tag"/>{alumno.nombreAlumno +' '+alumno.apellidosAlumno.split(' ')[0]}
																</div>
																<div className="cRPuntuacion">
																	<i className="fas fa-calculator"/> {alumno.casoPracticoRealizado.puntuacion}
																</div>
																<div className="cRDato">
																	<i style={{ color: 'green'}} className="fas fa-check-circle"/> {alumno.casoPracticoRealizado.correctas}
																</div>
																<div className="cRDato">
																	<i style={{ color: 'red'}} className="fas fa-times-circle"/> {alumno.casoPracticoRealizado.erroneas}
																</div>
																<div className="cRDato">
																	<i className="far fa-circle"/> {alumno.casoPracticoRealizado.enBlanco}
																</div>

														</div>
													)}
												</div>
											</div>
										</div>

								):null}

								{bloqueTemas && bloqueTemas.casoPractico != 'No asignado' ? (
									<div className="item">
										<div className="titleItem">
											<i className="fas fa-info-circle"></i>
											Caso Práctico
											<i className="fas fa-clipboard-check"></i>
										</div>
										<div className="bodyItem">
											<div className="celdaInfoBody">
												<div className="testAsignado">
													<i className="fas fa-clipboard-check"></i>
													{bloqueTemas.casoPractico}
												</div>
											</div>
											<div>

												<BotonMedio id={'verCasoPracticoExtra-'+key} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Caso Práctico"} onClick={this.handleOnClick}/>

											</div>

										</div>
									</div>
								):null}

								{bloqueTemas && bloqueTemas.casosPractico != undefined && bloqueTemas.casosPractico.length > 0 ? (
									bloqueTemas.casosPractico.map((test, key89)=>
									<div className="itemDoble" key={key89}>
										<div className="item">
											<div className="titleItem">
												<i className="fas fa-info-circle"></i>
												Caso Práctico
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="celdaInfoBody">
													<div className="testAsignado">
														<i className="fas fa-clipboard-check"></i>
														{'nombre'}
													</div>
												</div>
												<div>

													<BotonMedio id={'verCasoPractic-'+key+'-'+key89} icon1={"fas fa-check-double"} icon2={"fas fa-external-link-alt"} texto={"Ver Caso Pra."} onClick={this.handleOnClick}/>
													<BotonMedio id={'verRankingcp-'+key+'-'+key89} icon1={"fas fa-eye"} icon2={"fas fa-trophy"} texto={"Ver Ranking"} onClick={this.handleOnClick}/>

												</div>

											</div>
										</div>


										<div className="item" style={{display: 'none'}} id={'rankingcp-'+key+'-'+key89}>
											<div className="titleItem">
												<i className="fas fa-info-circle"></i>
												Ranking Caso Práctico
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="bodyRanking">
												<div className="celdaRankingLeyenda">


														<div className="cRPosicion" key={key89}>
															<i className="fas fa-trophy"/> Posición
														</div>
														<div className="cRNombre" key={key89}>
														<i className="fas fa-tag"/> Alumno
														</div>
														<div className="cRDato" key={key89}>
															<i className="fas fa-calculator"/> Puntuación
														</div>
														<div className="cRDato" key={key89}>
															<i style={{ color: 'green'}} className="fas fa-check-circle"/> Correctas
														</div>
														<div className="cRDato" key={key89}>
															<i style={{ color: 'red'}} className="fas fa-times-circle"/> Erroneas
														</div>
														<div className="cRDato" key={key89}>
															<i className="far fa-circle"/> En blanco
														</div>

														<div className="cRDato" key={key89}>
															Total Preguntas: {

																bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 ) && bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 ).length > 0 &&	bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 )[0].casoPracticoResultados.filter(t=> t.idTest == test.idCasoPractico).length > 0 ? (
																parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 )[0].casoPracticoResultados.filter(t=> t.idTest == test.idCasoPractico)[0].correctas) + parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 )[0].casoPracticoResultados.filter(t=> t.idTest == test.idCasoPractico)[0].erroneas) + parseInt(bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 )[0].casoPracticoResultados.filter(t=> t.idTest == test.idCasoPractico)[0].enBlanco)
															):('Nadie lo hizo')
															}
														</div>

												</div>
													{

														bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 ).length > 0 && bloqueTemas.alumnos.filter(a=> a.casoPracticoResultados.length > 0 ).map((tA, key4582) =>
															<div key={key4582}>

																{tA.casoPracticoResultados.filter(to => to.idTest == test.idCasoPractico).length > 0 && tA.casoPracticoResultados.filter(to => to.idTest == test.idCasoPractico).sort((a, b) => b.puntuacion - a.puntuacion).map((infoTest, key4581) =>
																<div className="celdaRanking" key={key4581}>

																		<div className="cRPosicion" key={key4581}>
																			<i className="fas fa-trophy"/> {parseInt(key4581+1)}
																		</div>
																		<div className="cRNombre" key={key4581}>
																		<i className="fas fa-tag"/>{tA.nombreAlumno +' '+tA.apellidosAlumno.split(' ')[0]}
																		</div>
																		<div className="cRPuntuacion" key={key4581}>
																			<i className="fas fa-calculator"/> {infoTest.puntuacion}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i style={{ color: 'green'}} className="fas fa-check-circle"/> {infoTest.correctas}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i style={{ color: 'red'}} className="fas fa-times-circle"/> {infoTest.erroneas}
																		</div>
																		<div className="cRDato" key={key4581}>
																			<i className="far fa-circle"/> {infoTest.enBlanco}
																		</div>

																</div>
															)}


														</div>
														)

													}
												</div>
											</div>
										</div>


								</div>
									)

								):null}



								</div>

									<div className="displayItems">
									{bloqueTemas && bloqueTemas.idWordTestPDF != undefined && bloqueTemas.idWordTestPDF != 0 ? (
										<div className="item">
											<div className="titleItem">
												<i className="fas fa-file-word"></i>
												Test Word
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="celdaInfoBody">
													<div className="testAsignado">
														<i className="fas fa-clipboard-check"></i>
														{
															wordTest && wordTest.find(t => t.id == bloqueTemas.idWordTestPDF) && wordTest.find(t => t.id == bloqueTemas.idWordTestPDF).nombre
														}
													</div>
												</div>
												<div>

													<BotonMedio title={'Descargar Test Word 2010'} id={'descargarPdfTestExamen-'+key} icon1={"fas fa-check-double"} icon2={"fas fa-file-pdf"} texto={'Descargar Test'} onClick={this.downloadPdf}/>

													<BotonMedio title={'Respuestas del Test'} id={'descargarPdfTestRes-'+key} icon1={"fas fa-check-double"} icon2={"fas fa-file-pdf"} texto={'Descargar Respuestas'} onClick={this.downloadPdf}/>

												</div>

											</div>
										</div>
									):null}

									{bloqueTemas && bloqueTemas.idExamenGestion != undefined && bloqueTemas.idExamenGestion != 0 ? (
										<div className="item">
											<div className="titleItem">
												<i className="fas fa-file-pdf"></i>
												Examen Gestión
												<i className="fas fa-clipboard-check"></i>
											</div>
											<div className="bodyItem">
												<div className="celdaInfoBody">
													<div className="testAsignado">
														<i className="fas fa-clipboard-check"></i>
														{
															examGest && examGest.find(t => t.id == bloqueTemas.idExamenGestion) && examGest.find(t => t.id == bloqueTemas.idExamenGestion).nombre
														}
													</div>
												</div>
												<div>

													<BotonMedio title={'Descargar Examen'} id={'descargarPdfExaGes-'+key} icon1={"fas fa-check-double"} icon2={"fas fa-file-pdf"} texto={'Descargar Examen'} onClick={this.downloadPdf}/>


												</div>

											</div>
										</div>
									):null}

									</div>

							</div>
					</div>

				)}

				</div>

			</div>
		);
	}
}

export default ClaseVirtualPreparador;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import EditorTexto from '../../Inputs/EditorTexto';
import TextoRich from '../../Inputs/TextoRich';
import Submit from '../../Inputs/Submit';
import InputFile from '../../Inputs/InputFile';

class AddNoticia extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
		this.state = {
			textHtml: undefined
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e, textHtml = ''){
		if(textHtml != ''){
			this.setState({textHtml: textHtml})
			// guardarTexto en BD!
			this.props.onChange(e, textHtml);
		} else {
			this.props.onChange(e);
		}
	}
	handleOnClick(e){
		this.props.onClick(e);
	}

	render() {
		const { textHtml } = this.state;
		return (
			<div className= "bodyLoadTest">

					<div className="inputLoadTest22">
						<h4>
							<i className="fas fa-info-circle"></i>
							Titulo Noticia
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<InputTextArea id="titulo" name="titulo" valueDefault="¡Pegar test completo aquí!" width="100%" height="5vh" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest22">
						<h4>
							<i className="fas fa-info-circle"></i>
							Resumen Noticia
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<InputTextArea id="noticiaResumen" name="noticiaResumen" valueDefault="¡Pegar test completo aquí!" width="100%" height="10vh" onChange={this.onChange}/>
					</div>
					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Imagen Tablon
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<InputFile texto="Foto tablon" name="fotoTablon" icon1="fas fa-camera" onChange={this.onChange}/>
					</div>
					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Imagen Noticia
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<InputFile texto="Foto Noticia" name="fotoNoticia" icon1="fas fa-camera" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Noticia Completa
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<EditorTexto dataInfoTextHtml="<p>¡Bienvenido al editor de texto de CEJ APP!</p>" onChange={this.onChange}/>
					</div>



				<div className="textAreaLoadTest">
					<Submit id="InsertarNoticia" icon1="fas fa-upload" icon2="fas fa-database" texto="Añadir Noticia" onClick={this.handleOnClick}/>
				</div>

			</div>
		);
	}
}

export default AddNoticia;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Assets
import './css/inputIntervalo.css';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions

class InputIntervalo extends Component {

  static propTypes = {
    num: PropTypes.string.isRequired,
    numIntervalos: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {};
          this.onChangeIntervalo = this.onChangeIntervalo.bind(this);
    }

    onChangeIntervalo(e){
        this.props.onChange(e);
    }

    componentDidMount(){
      fetch('https://oposiciones-justicia.es/api/leyes/consulta')
        .then(res => res.json())
          .then(leyes => this.setState({
              busquedaLeyes: leyes
          })).catch(error => console.log('Obejet fecth failed', error));
    };
  render() {
    const { num, numIntervalos } = this.props;
    const { busquedaLeyes } = this.state
    if(numIntervalos > 0){
              return (
                  <div className="intervaloTemasAdm">
                      <div className="inputWithImgInterTA">
                          <input id={num} type="text" className="textInputInterLey" list="busquedaLey" placeholder="Siglas Ley" name='Ley' ref='Ley' onChange={this.onChangeIntervalo}/>
                          <i className="fas fa-search"></i>
                      </div>
                      <div className="inputWithImgInterTA">
                          <input id={num} type="text" className="textInputInter" placeholder="Art. Inicio" name='Articulo_inc' ref='Articulo_inc' onChange={this.onChangeIntervalo}/>
                            <i className="fas fa-chevron-circle-right"></i>
                      </div>
                      <div className="inputWithImgInterTA">
                          <input id={num} type="text" className="textInputInter" placeholder="Art. Fin" name='Articulo_fin' ref='Articulo_fin' onChange={this.onChangeIntervalo}/>
                            <i className="fas fa-chevron-circle-right"></i>
                      </div>
                      <datalist id="busquedaLey">{
                          busquedaLeyes && busquedaLeyes.map((ley, key) =>
                          <option key={key} value={ley.siglas_ley}>{ley.nombre_ley}</option>
                      )}</datalist>
                  </div>
              );
        }else{
          return (<div> </div>);
        }
        }
 }
// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default InputIntervalo;

import React, { Component } from 'react';
import './css/Menu.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { menuChangeVisibilidad, loadCurso, loadClase, loadTemasFacturados, loadAllSemFac, loadSemanaPrg, loadCategoEsc, loadTemaEsc, loadTemaAula, loadSemanasCurso } from '../../../actions/userAction';
import swal from 'sweetalert';

import Boton from '../../Inputs/Boton';

class Menu extends Component {
  static propTypes = {
    itemsMenu: PropTypes.array.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor() {
    super();
    this.state = {
      linkOpen: undefined,
      verTuto: null
    }

    this.hanldeClicMenu = this.hanldeClicMenu.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }



  async handleOnSubmit(e) {
    if (e.target.id == 'changeClase') {
      this.props.loadCurso(null, null, null, null)
      this.props.loadClase(null, true, false)
      this.props.loadTemasFacturados(null)
      this.props.loadAllSemFac(null)
      this.props.loadSemanaPrg(null)
      this.props.loadTemaAula(null)
      this.props.loadSemanasCurso(null)
      this.props.loadCategoEsc(null)
      this.props.loadTemaEsc(null)

      $('.cuerpoEscritorio').css("align-self", "center")
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
    } else if (e.target.id == 'semanaCargar') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/aulaVirtual') : ('https://oposiciones-justicia.es/aulaVirtual')
    } else if (e.target.id == 'goTestJusticia') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/hacerTestJusticia') : ('https://oposiciones-justicia.es/hacerTestJusticia')
    } else if (e.target.id == 'goTestCaso') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/casoPracticoJusticia') : ('https://oposiciones-justicia.es/casoPracticoJusticia')
    } else if (e.target.id == 'goVerTestTeoria') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/revisionTest') : ('https://oposiciones-justicia.es/revisionTest')
    } else if (e.target.id == 'goVerTestPractico') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/revisionTestPractico') : ('https://oposiciones-justicia.es/revisionTestPractico')
    } else if (e.target.id == 'goEscritorio') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
    } else if (e.target.id == 'verEltuto') {

      this.setState({ verTuto: this.state.verTuto == null || this.state.verTuto == false ? (true) : (false) })
    } else if (e.target.id == 'diccionarioJuridico') {
      window.open('https://dpej.rae.es', '_blank')

    }
  }
  hanldeClicMenu(e) {

    (!$("#menu").hasClass('menu-show')) ? null : $('.c_menu').toggleClass("menu-show");

    let pathRoute = e.target.textContent
    this.setState({ linkOpen: pathRoute })


  }

  componentDidMount() {
    let pathRoute = window.location.pathname.split('/')
    this.setState({ linkOpen: pathRoute[pathRoute.length - 1] });
  }

  render() {
    const { itemsMenu, semanaPrg, testJusticiaUserLoad, casoPracticoUserLoad, verTestUserJusticia, verCasoPUser, temaAulaLoad, temasFacturados } = this.props
    const { linkOpen, verTuto } = this.state


    let itemsMenuPermisos = itemsMenu.filter(item => item.nivelAcesso == this.props.tipoUser || this.props.tipoUser >= item.nivelAcessoSuperiorAeste);

    return (
      <div id="menu" className="c_menu">

        <div className="logoMenuEd" style={{ cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
          <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
        </div>

        <div className="cuerpoMenu">

          {
            itemsMenuPermisos && itemsMenuPermisos.map((item, key) =>
              linkOpen && linkOpen == item.url.split('/')[item.url.split('/').length - 1] || linkOpen == item.title ? (
                <Link to={item.url} key={key} title={item.title} onClick={this.hanldeClicMenu}>
                  <div className="c_line" style={{ color: '#3669b6' }}>
                    <div className="c_icon"><i className={item.fontawesome}></i></div>
                    <div id="linkMenu" className="c_text" >{item.title}</div>
                  </div>
                </Link>
              ) : (
                <Link to={item.url} key={key} title={item.title} onClick={this.hanldeClicMenu}>
                  <div className="c_line" >
                    <div className="c_icon"><i className={item.fontawesome}></i></div>
                    <div id="linkMenu" className="c_text" >{item.title}</div>
                  </div>
                </Link>
              )
            )
          }

          <div className="c_line" id="diccionarioJuridico" onClick={this.handleOnSubmit}>
            <div className="c_icon" id="diccionarioJuridico"><i id="diccionarioJuridico" className="fa-light fa-spell-check"></i></div>
            <div id="diccionarioJuridico" className="c_text" style={{ fontSize: '14px', fontWeight: '400' }}>Diccionario jurídico</div>
          </div>
          {semanaPrg && semanaPrg.length > 0 || testJusticiaUserLoad && testJusticiaUserLoad.length > 0 || casoPracticoUserLoad && casoPracticoUserLoad.length > 0 || verTestUserJusticia && verTestUserJusticia.length > 0 || verCasoPUser && verCasoPUser.length > 0 || temaAulaLoad != null ? (
            <div className="menuRecienteVisto">

              <p style={{ width: '100%', borderBottom: '2px solid #F1F1F1', textAlign: 'center', paddingTop: '12px', marginBottom: '10px' }}>Recientemente</p>

              {semanaPrg && semanaPrg.length > 0 ? (
                <div id="semanaCargar" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="semanaCargar" className="c_icon"><i id="semanaCargar" className={'fa-light fa-chalkboard-user'}></i></div>
                  <div id="semanaCargar" style={{ whiteSpace: 'unset' }} className="c_text" >
                    <p id="semanaCargar">Clase {semanaPrg[0].numeroSemanaClase}</p>
                  </div>
                </div>
              ) : null}

              {temaAulaLoad != null && temasFacturados != null && temasFacturados.length > 0 ? (
                <div id="semanaCargar" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="semanaCargar" className="c_icon"><i id="semanaCargar" className={'fa-light fa-chalkboard-user'}></i></div>
                  <div id="semanaCargar" style={{ whiteSpace: 'unset' }} className="c_text" >
                    <p id="semanaCargar">{temasFacturados.find(t => t.idTema == temaAulaLoad).titulo_tema}</p>
                  </div>
                </div>
              ) : null}

              {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 ? (
                <div id="goTestJusticia" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="goTestJusticia" className="c_icon"><i id="goTestJusticia" className={'fa-light fa-octagon-exclamation'}></i></div>
                  <div id="goTestJusticia" style={{ whiteSpace: 'unset' }} className="c_text" ><p id="goTestJusticia">Test teoría pendiente</p></div>
                </div>
              ) : null}

              {casoPracticoUserLoad && casoPracticoUserLoad.length > 0 ? (
                <div id="goTestCaso" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="goTestCaso" className="c_icon"><i id="goTestCaso" className={'fa-light fa-octagon-exclamation'}></i></div>
                  <div id="goTestCaso" style={{ whiteSpace: 'unset' }} className="c_text" ><p id="goTestCaso">Test práctico pendiente</p></div>
                </div>
              ) : null}

              {verTestUserJusticia && verTestUserJusticia.length > 0 ? (
                <div id="goVerTestTeoria" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="goVerTestTeoria" className="c_icon"><i id="goVerTestTeoria" className={'fa-light fa-book-open-reader'}></i></div>
                  <div id="goVerTestTeoria" style={{ whiteSpace: 'unset' }} className="c_text" ><p id="goVerTestTeoria">Test teoría ver</p></div>
                </div>
              ) : null}
              {verCasoPUser && verCasoPUser.length > 0 ? (
                <div id="goVerTestPractico" className="c_line" onClick={this.handleOnSubmit}>
                  <div id="goVerTestPractico" className="c_icon"><i id="goVerTestPractico" className={'fa-light fa-book-open-reader'}></i></div>
                  <div id="goVerTestPractico" style={{ whiteSpace: 'unset' }} className="c_text" ><p id="goVerTestPractico">Test práctico ver</p></div>
                </div>
              ) : null}


            </div>
          ) : null}

        </div>

        {this.props.cursoPredeterminado && this.props.cursoPredeterminado != null ? (
          <div className="menuChangeClase">

            <div style={{ color: '#3469B7', marginTop: '10px' }}>
              <b>{this.props.cursoPredeterminado}</b>
            </div>

            <div style={{ width: '140px', marginTop: '10px' }}>
              <Boton id="changeClase" icon1="" icon2="" texto="Cambiar Curso" onClick={this.handleOnSubmit} />
            </div>
          </div>
        ) : null}

        <div className="menuChangeClase" style={{ marginBottom: '40px' }}>

          <div style={{ color: '#3469B7', marginTop: '10px' }}>
            <b>¿Dudas con la web?</b>
          </div>

          <div style={{ width: '140px', marginTop: '10px' }}>
            <Boton id="verEltuto" icon1="" icon2="" texto={verTuto != null && verTuto == true ? ("Cerrar tutorial") : ("Ver tutorial")} onClick={this.handleOnSubmit} />
          </div>
        </div>
        {

          <div className={verTuto != null && verTuto == true ? ("tutorialVideoAtopePower tutorialVideoAtopePowerAnimate") : (verTuto != null && verTuto == false ? ("tutorialVideoAtopePower closeTutorial") : ("tutorialVideoAtopePower")

          )}>
            {verTuto != null && verTuto == true ? (
              <div className="botonsicoOff animationNover" style={{ width: '140px', position: 'absolute', top: '25px', right: '25px', zIndex: '1999' }}>
                <Boton id="verEltuto" icon1="" icon2="" texto={verTuto != null && verTuto == true ? ("Cerrar tutorial") : ("Ver tutorial")} onClick={this.handleOnSubmit} />
              </div>
            ) : null}



            <div className="recPlayVidDiv2" style={{
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1000px'
            }} onContextMenu={e => e.preventDefault()}>
              <ReactPlayer
                url={'../videos/App Web CEJ - Optimizado.mp4'}
                className='react-player'
                playing={false}
                width='100%'
                height='100%'
                controls={true}
                onContextMenu={e => e.preventDefault()}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              />
            </div>
          </div>

        }
      </div>
    );
  }
}
// Connect Redux
function mapStateToProps(state) {
  return {
    tipoUser: state.user.tipoUser,
    cursoPredeterminado: state.user.nombreClase,
    claseSelecionada: state.user.claseSeleted,
    semanaPrg: state.user.semanaPrg,
    testJusticiaUserLoad: state.user.testJusticiaUserLoad,
    casoPracticoUserLoad: state.user.casoPracticoUserLoad,
    verTestUserJusticia: state.user.verTestUserJusticia,
    verCasoPUser: state.user.verCasoPUser,
    temaAulaLoad: state.user.temaAulaLoad,
    temasFacturados: state.user.temasFacturados

  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    menuChangeVisibilidad,
    loadCurso,
    loadClase,
    loadTemasFacturados,
    loadAllSemFac,
    loadSemanaPrg,
    loadCategoEsc,
    loadTemaEsc,
    loadTemaAula,
    loadSemanasCurso

  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);

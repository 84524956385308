// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import './css/alumnos.css';

import BotonMedio from '../Botones/BotonMedio';

class Alumnos extends Component {
  static propTypes = {
    idClase: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      navS: 0
    };
    this.onToggleClick = this.onToggleClick.bind(this);
  }

  async onToggleClick(e) {

    if (e.target.id.split('-')[0] == 'nav') {

      this.setState({ navS: e.target.id.split('-')[1] })

    } else if (e.target.id.split('-')[0] == 'desactivar') {
      let key = e.target.id.split('-')[1]
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el alumno será desactivado de esta clase.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let alumnoSelected = this.state.alumnosClase.filter(a => a.activado == 1)[key]

            let data = {
              id_clase: this.props.idClase,
              id_usuario: alumnoSelected.idAlumno,
              activado: 0,
              activarTemas: null
            }

            let response = await fetch("https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              let data2 = { idClase: this.props.idClase }

              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClaseN", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              this.setState({ alumnosClase: cls2 })
              swal('Alumno desactivado', 'El alumno no podrá cargar esta clase o curso.', 'success')
            }
          } else {
            swal("¡Alumno no desactivado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'activar') {
      let key = e.target.id.split('-')[1]
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el alumno será activado en esta clase y podrá cargar el contenido.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let alumnoSelected = this.state.alumnosClase.filter(a => a.activado == 0)[key]

            let data = {
              id_clase: this.props.idClase,
              id_usuario: alumnoSelected.idAlumno,
              activado: 1,
              activarTemas: null
            }

            let response = await fetch("https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              let data2 = { idClase: this.props.idClase }

              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClaseN", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              this.setState({ alumnosClase: cls2 })
              swal('Alumno activado', 'El alumno ya puede ver el contenido de esta clase o curso.', 'success')
            }
          } else {
            swal("¡Alumno no activado!", '', "success");
          }
        });
    } else if (e.target.id.split('-')[0] == 'eliminar') {
      let key = e.target.id.split('-')[1]
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el alumno será eliminado de esta clase.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let alumnoSelected = this.state.alumnosClase.filter(a => a.activado == 0)[key]

            let data = {
              idClase: this.props.idClase,
              idAlumno: alumnoSelected.idAlumno
            }

            let response = await fetch("https://oposiciones-justicia.es/api/oficina/delAlumnoClase", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;


            if (cls.affectedRows > 0) {
              let data2 = { idClase: this.props.idClase }

              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClaseN", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              this.setState({ alumnosClase: cls2 })
              swal('Alumno eliminado', 'El alumno fue eliminado con exito de la clase o curso.', 'success')
            }
          } else {
            swal("¡Alumno no eliminado!", '', "success");
          }
        });
    }

  }

  async componentDidMount() {

    // cargamos los datos de la clase

    let data = { idClase: this.props.idClase }

    let response = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClaseN", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    let json = await response.json();
    let cls = await json;

    this.setState({ alumnosClase: cls })


  }
  render() {
    const { alumnosClase, navS } = this.state;
    return (
      <div className='ColCenterStart alumnosDBody'>



        <div className='ColCenterStart adb-nav'>
          <div> Alumnos </div>
          <div className='RowCenterCenter' style={{ margin: '10px' }}>
            <div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-0" onClick={this.onToggleClick}> Activos {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.activado == 1).length) : null} </div>
            <div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-1" onClick={this.onToggleClick}> No activos {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.activado == 0).length) : null}</div>
          </div>

        </div>

        {
          navS == 0 ? (
            <div className='RowCenterCenter grupoDeAlumnos'>
              {alumnosClase && alumnosClase.filter(a => a.activado == 1).map((alumnos, key) =>
                <div className="RowCenterCenter alumnoLoad-adb">
                  <div className="alumnoLoad-adb-aTfoto">
                    <img src={`../img/${alumnos.linkFotoPerfil}`} className="alumnoLoad-adb-imgATFoto" />
                  </div>
                  <div className="alumnoLoad-adb-name">
                    <p>{alumnos.nombreAlumno + ' ' + alumnos.apellidosAlumno}</p>
                  </div>
                  <i className='fa-duotone fa-person-circle-minus' id={"desactivar-" + key} onClick={this.onToggleClick} title="Desactivar alumno de la clase" />
                </div>
              )}
            </div>
          ) : null
        }

        {
          navS == 1 ? (
            <div className='RowCenterCenter grupoDeAlumnos'>
              {alumnosClase && alumnosClase.filter(a => a.activado == 0).map((alumnos, key) =>
                <div className="RowCenterCenter alumnoLoad-adb">
                  <div className="alumnoLoad-adb-aTfoto">
                    <img src={`../img/${alumnos.linkFotoPerfil}`} className="alumnoLoad-adb-imgATFoto" />
                  </div>
                  <div className="alumnoLoad-adb-name">
                    <p>{alumnos.nombreAlumno + ' ' + alumnos.apellidosAlumno}</p>
                  </div>
                  <i style={{ marginRight: '7px' }} className='fa-duotone fa-person-circle-plus' id={"activar-" + key} onClick={this.onToggleClick} title="Activar alumno en clase" />
                  <i className="fa-duotone fa-trash-can-xmark" id={"eliminar-" + key} onClick={this.onToggleClick} title="Eliminar alumno de esta clase" />
                </div>
              )}
            </div>
          ) : null
        }


      </div>

    );
  }
}
export default Alumnos;

// Actions
const CAMBIAR_PREG_JUSTICIA_EDT_CP = 'CAMBIAR_PREG_JUSTICIA_EDT_CP';
const ELIMINAR_PREG_JUSTICIA_EDT_CP = 'ELIMINAR_PREG_JUSTICIA_EDT_CP';

export function cambiarDatosPregJusticiaEdt(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_PREG_JUSTICIA_EDT_CP,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarPregJusticiaEdtCache() {
    return {
      type: ELIMINAR_PREG_JUSTICIA_EDT_CP
    };
}

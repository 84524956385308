// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Assets
import './css/TestJusticiaAlumnosVer.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico, openViewBusArt, addIdLeyBusArt, addArtBusArt } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
import { corregirTest, comprobarRespuestas, respuestaSbr } from '../../lib/utils/cargarTestJusticia';
// Components
import Input from '../../components/Inputs/Input';
import InputSelect from '../../components/Inputs/InputSelect';
import Submit from '../../components/Inputs/Submit';
import BotonMedio from '../../components/Inputs/BotonMedio';
import InputFile from '../../components/Inputs/InputFile';

import Boton from '../../components/Inputs/Boton';

import Dudas from '../../components/Dudas';

import swal from 'sweetalert';

class PreguntaTestVerTeoria extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    cronometro: PropTypes.object
  };
  constructor(props){
    super(props);
    this.state = {
        numPregTest: 1,
        displayRespondidas: false,
        displayEnDudas: false,
        displayVerNumeros: true,
        pregRespondidasArr: [],
        pregEnBlancoArr: [],
        pregDudosasArr: [],
        displayFinTest: true,
        stop: false,
        statusCrono: true,
        statusCasoPractico: false,
        inicioNumeracion: 0,
        finNumeracion: 10,

        pregAcertadasArr: undefined,
        pregErroresArr: undefined,
        pregEnBlancoArr: undefined,
        displayEnBlanco: false,
        displayAcertadas: false,
				displayErroneas: false,
        dudasView: false,
        reportesDuda: null,
        leyActive: false,
        leyActive2: false,
        plantearDuda: false,
        cargandoDudas: true
    }; 
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.actionTestUser = this.actionTestUser.bind(this);

    this.onChangeText = this.onChangeText.bind(this);
    this.viewArtC = this.viewArtC.bind(this);
  }
  viewArtC(e) {
    
    let preg = this.props.preguntaTestLoad

    this.props.openViewBusArt(null)
    this.props.addIdLeyBusArt(preg[0].idLey)
    this.props.addArtBusArt(preg[0].articulo)

    setTimeout(() => {
      this.props.openViewBusArt(true)
    }, 250);


  }
  onChangeText(e,editor){
    let textHtml = editor.getData();
    
    if(textHtml != ''){
      this.setState({textHtmlDudaAlumno: textHtml})
    }
    
  }

 

  
  async actionTestUser(e){
    if(e.target.id == 'verCasoPractico'){
      this.setState({statusCasoPractico: true})
    } else if(e.target.id == 'cerrarCasoPractico'){
      this.setState({statusCasoPractico: false})
    } else if(e.target.id == 'leyClick'){
      let val = this.state.leyActive
      this.setState({leyActive: !this.state.leyActive})

      if(val == false  && this.state.leyActive2 == false){
        this.setState({leyActive2: true})
        setTimeout(
          function() {
              if(this.state.leyActive == true){

                this.setState({leyActive: false, leyActive2: false})
              }
          }.bind(this),10000);
      }
      
    } else if (e.target.id == 'plantearDuda'){
      this.setState({plantearDuda: true})
    } else if (e.target.id == 'cerrarPlantearDuda'){
      this.setState({plantearDuda: false, textHtmlDudaAlumno: null })
    } else if (e.target.id == 'enviarDuda'){
      let infoPreg = this.props.preguntaTestLoad[0]
      let textAlumno = this.state.textHtmlDudaAlumno
      if (isDefined(textAlumno) && textAlumno != ''){
        let data = { 
          textoExplicacion: textAlumno, 
          idPreg: infoPreg.idPreg, 
          idR1: infoPreg.respuestas[0].idR, 
          idR2: infoPreg.respuestas[1].idR, 
          idR3: infoPreg.respuestas[2].idR, 
          idR4: infoPreg.respuestas[3].idR, 
          fecha: moment().format('YYYY-MM-DD HH:mm'), 
          idUser: this.props.idUser 
        }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportPreguntaJusticia", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        if(cls1.affectedRows > 0){
          
          swal("Duda enviada",'La resolveremos lo antes posible.','success');
         
          let data2 = { 
          idUserRecibe: 15,
          idUserEnvia: this.props.idUser,
          fecha: moment().format('YYYY-MM-DD HH:mm'),
          titulo: 'Acaban de plantear una duda - Test Teórico',
          notificacionTexto: 'Se acaba detectar el envio de una duda. '+ moment().format('DD-MM-YY HH:mm')+'.',
          tipoPreparadores: 1,
          tipoOficina: 0,
          tipoDuda: 1,
          tipoUrgente: 0,
          tipoReformasLey: 0,
          idDuda: cls1.insertId
          }
          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
          let json2 = await response2.json();
          let cls2 = await json2;
          this.setState({dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true})
          
         }

      } else {
        swal('Error falta texto', 'Describa su duda en el area de texto','error')
      }
      
    }
  }
 
  async handleOnSubmit(e){
    if (e.target.id == 'infoPuntuacion'){
      try{
        let test = this.props.verTestUserJusticia
        let data = { idClase: test[0].idClase}
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        let restamos = await cls1[0].malJusticia;
        let restamosBlanco = await cls1[0].restaEnBlanco;
        let totalPreg = await cls1[0].totalPregRegla;
        let aciertoCP = await cls1[0].aciertoSuma;

        let restaMal = parseFloat(parseFloat(totalPreg/test.length)*restamos).toFixed(2)
        let restaEnBlanco = parseFloat(parseFloat(totalPreg/test.length)*restamosBlanco).toFixed(2)
        let sumaBien = parseFloat(parseFloat(totalPreg/test.length)*aciertoCP).toFixed(2)
        totalPreg = await cls1[0].totalPregRegla;
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoClsMinAprobar", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
        let json2 = await response2.json();
        let cls2 = await json2;
        let porcentajeAprobado = await cls2[0].minJusticiaTest

        let name = "Test "+test.length+" preguntas - Puntuación";
        let content = document.createElement('div');
        content.innerHTML = `
        <strong>Acierto: +`+sumaBien+`</strong></br>
        <strong> Erroneas: -`+restaMal+`</strong></br>
        <strong>En Blanco: -`+restaEnBlanco+`</strong></br></br>
        <strong>Puntuación mínima: `+(totalPreg*(porcentajeAprobado/100)).toFixed(2)+` Puntos</strong> de 100</br>
        
        Necesita `+(test.length*(porcentajeAprobado/100)).toFixed(2)+` preguntas correctas y ninguna erronea.
        </br></br></br>
        La puntuación se calcula proporcial a un test con `+totalPreg+` preguntas. Acierto: +1, Erroneas: -`+restamos.toFixed(2)+` y En Blanco: -`+restamosBlanco.toFixed(2)+`.`
        content.style.textAlign = "left";
        swal({
            title: name,
            content: content,
            icon: "info",
        })
      } catch(e){console.log(e)}
    }


  }
  
  async componentDidMount(){
    
    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
  		.then(res => res.json())
  			.then(leyes => this.setState({
  					busquedaLeyes: leyes
  	})).catch(error => console.log('Obejet fecth failed', error))

  }

  render() {

    const { isLogged, nombreUser, tipoUser, preguntaTestLoad, notificacion  } = this.props; // verTestUserJusticia
    const { dudasView, leyActive, reportesDuda, plantearDuda, cargandoDudas } = this.state;
    const nivelRequired = 1;
    if(isLogged && (nivelRequired == tipoUser || tipoUser >= 1) ){
     
     
        return (
          

              preguntaTestLoad && preguntaTestLoad.length > 0 ? (
                <div id="bodyHacerTest" className="bodyHacerTest">

                  <div className="BHT-contentTest">
                  
                    {preguntaTestLoad && preguntaTestLoad.map((preg, key) =>
    
                    <div className="BHT-CT-pregTestContent" >
                      
                        
                      <div className="BHT-CT-pregTest">
                        
                        <div className="BHT-CT-PT-numP">
                          <div className="numPregTv">
                            {/* Pregunta {+' '+preg.numPreg}  */}
                            {'ID #'+preg.idPreg } 
                          </div>
                          
                          
                          <div className="BHT-CT-PT-numP-settings"> 
                            <div className="BHT-CT-PT-numP-settings-art" onClick={this.actionTestUser}>
                              <p id="leyClick">Ley</p>
                              <div id="leyClick"className="BHT-CT-PT-numP-settings-art-ley" title={preg.nombreLey}>
                                {
                                  leyActive && leyActive == true ? (preg.nombreLey):(preg.siglasLey)
                                }
                              </div>
                            </div>
                            <div className="BHT-CT-PT-numP-settings-art" onClick={this.viewArtC}>
                              <p>Artículo</p>
                              <div className="BHT-CT-PT-numP-settings-art-numArt">
                                {preg.articulo}
                              </div>
                                
                                
                            </div>
                            <div className="BHT-CT-PT-numP-infoTest" style={{display: 'none'}}>
                              <i className="fas fa-info-circle" title="Info Puntuación del Test" onClick={this.handleOnSubmit} id="infoPuntuacion"></i>
                            </div>
                            
                          
                          </div>
                        </div>
                        <div className="BHT-CT-PT-enunciado">
                          {preg.pregunta}
                        </div>
    
                        <div className="BHT-CT-PT-respuesta2">
                          <div className="BHT-CT-PT-letra" style={
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 1 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 5 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 6 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 9 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 11 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 12 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 15 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 17 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 20 ||
                              preg.respuestas.find(r=> r.idR == preg.idR1).validez == 24 ? 
                              ({color: '#49d6ab'}):({})
                          }>
                              A
                          </div>
                          <div className="BHT-CT-PT-contenido">
                            {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR1).respuesta)}
                          </div>
                        </div>
                        { preg.respuestas.find(r=> r.idR == preg.idR1).nota && preg.respuestas.find(r=> r.idR == preg.idR1).nota != "" ? (
                          <div className="BHT-CT-PT-respuestaNota">
                            <div className="BHT-CT-PT-letra">
                              <i className="fas fa-level-up-alt"/>
                            </div>
                            <div className="BHT-CT-PT-contenido">
                              {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR1).nota)}
                            </div>
                          </div>
                        ):null }
                        

                        <div className="BHT-CT-PT-respuesta2">
                          <div className="BHT-CT-PT-letra" 
                            style={
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 1 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 5 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 6 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 9 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 11 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 12 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 15 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 17 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 20 ||
                              preg.respuestas.find(r=> r.idR == preg.idR2).validez == 24 ? 
                              ({color: '#49d6ab'}):({})
                          }
                          > 
                              B
                          </div>
                          <div className="BHT-CT-PT-contenido" >
                            { ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR2).respuesta) }
                          </div>
                        </div>
                        { preg.respuestas.find(r=> r.idR == preg.idR2).nota && preg.respuestas.find(r=> r.idR == preg.idR2).nota != "" ? (
                          <div className="BHT-CT-PT-respuestaNota">
                            <div className="BHT-CT-PT-letra">
                              <i className="fas fa-level-up-alt"/>
                            </div>
                            <div className="BHT-CT-PT-contenido">
                              {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR2).nota)}
                            </div>
                          </div>
                        ):null }


                        <div className="BHT-CT-PT-respuesta2">
                          <div className="BHT-CT-PT-letra" 
                            style={
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 1 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 5 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 6 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 9 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 11 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 12 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 15 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 17 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 20 ||
                              preg.respuestas.find(r=> r.idR == preg.idR3).validez == 24 ? 
                              ({color: '#49d6ab'}):({})
                          }
                          >
                              C
                          </div>
                          <div className="BHT-CT-PT-contenido">
                            {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR3).respuesta)}
                          </div>
                        </div>
                        { preg.respuestas.find(r=> r.idR == preg.idR3).nota && preg.respuestas.find(r=> r.idR == preg.idR3).nota != "" ? (
                          <div className="BHT-CT-PT-respuestaNota">
                            <div className="BHT-CT-PT-letra">
                              <i className="fas fa-level-up-alt"/>
                            </div>
                            <div className="BHT-CT-PT-contenido">
                              {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR3).nota)}
                            </div>
                          </div>
                        ):null }

                        <div className="BHT-CT-PT-respuesta2" >
                          <div className="BHT-CT-PT-letra"  
                            style={
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 1 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 5 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 6 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 9 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 11 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 12 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 15 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 17 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 20 ||
                              preg.respuestas.find(r=> r.idR == preg.idR4).validez == 24 ? 
                              ({color: '#49d6ab'}):({})
                          }
                          >
                              D
                          </div>
                          <div className="BHT-CT-PT-contenido">
                            {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR4).respuesta)}
                          </div>
                        </div>
                        { preg.respuestas.find(r=> r.idR == preg.idR4).nota && preg.respuestas.find(r=> r.idR == preg.idR4).nota != "" ? (
                          <div className="BHT-CT-PT-respuestaNota">
                            <div className="BHT-CT-PT-letra">
                              <i className="fas fa-level-up-alt"/>
                            </div>
                            <div className="BHT-CT-PT-contenido">
                              {ReactHtmlParser(preg.respuestas.find(r=> r.idR == preg.idR4).nota)}
                            </div>
                          </div>
                        ):null }
    
                      </div>
                      
                    </div>
                    
                    )}
                  </div>
                  
                
                  
                    <div style={{width: '100%'}}>
                      
                    {tipoUser && notificacion != null || tipoUser == 58 || tipoUser >= 98 ? null:(
                        <div className="dudaPlantearBody">
                          <div className="DPB-imgBoton">

                            <img src={'../img/woman1-2.png'}/>
                            <div className="DPB-info">
                              {notificacion && notificacion == true ? (
                                <p>
                                Si no hemos resuelto su duda o le ha surgido otra duda, puede plantearnos de nuevo una duda.
                              </p>
                              ):(
                                <p>
                                Si no ha encontrado su duda resuelta o pendiente, entre las ya existentes, puede plantearnos su duda.
                              </p>
                              )}
                              
                              <div className="DPB-boton">
                                <Boton id="plantearDuda" icon1="" icon2="" texto="Plantear duda" onClick={this.actionTestUser}/>
                              </div>
                              
                            </div>

                          </div>
                            
                        </div>
                    )}
                      
                      { plantearDuda && plantearDuda == true ? (
                        <div className="plantearDudaPreg2">
                          <div style={{padding: '10px', lineHeight: '1.7'}}>
                            ¿En que podemos ayudarte? <br/>
                            <b>Plantee su duda y será resuelta lo antes posible.</b>
                          </div>
                          <div className="editorPlantearrDuda">
                            <CKEditor
                              editor = { ClassicEditor }
                              data={''}
                              onInit={ editor => {} }
                              config={ {
                                toolbar: [ 'bold', '|' ,'link', '|', 'undo', 'redo' ]
                                // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                                } }  
                              onChange={this.onChangeText}
                            />
                          </div>
                          <div className="editorPlantearrDudaBotones">
                            <div className="epdb-boton">
                              <Boton id="cerrarPlantearDuda" icon1="" icon2="" texto="Cancelar" onClick={this.actionTestUser}/>
                            </div>
                            <div className="epdb-boton">
                              <Boton id="enviarDuda" icon1="" icon2="" texto="Enviar duda" onClick={this.actionTestUser}/>
                            </div>
                            
                          </div>
                          
                        </div>
                      ):null}
                      
                    </div>
                    
                    
                    
                  
                </div>
              
              ):(null)


        );
      

  } else {
    return(<Redirect to ="/" />);
    }
    //<VistaPreviaTest numPregTest={numPregTest} test={test}/>
  }
}
// Conectamos Redux Store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser,
    cronometro: state.user.cronometro,
    idUser: state.user.idUser
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators ({
    loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico, openViewBusArt, addIdLeyBusArt, addArtBusArt
  },dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PreguntaTestVerTeoria);

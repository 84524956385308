// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
//Assets
import './css/menuAdmin.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class MenuAdmin extends Component {
  static propTypes = {
    itemsMenu: PropTypes.array.isRequired,
    titleMenu: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(){
        super();
        this.handleToggleClick = this.handleToggleClick.bind(this)
    }
    handleToggleClick(e){
        if(e.target.id === 'ToggleMenu'){
            $('.c_menu_adm').toggleClass("menu-show-adm");
        }
      };
    render() {
      const {itemsMenu, titleMenu} = this.props
      let itemsMenuPermisos = itemsMenu.filter(item => item.nivelAcesso <= this.props.tipoUser);
        return (
            <div id="" className="c_menu_adm">
                <div className="c_line_adm_especial">
                    <div className="c_icon_adm"><i id="ToggleMenu" onClick={this.handleToggleClick} className="fas fa-bars"></i></div>
                    <div id="ToggleMenu" className="c_text_adm_especial" onClick={this.handleToggleClick}>
                    {titleMenu}
                    </div>
                </div>
                {
                  itemsMenuPermisos && itemsMenuPermisos.map((item, key) =>
                    <Link to={item.url} key={key}>
                      <div className="c_line_adm">
                          <div className="c_icon_adm"><i className={item.fontawesome}></i></div>
                          <div className="c_text_adm">{item.title}</div>
                      </div>
                      </Link>
                    )
                }
            </div>
        );
    }
}
// Conectamos Redux Store
function mapStateToProps(state){
  return{
    tipoUser: state.user.tipoUser
  }
}
export default connect(mapStateToProps, null)(MenuAdmin);

// Dependencies
import React, { Component } from 'react';
import PropTypes, { elementType } from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import bcrypt from 'bcryptjs';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Input from '../../../../../components/Inputs/Input';
import Input2 from '../../../../../components/Inputs/Input2';

import Boton from '../../../../../components/Inputs/Boton';
import InputSelect from '../../../../../components/Inputs/InputSelect';
import Submit from '../../../../../components/Inputs/Submit';
import Alumnos from '../../../../../components/Alumnos';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    idUsuario: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      statusBarraProceso: 'completar',
      type: 'password',
      iconLifeActive: 'fas fa-toggle-off',
      iconLifePass: 'fas fa-lock',
      oposicion_add: '',
      dni_add: '',
      clase_add: '',
      total_pagado: '',
      activo_add: '',
      alumName_add: '',
      alumApellidos_add: '',
      pass_add: '',
      email_add: '',
      tlf_add: '',
      location_add: '',
      clase_edit: '',
      displayEditarAlumno: 'none',
      claseCorrecta: null,
      activo_edit: '',
      acceso_web: '',
      activo_temas: '',
      valuesActivo: [{
        value: '',
        name: 'Seleccione una opción',
        disable: 'disabled',
        selected: 'selected'
      },
      {
        value: '1',
        name: 'Si',
        disable: undefined,
        selected: undefined
      },
      {
        value: '0',
        name: 'No',
        disable: undefined,
        selected: undefined
      }

      ]
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onChangeInputPassword = this.onChangeInputPassword.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.handleBuscarAlumno = this.handleBuscarAlumno.bind(this);
  }
  onFocus(e) {
    this.setState({ type: "text", iconLifePass: 'fas fa-unlock-alt' });
  };
  onBlur(e) {
    this.setState({ type: "password", iconLifePass: 'fas fa-lock' });
  };

  handleBuscarAlumno(e) {
    let { busquedaOpo, busquedaAlumnos, slc_dni_edit, clase_edit, activo_edit } = this.state
    let alumnoCorrecto = busquedaAlumnos.find(alumno => alumno.dni == e.target.value);
    if (alumnoCorrecto != undefined) {
      this.setState({ [e.target.name]: e.target.value });
      var data = { id_usuario: alumnoCorrecto.id_usuario }
      fetch("https://oposiciones-justicia.es/api/oficina/infoAlumnoClase", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(data => {
        if (data != undefined && data.length > 0) {
          let clasesAlumno = []
          for (var i = 0; i < data.length; i++) {
            clasesAlumno.push({
              idClase: data[i].idClase,
              nombreClase: data[i].nombreClase
            })
          }
          this.setState({ listaClasesAlumno: clasesAlumno });
          this.setState({ displayEditarAlumno: 'block' });

        }
      }).catch(function (err) { console.log(err) });
    } else {
      this.setState({ clase_edit: '', activo_edit: '', displayEditarAlumno: 'none' });
      this.refs.ref_clase_edit.refs.clase_edit.value = '';
    }
  }
  cleanForm() {
    setTimeout(
      function () {
        window.location.reload();
      }.bind(this), 1500);
    switch (this.props.selectedOption) {
      case 'Añadir_Alumno': {
        this.setState({ oposicion_add: '', clase_add: '', total_pagado: '', activo_add: '', alumName_add: '', alumApellidos_add: '', pass_add: '', email_add: '', tlf_add: '', location_add: '', dni_add: '' });
        this.refs.ref_oposicion_add.refs.oposicion_add.value = '';
        this.refs.ref_clase_add.refs.clase_add.value = '';
        this.refs.ref_total_pagado.refs.total_pagado.value = '';
        this.refs.ref_activo_add.refs.activo_add.value = '';
        this.refs.ref_dni_add.refs.dni_add.value = '';
        this.refs.ref_alumName_add.refs.alumName_add.value = '';
        this.refs.ref_alumApellidos_add.refs.alumApellidos_add.value = '';
        this.refs.ref_pass_add.refs.pass_add.value = '';
        this.refs.ref_email_add.refs.email_add.value = '';
        this.refs.ref_tlf_add.refs.tlf_add.value = '';
        this.refs.ref_location_add.refs.location_add.value = '';
      } break;

      case 'Borrar_Empleado': {
        this.setState({ slc_dni_del: '' });
        this.refs.ref_slc_dni_del.refs.slc_dni_del.value = '';
      } break;
      case 'Editar_Alumno': {
        this.setState({ clase_edit: '', activo_edit: '' });
        this.refs.ref_clase_edit.refs.clase_edit.value = '';
        this.refs.ref_activo_edit.refs.activo_edit.value = '';
      } break;
    }
  }
  onChangeInput(e) {


    if (this.props.selectedOption == 'Añadir_Alumno') {
      this.setState({ [e.target.name]: e.target.value });
      if (e.target.name === 'oposicion_add') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
        if (oposicionObject != undefined) {
          let data = { id_oposicion: oposicionObject.id_oposicion };
          fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => this.setState({ busquedaSemClases: cls }))
            .catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ busquedaSemClases: '' });
        }
      }

      if ((this.state.oposicion_add.length > 0 && this.state.dni_add.length > 0 && this.state.total_pagado.length > 0 && this.state.clase_add.length > 0 && e.target.value.length > 0 && this.state.activo_add.length > 0 && this.state.alumName_add.length > 0 && this.state.alumApellidos_add.length > 0 && this.state.pass_add.length > 0 && this.state.email_add.length > 0 && this.state.tlf_add.length > 0 && this.state.location_add.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }
    } else if (this.props.selectedOption == 'Borrar_Alumno') {
      this.setState({ [e.target.name]: e.target.value });
      if ((e.target.value.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }
    } else if (this.props.selectedOption == 'Editar_Alumno') {

      if (e.target.name == "clase_edit") {

        let alumnoCorrecto = this.state.busquedaAlumnos.find(alumno => alumno.dni == this.state.slc_dni_edit);

        let claseObject = this.state.listaClasesAlumno.find(clase => clase.nombreClase == e.target.value);

        this.setState({ clase_edit: e.target.value })

        if (alumnoCorrecto != undefined && claseObject != undefined) {
          let data = { idClase: claseObject.idClase, id_usuario: alumnoCorrecto.id_usuario };

          fetch("https://oposiciones-justicia.es/api/oficina/buscaAlumnoByClaseInfo", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {

            this.setState({ claseCorrecta: true, activo_temas: cls[0].temasActivados == 1 ? 'SI' : 'NO', activo_edit: cls[0].activado == 1 ? 'SI' : 'NO', acceso_web: cls[0].activo == 1 ? 'SI' : 'NO' });
            this.refs.ref_activo_temas.refs.activo_temas.value = cls[0].temasActivados == 1 ? 'SI' : 'NO';
            this.refs.ref_activo_edit.refs.activo_edit.value = cls[0].activado == 1 ? 'SI' : 'NO';
            this.refs.ref_acceso_web.refs.acceso_web.value = cls[0].activo == 1 ? 'SI' : 'NO';

          }).catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ claseCorrecta: null })
        }

      } else if (e.target.name == 'activo_edit' || e.target.name == 'acceso_web' || e.target.name == 'activo_temas' || e.target.name == 'tipoUser') {

        let value = e.target.value

        this.setState({ [e.target.name]: value });

      }
    }

  }

  onChangeInputPassword(e) {
    switch (this.props.selectedOption) {
      case 'Añadir_Alumno': {
        if (e.target.value.length > 0) {
          let hash = bcrypt.hashSync(e.target.value, 8);
          this.setState({ [e.target.name]: hash });
        } else { this.setState({ [e.target.name]: undefined }); }

        if ((this.state.oposicion_add.length > 0 && this.state.clase_add.length > 0 && this.state.total_pagado.length > 0 && e.target.value.length > 0 && this.state.activo_add.length > 0 && this.state.alumName_add.length > 0 && this.state.alumApellidos_add.length > 0 && this.state.email_add.length > 0 && this.state.tlf_add.length > 0 && this.state.location_add.length > 0)) {
          this.setState({ statusBarraProceso: "enviar" });
        } else { this.setState({ statusBarraProceso: "completar" }); }
      } break;

      case 'Editar_Alumno': {
        if (e.target.value.length > 0) {
          let hash = bcrypt.hashSync(e.target.value, 8);
          this.setState({ [e.target.name]: hash });
        } else { this.setState({ [e.target.name]: '' }); }

        if ((this.state.nombre_usuario_editar.length > 0 && e.target.value.length > 0)) {
          this.setState({ statusBarraProceso: "enviar" });
        } else {
          this.setState({ statusBarraProceso: "completar" });
        }
      } break;
    }
  }
  async handleSubmitForm(e) {
    switch (e.target.id) {
      case 'añadir_alumno': {

        window.location.href = "https://oposiciones-justicia.es/preparacion"

        // let { busquedaSemClases, busquedaOpo, oposicion_add, dni_add, clase_add, total_pagado, activo_add, alumName_add, alumApellidos_add, pass_add, email_add, tlf_add, location_add, fecha_add } = this.state
        // let nota = 'Nada';
        // try {
        //   let data = { nombreUsuario: dni_add }
        //   let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/nameUserDisponible", {
        //     method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        //   });
        //   if (!response1.ok) {
        //     throw Error(response1.statusText);
        //   }
        //   let json1 = await response1.json();
        //   let cls1 = await json1;
        //   console.log(cls1)

        //   if (cls1.length == 0) {

        //     if (isDefined(oposicion_add) && isDefined(clase_add) && isDefined(total_pagado) && isDefined(activo_add) && isDefined(alumName_add) && isDefined(alumApellidos_add) && isDefined(pass_add) && isDefined(email_add) && isDefined(tlf_add) && isDefined(location_add), isDefined(fecha_add)) {
        //       let activo = 0;
        //       if (activo_add == 1) { activo = 1; }
        //       let data = {
        //         activo_add: activo,
        //         dni: dni_add,
        //         alumName_add: alumName_add,
        //         alumApellidos_add: alumApellidos_add,
        //         pass_add: pass_add,
        //         email_add: email_add,
        //         tlf_add: tlf_add,
        //         location_add: location_add,
        //         tipoUsuario: 'Alumno',
        //         nivelUsuario: 1
        //       }


        //       fetch("https://oposiciones-justicia.es/api/oficina/addAlumno", {
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify(data)
        //       }).then(response => {
        //         if (response.status >= 400) { throw new Error("Bad response from server"); }
        //         return response.json();
        //       }).then(data => {
        //         if (data.affectedRows > 0) {
        //           let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === oposicion_add);
        //           let idOposicion = oposicionObject.id_oposicion;
        //           let claseObject = busquedaSemClases.find(clase => clase.dia_semanal === clase_add && clase.id_oposicion == idOposicion);
        //           let data2 = {
        //             id_clase: claseObject.id_clase,
        //             id_usuario: data.insertId,
        //             activado: activo
        //           }
        //           this.setState({ id_usuarioddf: data.insertId });
        //           return fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClase", {
        //             method: 'POST',
        //             headers: { 'Content-Type': 'application/json' },
        //             body: JSON.stringify(data2)
        //           });
        //         }
        //       }).then(response => {
        //         if (response.status >= 400) { throw new Error("Bad response from server"); }
        //         return response.json();
        //       }).then(data => {

        //         let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === oposicion_add);
        //         let idOposicion = oposicionObject.id_oposicion;
        //         let claseObject = busquedaSemClases.find(clase => clase.dia_semanal === clase_add && clase.id_oposicion == idOposicion);
        //         var factura = {
        //           idClase: claseObject.id_clase,
        //           idAlumno: this.state.id_usuarioddf,
        //           idCobrador: this.props.idUsuario,
        //           totalPagado: total_pagado,
        //           estado: 'Pagado',
        //           metodoPago: 'oficina-metalico',
        //           datosActivados: 1,
        //           sucripcion: 'No',
        //           concepto: 'Nuevo Alumno: ' + alumName_add + ', ' + alumApellidos_add + '.Fecha de pago: ' + moment().format("YYYY-MM-DD HH:mm:ss") + '. Primer mes pagado de la clase: ' + clase_add + '.',
        //           fechaFactura: moment(fecha_add).format("YYYY-MM-DD HH:mm:ss"),
        //           fechaCaducidad: moment(fecha_add).add(1, 'M').subtract(parseInt(moment(fecha_add).format("DD")), 'd').format("YYYY-MM-DD HH:mm:ss")
        //         }
        //         return fetch("https://oposiciones-justicia.es/api/oficina/addFactura", {
        //           method: 'POST',
        //           headers: { 'Content-Type': 'application/json' },
        //           body: JSON.stringify(factura)
        //         });
        //       }).then(response => {
        //         if (response.status >= 400) { throw new Error("Bad response from server"); }
        //         return response.json();
        //       }).then(cls => {
        //         this.setState({ statusBarraProceso: "completado" });
        //         swal('Alumno Añadido', '', 'success')
        //         this.cleanForm();
        //       }).catch(error => console.log('Object fecth failed', error))



        //     } else {
        //       swal("Complete todos los datos para procesar la solicitud", '', "warning");
        //     }
        //   } else {
        //     swal("El usuario ya exisite", '', "warning");
        //   }
        // } catch (e) { console.log(e) }

      } break;
      case 'borrar_alumno': {
        let { busquedaAlumnos, slc_dni_del } = this.state
        if (isDefined(slc_dni_del)) {
          let alumnosObject = busquedaAlumnos.find(alumno => alumno.dni == slc_dni_del);

          if (alumnosObject != undefined) {


            var data = {
              id_usuario: alumnosObject.id_usuario
            }
            swal({
              title: "¿Estas segur@?",
              text: "Si le das a ok, el alumno será borrado y toda la informacion de la base de datos, respecto a este alumno será destruida, no se debe hacer nunca, puede DESACTIVAR al alumno desde editar alumno.",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
              .then((willDelete) => {
                if (willDelete) {
                  fetch("https://oposiciones-justicia.es/api/oficina/delAlumno", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                  }).then(response => {
                    if (response.status >= 400) { throw new Error("Bad response from server"); }
                    return response.json();
                  }).then(data => {
                    if (data.affectedRows > 0) {
                      this.setState({ statusBarraProceso: "completado" });
                      this.cleanForm();
                    }
                  }).catch(function (err) { console.log(err) });

                  swal("¡Alumno eliminado con exito!", '', "success");
                  this.forceUpdate();
                } else {
                  swal("¡Alumno no borrado!", '', "success");
                }
              });
          } else {
            swal("Error", 'Indique un DNI correcto, el actual no existe en la base de datos.', "warning");
          }
        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'editar_alumno': {
        let { listaClasesAlumno, busquedaAlumnos, slc_dni_edit, clase_edit, activo_edit, activo_temas, acceso_web, tipoUser } = this.state





        if (tipoUser == '' || tipoUser == 'Alumno' || tipoUser == 'Preparador' || tipoUser == 'Oficina' || tipoUser == 'Administrador') {
          if (isDefined(clase_edit) && isDefined(activo_edit) && isDefined(slc_dni_edit) && isDefined(activo_temas) && isDefined(acceso_web)) {

            let alumnosObject = busquedaAlumnos.find(alumno => alumno.dni == slc_dni_edit);

            let claseObject = listaClasesAlumno.find(clase => clase.nombreClase == clase_edit);


            if (tipoUser == 'Alumno'){
              let data3x = {
                idUser: alumnosObject.id_usuario,
                tipoUser: tipoUser,
                nivelUser: 1
              }
              fetch("https://oposiciones-justicia.es/api/oficina/editUserTipo", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data3x)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                
              }).catch(function (err) { console.log(err) });
            } else if (tipoUser == 'Preparador'){
              let data3x = {
                idUser: alumnosObject.id_usuario,
                tipoUser: tipoUser,
                nivelUser: 52
              }
              fetch("https://oposiciones-justicia.es/api/oficina/editUserTipo", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data3x)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                
              }).catch(function (err) { console.log(err) });
            } else if (tipoUser == 'Oficina'){
              let data3x = {
                idUser: alumnosObject.id_usuario,
                tipoUser: tipoUser,
                nivelUser: 98
              }
              fetch("https://oposiciones-justicia.es/api/oficina/editUserTipo", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data3x)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                
              }).catch(function (err) { console.log(err) });
            } else if (tipoUser == 'Administrador'){
              let data3x = {
                idUser: alumnosObject.id_usuario,
                tipoUser: tipoUser,
                nivelUser: 99
              }
              fetch("https://oposiciones-justicia.es/api/oficina/editUserTipo", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data3x)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                
              }).catch(function (err) { console.log(err) });
            }

            
            let data3 = {
              id_clase: claseObject.idClase,
              id_usuario: alumnosObject.id_usuario,
              activado: activo_edit == 'SI' ? 1 : 0,
              activarTemas: activo_temas == 'SI' ? 1 : 0
            }
            fetch("https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data3)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                this.setState({ statusBarraProceso: "completado" });
                this.cleanForm();
              }
            }).catch(function (err) { console.log(err) });

            let data4 = {
              id_usuario: alumnosObject.id_usuario,
              acceso: acceso_web == 'SI' ? 1 : 0
            }
            fetch("https://oposiciones-justicia.es/api/oficina/editUserActivado", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data4)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                swal('Cambios realizados', '', 'success')

              }
            }).catch(function (err) { console.log(err) });

            
          } else {
            swal("Complete todos los datos para procesar la solicitud", '', "warning");
          }
        } else {
          swal("Tipo usuario erroneo", 'Seleccione uno de los disponibles', "warning");
        }

      } break;
    }
  }
  componentDidMount() {
    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
      .then(res => res.json())
      .then(clases => this.setState({
        busquedaSemClasesAll: clases
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
      .then(res => res.json())
      .then(alumnos => this.setState({
        busquedaAlumnos: alumnos
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/clases/consultaPre')
      .then(res => res.json())
      .then(preparadores => this.setState({
        busquedaPre: preparadores
      })).catch(error => console.log('Object fecth failed', error));
  };

  render() {
    const { selectedOption } = this.props;
    const { busquedaAlumnos, busquedaSemClases, busquedaCitasName, busquedaPre, busquedaOpo, statusBarraProceso, type, iconLifeActive, iconLifePass, valuesActivo, displayEditarAlumno, listaClasesAlumno, claseCorrecta,
      activo_edit, acceso_web, activo_temas } = this.state
    switch (selectedOption) {
      case 'Añadir_Alumno': {
        return (


          <div className='rowBotnes2'>

            <Boton id="añadir_alumno" onClick={this.handleSubmitForm} icon1="" icon2="" texto="Abrir inscripciones" />

          </div>


        );
      }
        break;
      case 'Borrar_Alumno': {
        return (
          <div className='ColCenterStart'>

            <Input2 type="text" name="slc_dni_del" placeholder="Buscar Alumno" icon="fas fa-search" datalist="busquedaAlumnos" ref="ref_slc_dni_del" onChange={this.onChangeInput} />

            <div className='rowBotnes2'>

              <Boton id="borrar_alumno" onClick={this.handleSubmitForm} icon1="" icon2="" texto="Borrar Alumno" />

            </div>
            <datalist id="busquedaAlumnos">{
              busquedaAlumnos && busquedaAlumnos.map((cls, key) =>
                <option key={key} value={cls.dni}>{cls.nombre + ' ' + cls.apellidos}</option>
              )
            }</datalist>
          </div>
        );
      } break;
      case 'Editar_Alumno': {
        return (
          <div className='ColCenterStart'>

            <div className="celdaInputDAPFBN">
              <Input2 type="text" name="slc_dni_edit" placeholder="Buscar Alumno" icon="fas fa-search" datalist="busquedaAlumnos" ref="ref_slc_dni_edit" onChange={this.handleBuscarAlumno} />
              <p>Dni Alumno</p>
            </div>
            {
              displayEditarAlumno && displayEditarAlumno == 'block' ? (
                <React.Fragment>
                  <div className="celdaInputDAPFBN">
                    <Input2 type="text" name="clase_edit" placeholder="Clase" icon="fas fa-search" datalist="listaClasesAlumno" ref="ref_clase_edit" onChange={this.onChangeInput} />
                    <p>Clase</p>
                  </div>
                  {
                    claseCorrecta != null ? (
                      <> <div className='RowCenterCenter' style={{ marginTop: '20px' }}>
                        <div className="celdaInputDAPFBN" style={{ width: '280px' }}>
                          <Input2 type="text" datalist="valuesActivos" name="activo_edit" placeholder="Clase" icon={activo_edit == 'NO' || activo_edit == '' ? "fa-light fa-toggle-off" : "fa-light fa-toggle-on"} ref="ref_activo_edit" onChange={this.onChangeInput} />
                          <p>¿Puede cargar la clase o curso?</p>
                        </div>

                        <div className="celdaInputDAPFBN" style={{ width: '280px' }}>
                          <Input2 type="text" datalist="valuesActivos" name="acceso_web" placeholder="Clase" icon={acceso_web == 'NO' || acceso_web == '' ? "fa-light fa-toggle-off" : "fa-light fa-toggle-on"} ref="ref_acceso_web" onChange={this.onChangeInput} />
                          <p>Acceso web, ¿Puede iniciar sesión?</p>
                        </div>

                        <div className="celdaInputDAPFBN" style={{ width: '280px' }}>
                          <Input2 type="text" datalist="valuesActivos" name="activo_temas" placeholder="Clase" icon={activo_temas == 'NO' || activo_temas == '' ? "fa-light fa-toggle-off" : "fa-light fa-toggle-on"} ref="ref_activo_temas" onChange={this.onChangeInput} />
                          <p>Ver todos los bloques de estudio</p>
                        </div>
                      </div>

                        <div className='RowCenterCenter' style={{ margin: '20px' }}>


                          <div className="celdaInputDAPFBN" style={{ width: '280px' }}>
                            <Input2 type="text" id="tipoUser" name="tipoUser" placeholder="Alumno, Preparador, Oficina, ..." icon="fas fa-search" datalist="busquedaTipo" ref="ref_tipoUser" onChange={this.onChangeInput} />
                            <p>Tipo usuario</p>
                          </div>
                          <datalist id="busquedaTipo">

                            <option value={'Alumno'}></option>
                            <option value={'Preparador'}></option>
                            <option value={'Oficina'}></option>
                            <option value={'Administrador'}></option>
                          </datalist>

                        </div>
                      </>
                    ) : null
                  }

                  {
                    claseCorrecta != null ? (
                      <div className='rowBotnes2'>

                        <Boton id="editar_alumno" onClick={this.handleSubmitForm} icon1="" icon2="" texto="Editar Alumno" />

                      </div>
                    ) : null}

                </React.Fragment>
              ) : null
            }


            {/* <InputSelect display={displayEditarAlumno} values={valuesActivo} name="activo_edit" icon={iconLifeActive} ref="ref_activo_edit" onChange={this.onChangeInput} />

            {displayEditarAlumno == 'block' ? (<h55><i className="fas fa-info-circle"></i>Activar temas sin facturar<i className="fas fa-arrow-circle-down"></i></h55>) : ('')}
            <InputSelect display={displayEditarAlumno} values={valuesActivo} name="activo_temas" icon={iconLifeActive} ref="ref_activo_temas" onChange={this.onChangeInput} />

            {displayEditarAlumno == 'block' ? (<h55><i className="fas fa-info-circle"></i>Acceso Web Permitido<i className="fas fa-arrow-circle-down"></i></h55>) : ('')}
            <InputSelect display={displayEditarAlumno} values={valuesActivo} name="acceso_web" icon={iconLifeActive} ref="ref_acceso_web" onChange={this.onChangeInput} />

            {displayEditarAlumno == 'block' ? (<Submit id="editar_alumno" icon1="fas fa-edit" icon2="fas fa-database" texto="Editar Alumno" onClick={this.handleSubmitForm} />) : ('')} */}

            <datalist id="busquedaAlumnos">{
              busquedaAlumnos && busquedaAlumnos.map((cls, key) =>
                <option key={key} value={cls.dni}>{cls.nombre + ' ' + cls.apellidos}</option>
              )
            }</datalist>
            <datalist id="valuesActivos">
              <option value="SI"></option>
              <option value="NO"></option>
            </datalist>
            <datalist id="listaClasesAlumno">{
              listaClasesAlumno && listaClasesAlumno.map((cls, key) =>
                <option key={key} value={cls.nombreClase}></option>
              )
            }</datalist>

          </div>
        );
      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}

export default OptionBar;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/inputTemas.css';
import Input from '../Input';
class InputTemas extends Component {

  static propTypes = {
    num: PropTypes.string.isRequired,
    numIntervalos: PropTypes.string.isRequired,
		displayTestJusticia:  PropTypes.string.isRequired,
		displayCasoPractico:  PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
		   iconLifeActive: 'fas fa-toggle-off',
		   iconLifeText: 'No',
		   readOnly: true
        };
          this.onChangeIntervalo = this.onChangeIntervalo.bind(this);
          this.iconLifeActive = this.iconLifeActive.bind(this);
          this.onChangeInputDinamic = this.onChangeInputDinamic.bind(this);
    }

    onChangeInputDinamic(e){
        this.setState({[e.target.name]: e.target.value});

        if(e.target.name == 'numInterLeyes'){
          this.setState({numInterLeyes: e.target.value});

        let intervaloDinamico = [{}];
        for (var i = 0; i <  e.target.value; i++) {
          intervaloDinamico[i] = {num: ''+i};
        }

				if (e.target.value <= 0  || e.target.value == ''){
					this.setState({intervalos: undefined});
				} else {
					this.setState({intervalos: intervaloDinamico});
				}

      } else if (e.target.name == 'numInterPDF'){
        this.setState({numInterPDF: e.target.value});

      let intervaloDinamicoPdf = [{}];
      for (var i = 0; i <  e.target.value; i++) {
        intervaloDinamicoPdf[i] = {num: ''+i};
      }
			if (e.target.value <= 0 || e.target.value == ''){
				this.setState({intervalosPDF: undefined});
			} else {
				this.setState({intervalosPDF: intervaloDinamicoPdf});
			}

      }
    }

    iconLifeActive(e){
    $("i[title='"+e.target.title+"']").toggleClass('fas fa-toggle-on fas fa-toggle-off');
    if(e.target.className == 'fas fa-toggle-off'){
      $("div[title='"+e.target.title+"']").text('No');
    } else {
      $("div[title='"+e.target.title+"']").text('Si');
    }
    this.props.onChange(e);
    }

    onChangeIntervalo(e){
	    if(e.target.name == 'Tema'){
          let temaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema === e.target.value);
          if (temaObject != undefined){
            this.props.onChange(e);
            this.setState({id_tema: temaObject.id_tema, readOnly: false});
            let data = {
              id_tema: temaObject.id_tema
            }
            fetch("https://oposiciones-justicia.es/api/leyes/consultaTemaLeyes", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
                }).then(res => {if (res.status >= 400) { throw new Error("Bad response from server");} return res.json()})
                .then(intervaloLeyesTemas => this.setState({
                    busquedaLeyesTema: intervaloLeyesTemas
                }))
              .catch(error => console.log('Object fecth failed', error))

              fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
                      method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify(data)
                  }).then(res => {if (res.status >= 400) { throw new Error("Bad response from server");} return res.json()})
                  .then(temasApuntes => this.setState({
                      busquedaTemasApuntes: temasApuntes
                  }))
                .catch(error => console.log('Object fecth failed', error))

          } else {
            this.setState({busquedaLeyesTema: '' , busquedaTemasApuntes: '', readOnly: true, intervalos: undefined,intervalosPDF: undefined});
						this.refs.ref_numInterPDF.value= '';
						this.refs.ref_ref_numInterLeyes.value= '';
          }
        }else if(e.target.name == 'TemaLey'){
          let artObject = this.state.busquedaLeyes.find(leyes => leyes.id_ley == e.target.value);
          if (artObject != undefined){
            this.props.onChange(e);
          }
        } else {
          this.props.onChange(e);
        }

    }

    componentDidMount(){

      fetch('https://oposiciones-justicia.es/api/temas/consulta')
        .then(res => res.json())
          .then(temas => this.setState({
              busquedaTemas: temas
          }))
        .catch(error => console.log('Object fecth failed', error))

        fetch('https://oposiciones-justicia.es/api/leyes/consulta')
          .then(res => res.json())
            .then(leyes => this.setState({
                busquedaLeyes: leyes
            }))
          .catch(error => console.log('Object fecth failed', error))

    };
  render() {
    const { num, numIntervalos, numInterLeyes, displayTestJusticia, displayCasoPractico } = this.props;
    const { readOnly, intervalosPDF, intervalos, busquedaTemasApuntes, busquedaArtTema, busquedaLeyes, busquedaLeyesTema, busquedaTemas, iconLifeActive, iconLifeText} = this.state
    if(numIntervalos > 0){
              return (
                  <div className="intervaloTemas">
				<h55><i className="fas fa-info-circle"></i>TEMA {num}<i className="fas fa-arrow-circle-down"></i></h55>
				<div className="temas">
				  <div className="inputWithImgTema">
					 <input id={num} type="text" className="textInputTipo" name='TipoTema' ref='ref_TipoTema' placeholder="Normal ó Repaso" onChange={this.onChangeIntervalo} list="tipoTema" />
					   <i className="fas fa-sort-numeric-up"></i>
				  </div>
				</div>
				   <div className="temas">
	                        <div className="inputWithImgTema">
	                            <input id={num} type="text" className="textInputTipo" placeholder="Nombre Tema" name='Tema' ref='Tema' list="busquedaTemas" onChange={this.onChangeIntervalo} />
	                              <i className="fas fa-search"></i>
	                        </div>
	                    </div>

					<div className="temas">
                        <div className="inputWithImgTema">
                            <input id={num} style={{display: displayTestJusticia}} type="text" className="textInputTipo" name='PorcentajePreguntasTestJusticiaTema' ref='ref_PorcentajePreguntasTestTema' placeholder="Test Justicia" onChange={this.onChangeIntervalo}/>
                              <i className="fas fa-percent" style={{display: displayTestJusticia}}></i>
                        </div>
                      </div>

                      <h55><i className="fas fa-info-circle"></i>Nº de PDFs<i className="fas fa-arrow-circle-down"></i></h55>
                      <div className="temas">
                      <div className="inputWithImgTema">
                          <input readOnly={readOnly} id={num} type="number" className="textInputTipo" name='numInterPDF' ref='ref_numInterPDF' placeholder="1,2,3..." onChange={this.onChangeInputDinamic} min={0} max={25} />
                            <i className="fas fa-sort-numeric-up"></i>
                      </div>
                    </div>

                    <div>{ intervalosPDF && intervalosPDF.map((intervalo, key) =>
                      <div key={key} className="temas">
                        <div className="inputWithImgTema">
                            <input readOnly={readOnly} id={num} title={intervalo.num} type="text" className="textInputTipo" name='ApuntesTema' ref='ref_ApuntesTema' placeholder="Seleccione PDF" onChange={this.onChangeIntervalo} list={'busquedaTemasApuntes'+num+intervalo.num}/>
                              <i className="fas fa-file-pdf"></i>
                        </div>
                        <datalist id={'busquedaTemasApuntes'+num+intervalo.num}>{
                            busquedaTemasApuntes && busquedaTemasApuntes.map((apunte, key) =>
                            <option key={key} value={apunte.id_apunte}>{apunte.titulo_archivo}</option>
                        )}</datalist>
                      </div>
                      )}
                      </div>
                      <h55><i className="fas fa-info-circle"></i>Nº Intervalos Leyes<i className="fas fa-arrow-circle-down"></i></h55>
                      <div className="temas">
                      <div className="inputWithImgTema">
                          <input readOnly={readOnly} id={num} type="number" className="textInputTipo" name='numInterLeyes' ref='ref_numInterLeyes' placeholder="1,2,3.." onChange={this.onChangeInputDinamic} min={0} max={25} />
                            <i className="fas fa-sort-numeric-up"></i>
                      </div>
                    </div>

                      <div>{ intervalos && intervalos.map((intervalo, key) =>
                          <div  key={key} className="intervaloTemass">
                          <div className="temasIntervalos">
                                <div className="inputWithImgTema">
                                    <input readOnly={readOnly} id={num} title={intervalo.num} type="text" className="textInputTema2" placeholder="Ley" name='TemaLey' ref='TemaLey' list={'busquedaLeyes'+num} onChange={this.onChangeIntervalo} />
                                      <i className="fas fa-chevron-circle-right"></i>
                                </div>
                                <div id={num} className="toggleOnOff">
                                <i  id={num} title={num+intervalo.num} ref ="ref_toggleOnOff" name="toggleOnOff" className="fas fa-toggle-off" onClick={this.iconLifeActive}></i><div title={num+intervalo.num}>No</div></div>
                            </div>
                            <div className="temasIntervalos">
                              <div className="inputWithImgTema">
                                  <input readOnly={readOnly} id={num} title={intervalo.num} type="text" className="textInputTema" placeholder="Art. Inicio" name='Articulo_inc' ref='Articulo_inc' list={'busquedaArtInc'+num+intervalo.num} onChange={this.onChangeIntervalo} />
                                    <i className="fas fa-chevron-circle-right"></i>
                              </div>
                              <div className="inputWithImgTema">
                                  <input readOnly={readOnly} id={num} title={intervalo.num} type="text" className="textInputTema" placeholder="Art. Fin" name='Articulo_fin' ref='Articulo_fin' list={'busquedaArtFin'+num+intervalo.num} onChange={this.onChangeIntervalo} />
                                <i className="fas fa-chevron-circle-right"></i>
                              </div>
                              </div>
                              <datalist id={'busquedaLeyes'+num}>{
                                  busquedaLeyesTema && busquedaLeyesTema.map((ley, key) =>
                              <option key={key} value={ley.id_ley}>
                              {
                              this.state.busquedaLeyes.find(leyes => leyes.id_ley === ley.id_ley).siglas_ley
                              }
                                  </option>
                              )}</datalist>
                              <datalist id={'busquedaArtInc'+num+intervalo.num}>{
                                  busquedaLeyesTema && busquedaLeyesTema.map((ley, key) =>
                              <option key={key} value={ley.art_inicio}>{
                              this.state.busquedaLeyes.find(leyes => leyes.id_ley === ley.id_ley).siglas_ley
                              }</option>
                              )}</datalist>

                              <datalist id={'busquedaArtFin'+num+intervalo.num}>{
                                  busquedaLeyesTema && busquedaLeyesTema.map((ley, key) =>
                              <option key={key} value={ley.art_fin}>{
                              this.state.busquedaLeyes.find(leyes => leyes.id_ley === ley.id_ley).siglas_ley
                              }</option>
                              )}</datalist>


                            </div>
                        )}
                        </div>
                        <datalist id="tipoTema">
                          <option  value="Normal"></option>
                          <option  value="Repaso"></option>
                        </datalist>
                        <datalist id="busquedaTemas">{
                            busquedaTemas && busquedaTemas.map((tema, key) =>
                            <option key={key} value={tema.titulo_tema}></option>
                        )}</datalist>
                  </div>
              );
        }else{
          return (<div> </div>);
        }
        }
 }
// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default InputTemas;

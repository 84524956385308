// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/InputTemaPdfEdt.css';
import Input from '../Input';
import BotonMedio from '../BotonMedio';

class InputTemaPdfEdt extends Component {

  static propTypes = {
    infoPdf: PropTypes.array
  };

constructor(props){
        super(props);
        this.state = {};
          this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(e){
          this.props.onChange(e);
    }

  render() {
    const { infoPdf, busquedaTemasApuntes } = this.props;
    const { intervalos, iconLifeActive, iconLifeText} = this.state
    let num = 0;
    if(infoPdf.length > 0 && infoPdf != undefined){
              return (
                  <div>
                  {
                    infoPdf && infoPdf.map((pdf, key) =>
                    <div className="bloqueLey" key={key}>
										<div className="">Archivo PDF {key+1}</div>
                          <div className="temas">
                                <div className="inputWithImgTema">
                                    <input id={key} type="text" className="textInputTipo" placeholder="Nombre Archivo" value={pdf.titulo_archivo}
                                    name='Pdf' ref='Pdf' list="busquedaTemasApuntes" title='Nombre PDF' onChange={this.onChangeInput} readOnly={pdf.readOnly}/>
                                      <i className="fas fa-search"></i>
                                </div>
                          </div>
                            <div className="temas">
                              <input type="radio" id={key} name={key+"editarBorrar"} value="1" onClick={this.onChangeInput}/>Editar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="2" onClick={this.onChangeInput}/>Borrar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="3" onClick={this.onChangeInput}/>Nada
                            </div>
                        </div>
                    )}
										<datalist id={'busquedaTemasApuntes'}>{
												busquedaTemasApuntes && busquedaTemasApuntes.map((apunte, key) =>
												<option key={key} value={apunte.id_apunte}>{apunte.titulo_archivo}</option>
										)}</datalist>
                  </div>
              );
        }else{
          return (<div> </div>);
        }
        }
 }
// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default InputTemaPdfEdt;

export default [
  {
    title: 'Administración',
    url: '/administration',
    visible: true,
    nivelAcesso: 99,
    nivelAcessoSuperiorAeste: 99,
    fontawesome: 'fa-light fa-folder-gear'
  },
  {
    title: 'Oficina',
    url: '/Oficina',
    visible: true,
    nivelAcesso: 98,
    nivelAcessoSuperiorAeste: 98,
    fontawesome: 'fa-light fa-user-headset'
  },
  {
    title: 'Preparador',
    url: '/preparador',
    visible: true,
    nivelAcesso: 50,
    nivelAcessoSuperiorAeste: 98,
    fontawesome: 'fa-light fa-person-chalkboard'
  },
  {
    title: 'Ejercicios',
    url: '/informatica',
    visible: true,
    nivelAcesso: 60,
    nivelAcessoSuperiorAeste: 98,
    fontawesome: 'fa-light fa-computer'
  },
  {
    title: 'Perfil',
    url: '/perfil',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-user-gear'
  },
  
	{
    title: 'Facturas',
    url: '/Facturacion',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-cash-register'
  },
  {
    title: 'Escritorio',
    url: '/escritorio',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-house-chimney'
  },
  {
    title: 'Aula Virtual',
    url: '/aulaVirtual',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    suscripcionPresencial: true,
    fontawesome: 'fa-light fa-chalkboard-user'
  },
	{
    title: 'Reformas Legislación',
    url: '/infoReformas',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-books-medical'
  },
	{
    title: 'Dudas',
    url: '/DudasYReportes',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-comments-question-check'
  },
  {
    title: 'Expediente',
    url: '/expedienteTest',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-briefcase'
  }
  ,
  {
    title: 'Generador Test',
    url: '/testJusticia',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    suscripcionTest: true,
    fontawesome: 'fa-light fa-clipboard-list-check'
  },
  {
    title: 'Examenes Oficiales',
    url: '/convocatorias',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    suscripcionTest: true,
    fontawesome: 'fa-light fa-calendar-star'
  },
	{
    title: 'Informatica',
    url: '/WindowsOffice',
    visible: true,
    nivelAcesso: 1,
    nivelAcessoSuperiorAeste: 1,
    fontawesome: 'fa-light fa-computer'
  }
// ,
//   {
//     title: 'Diccionario jurídico',
//     url: '/suscripcion',
//     visible: true,
//     nivelAcesso: 1,
//     nivelAcessoSuperiorAeste: 1,
//     fontawesome: 'fa-light fa-up-right-from-square'
//   }
];

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
// Assets
import './css/infoProgramacionClase.css';
import Alumnos from '../Alumnos';
import Boton from '../Inputs/Boton';
import Input2 from '../Inputs/Input2';

class InfoProgramacionClase extends Component {

  static propTypes = {
    programacionClase: PropTypes.array,
    datosPreparador: PropTypes.array,
    alumnosClase: PropTypes.string,
    idClase: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = { alumnosToDelete: [], alumnoNuevo: 0 };
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleAlumnoSelect = this.handleAlumnoSelect.bind(this);
    this.handleonClick = this.handleonClick.bind(this);
  }

  handleonClick(e) {
    if (e.target.id == 'cambiarPreparador') {
      if (this.state.preparadorNuevo != '' && this.state.preparadorNuevo > 0) {
        // Hacer fetch que cambie al preparadore
        let data = {
          idPreparador: this.state.preparadorNuevo,
          idClase: this.props.idClase
        }
        fetch("https://oposiciones-justicia.es/api/oficina/changePreparadorClase", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        }).then(response => {
          if (response.status >= 400) { throw new Error("Bad response from server"); }
          return response.json();
        }).then(cls => {
          swal('Preparador Actualizado', 'Se Recargará la página para ver los cambios.', 'success');
          setTimeout(
            function () {
              window.location.reload();
            }.bind(this), 2000);
        }).catch(error => console.log('Object fecth failed', error))
      } else {
        swal("Error", 'Seleccione un preparador.', 'warning');
      }
    } else if (e.target.id == 'borrarAlumnos') {
      if (this.state.alumnosToDelete.length > 0) {
        // borrar alumnos fetch y swal info
        let data = {
          alumnos: this.state.alumnosToDelete,
          idClase: this.props.idClase
        }
        fetch("https://oposiciones-justicia.es/api/oficina/delAlumnosClase", {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        }).then(response => {
          if (response.status >= 400) { throw new Error("Bad response from server"); }
          return response.json();
        }).then(cls => {
          swal('Alumnos eliminados de clase', 'Recarge la página para ver los cambios.', 'success');
        }).catch(error => console.log('Object fecth failed', error))


      } else {
        swal("Error", 'Necesita seleccionar minimo un Alumno de la clase.', 'warning');
      }

    } else if (e.target.id == 'addAlumno') {
      if (this.state.alumnoNuevo != '' && this.state.alumnoNuevo > 0) {
        let alumnoExiste = this.props.alumnosClase.filter(a => a.idAlumno == this.state.alumnoNuevo).length
        if (alumnoExiste == 0 && this.props.idClase != undefined) {
          // añadir alumno fetch y swal info
          let dataA = { id_usuario: parseInt(this.state.alumnoNuevo), id_clase: this.props.idClase, }
          var estaEnLaClase = undefined
          fetch("https://oposiciones-justicia.es/api/oficina/tieneHistorialdeClaseElAlumno", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataA)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            if (cls.length > 0) { estaEnLaClase = 'Si' }
          }).catch(error => console.log('Object fecth failed', error))
          // saber si el alumno esta en una clase
          // si esta addAlumnoClase + addAlumnoClaseInfo
          setTimeout(
            function () {
              if (estaEnLaClase != undefined && estaEnLaClase == 'Si') {
                // si no esta en ninguna --> addAlumnoClase + editAlumnoClaseInfo

                let data = {
                  id_usuario: parseInt(this.state.alumnoNuevo),
                  id_clase: this.props.idClase,
                  activado: 1
                }
                fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClase", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data)
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(cls => {
                  swal('Alumno añadido a clase', 'Recarge la página para ver los cambios.', 'success');
                }).catch(error => console.log('Object fecth failed', error))
                let data2 = {
                  id_usuario: parseInt(this.state.alumnoNuevo),
                  id_clase: this.props.idClase,
                  activado: 1
                }

              } else {

                let data = {
                  id_usuario: parseInt(this.state.alumnoNuevo),
                  id_clase: this.props.idClase,
                  activado: 1
                }
                fetch("https://oposiciones-justicia.es/api/oficina/addAlumnoClase", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data)
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(cls => {
                  swal('Alumno añadido a clase', 'Recarge la página para ver los cambios.', 'success');
                }).catch(error => console.log('Object fecth failed', error))
                let data2 = {
                  id_usuario: parseInt(this.state.alumnoNuevo),
                  id_clase: this.props.idClase,
                  activado: 1
                }

              }
            }.bind(this), 500);

        } else {
          swal("Error", 'El alumno, ya esta en esta clase', 'warning');
        }
      } else {
        swal("Error", 'Debe seleccionar un alumno.', 'warning');
      }

    }
  }

  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onToggleClick(e) {
    if (e.target.title == 'programa') {
      let id = e.target.id;
      $('#' + id + 'infoPRG').slideToggle(1000);
    }
  }

  handleAlumnoSelect(e, alumno = undefined) {
    if (e.target.id.split('-')[0] == "alumno") {
      if (alumno != undefined) {
        // Aqui seleccionamos el alumno y lo ponemos en la lista de alumnosToDelete
        let alumnos = this.state.alumnosToDelete
        if (alumnos.filter(a => a.idAlumno == alumno.idAlumno).length == 0) {
          alumnos.push(alumno)
          this.setState({ alumnosToDelete: alumnos })
        } else {
          this.setState({ alumnosToDelete: alumnos.filter(a => a.idAlumno != alumno.idAlumno) })
        }
      }
    } else if (e.target.id == "borrarAlumnos") {
      this.handleonClick(e);
    }
  }
  componentDidMount() {
    fetch('https://oposiciones-justicia.es/api/oficina/preparadores')
      .then(res => res.json())
      .then(data => this.setState({
        preparadores: data
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/oficina/alumnos')
      .then(res => res.json())
      .then(data => this.setState({
        alumnos: data
      })).catch(error => console.log('Object fecth failed', error));
  }
  render() {
    const { datosPreparador, alumnosClase } = this.props;
    const { preparadores, alumnos } = this.state
    if (datosPreparador != undefined) {
      return (
        <div className="sectionInfoClases">

          <div className="RowCenterCenter sicTitlePrepa" title="Preparador de la clase">
            <i className="fa-duotone fa-chalkboard-user" title="Preparador de la clase" />
            {datosPreparador[0].nombre + ' ' + datosPreparador[0].apellidos}
          </div>

          <div className="edtAlmPrep">
            <div className="celdaBlockChangePre">

              <div className="celdaInputDAPFBN">
                <Input2 type="text" name="alumnoNuevo" placeholder="Alumno" icon="fas fa-search" datalist="alumnos" ref="ref_alumnoNuevo" onChange={this.onChangeInput} />
                <p>Id Alumno</p>
              </div>

              <Boton id={"addAlumno"} icon1={""} icon2={""} texto={"Añadir Alumno"} onClick={this.handleonClick} />
            </div>

            <div className="celdaBlockChangePre">
              <div className="celdaInputDAPFBN">
                <Input2 type="text" name="preparadorNuevo" placeholder="Preparador" icon="fas fa-search" datalist="preparadores" ref="ref_preparadorNuevo" onChange={this.onChangeInput} />
                <p>Preparador</p>
              </div>

              <Boton id={"cambiarPreparador"} icon1={""} icon2={""} texto={"Cambiar Preparador"} onClick={this.handleonClick} />
            </div>
          </div>

          <div className="cmtAlumnos">
            <Alumnos idClase={this.props.idClase} />
          </div>



          <datalist id="alumnos">{
            alumnos && alumnos.map((alm, key) =>
              <option key={key} value={alm.idAlumno}>{alm.nombreAlumno + ' ' + alm.apellidosAlumno + ';' + alm.dni}</option>
            )
          }</datalist>
          <datalist id="preparadores">{
            preparadores && preparadores.map((pre, key) =>
              <option key={key} value={pre.idPreparador}>{pre.nombrePreparador + ' ' + pre.apellidosPreparador}</option>
            )
          }</datalist>

        </div>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default InfoProgramacionClase;

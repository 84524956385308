// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import bcrypt from 'bcryptjs';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets

import Boton from '../../../../../components/Inputs/Boton';
import Input2 from '../../../../../components/Inputs/Input2';
import InfoProgramacionClase from '../../../../../components/InfoProgramacionClase';

import Word2010ClaseInfo from '../../../../Word2010ClaseInfo'
import CursosInfo from '../../../../CursosInfo';

import SincroClases from '../../../../SincroClases';


class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      displayClases: false,
      programacionClase: [],
      nombrePreparador: undefined,
      totalAlumnos: undefined,
      displayClaseInfo: 'none',
      oposicionSelecionada: null,
      tipoClase: null
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClic = this.onHandleClic.bind(this);
  }

  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Buscar_Clase') {

      if (e.target.name === 'oposicionSelecionada') {
        try {
          let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
          if (oposicionObject != undefined) {

            let data = { id_oposicion: oposicionObject.id_oposicion };
            let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            await this.setState({ busquedaClasesSemanas: cls1, displayClaseInfo: 'block' })

          } else {
            this.setState({
              oposicionSelecionada: undefined,
              claseSelecionada: undefined,
              nombrePreparador: undefined,
              totalAlumnos: undefined,
              displayClaseInfo: 'none',
              programacionClase: [],
              busquedaClasesSemanas: undefined
            });
            this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
          }
        } catch (e) { console.log(e) }
      } else if (e.target.name == "claseSelecionada") {
        try {

          let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == e.target.value);
          if (claseObject != undefined) {
            let data = { idClase: claseObject.id_clase };
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined) {
              let alumnosClase = [];
              for (var i = 0; i < cls1.length; i++) {
                alumnosClase.push({
                  idAlumno: cls1[i].idAlumno,
                  nombreAlumno: cls1[i].nombreAlumno,
                  apellidosAlumno: cls1[i].apellidosAlumno,
                  linkFotoPerfil: cls1[i].linkFotoPerfil
                })
              }
              await this.setState({ alumnosClase: alumnosClase, idClase: claseObject.id_clase })

              let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/programacionClase", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              let prevPRGClase = [];
              for (var p = 0; p < cls2.length; p++) {
                prevPRGClase.push({
                  numeroSemanaClase: cls2[p].numeroSemanaClase,
                  numeroSemanaProgramacion: cls2[p].numeroSemanaProgramacion,
                  fechaClase: moment(cls2[p].fechaClase).format("YY-MM-DD HH:mm"),
                  testJusticia: cls2[p].testJusticia,
                  testWord: cls2[p].testWord,
                  casoPractico: cls2[p].casoPractico
                })
              }
              await this.setState({ programacionClase: prevPRGClase });

              let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/infoClase", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              let datosPreparador = [{
                nombre: cls3[0].nombrePreparador,
                apellidos: cls3[0].apellidosPreparador
              }]
              await this.setState({ datosPreparador: datosPreparador });
              await this.setState({ displayClases: true })

            }

          } else {
            await this.setState({
              nombrePreparador: undefined,
              programacionClase: undefined,
              displayClases: false
            });
          }

        } catch (e) { console.log(e) }
      }

    } else if (this.props.selectedOption == 'ADD_Clase') {
      if (e.target.name === 'oposicionSelecionada') {
        try {

          let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

          if (oposicionObject != undefined) {
            this.setState({
              oposicionSelecionada: oposicionObject
            })
          } else {
            this.setState({
              oposicionSelecionada: null
            });
          }

        } catch (e) { console.log(e) }
      } else if (e.target.id == 'preparador') {
        let value = e.target.value
        let preObject = this.state.busquedaPre.find(prep => prep.nombre_usuario === value)

        if (preObject != undefined) {
          this.setState({
            preparador: preObject
          })
        } else {
          this.setState({
            preparador: null
          });
        }

      }
    }
  }



  onHandleClic(e) {

    if (e.target.id == 'addClase') {


      let { nombreClase, preparador, horaInicio, horaFin, oposicionSelecionada, maxAlumnos, diaPre, diaOnline, minAproTeo, minAproCP, tipoClase } = this.state


      if (tipoClase != null && tipoClase != 'PRESENCIAL' || tipoClase != 'ONLINE' || tipoClase != 'CURSO ONLINE' || tipoClase != 'CURSO BASE') {

        if (isDefined(nombreClase) && isDefined(preparador) && isDefined(horaInicio) && isDefined(horaFin) && isDefined(oposicionSelecionada) && isDefined(maxAlumnos) && isDefined(diaPre) && isDefined(diaOnline) && isDefined(minAproTeo) && isDefined(minAproCP) && isDefined(tipoClase)) {

          if (this.state.busquedaSemClasesAll.find(p => p.dia_semanal == nombreClase) == undefined) {
            let data = {
              nombreClase: nombreClase,
              preparador: preparador,
              horaInicio: horaInicio,
              horaFin: horaFin,
              oposicionSelecionada: oposicionSelecionada,
              maxAlumnos: maxAlumnos,
              diaPre: diaPre,
              diaOnline: diaOnline,
              minAproTeo: minAproTeo,
              minAproCP: minAproCP,
              tipoClase: tipoClase
            }

            fetch("https://oposiciones-justicia.es/api/clases/addClaseN", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {

                swal({
                  title: 'Clase añadida',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })
              }
            }).catch(function (err) { console.log(err) });
          } else {
            swal('El nombre ya existe', 'Cambie el nombre de la clase, ya existe uno igual', 'info')
          }




        }
      } else {
        swal('Error en tipo de clase', 'Selecione un tipo de clase/curso del listado disponible', 'info')
      }


    }


  }


  componentDidMount() {
    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
      .then(res => res.json())
      .then(clases => this.setState({
        busquedaSemClasesAll: clases
      })

      ).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/clases/consultaPre')
      .then(res => res.json())
      .then(preparadores => this.setState({
        busquedaPre: preparadores
      }))
      .catch(error => console.log('Object fecth failed', error))
  };

  render() {
    const { selectedOption } = this.props;
    const { datosPreparador, programacionClase, displayClaseInfo, busquedaClasesSemanas, busquedaOpo, displayClases, alumnosClase, idClase, busquedaSemClasesAll, busquedaPre } = this.state

    switch (selectedOption) {

      case 'Buscar_Clase': {
        return (
          <div className="ColCenterStart">

            <div className="celdaInputDAPFBN" style={{ width: '250px' }}>
              <Input2 type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />
              <p>Oposición</p>
            </div>
            {
              displayClaseInfo != 'none' ? (
                <div className="celdaInputDAPFBN" style={{ width: '250px' }}>
                  <Input2 type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />
                  <p>Clase</p>
                </div>
              ) : null
            }

            {displayClases && alumnosClase != undefined && datosPreparador != undefined ? (
              <InfoProgramacionClase datosPreparador={datosPreparador} alumnosClase={alumnosClase} idClase={idClase} />
            ) : ('')}

            <datalist id="busquedaClasesSemanas">{
              busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
          </div>
        );
      } break;
      case 'Buscar_Clase_Word2010': {
        return (
          <Word2010ClaseInfo />
        );
      } break;
      case 'INFO_CURSOS': {
        return (
          <CursosInfo />
        );
      } break;
      case 'CAMBIO_CLASE': {
        return (
          <SincroClases />
        );
      } break;
      case 'ADD_Clase': {
        return (
          <div className='ColCenterStart'>
            <h4 style={{ color: '#3469b7', margin: '25px' }}> AÑADIENDO CLASE </h4>

            <div className='RowCenterCenter' style={{ margin: '10px' }}>


              <div className="celdaInputDAPFBN" style={{ width: '325px' }}>
                <Input2 type="text" id="nombreClase" name="nombreClase" placeholder="Nombre clase" icon="fas fa-search" ref="ref_nombreClase" onChange={this.onChangeInput} />
                <p>Nombre clase</p>
              </div>

            </div>
            <div className='RowCenterCenter' style={{ margin: '10px' }}>


              <div className="celdaInputDAPFBN" style={{ width: '325px' }}>
                <Input2 type="text" id="tipoClase" name="tipoClase" placeholder="PRESENCIAL, ONLINE, CURSO ONLINE,..." icon="fas fa-search" datalist="busquedaTipo" ref="ref_tipoClase" onChange={this.onChangeInput} />
                <p>Tipo de clase/curso</p>
              </div>
              <datalist id="busquedaTipo">

                <option value={'PRESENCIAL'}></option>
                <option value={'ONLINE'}></option>
                <option value={'CURSO ONLINE'}></option>
                <option value={'CURSO BASE'}></option>
              </datalist>

            </div>
            <div className='RowCenterCenter' style={{ margin: '10px' }}>


              <div className="celdaInputDAPFBN" style={{ width: '325px' }}>
                <Input2 type="text" id="preparador" name="preparador" placeholder="Preparador" icon="fas fa-search" datalist="busquedaPre" ref="ref_preparador" onChange={this.onChangeInput} />
                <p>Preparador</p>
              </div>
              <datalist id="busquedaPre">{
                busquedaPre && busquedaPre.map((pre, key) =>
                  <option key={key} value={pre.nombre_usuario}>{pre.nombre + ' '}{pre.apellidos + '.'}</option>
                )
              }</datalist>

            </div>


            <div className='RowCenterCenter' style={{ margin: '10px' }}>

              <div className="celdaInputDAPFBN" style={{ width: '125px' }}>
                <Input2 type="time" step="3600000" id="horaInicio" name="horaInicio" placeholder="Ej: 17:00" icon="fas fa-search" ref="ref_horaInicio" onChange={this.onChangeInput} />
                <p>Hora inicio</p>
              </div>

              <div className="celdaInputDAPFBN" style={{ width: '125px' }}>
                <Input2 type="time" step="3600000" id="horaFin" name="horaFin" placeholder="Ej: 17:00" icon="fas fa-search" ref="ref_horaFin" onChange={this.onChangeInput} />
                <p>Hora Fin</p>
              </div>

            </div>

            <div className='RowCenterCenter' style={{ margin: '10px' }}>

              <div className="celdaInputDAPFBN" style={{ width: '210px' }}>
                <Input2 type="text" id="oposicionSelecionada" name="oposicionSelecionada" placeholder="Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />
                <p>Oposición</p>
              </div>
              <datalist id="busquedaOpo">{
                busquedaOpo && busquedaOpo.map((opo, key) =>
                  <option key={key} value={opo.nombre}></option>
                )
              }</datalist>

              <div className="celdaInputDAPFBN" style={{ width: '210px' }}>
                <Input2 type="text" id="maxAlumnos" name="maxAlumnos" placeholder="25-1000" icon="fas fa-search" ref="ref_maxAlumnos" onChange={this.onChangeInput} />
                <p>Maximo Alumnos</p>
              </div>

            </div>

            <div className='RowCenterCenter' style={{ margin: '10px' }}>

              <div className="celdaInputDAPFBN" style={{ width: '210px' }}>
                <Input2 type="text" id="diaPre" name="diaPre" placeholder="Lunes a Domingo" icon="fas fa-search" ref="ref_diaPre" onChange={this.onChangeInput} />
                <p>Día prensencial</p>
              </div>

              <div className="celdaInputDAPFBN" style={{ width: '210px' }}>
                <Input2 type="text" id="diaOnline" name="diaOnline" placeholder="Lunes a Domingo" icon="fas fa-search" ref="ref_diaOnline" onChange={this.onChangeInput} />
                <p>Día online</p>
              </div>

            </div>



            <div className='RowCenterCenter' style={{ margin: '10px' }}>

              <div className="celdaInputDAPFBN" style={{ width: '240px' }}>
                <Input2 type="text" id="minAproTeo" name="minAproTeo" placeholder="Ej: 50" icon="fas fa-search" ref="ref_minAproTeo" onChange={this.onChangeInput} />
                <p>Teorico Minimo Aprobado %</p>
              </div>

              <div className="celdaInputDAPFBN" style={{ width: '240px' }}>
                <Input2 type="text" id="minAproCP" name="minAproCP" placeholder="Ej: 50" icon="fas fa-search" ref="ref_minAproCP" onChange={this.onChangeInput} />
                <p>Practico Minimo Aprobado %</p>
              </div>

            </div>

            <div className='RowCenterCenter' style={{ margin: '25px' }}>
              <Boton id={"addClase"} icon1={""} icon2={""} texto={"Añadir clase"} onClick={this.onHandleClic} />
            </div>


          </div>
        );
      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}

export default OptionBar;

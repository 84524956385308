// Actions
const GEN_DEL_PREG = 'GEN_DEL_PREG';
const ADD_PREG_TO_DEL = 'ADD_PREG_TO_DEL';
const DEL_PREG_TO_DEL = 'DEL_PREG_TO_DEL';
const VACIAR_PREG_TO_DEL = 'VACIAR_PREG_TO_DEL';

export function genPregToDelete(preguntaInicial) {
    return {
	    type: GEN_DEL_PREG,
      value: preguntaInicial
    };
}
export function addPregunta(preguntaNew) {
    return {
      type: ADD_PREG_TO_DEL,
      value: preguntaNew
    };
}
export function delPregunta(idPregToDel) {
    return {
	    type: DEL_PREG_TO_DEL,
      idPreg: idPregToDel
    };
}
export function vaciarPregToDelete() {
    return {
	    type: VACIAR_PREG_TO_DEL
    };
}

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';


//Assets
import './css/TestJusticiaAlumnos.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
import { corregirTest, comprobarRespuestas, respuestaSbr } from '../../lib/utils/cargarTestJusticia';
// Components
import Input from '../../components/Inputs/Input';
import InputSelect from '../../components/Inputs/InputSelect';
import Submit from '../../components/Inputs/Submit';
import BotonMedio from '../../components/Inputs/BotonMedio';
import InputFile from '../../components/Inputs/InputFile';
import swal from 'sweetalert';

class TestJusticiaAlumnos extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    testJusticiaUserLoad: PropTypes.array,
    cronometro: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      numPregTest: 1,
      numPregSimultaneas: 1,
      pregExtra: [],
      displayEnBlanco: false,
      displayRespondidas: false,
      displayEnDudas: false,
      displayVerNumeros: true,
      pregRespondidasArr: [],
      pregEnBlancoArr: [],
      pregDudosasArr: [],
      displayFinTest: true,
      temporizador100: undefined,
      stop: false,
      statusCrono: true,
      statusCasoPractico: false,
      inicioNumeracion: 0,
      finNumeracion: 10,
      autoLoadPreg: true,
      autoSaveIdInterval: null
    };
    this.loadPreg = this.loadPreg.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.cronometro2 = this.cronometro2.bind(this);
    this.temporizador = this.temporizador.bind(this);
    this.reportPregunta = this.reportPregunta.bind(this);
    this.controlCronometro = this.controlCronometro.bind(this);
    this.actionTestUser = this.actionTestUser.bind(this);

    this.reloadTest = this.reloadTest.bind(this);

    this.autoSaveTest = this.autoSaveTest.bind(this);




  }
  async reloadTest() {

    let pregRespondidasArr = []
    let pregEnBlancoArr = []
    let pregDudosasArr = []
    for (var i = 0; i < this.props.testJusticiaUserLoad.length; i++) {
      if (this.props.testJusticiaUserLoad[i].respuestas.filter(r => r.valorUsuario == 1).length > 0) {

        pregRespondidasArr.push({
          numPreg: this.props.testJusticiaUserLoad[i].numPreg
        })
      } else {

        pregEnBlancoArr.push({
          numPreg: this.props.testJusticiaUserLoad[i].numPreg
        })
      }

      if (this.props.testJusticiaUserLoad[i].dudosa == 1) {
        pregDudosasArr.push({
          numPreg: this.props.testJusticiaUserLoad[i].numPreg
        })
      }
    }

    this.setState({ pregRespondidasArr: pregRespondidasArr, pregEnBlancoArr: pregEnBlancoArr, pregDudosasArr: pregDudosasArr })

  }
  async actionTestUser(e) {
    if (e.target.id == 'verCasoPractico') {
      this.setState({ statusCasoPractico: true })
    } else if (e.target.id == 'cerrarCasoPractico') {
      this.setState({ statusCasoPractico: false })
    } else if (e.target.id == 'marcarComoDudosa') {
      let infoPreg = this.props.testJusticiaUserLoad.find(p => p.numPreg == this.state.numPregTest)
      let numPreg = this.state.numPregTest
      if (isDefined(infoPreg)) {
        let value = infoPreg.dudosa == 0 ? (1) : (0)
        await this.props.loadDudosa(value, numPreg)
        await this.reloadTest()
      }


    } else if (e.target.id == 'cerrarFiltroPreg') {
      this.setState({ displayVerNumeros: true, displayEnDudas: false, displayRespondidas: false, displayEnBlanco: false })


      let position = await this.props.testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)

      let ini = (position - 5)
      let fin = (position - 5) + 10
      if (ini > 0) {

        if (fin <= this.props.testJusticiaUserLoad.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.props.testJusticiaUserLoad.length
          if (position - this.props.testJusticiaUserLoad.length > 5) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            ini = this.props.testJusticiaUserLoad.length - 10 > 0 ? (this.props.testJusticiaUserLoad.length - 10) : (0)
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        ini = 0
        fin = 10
        if (fin <= this.props.testJusticiaUserLoad.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.props.testJusticiaUserLoad.length
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        }
      }


    } else if (e.target.id == 'siguiente') {

      if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {


        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.props.testJusticiaUserLoad.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.testJusticiaUserLoad.length
            if ((ini + 5) - this.props.testJusticiaUserLoad.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.props.testJusticiaUserLoad.length - 10 > 0 ? (this.props.testJusticiaUserLoad.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.props.testJusticiaUserLoad.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.testJusticiaUserLoad.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnBlanco == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if ((ini + 5) - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayRespondidas == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            if ((ini + 5) - this.state.pregRespondidasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnDudas == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if ((ini + 5) - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      }

    } else if (e.target.id == 'anterior') {

      if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {


        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.props.testJusticiaUserLoad.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.testJusticiaUserLoad.length
            if ((ini + 5) - this.props.testJusticiaUserLoad.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.props.testJusticiaUserLoad.length - 10 > 0 ? (this.props.testJusticiaUserLoad.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.props.testJusticiaUserLoad.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.testJusticiaUserLoad.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnBlanco == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if ((ini + 5) - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayRespondidas == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            if ((ini + 5) - this.state.pregRespondidasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnDudas == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if ((ini + 5) - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      }

    } else if (e.target.id == 'autoPreg') {
      this.setState({ autoLoadPreg: !this.state.autoLoadPreg })
    }
  }
  controlCronometro(e) {
    if (e.target.id == 'parar') {
      this.setState({ statusCrono: false })
      clearInterval(this.timerID2);
      swal('Ejercicio pausado', 'Puede navegar por la aplicación. Al volver abrir el test se renaudará automáticamente, también puede darle al play situado en el cronómetro.', 'info')
    } else if (e.target.id == 'renaudar') {
      this.setState({ statusCrono: true })
      this.timerID2 = setInterval(
        () => this.cronometro2(),
        1000
      );
    }
  }
  async reportPregunta(e) {

    if (e.target.id.split('-')[0] == 'preg') {
      let infoPreg = this.props.testJusticiaUserLoad.find(p => p.numPreg == this.state.numPregTest)
      let idUser = this.state.idUser
      if (infoPreg != undefined && idUser != undefined) {
        swal({
          content: {
            element: "input",
            attributes: {
              placeholder: "¿Puedes describir el error aquí?, gracias ^^.",
              height: '100px'
            },
          },
          title: "Reportar Pregunta Nº " + infoPreg.numPreg + ".",
          text: infoPreg.pregunta + `

          La pregunta será revisada lo antes posible.
          `,
          buttons: true,
          dangerMode: true,
          closeOnClickOutside: false,
          icon: "https://i.imgur.com/5cbzqFj.gif",
        })
          .then((name) => {
            if (name) {
              if (name == undefined || !name) { name = "El usuario no indico nada" }
              let data = { textoExplicacion: name, idPreg: infoPreg.idPreg, idR1: infoPreg.respuestas[0].idR, idR2: infoPreg.respuestas[1].idR, idR3: infoPreg.respuestas[2].idR, idR4: infoPreg.respuestas[3].idR, fecha: moment().format('YYYY-MM-DD HH:mm'), idUser: idUser }
              fetch("https://oposiciones-justicia.es/api/usuarios/reportPreguntaJusticia", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                if (data.affectedRows > 0) {
                  return swal("Pregunta reportada", '', 'https://i.giphy.com/media/tIeCLkB8geYtW/giphy.webp');
                } else {
                  return swal("Error Pregunta no reportada!");
                }
              }).catch(function (err) { console.log(err) });

            } else {
              swal("Pregunta no Reportada", 'Debe indicar la indidencia', "success");
            }
          });
      }

    } else if (e.target.id.split('-')[0] == 'pregExtra') {
      let infoPreg = this.state.pregExtra[e.target.id.split('-')[1]]
      let idUser = this.state.idUser
      if (infoPreg != undefined && idUser != undefined) {
        swal({
          content: {
            element: "input",
            attributes: {
              placeholder: "¿Puedes describir el error aquí?, gracias ^^.",
              height: '100px'
            },
          },
          title: "Reportar Pregunta Nº " + infoPreg.numPreg + ".",
          text: infoPreg.pregunta + `

          La pregunta será revisada lo antes posible.
          `,
          buttons: true,
          dangerMode: true,
          closeOnClickOutside: false,
          icon: "https://i.imgur.com/5cbzqFj.gif",
        })
          .then((name) => {
            if (name) {
              if (name == undefined || !name) { name = "El usuario no indico nada" }
              let data = { textoExplicacion: name, idPreg: infoPreg.idPregunta, idUser: idUser }
              fetch("https://oposiciones-justicia.es/api/usuarios/reportPreguntaJusticia", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                if (data.affectedRows > 0) {
                  return swal("Pregunta reportada", '', 'https://i.giphy.com/media/tIeCLkB8geYtW/giphy.webp');
                } else {
                  return swal("Error Pregunta no reportada!");
                }
              }).catch(function (err) { console.log(err) });

            } else {
              swal("Pregunta no Reportada", 'Debe indicar la indidencia', "success");
            }
          });
      }
    }

  }

  cronometro2() {
    let { cronometro } = this.props

    let min = cronometro.minutos
    let seg = cronometro.segundos + 1

    if (seg == 60) {
      min = min + 1
      seg = 0
    }

    this.props.cronoFunc(min, seg)

  }
  async temporizador() {

    let tiempo = this.state.temporizador100
    if (tiempo > 0) {
      let nTiempo = this.state.temporizador100 - 1
      this.setState({ temporizador100: nTiempo })

      let displayText = 'Tiempo estimado #1 segundos.'
      $(".swal-text").text(displayText.replace(/#1/, nTiempo))

      if (this.state.stop == false) {
        setTimeout(
          function () {
            this.temporizador()
          }.bind(this), 1000);
      }

    } else {
      swal({
        title: 'Error al Finalizar Test',
        text: 'No se ha completado el proceso de finalizar test, se recargará la página y podrá volver a intentar finalizar el test.',
        icon: "warning",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then(() => {
        window.location.reload()
      })
    }

  }

  async loadPreg(e) {
    try {
      let key = await e.target.id
      if (key != undefined) {
        let { inicioNumeracion, finNumeracion } = this.state
        let { testJusticiaUserLoad } = this.props

        let value = await testJusticiaUserLoad.slice(inicioNumeracion, finNumeracion)[key].numPreg

        if (this.state.displayEnBlanco == true) {
          value = await this.state.pregEnBlancoArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        } else if (this.state.displayRespondidas == true) {
          value = await this.state.pregRespondidasArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        } else if (this.state.displayEnDudas == true) {
          value = await this.state.pregDudosasArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        }

        if (value > 0 && value <= testJusticiaUserLoad.length) {
          this.setState({ numPregTest: value })


          if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
            let position = await testJusticiaUserLoad.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                if (position - testJusticiaUserLoad.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayEnBlanco == true) {

            let position = await this.state.pregEnBlancoArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayRespondidas == true) {

            let position = await this.state.pregRespondidasArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayEnDudas == true) {

            let position = await this.state.pregDudosasArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          }




        } else {
          if (e.target.value == '' || e.target.value == undefined) {

          } else {
            swal('La pregunta: ' + value + ' no existe', '', 'error')
          }

        }

        this.reloadTest()
      }
    } catch (e) { console.log(e) }
  }
  async autoSaveTest() {
    if (this.state.autoSaveIdInterval != null && this.state.autoSaveIdInterval != undefined) {
      clearTimeout(this.state.autoSaveIdInterval)
      this.setState({ autoSaveIdInterval: null })
      // console.log('ENTRO PRIMERO')
    }

    let idInterval = setTimeout(async () => {

      let dat = {
        idUser: this.props.idUser
      }
      let response0 = await fetch('https://oposiciones-justicia.es/api/usuarios/autoGuardarTest', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
      });
      if (!response0.ok) {
        throw Error(response0.statusText);
      }
      let json0 = await response0.json();
      let cls0 = await json0;


      // guardamos el test en la nube
      let minutos = this.props.cronometro.minutos
      let segundos = this.props.cronometro.segundos
      let test = await this.props.testJusticiaUserLoad

      let data = {
        idUser: this.props.idUser,
        test: JSON.stringify(test).replace(/\r?\n|\r/g, ' ').replace(/'/g, ' '),
        minutos: minutos,
        segundos: segundos
      }
      // guardarTestEnLaNube

      let url = cls0.length == 0 ? 'https://oposiciones-justicia.es/api/usuarios/autoGuardarTestEnLaNube' : 'https://oposiciones-justicia.es/api/usuarios/autoGuardarTestEnLaNubeUPDT'

      let response = await fetch(url, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

    }, 1000);

    this.setState({ autoSaveIdInterval: idInterval })
  }
  async handleOnSubmit(e) {
    if (e.target.id == 'siguiente') {
      let { numPregTest } = this.state
      let { testJusticiaUserLoad } = this.props
      let nextPreg = parseInt(numPregTest) + 1
      if (nextPreg <= testJusticiaUserLoad.length) {


        this.setState({ numPregTest: nextPreg })


        if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
          let position = await testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                if (position - testJusticiaUserLoad.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {

          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayRespondidas == true) {

          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnDudas == true) {

          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }

        this.reloadTest()

      } else {
        swal('No hay más preguntas', '', 'info')
      }

    } else if (e.target.id == 'anterior') {
      let { numPregSimultaneas, numPregTest, pregExtra } = this.state
      let { testJusticiaUserLoad } = this.props
      let nextPreg = parseInt(numPregTest) - parseInt(numPregSimultaneas)
      if (await nextPreg <= 0) {
        nextPreg = 1
      }

      if (nextPreg > 0) {

        this.setState({ numPregTest: nextPreg })

        if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
          let position = await testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                if (position - testJusticiaUserLoad.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= testJusticiaUserLoad.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = testJusticiaUserLoad.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {

          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayRespondidas == true) {

          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnDudas == true) {

          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }


        this.reloadTest()
      } else {
        swal('No hay más preguntas', '', 'info')
      }

    } else if (e.target.id.split('-')[0] == "selectRespuesta") {

      this.autoSaveTest()

      let numPreg = e.target.id.split('-')[2]
      let posRes = e.target.id.split('-')[1]
      let idR = e.target.id.split('-')[3]

      let { testJusticiaUserLoad } = this.props
      let pregunta = await testJusticiaUserLoad.find(p => p.numPreg == numPreg)

      if (pregunta != undefined) {
        await this.props.selectPreg(numPreg, posRes, idR)

        await this.reloadTest()



        let { numPregTest } = this.state

        let valorUsuario = await pregunta.respuestas.filter(r => r.idR == idR)[0].valorUsuario

        if (valorUsuario == 1 && this.state.autoLoadPreg == true) {


          let nextPreg = parseInt(numPregTest) + 1

          let position = this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (nextPreg <= testJusticiaUserLoad.length && position != -1) {


            this.setState({ numPregTest: nextPreg })


            if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
              let position = await testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
              if (position != -1) {
                let ini = (position - 5)
                let fin = (position - 5) + 10
                if (ini > 0) {

                  if (fin <= testJusticiaUserLoad.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = testJusticiaUserLoad.length
                    if (position - testJusticiaUserLoad.length > 5) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                } else {
                  ini = 0
                  fin = 10
                  if (fin <= testJusticiaUserLoad.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = testJusticiaUserLoad.length
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  }
                }
              }

            } else if (this.state.displayEnBlanco == true) {

              let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

              if (position != -1) {
                let ini = (position - 5)
                let fin = (position - 5) + 10
                if (ini > 0) {

                  if (fin <= this.state.pregEnBlancoArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregEnBlancoArr.length
                    if (position - this.state.pregEnBlancoArr.length > 5) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                } else {
                  ini = 0
                  fin = 10
                  if (fin <= this.state.pregEnBlancoArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregEnBlancoArr.length
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  }
                }
              }

            } else if (this.state.displayRespondidas == true) {

              let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
              if (position != -1) {
                let ini = (position - 5)
                let fin = (position - 5) + 10
                if (ini > 0) {

                  if (fin <= this.state.pregRespondidasArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregRespondidasArr.length
                    if (position - this.state.pregRespondidasArr.length > 5) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                } else {
                  ini = 0
                  fin = 10
                  if (fin <= this.state.pregRespondidasArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregRespondidasArr.length
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  }
                }
              }

            } else if (this.state.displayEnDudas == true) {

              let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
              if (position != -1) {
                let ini = (position - 5)
                let fin = (position - 5) + 10
                if (ini > 0) {

                  if (fin <= this.state.pregDudosasArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregDudosasArr.length
                    if (position - this.state.pregDudosasArr.length > 5) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                } else {
                  ini = 0
                  fin = 10
                  if (fin <= this.state.pregDudosasArr.length) {
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  } else {
                    fin = this.state.pregDudosasArr.length
                    this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                  }
                }
              }

            }

            this.reloadTest()

          } else {

            let postionOtraPorEncima = this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg > nextPreg)

            let postionOtraPordebajo = this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg < nextPreg)

            if (postionOtraPorEncima != -1) {
              nextPreg = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg)[postionOtraPorEncima].numPreg
              this.setState({ numPregTest: nextPreg })

              if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
                let position = await testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= testJusticiaUserLoad.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = testJusticiaUserLoad.length
                      if (position - testJusticiaUserLoad.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= testJusticiaUserLoad.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = testJusticiaUserLoad.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayEnBlanco == true) {

                let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregEnBlancoArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregEnBlancoArr.length
                      if (position - this.state.pregEnBlancoArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregEnBlancoArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregEnBlancoArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayRespondidas == true) {

                let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregRespondidasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregRespondidasArr.length
                      if (position - this.state.pregRespondidasArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregRespondidasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregRespondidasArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayEnDudas == true) {

                let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregDudosasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregDudosasArr.length
                      if (position - this.state.pregDudosasArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregDudosasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregDudosasArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              }


            } else if (postionOtraPordebajo != -1) {
              nextPreg = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg)[postionOtraPordebajo].numPreg
              this.setState({ numPregTest: nextPreg })


              if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
                let position = await testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= testJusticiaUserLoad.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = testJusticiaUserLoad.length
                      if (position - testJusticiaUserLoad.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = testJusticiaUserLoad.length - 10 > 0 ? (testJusticiaUserLoad.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= testJusticiaUserLoad.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = testJusticiaUserLoad.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayEnBlanco == true) {

                let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregEnBlancoArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregEnBlancoArr.length
                      if (position - this.state.pregEnBlancoArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregEnBlancoArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregEnBlancoArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayRespondidas == true) {

                let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregRespondidasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregRespondidasArr.length
                      if (position - this.state.pregRespondidasArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregRespondidasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregRespondidasArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              } else if (this.state.displayEnDudas == true) {

                let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
                if (position != -1) {
                  let ini = (position - 5)
                  let fin = (position - 5) + 10
                  if (ini > 0) {

                    if (fin <= this.state.pregDudosasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregDudosasArr.length
                      if (position - this.state.pregDudosasArr.length > 5) {
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      } else {
                        ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                        this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                      }
                    }
                  } else {
                    ini = 0
                    fin = 10
                    if (fin <= this.state.pregDudosasArr.length) {
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    } else {
                      fin = this.state.pregDudosasArr.length
                      this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                    }
                  }
                }

              }



            }


          }
        }
      }
    } else if (e.target.id == 'finTest') {
      if (this.props.testJusticiaUserLoad[0].idBloque < 1) {
        // TEST AUTOGENERADO
        await swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, se corregira el test y se finalizará.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              try {

                let dat = {
                  idUser: this.props.idUser
                }
                let response0 = await fetch('https://oposiciones-justicia.es/api/usuarios/autoGuardarTest', {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
                });
                if (!response0.ok) {
                  throw Error(response0.statusText);
                }
                let json0 = await response0.json();
                let cls0 = await json0;

                if (this.state.autoSaveIdInterval != null && this.state.autoSaveIdInterval != undefined) {
                  clearTimeout(this.state.autoSaveIdInterval)
                  this.setState({ autoSaveIdInterval: null })
                }

                if(cls0.length > 0) {

                  let response = await fetch('https://oposiciones-justicia.es/api/usuarios/borrarAutoGuardarTest', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
                  });
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
                  let json = await response.json();
                  let cls = await json;

                }

                await this.setState({ temporizador100: 120, stop: false })
                this.temporizador()
                let displayText = 'Tiempo estimado #1 segundos.'
                swal({
                  title: 'Calculando Resultado',
                  text: displayText.replace(/#1/, this.state.temporizador100),
                  icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })
                await this.setState({ displayFinTest: false })
                await clearInterval(this.timerID)
                let minutos = this.props.cronometro.minutos;
                let segundos = this.props.cronometro.segundos;
                let test = await this.props.testJusticiaUserLoad;

                let resultadosAds = await this.bienMalBlanco(test)

                let data = {
                  correcta: resultadosAds.correcta,
                  mal: resultadosAds.mal,
                  enBlanco: resultadosAds.enBlanco,
                  puntuacion: resultadosAds.puntuacion,
                  puntuacionReal: resultadosAds.puntuacionReal,
                  fecha: moment().format('YYYY-MM-DD'),
                  minutos: minutos,
                  segundos: segundos,
                  idAlumno: this.props.idUser,
                  idClase: test[0].idClase,
                  idTest: test[0].idTest,
                  idPrg: test[0].idPrg,
                  numBloque: test[0].numBloque,
                  idTema: test[0].idTema,
                  numTestBloque: test[0].numTestBloque
                }

                let urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacion'
                if (!isDefined(test[0].idPrg) && isDefined(test[0].idTest)) {
                  urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacionCurso'
                } else {
                  urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacion'
                }

                let response1 = await fetch(urlFetch1, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                if (cls1 != undefined) {
                  let data2 = { test: this.props.testJusticiaUserLoad, idTestExp: cls1.insertId }
                  let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/addExpedienteTest", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;
                  if (cls2 != undefined) {
                    await this.setState({ stop: true })
                    this.props.borrarTestUser()
                    let name = "Test Finalizado";
                    let content = document.createElement('div');
                    content.innerHTML = `
                    <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
                    Puntuación: <b>`+ resultadosAds.puntuacion + `</b>.</br>
                    <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
                    Correctas: <b>`+ resultadosAds.correcta + `</b>.</br>
                    <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
                    Erroneas: <b>`+ resultadosAds.mal + `.</b></br>
                    <i style="color: Dodgerblue;" class="far fa-circle"></i>
                    En Blanco: <b>`+ resultadosAds.enBlanco + `</b>.</br>
                    <i style="color: Dodgerblue;" class="fas fa-stopwatch"></i>
                    Tiempo: <b>`+ minutos + `:` + segundos + ` </b> minutos.`
                    content.style.textAlign = "left";

                    swal({
                      title: name,
                      content: content,
                      icon: "success",
                      buttons: {
                        cancel: "Cerrar Test",
                        catch: {
                          text: "Revisar Test",
                          value: "catch",
                        },
                        defeat: false,
                      },
                      closeOnEsc: false,
                      closeOnClickOutside: false,
                    }).then(async (value) => {
                      switch (value) {

                        case "catch":
                          try {
                            let busquedaLeyes = this.state.busquedaLeyes
                            let idTest = await test[0].idTest
                            let idExp = await cls1.insertId
                            let testNew = []
                            let data11 = { idTest: idTest, idExp: idExp }
                            let response11 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
                              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
                            });
                            if (!response11.ok) {
                              throw Error(response11.statusText);
                            }
                            let json11 = await response11.json();
                            let cls11 = await json11;
                            if (cls11 != undefined && cls11.length > 0) {
                              for (var i = 0; i < await cls11.length; i++) {
                                //  testNew.push({
                                //    dudosa: cls11[i].dudosa,
                                //   idTest: cls11[i].idTest, numPreg: cls11[i].numPreg, idPreg: cls11[i].idPregunta,
                                //   pregunta: cls11[i].pregunta, tipoPregunta: cls11[i].tipoPregunta, articulo: cls11[i].articulo,
                                //   idLey: cls11[i].idLey,
                                //   siglasLey: busquedaLeyes.find(l=> l.id_ley == cls11[i].idLey).siglas_ley,
                                //   nombreLey: busquedaLeyes.find(l=> l.id_ley == cls11[i].idLey).nombre_ley,
                                //   idR1: cls11[i].idR1, idR2: cls11[i].idR2, idR3: cls11[i].idR3, idR4: cls11[i].idR4,
                                //   respuestas: []
                                // })
                                testNew.push({
                                  dudosa: cls11[i].dudosa,
                                  idTest: cls11[i].idTest, numPreg: cls11[i].numPreg, idPreg: cls11[i].idPregunta,
                                  pregunta: cls11[i].pregunta, tipoPregunta: cls11[i].tipoPregunta, articulo: cls11[i].articulo,
                                  idLey: cls11[i].idLey,
                                  siglasLey: busquedaLeyes.find(l => l.id_ley == cls11[i].idLey).siglas_ley,
                                  nombreLey: busquedaLeyes.find(l => l.id_ley == cls11[i].idLey).nombre_ley,
                                  idR1: cls11[i].idR1, idR2: cls11[i].idR2, idR3: cls11[i].idR3, idR4: cls11[i].idR4,
                                  idClase: cls11[i].idClase != null ? (cls11[i].idClase) : (""),
                                  idPrg: cls11[i].idPrg != null ? (cls11[i].idPrg) : (""),
                                  idBloque: cls11[i].idBloque != null ? (cls11[i].idBloque) : (""),
                                  numBloque: cls11[i].numBloque != null ? (cls11[i].numBloque) : (""),
                                  numTestBloque: cls11[i].numTestBloque != null ? (cls11[i].numTestBloque) : (""),
                                  idTema: cls11[i].idTema != null ? (cls11[i].idTema) : (""),
                                  minutos: cls11[i].minutos,
                                  segundos: cls11[i].segundos,
                                  fecha: cls11[i].fecha,
                                  puntuacion: cls11[i].puntuacion,
                                  respuestas: []
                                })
                                let r1Sbr = cls11[i].R1
                                if (cls11[i].subrayado1 != '' && cls11[i].subrayado1 != undefined) {
                                  r1Sbr = await respuestaSbr(cls11[i].R1, cls11[i].subrayado1)
                                }

                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR1,
                                  respuesta: r1Sbr,
                                  validez: cls11[i].validezR1,
                                  subrayado: cls11[i].subrayado1,
                                  nota: cls11[i].nota1,
                                  valorUser: cls11[i].r1ValorUser
                                })
                                let r2Sbr = cls11[i].R2
                                if (cls11[i].subrayado2 != '' && cls11[i].subrayado2 != undefined) {
                                  r2Sbr = await respuestaSbr(cls11[i].R2, cls11[i].subrayado2)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR2,
                                  respuesta: r2Sbr,
                                  validez: cls11[i].validezR2,
                                  subrayado: cls11[i].subrayado2,
                                  nota: cls11[i].nota2,
                                  valorUser: cls11[i].r2ValorUser
                                })
                                let r3Sbr = cls11[i].R3
                                if (cls11[i].subrayado3 != '' && cls11[i].subrayado3 != undefined) {
                                  r3Sbr = await respuestaSbr(cls11[i].R3, cls11[i].subrayado3)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR3,
                                  respuesta: r3Sbr,
                                  validez: cls11[i].validezR3,
                                  subrayado: cls11[i].subrayado3,
                                  nota: cls11[i].nota3,
                                  valorUser: cls11[i].r3ValorUser
                                })
                                let r4Sbr = cls11[i].R4
                                if (cls11[i].subrayado4 != '' && cls11[i].subrayado4 != undefined) {
                                  r4Sbr = await respuestaSbr(cls11[i].R4, cls11[i].subrayado4)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR4,
                                  respuesta: r4Sbr,
                                  validez: cls11[i].validezR4,
                                  subrayado: cls11[i].subrayado4,
                                  nota: cls11[i].nota4,
                                  valorUser: cls11[i].r4ValorUser
                                })
                              }
                              if (testNew != undefined) {
                                await this.props.borrarTestUser()
                                let testRespuestasBien = await comprobarRespuestas(testNew)
                                await this.props.loadTestJusticia(undefined)
                                // await this.props.loadCasoPractico(undefined, 'htmlText')
                                await this.props.loadTestJusticia(testRespuestasBien)

                                setTimeout(
                                  function () {
                                    this.props.history.push('/revisionTest');
                                  }.bind(this), 800);

                              }
                            }
                          } catch (e) { console.log(e) }


                          break;

                        default:
                          this.props.borrarTestUser()
                          setTimeout(
                            function () {
                              this.props.history.push('/')
                            }.bind(this), 500);

                      }
                    });

                  }

                }
              } catch (e) {
                console.log(e)
              }
            } else {
              swal("¡Test no finalizado!", 'Puede seguir completando el test', "success");
            }
          });

      } else {
        // test con idBloque
        await swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, se corregira el test y se finalizara.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {

              try {
                let dat = {
                  idUser: this.props.idUser
                }
                let response0 = await fetch('https://oposiciones-justicia.es/api/usuarios/autoGuardarTest', {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
                });
                if (!response0.ok) {
                  throw Error(response0.statusText);
                }
                let json0 = await response0.json();
                let cls0 = await json0;

                if (this.state.autoSaveIdInterval != null && this.state.autoSaveIdInterval != undefined) {
                  clearTimeout(this.state.autoSaveIdInterval)
                  this.setState({ autoSaveIdInterval: null })
                }

                if(cls0.length > 0) {

                  let response = await fetch('https://oposiciones-justicia.es/api/usuarios/borrarAutoGuardarTest', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
                  });
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
                  let json = await response.json();
                  let cls = await json;

                }

                await this.setState({ temporizador100: 120, stop: false })
                this.temporizador()
                let displayText = 'Tiempo estimado #1 segundos.'
                swal({
                  title: 'Calculando Resultado',
                  text: displayText.replace(/#1/, this.state.temporizador100),
                  icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })
                await this.setState({ displayFinTest: false })
                await clearInterval(this.timerID)
                let minutos = this.props.cronometro.minutos;
                let segundos = this.props.cronometro.segundos;
                let test = await this.props.testJusticiaUserLoad;
                let resultadosAds = await this.bienMalBlanco(test)

                let data = {
                  correcta: resultadosAds.correcta,
                  mal: resultadosAds.mal,
                  enBlanco: resultadosAds.enBlanco,
                  puntuacion: resultadosAds.puntuacion,
                  puntuacionReal: resultadosAds.puntuacionReal,
                  fecha: moment().format('YYYY-MM-DD'),
                  minutos: minutos,
                  segundos: segundos,
                  idAlumno: this.state.idUser,
                  idClase: test[0].idClase,
                  idTest: test[0].idTest,
                  idPrg: test[0].idPrg,
                  idBloque: test[0].idBloque,
                  numBloque: test[0].numBloque,
                  idTema: test[0].idTema,
                  numTestBloque: test[0].numTestBloque
                }
                // console.log('data2')
                // console.log(data)

                let urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacionTemaPrg'
                if (!isDefined(test[0].idPrg) && isDefined(test[0].idTest)) {
                  urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacionTemaPrgCurso'
                } else {
                  urlFetch1 = 'https://oposiciones-justicia.es/api/usuarios/addTestPuntuacionTemaPrg'
                }

                let response1 = await fetch(urlFetch1, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                if (cls1 != undefined) {
                  let data2 = { test: this.props.testJusticiaUserLoad, idTestExp: cls1.insertId }
                  let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/addExpedienteTest", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;
                  if (cls2 != undefined) {
                    await this.setState({ stop: true })
                    this.props.borrarTestUser()
                    let name = "Test Finalizado";
                    let content = document.createElement('div');
                    content.innerHTML = `
                    <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
                    Puntuación: <b>`+ resultadosAds.puntuacion + `</b>.</br>
                    <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
                    Correctas: <b>`+ resultadosAds.correcta + `</b>.</br>
                    <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
                    Erroneas: <b>`+ resultadosAds.mal + `.</b></br>
                    <i style="color: Dodgerblue;" class="far fa-circle"></i>
                    En Blanco: <b>`+ resultadosAds.enBlanco + `</b>.</br>
                    <i style="color: Dodgerblue;" class="fas fa-stopwatch"></i>
                    Tiempo: <b>`+ minutos + `:` + segundos + ` </b> minutos.`
                    content.style.textAlign = "left";

                    swal({
                      title: name,
                      content: content,
                      icon: "success",
                      buttons: {
                        cancel: "Cerrar Test",
                        catch: {
                          text: "Revisar Test",
                          value: "catch",
                        },
                        defeat: false,
                      },
                      closeOnEsc: false,
                      closeOnClickOutside: false,
                    }).then(async (value) => {
                      switch (value) {

                        case "catch":
                          try {
                            let busquedaLeyes = this.state.busquedaLeyes
                            let idTest = await test[0].idTest
                            let idExp = await cls1.insertId



                            let testNew = []
                            let data11 = { idTest: idTest, idExp: idExp }
                            // console.log(data11)
                            // console.log(cls2)
                            // console.log(cls1)
                            let response11 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
                              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
                            });
                            if (!response11.ok) {
                              throw Error(response11.statusText);
                            }
                            let json11 = await response11.json();
                            let cls11 = await json11;
                            if (cls11 != undefined && cls11.length > 0) {
                              for (var i = 0; i < await cls11.length; i++) {
                                //  testNew.push({
                                //   dudosa: cls11[i].dudosa,
                                //   idTest: cls11[i].idTest, numPreg: cls11[i].numPreg, idPreg: cls11[i].idPregunta,
                                //   pregunta: cls11[i].pregunta, tipoPregunta: cls11[i].tipoPregunta, articulo: cls11[i].articulo,
                                //   idLey: cls11[i].idLey,
                                //   siglasLey: busquedaLeyes.find(l=> l.id_ley == cls11[i].idLey).siglas_ley,
                                //   nombreLey: busquedaLeyes.find(l=> l.id_ley == cls11[i].idLey).nombre_ley,
                                //   idR1: cls11[i].idR1, idR2: cls11[i].idR2, idR3: cls11[i].idR3, idR4: cls11[i].idR4,
                                //   respuestas: []
                                // })
                                testNew.push({
                                  dudosa: cls11[i].dudosa,
                                  idTest: cls11[i].idTest, numPreg: cls11[i].numPreg, idPreg: cls11[i].idPregunta,
                                  pregunta: cls11[i].pregunta, tipoPregunta: cls11[i].tipoPregunta, articulo: cls11[i].articulo,
                                  idLey: cls11[i].idLey,
                                  siglasLey: busquedaLeyes.find(l => l.id_ley == cls11[i].idLey).siglas_ley,
                                  nombreLey: busquedaLeyes.find(l => l.id_ley == cls11[i].idLey).nombre_ley,
                                  idR1: cls11[i].idR1, idR2: cls11[i].idR2, idR3: cls11[i].idR3, idR4: cls11[i].idR4,
                                  idClase: cls11[i].idClase != null ? (cls11[i].idClase) : (""),
                                  idPrg: cls11[i].idPrg != null ? (cls11[i].idPrg) : (""),
                                  idBloque: cls11[i].idBloque != null ? (cls11[i].idBloque) : (""),
                                  numBloque: cls11[i].numBloque != null ? (cls11[i].numBloque) : (""),
                                  numTestBloque: cls11[i].numTestBloque != null ? (cls11[i].numTestBloque) : (""),
                                  idTema: cls11[i].idTema != null ? (cls11[i].idTema) : (""),
                                  minutos: cls11[i].minutos,
                                  segundos: cls11[i].segundos,
                                  fecha: cls11[i].fecha,
                                  puntuacion: cls11[i].puntuacion,
                                  respuestas: []
                                })
                                let r1Sbr = cls11[i].R1
                                if (cls11[i].subrayado1 != '' && cls11[i].subrayado1 != undefined) {
                                  r1Sbr = await respuestaSbr(cls11[i].R1, cls11[i].subrayado1)
                                }

                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR1,
                                  respuesta: r1Sbr,
                                  validez: cls11[i].validezR1,
                                  subrayado: cls11[i].subrayado1,
                                  nota: cls11[i].nota1,
                                  valorUser: cls11[i].r1ValorUser
                                })
                                let r2Sbr = cls11[i].R2
                                if (cls11[i].subrayado2 != '' && cls11[i].subrayado2 != undefined) {
                                  r2Sbr = await respuestaSbr(cls11[i].R2, cls11[i].subrayado2)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR2,
                                  respuesta: r2Sbr,
                                  validez: cls11[i].validezR2,
                                  subrayado: cls11[i].subrayado2,
                                  nota: cls11[i].nota2,
                                  valorUser: cls11[i].r2ValorUser
                                })
                                let r3Sbr = cls11[i].R3
                                if (cls11[i].subrayado3 != '' && cls11[i].subrayado3 != undefined) {
                                  r3Sbr = await respuestaSbr(cls11[i].R3, cls11[i].subrayado3)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR3,
                                  respuesta: r3Sbr,
                                  validez: cls11[i].validezR3,
                                  subrayado: cls11[i].subrayado3,
                                  nota: cls11[i].nota3,
                                  valorUser: cls11[i].r3ValorUser
                                })
                                let r4Sbr = cls11[i].R4
                                if (cls11[i].subrayado4 != '' && cls11[i].subrayado4 != undefined) {
                                  r4Sbr = await respuestaSbr(cls11[i].R4, cls11[i].subrayado4)
                                }
                                testNew[testNew.length - 1].respuestas.push({
                                  idR: cls11[i].idR4,
                                  respuesta: r4Sbr,
                                  validez: cls11[i].validezR4,
                                  subrayado: cls11[i].subrayado4,
                                  nota: cls11[i].nota4,
                                  valorUser: cls11[i].r4ValorUser
                                })
                              }
                              if (testNew != undefined) {
                                let testRespuestasBien = await comprobarRespuestas(testNew)
                                await this.props.borrarTestUser()
                                await this.props.loadTestJusticia(undefined)
                                //await this.props.loadCasoPractico(undefined, 'htmlText')
                                await this.props.loadTestJusticia(testRespuestasBien)

                                setTimeout(
                                  function () {
                                    this.props.history.push('/revisionTest');
                                  }.bind(this), 800);
                              }
                            }
                          } catch (e) { console.log(e) }


                          break;

                        default:
                          this.props.borrarTestUser()
                          setTimeout(
                            function () {
                              this.props.history.push('/')
                            }.bind(this), 800);

                      }
                    });


                  }

                }
              } catch (e) {
                console.log(e)
              }
            } else {
              swal("¡Test no finalizado!", 'Puede seguir completando el test', "success");
            }
          });
      }
    } else if (e.target.id == 'saveTest') {

      let data2x3 = {
        idUser: this.props.idUser
      }
      let response = await fetch('https://oposiciones-justicia.es/api/usuarios/testNube', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2x3)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      if (cls.length < 6) {


        this.setState({ statusCrono: false })
        clearInterval(this.timerID2);

        swal({
          text: "Escriba un nombre para recordar el test, que se guardará para finalizar más tarde desde cualquier dispositivo.",
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: {
            element: "input",
            attributes: {
              placeholder: "Escriba el nombre para recordar el test.",
              type: "text",
            },
          },
          buttons: ["Cancelar", 'Guardar test'],
        })
          .then(async (value) => {

            if (value == null || value == '' || value == undefined) {

              swal("No se guardo el test", 'Puede continuar completando el ejercicio. Para guardarlo y terminarlo más tarde debe añadir un nombre al test, no deje el espacio en blanco.', 'info');
              this.setState({ statusCrono: true })
              this.timerID2 = setInterval(
                () => this.cronometro2(),
                1000
              );

            } else {
              await swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, se guardará el test y podrá cargarlo para finalizarlo más tarde desde cualquier dispositivo.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
                .then(async (willDelete) => {
                  if (willDelete) {
                    let minutos = this.props.cronometro.minutos
                    let segundos = this.props.cronometro.segundos
                    let test = await this.props.testJusticiaUserLoad

                    let data = {
                      idUser: this.props.idUser,
                      test: JSON.stringify(test).replace(/\r?\n|\r/g, ' ').replace(/'/g, ' '),
                      minutos: minutos,
                      segundos: segundos,
                      nombre: value
                    }

                    let response = await fetch('https://oposiciones-justicia.es/api/usuarios/guardarTestEnLaNube', {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                    });
                    if (!response.ok) {
                      throw Error(response.statusText);
                    }
                    let json = await response.json();
                    let cls = await json;

                    if (cls.affectedRows > 0) {

                      await swal({
                        title: 'Test guardado',
                        text: '¡Recargando web!',
                        icon: "success",
                        buttons: false,
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                        timer: 1500,
                      }).then(() => {
                        this.props.borrarTestUser()
                        window.location.href = "https://oposiciones-justicia.es/testPendienteFinalizar";
                      })

                    } else {
                      swal("¡Algo fallo!", 'No se guardo el test vuelva a intentarlo', "success");
                    }


                  } else {
                    swal("¡Test no guardado!", 'Puede seguir completando el test', "success");
                    this.setState({ statusCrono: true })
                    this.timerID2 = setInterval(
                      () => this.cronometro2(),
                      1000
                    );
                  }
                });
            }

          });

      } else {
        swal('Error', 'Solo puede guardar 6 ejercicios ya tiene el máximo. Elimine o finalice algun test guardado.', 'info')
      }





      // TEST AUTOGENERADO



    } else if (e.target.id == 'infoPuntuacion') {
      try {
        let test = this.props.testJusticiaUserLoad
        let data = { idClase: test[0].idClase }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        let restamos = await cls1[0].malJusticia;
        let restamosBlanco = await cls1[0].restaEnBlanco;
        let totalPreg = await cls1[0].totalPregRegla;
        let aciertoCP = await cls1[0].aciertoSuma;

        let restaMal = parseFloat(parseFloat(totalPreg / test.length) * restamos).toFixed(2)
        let restaEnBlanco = parseFloat(parseFloat(totalPreg / test.length) * restamosBlanco).toFixed(2)
        let sumaBien = parseFloat(parseFloat(totalPreg / test.length) * aciertoCP).toFixed(2)
        totalPreg = await cls1[0].totalPregRegla;
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoClsMinAprobar", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        let porcentajeAprobado = await cls2[0].minJusticiaTest

        let name = "Test " + test.length + " preguntas - Puntuación";
        let content = document.createElement('div');
        content.innerHTML = `
        <strong>Acierto: +`+ sumaBien + `</strong></br>
        <strong> Erroneas: -`+ restaMal + `</strong></br>
        <strong>En Blanco: -`+ restaEnBlanco + `</strong></br></br>
        <strong>Puntuación mínima: `+ (totalPreg * (porcentajeAprobado / 100)).toFixed(2) + ` Puntos</strong> de 100</br>
        
        Necesita `+ (test.length * (porcentajeAprobado / 100)).toFixed(2) + ` preguntas correctas y ninguna erronea.
        </br></br></br>
        La puntuación se calcula proporcial a un test con `+ totalPreg + ` preguntas. Acierto: +1, Erroneas: -` + restamos.toFixed(2) + ` y En Blanco: -` + restamosBlanco.toFixed(2) + `.`
        content.style.textAlign = "left";
        swal({
          title: name,
          content: content,
          icon: "info",
        })
      } catch (e) { console.log(e) }
    } else if (e.target.id == 'respondidas') {


      let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)
      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            if (position - this.state.pregRespondidasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregRespondidasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregRespondidasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      await this.setState({ displayVerNumeros: true, displayEnDudas: false, displayRespondidas: true, displayEnBlanco: false })

    } else if (e.target.id == 'enBlanco') {


      let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)

      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if (position - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      await this.setState({ displayVerNumeros: true, displayEnDudas: false, displayRespondidas: false, displayEnBlanco: true })
    } else if (e.target.id == 'dudosasVer') {


      let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)
      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if (position - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      await this.setState({ displayVerNumeros: true, displayEnDudas: true, displayRespondidas: false, displayEnBlanco: false })
    }


  }
  async bienMalBlanco(test) {
    try {
      let correcta = 0
      let mal = 0
      let enBlanco = 0

      for (var i = 0; i < test.length; i++) {
        let pregunta = test[i]
        let pregTest = await corregirTest(pregunta)
        if (pregTest == 1) {
          correcta = correcta + 1
          let numPreg = pregunta.numPreg
          let value = 1
          this.props.puntosRes(value, numPreg)
        } else if (pregTest == 2) {
          mal = mal + 1
          let numPreg = pregunta.numPreg
          let value = 2
          this.props.puntosRes(value, numPreg)
        } else if (pregTest == 0) {
          enBlanco = enBlanco + 1
          let numPreg = pregunta.numPreg
          let value = 0
          this.props.puntosRes(value, numPreg)
        }
      }

      let restamos = 0.25;
      let totalPreg = 100
      let restamosBlanco = 0;
      let puntuacionRealPorcentaje = 60
      let aciertoJus = 1
      let data = { idClase: test[0].idClase }
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      restamos = await cls1[0].malJusticia;
      restamosBlanco = await cls1[0].restaEnBlanco;
      totalPreg = await cls1[0].totalPregRegla;
      puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
      aciertoJus = await cls1[0].aciertoSuma;

      let multiploMalF = parseFloat(parseFloat(totalPreg / test.length) * restamos)
      let multiploMalBlanco = parseFloat(parseFloat(totalPreg / test.length) * restamosBlanco)
      let multiploAcierto = parseFloat(parseFloat(totalPreg / test.length) * aciertoJus)

      let correctaF = parseFloat(correcta)
      let malF = await parseFloat(parseFloat(mal) * multiploMalF).toFixed(2)
      let enBlancoFF = await parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
      let puntuacion = await parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
      let puntuacionReal = await parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 100)).toFixed(2)

      let todo = { correcta: correcta, mal: mal, enBlanco: enBlanco, puntuacion: puntuacion, puntuacionReal: puntuacionReal }
      return todo
    } catch (e) { console.log(e) }
  }
  async componentDidMount() {
    let response1xyz = await fetch("https://oposiciones-justicia.es/api/temas/allTemas");
    if (!response1xyz.ok) {
      throw Error(response1xyz.statusText);
    }
    let json1xyz = await response1xyz.json();
    let cls1xyz = await json1xyz;
    this.setState({ todoTemasBD: cls1xyz })

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))

    if (this.props.testJusticiaUserLoad != undefined && this.props.testJusticiaUserLoad.length > 0) {

      this.timerID2 = setInterval(
        () => this.cronometro2(),
        1000
      );

      let nextPreg = 1
      for (var i = 0; i < this.props.testJusticiaUserLoad.length; i++) {
        let todas = false;
        for (var j = 0; j < this.props.testJusticiaUserLoad[i].respuestas.length; j++) {
          if (this.props.testJusticiaUserLoad[i].respuestas[j].valorUsuario == 1) {
            todas = true;
          }
        }
        if (todas != true) {
          nextPreg = await this.props.testJusticiaUserLoad[i].numPreg;
          break
        }
      }
      await this.setState({ numPregTest: nextPreg })
      await this.setState({ pregExtra: [] })

      if (this.state.displayEnBlanco == false && this.state.displayRespondidas == false && this.state.displayEnDudas == false) {
        let position = await this.props.testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.props.testJusticiaUserLoad.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.props.testJusticiaUserLoad.length
              if (position - this.props.testJusticiaUserLoad.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.props.testJusticiaUserLoad.length - 10 > 0 ? (this.props.testJusticiaUserLoad.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.props.testJusticiaUserLoad.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.props.testJusticiaUserLoad.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayEnBlanco == true) {

        let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregEnBlancoArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregEnBlancoArr.length
              if (position - this.state.pregEnBlancoArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregEnBlancoArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregEnBlancoArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayRespondidas == true) {

        let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregRespondidasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregRespondidasArr.length
              if (position - this.state.pregRespondidasArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregRespondidasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregRespondidasArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayEnDudas == true) {

        let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregDudosasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregDudosasArr.length
              if (position - this.state.pregDudosasArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregDudosasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregDudosasArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      }
      this.reloadTest()

      let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
      fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(cls => {
        this.setState({ idUser: cls[0].id_usuario });
      }).catch(error => console.log('Object fecth failed', error))



      let datadgr4 = { idUser: this.props.idUser }

      let response2ERT = await fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(datadgr4)
      });
      if (!response2ERT.ok) {
        throw Error(response2ERT.statusText);
      }
      let json2ERT = await response2ERT.json();
      let cls2ERT = await json2ERT;
      if (cls2ERT != undefined && cls2ERT.length > 0) {
        this.setState({ cursos: cls2ERT })
      }

      //let claseObject = await this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
      //if(claseObject != undefined){

      let data1 = { idClase: this.props.testJusticiaUserLoad[0].idClase }

      let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      if (cls1 != undefined) {
        this.setState({ busquedaClasesFecha: cls1 });
      }


      //}



    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID2);
  }


  render() {
    const { isLogged, nombreUser, tipoUser, testJusticiaUserLoad, cronometro, semanasFacturadas, temasFacturados } = this.props; // testJusticiaUserLoad
    const { statusCrono, numPregTest, numPregSimultaneas, pregExtra, pregRespondidasArr, pregEnBlancoArr, displayRespondidas, displayEnBlanco, displayVerNumeros, displayFinTest, statusCasoPractico, inicioNumeracion, finNumeracion, pregDudosasArr, displayEnDudas, autoLoadPreg, todoTemasBD } = this.state;
    const nivelRequired = 1;
    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1) && (semanasFacturadas != null || tipoUser >= 58 || temasFacturados != null)) {
      if (testJusticiaUserLoad && testJusticiaUserLoad != undefined && testJusticiaUserLoad.length > 0) {

        return (

          testJusticiaUserLoad && testJusticiaUserLoad.length > 0 ? (
            <div className="bodyHacerTest">

              <div className="BHT-header">

                <div className="BHT-H-tipoTest">
                  Test Teórico
                </div>

                <div className="BHT-H-infoTest">
                  {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && isDefined(testJusticiaUserLoad[0].idClase) && testJusticiaUserLoad[0].idClase > 0 ? (
                    <div>
                      {this.state.cursos && this.state.cursos.length > 0 && this.state.cursos.find(c => c.idClase == testJusticiaUserLoad[0].idClase).diaSemanal}
                    </div>
                  ) : null}

                  {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && testJusticiaUserLoad[0].idTest == null ? (
                    <div>
                      Test autogenerado
                    </div>
                  ) : null}


                  {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && isDefined(testJusticiaUserLoad[0].idPrg) && testJusticiaUserLoad[0].idPrg > 0 ? (
                    <div>
                      Clase Nº
                      {this.state.busquedaClasesFecha && this.state.busquedaClasesFecha.length > 0 && this.state.busquedaClasesFecha.find(c => c.idPrograma == testJusticiaUserLoad[0].idPrg).semanaClase}
                    </div>
                  ) : (
                    testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && isDefined(testJusticiaUserLoad[0].idTema) && todoTemasBD != undefined ? (
                      <div>
                        {todoTemasBD.find(t => t.id_tema == testJusticiaUserLoad[0].idTema).titulo_tema
                          + ' (' + todoTemasBD.find(t => t.id_tema == testJusticiaUserLoad[0].idTema).nombre_tema + ')'}
                      </div>
                    ) : null

                  )}
                  {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && testJusticiaUserLoad[0].numBloque > 0 && isDefined(testJusticiaUserLoad[0].idPrg) ? (
                    <div>
                      Bloque {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 ? (testJusticiaUserLoad[0].numBloque) : null}
                    </div>
                  ) : null}
                  {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 && testJusticiaUserLoad[0].numTestBloque > 0 ? (
                    <div style={{ borderRight: 'unset' }}>
                      Test {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 ? (testJusticiaUserLoad[0].numTestBloque) : null}
                    </div>
                  ) : null}

                </div>

                <div className="BHT-H-totalPreg">
                  Total Preguntas: {testJusticiaUserLoad && testJusticiaUserLoad.length > 0 ? (testJusticiaUserLoad.length) : null}
                </div>

              </div>



              <div className="BHT-contentTest">

                <div className="BHT-CT-navPreg">
                  <div className="BHT-CT-NP-item" id="respondidas" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} style={displayRespondidas == false ? ({}) : ({ fontWeight: 'bold' })} >
                    <div id="respondidas" style={{ background: '#c8c8c8', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }}></div>
                    Completadas : {pregRespondidasArr && pregRespondidasArr.length > 0 ? (pregRespondidasArr.length) : (' 0')}
                  </div>
                  <div className="BHT-CT-NP-item" id="enBlanco" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} style={displayEnBlanco == false ? ({}) : ({ fontWeight: 'bold' })}>
                    <div style={{ background: '#FFFFFF', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px', boxShadow: '0px 0px 4px 0px grey' }}></div>
                    En blanco : {pregEnBlancoArr && pregEnBlancoArr.length > 0 ? (pregEnBlancoArr.length) : (' 0')}
                  </div>
                  <div className="BHT-CT-NP-item" id="dudosasVer" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} style={displayEnDudas == false ? ({}) : ({ fontWeight: 'bold' })}>
                    <div style={{ background: '#707070', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }} ></div>
                    Dudosas : {pregDudosasArr && pregDudosasArr.length > 0 ? (pregDudosasArr.length) : (' 0')}
                  </div>

                  <i id="cerrarFiltroPreg" style={statusCasoPractico == false && displayRespondidas == true || displayEnBlanco == true || displayEnDudas == true ? ({ display: 'block' }) : ({ display: 'none' })} title={'Ver todas las preguntas'} className="fas fa-times-circle limpiarFiltroPreg" onClick={statusCrono == true ? (this.actionTestUser) : (null)} />
                </div>

                <div className="BHT-CT-pregNavLoad">
                  <div className="BHT-CT-PNL-icon">
                    <i className="fas fa-angle-left" id="anterior" onClick={statusCrono == true ? (this.actionTestUser) : (null)}></i>
                  </div>

                  <div className="BHT-CT-PNL-bodyNums">

                    {displayRespondidas && displayRespondidas == true ? (
                      pregRespondidasArr.length > 0 && pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                        <div id={key} onClick={statusCrono == true ? (this.loadPreg) : (null)} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ border: '3px solid #3469b7' })} className={
                          pregDudosasArr && pregDudosasArr.length > 0 && pregDudosasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ?
                            ("BHT-CT-PNL-numCelda-dudosa") :
                            ("BHT-CT-PNL-numCelda-res")}>
                          {preg.numPreg}
                        </div>
                      )
                    ) : null}

                    {displayEnBlanco && displayEnBlanco == true ? (
                      pregEnBlancoArr.length > 0 && pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                        <div id={key} onClick={statusCrono == true ? (this.loadPreg) : (null)} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ border: '3px solid #3469b7' })} className={
                          pregDudosasArr && pregDudosasArr.length > 0 && pregDudosasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ?
                            ("BHT-CT-PNL-numCelda-dudosa") :
                            ("BHT-CT-PNL-numCelda-blanco")}>
                          {preg.numPreg}
                        </div>
                      )
                    ) : null}

                    {displayEnDudas && displayEnDudas == true ? (
                      pregDudosasArr.length > 0 && pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                        <div id={key} onClick={statusCrono == true ? (this.loadPreg) : (null)} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ border: '3px solid #3469b7' })} className="BHT-CT-PNL-numCelda-dudosa">
                          {preg.numPreg}
                        </div>
                      )
                    ) : null}

                    {testJusticiaUserLoad && displayEnBlanco == false && displayEnDudas == false && displayRespondidas == false ? (
                      testJusticiaUserLoad.length > 0 && testJusticiaUserLoad.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                        <div id={key} onClick={statusCrono == true ? (this.loadPreg) : (null)} className={

                          pregDudosasArr && pregDudosasArr.length > 0 && pregDudosasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                            numPregTest && numPregTest != preg.numPreg ?
                              ("BHT-CT-PNL-numCelda-dudosa") :
                              ("BHT-CT-PNL-numCelda-selected BHT-CT-PNL-numCelda-dudosa")
                          ) : (
                            pregEnBlancoArr && pregEnBlancoArr.length > 0 && pregEnBlancoArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                              numPregTest && numPregTest != preg.numPreg ?
                                ("BHT-CT-PNL-numCelda-blanco") :
                                ("BHT-CT-PNL-numCelda-selected BHT-CT-PNL-numCelda-blanco")
                            ) : (
                              pregRespondidasArr && pregRespondidasArr.length > 0 && pregRespondidasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                                numPregTest && numPregTest != preg.numPreg ?
                                  ("BHT-CT-PNL-numCelda-res") :
                                  ("BHT-CT-PNL-numCelda-selected BHT-CT-PNL-numCelda-res")
                              ) : null
                            )
                          )

                        }>
                          {preg.numPreg}
                        </div>
                      )
                    ) : null}




                  </div>

                  <div className="BHT-CT-PNL-icon">
                    <i className="fas fa-angle-right" id="siguiente" onClick={statusCrono == true ? (this.actionTestUser) : (null)}></i>
                  </div>
                </div>

                <div className="BHT-CT-timerFinTest" style={{ flexFlow: 'row wrap' }}>
                  <div style={{ margin: '10px' }} title="Tiempo transcurrido en minutos." className="BHT-CT-TFT-timer">

                    {cronometro.minutos > 9 ? (
                      cronometro.minutos
                    ) : (
                      '0' + cronometro.minutos
                    )}
                    :
                    {cronometro.segundos > 9 ? (
                      cronometro.segundos
                    ) : (
                      '0' + cronometro.segundos
                    )}

                    <i onClick={this.controlCronometro} className={statusCrono == false ? ("fas fa-play") : ("fas fa-pause")} id={statusCrono == false ? ("renaudar") : ("parar")} title={statusCrono == false ? ("Renaudar test.") : ("Parar test. 'Podrá finalizarlo más tarde'")} />

                  </div>

                  <div style={{ margin: '10px' }} className="BHT-CT-TFT-finTest" id="saveTest" onClick={this.handleOnSubmit}>
                    Guardar Test
                  </div>

                  <div style={{ margin: '10px' }} className="BHT-CT-TFT-finTest" id="finTest" onClick={this.handleOnSubmit}>
                    Finalizar Test
                  </div>
                </div>

                {testJusticiaUserLoad && numPregTest && testJusticiaUserLoad.filter(p => p.numPreg == numPregTest).length > 0 && testJusticiaUserLoad.filter(p => p.numPreg == numPregTest).map((preg, key) =>

                  <div className="BHT-CT-pregTestContent" style={statusCrono == false ? ({ filter: 'blur(7px)' }) : ({ filter: 'blur(0px)' })} >
                    <div className="BHT-CT-navLeft">
                      <i className="fas fa-angle-left" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id="anterior"></i>
                    </div>

                    <div id="marcarComoDudosa" onClick={statusCrono == true ? (this.actionTestUser) : (null)} className="BHT-CT-PT-botonPregDudosa">
                      {preg.dudosa == 1 ? ("ELIMINAR DUDOSA") : ("PREGUNTA DUDOSA")}

                    </div>
                    <div className="BHT-CT-pregTest">

                      <div className="BHT-CT-PT-numP">
                        Pregunta {+' ' + preg.numPreg}

                        <div className="BHT-CT-PT-numP-settings">
                          <div className="BHT-CT-PT-numP-FUNAUTO">
                            <p style={autoLoadPreg && autoLoadPreg == true ? ({}) : ({ color: '#71849e' })}>Auto {autoLoadPreg && autoLoadPreg == true ? ('ON') : ('OFF')}</p>
                            <i style={autoLoadPreg && autoLoadPreg == true ? ({}) : ({ color: '#71849e' })} id="autoPreg" title={"Pasar a nueva pregunta automaticamente, al seleccionar una respuesta."} className={autoLoadPreg && autoLoadPreg == true ? ("fas fa-toggle-on") : ("fas fa-toggle-off")} onClick={statusCrono == true ? (this.actionTestUser) : (null)}></i>
                          </div>
                          <div className="BHT-CT-PT-numP-infoTest">
                            <i className="fas fa-info-circle" title="Info Puntuación del Test" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id="infoPuntuacion"></i>
                          </div>


                        </div>
                      </div>
                      <div className="BHT-CT-PT-enunciado">
                        {preg.pregunta}
                      </div>

                      <div className="BHT-CT-PT-respuesta" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id={'selectRespuesta-0-' + preg.numPreg + '-' + preg.respuestas[0].idR} style={preg.respuestas[0].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                        <div className="BHT-CT-PT-letra" id={'selectRespuesta-0-' + preg.numPreg + '-' + preg.respuestas[0].idR} style={preg.respuestas[0].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          A
                        </div>
                        <div className="BHT-CT-PT-contenido" id={'selectRespuesta-0-' + preg.numPreg + '-' + preg.respuestas[0].idR} style={preg.respuestas[0].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          {preg.respuestas[0].respuesta}
                        </div>
                      </div>

                      <div className="BHT-CT-PT-respuesta" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id={'selectRespuesta-1-' + preg.numPreg + '-' + preg.respuestas[1].idR} style={preg.respuestas[1].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                        <div className="BHT-CT-PT-letra" id={'selectRespuesta-1-' + preg.numPreg + '-' + preg.respuestas[1].idR} style={preg.respuestas[1].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          B
                        </div>
                        <div className="BHT-CT-PT-contenido" id={'selectRespuesta-1-' + preg.numPreg + '-' + preg.respuestas[1].idR} style={preg.respuestas[1].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          {preg.respuestas[1].respuesta}
                        </div>
                      </div>

                      <div className="BHT-CT-PT-respuesta" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id={'selectRespuesta-2-' + preg.numPreg + '-' + preg.respuestas[2].idR} style={preg.respuestas[2].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                        <div className="BHT-CT-PT-letra" id={'selectRespuesta-2-' + preg.numPreg + '-' + preg.respuestas[2].idR} style={preg.respuestas[2].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          C
                        </div>
                        <div className="BHT-CT-PT-contenido" id={'selectRespuesta-2-' + preg.numPreg + '-' + preg.respuestas[2].idR} style={preg.respuestas[2].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          {preg.respuestas[2].respuesta}
                        </div>
                      </div>

                      <div className="BHT-CT-PT-respuesta" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id={'selectRespuesta-3-' + preg.numPreg + '-' + preg.respuestas[3].idR} style={preg.respuestas[3].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                        <div className="BHT-CT-PT-letra" id={'selectRespuesta-3-' + preg.numPreg + '-' + preg.respuestas[3].idR} style={preg.respuestas[3].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          D
                        </div>
                        <div className="BHT-CT-PT-contenido" id={'selectRespuesta-3-' + preg.numPreg + '-' + preg.respuestas[3].idR} style={preg.respuestas[3].valorUsuario == 1 ? ({ color: '#3062AA', fontWeight: 'bold' }) : ({})}>
                          {preg.respuestas[3].respuesta}
                        </div>
                      </div>


                    </div>
                    <div className="BHT-CT-navRight">
                      <i className="fas fa-angle-right" onClick={statusCrono == true ? (this.handleOnSubmit) : (null)} id="siguiente"></i>
                    </div>
                  </div>

                )}
              </div>


            </div >

          ) : (<div></div>)


        );
      } else {

        return (
          <div className="inBodyAvisoTest">

            <div className="imgNiggaTest">
              <img src="../img/scratching-head.png" />
              <b>
                Ningun test teórico cargado en memoria.<br /><br />
                Para cargar un test teórico, puede ir a una clase en el aula virtual o desde el expediente repetir un test.


              </b>

            </div>

          </div>
        );
      }

    } else {
      if (isLogged && tipoUser < 58) {

        swal({
          title: 'Area restringida',
          text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 800,
        }).then(() => {
          window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
        })
        return (null);
      } else {
        return (<Redirect to="/" />);
      }
    }
    //<VistaPreviaTest numPregTest={numPregTest} test={test}/>
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    testJusticiaUserLoad: state.user.testJusticiaUserLoad,
    cronometro: state.user.cronometro,
    idUser: state.user.idUser,
    semanasFacturadas: state.user.semanasFacturadas,
    temasFacturados: state.user.temasFacturados
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TestJusticiaAlumnos);

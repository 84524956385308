// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
// Assets
import './css/infoProgramacionTemporal.css';

class InfoProgramacionTemporal extends Component {

  static propTypes = {
    programacionClase: PropTypes.array.isRequired
  };

constructor(props){
        super(props);
        this.state = {};
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){
			if(e.target.title == 'programa'){
				let id = e.target.id;
				$('#'+id+'infoPRG').slideToggle(1000);
			}
    }

  render() {
    const { programacionClase } = this.props;

    return (
	    <div className="sectionInfoClases">
	    		{programacionClase && programacionClase.map((prg, key) =>
				<div className="blockIA">
					<div className="sidebarBlockIA">
						<div className ="sidebarTitleBIA">
							<i className="fas fa-info-circle"></i>Semana Nº {prg.numeroSemanaClase}
							<i className="fas fa-calendar-alt"></i>{prg.fechaClase}
						</div>
						<div id={key} title="programa" className="toggleBIA">
							<i id={key} title="programa" className="fas fa-arrow-alt-circle-down" onClick={this.onToggleClick}></i>
						</div>
					</div>
				<div className="bodyBlockIA" id={key+'infoPRG'}>
					<div className="celdaBlockIA">
						<div className="tituloInfoBIA"><i className="fas fa-gavel"></i>Test Justicia<i className="fas fa-arrow-right"></i></div>
						<div className="datoInfoBIA">{prg.testJusticia}</div>
					</div>
					<div className="celdaBlockIA">
						<div className="tituloInfoBIA"><i className="fas fa-file-word"></i>Test Word<i className="fas fa-arrow-right"></i></div>
						<div className="datoInfoBIA"> {prg.testWord} </div>
					</div>
					<div className="celdaBlockIA">
						<div className="tituloInfoBIA"><i className="fas fa-edit"></i>Caso Practico<i className="fas fa-arrow-right"></i></div>
						<div className="datoInfoBIA">{prg.casoPractico}</div>
					</div>
				</div>
			</div>
			)}
          </div>
              );
        }
 }

export default InfoProgramacionTemporal;

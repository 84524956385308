// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
// Assets
import './css/infoProgramacion.css';

class InfoProgramacion extends Component {

  static propTypes = {
    infoPrograma: PropTypes.array,
		infoTemasPrograma: PropTypes.array
  };

constructor(props){
        super(props);
        this.state = {
					infoTemas:[{}]
				};
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){
			if(e.target.title == 'programa'){
				let id = e.target.id;
				$('#'+id+'infoPRG').slideToggle(1000);
			} else if (e.target.title == 'temasprg'){
				let id = e.target.id;
				$('#'+id+'temasprg').slideToggle(1000);
			}
    }

  render() {
    const { infoPrograma, infoTemasPrograma} = this.props;
		const { infoTemas } = this.state;

              return (
    <div className="sectionInfoAlumno">
								{infoPrograma && infoPrograma.map((prg, key) =>
									<div className="blockIA">
										<div className="sidebarBlockIA">
											<div className ="sidebarTitleBIA"><i className="fas fa-info-circle"></i>Info de Programación</div>
											<div id={key} title="programa" className="toggleBIA">
											<i id={key} title="programa" className="fas fa-arrow-alt-circle-down" onClick={this.onToggleClick}></i>
											</div>
										</div>
										<div className="bodyBlockIA" id={key+'infoPRG'}>
											<div className="celdaBlockIA">
												<div className="tituloInfoBIA" title="Semanas que tiene la Programación"><i className="fas fa-calendar"></i>Programa<i className="fas fa-arrow-right"></i></div>
												<div className="datoInfoBIA">Semana Nº{prg.numeroSemana} </div>
											</div>
											<div className="celdaBlockIA">
												<div className="tituloInfoBIA"><i className="fas fa-gavel"></i>Test Justicia<i className="fas fa-arrow-right"></i></div>
												<div className="datoInfoBIA">{prg.testJusticia}</div>
											</div>
											<div className="celdaBlockIA">
												<div className="tituloInfoBIA"><i className="fas fa-file-word"></i>Test Word<i className="fas fa-arrow-right"></i></div>
												<div className="datoInfoBIA"> {prg.testWord} </div>
											</div>
											<div className="celdaBlockIA">
												<div className="tituloInfoBIA"><i className="fas fa-edit"></i>Caso Practico<i className="fas fa-arrow-right"></i></div>
												<div className="datoInfoBIA">{prg.casoPractico}</div>
											</div>
										</div>
									</div>
								)}

								{infoTemasPrograma.length > 0 && infoTemas.map((prg, key) =>
									<div className="blockIA">
										<div className="sidebarBlockIA">
											<div className ="sidebarTitleBIA"><i className="fas fa-info-circle"></i>Temas de Programación</div>
											<div id={key} title="temasprg" className="toggleBIA">
											<i id={key} title="temasprg"className="fas fa-arrow-alt-circle-down" onClick={this.onToggleClick}></i>
											</div>
										</div>
										<div className="bodyBlockIA" id={key+'temasprg'}>
										{infoTemasPrograma && infoTemasPrograma.map((prg, key) =>

											<div className="celdaBlockIA">
												<div className="tituloInfoBIA"><i className="fas fa-book"></i>{prg.tituloTema}<i className="fas fa-arrow-right"></i></div>
												<div className="datoInfoBIA">{prg.tipoTema}</div>
											</div>

										)}
										</div>
									</div>
								)}

          </div>
              );

        }
 }

export default InfoProgramacion;

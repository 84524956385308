// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
// Actions
import { iniciarSesion } from '../../../actions/userAction';

// Assets
import './css/css.css';

import Footer from '../../../components/Global/Footer/Footer';
import Boton from '../../../components/Inputs/Boton';
import Input2 from '../../../components/Inputs/Input2';

class PCSelectOpo extends Component {
  static propTypes = {
    iniciarSesion: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string,
    tipoUser: PropTypes.number
  };

  constructor(props) { 
    super(props);
    this.state = {
      viewPrepaHome: 1,
      preparacion: {
        locaActual: 'selectOpo',
        modePrepa: 'normal',
        tipoPreparacion: null,
        oposiciones: [
          {
            infoText: 'Auxilio y Tramitacion LIBRE',
            tipoPreparacionOpo: 'Auxilio-y-Tramitacion-LIBRE'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Gestión Interna',
            tipoPreparacionOpo: ''
          },
          {
            infoText: 'Tramitacion Interna',
            tipoPreparacionOpo: ''
          }

        ]
      }
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    if (e.target.id.split('-')[0] == 'opoSelected') {
      // infoText: 'Gestion Libre',
      // tipoPreparacionOpo: 'Gestion-libre',
      // urlOpoSelectd: 'Online_Video_Presencial'


      let idLoca = e.target.id.split('-')[1]
      let prevUrlPrepa = this.state.preparacion.oposiciones[idLoca].urlOpoSelectd
      let tipoPreparacionOpo = this.state.preparacion.oposiciones[idLoca].tipoPreparacionOpo
      this.props.history.push('/Preparacion/' + prevUrlPrepa + '/' + tipoPreparacionOpo);




    } else if (e.target.id == 'atras') {
      this.props.history.push('/Preparacion');
    } else if (e.target.id == 'help') {
      swal('Si necesita ayuda','Puede llamarnos al: 688 98 80 99','info')
    }
  }
  onChangeInput(e) {

    if (e.target.name == 'dniInput') {
      this.setState({ checkedDni: undefined })
      this.setState({ dniUser: e.target.value });
    } else if (e.target.name == "passwordInput") {
      this.setState({ checkedPass: undefined })
      this.setState({ passwordUserInput: e.target.value });
    }

  }
  componentDidMount() {
    let url = location.href;
    let urlCheck = url.split('/')[url.split('/').length - 2]
    let urlCheck2 = url.split('/')[url.split('/').length - 1]

    this.setState({ metodoPrepa: urlCheck2 })
    if (urlCheck == 'Preparacion' && (urlCheck2 != 'Cursos_Online' && urlCheck2 != 'Presencial' && urlCheck2 != 'Online_Video_Presencial')) {
      // Url no valida redigrig atras!
      this.props.history.push('/Preparacion')
    } else {
      if (urlCheck2 == 'Cursos_Online') {
        let oposiciones = [
          {
            infoText: 'Auxilio + Tramitacion LIBRE',
            tipoPreparacionOpo: 'Aux-Tra-libre',
            urlOpoSelectd: 'Cursos_Online'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: 'Gestion-libre',
            urlOpoSelectd: 'Cursos_Online'
          },
          {
            infoText: 'Gestión Interna',
            tipoPreparacionOpo: 'Gestion-interna',
            urlOpoSelectd: 'Cursos_Online'
          },
          {
            infoText: 'Tramitacion Interna',
            tipoPreparacionOpo: 'Tramitacion-interna',
            urlOpoSelectd: 'Cursos_Online'
          }
        ]

        let preparacion = this.state
        preparacion.oposiciones = oposiciones
        this.setState({ preparacion: preparacion })

      } else if (urlCheck2 == 'Presencial') {
        let oposiciones = [
          {
            infoText: 'Auxilio + Tramitacion LIBRE',
            tipoPreparacionOpo: 'Aux-Tra-libre',
            urlOpoSelectd: 'Presencial'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: 'Gestion-libre',
            urlOpoSelectd: 'Presencial'
          }
        ]
        let preparacion = this.state
        preparacion.oposiciones = oposiciones
        this.setState({ preparacion: preparacion })
      } else if (urlCheck2 == 'Online_Video_Presencial') {
        let oposiciones = [
          {
            infoText: 'Auxilio + Tramitacion LIBRE',
            tipoPreparacionOpo: 'Aux-Tra-libre',
            urlOpoSelectd: 'Online_Video_Presencial'
          },
          {
            infoText: 'Gestion Libre',
            tipoPreparacionOpo: 'Gestion-libre',
            urlOpoSelectd: 'Online_Video_Presencial'
          }
        ]
        let preparacion = this.state
        preparacion.oposiciones = oposiciones
        this.setState({ preparacion: preparacion })
      }

    }


  }



  render() {
    let { preparacion, viewPrepaHome, metodoPrepa } = this.state
    let { isLogged } = this.props
    return (
      <div className="CursosCej234fg" style={isLogged && isLogged == true ? ({
        position: 'absolute',
        zIndex: '1000',
        top: '0',
        right: '0'
      }) : ({})}>
        <div className="CC-headerNavLeft22">

          <div className='CC-HN-top'>

            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>

            <div className='infoStatusPrepaSelection'>
              ¡Genial!<br /><br />
              Usted a elegido, <b>{
                metodoPrepa == 'Cursos_Online' ? ('Curso 100% Online') : (
                  metodoPrepa == 'Online_Video_Presencial' ? ('Clases por videoconferencia') : ('Preparación Presencial')
                )}</b>. Ya tiene el primer paso hecho, ahora... seleccione una oposición.

            </div>



          </div>
          <div className='divAtrasv'>
            <div className='CC-HN-medInfo22'>
              SELECCIONE UNA OPOSICIÓN
            </div>
          </div>


          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-headerNavLeft222">

          <div className='CC-HN-top2'>
            <div className="logoMenuEd" style={{ display: 'flex', padding: '0px', alignSelf: 'flex-start', cursor: 'pointer' }} id="goEscritorio" onClick={this.handleOnSubmit} >
              <img id="goEscritorio" style={{ cursor: 'pointer' }} src="../img/rev1LogoWeb.png" />
            </div>
            <div>
              <div className='CC-HN-NavPrepa'>
                <div className='infoStatusPrepaSelection ctrlWd334'>
                  ¡Genial!
                  Usted a elegido, <b>{
                    metodoPrepa == 'Cursos_Online' ? ('Curso 100% Online') : (
                      metodoPrepa == 'Online_Video_Presencial' ? ('Clases por videoconferencia') : ('Preparación Presencial')
                    )}</b>. Ya tiene el primer paso hecho, ahora... <br /><br /><b>Seleccione una oposición</b>

                </div>
              </div>
            </div>



          </div>



          <div className='CC-HN-navBottom'>
            <div className='divAtrasv'>
              <div className='CC-HN-navBottom-atras22' id="atras" onClick={this.onHandleClick}>
                Atrás
              </div>
            </div>

            <div className='CC-HN-navBottom-help' id="help" onClick={this.onHandleClick}>
              ¿Necesitas ayuda?
              <i className="fa-light fa-phone-flip"></i>
            </div>
          </div>
        </div>

        <div className="CC-contentCur2">
          <div className='CC-CC-bodyOfertas22'>
            {preparacion && preparacion.oposiciones.map((cls, key0) =>
              <div className={key0 == 1 ? ('CC-CC-bO-tarjeta22') : ('CC-CC-bO-tarjeta22')}
                style={{ zIndex: preparacion.oposiciones.length - key0 }}
                id={'opoSelected-' + key0} onClick={this.onHandleClick}
                key={key0}>


                <div className='CC-CC-bO-T-infoText22 contoneoLetras' id={'opoSelected-' + key0}>

                  {cls.infoText}


                </div>

              </div>
            )}



          </div>

          <div className='CC-CC-bodyOfertas222'>
            {/* {preparacion && viewPrepaHome != undefined ? (
              <div className={'CC-CC-bO-tarjeta'} >
                

                <div className='CC-CC-bO-T-infoText22 contoneoLetras'>

                  {preparacion.oposiciones[viewPrepaHome].infoText}
                </div>

                


              </div>
            ) : null} */}

            {preparacion && preparacion.oposiciones.map((cls, key0) =>
              <div className={key0 == 1 ? ('CC-CC-bO-tarjeta22 controlWidTarjeta') : ('CC-CC-bO-tarjeta22')}
                style={{ zIndex: preparacion.oposiciones.length - key0 }}
                id={'opoSelected-' + key0} onClick={this.onHandleClick}
                key={key0}>


                <div className='CC-CC-bO-T-infoText22 contoneoLetras' id={'opoSelected-' + key0}>

                  {cls.infoText}


                </div>



              </div>
            )}



          </div>
        </div>



        {/* <div className="bottomLogin">
            <Footer />
        </div> */}
      </div>
    );

  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    iniciarSesion
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PCSelectOpo);


// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
import '../css/cssSemana.css';
//Componets
import Input from '../../../../../components/Inputs/Input';
import Input2 from '../../../../../components/Inputs/Input2';
import Boton from '../../../../../components/Inputs/Boton';
import Boton2 from '../../../../../components/Inputs/Boton2';

import InputSelect from '../../../../../components/Inputs/InputSelect';
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import InfoProgramacion from '../../../../../components/InfoProgramacion';
import BotonMedio from '../../../../../components/Inputs/BotonMedio';
import TemaSeleted from '../../../../../components/TemaSeleted';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cargarContenidoAPClaseCrear } from '../actions/baroptionAction';


class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      statusBarraProceso: 'completar',
      readClaseSelecionada: true,
      readSemanaProgramacion: true,
      infoPrograma: [],
      infoTemasPrograma: [],
      infoProgramaOrder: [],
      displayClaseInput: 'none',
      displayClonar: false,
      busquedaTemasOposicion: undefined,
      clonarSemanas: undefined,
      semanaSelected: undefined,
      valuesActivo: [{
        value: '',
        name: 'Seleccione una opción',
        disable: 'disabled',
        selected: 'selected'
      },
      {
        value: '1',
        name: 'Clonar 1 Semana específica.',
        disable: undefined,
        selected: undefined
      },
      {
        value: '0',
        name: 'Clonar todas las Semanas.',
        disable: undefined,
        selected: undefined
      }

      ],
      infoSemanaPrg: {
        idOposicion: undefined,
        tituloOpo: '',
        idClase: '',
        idTestJusticia: undefined,
        idTestWord: undefined,
        idTestCasoPractico: undefined,
        idCasoMecanografia: undefined,
        numeroSemana: '',
        idPrg: undefined,
        idCategoriaOrigen: null,
        idCategoria: null,
        nombre_semanaOrigen: null,
        nombre_semana: ''
      },
      listaSemanasClase: undefined,
      bloqueTemas: [],
      apuntesTemas: [],
      displayClases: 'none',
      cursoBaseSelected: null,
      cursosDinamicos: null,
      cursoBaseFechaMax: null,
      fechaClaseBase: '',
      navS: 0,
      navS2: 0,
      aplCurso: {
        opoName: '',
        opoId: '',
        cursoBaseName: '',
        cursoBaseId: '',
        cursoDestinoName: '',
        cursoDestinoId: '',
        modo: '',
        semanaVALUE: '',
        semanaSelected: null,
        fechaStart: null
      },
      busquedaCursosBase: undefined,
      claseSelecionada: undefined
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.onChangeInputNew = this.onChangeInputNew.bind(this);
    this.handleSubmitFormNew = this.handleSubmitFormNew.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
    this.onChangeInputNew2 = this.onChangeInputNew2.bind(this);


  }
  async onChangeInputNew2(e) {

    if (e.target.id.split(['-'])[0] == 'id_clase_selected_din') {

      let keyTarget = e.target.id.split(['-'])[1]

      let value = e.target.value

      let claseObject = await this.state.busquedaClases.find(clase => clase.id_clase == value)

      if (claseObject != undefined) {
        let data = { idClase: claseObject.id_clase }

        // SACAR SEMANA BASE ULTIMA --> 

        let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrderFecha", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;

        let cursosDin = this.state.cursosDinamicos
        cursosDin[keyTarget].idClase = claseObject.id_clase
        if (cls2.length > 0) {
          let cursoBaseFechaMax = cls2.sort((a, b) => new Date(b.fecha_clase) - new Date(a.fecha_clase))[0].fecha_clase

          cursosDin[keyTarget].cursoBaseFechaMax = cursoBaseFechaMax
        } else {
          cursosDin[keyTarget].cursoBaseFechaMax = null
        }

        this.setState({ cursosDinamicos: cursosDin });



      } else {
        let cursosDin = this.state.cursosDinamicos
        cursosDin[keyTarget].idClase = value
        cursosDin[keyTarget].cursoBaseFechaMax = null

        this.setState({ cursosDinamicos: cursosDin });
      }

    } else if (e.target.id.split(['-'])[0] == 'fechaClaseDin') {
      let keyTarget = e.target.id.split(['-'])[1]
      let value = e.target.value
      if (value.length > 0) {

        let cursosDin = this.state.cursosDinamicos
        cursosDin[keyTarget].fechaDin = value

        this.setState({ cursosDinamicos: cursosDin });


      } else {
        let cursosDin = this.state.cursosDinamicos
        cursosDin[keyTarget].fechaDin = value

        this.setState({ cursosDinamicos: cursosDin });
      }
    } else if (e.target.id == 'fechaClaseBase') {

      if (e.target.value.length > 0) {
        let fechaClaseBase = e.target.value

        this.setState({ fechaClaseBase: fechaClaseBase });
      } else {
        this.setState({ fechaClaseBase: null });
      }
    } else if (e.target.id == 'categoriaSemPrg') {
      let infoSemanaPrg = this.state.infoSemanaPrg
      if (!isDefined(infoSemanaPrg.idCategoriaOrigen)) {
        infoSemanaPrg.idCategoriaOrigen = infoSemanaPrg.idCategoria
      }
      if (e.target.value == '') {
        infoSemanaPrg.idCategoria = null
      } else {
        infoSemanaPrg.idCategoria = e.target.value
      }

      this.setState({ infoSemanaPrg: infoSemanaPrg })

    } else if (e.target.id == 'nameSemPrg') {
      let infoSemanaPrg = this.state.infoSemanaPrg
      if (!isDefined(infoSemanaPrg.nombre_semanaOrigen)) {
        infoSemanaPrg.nombre_semanaOrigen = infoSemanaPrg.nombre_semana
      }


      if (e.target.value == '') {
        infoSemanaPrg.nombre_semana = null
      } else {
        infoSemanaPrg.nombre_semana = e.target.value
      }
      this.setState({ infoSemanaPrg: infoSemanaPrg })
    }

    let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase } = this.state
    if (infoSemanaPrg.idOposicion != undefined) {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
    } else {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
    }

  }
  async onHandleClick(e) {


    if (e.target.id == 'addClaseDin') {
      let cursosDinamicos = this.state.cursosDinamicos == null ? ([]) : (this.state.cursosDinamicos)

      cursosDinamicos.push({
        idClase: '',
        fechaDin: '',
        nombreClase: null,
        cursoBaseFechaMax: null
      })

      this.setState({ cursosDinamicos: cursosDinamicos })
      let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursoBaseFechaMax, fechaClaseBase } = this.state
      if (infoSemanaPrg.idOposicion != undefined) {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
      } else {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
      }
    } else if (e.target.id.split('-')[0] == 'nav') {
      let val = e.target.id.split('-')[1]
      this.setState({ navS: val })

      let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase } = this.state
      if (infoSemanaPrg.idOposicion != undefined) {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
      } else {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
      }

    } else if (e.target.id.split('-')[0] == 'nav2') {
      let val = e.target.id.split('-')[1]
      this.setState({ navS2: val })

      let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase } = this.state
      if (infoSemanaPrg.idOposicion != undefined) {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
      } else {
        this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
      }

    } else if (e.target.id == 'add_semana') {
      try {
        swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, la semana será insertada y aplicada. Puedes aplicar la clase a varios cursos de forma dinámica.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {

              let semanaInfo = this.state.infoSemanaPrg
              let bloqueTemas = this.state.bloqueTemas
              let datosValidos = true;

              let { fechaClaseBase } = this.state
              if (semanaInfo.idOposicion != undefined && semanaInfo.idClase != undefined && semanaInfo.numeroSemana != undefined && semanaInfo.idCategoria != undefined && semanaInfo.idCategoria != '' && semanaInfo.idCategoria != null && semanaInfo.nombre_semana != undefined && semanaInfo.nombre_semana != '' && semanaInfo.nombre_semana != null && fechaClaseBase != undefined && fechaClaseBase != '' && fechaClaseBase != null) {
                for (var i = 0; i < bloqueTemas.length; i++) {
                  if (bloqueTemas[i].tipoBloque != undefined) {

                    for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                      if (bloqueTemas[i].temas[j].idTema == undefined) {
                        swal('El bloque de temas Nº ' + (i + 1) + ', no tiene ningun tema asignado', '', 'error')
                        datosValidos = false
                        break;

                      }
                    }

                  } else {
                    swal('El bloque de temas Nº ' + (i + 1) + 'no tiene tipo de bloque', '', 'error')
                    datosValidos = false
                    break;

                  }

                }

              } else {
                swal('Rellene todos los campos de configuracion de semana', '', 'error')
                datosValidos = false
              }

              if (datosValidos == true) {

                swal({
                  title: 'Insertando y aplicando Semana de Programación',
                  text: '¡En breve estará listo!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })
                // Realizar async await para meter todo en orden

                let { bloqueTemas, infoSemanaPrg, cursosDinamicos } = this.state

                // 1º Semana programacion --> insertar infoSemanaPrg
                let data = { infoSemanaPrg: infoSemanaPrg }
                let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarSemanaPrgCompleta", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                infoSemanaPrg.idPrg = cls1.insertId
                // idPrg
                if (cls1.length != 0 || cls1 != undefined) {
                  // 2º Insertar Bloques de Temas
                  for (var i = 0; i < bloqueTemas.length; i++) {
                    //2.1 Bloque --> insertar bloqueTemas[i]
                    let data1 = { idSemanaPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase, bloqueTemas: bloqueTemas[i] }
                    let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarBloque2", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;
                    bloqueTemas[i].idBloque = await cls2.insertId
                    // idBloque
                    // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
                    for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                      // 2º.2.1 Tema de bloque --> insertar bloqueTemas[i].temas[j]
                      let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[i].idBloque, tema: bloqueTemas[i].temas[j] }
                      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg2", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response3.ok) {
                        throw Error(response3.statusText);
                      }
                      let json3 = await response3.json();
                      let cls3 = await json3;
                      bloqueTemas[i].temas[j].idTemaPrg = cls3.insertId
                      // idTemaPrg


                      // insertar test Justicia Bloque
                      if (bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length; k++) {

                          let data6 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined)[k].idTestJusticia }
                          let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                          });
                          if (!response6.ok) {
                            throw Error(response6.statusText);
                          }
                          let json6 = await response6.json();
                          let cls6 = await json6;
                        }
                      }

                      //insertar casos practicos  Bloque
                      if (bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length; k++) {

                          let data7 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined)[k].idTestCP }
                          let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                          });
                          if (!response7.ok) {
                            throw Error(response7.statusText);
                          }
                          let json7 = await response7.json();
                          let cls7 = await json7;
                        }
                      }

                      //insertar examens desarrollo Bloque
                      if (bloqueTemas[i].examenGestionPdf.filter(c => c.idEjer != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined).length; k++) {

                          let data7X2 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined)[k].idExamenGestion }
                          let response7X2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarExamDesarolloBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7X2)
                          });
                          if (!response7X2.ok) {
                            throw Error(response7X2.statusText);
                          }
                          let json7X2 = await response7X2.json();
                          let cls7X2 = await json7X2;
                        }
                      }


                    }


                  }

                }

                // 3º Aplicar programacion --------------

                let fechaInicioPrg = moment(fechaClaseBase).format("YYYY-MM-DD HH:mm:ss")

                let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
                let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
                let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")

                let infoProgramacionInsert = []

                infoProgramacionInsert.push({
                  id_programa: infoSemanaPrg.idPrg,
                  id_clase: infoSemanaPrg.idClase,
                  numero_semana_clase: infoSemanaPrg.numeroSemana,
                  fecha_clase: fechaInicioPrgInser,
                  fecha_mostrar_tema: fechaDesbloqueoTema,
                  fecha_mostrar_test: fechaDesbloqueoTest
                })


                let data2345 = { programacion: infoProgramacionInsert }
                let response1x234 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2345)
                });
                if (!response1x234.ok) {
                  throw Error(response1x234.statusText);
                }
                let json1x234 = await response1x234.json();
                let cls1x234 = await json1x234;

                // FIN 3º -------------------------------

                // 4º Aplicar a las otras de forma dinamica
                let cursosDinFiltro = cursosDinamicos != null && cursosDinamicos != undefined && cursosDinamicos.length > 0 ? (
                  cursosDinamicos.filter(c => c.idClase != null && c.idClase != '' && c.fechaDin != null && c.fechaDin != '')
                ) : null
                if (cursosDinamicos != null && cursosDinamicos != undefined && cursosDinamicos.length > 0 && cursosDinFiltro.length > 0) {

                  for (let h2 = 0; h2 < cursosDinFiltro.length; h2++) {
                    let infoPrgAplicar = []


                    let fechaInicioPrgInser2 = moment(cursosDinFiltro[h2].fechaDin).format("YYYY-MM-DD HH:mm:ss")
                    let fechaDesbloqueoTema2 = moment(cursosDinFiltro[h2].fechaDin).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
                    let fechaDesbloqueoTest2 = moment(cursosDinFiltro[h2].fechaDin).format("YYYY-MM-DD HH:mm:ss")

                    infoPrgAplicar.push({
                      id_programa: infoSemanaPrg.idPrg,
                      id_clase: cursosDinFiltro[h2].idClase,
                      numero_semana_clase: infoSemanaPrg.numeroSemana,
                      fecha_clase: fechaInicioPrgInser2,
                      fecha_mostrar_tema: fechaDesbloqueoTema2,
                      fecha_mostrar_test: fechaDesbloqueoTest2
                    })


                    let data2345x = { programacion: infoPrgAplicar }
                    let response1x234x = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2345x)
                    });
                    if (!response1x234x.ok) {
                      throw Error(response1x234x.statusText);
                    }
                    let json1x234x = await response1x234x.json();
                    let cls1x234x = await json1x234x;

                  }

                }

                // 4º fin ........
                await swal({
                  title: 'Semana Insertada Correctamente',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  this.props.cargarContenidoAPClaseCrear(null, null, null, null, null, null, null)
                  window.location.reload()
                })



              } else {
                swal('Rellene todos los datos para insertar una semana', '', 'error')
              }


            } else {
              swal("Proceso cancelado", '', "success");
            }
          });


      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'deleteClaseDin') {
      let val = e.target.id.split('-')[1]
      let cursosDinamicos = this.state.cursosDinamicos

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la clase dinámica será eliminada",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await cursosDinamicos.splice(val, 1)
            this.setState({ cursosDinamicos: cursosDinamicos })
          } else {
            swal("Acción no realizada", '', "success");
          }
        });

    }

    let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase } = this.state
    if (infoSemanaPrg.idOposicion != undefined) {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
    } else {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
    }

  }


  cleanForm() {
    switch (this.props.selectedOption) {
      case 'Aplicar_Programacion': {
        let clase = this.state.claseSelecionada;
        let fecha = moment(this.state.fechaStart).format("DD-MM-YY");
        swal("¡Programacion creada!", "Se ha generado una programacion para la clase " + clase + ", la primera clase comienza el " + fecha + ".", "success");
        this.setState({
          oposicionSelecionada: undefined,
          claseSelecionada: undefined,
          semanaProgramacion: undefined,
          fechaStart: undefined,
          infoPrograma: [],
          infoTemasPrograma: [],
          infoProgramaOrder: [],
          statusBarraProceso: "completado"
        });
        this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
        this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
        this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        this.refs.ref_fechaStart.refs.fechaStart.value = '';
      } break;
      case 'Add_Semana_to_Programacion': {
        let clase = this.state.claseSelecionada;
        let fecha = moment(this.state.fechaStart).format("DD-MM-YY");
        swal("¡Programacion creada!", "Se ha añadido una semana a la progrmacion para la clase " + clase + ", la clase esta puesta el " + fecha + ".", "success");
        this.setState({
          oposicionSelecionada: undefined,
          claseSelecionada: undefined,
          semanaProgramacion: undefined,
          fechaStart: undefined,
          infoPrograma: [],
          infoTemasPrograma: [],
          infoProgramaOrder: [],
          statusBarraProceso: "completado"
        });
        this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
        this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
        this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        this.refs.ref_fechaStart.refs.fechaStart.value = '';
      } break;
      case 'Clonar_Programacion': {
        let clase = this.state.claseSelecionada;
        let opo = this.state.oposicionSelecionada;
        swal("¡Clonación de Programacion Confirmada!", "Se ha generado una programacion para la clase " + clase + ", programación de la Oposición " + opo + ".", "success");
        this.setState({
          oposicionSelecionada: undefined,
          claseSelecionada: undefined,
          statusBarraProceso: "completado",
          infoProgramaDefault: undefined,
          infoProgramaDefaultTemas: undefined,
          infoProgramaDefaultTemasApuntes: undefined,
          infoProgramaDefaultTemasIntervalos: undefined
        });
        this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
        this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
      } break;
      case 'Posponer_Clase': {
        let semanas = this.state.semanasAdelantar;
        let fecha = moment(this.state.fechaPosponer1).format("DD-MM-YY");
        swal("¡Clases Pospuestas!", "Se han adelantado todas las clase, " + semanas + " semanas, a partir de " + fecha + ".", "success");
        this.setState({
          semanasAdelantar: undefined,
          fechaPosponer1: undefined,
          statusBarraProceso: "completado"
        });
        this.refs.ref_semanasAdelantar.refs.semanasAdelantar.value = '';
        this.refs.ref_fechaPosponer1.refs.fechaPosponer1.value = '';
      } break;
      case 'Atrasar_Clases': {
        let semanas = this.state.semanasAtrasar;
        let fecha = moment(this.state.fechaAtrasar).format("DD-MM-YY");
        swal("¡Clases Atrasadas!", "Se han atrasado todas las clase, " + semanas + " semanas, a partir de " + fecha + ".", "success");
        this.setState({
          semanasAtrasar: undefined,
          fechaAtrasar: undefined,
          statusBarraProceso: "completado"
        });
        this.refs.ref_semanasAtrasar.refs.semanasAtrasar.value = '';
        this.refs.ref_fechaAtrasar.refs.fechaAtrasar.value = '';
        this.refs.ref_fechaFinUpdate3.refs.fechaFinUpdate3.value = '';
      } break;
    }
  }

  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Aplicar_Programacion') {
      let oposicionSelecionada = this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value;
      let claseSelecionada = this.refs.ref_claseSelecionada.refs.claseSelecionada.value;
      let semanaProgramacion = this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value;
      let fechaStart = this.refs.ref_fechaStart.refs.fechaStart.value;
      let infoProgramaT = this.state.infoPrograma;
      if ((oposicionSelecionada.length > 0 && claseSelecionada.length > 0 && semanaProgramacion.length > 0 && fechaStart.length > 0 && infoProgramaT.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }

      if (e.target.name === 'oposicionSelecionada') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
        if (oposicionObject != undefined) {
          let data = { id_oposicion: oposicionObject.id_oposicion };
          fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => this.setState({ busquedaClasesSemanas: cls, readClaseSelecionada: false })
          ).catch(error => console.log('Object fecth failed', error));
        } else {
          this.setState({ busquedaClasesSemanas: undefined, readClaseSelecionada: true, busquedaSemanaProgramacion: undefined, readSemanaProgramacion: true, infoTemasPrograma: [], infoPrograma: [], infoProgramaOrder: [] });
          this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        }
      }
      if (e.target.name === 'claseSelecionada') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
        let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == e.target.value);
        if (oposicionObject != undefined && claseObject != undefined) {

          let data = { idOposicion: oposicionObject.id_oposicion, idClase: claseObject.id_clase };
          let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaID", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;

          await this.setState({ busquedaSemanaProgramacion: cls2 })

          var busquedaClasesFecha2 = await this.state.busquedaSemanaProgramacion
          for (var i = 0; i < busquedaClasesFecha2.length; i++) {
            busquedaClasesFecha2[i].temas = []
          }
          for (var k = 0; k < busquedaClasesFecha2.length; k++) {
            let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


            let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;
            for (var z = 0; z < cls3.length; z++) {
              let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
              if (locaBloque != -1) {
                busquedaClasesFecha2[locaBloque].temas.push({
                  idTema: cls3[z].idTema,
                  tituloTema: cls3[z].tituloTema,
                  nombreTema: cls3[z].nombreTema
                })
              }
            }
          }

          await this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2, readSemanaProgramacion: false });

        } else {
          this.setState({ busquedaSemanaProgramacion: undefined, readSemanaProgramacion: true, infoTemasPrograma: [], infoPrograma: [], infoProgramaOrder: [] });
          this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        }
      }
      if (e.target.name === 'semanaProgramacion') {
        let semanaObject = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == e.target.value);
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
        let idOposicion = oposicionObject.id_oposicion;
        let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == this.state.claseSelecionada);
        if (semanaObject != undefined && claseObject != undefined) {
          try {
            let idPrograma = semanaObject.id_programa;
            let data = { idPrograma: idPrograma, idOposicion: idOposicion, idClase: claseObject.id_clase }

            let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/infoPRGselect", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            await this.setState({
              infoPrograma: [{
                numeroSemana: cls1[0].numeroSemana,
                testJusticia: cls1[0].nombreTestJusticia,
                testWord: cls1[0].nombreTestWord,
                casoPractico: cls1[0].nombreCasoPractico
              }]
            })

            let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrder", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;
            await this.setState({ bloquesSemanaPrg: cls2 })
            let prevOrderPrg = [];
            for (var i = 0; i < cls2.length; i++) {
              prevOrderPrg.push({
                idPrograma: cls2[i].idPrograma
              })
            }
            this.setState({ infoProgramaOrder: prevOrderPrg })
          } catch (e) { console.log(e) }


        } else {
          this.setState({ infoPrograma: [], infoProgramaOrder: [] })
        }
      }
    } else if (this.props.selectedOption == 'Clonar_Programacion') {

      if (e.target.id == 'opoDin') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

        if (oposicionObject != undefined) {

          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let aplCurso = this.state.aplCurso

            aplCurso.opoName = oposicionObject.nombre
            aplCurso.opoId = oposicionObject.id_oposicion
            aplCurso.cursoBaseName = ''
            aplCurso.cursoBaseId = ''
            aplCurso.cursoDestinoName = ''
            aplCurso.cursoDestinoId = ''
            aplCurso.modo = ''
            aplCurso.semanaVALUE = ''
            aplCurso.semanaSelected = null
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso, busquedaCursosBase: cls1 })
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.opoName = e.target.value
          aplCurso.opoId = ''
          aplCurso.cursoBaseName = ''
          aplCurso.cursoBaseId = ''
          aplCurso.cursoDestinoName = ''
          aplCurso.cursoDestinoId = ''
          aplCurso.modo = ''
          aplCurso.semanaVALUE = ''
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, busquedaCursosBase: undefined })

        }


      } else if (e.target.id == 'cursoBase') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso base',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idOposicion: this.state.aplCurso.opoId, idClase: cursoObject.id_clase };
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaID", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {

            if (cls1.length > 0) {

              if (cursoObject != undefined) {

                try {
                  // swal('Cargando semanas de clase', 'Esto puede demorarse de 1 a 2 minutos', 'info')
                  swal({
                    title: 'Cargando programación de clase',
                    text: 'Este proceso puede demorarse 1 a 2 minutos',
                    icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                    buttons: false,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                  })

                  let data = { idClase: cursoObject.id_clase, idOposicion: this.state.aplCurso.opoId }

                  let semanasPrg = []
                  let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrder", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response1.ok) {
                    throw Error(response1.statusText);
                  }
                  let json1 = await response1.json();
                  let cls1 = await json1;

                  for (var i = 0; i < cls1.length; i++) {
                    semanasPrg.push({
                      idOposicion: cls1[i].idOposicon,
                      idClase: cursoObject.id_clase,
                      idTestJusticia: cls1[i].testJusticiaId,
                      idTestWord: cls1[i].testWordId,
                      idTestCasoPractico: cls1[i].casoPracticoId,
                      idCasoMecanografia: cls1[i].ejerMecaId,
                      numeroSemana: cls1[i].numeroSem,
                      idPrg: cls1[i].idPrograma,
                      bloqueTemas: [],
                      videos: [],
                      idCategoria: cls1[i].idCategoria,
                      nombre_semana: cls1[i].nombreSemana
                    })
                  }
                  this.setState({ semanasPrg: semanasPrg })

                  let data2 = { semanasPrg: semanasPrg }
                  let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/bloqueSSemPrg", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;
                  for (var i = 0; i < semanasPrg.length; i++) {

                    for (var j = 0; j < cls2.length; j++) {
                      if (semanasPrg[i].idPrg == cls2[j].id_semana) {
                        semanasPrg[i].bloqueTemas.push({
                          idBloque: cls2[j].id_bloque,
                          idTestExtra: cls2[j].id_test_justicia,
                          idCasoExtra: cls2[j].id_caso_practico_extra,
                          tipoBloque: cls2[j].tipoBloque,
                          idWordTestPDF: cls2[j].idWordTestPDF,
                          idExamenGestion: cls2[j].idExamenGestion,
                          testJusticia: [],
                          casoPractico: [],
                          examenGestionPdf: [],
                          temas: []
                        })

                      }
                    }
                  }



                  //cargamos todo el contenido vamos a copiar todas las clases.

                  for (var i = 0; i < semanasPrg.length; i++) {

                    for (var j = 0; j < semanasPrg[i].bloqueTemas.length; j++) {
                      let data3 = { idBloque: semanasPrg[i].bloqueTemas[j].idBloque, idPrograma: semanasPrg[i].idPrg }

                      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/temasDeBloqu1e", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                      });
                      if (!response3.ok) {
                        throw Error(response3.statusText);
                      }
                      let json3 = await response3.json();
                      let cls3 = await json3;

                      for (var z = 0; z < cls3.length; z++) {
                        semanasPrg[i].bloqueTemas[j].temas.push({
                          idTemaPrg: cls3[z].idTemaPrg,
                          idTema: cls3[z].idTema
                        })

                      }
                      //sacar test Justicia
                      let data6 = { idBloque: semanasPrg[i].bloqueTemas[j].idBloque }
                      let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/testJusticiaBloqueLoad", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                      });
                      if (!response6.ok) {
                        throw Error(response6.statusText);
                      }
                      let json6 = await response6.json();
                      let cls6 = await json6;
                      for (var z = 0; z < cls6.length; z++) {
                        semanasPrg[i].bloqueTemas[j].testJusticia.push({
                          idTestJusticia: cls6[z].idTest
                        })
                      }
                      // sacar caso practicos
                      let data7 = { idBloque: semanasPrg[i].bloqueTemas[j].idBloque }
                      let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/casoPracticoBloqueLoad", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                      });
                      if (!response7.ok) {
                        throw Error(response7.statusText);
                      }
                      let json7 = await response7.json();
                      let cls7 = await json7;
                      for (var z = 0; z < cls7.length; z++) {
                        semanasPrg[i].bloqueTemas[j].casoPractico.push({
                          idCasoPractico: cls7[z].idTest
                        })
                      }

                      // sacar examenes gestion
                      let data8 = { idBloque: semanasPrg[i].bloqueTemas[j].idBloque }
                      let response8 = await fetch("https://oposiciones-justicia.es/api/oficina/examenDesarrolloBloqueLoad", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data8)
                      });
                      if (!response8.ok) {
                        throw Error(response8.statusText);
                      }
                      let json8 = await response8.json();
                      let cls8 = await json8;
                      for (var z = 0; z < cls8.length; z++) {
                        semanasPrg[i].bloqueTemas[j].casoPractico.push({
                          idExamenGestion: cls8[z].idTest
                        })
                      }


                    }
                  }



                  this.setState({ semanasPrg: semanasPrg.sort((b, a) => b.numeroSemana - a.numeroSemana) })


                } catch (e) { console.log(e) }

              } else {
                this.setState({ infoProgramaDefault: [], infoProgramaDefaultTemas: [], infoProgramaDefaultTemasApuntes: [], infoProgramaDefaultTemasIntervalos: [], displayClases: 'none' })
              }
            }


            var busquedaClasesFecha2 = await cls1.sort((b, a) => new Date(a.numero_semana) - new Date(b.numero_semana))
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
                if (locaBloque != -1) {
                  await busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema,
                    nombreTema: cls3[z].nombreTema,
                    categoria: cls3[z].categoria
                  })
                }
              }
            }

            let aplCurso = this.state.aplCurso
            aplCurso.cursoBaseName = cursoObject.dia_semanal
            aplCurso.cursoBaseId = cursoObject.id_clase
            this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2.sort((b, a) => b.numero_semana - a.numero_semana), aplCurso: aplCurso })
            swal.close()
          } else {
            swal('Error', 'Este curso no dispone de ninguna programación fija. Debe escoger un curso que tenga aplicada la programación de forma normal', 'warning')
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoBaseName = e.target.value
          aplCurso.cursoBaseId = ''

          this.setState({ aplCurso: aplCurso, busquedaSemanaProgramacion: null })
        }
      } else if (e.target.id == 'cursoDestino') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso destino',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idClase: cursoObject.id_clase }
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let maximaSemana = cls1.sort((b, a) => new Date(a.fecha_clase) - new Date(b.fecha_clase))

            let ultimaClase = cls1.filter(c => c.fecha_clase == maximaSemana[0].fecha_clase)[0].fecha_clase



            this.setState({
              ultimaClaseFecha: ultimaClase,
              nuevaSemana: maximaSemana + 1,
              programasDeClase: cls1
            })

            let aplCurso = this.state.aplCurso
            aplCurso.cursoDestinoName = cursoObject.dia_semanal
            aplCurso.cursoDestinoId = cursoObject.id_clase
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            swal.close()
          } else {
            // no tiene ningun clase!
            this.setState({
              ultimaClaseFecha: '',
              nuevaSemana: 1,
              programasDeClase: null
            })
            let aplCurso = this.state.aplCurso
            aplCurso.cursoDestinoName = cursoObject.dia_semanal
            aplCurso.cursoDestinoId = cursoObject.id_clase
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            swal.close()
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoDestinoName = e.target.value
          aplCurso.cursoDestinoId = ''
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, ultimaClaseFecha: '', nuevaSemana: 1, programasDeClase: null })
        }
      } else if (e.target.id == 'semanaPrg') {

        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'semana'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'completaPrg') {
        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'completa'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'selectSemanaPrg') {
        let semanaSelected = this.state.semanasPrg.find(prg => prg.numeroSemana == e.target.value);
        if (semanaSelected != undefined) {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = semanaSelected
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }

      } else if (e.target.id == 'fechaStart') {

        if (e.target.value.length > 0) {
          if (this.state.ultimaClaseFecha == '' || (moment(e.target.value).format('YYYY-MM-DD HH:mm') >= moment(this.state.ultimaClaseFecha).format('YYYY-MM-DD HH:mm')) || (moment(e.target.value).format('YYYY-MM-DD HH:mm') <= moment(this.state.ultimaClaseFecha).format('YYYY-MM-DD HH:mm'))) {
            let aplCurso = this.state.aplCurso
            aplCurso.fechaStart = e.target.value
            this.setState({ aplCurso: aplCurso })
            if ((moment(e.target.value).format('YYYY-MM-DD HH:mm') < moment(this.state.ultimaClaseFecha).format('YYYY-MM-DD HH:mm'))) {
              swal('Fecha anterior', 'Puede seleccionar esta fecha, pero debe esta segur@. Lo normal sería: seleccionar un fecha superior a: ' + moment(this.state.ultimaClaseFecha).format('DD-MM-YY HH:mm') + '. Normalmente debería indicar esta fecha: ' + moment(this.state.ultimaClaseFecha).add(7, 'd').format('DD-MM-YY HH:mm'), 'info')
            }

          } else {
            let aplCurso = this.state.aplCurso
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            // swal('Error', 'Debe seleccionar un fecha superior a: ' + moment(this.state.ultimaClaseFecha).format('DD-MM-YY HH:mm') + '. Normalmente debería indicar esta fecha: ' + moment(this.state.ultimaClaseFecha).add(7, 'd').format('DD-MM-YY HH:mm'), 'info')
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }



      } else if (e.target.id == 'aplicaProgramacion') {

        let { aplCurso, busquedaSemanaProgramacion } = this.state

        let infoProgramacionInsert = [];

        if (aplCurso && aplCurso.modo == 'completa') {
          swal({
            title: 'Aplicando Semanas de Clase',
            text: '¡Espere, este proceso puede tardar!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })

          let semanaObject = this.state.aplCurso.semanaSelected
          let claseObject = this.state.busquedaCursosBase.find(prg => prg.id_clase == this.state.aplCurso.cursoDestinoId)

       

          let posicionPrg = this.state.busquedaSemanaProgramacion.findIndex(prg => prg.id_programa == semanaObject.id_programa)
          let numSemanaPrimera = this.state.busquedaSemanaProgramacion[posicionPrg].numero_semana
         


          for (var i = 0; i < busquedaSemanaProgramacion.length; i++) {
            let fechaInicioPrg
            if (i == 0) {
              fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")
            } else {
              fechaInicioPrg = moment(aplCurso.fechaStart).add(7 * i, 'd').format("YYYY-MM-DD HH:mm")
            }

            let posicionIdPrgCorrecta = posicionPrg + i;
            if (posicionIdPrgCorrecta >= busquedaSemanaProgramacion.length) {
              posicionIdPrgCorrecta = posicionIdPrgCorrecta - busquedaSemanaProgramacion.length
            }
            let idPrg = busquedaSemanaProgramacion[posicionIdPrgCorrecta].id_programa
            //let numero_semana = i + 1;
            let numero_semana = busquedaSemanaProgramacion[posicionIdPrgCorrecta].numero_semana
            let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
            let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
            let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

            infoProgramacionInsert.push({
              id_programa: idPrg,
              id_clase: claseObject.id_clase,
              numero_semana_clase: numero_semana,
              fecha_clase: fechaInicioPrgInser,
              fecha_mostrar_tema: fechaDesbloqueoTema,
              fecha_mostrar_test: fechaDesbloqueoTest
            })
          }

          var data = { programacion: infoProgramacionInsert }

          fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) {
              swal('Error', 'El servidor fallo al intentar este procedmiento. Vuelva a intentarlo', 'warning');
              throw new Error("Bad response from server");
            }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {

              swal({
                title: 'Proceso completado',
                text: '¡Clases aplicadas correctamente!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2500,
              }).then(() => {
                window.location.reload()
              })
            }
          }).catch(function (err) { console.log(err) });

        } else if (aplCurso && aplCurso.modo == 'semana') {
          swal({
            title: 'Aplicando 1 Semana de Clase',
            text: '¡Espere, un momento!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })

          let semanaObject = this.state.aplCurso.semanaSelected
          let claseObject = this.state.busquedaCursosBase.find(prg => prg.id_clase == this.state.aplCurso.cursoDestinoId)

          let fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")



          let idPrg = semanaObject.id_programa
          let numero_semana = semanaObject.numero_semana
          let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
          let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
          let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

          infoProgramacionInsert.push({
            id_programa: idPrg,
            id_clase: claseObject.id_clase,
            numero_semana_clase: numero_semana,
            fecha_clase: fechaInicioPrgInser,
            fecha_mostrar_tema: fechaDesbloqueoTema,
            fecha_mostrar_test: fechaDesbloqueoTest
          })


          var data = { programacion: infoProgramacionInsert }
          fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) {
              swal('Error', 'El servidor fallo al intentar este procedmiento. Vuelva a intentarlo', 'warning');
              throw new Error("Bad response from server");
            }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {

              swal({
                title: 'Proceso completado',
                text: '¡Clase aplicada correctamente!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2500,
              }).then(() => {
                window.location.reload()
              })
            }
          }).catch(function (err) { console.log(err) });

        }



      }


    } else if (this.props.selectedOption == 'Eliminar_Programacion') {

      if (e.target.name === 'oposicionSelecionada') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
        if (oposicionObject != undefined) {
          let data = { id_oposicion: oposicionObject.id_oposicion };
          fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => this.setState({ busquedaClasesSemanas: cls, displayClaseInput: 'block' })
          ).catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ busquedaClasesSemanas: undefined, displayClaseInput: 'none' });
          this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
        }

      }

      // else if (e.target.id == 'claseSelecionada') {

      //   claseSelecionada


      // }


    } else if (this.props.selectedOption == 'Posponer_Clase') {
      let semanasAdelantar = this.refs.ref_semanasAdelantar.refs.semanasAdelantar.value;
      let fechaPosponer1 = this.refs.ref_fechaPosponer1.refs.fechaPosponer1.value;
      if ((semanasAdelantar.length > 0 && fechaPosponer1.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }
    } else if (this.props.selectedOption == 'Atrasar_Clases') {
      let semanasAtrasar = this.refs.ref_semanasAtrasar.refs.semanasAtrasar.value;
      let fechaAtrasar = this.refs.ref_fechaAtrasar.refs.fechaAtrasar.value;
      let fechaFinUpdate3 = this.refs.ref_fechaFinUpdate3.refs.fechaFinUpdate3.value;
      if ((semanasAtrasar.length > 0 && fechaAtrasar.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }

    } else if (this.props.selectedOption == 'Add_Semana_to_Programacion') {
      let oposicionSelecionada = this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value;
      let claseSelecionada = this.refs.ref_claseSelecionada.refs.claseSelecionada.value;
      let semanaProgramacion = this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value;
      let fechaStart = this.refs.ref_fechaStart.refs.fechaStart.value;
      let infoProgramaT = this.state.infoPrograma;
      if ((oposicionSelecionada.length > 0 && claseSelecionada.length > 0 && semanaProgramacion.length > 0 && fechaStart.length > 0 && infoProgramaT.length > 0)) {
        this.setState({ statusBarraProceso: "enviar" });
      } else {
        this.setState({ statusBarraProceso: "completar" });
      }

      if (e.target.name === 'oposicionSelecionada') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
        if (oposicionObject != undefined) {
          let data = { id_oposicion: oposicionObject.id_oposicion };
          fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => this.setState({ busquedaClasesSemanas: cls, readClaseSelecionada: false })
          ).catch(error => console.log('Object fecth failed', error));
        } else {
          this.setState({ busquedaClasesSemanas: undefined, readClaseSelecionada: true, busquedaSemanaProgramacion: undefined, readSemanaProgramacion: true, infoTemasPrograma: [], infoPrograma: [], infoProgramaOrder: [] });
          this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        }
      }
      if (e.target.name === 'claseSelecionada') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
        let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == e.target.value);
        if (oposicionObject != undefined && claseObject != undefined) {
          let data = { idOposicion: oposicionObject.id_oposicion, idClase: claseObject.id_clase };
          let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaID", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;

          await this.setState({ busquedaSemanaProgramacion: cls2 })

          var busquedaClasesFecha2 = await this.state.busquedaSemanaProgramacion
          for (var i = 0; i < busquedaClasesFecha2.length; i++) {
            busquedaClasesFecha2[i].temas = []
          }
          for (var k = 0; k < busquedaClasesFecha2.length; k++) {
            let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


            let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;
            for (var z = 0; z < cls3.length; z++) {
              let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
              if (locaBloque != -1) {
                busquedaClasesFecha2[locaBloque].temas.push({
                  idTema: cls3[z].idTema,
                  tituloTema: cls3[z].tituloTema,
                  nombreTema: cls3[z].nombreTema
                })
              }
            }
          }

          await this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2, readSemanaProgramacion: false });


        } else {
          this.setState({ busquedaClasesSemanas: undefined, readClaseSelecionada: true, busquedaSemanaProgramacion: undefined, readSemanaProgramacion: true, infoTemasPrograma: [], infoPrograma: [], infoProgramaOrder: [] });
          this.refs.ref_semanaProgramacion.refs.semanaProgramacion.value = '';
        }
      }
      if (e.target.name === 'semanaProgramacion') {
        let semanaObject = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == e.target.value);
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
        let idOposicion = oposicionObject.id_oposicion;
        let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == this.state.claseSelecionada);
        if (semanaObject != undefined && claseObject != undefined) {
          let idPrograma = semanaObject.id_programa;
          let data = { idPrograma: idPrograma, idOposicion: idOposicion, idClase: claseObject.id_clase }

          fetch("https://oposiciones-justicia.es/api/oficina/infoPRGselect", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            this.setState({
              infoPrograma: [{
                numeroSemana: cls[0].numeroSemana,
                testJusticia: cls[0].nombreTestJusticia,
                testWord: cls[0].nombreTestWord,
                casoPractico: cls[0].nombreCasoPractico
              }]
            })
          }).catch(error => console.log('Object fecth failed', error));

          fetch("https://oposiciones-justicia.es/api/oficina/infoTemasPRGselect", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            let prevInfoTemaPRG = [];
            let tipoTema = '';
            for (var i = 0; i < cls.length; i++) {
              if (1 == cls[i].tipoTema) { tipoTema = "Repaso" } else { tipoTema = "Oficial" }
              prevInfoTemaPRG.push({
                tituloTema: cls[i].tituloTema,
                nombreTema: cls[i].nombreTema,
                tipoTema: tipoTema
              })
            }
            this.setState({ infoTemasPrograma: prevInfoTemaPRG })
          }).catch(error => console.log('Object fecth failed', error))

          fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrder", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            let prevOrderPrg = [];
            for (var i = 0; i < cls.length; i++) {
              prevOrderPrg.push({
                idPrograma: cls[i].idPrograma
              })
            }
            this.setState({ infoProgramaOrder: prevOrderPrg })
          }).catch(error => console.log('Object fecth failed', error))

          let dataIPC = { idClase: claseObject.id_clase }
          fetch("https://oposiciones-justicia.es/api/oficina/infoProgramasClases", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataIPC)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            let maximaSemana = Math.max.apply(Math, cls.map(function (o) { return o.numero_semana_clase; }))
            let ultimaClase = cls.filter(c => c.numero_semana_clase == maximaSemana)[0].fecha_clase
            this.setState({
              ultimaClaseFecha: ultimaClase,
              nuevaSemana: maximaSemana + 1,
              programasDeClase: cls
            })
          }).catch(error => console.log('Object fecth failed', error))

        } else {
          this.setState({ programasDeClase: undefined, infoTemasPrograma: [], infoPrograma: [], infoProgramaOrder: [], ultimaClaseFecha: [] })
        }
      }
    }

  }

  async handleSubmitForm(e) {
    switch (e.target.id) {
      case 'aplicar_programacion': {

        let { infoProgramaOrder, semanaProgramacion, claseSelecionada, fechaStart } = this.state

        let infoProgramacionInsert = [];
        if (isDefined(semanaProgramacion) && isDefined(claseSelecionada) && isDefined(fechaStart)) {

          swal({
            title: 'Aplicando Semanas de Clase',
            text: '¡Espere, este proceso puede tardar!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })
          let semanaObject = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == semanaProgramacion);
          let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == claseSelecionada);


          let posicionPrg = this.state.infoProgramaOrder.findIndex(prg => prg.idPrograma == semanaObject.id_programa);

          for (var i = 0; i < infoProgramaOrder.length; i++) {
            let fechaInicioPrg
            if (i == 0) {
              fechaInicioPrg = moment(fechaStart).format("YYYY-MM-DD HH:mm:ss")
            } else {
              fechaInicioPrg = moment(fechaStart).add(7, 'd').format("YYYY-MM-DD HH:mm:ss")
              fechaStart = moment(fechaStart).add(7, 'd').format("YYYY-MM-DD HH:mm:ss")
            }

            let posicionIdPrgCorrecta = posicionPrg + i;
            if (posicionIdPrgCorrecta >= infoProgramaOrder.length) {
              posicionIdPrgCorrecta = posicionIdPrgCorrecta - infoProgramaOrder.length
            }
            let idPrg = infoProgramaOrder[posicionIdPrgCorrecta].idPrograma
            let numero_semana = i + 1;

            let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
            let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
            let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")

            infoProgramacionInsert.push({
              id_programa: idPrg,
              id_clase: claseObject.id_clase,
              numero_semana_clase: numero_semana,
              fecha_clase: fechaInicioPrgInser,
              fecha_mostrar_tema: fechaDesbloqueoTema,
              fecha_mostrar_test: fechaDesbloqueoTest
            })
          }

          var data = { programacion: infoProgramacionInsert }

          fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {
              this.cleanForm();
            }
          }).catch(function (err) { console.log(err) });

        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'aplicar_semana_programacion': {

        let { semanaProgramacion, claseSelecionada, fechaStart, ultimaClaseFecha, nuevaSemana, programasDeClase } = this.state

        let infoProgramacionInsert = [];
        if (isDefined(semanaProgramacion) && isDefined(claseSelecionada) && isDefined(fechaStart)) {
          let semanaObject2 = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == semanaProgramacion);
          let pDC = programasDeClase.filter(pc => pc.id_programa == semanaObject2.id_programa)
          if (pDC && pDC.length == 0) {
            let fechaInicioPrg = moment(fechaStart).format("YYYY-MM-DD HH:mm:ss")
            let fechaNuevaSemana = moment(ultimaClaseFecha).format("YYYY-MM-DD HH:mm:ss")

            if (fechaInicioPrg > fechaNuevaSemana) {

              swal({
                title: 'Aplicando Clase',
                text: '¡Espere, este proceso puede tardar!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })


              let semanaObject = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == semanaProgramacion);
              let claseObject = this.state.busquedaClasesSemanas.find(prg => prg.dia_semanal == claseSelecionada);

              let numero_semana = nuevaSemana;
              let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
              let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
              let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
              infoProgramacionInsert.push({
                id_programa: semanaObject.id_programa,
                id_clase: claseObject.id_clase,
                numero_semana_clase: numero_semana,
                fecha_clase: fechaInicioPrgInser,
                fecha_mostrar_tema: fechaDesbloqueoTema,
                fecha_mostrar_test: fechaDesbloqueoTest
              })


              var data = { programacion: infoProgramacionInsert }

              fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
              }).then(response => {
                if (response.status >= 400) { throw new Error("Bad response from server"); }
                return response.json();
              }).then(data => {
                if (data.affectedRows > 0) {
                  this.cleanForm();
                }
              }).catch(function (err) { console.log(err) });
            } else {
              swal("La clase tiene que tener una fecha superior a: " + fechaNuevaSemana + '', 'Recuerda deberia ser una semana más tarde', 'warning')
            }
          } else {
            swal("Esta semana ya esta siendo utilizada, debe clonar la semana para pode usarla.", 'El id del programa:' + semanaObject2.id_programa + '.', "warning");
          }
        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'clonar_programacion': {
        let { semanasPrg, clonarSemanas, semanaCargadaParaCopiar } = this.state
        if (isDefined(semanasPrg) && isDefined(this.state.aplCurso)) {
          swal({
            title: 'Insertando y aplicando Semanas Clonadas',
            text: '¡Espere, este proceso puede tardar!',
            icon: "https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })
          try {

            let idCursoDestino = this.state.aplCurso.cursoDestinoId

            if (this.state.aplCurso.modo == 'completa') {

              for (var u = 0; u < semanasPrg.length; u++) {
                semanasPrg[u].idClase = idCursoDestino
              }
              let semanaObject = this.state.aplCurso.semanaSelected
              semanaObject.idClase = idCursoDestino

              for (var q = 0; q < semanasPrg.length; q++) {

                let posicionPrg = this.state.semanasPrg.findIndex(prg => prg.idPrg == semanaObject.idPrg)
                let posicionIdPrgCorrecta = posicionPrg + q;
                if (posicionIdPrgCorrecta >= semanasPrg.length) {
                  posicionIdPrgCorrecta = posicionIdPrgCorrecta - semanasPrg.length
                }

                let infoSemanaPrg = await semanasPrg[posicionIdPrgCorrecta]

                let bloqueTemas = await semanasPrg[posicionIdPrgCorrecta].bloqueTemas

                let fechaClaseBase = this.state.aplCurso.fechaStart


                // 1º Semana programacion --> insertar infoSemanaPrg
                let data = { infoSemanaPrg: infoSemanaPrg }
                let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarSemanaPrgCompleta", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                infoSemanaPrg.idPrg = cls1.insertId
                // idPrg
                if (cls1.length != 0 || cls1 != undefined) {
                  // 2º Insertar Bloques de Temas
                  for (var i = 0; i < bloqueTemas.length; i++) {
                    //2.1 Bloque --> insertar bloqueTemas[i]
                    let data1 = { idSemanaPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase, bloqueTemas: bloqueTemas[i] }
                    let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarBloque2", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;
                    bloqueTemas[i].idBloque = await cls2.insertId
                    // idBloque
                    // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
                    for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                      // 2º.2.1 Tema de bloque --> insertar bloqueTemas[i].temas[j]
                      let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[i].idBloque, tema: bloqueTemas[i].temas[j] }
                      let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg2", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response3.ok) {
                        throw Error(response3.statusText);
                      }
                      let json3 = await response3.json();
                      let cls3 = await json3;
                      bloqueTemas[i].temas[j].idTemaPrg = cls3.insertId
                      // idTemaPrg


                      // insertar test Justicia Bloque
                      if (bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length; k++) {

                          let data6 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined)[k].idTestJusticia }
                          let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                          });
                          if (!response6.ok) {
                            throw Error(response6.statusText);
                          }
                          let json6 = await response6.json();
                          let cls6 = await json6;
                        }
                      }

                      //insertar casos practicos  Bloque
                      if (bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length; k++) {

                          let data7 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined)[k].idTestCP }
                          let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                          });
                          if (!response7.ok) {
                            throw Error(response7.statusText);
                          }
                          let json7 = await response7.json();
                          let cls7 = await json7;
                        }
                      }

                      //insertar examens desarrollo Bloque
                      if (bloqueTemas[i].examenGestionPdf.filter(c => c.idEjer != undefined).length > 0) {
                        for (var k = 0; k < bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined).length; k++) {

                          let data7X2 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined)[k].idExamenGestion }
                          let response7X2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarExamDesarolloBloque", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7X2)
                          });
                          if (!response7X2.ok) {
                            throw Error(response7X2.statusText);
                          }
                          let json7X2 = await response7X2.json();
                          let cls7X2 = await json7X2;
                        }
                      }


                    }


                  }

                }

                // 3º Aplicar programacion --------------
                let fechaInicioPrg
                if (q == 0) {
                  fechaInicioPrg = moment(fechaClaseBase).format("YYYY-MM-DD HH:mm")
                } else {
                  fechaInicioPrg = moment(fechaClaseBase).add(7 * q, 'd').format("YYYY-MM-DD HH:mm")
                }



                let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
                let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
                let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")

                let infoProgramacionInsert = []

                infoProgramacionInsert.push({
                  id_programa: infoSemanaPrg.idPrg,
                  id_clase: infoSemanaPrg.idClase,
                  numero_semana_clase: infoSemanaPrg.numeroSemana,
                  fecha_clase: fechaInicioPrgInser,
                  fecha_mostrar_tema: fechaDesbloqueoTema,
                  fecha_mostrar_test: fechaDesbloqueoTest
                })


                let data2345 = { programacion: infoProgramacionInsert }
                let response1x234 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2345)
                });
                if (!response1x234.ok) {
                  throw Error(response1x234.statusText);
                }
                let json1x234 = await response1x234.json();
                let cls1x234 = await json1x234;

                // FIN 3º -------------------------------

                // let data = { infoSemanaPrg: infoSemanaPrg }
                // let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarSemanaPrg", {
                //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                // });
                // if (!response1.ok) {
                //   throw Error(response1.statusText);
                // }
                // let json1 = await response1.json();
                // let cls1 = await json1;
                // infoSemanaPrg.idPrg = cls1.insertId
                // // idPrg
                // if (cls1.length != 0 || cls1 != undefined) {

                //   for (var k = 0; k < infoSemanaPrg.videos.length; k++) {

                //     let data8 = { idPrg: infoSemanaPrg.idPrg, idVideo: infoSemanaPrg.videos[k].idVideo }
                //     let response8 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarVideosSemana", {
                //       method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data8)
                //     });
                //     if (!response8.ok) {
                //       throw Error(response8.statusText);
                //     }
                //     let json8 = await response8.json();
                //     let cls8 = await json8;
                //   }

                //   // 2º Bloques de Temas 1 a 1. -->  FOR (i) bloqueTemas.length
                //   for (var i = 0; i < bloqueTemas.length; i++) {
                //     //2.1 Bloque --> insertar bloqueTemas[i]
                //     let data1 = { idSemanaPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase, bloqueTemas: bloqueTemas[i] }

                //     let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarBloque", {
                //       method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                //     });
                //     if (!response2.ok) {
                //       throw Error(response2.statusText);
                //     }
                //     let json2 = await response2.json();
                //     let cls2 = await json2;
                //     bloqueTemas[i].idBloque = cls2.insertId


                //     // idBloque


                //     // insertar test Justicia
                //     for (var k = 0; k < bloqueTemas[i].testJusticia.length; k++) {
                //       // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                //       let data6 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].testJusticia[k].idTestJusticia }
                //       let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                //       });
                //       if (!response6.ok) {
                //         throw Error(response6.statusText);
                //       }
                //       let json6 = await response6.json();
                //       let cls6 = await json6;
                //     }
                //     //insertar casos practicos
                //     for (var k = 0; k < bloqueTemas[i].casoPractico.length; k++) {
                //       // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                //       let data7 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].casoPractico[k].idCasoPractico }
                //       let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                //       });
                //       if (!response7.ok) {
                //         throw Error(response7.statusText);
                //       }
                //       let json7 = await response7.json();
                //       let cls7 = await json7;
                //     }

                //     // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
                //     for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                //       // 2º.2.1 Tema de bloque --> insertar bloqueTemas[i].temas[j]
                //       let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[i].idBloque, tema: bloqueTemas[i].temas[j] }

                //       let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg", {
                //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                //       });
                //       if (!response3.ok) {
                //         throw Error(response3.statusText);
                //       }
                //       let json3 = await response3.json();
                //       let cls3 = await json3;
                //       bloqueTemas[i].temas[j].idTemaPrg = cls3.insertId
                //       // idTemaPrg
                //       // 2º.2.1.1 Intervalos de Leyes de Tema --> FOR (k) bloqueTemas[i].temas[j].intervalosLeyes.length
                //       for (var k = 0; k < bloqueTemas[i].temas[j].intervalosLeyes.length; k++) {
                //         // 2º.2.1.1.1 Insertar leyes --> bloqueTemas[i].temas[j].intervalosLeyes[k]
                //         let data3 = { idTemaPrg: bloqueTemas[i].temas[j].idTemaPrg, intervaloLey: bloqueTemas[i].temas[j].intervalosLeyes[k] }
                //         let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarIntervaloLey", {
                //           method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                //         });
                //         if (!response4.ok) {
                //           throw Error(response4.statusText);
                //         }
                //         let json4 = await response4.json();
                //         let cls4 = await json4;
                //       }
                //       // 2º.2.1.2 apuntes de Tema --> FOR (k) bloqueTemas[i].temas[j].apuntes.length
                //       for (var k = 0; k < bloqueTemas[i].temas[j].apuntes.length; k++) {
                //         // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                //         let data4 = { idTemaPrg: bloqueTemas[i].temas[j].idTemaPrg, apunte: bloqueTemas[i].temas[j].apuntes[k] }
                //         let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarApunteTemaPrg", {
                //           method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                //         });
                //         if (!response5.ok) {
                //           throw Error(response5.statusText);
                //         }
                //         let json5 = await response5.json();
                //         let cls5 = await json5;
                //       }



                //     }


                //   }

                // }

              }
              // swal('Semanas clonadas a la clase nueva Correctamente', 'Aplique la programacion para poder editarla en preparador', 'success')
              swal('Semanas clonadas a la clase/curso', 'Clonación total y clases aplicadas en la fecha indicada secuencialmente +7 días.', 'success')
              /*setTimeout(
              function() {
                  window.location.reload();
              }.bind(this),3000);*/
            } else if (this.state.aplCurso.modo == 'semana') {



              let infoSemanaPrg = this.state.aplCurso.semanaSelected
              infoSemanaPrg.idClase = idCursoDestino
              let bloqueTemas = infoSemanaPrg.bloqueTemas


              let fechaClaseBase = this.state.aplCurso.fechaStart


              // 1º Semana programacion --> insertar infoSemanaPrg
              let data = { infoSemanaPrg: infoSemanaPrg }
              let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarSemanaPrgCompleta", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              infoSemanaPrg.idPrg = cls1.insertId
              // idPrg
              if (cls1.length != 0 || cls1 != undefined) {
                // 2º Insertar Bloques de Temas
                for (var i = 0; i < bloqueTemas.length; i++) {
                  //2.1 Bloque --> insertar bloqueTemas[i]
                  let data1 = { idSemanaPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase, bloqueTemas: bloqueTemas[i] }
                  let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarBloque2", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;
                  bloqueTemas[i].idBloque = await cls2.insertId
                  // idBloque
                  // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
                  for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                    // 2º.2.1 Tema de bloque --> insertar bloqueTemas[i].temas[j]
                    let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[i].idBloque, tema: bloqueTemas[i].temas[j] }
                    let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg2", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                    });
                    if (!response3.ok) {
                      throw Error(response3.statusText);
                    }
                    let json3 = await response3.json();
                    let cls3 = await json3;
                    bloqueTemas[i].temas[j].idTemaPrg = cls3.insertId
                    // idTemaPrg


                    // insertar test Justicia Bloque
                    if (bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length > 0) {
                      for (var k = 0; k < bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined).length; k++) {

                        let data6 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].testJusticia.filter(c => c.idTestJusticia != undefined)[k].idTestJusticia }
                        let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                        });
                        if (!response6.ok) {
                          throw Error(response6.statusText);
                        }
                        let json6 = await response6.json();
                        let cls6 = await json6;
                      }
                    }

                    //insertar casos practicos  Bloque
                    if (bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length > 0) {
                      for (var k = 0; k < bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined).length; k++) {

                        let data7 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].casoPractico.filter(c => c.idTestCP != undefined)[k].idTestCP }
                        let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                        });
                        if (!response7.ok) {
                          throw Error(response7.statusText);
                        }
                        let json7 = await response7.json();
                        let cls7 = await json7;
                      }
                    }

                    //insertar examens desarrollo Bloque
                    if (bloqueTemas[i].examenGestionPdf.filter(c => c.idEjer != undefined).length > 0) {
                      for (var k = 0; k < bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined).length; k++) {

                        let data7X2 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].examenGestionPdf.filter(c => c.idExamenGestion != undefined)[k].idExamenGestion }
                        let response7X2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarExamDesarolloBloque", {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7X2)
                        });
                        if (!response7X2.ok) {
                          throw Error(response7X2.statusText);
                        }
                        let json7X2 = await response7X2.json();
                        let cls7X2 = await json7X2;
                      }
                    }


                  }


                }

              }

              // 3º Aplicar programacion --------------

              let fechaInicioPrg = moment(fechaClaseBase).format("YYYY-MM-DD HH:mm:ss")

              let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")
              let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm:ss")
              let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm:ss")

              let infoProgramacionInsert = []

              infoProgramacionInsert.push({
                id_programa: infoSemanaPrg.idPrg,
                id_clase: infoSemanaPrg.idClase,
                numero_semana_clase: infoSemanaPrg.numeroSemana,
                fecha_clase: fechaInicioPrgInser,
                fecha_mostrar_tema: fechaDesbloqueoTema,
                fecha_mostrar_test: fechaDesbloqueoTest
              })


              let data2345 = { programacion: infoProgramacionInsert }
              let response1x234 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2345)
              });
              if (!response1x234.ok) {
                throw Error(response1x234.statusText);
              }
              let json1x234 = await response1x234.json();
              let cls1x234 = await json1x234;


              swal('Semana clonada a la clase/curso', 'Clonada y aplicada en la fecha indicada.', 'success')

            } else {
              swal('Error debe seleccionar que debemos clonar', '1 semana o toda la programacion completa', 'error')
            }
          } catch (e) { console.log(e) }




          // fin menter semanas

        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'adelantar_programacion': {
        let { semanasAdelantar, fechaPosponer1, fechaPosponer2 } = this.state
        if (isDefined(semanasAdelantar) && isDefined(fechaPosponer1)) {
          var data = { semanasAdelantar: semanasAdelantar, fechaPosponer1: fechaPosponer1 }
          fetch("https://oposiciones-justicia.es/api/oficina/APClaseAdelantar", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {
              this.cleanForm();
            }
          }).catch(function (err) { console.log(err) });
        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'atrasar_programacion': {
        let { semanasAtrasar, fechaAtrasar } = this.state
        if (isDefined(semanasAtrasar) && isDefined(fechaAtrasar)) {
          var data = { semanasAtrasar: semanasAtrasar, fechaAtrasar: fechaAtrasar }
          fetch("https://oposiciones-justicia.es/api/oficina/APClaseAtrasar", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {
              this.cleanForm();
            }
          }).catch(function (err) { console.log(err) });
        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
      case 'eliminar_programacion': {
        let { oposicionSelecionada, claseSelecionada, busquedaClasesSemanas, busquedaOpo } = this.state

        if (isDefined(oposicionSelecionada) && isDefined(claseSelecionada)) {
          let objectOpo = busquedaOpo.find(opo => opo.nombre == oposicionSelecionada);
          let claseObject = busquedaClasesSemanas.find(prg => prg.dia_semanal == claseSelecionada);
          if (claseObject != undefined) {

            swal({
              title: "¿Estas segur@?",
              text: "Si le das a ok, toda la programación base y aplicada de la clase sera eliminada. Para quitar una clase puede hacerlo desde Programación Dinamica - Eliminar.",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
              .then(async (willDelete) => {
                if (willDelete) {

                  let data = { idOposicion: objectOpo.id_oposicion, idClase: claseObject.id_clase }
                  let response = await fetch('https://oposiciones-justicia.es/api/oficina/APBorrarProgramacionClase', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
                  let json = await response.json();
                  let cls = await json;

                  let data1 = { idClase: claseObject.id_clase }
                  let response1 = await fetch('https://oposiciones-justicia.es/api/oficina/prgApDel', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                  });
                  if (!response1.ok) {
                    throw Error(response1.statusText);
                  }
                  let json1 = await response1.json();
                  let cls1 = await json1;


                  await swal({
                    title: 'Programación eliminada correctamente',
                    text: '¡Recargando!',
                    icon: "success",
                    buttons: false,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    timer: 1500,
                  }).then(() => {
                    window.location.reload()
                  })

                } else {
                  swal("¡Programación no borrada!", '', "success");
                }
              });
          } else {
            swal('El nombre de la clase/curso es incorrecto', 'Introduzca y seleccione una clase/curso valido', 'info')
          }


        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;
    }
  }

  async onChangeInputNew(e) {

    if (e.target.name == 'nombre_opo_añadir') {
      try {
        let value = e.target.value
        let oposicionObject = await this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value)
        if (oposicionObject != undefined) {
          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1.length != 0 || cls1 != undefined) {
            await this.setState({ busquedaClases: cls1, displayClases: 'block' });
          } else {
            this.refs.ref_id_clase_selected.refs.id_clase_selected.value = '';
            await this.setState({ displayClases: 'none', busquedaClases: undefined });
          }


          let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaTemasOposicion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          await this.setState({ busquedaTemasOposicion: cls2 });

          let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/apuntesTemas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json3 = await response3.json();
          let cls3 = await json3;
          await this.setState({ apuntesTemas: cls3 });
          let infoSem = await this.state.infoSemanaPrg
          infoSem.idOposicion = oposicionObject.id_oposicion
          infoSem.nombreOposicion = oposicionObject.nombre,
            infoSem.tituloOpo = oposicionObject.nombre
          await this.setState({ infoSemanaPrg: infoSem });
        } else {
          let infoSem = this.state.infoSemanaPrg
          infoSem.tituloOpo = value
          this.setState({ infoSemanaPrg: infoSem, displayClases: 'none', cursoBaseSelected: null, idOpoBaseSeleted: null, cursosDinamicos: null, cursoBaseFechaMax: null });
        }

      } catch (e) { console.log(e) }

    } else if (e.target.name == 'id_clase_selected') {
      try {
        let value = e.target.value
        let oposicionObject = await this.state.busquedaOpo.find(oposicion => oposicion.id_oposicion == this.state.infoSemanaPrg.idOposicion)
        let claseObject = await this.state.busquedaClases.find(clase => clase.id_clase == value)

        if (claseObject != undefined && oposicionObject != undefined) {
          let data = { idOpo: oposicionObject.id_oposicion, idClase: claseObject.id_clase }
          let response1 = await fetch("https://oposiciones-justicia.es/api/prosemanal/programasDeClase", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1.length != 0 || cls1 != undefined) {
            // SACAR SEMANA BASE ULTIMA --> 

            let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrderFecha", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;
            if (cls2.length > 0) {
              let cursoBaseFechaMax = cls2.sort((a, b) => new Date(b.fecha_clase) - new Date(a.fecha_clase))[0].fecha_clase
              this.setState({ cursoBaseFechaMax: cursoBaseFechaMax })
            } else {
              this.setState({ cursoBaseFechaMax: null })
            }

            let maximaSemana = Math.max.apply(Math, cls1.map(function (o) { return o.numero_semana; }))

            let infoSem = this.state.infoSemanaPrg
            infoSem.numeroSemana = cls1.length > 0 ? (maximaSemana + 1) : (1);
            infoSem.idClase = claseObject.id_clase
            this.setState({ programasDeClase: cls1, infoSemanaPrg: infoSem, listaSemanasClase: cls1, maxNumSemRecomendado: cls1.length > 0 ? (maximaSemana + 1) : (1), cursoBaseSelected: claseObject.id_clase, idOpoBaseSeleted: oposicionObject.id_oposicion });

          } else {
            let infoSem = this.state.infoSemanaPrg
            infoSem.idClase = claseObject.id_clase
            this.setState({ programasDeClase: undefined, infoSemanaPrg: infoSem, listaSemanasClase: undefined, maxNumSemRecomendado: 1, cursoBaseSelected: claseObject.id_clase, idOpoBaseSeleted: oposicionObject.id_oposicion });

          }

        } else {
          let infoSem = this.state.infoSemanaPrg
          infoSem.idClase = value

          this.setState({ infoSemanaPrg: infoSem, cursoBaseSelected: null, idOpoBaseSeleted: null, cursosDinamicos: null, cursoBaseFechaMax: null });
        }

      } catch (e) { console.log(e) }

    } else if (e.target.name == 'test_pred_añadir') {

      let objectTest = this.state.busquedaTestPre.filter(t => t.nombre_test == e.target.value)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idTestJusticia = objectTest[0].id_test
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'test_word_pred_añadir') {

      let objectTest = this.state.busquedaTestPreWord.filter(t => t.nombre_test == e.target.value)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idTestWord = objectTest[0].id_test
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'test_caso_practico_pred_añadir') {

      let objectTest = this.state.busquedaTestPreCasoPractico.filter(t => t.nombre_cp == e.target.value)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idTestCasoPractico = objectTest[0].id_caso_practico
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'meca_pred_añadir') {

      let objectTest = this.state.busquedaMeca.filter(t => t.titulo_ejercicio == e.target.value)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idCasoMecanografia = objectTest[0].id_meca
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'num_semana_añadir') {
      let value = e.target.value
      let infoSem = await this.state.infoSemanaPrg

      if (this.state.listaSemanasClase != undefined && (this.state.listaSemanasClase.length == 0 || this.state.listaSemanasClase.filter(s => s.numero_semana == value).length == 0)) {
        infoSem.numeroSemana = value
        await this.setState({ infoSemanaPrg: infoSem })
      } else {
        swal('El número de semana ya existe. Semana auto-recomendada:' + this.state.maxNumSemRecomendado + '.', 'Listado de Semanas existentes :' + this.state.listaSemanasClase.map((cls, key) => cls.numero_semana + '') + '.', 'error')

      }

    } else if (e.target.name.split('-')[0] == 'test_pred_añadir') {

      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.busquedaTestPre.filter(t => t.nombre_test == e.target.value)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idTestExtra = objectTest[0].id_test
        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'test_caso_practico_pred_añadir') {
      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.busquedaTestPreCasoPractico.filter(t => t.nombre_cp == e.target.value)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idCasoExtra = objectTest[0].id_caso_practico
        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'tipoBloque') {
      let locaBloque = e.target.name.split('-')[1]
      if (e.target.value == 'Normal') {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].tipoBloque = 0
        bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
        this.setState({ bloqueTemas: bloqueTemas })
      } else if (e.target.value == "Repaso") {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].tipoBloque = 1
        bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
        this.setState({ bloqueTemas: bloqueTemas })
      } else {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].tipoBloque = undefined
        bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
        this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'temaName') {
      try {
        // console.log('this.state.busquedaTemasOposicion')
        // console.log(this.state.busquedaTemasOposicion)
        let val = e.target.value
        let locaBloque = e.target.name.split('-')[1]
        let locaTema = e.target.name.split('-')[2]
        let objectTemas = await this.state.busquedaTemasOposicion.filter(t => t.tituloTema == e.target.value)
        if (await objectTemas.length > 0) {
          let bloqueTemas = this.state.bloqueTemas
          bloqueTemas[locaBloque].temas[locaTema].idTema = objectTemas[0].idTema
          bloqueTemas[locaBloque].temas[locaTema].tituloTema = objectTemas[0].tituloTema
          bloqueTemas[locaBloque].temas[locaTema].nombreTema = objectTemas[0].nombreTema

          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = []
          this.setState({ bloqueTemas: bloqueTemas })

        } else {
          let bloqueTemas = this.state.bloqueTemas
          bloqueTemas[locaBloque].temas[locaTema].idTema = undefined
          bloqueTemas[locaBloque].temas[locaTema].tituloTema = val
          bloqueTemas[locaBloque].temas[locaTema].nombreTema = undefined
          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = []
          this.setState({ bloqueTemas: bloqueTemas })
        }


      } catch (e) { console.log(e) }
    } else if (e.target.name.split('-')[0] == 'siglasLey') {
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaInter = e.target.name.split('-')[3]

      let objectLeyes = this.state.busquedaLeyes.filter(l => l.siglas_ley == e.target.value)
      if (objectLeyes.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].idLey = objectLeyes[0].id_ley
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].nombreLey = objectLeyes[0].nombre_ley
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].siglasLey = objectLeyes[0].siglas_ley
        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'artInicio') {
      try {
        let value = e.target.value
        let locaBloque = e.target.name.split('-')[1]
        let locaTema = e.target.name.split('-')[2]
        let locaInter = e.target.name.split('-')[3]

        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].artInicio = value

        await this.setState({ bloqueTemas: bloqueTemas })

      } catch (e) { console.log(e) }


    } else if (e.target.name.split('-')[0] == 'artFin') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaInter = e.target.name.split('-')[3]
      let bloqueTemas = await this.state.bloqueTemas

      bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].artFin = value
      await this.setState({ bloqueTemas: bloqueTemas })
    } else if (e.target.name.split('-')[0] == 'apunte') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaApunte = e.target.name.split('-')[3]

      let objectApuntes = this.state.apuntesTemas.filter(l => l.titulo == value && l.idTema == this.state.bloqueTemas[locaBloque].temas[locaTema].idTema)

      if (objectApuntes.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].idApunte = objectApuntes[0].idApunte
        bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].tituloApunte = objectApuntes[0].titulo

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'wordPdf') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]

      let objectWord = this.state.busquedaWordPDF.find(l => l.id == value.split('-')[0])

      if (objectWord != undefined) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idWordTestPDF = objectWord.id

        await this.setState({ bloqueTemas: bloqueTemas })
      }
    } else if (e.target.name.split('-')[0] == 'examenGestionPdfAñadir') {


      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTest = e.target.name.split('-')[2]

      let objectExamGes = this.state.loadExamanesGestion.find(l => l.nombre == value)

      if (objectExamGes != undefined) {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = objectExamGes.id
        bloqueTemas[locaBloque].examenGestionPdf[locaTest].titulo = objectExamGes.nombre
        this.setState({ bloqueTemas: bloqueTemas })
      } else {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = undefined
        bloqueTemas[locaBloque].examenGestionPdf[locaTest].titulo = value
        this.setState({ bloqueTemas: bloqueTemas })
      }
    } else if (e.target.name.split('-')[0] == 'testJusAñadir') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTest = e.target.name.split('-')[2]

      let objectTest = this.state.busquedaTestPre.find(t => t.nombre_test == e.target.value)

      if (objectTest != undefined) {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia = objectTest.id_test
        bloqueTemas[locaBloque].testJusticia[locaTest].titulo = objectTest.nombre_test
        this.setState({ bloqueTemas: bloqueTemas })
      } else {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia = undefined
        bloqueTemas[locaBloque].testJusticia[locaTest].titulo = value
        this.setState({ bloqueTemas: bloqueTemas })
      }
    } else if (e.target.name.split('-')[0] == 'casoPracAñadir') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTest = e.target.name.split('-')[2]

      let objectTest = this.state.busquedaTestPreCasoPractico.find(t => t.nombre_cp == value)

      if (objectTest != undefined) {
        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = objectTest.id_caso_practico
        bloqueTemas[locaBloque].casoPractico[locaTest].titulo = objectTest.nombre_cp
        this.setState({ bloqueTemas: bloqueTemas })
      } else {

        let bloqueTemas = this.state.bloqueTemas
        bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = undefined
        bloqueTemas[locaBloque].casoPractico[locaTest].titulo = value
        this.setState({ bloqueTemas: bloqueTemas })

      }
    }
  }

  async handleSubmitFormNew(e) {
    if (e.target.id == 'add_bloque_temas') {

      try {
        if (this.state.infoSemanaPrg.idOposicion != undefined) {
          let bloqueTemas = await this.state.bloqueTemas
          bloqueTemas.push({
            idBloque: undefined,
            tipoBloque: undefined,
            tipoBloqueTitulo: '',
            idWordTestPDF: 1,
            idExamenGestion: undefined,
            testJusticia: [],
            casoPractico: [],
            examenGestionPdf: [],
            temas: [],
            alumnosInscritos: []
          })
          await this.setState({ bloqueTemas: bloqueTemas })
        } else {
          swal('Debe elegir una oposicion', 'Segun la oposición seleccionada tendra disponibles unos temas y apuntes', 'error')
        }

      } catch (e) { console.log(e) }

    } else if (e.target.id == 'add_semana') {
      try {
        let semanaInfo = await this.state.infoSemanaPrg
        let bloqueTemas = await this.state.bloqueTemas
        let datosValidos = true;
        if (semanaInfo.idOposicion != undefined && semanaInfo.idClase != undefined && semanaInfo.numeroSemana != undefined) {
          for (var i = 0; i < bloqueTemas.length; i++) {
            if (bloqueTemas[i].tipoBloque != undefined) {

              for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                if (bloqueTemas[i].temas[j].idTema != undefined) {

                  for (var k = 0; k < bloqueTemas[i].temas[j].intervalosLeyes.length; k++) {
                    if (bloqueTemas[i].temas[j].intervalosLeyes.length > 0) {

                      if (bloqueTemas[i].temas[j].intervalosLeyes[k].idLey != undefined && bloqueTemas[i].temas[j].intervalosLeyes[k].artInicio != undefined && bloqueTemas[i].temas[j].intervalosLeyes[k].artFin != undefined) {

                      } else {
                        swal('El bloque de temas Nº ' + i + 1 + ', en el intervalos número: ' + k + 1 + 'falta un dato.', '', 'error')
                        break;
                        datosValidos = false
                      }

                    } else {
                      swal('El bloque de temas Nº ' + i + 1 + ', no tiene ningun intervalos de leyes asignado', '', 'error')
                      break;
                      datosValidos = false
                    }
                  }

                  for (var k = 0; k < bloqueTemas[i].temas[j].apuntes.length; k++) {
                    if (bloqueTemas[i].temas[j].apuntes.length > 0) {

                      if (bloqueTemas[i].temas[j].apuntes[k].idApunte != undefined && bloqueTemas[i].temas[j].apuntes[k].tituloApunte != undefined) {

                      } else {
                        swal('El bloque de temas Nº ' + i + 1 + ', en el apunte número: ' + k + 1 + 'falta escoger un apunte.', '', 'error')
                        break;
                        datosValidos = false
                      }

                    } else {
                      swal('El bloque de temas Nº ' + i + 1 + ', no tiene ningun apunte es necesario un apunte por cada tema.', '', 'error')
                      break;
                      datosValidos = false
                    }
                  }

                } else {
                  swal('El bloque de temas Nº ' + i + 1 + ', no tiene ningun tema asignado', '', 'error')
                  break;
                  datosValidos = false
                }
              }

            } else {
              swal('El bloque de temas Nº ' + i + 1 + 'no tiene tipo de bloque', '', 'error')
              break;
              datosValidos = false
            }

          }

        } else {
          swal('Rellene todos los campos de configuracion de semana', '', 'error')
          datosValidos = false
        }

        if (datosValidos == true) {

          swal({
            title: 'Insertando Semana de Programación',
            text: '¡Espere, este proceso puede tardar!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })
          // Realizar fetch async para meter todo en orden
          let bloqueTemas = await this.state.bloqueTemas
          let infoSemanaPrg = await this.state.infoSemanaPrg
          // 1º Semana programacion --> insertar infoSemanaPrg
          let data = { infoSemanaPrg: infoSemanaPrg }
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarSemanaPrg", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          infoSemanaPrg.idPrg = cls1.insertId
          // idPrg
          if (cls1.length != 0 || cls1 != undefined) {
            // 2º Bloques de Temas 1 a 1. -->  FOR (i) bloqueTemas.length
            for (var i = 0; i < bloqueTemas.length; i++) {
              //2.1 Bloque --> insertar bloqueTemas[i]
              let data1 = { idSemanaPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase, bloqueTemas: bloqueTemas[i] }
              let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarBloque", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              bloqueTemas[i].idBloque = cls2.insertId
              // idBloque
              // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
              for (var j = 0; j < bloqueTemas[i].temas.length; j++) {
                // 2º.2.1 Tema de bloque --> insertar bloqueTemas[i].temas[j]
                let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[i].idBloque, tema: bloqueTemas[i].temas[j] }
                let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                bloqueTemas[i].temas[j].idTemaPrg = cls3.insertId
                // idTemaPrg
                // 2º.2.1.1 Intervalos de Leyes de Tema --> FOR (k) bloqueTemas[i].temas[j].intervalosLeyes.length
                for (var k = 0; k < bloqueTemas[i].temas[j].intervalosLeyes.length; k++) {
                  // 2º.2.1.1.1 Insertar leyes --> bloqueTemas[i].temas[j].intervalosLeyes[k]
                  let data3 = { idTemaPrg: bloqueTemas[i].temas[j].idTemaPrg, intervaloLey: bloqueTemas[i].temas[j].intervalosLeyes[k] }
                  let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarIntervaloLey", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                  });
                  if (!response4.ok) {
                    throw Error(response4.statusText);
                  }
                  let json4 = await response4.json();
                  let cls4 = await json4;
                }
                // 2º.2.1.2 apuntes de Tema --> FOR (k) bloqueTemas[i].temas[j].apuntes.length
                for (var k = 0; k < bloqueTemas[i].temas[j].apuntes.length; k++) {
                  // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                  let data4 = { idTemaPrg: bloqueTemas[i].temas[j].idTemaPrg, apunte: bloqueTemas[i].temas[j].apuntes[k] }
                  let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarApunteTemaPrg", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                  });
                  if (!response5.ok) {
                    throw Error(response5.statusText);
                  }
                  let json5 = await response5.json();
                  let cls5 = await json5;
                }

                // insertar test Justicia
                for (var k = 0; k < bloqueTemas[i].testJusticia.length; k++) {
                  // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                  let data6 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].testJusticia[k].idTestJusticia }
                  let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                  });
                  if (!response6.ok) {
                    throw Error(response6.statusText);
                  }
                  let json6 = await response6.json();
                  let cls6 = await json6;
                }
                //insertar casos practicos
                for (var k = 0; k < bloqueTemas[i].casoPractico.length; k++) {
                  // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[i].temas[j].apuntes[k]
                  let data7 = { idBloque: bloqueTemas[i].idBloque, idTest: bloqueTemas[i].casoPractico[k].idTestCP }
                  let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                  });
                  if (!response7.ok) {
                    throw Error(response7.statusText);
                  }
                  let json7 = await response7.json();
                  let cls7 = await json7;
                }

              }


            }

          }
          swal('Semana Insertada Correctamente', '', 'success')
          window.location.reload();


        } else {
          swal('Rellene todos los datos para insertar una semana', '', 'error')
        }

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'delBloqueTema') {

      let locaBloque = e.target.id.split('-')[1]
      let bloqueTemas = await this.state.bloqueTemas

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el bloque de Temas será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            bloqueTemas.splice(locaBloque, 1)
            this.setState({ bloqueTemas: bloqueTemas })

            // for (var i = 0; i < this.state.bloqueTemas.length; i++) {
            //   // rellenar 1 false

            //   this.refs['ref_tipoBloque-' + i].refs['tipoBloque-' + i].value = this.state.bloqueTemas[i].tipoBloque != undefined ? (this.state.bloqueTemas[i].tipoBloque == '0' ? ('Normal') : ('Repaso')) : ('')

            //   this.refs['ref_test_pred_añadir-' + i].refs['test_pred_añadir-' + i].value = this.state.bloqueTemas[i].idTestExtra != undefined ? (this.state.busquedaTestPre.filter(t => t.id_test == this.state.bloqueTemas[i].idTestExtra)[0].nombre_test) : ('')

            //   this.refs['ref_test_caso_practico_pred_añadir-' + i].refs['test_caso_practico_pred_añadir-' + i].value = this.state.bloqueTemas[i].idCasoExtra != undefined ? (this.state.busquedaTestPreCasoPractico.filter(t => t.id_caso_practico == this.state.bloqueTemas[i].idCasoExtra)[0].nombre_cp) : ('')

            //   // temas
            //   for (var j = 0; j < this.state.bloqueTemas[i].temas.length; j++) {
            //     this.refs['ref_temaName-' + i + '-' + j].refs['temaName-' + i + '-' + j].value = this.state.bloqueTemas[i].temas[j].tituloTema != undefined ? (this.state.bloqueTemas[i].temas[j].tituloTema) : ('')

            //     for (var k = 0; k < this.state.bloqueTemas[i].temas[j].intervalosLeyes.length; k++) {

            //       this.refs['ref_siglasLey-' + i + '-' + j + '-' + k].refs['siglasLey-' + i + '-' + j + '-' + k].value = this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].siglasLey != undefined ? (this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].siglasLey) : ('')

            //       this.refs['ref_artInicio-' + i + '-' + j + '-' + k].refs['artInicio-' + i + '-' + j + '-' + k].value = this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].artInicio != undefined ? (this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].artInicio) : ('')

            //       this.refs['ref_artFin-' + i + '-' + j + '-' + k].refs['artFin-' + i + '-' + j + '-' + k].value = this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].artFin != undefined ? (this.state.bloqueTemas[i].temas[j].intervalosLeyes[k].artFin) : ('')

            //     }

            //     for (var k = 0; k < this.state.bloqueTemas[i].temas[j].apuntes.length; k++) {

            //       this.refs['ref_apunte-' + i + '-' + j + '-' + k].refs['apunte-' + i + '-' + j + '-' + k].value = this.state.bloqueTemas[i].temas[j].apuntes[k].tituloApunte != undefined ? (this.state.bloqueTemas[i].temas[j].apuntes[k].tituloApunte) : ('')

            //     }
            //   }

            // }
          } else {
            swal("¡No borrado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'add_tema_bloque') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas
        if (this.state.infoSemanaPrg.idOposicion != undefined) {
          await bloqueTemas[locaBloque].temas.push({
            idTemaPrg: undefined,
            idTema: undefined,
            tipoTema: undefined,
            tituloTema: '',
            nombreTema: undefined,
            nota: null,
            intervalosLeyes: [],
            apuntes: []
          })
          await this.setState({ bloqueTemas: bloqueTemas })
        } else {
          swal('Debe elegir una oposicion', 'Segun la oposición seleccionada tendra disponibles unos temas y apuntes', 'error')
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id.split('-')[0] == 'borrarTema') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].temas.splice(locaTema, 1)
            this.setState({ bloqueTemas: bloqueTemas })

            // temas
            // for (var j = 0; j < this.state.bloqueTemas[locaBloque].temas.length; j++) {
            //   this.refs['ref_temaName-' + locaBloque + '-' + j].refs['temaName-' + locaBloque + '-' + j].value = this.state.bloqueTemas[locaBloque].temas[j].tituloTema != undefined ? (this.state.bloqueTemas[locaBloque].temas[j].tituloTema) : ('')

            //   for (var k = 0; k < this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes.length; k++) {

            //     this.refs['ref_siglasLey-' + locaBloque + '-' + j + '-' + k].refs['siglasLey-' + locaBloque + '-' + j + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].siglasLey != undefined ? (this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].siglasLey) : ('')

            //     this.refs['ref_artInicio-' + locaBloque + '-' + j + '-' + k].refs['artInicio-' + locaBloque + '-' + j + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].artInicio != undefined ? (this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].artInicio) : ('')

            //     this.refs['ref_artFin-' + locaBloque + '-' + j + '-' + k].refs['artFin-' + locaBloque + '-' + j + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].artFin != undefined ? (this.state.bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].artFin) : ('')

            //   }

            //   for (var k = 0; k < this.state.bloqueTemas[locaBloque].temas[j].apuntes.length; k++) {

            //     this.refs['ref_apunte-' + locaBloque + '-' + j + '-' + k].refs['apunte-' + locaBloque + '-' + j + '-' + k].value = bloqueTemas[locaBloque].temas[j].apuntes[k].tituloApunte != undefined ? (bloqueTemas[locaBloque].temas[j].apuntes[k].tituloApunte) : ('')

            //   }
            // }
          } else {
            swal("¡Tema no borrado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'add_intervalor_tema_ley') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.push({
        idLey: undefined,
        nombreLey: undefined,
        siglasLey: undefined,
        artInicio: undefined,
        artFin: undefined
      })
      await this.setState({ bloqueTemas: bloqueTemas })

    } else if (e.target.id.split('-')[0] == 'borrarIntervalo') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let locaInter = e.target.id.split('-')[3]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el intervalo del tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.splice(locaInter, 1)
            await this.setState({ bloqueTemas: bloqueTemas })
            for (var k = 0; k < this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.length; k++) {

              this.refs['ref_siglasLey-' + locaBloque + '-' + locaTema + '-' + k].refs['siglasLey-' + locaBloque + '-' + locaTema + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].siglasLey != undefined ? (this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].siglasLey) : ('')

              this.refs['ref_artInicio-' + locaBloque + '-' + locaTema + '-' + k].refs['artInicio-' + locaBloque + '-' + locaTema + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].artInicio != undefined ? (this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].artInicio) : ('')

              this.refs['ref_artFin-' + locaBloque + '-' + locaTema + '-' + k].refs['artFin-' + locaBloque + '-' + locaTema + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].artFin != undefined ? (this.state.bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].artFin) : ('')

            }
          } else {
            swal("¡Intervalo de tema no borrado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'add_apunte_tema') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      await bloqueTemas[locaBloque].temas[locaTema].apuntes.push({
        idApunte: undefined,
        tituloApunte: undefined
      })
      await this.setState({ bloqueTemas: bloqueTemas })

    } else if (e.target.id.split('-')[0] == 'borrarApunte') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let locaApunte = e.target.id.split('-')[3]
      let bloqueTemas = this.state.bloqueTemas


      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el apunte del tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].temas[locaTema].apuntes.splice(locaApunte, 1)
            await this.setState({ bloqueTemas: bloqueTemas })

            for (var k = 0; k < this.state.bloqueTemas[locaBloque].temas[locaTema].apuntes.length; k++) {

              this.refs['ref_apunte-' + locaBloque + '-' + locaTema + '-' + k].refs['apunte-' + locaBloque + '-' + locaTema + '-' + k].value = this.state.bloqueTemas[locaBloque].temas[locaTema].apuntes[k].tituloApunte != undefined ? (this.state.bloqueTemas[locaBloque].temas[locaTema].apuntes[k].tituloApunte) : ('')

            }


          } else {
            swal("¡Apunte no borrado!", '', "success");
          }
        });

    } else if (e.target.id.split('-')[0] == 'addTestJusticia') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas
        await bloqueTemas[locaBloque].testJusticia.push({
          idTestJusticia: undefined,
          titulo: ''
        })
        await this.setState({ bloqueTemas: bloqueTemas })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'addCasoPractico') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas
        await bloqueTemas[locaBloque].casoPractico.push({
          idTestCP: undefined,
          titulo: ''
        })
        await this.setState({ bloqueTemas: bloqueTemas })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'addexamenGestionPdf') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas
        await bloqueTemas[locaBloque].examenGestionPdf.push({
          idEjer: undefined,
          titulo: ''
        })
        this.setState({ bloqueTemas: bloqueTemas })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'delTestJus') {

      let locaBloque = e.target.id.split('-')[1]
      let locaTest = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas


      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].testJusticia.splice(locaTest, 1)
            this.setState({ bloqueTemas: bloqueTemas })

            // for (var k = 0; k < this.state.bloqueTemas[locaBloque].testJusticia.length; k++) {
            //   let busquedaTest = this.state.busquedaTestPre.find(t => t.id_test == this.state.bloqueTemas[locaBloque].testJusticia[k].idTestJusticia)

            //   this.refs['ref_testJusAñadir-' + locaBloque + '-' + k].refs['testJusAñadir-' + locaBloque + '-' + k].value = busquedaTest != undefined ? (busquedaTest.nombre_test) : ('')

            // }

          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });

    } else if (e.target.id.split('-')[0] == 'delCaso') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTest = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].casoPractico.splice(locaTest, 1)
            this.setState({ bloqueTemas: bloqueTemas })

            // for (var k = 0; k < this.state.bloqueTemas[locaBloque].casoPractico.length; k++) {
            //   let busquedaTest = this.state.busquedaTestPreCasoPractico.find(t => t.id_test == this.state.bloqueTemas[locaBloque].casoPractico[k].idTestCP)

            //   this.refs['ref_casoPracAñadir-' + locaBloque + '-' + k].refs['casoPracAñadir-' + locaBloque + '-' + k].value = busquedaTest != undefined ? (busquedaTest.nombre_cp) : ('')

            // }

          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });

    } else if (e.target.id.split('-')[0] == 'delexamenGestionPdf') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTest = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el ejercicio será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            await bloqueTemas[locaBloque].examenGestionPdf.splice(locaTest, 1)
            this.setState({ bloqueTemas: bloqueTemas })

          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });

    } else if (e.target.id.split('-')[0] == 'borrarTodosIntervalo') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, todos los intervalos del tema serán borrados.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = []
            await this.setState({ bloqueTemas: bloqueTemas })

          } else {
            swal("¡Intervalos de tema no borrados!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'adelantarBloque') {

      let locaBloque = parseInt(e.target.id.split('-')[1])
      let posSiguiente = locaBloque + 1
      let bloqueTemas = this.state.bloqueTemas
      let bloqueSave = bloqueTemas[locaBloque]

      if (bloqueTemas.length > posSiguiente) {
        bloqueTemas.splice(locaBloque, 1)
        bloqueTemas.splice(posSiguiente, 0, bloqueSave)
        this.setState({ bloqueTemas: bloqueTemas })
      }



    } else if (e.target.id.split('-')[0] == 'atrasarBloque') {

      let locaBloque = parseInt(e.target.id.split('-')[1])
      let posSiguiente = locaBloque - 1
      let bloqueTemas = this.state.bloqueTemas
      let bloqueSave = bloqueTemas[locaBloque]

      if (bloqueTemas.length > posSiguiente && posSiguiente >= 0) {
        bloqueTemas.splice(locaBloque, 1)
        bloqueTemas.splice(posSiguiente, 0, bloqueSave)
        this.setState({ bloqueTemas: bloqueTemas })
      }



    }

  }

  componentDidMount() {
    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/prosemanal/consultaTestPred')
      .then(res => res.json())
      .then(test => this.setState({
        busquedaTestPre: test
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
      .then(res => res.json())
      .then(meca => this.setState({
        busquedaMeca: meca
      })).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreWord')
      .then(res => res.json())
      .then(info => this.setState({ busquedaTestPreWord: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreCasoPractico')
      .then(res => res.json())
      .then(info => this.setState({ busquedaTestPreCasoPractico: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/loadTestWord')
      .then(res => res.json())
      .then(info => this.setState({ busquedaWordPDF: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
      .then(res => res.json())
      .then(info => this.setState({ loadExamanesGestion: info })
      ).catch(error => console.log('Object fecth failed', error))


    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
      .then(res => res.json())
      .then(cls => this.setState({
        categoriasJusticia: cls
      }))

    if (this.props.selectedOption == 'add_semana_prg' && this.props.contentSaveAPClaseCrear != undefined && this.props.contentSaveAPClaseCrear != null) {

      swal("Cambios sin guardar", "Cambios sin finalizar detectados, ¿Quieré cargarlos?", {
        buttons: {
          cancel: "Cerrar",
          catch: {
            text: "Cargar información",
            value: "catch",
          },
        },
      })
        .then(async (value) => {
          switch (value) {

            case "catch":
              this.setState({
                infoSemanaPrg: this.props.contentSaveAPClaseCrear.infoSemanaPrg,
                bloqueTemas: this.props.contentSaveAPClaseCrear.bloqueTemas,
                cursoBaseSelected: this.props.contentSaveAPClaseCrear.cursoBaseSelected,
                cursosDinamicos: this.props.contentSaveAPClaseCrear.cursosDinamicos,
                cursoBaseFechaMax: this.props.contentSaveAPClaseCrear.cursoBaseFechaMax,
                fechaClaseBase: this.props.contentSaveAPClaseCrear.fechaClaseBase,
                displayClases: 'block'
              })

              let oposicionObject = await this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.props.contentSaveAPClaseCrear.infoSemanaPrg.nombreOposicion)
              if (oposicionObject != undefined) {
                let data = { id_oposicion: oposicionObject.id_oposicion }
                let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                if (cls1.length != 0 || cls1 != undefined) {
                  await this.setState({ busquedaClases: cls1, displayClases: 'block' });
                } else {
                  // this.refs.ref_id_clase_selected.refs.id_clase_selected.value = '';
                  await this.setState({ displayClases: 'none', busquedaClases: undefined });
                }


                let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaTemasOposicion", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;
                await this.setState({ busquedaTemasOposicion: cls2 });

                let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/apuntesTemas", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                await this.setState({ apuntesTemas: cls3 });

              }

              let claseObject = await this.state.busquedaClases.find(clase => clase.id_clase == this.props.contentSaveAPClaseCrear.infoSemanaPrg.idClase)

              if (claseObject != undefined && oposicionObject != undefined) {
                let data = { idOpo: oposicionObject.id_oposicion, idClase: claseObject.id_clase }
                let response1 = await fetch("https://oposiciones-justicia.es/api/prosemanal/programasDeClase", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                });
                if (!response1.ok) {
                  throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;
                if (cls1.length != 0 || cls1 != undefined) {
                  // SACAR SEMANA BASE ULTIMA --> 

                  let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramaOrderFecha", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;
                  if (cls2.length > 0) {
                    let cursoBaseFechaMax = cls2.sort((a, b) => new Date(b.fecha_clase) - new Date(a.fecha_clase))[0].fecha_clase
                    this.setState({ cursoBaseFechaMax: cursoBaseFechaMax })
                  } else {
                    this.setState({ cursoBaseFechaMax: null })
                  }

                  let maximaSemana = Math.max.apply(Math, cls1.map(function (o) { return o.numero_semana; }))


                  this.setState({ programasDeClase: cls1, listaSemanasClase: cls1, maxNumSemRecomendado: maximaSemana + 1, cursoBaseSelected: claseObject.id_clase, idOpoBaseSeleted: oposicionObject.id_oposicion });

                } else {

                  this.setState({ programasDeClase: undefined, listaSemanasClase: undefined, maxNumSemRecomendado: 1, cursoBaseSelected: claseObject.id_clase, idOpoBaseSeleted: oposicionObject.id_oposicion });

                }

              } else {
                this.setState({ cursoBaseSelected: null, idOpoBaseSeleted: null, cursosDinamicos: null, cursoBaseFechaMax: null });
              }

              swal.close()
              break;

            default:
              swal.close()
          }
        });

    }



  };

  componentWillUnmount() {
    // swal('CERRAMOS', 'CERRAMOS', 'INFO')
    let { infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase } = this.state
    if (infoSemanaPrg.idOposicion != undefined) {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, true)
    } else {
      this.props.cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, null)
    }

  }
  render() {
    const { selectedOption } = this.props;
    const { infoPrograma, infoTemasPrograma, busquedaSemanaProgramacion, readSemanaProgramacion, readClaseSelecionada, statusBarraProceso, busquedaClasesSemanas, busquedaOpo, displayClaseInput, ultimaClaseFecha,
      displayClases, bloqueTemas, busquedaTemasOposicion, busquedaLeyes, apuntesTemas, busquedaClases, programasDeClase, busquedaTestPre, busquedaTestPreCasoPractico, busquedaMeca, busquedaTestPreWord, infoSemanaPrg, listaSemanasClase, semanasPrg, valuesActivo, clonarSemanas, busquedaWordPDF, loadExamanesGestion, displayClonar, semanaCargadaParaCopiar,

      cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, categoriasJusticia, navS, navS2,

      busquedaCursosBase, aplCurso, claseSelecionada

    } = this.state

    // semanasPrg && semanasPrg != undefined ? (console.log('semanasPrg') + console.log(semanasPrg)) : null
    // busquedaSemanaProgramacion && busquedaSemanaProgramacion != undefined ? (console.log('busquedaSemanaProgramacion') + console.log(busquedaSemanaProgramacion)) : null

    // aplCurso && aplCurso != undefined ? (console.log('aplCurso') + console.log(aplCurso)) : null



    // semanaCargadaParaCopiar && semanaCargadaParaCopiar != undefined ? console.log(semanaCargadaParaCopiar) : null



    // cursosDinamicos && cursosDinamicos != undefined ? (console.log('cursosDinamicos') + console.log(cursosDinamicos)) : null
    // fechaClaseBase && fechaClaseBase != undefined ? (console.log('fechaClaseBase') + console.log(fechaClaseBase)) : null


    // bloqueTemas && bloqueTemas != undefined ? (console.log('bloqueTemas') + console.log(bloqueTemas)) : null
    // infoSemanaPrg && infoSemanaPrg != undefined ? (console.log('infoSemanaPrg') + console.log(infoSemanaPrg)) : null





    switch (selectedOption) {
      case 'Aplicar_Programacion': {
        return (
          <form className="formMeca">
            <BarraProceso estadoProceso={statusBarraProceso} />
            <Input type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />
            <Input readOnly={readClaseSelecionada} type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />
            <h55> <i className="fas fa-info-circle"></i>Seleccione la primera semana<i className="fas fa-arrow-circle-down"></i></h55>
            <Input readOnly={readSemanaProgramacion} type="text" name="semanaProgramacion" placeholder="Nº Semana Programación" icon="fas fa-search" datalist="busquedaSemanaProgramacion" ref="ref_semanaProgramacion" onChange={this.onChangeInput} />
            <h55> <i className="fas fa-info-circle"></i>Fecha de primera clase<i className="fas fa-arrow-circle-down"></i></h55>
            <Input step={600} type="datetime-local" name="fechaStart" placeholder="Hora" icon="fas fa-clock" ref="ref_fechaStart" onChange={this.onChangeInput} />
            <InfoProgramacion infoPrograma={infoPrograma} infoTemasPrograma={infoTemasPrograma} />
            <Submit id="aplicar_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Aplicar Programacion a Clase" onClick={this.handleSubmitForm} />
            <div className="aclaracion">
              <div> <i className="fas fa-exclamation-triangle"></i></div>
              <div className="textAclaracion">
                Se aplicara la programación seleccionada a la clase a partir de la fecha indicada. Recuerda que para asignar una programacion primero debes clonar una programacion, de una oposición en la clase deseada. Las semanas cargadas son las clonadas.
              </div>
            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ' (' + a.nombreTema + ')' + '.') : (a.tituloTema + ' (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaClasesSemanas">{
              busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
          </form>
        );
      } break;
      case 'Add_Semana_to_Programacion': {
        return (
          <form className="formMeca">
            <BarraProceso estadoProceso={statusBarraProceso} />
            <Input type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />

            <Input readOnly={readClaseSelecionada} type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />

            <h55> <i className="fas fa-info-circle"></i>Seleccione el Programa<i className="fas fa-arrow-circle-down"></i></h55>
            <Input readOnly={readSemanaProgramacion} type="text" name="semanaProgramacion" placeholder="Nº Semana Programación" icon="fas fa-search" datalist="busquedaSemanaProgramacion" ref="ref_semanaProgramacion" onChange={this.onChangeInput} />

            <h55> <i className="fas fa-info-circle"></i>Fecha de la clase superior a {ultimaClaseFecha && moment(ultimaClaseFecha).format("YYYY-MM-DD HH:mm")}<i className="fas fa-arrow-circle-down"></i></h55>
            <Input step={600} type="datetime-local" name="fechaStart" placeholder="Hora" icon="fas fa-clock" ref="ref_fechaStart" onChange={this.onChangeInput} />



            <Submit id="aplicar_semana_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Aplicar Programacion a Clase" onClick={this.handleSubmitForm} />

            <div className="aclaracion">
              <div> <i className="fas fa-exclamation-triangle"></i></div>
              <div className="textAclaracion">
                Se aplicara la programación seleccionada a la clase despues de la ultima clase, seleccione la fecha mayor a la indicada.
              </div>
            </div>
            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ' (' + a.nombreTema + ')' + '.') : (a.tituloTema + ' (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaClasesSemanas">{
              busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
          </form>
        );
      } break;
      case 'Clonar_Programacion': {
        return (
          <div className='BodyApClaseDinamica'>


            <div className='BACD-content' style={{ marginTop: '0px' }}>
              <div className='BACD-C-row'>
                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo" value={aplCurso.opoName}
                    autoComplete={"off"} id={"opoDin"} type="text" name={"opoDin-"} ref={"opoDin"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {aplCurso && aplCurso.opoName != '' && aplCurso.opoId != '' ?
                  (
                    <div className='BACD-C-row-cursos'>

                      <div id={"claseBase"} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaCursosBase" value={aplCurso.cursoBaseName}
                          autoComplete={"off"} id={"cursoBase"} type="text" name={"cursoBase"} ref={"cursoBase"} placeholder="Curso Base" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Curso Base</p>
                      </div>
                      {
                        aplCurso.cursoBaseId && aplCurso.cursoBaseId != '' ? (
                          <React.Fragment>

                            <i className="fas fa-exchange-alt icon2f34"></i>

                            <div id={"cursoDestino"} className="celdaInputDAPFBN animacionBotonEntra">
                              <Input2
                                datalist="busquedaCursosBase" value={aplCurso.cursoDestinoName}
                                autoComplete={"off"} id={"cursoDestino"} type="text" name={"cursoDestino"} ref={"cursoDestino"} placeholder="Curso Destino" icon="fas fa-tag" onChange={this.onChangeInput} />
                              <p>Curso Destino</p>
                            </div>


                          </React.Fragment>
                        ) : null
                      }


                    </div>
                  ) : null}

                {aplCurso.cursoDestinoId && aplCurso.cursoDestinoId != '' ? (
                  <div className='BACD-C-row-modoAsig'>
                    <div className='BACD-C-row-modoAsig-title'>
                      MODO DE CLONACIÓN
                    </div>
                    <div className='BACD-C-row-navBot'>
                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'semana' ? (
                          <Boton id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        )}
                      </div>

                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'completa' ? (
                          <Boton id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        )}
                      </div>
                    </div>
                    <div className='BACD-C-row-modoAsig-title2'>
                      {(aplCurso.modo == 'completa' || aplCurso.modo == 'semana') && ultimaClaseFecha != undefined && ultimaClaseFecha != '' && ultimaClaseFecha != null ? (
                        'Ultima clase aplicada el ' + moment(ultimaClaseFecha).format("YYYY-MM-DD HH:mm")
                      ) : null
                      }
                    </div>
                    <div className='BACD-C-row-navBot'>
                      {aplCurso.modo == 'completa' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '150px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="1º Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>1º Semana</p>
                        </div>
                      ) : null
                      }

                      {aplCurso.modo == 'completa' && aplCurso.semanaSelected != null ? (
                        <div id={"fechaStart"} style={{ width: '220px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            type="datetime-local"
                            autoComplete={"off"} id={"fechaStart"} name={"fechaStart"} ref={"fechaStart"} placeholder="Fecha 1º Clase" icon="fas fa-calendar" onChange={this.onChangeInput} />
                          <p>Fecha 1º Clase</p>
                        </div>
                      ) : null
                      }

                      {aplCurso.modo == 'semana' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '150px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Semana</p>
                        </div>
                      ) : null
                      }
                      {aplCurso.modo == 'semana' && aplCurso.semanaSelected != null ? (
                        <div id={"fechaStart"} style={{ width: '220px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2 type="datetime-local"
                            autoComplete={"off"} id={"fechaStart"} name={"fechaStart"} ref={"fechaStart"} placeholder="Fecha 1º Clase" icon="fas fa-calendar" onChange={this.onChangeInput} />
                          <p>Fecha Clase</p>
                        </div>
                      ) : null
                      }


                    </div>

                    {aplCurso.fechaStart != null && aplCurso.opoId != '' && aplCurso.cursoBaseId != '' && aplCurso.cursoDestinoId != '' && aplCurso.semanaSelected != null ? (
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Boton id="clonar_programacion" icon1="" icon2="" texto="CLONAR PROGRAMACIÓN" onClick={this.handleSubmitForm} />
                      </div>

                    ) : null}

                  </div>
                ) : null

                }

              </div>
              {aplCurso && aplCurso.opoId == '' ?
                (
                  <div className='BACD-C-row-info'>

                    <div className='BACD-C-R-I-title'>
                      <b>Seleccione una oposición para continuar</b>
                      <div className="barBottomBorder"></div>
                    </div>
                    <div className='BACD-C-R-I-infoTxt'>

                    </div>
                  </div>
                ) : (
                  aplCurso && aplCurso.cursoBaseId == '' ?
                    (
                      <div className='BACD-C-row-info'>

                        <div className='BACD-C-R-I-title'>
                          <b>Seleccione el curso base para continuar</b>
                          <div className="barBottomBorder"></div>
                        </div>
                        <div className='BACD-C-R-I-infoTxt'>
                          <p>El <b>curso base</b> es el origen de la programación.</p>
                        </div>
                      </div>
                    ) : (
                      aplCurso && aplCurso.cursoDestinoId == '' ?
                        (
                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Seleccione el curso destino para continuar</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            <div className='BACD-C-R-I-infoTxt'>
                              <p>El <b>curso base</b> es el origen de la programación.</p>
                              <p>El <b>curso destino</b> es donde se clonará la programación como base para el curso/clase.</p>
                            </div>
                          </div>
                        ) : (
                          aplCurso && aplCurso.modo == '' ?
                            (
                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Seleccione el modo de clonación para continuar</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>
                                  <p>El <b>curso base</b> es el origen de la programación.</p>
                                  <p>El <b>curso destino</b> es donde se clonará la programación como base para el curso/clase.</p>
                                  <p>El <b>modo de clonación</b>, indica si clonamos 1 clase o toda la programación.</p>

                                </div>
                              </div>
                            ) : (
                              aplCurso && aplCurso.semanaSelected == null ?
                                (
                                  <div className='BACD-C-row-info'>

                                    <div className='BACD-C-R-I-title'>
                                      <b>Seleccione una semana para continuar</b>
                                      <div className="barBottomBorder"></div>
                                    </div>
                                    <div className='BACD-C-R-I-infoTxt'>
                                      <p>El <b>curso base</b> es el origen de la programación.</p>
                                      <p>El <b>curso destino</b> es donde se clonará la programación como base para el curso/clase.</p>
                                      <p>El <b>modo de clonación</b>, indica si clonamos 1 clase o toda la programación.</p>

                                      {
                                        aplCurso.modo == 'completa' ? (
                                          <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                        ) : null
                                      }
                                      {aplCurso.modo == 'semana' ? (
                                        <p>La <b>semana</b> elegida será la aplicada.</p>
                                      ) : null
                                      }
                                    </div>
                                  </div>
                                ) : (
                                  aplCurso && aplCurso.fechaStart == null ?
                                    (
                                      <div className='BACD-C-row-info'>

                                        <div className='BACD-C-R-I-title'>
                                          <b>Seleccione una fecha para continuar</b>
                                          <div className="barBottomBorder"></div>
                                        </div>
                                        <div className='BACD-C-R-I-infoTxt'>
                                          <p>El <b>curso base</b> es el origen de la programación.</p>
                                          <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                          <p>El <b>modo de clonación</b>, indica si clonamos 1 clase o toda la programación.</p>

                                          {
                                            aplCurso.modo == 'completa' ? (
                                              <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                            ) : null
                                          }
                                          {aplCurso.modo == 'semana' ? (
                                            <p>La <b>semana</b> elegida será la aplicada.</p>
                                          ) : null
                                          }
                                          <p>La <b>fecha</b> debe ser superior a la ultima aplicada, en caso de tener alguna clase aplicada el curso destino.</p>

                                        </div>
                                      </div>
                                    ) : (

                                      <div className='BACD-C-row-info'>

                                        <div className='BACD-C-R-I-title'>
                                          <b>Todo listo para clonar programacion dinámica</b>
                                          <div className="barBottomBorder"></div>
                                        </div>
                                        <div className='BACD-C-R-I-infoTxt'>
                                          <p>El <b>curso base</b> es el origen de la programación.</p>
                                          <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                          <p>El <b>modo de clonación</b>, indica si clonamos 1 clase o toda la programación.</p>

                                          {
                                            aplCurso.modo == 'completa' ? (
                                              <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                            ) : null
                                          }
                                          {aplCurso.modo == 'semana' ? (
                                            <p>La <b>semana</b> elegida será la aplicada.</p>
                                          ) : null
                                          }
                                          <p>La <b>fecha</b> debe ser superior a la ultima aplicada, en caso de tener alguna clase aplicada el curso destino.</p>

                                        </div>
                                      </div>

                                    )
                                )
                            )
                        )
                    )
                )}


            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, {cls.nombre_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}

                </option>
              )
            }</datalist>

            <datalist id="busquedaCursosBase">{
              busquedaCursosBase && busquedaCursosBase.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>

          </div>
        );
        // return (
        //   <form className="formMeca">
        //     <BarraProceso estadoProceso={statusBarraProceso} />
        //     <h55><i className="fas fa-info-circle"></i>¿Que clonamos?<i className="fas fa-arrow-circle-down"></i></h55>

        //     <InputSelect values={valuesActivo} name="clonarSemanas" ref="ref_clonarSemanas" onChange={this.onChangeInput} />

        //     <Input2 type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />


        //     <Input2 readOnly={readClaseSelecionada} type="text" name="claseSelecionada" placeholder="Clase Origen" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />

        //     <datalist id="busquedaClasesSemanas">{
        //       busquedaClases && busquedaClases.map((clase, key) =>
        //         <option key={key} value={clase.id_clase}>{clase.dia_semanal}</option>
        //       )
        //     }</datalist>

        //     {clonarSemanas && clonarSemanas == 1 && displayClonar && displayClonar == true ? (
        //       <Input2 readOnly={readClaseSelecionada} type="text" name="semanaSelected" placeholder="Semana de clase" icon="fas fa-search" datalist="semanasPrg23" ref="ref_semanaSelected" onChange={this.onChangeInput} />
        //     ) : null}

        //     <datalist id="semanasPrg23">{
        //       semanasPrg && semanasPrg != undefined && semanasPrg.map((cls, key) =>
        //         <option key={key} value={'Nº Semana - ' + cls.numeroSemana}> Nº Semana {cls.numeroSemana} </option>
        //       )
        //     }</datalist>

        //     {displayClonar && displayClonar == true ? (
        //       <Input2 type="text" name="claseAsignarNuevaPrg" display={displayClases} placeholder="Clase Destino" icon="fas fa-search" datalist="busquedaClases" ref="ref_claseAsignarNuevaPrg" onChange={this.onChangeInput} />
        //     ) : null
        //     }

        //     <datalist id="busquedaClases">{
        //       busquedaClases && busquedaClases.map((clase, key) =>
        //         <option key={key} value={clase.id_clase}>{clase.dia_semanal}</option>
        //       )
        //     }</datalist>

        //     {displayClonar && displayClonar == true ? (
        //       <Submit id="clonar_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Clonar Programacion a Clase" onClick={this.handleSubmitForm} />
        //     ) : null}

        //     <div className="aclaracion">
        //       <div> <i className="fas fa-exclamation-triangle"></i></div>
        //       <div className="textAclaracion">
        //         Se clonara la programación de la oposicion seleccionada y se asignara a la clase seleccionada. Para iniciar las clases debes aplicar la programacion despues de clonarla.
        //       </div>
        //     </div>

        //     <datalist id="busquedaOpo">{
        //       busquedaOpo && busquedaOpo.map((opo, key) =>
        //         <option key={key} value={opo.nombre}></option>
        //       )
        //     }</datalist>
        //   </form>
        // );
      } break;
      case 'Posponer_Clase': {
        return (
          <form className="formMeca">
            <BarraProceso estadoProceso={statusBarraProceso} />
            <h44> <i className="fas fa-info-circle"></i>Nº de Semanas a Posponer<i className="fas fa-arrow-circle-down"></i></h44>
            <Input type="number" min="0" name="semanasAdelantar" placeholder="Nº de Semanas: 1,2,3..." title="Indicar el numero de semanas a adelantar" icon="fas fa-pen-alt" ref="ref_semanasAdelantar" onChange={this.onChangeInput} />
            <h44><i className="fas fa-info-circle"></i>Aplicar Desde<i className="fas fa-arrow-circle-down"></i></h44>
            <Input step={600} type="datetime-local" name="fechaPosponer1" icon="fas fa-clock" ref="ref_fechaPosponer1" title="Eliga el día desde el que aplicar el adelanto." onChange={this.onChangeInput} />
            <h55><i className="fas fa-info-circle"></i>Se adelantarán {this.refs.ref_semanasAdelantar && this.refs.ref_semanasAdelantar.refs.semanasAdelantar.value} semanas a todas las clases. Desde {this.refs.ref_fechaPosponer1 && moment(this.refs.ref_fechaPosponer1.refs.fechaPosponer1.value).format("DD-MM-YY")}<i className="fas fa-info-circle"></i></h55>
            <Submit id="adelantar_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Posponer Clases" onClick={this.handleSubmitForm} />
          </form>
        );
      } break;
      case 'Atrasar_Clases': {
        return (
          <form className="formMeca">
            <BarraProceso estadoProceso={statusBarraProceso} />
            <h44> <i className="fas fa-info-circle"></i>Nº de Semanas para atrasar<i className="fas fa-arrow-circle-down"></i></h44>
            <Input type="number" min="0" name="semanasAtrasar" placeholder="Nº de Semanas: 1,2,3..." title="Indicar el numero de semanas a atrasar" icon="fas fa-pen-alt" ref="ref_semanasAtrasar" onChange={this.onChangeInput} />
            <h44> <i className="fas fa-info-circle"></i>¿Desde donde atrasamos?<i className="fas fa-arrow-circle-down"></i></h44>
            <Input step={600} type="datetime-local" name="fechaAtrasar" icon="fas fa-clock" ref="ref_fechaAtrasar" title="La fecha que se necesita para atrasar la fecha apartir de esta" onChange={this.onChangeInput} />
            <h55><i className="fas fa-info-circle"></i><div>Se atrasarán {this.refs.ref_semanasAtrasar && this.refs.ref_semanasAtrasar.refs.semanasAtrasar.value} semanas todas las clases con fecha mayor o igual a {this.refs.ref_fechaAtrasar && moment(this.refs.ref_fechaAtrasar.refs.fechaAtrasar.value).format("DD-MM-YY")}</div><i className="fas fa-info-circle"></i></h55>
            <Submit id="atrasar_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Atrasar Clases" onClick={this.handleSubmitForm} />
          </form>
        );
      } break;
      case 'Eliminar_Programacion': {
        return (

          <div className='BodyApClaseDinamica'>

            <div className='BACD-content' style={{ marginTop: '0px',boxShadow: 'unset' }}>

              <div className='BACD-C-row'>


                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo"
                    autoComplete={"off"} id={"oposicionSelecionada"} type="text" name={"oposicionSelecionada"} ref={"ref_oposicionSelecionada"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {
                  displayClaseInput != 'none' ? (
                    <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                      <Input2
                        datalist="busquedaClasesSemanas"
                        autoComplete={"off"} id={"claseSelecionada"} type="text" name={"claseSelecionada"} ref={"ref_claseSelecionada"} placeholder="Buscar Clase" icon="fas fa-tag" onChange={this.onChangeInput} />
                      <p>Clase/Curso</p>
                    </div>
                  ) : null
                }
                {
                  claseSelecionada != undefined && claseSelecionada != '' && busquedaClasesSemanas.find(prg => prg.dia_semanal == claseSelecionada) != undefined ? (
                    <div className='ColCenterStart' style={{ marginTop: '15px' }}>
                      <Boton id="eliminar_programacion" icon1="" icon2="" texto="Eliminar Programación" onClick={this.handleSubmitForm} />
                    </div>
                  ) : null
                }



              </div>

            </div>

            {/* <form className="formMeca">
              <BarraProceso estadoProceso={statusBarraProceso} />
              <Input type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo" ref="ref_oposicionSelecionada" onChange={this.onChangeInput} />
              <Input display={displayClaseInput} type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas" ref="ref_claseSelecionada" onChange={this.onChangeInput} />
              <Submit id="eliminar_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Eliminar Programacion" onClick={this.handleSubmitForm} />
            </form> */}


            <datalist id="busquedaClasesSemanas">{
              busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
          </div>


        );
      } break;
      case 'add_semana_prg': {
        return (
          <div className="ColCenterStart">
            <h3 style={{ color: '#3469b7', marginBottom: '25px' }}> Configuración de semana y curso base </h3>

            <div className='celdaInputDAPFBN animacionBotonEntra'>
              <Input2 value={infoSemanaPrg.tituloOpo} datalist='busquedaOpo' autoComplete={'off'} id={'nombre_opo_añadir'} type='text' name={'nombre_opo_añadir'} ref={'nombre_opo_añadir'} placeholder='Oposición' icon='fa-light fa-money-check-dollar-pen' onChange={this.onChangeInputNew} />
              <p>Oposición</p>
            </div>

            {
              displayClases != 'none' ? (
                <div className='ColCenterStart'>
                  {cursoBaseSelected != null ? (
                    <div className='RowCenterCenter' style={{ justifyContent: 'space-between', width: '100%', padding: '8px 15px' }}>
                      <div style={{ padding: '10px', color: '#3469b7' }}>
                        {cursoBaseSelected != null && busquedaClases && busquedaClases.length > 0 && busquedaClases.find(c => c.id_clase == cursoBaseSelected) != undefined ? (
                          busquedaClases.find(c => c.id_clase == cursoBaseSelected).dia_semanal
                        ) : 'Clase/Curso no valido'}
                      </div>

                      <div style={{ padding: '10px', color: '#3469b7' }} title="Fecha de la ultima clase aplicada">
                        {cursoBaseFechaMax != null ? (moment(cursoBaseFechaMax).format('DD-MM-YYYY HH:mm')) : ('No tiene fecha previa')}
                      </div>
                    </div>
                  ) : null}

                  <div className='RowCenterCenter'>
                    <div className='celdaInputDAPFBN animacionBotonEntra'>
                      <Input2 value={infoSemanaPrg.idClase} datalist='busquedaClases' autoComplete={'off'} id={'id_clase_selected'} type='text' name={'id_clase_selected'} ref={'id_clase_selected'} placeholder='Id Curso Base' icon='fa-light fa-money-check-dollar-pen' onChange={this.onChangeInputNew} />
                      <p>Id Curso Base</p>
                    </div>
                    <div className='celdaInputDAPFBN animacionBotonEntra'>
                      <Input2 value={infoSemanaPrg.numeroSemana} type="text" datalist="listaSemanasClase" name="num_semana_añadir" placeholder="Numero Semana" icon="fas fa-sort-numeric-up" ref="ref_num_semana_añadir" onChange={this.onChangeInputNew} />
                      <p>Nº de Clase/Semana</p>
                    </div>

                    <div className="celdaInputDAPFBN animacionBotonEntra" style={{ width: '225px' }}>
                      <Input2 value={fechaClaseBase} type="datetime-local"
                        autoComplete={"off"} id={"fechaClaseBase"} name={"fechaClaseBase"} ref={"fechaClaseBase"} placeholder="Fecha" icon="fas fa-calendar" onChange={this.onChangeInputNew2} />
                      <p>Fecha Clase</p>
                    </div>
                  </div>

                  <div className='RowCenterCenter'>

                    <div style={{ width: '135px' }} className="celdaInputDAPFBN animacionBotonEntra">
                      <Input2 value={infoSemanaPrg.idCategoria != null ? (infoSemanaPrg.idCategoria) : ('')}
                        datalist="categoriasJusticia" autoComplete={"off"} id={"categoriaSemPrg"} type="text" name={"categoriaSemPrg"} ref={"categoriaSemPrg"} placeholder="Categoría Semana" icon="fas fa-tag" onChange={this.onChangeInputNew2} />
                      <p>Categoría</p>

                      {infoSemanaPrg.idCategoria != null && categoriasJusticia.find(c => c.id_categoria == infoSemanaPrg.idCategoria) != undefined ? (
                        <div className='pnavBotbacdcrow'>
                          {isDefined(infoSemanaPrg.idCategoria) && categoriasJusticia.find(c => c.id_categoria == infoSemanaPrg.idCategoria).nombre}
                        </div>
                      ) : (
                        <div className='pnavBotbacdcrow'>
                          Indique una Categoría
                        </div>
                      )}

                    </div>

                    <div className='celdaInputDAPFBN animacionBotonEntra' style={{ width: '300px' }}>
                      <Input2
                        value={infoSemanaPrg.nombre_semana != null ? (infoSemanaPrg.nombre_semana) : ('')}
                        autoComplete={"off"} id={"nameSemPrg"} type="text" name={"nameSemPrg"} ref={"nameSemPrg"} placeholder="Nombre Semana" icon="fas fa-tag" onChange={this.onChangeInputNew2} />
                      <p>Nombre</p>
                      {infoSemanaPrg.nombre_semana != null ? (
                        <div className='pnavBotbacdcrow'>
                          {infoSemanaPrg.nombre_semana}
                        </div>
                      ) : (
                        <div className='pnavBotbacdcrow'>
                          Indique el nombre de semana
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              ) : null
            }

            {
              cursoBaseSelected != null ? (
                <div className='ColCenterStart' style={{ marginTop: '25px' }}>

                  <Boton id='addClaseDin' onClick={this.onHandleClick} icon1='' icon2='' texto='Añadir en Curso/Clase dinámica' />

                  <p style={{ marginTop: '10px', marginBottom: '15px', maxWidth: '460px' }}>
                    Puede agregar las clases/cursos compatibles, para asignar la programación de forma dinamíca, deberá indicar la fecha para cada clase/curso.
                  </p>

                  {cursosDinamicos != null && cursosDinamicos.length > 0 && cursosDinamicos.map((curso, key) =>

                    <div className='ColCenterStart'>
                      {curso.idClase != null ? (
                        <div className='RowCenterCenter' style={{ justifyContent: 'space-between', width: '100%', padding: '8px 15px' }}>
                          <div style={{ padding: '10px', color: '#3469b7' }}>

                            {cursosDinamicos != null && cursosDinamicos.length > 0 && curso.idClase != null && busquedaClases && busquedaClases.length > 0 && busquedaClases.find(c => c.id_clase == curso.idClase) != undefined ? (
                              busquedaClases.find(c => c.id_clase == curso.idClase).dia_semanal
                            ) : 'Clase/Curso no valido'}

                          </div>

                          <div style={{ padding: '10px', color: '#3469b7' }} title="Fecha de la ultima clase aplicada">

                            {cursosDinamicos != null && curso.idClase != null && curso.cursoBaseFechaMax != null ? (moment(curso.cursoBaseFechaMax).format('DD-MM-YYYY HH:mm')) : ('No tiene fecha previa')}

                          </div>
                        </div>
                      ) : null}

                      <div className='RowCenterCenter'>
                        <div className='celdaInputDAPFBN animacionBotonEntra'>
                          <Input2 value={curso.idClase} datalist='busquedaClases' autoComplete={'off'} id={'id_clase_selected_din-' + key} type='text' name={'id_clase_selected_din-' + key} ref={'id_clase_selected_din-' + key} placeholder='Id Curso Base' icon='fa-light fa-money-check-dollar-pen' onChange={this.onChangeInputNew2} />
                          <p>Id Curso Dinámico</p>
                        </div>
                        {curso.idClase != null && curso.idClase != '' ? (
                          <div className="celdaInputDAPFBN animacionBotonEntra" style={{ width: '225px' }}>
                            <Input2 value={curso.fechaDin} type="datetime-local"
                              autoComplete={"off"} id={"fechaClaseDin-" + key} name={"fechaClaseDin-" + key} ref={"fechaClaseDin-" + key} placeholder="Fecha" icon="fas fa-calendar" onChange={this.onChangeInputNew2} />
                            <p>Fecha Clase</p>
                          </div>
                        ) : null}

                        <i title="Eliminar clase dinámica." id={"deleteClaseDin-" + key} onClick={this.onHandleClick} className="fa-duotone fa-trash botonDel"></i>
                      </div>

                    </div>

                  )}


                </div>
              ) : null
            }



            {
              displayClases != 'none' && infoSemanaPrg.idCategoria != null ? (

                <div className="ColCenterStart" style={{ margin: '50px 0px' }}>

                  <h3 style={{ color: '#3469b7', marginBottom: '25px' }}> Bloques de Estudio por Temas </h3>

                  <Boton id='add_bloque_temas' onClick={this.handleSubmitFormNew} icon1='' icon2='' texto='Añadir bloque estudio' />


                  <div className="bloqueTemas">
                    {bloqueTemas && bloqueTemas.length > 0 && bloqueTemas.map((bloque, key) =>
                      <div className="bloqueTema" key={key}>

                        <h3 style={{ color: '#3469b7', marginBottom: '15px', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'center' }}>

                          {key > 0 ? (<i id={"atrasarBloque-" + key} onClick={this.handleSubmitFormNew} className="fa-light fa-angle-left botonDel" style={{ marginRight: '10px' }} ></i>) : null}

                          Bloque Nº {key + 1}

                          {(key + 1) < bloqueTemas.length ? (<i id={"adelantarBloque-" + key} onClick={this.handleSubmitFormNew} className="fa-light fa-angle-right botonDel" style={{ marginLeft: '10px' }}></i>) : null}
                        </h3>

                        <div className="zonaTestBT">
                          <Boton id={"delBloqueTema-" + key} icon1="" icon2="" texto="Borrar Bloque" onClick={this.handleSubmitFormNew} />

                          <div className='celdaInputDAPFBN animacionBotonEntra' style={{ marginTop: '40px' }}>
                            <Input2 value={bloque.tipoBloqueTitulo} datalist='tipoBloque' autoComplete={'off'} id={"tipoBloque-" + key} type='text' name={"tipoBloque-" + key} ref={"ref_tipoBloque-" + key} placeholder='Tipo Bloque' icon='fas fa-info-circle' onChange={this.onChangeInputNew} />
                            <p>Tipo de bloque</p>
                          </div>

                          <div className='ColCenterStart adb-nav'>
                            <div className='RowCenterCenter' style={{ margin: '10px' }}>
                              <div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-0' onClick={this.onHandleClick}>
                                Temas
                              </div>
                              <div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-1' onClick={this.onHandleClick}>
                                Ejercicios
                              </div>
                            </div>
                          </div>

                        </div>

                        {navS == 1 ? (
                          <div className='ColCenterStart ejerciciosSemCreate'>
                            <div className='ColCenterStart adb-nav'>
                              <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                <div className={navS2 == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-0' onClick={this.onHandleClick}>
                                  Test Teórico
                                </div>
                                <div className={navS2 == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-1' onClick={this.onHandleClick}>
                                  Test Práctico
                                </div>
                                <div className={navS2 == 2 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-2' onClick={this.onHandleClick}>
                                  Examen Desarrollo
                                </div>
                              </div>
                            </div>

                            {navS2 == 0 ? (

                              <div className='ColCenterStart'>
                                <Boton id={"addTestJusticia-" + key} icon1="" icon2="" texto="Añadir Test Teórico" onClick={this.handleSubmitFormNew} />

                                {bloque && bloque.testJusticia && bloque.testJusticia.length > 0 && bloque.testJusticia.map((test, key22) =>
                                  <div className="bloqTestJus" style={{ marginTop: '20px' }} key={key22}>

                                    <div className='celdaInputDAPFBN animacionBotonEntra'>
                                      <Input2 value={test.titulo} type="text" id={"testJusAñadir-" + key + "-" + key22} name={"testJusAñadir-" + key + "-" + key22} placeholder="Test Justicia" icon="fas fa-search" datalist="busquedaTestPre" ref={"ref_testJusAñadir-" + key + "-" + key22} onChange={this.onChangeInputNew} />

                                      <p>Test Teoría</p>
                                    </div>
                                    <i title="Eliminar Test" id={'delTestJus-' + key + '-' + key22} onClick={this.handleSubmitFormNew} className="fa-duotone fa-trash botonDel"></i>

                                  </div>
                                )}


                              </div>

                            ) : null}

                            {navS2 == 1 ? (
                              <div className='ColCenterStart'>
                                <Boton id={"addCasoPractico-" + key} icon1="" icon2="" texto="Añadir Caso Práctico" onClick={this.handleSubmitFormNew} />

                                {bloque && bloque.casoPractico && bloque.casoPractico.length > 0 && bloque.casoPractico.map((test, key223) =>
                                  <div className="bloqTestJus" style={{ marginTop: '20px' }} key={key223}>


                                    <div className='celdaInputDAPFBN animacionBotonEntra'>
                                      <Input2 value={test.titulo} type="text" id={"casoPracAñadir-" + key + "-" + key223} name={"casoPracAñadir-" + key + "-" + key223} placeholder="Caso Práctico" icon="fas fa-search" datalist="busquedaTestPreCasoPractico" ref={"ref_casoPracAñadir-" + key + "-" + key223} onChange={this.onChangeInputNew} />
                                      <p>Test Práctico</p>
                                    </div>
                                    <i title="Eliminar " id={'delCaso-' + key + '-' + key223} onClick={this.handleSubmitFormNew} className="fa-duotone fa-trash botonDel"></i>

                                  </div>
                                )}
                              </div>
                            ) : null}

                            {navS2 == 2 ? (
                              // <div>
                              //   <Input type="text" name={"examenGestionPdf-" + key} placeholder="Examen Gestión" icon="fas fa-search" datalist="busquedaExamenGestion" ref={"ref_examenGestionPdf-" + key} onChange={this.onChangeInputNew} />
                              // </div>

                              <div className='ColCenterStart'>
                                <Boton id={"addexamenGestionPdf-" + key} icon1="" icon2="" texto="Añadir Examen" onClick={this.handleSubmitFormNew} />

                                {bloque && bloque.examenGestionPdf && bloque.examenGestionPdf.length > 0 && bloque.examenGestionPdf.map((test, key223) =>
                                  <div className="bloqTestJus" style={{ marginTop: '20px' }} key={key223}>

                                    <div className='celdaInputDAPFBN animacionBotonEntra'>
                                      <Input2 value={test.titulo} type="text" name={"examenGestionPdfAñadir-" + key + "-" + key223} placeholder="Examen Desarrollo" icon="fas fa-search" datalist="busquedaExamenGestion" ref={"ref_examenGestionPdfAñadir-" + key + "-" + key223} onChange={this.onChangeInputNew} />
                                      <p>Examen Desarrollo</p>
                                    </div>
                                    <i title="Eliminar " id={'delexamenGestionPdf-' + key + '-' + key223} onClick={this.handleSubmitFormNew} className="fa-duotone fa-trash botonDel"></i>



                                  </div>
                                )}
                              </div>
                            ) : null}





                          </div>
                        ) : null}

                        {navS == 0 ? (
                          <div className="zonaTemas">
                            <Boton id={"add_tema_bloque-" + key} icon1="" icon2="" texto="Añadir tema" onClick={this.handleSubmitFormNew} />
                            {bloque && bloque.temas.length > 0 && bloque.temas.map((tema, key2) =>
                              <div className="temaBody" key={key2}>


                                <div className="tituloTema">
                                  {tema && tema.tituloTema != undefined && tema.idTema != undefined ? (tema.tituloTema) : ('Eliga un tema')}

                                  <i title="Eliminar tema" style={{ marginLeft: '15px' }} id={"borrarTema-" + key + "-" + key2} onClick={this.handleSubmitFormNew} className="fa-duotone fa-trash botonDel"></i>
                                </div>


                                <div className='celdaInputDAPFBN animacionBotonEntra'>
                                  <Input2 value={tema.tituloTema} type="text" autoComplete="transaction-amount" name={"temaName-" + key + "-" + key2} placeholder="Tema" icon="fas fa-search" datalist="busquedaTemasOposicion" ref={"ref_temaName-" + key + "-" + key2} onChange={this.onChangeInputNew} />
                                  <p>Tema</p>
                                </div>
                                <datalist id='busquedaTemasOposicion'>{
                                  busquedaTemasOposicion && busquedaTemasOposicion.map((tema, key100) =>
                                    <option key={key100} value={tema.tituloTema}>{'Id: ' + tema.idTema + ' (' + tema.nombreTema + ')'}</option>
                                  )
                                }</datalist>


                                {tema && tema.idTema != undefined ? (
                                  <TemaSeleted idTema={tema.idTema} />
                                ) : null}


                              </div>
                            )}
                          </div>
                        ) : null}


                      </div>
                    )}
                  </div>
                </div>





              ) : null
            }



            {bloqueTemas && bloqueTemas.length > 0 ? (
              <Boton id="add_semana" icon1="" icon2="" texto="Añadir Semana" onClick={this.onHandleClick} />
            ) : null}

            <datalist id="tipoBloque">
              <option value="Normal"> </option>
              <option value="Repaso"> </option>
            </datalist>
            <datalist id="categoriasJusticia">{
              categoriasJusticia && categoriasJusticia.map((opo, key) =>
                <option key={key} value={opo.id_categoria}>
                  {opo.nombre}
                </option>
              )
            }</datalist>

            <datalist id="busquedaWordPDF">{
              busquedaWordPDF && busquedaWordPDF.map((test, key) =>
                <option key={key} value={test.id + '-' + test.nombre}></option>
              )
            }</datalist>
            <datalist id="busquedaExamenGestion">{
              loadExamanesGestion && loadExamanesGestion.map((test, key) =>
                <option key={key} value={test.nombre}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((oposicion, key) =>
                <option key={key} value={oposicion.nombre}></option>
              )
            }</datalist>
            <datalist id="busquedaClases">{
              busquedaClases && busquedaClases.map((clase, key) =>
                <option key={key} value={clase.id_clase}>{clase.dia_semanal}</option>
              )
            }</datalist>
            <datalist id="listaSemanasClase">{
              listaSemanasClase && listaSemanasClase.map((semana, key) =>
                <option key={key} value={semana.numero_semana}></option>
              )
            }</datalist>

            <datalist id="busquedaTestPreWord">{
              busquedaTestPreWord && busquedaTestPreWord.map((test, key) =>
                <option key={key} value={test.nombre_test}></option>
              )
            }</datalist>

            {/* <datalist id="busquedaTestPre">{
              busquedaTestPre && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPre.filter(t => t.id_oposicion == infoSemanaPrg.idOposicion).map((test, key) =>
                <option key={key} value={test.nombre_test}></option>
              )
            }</datalist>
            <datalist id="busquedaTestPreCasoPractico">{
              busquedaTestPreCasoPractico && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPreCasoPractico.filter(t => t.id_oposicion == infoSemanaPrg.idOposicion).map((test, key) =>
                <option key={key} value={test.nombre_cp}></option>
              )
            }</datalist> */}
            <datalist id="busquedaTestPre">{
              busquedaTestPre && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPre.filter(t => t.id_oposicion == 4).map((test, key) =>
                <option key={key} value={test.nombre_test}></option>
              )
            }</datalist>
            <datalist id="busquedaTestPreCasoPractico">{
              busquedaTestPreCasoPractico && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPreCasoPractico.filter(t => t.id_oposicion == 4).map((test, key) =>
                <option key={key} value={test.nombre_cp}></option>
              )
            }</datalist>

            <datalist id="busquedaMeca">{
              busquedaMeca && busquedaMeca.map((meca, key) =>
                <option key={key} value={meca.titulo_ejercicio}></option>
              )
            }</datalist>
          </div>
        );
      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}


// Estados del store
function mapStateToProps(state) {
  return {
    contentSaveAPClaseCrear: state.user.contentSaveAPClaseCrear
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cargarContenidoAPClaseCrear
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionBar);


// utils
import { isDefined } from '../../../../../lib/utils/is';

export function separarPreguntasTestJusticia (textArea, state) {



	if(state.testCompleto[0].oposicion != undefined && state.testCompleto[0].testPredefinido != undefined && textArea.length>0 ){
		swal('Preparando Test','','info')

	   let cadena = textArea;
	   let er1Separador = /\s?[0-9][0-9]?[0-9]?\.-/g
	   let arraySeparado = cadena.split(er1Separador).filter(p => p !== "");
	   for (var i = 0; i < arraySeparado.length; i++) {
		   arraySeparado[i] = arraySeparado[i].trim();
	   }

	let preguntasRespuestasClasificado = state.testCompleto
	preguntasRespuestasClasificado[0].preguntas = [];
	let eRResA = /a\)/
	let eRResB = /b\)/
	let eRResC = /c\)/
	let eRResD = /d\)/
	let eRArticulo = /Art\./
	// Tipos de respueta / preguntas -->
	let eRResMultiple = /Son correctas:/
	let eRResMultipleIncorrecta= /Son incorrectas:/
	let eRY = /y/
	let eRO = /o/
	let eRRUV = /Es correcta la/
	let eRRUVIncorrecta = /Es incorrecta la/

	let eRSubrayadoIn = /\s?\[\./
	let eRSubrayadoFin = /\s?\.\]\.?/
	let eRNota = /\(\-/
	let eRNotaFin = /\-\)\.?/
	var siglasLeyMal = '';
	try {
	   for (var i = 0; i < arraySeparado.length; i++) {
		   var numPreguntaError = parseInt(i +1);
		   let r1P = eRResA.exec(arraySeparado[i])
		   let r2P = eRResB.exec(arraySeparado[i])
		   let r3P = eRResC.exec(arraySeparado[i])
		   let r4P = eRResD.exec(arraySeparado[i])





			 if(arraySeparado[i].split('a)').length == 1){
				 throw new Error('No se encontro la respuesta a');
			 } else if (arraySeparado[i].split('a)').length > 2) {
				 throw new Error('Se encontraron 2 respuesta a');
			 }
			 if(arraySeparado[i].split('b)').length == 1){
				 throw new Error('No se encontro la respuesta b');
			 } else if (arraySeparado[i].split('b)').length > 2) {
				 throw new Error('Se encontraron 2 respuesta b');
			 }
			 if(arraySeparado[i].split('c)').length == 1){
				 throw new Error('No se encontro la respuesta c');
			 } else if (arraySeparado[i].split('c)').length > 2) {
				 throw new Error('Se encontraron 2 respuesta c');
			 }
			 if(arraySeparado[i].split('d)').length == 1){
				 throw new Error('No se encontro la respuesta d');
			 } else if (arraySeparado[i].split('d)').length > 2) {
				 throw new Error('Se encontraron 2 respuesta d');
			 }

			 if(arraySeparado[i].split('Art.').length == 1){
				 throw new Error('No se encontro Articulo');
			 } else if (arraySeparado[i].split('Art.').length > 2) {
				 throw new Error('Articulo error doble');
			 }
		   let pregunta = arraySeparado[i].substring(0 ,r1P.index).trim()
			 if(pregunta.split('"') != undefined && pregunta.split('"').length > 1){
				 throw new Error('Error uso de comillas dobles Preg');
			 }
		   let r1 =  arraySeparado[i].substring(r1P.index + r1P[0].length ,r2P.index).trim()
			 if(r1.split('"') != undefined && r1.split('"').length > 1){
				 throw new Error('Error uso de comillas dobles');
			 }
		   let r2 =  arraySeparado[i].substring(r2P.index + r2P[0].length ,r3P.index).trim()
			 if(r2.split('"') != undefined && r2.split('"').length > 1){
				 throw new Error('Error uso de comillas dobles');
			 }
		   let r3 =  arraySeparado[i].substring(r3P.index + r3P[0].length ,r4P.index).trim()
			 if(r3.split('"') != undefined && r3.split('"').length > 1){
				 throw new Error('Error uso de comillas dobles');
			 }
		   let r4 =  arraySeparado[i].substring(r4P.index + r4P[0].length).trim()
			 if(r4.split('"') != undefined && r4.split('"').length > 1){
				 throw new Error('Error uso de comillas dobles');
			 }

		   let articulo, ley;
		   let tipoPregunta = 1;
		   let r1V = 0;
		   let r2V = 0;
		   let r3V = 0;
		   let r4V = 0;
		   let notaA = ''
		   let notaB = ''
		   let notaC = ''
		   let notaD = ''
		   let subrayadoA = ''
		   let subrayadoB = ''
		   let subrayadoC = ''
		   let subrayadoD = ''
		   // TIPO DE PREGUNTA 1, 2, 4, 5 --> Simple y Multiple correcta
		   // Aqui analizamos las correctas y generamos el tipo de pregunta/respuesta.
		   if(eRArticulo.exec(r1) != null){
			   if (eRResMultiple.exec(r1) != null || eRResMultipleIncorrecta.exec(r1) != null) {
				   let pArticulo = eRArticulo.exec(r1);
				   let notaAP = eRNota.exec(r1)
				   let notaAPF = eRNotaFin.exec(r1)
				   let subrayadoInicio = eRSubrayadoIn.exec(r1)
				   let subrayadoFin = eRSubrayadoFin.exec(r1)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r1, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					 }



				   if(notaAP != null){
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]
				   if(notaAP != null){
					    notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				   if(subrayadoInicio != null){
					   subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				   }

				   r1 =  r1.substring(0 ,pArticulo.index).trim()
					 let resMult
					 if(eRResMultiple.exec(r1) != null){
						 resMult = eRResMultiple.exec(r1);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r1);
					 }
				   if(eRY.exec(r1) != null){
					   // Es MC
					   r1V = 1;

					   let r1Valida = r1.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[0].trim()
					   let r2Valida = r1.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
   					    }

					   tipoPregunta = 2;
				   } else if (eRO.exec(r1) != null){
					   // Es MUV
					   r1V = 20;
					   let r1Valida = r1.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[0].trim()
					   let r2Valida = r1.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
   					    }
					   tipoPregunta = 5;
				   }
					 //let r1Contenido = r1.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[1].split('.')[1].trim()

					 let r1Contenido = r1.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r1Contenido == undefined) { r1Contenido = ''}
					 if(eRResMultiple.exec(r1) != null){
						 r1 =  'Son correctas: . '+r1Contenido;
					 } else {
						 r1 =  'Son incorrectas: . '+r1Contenido;
					 }


			   }else if(eRRUV.exec(r1) != null || eRRUVIncorrecta.exec(r1) != null){
			// Es correcta una RUV
					let pArticulo = eRArticulo.exec(r1);
					let notaAP = eRNota.exec(r1)
					let notaAPF = eRNotaFin.exec(r1)
					let subrayadoInicio = eRSubrayadoIn.exec(r1)
					let subrayadoFin = eRSubrayadoFin.exec(r1)
					if(notaAP != null && notaAPF == null){
						throw new Error('Error en formulacion de nota r1, el final esta mal');
					}

					if(notaAP == null && notaAPF != null){
						throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					}

					if(subrayadoInicio != null && subrayadoFin == null){
						throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					}

					if(subrayadoInicio == null && subrayadoFin != null){
						throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					}
					if(notaAP != null){
						articulo = r1.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
					} else if (subrayadoInicio != null){
						articulo = r1.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
					} else {
						articulo = r1.substring(pArticulo.index + pArticulo[0].length).trim()
					}

					ley= articulo.split(' ')[1].split('.')[0];
					articulo = articulo.split(' ')[0]

					if(notaAP != null){
						notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					}
				    if(subrayadoInicio != null){
					    subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				    }
					r1 =  r1.substring(0 ,pArticulo.index).trim()
					let resRuv = eRRUV.exec(r1);
					if(eRRUV.exec(r1) != null){
						resRuv = eRRUV.exec(r1);
					} else {
						resRuv = eRRUVIncorrecta.exec(r1);
					}

					let ruvValida = r1.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
					if(ruvValida.length != 1){
						ruvValida = r1.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
					}
					// clasificar si es ruv 1 o ruv 2
					if( r1V != 9 && r1V != 8 && r1V != 10 && r1V != 11 &&
						r2V != 9 && r2V != 8 && r2V != 10 && r2V != 11 &&
						r3V != 9 && r3V != 8 && r3V != 10 && r3V != 11 &&
						r4V != 9 && r4V != 8 && r4V != 10 && r4V != 11 ){

						// Es RUV1
						r1V = 6;

					    if(ruvValida == 'a'){
						 if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
					    } else if (ruvValida == 'b'){
						   if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
					   } else if(ruvValida == 'c'){
						   if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
					   } else if(ruvValida == 'd'){
						   if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
					   }


				    } else {
					    // aqui ruv2
					    r1V = 12;

					   if(ruvValida == 'a'){
						if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 }else { r1V = 14 }
					   } else if (ruvValida == 'b'){
						  if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 }else { r2V = 14 }
					  } else if(ruvValida == 'c'){
						  if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 }else { r3V = 14 }
					  } else if(ruvValida == 'd'){
						  if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 }else { r4V = 14 }
					  }

				    }
				   tipoPregunta = 4;
				   let r1Contenido = r1.substring(resRuv.index + resRuv[0].length + 3).trim()
					 if(eRRUV.exec(r1) != null){
 						r1 =  'Es correcta la , '+r1Contenido;
	 					} else {
	 						r1 =  'Es incorrecta la , '+r1Contenido;
	 					}


				} else {
					// Pregunta Normal
				   let pArticulo = eRArticulo.exec(r1);
				   let notaAP = eRNota.exec(r1)
				   let notaAPF = eRNotaFin.exec(r1)
				   let subrayadoInicio = eRSubrayadoIn.exec(r1)
				   let subrayadoFin = eRSubrayadoFin.exec(r1)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r1, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					 }

				   if(notaAP != null){
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
					   articulo = r1.substring(pArticulo.index + pArticulo[0].length).trim()
				   }

				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]

				   if(notaAP != null){
					   notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				  if(subrayadoInicio != null){
					  subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				  }
				   r1 =  r1.substring(0 ,pArticulo.index).trim()
				   r1V = 1;

			   }
		   } else if (eRArticulo.exec(r2) != null) {
			   if (eRResMultiple.exec(r2) != null || eRResMultipleIncorrecta.exec(r2) != null) {
				   let pArticulo = eRArticulo.exec(r2);
				   let notaAP = eRNota.exec(r2)
				   let notaAPF = eRNotaFin.exec(r2)
				   let subrayadoInicio = eRSubrayadoIn.exec(r2)
				   let subrayadoFin = eRSubrayadoFin.exec(r2)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r2, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r2, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r2, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
					 }

				   if(notaAP != null){
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]
				   if(notaAP != null){
					   notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				  if(subrayadoInicio != null){
					  subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				  }
				   r2 =  r2.substring(0 ,pArticulo.index).trim()


				   let resMult
					 if(eRResMultiple.exec(r2) != null){
						 resMult = eRResMultiple.exec(r2);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r2);
					 }
				   if(eRY.exec(r2) != null){
					   // Es MC
					   r2V = 1;

					   let r1Valida = r2.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[0].trim()
					   let r2Valida = r2.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
   					    }

					   tipoPregunta = 2;
				   } else if (eRO.exec(r2) != null){
					   // Es MUV
					   r2V = 20;
					   let r1Valida = r2.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[0].trim()
					   let r2Valida = r2.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
   					    }
					   tipoPregunta = 5;
				   }
					 let r2Contenido = r2.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r2Contenido == undefined) { r2Contenido = ''}
					 if(eRResMultiple.exec(r2) != null){
						 r2 =  'Son correctas: . '+r2Contenido;
					 } else {
						 r2 =  'Son incorrectas: . '+r2Contenido;
					 }


			   } else if (eRRUV.exec(r2) != null || eRRUVIncorrecta.exec(r2) != null){
			// Es correcta una RUV
					let pArticulo = eRArticulo.exec(r2);
					let notaAP = eRNota.exec(r2)
					let notaAPF = eRNotaFin.exec(r2)
					let subrayadoInicio = eRSubrayadoIn.exec(r2)
					let subrayadoFin = eRSubrayadoFin.exec(r2)

					if(notaAP != null && notaAPF == null){
						throw new Error('Error en formulacion de nota r2, el final esta mal');
					}

					if(notaAP == null && notaAPF != null){
						throw new Error('Error en formulacion de nota r2, el inicio esta mal');
					}

					if(subrayadoInicio != null && subrayadoFin == null){
						throw new Error('Error en formulacion del subrayado r2, el final esta mal');
					}

					if(subrayadoInicio == null && subrayadoFin != null){
						throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
					}

					if(notaAP != null){
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
					} else if (subrayadoInicio != null){
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
					} else {
					   articulo = r2.substring(pArticulo.index + pArticulo[0].length).trim()
					}
					ley= articulo.split(' ')[1].split('.')[0];
					articulo = articulo.split(' ')[0]

					if(notaAP != null){
						notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					}
					if(subrayadoInicio != null){
						subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
					}
					r2 =  r2.substring(0 ,pArticulo.index).trim()

					let resRuv
					if(eRRUV.exec(r2) != null){
						resRuv = eRRUV.exec(r2);
					} else {
						resRuv = eRRUVIncorrecta.exec(r2);
					}
					let ruvValida = r2.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
					if(ruvValida.length != 1){
						ruvValida = r2.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
					}
					// clasificar si es ruv 1 o ruv 2
					if( r1V != 9 && r1V != 8 && r1V != 10 && r1V != 11 &&
						r2V != 9 && r2V != 8 && r2V != 10 && r2V != 11 &&
						r3V != 9 && r3V != 8 && r3V != 10 && r3V != 11 &&
						r4V != 9 && r4V != 8 && r4V != 10 && r4V != 11 ){

						// Es RUV1
						r2V = 6;
						if(ruvValida == 'a'){
 						 if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
 					    } else if (ruvValida == 'b'){
 						   if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
 					   } else if(ruvValida == 'c'){
 						   if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
 					   } else if(ruvValida == 'd'){
 						   if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
 					   }
				    } else {
					    // aqui ruv2
					    r2V = 12;

					    if(ruvValida == 'a'){
 						if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 }else { r1V = 14 }
 					   } else if (ruvValida == 'b'){
 						  if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 }else { r2V = 14 }
 					  } else if(ruvValida == 'c'){
 						  if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 }else { r3V = 14 }
 					  } else if(ruvValida == 'd'){
 						  if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 }else { r4V = 14 }
 					  }
				    }
				   tipoPregunta = 4;
				   let r2Contenido = r2.substring(resRuv.index + resRuv[0].length + 3).trim()
					 if(eRRUV.exec(r2) != null){
 						r2 =  'Es correcta la , '+r2Contenido;
 					} else {
 						r2 =  'Es incorrecta la , '+r2Contenido;
 					}


				} else {
					// Pregunta Normal
				   let pArticulo = eRArticulo.exec(r2);
				   let notaAP = eRNota.exec(r2)
				   let notaAPF = eRNotaFin.exec(r2)
				   let subrayadoInicio = eRSubrayadoIn.exec(r2)
				   let subrayadoFin = eRSubrayadoFin.exec(r2)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r2, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r2, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r2, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
					 }

				   if(notaAP != null){
					 articulo = r2.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
					 articulo = r2.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
					 articulo = r2.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]
				   if(notaAP != null){
 					  notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
 				  }
 				  if(subrayadoInicio != null){
 					  subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
 				  }

				   r2 =  r2.substring(0 ,pArticulo.index).trim()
				   r2V = 1;
			   }
		   } else if (eRArticulo.exec(r3) != null) {
			   if (eRResMultiple.exec(r3) != null || eRResMultipleIncorrecta.exec(r3) != null) {
				   let pArticulo = eRArticulo.exec(r3);
				   let notaAP = eRNota.exec(r3)
				   let notaAPF = eRNotaFin.exec(r3)
				   let subrayadoInicio = eRSubrayadoIn.exec(r3)
				   let subrayadoFin = eRSubrayadoFin.exec(r3)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r3, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r3, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r3, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
					 }

				   if(notaAP != null){
					 articulo = r3.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
					 articulo = r3.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
					 articulo = r3.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]


				   if(notaAP != null){
					   notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				   if(subrayadoInicio != null){
					   subrayadoC = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				   }

				   r3 =  r3.substring(0 ,pArticulo.index).trim()


				   let resMult
					 if(eRResMultiple.exec(r3) != null){
						 resMult = eRResMultiple.exec(r3);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r3);
					 }
				   if(eRY.exec(r3) != null){
					   // Es MC
					   r3V = 1;

					   let r1Valida = r3.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[0].trim()
					   let r2Valida = r3.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
   					    }

					   tipoPregunta = 2;
				   } else if (eRO.exec(r3) != null){
					   // Es MUV
					   r3V = 20;
					   let r1Valida = r3.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[0].trim()
					   let r2Valida = r3.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
   					    }
					   tipoPregunta = 5;
				   }


					 let r3Contenido = r3.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r3Contenido == undefined) { r3Contenido = ''}
					 if(eRResMultiple.exec(r3) != null){
						 r3 =  'Son correctas: . '+r3Contenido;
					 } else {
						 r3 =  'Son incorrectas: . '+r3Contenido;
					 }

			   } else if(eRRUV.exec(r3) != null || eRRUVIncorrecta.exec(r3) != null){
			// Es correcta una RUV
			let pArticulo = eRArticulo.exec(r3);
			let notaAP = eRNota.exec(r3)
			let notaAPF = eRNotaFin.exec(r3)
			let subrayadoInicio = eRSubrayadoIn.exec(r3)
			let subrayadoFin = eRSubrayadoFin.exec(r3)

			if(notaAP != null && notaAPF == null){
				throw new Error('Error en formulacion de nota r3, el final esta mal');
			}

			if(notaAP == null && notaAPF != null){
				throw new Error('Error en formulacion de nota r3, el inicio esta mal');
			}

			if(subrayadoInicio != null && subrayadoFin == null){
				throw new Error('Error en formulacion del subrayado r3, el final esta mal');
			}

			if(subrayadoInicio == null && subrayadoFin != null){
				throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
			}

			if(notaAP != null){
				articulo = r3.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
			} else if (subrayadoInicio != null){
				articulo = r3.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
			} else {
				articulo = r3.substring(pArticulo.index + pArticulo[0].length).trim()
			}

			ley= articulo.split(' ')[1].split('.')[0];
			articulo = articulo.split(' ')[0]

			if(notaAP != null){
				notaA = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
			}
				if(subrayadoInicio != null){
					subrayadoA = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				}
			r3 =  r3.substring(0 ,pArticulo.index).trim()
			let resRuv = eRRUV.exec(r3);
			if(eRRUV.exec(r3) != null){
				resRuv = eRRUV.exec(r3);
			} else {
				resRuv = eRRUVIncorrecta.exec(r3);
			}

			let ruvValida = r3.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
			if(ruvValida.length != 1){
				ruvValida = r3.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
			}
			// clasificar si es ruv 1 o ruv 2
			if( r1V != 9 && r1V != 8 && r1V != 10 && r1V != 11 &&
				r2V != 9 && r2V != 8 && r2V != 10 && r2V != 11 &&
				r3V != 9 && r3V != 8 && r3V != 10 && r3V != 11 &&
				r4V != 9 && r4V != 8 && r4V != 10 && r4V != 11 ){

				// Es RUV1
				r3V = 6;

					if(ruvValida == 'a'){
				 if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
					} else if (ruvValida == 'b'){
					 if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
				 } else if(ruvValida == 'c'){
					 if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
				 } else if(ruvValida == 'd'){
					 if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
				 }


				} else {
					// aqui ruv2
					r3V = 12;

				 if(ruvValida == 'a'){
				if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 }else { r1V = 14 }
				 } else if (ruvValida == 'b'){
					if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 }else { r2V = 14 }
				} else if(ruvValida == 'c'){
					if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 }else { r3V = 14 }
				} else if(ruvValida == 'd'){
					if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 }else { r4V = 14 }
				}

				}
			 tipoPregunta = 4;
			 let r3Contenido = r3.substring(resRuv.index + resRuv[0].length + 3).trim()
			 if(eRRUV.exec(r3) != null){
				r3 =  'Es correcta la , '+r3Contenido;
				} else {
					r3 =  'Es incorrecta la , '+r3Contenido;
				}


				} else {
					// Pregunta Normal
				   let pArticulo = eRArticulo.exec(r3);
				   let notaAP = eRNota.exec(r3)
				   let notaAPF = eRNotaFin.exec(r3)
				   let subrayadoInicio = eRSubrayadoIn.exec(r3)
				   let subrayadoFin = eRSubrayadoFin.exec(r3)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r3, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r3, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r3, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
					 }

				   if(notaAP != null){
				    articulo = r3.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
				    articulo = r3.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
				    articulo = r3.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]

				   if(notaAP != null){
 					  notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
 				  }
 				  if(subrayadoInicio != null){
 					  subrayadoC = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
 				  }

				   r3 =  r3.substring(0 ,pArticulo.index).trim()
				   r3V = 1;

			   }
		   } else if (eRArticulo.exec(r4) != null) {
			   if (eRResMultiple.exec(r4) != null || eRResMultipleIncorrecta.exec(r4) != null) {
				   let pArticulo = eRArticulo.exec(r4);
				   let notaAP = eRNota.exec(r4)
				   let notaAPF = eRNotaFin.exec(r4)
				   let subrayadoInicio = eRSubrayadoIn.exec(r4)
				   let subrayadoFin = eRSubrayadoFin.exec(r4)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r4, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r4, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r4, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
					 }

				   if(notaAP != null){
				    		articulo = r4.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
				    		articulo = r4.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
				    		articulo = r4.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]
				   if(notaAP != null){
					   notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				   if(subrayadoInicio != null){
					   subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				   }

				   r4 =  r4.substring(0 ,pArticulo.index).trim()


				   let resMult
					 if(eRResMultiple.exec(r4) != null){
						 resMult = eRResMultiple.exec(r4);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r4);
					 }
				   if(eRY.exec(r4) != null){
					   // Es MC
					   r4V = 1;

					   let r1Valida = r4.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[0].trim()
					   let r2Valida = r4.substring(resMult.index + resMult[0].length, pArticulo.index).split('y')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 5 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 2 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 5 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 2 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 5 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 2 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 5 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 2 }
   					    }

					   tipoPregunta = 2;
				   } else if (eRO.exec(r4) != null){
					   // Es MUV
					   r4V = 20;
					   let r1Valida = r4.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[0].trim()
					   let r2Valida = r4.substring(resMult.index + resMult[0].length, pArticulo.index).split('o')[1].split('.')[0].trim()
					   if(r1Valida == 'a'){
						   if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
				   		} else if (r1Valida == 'b'){
							if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
			   			} else if(r1Valida == 'c'){
							if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
		   				} else if(r1Valida == 'd'){
							if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
						}

						if(r2Valida == 'a'){
   						  if(r1V == 1){ r1V = 20 } else if(r1V == 9){ r1V=11 }else if(r1V == 8){ r1V = 10 } else if(r1V == 14){ r1V = 16} else if (r1V == 15) { r1V = 17} else { r1V = 21 }
					  	} else if (r2Valida == 'b'){
   						    if(r2V == 1){ r2V = 20 } else if(r2V == 9){ r2V=11 }else if(r2V == 8){ r2V = 10 } else if(r2V == 14){ r2V = 16} else if (r2V == 15) { r2V = 17} else { r2V = 21 }
   					    } else if(r2Valida == 'c'){
   						    if(r3V == 1){ r3V = 20 } else if(r3V == 9){ r3V=11 }else if(r3V == 8){ r3V = 10 } else if(r3V == 14){ r3V = 16} else if (r3V == 15) { r3V = 17} else { r3V = 21 }
   					    } else if(r2Valida == 'd'){
   						    if(r4V == 1){ r4V = 20 } else if(r4V == 9){ r4V=11 }else if(r4V == 8){ r4V = 10 } else if(r4V == 14){ r4V = 16} else if (r4V == 15) { r4V = 17} else { r4V = 21 }
   					    }
					   tipoPregunta = 5;
				   }

					 let r4Contenido = r4.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r4Contenido == undefined) { r4Contenido = ''}
					 if(eRResMultiple.exec(r4) != null){
						 r4 =  'Son correctas: . '+r4Contenido;
					 } else {
						 r4 =  'Son incorrectas: . '+r4Contenido;
					 }


			   } else if(eRRUV.exec(r4) != null || eRRUVIncorrecta.exec(r4) != null){
					 // Es correcta una RUV
					let pArticulo = eRArticulo.exec(r4);
					let notaAP = eRNota.exec(r4)
					let notaAPF = eRNotaFin.exec(r4)
					let subrayadoInicio = eRSubrayadoIn.exec(r4)
					let subrayadoFin = eRSubrayadoFin.exec(r4)

					if(notaAP != null && notaAPF == null){
						throw new Error('Error en formulacion de nota r4, el final esta mal');
					}

					if(notaAP == null && notaAPF != null){
						throw new Error('Error en formulacion de nota r4, el inicio esta mal');
					}

					if(subrayadoInicio != null && subrayadoFin == null){
						throw new Error('Error en formulacion del subrayado r4, el final esta mal');
					}

					if(subrayadoInicio == null && subrayadoFin != null){
						throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
					}

					if(notaAP != null){
						articulo = r4.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
					} else if (subrayadoInicio != null){
						articulo = r4.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
					} else {
						articulo = r4.substring(pArticulo.index + pArticulo[0].length).trim()
					}
					ley= articulo.split(' ')[1].split('.')[0];
					articulo = articulo.split(' ')[0]
					if(notaAP != null){
  					   notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
  				   }
  				   if(subrayadoInicio != null){
  					   subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
  				   }

					r4 =  r4.substring(0 ,pArticulo.index).trim()

					let resRuv
					if(eRRUV.exec(r4) != null){
						resRuv = eRRUV.exec(r4);
					} else {
						resRuv = eRRUVIncorrecta.exec(r4);
					}
					let ruvValida = r4.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
					if(ruvValida.length != 1){
						ruvValida = r4.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
					}
					// clasificar si es ruv 1 o ruv 2
					if( r1V != 9 && r1V != 8 && r1V != 10 && r1V != 11 &&
						r2V != 9 && r2V != 8 && r2V != 10 && r2V != 11 &&
						r3V != 9 && r3V != 8 && r3V != 10 && r3V != 11 &&
						r4V != 9 && r4V != 8 && r4V != 10 && r4V != 11 ){

						// Es RUV1
						r4V = 6;
						if(ruvValida == 'a'){
 						 if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
 					    } else if (ruvValida == 'b'){
 						   if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
 					   } else if(ruvValida == 'c'){
 						   if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
 					   } else if(ruvValida == 'd'){
 						   if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
 					   }
				    } else {
					    // aqui ruv2
					    r4V = 12;

					    if(ruvValida == 'a'){
 						if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 }else { r1V = 14 }
 					   } else if (ruvValida == 'b'){
 						  if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 }else { r2V = 14 }
 					  } else if(ruvValida == 'c'){
 						  if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 }else { r3V = 14 }
 					  } else if(ruvValida == 'd'){
 						  if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 }else { r4V = 14 }
 					  }
				    }
				   tipoPregunta = 4;
				   let r4Contenido = r4.substring(resRuv.index + resRuv[0].length + 3).trim()

					 if(eRRUV.exec(r4) != null){
 						r4 =  'Es correcta la , '+r4Contenido;
 					} else {
 						r4 =  'Es incorrecta la , '+r4Contenido;
 					}


				} else {
					// Pregunta Normal
				   let pArticulo = eRArticulo.exec(r4);
				   let notaAP = eRNota.exec(r4)
				   let notaAPF = eRNotaFin.exec(r4)
				   let subrayadoInicio = eRSubrayadoIn.exec(r4)
				   let subrayadoFin = eRSubrayadoFin.exec(r4)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r4, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r4, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r4, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
					 }

				   if(notaAP != null){
				   	articulo = r4.substring(pArticulo.index + pArticulo[0].length, notaAP.index).trim()
				   } else if (subrayadoInicio != null){
				   	articulo = r4.substring(pArticulo.index + pArticulo[0].length, subrayadoInicio.index).trim()
				   } else {
				   	articulo = r4.substring(pArticulo.index + pArticulo[0].length).trim()
				   }
				   ley= articulo.split(' ')[1].split('.')[0];
				   articulo = articulo.split(' ')[0]
				   if(notaAP != null){
					   notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
				   }
				   if(subrayadoInicio != null){
					   subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
				   }

				   r4 =  r4.substring(0 ,pArticulo.index).trim()
				   r4V = 1;
			   }
		   }

		   // TIPO DE PREGUNTA 3 --> Multiple False MF

		   // Localizamos la Multiple Falsa
			   if((eRResMultiple.exec(r1) != null || eRResMultipleIncorrecta.exec(r1) != null )&& r1V == 0){
				   let notaAP = eRNota.exec(r1)
				   let notaAPF = eRNotaFin.exec(r1)
				   let subrayadoInicio = eRSubrayadoIn.exec(r1)
				   let subrayadoFin = eRSubrayadoFin.exec(r1)
					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r1, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					 }
					 if(subrayadoInicio != null) {
						 subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
						 if(notaAP != null){
							 notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						 }
					 } else if (notaAP != null) {
						 notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					 }

				   let resMult
					 if(eRResMultiple.exec(r1) != null){
						 resMult = eRResMultiple.exec(r1);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r1);
					 }
				   if(eRY.exec(r1) != null){
					   r1V = 3;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[1].split('.')[0].trim()
					   } else if(subrayadoInicio != null){
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[1].split('.')[0].trim()
					   } else {
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length).split('y')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length).split('y')[1].split('.')[0].trim()
					   }
					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 5 } else if(r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 5 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
	 				    }
					    if(tipoPregunta == 1){
						    tipoPregunta = 3;
					    }
				   } else if (eRO.exec(r1) != null){
					   r1V = 22;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[1].split('.')[0].trim()
					   } else if(subrayadoInicio != null){
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[1].split('.')[0].trim()
					   } else {
						   r1ValidaFalsa = r1.substring(resMult.index + resMult[0].length).split('o')[0].trim()
						   r2ValidaFalsa = r1.substring(resMult.index + resMult[0].length).split('o')[1].split('.')[0].trim()
					   }
					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 24 } else if(r1V == 8){ r1V = 10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 24 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
	 				    }
					   if(tipoPregunta == 1){
						   tipoPregunta = 5;
					   }
				   }


					 let r1Contenido = r1.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r1Contenido == undefined) { r1Contenido = ''}
					 if(eRResMultiple.exec(r1) != null){
						 r1 =  'Son correctas: . '+r1Contenido;
					 } else {
						 r1 =  'Son incorrectas: . '+r1Contenido;
					 }

			   } else if((eRResMultiple.exec(r2) != null || eRResMultipleIncorrecta.exec(r2) != null) && r2V == 0) {
				   let notaAP = eRNota.exec(r2)
				   let notaAPF = eRNotaFin.exec(r2)
				   let subrayadoInicio = eRSubrayadoIn.exec(r2)
				   let subrayadoFin = eRSubrayadoFin.exec(r2)
					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r2, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r2, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r2, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
					 }
					 if(subrayadoInicio != null) {
						 subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
						 if(notaAP != null){
							 notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						 }
					 } else if (notaAP != null) {
						 notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					 }

				   let resMult
					 if(eRResMultiple.exec(r2) != null){
						 resMult = eRResMultiple.exec(r2);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r2);
					 }
				   if(eRY.exec(r2) != null){
					   r2V = 3;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
							r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[0].trim()
				   			r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[1].split('.')[0].trim()
				   		} else if(subrayadoInicio != null){
				   			r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[0].trim()
				   			r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[1].split('.')[0].trim()
				   		} else {
							r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length).split('y')[0].trim()
							r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length).split('y')[1].split('.')[0].trim()
			   			}

					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 5 } else if(r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 5 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
	 				    }
					   if(tipoPregunta == 1){
						   tipoPregunta = 3;
					   }
				   } else if (eRO.exec(r2) != null){
					   r2V = 22;
					   let r1ValidaFalsa, r2ValidaFalsa;
						if(notaAP != null){
							r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[0].trim()
							r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[1].split('.')[0].trim()
						} else if(subrayadoInicio != null){
							r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[0].trim()
							r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[1].split('.')[0].trim()
						} else {
							r1ValidaFalsa = r2.substring(resMult.index + resMult[0].length).split('o')[0].trim()
							r2ValidaFalsa = r2.substring(resMult.index + resMult[0].length).split('o')[1].split('.')[0].trim()
						}
  					   if(r1ValidaFalsa == 'a'){
  						    if(r1V == 1){ r1V = 24 } else if(r1V == 8){ r1V = 10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
  					    } else if (r1ValidaFalsa == 'b'){
  						    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
  					    } else if(r1ValidaFalsa == 'c'){
  						    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
  					    } else if(r1ValidaFalsa == 'd'){
  						    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
  					    }

  					    if(r2ValidaFalsa == 'a'){
  	 					    if(r1V == 1){ r1V = 24 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
  	 				    } else if (r2ValidaFalsa == 'b'){
  	 					    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
  	 				    } else if(r2ValidaFalsa == 'c'){
  	 					    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
  	 				    } else if(r2ValidaFalsa == 'd'){
  	 					    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
  	 				    }
  					   if(tipoPregunta == 1){
 						   tipoPregunta = 5;
 					   }
				   }

					 let r2Contenido = r2.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r2Contenido == undefined) { r2Contenido = ''}
					 if(eRResMultiple.exec(r2) != null){
						 r2 =  'Son correctas: . '+r2Contenido;
					 } else {
						 r2 =  'Son incorrectas: . '+r2Contenido;
					 }
			   } else if( (eRResMultiple.exec(r3) != null || eRResMultipleIncorrecta.exec(r3) != null) && r3V == 0) {
				   let notaAP = eRNota.exec(r3)
				   let notaAPF = eRNotaFin.exec(r3)
				   let subrayadoInicio = eRSubrayadoIn.exec(r3)
				   let subrayadoFin = eRSubrayadoFin.exec(r3)
					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r3, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r3, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r3, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
					 }
					 if(subrayadoInicio != null) {
 						subrayadoC = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
 						if(notaAP != null){
 							notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
 						}
 					} else if (notaAP != null) {
 						notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
 					}

				   let resMult
					 if (eRResMultiple.exec(r3) != null) {
						 resMult = eRResMultiple.exec(r3);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r3);
					 }
				   if(eRY.exec(r3) != null){
					   r3V = 3;
					   let r1ValidaFalsa, r2ValidaFalsa;
					if(notaAP != null){
						r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[0].trim()
						r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[1].split('.')[0].trim()
					} else if(subrayadoInicio != null){
						r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[0].trim()
						r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[1].split('.')[0].trim()
					} else {
						r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length).split('y')[0].trim()
						r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length).split('y')[1].split('.')[0].trim()
					}

					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 5 } else if(r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 5 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
	 				    }
					    if(tipoPregunta == 1){
 						   tipoPregunta = 3;
 					   }
				   } else if (eRO.exec(r3) != null){
					   r3V = 22;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
							r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[0].trim()
							r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[1].split('.')[0].trim()
						} else if(subrayadoInicio != null){
							r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[0].trim()
							r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[1].split('.')[0].trim()
						} else {
							r1ValidaFalsa = r3.substring(resMult.index + resMult[0].length).split('o')[0].trim()
							r2ValidaFalsa = r3.substring(resMult.index + resMult[0].length).split('o')[1].split('.')[0].trim()
						}
  					   if(r1ValidaFalsa == 'a'){
  						    if(r1V == 1){ r1V = 24 } else if(r1V == 8){ r1V = 10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
  					    } else if (r1ValidaFalsa == 'b'){
  						    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
  					    } else if(r1ValidaFalsa == 'c'){
  						    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
  					    } else if(r1ValidaFalsa == 'd'){
  						    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
  					    }

  					    if(r2ValidaFalsa == 'a'){
  	 					    if(r1V == 1){ r1V = 24 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
  	 				    } else if (r2ValidaFalsa == 'b'){
  	 					    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
  	 				    } else if(r2ValidaFalsa == 'c'){
  	 					    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
  	 				    } else if(r2ValidaFalsa == 'd'){
  	 					    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
  	 				    }
  					    if(tipoPregunta == 1){
 						   tipoPregunta = 5;
 					   }
				   }

					 let r3Contenido = r3.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r3Contenido == undefined) { r3Contenido = ''}
					 if(eRResMultiple.exec(r3) != null){
						 r3 =  'Son correctas: . '+r3Contenido;
					 } else {
						 r3 =  'Son incorrectas: . '+r3Contenido;
					 }
			   } else if( (eRResMultiple.exec(r4) != null || eRResMultipleIncorrecta.exec(r4) != null) && r4V == 0) {
				   let notaAP = eRNota.exec(r4)
				   let notaAPF = eRNotaFin.exec(r4)
				   let subrayadoInicio = eRSubrayadoIn.exec(r4)
				   let subrayadoFin = eRSubrayadoFin.exec(r4)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r4, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r4, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r4, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
					 }
						if(subrayadoInicio != null) {
	 						subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
	 						if(notaAP != null){
	 							notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
	 						}
	 					} else if (notaAP != null) {
	 						notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
	 					}

				   let resMult
					 if(eRResMultiple.exec(r4) != null){
						 resMult = eRResMultiple.exec(r4);
					 } else {
						 resMult = eRResMultipleIncorrecta.exec(r4);
					 }
				   if(eRY.exec(r4) != null){
					   r4V = 3;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length, notaAP.index).split('y')[1].split('.')[0].trim()
					   } else if(subrayadoInicio != null){
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('y')[1].split('.')[0].trim()
					   } else {
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length).split('y')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length).split('y')[1].split('.')[0].trim()
					   }

					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 5 } else if(r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 5 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 4 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 5 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 4 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 5 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 4 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 5 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 4 }
	 				    }
					    if(tipoPregunta == 1){
 						   tipoPregunta = 3;
 					   }
				   } else if (eRO.exec(r4) != null){
					   r4V = 22;
					   let r1ValidaFalsa, r2ValidaFalsa;
					   if(notaAP != null){
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length, notaAP.index).split('o')[1].split('.')[0].trim()
					   } else if(subrayadoInicio != null){
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length, subrayadoInicio.index).split('o')[1].split('.')[0].trim()
					   } else {
						   r1ValidaFalsa = r4.substring(resMult.index + resMult[0].length).split('o')[0].trim()
						   r2ValidaFalsa = r4.substring(resMult.index + resMult[0].length).split('o')[1].split('.')[0].trim()
					   }
					   if(r1ValidaFalsa == 'a'){
						    if(r1V == 1){ r1V = 24 } else if(r1V == 8){ r1V = 10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
					    } else if (r1ValidaFalsa == 'b'){
						    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
					    } else if(r1ValidaFalsa == 'c'){
						    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
					    } else if(r1ValidaFalsa == 'd'){
						    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
					    }

					    if(r2ValidaFalsa == 'a'){
	 					    if(r1V == 1){ r1V = 24 } else if (r1V== 8){ r1V =  10} else if(r1V==9) { r1V =  11 } else if(r1V==14){ r1V = 16 } else if(r1V==15){ r1V = 17 }  else { r1V = 23 }
	 				    } else if (r2ValidaFalsa == 'b'){
	 					    if(r2V == 1){ r2V = 24 } else if(r2V== 8){ r2V =  10} else if(r2V==9) { r2V =  11 } else if(r2V==14){ r2V = 16 } else if(r2V==15){ r2V = 17 }  else { r2V = 23 }
	 				    } else if(r2ValidaFalsa == 'c'){
	 					    if(r3V == 1){ r3V = 24 } else if(r3V== 8){ r3V =  10} else if(r3V==9) { r3V =  11 } else if(r3V==14){ r3V = 16 } else if(r3V==15){ r3V = 17 }  else { r3V = 23 }
	 				    } else if(r2ValidaFalsa == 'd'){
	 					    if(r4V == 1){ r4V = 24 } else if(r4V== 8){ r4V =  10} else if(r4V==9) { r4V =  11 } else if(r4V==14){ r4V = 16 } else if(r4V==15){ r4V = 17 }  else { r4V = 23 }
	 				    }
					   if(tipoPregunta == 1){
 						   tipoPregunta = 5;
 					   }
				   }

					 let r4Contenido = r4.substring(resMult.index + resMult[0].length + 7).trim()
					 if(r4Contenido == undefined) { r4Contenido = ''}
					 if(eRResMultiple.exec(r4) != null){
						 r4 =  'Son correctas: . '+r4Contenido;
					 } else {
						 r4 =  'Son incorrectas: . '+r4Contenido;
					 }
			   }

			    // Fin MF

			   // Inicio respuesta unica variable falsa corresponde a  7 y 13, tipo 4.
			 if((eRRUV.exec(r1) != null || eRRUVIncorrecta.exec(r1) != null) && r1V == 0){
					let notaAP = eRNota.exec(r1)
					let notaAPF = eRNotaFin.exec(r1)
					let subrayadoInicio = eRSubrayadoIn.exec(r1)
					let subrayadoFin = eRSubrayadoFin.exec(r1)
					if(notaAP != null && notaAPF == null){
						throw new Error('Error en formulacion de nota r1, el final esta mal');
					}

					if(notaAP == null && notaAPF != null){
						throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					}

					if(subrayadoInicio != null && subrayadoFin == null){
						throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					}

					if(subrayadoInicio == null && subrayadoFin != null){
						throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					}
					if(subrayadoInicio != null) {
						subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

						if(notaAP != null){
							notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						}
						r1 = r1.substring(0 ,subrayadoInicio.index).trim()
					} else if (notaAP != null) {
						notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						r1 = r1.substring(0 ,notaAP.index).trim()
					} else if (notaAP == null && subrayadoInicio == null) {
						r1 = r1.substring(0).trim()
					}

					let resRuv
					if(eRRUV.exec(r1) != null){
						resRuv = eRRUV.exec(r1);
					} else {
						resRuv = eRRUVIncorrecta.exec(r1);
					}
					let ruvValida = r1.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
					if(ruvValida.length != 1){
						ruvValida = r1.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
					}
					// clasificar si es ruv 1 o ruv 2
  					if( r1V != 7 && r2V != 7 && r3V != 7 && r4V != 7 && r1V != 6 && r2V != 6 && r3V != 6 && r4V != 6  ){

  						// Es RUVF1
  						r1V = 7;
						if(ruvValida == 'a'){
						  if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
						} else if (ruvValida == 'b'){
						    if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
					    } else if(ruvValida == 'c'){
						    if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 ||  r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
					    } else if(ruvValida == 'd'){
						    if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
					    }
  				    } else {
  					    // aqui RUVF2
  					    r1V = 13;

					    if(ruvValida == 'a'){
						 if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 } else if(r1V == 9){ r1V = 19 }else { r1V = 14 }
					    } else if (ruvValida == 'b'){
						   if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 } else if(r2V == 9){ r2V = 19 }else { r2V = 14 }
					   } else if(ruvValida == 'c'){
						   if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 } else if(r3V == 9){ r3V = 19 } else { r3V = 14 }
					   } else if(ruvValida == 'd'){
						   if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 } else if(r4V == 9){ r4V = 19 } else { r4V = 14 }
					   }
  				    }

  				  let rContenido = r1.substring(resRuv.index + resRuv[0].length + 3).trim()

					 if(eRRUV.exec(r1) != null){
 						r1 =  'Es correcta la , '+rContenido;
	 					} else {
	 					r1 =  'Es correcta la , '+rContenido;
	 					}
				   if(tipoPregunta == 1){
					   tipoPregunta = 4;
				   }
			   }

			   if((eRRUV.exec(r2) != null || eRRUVIncorrecta.exec(r2) != null) && r2V == 0){
  					let notaAP = eRNota.exec(r2)
  					let notaAPF = eRNotaFin.exec(r2)
  					let subrayadoInicio = eRSubrayadoIn.exec(r2)
  					let subrayadoFin = eRSubrayadoFin.exec(r2)
						if(notaAP != null && notaAPF == null){
 						 throw new Error('Error en formulacion de nota r2, el final esta mal');
 					 }

 					 if(notaAP == null && notaAPF != null){
 						 throw new Error('Error en formulacion de nota r2, el inicio esta mal');
 					 }

 					 if(subrayadoInicio != null && subrayadoFin == null){
 						 throw new Error('Error en formulacion del subrayado r2, el final esta mal');
 					 }

 					 if(subrayadoInicio == null && subrayadoFin != null){
 						 throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
 					 }
						if(subrayadoInicio != null) {
							subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

							if(notaAP != null){
								notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
							}
							r2 = r2.substring(0 ,subrayadoInicio.index).trim()
						} else if (notaAP != null) {
							notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
							r2 = r2.substring(0 ,notaAP.index).trim()
						} else if (notaAP == null && subrayadoInicio == null) {
							r2 = r2.substring(0).trim()
						}

  					let resRuv
						if(eRRUV.exec(r2) != null){
							resRuv = eRRUV.exec(r2);
						} else {
							resRuv = eRRUVIncorrecta.exec(r2);
						}
  					let ruvValida = r2.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
						if(ruvValida.length != 1){
							ruvValida = r2.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
						}
  					// clasificar si es ruv 1 o ruv 2
  					if( r1V != 7 && r2V != 7 && r3V != 7 && r4V != 7 && r1V != 6 && r2V != 6 && r3V != 6 && r4V != 6  ){

  						// Es RUVF1
  						r2V = 7;
						if(ruvValida == 'a'){
						  if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
						} else if (ruvValida == 'b'){
						    if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
					    } else if(ruvValida == 'c'){
						    if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 ||  r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
					    } else if(ruvValida == 'd'){
						    if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
					    }
  				    } else {
  					    // aqui RUVF2
  					    r2V = 13;

					    if(ruvValida == 'a'){
						 if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 } else if(r1V == 9){ r1V = 19 }else { r1V = 14 }
					    } else if (ruvValida == 'b'){
						   if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 } else if(r2V == 9){ r2V = 19 }else { r2V = 14 }
					   } else if(ruvValida == 'c'){
						   if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 } else if(r3V == 9){ r3V = 19 } else { r3V = 14 }
					   } else if(ruvValida == 'd'){
						   if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 } else if(r4V == 9){ r4V = 19 } else { r4V = 14 }
					   }
  				    }

  				   let rContenido = r2.substring(resRuv.index + resRuv[0].length + 3).trim()
						 if(eRRUV.exec(r2) != null){
							 r2 =  'Es correcta la , '+rContenido;
						 } else {
							r2 =  'Es incorrecta la , '+rContenido;
							}
				   if(tipoPregunta == 1){
					   tipoPregunta = 4;
				   }
  			   }

			   if((eRRUV.exec(r3) != null || eRRUVIncorrecta.exec(r3) != null) && r3V == 0){
  					let notaAP = eRNota.exec(r3)
  					let notaAPF = eRNotaFin.exec(r3)
  					let subrayadoInicio = eRSubrayadoIn.exec(r3)
  					let subrayadoFin = eRSubrayadoFin.exec(r3)
						if(notaAP != null && notaAPF == null){
 						 throw new Error('Error en formulacion de nota r3, el final esta mal');
 					 }

 					 if(notaAP == null && notaAPF != null){
 						 throw new Error('Error en formulacion de nota r3, el inicio esta mal');
 					 }

 					 if(subrayadoInicio != null && subrayadoFin == null){
 						 throw new Error('Error en formulacion del subrayado r3, el final esta mal');
 					 }

 					 if(subrayadoInicio == null && subrayadoFin != null){
 						 throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
 					 }
						if(subrayadoInicio != null) {
							subrayadoC = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

							if(notaAP != null){
								notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
							}
							r3 = r3.substring(0 ,subrayadoInicio.index).trim()
						} else if (notaAP != null) {
							notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
							r3 = r3.substring(0 ,notaAP.index).trim()
						} else if (notaAP == null && subrayadoInicio == null) {
							r3 = r3.substring(0).trim()
						}

  					let resRuv
						if(eRRUV.exec(r3) != null){
							resRuv = eRRUV.exec(r3);
						} else {
							resRuv = eRRUVIncorrecta.exec(r3);
						}
  					let ruvValida = r3.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
						if(ruvValida.length != 1){
							ruvValida = r3.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
						}
						console.log(ruvValida)
  					// clasificar si es ruv 1 o ruv 2
  					if( r1V != 7 && r2V != 7 && r3V != 7 && r4V != 7 && r1V != 6 && r2V != 6 && r3V != 6 && r4V != 6  ){

  						// Es RUVF1
  						r3V = 7;
						if(ruvValida == 'a'){
						  if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
						} else if (ruvValida == 'b'){
							console.log(r2V)
						    if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
					    } else if(ruvValida == 'c'){
						    if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 ||  r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
					    } else if(ruvValida == 'd'){
						    if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
					    }
  				    } else {
  					    // aqui RUVF2
  					    r3V = 13;

					    if(ruvValida == 'a'){
						 if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 } else if(r1V == 9){ r1V = 19 }else { r1V = 14 }
					    } else if (ruvValida == 'b'){
						   if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 } else if(r2V == 9){ r2V = 19 }else { r2V = 14 }
					   } else if(ruvValida == 'c'){
						   if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 } else if(r3V == 9){ r3V = 19 } else { r3V = 14 }
					   } else if(ruvValida == 'd'){
						   if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 } else if(r4V == 9){ r4V = 19 } else { r4V = 14 }
					   }
  				    }

  				   let rContenido = r3.substring(resRuv.index + resRuv[0].length + 3).trim()

						 if(eRRUV.exec(r3) != null){
 							r3 =  'Es correcta la , '+rContenido;
	 						} else {
	 							r3 =  'Es incorrecta la , '+rContenido;
	 						}
				   if(tipoPregunta == 1){
					   tipoPregunta = 4;
				   }
  			   }

			   if((eRRUV.exec(r4) != null || eRRUVIncorrecta.exec(r4) != null) && r4V == 0){
  					let notaAP = eRNota.exec(r4)
  					let notaAPF = eRNotaFin.exec(r4)
  					let subrayadoInicio = eRSubrayadoIn.exec(r4)
  					let subrayadoFin = eRSubrayadoFin.exec(r4)
						if(notaAP != null && notaAPF == null){
 						 throw new Error('Error en formulacion de nota r4, el final esta mal');
 					 }

 					 if(notaAP == null && notaAPF != null){
 						 throw new Error('Error en formulacion de nota r4, el inicio esta mal');
 					 }

 					 if(subrayadoInicio != null && subrayadoFin == null){
 						 throw new Error('Error en formulacion del subrayado r4, el final esta mal');
 					 }

 					 if(subrayadoInicio == null && subrayadoFin != null){
 						 throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
 					 }
					if(subrayadoInicio != null) {
						subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

						if(notaAP != null){
							notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						}
						r4 = r4.substring(0 ,subrayadoInicio.index).trim()
					} else if (notaAP != null) {
						notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
						r4 = r4.substring(0 ,notaAP.index).trim()
					} else if (notaAP == null && subrayadoInicio == null) {
						r4 = r4.substring(0).trim()
					}

  					let resRuv
						if(eRRUV.exec(r4) != null){
							resRuv = eRRUV.exec(r4);
						} else {
							resRuv = eRRUV.exec(r4);
						}
  					let ruvValida = r4.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split(',')[0].trim()
						if(ruvValida.length != 1){
							ruvValida = r4.substring(resRuv.index + resRuv[0].length, resRuv.index + resRuv[0].length+4).split('.')[0].trim()
						}
  					// clasificar si es ruv 1 o ruv 2
  					if( r1V != 7 && r2V != 7 && r3V != 7 && r4V != 7 && r1V != 6 && r2V != 6 && r3V != 6 && r4V != 6  ){

  						// Es RUVF1
  						r4V = 7;
						if(ruvValida == 'a'){
						  if(r1V == 2){ r1V=10 }else if(r1V == 4){ r1V = 10 } else if(r1V == 5){ r1V = 11} else if (r1V == 21) { r1V = 10} else if(r1V ==23) { r1V = 10 } else if(r1V == 24) { r1V = 11 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if(r1V == 1){ r1V = 9 } else { r1V = 8 }
						} else if (ruvValida == 'b'){
						    if(r2V == 2){ r2V=10 }else if(r2V == 4){ r2V = 10 } else if(r2V == 5){ r2V = 11} else if (r2V == 21) { r2V = 10} else if(r2V ==23) { r2V = 10 } else if(r2V == 24) { r2V = 11 } else if(r2V == 8 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if(r2V == 1){ r2V = 9 } else { r2V = 8 }
					    } else if(ruvValida == 'c'){
						    if(r3V == 2){ r3V=10 }else if(r3V == 4){ r3V = 10 } else if(r3V == 5){ r3V = 11} else if (r3V == 21) { r3V = 10} else if(r3V ==23) { r3V = 10 } else if(r3V == 24) { r3V = 11 } else if(r3V == 8 ||  r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if(r3V == 1){ r3V = 9 } else { r3V = 8 }
					    } else if(ruvValida == 'd'){
						    if(r4V == 2){ r4V=10 }else if(r4V == 4){ r4V = 10 } else if(r4V == 5){ r4V = 11} else if (r4V == 21) { r4V = 10} else if(r4V ==23) { r4V = 10 } else if(r4V == 24) { r4V = 11 } else if(r4V == 8 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if(r4V == 1){ r4V = 9 } else { r4V = 8 }
					    }
  				    } else {
  					    // aqui RUVF2
  					    r4V = 13;

					    if(ruvValida == 'a'){
						 if(r1V == 2){ r1V= 16 }else if(r1V == 4){ r1V = 16 } else if(r1V == 5){ r1V = 17} else if (r1V == 21) { r1V = 16} else if(r1V ==23) { r1V = 16 } else if(r1V == 24) { r1V = 17 } else if(r1V == 8 || r1V == 10 || r1V == 11 ) {  r1V = 18 } else if (r1V == 8 || r1V == 9 || r1V == 10 || r1V == 11 && r1V == 1){ r1V = 19 } else if (r1V == 1){ r1V=15 } else if(r1V == 9){ r1V = 19 }else { r1V = 14 }
					    } else if (ruvValida == 'b'){
						   if(r2V == 2){ r2V= 16 }else if(r2V == 4){ r2V = 16 } else if(r2V == 5){ r2V = 17} else if (r2V == 21) { r2V = 16} else if(r2V ==23) { r2V = 16 } else if(r2V == 24) { r2V = 17 } else if(r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 ) {  r2V = 18 } else if (r2V == 8 || r2V == 9 || r2V == 10 || r2V == 11 && r2V == 1){ r2V = 19 } else if (r2V == 1){ r2V=15 } else if(r2V == 9){ r2V = 19 }else { r2V = 14 }
					   } else if(ruvValida == 'c'){
						   if(r3V == 2){ r3V= 16 }else if(r3V == 4){ r3V = 16 } else if(r3V == 5){ r3V = 17} else if (r3V == 21) { r3V = 16} else if(r3V ==23) { r3V = 16 } else if(r3V == 24) { r3V = 17 } else if(r3V == 8 || r3V == 10 || r3V == 11 ) {  r3V = 18 } else if (r3V == 8 || r3V == 9 || r3V == 10 || r3V == 11 && r3V == 1){ r3V = 19 } else if (r3V == 1){ r3V=15 } else if(r3V == 9){ r3V = 19 } else { r3V = 14 }
					   } else if(ruvValida == 'd'){
						   if(r4V == 2){ r4V= 16 }else if(r4V == 4){ r4V = 16 } else if(r4V == 5){ r4V = 17} else if (r4V == 21) { r4V = 16} else if(r4V ==23) { r4V = 16 } else if(r4V == 24) { r4V = 17 } else if(r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 ) {  r4V = 18 } else if (r4V == 8 || r4V == 9 || r4V == 10 || r4V == 11 && r4V == 1){ r4V = 19 } else if (r4V == 1){ r4V=15 } else if(r4V == 9){ r4V = 19 } else { r4V = 14 }
					   }
  				    }

  				   let rContenido = r4.substring(resRuv.index + resRuv[0].length + 3).trim()

						 if(eRRUV.exec(r4) != null){
 							r4 =  'Es correcta la , '+rContenido;
	 						} else {
	 							r4 =  'Es incorrecta la , '+rContenido;
	 						}
				   if(tipoPregunta == 1){
					   tipoPregunta = 4;
				   }
  			   }
			   // Fin RUVF

			   // Notas  y subrayado falsas 0, 2 y 4.
			   		// Añadir nuevas falsas --> 8, 10, 14, 16, 21, 23
			   if(r1V == 0 || r1V == 2 || r1V == 4 || r1V == 8 || r1V == 10 || r1V == 14 || r1V == 16 || r1V == 21 || r1V == 23 || r1V == 18 ){
				   let notaAP = eRNota.exec(r1)
				   let notaAPF = eRNotaFin.exec(r1)
				   let subrayadoInicio = eRSubrayadoIn.exec(r1)
				   let subrayadoFin = eRSubrayadoFin.exec(r1)
					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r1, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r1, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r1, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r1, el inicio esta mal');
					 }

					 if(subrayadoInicio != null) {
						 subrayadoA = r1.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

						 if(notaAP != null){
						   notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   }

						 r1 = r1.substring(0 ,subrayadoInicio.index).trim()
					 } else if (notaAP != null) {
						 notaA = r1.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   r1 = r1.substring(0 ,notaAP.index).trim()
					 } else if (notaAP == null && subrayadoInicio == null) {
					   r1 = r1.substring(0).trim()
				   }


			   }
			   if(r2V == 0 || r2V == 2 || r2V == 4 || r2V == 8 || r2V == 10 || r2V == 14 || r2V == 16 || r2V == 21 || r2V == 23  || r2V == 18 ){
				   let notaAP = eRNota.exec(r2)
				   let notaAPF = eRNotaFin.exec(r2)
				   let subrayadoInicio = eRSubrayadoIn.exec(r2)
				   let subrayadoFin = eRSubrayadoFin.exec(r2)
					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r2, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r2, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r2, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r2, el inicio esta mal');
					 }
					 if(subrayadoInicio != null) {
						 subrayadoB = r2.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

						 if(notaAP != null){
						   notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   }

						 r2 = r2.substring(0 ,subrayadoInicio.index).trim()
					 } else if (notaAP != null) {
						 notaB = r2.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   r2 = r2.substring(0 ,notaAP.index).trim()
					 } else if (notaAP == null && subrayadoInicio == null) {
					   r2 = r2.substring(0).trim()
				   }

			   }
			   if(r3V == 0 || r3V == 2 || r3V == 4 || r3V == 8 || r3V == 10 || r3V == 14 || r3V == 16 || r3V == 21 || r3V == 23  || r3V == 18 ){

				   let notaAP = eRNota.exec(r3)
				   let notaAPF = eRNotaFin.exec(r3)
				   let subrayadoInicio = eRSubrayadoIn.exec(r3)
				   let subrayadoFin = eRSubrayadoFin.exec(r3)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r3, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r3, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r3, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r3, el inicio esta mal');
					 }

					 if(subrayadoInicio != null) {
						 subrayadoC = r3.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()

						 if(notaAP != null){
						   notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   }

						 r3 = r3.substring(0 ,subrayadoInicio.index).trim()
					 } else if (notaAP != null) {
						 notaC = r3.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   r3 = r3.substring(0 ,notaAP.index).trim()
					 } else if (notaAP == null && subrayadoInicio == null) {
					   r3 = r3.substring(0).trim()
				   }

			   }
			   if(r4V == 0 || r4V == 2 || r4V == 4 || r4V == 8 || r4V == 10 || r4V == 14 || r4V == 16 || r4V == 21 || r4V == 23  || r4V == 18 ){
				   let notaAP = eRNota.exec(r4)
				   let notaAPF = eRNotaFin.exec(r4)
				   let subrayadoInicio = eRSubrayadoIn.exec(r4)
				   let subrayadoFin = eRSubrayadoFin.exec(r4)

					 if(notaAP != null && notaAPF == null){
						 throw new Error('Error en formulacion de nota r4, el final esta mal');
					 }

					 if(notaAP == null && notaAPF != null){
						 throw new Error('Error en formulacion de nota r4, el inicio esta mal');
					 }

					 if(subrayadoInicio != null && subrayadoFin == null){
						 throw new Error('Error en formulacion del subrayado r4, el final esta mal');
					 }

					 if(subrayadoInicio == null && subrayadoFin != null){
						 throw new Error('Error en formulacion del subrayado r4, el inicio esta mal');
					 }

					 if(subrayadoInicio != null) {
						 subrayadoD = r4.substring(subrayadoInicio.index + subrayadoInicio[0].length, subrayadoFin.index).trim()
						 if(notaAP != null){
						   notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   }
						 r4 = r4.substring(0 ,subrayadoInicio.index).trim()
					 } else if (notaAP != null) {
						 notaD = r4.substring(notaAP.index + notaAP[0].length , notaAPF.index).trim()
					   r4 = r4.substring(0 ,notaAP.index).trim()
					 } else if (notaAP == null && subrayadoInicio == null) {
					   r4 = r4.substring(0).trim()
				   }

			   }

			   let idLey = ''
			   if(state.busquedaLeyes.find( l => l.siglas_ley == ley) != undefined){
				   idLey = state.busquedaLeyes.find( l => l.siglas_ley == ley).id_ley
			   } else {
				   siglasLeyMal = ley;
				   throw new Error('Ley no existe');
			   }

		   if(isDefined(pregunta) && isDefined(r1) && isDefined(r2) && isDefined(r3) && isDefined(r4)){

			   let data = { pregunta: pregunta, articulo: articulo, idLey: idLey, r1: r1, r2: r2, r3:r3, r4: r4}
			   let numPreguntt = parseInt(i +1);
			   fetch("https://oposiciones-justicia.es/api/informatica/preguntaDuplicadaJusticia", {
				   method: 'POST',
				   headers: {'Content-Type': 'application/json'},
				   body: JSON.stringify(data)
			   }).then(response =>  {
				   if (response.status >= 400) { throw new Error("Bad response from server");}
				   return response.json();
			   }).then(cls => {
	   			if (cls.length == 0 || cls == undefined ){
					preguntasRespuestasClasificado[0].preguntas.push({
						insertar: 1,
	  					numPreg: numPreguntt,
	  					pregunta: pregunta,
	  					idPregunta: 0,
	  					tipoPregunta: tipoPregunta,
	  					ley: ley,
	  					idLey: idLey,
	  					articulo: articulo,
	  					r1: r1,
	  					r1_validez: r1V,
	  					idRespuesta1: 0,
	  					r2: r2,
	  					idRespuesta2: 0,
	  					r2_validez: r2V,
	  					r3: r3,
	  					idRespuesta3: 0,
	  					r3_validez: r3V,
	  					r4: r4,
	  					idRespuesta4: 0,
	  					r4_validez: r4V,
	  					notaA: notaA,
	  					notaB: notaB,
	  					notaC: notaC,
	  					notaD: notaD,
	  					subrayadoA: subrayadoA,
	  					subrayadoB: subrayadoB,
	  					subrayadoC: subrayadoC,
	  					subrayadoD: subrayadoD,
	  					errorDetectado: 0,
	  					preguntaCheck: 0
	  			   })
			   } else if(cls.length > 0 && state.testCompleto[0].testPredefinido == 1){
					 console.log(cls)
					 let r1Id = 0
				   let r2Id = 0
				   let r3Id = 0
				   let r4Id = 0
					 let insertar = 1
					 let idPregunta = 0
					 let preguntaCheck = 0
					 for (var p = 0; p < cls.length; p++) {
						 if(cls[p].idR1 != null && cls[p].idR2 != null && cls[p].idR3 != null && cls[p].idR4 != null && cls[p].idR1 != 'null' && cls[p].idR2 != 'null' && cls[p].idR3 != 'null' && cls[p].idR4 != 'null'){
							 r1Id = cls[p].idR1
							 r2Id = cls[p].idR2
							 r3Id = cls[p].idR3
							 r4Id = cls[p].idR4
							 insertar = 2
							 preguntaCheck = 1
							 idPregunta = cls[0].idPregunta
							 break;
						 }
					 }

				   preguntasRespuestasClasificado[0].preguntas.push({
					   insertar: insertar,
					   numPreg: numPreguntt,
					   pregunta: pregunta,
					   idPregunta: idPregunta,
					   tipoPregunta: tipoPregunta,
					   ley: ley,
					   idLey: idLey,
					   articulo: articulo,
					   r1: r1,
					   r1_validez: r1V,
					   idRespuesta1: r1Id,
					   r2: r2,
					   idRespuesta2: r2Id,
					   r2_validez: r2V,
					   r3: r3,
					   idRespuesta3: r3Id,
					   r3_validez: r3V,
					   r4: r4,
					   idRespuesta4: r4Id,
					   r4_validez: r4V,
					   notaA: notaA,
					   notaB: notaB,
					   notaC: notaC,
					   notaD: notaD,
					   subrayadoA: subrayadoA,
					   subrayadoB: subrayadoB,
					   subrayadoC: subrayadoC,
					   subrayadoD: subrayadoD,
					   errorDetectado: 0,
					   preguntaCheck: preguntaCheck
				 })
			 } else if(cls.length > 0 && state.testCompleto[0].testPredefinido == 0) {
				 preguntasRespuestasClasificado[0].preguntas.push({
					 insertar: 0,
					 numPreg: numPreguntt,
					 pregunta: pregunta,
					 idPregunta: cls[0].idPregunta,
					 tipoPregunta: tipoPregunta,
					 ley: ley,
					 idLey: idLey,
					 articulo: articulo,
					 r1: r1,
					 r1_validez: r1V,
					 idRespuesta1: 0,
					 r2: r2,
					 idRespuesta2: 0,
					 r2_validez: r2V,
					 r3: r3,
					 idRespuesta3: 0,
					 r3_validez: r3V,
					 r4: r4,
					 idRespuesta4: 0,
					 r4_validez: r4V,
					 notaA: notaA,
					 notaB: notaB,
					 notaC: notaC,
					 notaD: notaD,
					 subrayadoA: subrayadoA,
					 subrayadoB: subrayadoB,
					 subrayadoC: subrayadoC,
					 subrayadoD: subrayadoD,
					 errorDetectado: 0,
					 preguntaCheck: 1
			    })
			 }
			   }).catch(error => console.log('Object fecth failed', error))

		   }
	   }
	   return preguntasRespuestasClasificado;
   } catch(e){
	   if( e.message == "Ley no existe"){
		    swal('Error en ley de la pregunta: '+numPreguntaError+'.','Las siglas de la ley: '+siglasLeyMal+' no existen en la base de datos.','warning')
	   } else if(e.message == "Error uso de comillas dobles Preg"){
				swal('Error en texto de la pregunta: '+numPreguntaError+'.',"No puede contener comillas dobles cambiar por comillas simples --> ' ejemplo'.",'warning')
		 } else if (e.message == "Error uso de comillas dobles"){
				swal('Error en alguna respuesta de la pregunta: '+numPreguntaError+'.',"No puede contener comillas dobles, cambialas por comillas simples --> 'ejemplo'.",'warning')
		 } else if (e.message == "Pregunta duplicada"){
				swal('Pregunta Nº: '+preg1+' es la misma que la Nº: '+preg2+'.','Elimine una y vuelva a intentar insertar el test','warning')
		 } else if (e.message == "No se encontro la respuesta a"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no tiene respuesta a)','No encontramos ninguna respuesta comenzando por a)','warning')
		 } else if (e.message == "Se encontraron 2 respuesta a"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', la respuesta a) es doble.','Elimine una y vuelva a intentar insertar el test','warning')
		 } else if (e.message == "No se encontro la respuesta b"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no tiene respuesta b)','No encontramos ninguna respuesta comenzando por b)','warning')
		 } else if (e.message == "Se encontraron 2 respuesta b"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', la respuesta b) es doble.','Elimine una y vuelva a intentar insertar el test','warning')
		 } else if (e.message == "No se encontro la respuesta c"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no tiene respuesta c)','No encontramos ninguna respuesta comenzando por c)','warning')
		 } else if (e.message == "Se encontraron 2 respuesta c"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', la respuesta c) es doble.','Elimine una y vuelva a intentar insertar el test','warning')
		 } else if (e.message == "No se encontro la respuesta d"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no tiene respuesta d)','No encontramos ninguna respuesta comenzando por d)','warning')
		 } else if (e.message == "Se encontraron 2 respuesta d"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', la respuesta d) es doble.','Elimine una y vuelva a intentar insertar el test','warning')
		 } else if (e.message == "Error en formulacion de nota r1, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta a), la nota mal formulada.','Se detecto que el final no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion de nota r1, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta a), la nota mal formulada.','Se detecto que el inicio no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion del subrayado r1, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta a), subrayado mal formulado.','Se detecto que el final no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion del subrayado r1, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta a), subrayado mal formulado.','Se detecto que el inicio no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion de nota r2, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta b), la nota mal formulada.','Se detecto que el final no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion de nota r2, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta b), la nota mal formulada.','Se detecto que el inicio no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion del subrayado r2, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta b), subrayado mal formulado.','Se detecto que el final no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion del subrayado r2, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta b), subrayado mal formulado.','Se detecto que el inicio no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion de nota r3, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta c), la nota mal formulada.','Se detecto que el final no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion de nota r3, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta c), la nota mal formulada.','Se detecto que el inicio no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion del subrayado r3, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta c), subrayado mal formulado.','Se detecto que el final no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion del subrayado r3, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta c), subrayado mal formulado.','Se detecto que el inicio no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion de nota r4, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta d), la nota mal formulada.','Se detecto que el final no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion de nota r4, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta d), la nota mal formulada.','Se detecto que el inicio no es correcto Ejemplo:(-Nota aqui dentro-)','warning')
		 } else if (e.message == "Error en formulacion del subrayado r4, el final esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta d), subrayado mal formulado.','Se detecto que el final no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Error en formulacion del subrayado r4, el inicio esta mal"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', respuesta d), subrayado mal formulado.','Se detecto que el inicio no es correcto Ejemplo:[.2,5-10.]','warning')
		 } else if (e.message == "Articulo error doble"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no puede contener 2 Art.','Se detecto que el indicador de la respuesta correcta esta más de una vez. Recuerdo Art. solo puede aparecer una vez indica la respuesta correcta.','warning')
		 } else if (e.message == "No se encontro Articulo"){
				swal('Error en Pregunta Nº: '+numPreguntaError+', no se encontro la respuesta correcta "Art. 250 LEC."','Se detecto que el indicador de la respuesta correcta no se encuentra.','warning')
		 } else {
		   swal('Error en la Pregunta: '+numPreguntaError+'.','','warning')
		   //console.log(e)
	   }
   }


}else{
	swal("Debe seleccionar una Oposición y test predefinido para continuar.",'','warning')
	return false;
}
}

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/prgInfoSel.css';
import Submit from '../../../Inputs/Submit';
import ReactHtmlParser from 'react-html-parser';

class PreguntaJusticia extends Component {

  static propTypes = {
    pregunta: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          toogleInfoPregunta: 'none',
          mostrarResultado: 0
        };
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){

      if(e.target.id == 'toogleInfoPregunta'){
        let { id } = this.props;
        $('#infoPregunta-'+id).slideToggle(1000);
      }

    }

    handleOnClick(e){

      switch (e.target.id.split('-')[0]) {
        case 'seleccionarPreguntaEdt':
          if(this.state.mostrarResultado == 0){
            this.setState({ mostrarResultado: 1 })
          } else {
            this.setState({ mostrarResultado: 0 })
          }
          break;
          case 'textRespuestaTextGrande':
            $('.textRespuesta').toggleClass("textRespuestaTextGrande")
            break;
        default:

      }
    }

  componentDidMount(){


  }

  render() {
    const { id, pregunta } = this.props;
    const { mostrarResultado } = this.state
              return (
                <div className="infoPreguntaBodyPrgVPT">

                  <div className={'resumenPreguntaVPT'}>

                    <div className="infoCompressPreg" style={{width: '150px', background: '#ffb75a', marginRight: '3px',borderRadius: '5px 0px 0px 0px'}} title="Número de Pregunta">
                      <i className="fas fa-info-circle"></i>
                      Pregunta {pregunta.numPreg}
                    </div>

                    <div className="infoCompressPregText" title="Pregunta acortada.">
                      {pregunta.pregunta.substring(0,30)+'...'}
                    </div>

                    <div className="infoCompressPreg" title={pregunta.nombreLey}>
                      <i className="fas fa-book"></i>
                      {pregunta.siglasLey}
                    </div>

                    <div className="infoCompressPreg" title={"Artículo de: "+pregunta.nombreLey}>
                      <i className="fas fa-bookmark"></i>
                      {pregunta.articulo}
                    </div>

                    <div className="infoCompressPregToogle">
                      <i className="fas fa-caret-down" id={'toogleInfoPregunta'} onClick={this.onToggleClick}></i>
                    </div>

        					</div>


        					<div className="infoPregunta" id={'infoPregunta-'+id}>
                    <div className="infoPreguntaFlex">
                      <div className="delSelectPregVPT">
                      <Submit id={"seleccionarPreguntaEdt-"+id} icon1="fas fa-eye" icon2="fas fa-check-circle" texto="Mostrar Correcta" onClick={this.handleOnClick}/>
                      <Submit id={"textRespuestaTextGrande-"+id} icon1="fas fa-search-plus" icon2="fas fa-eye" texto="Agrandar Fuente" onClick={this.handleOnClick}/>
                      </div>
          						<div className="PreguntaCompleta">

                        <i className="fas fa-question"></i>
                        <i className="fas fa-angle-right" style={{marginRight: '15px'}}></i>
                        <div className="textRespuesta">
                          {pregunta.pregunta}
                        </div>
                      </div>



                      <div className={ mostrarResultado == 1 ? (
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 1 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 5 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 6 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 9 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 11 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 12 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 15 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 17 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 20 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR1).validez == 24 ?
                         ('respuestaCorrecta'):('respuesta1')):('respuesta1')
                      }>
                        <letra> A </letra>
                        <i className="fas fa-angle-right"></i>
                        { mostrarResultado == 1 ?(
                          <div className="textRespuesta">
                            {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR1).respuestaHtml)}
                          </div>
                        ):(
                          <div className="textRespuesta">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR1).respuesta}
                          </div>
                        )}
                      </div>
                      {mostrarResultado == 1 && pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota != undefined ? (
                        <div className='nota'>
                          <letra> Nota A) </letra>
                          <div className="textRespuestaNota">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR1).nota}
                          </div>
                        </div>
                      ):null}

                      <div className={ mostrarResultado == 1 ? (
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 1 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 5 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 6 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 9 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 11 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 12 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 15 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 17 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 20 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR2).validez == 24 ?
                         ('respuestaCorrecta'):('respuesta2')):('respuesta2')
                      }>
                        <letra> B </letra>
                        <i className="fas fa-angle-right"></i>
                        { mostrarResultado == 1 ?(
                          <div className="textRespuesta">
                            {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR2).respuestaHtml)}
                          </div>
                        ):(
                          <div className="textRespuesta">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR2).respuesta}
                          </div>
                        )}

                      </div>
                      {mostrarResultado == 1 && pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota != undefined ? (
                        <div className='nota'>
                          <letra> Nota B) </letra>
                          <div className="textRespuestaNota">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR2).nota}
                          </div>
                        </div>
                      ):null}

                      <div className={ mostrarResultado == 1 ? (
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 1 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 5 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 6 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 9 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 11 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 12 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 15 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 17 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 20 ||
                        pregunta.respuestas.find(r=> r.idR == pregunta.idR3).validez == 24 ?
                         ('respuestaCorrecta'):('respuesta1')):('respuesta1')
                      }>
                        <letra> C </letra>
                        <i className="fas fa-angle-right"></i>
                        { mostrarResultado == 1 ?(
                          <div className="textRespuesta">
                            {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR3).respuestaHtml)}
                          </div>
                        ):(
                          <div className="textRespuesta">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR3).respuesta}
                          </div>
                        )}
                      </div>
                      {mostrarResultado == 1 && pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota != undefined ? (
                        <div className='nota'>
                          <letra> Nota C) </letra>
                          <div className="textRespuestaNota">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR3).nota}
                          </div>
                        </div>
                      ):null}

                      <div className={ mostrarResultado == 1 ? (
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 1 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 5 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 6 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 9 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 11 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 12 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 15 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 17 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 20 ||
                          pregunta.respuestas.find(r=> r.idR == pregunta.idR4).validez == 24 ?
                         ('respuestaCorrecta'):('respuesta2')):('respuesta2')
                      }>
                        <letra> D </letra>
                        <i className="fas fa-angle-right"></i>
                        { mostrarResultado == 1 ?(
                          <div className="textRespuesta">
                            {ReactHtmlParser(pregunta.respuestas.find(r=> r.idR == pregunta.idR4).respuestaHtml)}
                          </div>
                        ):(
                          <div className="textRespuesta">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR4).respuesta}
                          </div>
                        )}
                      </div>
                      {mostrarResultado == 1 && pregunta && pregunta.respuestas && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != '' && pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota != undefined ? (
                        <div className='nota'>
                          <letra> Nota D) </letra>
                          <div className="textRespuestaNota">
                            {pregunta.respuestas.find(r=> r.idR == pregunta.idR4).nota}
                          </div>
                        </div>
                      ):null}


                    </div>
                  </div>

                </div>

              );

        }
 }

export default PreguntaJusticia;

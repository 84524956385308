// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isDefined } from '../../../../lib/utils/is'
//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Input2 from '../../../Inputs/Input2'


// Assets
import './css.css';
import TemaSeleted from '../..';
import swal from 'sweetalert';

class CambioLeyTemaAdm extends Component {
  static propTypes = {
    idTema: PropTypes.number.isRequired,
    temaIntervalos: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      infoTema: null,
      sincroActiva: true,
      navEdit: '0',
      leySelectedNesd: 0,
      TemaSeletedSincr: 0,
      temaIntervalosSave: JSON.parse(JSON.stringify(this.props.temaIntervalos)),
      actualizacionEstado: null,
      temasEnSincro: [],
      temaTestTeorico: [],
      temaTestTeoricoS: null,
      temaTestTeoricoALL: [],
      temaTestTeoricoALLS: [],
      temaTestPractico: [],
      temaTestPracticoS: null,
      temaEjerDesarrollo: [],
      temaEjerDesarrolloS: null,
      temaEjerDesarrolloALL: [],
      temaEjerDesarrolloALLS: []
    };
    this.onHandleClick = this.onHandleClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputOut = this.onChangeInputOut.bind(this);
    this.onChangeText = this.onChangeText.bind(this);

    this.onBlurText = this.onBlurText.bind(this);


  }


  onChangeText(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      // let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].notaPreparador = textHtml
      this.setState({ infoTema: infoTema })

    } else {
      let infoTema = this.state.infoTema

      infoTema[0].notaPreparador = ''
      this.setState({ infoTema: infoTema })
    }
  }

  async onBlurText(e, editor) {

    let infoTema = this.state.infoTema
    let infoTemaS = this.state.infoTemaS
    if (infoTemaS[0].notaPreparador != infoTema[0].notaPreparador && infoTemaS[0].notaPreparador != null) {
      // actualizamos en bd
      this.setState({ actualizacionEstado: 'subiendo' })
      let data = {
        notaPreparador: infoTema[0].notaPreparador,
        idTema: this.props.idTema
      }
      let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateNotaP'

      let response = await fetch(urlFetX, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      if (cls.affectedRows > 0) {

        if (this.state.sincroActiva != null) {
          // sincronia temas
          let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
            this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
          ) : (
            this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
              this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
            ) : ([])
          )

          for (let index = 0; index < temasFiltroPorOpo.length; index++) {

            let data2 = {
              notaPreparador: infoTema[0].notaPreparador,
              idTema: temasFiltroPorOpo[index].idTemaSincro
            }
            let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateNotaP'

            let response2 = await fetch(urlFetX2, {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;


          }

        }
      }

      infoTemaS[0].notaPreparador = infoTema[0].notaPreparador
      this.setState({ infoTemaS: infoTemaS })
      setTimeout(() => {
        this.setState({ actualizacionEstado: 'completado' })
        setTimeout(() => {
          this.setState({ actualizacionEstado: null })
        }, 1250);
      }, 400);
    }

  }

  async onChangeInputOut(e) {



    if (e.target.id.split('-')[0] == 'artInicio') {

      let value = e.target.value

      let locaLey = e.target.id.split('-')[1]
      let locaArt = e.target.id.split('-')[2]
      // actulizamos[locaLey].intervalosArt[locaArt].art_inicio

      if (value == '') {
        value = '-'
      }
      let actulizamos = this.state.temaIntervalosSave
      actulizamos[locaLey].intervalosArt[locaArt].art_inicio = value
      this.setState({ temaIntervalosSave: actulizamos })


      if (this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_inicio != this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio) {


        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          artInicio: this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_inicio,
          artFin: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
          artInicioSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
          artFinSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
          idTema: this.props.idTema,
          idLey: this.props.temaIntervalos[locaLey].idLey
        }

        let response = await fetch("https://oposiciones-justicia.es/api/temas/updteIntervaloInicio", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        if (cls.affectedRows > 0) {

          if (this.state.sincroActiva != null) {
            // sincronia temas
            let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
              this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
            ) : (
              this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
              ) : ([])
            )

            for (let index = 0; index < temasFiltroPorOpo.length; index++) {

              let data2 = {
                artInicio: this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_inicio,
                artFin: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
                artInicioSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
                artFinSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
                idTema: temasFiltroPorOpo[index].idTemaSincro,
                idLey: this.props.temaIntervalos[locaLey].idLey
              }
              let urlFet2 = "https://oposiciones-justicia.es/api/temas/updteIntervaloInicio"
              let response2 = await fetch(urlFet2, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

            }
          }

          this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio = value
        }


        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }

      //this.forceUpdate()

      //updteIntervaloInicio
      //update del intervalos exacto tanto inicio como fin
      // cuando se crea se genera directo en bd con - - info art ini y fin
      // api existe en temas /insertIntervalo
    } else if (e.target.id.split('-')[0] == 'artFin') {

      let value = e.target.value

      let locaLey = e.target.id.split('-')[1]
      let locaArt = e.target.id.split('-')[2]

      if (value == '') {
        value = '-'
      }
      let actulizamos = this.state.temaIntervalosSave
      actulizamos[locaLey].intervalosArt[locaArt].art_fin = value
      this.setState({ temaIntervalosSave: actulizamos })

      // actulizamos[locaLey].intervalosArt[locaArt].art_inicio
      if (this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_fin != this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin) {


        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          artInicio: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
          artFin: this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_fin,
          artInicioSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
          artFinSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
          idTema: this.props.idTema,
          idLey: this.props.temaIntervalos[locaLey].idLey
        }

        let response = await fetch("https://oposiciones-justicia.es/api/temas/updteIntervaloFin", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        if (cls.affectedRows > 0) {

          if (this.state.sincroActiva != null) {
            // sincronia temas
            let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
              this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
            ) : (
              this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
              ) : ([])
            )

            for (let index = 0; index < temasFiltroPorOpo.length; index++) {

              let data2 = {
                artInicio: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
                artFin: this.state.temaIntervalosSave[locaLey].intervalosArt[locaArt].art_fin,
                artInicioSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio,
                artFinSave: this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin,
                idTema: temasFiltroPorOpo[index].idTemaSincro,
                idLey: this.props.temaIntervalos[locaLey].idLey
              }
              let urlFet2 = "https://oposiciones-justicia.es/api/temas/updteIntervaloFin"
              let response2 = await fetch(urlFet2, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

            }
          }

          this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin = value
        }


        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }


    } else if (e.target.id.split('-')[0] == 'tema') {
      let loca = e.target.id.split('-')[1]



      if (this.state.temasEnSincro[loca].idTema1 != null && this.state.temasEnSincro[loca].idTema2 != null && (this.state.temasEnSincro[loca].idTemaSincro != null && this.state.temasEnSincro[loca].idTema2 != this.state.temasEnSincro[loca].idTemaSincro && this.state.temasEnSincro[loca].idTema1 != this.state.temasEnSincro[loca].idTemaSincro) || (this.state.temasEnSincro[loca].idTemaSincro == null && this.state.temasEnSincro[loca].idTema2 != null && this.state.temasEnSincro[loca].idTema1 != null)) {


        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          idTema1: this.state.temasEnSincro[loca].idTema1,
          idTema2: this.state.temasEnSincro[loca].idTema2,
          idTemaSyncr: this.state.temasEnSincro[loca].idTemaSincro
        }

        let urlFet = ""
        if (this.state.temasEnSincro[loca].idTemaSincro == null) {
          urlFet = "https://oposiciones-justicia.es/api/temas/insertSyncTemas"
        } else {
          urlFet = "https://oposiciones-justicia.es/api/temas/updteSyncTemas"
        }
        let response = await fetch(urlFet, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        if (cls.affectedRows > 0) {
          let temasEnSincro = this.state.temasEnSincro
          temasEnSincro[loca].idTemaSincro = this.props.idTema != this.state.temasEnSincro[loca].idTema1 ? (this.state.temasEnSincro[loca].idTema1) : (this.state.temasEnSincro[loca].idTema2)
          this.setState({ temasEnSincro: temasEnSincro })

          // if (this.state.sincroActiva != null) {
          //   // sincronia temas
          //   // añadir que la oposicion no sea la misma, es decir que el tema que stamos insertando no se vincule con los mismo de su oposicion
          //   for (let index = 0; index < this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro).length; index++) {

          //     let temaOtro = this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro)[index].idTema1
          //     let temaOtro2 = this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro)[index].idTema2

          //     let data2 = {
          //       idTema1: temaOtro != this.props.idTema ? (temaOtro):(temaOtro2),
          //       idTema2: temasEnSincro[loca].idTemaSincro,
          //       idTemaSyncr: temasEnSincro[loca].idTemaSincro
          //     }
          //     let response2 = await fetch(urlFet, {
          //       method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          //     });
          //     if (!response2.ok) {
          //       throw Error(response2.statusText);
          //     }
          //     let json2 = await response2.json();
          //     let cls2 = await json2;

          //   }
          // }

        }




        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }

    } else if (e.target.id.split('-')[0] == 'leyIntervalo') {
      let value = e.target.value

      let locaLey = this.state.leySelectedNesd
      // ver si existe ya esta ley si es nueva no actualizamos solo si existe, signifca que la cambiamos en todos los intervalos, si es nueva se genera cuando añadimos un articulo.
      if (this.state.temaIntervalosSave[locaLey] != undefined && this.props.temaIntervalos[locaLey] != undefined) {

        if (this.props.temaIntervalos[locaLey].idLey != null && this.state.temaIntervalosSave[locaLey].idLey != null && this.props.temaIntervalos[locaLey].intervalosArt.length > 0 && (this.props.temaIntervalos[locaLey].idLey != this.state.temaIntervalosSave[locaLey].idLey)) {
          // ACTUALIZAMOS TODOS LOS INTERVALOS
          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            idLey: this.state.temaIntervalosSave[locaLey].idLey,
            idLeySave: this.props.temaIntervalos[locaLey].idLey,
            idTema: this.props.idTema
          }

          let urlFet = "https://oposiciones-justicia.es/api/temas/updateIntervaloAllLey"
          let response = await fetch(urlFet, {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          if (cls.affectedRows > 0) {
            if (this.state.sincroActiva != null) {
              // sincronia temas
              let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
                this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
              ) : (
                this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                  this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
                ) : ([])
              )

              for (let index = 0; index < temasFiltroPorOpo.length; index++) {

                let data2 = {
                  idLey: this.state.temaIntervalosSave[locaLey].idLey,
                  idLeySave: this.props.temaIntervalos[locaLey].idLey,
                  idTema: temasFiltroPorOpo[index].idTemaSincro
                }
                let urlFet2 = "https://oposiciones-justicia.es/api/temas/updateIntervaloAllLey"
                let response2 = await fetch(urlFet2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

              }
            }

            let temaIntervalosSave = this.state.temaIntervalosSave

            this.props.temaIntervalos[locaLey].idLey = temaIntervalosSave[locaLey].idLey
            this.props.temaIntervalos[locaLey].linkLey = temaIntervalosSave[locaLey].linkLey
            this.props.temaIntervalos[locaLey].nombreLey = temaIntervalosSave[locaLey].nombreLey
            this.props.temaIntervalos[locaLey].siglasLey = temaIntervalosSave[locaLey].siglasLey


            for (let j = 0; j < this.props.temaIntervalos[locaLey].intervalosArt.length; j++) {
              this.props.temaIntervalos[locaLey].intervalosArt[j].id_ley = this.state.temaIntervalosSave[locaLey].idLey
            }

            for (let kk = 0; kk < temaIntervalosSave[locaLey].intervalosArt.length; kk++) {
              temaIntervalosSave[locaLey].intervalosArt[kk].id_ley = this.state.temaIntervalosSave[locaLey].idLey
            }


            this.setState({ temaIntervalosSave: temaIntervalosSave })
          }

          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);
        }


      }
    } else if (e.target.id.split('-')[0] == 'video') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      let videoTema = this.state.videoTema

      videoTema[loca].titulo = value
      this.setState({ videoTema: videoTema })

      let videoTemaSave = this.state.videoTemaSave
      let videoTemas = this.state.videoTemas
      let videoTemasSave = this.state.videoTemasSave

      if (value != '') {
        let objetVIdeos = this.state.allVideoTemas.find(v => v.titulo == value)

        if (objetVIdeos != undefined) {
          videoTema[loca].titulo = await objetVIdeos.titulo
          videoTema[loca].link = await objetVIdeos.link
          videoTema[loca].id_tema = await this.props.idTema
          videoTema[loca].id_video = await objetVIdeos.id_video

          this.setState({ videoTema: videoTema })

          if (videoTemaSave[loca] != undefined) {
            // row video ya existe update!
            if (
              videoTemaSave[loca].id_video != objetVIdeos.id_video
              &&
              videoTemaSave.filter(v => v.id_video == objetVIdeos.id_video && v.id_tema == this.props.idTema).length == 0
            ) {
              this.setState({ actualizacionEstado: 'subiendo' })
              //update 
              let data = {
                titulo: objetVIdeos.titulo,
                link: objetVIdeos.link,
                id_tema: this.props.idTema,
                id_video: objetVIdeos.id_video,
                id_videoSave: videoTemaSave[loca].id_video
              }
              let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateVideoTemas'

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;
              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //videoTemas

                    let videoBusqudaAntes = videoTemas.find(v => v.id_video == videoTemaSave[loca].id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)
                    let videoBusquedaNuevo = videoTemas.find(v => v.id_video == objetVIdeos.id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusqudaAntes != undefined) {
                      //update
                      if (videoBusquedaNuevo == undefined) {
                        let data2 = {
                          titulo: objetVIdeos.titulo,
                          link: objetVIdeos.link,
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_video: objetVIdeos.id_video,
                          id_videoSave: videoTemaSave[loca].id_video
                        }
                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateVideoTemas'

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {
                          let lovaFC = videoTemas.findIndex(v => v.id_video == videoTemaSave[loca].id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)
                          if (lovaFC != -1) {
                            videoTemas[lovaFC].titulo = objetVIdeos.titulo
                            videoTemas[lovaFC].link = objetVIdeos.link
                            videoTemas[lovaFC].id_tema = this.state.temasEnSincro[index].idTemaSincro
                            videoTemas[lovaFC].id_video = objetVIdeos.id_video
                            let lovaFC2 = videoTemasSave.findIndex(v => v.id_video == videoTemaSave[loca].id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)
                            if (lovaFC2 != -1) {
                              videoTemasSave[lovaFC2].titulo = objetVIdeos.titulo
                              videoTemasSave[lovaFC2].link = objetVIdeos.link
                              videoTemasSave[lovaFC2].id_tema = this.state.temasEnSincro[index].idTemaSincro
                              videoTemasSave[lovaFC2].id_video = objetVIdeos.id_video
                            }


                            this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemasSave })
                          }
                        }


                      } else {
                        // video ya existe 
                      }
                    } else {
                      //insert
                      if (videoBusquedaNuevo == undefined) {
                        let data2 = {
                          titulo: objetVIdeos.titulo,
                          link: objetVIdeos.link,
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_video: objetVIdeos.id_video,
                          id_videoSave: null
                        }
                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertVideoTemas'

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {

                          videoTemas.push({
                            titulo: objetVIdeos.titulo,
                            link: objetVIdeos.link,
                            id_tema: this.state.temasEnSincro[index].idTemaSincro,
                            id_video: objetVIdeos.id_video,
                          })
                          videoTemasSave.push({
                            titulo: objetVIdeos.titulo,
                            link: objetVIdeos.link,
                            id_tema: this.state.temasEnSincro[index].idTemaSincro,
                            id_video: objetVIdeos.id_video,
                          })


                          this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemasSave })

                        }

                      } else {
                        // video ya existe 
                      }
                    }
                  }
                }
                // let videoTemas = this.state.videoTemas
                // videoTemas.splice(locaVid, 1);
                // this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemas })



                videoTema[loca].titulo = objetVIdeos.titulo
                videoTema[loca].link = objetVIdeos.link
                videoTema[loca].id_tema = this.props.idTema
                videoTema[loca].id_video = objetVIdeos.id_video

                videoTemaSave[loca].titulo = objetVIdeos.titulo
                videoTemaSave[loca].link = objetVIdeos.link
                videoTemaSave[loca].id_tema = this.props.idTema
                videoTemaSave[loca].id_video = objetVIdeos.id_video

                this.setState({ videoTema: videoTema, videoTemaSave: videoTemaSave })
              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);

            } else {
              // El video ya lo tiene disponible este tema!

              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }


          } else {
            // video nuevo
            let videoBusqudaD34 = videoTemaSave.find(v => v.id_video == objetVIdeos.id_video && v.id_tema == this.props.idTema)

            if (videoBusqudaD34 == undefined) {
              this.setState({ actualizacionEstado: 'subiendo' })
              let data = {
                titulo: objetVIdeos.titulo,
                link: objetVIdeos.link,
                id_tema: this.props.idTema,
                id_video: objetVIdeos.id_video,
                id_videoSave: null
              }
              let urlFetX = 'https://oposiciones-justicia.es/api/temas/insertVideoTemas'

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //videoTemas objetVIdeos.id_video

                    let videoBusquedaExiste = videoTemas.find(v => v.id_video == objetVIdeos.id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste == undefined) {
                      //insert
                      let data2 = {
                        titulo: objetVIdeos.titulo,
                        link: objetVIdeos.link,
                        id_tema: this.state.temasEnSincro[index].idTemaSincro,
                        id_video: objetVIdeos.id_video,
                        id_videoSave: null
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertVideoTemas'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      if (cls2.affectedRows > 0) {

                        videoTemas.push({
                          titulo: objetVIdeos.titulo,
                          link: objetVIdeos.link,
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_video: objetVIdeos.id_video,
                        })
                        videoTemasSave.push({
                          titulo: objetVIdeos.titulo,
                          link: objetVIdeos.link,
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_video: objetVIdeos.id_video,
                        })


                        this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemasSave })

                      }
                    }
                  }
                }


                // videoTema.push({
                //   titulo: objetVIdeos.titulo,
                //   link: objetVIdeos.link,
                //   id_tema: this.props.idTema,
                //   id_video: objetVIdeos.id_video,
                // })
                videoTemaSave.push({
                  titulo: objetVIdeos.titulo,
                  link: objetVIdeos.link,
                  id_tema: this.props.idTema,
                  id_video: objetVIdeos.id_video,
                })


                videoTemas.push({
                  titulo: objetVIdeos.titulo,
                  link: objetVIdeos.link,
                  id_tema: this.props.idTema,
                  id_video: objetVIdeos.id_video,
                })
                videoTemasSave.push({
                  titulo: objetVIdeos.titulo,
                  link: objetVIdeos.link,
                  id_tema: this.props.idTema,
                  id_video: objetVIdeos.id_video,
                })

                this.setState({ videoTemaSave: videoTemaSave, videoTemas: videoTemas, videoTemasSave: videoTemasSave })

              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);






            } else {
              // YA EXISTE EL VIDEO
              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }

          }

        }
      }



    } else if (e.target.id.split('-')[0] == 'testTeorico') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      let temaTestTeorico = this.state.temaTestTeorico

      temaTestTeorico[loca].nombre_test = value
      this.setState({ temaTestTeorico: temaTestTeorico })

      let temaTestTeoricoS = this.state.temaTestTeoricoS

      let temaTestTeoricoALL = this.state.temaTestTeoricoALL
      let temaTestTeoricoALLS = this.state.temaTestTeoricoALLS

      if (value != '') {
        let objetVIdeos = this.state.testTeoricoOpo.find(v => v.nombre_test == value)

        if (objetVIdeos != undefined) {

          temaTestTeorico[loca].idTest = await objetVIdeos.id_test
          temaTestTeorico[loca].id_test = await objetVIdeos.id_test
          temaTestTeorico[loca].idTema = await this.props.idTema
          temaTestTeorico[loca].nombre_test = await objetVIdeos.nombre_test
          temaTestTeorico[loca].id_oposicion = await objetVIdeos.id_oposicion

          this.setState({ temaTestTeorico: temaTestTeorico })

          if (temaTestTeoricoS[loca] != undefined) {
            // row video ya existe update!
            if (
              temaTestTeoricoS[loca].idTest != objetVIdeos.id_test
              &&
              temaTestTeoricoS.filter(v => v.idTest == objetVIdeos.id_test && v.idTema == this.props.idTema).length == 0
            ) {
              this.setState({ actualizacionEstado: 'subiendo' })
              //update 
              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id_test,
                id_testSave: temaTestTeoricoS[loca].idTest
              }
              let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTestTeoricoTemas' // updateVideoTemas

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;
              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestTeoricoALL

                    let videoBusqudaAntes = temaTestTeoricoALL.find(v => v.idTest == temaTestTeoricoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                    let videoBusquedaNuevo = temaTestTeoricoALL.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusqudaAntes != undefined) {
                      //update
                      if (videoBusquedaNuevo == undefined) {
                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id_test,
                          id_testSave: temaTestTeoricoS[loca].idTest
                        }

                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateTestTeoricoTemas'

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {
                          let lovaFC = temaTestTeoricoALL.findIndex(v => v.idTest == temaTestTeoricoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                          if (lovaFC != -1) {

                            temaTestTeoricoALL[lovaFC].idTest = await objetVIdeos.id_test
                            temaTestTeoricoALL[lovaFC].id_test = await objetVIdeos.id_test
                            temaTestTeoricoALL[lovaFC].idTema = await this.state.temasEnSincro[index].idTemaSincro
                            temaTestTeoricoALL[lovaFC].nombre_test = await objetVIdeos.nombre_test
                            temaTestTeoricoALL[lovaFC].id_oposicion = await objetVIdeos.id_oposicion


                            let lovaFC2 = temaTestTeoricoALLS.findIndex(v => v.idTest == temaTestTeoricoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                            if (lovaFC2 != -1) {
                              temaTestTeoricoALLS[lovaFC2].idTest = await objetVIdeos.id_test
                              temaTestTeoricoALLS[lovaFC2].id_test = await objetVIdeos.id_test
                              temaTestTeoricoALLS[lovaFC2].idTema = await this.state.temasEnSincro[index].idTemaSincro
                              temaTestTeoricoALLS[lovaFC2].nombre_test = await objetVIdeos.nombre_test
                              temaTestTeoricoALLS[lovaFC2].id_oposicion = await objetVIdeos.id_oposicion
                            }


                            this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALLS })
                          }
                        }


                      } else {
                        // video ya existe 
                      }
                    } else {
                      //insert
                      if (videoBusquedaNuevo == undefined) {

                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id_test,
                          id_testSave: null
                        }
                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertTestTeoriaTemas' // insertVideoTemas

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {

                          temaTestTeoricoALL.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id_test,
                            id_oposicion: objetVIdeos.id_oposicion,
                            id_test: objetVIdeos.id_test,
                            nombre_test: objetVIdeos.nombre_test
                          })
                          temaTestTeoricoALLS.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id_test,
                            id_oposicion: objetVIdeos.id_oposicion,
                            id_test: objetVIdeos.id_test,
                            nombre_test: objetVIdeos.nombre_test
                          })


                          this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALLS })

                        }

                      } else {
                        // video ya existe 
                      }
                    }
                  }
                }
                // let temaTestTeoricoALL = this.state.temaTestTeoricoALL
                // temaTestTeoricoALL.splice(locaVid, 1);
                // this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALL })

                temaTestTeorico[loca].idTest = await objetVIdeos.id_test
                temaTestTeorico[loca].id_test = await objetVIdeos.id_test
                temaTestTeorico[loca].idTema = await this.props.idTema
                temaTestTeorico[loca].nombre_test = await objetVIdeos.nombre_test
                temaTestTeorico[loca].id_oposicion = await objetVIdeos.id_oposicion


                temaTestTeoricoS[loca].idTest = await objetVIdeos.id_test
                temaTestTeoricoS[loca].id_test = await objetVIdeos.id_test
                temaTestTeoricoS[loca].idTema = await this.props.idTema
                temaTestTeoricoS[loca].nombre_test = await objetVIdeos.nombre_test
                temaTestTeoricoS[loca].id_oposicion = await objetVIdeos.id_oposicion

                this.setState({ temaTestTeorico: temaTestTeorico, temaTestTeoricoS: temaTestTeoricoS })
              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);

            } else {
              // El video ya lo tiene disponible este tema!

              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }


          } else {
            // video nuevo
            let videoBusqudaD34 = temaTestTeoricoS.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.props.idTema)

            if (videoBusqudaD34 == undefined) {
              this.setState({ actualizacionEstado: 'subiendo' })

              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id_test,
                id_testSave: null
              }

              let urlFetX = 'https://oposiciones-justicia.es/api/temas/insertTestTeoriaTemas'

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestTeoricoALL objetVIdeos.id_video

                    let videoBusquedaExiste = temaTestTeoricoALL.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste == undefined) {
                      //insert

                      let data2 = {
                        id_tema: this.state.temasEnSincro[index].idTemaSincro,
                        id_test: objetVIdeos.id_test,
                        id_testSave: null
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertTestTeoriaTemas'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      if (cls2.affectedRows > 0) {

                        temaTestTeoricoALL.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id_test,
                          id_oposicion: objetVIdeos.id_oposicion,
                          id_test: objetVIdeos.id_test,
                          nombre_test: objetVIdeos.nombre_test
                        })
                        temaTestTeoricoALLS.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id_test,
                          id_oposicion: objetVIdeos.id_oposicion,
                          id_test: objetVIdeos.id_test,
                          nombre_test: objetVIdeos.nombre_test
                        })


                        this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALLS })

                      }
                    }
                  }
                }


                // videoTema.push({
                //   titulo: objetVIdeos.titulo,
                //   link: objetVIdeos.link,
                //   id_tema: this.props.idTema,
                //   id_video: objetVIdeos.id_video,
                // })
                await temaTestTeoricoS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })

                await temaTestTeoricoALL.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })
                await temaTestTeoricoALLS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })

                this.setState({ temaTestTeoricoS: temaTestTeoricoS, temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALLS })

              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);






            } else {
              // YA EXISTE EL VIDEO
              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }

          }

        }
      }



    } else if (e.target.id.split('-')[0] == 'testTeoricoVuelta') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      if (value != '') {
        let temaTestTeorico = this.state.temaTestTeorico

        temaTestTeorico[loca].vuelta = value == '' ? '' : parseInt(value)
        this.setState({ temaTestTeorico: temaTestTeorico })

        let temaTestTeoricoS = this.state.temaTestTeoricoS

        let temaTestTeoricoALL = this.state.temaTestTeoricoALL
        let temaTestTeoricoALLS = this.state.temaTestTeoricoALLS


        // actulizamos[locaLey].intervalosArt[locaArt].art_inicio
        if (parseInt(temaTestTeoricoS[loca].vuelta) != parseInt(temaTestTeorico[loca].vuelta)) {


          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            vuelta: temaTestTeorico[loca].vuelta,
            idTema: this.props.idTema,
            idTest: temaTestTeorico[loca].idTest
          }

          let response = await fetch("https://oposiciones-justicia.es/api/temas/updteVueltaTestTeoTemas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          if (cls.affectedRows > 0) {

            if (this.state.sincroActiva != null) {
              // sincronia temas
              for (let index = 0; index < this.state.temasEnSincro.length; index++) {

                let data2 = {
                  vuelta: temaTestTeorico[loca].vuelta,
                  idTema: this.state.temasEnSincro[index].idTemaSincro,
                  idTest: temaTestTeorico[loca].idTest
                }
                let urlFet2 = "https://oposiciones-justicia.es/api/temas/updteVueltaTestTeoTemas"
                let response2 = await fetch(urlFet2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                if (cls2.affectedRows > 0) {
                  let locaALL2 = temaTestTeoricoALL.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaTestTeorico[loca].idTest)
                  if (locaALL != -1) {
                    temaTestTeoricoALL[locaALL2].vuelta = temaTestTeorico[loca].vuelta
                  }
                  let locaALLs2 = temaTestTeoricoALLS.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaTestTeorico[loca].idTest)
                  if (locaALL != -1) {
                    temaTestTeoricoALLS[locaALLs2].vuelta = temaTestTeorico[loca].vuelta
                  }
                  this.setState({
                    temaTestTeoricoALL: temaTestTeoricoALL,
                    temaTestTeoricoALLS: temaTestTeoricoALLS
                  })
                }

              }
            }

            //this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin = value

            // actualizamos ALL sincronia


          }

          // ACTUALIZAMOS save + all del tema actual
          temaTestTeoricoS[loca].vuelta = temaTestTeorico[loca].vuelta
          let locaALL = temaTestTeoricoALL.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaTestTeorico[loca].idTest)
          if (locaALL != -1) {
            temaTestTeoricoALL[locaALL].vuelta = temaTestTeorico[loca].vuelta
          }
          let locaALLs = temaTestTeoricoALLS.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaTestTeorico[loca].idTest)
          if (locaALL != -1) {
            temaTestTeoricoALLS[locaALLs].vuelta = temaTestTeorico[loca].vuelta
          }


          this.setState({
            temaTestTeoricoS: temaTestTeoricoS,
            temaTestTeoricoALL: temaTestTeoricoALL,
            temaTestTeoricoALLS: temaTestTeoricoALLS
          })

          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);
        }


      }

    } else if (e.target.id.split('-')[0] == 'testPractico') {

      // temaTestTeorico videoTema
      // temaTestTeoricoS videoTemaSave
      // temaTestTeoricoALL videoTemas
      // temaTestTeoricoALLS videoTemasSave

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      let temaTestPractico = this.state.temaTestPractico

      temaTestPractico[loca].nombre_test = value
      this.setState({ temaTestPractico: temaTestPractico })

      let temaTestPracticoS = this.state.temaTestPracticoS

      let temaTestPracticoALL = this.state.temaTestPracticoALL
      let temaTestPracticoALLS = this.state.temaTestPracticoALLS


      if (value != '') {
        let objetVIdeos = this.state.testPracticoOpo.find(v => v.nombre_test == value)

        if (objetVIdeos != undefined) {

          temaTestPractico[loca].idTest = await objetVIdeos.id_test
          temaTestPractico[loca].id_test = await objetVIdeos.id_test
          temaTestPractico[loca].idTema = await this.props.idTema
          temaTestPractico[loca].nombre_test = await objetVIdeos.nombre_test
          temaTestPractico[loca].id_oposicion = await objetVIdeos.id_oposicion

          this.setState({ temaTestPractico: temaTestPractico })

          if (temaTestPracticoS[loca] != undefined) {
            // row video ya existe update!
            if (
              temaTestPracticoS[loca].idTest != objetVIdeos.id_test
              &&
              temaTestPracticoS.filter(v => v.idTest == objetVIdeos.id_test && v.idTema == this.props.idTema).length == 0
            ) {
              this.setState({ actualizacionEstado: 'subiendo' })
              //update 
              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id_test,
                id_testSave: temaTestPracticoS[loca].idTest
              }
              let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTestPracticoTemas' // updateVideoTemas

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;
              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestPracticoALL

                    let videoBusqudaAntes = temaTestPracticoALL.find(v => v.idTest == temaTestPracticoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                    let videoBusquedaNuevo = temaTestPracticoALL.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusqudaAntes != undefined) {
                      //update
                      if (videoBusquedaNuevo == undefined) {
                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id_test,
                          id_testSave: temaTestPracticoS[loca].idTest
                        }

                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateTestPracticoTemas'

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {
                          let lovaFC = temaTestPracticoALL.findIndex(v => v.idTest == temaTestPracticoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                          if (lovaFC != -1) {

                            temaTestPracticoALL[lovaFC].idTest = await objetVIdeos.id_test
                            temaTestPracticoALL[lovaFC].id_test = await objetVIdeos.id_test
                            temaTestPracticoALL[lovaFC].idTema = await this.state.temasEnSincro[index].idTemaSincro
                            temaTestPracticoALL[lovaFC].nombre_test = await objetVIdeos.nombre_test
                            temaTestPracticoALL[lovaFC].id_oposicion = await objetVIdeos.id_oposicion


                            let lovaFC2 = temaTestPracticoALLS.findIndex(v => v.idTest == temaTestPracticoS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                            if (lovaFC2 != -1) {
                              temaTestPracticoALLS[lovaFC2].idTest = await objetVIdeos.id_test
                              temaTestPracticoALLS[lovaFC2].id_test = await objetVIdeos.id_test
                              temaTestPracticoALLS[lovaFC2].idTema = await this.state.temasEnSincro[index].idTemaSincro
                              temaTestPracticoALLS[lovaFC2].nombre_test = await objetVIdeos.nombre_test
                              temaTestPracticoALLS[lovaFC2].id_oposicion = await objetVIdeos.id_oposicion
                            }


                            this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALLS })
                          }
                        }


                      } else {
                        // video ya existe 
                      }
                    } else {
                      //insert
                      if (videoBusquedaNuevo == undefined) {

                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id_test,
                          id_testSave: null
                        }
                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertTestPracticoTemas' // insertVideoTemas

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {

                          temaTestPracticoALL.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id_test,
                            id_oposicion: objetVIdeos.id_oposicion,
                            id_test: objetVIdeos.id_test,
                            nombre_test: objetVIdeos.nombre_test
                          })
                          temaTestPracticoALLS.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id_test,
                            id_oposicion: objetVIdeos.id_oposicion,
                            id_test: objetVIdeos.id_test,
                            nombre_test: objetVIdeos.nombre_test
                          })


                          this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALLS })

                        }

                      } else {
                        // video ya existe 
                      }
                    }
                  }
                }
                // let temaTestPracticoALL = this.state.temaTestPracticoALL
                // temaTestPracticoALL.splice(locaVid, 1);
                // this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALL })

                temaTestPractico[loca].idTest = await objetVIdeos.id_test
                temaTestPractico[loca].id_test = await objetVIdeos.id_test
                temaTestPractico[loca].idTema = await this.props.idTema
                temaTestPractico[loca].nombre_test = await objetVIdeos.nombre_test
                temaTestPractico[loca].id_oposicion = await objetVIdeos.id_oposicion


                temaTestPracticoS[loca].idTest = await objetVIdeos.id_test
                temaTestPracticoS[loca].id_test = await objetVIdeos.id_test
                temaTestPracticoS[loca].idTema = await this.props.idTema
                temaTestPracticoS[loca].nombre_test = await objetVIdeos.nombre_test
                temaTestPracticoS[loca].id_oposicion = await objetVIdeos.id_oposicion

                this.setState({ temaTestPractico: temaTestPractico, temaTestPracticoS: temaTestPracticoS })
              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);

            } else {
              // El video ya lo tiene disponible este tema!

              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }


          } else {
            // video nuevo
            let videoBusqudaD34 = temaTestPracticoS.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.props.idTema)

            if (videoBusqudaD34 == undefined) {
              this.setState({ actualizacionEstado: 'subiendo' })

              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id_test,
                id_testSave: null
              }

              let urlFetX = 'https://oposiciones-justicia.es/api/temas/insertTestPracticoTemas'

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestPracticoALL objetVIdeos.id_video

                    let videoBusquedaExiste = temaTestPracticoALL.find(v => v.idTest == objetVIdeos.id_test && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste == undefined) {
                      //insert

                      let data2 = {
                        id_tema: this.state.temasEnSincro[index].idTemaSincro,
                        id_test: objetVIdeos.id_test,
                        id_testSave: null
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertTestPracticoTemas'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      if (cls2.affectedRows > 0) {

                        temaTestPracticoALL.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id_test,
                          id_oposicion: objetVIdeos.id_oposicion,
                          id_test: objetVIdeos.id_test,
                          nombre_test: objetVIdeos.nombre_test
                        })
                        temaTestPracticoALLS.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id_test,
                          id_oposicion: objetVIdeos.id_oposicion,
                          id_test: objetVIdeos.id_test,
                          nombre_test: objetVIdeos.nombre_test
                        })


                        this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALLS })

                      }
                    }
                  }
                }


                // videoTema.push({
                //   titulo: objetVIdeos.titulo,
                //   link: objetVIdeos.link,
                //   id_tema: this.props.idTema,
                //   id_video: objetVIdeos.id_video,
                // })
                await temaTestPracticoS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })

                await temaTestPracticoALL.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })
                await temaTestPracticoALLS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id_test,
                  id_oposicion: objetVIdeos.id_oposicion,
                  id_test: objetVIdeos.id_test,
                  nombre_test: objetVIdeos.nombre_test
                })

                this.setState({ temaTestPracticoS: temaTestPracticoS, temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALLS })

              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);






            } else {
              // YA EXISTE EL VIDEO
              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }

          }

        }
      }



    } else if (e.target.id.split('-')[0] == 'testPracticoVuelta') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      if (value != '') {
        let temaTestPractico = this.state.temaTestPractico

        temaTestPractico[loca].vuelta = value == '' ? '' : parseInt(value)
        this.setState({ temaTestPractico: temaTestPractico })

        let temaTestPracticoS = this.state.temaTestPracticoS

        let temaTestPracticoALL = this.state.temaTestPracticoALL
        let temaTestPracticoALLS = this.state.temaTestPracticoALLS


        // actulizamos[locaLey].intervalosArt[locaArt].art_inicio
        if (parseInt(temaTestPracticoS[loca].vuelta) != parseInt(temaTestPractico[loca].vuelta)) {


          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            vuelta: temaTestPractico[loca].vuelta,
            idTema: this.props.idTema,
            idTest: temaTestPractico[loca].idTest
          }

          let response = await fetch("https://oposiciones-justicia.es/api/temas/updteVueltaTestPraTemas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          if (cls.affectedRows > 0) {

            if (this.state.sincroActiva != null) {
              // sincronia temas
              for (let index = 0; index < this.state.temasEnSincro.length; index++) {

                let data2 = {
                  vuelta: temaTestPractico[loca].vuelta,
                  idTema: this.state.temasEnSincro[index].idTemaSincro,
                  idTest: temaTestPractico[loca].idTest
                }
                let urlFet2 = "https://oposiciones-justicia.es/api/temas/updteVueltaTestPraTemas"
                let response2 = await fetch(urlFet2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                if (cls2.affectedRows > 0) {
                  let locaALL2 = temaTestPracticoALL.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaTestPractico[loca].idTest)
                  if (locaALL != -1) {
                    temaTestPracticoALL[locaALL2].vuelta = temaTestPractico[loca].vuelta
                  }
                  let locaALLs2 = temaTestPracticoALLS.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaTestPractico[loca].idTest)
                  if (locaALL != -1) {
                    temaTestPracticoALLS[locaALLs2].vuelta = temaTestPractico[loca].vuelta
                  }
                  this.setState({
                    temaTestPracticoALL: temaTestPracticoALL,
                    temaTestPracticoALLS: temaTestPracticoALLS
                  })
                }

              }
            }

            //this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin = value

            // actualizamos ALL sincronia


          }

          // ACTUALIZAMOS save + all del tema actual
          temaTestPracticoS[loca].vuelta = temaTestPractico[loca].vuelta
          let locaALL = temaTestPracticoALL.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaTestPractico[loca].idTest)
          if (locaALL != -1) {
            temaTestPracticoALL[locaALL].vuelta = temaTestPractico[loca].vuelta
          }
          let locaALLs = temaTestPracticoALLS.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaTestPractico[loca].idTest)
          if (locaALL != -1) {
            temaTestPracticoALLS[locaALLs].vuelta = temaTestPractico[loca].vuelta
          }


          this.setState({
            temaTestPracticoS: temaTestPracticoS,
            temaTestPracticoALL: temaTestPracticoALL,
            temaTestPracticoALLS: temaTestPracticoALLS
          })

          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);
        }


      }

    } else if (e.target.id.split('-')[0] == 'ejerDesarrollo') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      let temaEjerDesarrollo = this.state.temaEjerDesarrollo

      temaEjerDesarrollo[loca].nombre = value
      this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })

      let temaEjerDesarrolloS = this.state.temaEjerDesarrolloS

      let temaEjerDesarrolloALL = this.state.temaEjerDesarrolloALL
      let temaEjerDesarrolloALLS = this.state.temaEjerDesarrolloALLS

      if (value != '') {
        let objetVIdeos = this.state.ejerDesarrollo.find(v => v.nombre == value)

        if (objetVIdeos != undefined) {

          temaEjerDesarrollo[loca].idTest = await objetVIdeos.id
          temaEjerDesarrollo[loca].id_test = await objetVIdeos.id
          temaEjerDesarrollo[loca].idTema = await this.props.idTema
          temaEjerDesarrollo[loca].nombre = await objetVIdeos.nombre

          this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })

          if (temaEjerDesarrolloS[loca] != undefined) {
            // row video ya existe update!
            if (
              temaEjerDesarrolloS[loca].idTest != objetVIdeos.id
              &&
              temaEjerDesarrolloS.filter(v => v.idTest == objetVIdeos.id && v.idTema == this.props.idTema).length == 0
            ) {
              this.setState({ actualizacionEstado: 'subiendo' })
              //update 
              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id,
                id_testSave: temaEjerDesarrolloS[loca].idTest
              }
              let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateEjerDesarrolloTemas' // updateVideoTemas

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;
              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaEjerDesarrolloALL

                    let videoBusqudaAntes = temaEjerDesarrolloALL.find(v => v.idTest == temaEjerDesarrolloS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                    let videoBusquedaNuevo = temaEjerDesarrolloALL.find(v => v.idTest == objetVIdeos.id && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusqudaAntes != undefined) {
                      //update
                      if (videoBusquedaNuevo == undefined) {
                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id,
                          id_testSave: temaEjerDesarrolloS[loca].idTest
                        }

                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateEjerDesarrolloTemas'

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {
                          let lovaFC = temaEjerDesarrolloALL.findIndex(v => v.idTest == temaEjerDesarrolloS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                          if (lovaFC != -1) {

                            temaEjerDesarrolloALL[lovaFC].idTest = await objetVIdeos.id
                            temaEjerDesarrolloALL[lovaFC].id_test = await objetVIdeos.id
                            temaEjerDesarrolloALL[lovaFC].idTema = await this.state.temasEnSincro[index].idTemaSincro
                            temaEjerDesarrolloALL[lovaFC].nombre = await objetVIdeos.nombre


                            let lovaFC2 = temaEjerDesarrolloALLS.findIndex(v => v.idTest == temaEjerDesarrolloS[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                            if (lovaFC2 != -1) {
                              temaEjerDesarrolloALLS[lovaFC2].idTest = await objetVIdeos.id
                              temaEjerDesarrolloALLS[lovaFC2].id_test = await objetVIdeos.id
                              temaEjerDesarrolloALLS[lovaFC2].idTema = await this.state.temasEnSincro[index].idTemaSincro
                              temaEjerDesarrolloALLS[lovaFC2].nombre = await objetVIdeos.nombre
                            }


                            this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALLS })
                          }
                        }


                      } else {
                        // video ya existe 
                      }
                    } else {
                      //insert
                      if (videoBusquedaNuevo == undefined) {

                        let data2 = {
                          id_tema: this.state.temasEnSincro[index].idTemaSincro,
                          id_test: objetVIdeos.id,
                          id_testSave: null
                        }
                        let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertEjerDesarrolloTemas' // insertVideoTemas

                        let response2 = await fetch(urlFetX2, {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                        });
                        if (!response2.ok) {
                          throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();
                        let cls2 = await json2;
                        if (cls2.affectedRows > 0) {

                          temaEjerDesarrolloALL.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id,
                            id_test: objetVIdeos.id,
                            nombre: objetVIdeos.nombre
                          })
                          temaEjerDesarrolloALLS.push({
                            idTema: this.state.temasEnSincro[index].idTemaSincro,
                            idTest: objetVIdeos.id,
                            id_test: objetVIdeos.id,
                            nombre: objetVIdeos.nombre
                          })


                          this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALLS })

                        }

                      } else {
                        // video ya existe 
                      }
                    }
                  }
                }
                // let temaEjerDesarrolloALL = this.state.temaEjerDesarrolloALL
                // temaEjerDesarrolloALL.splice(locaVid, 1);
                // this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALL })

                temaEjerDesarrollo[loca].idTest = await objetVIdeos.id
                temaEjerDesarrollo[loca].id_test = await objetVIdeos.id
                temaEjerDesarrollo[loca].idTema = await this.props.idTema
                temaEjerDesarrollo[loca].nombre = await objetVIdeos.nombre


                temaEjerDesarrolloS[loca].idTest = await objetVIdeos.id
                temaEjerDesarrolloS[loca].id_test = await objetVIdeos.id
                temaEjerDesarrolloS[loca].idTema = await this.props.idTema
                temaEjerDesarrolloS[loca].nombre = await objetVIdeos.nombre

                this.setState({ temaEjerDesarrollo: temaEjerDesarrollo, temaEjerDesarrolloS: temaEjerDesarrolloS })
              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);

            } else {
              // El video ya lo tiene disponible este tema!

              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }


          } else {
            // video nuevo
            let videoBusqudaD34 = temaEjerDesarrolloS.find(v => v.idTest == objetVIdeos.id && v.idTema == this.props.idTema)

            if (videoBusqudaD34 == undefined) {
              this.setState({ actualizacionEstado: 'subiendo' })

              let data = {
                id_tema: this.props.idTema,
                id_test: objetVIdeos.id,
                id_testSave: null
              }

              let urlFetX = 'https://oposiciones-justicia.es/api/temas/insertEjerDesarrolloTemas'

              let response = await fetch(urlFetX, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {

                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaEjerDesarrolloALL objetVIdeos.id_video

                    let videoBusquedaExiste = temaEjerDesarrolloALL.find(v => v.idTest == objetVIdeos.id && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste == undefined) {
                      //insert

                      let data2 = {
                        id_tema: this.state.temasEnSincro[index].idTemaSincro,
                        id_test: objetVIdeos.id,
                        id_testSave: null
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/insertEjerDesarrolloTemas'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      if (cls2.affectedRows > 0) {

                        temaEjerDesarrolloALL.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id,
                          id_test: objetVIdeos.id,
                          nombre: objetVIdeos.nombre
                        })
                        temaEjerDesarrolloALLS.push({
                          idTema: this.state.temasEnSincro[index].idTemaSincro,
                          idTest: objetVIdeos.id,
                          id_test: objetVIdeos.id,
                          nombre: objetVIdeos.nombre
                        })


                        this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALLS })

                      }
                    }
                  }
                }


                // videoTema.push({
                //   titulo: objetVIdeos.titulo,
                //   link: objetVIdeos.link,
                //   id_tema: this.props.idTema,
                //   id_video: objetVIdeos.id_video,
                // })
                await temaEjerDesarrolloS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id,
                  id_test: objetVIdeos.id,
                  nombre: objetVIdeos.nombre
                })

                await temaEjerDesarrolloALL.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id,
                  id_test: objetVIdeos.id,
                  nombre: objetVIdeos.nombre
                })
                await temaEjerDesarrolloALLS.push({
                  idTema: this.props.idTema,
                  idTest: objetVIdeos.id,
                  id_test: objetVIdeos.id,
                  nombre: objetVIdeos.nombre
                })

                this.setState({ temaEjerDesarrolloS: temaEjerDesarrolloS, temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALLS })

              }

              setTimeout(() => {
                this.setState({ actualizacionEstado: 'completado' })
                setTimeout(() => {
                  this.setState({ actualizacionEstado: null })
                }, 1250);
              }, 400);






            } else {
              // YA EXISTE EL VIDEO
              //swal('Video duplicado','Elija otro video, este ya esta asignado a este tema.','info')
            }

          }

        }
      }



    } else if (e.target.id.split('-')[0] == 'ejerDesarrolloVuelta') {

      let value = e.target.value

      let loca = e.target.id.split('-')[1]

      if (value != '') {
        let temaEjerDesarrollo = this.state.temaEjerDesarrollo

        temaEjerDesarrollo[loca].vuelta = value == '' ? '' : parseInt(value)
        this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })

        let temaEjerDesarrolloS = this.state.temaEjerDesarrolloS

        let temaEjerDesarrolloALL = this.state.temaEjerDesarrolloALL
        let temaEjerDesarrolloALLS = this.state.temaEjerDesarrolloALLS


        // actulizamos[locaLey].intervalosArt[locaArt].art_inicio
        if (parseInt(temaEjerDesarrolloS[loca].vuelta) != parseInt(temaEjerDesarrollo[loca].vuelta)) {


          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            vuelta: temaEjerDesarrollo[loca].vuelta,
            idTema: this.props.idTema,
            idTest: temaEjerDesarrollo[loca].idTest
          }

          let response = await fetch("https://oposiciones-justicia.es/api/temas/updteVueltaEjerDesarrolloTemas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          if (cls.affectedRows > 0) {

            if (this.state.sincroActiva != null) {
              // sincronia temas
              for (let index = 0; index < this.state.temasEnSincro.length; index++) {

                let data2 = {
                  vuelta: temaEjerDesarrollo[loca].vuelta,
                  idTema: this.state.temasEnSincro[index].idTemaSincro,
                  idTest: temaEjerDesarrollo[loca].idTest
                }
                let urlFet2 = "https://oposiciones-justicia.es/api/temas/updteVueltaEjerDesarrolloTemas"
                let response2 = await fetch(urlFet2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                if (cls2.affectedRows > 0) {
                  let locaALL2 = temaEjerDesarrolloALL.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaEjerDesarrollo[loca].idTest)
                  if (locaALL != -1) {
                    temaEjerDesarrolloALL[locaALL2].vuelta = temaEjerDesarrollo[loca].vuelta
                  }
                  let locaALLs2 = temaEjerDesarrolloALLS.findIndex(o => o.idTema == this.state.temasEnSincro[index].idTemaSincro && o.idTest == temaEjerDesarrollo[loca].idTest)
                  if (locaALL != -1) {
                    temaEjerDesarrolloALLS[locaALLs2].vuelta = temaEjerDesarrollo[loca].vuelta
                  }
                  this.setState({
                    temaEjerDesarrolloALL: temaEjerDesarrolloALL,
                    temaEjerDesarrolloALLS: temaEjerDesarrolloALLS
                  })
                }

              }
            }

            //this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_fin = value

            // actualizamos ALL sincronia


          }

          // ACTUALIZAMOS save + all del tema actual
          temaEjerDesarrolloS[loca].vuelta = temaEjerDesarrollo[loca].vuelta
          let locaALL = temaEjerDesarrolloALL.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaEjerDesarrollo[loca].idTest)
          if (locaALL != -1) {
            temaEjerDesarrolloALL[locaALL].vuelta = temaEjerDesarrollo[loca].vuelta
          }
          let locaALLs = temaEjerDesarrolloALLS.findIndex(o => o.idTema == this.props.idTema && o.idTest == temaEjerDesarrollo[loca].idTest)
          if (locaALL != -1) {
            temaEjerDesarrolloALLS[locaALLs].vuelta = temaEjerDesarrollo[loca].vuelta
          }


          this.setState({
            temaEjerDesarrolloS: temaEjerDesarrolloS,
            temaEjerDesarrolloALL: temaEjerDesarrolloALL,
            temaEjerDesarrolloALLS: temaEjerDesarrolloALLS
          })

          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);
        }


      }

    } else if (e.target.id == 'categoriaTema') {

      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      let busqueda = this.state.categoriasJusticia.find(c => c.nombre == infoTema[0].nombreCategoria)
      if (infoTemaS[0].nombreCategoria != '') {
        if (busqueda != undefined && infoTemaS[0].categoria == null || infoTemaS[0].categoria != busqueda.id_categoria) {
          // actualizamos en bd
          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            idCategoria: busqueda.id_categoria,
            idTema: this.props.idTema
          }
          let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTemaCategoria'

          let response = await fetch(urlFetX, {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;


          if (cls.affectedRows > 0) {

            if (this.state.sincroActiva != null) {
              // sincronia temas
              for (let index = 0; index < this.state.temasEnSincro.length; index++) {

                let data2 = {
                  idCategoria: busqueda.id_categoria,
                  idTema: this.state.temasEnSincro[index].idTemaSincro
                }
                let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateTemaCategoria'

                let response2 = await fetch(urlFetX2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;


              }

            }
          }

          infoTema[0].nombreCategoria = busqueda.nombre
          infoTema[0].categoria = busqueda.id_categoria
          infoTemaS[0].nombreCategoria = busqueda.nombre
          infoTemaS[0].categoria = busqueda.id_categoria

          this.setState({ infoTema: infoTema, infoTemaS: infoTemaS })
          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);

        }
      }

    } else if (e.target.id == 'oposicionTema') {

      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS

      let busqueda = this.state.busquedaOpo.find(c => c.nombre == infoTema[0].nombreOpo)

      if (infoTemaS[0].nombreOpo != '') {
        if (busqueda != undefined && infoTemaS[0].id_oposicion == null || infoTemaS[0].id_oposicion != busqueda.id_oposicion) {
          // actualizamos en bd
          this.setState({ actualizacionEstado: 'subiendo' })
          let data = {
            idOpo: busqueda.id_oposicion,
            idTema: this.props.idTema
          }
          let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTemaOposicion'

          let response = await fetch(urlFetX, {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          infoTema[0].nombreOpo = busqueda.nombre
          infoTema[0].id_oposicion = busqueda.id_oposicion
          infoTemaS[0].nombreOpo = busqueda.nombre
          infoTemaS[0].id_oposicion = busqueda.id_oposicion
          this.setState({ infoTema: infoTema, infoTemaS: infoTemaS })

          setTimeout(() => {
            this.setState({ actualizacionEstado: 'completado' })
            setTimeout(() => {
              this.setState({ actualizacionEstado: null })
            }, 1250);
          }, 400);

        }
      }

    } else if (e.target.id == 'numTema') {


      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      // actualizamos en bd
      if (infoTemaS[0].numeroTema != infoTema[0].numeroTema) {


        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          numTema: infoTema[0].numeroTema,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTemaNumTema'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].numeroTema = infoTema[0].numeroTema
        this.setState({ infoTemaS: infoTemaS })

        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'tituloTema') {
      // actualizamos en bd
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].titulo_tema != infoTema[0].titulo_tema) {
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          tituloTema: infoTema[0].titulo_tema,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTemaTitulo'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].titulo_tema = infoTema[0].titulo_tema
        this.setState({ infoTemaS: infoTemaS })

        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'nombreTema') {
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].nombre_tema != infoTema[0].nombre_tema) {
        // actualizamos en bd
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          nombreTema: infoTema[0].nombre_tema,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTemaNombre'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].nombre_tema = infoTema[0].nombre_tema
        this.setState({ infoTemaS: infoTemaS })
        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'primeraVueltaTiempo') {
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].primeraVueltaTiempo != infoTema[0].primeraVueltaTiempo) {
        // actualizamos en bd
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          timingVuelta: infoTema[0].primeraVueltaTiempo,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTema1V'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].primeraVueltaTiempo = infoTema[0].primeraVueltaTiempo
        this.setState({ infoTemaS: infoTemaS })
        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'segundaVueltaTiempo') {
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].segundaVueltaTiempo != infoTema[0].segundaVueltaTiempo) {
        // actualizamos en bd
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          timingVuelta: infoTema[0].segundaVueltaTiempo,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTema2V'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].segundaVueltaTiempo = infoTema[0].segundaVueltaTiempo
        this.setState({ infoTemaS: infoTemaS })
        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'repasoVueltaTiempo') {
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].repasoVueltaTiempo != infoTema[0].repasoVueltaTiempo) {
        // actualizamos en bd
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          timingVuelta: infoTema[0].repasoVueltaTiempo,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateTema3V'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;
        infoTemaS[0].repasoVueltaTiempo = infoTema[0].repasoVueltaTiempo
        this.setState({ infoTemaS: infoTemaS })
        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    } else if (e.target.id == 'textAreaEdtTema') {
      let infoTema = this.state.infoTema
      let infoTemaS = this.state.infoTemaS
      if (infoTemaS[0].notaPreparador != infoTema[0].notaPreparador && infoTemaS[0].notaPreparador != null) {
        // actualizamos en bd
        this.setState({ actualizacionEstado: 'subiendo' })
        let data = {
          notaPreparador: infoTema[0].notaPreparador,
          idTema: this.props.idTema
        }
        let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateNotaP'

        let response = await fetch(urlFetX, {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        if (cls.affectedRows > 0) {

          if (this.state.sincroActiva != null) {
            // sincronia temas
            let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
              this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
            ) : (
              this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
              ) : ([])
            )

            for (let index = 0; index < temasFiltroPorOpo.length; index++) {

              let data2 = {
                notaPreparador: infoTema[0].notaPreparador,
                idTema: temasFiltroPorOpo[index].idTemaSincro
              }
              let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/updateNotaP'

              let response2 = await fetch(urlFetX2, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;


            }

          }
        }

        infoTemaS[0].notaPreparador = infoTema[0].notaPreparador
        this.setState({ infoTemaS: infoTemaS })
        setTimeout(() => {
          this.setState({ actualizacionEstado: 'completado' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);
        }, 400);
      }
    }



  }
  async onChangeInput(e) {
    // let value = e.target.value
    //   let locaLey = e.target.id.split('-')[1]
    //   let locaArt = e.target.id.split('-')[2]
    //   this.props.temaIntervalos[locaLey].intervalosArt[locaArt].art_inicio = value

    // this.props.onChange(e)

    if (e.target.id.split('-')[0] == 'artInicio') {
      let value = e.target.value

      let locaLey = e.target.id.split('-')[1]
      let locaArt = e.target.id.split('-')[2]
      let actulizamos = this.state.temaIntervalosSave
      actulizamos[locaLey].intervalosArt[locaArt].art_inicio = value
      this.setState({ temaIntervalosSave: actulizamos })

      //this.forceUpdate()

      //updteIntervaloInicio
      //update del intervalos exacto tanto inicio como fin
      // cuando se crea se genera directo en bd con - - info art ini y fin
      // api existe en temas /insertIntervalo
    } else if (e.target.id.split('-')[0] == 'artFin') {
      let value = e.target.value

      let locaLey = e.target.id.split('-')[1]
      let locaArt = e.target.id.split('-')[2]
      let actulizamos = this.state.temaIntervalosSave
      actulizamos[locaLey].intervalosArt[locaArt].art_fin = value
      this.setState({ temaIntervalosSave: actulizamos })

      //this.forceUpdate()

      //updteIntervaloInicio
      //update del intervalos exacto tanto inicio como fin
      // cuando se crea se genera directo en bd con - - info art ini y fin
      // api existe en temas /insertIntervalo
    } else if (e.target.id.split('-')[0] == 'video') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let videoTema = this.state.videoTema
      videoTema[loca].titulo = value
      this.setState({ videoTema: videoTema })

      if (this.state.videoTema.filter(v => v.titulo == value).length > 1) {
        swal('Video duplicado', 'No puede asignar el mismo video 2 veces.', 'info')
      }
      //this.forceUpdate()

      //updteIntervaloInicio
      //update del intervalos exacto tanto inicio como fin
      // cuando se crea se genera directo en bd con - - info art ini y fin
      // api existe en temas /insertIntervalo
    } else if (e.target.id.split('-')[0] == 'testTeorico') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaTestTeorico = this.state.temaTestTeorico
      temaTestTeorico[loca].nombre_test = value
      this.setState({ temaTestTeorico: temaTestTeorico })

      if (this.state.temaTestTeorico.filter(v => v.nombre_test == value).length > 1) {

        swal('Test duplicado', 'No puede asignar el mismo test 2 veces.', 'info')

      }

    } else if (e.target.id.split('-')[0] == 'testPractico') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaTestPractico = this.state.temaTestPractico
      temaTestPractico[loca].nombre_test = value
      this.setState({ temaTestPractico: temaTestPractico })

      if (this.state.temaTestPractico.filter(v => v.nombre_test == value).length > 1) {

        swal('Test duplicado', 'No puede asignar el mismo test 2 veces.', 'info')

      }

    } else if (e.target.id.split('-')[0] == 'ejerDesarrollo') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaEjerDesarrollo = this.state.temaEjerDesarrollo
      temaEjerDesarrollo[loca].nombre = value
      this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })

      if (this.state.temaEjerDesarrollo.filter(v => v.nombre == value).length > 1) {

        swal('Test duplicado', 'No puede asignar el mismo test 2 veces.', 'info')

      }

    } else if (e.target.id.split('-')[0] == 'testTeoricoVuelta') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaTestTeorico = this.state.temaTestTeorico
      temaTestTeorico[loca].vuelta = value == '' ? '' : parseInt(value)
      this.setState({ temaTestTeorico: temaTestTeorico })


    } else if (e.target.id.split('-')[0] == 'testPracticoVuelta') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaTestPractico = this.state.temaTestPractico
      temaTestPractico[loca].vuelta = value == '' ? '' : parseInt(value)
      this.setState({ temaTestPractico: temaTestPractico })


    } else if (e.target.id.split('-')[0] == 'ejerDesarrolloVuelta') {
      let value = e.target.value

      let loca = e.target.id.split('-')[1]
      let temaEjerDesarrollo = this.state.temaEjerDesarrollo
      temaEjerDesarrollo[loca].vuelta = value == '' ? '' : parseInt(value)
      this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })


    } else if (e.target.id.split('-')[0] == 'opo') {
      let value = e.target.value
      let busquedaOpoObjet = this.state.busquedaOpo.find(o => o.nombre == value)

      let loca = e.target.id.split('-')[1]

      let temasEnSincro = this.state.temasEnSincro
      if (busquedaOpoObjet != undefined) {
        temasEnSincro[loca].nombreOpo = value
        temasEnSincro[loca].idOpo = busquedaOpoObjet.id_oposicion
        temasEnSincro[loca].idTema2 = null
        temasEnSincro[loca].nombre = null
        temasEnSincro[loca].numeroTema = null
        temasEnSincro[loca].titulo = ""

      } else {
        temasEnSincro[loca].nombreOpo = value
        temasEnSincro[loca].idOpo = null
        temasEnSincro[loca].idTema2 = null
        temasEnSincro[loca].nombre = null
        temasEnSincro[loca].numeroTema = null
        temasEnSincro[loca].titulo = ""
      }
      this.setState({ temasEnSincro: temasEnSincro })
    } else if (e.target.id.split('-')[0] == 'tema') {
      let value = e.target.value
      let loca = e.target.id.split('-')[1]
      let temasEnSincro = this.state.temasEnSincro
      let aObjet = this.state.temasAll.find(o => o.id_oposicion == temasEnSincro[loca].idOpo && o.titulo_tema == value)

      if (aObjet != undefined) {
        temasEnSincro[loca].idTema2 = aObjet.id_tema
        temasEnSincro[loca].nombre = aObjet.nombre_tema
        temasEnSincro[loca].numeroTema = aObjet.numeroTema
        temasEnSincro[loca].titulo = aObjet.titulo_tema

      } else {

        temasEnSincro[loca].idTema2 = null
        temasEnSincro[loca].nombre = null
        temasEnSincro[loca].numeroTema = null
        temasEnSincro[loca].titulo = value
      }
      this.setState({ temasEnSincro: temasEnSincro })


    } else if (e.target.id.split('-')[0] == 'leyIntervalo') {
      let value = e.target.value
      let loca = this.state.leySelectedNesd
      // siglas_ley
      // nombre_ley
      let objLey = this.state.busquedaLeyes.find(o => o.siglas_ley == value)

      if (objLey != undefined) {
        let actulizamos = this.state.temaIntervalosSave
        actulizamos[loca].idLey = objLey.id_ley
        actulizamos[loca].linkLey = objLey.link_ley
        actulizamos[loca].nombreLey = objLey.nombre_ley
        actulizamos[loca].siglasLey = objLey.siglas_ley
        this.setState({ temaIntervalosSave: actulizamos })
      } else {
        let actulizamos = this.state.temaIntervalosSave
        actulizamos[loca].idLey = null
        actulizamos[loca].linkLey = null
        actulizamos[loca].nombreLey = null
        actulizamos[loca].siglasLey = value
        this.setState({ temaIntervalosSave: actulizamos })
      }
    } else if (e.target.id == 'categoriaTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].nombreCategoria = value
      infoTema[0].categoria = null
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'oposicionTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].nombreOpo = value
      infoTema[0].id_oposicion = null
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'numTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].numeroTema = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'tituloTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].titulo_tema = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'nombreTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].nombre_tema = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'primeraVueltaTiempo') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].primeraVueltaTiempo = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'segundaVueltaTiempo') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].segundaVueltaTiempo = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'repasoVueltaTiempo') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].repasoVueltaTiempo = value
      this.setState({ infoTema: infoTema })

    } else if (e.target.id == 'textAreaEdtTema') {
      let value = e.target.value
      let infoTema = this.state.infoTema

      infoTema[0].notaPreparador = value
      this.setState({ infoTema: infoTema })

    }


  }
  async onHandleClick(e) {
    if (e.target.id == "cerrarEdt") {

      this.props.onClick(e)


    } else if (e.target.id.split('-')[0] == 'nav') {

      this.setState({ navEdit: e.target.id.split('-')[1] })
      if (e.target.id.split('-')[1] == 1 || e.target.id.split('-')[1] == '1' || e.target.id.split('-')[1] == 3 || e.target.id.split('-')[1] == '3') {
        this.setState({ actualizacionEstado: 'sincronizando' })
        setTimeout(() => {
          this.setState({ actualizacionEstado: null })
        }, 1250);
      }

      if (e.target.id.split('-')[1] == 3 || e.target.id.split('-')[1] == '3') {
        // cargar videos tema

        let response2 = await fetch("https://oposiciones-justicia.es/api/temas/loadVideos");
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        this.setState({ allVideoTemas: cls2 })

        let response1 = await fetch("https://oposiciones-justicia.es/api/temas/loadVideosTemas");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        let filtro = JSON.parse(JSON.stringify(cls1.filter(v => v.id_tema == this.props.idTema)))

        this.setState({ videoTema: JSON.parse(JSON.stringify(filtro)), videoTemaSave: JSON.parse(JSON.stringify(filtro)), videoTemas: JSON.parse(JSON.stringify(cls1)), videoTemasSave: JSON.parse(JSON.stringify(cls1)) })
      } else if (e.target.id.split('-')[1] == 4 || e.target.id.split('-')[1] == '4') {
        // cargar videos tema
        let idOpo = await this.state.infoTema[0].id_oposicion

        if (idOpo == '' || idOpo == null) {
          this.setState({ navEdit: 0 })
          swal('Error', 'Asigne una oposición al tema para cargar los test', 'warning')
        } else {
          this.setState({ actualizacionEstado: 'sincronizando' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);

          let data3 = {
            idTema: this.props.idTema,
            idOpo: idOpo
          }

          let response7 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaOpo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response7.ok) {
            throw Error(response7.statusText);
          }
          let json7 = await response7.json();
          let cls7 = await json7;


          let response8 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaOpo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response8.ok) {
            throw Error(response8.statusText);
          }
          let json8 = await response8.json();
          let cls8 = await json8;


          let response5 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response5.ok) {
            throw Error(response5.statusText);
          }
          let json5 = await response5.json();
          let cls5 = await json5;



          let response6 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response6.ok) {
            throw Error(response6.statusText);
          }
          let json6 = await response6.json();
          let cls6 = await json6;

          this.setState({

            testTeoricoOpo: JSON.parse(JSON.stringify(cls7)),

            testPracticoOpo: JSON.parse(JSON.stringify(cls8)),

            temaTestTeoricoALL: JSON.parse(JSON.stringify(cls5)), temaTestTeoricoALLS: JSON.parse(JSON.stringify(cls5)), temaTestTeorico: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema))), temaTestTeoricoS: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema))),

            temaTestPracticoALL: JSON.parse(JSON.stringify(cls6)), temaTestPracticoALLS: JSON.parse(JSON.stringify(cls6)), temaTestPractico: JSON.parse(JSON.stringify(cls6.filter(t => t.idTema == this.props.idTema))), temaTestPracticoS: JSON.parse(JSON.stringify(cls6.filter(t => t.idTema == this.props.idTema)))

          })
        }
        // this.setState({ videoTema: JSON.parse(JSON.stringify(filtro)), videoTemaSave: JSON.parse(JSON.stringify(filtro)), videoTemas: JSON.parse(JSON.stringify(cls1)), videoTemasSave: JSON.parse(JSON.stringify(cls1)) })
      } else if (e.target.id.split('-')[1] == 5 || e.target.id.split('-')[1] == '5') {

        let idOpo = await this.state.infoTema[0].id_oposicion

        if (idOpo == '' || idOpo == null) {
          this.setState({ navEdit: 0 })
          swal('Error', 'Asigne una oposición al tema para cargar los test', 'warning')
        } else {
          this.setState({ actualizacionEstado: 'sincronizando' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);

          let data3 = {
            idTema: this.props.idTema,
            idOpo: idOpo
          }

          let response7 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaOpo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response7.ok) {
            throw Error(response7.statusText);
          }
          let json7 = await response7.json();
          let cls7 = await json7;


          let response8 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaOpo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response8.ok) {
            throw Error(response8.statusText);
          }
          let json8 = await response8.json();
          let cls8 = await json8;


          let response5 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response5.ok) {
            throw Error(response5.statusText);
          }
          let json5 = await response5.json();
          let cls5 = await json5;



          let response6 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response6.ok) {
            throw Error(response6.statusText);
          }
          let json6 = await response6.json();
          let cls6 = await json6;

          this.setState({

            testTeoricoOpo: JSON.parse(JSON.stringify(cls7)),

            testPracticoOpo: JSON.parse(JSON.stringify(cls8)),

            temaTestTeoricoALL: JSON.parse(JSON.stringify(cls5)), temaTestTeoricoALLS: JSON.parse(JSON.stringify(cls5)), temaTestTeorico: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema))), temaTestTeoricoS: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema))),

            temaTestPracticoALL: JSON.parse(JSON.stringify(cls6)), temaTestPracticoALLS: JSON.parse(JSON.stringify(cls6)), temaTestPractico: JSON.parse(JSON.stringify(cls6.filter(t => t.idTema == this.props.idTema))), temaTestPracticoS: JSON.parse(JSON.stringify(cls6.filter(t => t.idTema == this.props.idTema)))

          })
        }


      } else if (e.target.id.split('-')[1] == 4 || e.target.id.split('-')[1] == '6') {
        // cargar videos tema
        let idOpo = await this.state.infoTema[0].id_oposicion

        if (idOpo == '' || idOpo == null) {
          this.setState({ navEdit: 0 })
          swal('Error', 'Asigne una oposición al tema para cargar los ejercicios', 'warning')
        } else {
          this.setState({ actualizacionEstado: 'sincronizando' })
          setTimeout(() => {
            this.setState({ actualizacionEstado: null })
          }, 1250);

          let data3 = {
            idTema: this.props.idTema
          }


          let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/examGest");
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;


          let response5 = await fetch("https://oposiciones-justicia.es/api/temas/ejerDesarrolloTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response5.ok) {
            throw Error(response5.statusText);
          }
          let json5 = await response5.json();
          let cls5 = await json5;


          this.setState({

            ejerDesarrollo: JSON.parse(JSON.stringify(cls1)),

            temaEjerDesarrolloALL: JSON.parse(JSON.stringify(cls5)),
            temaEjerDesarrolloALLS: JSON.parse(JSON.stringify(cls5)),
            temaEjerDesarrollo: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema))),
            temaEjerDesarrolloS: JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema)))

          })
        }
        // this.setState({ videoTema: JSON.parse(JSON.stringify(filtro)), videoTemaSave: JSON.parse(JSON.stringify(filtro)), videoTemas: JSON.parse(JSON.stringify(cls1)), videoTemasSave: JSON.parse(JSON.stringify(cls1)) })
      }


    } else if (e.target.id == 'addTEMAsync') {
      let temasEnSincro = this.state.temasEnSincro

      temasEnSincro.push({
        categoria: null,
        idOpo: null,
        idTema1: this.props.idTema,
        idTema2: null,
        idTemaSincro: null,
        nombre: null,
        nombreOpo: "",
        numeroTema: null,
        titulo: ""
      })
      this.setState({ temasEnSincro: temasEnSincro })

    } else if (e.target.id.split('-')[0] == 'borrarTemaSync') {
      let loca = e.target.id.split('-')[1]



      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación de la sincronización entre estos temas",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idTema1: this.state.temasEnSincro[loca].idTema1,
              idTema2: this.state.temasEnSincro[loca].idTemaSincro
            }
            if (this.state.temasEnSincro[loca].idTemaSincro != null) {
              let urlFet = "https://oposiciones-justicia.es/api/temas/delSyncTemas"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {

                // for (let index = 0; index < this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro).length; index++) {
                //   let temaOtro = this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro)[index].idTema1
                //   let temaOtro2 = this.state.temasEnSincro.filter(t => t.idTema1 != temasEnSincro[loca].idTemaSincro && t.idTema2 != temasEnSincro[loca].idTemaSincro)[index].idTema2
                //   let data2 = {
                //     idTema1: temaOtro != this.props.idTema ? (temaOtro) : (temaOtro2),
                //     idTema2: this.state.temasEnSincro[loca].idTemaSincro
                //   }

                //   let urlFet = "https://oposiciones-justicia.es/api/temas/delSyncTemas"

                //   let response2 = await fetch(urlFet, {
                //     method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                //   });
                //   if (!response2.ok) {
                //     throw Error(response2.statusText);
                //   }
                //   let json2 = await response2.json();
                //   let cls2 = await json2;

                // }
                let temasEnSincro = this.state.temasEnSincro
                temasEnSincro.splice(loca, 1);

                this.setState({ temasEnSincro: temasEnSincro })

              }
            } else {
              let temasEnSincro = this.state.temasEnSincro
              temasEnSincro.splice(loca, 1);

              this.setState({ temasEnSincro: temasEnSincro })
            }




            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });



    } else if (e.target.id.split('-')[0] == 'leySeletdgfr') {
      let loca = e.target.id.split('-')[1]
      this.setState({ leySelectedNesd: loca })
    } else if (e.target.id == 'addLey') {
      let temaIntervalosSave = this.state.temaIntervalosSave


      temaIntervalosSave.push({
        idLey: null,
        intervalosArt: [],
        linkLey: null,
        nombreLey: null,
        siglasLey: ""
      })
      this.setState({ temaIntervalosSave: temaIntervalosSave, leySelectedNesd: temaIntervalosSave.length - 1 })
    } else if (e.target.id.split('-')[0] == 'borrarIntervalo') {
      let loca = e.target.id.split('-')[1]
      let loca2 = e.target.id.split('-')[2]


      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del intervalo",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idTema: this.props.idTema,
              idLey: this.state.temaIntervalosSave[loca].intervalosArt[loca2].id_ley,
              artInicio: this.state.temaIntervalosSave[loca].intervalosArt[loca2].art_inicio,
              artFin: this.state.temaIntervalosSave[loca].intervalosArt[loca2].art_fin
            }

            let urlFet = "https://oposiciones-justicia.es/api/temas/borrarIntervaloUnd"

            let response = await fetch(urlFet, {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              if (this.state.sincroActiva != null) {
                // sincronia temas
                let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
                  this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
                ) : (
                  this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                    this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
                  ) : ([])
                )
                for (let index = 0; index < temasFiltroPorOpo.length; index++) {

                  let data2 = {
                    idTema: temasFiltroPorOpo[index].idTemaSincro,
                    idLey: this.state.temaIntervalosSave[loca].intervalosArt[loca2].id_ley,
                    artInicio: this.state.temaIntervalosSave[loca].intervalosArt[loca2].art_inicio,
                    artFin: this.state.temaIntervalosSave[loca].intervalosArt[loca2].art_fin
                  }
                  let urlFet2 = "https://oposiciones-justicia.es/api/temas/borrarIntervaloUnd"
                  let response2 = await fetch(urlFet2, {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                  });
                  if (!response2.ok) {
                    throw Error(response2.statusText);
                  }
                  let json2 = await response2.json();
                  let cls2 = await json2;

                }
              }

              let temaIntervalosSave = this.state.temaIntervalosSave
              temaIntervalosSave[loca].intervalosArt.splice(loca2, 1);

              this.setState({ temaIntervalosSave: temaIntervalosSave })
              this.props.temaIntervalos[loca].intervalosArt.splice(loca2, 1)


            }





            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });


    } else if (e.target.id == 'addIntervaloArticulos') {
      let loca = this.state.leySelectedNesd
      let temaIntervalosSave = this.state.temaIntervalosSave

      temaIntervalosSave[loca].intervalosArt.push({
        art_fin: "-",
        art_inicio: "-",
        id_ley: temaIntervalosSave[loca].idLey,
        id_tema: this.props.idTema
      })

      let data = {
        artFin: "-",
        artInicio: "-",
        idLey: temaIntervalosSave[loca].idLey,
        idTema: this.props.idTema
      }
      let urlFet = "https://oposiciones-justicia.es/api/temas/addIntervaloUnd"
      let response = await fetch(urlFet, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;



      if (cls.affectedRows > 0) {

        if (this.state.sincroActiva != null) {
          let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
            this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
          ) : (
            this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
              this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
            ) : ([])
          )
          for (let index = 0; index < temasFiltroPorOpo.length; index++) {

            let data2 = {
              artFin: "-",
              artInicio: "-",
              idLey: temaIntervalosSave[loca].idLey,
              idTema: temasFiltroPorOpo[index].idTemaSincro
            }
            let urlFet2 = "https://oposiciones-justicia.es/api/temas/addIntervaloUnd"
            let response2 = await fetch(urlFet2, {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;

          }
        }

        if (temaIntervalosSave[loca].intervalosArt.length == 1 && this.props.temaIntervalos[loca] == undefined) {
          this.props.temaIntervalos.push({
            idLey: temaIntervalosSave[loca].idLey,
            intervalosArt: [{
              art_fin: "-",
              art_inicio: "-",
              id_ley: temaIntervalosSave[loca].idLey,
              id_tema: this.props.idTema
            }],
            linkLey: temaIntervalosSave[loca].linkLey,
            nombreLey: temaIntervalosSave[loca].nombreLey,
            siglasLey: temaIntervalosSave[loca].siglasLey
          })

        } else {
          this.props.temaIntervalos[loca].intervalosArt.push({
            art_fin: "-",
            art_inicio: "-",
            id_ley: temaIntervalosSave[loca].idLey,
            id_tema: this.props.idTema
          })
        }


        this.setState({ temaIntervalosSave: temaIntervalosSave })

      }
    } else if (e.target.id == 'deleteLey') {
      let loca = this.state.leySelectedNesd

      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación de la ley e intervalos",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            this.setState({ actualizacionEstado: 'subiendo' })
            if (this.props.temaIntervalos[loca] != undefined) {
              let data = {
                idTema: this.props.idTema,
                idLey: this.props.temaIntervalos[loca].idLey
              }

              let urlFet = "https://oposiciones-justicia.es/api/temas/borrarIntervalosLey"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  let temasFiltroPorOpo = await this.state.infoTema[0].id_oposicion == 4 || this.state.infoTema[0].id_oposicion == 9 ? (
                    this.state.temasEnSincro.filter(t => t.idOpo == 4 || t.idOpo == 9)
                  ) : (
                    this.state.infoTema[0].id_oposicion == 3 || this.state.infoTema[0].id_oposicion == 8 ? (
                      this.state.temasEnSincro.filter(t => t.idOpo == 3 || t.idOpo == 8)
                    ) : ([])
                  )
                  for (let index = 0; index < temasFiltroPorOpo.length; index++) {

                    let data2 = {
                      idLey: this.props.temaIntervalos[loca].idLey,
                      idTema: temasFiltroPorOpo[index].idTemaSincro
                    }
                    let urlFet2 = "https://oposiciones-justicia.es/api/temas/borrarIntervalosLey"
                    let response2 = await fetch(urlFet2, {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;

                  }
                }
                let temaIntervalosSave = this.state.temaIntervalosSave
                temaIntervalosSave.splice(loca, 1);

                this.setState({ temaIntervalosSave: temaIntervalosSave, leySelectedNesd: temaIntervalosSave.length - 1 })
                if (this.props.temaIntervalos[loca] != undefined) {
                  this.props.temaIntervalos.splice(loca, 1)
                }

              }
            } else {
              let temaIntervalosSave = this.state.temaIntervalosSave
              temaIntervalosSave.splice(loca, 1);
              this.setState({ temaIntervalosSave: temaIntervalosSave, leySelectedNesd: temaIntervalosSave.length - 1 })
            }


            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });

    } else if (e.target.id == 'sincroActiva') {
      this.setState({ sincroActiva: this.state.sincroActiva == null ? (true) : (null) })
      this.setState({ actualizacionEstado: 'sincronizando' })
      setTimeout(() => {
        this.setState({ actualizacionEstado: null })
      }, 1250);
    } else if (e.target.id == 'addVideo') {
      let videoTema = this.state.videoTema

      videoTema.push({
        id_tema: this.props.idTema,
        id_video: null,
        titulo: '',
        link: null,
        nuevo: true
      })
      this.setState({ videoTema: videoTema })
    } else if (e.target.id == 'addTestTeorico') {
      let temaTestTeorico = this.state.temaTestTeorico

      temaTestTeorico.push({
        idTema: this.props.idTema,
        idTest: null,
        nombre_test: '',
        vuelta: 1
      })
      this.setState({ temaTestTeorico: temaTestTeorico })
    } else if (e.target.id == 'addTestPractico') {
      let temaTestPractico = this.state.temaTestPractico

      temaTestPractico.push({
        idTema: this.props.idTema,
        idTest: null,
        nombre_test: '',
        vuelta: 1
      })
      this.setState({ temaTestPractico: temaTestPractico })
    } else if (e.target.id == 'addEjerDesarrollo') {
      let temaEjerDesarrollo = this.state.temaEjerDesarrollo

      temaEjerDesarrollo.push({
        idTema: this.props.idTema,
        idTest: null,
        nombre: '',
        vuelta: 1
      })
      this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })
    } else if (e.target.id.split('-')[0] == 'borrarVideo') {
      let loca = e.target.id.split('-')[1]

      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del video en este tema y los sincronizados, si esta activa la funcion.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let videoTema = this.state.videoTema
            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idVideo: videoTema[loca].id_video,
              idTema: this.props.idTema
            }
            if (videoTema[loca].id_video != null) {
              let urlFet = "https://oposiciones-justicia.es/api/temas/delVideoTema"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //videoTemas objetVIdeos.id_video

                    let videoBusquedaExiste = this.state.videoTemas.find(v => v.id_video == videoTema[loca].id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste != undefined) {
                      //insert
                      let data2 = {
                        idVideo: videoTema[loca].id_video,
                        idTema: this.state.temasEnSincro[index].idTemaSincro
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/delVideoTema'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      let locaVid = this.state.videoTemas.findIndex(v => v.id_video == videoTema[loca].id_video && v.id_tema == this.state.temasEnSincro[index].idTemaSincro)
                      if (locaVid != -1) {
                        let videoTemas = this.state.videoTemas
                        videoTemas.splice(locaVid, 1);
                        this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemas })

                      }

                    }
                  }
                }

                let locaVid2 = this.state.videoTemas.findIndex(v => v.id_video == videoTema[loca].id_video && v.id_tema == this.props.idTema)
                if (locaVid2 != -1) {
                  let videoTemas = this.state.videoTemas
                  videoTemas.splice(locaVid2, 1);
                  this.setState({ videoTemas: videoTemas, videoTemasSave: videoTemas })
                }


                videoTema.splice(loca, 1);
                this.setState({ videoTema: videoTema, videoTemaSave: videoTema })
              }
            } else {
              videoTema.splice(loca, 1);

              this.setState({ videoTema: videoTema })
            }




            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });



    } else if (e.target.id.split('-')[0] == 'borrarTestTeorico') {
      let loca = e.target.id.split('-')[1]
      // temaTestTeorico videoTema
      // temaTestTeoricoALL videoTemas
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del TEST TEÓRICO en este tema y los sincronizados, si esta activa la funcion.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let temaTestTeorico = this.state.temaTestTeorico
            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idTest: temaTestTeorico[loca].idTest,
              idTema: this.props.idTema
            }
            if (temaTestTeorico[loca].idTest != null) {
              let urlFet = "https://oposiciones-justicia.es/api/temas/delTestTeoTema"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestTeoricoALL objetVIdeos.id_video

                    let videoBusquedaExiste = this.state.temaTestTeoricoALL.find(v => v.idTest == temaTestTeorico[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste != undefined) {
                      //insert
                      let data2 = {
                        idTest: temaTestTeorico[loca].idTest,
                        idTema: this.state.temasEnSincro[index].idTemaSincro
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/delTestTeoTema'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      let locaVid = this.state.temaTestTeoricoALL.findIndex(v => v.idTest == temaTestTeorico[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                      if (locaVid != -1) {
                        let temaTestTeoricoALL = this.state.temaTestTeoricoALL
                        temaTestTeoricoALL.splice(locaVid, 1);
                        this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALL })

                      }

                    }
                  }
                }

                let locaVid2 = this.state.temaTestTeoricoALL.findIndex(v => v.idTest == temaTestTeorico[loca].idTest && v.idTema == this.props.idTema)
                if (locaVid2 != -1) {
                  let temaTestTeoricoALL = this.state.temaTestTeoricoALL
                  temaTestTeoricoALL.splice(locaVid2, 1);
                  this.setState({ temaTestTeoricoALL: temaTestTeoricoALL, temaTestTeoricoALLS: temaTestTeoricoALL })
                }


                temaTestTeorico.splice(loca, 1);
                this.setState({ temaTestTeorico: temaTestTeorico, temaTestTeoricoS: temaTestTeorico })
              }
            } else {
              temaTestTeorico.splice(loca, 1);

              this.setState({ temaTestTeorico: temaTestTeorico })
            }




            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });



    } else if (e.target.id.split('-')[0] == 'borrarTestPractico') {
      let loca = e.target.id.split('-')[1]
      // temaTestTeorico videoTema
      // temaTestTeoricoALL videoTemas
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del TEST PRÁCTICO en este tema y los sincronizados, si esta activa la funcion.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let temaTestPractico = this.state.temaTestPractico
            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idTest: temaTestPractico[loca].idTest,
              idTema: this.props.idTema
            }
            if (temaTestPractico[loca].idTest != null) {
              let urlFet = "https://oposiciones-justicia.es/api/temas/delTestPraTema"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaTestPracticoALL objetVIdeos.id_video

                    let videoBusquedaExiste = this.state.temaTestPracticoALL.find(v => v.idTest == temaTestPractico[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste != undefined) {
                      //insert
                      let data2 = {
                        idTest: temaTestPractico[loca].idTest,
                        idTema: this.state.temasEnSincro[index].idTemaSincro
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/delTestPraTema'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      let locaVid = this.state.temaTestPracticoALL.findIndex(v => v.idTest == temaTestPractico[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                      if (locaVid != -1) {
                        let temaTestPracticoALL = this.state.temaTestPracticoALL
                        temaTestPracticoALL.splice(locaVid, 1);
                        this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALL })

                      }

                    }
                  }
                }

                let locaVid2 = this.state.temaTestPracticoALL.findIndex(v => v.idTest == temaTestPractico[loca].idTest && v.idTema == this.props.idTema)
                if (locaVid2 != -1) {
                  let temaTestPracticoALL = this.state.temaTestPracticoALL
                  temaTestPracticoALL.splice(locaVid2, 1);
                  this.setState({ temaTestPracticoALL: temaTestPracticoALL, temaTestPracticoALLS: temaTestPracticoALL })
                }


                temaTestPractico.splice(loca, 1);
                this.setState({ temaTestPractico: temaTestPractico, temaTestPracticoS: temaTestPractico })
              }
            } else {
              temaTestPractico.splice(loca, 1);

              this.setState({ temaTestPractico: temaTestPractico })
            }




            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });



    } else if (e.target.id.split('-')[0] == 'borrarEjerDesarrollo') {
      let loca = e.target.id.split('-')[1]
      // temaEjerDesarrollo videoTema
      // temaEjerDesarrolloALL videoTemas
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del EJERCICIO DE DESARROLLO en este tema y los sincronizados, si esta activa la función.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let temaEjerDesarrollo = this.state.temaEjerDesarrollo
            this.setState({ actualizacionEstado: 'subiendo' })
            let data = {
              idTest: temaEjerDesarrollo[loca].idTest,
              idTema: this.props.idTema
            }
            if (temaEjerDesarrollo[loca].idTest != null) {
              let urlFet = "https://oposiciones-justicia.es/api/temas/delEjerDesarrolloTema"

              let response = await fetch(urlFet, {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                if (this.state.sincroActiva != null) {
                  // sincronia temas
                  for (let index = 0; index < this.state.temasEnSincro.length; index++) {
                    //temaEjerDesarrolloALL objetVIdeos.id_video

                    let videoBusquedaExiste = this.state.temaEjerDesarrolloALL.find(v => v.idTest == temaEjerDesarrollo[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)

                    if (videoBusquedaExiste != undefined) {
                      //insert
                      let data2 = {
                        idTest: temaEjerDesarrollo[loca].idTest,
                        idTema: this.state.temasEnSincro[index].idTemaSincro
                      }
                      let urlFetX2 = 'https://oposiciones-justicia.es/api/temas/delEjerDesarrolloTema'

                      let response2 = await fetch(urlFetX2, {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                      });
                      if (!response2.ok) {
                        throw Error(response2.statusText);
                      }
                      let json2 = await response2.json();
                      let cls2 = await json2;
                      let locaVid = this.state.temaEjerDesarrolloALL.findIndex(v => v.idTest == temaEjerDesarrollo[loca].idTest && v.idTema == this.state.temasEnSincro[index].idTemaSincro)
                      if (locaVid != -1) {
                        let temaEjerDesarrolloALL = this.state.temaEjerDesarrolloALL
                        temaEjerDesarrolloALL.splice(locaVid, 1);
                        this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALL })

                      }

                    }
                  }
                }

                let locaVid2 = this.state.temaEjerDesarrolloALL.findIndex(v => v.idTest == temaEjerDesarrollo[loca].idTest && v.idTema == this.props.idTema)
                if (locaVid2 != -1) {
                  let temaEjerDesarrolloALL = this.state.temaEjerDesarrolloALL
                  temaEjerDesarrolloALL.splice(locaVid2, 1);
                  this.setState({ temaEjerDesarrolloALL: temaEjerDesarrolloALL, temaEjerDesarrolloALLS: temaEjerDesarrolloALL })
                }


                temaEjerDesarrollo.splice(loca, 1);
                this.setState({ temaEjerDesarrollo: temaEjerDesarrollo, temaEjerDesarrolloS: temaEjerDesarrollo })
              }
            } else {
              temaEjerDesarrollo.splice(loca, 1);

              this.setState({ temaEjerDesarrollo: temaEjerDesarrollo })
            }




            setTimeout(() => {
              this.setState({ actualizacionEstado: 'completado' })
              setTimeout(() => {
                this.setState({ actualizacionEstado: null })
              }, 1250);
            }, 400);


          }
        });



    } else if (e.target.id.split('-')[0] == 'TemaSyncTotal') {
      let key = e.target.id.split('-')[1]
      let temaSel = this.state.temasEnSincro[key]

      swal({
        title: "¿Estas segur@?",
        text: "Se eliminara y copiarán en " + temaSel.titulo + " de " + temaSel.nombreOpo + " las categoria, nota preparador, leyes, videos y ejercicios del tema: " + this.state.infoTema[0].titulo_tema + ", " + this.state.infoTema[0].nombre_tema + "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            swal({
              title: 'Sincronizando Tema',
              text: ' ',
              icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })

            let response1 = await fetch("https://oposiciones-justicia.es/api/temas/loadVideosTemas");
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;


            let idOpo = await this.state.infoTema[0].id_oposicion

            let data3 = {
              idTema: this.props.idTema,
              idOpo: idOpo
            }

            let response5 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response5.ok) {
              throw Error(response5.statusText);
            }
            let json5 = await response5.json();
            let cls5 = await json5;

            let response6 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response6.ok) {
              throw Error(response6.statusText);
            }
            let json6 = await response6.json();
            let cls6 = await json6;

            let response7 = await fetch("https://oposiciones-justicia.es/api/temas/ejerDesarrolloTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response7.ok) {
              throw Error(response7.statusText);
            }
            let json7 = await response7.json();
            let cls7 = await json7;



            let intervalosTema = this.state.temaIntervalosSave
            let videos = JSON.parse(JSON.stringify(cls1.filter(v => v.id_tema == this.props.idTema)))
            let temaTestTeorico = JSON.parse(JSON.stringify(cls5.filter(t => t.idTema == this.props.idTema)))
            let temaTestPractico = JSON.parse(JSON.stringify(cls6.filter(t => t.idTema == this.props.idTema)))
            let temaEjerDesarrollo = JSON.parse(JSON.stringify(cls7.filter(t => t.idTema == this.props.idTema)))

            let categoriaTema = this.state.infoTema[0].categoria
            let nombreTema = this.state.infoTema[0].nombre_tema

            let notaTema = this.state.infoTema[0].notaPreparador

            let intervalosDatos = []
            for (let x = 0; x < intervalosTema.length; x++) {
              for (let x2 = 0; x2 < intervalosTema[x].intervalosArt.length; x2++) {
                intervalosDatos.push({
                  id_ley: intervalosTema[x].intervalosArt[x2].id_ley,
                  articulo_inc: intervalosTema[x].intervalosArt[x2].art_inicio,
                  articulo_fin: intervalosTema[x].intervalosArt[x2].art_fin
                })
              }
            }

            // console.log('intervalosDatos')
            // console.log(intervalosDatos)

            // console.log('categoriaTema')
            // console.log(categoriaTema)

            // console.log('nombreTema')
            // console.log(nombreTema)

            // console.log('notaTema')
            // console.log(notaTema)

            // console.log('intervalosTema')
            // console.log(intervalosTema)

            // console.log('videos')
            // console.log(videos)

            // console.log('temaTestTeorico')
            // console.log(temaTestTeorico)

            // console.log('temaTestPractico')
            // console.log(temaTestPractico)

            // console.log('temaEjerDesarrollo')
            // console.log(temaEjerDesarrollo)

            // console.log('temaEjerDesarrollo')
            // console.log(temaEjerDesarrollo)

            // eliminar datos del tema APIS en TEMA!

            let dataX1 = {
              idTema: temaSel.idTemaSincro
            }
            let response01 = await fetch('https://oposiciones-justicia.es/api/temas/borrarIntervalos', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX1)
            });
            if (!response01.ok) {
              throw Error(response01.statusText);
            }
            let json01 = await response01.json();
            let cls01 = await json01;

            let response02 = await fetch('https://oposiciones-justicia.es/api/temas/delTodosVideosTema', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX1)
            });
            if (!response02.ok) {
              throw Error(response02.statusText);
            }
            let json02 = await response02.json();
            let cls02 = await json02;

            let response03 = await fetch('https://oposiciones-justicia.es/api/temas/delTodosTestTeoTema', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX1)
            });
            if (!response03.ok) {
              throw Error(response03.statusText);
            }
            let json03 = await response03.json();
            let cls03 = await json03;

            let response04 = await fetch('https://oposiciones-justicia.es/api/temas/delTodosTestPraTema', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX1)
            });
            if (!response04.ok) {
              throw Error(response04.statusText);
            }
            let json04 = await response04.json();
            let cls04 = await json04;

            let response05 = await fetch('https://oposiciones-justicia.es/api/temas/delTodosEjerDesarrolloTema', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX1)
            });
            if (!response05.ok) {
              throw Error(response05.statusText);
            }
            let json05 = await response05.json();
            let cls05 = await json05;


            // insertar datos nuevos sincro

            if (intervalosDatos.length > 0) {
              let dataX2 = {
                id_tema: temaSel.idTemaSincro,
                intervalosDatos: intervalosDatos
              }
              let response06 = await fetch('https://oposiciones-justicia.es/api/temas/addIntervalos', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX2)
              });
              if (!response06.ok) {
                throw Error(response06.statusText);
              }
              let json06 = await response06.json();
              let cls06 = await json06;
            }

            if (videos.length > 0) {
              let dataX3 = {
                idTema: temaSel.idTemaSincro,
                videos: videos
              }
              let response07 = await fetch('https://oposiciones-justicia.es/api/temas/addVideos', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX3)
              });
              if (!response07.ok) {
                throw Error(response07.statusText);
              }
              let json07 = await response07.json();
              let cls07 = await json07;
            }
            // insertTodosTestTeoriaTemas
            if (temaTestTeorico.length > 0) {
              let dataX4 = {
                idTema: temaSel.idTemaSincro,
                temaTestTeorico: temaTestTeorico
              }
              let response08 = await fetch('https://oposiciones-justicia.es/api/temas/insertTodosTestTeoriaTemas', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX4)
              });
              if (!response08.ok) {
                throw Error(response08.statusText);
              }
              let json08 = await response08.json();
              let cls08 = await json08;
            }

            if (temaTestPractico.length > 0) {
              let dataX5 = {
                idTema: temaSel.idTemaSincro,
                temaTestPractico: temaTestPractico
              }
              let response09 = await fetch('https://oposiciones-justicia.es/api/temas/insertTodosTestPracticoTemas', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX5)
              });
              if (!response09.ok) {
                throw Error(response09.statusText);
              }
              let json09 = await response09.json();
              let cls09 = await json09;
            }

            if (temaEjerDesarrollo.length > 0) {
              let dataX6 = {
                idTema: temaSel.idTemaSincro,
                temaEjerDesarrollo: temaEjerDesarrollo
              }
              let response10 = await fetch('https://oposiciones-justicia.es/api/temas/insertTodosEjerDesrrrolloTemas', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX6)
              });
              if (!response10.ok) {
                throw Error(response10.statusText);
              }
              let json10 = await response10.json();
              let cls10 = await json10;
            }

            if (categoriaTema != undefined && categoriaTema != null) {

              let dataX7 = {
                idTema: temaSel.idTemaSincro,
                idCategoria: categoriaTema
              }
              let response11 = await fetch('https://oposiciones-justicia.es/api/temas/updateTemaCategoria', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX7)
              });
              if (!response11.ok) {
                throw Error(response11.statusText);
              }
              let json11 = await response11.json();
              let cls11 = await json11;
            }

            if (nombreTema != undefined && nombreTema != null) {
              let dataX8 = {
                idTema: temaSel.idTemaSincro,
                nombreTema: nombreTema
              }
              let response12 = await fetch('https://oposiciones-justicia.es/api/temas/updateTemaNombre', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX8)
              });
              if (!response12.ok) {
                throw Error(response12.statusText);
              }
              let json12 = await response12.json();
              let cls12 = await json12;
            }

            if (notaTema != undefined && notaTema != null) {
              let dataX9 = {
                idTema: temaSel.idTemaSincro,
                notaPreparador: notaTema
              }
              let response13 = await fetch('https://oposiciones-justicia.es/api/temas/updateNotaP', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataX9)
              });
              if (!response13.ok) {
                throw Error(response13.statusText);
              }
              let json13 = await response13.json();
              let cls13 = await json13;
            }
            swal('Tema sincronizado', 'Se actualizo la siguiente información: Categoría tema, nombre tema, nota del preparador, leyes y ejercicios (Teórico, Práctico y desarrollo).', 'info')

          }
        });
    }


  }
  async componentDidUpdate() {
    //Apuntes del tema consultaApuntesTemas

  }
  async componentDidMount() {
    // cargar info del tema
    // para editarla
    fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
      .then(res => res.json())
      .then(cls =>
        this.setState({
          categoriasJusticia: cls
        }))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/preparador/examGest')
      .then(res => res.json())
      .then(asd => this.setState({
        ejerDesarrollo: JSON.parse(JSON.stringify(asd))
      })).catch(error => console.log('Object fecth failed', error));



    fetch('https://oposiciones-justicia.es/api/temas/allTemas')
      .then(res => res.json())
      .then(asd => this.setState({
        temasAll: asd
      })).catch(error => console.log('Object fecth failed', error));


    let response3 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
    if (!response3.ok) {
      throw Error(response3.statusText);
    }
    let json3 = await response3.json();
    let cls3 = await json3;
    this.setState({ busquedaLeyes: cls3 });

    let data2 = {
      id_tema: this.props.idTema
    }
    let response2 = await fetch("https://oposiciones-justicia.es/api/temas/infoTema", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;
    for (let gg = 0; gg < await cls2.length; gg++) {
      await cls2[gg].titulo_tema == null ? cls2[gg].titulo_tema = '' : null
      await cls2[gg].nombre_tema == null ? cls2[gg].nombre_tema = '' : null
      await cls2[gg].numeroTema == null ? cls2[gg].numeroTema = '' : null
      await cls2[gg].categoria == null ? cls2[gg].categoria = '' : null
      await cls2[gg].id_oposicion == null ? cls2[gg].id_oposicion = '' : null
      await cls2[gg].notaPreparador == null ? cls2[gg].notaPreparador = '' : null

      cls2[gg].nombreOpo = ''
      cls2[gg].nombreCategoria = ''
    }
    this.setState({ infoTema: JSON.parse(JSON.stringify(cls2)), infoTemaS: JSON.parse(JSON.stringify(cls2)) })

    let response4 = await fetch("https://oposiciones-justicia.es/api/temas/sincroTemasInfo", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
    });
    if (!response4.ok) {
      throw Error(response4.statusText);
    }
    let json4 = await response4.json();
    let cls4 = await json4;
    this.setState({ temasEnSincro: cls4 })

    let data3 = {
      idTema: this.props.idTema,
      idOpo: cls2.length > 0 && cls2[0].id_oposicion == '' ? 4 : cls2[0].id_oposicion
    }


    let response5 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaOpo", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
    });
    if (!response5.ok) {
      throw Error(response5.statusText);
    }
    let json5 = await response5.json();
    let cls5 = await json5;
    this.setState({ testTeoricoOpo: JSON.parse(JSON.stringify(cls5)) })

    let response6 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaOpo", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
    });
    if (!response6.ok) {
      throw Error(response6.statusText);
    }
    let json6 = await response6.json();
    let cls6 = await json6;
    this.setState({ testPracticoOpo: JSON.parse(JSON.stringify(cls6)) })

    // testTeoricoOpo




  }
  render() {

    const {
      infoTema, navEdit, busquedaLeyes, leySelectedNesd, temaIntervalosSave, actualizacionEstado, temasEnSincro, busquedaOpo, temasAll, sincroActiva, allVideoTemas, videoTemas, videoTema, categoriasJusticia, testTeoricoOpo, testPracticoOpo, temaTestTeorico, temaTestPractico, temaEjerDesarrollo, temaEjerDesarrolloS, temaEjerDesarrolloALL, temaEjerDesarrolloALLS, ejerDesarrollo
    } = this.state;

    // console.log('this.state.temasEnSincro')
    // console.log(this.state.temasEnSincro)


    // console.log('this.state.infoTema')
    // console.log(this.state.infoTema)

    const { tipoUser, idTema } = this.props;


    return (
      <div className='edtTemaNe-adm'>

        <div className='eTNe-a-content'>


          <div className="eTNe-a-c-tituloTema">
            <div className="eTNe-a-c-tituloTema-Name">
              {infoTema && infoTema.length > 0 && infoTema[0].titulo_tema}
            </div>
            <div className="eTNe-a-c-tituloTema-SSName">
              {infoTema && infoTema.length > 0 && infoTema[0].nombre_tema}
            </div>
          </div>


          <div className="eTNe-a-content-nav">
            <div id={'nav-0'} onClick={this.onHandleClick} className={navEdit == 0 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Info
            </div>
            <div id={'nav-1'} onClick={this.onHandleClick} className={navEdit == 1 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Leyes
            </div>
            <div id={'nav-3'} onClick={this.onHandleClick} className={navEdit == 3 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Vídeos
            </div>
            <div id={'nav-4'} onClick={this.onHandleClick} className={navEdit == 4 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Test Teorico
            </div>
            <div id={'nav-5'} onClick={this.onHandleClick} className={navEdit == 5 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Test Practico
            </div>
            <div id={'nav-6'} onClick={this.onHandleClick} className={navEdit == 6 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} style={{ borderRight: '1px solid grey' }}>
              Ejer Desarrollo
            </div>
            <div id={'nav-2'} onClick={this.onHandleClick} className={navEdit == 2 ? ("eTNe-a-content-navOption eTNe-a-content-navOption-Selec") : ("eTNe-a-content-navOption")} >
              Sincro
            </div>



            <i className="fa-light fa-circle-xmark" id="cerrarEdt" onClick={this.onHandleClick}></i>
          </div>

          {/* info tema */}
          {navEdit == 0 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <div className='eTNe-actC-BLAS-row-row'>

                  <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                  <i title="Borrar tema" className="fa-light fa-circle-trash" onClick={this.onHandleClick} id="borrarTemaSel"></i>
                </div>

              </div>

              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3" style={{ height: '305px' }}>

                <div className="eTNe-actC-BLAS-row">

                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].numeroTema}
                        autoComplete={"off"} id={"numTema"} type="text" name={"numTema"} ref={"numTema"} placeholder="Numero Tema" icon="fa-light fa-list-ol" onChange={this.onChangeInput} />
                      <p>Nº Tema</p>

                    </div>

                  </div>
                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                      <Input2
                        datalist="categoriasJusticia" onBlur={this.onChangeInputOut} value={
                          categoriasJusticia && infoTema && infoTema[0].categoria != null ? (
                            categoriasJusticia && categoriasJusticia.find(o => o.id_categoria == infoTema[0].categoria).nombre
                          ) : (
                            infoTema && infoTema.length > 0 && infoTema[0].nombreCategoria && infoTema[0].nombreCategoria
                          )}

                        autoComplete={"off"} id={"categoriaTema"} type="text" name={"categoriaTema"} ref={"categoriaTema"} placeholder="Categoria" icon="fa-light fa-group-arrows-rotate" onChange={this.onChangeInput} />
                      <p>Categoría</p>
                    </div>

                  </div>
                </div>
                <div className="eTNe-actC-BLAS-row">
                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].titulo_tema}
                        autoComplete={"off"} id={"tituloTema"} type="text" name={"tituloTema"} ref={"tituloTema"} placeholder="Titulo tema" icon="fa-light fa-file-signature" onChange={this.onChangeInput} />
                      <p>Titulo</p>

                    </div>

                  </div>
                </div>
                <div className="eTNe-actC-BLAS-row">
                  <div className="eTNe-actC-BLAS-row-row">


                    <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].nombre_tema}
                        autoComplete={"off"} id={"nombreTema"} type="text" name={"nombreTema"} ref={"nombreTema-"} placeholder="Nombre tema" icon="fa-light fa-file-signature" onChange={this.onChangeInput} />
                      <p>Nombre</p>

                    </div>

                  </div>
                </div>
                <div className="eTNe-actC-BLAS-row">



                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                      <Input2
                        datalist="busquedaOpo" onBlur={this.onChangeInputOut} value={
                          infoTema && infoTema[0].id_oposicion != null ? (
                            busquedaOpo.find(o => o.id_oposicion == infoTema[0].id_oposicion).nombre
                          ) : (
                            infoTema && infoTema.length > 0 && infoTema[0].nombreOpo && infoTema[0].nombreOpo
                          )}
                        autoComplete={"off"} id={"oposicionTema"} type="text" name={"oposicionTema"} ref={"oposicionTema"} placeholder="Oposicion tema" icon="fa-light fa-gavel" onChange={this.onChangeInput} />
                      <p>Oposición</p>

                    </div>

                  </div>

                </div>


                <div className="eTNe-actC-BLAS-row">



                  <div className="eTNe-actC-BLAS-row-row" style={{ width: '100%' }}>

                    {/* <div className="celdaInputDAPFBN" style={{ width: '100%' }}>
                      <textarea className="textAreaEdtTema" id="textAreaEdtTema" name="textAreaEdtTema"
                        onBlur={this.onChangeInputOut}
                        onChange={this.onChangeInput}
                        value={infoTema && infoTema[0].notaPreparador}
                      >

                      </textarea>

                      <p>Nota preparador</p>

                    </div> */}

                    <div className="editorPlantearrDuda" style={{ width: '100%', padding: '0px' }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={infoTema && infoTema[0].notaPreparador ? (infoTema[0].notaPreparador) : ('')}
                        onInit={editor => { }}
                        config={{
                          toolbar: ['bold', '|', 'link', '|', 'undo', 'redo'],
                          language: 'es'
                        }}
                        onChange={this.onChangeText}
                        onBlur={this.onBlurText}
                      />
                    </div>

                  </div>

                </div>

                {/* <div className="eTNe-actC-BLAS-row">

                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '116px' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].primeraVueltaTiempo}
                        autoComplete={"off"} id={"primeraVueltaTiempo"} type="time" name={"primeraVueltaTiempo"} ref={"primeraVueltaTiempo"} placeholder="Tiempo estudio" icon="fa-light fa-user-clock" onChange={this.onChangeInput} />
                      <p>Tº 1ª Vuelta</p>

                    </div>

                  </div>
                  <div className="eTNe-actC-BLAS-row-row">

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '116px' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].segundaVueltaTiempo}
                        autoComplete={"off"} id={"segundaVueltaTiempo"} type="time" name={"segundaVueltaTiempo"} ref={"segundaVueltaTiempo"} placeholder="Tiempo estudio" icon="fa-light fa-user-clock" onChange={this.onChangeInput} />
                      <p>Tº 2ª Vuelta</p>
                    </div>

                  </div>
                  <div className="eTNe-actC-BLAS-row-row">


                    <div className="celdaInputDAPFBN" style={{ width: '116px' }}>
                      <Input2
                        onBlur={this.onChangeInputOut} value={infoTema && infoTema[0].repasoVueltaTiempo}
                        autoComplete={"off"} id={"repasoVueltaTiempo"} type="time" name={"repasoVueltaTiempo"} ref={"repasoVueltaTiempo-"} placeholder="Tiempo estudio" icon="fa-light fa-user-clock" onChange={this.onChangeInput} />
                      <p>Tº Repasos</p>

                    </div>

                  </div>
                </div> */}


                <datalist id="categoriasJusticia">{
                  categoriasJusticia && categoriasJusticia.map((opo, key) =>
                    <option key={key} value={opo.nombre}>
                      Aux: {opo.temasAuxilio}; Tra: {opo.temasTramitacion}; Ges: {opo.temasGestion}; Ges PI: {opo.temasGestionPI};
                    </option>
                  )
                }</datalist>
                <datalist id="busquedaOpo">{
                  busquedaOpo && busquedaOpo.map((opo, key2d4) =>
                    <option key={key2d4} value={opo.nombre}></option>
                  )
                }</datalist>

              </div>





            </div>
          ) : null}

          {/* Leyes */}
          {navEdit == 1 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <div className='eTNe-actC-BLAS-row-row'>

                  <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                  <i title="Borrar ley e intervalos seleccionada" className="fa-light fa-circle-trash" onClick={this.onHandleClick} id="deleteLey"></i>
                  <i title="Añadir ley e intervalos." className="fa-light fa-circle-plus" onClick={this.onHandleClick} id="addLey"></i>
                </div>

                {temaIntervalosSave && temaIntervalosSave.map((temas32, key12) =>

                  <div className={key12 == leySelectedNesd ? ('NAVlCIRCLEz sevjdj32') : ('NAVlCIRCLEz')} title={temas32.nombreLey} id={"leySeletdgfr-" + key12} onClick={this.onHandleClick}>
                    {temas32.siglasLey}
                  </div>
                )}


              </div>

              {temaIntervalosSave && temaIntervalosSave != undefined && temaIntervalosSave.length > 0 ? (
                <div className="eTNe-actC-head">

                  <div className="celdaInputDAPFBN">
                    <Input2
                      datalist="busquedaLeyes" onBlur={this.onChangeInputOut} value={temaIntervalosSave[leySelectedNesd].siglasLey}
                      autoComplete={"off"} id={"leyIntervalo-"} type="text" name={"leyIntervalo-"} ref={"leyIntervalo-"} placeholder="Nombre ley" icon="fa-light fa-scale-balanced" onChange={this.onChangeInput} />
                    <p>Legislación</p>
                    <datalist id="busquedaLeyes">{
                      busquedaLeyes && busquedaLeyes.map((ley, key234) =>
                        <option key={key234} value={ley.siglas_ley}>
                          {ley.nombre_ley}
                        </option>
                      )
                    }</datalist>
                  </div>

                  {temaIntervalosSave[leySelectedNesd].idLey != null ? (
                    <i title="Añadir intervalo de articulos" className="fa-light fa-circle-plus" onClick={this.onHandleClick} id="addIntervaloArticulos"></i>
                  ) : null}

                </div>
              ) : null}
              {temaIntervalosSave && temaIntervalosSave != undefined && temaIntervalosSave.length > 0 ? (
                <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                  {temaIntervalosSave && temaIntervalosSave[leySelectedNesd].intervalosArt.length > 0 && temaIntervalosSave[leySelectedNesd].intervalosArt.map((intLey, keyfhj67) =>

                    <div className="eTNe-actC-BLAS-row">

                      <i className="fa-light fa-circle-trash" id={"borrarIntervalo-" + leySelectedNesd + "-" + keyfhj67} onClick={this.onHandleClick}></i>
                      <div className="eTNe-actC-BLAS-row-row">
                        <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                          <Input2 value={intLey.art_inicio} onBlur={this.onChangeInputOut}
                            autoComplete={"off"} id={"artInicio-" + leySelectedNesd + "-" + keyfhj67} type="text" name={"artInicio-" + leySelectedNesd + "-" + keyfhj67} ref={"artInicio-" + leySelectedNesd + "-" + keyfhj67} placeholder="Art. Inicio" icon="fa-light fa-gavel" onChange={this.onChangeInput} />
                          <p>Art. Inicio</p>
                        </div>

                        <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                          <Input2 value={intLey.art_fin} onBlur={this.onChangeInputOut}
                            autoComplete={"off"} id={"artFin-" + leySelectedNesd + "-" + keyfhj67} type="text" name={"artFin-" + leySelectedNesd + "-" + keyfhj67} ref={"artFin-" + leySelectedNesd + "-" + keyfhj67} placeholder="Art. Fin" icon="fa-light fa-gavel" onChange={this.onChangeInput} />
                          <p>Art. Fin</p>
                        </div>
                      </div>

                    </div>

                  )}



                </div>
              ) : null}



            </div>
          ) : null}

          {/* Temas sincronizados */}
          {navEdit == 2 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>

                <i class="fa-light fa-circle-plus" id="addTEMAsync" onClick={this.onHandleClick}></i>
              </div>


              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                {temasEnSincro && temasEnSincro.length > 0 && temasEnSincro.map((temSic3, keygj4) =>

                  <div className="eTNe-actC-BLAS-row">

                    <i className="fa-light fa-circle-trash" id={"borrarTemaSync-" + keygj4} onClick={this.onHandleClick}></i>
                    <div className="eTNe-actC-BLAS-row-row">
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                        <Input2 datalist="busquedaOpo" value={temSic3.nombreOpo}
                          autoComplete={"off"} id={"opo-" + keygj4} type="text" name={"opo-" + keygj4} ref={"opo-" + keygj4} placeholder="Oposicion" icon="fa-light fa-gavel" onChange={this.onChangeInput} />
                        <p>Oposición</p>

                      </div>

                      {temSic3.nombreOpo != null && temSic3.nombreOpo != '' && temSic3.idOpo != null ? (
                        <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                          <Input2 datalist={"busquedaTemasFiltro-" + keygj4} value={temSic3.titulo} onBlur={this.onChangeInputOut}
                            autoComplete={"off"} id={"tema-" + keygj4} type="text" name={"tema-" + keygj4} ref={"tema-" + keygj4} placeholder="Tema" icon="fa-light fa-book" onChange={this.onChangeInput} />
                          <p>Tema</p>
                          <i title="Sincronizar todo el tema desde 0" className="fa-duotone fa-rotate postionEdtTemaSunctotal" id={"TemaSyncTotal-" + keygj4} onClick={this.onHandleClick}></i>
                          <datalist id={"busquedaTemasFiltro-" + keygj4}>{
                            temasAll && temasAll.filter(p => p.id_oposicion == temSic3.idOpo).map((te, keyhyt) =>
                              <option key={keyhyt} value={te.titulo_tema}>{te.nombre_tema}</option>
                            )
                          }</datalist>
                        </div>

                      ) : null}
                    </div>

                  </div>

                )}
                <datalist id="busquedaOpo">{
                  busquedaOpo && busquedaOpo.map((opo, key2d4) =>
                    <option key={key2d4} value={opo.nombre}></option>
                  )
                }</datalist>



              </div>




            </div>
          ) : null}
          {/* Videos */}
          {navEdit == 3 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                <i class="fa-light fa-circle-plus" id="addVideo" onClick={this.onHandleClick}></i>
              </div>


              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                {videoTema && videoTema.length > 0 && videoTema.map((vvSic3, keygj4fgft) =>

                  <div className="eTNe-actC-BLAS-row">

                    <i className="fa-light fa-circle-trash" id={"borrarVideo-" + keygj4fgft} onClick={this.onHandleClick}></i>
                    <div className="eTNe-actC-BLAS-row-row">

                      <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                        <Input2 datalist={"busquedaVideos"} value={vvSic3.titulo} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"video-" + keygj4fgft} type="text" name={"video-" + keygj4fgft} ref={"video-" + keygj4fgft} placeholder="Video" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Video</p>

                      </div>


                    </div>

                  </div>

                )}
                <datalist id="busquedaVideos">{
                  allVideoTemas && allVideoTemas.map((v, key223d4) =>
                    <option key={key223d4} value={v.titulo}>{v.link}</option>
                  )
                }</datalist>



              </div>




            </div>
          ) : null}

          {/* test teorico */}
          {navEdit == 4 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                <i class="fa-light fa-circle-plus" id="addTestTeorico" onClick={this.onHandleClick}></i>
              </div>


              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                {temaTestTeorico && temaTestTeorico.length > 0 && temaTestTeorico.map((vvSics3, keyg1) =>

                  <div className="eTNe-actC-BLAS-row">

                    <i className="fa-light fa-circle-trash" id={"borrarTestTeorico-" + keyg1} onClick={this.onHandleClick}></i>
                    <div className="eTNe-actC-BLAS-row-row">

                      <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                        <Input2 datalist={"BusquedaTestTeoricoOpo"} value={vvSics3.nombre_test} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"testTeorico-" + keyg1} type="text" name={"testTeorico-" + keyg1} ref={"testTeorico-" + keyg1} placeholder="Nombre Test" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Test teórico</p>

                      </div>

                      <div className="celdaInputDAPFBN" style={{ width: '100px' }}>
                        <Input2 value={vvSics3.vuelta} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"testTeoricoVuelta-" + keyg1} type="text" name={"testTeoricoVuelta-" + keyg1} ref={"testTeoricoVuelta-" + keyg1} placeholder="Nº Vuelta" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Nº Vuelta</p>

                      </div>


                    </div>

                  </div>

                )}
                <datalist id="BusquedaTestTeoricoOpo">{
                  testTeoricoOpo && testTeoricoOpo.map((v, key223d4) =>
                    <option key={key223d4} value={v.nombre_test}></option>
                  )
                }</datalist>



              </div>




            </div>
          ) : null}

          {/* test practico */}
          {navEdit == 5 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                <i class="fa-light fa-circle-plus" id="addTestPractico" onClick={this.onHandleClick}></i>
              </div>


              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                {temaTestPractico && temaTestPractico.length > 0 && temaTestPractico.map((vvSics3, keyg1) =>

                  <div className="eTNe-actC-BLAS-row">

                    <i className="fa-light fa-circle-trash" id={"borrarTestPractico-" + keyg1} onClick={this.onHandleClick}></i>
                    <div className="eTNe-actC-BLAS-row-row">

                      <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                        <Input2 datalist={"BusquedaTestPracticoOpo"} value={vvSics3.nombre_test} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"testPractico-" + keyg1} type="text" name={"testPractico-" + keyg1} ref={"testPractico-" + keyg1} placeholder="Nombre Test" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Test práctico</p>

                      </div>

                      <div className="celdaInputDAPFBN" style={{ width: '100px' }}>
                        <Input2 value={vvSics3.vuelta} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"testPracticoVuelta-" + keyg1} type="text" name={"testPracticoVuelta-" + keyg1} ref={"testPracticoVuelta-" + keyg1} placeholder="Nº Vuelta" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Nº Vuelta</p>

                      </div>


                    </div>

                  </div>

                )}
                <datalist id="BusquedaTestPracticoOpo">{
                  testPracticoOpo && testPracticoOpo.map((v, key223d4) =>
                    <option key={key223d4} value={v.nombre_test}></option>
                  )
                }</datalist>



              </div>




            </div>
          ) : null}

          {/* ejer desarrollo */}
          {navEdit == 6 ? (
            <div className="eTNe-a-c-edtContnet">
              <div className='navLeyeeTNe-a-c-edt'>
                <i title="Sincronización de cambios entre temas. ¡Verde activado!" className={"fa-light fa-circle-bolt"} style={sincroActiva != null ? ({ color: '#4f8832' }) : ({ color: '#8d5050' })} onClick={this.onHandleClick} id="sincroActiva"></i>
                <i class="fa-light fa-circle-plus" id="addEjerDesarrollo" onClick={this.onHandleClick}></i>
              </div>


              <div className="eTNe-actC-bodyLeyesArtvlkdh" id="style-3">

                {temaEjerDesarrollo && temaEjerDesarrollo.length > 0 && temaEjerDesarrollo.map((vvSics3, keyg1) =>

                  <div className="eTNe-actC-BLAS-row">

                    <i className="fa-light fa-circle-trash" id={"borrarEjerDesarrollo-" + keyg1} onClick={this.onHandleClick}></i>
                    <div className="eTNe-actC-BLAS-row-row">

                      <div className="celdaInputDAPFBN" style={{ width: 'unset' }}>
                        <Input2 datalist={"BusquedaEjerDesarrolloOpo"} value={vvSics3.nombre} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"ejerDesarrollo-" + keyg1} type="text" name={"ejerDesarrollo-" + keyg1} ref={"ejerDesarrollo-" + keyg1} placeholder="Nombre Ejer" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Ejer Desarrollo</p>

                      </div>

                      <div className="celdaInputDAPFBN" style={{ width: '100px' }}>
                        <Input2 value={vvSics3.vuelta} onBlur={this.onChangeInputOut}
                          autoComplete={"off"} id={"ejerDesarrolloVuelta-" + keyg1} type="text" name={"ejerDesarrolloVuelta-" + keyg1} ref={"ejerDesarrolloVuelta-" + keyg1} placeholder="Nº Vuelta" icon="fa-light fa-video" onChange={this.onChangeInput} />
                        <p>Nº Vuelta</p>

                      </div>


                    </div>

                  </div>

                )}
                <datalist id="BusquedaEjerDesarrolloOpo">{
                  ejerDesarrollo && ejerDesarrollo.map((v, key223d4) =>
                    <option key={key223d4} value={v.nombre}></option>
                  )
                }</datalist>



              </div>




            </div>
          ) : null}




          {actualizacionEstado != null ? (
            <div className='cartelActualizandoFull'>

              {actualizacionEstado != null && actualizacionEstado == 'subiendo' ? (
                <div className='calAF-Row'>
                  <i className="fa-light fa-arrows-rotate iAnimationRotate"></i>
                  <p>Actualizando</p>
                </div>
              ) : null}
              {actualizacionEstado != null && actualizacionEstado == 'completado' ? (
                <div className='calAF-Row'>
                  <i style={{ color: '#4f8832' }} className="fa-light fa-cloud-check iAnimationZoom"></i>
                  <p>Actualizado</p>
                </div>
              ) : null}
            </div>
          ) : null}

          {actualizacionEstado != null && actualizacionEstado == 'sincronizando' ?
            (sincroActiva != null ? (
              <div className='cartelActualizandoFull'>
                <div className='calAF-Row'>
                  <i style={{ color: '#4f8832' }} className="fa-light fa-circle-bolt iAnimationZoom"></i>
                  <p>Cambios Sincronizados</p>
                </div>
              </div>
            ) : (
              <div className='cartelActualizandoFull'>
                <div className='calAF-Row'>
                  <i style={{ color: '#8d5050' }} className="fa-light fa-circle-bolt iAnimationZoom"></i>
                  <p>Cambios solo en este tema</p>
                </div>
              </div>
            )) : null
          }
        </div>

      </div >
    );
  }
}

// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    temasFacturados: state.user.temasFacturados,
    tipoUser: state.user.tipoUser
  }
}


export default connect(mapStateToProps, null)(CambioLeyTemaAdm);
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import moment from 'moment';
import $ from 'jquery';

//Assets
import './css/expedienteUser.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { loadTestJusticia, loadCasoPractico, puntosRes, puntosResCP } from '../../actions/userAction';
// Utils respuestaSbr
import { isDefined } from '../../lib/utils/is';
import {  comprobarRespuestas, respuestaSbr, corregirTest } from '../../lib/utils/cargarTestJusticia';
// Components

import Input2 from '../Inputs/Input2';
import InputSelect from '../Inputs/InputSelect';
import Submit from '../Inputs/Submit';
import BotonMedio from '../Inputs/BotonMedio';

class ExpedienteAlumno extends Component {
  static propTypes = {
    idAlumno: PropTypes.number.isRequired,
    idClase: PropTypes.number
  };
  constructor(props){
    super(props);
    this.state = {
      verTestJusticia: false,
      verCasoPractico: false,
      verExamGes: false,
      testJusticiaHistorialSave: undefined,
      infoBloquesTemas: undefined,

    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.bienMalBlanco = this.bienMalBlanco.bind(this)
    this.bienMalBlancoCP = this.bienMalBlancoCP.bind(this)
    this.listadoArtErrores = this.listadoArtErrores.bind(this)
  }

  async onChangeInput(e) {

    if(e.target.name === 'semanaFacturadaSelectedTestJus'){
      let value = e.target.value

      let semanaSelected = this.state.semanasFacturadas.find( s => moment(s.fechaClase).format('DD-MM-YY') == value)

      if(semanaSelected != undefined && semanaSelected != '' && semanaSelected != ' '){
        let expFiltradoJus = this.state.testJusticiaHistorialSave.filter(exp => exp.idPrg == semanaSelected.idPrg)

        let expFiltradoCP = this.state.casoPractioHistorialSave.filter(exp => exp.idPrg == semanaSelected.idPrg)

        let expFiltradoPregCortas = this.state.examGesHistorialSave.filter(exp => exp.idPrg == semanaSelected.idPrg)

        if(expFiltradoJus && expFiltradoJus.length > 0) {
          this.setState({ testJusticiaHistorial: expFiltradoJus })
        } else {
          this.setState({ testJusticiaHistorial: [] })
        }

        if(expFiltradoCP && expFiltradoCP.length > 0) {
          this.setState({ casoPractioHistorial: expFiltradoCP })
        } else {
          this.setState({ casoPractioHistorial: [] })

        }

        if(expFiltradoPregCortas && expFiltradoPregCortas.length > 0) {
          this.setState({ examGesHistorial: expFiltradoPregCortas })
        } else {
          this.setState({ examGesHistorial: [] })

        }



      } else {
        this.setState({ testJusticiaHistorial: this.state.testJusticiaHistorialSave, casoPractioHistorial: this.state.casoPractioHistorialSave, examGesHistorial: this.state.examGesHistorialSave})
      }


    }
  }
  async handleOnSubmit(e){
    if(e.target.id == 'verTestJusticiaHistorial'){
      this.setState({verTestJusticia: true, verCasoPractico: false, verExamGes: false})
    } else if(e.target.id == 'verCPHistorial'){
      this.setState({verTestJusticia: false, verCasoPractico: true, verExamGes: false})
    } else if (e.target.id.split('-')[0] == 'verTest'){
      let numExpediente = await e.target.id.split('-')[1];
      let testPuntuaciones = this.state.testJusticiaHistorial
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      try{
        let testNew = []
        let data1 = { idTest: idTest , idExp: idExp}
  			let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
  				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
  				if (!response1.ok) {
  					throw Error(response1.statusText);
  				}
  			let json1 = await response1.json();
  			let cls1 = await json1;
        if(cls1 != undefined && cls1.length > 0){
  				for (var i = 0; i < await cls1.length; i++) {

            let articuloRevisado = ''
              if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
                let artDFG= cls1[i].articulo.split('.y.')
                for (var k = 0; k < artDFG.length; k++) {
                  if(k != artDFG.length-1 && k != artDFG.length-2){
                    articuloRevisado = articuloRevisado + artDFG[k] + ' , '
                  } else if(k == artDFG.length-2){
                    articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
                  } else if(k == artDFG.length-1){
                    articuloRevisado = articuloRevisado + artDFG[k]
                  }
                }
              } else {
                articuloRevisado = cls1[i].articulo
              }

  					 testNew.push({
              dudosa: cls1[i].dudosa,
  						idTest: cls1[i].idTest, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
  						pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo:articuloRevisado,
  						idLey: cls1[i].idLey,
  						siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
  						nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
  						idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
  						respuestas: []
  					})
              let r1Sbr = cls1[i].R1
              if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
              }

              testNew[testNew.length-1].respuestas.push({
                idR: cls1[i].idR1,
                respuesta: r1Sbr,
                validez: cls1[i].validezR1,
                subrayado: cls1[i].subrayado1,
                nota: cls1[i].nota1,
                valorUser: cls1[i].r1ValorUser
              })
              let r2Sbr = cls1[i].R2
              if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
              }
              testNew[testNew.length-1].respuestas.push({
                idR: cls1[i].idR2,
                respuesta: r2Sbr,
                validez: cls1[i].validezR2,
                subrayado: cls1[i].subrayado2,
                nota: cls1[i].nota2,
                valorUser: cls1[i].r2ValorUser
              })
              let r3Sbr = cls1[i].R3
              if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
              }
              testNew[testNew.length-1].respuestas.push({
                idR: cls1[i].idR3,
                respuesta: r3Sbr,
                validez: cls1[i].validezR3,
                subrayado: cls1[i].subrayado3,
                nota: cls1[i].nota3,
                valorUser: cls1[i].r3ValorUser
              })
              let r4Sbr = cls1[i].R4
              if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
              }
              testNew[testNew.length-1].respuestas.push({
                idR: cls1[i].idR4,
                respuesta: r4Sbr,
                validez: cls1[i].validezR4,
                subrayado: cls1[i].subrayado4,
                nota: cls1[i].nota4,
                valorUser: cls1[i].r4ValorUser
              })
  				}
          if(testNew != undefined){
            let testRespuestasBien = await comprobarRespuestas(testNew)
            await this.props.loadTestJusticia(undefined)
            await this.props.loadCasoPractico(undefined,'htmlText')
            await this.props.loadTestJusticia(testRespuestasBien)

            await this.props.history.push('/revisionTest');
          }
        }
      } catch(e){console.log(e)}
    } else if (e.target.id.split('-')[0] == 'verCasoPractico'){
      try{

        let numExpediente = await e.target.id.split('-')[1];
        let testPuntuaciones = this.state.casoPractioHistorial
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp

          let testNew = []
          let htmlText = undefined
          let data1 = { idTest: idTest , idExp: idExp}
    			let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
    				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
    				if (!response1.ok) {
    					throw Error(response1.statusText);
    				}
    			let json1 = await response1.json();
    			let cls1 = await json1;

          if(cls1 != undefined && cls1.length > 0){
            htmlText = cls1[0].textHtml
    				for (var i = 0; i < await cls1.length; i++) {

              let articuloRevisado = ''
                if(cls1[i].articulo.split('.y.') != undefined && cls1[i].articulo.split('.y.').length > 0){
                  let artDFG= cls1[i].articulo.split('.y.')
                  for (var k = 0; k < artDFG.length; k++) {
                    if(k != artDFG.length-1 && k != artDFG.length-2){
                      articuloRevisado = articuloRevisado + artDFG[k] + ' , '
                    } else if(k == artDFG.length-2){
                      articuloRevisado = articuloRevisado + artDFG[k] +  ' y '
                    } else if(k == artDFG.length-1){
                      articuloRevisado = articuloRevisado + artDFG[k]
                    }
                  }
                } else {
                  articuloRevisado = cls1[i].articulo
                }

              testNew.push({
               idTest: cls1[i].idTest, numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
               pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: articuloRevisado,
               idLey: cls1[i].idLey,
               siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
               nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
               idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
               respuestas: []
             })
               let r1Sbr = cls1[i].R1
               if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                 r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
               }

               testNew[testNew.length-1].respuestas.push({
                 idR: cls1[i].idR1,
                 respuesta: r1Sbr,
                 validez: cls1[i].validezR1,
                 subrayado: cls1[i].subrayado1,
                 nota: cls1[i].nota1,
                 valorUser: cls1[i].r1ValorUser
               })
               let r2Sbr = cls1[i].R2
               if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                 r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
               }
               testNew[testNew.length-1].respuestas.push({
                 idR: cls1[i].idR2,
                 respuesta: r2Sbr,
                 validez: cls1[i].validezR2,
                 subrayado: cls1[i].subrayado2,
                 nota: cls1[i].nota2,
                 valorUser: cls1[i].r2ValorUser
               })
               let r3Sbr = cls1[i].R3
               if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                 r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
               }
               testNew[testNew.length-1].respuestas.push({
                 idR: cls1[i].idR3,
                 respuesta: r3Sbr,
                 validez: cls1[i].validezR3,
                 subrayado: cls1[i].subrayado3,
                 nota: cls1[i].nota3,
                 valorUser: cls1[i].r3ValorUser
               })
               let r4Sbr = cls1[i].R4
               if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                 r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
               }
               testNew[testNew.length-1].respuestas.push({
                 idR: cls1[i].idR4,
                 respuesta: r4Sbr,
                 validez: cls1[i].validezR4,
                 subrayado: cls1[i].subrayado4,
                 nota: cls1[i].nota4,
                 valorUser: cls1[i].r4ValorUser
               })
    				}
            if(testNew != undefined && htmlText != undefined){
              let testRespuestasBien = await comprobarRespuestas(testNew)

              // borrar caso y test load!
              await this.props.loadTestJusticia(undefined)
              await this.props.loadCasoPractico(undefined,'htmlText')
              await this.props.loadCasoPractico(testRespuestasBien, htmlText)
              await this.props.history.push('/revisionTest');
            }
          }
        } catch(e){console.log(e)}

    } else if (e.target.id.split('-')[0] == 'infoTest'){
      let key = e.target.id.split('-')[1]
      $('#infoTestH-'+key).fadeToggle(700).css("display", "flex");
      $('#infoTestH3-'+key).fadeToggle(700).css("display", "flex");

      if(this.state.verTestJusticia == true){
        let numExpediente = key;
        let testPuntuaciones = this.state.testJusticiaHistorial
        let busquedaLeyes = this.state.busquedaLeyes
        let idTest = await testPuntuaciones[numExpediente].idTest
        let idExp = await testPuntuaciones[numExpediente].idExp
        let idClase = await testPuntuaciones[numExpediente].idClase
        let testNew = []

        let listadoErroresExiste = testPuntuaciones[numExpediente].listadoErrores
        if(listadoErroresExiste == undefined ){

          let data1 = { idTest: idTest , idExp: idExp }

          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
          let json1 = await response1.json();
          let cls1 = await json1;

          if(cls1 != undefined && cls1.length > 0){
            for (var i = 0; i < await cls1.length; i++) {
               testNew.push({
                dudosa: cls1[i].dudosa,
                idTest: cls1[i].idTest, idClase: idClase,numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
                let r1Sbr = cls1[i].R1
                if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                  r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
                }

                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                  r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                  r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                  r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
            }

          }

          let test = await testNew;

          let resultadosAds = await this.listadoArtErrores(test)

          let filtroErrores = []
          for (var i = 0; i < resultadosAds.length; i++) {
            let pregunta = resultadosAds[i]

            if(filtroErrores.length > 0){

              let locaSiglas = await filtroErrores.findIndex(c => c.siglasLey == pregunta.siglasLey)
              if(locaSiglas != -1){
                filtroErrores[locaSiglas].articulos.push({
                  art: pregunta.articulo,
                  numPreg: pregunta.numPreg
                })
              } else {
                filtroErrores.push({
                  siglasLey:  pregunta.siglasLey,
                  nombreLey: pregunta.nombreLey,
                  articulos: [{art: pregunta.articulo, numPreg: pregunta.numPreg}]
                })
              }

            } else {
              filtroErrores.push({
                siglasLey:  pregunta.siglasLey,
                nombreLey: pregunta.nombreLey,
                articulos: [{art: pregunta.articulo, numPreg: pregunta.numPreg}]
              })
            }

          }

          for (var j = 0; j < filtroErrores.length; j++) {
            filtroErrores[j].articulos = filtroErrores[j].articulos.sort((a, b) => a.art.split('.')[0] - b.art.split('.')[0])
          }

          testPuntuaciones[numExpediente].listadoErrores = filtroErrores
          await this.setState({testJusticiaHistorial: testPuntuaciones})
          $('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        } else {
          $('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        }

        } else if (this.state.verCasoPractico == true) {
          let numExpediente = key;
          let testPuntuaciones = this.state.casoPractioHistorial
          let busquedaLeyes = this.state.busquedaLeyes
          let idTest = await testPuntuaciones[numExpediente].idTest
          let idExp = await testPuntuaciones[numExpediente].idExp
          let idClase = await testPuntuaciones[numExpediente].idClase
          let testNew = []

          let listadoErroresExiste = testPuntuaciones[numExpediente].listadoErrores
          if(listadoErroresExiste == undefined){

          let data1 = { idTest: idTest , idExp: idExp}
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
          let json1 = await response1.json();
          let cls1 = await json1;
          if(cls1 != undefined && cls1.length > 0){
            for (var i = 0; i < await cls1.length; i++) {
               testNew.push({
                idTest: cls1[i].idTest, idClase: idClase,numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
                let r1Sbr = cls1[i].R1
                if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                  r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
                }

                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                  r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                  r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                  r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
            }

          }

          let test = await testNew;
          let resultadosAds = await this.listadoArtErrores(test)


          let filtroErrores = []
          for (var i = 0; i < resultadosAds.length; i++) {
            let pregunta = resultadosAds[i]

            if(filtroErrores.length > 0){

              let locaSiglas = await filtroErrores.findIndex(c => c.siglasLey == pregunta.siglasLey)
              if(locaSiglas != -1){
                filtroErrores[locaSiglas].articulos.push({
                  art: pregunta.articulo,
                  numPreg: pregunta.numPreg
                })
              } else {
                filtroErrores.push({
                  siglasLey:  pregunta.siglasLey,
                  nombreLey: pregunta.nombreLey,
                  articulos: [{art: pregunta.articulo, numPreg: pregunta.numPreg}]
                })
              }

            } else {
              filtroErrores.push({
                siglasLey:  pregunta.siglasLey,
                nombreLey: pregunta.nombreLey,
                articulos: [{art: pregunta.articulo, numPreg: pregunta.numPreg}]
              })
            }

          }

          for (var j = 0; j < filtroErrores.length; j++) {
            filtroErrores[j].articulos = filtroErrores[j].articulos.sort((a, b) => a.art.split('.')[0] - b.art.split('.')[0])
          }

          testPuntuaciones[numExpediente].listadoErrores = filtroErrores
          await this.setState({casoPractioHistorial: testPuntuaciones})
          $('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        } else {
          $('#infoTestH2-'+key).fadeToggle(700).css("display", "flex");
        }

      }

    } else if (e.target.id.split('-')[0] == 'revisarPuntuacion'){
      let key = e.target.id.split('-')[1]
      // sacar resultado test.
      let numExpediente = await e.target.id.split('-')[1];
      let testPuntuaciones = this.state.testJusticiaHistorial
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      let idClase = await testPuntuaciones[numExpediente].idClase
      let testNew = []
      let name = "¿Esta seguro de Revisar la Puntuación del Test?";
      let content = document.createElement('div');
      content.innerHTML = `Se realizara una comprobación de la puntuación del test y se actualizara la puntuación.`
      content.style.textAlign = "left";

      swal({
      	title: name,
      	content: content,
      	icon: "info",
      	buttons: {
      		cancel: "Cancelar",
      		catch: {
      			text: "Recalcular Puntuación!",
      			value: "catch",
      		},
      		defeat: false,
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
    }).then( async (value) => {
      	switch (value) {

      		case "catch":
          let data1 = { idTest: idTest , idExp: idExp}
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestUser", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
          let json1 = await response1.json();
          let cls1 = await json1;
          if(cls1 != undefined && cls1.length > 0){
            for (var i = 0; i < await cls1.length; i++) {
               testNew.push({
                dudosa: cls1[i].dudosa,
                idTest: cls1[i].idTest, idClase: idClase,numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
                let r1Sbr = cls1[i].R1
                if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                  r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
                }

                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                  r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                  r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                  r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
            }

          }


          let test = await testNew;
          let resultadosAds = await this.bienMalBlanco(test)
          let data = {
          correcta: resultadosAds.correcta,
          mal: resultadosAds.mal,
          enBlanco: resultadosAds.enBlanco,
          puntuacion: resultadosAds.puntuacion,
          puntuacionReal: resultadosAds.puntuacionReal,
          idTestExp: idExp
          }

          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/UpdateTestPuntuacion", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if(cls2 != undefined){
            let content = document.createElement('div');
            content.innerHTML = `
            <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
            Puntuación: <b>`+data.puntuacion+`</b>.</br>
            <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
            Correctas: <b>`+data.correcta+`</b>.</br>
            <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
            Erroneas: <b>`+data.mal+`.</b></br>
            <i style="color: Dodgerblue;" class="far fa-circle"></i>
            En Blanco: <b>`+data.enBlanco+`</b>. </br></br>
            <b>Se recargara la web en 6 segundos para ver los cambios.</b>
            `
            content.style.textAlign = "left";
            swal({
              title: 'Puntuación actualizada',
              content: content,
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })
            setTimeout(
            function() {
                window.location.reload();
            }.bind(this),6000);
          }

      			break;

      		default:

      	}
      });


    } else if (e.target.id.split('-')[0] == 'revisarPuntuacionCP'){
      let key = e.target.id.split('-')[1]
      // sacar resultado test.
      let numExpediente = await e.target.id.split('-')[1];
      let testPuntuaciones = this.state.casoPractioHistorial
      let busquedaLeyes = this.state.busquedaLeyes
      let idTest = await testPuntuaciones[numExpediente].idTest
      let idExp = await testPuntuaciones[numExpediente].idExp
      let idClase = await testPuntuaciones[numExpediente].idClase
      let testNew = []
      let name = "¿Esta seguro de Revisar la Puntuación del Test?";
      let content = document.createElement('div');
      content.innerHTML = `Se realizara una comprobación de la puntuación del test y se actualizara la puntuación.`
      content.style.textAlign = "left";

      swal({
      	title: name,
      	content: content,
      	icon: "info",
      	buttons: {
      		cancel: "Cancelar",
      		catch: {
      			text: "Recalcular Puntuación!",
      			value: "catch",
      		},
      		defeat: false,
      },
      closeOnEsc: false,
      closeOnClickOutside: false,
    }).then( async (value) => {
      	switch (value) {

      		case "catch":
          let data1 = { idTest: idTest , idExp: idExp}
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCasoPUser", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
          let json1 = await response1.json();
          let cls1 = await json1;
          if(cls1 != undefined && cls1.length > 0){
            for (var i = 0; i < await cls1.length; i++) {
               testNew.push({
                idTest: cls1[i].idTest, idClase: idClase,numPreg: cls1[i].numPreg, idPreg: cls1[i].idPregunta,
                pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                idLey: cls1[i].idLey,
                siglasLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).siglas_ley,
                nombreLey: busquedaLeyes.find(l=> l.id_ley == cls1[i].idLey).nombre_ley,
                idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                respuestas: []
              })
                let r1Sbr = cls1[i].R1
                if(cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined){
                  r1Sbr = await respuestaSbr(cls1[i].R1,cls1[i].subrayado1)
                }

                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUsuario: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if(cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined){
                  r2Sbr = await respuestaSbr(cls1[i].R2,cls1[i].subrayado2)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUsuario: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if(cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined){
                  r3Sbr = await respuestaSbr(cls1[i].R3,cls1[i].subrayado3)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUsuario: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if(cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined){
                  r4Sbr = await respuestaSbr(cls1[i].R4,cls1[i].subrayado4)
                }
                testNew[testNew.length-1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUsuario: cls1[i].r4ValorUser
                })
            }

          }


          let test = await testNew;
          let resultadosAds = await this.bienMalBlancoCP(test)
          let data = {
          correcta: resultadosAds.correcta,
          mal: resultadosAds.mal,
          enBlanco: resultadosAds.enBlanco,
          puntuacion: resultadosAds.puntuacion,
          puntuacionReal: resultadosAds.puntuacionReal,
          idTestExp: idExp
          }

          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/UpdateTestPuntuacionCP", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if(cls2 != undefined){
            let content = document.createElement('div');
            content.innerHTML = `
            <i style="color: Dodgerblue;" style="color: Dodgerblue;" class="fas fa-flag"></i>
            Puntuación: <b>`+data.puntuacion+`</b>.</br>
            <i style="color: #3fa92c;" class="fas fa-check-circle"></i>
            Correctas: <b>`+data.correcta+`</b>.</br>
            <i style="color: #f11e1e;" class="fas fa-times-circle"></i>
            Erroneas: <b>`+data.mal+`.</b></br>
            <i style="color: Dodgerblue;" class="far fa-circle"></i>
            En Blanco: <b>`+data.enBlanco+`</b>. </br></br>
            <b>Se recargara la web en 6 segundos para ver los cambios.</b>
            `
            content.style.textAlign = "left";
            swal({
              title: 'Puntuación actualizada',
              content: content,
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })
            setTimeout(
            function() {
                window.location.reload();
            }.bind(this),6000);
          }

      			break;

      		default:

      	}
      });


    } else if (e.target.id.split('-')[0] == 'verExamGesHis'){
      this.setState({verTestJusticia: false, verCasoPractico: false, verExamGes: true})
    } else if (e.target.id.split('-')[0] == 'downloadMiExamen'){
      let loca = e.target.id.split('-')[1]
      let pathFile = this.state.examGesHistorial.find(t => t.id_exp == this.state.examGesHistorial[loca].id_exp).linkArchivoAlumno
      if(pathFile != undefined){
        swal('Iniciando descarga, espere.','Si en 20 segundos no se inicia vuelva a darle clic','info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            responseType: 'blob'
            }).then(res =>  {
                if (res.status >= 400) { throw new Error("Bad res from server");}
                return res.blob();
            }).then(blob => {
              saveAs(blob,nameFile);
            }).catch(function(err) {console.log(err)
              alert("Link no valido para descarga, reportelo, gracias.")
            });
      }
    } else if (e.target.id.split('-')[0] == 'downloadExamenResuelto'){
      let loca = e.target.id.split('-')[1]
      let examen = this.state.examGesHistorial.find(t => t.id_exp == this.state.examGesHistorial[loca].id_exp)
      let idExamGes = this.state.infoBloques.find(b=>b.id_semana == examen.idPrg && b.id_bloque == examen.idBloque).idExamenGestion
      let pathFile = this.state.examenGestion.find(e=> e.id == idExamGes).linkSolucion
      if(pathFile != undefined){
        swal('Iniciando descarga, espere.','Si en 20 segundos no se inicia vuelva a darle clic','info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route="+pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            responseType: 'blob'
            }).then(res =>  {
                if (res.status >= 400) { throw new Error("Bad res from server");}
                return res.blob();
            }).then(blob => {
              saveAs(blob,nameFile);
            }).catch(function(err) {console.log(err)
              alert("Link no valido para descarga, reportelo, gracias.")
            });
      }
    } else if (e.target.id.split('-')[0] == 'borrarTest'){
      let numExpediente = await e.target.id.split('-')[1];
      let testPuntuaciones = this.state.testJusticiaHistorial
      let idExp = await testPuntuaciones[numExpediente].idExp
      swal({
    	  title: "¿Estas segur@?",
    	  text: "Si le das a ok, el test será borrado.",
    	  icon: "warning",
    	  buttons: true,
    	  dangerMode: true,
    	})
    	.then((willDelete) => {
    	  if (willDelete) {
          let data = { idExp: idExp}
    		 fetch("https://oposiciones-justicia.es/api/usuarios/delTestExp", {
    		    method: 'POST',
    		    headers: {'Content-Type': 'application/json'},
    		    body: JSON.stringify(data)
    		  }).then(response =>  {
    			 if (response.status >= 400) { throw new Error("Bad response from server");}
    		    return response.json();
    		  }).then(data => {
    			 if(data.affectedRows > 0){
             let content = document.createElement('div');
             content.innerHTML = `Se recargara la web en 6 segundos para ver los cambios.`
             content.style.textAlign = "left";
             swal({
               title: 'Test Eliminado',
               content: content,
               icon: "success",
               buttons: false,
               closeOnEsc: false,
               closeOnClickOutside: false,
             })
             setTimeout(
             function() {
                 window.location.reload();
             }.bind(this),6000);
    			 }}).catch(function(err) {console.log(err)});

    	  } else {
    	    swal("¡Test no borrado!",'',"success");
    	  }
    	});
    } else if (e.target.id.split('-')[0] == 'borrarCP'){
      let numExpediente = await e.target.id.split('-')[1];
      let testPuntuaciones = this.state.casoPractioHistorial
      let idExp = await testPuntuaciones[numExpediente].idExp

      swal({
    	  title: "¿Estas segur@?",
    	  text: "Si le das a ok, el test será borrado.",
    	  icon: "warning",
    	  buttons: true,
    	  dangerMode: true,
    	})
    	.then((willDelete) => {
    	  if (willDelete) {
          let data = { idExp: idExp}
    		 fetch("https://oposiciones-justicia.es/api/usuarios/delCPExp", {
    		    method: 'POST',
    		    headers: {'Content-Type': 'application/json'},
    		    body: JSON.stringify(data)
    		  }).then(response =>  {
    			 if (response.status >= 400) { throw new Error("Bad response from server");}
    		    return response.json();
    		  }).then(data => {
    			 if(data.affectedRows > 0){
             let content = document.createElement('div');
             content.innerHTML = `Se recargara la web en 6 segundos para ver los cambios.`
             content.style.textAlign = "left";
             swal({
               title: 'Caso Practico Eliminado',
               content: content,
               icon: "success",
               buttons: false,
               closeOnEsc: false,
               closeOnClickOutside: false,
             })
             setTimeout(
             function() {
                 window.location.reload();
             }.bind(this),6000);
    			 }}).catch(function(err) {console.log(err)});

    	  } else {
    	    swal("¡Test no borrado!",'',"success");
    	  }
    	});

    }
  }
  async bienMalBlancoCP(test){
    try{
      let correcta = 0
      let mal = 0
      let enBlanco = 0

      for (var i = 0; i < test.length; i++) {
          let pregunta = test[i]
          let pregTest = await corregirTest(pregunta)
          if(pregTest == 1){
            correcta = correcta + 1
            let numPreg = pregunta.numPreg
            let value = 1
            this.props.puntosResCP(value, numPreg)
          } else if(pregTest == 2){
            mal = mal + 1
            let numPreg = pregunta.numPreg
            let value = 2
            this.props.puntosResCP(value,numPreg)
          } else if(pregTest == 0){
            enBlanco = enBlanco + 1
            let numPreg = pregunta.numPreg
            let value = 0
            this.props.puntosResCP(value,numPreg)
          }
      }
      let restamos = 0.50;
      let totalPreg = 20
      let restamosBlanco = 0;
      let puntuacionRealPorcentaje = 20
      let aciertoCP = 1
      if(test[0].idClase && test[0].idClase != ''){
        let data = { idClase: test[0].idClase}
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malCasoPractico", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malCasoP;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoCP = await cls1[0].aciertoSuma;
      } else {
        let data = { idClase: 1}
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malCasoPractico", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        restamos = await cls1[0].malCasoP;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoCP = await cls1[0].aciertoSuma;
      }


      let multiploMalF = parseFloat(parseFloat(totalPreg/test.length)*restamos)
      let multiploMalBlanco = parseFloat(parseFloat(totalPreg/test.length)*restamosBlanco)
      let multiploAcierto = parseFloat(parseFloat(totalPreg/test.length)*aciertoCP)

      let correctaF = parseFloat(correcta)
      let malF = await parseFloat(parseFloat(mal) * multiploMalF).toFixed(2)
      let enBlancoFF = await parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
      let puntuacion = await parseFloat(((correctaF*multiploAcierto) -  malF) - enBlancoFF).toFixed(2)
      let puntuacionReal = await parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje/20)).toFixed(2)
      let todo = { correcta: correcta, mal: mal, enBlanco: enBlanco, puntuacion: puntuacion, puntuacionReal: puntuacionReal}
      return todo
    } catch(e){console.log(e)}
  }
  async listadoArtErrores(test){
    try{
      let listadoErrores = []
      for (var i = 0; i < test.length; i++) {
        let pregunta = test[i]
        let pregTest = await corregirTest(pregunta)
        if(pregTest == 2){
          listadoErrores.push(pregunta)
        } else if(pregTest == 0){
          listadoErrores.push(pregunta)
        }
      }
      return listadoErrores
    } catch(e){console.log(e)}
  }
  async bienMalBlanco(test){
    try{
      let correcta = 0
      let mal = 0
      let enBlanco = 0

      for (var i = 0; i < test.length; i++) {
          let pregunta = test[i]
          let pregTest = await corregirTest(pregunta)
          if(pregTest == 1){
            correcta = correcta + 1
            let numPreg = pregunta.numPreg
            let value = 1
            this.props.puntosRes(value, numPreg)
          } else if(pregTest == 2){
            mal = mal + 1
            let numPreg = pregunta.numPreg
            let value = 2
            this.props.puntosRes(value,numPreg)
          } else if(pregTest == 0){
            enBlanco = enBlanco + 1
            let numPreg = pregunta.numPreg
            let value = 0
            this.props.puntosRes(value,numPreg)
          }
      }

      let restamos = 0.25;
      let totalPreg = 100
      let restamosBlanco = 0;
      let puntuacionRealPorcentaje = 60
      let aciertoJus = 1
      if(test[0].idClase && test[0].idClase != ''){
        let data = { idClase: test[0].idClase}
  			let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
  				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
  				if (!response1.ok) {
  					throw Error(response1.statusText);
  				}
  			let json1 = await response1.json();
  			let cls1 = await json1;
  			restamos = await cls1[0].malJusticia;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoJus = await cls1[0].aciertoSuma;
      } else {
        let data = { idClase: 1}
  			let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malJusticia", {
  				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data)});
  				if (!response1.ok) {
  					throw Error(response1.statusText);
  				}
  			let json1 = await response1.json();
  			let cls1 = await json1;
  			restamos = await cls1[0].malJusticia;
        restamosBlanco = await cls1[0].restaEnBlanco;
        totalPreg = await cls1[0].totalPregRegla;
        puntuacionRealPorcentaje = await cls1[0].puntuacionRealPorcentaje;
        aciertoJus = await cls1[0].aciertoSuma;
      }


      let multiploMalF = parseFloat(parseFloat(totalPreg/test.length)*restamos)
      let multiploMalBlanco = parseFloat(parseFloat(totalPreg/test.length)*restamosBlanco)
      let multiploAcierto = parseFloat(parseFloat(totalPreg/test.length)*aciertoJus)

      let correctaF = parseFloat(correcta)
      let malF = await parseFloat(parseFloat(mal) * multiploMalF).toFixed(2)
      let enBlancoFF = await parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
      let puntuacion = await parseFloat(((correctaF*multiploAcierto) -  malF) - enBlancoFF).toFixed(2)
      let puntuacionReal = await parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje/100)).toFixed(2)

      let todo = { correcta: correcta, mal: mal, enBlanco: enBlanco, puntuacion: puntuacion, puntuacionReal: puntuacionReal}
      return todo
    } catch(e){console.log(e)}
  }

  async componentDidMount(){

    try{


      let data2 = { idUser: this.props.idAlumno}

      let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoSemanas", {
        method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
        if (!response0.ok) {
          throw Error(response0.statusText);
        }
      let json0 = await response0.json();
      let cls0 = await json0;
      await this.setState({infoSemanal: cls0});

      let response00 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoBloques", {
        method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
        if (!response00.ok) {
          throw Error(response00.statusText);
        }
      let json00 = await response00.json();
      let cls00 = await json00;
      await this.setState({infoBloques: cls00});


        let dataFor2 = { idBloque: 1 }
        let response0023 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoBloquesTemas", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(dataFor2)});
          if (!response0023.ok) {
            throw Error(response0023.statusText);
          }
        let json0023 = await response0023.json();
        let cls0023 = await json0023;

        let infoBloquesTemas = cls0023

      await this.setState({infoBloquesTemas: infoBloquesTemas});


      let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteTestUser", {
        method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
      let json2 = await response2.json();
      let cls2 = await json2;
      let testPuntuaciones= []
      for (var i = 0; i < await cls2.length; i++) {
        testPuntuaciones.push({
          tipo: 'testJusticia',
          idExp: cls2[i].id_test_expediente == undefined ? (''):(cls2[i].id_test_expediente),
          idTest: cls2[i].id_test == undefined ? (''):(cls2[i].id_test),
          idClase: cls2[i].id_clase == undefined ? (''):(cls2[i].id_clase),
          idPrg: cls2[i].id_prg == undefined ? (''):(cls2[i].id_prg),
          idBloque: cls2[i].id_bloque == undefined ? (''):(cls2[i].id_bloque),
          idTemaPrg: cls2[i].id_tema_prg == undefined ? (''):(cls2[i].id_tema_prg),
          correctas: cls2[i].correctas == undefined ? (''):(cls2[i].correctas),
          erroneas: cls2[i].erroneas == undefined ? (''):(cls2[i].erroneas),
          enBlanco: cls2[i].enBlanco == undefined ? (''):(cls2[i].enBlanco),
          puntuacion: cls2[i].puntuacion == undefined ? (''):(cls2[i].puntuacion),
          puntuacionReal: cls2[i].puntuacionReal == undefined ? (''):(cls2[i].puntuacionReal),
          minutos: cls2[i].minutos == undefined ? (''):(cls2[i].minutos),
          segundos: cls2[i].segundos == undefined ? (''):(cls2[i].segundos),
          fecha: cls2[i].fecha == undefined ? (''):(cls2[i].fecha),
          intento: undefined,
          listadoErrores: undefined
        })
      }
      await testPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))

      for (var z = 0; z < testPuntuaciones.length; z++) {
        let busquedaMismoTest = testPuntuaciones.filter(t=> t.idExp != testPuntuaciones[z].idExp && t.idExp < testPuntuaciones[z].idExp && t.idTest == testPuntuaciones[z].idTest && t.idPrg == testPuntuaciones[z].idPrg && t.idBloque == testPuntuaciones[z].idBloque)
        if(busquedaMismoTest && busquedaMismoTest.length > 0){
          testPuntuaciones[z].intento = busquedaMismoTest.length + 1
        } else {
          testPuntuaciones[z].intento = 1
        }
      }
      await this.setState({ testJusticiaHistorial: testPuntuaciones, testJusticiaHistorialSave: testPuntuaciones });

      let response22 = await fetch("https://oposiciones-justicia.es/api/usuarios/expedienteCasoPracticoUser", {
        method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
        if (!response22.ok) {
          throw Error(response22.statusText);
        }
      let json22 = await response22.json();
      let cls22 = await json22;
      let casoPracticoPuntuaciones = []
      for (var i = 0; i < await cls22.length; i++) {
        casoPracticoPuntuaciones.push({
          tipo: 'casoPractico',
          idExp: cls22[i].id_test_expediente == undefined ? (''):(cls22[i].id_test_expediente),
          idTest: cls22[i].id_caso_practico == undefined ? (''):(cls22[i].id_caso_practico),
          idClase: cls22[i].id_clase == undefined ? (''):(cls22[i].id_clase),
          idPrg: cls22[i].id_prg == undefined ? (''):(cls22[i].id_prg),
          idBloque: cls22[i].id_bloque == undefined ? (''):(cls22[i].id_bloque),
          idTemaPrg: cls22[i].id_tema_prg == undefined ? (''):(cls22[i].id_tema_prg),
          correctas: cls22[i].correctas == undefined ? (''):(cls22[i].correctas),
          erroneas: cls22[i].erroneas == undefined ? (''):(cls22[i].erroneas),
          enBlanco: cls22[i].enBlanco == undefined ? (''):(cls22[i].enBlanco),
          puntuacion: cls22[i].puntuacion == undefined ? (''):(cls22[i].puntuacion),
          puntuacionReal: cls22[i].puntuacionReal == undefined ? (''):(cls22[i].puntuacionReal),
          minutos: cls22[i].minutos == undefined ? (''):(cls22[i].minutos),
          segundos: cls22[i].segundos == undefined ? (''):(cls22[i].segundos),
          fecha: cls22[i].fecha == undefined ? (''):(cls22[i].fecha),
          intento: undefined,
          listadoErrores: undefined
        })
      }
      let todosLosExpedientesCP = await casoPracticoPuntuaciones.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))


      for (var z = 0; z < todosLosExpedientesCP.length; z++) {
        let busquedaMismoTest = todosLosExpedientesCP.filter(t=> t.idExp != todosLosExpedientesCP[z].idExp && t.idExp < todosLosExpedientesCP[z].idExp && t.idTest == todosLosExpedientesCP[z].idTest && t.idPrg == todosLosExpedientesCP[z].idPrg && t.idBloque == todosLosExpedientesCP[z].idBloque)
        if(busquedaMismoTest && busquedaMismoTest.length > 0){
          todosLosExpedientesCP[z].intento = busquedaMismoTest.length + 1
        } else {
          todosLosExpedientesCP[z].intento = 1
        }
      }

      await this.setState({ casoPractioHistorial: todosLosExpedientesCP, casoPractioHistorialSave: todosLosExpedientesCP });

      // sacar examGesHistorial
      let response2212 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
        method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
        if (!response2212.ok) {
          throw Error(response2212.statusText);
        }
      let json2212 = await response2212.json();
      let cls2212 = await json2212;
      let examGesHistorial = []

      for (var i = 0; i < cls2212.length; i++) {
        if(cls2212[i].puntuacion != null){
          examGesHistorial.push(cls2212[i])
        }
      }

      let examGesHistorialOrder = await examGesHistorial.sort((a, b) => b.id_exp - a.id_exp)
      await this.setState({ examGesHistorial: examGesHistorialOrder, examGesHistorialSave: examGesHistorialOrder });



      let response3 = await fetch("https://oposiciones-justicia.es/api/clases/todasClases");
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
      let json3 = await response3.json();
      let cls3 = await json3;
      await this.setState({ infoAllClases: cls3 });
      let response4 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
        if (!response4.ok) {
          throw Error(response4.statusText);
        }
      let json4 = await response4.json();
      let cls4 = await json4;
      await this.setState({ busquedaLeyes: cls4 });

      let response41 = await fetch("https://oposiciones-justicia.es/api/usuarios/examenGestion");
        if (!response41.ok) {
          throw Error(response41.statusText);
        }
      let json41 = await response41.json();
      let cls41 = await json41;
      await this.setState({ examenGestion: cls41 });

      try {
        if(this.props.idClase != undefined && this.props.idAlumno != undefined){

          let facturas = []
          let semanasFacturadas = []
          let temasFacturados = []

          let data123 = { idUser: this.props.idAlumno, idClase: this.props.idClase}
          let response123 = await fetch("https://oposiciones-justicia.es/api/usuarios/temasActivos", {
    				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data123)});
    				if (!response123.ok) {
    					throw Error(response123.statusText);
    				}
    			let json123 = await response123.json();
    			let cls123 = await json123;
          await this.setState({ userAccesoTemas: cls123[0].activoTemas });

          let data1 = { idClase: this.props.idClase, idUser: this.props.idAlumno}
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
    				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
    				if (!response1.ok) {
    					throw Error(response1.statusText);
    				}
    			let json1 = await response1.json();
    			let cls1 = await json1;
          for (var i = 0; i < cls1.length; i++) {
            facturas.push({
              idFactura: cls1[i].idFactura,
              fechaPago: cls1[i].fechaPago,
              fechaCaducidad: cls1[i].fechaCaducidad,
              tipo: cls1[i].tipo
            })
          }
    			await this.setState({facturaAlumnoClase: facturas});

          for (var i = 0; i < facturas.length; i++) {
            let data2 = { idClase: this.props.idClase, idUser: this.props.idAlumno, fechaPago: facturas[i].fechaPago, fechaCaducidad: facturas[i].fechaCaducidad }

            let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            if(facturas[i].tipo == 3){
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadasCursoAuto"
            } else {
              urlSemClasefAC= "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"
            }

            let response2 = await fetch(urlSemClasefAC, {
              method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
            let json2 = await response2.json();
            let cls2 = await json2;
            for (var f = 0; f < cls2.length; f++) {
              let fechaMostra = moment(cls2[f].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
              let ahora = moment().format('YYYY-MM-DD HH:mm')
              if(ahora >= fechaMostra){
                semanasFacturadas.push(cls2[f])
              }

            }
          }
          for (var i = 0; i < semanasFacturadas.length; i++) {
            semanasFacturadas[i].temas = []
          }
          for (var k = 0; k < semanasFacturadas.length; k++) {
            let data3 = { idPrg: semanasFacturadas[k].idPrg }


            let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
              method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
            let json3 = await response3.json();
            let cls3 = await json3;

            for (var z = 0; z < cls3.length; z++) {
              let locaBloque = await semanasFacturadas.findIndex(c => c.idPrg == cls3[z].idPrg && c.numClase == semanasFacturadas[k].numClase);
              if(locaBloque != -1){
                semanasFacturadas[locaBloque].temas.push({
                  idTema: cls3[z].idTema,
                  tituloTema: cls3[z].tituloTema
                })
              }
            }
          }

          for (var k = 0; k < semanasFacturadas.length; k++) {
            let data3 = { idPrg: semanasFacturadas[k].idPrg }
            let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/temasDeClaseFacturados", {
              method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
            let json3 = await response3.json();
            let cls3 = await json3;
            if(cls3 != undefined){
              for (var p = 0; p < cls3.length; p++) {
                temasFacturados.push(cls3[p])
              }
            }
          }
          await this.setState({ semanasFacturadas: semanasFacturadas, temasFacturados: temasFacturados,   displaySemanas: 'flex' })
          //this.refs.ref_prgSelect.refs.prgSelect.value = '-'+moment().format('MM')

        } else {
          swal('¡Acceso Denegado!','No tiene activado el acceso a esta clase.','error')
        }

      } catch(e){console.log(e)}

    } catch (e){ console.log(e)}
  }
  render() {

    const { isLogged, nombreUser, tipoUser } = this.props;
    const { testJusticiaHistorial, casoPractioHistorial, infoAllClases, verTestJusticia, verCasoPractico, infoSemanal, infoBloques, verExamGes, examGesHistorial, semanasFacturadas } = this.state
    //console.log(infoBloques)
    const nivelRequired = 1;

    if(isLogged && (nivelRequired >= tipoUser || tipoUser >= 1) ){
    return (
      <div className="bodyHistorial">

        <div className="historyMenu">


          <div className="hmBotones">
            <BotonMedio id={"verTestJusticiaHistorial"} icon1="fas fa-eye" icon2="fas fa-load" texto={"Test Teoría"} onClick={this.handleOnSubmit}/>
            <BotonMedio id={"verCPHistorial"} icon1="fas fa-eye" icon2="fas fa-load" texto={"Caso Práctico"} onClick={this.handleOnSubmit}/>
            {examGesHistorial && examGesHistorial.length > 0 ? (
              <BotonMedio id={"verExamGesHis"} icon1="fas fa-eye" icon2="fas fa-load" texto={"Examen Gestión"} onClick={this.handleOnSubmit}/>
            ):null}
          </div>
          <div className="hmBotones">
            {(verTestJusticia && verTestJusticia == true) || (verCasoPractico && verCasoPractico == true) || (verExamGes && verExamGes == true) ? (
            <Input2 type="text" name="semanaFacturadaSelectedTestJus" placeholder="Buscar Semana" icon="fas fa-search" datalist="semanasFacturadas" ref="ref_semanaFacturadaSelected" onChange={ this.onChangeInput}/>
            ):null}

            <datalist id="semanasFacturadas">{
              semanasFacturadas && semanasFacturadas.sort((a, b) => new Date(b.fechaClase) - new Date(a.fechaClase)).map((cls, key) =>
                <option key={key} value={moment(cls.fechaClase).format('DD-MM-YY')}>{'Semana '+cls.numClase},
                Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a,key2)=>
                  cls.temas.length-1 == key2 ? (a.tituloTema+'.'):(a.tituloTema+', ')
                )}</option>
              )
            }</datalist>
          </div>

        </div>

        <h44> <i className="fas fa-info-circle"></i>
        {verTestJusticia && verTestJusticia == true ? ('Expediente Test Teoría') : null}

        {verCasoPractico && verCasoPractico == true ? ('Expediente Casos Prácticos') : null}

        {verExamGes && verExamGes == true ? ('Expediente Examenes Gestión') : null}

        {!verExamGes && !verTestJusticia && !verCasoPractico ? ('Elija una opción para cargar el expediente deseado') : null}

        <i className="fas fa-eye"></i>
        </h44>

        <div className="displayBlockPuntuacionesTest">
          {verTestJusticia && verTestJusticia == true && testJusticiaHistorial && testJusticiaHistorial.length == 0 ? ('No se encontro ningun test teorico completado en la semana seleccionada') : null}
          {verTestJusticia && verTestJusticia == true && testJusticiaHistorial && testJusticiaHistorial.map( (test,key) =>
            <div>
              <div className="barraPuntuacionTestU" id={key}>


                <div className="rowBPT" style={{width:'125px'}} title="Fecha">
                  <div className="iconRowBPT"> <i className="fas fa-calendar-alt"/> </div>
                  <div className="infoRowBPT"> {moment(test.fecha).format('DD-MM-YY')} </div>
                </div>

                <div className="rowBPTUser" style={{width:'95px'}} title="Puntuación Test" >
                  <div className="iconRowBPT"> <i className="fas fa-flag"/> </div>
                  <div className="infoRowBPT"> {test.puntuacion} </div>
                </div>



                {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' ? (
                  <div className="rowBPTUser">

                    <div className="iconRowBPT"> <i className="fas fa-chalkboard-teacher"/> </div>
                    <div className="infoRowBPT">
                      { 'Semana Nº '+ infoSemanal.find(t=> t.id_clase == test.idClase && t.id_programa == test.idPrg).numero_semana_clase }
                    </div>

                    {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' ? (
                      <div className="rowBPTUser">
                        <div className="iconRowBPT"> <i className="fas fa-arrow-right"/> </div>
                        <div className="infoRowBPT">
                            {' Bloque Nº '+ parseInt(infoBloques.filter(b=>b.id_semana == test.idPrg).sort((a, b) => a.id_bloque - b.id_bloque).findIndex(t=> t.id_bloque == test.idBloque) + 1)}

                        </div>


                      </div>
                    ):null}
                  </div>
                  ):(
                    <div className="rowBPTUser">
                      <div className="iconRowBPT"> <i className="fas fa-info"/> </div>
                      <div className="infoRowBPT">
                        {'Test autogenerado'}
                      </div>
                    </div>
                  )
                }


                <div className="rowBPTUser" title="¡Más info del Test, dame clic!">
                  <div className="iconRowBPT"> <i id={"infoTest-"+key} onClick={this.handleOnSubmit} className="fas fa-arrow-alt-circle-down"/> </div>
                </div>

              </div>

              <div id={'infoTestH-'+key} className="infoTestH4">


                  <div className="rowBPTUser" title="Puntuación Convocatoría" >
                    <div className="iconRowBPT"> <i className="fas fa-flag"/> </div>
                    <div className="infoRowBPT"> P. Convocatoría: {test.puntuacionReal} </div>
                  </div>
                  <div className="rowBPTUser"  title="Total Correctas">
                    <div className="iconRowBPT"> <i style={{color: '#3fa92c'}} className="fas fa-check-circle"/> </div>
                    <div className="infoRowBPT"> {test.correctas} </div>
                  </div>
                  <div className="rowBPTUser"  title="Total Erroneas">
                    <div className="iconRowBPT"> <i style={{color: '#f11e1e'}} className="fas fa-times-circle"/> </div>
                    <div className="infoRowBPT"> {test.erroneas} </div>
                  </div>
                  <div className="rowBPTUser"  title="Total en Blanco">
                    <div className="iconRowBPT"> <i className="far fa-circle"/> </div>
                    <div className="infoRowBPT"> {test.enBlanco} </div>
                  </div>

                  <div className="rowBPTUser"  title="Tiempo transcurrido">
                    <div className="iconRowBPT"> <i className="fas fa-stopwatch"/> </div>
                    <div className="infoRowBPT">
                      {test.segundos > 9 ? (''+test.minutos+':'+test.segundos+' m.'):(''+test.minutos+':0'+test.segundos+' m.')}
                    </div>
                  </div>

                  <div className="rowBPTUser" title="Número intento">
                    <div className="iconRowBPT"> <i className="fas fa-history"/> </div>
                    <div className="infoRowBPT">
                      {'Intento Nº '+test.intento}
                    </div>
                  </div>



              </div>

              <div id={'infoTestH3-'+key} className="infoTestH3">

                  <div className="rowBPTUser" style={{marginRight: '5px'}}>
                    <div className="botonRowBPT" id={'verTest-'+key} onClick={this.handleOnSubmit}>
                        <i className="fas fa-eye"/> Ver Test
                    </div>
                  </div>



                  <div className="rowBPTUser" style={{marginRight: '5px'}}>
                    <div className="botonRowBPT" title="Revisar Puntuación" id={'revisarPuntuacion-'+key} onClick={this.handleOnSubmit}>
                        <i className="fas fa-recycle"/> R. Puntuación.
                    </div>
                  </div>
                  <div className="rowBPTUser" style={{marginRight: '5px'}}>
                    <div className="botonRowBPT" title="Borrar Test Expediente" id={'borrarTest-'+key} onClick={this.handleOnSubmit}>
                        <i className="fas fa-trash"/> Borrar
                    </div>
                  </div>
              </div>

              {test && test.listadoErrores && test.listadoErrores.length > 0 ? (
                <div id={'infoTestH2-'+key} className="infoTestH2">
                    <div style={{ alignSelf: "center", marginBottom: '10px' }}> <b>{'Debe repasar los siguientes artículos'}</b></div>
                    <div className="infoTestH5">
                      {test.listadoErrores.map((err,keysdrrgre45) =>
                        <div className="celdaLey">

                          <div className="celdaLeyTitulo" title={err.nombreLey}>
                            {err.siglasLey}
                          </div>
                          <div className="celdaLeyArticulos">
                            {err.articulos.map((art,keysdrr) =>
                                <div className="clAalmExp">
                                  <div className="nArtExAlumn" style={{marginRight: '8px'}}title="Artículo">
                                    <i className="fas fa-gavel"></i>
                                    <b>{art.art}</b>
                                  </div>
                                  <div style={{fontSize: '14px'}}className="nArtExAlumn" title="Número de Pregunta en Test">
                                    <i className="fas fa-question-circle"></i>
                                    {art.numPreg}
                                  </div>

                                </div>
                            )}
                          </div>

                        </div>

                      )}
                    </div>
                </div>
              ):null}

            </div>)}

            {verCasoPractico && verCasoPractico == true && casoPractioHistorial && casoPractioHistorial.length == 0 ? ('No se encontro ningun test práctico completado en la semana seleccionada') : null}
          {verCasoPractico && verCasoPractico == true && casoPractioHistorial && casoPractioHistorial.map( (test,key) =>
          <div>
            <div className="barraPuntuacionTestU" id={key}>

              <div className="rowBPTUser" title="¡Info del Test, dame clic!">
                <div className="iconRowBPT"> <i id={"infoTest-"+key} onClick={this.handleOnSubmit} className="fas fa-hand-point-down"/> </div>
              </div>
              <div className="rowBPT" style={{width:'125px'}} title="Fecha">
                <div className="iconRowBPT"> <i className="fas fa-calendar-alt"/> </div>
                <div className="infoRowBPT"> {moment(test.fecha).format('DD-MM-YY')} </div>
              </div>

              <div className="rowBPTUser" style={{width:'95px'}} title="Puntuación Test" >
                <div className="iconRowBPT"> <i className="fas fa-flag"/> </div>
                <div className="infoRowBPT"> {test.puntuacion} </div>
              </div>

              <div className="rowBPTUser" style={{width:'75px'}} title="Total Correctas">
                <div className="iconRowBPT"> <i style={{color: '#3fa92c'}} className="fas fa-check-circle"/> </div>
                <div className="infoRowBPT"> {test.correctas} </div>
              </div>
              <div className="rowBPTUser" style={{width:'75px'}} title="Total Erroneas">
                <div className="iconRowBPT"> <i style={{color: '#f11e1e'}} className="fas fa-times-circle"/> </div>
                <div className="infoRowBPT"> {test.erroneas} </div>
              </div>
              <div className="rowBPTUser" style={{width:'75px'}} title="Total en Blanco">
                <div className="iconRowBPT"> <i className="far fa-circle"/> </div>
                <div className="infoRowBPT"> {test.enBlanco} </div>
              </div>
              <div className="rowBPTUser" style={{width:'115px'}} title="Tiempo transcurrido">
                <div className="iconRowBPT"> <i className="fas fa-stopwatch"/> </div>
                <div className="infoRowBPT">
                  {test.segundos > 9 ? (''+test.minutos+':'+test.segundos+' m.'):(''+test.minutos+':0'+test.segundos+' m.')}
                </div>
              </div>

              <div className="rowBPT" style={{alignSelf: 'center'}}>
                <div className="botonRowBPT" id={'verCasoPractico-'+key} onClick={this.handleOnSubmit}>
                    <i className="fas fa-eye"/> Ver Caso
                </div>
              </div>

            </div>
            <div id={'infoTestH-'+key} className="infoTestH">

                <div className="rowBPTUser" title="Número intento">
                  <div className="iconRowBPT"> <i className="fas fa-history"/> </div>
                  <div className="infoRowBPT">
                    {'Intento Nº '+test.intento}
                  </div>
                </div>
                <div className="rowBPTUser" title="Puntuación Convocatoría" >
                  <div className="iconRowBPT"> <i className="fas fa-flag"/> </div>
                  <div className="infoRowBPT"> P. Convocatoría: {test.puntuacionReal} </div>
                </div>


                {test.idBloque != undefined && test.idPrg != undefined && test.idClase != undefined && test.idBloque != '' && test.idPrg != '' && test.idClase != '' ? (
                  <div className="rowBPTUser">
                    <div className="iconRowBPT"> <i className="fas fa-calendar-alt"/> </div>
                    <div className="infoRowBPT">
                      {'Semana Nº '+ infoSemanal.find(t=> t.id_clase == test.idClase && t.id_programa == test.idPrg).numero_semana_clase }
                    </div>
                    <div className="iconRowBPT"> <i className="fas fa-arrow-right"/> </div>
                    <div className="infoRowBPT">
                        {' Bloque Nº '+ parseInt(infoBloques.filter(b=>b.id_semana == test.idPrg).sort((a, b) => a.id_bloque - b.id_bloque).findIndex(t=> t.id_bloque == test.idBloque) + 1)}
                    </div>


                  </div>
                  ):(
                    <div className="rowBPTUser">
                      <div className="iconRowBPT"> <i className="fas fa-info"/> </div>
                      <div className="infoRowBPT">
                        {'Caso P. auto-generado'}
                      </div>
                    </div>
                  )
                }

                <div className="rowBPTUser" style={{alignSelf: 'center', marginRight: '5px'}}>
                  <div className="botonRowBPT" title="Revisar Puntuación" id={'revisarPuntuacionCP-'+key} onClick={this.handleOnSubmit}>
                      <i className="fas fa-recycle"/> R. Puntuación.
                  </div>
                </div>

                <div className="rowBPTUser" style={{alignSelf: 'center'}}>
                  <div className="botonRowBPT" title="Borrar Caso Practico Expediente" id={'borrarCP-'+key} onClick={this.handleOnSubmit}>
                      <i className="fas fa-trash"/> Borrar
                  </div>
                </div>

            </div>

          </div>)}

          {verExamGes && verExamGes == true && examGesHistorial && examGesHistorial.map( (examen,key) =>
            <div>
              <div className="barraPuntuacionTestU" id={key}>
                <div className="rowBPTUser" title="¡Ver aclaración preparador!">
                  <div className="iconRowBPT"> <i id={"infoTest-"+key} onClick={this.handleOnSubmit} className="fas fa-hand-point-down"/> </div>
                </div>

                {examen.idBloque != undefined && examen.idPrg != undefined && examen.idClase != undefined && examen.idBloque != '' && examen.idPrg != '' && examen.idClase != '' ? (
                <div className="rowBPT" style={{width:'125px'}} title="Fecha">
                  <div className="iconRowBPT"> <i className="fas fa-calendar-alt"/> </div>
                  <div className="infoRowBPT"> {moment(infoSemanal.find(t=> t.id_clase == examen.idClase && t.id_programa == examen.idPrg).fecha_clase).format('DD-MM-YY')} </div>
                </div>
                ):null}

                <div className="rowBPTUser" style={{width:'95px'}} title="Puntuación Examen" >
                  <div className="iconRowBPT"> <i className="fas fa-flag"/> </div>
                  <div className="infoRowBPT"> {examen.puntuacion} </div>
                </div>



                {examen.idBloque != undefined && examen.idPrg != undefined && examen.idClase != undefined && examen.idBloque != '' && examen.idPrg != '' && examen.idClase != '' ? (
                  <div className="rowBPTUser">
                    <div className="iconRowBPT"> <i className="fas fa-calendar-alt"/> </div>
                    <div className="infoRowBPT">
                      {'Semana Nº '+ infoSemanal.find(t=> t.id_clase == examen.idClase && t.id_programa == examen.idPrg).numero_semana_clase }
                    </div>
                    <div className="iconRowBPT"> <i className="fas fa-arrow-right"/> </div>
                    <div className="infoRowBPT">
                        {' Bloque Nº '+ parseInt(infoBloques.filter(b=>b.id_semana == examen.idPrg).sort((a, b) => a.id_bloque - b.id_bloque).findIndex(t=> t.id_bloque == examen.idBloque) + 1)}
                    </div>


                  </div>
                  ):(
                    <div className="rowBPTUser">
                      <div className="iconRowBPT"> <i className="fas fa-info"/> </div>
                      <div className="infoRowBPT">
                        {'Examen auto-generado'}
                      </div>
                    </div>
                  )
                }

                <div className="rowBPTUser" style={{alignSelf: 'center', marginRight: '5px'}}>
                  <div className="botonRowBPT" id={'downloadMiExamen-'+key} onClick={this.handleOnSubmit}>
                      <i className="fas fa-download"/> Mi Examen
                  </div>
                </div>

                <div className="rowBPTUser" style={{alignSelf: 'center'}}>
                  <div className="botonRowBPT" id={'downloadExamenResuelto-'+key} onClick={this.handleOnSubmit}>
                      <i className="fas fa-download"/> Resolución
                  </div>
                </div>



              </div>

              <div id={'infoTestH-'+key} className="infoTestH" style={{alingItems: 'center', justifyContent: 'center',flexFlow: 'column nowrap'}}>
                <h44><i className="fas fa-info-circle"></i>Nota Preparador</h44>
                {examen.anotaciones != null && examen.anotaciones != '' ? (

                  <div className="anotacionPreExaAlum">

                    {examen.anotaciones}
                  </div>
                ):('No existe ninguna nota para este examen.')}

              </div>

            </div>)}


        </div>

      </div>
    );
  } else {
    return(<Redirect to ="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators ({
    loadTestJusticia,
    loadCasoPractico,
    puntosRes,
    puntosResCP
  },dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpedienteAlumno));

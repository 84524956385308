// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/input.css';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ReactHtmlParser from 'react-html-parser';

class EditorTexto extends Component {

  static propTypes = {

  };

constructor(props){
        super(props);
        this.state = {
        }
        this.onChangeInput = this.onChangeInput.bind(this);
        //this.onFFocus = this.onFFocus.bind(this);
    }

    onChangeInput(e,editor){
      let textHtml = editor.getData();
      this.props.onChange(e, textHtml);
    }


  render() {
    const { dataInfoTextHtml } = this.props;
              return (
                <div style={{maxWith: "1000px"}}>
                <CKEditor
                    editor = { ClassicEditor }
                    data={ dataInfoTextHtml }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                    } }
                    onChange={this.onChangeInput}
                  />
                </div>

              );

        }
 }
/*
<CKEditor
    editor = { ClassicEditor }
    data="<p>¡Bienvenido al editor de texto de CEJ APP!</p>"
    onInit={ editor => {
        // You can store the "editor" and use when it is needed.
        console.log( 'Editor is ready to use!', editor );
    } }
    onChange={ ( event, editor ) => {
        const data = editor.getData();
        console.log( { event, editor, data } );
    } }
    onBlur={ ( event, editor ) => {
        console.log( 'Blur.', editor );
    } }
    onFocus={ ( event, editor ) => {
        console.log( 'Focus.', editor );
    } }
  />
*/
export default EditorTexto;

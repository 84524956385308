export default [
	// {
	// 	title: 'Herramientas',
	// 	url: '/Preparador/aulaVirtual',
	// 	visible: true,
	// 	nivelAcesso: 50,
	// 	fontawesome: 'fas fa-toolbox',
	// 	subMenu: [
	// 		// {
	// 		// 	title: 'Ver Clase',
	// 		// 	url: 'Entrar_En_Clase',
	// 		// 	fontawesome: 'fas fa-eye'
	// 		// },
	// 		{
	// 			title: 'Ver Test Teoría',
	// 			url: 'Ver_Test_Preparador',
	// 			fontawesome: 'fas fa-play'
	// 		},
	// 		{
	// 			title: 'Ver Test Práctico',
	// 			url: 'Ver_CasoPractico_Preparador',
	// 			fontawesome: 'fas fa-play'
	// 		}
	// 	]
	// },
	{
		title: 'Ver / Editar clase',
		url: '/Preparador/edtClasePrg',
		visible: true,
		nivelAcesso: 50,
		fontawesome: 'fas fa-info-circle',
		subMenu: [
			{
				title: 'Editar semana',
				url: 'Editar_Programacion',
				fontawesome: 'fas fa-plus'
			}
		]
	}
	// {
	// 	title: 'Reportes y Dudas',
	// 	url: '/Preparador/pregReportDudas',
	// 	visible: true,
	// 	nivelAcesso: 98,
	// 	fontawesome: 'fas fa-exclamation-triangle',
	// 	subMenu: [
	// 		{
	// 			title: 'Dudas y Reportes Justicia',
	// 			url: 'Preguntas_Reportadas_Justicia',
	// 			fontawesome: 'fas fa-exclamation'
	// 		},
	// 		{
	// 			title: 'Dudas y Reportes Caso P.',
	// 			url: 'Preguntas_Reportadas_CP',
	// 			fontawesome: 'fas fa-exclamation'
	// 		}
	// 	]
	// },
	// {
	// 	title: 'Generar Test Justicia',
	// 	url: '/Preparador/testJusticia',
	// 	visible: true,
	// 	nivelAcesso: 98,
	// 	fontawesome: 'fas fa-balance-scale',
	// 	subMenu: [
	// 		{
	// 			title: 'Editar Programa',
	// 			url: 'Editar_Programacion',
	// 			fontawesome: 'fas fa-plus'
	// 		},
	// 		{
	// 			title: 'Editar Programa',
	// 			url: 'Editar_Programacion',
	// 			fontawesome: 'fas fa-plus'
	// 		},
	// 		{
	// 			title: 'Editar Programa',
	// 			url: 'Editar_Programacion',
	// 			fontawesome: 'fas fa-plus'
	// 		}
	// 	]
	// },
	// {
	// 	title: 'Examenes Gestión',
	// 	url: '/Preparador/corregirExamGestion',
	// 	visible: true,
	// 	nivelAcesso: 98,
	// 	fontawesome: 'fas fa-eye',
	// 	subMenu: [
	// 		{
	// 			title: 'Corregir Examenes',
	// 			url: 'Corregir_Examen_Gestion',
	// 			fontawesome: 'fas fa-chalkboard'
	// 		}
	// 	]
	// }
];

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/insertTest.css';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cambiarDatosTestJusticiaEdt, eliminarTestJusticiaEdtCache, addPreguntaTestJusticiaEdt, deletePreguntaTestJusticiaEdt, changeNumPreguntaTestJusticiaEdt } from './actions/testJusticiaAction';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class EdtTest extends Component {
	static propTypes = {
		datosTestJusticiaEdt: PropTypes.array.isRequired
	};
	constructor(props) {
		super(props);
		this.state = {
			displayNameTest: 'none',
			numPreg: 1,
			numeroPreguntaEspecifico: undefined,
			busquedaLeyes: undefined,
			displayInsertTest: 'flex',
			preguntasDuplicadas: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
				}, {
					value: '0',
					name: 'No',
					disable: undefined,
					selected: undefined
				}
			],
			values2: [
				{
					value: '',
					name: '¿Unica o Multiple?',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '1',
					name: 'Unica Respuesta',
					disable: undefined,
					selected: undefined
				}, {
					value: '2',
					name: 'Multi Respuesta',
					disable: undefined,
					selected: undefined
				},
				, {
					value: '3',
					name: 'Multiple Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '4',
					name: 'Con una Variable',
					disable: undefined,
					selected: undefined
				}, {
					value: '5',
					name: 'Multiple a ó b',
					disable: undefined,
					selected: undefined
				}
			],
			values3: [
				{
					value: '',
					name: 'Res. Normal',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '0',
					name: 'Incorrecta',
					disable: undefined,
					selected: undefined
				}, {
					value: '1',
					name: 'Correcta',
					disable: undefined,
					selected: undefined
				}
			],
			values4: [
				{
					value: '',
					name: 'Res. Multiple',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '0',
					name: 'Incorrecta',
					disable: undefined,
					selected: undefined
				}, {
					value: '1',
					name: 'Mutl. Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '2',
					name: 'Var. de Multiple',
					disable: undefined,
					selected: undefined
				}, {
					value: '10',
					name: 'Var. de Multiple + Var. RUV1',
					disable: undefined,
					selected: undefined
				}, {
					value: '16',
					name: 'Var. de Multiple + Var. RUV2',
					disable: undefined,
					selected: undefined
				}, {
					value: '7',
					name: 'RUV 1 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '13',
					name: 'RUV 2 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '8',
					name: 'Var. RUV 1',
					disable: undefined,
					selected: undefined
				}, {
					value: '14',
					name: 'Var. RUV 2',
					disable: undefined,
					selected: undefined
				}
			],
			values5: [
				{
					value: '',
					name: 'Res. Multiple Falsa',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '1',
					name: 'Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '5',
					name: 'Correcta + Var. Mult. Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '0',
					name: 'Incorrecta',
					disable: undefined,
					selected: undefined
				}, {
					value: '3',
					name: 'Mult. Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '4',
					name: 'Var. Mult. Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '7',
					name: 'RUV 1 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '13',
					name: 'RUV 2 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '10',
					name: 'Var. de Multiple + Var. RUV1 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '16',
					name: 'Var. de Multiple + Var. RUV2 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '11',
					name: 'Var. de Multiple + Var. RUV1 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '17',
					name: 'Var. de Multiple + Var. RUV2 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '8',
					name: 'Var. RUV 1',
					disable: undefined,
					selected: undefined
				}, {
					value: '9',
					name: 'Var. RUV 1 "Falsa" + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '14',
					name: 'Var. RUV 2',
					disable: undefined,
					selected: undefined
				}, {
					value: '15',
					name: 'Var. RUV 2 "Falsa" + Correcta',
					disable: undefined,
					selected: undefined
				}
			],
			values6: [
				{
					value: '',
					name: 'RUV - Unica Variable',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '1',
					name: 'Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '0',
					name: 'Incorrecta',
					disable: undefined,
					selected: undefined
				}, {
					value: '6',
					name: 'RUV 1 --> Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '7',
					name: 'RUV 1 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '8',
					name: 'Var. RUV 1',
					disable: undefined,
					selected: undefined
				}, {
					value: '9',
					name: 'Var. RUV 1 "Falsa" + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '12',
					name: 'RUV 2 --> Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '13',
					name: 'RUV 2 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '14',
					name: 'Var. RUV 2',
					disable: undefined,
					selected: undefined
				}, {
					value: '15',
					name: 'Var. RUV 2 "Falsa" + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '3',
					name: 'Multiple --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '4',
					name: 'Var. Multiple',
					disable: undefined,
					selected: undefined
				}, {
					value: '5',
					name: 'Var. Multiple + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '22',
					name: 'MUV --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '23',
					name: 'Var. MUV',
					disable: undefined,
					selected: undefined
				}, {
					value: '24',
					name: 'Var. MUV + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '10',
					name: 'Var. de Multiple + Var. RUV1 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '16',
					name: 'Var. de Multiple + Var. RUV2 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '11',
					name: 'Var. de Multiple + Var. RUV1 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '17',
					name: 'Var. de Multiple + Var. RUV2 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '18',
					name: 'Var. RUV1 + Var. RUV2 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '19',
					name: 'Var. RUV1 + Var. RUV2 + Correcta',
					disable: undefined,
					selected: undefined
				}
			],
			values7: [
				{
					value: '',
					name: 'MUV - Mult. con a ó b',
					disable: 'disabled',
					selected: 'selected'
				}, {
					value: '1',
					name: 'Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '0',
					name: 'Incorrecta',
					disable: undefined,
					selected: undefined
				}, {
					value: '20',
					name: 'MUV --> Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '21',
					name: 'Var. MUV',
					disable: undefined,
					selected: undefined
				}, {
					value: '22',
					name: 'MUV --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '23',
					name: 'Var. MUV Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '24',
					name: 'Var. MUV Falsa + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '10',
					name: 'Var. MUV + Var. RUV1 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '16',
					name: 'Var. MUV + Var. RUV2 + Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '11',
					name: 'Var. MUV + Var. RUV1 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '17',
					name: 'Var. MUV + Var. RUV2 + Correcta',
					disable: undefined,
					selected: undefined
				}, {
					value: '7',
					name: 'RUV 1 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '13',
					name: 'RUV 2 --> Falsa',
					disable: undefined,
					selected: undefined
				}, {
					value: '8',
					name: 'Var. RUV 1',
					disable: undefined,
					selected: undefined
				}, {
					value: '14',
					name: 'Var. RUV 2',
					disable: undefined,
					selected: undefined
				}, {
					value: '18',
					name: 'Var. RUV 1 + Var. RUV 2',
					disable: undefined,
					selected: undefined
				}
			]
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleRellenarDatos = this.handleRellenarDatos.bind(this);
		this.borrarNumerosPrgInsert = this.borrarNumerosPrgInsert.bind(this);
		this.retrocederPreguntas = this.retrocederPreguntas.bind(this);
		this.meterTestTemplate = this.meterTestTemplate.bind(this);
	}

	handleRellenarDatos(numPregPrev) {
		let { datosTestJusticiaEdt } = this.props;
		let { numPreg, busquedaLeyes } = this.state;
		this.refs.ref_preguntaEdt.refs.preguntaEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).pregunta
		this.refs.ref_subrayadoAEdt.refs.subrayadoAEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoA
		this.refs.ref_subrayadoBEdt.refs.subrayadoBEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoB
		this.refs.ref_subrayadoCEdt.refs.subrayadoCEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoC
		this.refs.ref_subrayadoDEdt.refs.subrayadoDEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoD
		this.refs.ref_notaAEdt.refs.notaAEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).notaA
		this.refs.ref_notaBEdt.refs.notaBEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).notaB
		this.refs.ref_notaCEdt.refs.notaCEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).notaC
		this.refs.ref_notaDEdt.refs.notaDEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).notaD
		this.refs.ref_articuloEdt.refs.articuloEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).articulo

		let siglasLey = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).ley
		if (busquedaLeyes != undefined && siglasLey != '') {
			let objectLey = busquedaLeyes.find(opo => opo.siglas_ley == siglasLey)
			if (objectLey !== undefined) {
				this.refs.ref_siglasLeyEdt.refs.siglasLeyEdt.value = siglasLey;
			} else {
				setTimeout(
					function () {
						swal('Auto relleno de Ley fallo. Lal ley: ' + siglasLey + ' no existe en la base de datos CEJ.', 'Si no la encuentra manualmente, avise para que introduzcan dicha LEY.', 'warning')
					}.bind(this), 2000);
				this.refs.ref_siglasLeyEdt.refs.siglasLeyEdt.value = ""
			}
		}

		this.refs.ref_tipoRespuestaEdt.refs.tipoRespuestaEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta
		this.refs.ref_respuesta1Edt.refs.respuesta1Edt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r1
		this.refs.ref_respuesta2Edt.refs.respuesta2Edt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r2
		this.refs.ref_respuesta3Edt.refs.respuesta3Edt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r3
		this.refs.ref_respuesta4Edt.refs.respuesta4Edt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r4
		if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != '' && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != undefined) {
			setTimeout(function () {
				this.refs.ref_r1ValidezEdt.refs.r1ValidezEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r1_validez
				this.refs.ref_r2ValidezEdt.refs.r2ValidezEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r2_validez
				this.refs.ref_r3ValidezEdt.refs.r3ValidezEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r3_validez
				this.refs.ref_r4ValidezEdt.refs.r4ValidezEdt.value = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).r4_validez
			}.bind(this), 150);
		}

	}
	onChange(e) {
		let { datosTestJusticiaEdt } = this.props

		if (e.target.name == 'numeroPreguntaEspecifico') {
			if (e.target.value <= datosTestJusticiaEdt[0].preguntas.length && e.target.value > 0) {
				this.setState({ numeroPreguntaEspecifico: e.target.value })
			}
		} else if (e.target.name == 'tipoRespuestaEdt' || e.target.name == 'preguntaEdt' || e.target.name == 'subrayadoAEdt' || e.target.name == 'subrayadoBEdt' || e.target.name == 'subrayadoCEdt' || e.target.name == 'subrayadoDEdt' || e.target.name == 'articuloEdt' || e.target.name == 'respuesta1Edt' || e.target.name == 'r1ValidezEdt' || e.target.name == 'respuesta2Edt' || e.target.name == 'r2ValidezEdt' || e.target.name == 'respuesta3Edt' || e.target.name == 'r3ValidezEdt' || e.target.name == 'respuesta4Edt' || e.target.name == 'r4ValidezEdt' || e.target.name == 'notaAEdt' || e.target.name == 'notaBEdt' || e.target.name == 'notaCEdt' || e.target.name == 'notaDEdt') {
			let id = e.target.id.split('-')[1];
			this.props.cambiarDatosTestJusticiaEdt(id, e.target.name, e.target.value)

		} else if (e.target.name == 'siglasLeyEdt') {

			if (this.state.busquedaLeyes.find(l => l.siglas_ley == e.target.value) != undefined) {
				let id = e.target.id.split('-')[1];
				let idLey = this.state.busquedaLeyes.find(l => l.siglas_ley == e.target.value).id_ley
				this.props.cambiarDatosTestJusticiaEdt(id, e.target.name, e.target.value, idLey)
			}
		}

	}
	handleOnClick(e) {
		this.props.onClick(e);
		if (e.target.id == "preguntaSiguiente") {
			let { datosTestJusticiaEdt } = this.props
			if (this.state.numeroPreguntaEspecifico != undefined) {
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if (numPregPrev <= datosTestJusticiaEdt[0].preguntas.length && numPregPrev > 0) {
					this.setState({ numeroPreguntaEspecifico: undefined, numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1) {
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).editar == 1) {
						// validamos pregunta
						swal("¿Editamos la pregunta " + numPregReal + "?", "", {
							buttons: {
								noValidar: {
									text: "No editar",
									value: "noValidar",
								},
								validar: {
									text: "Validar Editar",
									value: "validar",
								}
							},
						})
							.then((value) => {
								switch (value) {
									case "validar":

										this.props.cambiarDatosTestJusticiaEdt(numPregReal, 'validarPregunta', 1)
										break;
									case "noValidar":
										break;
								}
							});
					}

				} else {
					swal('La pregunta: ' + numPregPrev + ' no existe, rango valido: 1 - ' + datosTestJusticiaEdt[0].preguntas.length + '.', '', 'warning');
				}

			} else {

				let numPregPrev = parseInt(this.state.numPreg + 1);
				if (numPregPrev <= datosTestJusticiaEdt[0].preguntas.length && numPregPrev > 0) {
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1) {
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).editar == 1) {
						// validamos pregunta
						swal("¿Editamos la pregunta " + numPregReal + "?", "", {
							buttons: {
								noValidar: {
									text: "No editar",
									value: "noValidar",
								},
								validar: {
									text: "Validar Editar",
									value: "validar",
								}
							},
						})
							.then((value) => {
								switch (value) {
									case "validar":

										this.props.cambiarDatosTestJusticiaEdt(numPregReal, 'validarPregunta', 1)
										break;
									case "noValidar":
										break;
								}
							});
					}
				} else {
					swal('¡No hay más preguntas, solo puede retroceder o finalizar la insercción!', '', 'warning');
				}
			}
		} else if (e.target.id == "preguntaAnterior") {
			let { datosTestJusticiaEdt } = this.props
			if (this.state.numeroPreguntaEspecifico != undefined) {
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if (numPregPrev <= datosTestJusticiaEdt[0].preguntas.length && numPregPrev > 0) {
					this.setState({ numeroPreguntaEspecifico: undefined, numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1) {
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).editar == 1) {
						// validamos pregunta
						swal("¿Editamos la pregunta " + numPregReal + "?", "", {
							buttons: {
								noValidar: {
									text: "No editar",
									value: "noValidar",
								},
								validar: {
									text: "Validar Editar",
									value: "validar",
								}
							},
						})
							.then((value) => {
								switch (value) {
									case "validar":

										this.props.cambiarDatosTestJusticiaEdt(numPregReal, 'validarPregunta', 1)
										break;
									case "noValidar":
										break;
								}
							});
					}
				} else {
					swal('La pregunta: ' + numPregPrev + ' no existe, rango valido: 1 - ' + datosTestJusticiaEdt[0].preguntas.length + '.', '', 'warning');
				}
			} else {
				let numPregPrev = parseInt(this.state.numPreg - 1);
				if (numPregPrev <= datosTestJusticiaEdt[0].preguntas.length && numPregPrev > 0) {
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1) {
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}

					let numPregReal = this.state.numPreg

					if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0
						&& datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).editar == 1) {
						// validamos pregunta
						swal("¿Editamos la pregunta " + numPregReal + "?", "", {
							buttons: {
								noValidar: {
									text: "No editar",
									value: "noValidar",
								},
								validar: {
									text: "Validar Editar",
									value: "validar",
								}
							},
						}).then((value) => {
							switch (value) {
								case "validar":
									this.props.cambiarDatosTestJusticiaEdt(numPregReal, 'validarPregunta', 1)
									break;
								case "noValidar":
									break;
							}
						});
					}
				} else {
					swal('No puede retroceder más.', '', 'warning');
				}
			}
		} else if (e.target.id == "loadPregClick") {
			let numPregPrev = parseInt(e.target.innerText);
			let { datosTestJusticiaEdt } = this.props;
			if (numPregPrev <= datosTestJusticiaEdt[0].preguntas.length && numPregPrev > 0) {
				this.setState({ numeroPreguntaEspecifico: undefined, numPreg: numPregPrev })
				this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numPregPrev;
				this.handleRellenarDatos(numPregPrev);
				if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1) {
					$("#iTScontainer2").addClass("iTScontainerValidada");
					$("#iTScontainer2").removeClass("iTScontainer2");
				} else {
					$("#iTScontainer2").addClass("iTScontainer2");
					$("#iTScontainer2").removeClass("iTScontainerValidada");
				}
				let numPregReal = this.state.numPreg
				if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).editar == 1) {
					// validamos pregunta
					swal("¿Editamos la pregunta " + numPregReal + "?", "", {
						buttons: {
							noValidar: {
								text: "No editar",
								value: "noValidar",
							},
							validar: {
								text: "Validar Editar",
								value: "validar",
							}
						},
					})
						.then((value) => {
							switch (value) {
								case "validar":
									let { datosTestJusticiaEdt } = this.props
									this.props.cambiarDatosTestJusticiaEdt(numPregReal, 'validarPregunta', 1)
									// comprobar si esta duplicada si es asi asiganar las ids a la pregunta nueva
									if (datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal).insertar == 1) {
										// es duplicada
										let pregunta = datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPregReal)
										let preg = pregunta.pregunta
										let r1 = pregunta.r1
										let r2 = pregunta.r2
										let r3 = pregunta.r3
										let r4 = pregunta.r4
										let idLey = pregunta.idLey
										let articulo = pregunta.articulo
										let numPreg = pregunta.numPreg

										if (isDefined(preg) && isDefined(r1) && isDefined(r2) && isDefined(r3) && isDefined(r4) && isDefined(articulo) && isDefined(idLey)
										) {

											let data = { pregunta: preg, articulo: articulo, idLey: idLey, r1: r1, r2: r2, r3: r3, r4: r4 }

											let esDuplicada = 0;
											fetch("https://oposiciones-justicia.es/api/informatica/preguntaDuplicadaJusticia", {
												method: 'POST',
												headers: { 'Content-Type': 'application/json' },
												body: JSON.stringify(data)
											}).then(response => {
												if (response.status >= 400) { throw new Error("Bad response from server"); }
												return response.json();
											}).then(cls => {
												if (cls.length > 0) {
													let id = numPreg;
													this.props.cambiarDatosTestJusticiaEdt(id, 'validarPregunta', 2)
													this.props.cambiarDatosTestJusticiaEdt(id, 'insertar', 2)
													// meter ids a pregunta y resp
													this.props.cambiarDatosTestJusticiaEdt(id, 'idPregunta', cls[0].idPregunta)
													this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta1', cls[0].idRespuesta)
													this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta2', cls[1].idRespuesta)
													this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta3', cls[2].idRespuesta)
													this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta4', cls[3].idRespuesta)
												}
											}).catch(error => console.log('Object fecth failed', error))
										}
									}
									break;
								case "noValidar":
									break;
							}
						});
				}
			}
		} else if (e.target.id == "editarTestTemplate") {
			let preguntaEditar = this.props.datosTestJusticiaEdt[0].preguntas.filter(preg => preg.preguntaCheck == 1 && preg.editar == 1 && preg.idPregunta != '' && preg.idPregunta != undefined && preg.insertar == 0 && preg.insertar == 0)
			let preguntasBorrar = this.props.datosTestJusticiaEdt[0].preguntas.filter(preg => preg.borrar == 1 && preg.preguntaCheck == 1)
			let preguntasInsert = this.props.datosTestJusticiaEdt[0].preguntas.filter(preg => preg.insertar == 1 && preg.preguntaCheck == 1)
			let preguntasDuplicadas = this.props.datosTestJusticiaEdt[0].preguntas.filter(preg => preg.insertar == 2 && preg.preguntaCheck == 2)
			// borramos preguntas del template
			// crear fetch --> deletePregTemplateJusticia

			if (preguntaEditar.length > 0 || preguntasBorrar.length > 0 || preguntasInsert.length > 0 || preguntasDuplicadas.length > 0) {

				swal('Procesando actualización', '', 'success')
				if (preguntasBorrar.length > 0 && preguntasBorrar != undefined) {
					let data = { idTest: this.props.datosTestJusticiaEdt[0].idTest, preguntas: preguntasBorrar }
					fetch("https://oposiciones-justicia.es/api/informatica/deletePregTemplateJusticia", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(response => {
						if (response.status >= 400) { throw new Error("Bad response from server"); }
						return response.json();
					}).then(cls => {

					}).catch(error => console.log('Object fecth failed', error))
				}
				// Añadimos las nuevas a bd y al template
				if (preguntasInsert.length > 0 && preguntasInsert != undefined) {
					for (var i = 0; i < preguntasInsert.length; i++) {
						let numPregRedux = preguntasInsert[i].numPreg;
						let numFor = i;
						let data = {
							idOposicion: this.props.datosTestJusticiaEdt[0].oposicion,
							idLey: preguntasInsert[i].idLey,
							articulo: preguntasInsert[i].articulo,
							pregunta: preguntasInsert[i].pregunta,
							tipoPregunta: preguntasInsert[i].tipoPregunta
						};
						console.log('data')
						console.log(data)
						fetch("https://oposiciones-justicia.es/api/informatica/addPreguntaJusticia", {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify(data)
						}).then(response => {
							if (response.status >= 400) { throw new Error("Bad response from server"); }
							return response.json();
						}).then(cls => {
							this.props.cambiarDatosTestJusticiaEdt(numPregRedux, 'idPregunta', cls.insertId)

							let data2 = {
								idPregunta: cls.insertId,
								r1: preguntasInsert[numFor].r1,
								r1Validez: preguntasInsert[numFor].r1_validez,
								notaA: preguntasInsert[numFor].notaA,
								subrayadoA: preguntasInsert[numFor].subrayadoA,
								r2: preguntasInsert[numFor].r2,
								r2Validez: preguntasInsert[numFor].r2_validez,
								notaB: preguntasInsert[numFor].notaB,
								subrayadoB: preguntasInsert[numFor].subrayadoB,
								r3: preguntasInsert[numFor].r3,
								r3Validez: preguntasInsert[numFor].r3_validez,
								notaC: preguntasInsert[numFor].notaC,
								subrayadoC: preguntasInsert[numFor].subrayadoC,
								r4: preguntasInsert[numFor].r4,
								r4Validez: preguntasInsert[numFor].r4_validez,
								notaD: preguntasInsert[numFor].notaD,
								subrayadoD: preguntasInsert[numFor].subrayadoD
							};
							return fetch("https://oposiciones-justicia.es/api/informatica/addRespuestasJusticia", {
								method: 'POST',
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify(data2)
							});
						}).then(response => {
							if (response.status >= 400) { throw new Error("Bad response from server"); }
							return response.json();
						}).then(cls => {
							if (cls != undefined) {
								for (var j = 0; j < cls.affectedRows; j++) {
									let num = j + 1;
									this.props.cambiarDatosTestJusticiaEdt(numPregRedux, 'idRespuesta' + num, parseInt(cls.insertId + j))
								}
								if (numFor == preguntasInsert.length - 1) {
									setTimeout(
										function () {
											let preguntasInsertRe = this.props.datosTestJusticiaEdt[0].preguntas.filter(preg => preg.insertar == 1 && preg.preguntaCheck == 1)
											this.meterTestTemplate(preguntasInsertRe, this.props.datosTestJusticiaEdt[0].idTest);
										}.bind(this), 150);
								}
							}
						}).catch(error => console.log('Object fecth failed', error))
					}
				}
				// Añadimos las duplicadas al template
				if (preguntasDuplicadas.length > 0 && preguntasDuplicadas != undefined) {
					meterTestTemplate(preguntasDuplicadas, this.props.datosTestJusticiaEdt[0].idTest)
				}

				// Editan las preguntas existentes
				if (preguntaEditar.length > 0 && preguntaEditar != undefined) {
					setTimeout(
						function () {
							let data = { preguntasEdt: preguntaEditar }
							fetch("https://oposiciones-justicia.es/api/informatica/updatePreguntasTestTemplate", {
								method: 'POST',
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify(data)
							}).then(response => {
								if (response.status >= 400) { throw new Error("Bad response from server"); }
								return response.json();
							}).then(cls => {
								let data2 = { preguntasEdt: preguntaEditar }
								return fetch("https://oposiciones-justicia.es/api/informatica/updateRespuestasTestTemplate", {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify(data2)
								});
							}).then(response => {
								if (response.status >= 400) { throw new Error("Bad response from server"); }
								return response.json();
							}).then(cls => {
								swal('Edición de Preguntas y Respuestas completada', '', 'success')
								this.props.eliminarTestJusticiaEdtCache();
								window.location.reload();
							}).catch(error => console.log('Object fecth failed', error))

						}.bind(this), 1500);
				} else {
					setTimeout(
						function () {
							this.props.eliminarTestJusticiaEdtCache();
							window.location.reload()
						}.bind(this), 1500);
				}
			} else {
				swal("Error", "Debe editar, borrar o añadir alguna pregunta para actualizar el test", 'warning')
			}
		} else if (e.target.id == "addPregunta") {
			let numPregNuevo = this.props.datosTestJusticiaEdt[0].preguntas.length + 1
			this.props.addPreguntaTestJusticiaEdt(numPregNuevo)
		} else if (e.target.id == "deletePregunta") {
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, la pregunta será eliminada.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then((willDelete) => {
					if (willDelete) {

						let numPregDel = this.state.numPreg
						let pregSelect = this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg == numPregDel)
						if (pregSelect.length > 0 && pregSelect != undefined) {
							if (pregSelect[0].insertar == 2 || pregSelect[0].insertar == 1) {
								this.borrarNumerosPrgInsert(numPregDel)
							} else {
								this.props.cambiarDatosTestJusticiaEdt(numPregDel, 'borrar', 1)
								this.props.cambiarDatosTestJusticiaEdt(numPregDel, 'validarPregunta', 1)
								swal('La pregunta' + numPregDel + '.', 'Ha sido señalada, será borrar al actualizar las preguntas del test', 'success')
							}
						}
					} else {
						swal("¡Pregunta no borrada!", '', "success");
					}
				});

		}
	}
	borrarNumerosPrgInsert(numPreg) {

		let numPregDel = numPreg
		let numCarga = numPreg - 1
		if (numCarga > 0) {
			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = numCarga;
			this.handleRellenarDatos(numCarga)
			this.setState({ numPreg: numCarga })

			this.props.deletePreguntaTestJusticiaEdt(numPregDel)

			let numChance = this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg > numPregDel).length

			this.retrocederPreguntas(numPregDel, numChance)

		} else if (this.props.datosTestJusticiaEdt[0].preguntas.length > 1) {
			let id = 1
			let value = this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg == 2);

			this.props.cambiarDatosTestJusticiaEdt(id, 'validarPregunta', value[0].preguntaCheck)
			this.props.cambiarDatosTestJusticiaEdt(id, 'tipoRespuesta', value[0].tipoPregunta)
			this.props.cambiarDatosTestJusticiaEdt(id, 'pregunta', value[0].pregunta)
			this.props.cambiarDatosTestJusticiaEdt(id, 'subrayadoA', value[0].subrayadoA)
			this.props.cambiarDatosTestJusticiaEdt(id, 'subrayadoB', value[0].subrayadoB)

			this.props.cambiarDatosTestJusticiaEdt(id, 'subrayadoC', value[0].subrayadoC)
			this.props.cambiarDatosTestJusticiaEdt(id, 'subrayadoD', value[0].subrayadoD)
			this.props.cambiarDatosTestJusticiaEdt(id, 'articulo', value[0].articulo)
			this.props.cambiarDatosTestJusticiaEdt(id, 'respuesta1', value[0].r1)
			this.props.cambiarDatosTestJusticiaEdt(id, 'r1Validez', value[0].r1_validez)

			this.props.cambiarDatosTestJusticiaEdt(id, 'respuesta2', value[0].r2)
			this.props.cambiarDatosTestJusticiaEdt(id, 'r2Validez', value[0].r2_validez)
			this.props.cambiarDatosTestJusticiaEdt(id, 'respuesta3', value[0].r3)
			this.props.cambiarDatosTestJusticiaEdt(id, 'r3Validez', value[0].r3_validez)
			this.props.cambiarDatosTestJusticiaEdt(id, 'respuesta4', value[0].r4)

			this.props.cambiarDatosTestJusticiaEdt(id, 'r4Validez', value[0].r4_validez)
			this.props.cambiarDatosTestJusticiaEdt(id, 'notaA', value[0].notaA)
			this.props.cambiarDatosTestJusticiaEdt(id, 'notaB', value[0].notaB)
			this.props.cambiarDatosTestJusticiaEdt(id, 'notaC', value[0].notaC)
			this.props.cambiarDatosTestJusticiaEdt(id, 'notaD', value[0].notaD)

			this.props.cambiarDatosTestJusticiaEdt(id, 'editar', value[0].editar)
			this.props.cambiarDatosTestJusticiaEdt(id, 'borrar', value[0].borrar)
			this.props.cambiarDatosTestJusticiaEdt(id, 'insertar', value[0].insertar)
			this.props.cambiarDatosTestJusticiaEdt(id, 'idPregunta', value[0].idPregunta)
			this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta1', value[0].idRespuesta1)
			this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta2', value[0].idRespuesta2)
			this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta3', value[0].idRespuesta3)
			this.props.cambiarDatosTestJusticiaEdt(id, 'idRespuesta4', value[0].idRespuesta4)

			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = 1;
			this.handleRellenarDatos(1)
			this.setState({ numPreg: 1 })
			this.props.deletePreguntaTestJusticiaEdtEdt(2)
			this.retrocederPreguntas(2, this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg > 2).length)
		}
	}
	retrocederPreguntas(numPregDel, numChance) {
		for (var i = 1; i <= numChance; i++) {
			let num = numPregDel + i
			this.props.changeNumPreguntaTestJusticiaEdt(num)
		}
	}

	meterTestTemplate(preguntas, idTestTemplate) {

		let data = { idTest: idTestTemplate, preguntas: preguntas };

		fetch("https://oposiciones-justicia.es/api/informatica/addTestTemplateResPregJusticia", {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		}).then(response => {
			if (response.status >= 400) { throw new Error("Bad response from server"); }
			return response.json();
		}).then(cls => {

		}).catch(error => console.log('Object fecth failed', error))

	}

	componentDidMount() {
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
			.then(res => res.json())
			.then(oposiciones => this.setState({
				busquedaOpo: oposiciones
			})).catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
			.then(leyes => this.setState({
				busquedaLeyes: leyes
			}))
			.catch(error => console.log('Obejet fecth failed', error))

		if (this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.preguntaCheck == 0).find(p => p.preguntaCheck == 0) != undefined) {
			let firstNumPregSinValidar = this.props.datosTestJusticiaEdt[0].preguntas.filter(p => p.preguntaCheck == 0).sort((a, b) => a.numPreg - b.numPreg).find(p => p.preguntaCheck == 0).numPreg
			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value = firstNumPregSinValidar;
			this.refs.ref_siglasLeyEdt.refs.siglasLeyEdt.value = this.props.datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == firstNumPregSinValidar).ley;
			this.handleRellenarDatos(firstNumPregSinValidar)
			this.setState({ numPreg: firstNumPregSinValidar })
		}


	}
	render() {
		const { datosTestJusticiaEdt } = this.props;
		const { values, values2, values3, values4, values5, values6, values7, busquedaOpo, busquedaLeyes, numPreg, displayInsertTest } = this.state;
		return (
			<div className="insertTest">

				<div className="barraAccion5">
					<div className="barraAccion2">
						<div className="botonBA">
							<Submit id="editarTestTemplate" icon1="fas fa-upload" icon2="fas fa-save" texto="Actualizar preguntas de Test" onClick={this.handleOnClick} />
						</div>
					</div>

					<div className="barraAccion6">
						<div className="preguntasSinValidar">
							{datosTestJusticiaEdt[0].preguntas.filter(preg => preg.preguntaCheck === 0).length > 0 ? (
								'Preguntas : ' + datosTestJusticiaEdt[0].preguntas.length + ', Sin editar: ' + datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar === 0).length + ', Editadas: ' + datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar === 1 && preg.preguntaCheck == 1).length
							) : (<p style={{ color: '#55cf31', fontSize: '28px' }}> Todas las Preguntas Editadas</p>)}
						</div>
					</div>


					<div className="barraAccion3">
						{datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1).length > 0 ? (
							datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1 && preg.preguntaCheck == 2).sort((a, b) => a.numPreg - b.numPreg).map((preg, key) =>
								<div className="botonBA4" key={key}>
									<div className="preguntaDuplicadaV" id="loadPregClick" onDoubleClick={this.handleOnClick}>
										{preg.numPreg}
									</div>
								</div>
							)
						) : ('')}
						{datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 0).length > 0 ? (
							datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 0).sort((a, b) => a.numPreg - b.numPreg).map((preg, key) =>
								<div className="botonBA4" key={key}>
									<div className="preguntaSinValidar" id="loadPregClick" onDoubleClick={this.handleOnClick}>
										{preg.numPreg}
									</div>
								</div>
							)
						) : ('')}
						{datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1).length > 0 ? (
							datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1 && preg.preguntaCheck == 0).sort((a, b) => a.numPreg - b.numPreg).map((preg, key) =>
								<div className="botonBA4" key={key}>
									<div className="preguntaEditada2" id="loadPregClick" onDoubleClick={this.handleOnClick}>
										{preg.numPreg}
									</div>
								</div>
							)
						) : ('')}
						{datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1).length > 0 ? (
							datosTestJusticiaEdt[0].preguntas.filter(preg => preg.editar == 1 && preg.preguntaCheck == 1).sort((a, b) => a.numPreg - b.numPreg).map((preg, key) =>
								<div className="botonBA4" key={key}>
									<div className="preguntaEditada" id="loadPregClick" onDoubleClick={this.handleOnClick}>
										{preg.numPreg}
									</div>
								</div>
							)
						) : ('')}
					</div>
				</div>

				<div className="iTSection">
					<div className="encimaPregunta">

						<div className="iTStitulo">
							<i className="fas fa-arrow-circle-right"></i>
							Pregunta
							{' ' + numPreg}
							<div>
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 1 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).editar == 1 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).borrar == 0 ? (
									<div style={{ color: '#29cb3d', marginLeft: '8px' }}> Editada Val. </div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).editar == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).borrar == 0 ? (
									<div style={{ color: '#cf1515', marginLeft: '15px' }}>No Editada</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).editar == 1 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).borrar == 0 ? (
									<div style={{ color: '#cf1515', marginLeft: '15px' }}>Editada</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 0 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).borrar == 1 ? (
									<div style={{ color: '#cf1515', marginLeft: '15px' }}>Borrar</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 1 && datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).borrar == 1 ? (
									<div style={{ color: '#cf1515', marginLeft: '15px' }}>Borrar Val.</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).preguntaCheck == 2 ? (
									<div style={{ color: '#cf1515', marginLeft: '15px' }}>Duplicada</div>
								) : ('')}
							</div>
						</div>

						<div className="barraAccion">
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="preguntaAnterior" icon1="fas fa-caret-left" texto="Anterior" onClick={this.handleOnClick} />
								</div>
								<div className="botonBA2">
									<Input type="number" min="0" id={'numeroPreguntaEspecifico'} name="numeroPreguntaEspecifico" ref="ref_numeroPreguntaEspecifico" icon="fas fa-sort-numeric-up" placeholder="1-99" onChange={this.onChange} />
								</div>
								<div className="botonBA">
									<Submit id="preguntaSiguiente" icon2="fas fa-caret-right" texto="Siguiente" onClick={this.handleOnClick} />
								</div>
							</div>
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="addPregunta" icon1="fas fa-plus" texto="Añadir" onClick={this.handleOnClick} />
								</div>
								<div className="botonBA">
									<Submit id="deletePregunta" icon2="fas fa-minus" texto="Borrar" onClick={this.handleOnClick} />
								</div>
							</div>
						</div>

					</div>
					<div className="iTScontainer2" id="iTScontainer2">

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Pregunta
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'preguntaEdt-' + numPreg} name="preguntaEdt" ref="ref_preguntaEdt" valueDefault="¡Pegar pregunta aquí!" width="400px" height="70px" onChange={this.onChange} />
								</div>
								<div className="iTSCSBInput2">

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 1
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaAEdt-' + numPreg} name="notaAEdt" ref="ref_notaAEdt" valueDefault="¡Nota respuesta 1 aquí!" width="90px" height="75px" onChange={this.onChange} />
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 2
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaBEdt-' + numPreg} name="notaBEdt" ref="ref_notaBEdt" valueDefault="¡Nota respuesta 2 aquí!" width="90px" height="75px" onChange={this.onChange} />
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 3
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaCEdt-' + numPreg} name="notaCEdt" ref="ref_notaCEdt" valueDefault="¡Nota respuesta 3 aquí!" width="90px" height="75px" onChange={this.onChange} />
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 4
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaDEdt-' + numPreg} name="notaDEdt" ref="ref_notaDEdt" valueDefault="¡Nota respuesta 4 aquí!" width="90px" height="75px" onChange={this.onChange} />
										</div>
									</div>

								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Tipo de Respuesta
								</div>
								<div className="iTSCSBInput">
									<InputSelect values={values2} id={'tipoRespuestaEdt-' + numPreg} name="tipoRespuestaEdt" ref="ref_tipoRespuestaEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
								</div>
								<div className="iTSCSBTitulo">
									Siglas Ley
								</div>
								<div className="iTSCSBInput">
									<Input datalist="leyesDataList" type="text" size="16" id={'siglasLeyEdt-' + numPreg} name="siglasLeyEdt" ref="ref_siglasLeyEdt" icon="fas fa-search" placeholder="Ej: LEC" onChange={this.onChange} />
									<datalist id="leyesDataList">
										{busquedaLeyes && busquedaLeyes.map((cls, key) =>
											<option key={key} value={cls.siglas_ley}>{cls.nombre_ley}</option>
										)}
									</datalist>
								</div>
								<div className="iTSCSBTitulo">
									Artículo
								</div>
								<div className="iTSCSBInput">
									<Input type="text" size="16" id={'articuloEdt-' + numPreg} name="articuloEdt" ref="ref_articuloEdt" icon="fas fa-arrow-right" placeholder="Ej: 128.a" onChange={this.onChange} />
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 1
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta1Edt-' + numPreg} name="respuesta1Edt" ref="ref_respuesta1Edt" valueDefault="¡Pegar respuesta 1 aquí!" width="400px" height="80px" onChange={this.onChange} />
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r1ValidezEdt-' + numPreg} ref="ref_r1ValidezEdt" name="r1ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r1ValidezEdt-' + numPreg} name="r1ValidezEdt" ref="ref_r1ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r1ValidezEdt-' + numPreg} name="r1ValidezEdt" ref="ref_r1ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r1ValidezEdt-' + numPreg} name="r1ValidezEdt" ref="ref_r1ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r1ValidezEdt-' + numPreg} name="r1ValidezEdt" ref="ref_r1ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 1
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoAEdt-' + numPreg} name="subrayadoAEdt" ref="ref_subrayadoAEdt" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 2
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta2Edt-' + numPreg} name="respuesta2Edt" ref="ref_respuesta2Edt" valueDefault="¡Pegar respuesta 2 aquí!" width="400px" height="80px" onChange={this.onChange} />
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r2ValidezEdt-' + numPreg} name="r2ValidezEdt" ref="ref_r2ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r2ValidezEdt-' + numPreg} name="r2ValidezEdt" ref="ref_r2ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r2ValidezEdt-' + numPreg} name="r2ValidezEdt" ref="ref_r2ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r2ValidezEdt-' + numPreg} name="r2ValidezEdt" ref="ref_r2ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r2ValidezEdt-' + numPreg} name="r2ValidezEdt" ref="ref_r2ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 2
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoBEdt-' + numPreg} name="subrayadoBEdt" ref="ref_subrayadoBEdt" valueDefault="¡Pegar subrayado respuesta 2 aquí!" width="200px" height="35px" onChange={this.onChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 3
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta3Edt-' + numPreg} name="respuesta3Edt" ref="ref_respuesta3Edt" valueDefault="¡Pegar respuesta 3 aquí!" width="400px" height="80px" onChange={this.onChange} />
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r3ValidezEdt-' + numPreg} name="r3ValidezEdt" ref="ref_r3ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r3ValidezEdt-' + numPreg} name="r3ValidezEdt" ref="ref_r3ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r3ValidezEdt-' + numPreg} name="r3ValidezEdt" ref="ref_r3ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r3ValidezEdt-' + numPreg} name="r3ValidezEdt" ref="ref_r3ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r3ValidezEdt-' + numPreg} name="r3ValidezEdt" ref="ref_r3ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 3
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoCEdt-' + numPreg} name="subrayadoCEdt" ref="ref_subrayadoCEdt" valueDefault="¡Pegar subrayado respuesta 3 aquí!" width="200px" height="35px" onChange={this.onChange} />
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 4
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta4Edt-' + numPreg} name="respuesta4Edt" ref="ref_respuesta4Edt" valueDefault="¡Pegar respuesta 4 aquí!" width="400px" height="80px" onChange={this.onChange} />
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r4ValidezEdt-' + numPreg} name="r4ValidezEdt" ref="ref_r4ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r4ValidezEdt-' + numPreg} name="r4ValidezEdt" ref="ref_r4ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r4ValidezEdt-' + numPreg} name="r4ValidezEdt" ref="ref_r4ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r4ValidezEdt-' + numPreg} name="r4ValidezEdt" ref="ref_r4ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								{datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r4ValidezEdt-' + numPreg} name="r4ValidezEdt" ref="ref_r4ValidezEdt" icon="fas fa-hand-pointer" onChange={this.onChange} />
									</div>
								) : ('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 4
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoDEdt-' + numPreg} name="subrayadoDEdt" ref="ref_subrayadoDEdt" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange} />
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


			</div>
		);
	}
}

// Estados del store
function mapStateToProps(state) {
	return {
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser: state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		datosTestJusticiaEdt: state.testJusticia.datosTestJusticiaEdt
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		cambiarDatosTestJusticiaEdt,
		eliminarTestJusticiaEdtCache,
		addPreguntaTestJusticiaEdt,
		deletePreguntaTestJusticiaEdt,
		changeNumPreguntaTestJusticiaEdt
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EdtTest);

// Actions
const CAMBIAR_TEST_JUSTICIA_ADD = 'CAMBIAR_TEST_JUSTICIA_ADD';
const ELIMINAR_PREGUNTAS_JUSTICIA_INSERT = 'ELIMINAR_PREGUNTAS_JUSTICIA_INSERT';

const ADD_PREGUNTA_NUEVA = 'ADD_PREGUNTA_NUEVA';
const DELETE_PREGUNTA_NUEVA = 'DELETE_PREGUNTA_NUEVA'
const DELETE_DESCENDER_PREGUNTA_NUEVA = 'DELETE_DESCENDER_PREGUNTA_NUEVA';

export function cambiarDatosTestJusticiaAdd(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_TEST_JUSTICIA_ADD,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarPreguntasJusticiaCache() {
    return {
      type: ELIMINAR_PREGUNTAS_JUSTICIA_INSERT
    };
}

export function addPreguntaTestJusticia(numPreguntaNueva) {
    return {
	    type: ADD_PREGUNTA_NUEVA,
	   	 numPregNew: numPreguntaNueva
    };
}
export function deletePreguntaTestJusticia(numPreguntaBorrar) {
    return {
	    type: DELETE_PREGUNTA_NUEVA,
	   	 numPregDel: numPreguntaBorrar
    };
}
export function changeNumPreguntaTestJusticia(numPreguntaBorrar) {
    return {
	    type: DELETE_DESCENDER_PREGUNTA_NUEVA,
	   	 numPregChange: numPreguntaBorrar
    };
}

// Dependencies
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../../../../lib/utils/is'
//Componets

import Input2 from '../../../../../components/Inputs/Input2'
import Boton from '../../../../../components/Inputs/Boton'
import LeyesAdm from '../../../../LeyesAdm'
import EdtArts from '../../../../EdtArts'

// Redux Imports
import { useSelector, useDispatch } from 'react-redux'
import { cronoFunc } from '../../../../../actions/userAction'

const OptionBar = (props) => {


  const [ley_seleccionada, setLey_seleccionada] = useState(null)

  const [nombre_ley, setNombre_ley] = useState('')
  const [siglas_ley, setSiglas_ley] = useState('')
  const [link_ley, setLink_ley] = useState('')

  const [busquedaLeyes, setBusquedaLeyes] = useState(null)

  const refInput1 = useRef()
  const refInput2 = useRef()
  const refInput3 = useRef()
  const refInputBusqueda = useRef()

  useEffect(() => {
    // didMount
    setLey_seleccionada(null)
    setNombre_ley('')
    setSiglas_ley('')
    setLink_ley('')

    if (props.selectedOption == 'Añadir_Ley') {

      refInput1.current.refs.nombre_ley.value = ''
      refInput2.current.refs.siglas_ley.value = ''
      refInput3.current.refs.link_ley.value = ''

    } else if (props.selectedOption == 'Borrar_Ley') {
      refInputBusqueda.current.refs.buscar_ley_del.value = ''
    }

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes =>
        setBusquedaLeyes(leyes)
      )
      .catch(error => console.log('Obejet fecth failed', error))


    return () => { // willUnMount
      // console.log('Cleanup')
    }
  }, [props.selectedOption]) // [] didUpdate si pones [nombre_ley] cada vez que se actualice nombre_ley ejecuta

  const onChangeInput = async (e) => {

    if (e.target.name == 'nombre_ley') {
      setNombre_ley(e.target.value)
    } else if (e.target.name == 'siglas_ley') {
      setSiglas_ley(e.target.value)
    } else if (e.target.name == 'link_ley') {
      setLink_ley(e.target.value)
    } else if (e.target.id == 'buscar_ley_del') {
      let value = e.target.value

      let leyObject = busquedaLeyes.find(ley => ley.siglas_ley === value);

      if (leyObject != undefined) {
        setLey_seleccionada(leyObject)
        setNombre_ley(leyObject.nombre_ley)
        setSiglas_ley(leyObject.siglas_ley)
        setLink_ley(leyObject.link_ley)

        setTimeout(() => {
          refInput1.current.refs.nombre_ley.value = leyObject.nombre_ley
          refInput2.current.refs.siglas_ley.value = leyObject.siglas_ley
          refInput3.current.refs.link_ley.value = leyObject.link_ley
        }, 500);

      } else {
        setLey_seleccionada(null)
      }


    }

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == 'añadir_ley') {
      if (isDefined(nombre_ley) && isDefined(siglas_ley) && isDefined(link_ley)) {

        let data = {
          nombre_ley: nombre_ley,
          siglas_ley: siglas_ley,
          link_ley: link_ley
        }

        let response = await fetch('https://oposiciones-justicia.es/api/leyes/addLey', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        })
        if (!response.ok) {
          throw Error(response.statusText)
        }
        let json = await response.json()
        let cls = await json

        if (cls.affectedRows > 0) {

          await swal({
            title: 'Ley insertada',
            text: 'Proceso completado',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 1500,
          }).then(() => {

            refInput1.current.refs.nombre_ley.value = ''
            refInput2.current.refs.siglas_ley.value = ''
            refInput3.current.refs.link_ley.value = ''
            setNombre_ley('')
            setSiglas_ley('')
            setLink_ley('')
            swal.close()

          })

        }

      } else {

        swal('Faltan datos', 'Rellene los 3 campos para añadir la ley', 'info')

      }

    } else if (e.target.id == 'abrir_ley') {

      let link = ley_seleccionada.link_ley
      window.open(link)

    } else if (e.target.id == 'editar_ley') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la ley será editada.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let data = {
              id_ley: ley_seleccionada.id_ley,
              nombre_ley: nombre_ley,
              siglas_ley: siglas_ley,
              link_ley: link_ley
            }
            let response = await fetch('https://oposiciones-justicia.es/api/leyes/edtLey', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {

              fetch('https://oposiciones-justicia.es/api/leyes/consulta')
                .then(res => res.json())
                .then(leyes => {
                  setBusquedaLeyes(leyes)
                  setLey_seleccionada(leyes.find(l => l.id_ley == ley_seleccionada.id_ley))
                })
                .catch(error => console.log('Obejet fecth failed', error))

              refInputBusqueda.current.refs.buscar_ley_del.value = siglas_ley


              swal('Cambios realizados', '', 'success')

            } else {

              swal('Cambios no realizados', 'Rellene todos los datos para procesar el cambio', 'warning')

            }
          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });




    } else if (e.target.id == 'borrar_ley') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la ley será eliminada.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let data = {
              id_ley: ley_seleccionada.id_ley
            }
            let response = await fetch('https://oposiciones-justicia.es/api/leyes/deleteLeyN', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              setLey_seleccionada(null)
              setNombre_ley('')
              setSiglas_ley('')
              setLink_ley('')

              refInput1.current.refs.nombre_ley.value = ''
              refInput2.current.refs.siglas_ley.value = ''
              refInput3.current.refs.link_ley.value = ''
              refInputBusqueda.current.refs.buscar_ley_del.value = ''

              fetch('https://oposiciones-justicia.es/api/leyes/consulta')
                .then(res => res.json())
                .then(leyes =>
                  setBusquedaLeyes(leyes)
                )
                .catch(error => console.log('Obejet fecth failed', error))
            }
          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });
    }

  }

  const { selectedOption } = props

  switch (selectedOption) {
    case 'Añadir_Ley': {
      return (

        <div className="ColCenterStart">

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput1} autoComplete={'off'} id={'nombre_ley'} type='text' name={'nombre_ley'} placeholder='Nombre ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
            <p>Nombre Ley</p>
          </div>

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput2} autoComplete={'off'} id={'siglas_ley'} type='text' name={'siglas_ley'} placeholder='Siglas ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
            <p>Siglas Ley</p>
          </div>

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput3} autoComplete={'off'} id={'link_ley'} type='text' name={'link_ley'} placeholder='Link ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
            <p>Link Ley</p>
          </div>

          <Boton id='añadir_ley' onClick={handleSubmitForm} icon1='' icon2='' texto='Añadir Ley' />
        </div>
      )
    }
      break
    case 'Borrar_Ley': {
      return (
        <div className="ColCenterStart">

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInputBusqueda} datalist='busquedaLey' autoComplete={'off'} id={'buscar_ley_del'} type='text' name={'buscar_ley_del'} placeholder='Ley a editar' icon='fas fa-search' onChange={onChangeInput} />
            <p>Ley</p>
          </div>

          {ley_seleccionada != null ? (
            <div className="ColCenterStart">
              <h4 style={{ color: '#3469b7', marginBottom: '4px' }} title={ley_seleccionada.nombre_ley} > {ley_seleccionada.siglas_ley} </h4>

              <h5 style={{ textAlign: 'left', maxWidth: '400px', color: '#3469b7', marginBottom: '20px' }}> {ley_seleccionada.nombre_ley}</h5>

              <div className='celdaInputDAPFBN'>
                <Input2 ref={refInput1} autoComplete={'off'} id={'nombre_ley'} type='text' name={'nombre_ley'} placeholder='Nombre ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
                <p>Nombre Ley</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 ref={refInput2} autoComplete={'off'} id={'siglas_ley'} type='text' name={'siglas_ley'} placeholder='Siglas ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
                <p>Siglas Ley</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 ref={refInput3} autoComplete={'off'} id={'link_ley'} type='text' name={'link_ley'} placeholder='Link ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
                <p>Link Ley</p>
              </div>
              <Boton id='abrir_ley' onClick={handleSubmitForm} icon1='' icon2='' texto='Ver Ley' />

              <div className='RowCenterCenter' style={{ marginTop: '15px', marginBottom: '5px' }}>
                <div style={{ margin: '10px' }}>
                  <Boton id='editar_ley' onClick={handleSubmitForm} icon1='' icon2='' texto='Editar Ley' />
                </div>
                <div style={{ margin: '10px' }}>
                  <Boton id='borrar_ley' onClick={handleSubmitForm} icon1='' icon2='' texto='Borrar Ley' />
                </div>
              </div>


            </div>
          ) : null}

          <datalist id="busquedaLey">{
            busquedaLeyes && busquedaLeyes.map((ley, key) =>
              <option key={key} value={ley.siglas_ley}> {ley.nombre_ley} </option>
            )
          }</datalist>
        </div>
      )
    } break
    case 'Add_Art_Masivo': {
      return (
        <div className="ColCenterStart">

          <LeyesAdm />

        </div>
      )
    } break
    case 'Edt_Arts': {
      return (
        <div className="ColCenterStart">
          
          <EdtArts />

        </div>
      )
    } break
    default: {
      return (
        <div className="selectOptionToLoad">
          <i className="fa-thin fa-face-monocle"></i>
          <h3> Seleccione una opción para continuar </h3>
        </div>
      )
    }
  }

}

OptionBar.propTypes = {
  selectedOption: PropTypes.string.isRequired
}



export default OptionBar

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import EditorTexto from '../../Inputs/EditorTexto';
import TextoRich from '../../Inputs/TextoRich';
import Submit from '../../Inputs/Submit';

class LoadTest extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
        	this.state = {
			displayNameTest: 'flex',
			textHtml: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		    ]
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e, textHtml = ''){
		if(textHtml != ''){
			try{

				this.setState({textHtml: textHtml})
	 			// guardarTexto en BD!
	 			this.props.onChange(e, textHtml);
				if(textHtml.split('"') && textHtml.split('"').length > 1){
 				 throw new Error('Error uso de comillas dobles o simples');
 			 }


			}catch(e){
				if( e.message == "Error uso de comillas dobles o simples"){
	 		    swal('Error en texto del caso practico','No puede contener comillas simples ni dobles','warning')
	 	   }
			}

		} else {
			this.props.onChange(e);
		}
	}
	handleOnClick(e){
		this.props.onClick(e);
	}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		.then(res => res.json())
		.then(leyes => this.setState({
			busquedaLeyes: leyes
		}))
		.catch(error => console.log('Obejet fecth failed', error))

	}
	render() {
		const { id, width, height, urlPdf, toClose } = this.props;
		const { values, displayNameTest, busquedaOpo, textHtml } = this.state;
		return (
			<div className= "bodyLoadTest">
				<div className="opocionesLoadTest">

					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Nombre Caso Práctico
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" id="testPredefinidoName" name="testPredefinidoName" icon="fas fa-tag" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						¿A qué oposición pertenece?
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion" id="oposicionTest" name="oposicionTest" icon="fas fa-search" onChange={this.onChange}/>
					<datalist id="oposcionesData">{
						busquedaOpo && busquedaOpo.map((cls, key) =>
							<option key={key} value={cls.nombre}></option>
						)
					}</datalist>

					</div>
				</div>
				<div className="textAreaLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Texto del Caso Practico
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<EditorTexto dataInfoTextHtml="<p>¡Bienvenido al editor de texto de CEJ APP!</p>" onChange={this.onChange}/>
					<TextoRich valueTextHtml={textHtml} />
				</div>
				<div className="textAreaLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Test del Caso Practico
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<InputTextArea id="infoTest" name="infoTest" valueDefault="¡Pegar test completo aquí!" width="100%" height="20vh" onChange={this.onChange}/>
					<Submit id="prepararTest" icon1="fas fa-upload" icon2="fas fa-database" texto="Preparar Caso Práctico para Insertar" onClick={this.handleOnClick}/>
				</div>

			</div>
		);
	}
}

export default LoadTest;

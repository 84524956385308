// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../data/menuAdmin';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions

// Components
import MenuAdmin from './components/MenuAdmin';

class Administracion extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };

  render() {

    const { isLogged, nombreUser, tipoUser } = this.props;
    const nivelRequired = 98;

    if (isLogged && nivelRequired <= tipoUser) {
      return (
        <div className="inBodyMenu">
          <div className="menuBody">
            <MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="Administracion" />
          </div>
          <div className="inBody">
            <div className="selectOptionToLoad">
              <i className="fa-thin fa-face-monocle"></i>
              <h3> Seleccione una opción para continuar </h3>
            </div>
          </div>

        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser
  }
}

export default connect(mapStateToProps, null)(Administracion);

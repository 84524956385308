// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input2 from '../../components/Inputs/Input2'
//Assets
import './css/css404.css';
import InputFileIcono from '../../components/Inputs/InputFileIcono'

class Page404 extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired
  };

  render() {
    const { children } = this.props;
    if(this.props.isLogged){
    return (
      <div className="sectionBody404">
        <div className="imgW4040">
          <img src="../img/404.png" />
        </div>
        <div className="textoPag4040">
          <h2>Oops... Página no encontrada</h2>
          <br/><br/>
          <h3>La página que está buscando no existe.<br/><br/>Disculpe las molestias.</h3>
        </div>
        
        
      </div>
    );
  }else{
    return(<Redirect to ="/escritorio" />);
  }
  }
}
// Conectamos Redux Store
function mapStateToProps(state){
  return {
      isLogged: state.user.isLogged
  }
}
export default connect(mapStateToProps, null)(Page404);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
// Assets
import './css/prgInfoSel.css';
import Submit from '../../../Inputs/Submit';

class PreguntaInfoDel extends Component {

  static propTypes = {
    pregunta: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    preguntasToEdt: PropTypes.array.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          toogleInfoPregunta: 'none'
        };
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){


      if(e.target.id == 'toogleInfoPregunta'){
        let { id } = this.props;
        $('#infoPregunta-'+id).slideToggle(1000);
      }
    }

    handleOnClick(e){
      switch (e.target.id.split('-')[0]) {
        case 'seleccionarPreguntaEdt':
          this.props.onClick(e)
          let { id } = this.props;
          $('#infoPregunta-'+id).slideToggle(1000);
          break;
        default:

      }
    }

  componentDidMount(){


  }

  render() {
    const { id, pregunta, preguntasToEdt } = this.props;

              return (
                <div className="infoPreguntaBody">

                  <div className={

                    preguntasToEdt && preguntasToEdt.length > 0 ? (preguntasToEdt.filter(p=> p.idPregunta == pregunta.idPregunta).length > 0 ? ('resumenPreguntaSel'):('resumenPregunta')):('resumenPregunta')

                  }>

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.idPregunta}
                    </div>

                    <div className="infoCompressPregText">
                      {pregunta.preguntaTexto.substring(0,30)+'...'}
                    </div>

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.articulo}
                    </div>

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.siglasLey}
                    </div>
                    <div className="infoCompressPregToogle">
                      <i className="fas fa-caret-down" id={'toogleInfoPregunta'} onClick={this.onToggleClick}></i>
                    </div>

        					</div>
        					<div className="infoPregunta" id={'infoPregunta-'+id}>
                    <div className="infoPreguntaFlex">
                      <div className="delSelectPreg">
                      <Submit id={"seleccionarPreguntaEdt-"+id} icon1="fas fa-plus" icon2="fas fa-trash" texto="Añadir pregunta a Editar" onClick={this.handleOnClick}/>
                      </div>
          						<div className="PreguntaCompleta">

                        <i className="fas fa-question"></i>
                        <i className="fas fa-angle-right" style={{marginRight: '15px'}}></i>
                        <div className="textRespuesta">
                          {pregunta.preguntaTexto}
                        </div>
                      </div>

                      <div className={
                        pregunta.r1_validez == 1 ||
                        pregunta.r1_validez == 5 ||
                        pregunta.r1_validez == 6 ||
                        pregunta.r1_validez == 9 ||
                        pregunta.r1_validez == 11 ||
                        pregunta.r1_validez == 12 ||
                        pregunta.r1_validez == 17 ||
                        pregunta.r1_validez == 20 ||
                        pregunta.r1_validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> A </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r1}
                        </div>
                      </div>

                      <div className={
                        pregunta.r2_validez == 1 ||
                        pregunta.r2_validez == 5 ||
                        pregunta.r2_validez == 6 ||
                        pregunta.r2_validez == 9 ||
                        pregunta.r2_validez == 11 ||
                        pregunta.r2_validez == 12 ||
                        pregunta.r2_validez == 17 ||
                        pregunta.r2_validez == 20 ||
                        pregunta.r2_validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> B </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r2}
                        </div>
                      </div>

                      <div className={
                        pregunta.r3_validez == 1 ||
                        pregunta.r3_validez == 5 ||
                        pregunta.r3_validez == 6 ||
                        pregunta.r3_validez == 9 ||
                        pregunta.r3_validez == 11 ||
                        pregunta.r3_validez == 12 ||
                        pregunta.r3_validez == 17 ||
                        pregunta.r3_validez == 20 ||
                        pregunta.r3_validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                      <letra> C </letra>
                      <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {pregunta.r3}
                      </div>
                      </div>

                      <div className={
                        pregunta.r4_validez == 1 ||
                        pregunta.r4_validez == 5 ||
                        pregunta.r4_validez == 6 ||
                        pregunta.r4_validez == 9 ||
                        pregunta.r4_validez == 11 ||
                        pregunta.r4_validez == 12 ||
                        pregunta.r4_validez == 17 ||
                        pregunta.r4_validez == 20 ||
                        pregunta.r4_validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> D </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r4}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              );

        }
 }

export default PreguntaInfoDel;

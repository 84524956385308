// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';

import Boton from '../Inputs/Boton';

import Input2 from '../Inputs/Input2';
// Assets
import './css/cita.css';

class Cita extends Component {

  static propTypes = {
    fecha: PropTypes.string.isRequired,
    hora: PropTypes.string.isRequired,
    nombreAlumno: PropTypes.string.isRequired,
    preparador: PropTypes.string.isRequired,
    oposicion: PropTypes.string.isRequired,
    estudios: PropTypes.string.isRequired,
    opositor: PropTypes.string.isRequired,
    telefono: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    nota: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      fecha: undefined,
      hora: undefined,
      nombreAlumno: undefined,
      preparador: undefined,
      oposicion: undefined,
      estudios: undefined,
      opositor: undefined,
      telefono: undefined,
      nota: undefined
    };

    this.onToggleClick = this.onToggleClick.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }


  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onToggleClick(e) {
    let { id, nota } = this.props;
    $('#' + id).slideToggle(1000);

    let className = $('#togleboton-' + id).attr("class");
    let splitClass = className.split(' ')[1];

    if (splitClass == 'fa-angle-down') {
      $(('#togleboton-' + id)).removeClass('fa-light fa-angle-down').addClass('fa-light fa-angle-up')
    } else if (splitClass == 'fa-angle-up') {
      $(('#togleboton-' + id)).removeClass('fa-light fa-angle-up').addClass('fa-light fa-angle-down')
    }

    if ($('#nota' + id).val().length == 0) {
      $('#nota' + id).val(nota);
    }
  }

  async onHandleClick(e) {
   
    if (e.target.id == 'borrar') {
      let data = { id_cita: this.props.id };
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la cita será borrada.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            fetch("https://oposiciones-justicia.es/api/oficina/delCita", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              swal({
                title: 'Cita eliminada',
                text: '¡Recargando web!',
                icon: 'success',
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1200,
              }).then(() => {
                window.location.reload()
              })
            }).catch(function (err) { console.log(err) });


          } else {
            swal("¡La cita no se ha eliminado!", '', "success");
          }
        });
    } else if (e.target.id == 'edt') {
      let { fecha, nombreAlumno, preparador, oposicion, estudios, opositor, telefono, nota } = this.state
    

      let data = {
        id_cita: this.props.id,
        preparador: preparador,
        nameAlumno: nombreAlumno,
        oposicion: oposicion,
        estudios: estudios,
        opositor: opositor,
        tlf: telefono,
        fecha: fecha != undefined && fecha != '' ? moment(fecha).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
        hora: fecha != undefined && fecha != '' ? moment(fecha).format('HH:mm:ss') : moment().format('HH:mm:ss'),
        nota: nota
      }
      let response = await fetch('https://oposiciones-justicia.es/api/oficina/editCita', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      swal({
        title: 'Cita editada',
        text: ' ',
        icon: 'success',
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 1200,
      }).then(() => {
        swal.close()
      })
    }

  }

  
  componentDidMount() {
    let { id, fecha, hora, nombreAlumno, preparador, oposicion, estudios, opositor, telefono, nota } = this.props;

    this.setState({
      id: id,
      fecha: fecha,
      hora: moment(fecha).format('HH:mm'),
      nombreAlumno: nombreAlumno,
      preparador: preparador,
      oposicion: oposicion,
      estudios: estudios,
      opositor: opositor,
      telefono: telefono,
      nota: nota
    })

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/clases/consultaPre')
      .then(res => res.json())
      .then(preparadores => this.setState({
        busquedaPre: preparadores
      }))
      .catch(error => console.log('Object fecth failed', error))

  }


  render() {
    const { id, fecha, hora, nombreAlumno, preparador, oposicion, estudios, opositor, telefono, nota, busquedaPre, busquedaOpo } = this.state;

    return (
      id != undefined && fecha != undefined && hora != undefined && nombreAlumno != undefined && preparador != undefined && oposicion != undefined && estudios != undefined && opositor != undefined && telefono != undefined && nota != undefined ? (


        <div className="cita2x">
          <div className="infoBasica2x" onClick={this.onToggleClick}>
            <div className="infoBasicaLeft">
              <div className="bloqueInfox23" style={{ width: '210px' }}>
                <i className="fa-light fa-calendar" ></i> {moment(fecha).format("DD-MM-YY") + ' a las ' + moment(fecha).format("HH:mm")}
              </div>

              <div className="bloqueInfox23" >
                <i className="fa-light fa-user" ></i> {nombreAlumno}
              </div>
            </div>
            <div className="infoBasicaToggle">
              <div className="toggleInfo2x">
                <i id={'togleboton-' + id} className="fa-light fa-angle-down"></i>
              </div>
            </div>
          </div>

          <div id={id} className="infoCompleta">
            <div className='RowCenterCenter' style={{ maxWidth: '480px', width: '100%', marginTop: '10px' }}>



              <div className='celdaInputDAPFBN'>
                <Input2 value={nombreAlumno} autoComplete={'off'} id={'nombreAlumno'} type='text' name={'nombreAlumno'} ref={'nombreAlumno'} placeholder='Nombre Alumno' icon='fa-light fa-user' onChange={this.onChangeInput} />
                <p>Nombre</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 value={estudios} autoComplete={'off'} id={'estudios'} type='text' name={'estudios'} ref={'estudios'} placeholder='Estudios' icon='fa-light fa-briefcase' onChange={this.onChangeInput} />
                <p>Estudios</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 value={preparador} datalist='busquedaPre' autoComplete={'off'} id={'preparador'} type='text' name={'preparador'} ref={'preparador'} placeholder='Nombre preparador' icon='fa-light fa-user' onChange={this.onChangeInput} />
                <p>Preparador</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 value={oposicion} datalist='busquedaOpo' autoComplete={'off'} id={'oposicion'} type='text' name={'oposicion'} ref={'oposicion'} placeholder='Oposición' icon='fa-light fa-search' onChange={this.onChangeInput} />
                <p>Oposición</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 value={telefono} autoComplete={'off'} id={'telefono'} type='text' name={'telefono'} ref={'telefono'} placeholder='telefono' icon='fa-light fa-phone' onChange={this.onChangeInput} />
                <p>Teléfono</p>
              </div>

              <div className='celdaInputDAPFBN'>
                <Input2 value={opositor} autoComplete={'off'} id={'opositor'} type='text' name={'opositor'} ref={'opositor'} placeholder='opositor' icon='fa-light fa-circle-info' onChange={this.onChangeInput} />
                <p>¿Ha opositado?</p>
              </div>



              <div className='celdaInputDAPFBN' style={{ width: '220px' }}>
                <Input2 value={moment(fecha).format('YYYY-MM-DD HH:mm')} autoComplete={'off'} id={'fecha'} type='datetime-local' name={'fecha'} ref={'fecha'} placeholder='Fecha' icon='fa-light fa-calendar' onChange={this.onChangeInput} />
                <p>Fecha</p>
              </div>

              <datalist id="busquedaPre">{
                busquedaPre && busquedaPre.map((pre, key) =>
                  <option key={key} value={pre.nombre + ' '+pre.apellidos}></option>
                )
              }</datalist>
              <datalist id="busquedaOpo">{
                busquedaOpo && busquedaOpo.map((opo, key) =>
                  <option key={key} value={opo.nombre}></option>
                )
              }</datalist>

            </div>
            <div className="infoCompletaFlex2">
              <textarea rows="6" cols="50" id={'nota' + id} name="nota" placeholder="¡Notas aquí!" onChange={this.onChangeInput} />
            </div>
            <div className='RowCenterCenter' style={{ marginTop: '10px', justifyContent: 'space-between', width: '100%' }}>

              <div className='RowCenterCenter' style={{ padding: '5px' }} >
                <Boton id='borrar' onClick={this.onHandleClick} icon1='' icon2='' texto='Borrar' />
              </div>
              
              <div className='RowCenterCenter' style={{ padding: '5px' }} >
                <Boton id='edt' onClick={this.onHandleClick} icon1='' icon2='' texto='Editar' />
              </div>

            </div>


          </div>
        </div>
      ) : null
    );

  }
}

export default Cita;

// Actions
const GEN_EDT_PREG_CP = 'GEN_EDT_PREG_CP';
const ADD_PREG_TO_EDT_CP = 'ADD_PREG_TO_EDT_CP';
const DEL_PREG_TO_EDT_CP = 'DEL_PREG_TO_EDT_CP';

export function genPregToDelete(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG_CP,
      value: preguntaInicial
    };
}

export function genPregToDeleteCP(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG_CP,
      value: preguntaInicial
    };
}
export function addPregunta(preguntaNew, numPreg) {
    return {
      type: ADD_PREG_TO_EDT_CP,
      value: preguntaNew,
      numPreg: numPreg
    };
}
export function delPregunta(idPregToDel) {
    return {
	    type: DEL_PREG_TO_EDT_CP,
      idPreg: idPregToDel
    };
}

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import './css/css.css'


import CajaSemanaWord from '../CajaSemanaWord';
import CajaSemanaWordTest from '../CajaSemanaWordTest';
import Input2 from '../../components/Inputs/Input2';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';
import { Ellipsis } from '../../components/react-css-spinners'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactPlayer from 'react-player';
import InputFileExamDesa from '../../components/Inputs/InputFileExamDesa';


const Word2010Home = (props) => {
  const tipoUser = useSelector(state => state.user.tipoUser)
  const idUser = useSelector(state => state.user.idUser)
  const [viewSelect, setViewSelect] = useState('clases')
  const [viewSelect2, setViewSelect2] = useState(null)
  const [inicioNumeracion, setinicioNumeracion] = useState(0)
  const [finNumeracion, setfinNumeracion] = useState(6)
  const [clasesWord, setClasesWord] = useState([])
  const [clasesWordS, setClasesWordS] = useState([])
  const [temasWord, setTemasWord] = useState(null)
  const [claseWordSelect, setClaseWordSelect] = useState(null)
  const [temaClaseSelect, setTemaClaseSelect] = useState(null)
  const [tutorias, setTutorias] = useState([])
  const [tutoriaSelect, setTutoriaSelect] = useState(null)
  const [textHtmlDudaArtAlumno, setTextHtmlDudaArtAlumno] = useState('')
  const [textHtml2DudaArtAlumno, setTextHtml2DudaArtAlumno] = useState('')
  const [textHtml3DudaArtAlumno, setTextHtml3DudaArtAlumno] = useState('')
  const [textHtmlDudaResolucion, setTextHtmlDudaResolucion] = useState('')



  const [categoriasTemas, setcategoriasTemas] = useState([])
  const [categoriaTemaSelect, setcategoriaTemaSelect] = useState(null)
  const [temaWordSelect, settemaWordSelect] = useState(null)
  const [videoView, setVideoView] = useState(0)

  const [viewDudaTema, setViewDudaTema] = useState(null)

  const [descargas, setDescargars] = useState(null)



  const [temasClaseAdm, setTemasClaseAdm] = useState([])
  const [categoriasLoadWord, setCategoriasLoadWord] = useState([])
  const [categoriaSeleted, setCategoriaSeleted] = useState(null)


  const refNClase = useRef()
  const refInfoCont = useRef()

  const refNameCat = useRef()
  const refNCat = useRef()
  const refInfoCont2 = useRef()

  const refNameTema = useRef()
  const refNTema = useRef()
  const refInfoCont3 = useRef()


  const [fileArchivos, setfileArchivos] = useState(null)
  const [fileEjercicios, setfileEjercicios] = useState(null)
  const [fileVideos, setfileVideos] = useState(null)
  const [fileTest, setfileTest] = useState(null)
  const [fileTestSolucion, setfileTestSolucion] = useState(null)

  const [temaSelectAdm, setTemaSelectedAdm] = useState(null)
  const refCateAdm = useRef()
  const refNVideo = useRef()

  const refFechaTuto = useRef()
  const refUrlTuto = useRef()
  const refInfoContTuto = useRef()
  const refInfoIndiTuto = useRef()

  const [tutoriaSelectAdm, setTutoriaSelectAdm] = useState()

  const [practicaSelectAdm, setPracticaSelectAdm] = useState()


  const [dudasAdmPendientes, setDudasAdmPendientes] = useState([])
  const [claseWordAdm, setclaseWordAdm] = useState(null)

  const [Practicas, setPracticas] = useState(null)
  const [practicaSelect, setPracticaSelect] = useState(null)

  const [EstadoAlumno, setEstadoAlumno] = useState(null)

  const [EstadoFactura, setEstadoFactura] = useState(null)

  const [intensivoCompra, setintensivoCompra] = useState(false)
  const [normalCompra, setnormalCompra] = useState(true)


  const [testSemanales, setTestSemanales] = useState(null)
  const [testSelectAdm, settestSelectAdm] = useState(null)


  // const [indicacionesEstudio, setindicacionesEstudio] = useState(null)
  // Falta añadir Test, editar/borrar test.

  useEffect(() => {
    // cargamos clases word 2010
    const fetchDataWord = async () => {
      try {
        const { clases, temasWord, categoriasTemario, tutorias } = await loadClasesWordYtemasYtutorias();

        const accesoAlumno = await obtenerAccesoAlumno();
        console.log('accesoAlumno', accesoAlumno)
        setEstadoAlumno(accesoAlumno)
        // console.log(`Meses Pagados: ${accesoAlumno.mesesPagados}`);
        // console.log(`Clases desbloqueadas: ${accesoAlumno.clasesDesbloqueadas}`);
        // console.log(`Test activados: ${accesoAlumno.testWordActivados}`);
        // console.log(`Practica Principal: ${accesoAlumno.practicaPrincipal}`);

        if (accesoAlumno.clasesDesbloqueadas > 0) {
          const testSemanalesLoad = await obtenerTestSemanales(accesoAlumno);

          console.log('testSemanalesLoad: ', testSemanalesLoad)
          setTestSemanales(testSemanalesLoad)

          for (let i = 0; i < clases.length; i++) {

            if (tipoUser > 50 || clases[i].numClase <= accesoAlumno.clasesDesbloqueadas) {
              clases[i].desbloqueada = 1;
            }
          }

          for (let j = 0; j < temasWord.length; j++) {
            if (tipoUser > 50 || temasWord[j].numeroTema <= accesoAlumno.clasesDesbloqueadas) {
              temasWord[j].desbloqueada = 1;
            }
          }

          // console.log('clases:', clases);
          setClasesWord(clases)
          setClasesWordS(clases.slice())

          // console.log('temasWord:', temasWord);
          setTemasWord(temasWord)

          // console.log('categoriasTemario:', categoriasTemario);
          setcategoriasTemas(categoriasTemario)

          // console.log('tutorias:', tutorias);
          // let tutoFiltro = obtenerTresTutoriasCercanas(tutorias)
          // console.log('tutoFiltro: ', tutoFiltro);
          // setTutorias(tutoFiltro)

          // let data1 = { idUser: '' }
          // let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/practicasTutorias', {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          // });
          // if (!response1.ok) {
          //   throw Error(response1.statusText);
          // }
          // let json1 = await response1.json();
          // let cls1 = await json1;

          // let data2 = { idUser: '' }
          // let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/archivosPracticasTutorias', {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          // });
          // if (!response2.ok) {
          //   throw Error(response2.statusText);
          // }
          // let json2 = await response2.json();
          // let cls2 = await json2;

          // for (let x = 0; x < cls1.length; x++) {

          //   let filtro = await cls2.filter(a => a.idPractica == cls1[x].idPractica)

          //   cls1[x].archivos = await filtro

          // }

          // if (tipoUser >= 50) {
          //   // setPracticas(cls1)
          // } else {

          //   if (accesoAlumno.mesesPagados >= 4 || accesoAlumno.practicaPrincipal == 'intensivo') {
          //     if (accesoAlumno.practicaPrincipal != 'intensivo') {
          //       cls1[accesoAlumno.practicaPrincipal - 1].principal = 1
          //     }
          //     setPracticas(cls1)
          //   } else {
          //     cls1[accesoAlumno.practicaPrincipal - 1].principal = 1
          //     cls1 = cls1.slice(0, accesoAlumno.mesesPagados)
          //     setPracticas(cls1)
          //   }

          // }

        }

      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchDataWord();


  }, []);


  async function obtenerAccesoAlumno() {

    // ver las facturas del alumno sacar el numero de meses pagados.
    // si es intensivo activar todo.

    let data1 = { idUser: idUser }
    let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/facturas', {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
    });
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    console.log('facturas')
    console.log(cls1)

    const FacturasAlumno = await cls1.filter(a => a.tipo == '100') // las facturas filtradas por tipo
    const facturasAlumnoIntensivo = await cls1.filter(a => a.tipo == '101')

    if ((facturasAlumnoIntensivo.length == 0 && FacturasAlumno.length == 0)) {
      setEstadoFactura('sinComprar')
      return {
        clasesDesbloqueadas: 0,
        testWordActivados: 0,
      };

    }

    let response1x = await fetch('https://oposiciones-justicia.es/api/word2010/infoEstudioInformatica');

        if (!response1x.ok) {
        throw Error(response1x.statusText);
        }
        let json1x = await response1x.json();
        let cls1x = await json1x;
        
    if (tipoUser >= 60) {
      setEstadoFactura('activo')
      return {
        mesesPagados: 12,
        clasesDesbloqueadas: 16,
        testWordActivados: 100,
        practicaPrincipal: 'intensivo',
        indicacionEstudio: cls1x[0].Repaso
      }
    }

    if (facturasAlumnoIntensivo.length > 0) {
      // intensivo
      let caducidad = moment(facturasAlumnoIntensivo[0].fecha_caducidad).startOf('day')
      let fechaActual = moment().startOf('day');
      let haPasado = fechaActual.isAfter(caducidad);

      // let caducidad = moment(FacturasAlumno[0].fecha_caducidad).startOf('day')
      // let fechaActual = moment().startOf('day')
      // let haPasado = fechaActual.isAfter(caducidad)

      let estadoPago = facturasAlumnoIntensivo[0].estado
      if (estadoPago == "Pendiente") {
        setEstadoFactura('pendiente')
        return {
          clasesDesbloqueadas: 0,
          testWordActivados: 0,
        };
      }

      if (haPasado == true) { // caducado

        if (FacturasAlumno.length == 0) {

          setEstadoFactura('caducada')

          return {
            clasesDesbloqueadas: 0,
            testWordActivados: 0,
          };
        } else {
          let estadoPago = FacturasAlumno[0].estado
          if (estadoPago == "Pendiente") {
            setEstadoFactura('pendiente')
            return {
              clasesDesbloqueadas: 0,
              testWordActivados: 0,
            };
          }

          let caducidad2 = moment(FacturasAlumno[0].fecha_caducidad).startOf('day')
          let fechaActual2 = moment().startOf('day');
          let haPasado2 = fechaActual2.isAfter(caducidad2);

          if (haPasado2 == true) {

            setEstadoFactura('caducada')

            return {
              clasesDesbloqueadas: 0,
              testWordActivados: 0,
            };
          }
        }

      }
      // comprobacion facturas

      const mesesPagados = FacturasAlumno.length;

      const clasesDesbloqueadas = 16;
      const testWordActivados = 50;
      let practicaPrincipal = 'intensivo'

      setEstadoFactura('activo')

      return {
        mesesPagados,
        clasesDesbloqueadas,
        testWordActivados,
        practicaPrincipal
      }


    } else {
      // normal

      let estadoPago = FacturasAlumno[0].estado
      if (estadoPago == "Pendiente") {
        setEstadoFactura('pendiente')
        return {
          clasesDesbloqueadas: 0,
          testWordActivados: 0,
        };
      }


      let caducidad = moment(FacturasAlumno[0].fecha_caducidad).startOf('day')
      let fechaActual = moment().startOf('day')
      let haPasado = fechaActual.isAfter(caducidad) // Debería dar true si hoy está después de la fecha de caducidad

      if (haPasado == true) {

        setEstadoFactura('caducada')

        return {
          clasesDesbloqueadas: 0,
          testWordActivados: 0,
        };
      }


      const mesesPagados = FacturasAlumno.filter(a => a.estado == 'Pagado').length;

      const clasesDesbloqueadas = mesesPagados >= 4 ? 16 : mesesPagados * 4;
      const testWordActivados = mesesPagados > 4 ? ((mesesPagados - 4) * 6) : 0;
      let practicaPrincipal = null

      

        let indicacionEstudio
      if (mesesPagados > 4) {
        let totalMesCtrl = mesesPagados
        while (totalMesCtrl > 4) {
          totalMesCtrl = totalMesCtrl - 4
        }
        practicaPrincipal = totalMesCtrl

        // coger indicacion estudio REPASO
        
        indicacionEstudio = cls1x[0].Repaso
        


      } else {
        practicaPrincipal = mesesPagados
        // coger indicacion estudio normal
        indicacionEstudio = cls1x[0].Normal
      }

      setEstadoFactura('activo')
      return {
        mesesPagados,
        clasesDesbloqueadas,
        testWordActivados,
        practicaPrincipal,
        indicacionEstudio
      }
    }




  }

  async function obtenerTestSemanales(accesoAlumno) {

    let response2 = await fetch("https://oposiciones-justicia.es/api/word2010/testSemanalesWord2010");
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;

    let filtro = tipoUser > 50 ? await cls2 : await cls2.slice(0, accesoAlumno.testWordActivados)
    return filtro

  }

  async function loadClasesWordYtemasYtutorias() {

    let clases = []
    let temasWord = []

    let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/clasesWord2010");
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;
    clases = await cls1

    let response3 = await fetch('https://oposiciones-justicia.es/api/word2010/temasClases');
    if (!response3.ok) {
      throw Error(response3.statusText);
    }
    let json3 = await response3.json();
    let cls3 = await json3;

    let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/temas');
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;
    temasWord = await cls2

    let response4 = await fetch('https://oposiciones-justicia.es/api/word2010/temasArchivos');
    if (!response4.ok) {
      throw Error(response4.statusText);
    }
    let json4 = await response4.json();
    let cls4 = await json4;

    let response5 = await fetch('https://oposiciones-justicia.es/api/word2010/temasDudas');
    if (!response5.ok) {
      throw Error(response5.statusText);
    }
    let json5 = await response5.json();
    let cls5 = await json5;

    let response6 = await fetch('https://oposiciones-justicia.es/api/word2010/temasVideos');
    if (!response6.ok) {
      throw Error(response6.statusText);
    }
    let json6 = await response6.json();
    let cls6 = await json6;

    for (let j = 0; j < temasWord.length; j++) {

      let filtroArchivos = await cls4.filter(a => a.idTema == temasWord[j].idTema)
      let filtroDudas = await cls5.filter(a => a.idTema == temasWord[j].idTema)
      let filtroVideos = await cls6.filter(a => a.idTema == temasWord[j].idTema).sort((a, b) => a.numVideo - b.numVideo)

      temasWord[j].archivos = await filtroArchivos
      temasWord[j].dudas = await filtroDudas
      temasWord[j].videos = await filtroVideos
    }

    for (let i = 0; i < clases.length; i++) {

      let filtroTema = await cls3.filter(a => a.idClase == clases[i].idClase)

      if (filtroTema.length > 0) {
        for (let k = 0; k < filtroTema.length; k++) {

          let filtroTemaCompleto = await temasWord.find(a => a.idTema == filtroTema[k].idTema)

          if (filtroTemaCompleto != undefined) {
            if (clases[i].temas == undefined) {
              clases[i].temas = []
              await clases[i].temas.push(filtroTemaCompleto)
            } else {
              await clases[i].temas.push(filtroTemaCompleto)
            }
          }

        }
      } else {
        clases[i].temas = []
      }

    }

    let response7 = await fetch('https://oposiciones-justicia.es/api/word2010/temasCategorias');
    if (!response7.ok) {
      throw Error(response7.statusText);
    }
    let json7 = await response7.json();
    let cls7 = await json7;
    let response8 = await fetch('https://oposiciones-justicia.es/api/word2010/temasCategoriasTemas');
    if (!response8.ok) {
      throw Error(response8.statusText);
    }
    let json8 = await response8.json();
    let cls8 = await json8;

    let categoriasTemario = []

    for (let l = 0; l < cls7.length; l++) {
      categoriasTemario.push(cls7[l])

      let filtroTemasCat = await cls8.filter(a => a.categoria == cls7[l].categoria)

      categoriasTemario[categoriasTemario.length - 1].temas = []

      for (let x = 0; x < filtroTemasCat.length; x++) {

        let filtroTemaCompleto = await temasWord.find(a => a.idTema == filtroTemasCat[x].idTema)

        if (filtroTemaCompleto != undefined) {
          categoriasTemario[categoriasTemario.length - 1].temas.push(filtroTemaCompleto)
        }

        let locaTem = await temasWord.findIndex(a => a.idTema == filtroTemasCat[x].idTema)
        if (locaTem != -1) {
          temasWord[locaTem].categoria = await filtroTemasCat[x].categoria
        }

      }
    }

    let tutorias = []
    let response9 = await fetch('https://oposiciones-justicia.es/api/word2010/tutorias');
    if (!response9.ok) {
      throw Error(response9.statusText);
    }
    let json9 = await response9.json();
    let cls9 = await json9;
    let response10 = await fetch('https://oposiciones-justicia.es/api/word2010/tutoriasAlumnos');
    if (!response10.ok) {
      throw Error(response10.statusText);
    }
    let json10 = await response10.json();
    let cls10 = await json10;

    let response11 = await fetch('https://oposiciones-justicia.es/api/word2010/tutoriasSugerencias');
    if (!response11.ok) {
      throw Error(response11.statusText);
    }
    let json11 = await response11.json();
    let cls11 = await json11;
    tutorias = await cls9
    for (let h = 0; h < tutorias.length; h++) {

      let filtroAlumnosTutorias = await cls10.filter(a => a.idTutoria == tutorias[h].idTutoria)
      let filtroSugerenciasTuto = await cls11.filter(a => a.idTutoria == tutorias[h].idTutoria)

      tutorias[h].alumnos = await filtroAlumnosTutorias
      tutorias[h].sugerencias = await filtroSugerenciasTuto

    }


    let response21 = await fetch("https://oposiciones-justicia.es/api/word2010/temarioWord2010");
    if (!response21.ok) {
      throw Error(response21.statusText);
    }
    let json21 = await response21.json();
    let cls21 = await json21;

    setDescargars({
      temario: cls21[0].linkTemario,
      atajos: cls21[0].linkAtajos
    })

    let response22 = await fetch("https://oposiciones-justicia.es/api/word2010/categoriasWord2010");
    if (!response22.ok) {
      throw Error(response22.statusText);
    }
    let json22 = await response22.json();
    let cls22 = await json22;
    setCategoriasLoadWord(cls22)

    let response23 = await fetch("https://oposiciones-justicia.es/api/word2010/temasDudasAdm");
    if (!response23.ok) {
      throw Error(response23.statusText);
    }
    let json23 = await response23.json();
    let cls23 = await json23;

    for (let ghj = 0; ghj < cls23.length; ghj++) {

      let filtroTemaCompleto = await temasWord.find(a => a.idTema == cls23[ghj].idTema)
      cls23[ghj].infoTema = await filtroTemaCompleto

    }
    setDudasAdmPendientes(cls23)


    return { clases, temasWord, categoriasTemario, tutorias }
  }

  async function myFiles(e) {
    // comprobar archivo y subir
    let id = e.target.id
    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < await files.length; i++) {
      filesLoad.push(files[i]);
    }

    if (id == 'fileApuntes') {
      setfileArchivos(filesLoad)
    } else if (id == 'fileEjercicios') {
      setfileEjercicios(filesLoad)
    } else if (id == 'fileVideos') {
      setfileVideos(filesLoad)
    } else if (id == 'fileTest') {
      setfileTest(filesLoad)
    } else if (id == 'fileSolucion') {
      setfileTestSolucion(filesLoad)
    }
  }

  async function myFilesAutoUpdate(e) {
    // comprobar archivo y subir

    let id = e.target.id
    // console.log('ID: ', id)
    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < await files.length; i++) {
      filesLoad.push(files[i]);
    }

    if (id == 'fileApuntes') {
      if (filesLoad.length > 0) {
        let dataArchivos = new FormData();
        for (var i = 0; i < filesLoad.length; i++) {
          dataArchivos.append('myFiles', filesLoad[i]);
        }
        let archivos = [];
        if (dataArchivos.getAll('myFiles').length > 0) {

          let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST', body: dataArchivos
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          for (var i2 = 0; i2 < cls.length; i2++) {
            let path = '..' + cls[i2].path.split('public')[1];
            let nameFile = cls[i2].originalname

            archivos.push({ nombre: nameFile, link: path, tipo: 'apunte', idTema: temaSelectAdm.idTema });
          }

          if (archivos.length > 0) {

            let data1 = { archivos: archivos }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Apuntes', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let temaWordC = await temasWord.slice()
            let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

            if (loca != -1) {
              for (let jh = 0; jh < archivos.length; jh++) {
                temaWordC[loca].archivos.push({
                  idArchivo: cls1.insertId + jh,
                  idTema: archivos[jh].idTema,
                  link: archivos[jh].link,
                  nombre: archivos[jh].nombre,
                  tipo: 'apunte'
                })
              }
              setTemasWord(temaWordC)
            }

          }
        }
      }

    } else if (id == 'fileEjercicios') {
      if (filesLoad.length > 0) {
        let dataArchivos = new FormData();
        for (var i = 0; i < filesLoad.length; i++) {
          dataArchivos.append('myFiles', filesLoad[i]);
        }
        let archivos = [];
        if (dataArchivos.getAll('myFiles').length > 0) {

          let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST', body: dataArchivos
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          for (var i2 = 0; i2 < cls.length; i2++) {
            let path = '..' + cls[i2].path.split('public')[1];
            let nameFile = cls[i2].originalname

            archivos.push({ nombre: nameFile, link: path, tipo: 'ejercicio', idTema: temaSelectAdm.idTema });
          }

          if (archivos.length > 0) {
            let data1 = { archivos: archivos }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Apuntes', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let temaWordC = await temasWord.slice()
            let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

            if (loca != -1) {
              for (let jh = 0; jh < archivos.length; jh++) {
                temaWordC[loca].archivos.push({
                  idArchivo: cls1.insertId + jh,
                  idTema: archivos[jh].idTema,
                  link: archivos[jh].link,
                  nombre: archivos[jh].nombre,
                  tipo: 'ejercicio'
                })
              }
              setTemasWord(temaWordC)
            }

          }
        }
      }
    } else if (id == 'fileVideos') {
      if (filesLoad.length > 0) {
        let dataVideos = new FormData();
        for (var k = 0; k < filesLoad.length; k++) {
          dataVideos.append('myFiles', filesLoad[k]);
        }
        let videos = [];
        if (dataVideos.getAll('myFiles').length > 0) {

          let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST', body: dataVideos
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          for (var j2 = 0; j2 < cls.length; j2++) {
            let path = '..' + cls[j2].path.split('public')[1];
            let nameFile = cls[j2].originalname

            let numVideoxd = temaSelectAdm.videos.length > 0 ? (temaSelectAdm.videos.length + j2) : (j2)
            videos.push({ nombre: nameFile, url: path, numVideo: numVideoxd, idTema: temaSelectAdm.idTema });

          }

        }
        if (videos.length > 0) {
          let data1 = { videos: videos }
          let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Videos', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          let temaWordC = await temasWord.slice()
          let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

          if (loca != -1) {

            for (let jh = 0; jh < videos.length; jh++) {
              temaWordC[loca].videos.push({
                idVideo: cls1.insertId + jh,
                idTema: videos[jh].idTema,
                url: videos[jh].url,
                nombre: videos[jh].nombre,
                numVideo: videos[jh].numVideo
              })
            }
            temaWordC[loca].videos = await temaWordC[loca].videos.sort((a, b) => a.numVideo - b.numVideo)
            setVideoView(temaWordC[loca].videos.length - 1)
            setTemasWord(temaWordC)

            setTimeout(() => {
              if (temaWordC[loca].videos.length > 0) {

                refNVideo.current.refs.nVideo.value = temaWordC[loca].videos[temaWordC[loca].videos.length - 1].numVideo
              }
            }, 200);
          }

        }
      }
    } else if (id == 'fileEjerciciosPracticas') {
      if (filesLoad.length > 0) {
        let dataArchivos = new FormData();
        for (var i = 0; i < filesLoad.length; i++) {
          dataArchivos.append('myFiles', filesLoad[i]);
        }
        let archivos = [];
        if (dataArchivos.getAll('myFiles').length > 0) {

          let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST', body: dataArchivos
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          for (var i2 = 0; i2 < cls.length; i2++) {
            let path = '..' + cls[i2].path.split('public')[1];
            let nameFile = cls[i2].originalname

            archivos.push({ nombre: nameFile, link: path, idPractica: practicaSelectAdm.idPractica });
          }

          if (archivos.length > 0) {
            let data1 = { archivos: archivos }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addPracticaApuntes', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let PracticaC = await Practicas.slice()
            let loca = await Practicas.findIndex(a => a.idPractica == practicaSelectAdm.idPractica)

            if (loca != -1) {
              for (let jh = 0; jh < archivos.length; jh++) {
                PracticaC[loca].archivos.push({
                  id: cls1.insertId + jh,
                  idPractica: practicaSelectAdm.idPractica,
                  link: archivos[jh].link,
                  nombre: archivos[jh].nombre
                })
              }
              setPracticas(PracticaC)
            }

          }
        }
      }
    }

  }



  function obtenerTresTutoriasCercanas(tutorias) {
    const fechaActual = moment();

    const tutoriasCercanas = tutorias
      .filter(tutoria => moment(tutoria.fecha).isSameOrAfter(fechaActual, 'day')) // Filtra tutorías futuras y del mismo día
      .sort((a, b) => moment(a.fecha).diff(b.fecha)) // Ordena por cercanía a la fecha actual
      .slice(0, 3);

    return tutoriasCercanas;
  }


  async function handleOnClic(e) {
    if (e.target.id == 'siguiente') {

      let ini = inicioNumeracion + 6
      let fin = finNumeracion + 6
      let letght = viewSelect == 'clases' ? clasesWord.length : testSemanales.length

      if (ini > 0) {

        if (fin <= letght) {
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        } else {
          fin = letght
          if ((ini + 6) - letght > 6) {
            setinicioNumeracion(ini)
            setfinNumeracion(fin)
          } else {
            ini = letght - 6 > 0 ? (letght - 6) : (0)
            setinicioNumeracion(ini)
            setfinNumeracion(fin)
          }
        }
      } else {
        ini = 0
        fin = 6
        if (fin <= letght) {
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        } else {
          fin = letght
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        }
      }

    } else if (e.target.id == 'anterior') {


      let ini = inicioNumeracion - 6
      let fin = finNumeracion - 6
      let letght = viewSelect == 'clases' ? clasesWord.length : testSemanales.length
      if (ini > 0) {

        if (fin <= letght) {
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        } else {
          fin = letght
          if ((ini + 6) - letght > 6) {
            setinicioNumeracion(ini)
            setfinNumeracion(fin)
          } else {
            ini = letght - 6 > 0 ? (letght - 6) : (0)
            setinicioNumeracion(ini)
            setfinNumeracion(fin)
          }
        }
      } else {
        ini = 0
        fin = 6
        if (fin <= letght) {
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        } else {
          fin = letght
          setinicioNumeracion(ini)
          setfinNumeracion(fin)
        }
      }


    } else if (e.target.id == 'Clases') {
      setinicioNumeracion(0)
      setfinNumeracion(6)
      setViewSelect('clases')
    } else if (e.target.id == 'Temario') {
      setinicioNumeracion(0)
      setfinNumeracion(6)
      setViewSelect('temario')
    } else if (e.target.id == 'ClaseOnline') {
      setinicioNumeracion(0)
      setfinNumeracion(6)
      setViewSelect('claseOnline')

    } else if (e.target.id == 'Test') {
      setinicioNumeracion(0)
      setfinNumeracion(6)
      setViewSelect('test')

    } else if (e.target.id == 'descargarManual') {
      // descargas

      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
      let pathFile = 'public' + descargas.temario.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = 'Manual Word.2010 - CEJ.pdf'
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    } else if (e.target.id == 'descargarAtajos') {
      // descargas
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + descargas.atajos.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = 'Atajos Word 2010 - CEJ'
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    } else if (e.target.id == 'enviarDudaTemario') {

      let data1 = { idUser: idUser, idTema: temaWordSelect.idTema, duda: textHtml2DudaArtAlumno }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/enviarDudaTemaW', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal('Duda enviada', 'La resolución de la duda se le enviará a su correo electrónico, así como podrá verla en el apartado dudas del tema cuando recarge la página. Recuerde comprobar su email en perfil.', 'info')
        setTextHtml2DudaArtAlumno('')
      }


    } else if (e.target.id == 'enviarDudaTemarioWClase') {

      let data1 = { idUser: idUser, idTema: temaClaseSelect.idTema, duda: textHtml3DudaArtAlumno }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/enviarDudaTemaW', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal('Duda enviada', 'La resolución de la duda se le enviará a su correo electrónico, así como podrá verla en el apartado dudas del tema. Recuerde comprobar su email en perfil.', 'info')
        setTextHtml3DudaArtAlumno('')
      }


    } else if (e.target.id.split('-')[0] == 'descargaApunteC') {
      let key = e.target.id.split('-')[1]
      let linkDescarga = temaClaseSelect.archivos.filter(a => a.tipo == 'apunte')[key].link
      let nameDes = temaClaseSelect.archivos.filter(a => a.tipo == 'apunte')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });



    } else if (e.target.id.split('-')[0] == 'descargaEjercicioC') {

      let key = e.target.id.split('-')[1]
      let linkDescarga = temaClaseSelect.archivos.filter(a => a.tipo == 'ejercicio')[key].link

      let nameDes = temaClaseSelect.archivos.filter(a => a.tipo == 'ejercicio')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    } else if (e.target.id.split('-')[0] == 'descargaApunteT') {
      let key = e.target.id.split('-')[1]
      let linkDescarga = temaWordSelect.archivos.filter(a => a.tipo == 'apunte')[key].link
      let nameDes = temaWordSelect.archivos.filter(a => a.tipo == 'apunte')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });



    } else if (e.target.id.split('-')[0] == 'descargaEjercicioT') {

      let key = e.target.id.split('-')[1]
      let linkDescarga = temaWordSelect.archivos.filter(a => a.tipo == 'ejercicio')[key].link

      let nameDes = temaWordSelect.archivos.filter(a => a.tipo == 'ejercicio')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    } else if (e.target.id == 'confirmarAsistencia') {

      if (moment().format('YYYY-MM-DD') <= moment(tutorias[tutoriaSelect].fecha).subtract(1, 'days').format('YYYY-MM-DD')) {
        let idTuto = tutorias[tutoriaSelect].idTutoria

        let data1 = { idUser: idUser, idTutoria: idTuto, tipo: 'online' }
        let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/confirmarAsistencia', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        if (cls1.affectedRows > 0) {
          swal('Asistencia confirmada', '', 'info')

          let tutoriasC = tutorias.slice()


          let loca = tutoriasC.findIndex(a => a.idTutoria == idTuto)

          if (loca != -1) {
            tutoriasC[loca].alumnos.push({
              idTutoria: idTuto,
              idUser: idUser,
              tipo: 'online'
            })

            setTutorias(tutoriasC)
          }

        }
      } else {
        swal('Tutoria no disponible', 'Solo puede confirmar la asistencia hasta 1 día antes de la tutoría.', 'info')
      }

    } else if (e.target.id == 'confirmarAsistenciaP') {
      let alumnosP = tutorias[tutoriaSelect].alumnos.filter(a => a.tipo == 'presencial').length

      if (moment().format('YYYY-MM-DD') <= moment(tutorias[tutoriaSelect].fecha).subtract(1, 'days').format('YYYY-MM-DD')) {
        if (alumnosP < 26) {

          let idTuto = tutorias[tutoriaSelect].idTutoria

          let data1 = { idUser: idUser, idTutoria: idTuto, tipo: 'presencial' }
          let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/confirmarAsistencia', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.affectedRows > 0) {
            swal('Asistencia confirmada', '', 'info')

            let tutoriasC = tutorias.slice()


            let loca = tutoriasC.findIndex(a => a.idTutoria == idTuto)

            if (loca != -1) {
              tutoriasC[loca].alumnos.push({
                idTutoria: idTuto,
                idUser: idUser,
                tipo: 'presencial'
              })

              setTutorias(tutoriasC)
            }

          }
        } else {
          swal('No hay hueco disponible', 'Ya se han apuntado el máximo de alumnos para la tutoría presencial, puede apuntarse confirmando su asistencia online.', 'info')
        }
      } else {
        swal('Tutoria no disponible', 'Solo puede confirmar la asistencia hasta 1 día antes de la tutoría.', 'info')
      }


    } else if (e.target.id == 'cancelarAsistencia') {
      let idTuto = tutorias[tutoriaSelect].idTutoria

      let data1 = { idUser: idUser, idTutoria: idTuto }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cancelarAsistencia', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal('Asistencia cancelada', '', 'info')

        let tutoriasC = tutorias.slice()


        let loca = tutoriasC.findIndex(a => a.idTutoria == idTuto)
        let locaUser = tutoriasC[loca].alumnos.findIndex(a => a.idUser == idUser)

        if (loca != -1) {
          tutoriasC[loca].alumnos.splice(locaUser, 1)

          setTutorias(tutoriasC)
        }

      }
    } else if (e.target.id == 'enviarSugerencia') {

      let idTuto = tutorias[tutoriaSelect].idTutoria
      let data1 = { idUser: idUser, idTutoria: idTuto, texto: textHtmlDudaArtAlumno }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/enviarSuge', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal('Sugerencia enviada', 'Puedes cancelarla en el panel de sugerencias', 'info')
        let tutoriasC = tutorias.slice()

        let loca = tutoriasC.findIndex(a => a.idTutoria == idTuto)

        if (loca != -1) {
          tutoriasC[loca].sugerencias.push({
            idTutoria: idTuto,
            idUser: idUser,
            texto: textHtmlDudaArtAlumno,
            idSugerencia: cls1.insertId
          })

          setTutorias(tutoriasC)
        }

        setTextHtmlDudaArtAlumno('')
      }

    } else if (e.target.id.split('-')[0] == 'cancelarSugerencia') {
      let idTuto = tutorias[tutoriaSelect].idTutoria
      let loca = e.target.id.split('-')[1]
      let idSuge = tutorias[tutoriaSelect].sugerencias[loca].idSugerencia
      let data1 = { idSugerencia: idSuge }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cancelarSuge', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal('Sugerencia cancelada', '', 'info')

        let tutoriasC = tutorias.slice()

        let loca = tutoriasC.findIndex(a => a.idTutoria == idTuto)

        if (loca != -1) {
          tutoriasC[loca].sugerencias.splice(loca, 1)

          setTutorias(tutoriasC)
        }

      }
    } else if (e.target.id == 'entrarEnClase') {

      let tutoria = tutorias[tutoriaSelect]

      if (moment().add(15, 'minutes').format('YYYY-MM-DD HH:mm') >= moment(tutoria.fecha).format('YYYY-MM-DD HH:mm')) {
        let dif = moment().diff(moment(tutoria.fecha), 'days')

        if (dif == 0) {
          window.open(tutoria.linkClase, '_blank');
        } else {
          swal('Esta tutoria ya finalizo', '', 'info')
        }

      } else {
        swal('Aun no puede acceder', 'El acceso será abierto ' + moment(tutoria.fecha).subtract(15, 'minutes').format('[El ] DD-MM-YY [ a las ]HH:mm'), 'info')
      }
    } else if (e.target.id == 'comprarPreparacionWord2010') {

      let fechaFac = moment().utc().startOf('month');
      let fechaCad = intensivoCompra == true ? moment().utc().add(2, 'months').endOf('month') : moment().utc().endOf('month');


      let data3 = {
        idAlumno: idUser,
        fechaFactura: fechaFac,
        fechaCaducidad: fechaCad,
        tipo: intensivoCompra == true ? 101 : 100,
        precio: intensivoCompra == true ? 60 : 30,
      }
      let urlF3 = "https://oposiciones-justicia.es/api/word2010/crearFACMensualTestNew"

      let response3 = await fetch(urlF3, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;

      let data2 = {
        idAlumno: idUser
      }
      let urlFetch2 = 'https://oposiciones-justicia.es/api/word2010/crearAlumno'
      let response2 = await fetch(urlFetch2, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;


      swal({
        title: 'Factura creada.',
        text: 'Puede realizar el pago en el apartador FACTURAS',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then(() => {
        window.location.reload()
      })

    } else if (e.target.id == 'comprarMes') {

      let fechaFac = moment().utc().startOf('month');
      let fechaCad = moment().utc().endOf('month');


      let data3 = {
        idAlumno: idUser,
        fechaFactura: fechaFac,
        fechaCaducidad: fechaCad,
        tipo: 100,
        precio: 30,
      }
      let urlF3 = "https://oposiciones-justicia.es/api/word2010/crearFACMensualTestNew"

      let response3 = await fetch(urlF3, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      });
      if (!response3.ok) {
        throw Error(response3.statusText);
      }
      let json3 = await response3.json();
      let cls3 = await json3;


      swal({
        title: 'Factura creada.',
        text: 'Puede realizar el pago en el apartador FACTURAS',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 5000,
      }).then(() => {
        window.location.reload()
      })

    } else if (e.target.id.split('-')[0] == 'descargaEjercicioP') {

      let puedeDescargar = null

      if (Practicas[practicaSelect].principal == 1) {

        let tutoria = tutorias[tutoriaSelect]

        if (moment().add(15, 'minutes').format('YYYY-MM-DD HH:mm') >= moment(tutoria.fecha).format('YYYY-MM-DD HH:mm')) {

          puedeDescargar = true

        } else {
          swal('Aun no puede descargar', 'La descarga estará disponible ' + moment(tutoria.fecha).subtract(15, 'minutes').format('[El ] DD-MM-YY [ a las ]HH:mm'), 'info')
        }

      } else {
        puedeDescargar = true
      }

      if (puedeDescargar != null && puedeDescargar == true) {
        let key = e.target.id.split('-')[1]
        let linkDescarga = Practicas[practicaSelect].archivos[key].link
        let nameDes = Practicas[practicaSelect].archivos[key].nombre

        swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

        let pathFile = 'public' + linkDescarga.split('..')[1]
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = nameDes
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          swal("Link no valido para descarga", "Reportelo, gracias.", 'info')
        });
      }


    }





  }
  function onChangeText(e, editor) {

    let textHtml = editor.getData();

    if (textHtml != '') {
      setTextHtmlDudaArtAlumno(textHtml.replaceAll('"', "'"))
    }


  }

  function onChangeText2(e, editor) {

    let textHtml = editor.getData();

    if (textHtml != '') {
      setTextHtml2DudaArtAlumno(textHtml.replaceAll('"', "'"))
    }


  }

  function onChangeText3(e, editor) {

    let textHtml = editor.getData();

    if (textHtml != '') {
      setTextHtml3DudaArtAlumno(textHtml.replaceAll('"', "'"))
    }


  }

  function onChangeTextResolucion(e, editor) {

    let textHtml = editor.getData();

    if (textHtml != '') {

      setTextHtmlDudaResolucion(textHtml.replaceAll('"', "'"))
    }


  }

  function onChangeInput(e) {

    if (e.target.id == 'busquedaClse') {

      let val = e.target.value
      let busqueda = clasesWord.filter(a => a.numClase == val)

      // console.log('val:', val)
      // console.log('busqueda:', busqueda)
      if (val != '' && busqueda.length > 0) {
        setClasesWord(busqueda)
      } else {
        setClasesWord(clasesWordS)
      }

    }

  }

  async function handleOnClicAdm(e) {

    if (e.target.id == 'addTemaClase') {
      let temasClaseAdmC = temasClaseAdm.slice()
      temasClaseAdmC.push({ value: '' })
      setTemasClaseAdm(temasClaseAdmC)
    } else if (e.target.id.split('-')[0] == 'borrarTema') {
      let loca = e.target.id.split('-')[1]
      let temasClaseAdmC = temasClaseAdm.slice()
      temasClaseAdmC.splice(loca, 1)
      setTemasClaseAdm(temasClaseAdmC)
    } else if (e.target.id == 'addClase') {
      let nClase = parseInt(refNClase.current.refs.nClaseAdm.value)
      let iClase = refInfoCont.current.value

      let data1 = { nClase, iClase }

      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addClase', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      let temasAF = temasClaseAdm.filter(a => a.idTema != undefined)
      let data2 = { idClase: cls1.insertId, temas: temasAF }
      let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemasClase', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      if (cls2.affectedRows > 0) {
        await swal({
          title: 'Clase insertado',
          text: '¡Recargando web!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 1500,
        }).then(() => {
          window.location.reload()
        })
      }
    } else if (e.target.id == 'addCategoria') {

      let nombre = refNameCat.current.refs.nameCategoria.value
      let ordenCategoria = refNCat.current.refs.nCategoria.value
      let temasInfo = refInfoCont2.current.value

      let data1 = { nombre, ordenCategoria, temasInfo }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addCategoriaWord2010', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      await swal({
        title: 'Categoría insertada',
        text: '¡Finalizando!',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 1500,
      }).then(() => {
        refNameCat.current.refs.nameCategoria.value = ''
        refNCat.current.refs.nCategoria.value = ''
        refInfoCont2.current.value = ''

        setTimeout(async () => {
          let response22 = await fetch("https://oposiciones-justicia.es/api/word2010/categoriasWord2010");
          if (!response22.ok) {
            throw Error(response22.statusText);
          }
          let json22 = await response22.json();
          let cls22 = await json22;
          setCategoriasLoadWord(cls22)

          setCategoriaSeleted(null)
          setViewSelect2(null)
        }, 200);
      })

    } else if (e.target.id == 'edtCategoria') {

      let nombre = refNameCat.current.refs.nameCategoria.value
      let ordenCategoria = refNCat.current.refs.nCategoria.value
      let temasInfo = refInfoCont2.current.value

      let categoria = categoriaSeleted.categoria

      let data1 = { categoria, nombre, ordenCategoria, temasInfo }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/updateCategoriaWord2010', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        await swal({
          title: 'Categoría actualizada',
          text: '¡Finalizando!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 1500,
        }).then(async () => {
          refNameCat.current.refs.nameCategoria.value = ''
          refNCat.current.refs.nCategoria.value = ''
          refInfoCont2.current.value = ''

          let response22 = await fetch("https://oposiciones-justicia.es/api/word2010/categoriasWord2010");
          if (!response22.ok) {
            throw Error(response22.statusText);
          }
          let json22 = await response22.json();
          let cls22 = await json22;

          setCategoriasLoadWord(cls22)

          setTimeout(() => {
            setCategoriaSeleted(null)
            setViewSelect2(null)
          }, 200);
        })
      }
    } else if (e.target.id == 'addTema') {
      swal({
        title: 'Insertando tema',
        text: 'Insertando tema + subiendo archivos ejercicios y videos.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      // insertar tema y pasar la id a cada uno
      let nombre = refNameTema.current.refs.nameTema.value
      let numeroTema = refNTema.current.refs.nTema.value
      let infoTema = refInfoCont3.current.value

      if (nombre != '' && numeroTema != '' && infoTema != '') {

        let data1 = { nombre, infoTema, numeroTema }
        let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let idTemaWord = await cls1.insertId
        if (idTemaWord != undefined) {
          // insertar categoria tema
          let data2 = { categoria: categoriaSeleted.categoria, idTema: idTemaWord }
          let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaCategoria', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;

          let dataArchivos = new FormData();
          let dataEjercicios = new FormData();
          let dataVideos = new FormData();
          if (fileArchivos != null) {
            for (var i = 0; i < fileArchivos.length; i++) {
              dataArchivos.append('myFiles', fileArchivos[i]);
            }
          }

          if (fileEjercicios != null) {
            for (var j = 0; j < fileEjercicios.length; j++) {
              dataEjercicios.append('myFiles', fileEjercicios[j]);
            }
          }
          if (fileVideos != null) {
            for (var k = 0; k < fileVideos.length; k++) {
              dataVideos.append('myFiles', fileVideos[k]);
            }
          }
          let archivos = [];
          if (dataArchivos.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataArchivos
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var i2 = 0; i2 < cls.length; i2++) {
              let path = '..' + cls[i2].path.split('public')[1];
              let nameFile = cls[i2].originalname

              archivos.push({ nombre: nameFile, link: path, tipo: 'apunte', idTema: idTemaWord });

            }
          }

          let ejercicios = [];
          if (dataEjercicios.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataEjercicios
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var j2 = 0; j2 < cls.length; j2++) {
              let path = '..' + cls[j2].path.split('public')[1];
              let nameFile = cls[j2].originalname

              ejercicios.push({ nombre: nameFile, link: path, tipo: 'ejercicio', idTema: idTemaWord });

            }
          }

          let videos = [];
          if (dataVideos.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataVideos
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var j2 = 0; j2 < cls.length; j2++) {
              let path = '..' + cls[j2].path.split('public')[1];
              let nameFile = cls[j2].originalname

              videos.push({ nombre: nameFile, url: path, numVideo: j2, idTema: idTemaWord });

            }
          }

          if (archivos.length > 0) {
            // insertamos

            let data1 = { archivos: archivos }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Apuntes', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          if (ejercicios.length > 0) {
            // insertamos
            let data1 = { archivos: ejercicios }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Apuntes', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          if (videos.length > 0) {
            // insertamos
            let data1 = { videos: videos }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemaWord2010Videos', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          await swal({
            title: 'Tema insertado',
            text: '¡Recargando web!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 1500,
          }).then(() => {
            window.location.reload()
          })
        }
      } else {
        swal('Error', 'Complete todos los datos', 'info')
      }


    } else if (e.target.id.split('-')[0] == 'viewVideoAdm') {
      let loca = e.target.id.split('-')[1]
      setVideoView(loca)

      setTimeout(() => {
        refNVideo.current.refs.nVideo.value = temaSelectAdm.videos[loca].numVideo
      }, 200);
    } else if (e.target.id.split('-')[0] == 'descargaApunteTAdm') {
      let key = e.target.id.split('-')[1]
      let linkDescarga = temaSelectAdm.archivos.filter(a => a.tipo == 'apunte')[key].link
      let nameDes = temaSelectAdm.archivos.filter(a => a.tipo == 'apunte')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });



    } else if (e.target.id.split('-')[0] == 'descargaEjercicioTAdm') {

      let key = e.target.id.split('-')[1]
      let linkDescarga = temaSelectAdm.archivos.filter(a => a.tipo == 'ejercicio')[key].link

      let nameDes = temaSelectAdm.archivos.filter(a => a.tipo == 'ejercicio')[key].nombre
      swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')

      let pathFile = 'public' + linkDescarga.split('..')[1]
      let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      let nameFile = nameDes
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
      }).then(res => {
        if (res.status >= 400) { throw new Error("Bad res from server"); }
        return res.blob();
      }).then(blob => {
        saveAs(blob, nameFile);
      }).catch(function (err) {
        console.log(err)
        alert("Link no valido para descarga, reportelo, gracias.")
      });
    } else if (e.target.id == 'edtTemaInfo') {
      let nombre = refNameTema.current.refs.nameTema.value
      let numeroTema = refNTema.current.refs.nTema.value
      let infoTema = refInfoCont3.current.value

      let idTemaWord = temaSelectAdm.idTema

      let data1 = { nombre, infoTema, numeroTema, idTema: idTemaWord }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/edtTemaWord2010', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      let data2 = { categoria: categoriaSeleted.categoria, idTema: idTemaWord, categoriaS: temaSelectAdm.categoria }
      let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/edtCatTemaWord2010', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      let temaWordC = await temasWord.slice()
      let loca = await temasWord.findIndex(a => a.idTema == idTemaWord)
      if (loca != -1) {
        temaWordC[loca].nombre = nombre
        temaWordC[loca].numeroTema = parseInt(numeroTema)
        temaWordC[loca].infoTema = infoTema
        temaWordC[loca].categoria = categoriaSeleted.categoria
      }


      setTemasWord(temaWordC)
      swal('Datos actualizados', 'Puede seguir editando el tema', 'info')
    } else if (e.target.id.split('-')[0] == 'deleteApunteTAdm') {
      let loca2 = e.target.id.split('-')[1]

      let archivoDel = temaSelectAdm.archivos.filter(a => a.tipo == 'apunte')[loca2]

      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del apunte",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { idArchivo: archivoDel.idArchivo }

            let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/borrarApunteTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data44 = { pathFile: '../public' + archivoDel.link.split('..')[1] }
            let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
            });
            if (!response44.ok) {
              // throw Error(response44.statusText);
              // console.log(response44.statusText)
            }
            let json44 = await response44.json();
            let cls44 = await json44;

            if (cls1.affectedRows > 0) {

              let temaWordC = await temasWord.slice()
              let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

              if (loca != -1) {

                temaWordC[loca].archivos = temaWordC[loca].archivos.filter(a => a.idArchivo != archivoDel.idArchivo)

                setTemasWord(temaWordC)
              }
            }
          }
        });
    } else if (e.target.id.split('-')[0] == 'deleteEjercicioTAdm') {
      let loca2 = e.target.id.split('-')[1]

      let archivoDel = temaSelectAdm.archivos.filter(a => a.tipo == 'ejercicio')[loca2]
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del ejercicio",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { idArchivo: archivoDel.idArchivo }

            let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/borrarApunteTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data44 = { pathFile: '../public' + archivoDel.link.split('..')[1] }
            let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
            });
            if (!response44.ok) {
              // throw Error(response44.statusText);
              // console.log(response44.statusText)
            }
            let json44 = await response44.json();
            let cls44 = await json44;

            if (cls1.affectedRows > 0) {

              let temaWordC = await temasWord.slice()
              let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

              if (loca != -1) {

                temaWordC[loca].archivos = temaWordC[loca].archivos.filter(a => a.idArchivo != archivoDel.idArchivo)

                setTemasWord(temaWordC)
              }
            }
          }
        });

    } else if (e.target.id.split('-')[0] == 'deleteEjercicioTAdmPract') {
      let loca2 = e.target.id.split('-')[1]

      let archivoDel = practicaSelectAdm.archivos[loca2]
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del ejercicio",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { idArchivo: archivoDel.id }

            let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/borrarApuntePractica", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data44 = { pathFile: '../public' + archivoDel.link.split('..')[1] }
            let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
            });
            if (!response44.ok) {
              // throw Error(response44.statusText);
              // console.log(response44.statusText)
            }
            let json44 = await response44.json();
            let cls44 = await json44;

            if (cls1.affectedRows > 0) {

              let temaWordC = await Practicas.slice()
              let loca = await Practicas.findIndex(a => a.idPractica == practicaSelectAdm.idPractica)

              if (loca != -1) {

                temaWordC[loca].archivos = temaWordC[loca].archivos.filter(a => a.id != archivoDel.id)

                setPracticas(temaWordC)
              }
            }
          }
        });

    } else if (e.target.id == 'deleteTemaVideo') {

      let archivoDel = temaSelectAdm.videos[videoView]
      swal({
        title: "¿Estas segur@?",
        text: "Procedera a la eliminación del video",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { idVideo: archivoDel.idVideo }

            let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/borrarVideoTema", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data44 = { pathFile: '../public' + archivoDel.url.split('..')[1] }
            let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
            });
            if (!response44.ok) {
              // throw Error(response44.statusText);
              // console.log(response44.statusText)
            }
            let json44 = await response44.json();
            let cls44 = await json44;

            if (cls1.affectedRows > 0) {

              let temaWordC = await temasWord.slice()
              let loca = await temasWord.findIndex(a => a.idTema == temaSelectAdm.idTema)

              if (loca != -1) {

                temaWordC[loca].videos = temaWordC[loca].videos.filter(a => a.idVideo != archivoDel.idVideo)
                if (temaWordC[loca].videos.length > 0) {
                  let pre = videoView == 0 ? 0 : videoView - 1
                  if (pre >= 0) {
                    setVideoView(pre)
                  } else {
                    setVideoView(null)
                  }
                } else {
                  setVideoView(null)
                }
                setTemasWord(temaWordC)
              }
            }
          }
        });

    } else if (e.target.id == 'edtTemaVideo') {
      if (refNVideo.current.refs.nVideo.value != '') {

        let archivoEdt = temaSelectAdm.videos[videoView]

        let idVideo = archivoEdt.idVideo
        let numVideo = parseInt(refNVideo.current.refs.nVideo.value)
        let idTemaWord = temaSelectAdm.idTema

        let data1 = { idVideo, numVideo, idTema: idTemaWord }
        let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/edtVideoTemaW', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let temaWordC = await temasWord.slice()
        let loca = await temasWord.findIndex(a => a.idTema == idTemaWord)
        let loca2 = await temasWord[loca].videos.findIndex(a => a.idVideo == idVideo)
        if (loca != -1 && loca2 != -1) {
          temaWordC[loca].videos[loca2].numVideo = numVideo
        }
        temaWordC[loca].videos = await temaWordC[loca].videos.sort((a, b) => a.numVideo - b.numVideo)
        setTemasWord(temaWordC)
        setTimeout(() => {
          refNVideo.current.refs.nVideo.value = temaSelectAdm.videos[videoView].numVideo
        }, 200);


        swal('Video actualizado', 'Puede seguir editando el tema', 'info')

      } else {
        swal('Indique el numero del video', '', 'info')
      }
    } else if (e.target.id == 'addTutoria') {
      let fecha = refFechaTuto.current.refs.fechaTutoria.value
      let linkClase = refUrlTuto.current.refs.urlTutoria.value
      let contenido = refInfoContTuto.current.value
      let indicaciones = refInfoIndiTuto.current.value

      let data1 = { fecha, linkClase, contenido, indicaciones }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/addTutoria', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal({
          title: 'Tutoría insertada',
          text: '¡Recargando web!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 1500,
        }).then(() => {
          window.location.reload()
        })
      }
    } else if (e.target.id == 'edtTutoria') {

      let fecha = refFechaTuto.current.refs.fechaTutoria.value
      let linkClase = refUrlTuto.current.refs.urlTutoria.value
      let contenido = refInfoContTuto.current.value
      let indicaciones = refInfoIndiTuto.current.value

      let data1 = { fecha, linkClase, contenido, indicaciones, idTutoria: tutoriaSelectAdm.idTutoria }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/edtTutoria', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal({
          title: 'Tutoría editada',
          text: '¡Recargando web!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 1500,
        }).then(() => {
          window.location.reload()
        })
      }
    } else if (e.target.id == 'edtPracticaTuto') {

      let numPractica = refUrlTuto.current.refs.numPractica.value
      let contenido = refInfoContTuto.current.value

      let data1 = { numPractica, contenido, idPractica: practicaSelectAdm.idPractica }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/edtTutoriaPractica', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1.affectedRows > 0) {
        swal({
          title: 'Tutoría editada',
          text: '¡Recargando web!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 1500,
        }).then(() => {
          window.location.reload()
        })
      }
    } else if (e.target.id == 'resolverDuda') {
      let idDuda = dudasAdmPendientes[viewDudaTema].idDuda
      let resolucion = textHtmlDudaResolucion

      // resolvemos bd
      let data1 = { idDuda, resolucion }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/resolverDuda', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      // enviamos email a todos los alumnos activos
      if (cls1.affectedRows > 0) {
        let response05 = await fetch('https://oposiciones-justicia.es/api/preparador/alumnosActivosPorClaseCurso');
        if (!response05.ok) {
          throw Error(response05.statusText);
        }
        let json05 = await response05.json();
        let cls05 = await json05;

        let alm = []

        for (let x23c = 0; x23c < cls05.length; x23c++) {

          if (cls05[x23c].accesoWebHabilitado == 1 && cls05[x23c].ActivoEnClase == 1) {
            let bus = alm.findIndex(a => a.id_usuario == cls05[x23c].id_usuario)
            let bus2 = alm.findIndex(a => a.email == cls05[x23c].email)


            if (bus == -1 && bus2 == -1) {
              let emailRegx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
              if (emailRegx.test(cls05[x23c].email)) {

                alm.push(cls05[x23c])

              }

            }
          }

        }

        swal({
          title: "¿Enviamos email a los alumnos?",
          text: "Si le das a ok, se enviará un email a todos los alumnos activos.",
          icon: "info",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              for (let index = 0; index < alm.length; index++) {
                let data2d = {
                  emailToEnviar: alm[index].email,

                  titulo: 'CEJ - Oposiciones justicia', // quien lo envia
                  segundoTitulo: 'Duda Word 2010 Resuelta', // titulo real
                  textoHtml: `
                              <p> Duda word 2010, resuelta.</p>
                              <p><b>Duda planteada:</b> </p> 
                              `+ dudasAdmPendientes[viewDudaTema].duda + `<br/>
                              <p><b>Resolución:</b></p>
                              `+ resolucion + `<br/>
                              <p>Un saludo, <br/> Fernando Díaz CEJ.</p>
                            `
                }

                fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data2d)
                }).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
                  .then(info => {

                  }).catch(error => console.log('Object fecth failed', error))

              }


              await swal({
                title: 'Duda resuelta y emails enviados',
                text: '¡Recargando web!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2000,
              }).then(() => {
                window.location.reload()
              })
            } else {
              await swal({
                title: 'Duda resuelta',
                text: '¡Recargando web!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1500,
              }).then(() => {
                window.location.reload()
              })
            }
          });

      }
      // antes tenemos que definir quienes son los alumnos de word 2010.

    } else if (e.target.id == 'addTemaClaseEdt') {
      let claseWw = JSON.parse(JSON.stringify(claseWordAdm))

      claseWw.temas.push({ value: '', new: true })

      setclaseWordAdm(claseWw)

    } else if (e.target.id.split('-')[0] == 'borrarTemaEdt') {
      let loca = e.target.id.split('-')[1]

      let claseWw = JSON.parse(JSON.stringify(claseWordAdm))

      if (claseWw.temas[loca].new != undefined) {
        claseWw.temas.splice(loca, 1)
        setclaseWordAdm(claseWw)
      } else {
        // borrar de bd
        let data1 = { idClase: claseWw.idClase, idTema: claseWw.temas[loca].idTema }
        let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/borrarTemaEdt', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        //actualizar state
        if (cls1.affectedRows > 0) {
          claseWw.temas.splice(loca, 1)
          setclaseWordAdm(claseWw)
        }

      }

    } else if (e.target.id == 'edtClaseInfo') {
      let claseWw = JSON.parse(JSON.stringify(claseWordAdm))

      let nClase = parseInt(refNClase.current.refs.nClaseAdm.value)
      let infoC = refInfoCont.current.value
      let idClase = claseWordAdm.idClase

      let data1 = { idClase, infoC, nClase }
      let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/edtClaseInfo', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      //actualizar state
      if (cls1.affectedRows > 0) {

        claseWw.numClase = nClase
        claseWw.contenidoTarjeta = infoC
        setclaseWordAdm(claseWw)

        swal('Clase actualizada', '', 'success')
      }
    } else if (e.target.id == 'addTest') {
      swal({
        title: 'Insertando test',
        text: 'Insertando test + subiendo archivos.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      // insertar tema y pasar la id a cada uno
      let numTest = refNameTema.current.refs.numTest.value

      if (numTest != '') {

        let data = { numTest: numTest }
        // 1º ver si esta en alumnos word 2010 y si esta activo.

        let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/insertTestWord2010", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let idTestAdd = await cls1.insertId
        if (idTestAdd != undefined) {

          let dataTest = new FormData();
          let dataTestRespuestas = new FormData();

          if (fileTest != null) {
            for (var i = 0; i < fileTest.length; i++) {
              dataTest.append('myFiles', fileTest[i]);
            }
          }


          if (fileTestSolucion != null) {
            for (var j = 0; j < fileTestSolucion.length; j++) {
              dataTestRespuestas.append('myFiles', fileTestSolucion[j]);
            }
          }

          let testLoad = [];
          if (dataTest.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataTest
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var i2 = 0; i2 < cls.length; i2++) {
              let path = '..' + cls[i2].path.split('public')[1];

              testLoad.push({ urlArchivo: path, idSemana: idTestAdd });

            }
          }

          let solucionLoad = [];
          if (dataTestRespuestas.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataTestRespuestas
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var j2 = 0; j2 < cls.length; j2++) {
              let path = '..' + cls[j2].path.split('public')[1];

              solucionLoad.push({ urlArchivo: path, idSemana: idTestAdd });

            }
          }



          if (testLoad.length > 0) {
            // insertamos

            let data1 = { urlArchivo: testLoad[0].urlArchivo, idSemana: idTestAdd }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cambiarTestWord2010', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          if (solucionLoad.length > 0) {
            // insertamos
            let data1 = { urlArchivo: solucionLoad[0].urlArchivo, idSemana: idTestAdd }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cambiarSolucionTestWord2010', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }



          await swal({
            title: 'Test insertado',
            text: '¡Recargando web!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 1500,
          }).then(() => {
            window.location.reload()
          })
        }
      } else {
        swal('Error', 'Complete todos los datos', 'info')
      }


    } else if (e.target.id == 'edtTest') {
      swal({
        title: 'Insertando test',
        text: 'Editando test.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      // insertar tema y pasar la id a cada uno
      let numTest = refNameTema.current.refs.numTest.value

      if (numTest != '') {

        let data = { numTest: numTest, idSemana: testSelectAdm.id_semana }
        // 1º ver si esta en alumnos word 2010 y si esta activo.

        let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/editTestWord2010", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let idTestAdd = testSelectAdm.id_semana
        if (idTestAdd != undefined) {

          let dataTest = new FormData();
          let dataTestRespuestas = new FormData();

          if (fileTest != null) {
            for (var i = 0; i < fileTest.length; i++) {
              dataTest.append('myFiles', fileTest[i]);
            }
          }


          if (fileTestSolucion != null) {
            for (var j = 0; j < fileTestSolucion.length; j++) {
              dataTestRespuestas.append('myFiles', fileTestSolucion[j]);
            }
          }

          let testLoad = [];
          if (dataTest.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataTest
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var i2 = 0; i2 < cls.length; i2++) {
              let path = '..' + cls[i2].path.split('public')[1];

              testLoad.push({ urlArchivo: path, idSemana: idTestAdd });

            }
          }

          let solucionLoad = [];
          if (dataTestRespuestas.getAll('myFiles').length > 0) {

            let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST', body: dataTestRespuestas
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            for (var j2 = 0; j2 < cls.length; j2++) {
              let path = '..' + cls[j2].path.split('public')[1];

              solucionLoad.push({ urlArchivo: path, idSemana: idTestAdd });

            }
          }



          if (testLoad.length > 0) {
            // insertamos
            if (testSelectAdm.link_test != '' && testSelectAdm.link_test != null && testSelectAdm.link_test != 'null') {
              //borramos archivos

              let data44 = { pathFile: '../public' + testSelectAdm.link_test.split('..')[1] }
              let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
              });
              if (!response44.ok) {
                // throw Error(response44.statusText);
                console.log(response44.statusText)
              }
              let json44 = await response44.json();
              let cls44 = await json44;

            }
            let data1 = { urlArchivo: testLoad[0].urlArchivo, idSemana: idTestAdd }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cambiarTestWord2010', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          if (solucionLoad.length > 0) {
            // insertamos
            if (testSelectAdm.link_soluciones != '' && testSelectAdm.link_soluciones != null && testSelectAdm.link_soluciones != 'null') {
              //borramos archivos

              let data44 = { pathFile: '../public' + testSelectAdm.link_soluciones.split('..')[1] }
              let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
              });
              if (!response44.ok) {
                // throw Error(response44.statusText);
                console.log(response44.statusText)
              }
              let json44 = await response44.json();
              let cls44 = await json44;

            }

            let data1 = { urlArchivo: solucionLoad[0].urlArchivo, idSemana: idTestAdd }
            let response1 = await fetch('https://oposiciones-justicia.es/api/word2010/cambiarSolucionTestWord2010', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }



          await swal({
            title: 'Test editado',
            text: '¡Recargando web!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 1500,
          }).then(() => {
            window.location.reload()
          })
        }
      } else {
        swal('Error', 'Complete todos los datos', 'info')
      }


    } else if (e.target.id == 'delTest') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el test será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data = { id_semana: testSelectAdm.id_semana }
            // 1º ver si esta en alumnos word 2010 y si esta activo.

            let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/delTest", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let idTestAdd = testSelectAdm.id_semana

            if (idTestAdd != undefined) {


              // insertamos
              if (testSelectAdm.link_test != '' && testSelectAdm.link_test != null && testSelectAdm.link_test != 'null') {
                //borramos archivos

                let data44 = { pathFile: '../public' + testSelectAdm.link_test.split('..')[1] }
                let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
                });
                if (!response44.ok) {
                  // throw Error(response44.statusText);
                  console.log(response44.statusText)
                }
                let json44 = await response44.json();
                let cls44 = await json44;

              }


              // insertamos
              if (testSelectAdm.link_soluciones != '' && testSelectAdm.link_soluciones != null && testSelectAdm.link_soluciones != 'null') {
                //borramos archivos

                let data44 = { pathFile: '../public' + testSelectAdm.link_soluciones.split('..')[1] }
                let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
                });
                if (!response44.ok) {
                  // throw Error(response44.statusText);
                  console.log(response44.statusText)
                }
                let json44 = await response44.json();
                let cls44 = await json44;

              }


              await swal({
                title: 'Test borrado',
                text: '¡Recargando web!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1500,
              }).then(() => {
                window.location.reload()
              })
            }
          } else {
            swal("¡Test no borrado!", '', "success");
          }
        });






    }

  }

  async function onChangeInputAdm(e) {
    if (e.target.id.split('-')[0] == 'temaW') {
      let loca = e.target.id.split('-')[1]
      let val = e.target.value
      if (val.split('-') != undefined) {
        let idTema = val.split('-')[0]

        let busWT = temasWord.find(a => a.idTema == idTema)
        if (busWT == undefined) {
          let temasClaseAdmC = temasClaseAdm.slice()

          temasClaseAdmC[loca] = { value: e.target.value }

          setTemasClaseAdm(temasClaseAdmC)
        } else {
          let temasClaseAdmC = temasClaseAdm.slice()

          temasClaseAdmC[loca] = busWT
          temasClaseAdmC[loca].value = e.target.value

          setTemasClaseAdm(temasClaseAdmC)
        }

      } else {
        let temasClaseAdmC = temasClaseAdm.slice()

        temasClaseAdmC[loca] = { value: e.target.value }

        setTemasClaseAdm(temasClaseAdmC)
      }



    } else if (e.target.id == 'categoriaW') {

      let val = e.target.value.split('-')[0]
      let busWT = categoriasLoadWord.find(a => a.categoria == val)

      if (busWT != undefined) {
        setCategoriaSeleted(busWT)
        setTimeout(() => {
          refNameCat.current.refs.nameCategoria.value = busWT.nombre
          refNCat.current.refs.nCategoria.value = busWT.ordenCategoria
          refInfoCont2.current.value = busWT.temasInfo
        }, 200);
      } else {
        setCategoriaSeleted(null)
      }

    } else if (e.target.id == 'categoriaWC') {
      if (e.target.value != '') {
        let val = e.target.value.split('-')[0]
        let busWT = categoriasLoadWord.find(a => a.categoria == val)

        if (busWT != undefined) {
          setCategoriaSeleted(busWT)
        } else {
          setCategoriaSeleted(null)
        }
      } else {
        setCategoriaSeleted(null)
      }


    } else if (e.target.id == 'temaWC') {
      let value = e.target.value
      let idT = value.split('-')[0]
      let busT = temasWord.find(a => a.idTema == idT)

      if (busT != undefined) {
        setTemaSelectedAdm(busT)

        setTimeout(() => {

          let cat = categoriasLoadWord.find(a => a.categoria == busT.categoria)
          setCategoriaSeleted(cat)
          refNameTema.current.refs.nameTema.value = busT.nombre
          refNTema.current.refs.nTema.value = busT.numeroTema
          refInfoCont3.current.value = busT.infoTema
          refCateAdm.current.refs.categoriaWC.value = cat.categoria + '-' + cat.nombre
          if (busT.videos.length > 0) {
            refNVideo.current.refs.nVideo.value = busT.videos[0].numVideo
          }


        }, 200);
      } else {
        setTemaSelectedAdm(null)
        setCategoriaSeleted(null)
      }
      // temaSelectAdm
      // setTemaSelectedAdm()
    } else if (e.target.id == 'testWC') {
      let value = e.target.value
      let busT = testSemanales.find(a => a.numero_test == value)

      if (busT != undefined) {
        settestSelectAdm(busT)

        setTimeout(() => {

          refNameTema.current.refs.numTest.value = busT.numero_test

        }, 200);
      } else {
        settestSelectAdm(null)
      }

    } else if (e.target.id == 'tutoAdmS') {
      let value = e.target.value
      let idT = value.split('-')[0]
      let busT = tutorias.find(a => a.idTutoria == idT)

      if (value != '') {
        if (busT != undefined) {

          setTutoriaSelectAdm(busT)

          setTimeout(() => {
            refFechaTuto.current.refs.fechaTutoria.value = moment(busT.fecha).format('YYYY-MM-DD[T]HH:mm')
            refUrlTuto.current.refs.urlTutoria.value = busT.linkClase
            refInfoContTuto.current.value = busT.contenido
            refInfoIndiTuto.current.value = busT.indicaciones
          }, 200);

        } else {
          setTutoriaSelectAdm(null)
        }
      } else {
        setTutoriaSelectAdm(null)
      }
    } else if (e.target.id == 'tutoAdmSP') {
      let value = e.target.value
      let idT = value
      let busT = Practicas.find(a => a.idPractica == idT)

      if (value != '') {
        if (busT != undefined) {

          setPracticaSelectAdm(busT)

          setTimeout(() => {
            refUrlTuto.current.refs.numPractica.value = busT.numPractica
            refInfoContTuto.current.value = busT.contenido
          }, 200);



        } else {
          setPracticaSelectAdm(null)
        }
      } else {
        setPracticaSelectAdm(null)
      }
    } else if (e.target.id == 'busquedaClse') {

      let val = e.target.value
      let busqueda = clasesWord.find(a => a.numClase == val)

      if (val != '' && busqueda != undefined) {
        for (let i = 0; i < busqueda.temas.length; i++) {
          busqueda.temas[i].value = await busqueda.temas[i].idTema + '-' + busqueda.temas[i].nombre
        }
        setclaseWordAdm(busqueda)

        setTimeout(() => {
          refNClase.current.refs.nClaseAdm.value = busqueda.numClase
          refInfoCont.current.value = busqueda.contenidoTarjeta
        }, 200);
      } else {
        setclaseWordAdm(null)
      }

    } else if (e.target.id.split('-')[0] == 'temaWEdt') {
      let loca = e.target.id.split('-')[1]
      let val = e.target.value

      if (val.split('-') != undefined) {
        let idTema = val.split('-')[0]

        let busWT = temasWord.find(a => a.idTema == idTema)
        if (busWT == undefined) {
          let temasClaseAdmC = JSON.parse(JSON.stringify(claseWordAdm))

          temasClaseAdmC.temas[loca].value = val

          setclaseWordAdm(temasClaseAdmC)
        } else {
          let temasClaseAdmC = JSON.parse(JSON.stringify(claseWordAdm))

          if (claseWordAdm.temas[loca].idTema != undefined && claseWordAdm.temas[loca].idTema == busWT.idTema) {
            temasClaseAdmC.temas[loca].value = val
            setclaseWordAdm(temasClaseAdmC)
          } else {

            if (claseWordAdm.temas[loca].new != undefined) {

              let temasAF = []
              temasAF.push(busWT)

              let data2 = { idClase: claseWordAdm.idClase, temas: temasAF }
              let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/addTemasClase', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              if (cls2.affectedRows > 0) {
                temasClaseAdmC.temas[loca] = busWT
                temasClaseAdmC.temas[loca].value = val

                setclaseWordAdm(temasClaseAdmC)

                swal('Tema insertado', '', 'success')
              }

            } else {

              let data2 = { idClase: claseWordAdm.idClase, idTemaNew: busWT.idTema, idTema: claseWordAdm.temas[loca].idTema }
              let response2 = await fetch('https://oposiciones-justicia.es/api/word2010/edtTemaClase', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              if (cls2.affectedRows > 0) {
                temasClaseAdmC.temas[loca] = busWT
                temasClaseAdmC.temas[loca].value = val

                setclaseWordAdm(temasClaseAdmC)

                swal('Tema editado', '', 'success')
              }
            }
          }

        }

      } else {
        let temasClaseAdmC = JSON.parse(JSON.stringify(claseWordAdm))

        temasClaseAdmC.temas[loca].value = await val

        setclaseWordAdm(temasClaseAdmC)
      }
    }


  }

  // console.log('EstadoFactura: ', EstadoFactura)
  // console.log('Practicas: ', Practicas)

  console.log('testSemanales: ', testSemanales)
  return (

    <>
      {/* {tipoUser > 50 || EstadoFactura == 'activo' ? ( */}
      {EstadoFactura == 'activo' ? (
        <div className="bodyWHC">

          {
            tipoUser > 60 ? (
              <div className="RowCenterCenter" style={{ marginBottom: '20px' }}>
                <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'CrearClase' ? (
                    <Boton id="CrearClase" icon1="" icon2="" texto="Gestión clases" onClick={() => (setViewSelect('CrearClase'))} />
                  ) : (
                    <Boton2 id="CrearClase" icon1="" icon2="" texto="Gestión clases" onClick={() => (setViewSelect('CrearClase'))} />
                  )}

                </div>
                <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'CrearCategoria' ? (
                    <Boton id="CrearCategoria" icon1="" icon2="" texto="Gestión Categorías" onClick={() => (setViewSelect('CrearCategoria'))} />
                  ) : (
                    <Boton2 id="CrearCategoria" icon1="" icon2="" texto="Gestión Categorías" onClick={() => (setViewSelect('CrearCategoria'))} />
                  )}

                </div>
                <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'CrearTema' ? (
                    <Boton id="CrearTema" icon1="" icon2="" texto="Gestión temas" onClick={() => (setViewSelect('CrearTema'))} />
                  ) : (
                    <Boton2 id="CrearTema" icon1="" icon2="" texto="Gestión temas" onClick={() => (setViewSelect('CrearTema'))} />
                  )}

                </div>
                {/* <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'CrearTutoria' ? (
                    <Boton id="CrearTutoria" icon1="" icon2="" texto="Gestión tutorias" onClick={() => (setViewSelect('CrearTutoria'))} />
                  ) : (
                    <Boton2 id="CrearTutoria" icon1="" icon2="" texto="Gestión tutorias" onClick={() => (setViewSelect('CrearTutoria'))} />
                  )}

                </div> */}

                <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'GesTest' ? (
                    <Boton id="GesTest" icon1="" icon2="" texto="Gestión Test" onClick={() => (setViewSelect('GesTest'))} />
                  ) : (
                    <Boton2 id="GesTest" icon1="" icon2="" texto="Gestión Test" onClick={() => (setViewSelect('GesTest'))} />
                  )}

                </div>
                <div className="RowCenterCenter" style={{ margin: '5px' }}>
                  {viewSelect == 'DudasAdmin' ? (
                    <Boton id="dudasWord2010Admin" icon1="" icon2="" texto="Resolución de Dudas" onClick={() => (setViewSelect('DudasAdmin'))} />
                  ) : (
                    <Boton2 id="dudasWord2010Admin" icon1="" icon2="" texto="Resolución de Dudas" onClick={() => (setViewSelect('DudasAdmin'))} />
                  )}

                </div>
              </div>
            ) : (
              <div className="RowCenterCenter" style={{ marginBottom: '20px' }}>
                <div className="RowCenterCenter" style={{ margin: '5px' }}>

                  <Boton2 id="" icon1="" icon2="" texto="Calcular Puntuación Test" onClick={() => window.open('https://cejusticia.es/calculadora-test/', '_blank')} />

                </div>
              </div>
            )
          }


          <div className="RowCenterCenter" style={{ marginTop: '10px' }}>
            <div className="RowCenterCenter" style={{ margin: '5px' }}>
              {
                viewSelect == 'clases' ? (
                  <Boton id="Clases" icon1="" icon2="" texto="Clases" onClick={handleOnClic} />
                ) : (
                  <Boton2 id="Clases" icon1="" icon2="" texto="Clases" onClick={handleOnClic} />
                )
              }

            </div>
            <div className="RowCenterCenter" style={{ margin: '5px' }}>
              {
                viewSelect == 'temario' ? (
                  <Boton id="Temario" icon1="" icon2="" texto="Temario" onClick={handleOnClic} />
                ) : (
                  <Boton2 id="Temario" icon1="" icon2="" texto="Temario" onClick={handleOnClic} />
                )
              }

            </div>
            <div className="RowCenterCenter" style={{ margin: '5px' }}>
              {
                viewSelect == 'test' ? (
                  <Boton id="Test" icon1="" icon2="" texto="Test Generales" onClick={handleOnClic} />
                ) : (
                  <Boton2 id="Test" icon1="" icon2="" texto="Test Generales" onClick={handleOnClic} />
                )
              }
            </div>
            {/* <div className="RowCenterCenter" style={{ margin: '5px' }}>
              {
                viewSelect == 'claseOnline' ? (
                  <Boton id="ClaseOnline" icon1="" icon2="" texto="Tutorías" onClick={handleOnClic} />
                ) : (
                  <Boton2 id="ClaseOnline" icon1="" icon2="" texto="Tutorías" onClick={handleOnClic} />
                )
              }
            </div> */}
          </div>


          {
            tipoUser > 50 && viewSelect == 'CrearClase' ? (
              <div className='contentOptionWordA' style={{ marginTop: '40px' }}>



                <div className="RowCenterCenter" style={{ marginBottom: '25px' }}>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCClasse' ? (
                        <Boton id="CrearClase" icon1="" icon2="" texto="Crear Clase" onClick={() => (setViewSelect2('CrearCClasse'))} />
                      ) : (
                        <Boton2 id="CrearClase" icon1="" icon2="" texto="Crear Clase" onClick={() => (setViewSelect2('CrearCClasse'))} />
                      )
                    }

                  </div>
                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'editarClase' ? (
                        <Boton id="edtClas" icon1="" icon2="" texto="Editar Clase" onClick={() => (setViewSelect2('editarClase'))} />
                      ) : (
                        <Boton2 id="edtClas" icon1="" icon2="" texto="Editar Clase" onClick={() => (setViewSelect2('editarClase'))} />
                      )
                    }
                  </div>

                </div>

                {
                  viewSelect2 == 'CrearCClasse' ? (
                    <>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNClase} id={'nClaseAdm'} type='text' name={'nClaseAdm'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                        <p>Nº Clase</p>
                      </div>

                      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <p style={{ marginTop: '15px' }}> Info contenido clase </p>
                        <textarea ref={refInfoCont} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                      </div>

                      <div className='RowCenterCenter' style={{ margin: '10px' }}>
                        <Boton id={'addTemaClase'} onClick={handleOnClicAdm} texto={'Añadir Tema'} />
                      </div>

                      <div className='RowCenterCenter' style={{ marginTop: '25px', marginBottom: '25px' }}>
                        {
                          temasClaseAdm && temasClaseAdm.length > 0 ? (
                            temasClaseAdm.map((tem, key1) => (
                              <div className='celdaInputDAPFBN'>
                                <Input2 datalist='temasWordo2010' value={tem.value} autoComplete={'off'} id={'temaW-' + key1} type='text' name={'temaW-' + key1} placeholder='Tema' icon='fa-duotone fa-books' onChange={onChangeInputAdm} />
                                <p>{tem.idTema == undefined ? 'Indicar Tema' : tem.nombre}</p>
                                <i title="Borrar Tema" id={'borrarTema-' + key1} className="inputFlyW fa-duotone fa-trash"
                                  onClick={handleOnClicAdm}
                                ></i>

                              </div>
                            ))
                          ) : (
                            <h3> Añada un tema </h3>
                          )
                        }
                        <datalist id="temasWordo2010">
                          {temasWord && temasWord.length > 0 && temasWord.map((cls, key03) => (
                            <option key={key03} value={cls.idTema + '-' + cls.nombre}>
                            </option>
                          )

                          )}
                        </datalist>
                      </div>
                      {temasClaseAdm && temasClaseAdm.length > 0 && refNClase.current.refs.nClaseAdm.value != '' && refInfoCont.current.value != '' ? (
                        <div className='RowCenterCenter' style={{ margin: '10px' }}>
                          <Boton id={'addClase'} onClick={handleOnClicAdm} texto={'Añadir Clase'} />
                        </div>
                      ) : null}
                    </>
                  ) : null
                }

                {
                  viewSelect2 == 'editarClase' ? (
                    <>

                      <div className='RowCenterCenter'>

                        <div className="celdaInputDAPFBN" style={{ width: 'auto', marginTop: '50px' }}>
                          <Input2 type="text" id="busquedaClse" name="busquedaClse" placeholder="Busqueda de clases" icon="fas fa-search" datalist="busquedaClse22" deletedMode={false} onChange={onChangeInputAdm} />
                          <p>Buscar clase</p>
                        </div>
                        <datalist id="busquedaClse22">
                          {clasesWord && clasesWord.length > 0 && clasesWord.map((cls, key03) => (
                            <option key={key03} value={cls.numClase}>

                              Clase {cls.numClase} : {cls.contenidoTarjeta}

                            </option>
                          )

                          )}
                        </datalist>

                      </div>

                      {claseWordAdm != null ? (
                        <div className='ColCenterCenter' style={{ marginTop: '25px' }}>
                          <div className='celdaInputDAPFBN'>
                            <Input2 autoComplete={'off'} ref={refNClase} id={'nClaseAdm'} type='text' name={'nClaseAdm'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                            <p>Nº Clase</p>
                          </div>

                          <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <p style={{ marginTop: '15px' }}> Info contenido clase </p>
                            <textarea ref={refInfoCont} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                          </div>

                          <div className='RowCenterCenter' style={{ margin: '10px' }}>
                            <Boton id={'edtClaseInfo'} onClick={handleOnClicAdm} texto={'Editar info clase'} />
                          </div>

                          <div className='RowCenterCenter' style={{ margin: '10px', marginTop: '25px' }}>
                            <Boton id={'addTemaClaseEdt'} onClick={handleOnClicAdm} texto={'Añadir Tema'} />
                          </div>

                          <div className='RowCenterCenter' style={{ marginTop: '25px', marginBottom: '25px' }}>
                            {
                              claseWordAdm && claseWordAdm.temas.length > 0 ? (
                                claseWordAdm.temas.map((tem, key1) => (
                                  <div className='celdaInputDAPFBN'>
                                    <Input2 datalist='temasWordo2010' value={tem.value} autoComplete={'off'} id={'temaWEdt-' + key1} type='text' name={'temaWEdt-' + key1} placeholder='Tema' icon='fa-duotone fa-books' onChange={onChangeInputAdm} />
                                    <p>{tem.idTema == undefined ? 'Indicar Tema' : tem.nombre}</p>
                                    <i title="Borrar Tema" id={'borrarTemaEdt-' + key1} className="inputFlyW fa-duotone fa-trash"
                                      onClick={handleOnClicAdm}
                                    ></i>

                                  </div>
                                ))
                              ) : (
                                <h3> Añada un tema </h3>
                              )
                            }
                            <datalist id="temasWordo2010">
                              {temasWord && temasWord.length > 0 && temasWord.map((cls, key03) => (
                                <option key={key03} value={cls.idTema + '-' + cls.nombre}>
                                </option>
                              )

                              )}
                            </datalist>
                          </div>



                        </div>

                      ) : null}
                    </>
                  ) : null
                }


              </div>
            ) : null
          }

          {
            tipoUser > 50 && viewSelect == 'CrearCategoria' ? (
              <div className='contentOptionWordA' style={{ marginTop: '50px' }}>

                <div className="RowCenterCenter" style={{ marginBottom: '25px' }}>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCCat' ? (
                        <Boton id="CrearClase" icon1="" icon2="" texto="Crear Categoria" onClick={() => (setViewSelect2('CrearCCat'))} />
                      ) : (
                        <Boton2 id="CrearClase" icon1="" icon2="" texto="Crear Categoria" onClick={() => (setViewSelect2('CrearCCat'))} />
                      )
                    }

                  </div>
                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'editarCategoria' ? (
                        <Boton id="CrearCategoria" icon1="" icon2="" texto="Editar Categoría" onClick={() => (setViewSelect2('editarCategoria'))} />
                      ) : (
                        <Boton2 id="CrearCategoria" icon1="" icon2="" texto="Editar Categoría" onClick={() => (setViewSelect2('editarCategoria'))} />
                      )
                    }
                  </div>

                </div>
                {viewSelect2 != null ? (
                  viewSelect2 == 'CrearCCat' ? (
                    <>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNameCat} id={'nameCategoria'} type='text' name={'nameCategoria'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Nombre</p>
                      </div>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNCat} id={'nCategoria'} type='text' name={'nCategoria'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Nº Categoría</p>
                      </div>

                      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <p style={{ marginTop: '15px' }}> Info categoria temas </p>
                        <textarea ref={refInfoCont2} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                      </div>

                      <div className='RowCenterCenter' style={{ margin: '10px' }}>
                        <Boton id={'addCategoria'} onClick={handleOnClicAdm} texto={'Añadir Categoría'} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='celdaInputDAPFBN'>
                        <Input2 datalist='categoriasWordo2010' autoComplete={'off'} id={'categoriaW'} type='text' name={'categoriaW'} placeholder='Categoria' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Indicar categoria</p>
                      </div>

                      <datalist id="categoriasWordo2010">
                        {categoriasLoadWord && categoriasLoadWord.length > 0 && categoriasLoadWord.map((cls, key03) => (
                          <option key={key03} value={cls.categoria + '-' + cls.nombre}>
                          </option>
                        )

                        )}
                      </datalist>
                      {
                        categoriaSeleted != null ? (
                          <>

                            <div className='celdaInputDAPFBN'>
                              <Input2 autoComplete={'off'} ref={refNameCat} id={'nameCategoria'} type='text' name={'nameCategoria'} placeholder='Ej: Fuente' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                              <p>Nombre</p>
                            </div>

                            <div className='celdaInputDAPFBN'>
                              <Input2 autoComplete={'off'} ref={refNCat} id={'nCategoria'} type='text' name={'nCategoria'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                              <p>Nº Categoría</p>
                            </div>

                            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <p style={{ marginTop: '15px' }}> Info categoria temas </p>
                              <textarea ref={refInfoCont2} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                            </div>

                            <div className='RowCenterCenter' style={{ margin: '10px' }}>
                              <Boton id={'edtCategoria'} onClick={handleOnClicAdm} texto={'Editar Categoría'} />
                            </div>
                          </>
                        ) : null
                      }
                    </>
                  )
                ) : null}

              </div>
            ) : null
          }

          {
            tipoUser > 50 && viewSelect == 'CrearTema' ? (
              <div className='contentOptionWordA' style={{ marginTop: '50px' }}>

                <div className="RowCenterCenter" style={{ marginBottom: '25px' }}>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTema' ? (
                        <Boton id="CrearTema" icon1="" icon2="" texto="Crear Tema" onClick={() => (setViewSelect2('CrearCTema'))} />
                      ) : (
                        <Boton2 id="CrearTema" icon1="" icon2="" texto="Crear Tema" onClick={() => (setViewSelect2('CrearCTema'))} />
                      )
                    }

                  </div>
                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTemaEdt' ? (
                        <Boton id="EdtTema" icon1="" icon2="" texto="Editar Tema" onClick={() => (setViewSelect2('CrearCTemaEdt'))} />
                      ) : (
                        <Boton2 id="EdtTema" icon1="" icon2="" texto="Editar Tema" onClick={() => (setViewSelect2('CrearCTemaEdt'))} />
                      )
                    }
                  </div>

                </div>
                {viewSelect2 != null ? (
                  viewSelect2 == 'CrearCTema' ? (
                    <>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNameTema} id={'nameTema'} type='text' name={'nameTema'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Nombre</p>
                      </div>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNTema} id={'nTema'} type='text' name={'nTema'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Nº Tema</p>
                      </div>

                      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <p style={{ marginTop: '15px' }}> Info tema </p>
                        <textarea ref={refInfoCont3} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                      </div>

                      <div className='celdaInputDAPFBN'>
                        <Input2 datalist='categoriasWordo2010' autoComplete={'off'} id={'categoriaWC'} type='text' name={'categoriaWC'} placeholder='Categoria' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Indicar categoria</p>
                      </div>
                      {
                        categoriaSeleted != null ? (
                          <p>Categoría: {categoriaSeleted.nombre}</p>
                        ) : (
                          <p>Seleccione una categoría para continuar</p>
                        )

                      }


                      <datalist id="categoriasWordo2010">
                        {categoriasLoadWord && categoriasLoadWord.length > 0 && categoriasLoadWord.map((cls, key03) => (
                          <option key={key03} value={cls.categoria + '-' + cls.nombre}>
                          </option>
                        )

                        )}
                      </datalist>

                      {categoriaSeleted != null ? (<>
                        <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                          <InputFileExamDesa texto="Subir apunte" id="apuntes" name="Apuntes" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                          {fileArchivos != null && fileArchivos.length > 0 && fileArchivos.map((file, key01) => (
                            <p key={key01}>{file.name}</p>
                          ))}

                        </div>

                        <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                          <InputFileExamDesa texto="Subir Ejercicios" id="Ejercicios" name="Ejercicios" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                          {fileEjercicios != null && fileEjercicios.length > 0 && fileEjercicios.map((file, key02) => (
                            <p key={key02}>{file.name}</p>
                          ))}

                        </div>

                        <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                          <InputFileExamDesa texto="Subir Videos" id="Videos" name="Videos" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                          {fileVideos != null && fileVideos.length > 0 && fileVideos.map((file, key03) => (
                            <p key={key03}>{file.name}</p>
                          ))}

                        </div>


                        <div className='RowCenterCenter' style={{ margin: '10px' }}>
                          <Boton id={'addTema'} onClick={handleOnClicAdm} texto={'Añadir Tema'} />
                        </div>
                      </>) : null}
                    </>
                  ) : (
                    <>
                      {/* editar tema */}
                      <div className='celdaInputDAPFBN'>
                        <Input2 datalist='temasWordo2010' autoComplete={'off'} id={'temaWC'} type='text' name={'temaWC'} placeholder='Tema' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Indicar Tema</p>
                      </div>

                      <datalist id="temasWordo2010">
                        {temasWord && temasWord.length > 0 && temasWord.map((cls, key03) => (
                          <option key={key03} value={cls.idTema + '-' + cls.nombre}>
                          </option>
                        ))}
                      </datalist>

                      {
                        temaSelectAdm != null ? (
                          <div className='ColCenterCenter'>
                            <div className='RowCenterCenter'>
                              <div className='celdaInputDAPFBN'>
                                <Input2 autoComplete={'off'} ref={refNameTema} id={'nameTema'} type='text' name={'nameTema'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                                <p>Nombre</p>
                              </div>

                              <div className='celdaInputDAPFBN'>
                                <Input2 autoComplete={'off'} ref={refNTema} id={'nTema'} type='text' name={'nTema'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                                <p>Nº Tema</p>
                              </div>

                              <div className='celdaInputDAPFBN'>
                                <Input2 datalist='categoriasWordo2010' ref={refCateAdm} autoComplete={'off'} id={'categoriaWC'} type='text' name={'categoriaWC'} placeholder='Categoria' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                                {categoriaSeleted != null ? (
                                  <p>{categoriaSeleted.nombre}</p>
                                ) : (
                                  <p>Seleccione Categoria</p>
                                )}
                              </div>
                            </div>


                            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <p style={{ marginTop: '15px' }}> Info tema </p>
                              <textarea ref={refInfoCont3} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                            </div>


                            <datalist id="categoriasWordo2010">
                              {categoriasLoadWord && categoriasLoadWord.length > 0 && categoriasLoadWord.map((cls, key03) => (
                                <option key={key03} value={cls.categoria + '-' + cls.nombre}>
                                </option>
                              )

                              )}
                            </datalist>



                            <div className='RowCenterCenter' style={{ margin: '25px' }}>
                              <Boton id={'edtTemaInfo'} onClick={handleOnClicAdm} texto={'Editar info Tema'} />
                            </div>


                            <div className='RowStartCenter' style={{ marginBottom: '35px' }}>
                              <div className='ColCenterStart'>
                                <div className='apuntesWWHeader'>
                                  <i className="fa-duotone fa-books"></i> Apuntes del tema
                                </div>
                                <div className='bodyAwHword'>
                                  <InputFileExamDesa texto="Subir apunte" id="apuntes" name="Apuntes" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />
                                  {
                                    temaSelectAdm.archivos.length > 0 && temaSelectAdm.archivos.filter(a => a.tipo == 'apunte').length > 0 && temaSelectAdm.archivos.filter(a => a.tipo == 'apunte').map((apunte, key5) => (
                                      <div className='celdaAputneword' onClick={handleOnClicAdm} id={"descargaApunteTAdm-" + key5} key={key5}>
                                        <p id={"descargaApunteTAdm-" + key5}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                        <i id={"descargaApunteTAdm-" + key5} className="fa-duotone fa-file-pdf"></i>
                                        <i id={"deleteApunteTAdm-" + key5} className="fa-duotone fa-trash"></i>
                                      </div>
                                    ))
                                  }


                                </div>
                              </div>

                              <div className='ColCenterStart'>
                                <div className='apuntesWWHeader'>
                                  <i className="fa-duotone fa-laptop-file"></i> Ejercicios
                                </div>
                                <div className='bodyAwHword'>
                                  <InputFileExamDesa texto="Subir Ejercicios" id="Ejercicios" name="Ejercicios" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />
                                  {
                                    temaSelectAdm.archivos.length > 0 && temaSelectAdm.archivos.filter(a => a.tipo == 'ejercicio').length > 0 && temaSelectAdm.archivos.filter(a => a.tipo == 'ejercicio').map((apunte, key6) => (
                                      <div className='celdaAputneword' onClick={handleOnClicAdm} id={"descargaEjercicioTAdm-" + key6} key={key6}>

                                        <p id={"descargaEjercicioTAdm-" + key6}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                        <i id={"descargaEjercicioTAdm-" + key6} className="fa-duotone fa-file-pdf"></i>
                                        <i id={"deleteEjercicioTAdm-" + key6} className="fa-duotone fa-trash"></i>
                                      </div>
                                    ))
                                  }

                                </div>
                              </div>

                            </div>

                            {
                              temaSelectAdm.videos.length > 0 ? (
                                <div className='contentVideosWord'>

                                  <div className='videosWordTema'>
                                    <ReactPlayer
                                      url={temaSelectAdm.videos[videoView].url}
                                      className='react-player'
                                      playing={false}
                                      width='100%'
                                      height='100%'
                                      controls={true}
                                      onContextMenu={e => e.preventDefault()}
                                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    />
                                  </div>

                                  <div className="RowCenterCenter">
                                    {
                                      temaSelectAdm.videos.length > 0 ? (
                                        temaSelectAdm.videos.map((video, key4) => (
                                          <div key={key4} id={"viewVideoAdm-" + key4} title={video.nombre} className={videoView == key4 ? ("cirSelectedV circleNavW") : ("circleNavW")} onClick={handleOnClicAdm}>
                                            {key4 + 1}
                                          </div>
                                        ))
                                      ) : null
                                    }

                                  </div>
                                  <div className='ColCenterCenter' style={{ marginTop: '15px' }}>
                                    <InputFileExamDesa texto="Subir Videos" id="Videos" name="Videos" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />

                                    <p style={{ marginTop: '10px' }}> Para editar el orden de los videos edite Nº video </p>

                                    <div className='celdaInputDAPFBN'>
                                      <Input2 autoComplete={'off'} ref={refNVideo} id={'nVideo'} type='text' name={'nVideo'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                                      <p>Nº Video</p>
                                    </div>

                                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                      <Boton id={'edtTemaVideo'} onClick={handleOnClicAdm} texto={'Editar Nº Video'} />
                                    </div>

                                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                      <Boton id={'deleteTemaVideo'} onClick={handleOnClicAdm} texto={'Borrar Video'} />
                                    </div>
                                  </div>


                                </div>
                              ) : (
                                <InputFileExamDesa texto="Subir Videos" id="Videos" name="Videos" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />
                              )}



                          </div>
                        ) : null
                      }


                    </>
                  )
                ) : null}

              </div>
            ) : null
          }

          {
            tipoUser > 50 && viewSelect == 'CrearTutoria' ? (
              <div className='contentOptionWordA'>
                <div className="RowCenterCenter" style={{ marginTop: '50px', marginBottom: '25px' }}>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTutoria' ? (
                        <Boton id="CrearTutoria" icon1="" icon2="" texto="Crear Tutoria" onClick={() => (setViewSelect2('CrearCTutoria'))} />
                      ) : (
                        <Boton2 id="CrearTutoria" icon1="" icon2="" texto="Crear Tutoria" onClick={() => (setViewSelect2('CrearCTutoria'))} />
                      )
                    }

                  </div>
                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTutoriaEdt' ? (
                        <Boton id="EdtTutoria" icon1="" icon2="" texto="Editar Tutoria" onClick={() => (setViewSelect2('CrearCTutoriaEdt'))} />
                      ) : (
                        <Boton2 id="EdtTutoria" icon1="" icon2="" texto="Editar Tutoria" onClick={() => (setViewSelect2('CrearCTutoriaEdt'))} />
                      )
                    }
                  </div>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTutoriaPracticaEdt' ? (
                        <Boton id="EdtPractica" icon1="" icon2="" texto="Editar Práctica" onClick={() => (setViewSelect2('CrearCTutoriaPracticaEdt'))} />
                      ) : (
                        <Boton2 id="EdtPractica" icon1="" icon2="" texto="Editar Práctica" onClick={() => (setViewSelect2('CrearCTutoriaPracticaEdt'))} />
                      )
                    }
                  </div>

                </div>

                {viewSelect2 == 'CrearCTutoria' ? (
                  <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                    <div className='celdaInputDAPFBN' style={{ width: '215px' }}>
                      <Input2 autoComplete={'off'} ref={refFechaTuto} id={'fechaTutoria'} type='datetime-local' name={'fechaTutoria'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                      <p>Fecha tutoría</p>
                    </div>

                    <div className='celdaInputDAPFBN' style={{ width: '215px' }}>
                      <Input2 autoComplete={'off'} ref={refUrlTuto} id={'urlTutoria'} type='text' name={'urlTutoria'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                      <p>Link Zoom</p>
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <p style={{ marginTop: '15px' }}> Info contenido tutoria </p>
                      <textarea ref={refInfoContTuto} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <p style={{ marginTop: '15px' }}> Indicaciones tutoria </p>
                      <textarea ref={refInfoIndiTuto} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                    </div>

                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                      <Boton id={'addTutoria'} onClick={handleOnClicAdm} texto={'Añadir Tutoria'} />
                    </div>


                  </div>
                ) : null}

                {viewSelect2 == 'CrearCTutoriaEdt' ? (
                  <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>


                    <div className='celdaInputDAPFBN'>
                      <Input2 datalist='tutoriasDataL' autoComplete={'off'} id={'tutoAdmS'} type='text' name={'tutoAdmS'} placeholder='Tutoría' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                      <p>Indicar Tutoría</p>
                    </div>

                    <datalist id="tutoriasDataL">
                      {tutorias && tutorias.length > 0 && tutorias.map((cls, key03) => (
                        <option key={key03} value={cls.idTutoria + '-' + moment(cls.fecha).format('DD-MM-YY HH:mm')}>
                        </option>
                      ))}
                    </datalist>

                    {tutoriaSelectAdm != null ? (
                      <>
                        <div className='celdaInputDAPFBN' style={{ width: '215px' }}>
                          <Input2 autoComplete={'off'} ref={refFechaTuto} id={'fechaTutoria'} type='datetime-local' name={'fechaTutoria'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                          <p>Fecha tutoría</p>
                        </div>

                        <div className='celdaInputDAPFBN' style={{ width: '215px' }}>
                          <Input2 autoComplete={'off'} ref={refUrlTuto} id={'urlTutoria'} type='text' name={'urlTutoria'} placeholder='Ej: 1' icon='fa-duotone fa-calendar-day' onChange={onChangeInputAdm} />
                          <p>Link Zoom</p>
                        </div>

                        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <p style={{ marginTop: '15px' }}> Info contenido tutoria </p>
                          <textarea ref={refInfoContTuto} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                        </div>

                        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <p style={{ marginTop: '15px' }}> Indicaciones tutoria </p>
                          <textarea ref={refInfoIndiTuto} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                        </div>

                        <div className='RowCenterCenter' style={{ margin: '10px' }}>
                          <Boton id={'edtTutoria'} onClick={handleOnClicAdm} texto={'Editar Tutoria'} />
                        </div>
                      </>
                    ) : null}

                  </div>
                ) : null}

                {viewSelect2 == 'CrearCTutoriaPracticaEdt' ? (
                  <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>


                    <div className='celdaInputDAPFBN'>
                      <Input2 datalist='tutoriasDataL2' autoComplete={'off'} id={'tutoAdmSP'} type='text' name={'tutoAdmSP'} placeholder='Tutoría' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                      <p>Indicar Práctica</p>
                    </div>

                    <datalist id="tutoriasDataL2">
                      {Practicas && Practicas.length > 0 && Practicas.map((cls, key03) => (
                        <option key={key03} value={cls.idPractica}>
                        </option>
                      ))}
                    </datalist>

                    {practicaSelectAdm != null ? (
                      <>

                        <div className='celdaInputDAPFBN' style={{ width: '215px' }}>
                          <Input2 autoComplete={'off'} ref={refUrlTuto} id={'numPractica'} type='text' name={'numPractica'} placeholder='Ej: 1' icon='fa-light fa-circle-info' onChange={onChangeInputAdm} />
                          <p>Num Práctica</p>
                        </div>

                        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <p style={{ marginTop: '15px' }}> Info contenido </p>
                          <textarea ref={refInfoContTuto} style={{ marginTop: '5px', width: '100%' }} name="" id="" cols="75" rows="2" className="textarea2"></textarea>
                        </div>



                        <div className='RowCenterCenter' style={{ margin: '10px' }}>
                          <Boton id={'edtPracticaTuto'} onClick={handleOnClicAdm} texto={'Editar Info Práctica'} />
                        </div>

                        <div className='RowStartCenter' style={{ marginBottom: '35px' }}>

                          <div className='ColCenterStart'>
                            <div className='apuntesWWHeader'>
                              <i className="fa-duotone fa-laptop-file"></i> Ejercicios
                            </div>
                            <div className='bodyAwHword'>
                              <InputFileExamDesa texto="Subir Ejercicios" id="EjerciciosPracticas" name="EjerciciosPracticas" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />
                              {
                                practicaSelectAdm.archivos.length > 0 && practicaSelectAdm.archivos.map((apunte, key6) => (
                                  <div className='celdaAputneword' onClick={handleOnClicAdm} id={"descargaEjercicioTAdmPract-" + key6} key={key6}>

                                    <p id={"descargaEjercicioTAdmPract-" + key6}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                    <i id={"descargaEjercicioTAdmPract-" + key6} className="fa-duotone fa-file-pdf"></i>
                                    <i id={"deleteEjercicioTAdmPract-" + key6} className="fa-duotone fa-trash"></i>
                                  </div>
                                ))
                              }

                            </div>
                          </div>

                        </div>
                      </>
                    ) : null}

                  </div>
                ) : null}


              </div>
            ) : null
          }

          {
            tipoUser > 50 && viewSelect == 'GesTest' ? (
              <div className='contentOptionWordA' style={{ marginTop: '50px' }}>

                <div className="RowCenterCenter" style={{ marginBottom: '25px' }}>

                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTest' ? (
                        <Boton id="CrearTest" icon1="" icon2="" texto="Crear Test" onClick={() => (setViewSelect2('CrearCTest'))} />
                      ) : (
                        <Boton2 id="CrearTest" icon1="" icon2="" texto="Crear Test" onClick={() => (setViewSelect2('CrearCTest'))} />
                      )
                    }

                  </div>
                  <div className="RowCenterCenter" style={{ margin: '5px' }}>
                    {
                      viewSelect2 == 'CrearCTestEdt' ? (
                        <Boton id="EdtTest" icon1="" icon2="" texto="Editar Test" onClick={() => (setViewSelect2('CrearCTestEdt'))} />
                      ) : (
                        <Boton2 id="EdtTest" icon1="" icon2="" texto="Editar Test" onClick={() => (setViewSelect2('CrearCTestEdt'))} />
                      )
                    }
                  </div>

                </div>
                {viewSelect2 != null ? (
                  viewSelect2 == 'CrearCTest' ? (
                    <>
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        Ultimo test subido {testSemanales && testSemanales.length > 0 ? (testSemanales[testSemanales.length - 1].numero_test) : null}
                      </div>
                      <div className='celdaInputDAPFBN'>
                        <Input2 autoComplete={'off'} ref={refNameTema} id={'numTest'} type='text' name={'numTest'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Nº Test</p>
                      </div>

                      <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                        <InputFileExamDesa texto="Subir Test" id="Test" name="Test" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                        {fileTest != null && fileTest.length > 0 && fileTest.map((file, key01) => (
                          <p key={key01}>{file.name}</p>
                        ))}

                      </div>

                      <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                        <InputFileExamDesa texto="Subir respuestas" id="Solucion" name="Solucion" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                        {fileTestSolucion != null && fileTestSolucion.length > 0 && fileTestSolucion.map((file, key02) => (
                          <p key={key02}>{file.name}</p>
                        ))}

                      </div>


                      <div className='RowCenterCenter' style={{ margin: '10px' }}>
                        <Boton id={'addTest'} onClick={handleOnClicAdm} texto={'Añadir Test'} />
                      </div>

                    </>


                  ) : (
                    <>
                      {/* editar tema */}
                      <div className='celdaInputDAPFBN'>
                        <Input2 datalist='testWd2' autoComplete={'off'} id={'testWC'} type='text' name={'testWC'} placeholder='Test' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                        <p>Indicar Test</p>
                      </div>

                      <datalist id="testWd2">
                        {testSemanales && testSemanales.length > 0 && testSemanales.map((cls, key03) => (
                          <option key={key03} value={cls.numero_test}>
                          </option>
                        ))}
                      </datalist>

                      {
                        testSelectAdm != null ? (
                          <>
                            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                              Ultimo test subido {testSemanales && testSemanales.length > 0 ? (testSemanales[testSemanales.length - 1].numero_test) : null}
                            </div>
                            <div className='celdaInputDAPFBN'>
                              <Input2 autoComplete={'off'} ref={refNameTema} id={'numTest'} type='text' name={'numTest'} placeholder='Ej: 1' icon='fa-duotone fa-circle-info' onChange={onChangeInputAdm} />
                              <p>Nº Test</p>
                            </div>

                            <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                              <InputFileExamDesa texto="Subir Test" id="Test" name="Test" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                              {fileTest != null && fileTest.length > 0 && fileTest.map((file, key01) => (
                                <p key={key01}>{file.name}</p>
                              ))}

                            </div>

                            <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>

                              <InputFileExamDesa texto="Subir respuestas" id="Solucion" name="Solucion" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                              {fileTestSolucion != null && fileTestSolucion.length > 0 && fileTestSolucion.map((file, key02) => (
                                <p key={key02}>{file.name}</p>
                              ))}

                            </div>


                            <div className='RowCenterCenter' style={{ margin: '10px' }}>
                              <Boton id={'edtTest'} onClick={handleOnClicAdm} texto={'Editar Test'} />
                            </div>

                            <div className='RowCenterCenter' style={{ margin: '10px' }}>
                              <Boton id={'delTest'} onClick={handleOnClicAdm} texto={'Borrar Test'} />
                            </div>

                          </>
                        ) : null
                      }


                    </>
                  )
                ) : null}

              </div>
            ) : null
          }



          {
            tipoUser > 50 && viewSelect == 'DudasAdmin' ? (
              <div className='contentOptionWordA'>
                {
                  dudasAdmPendientes && dudasAdmPendientes.length > 0 ? (
                    <div className='ColCenterCenter' style={{ width: '100%' }}>
                      <div className='apuntesWWHeader' style={{ marginTop: '25px', minWidth: '200px' }}>
                        <i className="fa-duotone fa-comments-question"></i> Dudas pendientes
                      </div>
                      {
                        dudasAdmPendientes && dudasAdmPendientes.length > 0 && dudasAdmPendientes.map((duda, key01) => (

                          <div className='bWordDudasCont'>


                            <div className='dudaBodyRowWord' key={key01} onClick={() => setViewDudaTema(key01)}>
                              <p onClick={() => setViewDudaTema(key01)}>
                                {ReactHtmlParser(duda.duda)}
                              </p>

                              <p style={{ position: 'absolute', top: '-25px', color: '#f53737' }}>
                                {'Nº ' + duda.infoTema.numeroTema + ' / ' + duda.infoTema.nombre + ' - ID: ' + duda.infoTema.idTema}
                              </p>

                              {
                                viewDudaTema != null && viewDudaTema == key01 ? (

                                  <div className='tutoriaLoad-sugerir'>

                                    <div className='zonaEditorText'>
                                      <div className="editorPlantearrDuda">
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={textHtmlDudaResolucion}
                                          onInit={editor => { }}
                                          config={{
                                            toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                                          }}

                                          onChange={onChangeTextResolucion}
                                        />
                                      </div>

                                      <div className="editorPlantearrDudaBotones" style={{ justifyContent: 'center', marginTop: '5px' }}>

                                        <div className="epdb-boton">
                                          <Boton id="resolverDuda" icon1="" icon2="" texto="Resolver Duda" onClick={handleOnClicAdm} />
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                ) : null
                              }




                            </div>




                          </div>
                        ))
                      }
                    </div>
                  ) : (
                    <h3>  No hay dudas pendientes </h3>
                  )
                }
              </div>
            ) : null
          }


          {
            viewSelect == 'clases' ? (
              claseWordSelect == null ? (

                clasesWord && clasesWord.length == 0 ? (
                  <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center', padding: '10px', marginTop: '70px' }}>
                    <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO WORD 2010 </div>
                    <Ellipsis color="#3469b7" size={72} />
                  </div>
                ) : (
                  <div className='contentOptionWord'>
                    {/* <div className='RowCenterCenter'>

                      <div className="celdaInputDAPFBN" style={{ width: 'auto', marginTop: '50px' }}>
                        <Input2 type="text" id="busquedaClse" name="busquedaClse" placeholder="Busqueda de clases" icon="fas fa-search" datalist="busquedaClse22" deletedMode={false} onChange={onChangeInput} />
                        <p>Buscar clase</p>
                      </div>
                      <datalist id="busquedaClse22">
                        {clasesWord && clasesWord.length > 0 && clasesWord.map((cls, key03) => (
                          <option key={key03} value={cls.numClase}>

                            Clase {cls.numClase} : {cls.contenidoTarjeta}

                          </option>
                        )

                        )}
                      </datalist>

                    </div> */}

                    <div className='RowCenterCenter' style={{marginTop: '50px'}}>
                      Estudio semanal
                    </div>
                    <div className='RowCenterCenter' style={{marginTop: '10px', color: '#3469b7'}}>
                      <b>{EstadoAlumno.indicacionEstudio}</b>
                    </div>

                    <div className="WHC-contentManual" style={{ marginTop: '20px' }}>
                      {inicioNumeracion && inicioNumeracion > 0 ? (
                        <i className="fas fa-angle-left flyPajarito2" id="anterior" onClick={handleOnClic}></i>
                      ) : null}
                      {
                        clasesWord && clasesWord.length > 0 ? (
                          clasesWord.slice(inicioNumeracion, finNumeracion).map((clase, key0) =>
                            <div className="bordeCajaSeWHaTFNO" key={key0}
                              style={clase.desbloqueada == 1 ? ({}) : ({ filter: 'blur(2px)' })}
                            >
                              {
                                clase.desbloqueada == 1 ? (
                                  <CajaSemanaWord semanaWord2010={clase} keyProp={key0} handleOnClicWord={() => setClaseWordSelect(clase)} />
                                ) : (
                                  <CajaSemanaWord semanaWord2010={clase} keyProp={key0} handleOnClicWord={() => swal('Clase no disponible', 'Se desbloquean 4 clases por mes', 'info')} />
                                )
                              }

                            </div>
                          )
                        ) : null

                      }
                      {finNumeracion && finNumeracion != clasesWord.length && clasesWord.length > 6 ? (
                        <i className="fas fa-angle-right flyPajarito" id="siguiente" onClick={handleOnClic}></i>
                      ) : null}

                    </div>

                    {/* <div className="WHC-hi-descargasdOWng">


                      <div className="WHC-hi-DOwng-DESCARGA">
                        <div className='iMW-boton2' id="descargarManual" onClick={handleOnClic} style={{ borderRight: '1px solid #bcbcbc' }}>
                          <i className="far fa-file-pdf" />Manual
                        </div>
                        <div className='iMW-boton2' id="descargarAtajos" onClick={handleOnClic}>
                          <i className="far fa-file-pdf" />Atajos
                        </div>
                      </div>
                      <div className="WHC-hi-DOwng-infD" style={{ fontStyle: 'italic' }}>
                        Descargables
                      </div>

                    </div> */}


                  </div>
                )

              ) : (
                <div className='contentClaseOpenWord'>

                  {/* <div className="vvatrjkvx" style={{ marginBottom: '20px', alignSelf: 'flex-start' }} onClick={() => setClaseWordSelect(null)}> Cerrar clase </div> */}
                  <div style={{ marginTop: '20px', height: 'auto !important', marginBottom: '20px', alignSelf: 'flex-start' }}>
                    <Boton id="Clases" icon1="" icon2="" texto="Cerrar clase" onClick={() => { setClaseWordSelect(null); setTemaClaseSelect(null) }} />
                  </div>


                  <h3 style={{ fontStyle: 'italic', fontSize: '20px' }}>
                    {claseWordSelect.contenidoTarjeta}
                  </h3>
                  <div className='navTutoriasDisponibles'>
                    {claseWordSelect.temas.length > 0 ? (
                      claseWordSelect.temas.map((temaClase, key1) => (

                        <div className='ntD-item' key={key1} onClick={() => setTemaClaseSelect(temaClase)} style={temaClaseSelect != null && temaClaseSelect == temaClase ? ({ color: '#3469b7', fontWeight: '500' }) : {}}>
                          {temaClase.nombre}
                        </div>
                      ))
                    ) : (
                      'No hay ningun tema disponible en esta clase'
                    )}
                  </div>
                  {
                    temaClaseSelect != null ? (
                      <div className='ColCenterStart'>

                        <p style={{ margin: '20px' }}>{temaClaseSelect.infoTema}</p>
                        {
                          temaClaseSelect.videos.length > 0 ? (
                            <div className='contentVideosWord'>

                              <div className='videosWordTema'>
                                <ReactPlayer
                                  url={temaClaseSelect.videos[videoView].url}
                                  className='react-player'
                                  playing={false}
                                  width='100%'
                                  height='100%'
                                  controls={true}
                                  onContextMenu={e => e.preventDefault()}
                                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                              </div>

                              <div className="RowCenterCenter">
                                {
                                  temaClaseSelect.videos.length > 0 ? (
                                    temaClaseSelect.videos.map((video, key4) => (
                                      <div key={key4} title={video.nombre} className={videoView == key4 ? ("cirSelectedV circleNavW") : ("circleNavW")} onClick={() => setVideoView(key4)}>
                                        {key4 + 1}
                                      </div>
                                    ))
                                  ) : null
                                }

                              </div>
                            </div>
                          ) : null}
                        <div className='RowStartCenter' style={{ marginBottom: '35px' }}>
                          <div className='ColCenterStart'>
                            <div className='apuntesWWHeader'>
                              <i className="fa-duotone fa-books"></i> Apuntes del tema
                            </div>
                            <div className='bodyAwHword'>
                              {
                                temaClaseSelect.archivos.length > 0 && temaClaseSelect.archivos.filter(a => a.tipo == 'apunte').length > 0 && temaClaseSelect.archivos.filter(a => a.tipo == 'apunte').map((apunte, key5) => (
                                  <div className='celdaAputneword' id={"descargaApunteC-" + key5} onClick={handleOnClic} key={key5}>
                                    <p id={"descargaApunteC-" + key5}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                    <i className="fa-duotone fa-file-pdf" id={"descargaApunteC-" + key5}></i>
                                  </div>
                                ))
                              }


                            </div>
                          </div>
                          {
                            temaClaseSelect.archivos.length > 0 && temaClaseSelect.archivos.filter(a => a.tipo == 'ejercicio').length > 0 ? (
                              <div className='ColCenterStart'>
                                <div className='apuntesWWHeader'>
                                  <i className="fa-duotone fa-laptop-file"></i> Ejercicios
                                </div>
                                <div className='bodyAwHword'>

                                  {
                                    temaClaseSelect.archivos.length > 0 && temaClaseSelect.archivos.filter(a => a.tipo == 'ejercicio').length > 0 && temaClaseSelect.archivos.filter(a => a.tipo == 'ejercicio').map((apunte, key6) => (
                                      <div className='celdaAputneword' id={"descargaEjercicioC-" + key6} onClick={handleOnClic} key={key6}>
                                        <p id={"descargaEjercicioC-" + key6}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                        <i className="fa-duotone fa-file-pdf" id={"descargaEjercicioC-" + key6}></i>
                                      </div>
                                    ))
                                  }

                                </div>
                              </div>
                            ) : null

                          }




                        </div>

                        <div className='ColCenterStart' style={{ width: '100%', marginBottom: '40px' }}>

                          <div className='tutoriaLoad-sugerir' style={{ marginTop: '0px' }}>

                            <div className='zonaEditorText'>
                              <div className="editorPlantearrDuda">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={textHtml3DudaArtAlumno}
                                  onInit={editor => { }}
                                  config={{
                                    toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                                    // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                                  }}

                                  onChange={onChangeText3}
                                />
                              </div>

                              <div className="editorPlantearrDudaBotones" style={{ justifyContent: 'center', marginTop: '5px' }}>

                                <div className="epdb-boton">
                                  <Boton id="enviarDudaTemarioWClase" icon1="" icon2="" texto="Plantear duda" onClick={handleOnClic} />
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className='apuntesWWHeader' style={{ marginTop: '25px', minWidth: '200px' }}>
                            <i className="fa-duotone fa-comments-question"></i> Dudas del Tema

                          </div>



                          <div className='bWordDudasCont'>
                            {temaClaseSelect.dudas && temaClaseSelect.dudas.length > 0 ? (
                              temaClaseSelect.dudas.map((duda, key7) => (
                                <div className='dudaBodyRowWord' key={key7} onClick={() => setViewDudaTema(key7)}>
                                  <p onClick={() => setViewDudaTema(key7)}>
                                    {ReactHtmlParser(duda.duda)}
                                  </p>
                                  {
                                    viewDudaTema != null && viewDudaTema == key7 ? (
                                      <p style={{ marginTop: '10px', paddingTop: '10px', borderTop: '1px solid #cbcbcb' }}>
                                        {ReactHtmlParser(duda.resolucion)}
                                      </p>
                                    ) : null
                                  }
                                  {duda.resolucion != null && duda.resolucion != '' ? (
                                    <p style={{ position: 'absolute', top: '-25px', color: '#20c14a' }}>
                                      Resuelta
                                    </p>
                                  ) : (
                                    <p style={{ position: 'absolute', top: '-25px', color: '#f53737' }}>
                                      Pendiente resolución
                                    </p>
                                  )}


                                </div>
                              ))
                            ) : (
                              <div style={{ alignSelf: 'center' }}>No existen dudas en este tema</div>
                            )}


                          </div>
                        </div>


                      </div>
                    ) : (
                      <h3 style={{ fontSize: '17px', margin: '20px' }}> Seleccione un tema para cargarlo </h3>
                    )
                  }


                </div>
              )


            ) : null
          }


          {
            viewSelect == 'test' ? (

              testSemanales.length > 0 ? (
                <div className='contentOptionWord'>

                  <div className='RowCenterCenter' style={{ width: 'auto', marginTop: '50px' }}>

                    <Boton2 id="" icon1="" icon2="" texto="Calcular Puntuación Test" onClick={() => window.open('https://cejusticia.es/calculadora-test/', '_blank')} />

                  </div>

                  <div className="WHC-contentManual" style={{ marginTop: '20px' }}>
                    {inicioNumeracion && inicioNumeracion > 0 ? (
                      <i className="fas fa-angle-left flyPajarito2" id="anterior" onClick={handleOnClic}></i>
                    ) : null}
                    {
                      testSemanales && testSemanales.length > 0 ? (
                        testSemanales.slice(inicioNumeracion, finNumeracion).map((test, key02x) =>
                          <div className="bordeCajaSeWHaTFNO" key={key02x}>

                            <CajaSemanaWordTest testWord={test} keyProp={key02x} handleOnClicWord={null} />

                          </div>
                        )
                      ) : null

                    }
                    {finNumeracion && finNumeracion != testSemanales.length && testSemanales.length > 6 ? (
                      <i className="fas fa-angle-right flyPajarito" id="siguiente" onClick={handleOnClic}></i>
                    ) : null}

                  </div>

                  {/* <div className="WHC-hi-descargasdOWng">


                    <div className="WHC-hi-DOwng-DESCARGA">
                      <div className='iMW-boton2' id="descargarManual" onClick={handleOnClic} style={{ borderRight: '1px solid #bcbcbc' }}>
                        <i className="far fa-file-pdf" />Manual
                      </div>
                      <div className='iMW-boton2' id="descargarAtajos" onClick={handleOnClic}>
                        <i className="far fa-file-pdf" />Atajos
                      </div>
                    </div>
                    <div className="WHC-hi-DOwng-infD" style={{ fontStyle: 'italic' }}>
                      Descargables
                    </div>

                  </div> */}


                </div>
              ) : (
                <div className='contentOptionWord'>
                  <p style={{ marginTop: '25px', marginBottom: '25px', fontSize: '18px' }}> Los test generales, empezerán ha desbloquearse a partir del 4º mes. Cada mes se desbloquearán 6 test.</p>
                </div>
              )

            ) : null
          }


          {
            viewSelect == 'temario' ? (
              <>

                <div className='contentTemario' style={{ marginTop: '20px' }}>

                  {temaWordSelect == null ? (
                    <>
                      <div id="wrapper">

                        <div className="scrollbar" id="style-2">

                          {categoriaTemaSelect == null && temaWordSelect == null ? (
                            <div className="force-overflow">
                              <div className="vvatrjkvx" style={{ cursor: 'unset' }}> Categorías </div>
                              {categoriasTemas && categoriasTemas.map((categoria, key) =>


                                <div className="contentCategoria" data-animation="bonus" key={key}>


                                  <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={() => setcategoriaTemaSelect(categoria.categoria)}>
                                    {<b style={{ marginRight: '5px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>}
                                    {'- ' + categoria.temasInfo}

                                  </div>



                                </div>

                              )}

                            </div>) : null}



                          {categoriaTemaSelect != null && temaWordSelect == null ? (
                            <div className="force-overflow" style={{ justifyContent: 'flex-start' }}>
                              {/* <div className="vvatrjkvx" onClick={() => setcategoriaTemaSelect(null)}> Volver atrás </div> */}
                              <div style={{ marginTop: '20px', height: 'auto !important', marginBottom: '20px', alignSelf: 'flex-start' }}>
                                <Boton id="Clases" icon1="" icon2="" texto="Volver atrás" onClick={() => setcategoriaTemaSelect(null)} />
                              </div>


                              {categoriasTemas &&
                                categoriasTemas.find(a => a.categoria == categoriaTemaSelect) != undefined &&
                                categoriasTemas.find(a => a.categoria == categoriaTemaSelect).temas != undefined &&
                                categoriasTemas.find(a => a.categoria == categoriaTemaSelect).temas.length > 0 &&
                                categoriasTemas.find(a => a.categoria == categoriaTemaSelect).temas.sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema)).map((temas, key3) =>

                                  // temas: [{nombre: 'Tema 1',idTema: 2, infoTema: 'El tema supremos'}]
                                  <div className="contentCategoria" data-animation="bonus" key={key3}>
                                    <div className="contentCategoriaTemas" style={{ justifyContent: 'flex-start' }} id={'tema-' + key3} onClick={() => { temas.desbloqueada == 1 ? settemaWordSelect(temas) : (swal('Tema bloqueado', 'Se debloquean 4 temas al mes', 'info')) }}>

                                      <b style={{ fontWeight: '600', marginRight: '8px' }} id={'tema-' + key3}>
                                        {temas.nombre}
                                      </b>

                                      {temas.infoTema != null && temas.infoTema != '' ? (
                                        temas.infoTema
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                            </div>
                          ) : null}


                        </div>
                      </div>

                      <div className='imgWordMamita'>

                      </div>
                    </>
                  ) : (
                    <div>
                      {/* <div className="vvatrjkvx" onClick={() => settemaWordSelect(null)}> Volver atrás </div> */}

                      <div style={{ marginTop: '20px', height: 'auto !important', marginBottom: '20px', alignSelf: 'flex-start', maxWidth: '175px' }}>
                        <Boton id="Clases" icon1="" icon2="" texto="Volver atrás" onClick={() => settemaWordSelect(null)} />
                      </div>


                      <div className='ColCenterStart'>
                        <h3 style={{ margin: '15px', marginBottom: '5px' }}> {temaWordSelect.nombre} </h3>
                        <p style={{ marginBottom: '20px' }}>{temaWordSelect.infoTema}</p>
                        {
                          temaWordSelect.videos.length > 0 ? (
                            <div className='contentVideosWord'>

                              <div className='videosWordTema'>
                                <ReactPlayer
                                  url={temaWordSelect.videos[videoView].url}
                                  className='react-player'
                                  playing={false}
                                  width='100%'
                                  height='100%'
                                  controls={true}
                                  onContextMenu={e => e.preventDefault()}
                                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                />
                              </div>

                              <div className="RowCenterCenter">
                                {
                                  temaWordSelect.videos.length > 0 ? (
                                    temaWordSelect.videos.map((video, key4) => (
                                      <div key={key4} title={video.nombre} className={videoView == key4 ? ("cirSelectedV circleNavW") : ("circleNavW")} onClick={() => setVideoView(key4)}>
                                        {key4 + 1}
                                      </div>
                                    ))
                                  ) : null
                                }

                              </div>
                            </div>
                          ) : null}
                        <div className='RowStartCenter' style={{ marginBottom: '35px' }}>
                          <div className='ColCenterStart'>
                            <div className='apuntesWWHeader'>
                              <i className="fa-duotone fa-books"></i> Apuntes del tema
                            </div>
                            <div className='bodyAwHword'>
                              {
                                temaWordSelect.archivos.length > 0 && temaWordSelect.archivos.filter(a => a.tipo == 'apunte').length > 0 && temaWordSelect.archivos.filter(a => a.tipo == 'apunte').map((apunte, key5) => (
                                  <div className='celdaAputneword' onClick={handleOnClic} id={"descargaApunteT-" + key5} key={key5}>
                                    <p id={"descargaApunteT-" + key5}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                    <i id={"descargaApunteT-" + key5} className="fa-duotone fa-file-pdf"></i>
                                  </div>
                                ))
                              }


                            </div>
                          </div>
                          {
                            temaWordSelect.archivos.length > 0 && temaWordSelect.archivos.filter(a => a.tipo == 'ejercicio').length > 0 ? (
                              <div className='ColCenterStart'>
                                <div className='apuntesWWHeader'>
                                  <i className="fa-duotone fa-laptop-file"></i> Ejercicios
                                </div>
                                <div className='bodyAwHword'>

                                  {
                                    temaWordSelect.archivos.length > 0 && temaWordSelect.archivos.filter(a => a.tipo == 'ejercicio').length > 0 && temaWordSelect.archivos.filter(a => a.tipo == 'ejercicio').map((apunte, key6) => (
                                      <div className='celdaAputneword' onClick={handleOnClic} id={"descargaEjercicioT-" + key6} key={key6}>
                                        <p id={"descargaEjercicioT-" + key6}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                        <i id={"descargaEjercicioT-" + key6} className="fa-duotone fa-file-pdf"></i>
                                      </div>
                                    ))
                                  }

                                </div>
                              </div>
                            ) : null
                          }


                        </div>

                        <div className='ColCenterStart' style={{ width: '100%', marginBottom: '40px' }}>

                          <div className='tutoriaLoad-sugerir' style={{ marginTop: '0px' }}>

                            <div className='zonaEditorText'>
                              <div className="editorPlantearrDuda">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={textHtml2DudaArtAlumno}
                                  onInit={editor => { }}
                                  config={{
                                    toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                                    // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                                  }}

                                  onChange={onChangeText2}
                                />
                              </div>

                              <div className="editorPlantearrDudaBotones" style={{ justifyContent: 'center', marginTop: '5px' }}>

                                <div className="epdb-boton">
                                  <Boton id="enviarDudaTemario" icon1="" icon2="" texto="Plantear duda" onClick={handleOnClic} />
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className='apuntesWWHeader' style={{ marginTop: '25px', minWidth: '200px' }}>
                            <i className="fa-duotone fa-comments-question"></i> Dudas del Tema

                          </div>



                          <div className='bWordDudasCont'>
                            {temaWordSelect.dudas && temaWordSelect.dudas.length > 0 ? (
                              temaWordSelect.dudas.map((duda, key7) => (
                                <div className='dudaBodyRowWord' key={key7} onClick={() => setViewDudaTema(key7)}>
                                  <p onClick={() => setViewDudaTema(key7)}>
                                    {ReactHtmlParser(duda.duda)}
                                  </p>
                                  {
                                    viewDudaTema != null && viewDudaTema == key7 ? (
                                      <p style={{ marginTop: '10px', paddingTop: '10px', borderTop: '1px solid #cbcbcb' }}>
                                        {ReactHtmlParser(duda.resolucion)}
                                      </p>
                                    ) : null
                                  }
                                  {duda.resolucion != null && duda.resolucion != '' ? (
                                    <p style={{ position: 'absolute', top: '-25px', color: '#20c14a' }}>
                                      Resuelta
                                    </p>
                                  ) : (
                                    <p style={{ position: 'absolute', top: '-25px', color: '#f53737' }}>
                                      Pendiente resolución
                                    </p>
                                  )}


                                </div>
                              ))
                            ) : (
                              'No existen dudas en este tema'
                            )}


                          </div>
                        </div>

                      </div>

                    </div>
                  )

                  }






                </div>
                {/* <div className="WHC-hi-descargasdOWng">


                  <div className="WHC-hi-DOwng-DESCARGA">
                    <div className='iMW-boton2' id="descargarManual" onClick={handleOnClic} style={{ borderRight: '1px solid #bcbcbc' }}>
                      <i className="far fa-file-pdf" />Manual
                    </div>
                    <div className='iMW-boton2' id="descargarAtajos" onClick={handleOnClic}>
                      <i className="far fa-file-pdf" />Atajos
                    </div>
                  </div>
                  <div className="WHC-hi-DOwng-infD" style={{ fontStyle: 'italic' }}>
                    Descargables
                  </div>

                </div> */}
              </>
            ) : null
          }

          {
            viewSelect == 'claseOnline' ? (
              <div className='contentTutoria' >

                <div className='cT-tutoDisponibles'>
                  <h3> Tutorías disponibles </h3>

                  <div className='navTutoriasDisponibles'>
                    {tutorias != null && tutorias.length > 0 ? (
                      tutorias.map((tutoria, key1) => (
                        <div className='ntD-item' key={key1} onClick={() => { setPracticaSelect(null); setTutoriaSelect(key1) }} style={tutoriaSelect != null && tutoriaSelect == key1 ? ({ color: '#3469b7', fontWeight: '500' }) : {}}>
                          {moment(tutoria.fecha).format('DD-MM-YY')}
                        </div>
                      ))
                    ) : null}
                  </div>

                  {
                    tutoriaSelect != null ? (
                      <div className='tutoriaLoad'>

                        <div className='urlClaseWord'>
                          <p>¿Cuándo es la tutoría?</p>
                          <p>{moment(tutorias[tutoriaSelect].fecha).format('[El] DD-MM-YY [a las] HH:mm')}</p>
                          {
                            tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) && tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) != undefined ? (
                              <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                <Boton2 id={'entrarEnClase'} onClick={handleOnClic} texto={'Entrar en tutoría Zoom'} />
                              </div>
                            ) : (<div style={{ marginTop: '10px' }}>
                              <b>Confirme su asistencia para porder entrar en la tutoría online o presencial.</b>  <br /><br /> Podrá confirmar asistencia hasta un día antes de la tutoría, el maximo de alumnos presencialmente es de 25.<br /><br /> <b>Si asiste presencialmente debe traer su portatil</b>, en caso de no disponer de uno se le facilitará uno de la academia.
                            </div>)}
                        </div>

                        {
                          tipoUser > 50 ? (
                            <div className='tutoriaLoad-asistencia'>
                              <h3> Asistentes </h3>
                              <div className='RowCenterCenter'>
                                {
                                  tutorias[tutoriaSelect].alumnos != undefined ? (
                                    <>
                                      <div className='RowCenterCenter' style={{ margin: '5px' }}>
                                        Asistentes online: {tutorias[tutoriaSelect].alumnos.filter(a => a.tipo == "online").length}
                                      </div>
                                      <div className='RowCenterCenter' style={{ margin: '5px' }}>
                                        / Presencial: {tutorias[tutoriaSelect].alumnos.filter(a => a.tipo == "presencial").length}
                                      </div>
                                    </>
                                  ) : null
                                }
                              </div>
                            </div>
                          ) : null
                        }

                        <div className='tutoriaLoad-asistencia'>
                          <h3>

                            {tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) && tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) != undefined ? (
                              tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser).tipo == 'presencial' ? (
                                'Asistencia presencial confirmada'
                              ) : (
                                'Asistencia online confirmada'
                              )

                            ) : 'Asistencia'

                            }
                          </h3>
                          <div className='RowCenterCenter'>
                            {
                              tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) && tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) != undefined ? (
                                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                  <Boton2 id={'cancelarAsistencia'} onClick={handleOnClic} texto={'Cancelar asistencia'} />
                                </div>
                              ) : (
                                <>
                                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                    <Boton id={'confirmarAsistencia'} onClick={handleOnClic} texto={'Confirmar asistencia online'} />
                                  </div>
                                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                                    <Boton id={'confirmarAsistenciaP'} onClick={handleOnClic} texto={'Confirmar asistencia presencial'} />
                                  </div>
                                </>
                              )
                            }
                          </div>
                        </div>

                        {
                          tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) && tutorias[tutoriaSelect].alumnos.find(a => a.idUser == idUser) != undefined ? (

                            <div className='tutoriaLoad-previo'>

                              <h3>Contenido tutoría y previo estudio </h3>
                              {EstadoAlumno.practicaPrincipal == 'intesivo' ? (
                                <p> Cada práctica corresponde a 4 clases.</p>
                              ) : (
                                <p> La práctica marcada en rojo es la principal de este mes. Cada práctica corresponde a 4 clases.</p>
                              )}

                              <div className='navTutoriasDisponibles' style={{
                                alignSelf: 'center',
                                marginBottom: '15px'
                              }}>
                                {Practicas != null && Practicas != undefined && Practicas.length > 0 ? (
                                  Practicas.map((practica, key09x) =>
                                    <div key={key09x} className='ntD-item' onClick={() => setPracticaSelect(key09x)} style={
                                      practica.principal == 1 ? ({ color: '#cb3a3a', fontWeight: '500' }) : (practicaSelect != null && practicaSelect == key09x ? ({ color: '#3469b7', fontWeight: '500' }) : {})
                                    }>
                                      {'P. ' + practica.numPractica}
                                    </div>
                                  )
                                ) : null}
                              </div>
                              {
                                practicaSelect != null ? (
                                  <>
                                    <h3 style={{ alignSelf: 'center' }}> Práctica {Practicas[practicaSelect].numPractica}  </h3>
                                    <p style={{ textAlign: 'center', alignSelf: 'center' }}> {Practicas[practicaSelect].contenido}</p>

                                    {
                                      Practicas[practicaSelect].archivos.length > 0 && Practicas[practicaSelect].archivos.length > 0 ? (
                                        <div className='RowStartCenter' style={{ marginBottom: '35px', marginTop: '15px', alignSelf: 'center' }}>
                                          <div className='ColCenterStart'>
                                            <div className='apuntesWWHeader'>
                                              <i className="fa-duotone fa-laptop-file"></i> Ejercicios
                                            </div>
                                            <div className='bodyAwHword'>

                                              {
                                                Practicas[practicaSelect].archivos.length > 0 && Practicas[practicaSelect].archivos.map((apunte, key68x) => (
                                                  <div className='celdaAputneword' onClick={handleOnClic} id={"descargaEjercicioP-" + key68x} key={key68x}>
                                                    <p style={{ marginTop: '0px' }} id={"descargaEjercicioP-" + key68x}> {apunte.nombre.split('.', apunte.nombre.split('.').length - 1).join('.')} </p>
                                                    <i id={"descargaEjercicioP-" + key68x} className="fa-duotone fa-file-pdf"></i>
                                                  </div>
                                                ))
                                              }

                                            </div>
                                          </div>
                                        </div>
                                      ) : null
                                    }



                                  </>
                                ) : (

                                  <p style={{ alignSelf: 'center' }}> Seleccione una práctica.</p>

                                )
                              }




                            </div>

                          ) : null}




                        {/* <div className='tutoriaLoad-segerido' style={{ marginLeft: '0px' }}>
                      <h3> Contenido sugerido por alumnos</h3>

                      {
                        tutorias[tutoriaSelect].sugerencias.length > 0 ? (
                          tutorias[tutoriaSelect].sugerencias.map((sug, key2) => (
                            <div className='tutoriaLoad-segerido' key={key2}>
                              {ReactHtmlParser(sug.texto)}

                              {
                                sug.idUser == idUser ? (
                                  <div className='RowCenterCenter' style={{ margin: '10px 0px' }}>
                                    <Boton2 id={'cancelarSugerencia-' + key2} onClick={handleOnClic} texto={'Cancelar mi sugerencia'} />
                                  </div>
                                ) : null
                              }

                            </div>
                          ))

                        ) : (
                          <div className='tutoriaLoad-segerido'>
                            <p>Aún ningun alumno realizo sugerencias</p>

                          </div>
                        )
                      }

                    </div>

                    <div className='tutoriaLoad-sugerir'>
                      <h3>Sugerir algun contenido</h3>

                      <div className='zonaEditorText'>
                        <div className="editorPlantearrDuda">
                          <CKEditor
                            editor={ClassicEditor}
                            data={textHtmlDudaArtAlumno}
                            onInit={editor => { }}
                            config={{
                              toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                              // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                            }}

                            onChange={onChangeText}
                          />
                        </div>

                        <div className="editorPlantearrDudaBotones" style={{ justifyContent: 'center', marginTop: '5px' }}>

                          <div className="epdb-boton">
                            <Boton id="enviarSugerencia" icon1="" icon2="" texto="Enviar sugerencia" onClick={handleOnClic} />
                          </div>

                        </div>
                      </div>
                    </div> */}
                      </div>
                    ) : null
                  }

                </div>

              </div>
            ) : null
          }

        </div >
      ) : null}

      {EstadoFactura == 'sinComprar' ? (
        <div className="bodyOptWord2010">

          <div id="masInfoOmenos" className="OW2-content">
            <div className="OW2-C-menu">

              <div style={{ paddingLeft: '0px' }}> Preparación: </div>
              <div id="normalCompra" onClick={() => { setintensivoCompra(false); setnormalCompra(true) }} style={normalCompra && normalCompra == true ? ({ borderRight: '1px solid #bcbcbc', color: '#3469b7', fontWeight: '500' }) : ({ borderRight: '1px solid #bcbcbc' })}>
                Normal </div>
              {/* <div id="intensivoCompra" onClick={() => { setintensivoCompra(true); setnormalCompra(false) }} style={intensivoCompra && intensivoCompra == true ? ({ color: '#3469b7', fontWeight: '500' }) : ({})}>
                Intensivo </div> */}

              <div id="" onClick={() => { swal('Proximamente', 'Aun no esta disponible', 'info') }} style={intensivoCompra && intensivoCompra == true ? ({ color: '#3469b7', fontWeight: '500' }) : ({})}>
                Intensivo </div>


            </div>
            <div className="recPlayVidDiv" onContextMenu={e => e.preventDefault()}
              style={{
                position: 'relative',
                alignSelf: 'center',
                marginTop: '0px',
                marginBottom: '15px',
                maxWidth: '450px'
              }}>
              {/* <ReactPlayer
                url={'../videos/word2010CEJ.mp4'}
                className='react-player'
                playing={false}
                width='100%'
                height='100%'
                controls={true}
                onContextMenu={e => e.preventDefault()}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              /> */}


            </div>
            {
              intensivoCompra && intensivoCompra == true ? (
                <div className="OW2-C-info" style={{ maxWidth: '850px' }}>

                  <p> <b>Bienvenid@</b>.</p>

                  <p> <b>Manual Word 2010</b> en PDF. </p>

                  <p>Preparación del manual en modo <b>intensivo</b> 2 meses.</p>

                  <p>El alumno deberá ir estudiando una <b>parte del manual y atajos</b>, al ritmo que disponga diario de estudio.</p>
                  <p>Recomedamos dar 3 vueltas al temario con sus practicas y test, asi como 2 vueltas a los Test Word</p>
                  <p>Mientras va estudiando o repasando el temario puede acceder a la sección <b>Test de Word</b>, dispondrá de 50 test tipo examen.</p>

                  <p> </p>


                  <p><b>*</b> Precio curso intensivo 80€/mes.</p>
                  <p><b>*</b> Tendrá acceso durante 2 meses.</p>


                </div>
              ) : null
            }

            {
              normalCompra && normalCompra == true ? (
                <div className="OW2-C-info" style={{ maxWidth: '850px' }}>

                  <p> <b>Bienvenid@</b>.</p>

                  <p> <b>Manual Windows 10 + Office 2019</b>. </p>

                  <p>Preparación en <b>4 meses</b> </p>

                  <p>100% online: Videos y Test del Temario</p>

                  <p> <b>Tutor</b>, para resolver cualquier duda vía whatsapp o plataforma.</p>
                  <p><b>*</b> Precio mensual 30€.</p>
                  <p><b>*</b> El contenido se ira debloqueando progresivamente.</p>

                </div>
              ) : null
            }


            <div className="OW2-C-menu-botonesS34">

              <div className='iMW-boton' style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
                <Boton id="comprarPreparacionWord2010" icon1="" icon2="" texto="Comprar" onClick={handleOnClic} />
              </div>
            </div>


            <div className="OW2-C-precio">
              {
                intensivoCompra && intensivoCompra == true ? (
                  '60€'
                ) : null
              }

              {
                normalCompra && normalCompra == true ? (
                  '30€'
                ) : null
              }
            </div>
          </div>



        </div>
      ) : null}

      {EstadoFactura == 'pendiente' ? (
        // FACTURA PENDIENTE ---> 
        <div className="infoW10body">
          <div className="iW10b-textHeader">
            <div className="iW10b-textHinfo">
              Factura pendiente
            </div>
          </div>

          <div className="iW10b-textinfo2">
            Por favor haga clic en el siguiente enlace para proceder al pago de la factura.
          </div>

          <div className='iMW-boton' style={{ marginTop: '8px' }}>
            <Boton id="irAFacturacion" icon1="" icon2="" texto="Ir a facturación" onClick={() => window.location.href = 'https://oposiciones-justicia.es/Facturacion'} />
          </div>

        </div>
      ) : null}

      {EstadoFactura == 'caducada' ? (
        <>
          <div className="iW10b-textHeader" style={{ maxWidth: '290px', borderBottom: '13px solid #3469b7b0' }}>
            <div className="iW10b-textHinfo">
              Factura caducada
            </div>
          </div>

          <div className="bodyOptWord2010">


            <div className="OW2-content" style={{ maxHeight: 'unset' }}>


              <div className="OW2-C-info">
                <p style={{ maxWidth: '650px' }}>Para poder seguir usando la plataforma,<br /> debe realizar <b>el pago mensual</b></p>

              </div>

              <div className='iMW-boton' style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
                <Boton id="comprarMes" icon1="" icon2="" texto="Comprar" onClick={handleOnClic} />
              </div>

              <div className="OW2-C-precio">
                30€
              </div>
            </div>



          </div>
        </>

      ) : null}

    </>
  );

}

export default Word2010Home

// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Utils
import { isDefined } from '../../lib/utils/is';
//Componets
import Input from '../../components/Inputs/Input';
import Input2 from '../../components/Inputs/Input2';
import Submit from '../../components/Inputs/Submit';
import Boton from '../../components/Inputs/Boton';



const SincroClases = (props) => {


  const [alumnoSelected, setalumnoSelected] = useState(null)
  const [claseActualSelected, setclaseActualSelected] = useState(null)
  const [claseNuevaSelected, setclaseNuevaSelected] = useState(null)

  const [busquedaAlumnos, setbusquedaAlumnos] = useState(null)
  const [busquedaSemClasesAll, setbusquedaSemClasesAll] = useState(null)
  const [busquedaOpo, setbusquedaOpo] = useState(null)

  const [listaClasesAlumno, setlistaClasesAlumno] = useState(null)


  const refInput1 = useRef();
  // const [statusBarraProceso, setStatusBarraProceso] = useState('completar')

  // const ref = React.createRef();
  useEffect(() => { // didMont
    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
      .then(res => res.json())
      .then(clases =>

        setbusquedaSemClasesAll(clases.filter(p => p.tipoClase == 'PRESENCIAL' || p.tipoClase == 'ONLINE'))

      ).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
      .then(res => res.json())
      .then(alumnos => setbusquedaAlumnos(alumnos)

      ).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => setbusquedaOpo(oposiciones)

      ).catch(error => console.log('Object fecth failed', error));

    // willunmount hacemos 
    return () => {
      console.log('Cleanup')
    }
  }, []) // [] es el didUpdate , nos indica que no se recarga con ningun valor, si pones [nombre_ley] cada vez que se actualice nombre_ley ejecuta todo

  const onChangeInput = (e) => {

    if (e.target.name == 'claseActual') {
      let value = e.target.value

      setclaseActualSelected(
        value != '' && listaClasesAlumno.find(p => p.nombreClase == value) != undefined ?

          listaClasesAlumno.find(p => p.nombreClase == value)
          : null
      );

      if (value == '' || listaClasesAlumno.find(p => p.nombreClase == value) == undefined) {
        setclaseNuevaSelected(null)
      }
    } else if (e.target.name == 'claseNueva') {

      let value = e.target.value
      setclaseNuevaSelected(
        value != '' && busquedaSemClasesAll.find(p => p.dia_semanal == value) != undefined ?

          busquedaSemClasesAll.find(p => p.dia_semanal == value)
          : null
      );
    }

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == 'cambiarAlumnoClase') {


      if (isDefined(alumnoSelected) && isDefined(claseActualSelected) && isDefined(claseNuevaSelected)) {
        if (listaClasesAlumno.filter(p => p.idClase == claseNuevaSelected.id_clase).length == 0) {
          if (claseNuevaSelected.id_clase != claseActualSelected.idClase) {

            swal({
              title: 'Cambiando alumno de clase',
              text: 'Realizando cambio, espere.',
              icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })
            let programacionExp = []

            if (claseActualSelected.idOpo == claseNuevaSelected.id_oposicion) {
              // sacar info de claseActual y claseNuevaSelected toda la prg

              let data1 = { idClase: claseActualSelected.idClase }
              let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/infoPrgCambioClase", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;

              let data2 = { idClase: claseNuevaSelected.id_clase }
              let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/infoPrgCambioClase", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              for (let jx = 0; jx < cls1.length; jx++) {

                if (cls2.find(a => a.id_programa == cls1[jx].id_programa) != undefined &&
                  programacionExp.filter(b => b.idPrg == cls1[jx].id_programa).length == 0
                ) {
                  programacionExp.push({
                    idPrg: cls1[jx].id_programa,
                    idClase: cls1[jx].id_clase,
                    idClaseNueva: cls2.find(a => a.id_programa == cls1[jx].id_programa).id_clase,
                    idUser: alumnoSelected.id_usuario
                  }) // creamos el array con las idPrg iguales entre las 2 clases
                }

              }

            }
            // console.log('programacionExp')
            // console.log(programacionExp)

            if (programacionExp.length > 0) {
              // hacemos for con los fetchs --> updExpTeo updExpPractico
              for (let sd = 0; sd < programacionExp.length; sd++) {

                let data1 = {
                  idClaseActual: claseActualSelected.idClase,
                  idClaseNueva: claseNuevaSelected.id_clase,
                  idPrg: programacionExp[sd].idPrg,
                  idUser: alumnoSelected.id_usuario
                }
  
                let response = await fetch('https://oposiciones-justicia.es/api/oficina/updExpTeo', {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                });
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                let json = await response.json();
                let cls = await json;

                let response12 = await fetch('https://oposiciones-justicia.es/api/oficina/updExpPractico', {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                });
                if (!response12.ok) {
                  throw Error(response12.statusText);
                }
                let json12 = await response12.json();
                let cls12 = await json12;
                
              }
              
            }
            let data = {
              idClaseActual: claseActualSelected.idClase,
              idClaseNueva: claseNuevaSelected.id_clase,
              idUser: alumnoSelected.id_usuario
            }
            // hacemos el cambio de clase updCambClase
            let response01 = await fetch('https://oposiciones-justicia.es/api/oficina/updCambClase', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response01.ok) {
              throw Error(response01.statusText);
            }
            let json01 = await response01.json();
            let cls01 = await json01;

            // hacemos cambio de facturas updCambClaseFAC

            let response02 = await fetch('https://oposiciones-justicia.es/api/oficina/updCambClaseFAC', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response02.ok) {
              throw Error(response02.statusText);
            }
            let json02 = await response02.json();
            let cls02 = await json02;

            await swal({
              title: 'Alumno cambiado',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 1500,
            }).then(() => {
              window.location.reload()
            })
          } else {
            swal('Misma clase', 'ERROR: Debe seleccionar 2 clases diferentes', 'info')
          }
        } else {
          swal('Alumno en clase nueva y actual', 'ERROR: El alumno ya esta en la clase nueva, tiene que borrarlo de la clase nueva para meterlo nuevamente y se sincronice el temario.', 'info')
        }


      } else {
        swal('Error', 'Rellene todos los campos', 'info')
      }
    }

  }

  const handleBuscarAlumno = (e) => {

    let alumnoCorrecto = busquedaAlumnos.find(alumno => alumno.dni == e.target.value);
    if (alumnoCorrecto != undefined) {
      setalumnoSelected(alumnoCorrecto)
      var data = { id_usuario: alumnoCorrecto.id_usuario }
      fetch("https://oposiciones-justicia.es/api/oficina/infoAlumnoClase", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(data => {
        if (data != undefined && data.length > 0) {
          let clasesAlumno = []
          for (var i = 0; i < data.length; i++) {
            clasesAlumno.push({
              idClase: data[i].idClase,
              nombreClase: data[i].nombreClase,
              idOpo: data[i].idOposicion
            })
          }
          setlistaClasesAlumno(clasesAlumno);

        }
      }).catch(function (err) { console.log(err) });
    } else {
      setlistaClasesAlumno(null)
      setclaseActualSelected(null)
      setclaseNuevaSelected(null)

    }
  }



  const { selectedOption } = props;
  // const { busquedaLeyes, statusBarraProceso } = state
  



  return (


    <div className='ColCenterStart'>

      <div className="celdaInputDAPFBN">
        <Input2 type="text" name="slc_dni_edit" placeholder="Buscar Alumno" icon="fas fa-search" datalist="busquedaAlumnos" onChange={handleBuscarAlumno} />
        <p>Dni Alumno</p>
      </div>
      {
        alumnoSelected && alumnoSelected != null ? (
          <React.Fragment>
            <div className="celdaInputDAPFBN">
              <Input2 type="text" id="claseActual" name="claseActual" placeholder="Clase" icon="fas fa-search" datalist="listaClasesAlumno" onChange={onChangeInput} />
              <p>Clase Actual</p>
            </div>
            {
              claseActualSelected != null ? (
                <div className='RowCenterCenter' style={{ marginTop: '20px' }}>

                  <div className="celdaInputDAPFBN">
                    <Input2 type="text" datalist="busquedaSemClasesAll" id="claseNueva" name="claseNueva" placeholder="Clase" icon="fas fa-search" onChange={onChangeInput} />
                    <p>Clase Nueva</p>
                  </div>

                </div>
              ) : null
            }

            {
              claseNuevaSelected != null ? (
                <div className='rowBotnes2'>

                  <Boton id="cambiarAlumnoClase" onClick={handleSubmitForm} icon1="" icon2="" texto="Cambiar Alumno de clase" />

                </div>
              ) : null}

          </React.Fragment>
        ) : null
      }


      <datalist id="busquedaAlumnos">{
        busquedaAlumnos && busquedaAlumnos.map((cls, key) =>
          <option key={key} value={cls.dni}>{cls.nombre + ' ' + cls.apellidos}</option>
        )
      }</datalist>

      <datalist id="listaClasesAlumno">{
        listaClasesAlumno && listaClasesAlumno.map((cls, key) =>
          <option key={key} value={cls.nombreClase}></option>
        )
      }</datalist>

      <datalist id="busquedaSemClasesAll">{
        busquedaSemClasesAll && busquedaSemClasesAll.map((cls, key) =>
          <option key={key} value={cls.dia_semanal}></option>
        )
      }</datalist>



    </div>

    // <div className="ColCenterStart">
    //   {/* <BarraProceso estadoProceso={statusBarraProceso} /> */}

    //   <div className='celdaInputDAPFBN'>
    //     <Input2 ref={refInput1} datalist='datalistlink' autoComplete={'off'} id={'nombre_ley'} type='text' name={'nombre_ley'} placeholder='Nombre ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
    //     <p>Alumno</p>
    //   </div>

    //   <div className='celdaInputDAPFBN'>
    //     <Input2 value={'siglas_ley'} datalist='datalistlink' autoComplete={'off'} id={'siglas_ley'} type='text' name={'siglas_ley'} placeholder='Siglas ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
    //     <p>Clase Actual</p>
    //   </div>

    //   <div className='celdaInputDAPFBN'>
    //     <Input2 value={'link_ley'} datalist='datalistlink' autoComplete={'off'} id={'link_ley'} type='text' name={'link_ley'} placeholder='Link ley' icon='fas fa-chevron-circle-right' onChange={onChangeInput} />
    //     <p>Clase Cambio</p>
    //   </div>


    //   <Boton id='changeClase' onClick={handleSubmitForm} icon1='' icon2='' texto='Realizar cambio' />
    // </div>
  );





}

SincroClases.propTypes = {

}



export default SincroClases;
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
// Utils
import { isDefined } from '../../../../../lib/utils/is';

//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Input from '../../../../../components/Inputs/Input';
import InputIntervalo from '../../../../../components/Inputs/InputIntervalo';
import InputFile from '../../../../../components/Inputs/InputFile';
import Submit from '../../../../../components/Inputs/Submit';

class optionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };

constructor(props){
  super(props);
  this.state = {
    fileTestWord: [],
    fileSolucionTestWord: [],
    fileTestWordEdt: [],
    fileSolucionTestWordEdt: []
  };
  this.onChangeInput = this.onChangeInput.bind(this);
  this.handleSubmitForm = this.handleSubmitForm.bind(this);
  this.myFiles = this.myFiles.bind(this);
}

myFiles(e){
  let files = e.target.files;
  let filesLoad = [];
  for (var i = 0; i < files.length; i++) {
    filesLoad.push(files[i]);
  }

  if(e.target.name == 'fileTestWord'){
    this.setState({fileTestWord: filesLoad});
  } else if(e.target.name == 'fileSolucionTestWord'){
    this.setState({fileSolucionTestWord: filesLoad});
  } else if(e.target.name == 'fileTestWordEdt'){
    this.setState({fileTestWordEdt: filesLoad});
  } else if(e.target.name == 'fileSolucionTestWordEdt'){
    this.setState({fileSolucionTestWordEdt: filesLoad});
  }
}
async onChangeInput(e){

  this.setState({[e.target.name]: e.target.value});
  if (this.props.selectedOption == 'Editar_Test_Word'){
      if(e.target.name == "wordSelected"){
        var e = e

        let wordEncontrar = this.state.wordsTestPdf.find(v=> v.id == e.target.value.split('-')[0])

        if(wordEncontrar != undefined){
          await this.setState({wordToEdt: wordEncontrar, nombreW: wordEncontrar.nombre, linkTest: wordEncontrar.linkTest, linkSolucion: wordEncontrar.linkResolucion })
          this.refs.ref_nombreWord.refs.nombreWord.value = wordEncontrar.nombre
        } else {
          await this.setState({wordToEdt: undefined})
        }
      } else if (e.target.name == 'nombreWord'){
        var e = e
        await this.setState({nombreW: e.target.value})
      }
  }

}

async handleSubmitForm(e){

  var e = e
  if(e.target.id == 'addTestWord'){
    try{
    if(this.state.nombreTestWord != '' && this.state.fileTestWord.length > 0 && this.state.fileSolucionTestWord.length > 0){
      swal({
        title: 'Insertanto Test Word',
        text: ' ',
        icon: "https://i.giphy.com/media/jAYUbVXgESSti/giphy.webp",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      let testWord = { nombre: this.state.nombreTestWord, link: undefined, linkSolucion: undefined}
      let data1 = new FormData();
      data1.append('myFiles', this.state.fileTestWord[0]);
      let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
        method: 'POST',body: data1});
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
      let json1 = await response1.json();
      let cls1 = await json1;
      if(cls1 != undefined){
        let path = cls1[0].path.split('src/')[1];
        testWord.link = path

        let data2 = new FormData();
        data2.append('myFiles', this.state.fileSolucionTestWord[0]);
        let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
          method: 'POST',body: data2});
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
        let json2 = await response2.json();
        let cls2 = await json2;
        if(cls2 != undefined){
          let path2 = cls2[0].path.split('src/')[1];
          testWord.linkSolucion = path2

          let data3 = { nombreTestWord: testWord.nombre, linkTest: testWord.link, linkSolucion: testWord.linkSolucion}
          let response3 = await fetch("https://oposiciones-justicia.es/api/temas/addTestWord", {
            method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
          let json3 = await response3.json();
          let cls3 = await json3;
          if (cls3 != undefined){
            swal({
              title: 'Test Word Pdf agregado correctamente',
              text: 'Actualizando página en 3 segundos.',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })
            setTimeout(
            function() {
                window.location.reload()
            }.bind(this),2500);
          }
        }
      }
    } else {
      swal('Faltan datos por rellenar','','error')
    }

  }catch(e){console.log(e)}
} else if (e.target.id == 'editar_word'){
    let {fileTestWordEdt, fileSolucionTestWordEdt, wordToEdt, nombreW } = this.state
    try{
      swal({
        title: 'Editando Test Word',
        text: ' ',
        icon: "https://i.giphy.com/media/jAYUbVXgESSti/giphy.webp",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
      if(fileTestWordEdt.length != 0){
        // Eliminamos test word y metemos el nuevo
          let data1 = {pathFile: '../'+wordToEdt.linkTest}
    			let response1 = await fetch("https://oposiciones-justicia.es/deleteFile", {
    				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
    				if (!response1.ok) {
    					throw Error(response1.statusText);
    				}
    			let json1 = await response1.json();
    			let cls1 = await json1;
          if(cls1 != undefined){
            let data2 = new FormData();
            data2.append('myFiles', fileTestWordEdt[0]);
            let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST',body: data2});
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
            let json2 = await response2.json();
            let cls2 = await json2;
            if(cls2 != undefined){
              let path = cls2[0].path.split('src/')[1];

              let data3 = {linkTestWordPdf: path, idTestWordPdf: wordToEdt.id }
        			let response3 = await fetch("https://oposiciones-justicia.es/api/temas/edtTestWordPdf", {
        				method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
        				if (!response3.ok) {
        					throw Error(response3.statusText);
        				}
        			let json3 = await response3.json();
        			let cls3 = await json3;
            }
          }
      }

      if(fileSolucionTestWordEdt.length != 0){
        // Eliminamos test solucion word y metemos el nuevo
        let data1 = {pathFile: '../'+wordToEdt.linkResolucion}
        let response1 = await fetch("https://oposiciones-justicia.es/deleteFile", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
        if(cls1 != undefined){
          let data2 = new FormData();
          data2.append('myFiles', fileSolucionTestWordEdt[0]);
          let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST',body: data2});
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
          let json2 = await response2.json();
          let cls2 = await json2;
          if(cls2 != undefined){
            let path = cls2[0].path.split('src/')[1];

            let data3 = {linkSolucionWordPdf: path, idTestWordPdf: wordToEdt.id}
            let response3 = await fetch("https://oposiciones-justicia.es/api/temas/edtSolucionWordPdf", {
              method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
            let json3 = await response3.json();
            let cls3 = await json3;
          }
        }
      }

      if(nombreW != wordToEdt.nombre){
        // cambiar nombre bd
        let data1 = {nombreTestWord: nombreW, idTestWordPdf: wordToEdt.id}
        let response1 = await fetch("https://oposiciones-justicia.es/api/temas/edtNameWordPdf", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;
      }

      await swal({
        title: 'Test Word editado',
        text: '¡Recargando web!',
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 2000,
      }).then(() => {
          window.location.reload()
      })

    } catch(e){console.log(e)}
  } else if (e.target.id == 'borrar_word'){
    swal({
  	  title: "¿Estas segur@?",
  	  text: "Si le das a ok, el word test será borrado.",
  	  icon: "warning",
  	  buttons: true,
  	  dangerMode: true,
  	})
  	.then(async (willDelete) => {
  	  if (willDelete) {
        let { wordToEdt } = this.state
        let data1 = {pathFile: '../'+wordToEdt.linkTest}
        let response1 = await fetch("https://oposiciones-justicia.es/deleteFile", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data1)});
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
        let json1 = await response1.json();
        let cls1 = await json1;

        let data2 = {pathFile: '../'+wordToEdt.linkResolucion}
        let response2 = await fetch("https://oposiciones-justicia.es/deleteFile", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data2)});
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
        let json2 = await response2.json();
        let cls2 = await json2;

        let data3 = {idTestWordPdf: wordToEdt.id}
        let response3 = await fetch("https://oposiciones-justicia.es/api/temas/deleteTestWordPdf", {
          method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
        let json3 = await response3.json();
        let cls3 = await json3;
        if(cls3 != undefined){

          await swal({
            title: 'Test Word Borrado',
            text: '¡Recargando web!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 2000,
          }).then(() => {
              window.location.reload()
          })
        }

  	  } else {
  	    swal("¡Test word no borrado!",'',"success");
  	  }
  	});
  }

}

 componentDidMount(){

  fetch('https://oposiciones-justicia.es/api/temas/loadTestWord')
  .then(res => res.json())
    .then(cls => this.setState({
        wordsTestPdf: cls
    }))
  .catch(error => console.log('Object fecth failed', error))


};
  render() {
    const { isLogged, nombreUser, tipoUser, selectedOption } = this.props;
    const { wordsTestPdf, wordToEdt } = this.state

      switch (selectedOption) {
        case 'Añadir_Test_Word': {
          return (
            <form className="form234">

              <h44><i className="fas fa-info-circle"> </i> Nombre Test Word <i className="fas fa-tag"> </i></h44>
              <Input type="text" name="nombreTestWord" placeholder="Nombre del Test Word" icon="fas fa-search" ref="ref_nombreTestWord" onChange={this.onChangeInput}/>
              <h44><i className="fas fa-info-circle"> </i> Test Word .pdf <i className="fas fa-file-pdf"> </i></h44>
              <InputFile texto="PDFs" name="fileTestWord" icon1="far fa-file-pdf" onChange={this.myFiles}/>

              <h44><i className="fas fa-info-circle"> </i> Solución Test Word .pdf <i className="fas fa-file-pdf"> </i></h44>
              <InputFile texto="PDFs" name="fileSolucionTestWord" icon1="far fa-file-pdf" onChange={this.myFiles}/>
              <Submit id="addTestWord" icon1="fas fa-upload" icon2="fas fa-database" texto="Agregar Test Word" onClick={this.handleSubmitForm}/>

            </form>


              );
        }
        break;

        case 'Editar_Test_Word': {
              return (
                <form className="form234">
                  <h44> <i className="fas fa-info-circle"></i> Seleccione un Video </h44>
                  <Input type="text" name="wordSelected" placeholder="Sel. test Word" icon="fas fa-search" datalist="wordTest" ref="ref_wordSelected" onChange={this.onChangeInput} />

                  {wordToEdt && wordToEdt != undefined ? (
                    <div className="bodyTemaToEdt">
                      <h44> <i className="fas fa-info-circle"></i> Nombre Video <i className="fas fa-tag"> </i></h44>
                      <Input type="text" name="nombreWord" placeholder="Edt Nombre Word Test" icon="fas fa-chevron-circle-right" ref="ref_nombreWord" onChange={this.onChangeInput}/>

                      <h44><i className="fas fa-info-circle"> </i> Nuevo Test Word .pdf <i className="fas fa-file-pdf"> </i></h44>
                      <InputFile texto="PDFs" name="fileTestWordEdt" icon1="far fa-file-pdf" onChange={this.myFiles}/>

                      <h44><i className="fas fa-info-circle"> </i> Nueva solucion T. Word .pdf <i className="fas fa-file-pdf"> </i></h44>
                      <InputFile texto="PDFs" name="fileSolucionTestWordEdt" icon1="far fa-file-pdf" onChange={this.myFiles}/>

                      <Submit id="editar_word" icon1="fas fa-edit" icon2="fas fa-database" texto="Editar Test Word" onClick={this.handleSubmitForm}/>
                      <Submit id="borrar_word" icon1="fas fa-trash" icon2="fas fa-database" texto="Borrar Test Word" onClick={this.handleSubmitForm}/>
                    </div>
                  ): null}


                  <datalist id="wordTest">{ wordsTestPdf && wordsTestPdf.map((wordTest, key) =>
                     <option key={key} value={wordTest.id+'-'+wordTest.nombre}>{wordTest.linkTest}</option>
                   )}</datalist>

                 </form>
              );
        }
        break;

        default: {
          return (
            <div style={{fontSize: '24px'}} >
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
              Selecciona una opción para continuar.
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
            </div>
          );
        }
      }
  }
}

export default optionBar;

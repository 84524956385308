// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
// Assets
import './css/css.css';
import Input2 from '../Inputs/Input2';
import Boton from '../Inputs/Boton';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadClaseCurso, filtrarClaseCurso } from '../../lib/utils/cargarSemanaClase.js';


import { selectPreguntasPorIdTest, respuestasTestId, mezclarRespuestasJusticia, comprobarRespuestas, selectPreguntasPorIdTestCP, respuestasTestIdCP } from '../../lib/utils/cargarTestJusticia'
// Actions
import { loadTestUser, loadCPUser, borrarTestUser, borrarCP, cronoFunc, loadCurso, loadClase, loadTemasFacturados, loadAllSemFac, loadSemanaPrg, loadTemaAula } from '../../actions/userAction'


import { isDefined } from '../../lib/utils/is';

import swal from 'sweetalert';
import CajaInfoTema from '../CajaInfoTema';

import TemaSeleted from '../TemaSeleted'

import Ranking from '../Ranking'

import InputFileExamDesa from '../Inputs/InputFileExamDesa'




class CajaInfoSemanaCurso extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		this.state = {
			tipoOpen: 'Principal',
			numSemLoad: 0,
			navS: 0,
			tipoEjerSelected: null,
			testSelected: 0,
			notaAlumnoAuto: null,
			ejerciciosLoad: null,
			planEstudio: null,
			temaPrueba: null
		};

		this.onHandleClick = this.onHandleClick.bind(this);
		this.onChangeBuscador = this.onChangeBuscador.bind(this);
		this.myFiles = this.myFiles.bind(this);
	}

	async myFiles(e) {
		if (e.target.name == 'examGest') {
			try {

				let files = e.target.files;
				let testSelected = this.state.testSelected

				var filesLoad = [];
				for (var i = 0; i < files.length; i++) {
					filesLoad.push(files[i]);
				}

				swal({
					title: "¿Estas segur@?",
					text: "Si le das a ok, el examen será enviado y no podra subir otro, tendrá disponible la solución. El sistema de correción es auto-evaluación por parte del alumno "
					,
					icon: "warning",
					buttons: true,
					dangerMode: true,
				})
					.then(async (willDelete) => {
						if (willDelete) {

							this.state.ejerciciosLoad.ejerDesarrollo[testSelected].examenToUpload = filesLoad

							if (this.state.ejerciciosLoad.ejerDesarrollo[testSelected].examenToUpload != undefined && this.state.ejerciciosLoad.ejerDesarrollo[testSelected].examenToUpload.length > 0) {
								swal({
									title: 'Subiendo Examen de Gestión',
									text: ' ',
									icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
									buttons: false,
									closeOnEsc: false,
									closeOnClickOutside: false,
								})

								let data1 = new FormData();
								data1.append('myFiles', this.state.ejerciciosLoad.ejerDesarrollo[testSelected].examenToUpload[0]);
								if (data1.getAll('myFiles') && data1.getAll('myFiles').length == 1) {

									let nombreArchivoOrig = data1.getAll('myFiles')[0].name
									// let tipoArchivo = nombreArchivo.split('.')[1]

									let nombreArchivo = '';
									let tipoArchivo = ''
									for (let i = 0; i < nombreArchivoOrig.split('.').length; i++) {
										if (i == nombreArchivoOrig.split('.').length - 1) {
											tipoArchivo = nombreArchivoOrig.split('.')[i]
										} else {

											if (i == nombreArchivoOrig.split('.').length - 2) {
												nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i]
											} else {
												nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i] + '.'
											}
										}

									}


									if (tipoArchivo && tipoArchivo == 'pdf') {
										let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
											method: 'POST', body: data1
										});
										if (!response1.ok) {
											throw Error(response1.statusText);
										}
										let json1 = await response1.json();
										let cls1 = await json1;
										if (cls1 != undefined) {
											let path = cls1[0].path.split('src/')[1];

											let data3 = {
												idAlumno: this.props.idUser,
												idExamGes: this.state.ejerciciosLoad.ejerDesarrollo[testSelected].idExamenGestion,
												idTema: null,
												fecha: moment().format('YYYY-MM-DD HH:mm'),
												ruta: path
											}
											let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/addExamGesCompletoCursoSinTema", {
												method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
											});
											if (!response3.ok) {
												throw Error(response3.statusText);
											}
											let json3 = await response3.json();
											let cls3 = await json3;
											if (cls3 != undefined) {

												await swal({
													title: 'Examen subido correctamente',
													text: 'Ya puede descargar la solución y realizar la auto-evaluación. ¡Recargando!',
													icon: "success",
													closeOnEsc: false,
													closeOnClickOutside: false,
													timer: 2500,
												}).then(() => {
													window.location.reload()
												})



											}

										}

									} else {
										swal('Error en nombre de Archivo', 'No puede contener ningun punto "." y debe ser un archivo PDF', 'error')
									}


								} else {
									swal('Solo puede escoger un archivo en .pdf', '', 'error')
								}


							} else {
								swal('Tiene que introducir un archivo en .pdf', '', 'error')
							}
						} else {
							swal("¡Examen no subido!", '', "success");

							this.setState({ tipoEjerSelected: null, testSelected: 0 })
							this.setState({ tipoEjerSelected: 'testDesarrollo', testSelected: 0 })
						}
					});
			} catch (e) { console.log(e) }
		}
	}

	async onHandleClick(e) {
		if (e.target.id == 'retroceder') {
			let numSemLoad = this.state.numSemLoad
			let semanasPrgCurso = this.props.semanasPrgCurso

			let nextNum = numSemLoad - 1
			if (nextNum < 0) {
				nextNum = semanasPrgCurso.length - 1
			}

			this.setState({ numSemLoad: nextNum, tipoOpen: 'Principal', navS: 0, testSelected: 0 })
			this.props.loadTemaAula(null)

		} else if (e.target.id == 'avanzar') {
			let numSemLoad = this.state.numSemLoad
			let semanasPrgCurso = this.props.semanasPrgCurso

			let nextNum = numSemLoad + 1
			if (nextNum > semanasPrgCurso.length - 1) {
				nextNum = 0
			}

			this.setState({ numSemLoad: nextNum, tipoOpen: 'Principal', navS: 0, testSelected: 0 })
			this.props.loadTemaAula(null)
		} else if (e.target.id == 'modoNormal') {
			this.setState({ tipoOpen: 'Principal', navS: 0, testSelected: 0 })
			this.props.loadTemaAula(null)
		} else if (e.target.id == 'modoRepaso') {
			this.setState({ tipoOpen: 'Repaso', navS: 0, testSelected: 0 })
			this.props.loadTemaAula(null)
		} else if (e.target.id.split('-')[0] == "nav") {
			let keyValNav = e.target.id.split('-')[1]

			if (keyValNav == 1) {

				// cargamos las historiales de los ejercicios
				let { semanasPrgCurso, temasFacturados } = this.props
				let { tipoOpen, numSemLoad } = this.state

				let ejerciciosPreLoad = {
					testTeorico: [],
					testPractico: [],
					ejerDesarrollo: []
				}

				if (temasFacturados.length > 0 && temasFacturados.length > 1) {


					if (tipoOpen == 'Principal') {
						if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testTeoria.length > 0) {
							ejerciciosPreLoad.testTeorico = semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testTeoria
						}

						if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testPractico.length > 0) {
							ejerciciosPreLoad.testPractico = semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testPractico
						}

						if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.ejerDesarrollo.length > 0) {
							ejerciciosPreLoad.ejerDesarrollo = semanasPrgCurso[numSemLoad].ejerciciosPrincipales.ejerDesarrollo
						}

						if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testTeoria.length > 0) {
							this.setState({ tipoEjerSelected: 'testTeorico' })
						} else if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.testPractico.length > 0) {
							this.setState({ tipoEjerSelected: 'testPractico' })
						} else if (semanasPrgCurso[numSemLoad].ejerciciosPrincipales.ejerDesarrollo.length > 0) {
							this.setState({ tipoEjerSelected: 'testDesarrollo' })
						} else {
							this.setState({ tipoEjerSelected: null })
						}


					} else {
						if (semanasPrgCurso[numSemLoad].ejerciciosRepaso.testTeoria.length > 0) {
							ejerciciosPreLoad.testTeorico = semanasPrgCurso[numSemLoad].ejerciciosRepaso.testTeoria
						}

						if (semanasPrgCurso[numSemLoad].ejerciciosRepaso.testPractico.length > 0) {
							ejerciciosPreLoad.testPractico = semanasPrgCurso[numSemLoad].ejerciciosRepaso.testPractico
						}

						if (semanasPrgCurso[numSemLoad].ejerciciosRepaso.ejerDesarrollo.length > 0) {
							ejerciciosPreLoad.ejerDesarrollo = semanasPrgCurso[numSemLoad].ejerciciosRepaso.ejerDesarrollo
						}
					}

				} else {
					// meter los ejer de prueba

					ejerciciosPreLoad.testTeorico = [{
						idTest: 861,
						ranking1: [],
						ranking2: [],
						vuelta: null,
						testJusticiaResultados: []
					}]

					ejerciciosPreLoad.testPractico = [{
						idTest: 106,
						ranking1: [],
						ranking2: [],
						textHtml: "<p>En el Juzgado ____ de ______, se tramita proceso monitorio núm.___, a instancias de D. Luis Ruiz Rey, mayor de edad, y domicilio en Madrid, C/___, núm.___, representado por el Procurador D. Antonio Robles Ruiz, contra D. Horacio Cruz Santisteban, mayor de edad, soltero, y con domicilio en Granada, C/___, núm.___, en reclamación de 29.500 euros, que le adeuda, según documento emitido en Barcelona, en fecha 15 de noviembre de 2.01_, y que aparece firmado por el deudor.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Requiérase al deudor para que efectúe el pago a D. Luis Ruiz, dentro del plazo de un mes.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; En el momento de la notificación del requerimiento de pago, D. Horacio se encuentra en su domicilio, y se niega a atender el requerimiento.</p><p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;El deudor presenta escrito de oposición dentro del plazo legalmente establecido.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dictada sentencia tras el proceso monitorio, se procede en ejecución a efectuar la diligencia de requerimiento de pago, por la cantidad de 32.800 euros, en concepto de principal e intereses, más 1.500, para gastos y costas.</p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Practíquese la diligencia teniendo en cuenta que:</p><p>El deudor es hallado en el domicilio que figura en el título ejecutivo.</p><p>Los bienes sobre los que se puede trabar el embargo son:</p><p>- 50 acciones del Banco Popular, designadas con el número ___ a___, con un valor nominal de 23 euros.</p><p>- Una pulsera de brillantes, valorada en 12.000 euros.</p><p>- Un piso en Motril, C/___, núm.___, inscrito en el Registro de la Propiedad de Motril, en el Tomo___, Libro___, Folio___, número registral___.</p><p>- 2.000 euros en una cuenta corriente.</p><p>- Una nómina de 900 euros mensuales.</p>",
						nombreCasoPractico: "EJECUCION DE PROCEDIMIENTO MONITORIO puesto repaso ejecucion I Y II 26-11-20",
						vuelta: null,
						casoPracticoResultados: []
					}]


					if (this.props.idOposicion == 3) {
						ejerciciosPreLoad.ejerDesarrollo = [{
							idExamenGestion: 20,
							examenExiste: false,
							vuelta: null,
							linkSolucion: "public/upload/files/examenesGestion/pdf/PREGUNTAS CORTAS ejecucion civil II CON RESPUESTAS-,-20-12-03-,-QJtJrzD3.pdf",
							linkExamen: "public/upload/files/examenesGestion/pdf/PREGUNTAS CORTAS ejecucion civil II-,-20-12-03-,-MobQ0av4.pdf",
							linkExaAlumnoDes: null
						}]
					}

					if (ejerciciosPreLoad.testTeorico.length > 0) {
						this.setState({ tipoEjerSelected: 'testTeorico' })
					} else if (ejerciciosPreLoad.testPractico.length > 0) {
						this.setState({ tipoEjerSelected: 'testPractico' })
					} else if (ejerciciosPreLoad.ejerDesarrollo.length > 0) {
						this.setState({ tipoEjerSelected: 'testDesarrollo' })
					} else {
						this.setState({ tipoEjerSelected: null })
					}

				}
				// cargar los ejer en ejerciciosLoad

				if (ejerciciosPreLoad.ejerDesarrollo.length > 0) {
					let data352 = { idUser: this.props.idUser }
					let response352 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data352)
					});
					if (!response352.ok) {
						throw Error(response352.statusText);
					}
					let json352 = await response352.json();
					let cls352 = await json352;

					if (cls352 != undefined && cls352.length > 0) {
						for (let j = 0; j < ejerciciosPreLoad.ejerDesarrollo.length; j++) {

							for (var ñ = 0; ñ < cls352.length; ñ++) {

								if (ejerciciosPreLoad.ejerDesarrollo[j].idExamenGestion == cls352[ñ].idExamenGestion && cls352[ñ].linkArchivoAlumno != null && cls352[ñ].linkArchivoAlumno != '') {
									ejerciciosPreLoad.ejerDesarrollo[j].examenExiste = true
									ejerciciosPreLoad.ejerDesarrollo[j].linkExaAlumnoDes = cls352[ñ].linkArchivoAlumno
									ejerciciosPreLoad.ejerDesarrollo[j].fechaExaEntregaA = cls352[ñ].fecha
									ejerciciosPreLoad.ejerDesarrollo[j].idExpExamDesa = cls352[ñ].id_exp
									ejerciciosPreLoad.ejerDesarrollo[j].linkSolucion = cls352[ñ].linkSolucion
									ejerciciosPreLoad.ejerDesarrollo[j].linkExamen = cls352[ñ].linkExamen

									if (cls352[ñ].puntuacion != null) {
										ejerciciosPreLoad.ejerDesarrollo[j].examenPuntuacion = cls352[ñ].puntuacion
										ejerciciosPreLoad.ejerDesarrollo[j].notaExamenDesarollo = cls352[ñ].anotaciones

									} else {
										ejerciciosPreLoad.ejerDesarrollo[j].examenPuntuacion = null
										ejerciciosPreLoad.ejerDesarrollo[j].notaExamenDesarollo = null
									}

								} else {
									ejerciciosPreLoad.ejerDesarrollo[j].linkExaAlumnoDes = null
								}

							}


						}
					}
				}


				if (ejerciciosPreLoad.testTeorico.length > 0) {
					for (let gh = 0; gh < ejerciciosPreLoad.testTeorico.length; gh++) {

						let data10 = {
							idTest: ejerciciosPreLoad.testTeorico[gh].idTest
						}
						let response10 = await fetch("https://oposiciones-justicia.es/api/temas/testTeoriaTemaLoadHistorial", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data10)
						});
						if (!response10.ok) {
							throw Error(response10.statusText);
						}
						let json10 = await response10.json();
						let cls10 = await json10;

						let filtroExpediente = await cls10.sort((a, b) => b.idExp - a.idExp).filter((object, key) => cls10.findIndex(a => a.idAlumno == object.idAlumno) == key)

						ejerciciosPreLoad.testTeorico[gh].testJusticiaResultados = await filtroExpediente
						ejerciciosPreLoad.testTeorico[gh].ranking1 = await filtroExpediente.filter(t => t.puntuacion >= 20).sort((a, b) => b.puntuacion - a.puntuacion)
						// Filtramos resualtado cogemos el ultima examen de cada alumno:
					}
				}
				if (ejerciciosPreLoad.testPractico.length > 0) {
					for (let gh2 = 0; gh2 < ejerciciosPreLoad.testPractico.length; gh2++) {

						let data11 = {
							idTest: ejerciciosPreLoad.testPractico[gh2].idTest
						}
						let response11 = await fetch("https://oposiciones-justicia.es/api/temas/testPracticoTemaLoadHistorial", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
						});
						if (!response11.ok) {
							throw Error(response11.statusText);
						}
						let json11 = await response11.json();
						let cls11 = await json11;

						let filtroExpediente = await cls11.sort((a, b) => b.idExp - a.idExp).filter((object, key) => cls11.findIndex(a => a.idAlumno == object.idAlumno) == key)

						ejerciciosPreLoad.testPractico[gh2].casoPracticoResultados = await filtroExpediente
						ejerciciosPreLoad.testPractico[gh2].ranking1 = await filtroExpediente.filter(t => t.puntuacion >= 5).sort((a, b) => b.puntuacion - a.puntuacion)
						// Filtramos resualtado cogemos el ultima examen de cada alumno:

					}
				}
				this.setState({ navS: keyValNav, ejerciciosLoad: ejerciciosPreLoad })

			} else {
				this.setState({ navS: keyValNav, ejerciciosLoad: null })
			}
			this.props.loadTemaAula(null)

		} else if (e.target.id == 'closeClaseOpen') {
			this.props.loadTemaAula(null)
		} else if (e.target.id == 'completarVuelta') {

			//obtener vueltas del tema 
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, se completara una vuelta de estudio al tema.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {
						let data33 = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad }

						let response66 = await fetch("https://oposiciones-justicia.es/api/temas/temarioVueltasLoad", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
						});
						if (!response66.ok) {
							throw Error(response66.statusText);
						}
						let json66 = await response66.json();
						let cls66 = await json66;

						let vueltasTemario = cls66

						if (vueltasTemario.length > 0 && vueltasTemario.find(v => v.id_tema == this.props.temaAulaLoad) != undefined) {
							let numVuelta = vueltasTemario.find(v => v.id_tema == this.props.temaAulaLoad).num_vuelta + 1

							let data = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad, numVuelta: numVuelta }

							let response = await fetch("https://oposiciones-justicia.es/api/usuarios/completarVuelta", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;
							// await swal({
							// 	title: 'Vuelta completada',
							// 	text: '¡Recargando!',
							// 	icon: "success",
							// 	closeOnEsc: false,
							// 	closeOnClickOutside: false,
							// 	timer: 1000,
							// }).then(() => {
							// 	window.location.reload()
							// })
							let data234 = { idUser: this.props.idUser }

							let response234 = await fetch("https://oposiciones-justicia.es/api/usuarios/numeroVueltasTemas", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data234)
							});
							if (!response234.ok) {
								throw Error(response234.statusText);
							}
							let json234 = await response234.json();
							let cls234 = await json234;
							this.setState({ numVueltasTemas: cls234 })
							await swal('Vuelta completada', '', 'success')
						} else {
							let numVuelta = 2

							let data = { idUser: this.props.idUser, idTema: this.props.temaAulaLoad, numVuelta: numVuelta }

							let response = await fetch("https://oposiciones-justicia.es/api/usuarios/completarVuelta1", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							// await swal({
							// 	title: 'Vuelta completada',
							// 	text: '¡Recargando!',
							// 	icon: "success",
							// 	closeOnEsc: false,
							// 	closeOnClickOutside: false,
							// 	timer: 1000,
							// }).then(() => {
							// 	window.location.reload()
							// })
							let data234 = { idUser: this.props.idUser }

							let response234 = await fetch("https://oposiciones-justicia.es/api/usuarios/numeroVueltasTemas", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data234)
							});
							if (!response234.ok) {
								throw Error(response234.statusText);
							}
							let json234 = await response234.json();
							let cls234 = await json234;
							this.setState({ numVueltasTemas: cls234 })
							await swal('Vuelta completada', '', 'success')

						}
					} else {
						swal("¡Vuelta no añadida!", '', "success");
					}
				});



		} else if (e.target.id == 'verPlanEstudio') {
			const { semanasPrgCurso } = this.props

			let planEstudio = []



			for (let i = 0; i < semanasPrgCurso.length; i++) {

				let existeMes = planEstudio.findIndex(m => m.mesYear == moment(semanasPrgCurso[i].fechaInicio).format('MM-YY'))
				if (existeMes != -1) {
					planEstudio[existeMes].programacion.push(
						semanasPrgCurso[i]
					)
				} else {
					planEstudio.push({
						fecha: moment(semanasPrgCurso[i].fechaInicio).format('YYYY-MM-01'),
						mesYear: moment(semanasPrgCurso[i].fechaInicio).format('MM-YY'),
						programacion: []
					})

					planEstudio[planEstudio.length - 1].programacion.push(
						semanasPrgCurso[i]
					)
				}

			}


			this.setState({ planEstudio: planEstudio })


		} else if (e.target.id == 'cerrarPlanEstudio') {
			this.setState({ planEstudio: null })
		} else if (e.target.id.split('-')[0] == 'verSemPlan') {
			let key = e.target.id.split('-')[1]
			$('#containerSemPlan-' + key).slideToggle(1000)
			if ($("#containerSemPlan-" + key).css("display") == 'block') {
				$("#containerSemPlan-" + key).css("display", "flex")
			}

		}
	}
	async onChangeBuscador(e) {
		// cambiar temaLoad! por semanasPrgCurso[numSemLoad].ejerciciosRepaso o ejerciciosPrincipales segun tipoOpen 
		if (e.target.id == 'tipoEjerLoadTeorico') {
			this.setState({ tipoEjerSelected: 'testTeorico', testSelected: 0 })

		} else if (e.target.id == 'tipoEjerLoadPractic') {
			this.setState({ tipoEjerSelected: 'testPractico', testSelected: 0 })

		} else if (e.target.id == 'tipoEjerLoadDesarrollo') {
			this.setState({ tipoEjerSelected: 'testDesarrollo', testSelected: 0 })

		} else if (e.target.id == 'verEjer') {


			let val = !this.state.ejerciciosVer

			$('.backGrodEjerClaseBloq').slideToggle(1000);
			setTimeout(
				function () {
					$('.SSFC-B-TL-bloqueNn2').css("visibility", val == true ? ("unset") : ("hidden"));
					$('.SSFC-B-TL-navEjeerNe').css("visibility", val == true ? ("unset") : ("hidden"));
					$('.SSFC-B-TL-ejerLoad').css("visibility", val == true ? ("unset") : ("hidden"));
				}.bind(this), 1010);
			this.setState({ ejerciciosVer: !val })

		} else if (e.target.id == 'realizarTestJus') {


			try {


				let locaTest = this.state.testSelected

				let idTest = await this.state.ejerciciosLoad.testTeorico[locaTest].idTest
				let idTema = null
				let idBloque = null
				let idClase = this.props.idClase
				let idPrg = null

				let numBloque = null
				let numTestBloque = null


				let testConPreguntas = await selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema) // idTema añdirlo y pasar si otros null se hace solo idTema pasarlo y añadirlo a todas
				let testConRespuestas = await comprobarRespuestas(await testConPreguntas)
				if (this.props.testJusticiaUserLoad.length > 0) {
					//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
					let name = "¡Ojo! - Test sin finalizar";
					let content = document.createElement('div');
					content.innerHTML = `
				 			 	Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  				Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  				`
					content.style.textAlign = "left";

					swal({
						title: name,
						content: content,
						icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
						buttons: {
							cancel: "Cargar el nuevo Test",
							catch: {
								text: "Ir a finalizar test",
								value: "catch",
							},
							defeat: false,
						},
						closeOnEsc: false,
						closeOnClickOutside: false,
					}).then(async (value) => {
						switch (value) {

							case "catch":
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
								break;

							default:
								await this.props.borrarTestUser()
								await this.props.loadTestUser(testConRespuestas)
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
						}
					});
				} else {
					await this.props.loadTestUser(testConRespuestas)
					// await this.props.history.push('/hacerTestJusticia');
					window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
				}
			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'repetirTestJus') {

			try {


				let locaTest = this.state.testSelected

				let idTest = await this.state.ejerciciosLoad.testTeorico[locaTest].idTest
				let idTema = null
				let idBloque = null
				let idClase = this.props.idClase
				let idPrg = null

				let numBloque = null
				let numTestBloque = null

				let testConRespuestas = await selectPreguntasPorIdTest(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
				let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))

				if (this.props.testJusticiaUserLoad.length > 0) {
					//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
					let name = "¡Ojo! - Test sin finalizar";
					let content = document.createElement('div');
					content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
					content.style.textAlign = "left";

					swal({
						title: name,
						content: content,
						icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
						buttons: {
							cancel: "Cargar el nuevo Test",
							catch: {
								text: "Ir a finalizar test",
								value: "catch",
							},
							defeat: false,
						},
						closeOnEsc: false,
						closeOnClickOutside: false,
					}).then(async (value) => {
						switch (value) {

							case "catch":
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
								break;

							default:
								await this.props.borrarTestUser()
								await this.props.loadTestUser(testPregResMezcla)
								// await this.props.history.push('/hacerTestJusticia');
								window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
						}
					});
				} else {
					await this.props.loadTestUser(testPregResMezcla)
					// await this.props.history.push('/hacerTestJusticia');
					window.location.href = 'https://oposiciones-justicia.es/hacerTestJusticia'
				}
			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'ejerToLoad') {
			let value = e.target.id.split('-')[1]
			this.setState({ testSelected: value })
		} else if (e.target.id.split('-')[0] == 'realizarCasoPracticoBloque') {


			let locaTest = this.state.testSelected
			let idTest = await this.state.ejerciciosLoad.testPractico[locaTest].idTest
			let idTema = null

			let idBloque = null
			let idClase = this.props.idClase
			let idPrg = null
			let numBloque = null
			let numTestBloque = null

			let testConPreguntas = await selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
			let testConRespuestas = await comprobarRespuestas(await testConPreguntas)

			if (this.props.casoPracticoUserLoad.length > 0) {
				//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
				let name = "¡Ojo! - Test sin finalizar";
				let content = document.createElement('div');
				content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
				content.style.textAlign = "left";

				swal({
					title: name,
					content: content,
					icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
					buttons: {
						cancel: "Cargar nuevo Test",
						catch: {
							text: "Ir a finalizar test",
							value: "catch",
						},
						defeat: false,
					},
					closeOnEsc: false,
					closeOnClickOutside: false,
				}).then(async (value) => {
					switch (value) {

						case "catch":
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
							break;

						default:
							await this.props.borrarCP()
							await this.props.loadCPUser(testConRespuestas, this.state.ejerciciosLoad.testPractico[locaTest].textHtml)
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
					}
				});
			} else {
				await this.props.loadCPUser(testConRespuestas, this.state.ejerciciosLoad.testPractico[locaTest].textHtml)
				// await this.props.history.push('/casoPracticoJusticia');
				window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
			}


		} else if (e.target.id.split('-')[0] == 'repetirCasoPracticoBloque') {

			let locaTest = this.state.testSelected
			let idTest = await this.state.ejerciciosLoad.testPractico[locaTest].idTest
			let idTema = null

			let idBloque = null
			let idClase = this.props.idClase
			let idPrg = null
			let numBloque = null
			let numTestBloque = null

			let testConRespuestas = await selectPreguntasPorIdTestCP(idTest, idClase, idPrg, idBloque, numBloque, numTestBloque, idTema)
			let testPregResMezcla = await comprobarRespuestas(await mezclarRespuestasJusticia(testConRespuestas))

			if (this.props.casoPracticoUserLoad.length > 0) {
				//Tiene un test en memoria sin finalizar puede borrarlo o finalizarlo.
				let name = "¡Ojo! - Test sin finalizar";
				let content = document.createElement('div');
				content.innerHTML = `
				  Si <b>borras el test, ¡perderás el resultado!</b> y se cargará el nuevo test. <br />
				  Si das clic en Ir a finalizar test, visualizarás el test y podrás guardar el resultado.
				  `
				content.style.textAlign = "left";

				swal({
					title: name,
					content: content,
					icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
					buttons: {
						cancel: "Cargar nuevo Test",
						catch: {
							text: "Ir a finalizar test",
							value: "catch",
						},
						defeat: false,
					},
					closeOnEsc: false,
					closeOnClickOutside: false,
				}).then(async (value) => {
					switch (value) {

						case "catch":
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
							break;

						default:
							await this.props.borrarCP()
							await this.props.loadCPUser(testPregResMezcla, this.state.ejerciciosLoad.testPractico[locaTest].textHtml)
							// await this.props.history.push('/casoPracticoJusticia');
							window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
					}
				});
			} else {
				await this.props.loadCPUser(testPregResMezcla, this.state.ejerciciosLoad.testPractico[locaTest].textHtml)
				// await this.props.history.push('/casoPracticoJusticia');
				window.location.href = 'https://oposiciones-justicia.es/casoPracticoJusticia'
			}

		} else if (e.target.id == 'descargarPdfExaGes') {

			let pathFile = this.state.ejerciciosLoad.ejerDesarrollo[this.state.testSelected].linkExamen
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}


		} else if (e.target.id == 'descargarPdfExaGesSolucion') {

			let pathFile = this.state.ejerciciosLoad.ejerDesarrollo[this.state.testSelected].linkSolucion
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}

		} else if (e.target.id == 'descargarPdfExaGesAlumn') {

			let pathFile = this.state.ejerciciosLoad.ejerDesarrollo[this.state.testSelected].linkExaAlumnoDes
			if (pathFile != undefined) {
				swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
				let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
					nameFile = nameFile + '.pdf'
				}
				fetch(url, {
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
					responseType: 'blob'
				}).then(res => {
					if (res.status >= 400) { throw new Error("Bad res from server"); }
					return res.blob();
				}).then(blob => {
					saveAs(blob, nameFile);
				}).catch(function (err) {
					console.log(err)
					alert("Link no valido para descarga. Reportelo, gracias.")
				});
			}

		} else if (e.target.id == 'puntuarMiExamen') {
			let { notaAlumnoAuto, testSelected } = this.state


			let textHtmlMensajeExamen = 'Examen auto-evaluado'
			if (textHtmlMensajeExamen != null && textHtmlMensajeExamen != '' && notaAlumnoAuto != null) {

				let data1 = {
					alumnSelectExam: {
						anotaciones: textHtmlMensajeExamen,
						puntuacion: notaAlumnoAuto,
						id_exp: this.state.ejerciciosLoad.ejerDesarrollo[0].idExpExamDesa
					}
				}

				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/corregirExamGes", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				await swal({
					title: 'Examen auto-evaluado',
					text: '¡Recargando web!',
					icon: "success",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
					timer: 2500,
				}).then(() => {
					window.location.reload()
				})



			} else {
				swal('Error falta puntuación', 'Indica la puntuacion con una coma "Ejemplo: 8,25 ó 8,00" no con punto.', 'error')
			}
		} else if (e.target.id == 'puntuacionExamenGestion') {
			this.setState({ notaAlumnoAuto: e.target.value })
		}

	}

	componentDidUpdate() {
		if (this.state.temaPrueba != null && this.props.temasFacturados.length > 0 && this.props.temasFacturados.length > 1) {
			this.setState({ temaPrueba: null })
		}
	}

	async componentDidMount() {

		// calcular la semana actual y poner en numSemLoad !
		let data = { idUser: this.props.idUser }

		let response = await fetch("https://oposiciones-justicia.es/api/usuarios/numeroVueltasTemas", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
		});
		if (!response.ok) {
			throw Error(response.statusText);
		}
		let json = await response.json();
		let cls = await json;
		this.setState({ numVueltasTemas: cls })

		if (this.props.temasFacturados.length > 0 && this.props.temasFacturados.length == 1) {
			let temaP = {
				idTema: this.props.temasFacturados[0].idTema,
				nombreTema: this.props.temasFacturados[0].titulo_tema,
				tipoBloque: 0,
				tituloTema: this.props.temasFacturados[0].nombre_tema
			}
			this.setState({ temaPrueba: temaP })
		} else {
			this.setState({ temaPrueba: null })
		}


		let ahora = moment().format('YYYY-MM-DD')
		let semanasPrgCurso = this.props.semanasPrgCurso

		let semanaActuales = await semanasPrgCurso.filter(a => a.fechaInicio <= ahora).sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio))

		let indexDeLaSemana = await semanasPrgCurso.findIndex(b => b.numSemana == semanaActuales[0].numSemana)

		if (indexDeLaSemana != -1 && indexDeLaSemana >= 0 && indexDeLaSemana <= semanasPrgCurso.length - 1) {
			this.setState({ numSemLoad: indexDeLaSemana })
		} else {
			this.setState({ numSemLoad: 0 })
		}


	}

	render() {
		const { semanasPrgCurso, temaAulaLoad, temasFacturados } = this.props;

		const { tipoOpen, numSemLoad, navS, tipoEjerSelected, testSelected, notaAlumnoAuto, ejerciciosLoad, numVueltasTemas, planEstudio, temaPrueba } = this.state

		return (
			semanasPrgCurso != null && semanasPrgCurso.length > 0 && temasFacturados && temasFacturados.length > 0 ? (
				<div className='bodySemPrgCurso'>
					{
						temasFacturados && temasFacturados.length > 1 ? (
							<div className='RowCenterCenter' style={{ marginBottom: '25px' }}>
								{planEstudio != null ? (
									<Boton id="cerrarPlanEstudio" icon1="" icon2="" texto="Cerrar planificación" onClick={this.onHandleClick} />
								) : (
									<Boton id="verPlanEstudio" icon1="" icon2="" texto="Ver planificación" onClick={this.onHandleClick} />
								)}
							</div>
						) : null

					}

					{
						temasFacturados && temasFacturados.length == 1 ? (
							<div>
								{
									planEstudio == null ? (
										<div className='bodySemPrgCurso-navSem' style={{ justifyContent: 'center' }}>


											<div className='bodySemPrgCurso-navSem-infoSEMload'>
												<div style={{ color: '#3469b7', fontWeight: '500' }}>
													SEMANA DE PRUEBA
												</div>
												<div>
													{moment(semanasPrgCurso[0].fechaInicio).format('DD-MM-YY')}
												</div>
											</div>

										</div>
									) : null
								}

								{
									planEstudio == null ? (
										<div className='bodySemPrgCurso-contentSemLoad'>
											<div className='bodySemPrgCurso-contentSemLoad-tipo'>
												<div className='tipoTemaRepasoNormal' id="modoNormal" onClick={this.onHandleClick} style={{ color: '#3469b7' }}>
													Principal
												</div>
											</div>

											<div className='ColCenterStart adb-nav'>
												<div className='RowCenterCenter' style={{ margin: '10px' }}>
													<div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-0' onClick={this.onHandleClick}>
														Temas
													</div>
													<div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-1' onClick={this.onHandleClick}>
														Ejercicios
													</div>
												</div>
											</div>
											{
												navS == 0 ? (
													<div className='bodySemPrgCurso-contentSemLoad-semLoad'>
														{temaAulaLoad == null && temaPrueba != null ? (


															<CajaInfoTema tema={temaPrueba} />


														) : null}

														{temaAulaLoad != null ? (
															<div>

																<div style={{ marginTop: '10px', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '10px 12px' }}>

																	<Boton id="closeClaseOpen" icon1="" icon2="" texto="Cambiar tema" onClick={this.onHandleClick} />
																	<div style={{ padding: '10px', color: '#3469b7' }}>
																		<b>Vuelta
																			{numVueltasTemas && numVueltasTemas.length > 0 && numVueltasTemas.find(t => t.id_tema == temaAulaLoad) != undefined ? (
																				' nº ' + numVueltasTemas.find(t => t.id_tema == temaAulaLoad).num_vuelta
																			) : (': 1')}
																		</b>
																	</div>
																	{/* <Boton id="completarVuelta" icon1="" icon2="" texto="Completar vuelta" onClick={this.onHandleClick} /> */}

																</div>
																<TemaSeleted idTema={temaAulaLoad} />

																{/* {temasFacturados && temasFacturados.length > 0 && temasFacturados.find(t => t.idTema == temaAulaLoad) != undefined && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != null && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != '' ? (

																	<div className="SSFC-B-TL-notaTema">
																		<b>Nota del preparador</b>
																		<div className="SSFC-B-TL-notaTemaTexto">
																			{

																				temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != null && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != '' ? (
																					temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador
																				) : (null)
																			}

																		</div>
																	</div>
																) : null} */}
															</div>
														) : null}
													</div>
												) : null
											}

											{
												navS == 1 ? (
													<div className='bodySemPrgCurso-contentSemLoad-semLoad'>
														<div className="SSFC-B-ejerLoad">

															<div className="SSFC-B-TL-bloqueNn2" style={{ visibility: 'unset' }}>


																{ejerciciosLoad.testTeorico.length > 0 ? (
																	<div id={'tipoEjerLoadTeorico'}
																		className={tipoEjerSelected == 'testTeorico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																		onClick={this.onChangeBuscador}
																		style={{ borderLeft: 'none' }}
																	>
																		Test Teórico
																	</div>
																) : null}

																{ejerciciosLoad.testPractico.length > 0 ? (
																	<div id={'tipoEjerLoadPractic'}
																		className={tipoEjerSelected == 'testPractico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																		onClick={this.onChangeBuscador}
																		style={{ borderLeft: ejerciciosLoad.testTeorico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
																	>
																		Test Practico
																	</div>
																) : null}

																{ejerciciosLoad.ejerDesarrollo.length > 0 ? (
																	<div id={'tipoEjerLoadDesarrollo'}
																		className={tipoEjerSelected == 'testDesarrollo' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																		onClick={this.onChangeBuscador}
																		style={{ borderLeft: ejerciciosLoad.testTeorico.length > 0 || ejerciciosLoad.testPractico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
																	>
																		Examen Desarrollo
																	</div>
																) : null}




															</div>


															<div className="SSFC-B-TL-navEjeerNe" style={{ visibility: 'unset' }}>



																{tipoEjerSelected == 'testTeorico' ? (
																	ejerciciosLoad.testTeorico.map((test, key10) =>
																		<div id={'ejerToLoad-' + key10}
																			className={testSelected == key10 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																			onClick={this.onChangeBuscador}
																			style={{ borderLeft: key10 > 0 ? ('1px solid #f1f1f1') : ('none') }}
																		>
																			Test {key10 + 1}
																		</div>
																	)
																) : null
																}

																{tipoEjerSelected == 'testPractico' ? (
																	ejerciciosLoad.testPractico.map((test, key11) =>
																		<div id={'ejerToLoad-' + key11}
																			className={testSelected == key11 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																			onClick={this.onChangeBuscador}
																			style={{ borderLeft: key11 > 0 ? ('1px solid #f1f1f1') : ('none') }}
																		>
																			Test {key11 + 1}
																		</div>
																	)
																) : null
																}

																{/* {tipoEjerSelected == 'testDesarrollo' ? (
																	<div id={''}
																		className={"SSFC-B-TL-ButonNavS"}
																		onClick={this.onChangeBuscador}
																		style={{ borderLeft: 'none' }}
																	>
																		Examen Desarrollo
																	</div>

																) : null
																} */}
																{tipoEjerSelected == 'testDesarrollo' ? (
																	ejerciciosLoad.ejerDesarrollo.map((test, key11x) =>
																		<div id={'ejerToLoad-' + key11x}
																			className={testSelected == key11x ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																			onClick={this.onChangeBuscador}
																			style={{ borderLeft: 'none' }}
																		>
																			Ejercicio {key11x + 1}
																		</div>
																	)
																) : null
																}






															</div>



															<div className="SSFC-B-TL-ejerLoad" style={{ visibility: 'unset' }}>
																{tipoEjerSelected == null ? (
																	<h3 style={{ color: 'Black' }}> Seleccione el tipo de ejercicio que desea realizar </h3>

																) : null}
																{/* semanasPrgCurso[numSemLoad].ejerciciosPrincipales */}
																{isDefined(testSelected) && tipoEjerSelected == 'testTeorico' ? (

																	ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser) != undefined

																		? (
																			<React.Fragment>
																				<div className="SSFC-B-TL-eL-testInfo">
																					<div className="SSFC-B-TL-eL-testInfoResultado">
																						<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																						<div className="SSFC-B-TL-eL-puntos">
																							{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																						</div>

																						<div className="SSFC-B-TL-eL-resultados">
																							{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																						</div>
																						<div className="SSFC-B-TL-eL-resultados">
																							{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																						</div>
																						<div className="SSFC-B-TL-eL-resultados">
																							{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																						</div>
																					</div>
																					<div>
																						<div className="SSFC-B-TL-eL-boton">
																							<Boton id="repetirTestJus" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																						</div>
																					</div>

																				</div>

																				<div className="SSFC-B-TL-eL-Ranking">
																					{isDefined(ejerciciosLoad.testTeorico[testSelected].ranking1) && ejerciciosLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																						<Ranking rank={ejerciciosLoad.testTeorico[testSelected].ranking1} />
																					) : (
																						<img src="../img/noRanking.jpg" />
																					)}
																				</div>

																			</React.Fragment>
																		) : (
																			<React.Fragment>
																				<div className="SSFC-B-TL-eL-testInfo">
																					<div className="SSFC-B-TL-eL-boton">
																						<Boton id="realizarTestJus" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																					</div>
																				</div>

																				<div className="SSFC-B-TL-eL-Ranking">
																					{isDefined(ejerciciosLoad.testTeorico[testSelected].ranking1) && ejerciciosLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																						<Ranking rank={ejerciciosLoad.testTeorico[testSelected].ranking1} />
																					) : (
																						<img src="../img/noRanking.jpg" />
																					)}
																				</div>

																			</React.Fragment>
																		)
																) : null
																}


																{isDefined(testSelected) && tipoEjerSelected == 'testPractico' ? (

																	ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser) != undefined ? (
																		<React.Fragment>
																			<div className="SSFC-B-TL-eL-testInfo">
																				<div className="SSFC-B-TL-eL-testInfoResultado">
																					<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																					<div className="SSFC-B-TL-eL-puntos">
																						{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																					</div>

																					<div className="SSFC-B-TL-eL-resultados">
																						{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																					</div>
																					<div className="SSFC-B-TL-eL-resultados">
																						{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																					</div>
																					<div className="SSFC-B-TL-eL-resultados">
																						{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																					</div>
																				</div>
																				<div>
																					<div className="SSFC-B-TL-eL-boton">
																						<Boton id="repetirCasoPracticoBloque" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																					</div>
																				</div>

																			</div>

																			<div className="SSFC-B-TL-eL-Ranking">
																				{isDefined(ejerciciosLoad.testPractico[testSelected].ranking1) && ejerciciosLoad.testPractico[testSelected].ranking1.length > 0 ? (
																					<Ranking rank={ejerciciosLoad.testPractico[testSelected].ranking1} />
																				) : (
																					<img src="../img/noRanking.jpg" />
																				)}
																			</div>

																		</React.Fragment>
																	) : (
																		<React.Fragment>
																			<div className="SSFC-B-TL-eL-testInfo">
																				<div className="SSFC-B-TL-eL-boton">
																					<Boton id="realizarCasoPracticoBloque" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																				</div>
																			</div>

																			<div className="SSFC-B-TL-eL-Ranking">
																				{isDefined(ejerciciosLoad.testPractico[testSelected].ranking1) && ejerciciosLoad.testPractico[testSelected].ranking1.length > 0 ? (
																					<Ranking rank={ejerciciosLoad.testPractico[testSelected].ranking1} />
																				) : (
																					<img src="../img/noRanking.jpg" />
																				)}
																			</div>

																		</React.Fragment>
																	)
																) : null

																}

																{isDefined(testSelected) && tipoEjerSelected == 'testDesarrollo' ? (

																	ejerciciosLoad.ejerDesarrollo[testSelected].examenExiste != false ? (


																		<React.Fragment>
																			<div className="SSFC-B-TL-eL-testInfo">

																				<div className="SSFC-B-TL-eL-testInfoResultado">
																					<div className="SSFC-B-TL-eL-title" style={{
																						marginRight: '0px',
																						alignItems: 'center',
																						justifyContent: 'center',
																						display: 'flex',
																						flexFlow: 'column nowrap'
																					}}>
																						<b style={{ fontWeight: '500' }}>Auto-Corrección</b>


																					</div>

																					<div className="SSFC-B-TL-eL-boton2">
																						<Boton id="descargarPdfExaGes" icon1="" icon2="" texto="Descargar examen" onClick={this.onChangeBuscador} />
																					</div>


																				</div>


																				<div className="SSFC-B-TL-eL-testInfoResultado">
																					<div className="SSFC-B-TL-eL-boton2">
																						<Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.onChangeBuscador} />
																					</div>
																					<div className="SSFC-B-TL-eL-boton2">
																						<Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.onChangeBuscador} />
																					</div>
																				</div>

																			</div>
																			<div className="SSFC-B-TL-eL-Ranking">

																				<div className="SSFC-B-TL-eL-notaPrepa">
																					Puede descargar la solución, modo auto-evaluación.
																				</div>

																				<img src="../img/examDesarrolloCompleted.jpg" />
																			</div>
																		</React.Fragment>




																	) : (
																		<React.Fragment>
																			<div className="SSFC-B-TL-eL-testInfo">
																				<div className="SSFC-B-TL-eL-testInfoResultado">
																					<div className="SSFC-B-TL-eL-title"> Subir examen en .pdf </div>

																				</div>
																				<div className="SSFC-B-TL-eL-testInfoResultado">
																					<div className="SSFC-B-TL-eL-boton2">
																						<Boton id="descargarPdfExaGes" icon1="" icon2="" texto="Descargar examen" onClick={this.onChangeBuscador} />
																					</div>
																					<div className="SSFC-B-TL-eL-boton2">

																						<InputFileExamDesa onChange={this.myFiles} name="examGest" texto="Subir examen" />
																					</div>
																				</div>

																			</div>

																			<div className="SSFC-B-TL-eL-Ranking">
																				<img src="../img/examDesarrollo.jpg" />
																			</div>

																		</React.Fragment>
																	)
																) : null

																}
															</div>


														</div>
													</div>
												) : null
											}

										</div>
									) : null
								}
							</div>
						) : null
					}
					{
						temasFacturados && temasFacturados.length > 1 && planEstudio == null ? (
							<div className='bodySemPrgCurso-navSem'>

								<i className="fa-thin fa-angle-left" id="retroceder" onClick={this.onHandleClick}></i>
								<div className='bodySemPrgCurso-navSem-infoSEMload'>
									<div style={{ color: '#3469b7', fontWeight: '500' }}>
										SEMANA {semanasPrgCurso[numSemLoad].numSemana}
									</div>
									<div>
										{moment(semanasPrgCurso[numSemLoad].fechaInicio).format('DD-MM-YY')}
									</div>
								</div>
								<i className="fa-thin fa-angle-right" id="avanzar" onClick={this.onHandleClick}></i>
							</div>
						) : null
					}



					{
						temasFacturados && temasFacturados.length > 1 && planEstudio == null ? (
							<div className='bodySemPrgCurso-contentSemLoad'>
								<div className='bodySemPrgCurso-contentSemLoad-tipo'>
									<div className='tipoTemaRepasoNormal' id="modoNormal" onClick={this.onHandleClick} style={
										tipoOpen == 'Principal' ? (
											semanasPrgCurso[numSemLoad].temasRepaso.length > 0 ? (
												{ borderRight: '2px solid grey', color: '#3469b7' }
											) : ({ color: '#3469b7' })

										) : (
											semanasPrgCurso[numSemLoad].temasRepaso.length > 0 ? (
												{ borderRight: '2px solid grey' }
											) : ({})
										)}
									>
										Principal
									</div>
									{semanasPrgCurso[numSemLoad].temasRepaso.length > 0 ? (
										<div className='tipoTemaRepasoNormal' id="modoRepaso" onClick={this.onHandleClick} style={tipoOpen == 'Repaso' ? ({ color: '#3469b7' }) : ({})}>
											Repaso
										</div>
									) : null}

								</div>

								<div className='ColCenterStart adb-nav'>
									<div className='RowCenterCenter' style={{ margin: '10px' }}>
										<div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-0' onClick={this.onHandleClick}>
											Temas
										</div>
										<div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-1' onClick={this.onHandleClick}>
											Ejercicios
										</div>
									</div>
								</div>
								{
									navS == 0 ? (
										<div className='bodySemPrgCurso-contentSemLoad-semLoad'>
											{temaAulaLoad == null ? (


												tipoOpen == 'Principal' ? (
													semanasPrgCurso[numSemLoad].temasPrincipales.map((tema, key) =>
														<CajaInfoTema tema={tema} key={key} />
													)
												) : (
													semanasPrgCurso[numSemLoad].temasRepaso.length > 0 ? (
														semanasPrgCurso[numSemLoad].temasRepaso.map((tema, key) =>
															<CajaInfoTema tema={tema} key={key} />
														)
													) : (
														'Esta semana no contiene repaso'
													)

												)
											) : null}

											{temaAulaLoad != null ? (
												<div>

													<div style={{ marginTop: '10px', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '10px 12px' }}>

														<Boton id="closeClaseOpen" icon1="" icon2="" texto="Cambiar tema" onClick={this.onHandleClick} />
														<div style={{ padding: '10px', color: '#3469b7' }}>
															<b>Vuelta
																{numVueltasTemas && numVueltasTemas.length > 0 && numVueltasTemas.find(t => t.id_tema == temaAulaLoad) != undefined ? (
																	' nº ' + numVueltasTemas.find(t => t.id_tema == temaAulaLoad).num_vuelta
																) : (': 1')}
															</b>
														</div>
														<Boton id="completarVuelta" icon1="" icon2="" texto="Completar vuelta" onClick={this.onHandleClick} />

													</div>
													<TemaSeleted idTema={temaAulaLoad} />
													{/* {temasFacturados && temasFacturados.length > 0 && temasFacturados.find(t => t.idTema == temaAulaLoad) != undefined && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != null && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != '' ? (

														<div className="SSFC-B-TL-notaTema">
															<b>Nota del preparador</b>
															<div className="SSFC-B-TL-notaTemaTexto">
																{

																	temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != null && temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador != '' ? (
																		temasFacturados.find(t => t.idTema == temaAulaLoad).notaPreparador
																	) : (null)
																}

															</div>
														</div>
													) : null} */}
												</div>
											) : null}
										</div>
									) : null
								}

								{
									navS == 1 ? (
										<div className='bodySemPrgCurso-contentSemLoad-semLoad'>
											<div className="SSFC-B-ejerLoad">

												<div className="SSFC-B-TL-bloqueNn2" style={{ visibility: 'unset' }}>




													{ejerciciosLoad.testTeorico.length > 0 ? (
														<div id={'tipoEjerLoadTeorico'}
															className={tipoEjerSelected == 'testTeorico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: 'none' }}
														>
															Test Teórico
														</div>
													) : null}

													{ejerciciosLoad.testPractico.length > 0 ? (
														<div id={'tipoEjerLoadPractic'}
															className={tipoEjerSelected == 'testPractico' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: ejerciciosLoad.testTeorico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
														>
															Test Practico
														</div>
													) : null}

													{ejerciciosLoad.ejerDesarrollo.length > 0 ? (
														<div id={'tipoEjerLoadDesarrollo'}
															className={tipoEjerSelected == 'testDesarrollo' ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: ejerciciosLoad.testTeorico.length > 0 || ejerciciosLoad.testPractico.length > 0 ? ('1px solid #f1f1f1') : ('none') }}
														>
															Examen Desarrollo
														</div>
													) : null}




												</div>


												<div className="SSFC-B-TL-navEjeerNe" style={{ visibility: 'unset' }}>



													{tipoEjerSelected == 'testTeorico' ? (
														ejerciciosLoad.testTeorico.map((test, key10) =>
															<div id={'ejerToLoad-' + key10}
																className={testSelected == key10 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																onClick={this.onChangeBuscador}
																style={{ borderLeft: key10 > 0 ? ('1px solid #f1f1f1') : ('none') }}
															>
																Test {key10 + 1}
															</div>
														)
													) : null
													}

													{tipoEjerSelected == 'testPractico' ? (
														ejerciciosLoad.testPractico.map((test, key11) =>
															<div id={'ejerToLoad-' + key11}
																className={testSelected == key11 ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																onClick={this.onChangeBuscador}
																style={{ borderLeft: key11 > 0 ? ('1px solid #f1f1f1') : ('none') }}
															>
																Test {key11 + 1}
															</div>
														)
													) : null
													}

													{/* {tipoEjerSelected == 'testDesarrollo' ? (
														<div id={''}
															className={"SSFC-B-TL-ButonNavS"}
															onClick={this.onChangeBuscador}
															style={{ borderLeft: 'none' }}
														>
															Examen Desarrollo
														</div>

													) : null
													} */}

													{tipoEjerSelected == 'testDesarrollo' ? (
														ejerciciosLoad.ejerDesarrollo.map((test, key11x) =>
															<div id={'ejerToLoad-' + key11x}
																className={testSelected == key11x ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
																onClick={this.onChangeBuscador}
																style={{ borderLeft: 'none' }}
															>
																Ejercicio {key11x + 1}
															</div>
														)
													) : null
													}




												</div>



												<div className="SSFC-B-TL-ejerLoad" style={{ visibility: 'unset' }}>
													{tipoEjerSelected == null ? (
														<h3 style={{ color: 'Black' }}> Seleccione el tipo de ejercicio que desea realizar </h3>

													) : null}
													{/* semanasPrgCurso[numSemLoad].ejerciciosPrincipales */}
													{isDefined(testSelected) && tipoEjerSelected == 'testTeorico' ? (

														ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser) != undefined

															? (
																<React.Fragment>
																	<div className="SSFC-B-TL-eL-testInfo">
																		<div className="SSFC-B-TL-eL-testInfoResultado">
																			<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																			<div className="SSFC-B-TL-eL-puntos">
																				{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																			</div>

																			<div className="SSFC-B-TL-eL-resultados">
																				{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																			</div>
																			<div className="SSFC-B-TL-eL-resultados">
																				{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																			</div>
																			<div className="SSFC-B-TL-eL-resultados">
																				{ejerciciosLoad.testTeorico[testSelected].testJusticiaResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																			</div>
																		</div>
																		<div>
																			<div className="SSFC-B-TL-eL-boton">
																				<Boton id="repetirTestJus" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																			</div>
																		</div>

																	</div>

																	<div className="SSFC-B-TL-eL-Ranking">
																		{isDefined(ejerciciosLoad.testTeorico[testSelected].ranking1) && ejerciciosLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																			<Ranking rank={ejerciciosLoad.testTeorico[testSelected].ranking1} />
																		) : (
																			<img src="../img/noRanking.jpg" />
																		)}
																	</div>

																</React.Fragment>
															) : (
																<React.Fragment>
																	<div className="SSFC-B-TL-eL-testInfo">
																		<div className="SSFC-B-TL-eL-boton">
																			<Boton id="realizarTestJus" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																	<div className="SSFC-B-TL-eL-Ranking">
																		{isDefined(ejerciciosLoad.testTeorico[testSelected].ranking1) && ejerciciosLoad.testTeorico[testSelected].ranking1.length > 0 ? (
																			<Ranking rank={ejerciciosLoad.testTeorico[testSelected].ranking1} />
																		) : (
																			<img src="../img/noRanking.jpg" />
																		)}
																	</div>

																</React.Fragment>
															)
													) : null
													}


													{isDefined(testSelected) && tipoEjerSelected == 'testPractico' ? (

														ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser) != undefined ? (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title"> Ultimo Resultado </div>
																		<div className="SSFC-B-TL-eL-puntos">
																			{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).puntuacion}
																		</div>

																		<div className="SSFC-B-TL-eL-resultados">
																			{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).correctas} <i className="fas fa-check-circle" />
																		</div>
																		<div className="SSFC-B-TL-eL-resultados">
																			{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).erroneas} <i className="fas fa-times-circle" />
																		</div>
																		<div className="SSFC-B-TL-eL-resultados">
																			{ejerciciosLoad.testPractico[testSelected].casoPracticoResultados.find(t => t.idAlumno == this.props.idUser).enBlanco} <i className="far fa-circle" />
																		</div>
																	</div>
																	<div>
																		<div className="SSFC-B-TL-eL-boton">
																			<Boton id="repetirCasoPracticoBloque" icon1="" icon2="" texto="Hacer Test" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																</div>

																<div className="SSFC-B-TL-eL-Ranking">
																	{isDefined(ejerciciosLoad.testPractico[testSelected].ranking1) && ejerciciosLoad.testPractico[testSelected].ranking1.length > 0 ? (
																		<Ranking rank={ejerciciosLoad.testPractico[testSelected].ranking1} />
																	) : (
																		<img src="../img/noRanking.jpg" />
																	)}
																</div>

															</React.Fragment>
														) : (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-boton">
																		<Boton id="realizarCasoPracticoBloque" icon1="" icon2="" texto="Realizar Test" onClick={this.onChangeBuscador} />
																	</div>
																</div>

																<div className="SSFC-B-TL-eL-Ranking">
																	{isDefined(ejerciciosLoad.testPractico[testSelected].ranking1) && ejerciciosLoad.testPractico[testSelected].ranking1.length > 0 ? (
																		<Ranking rank={ejerciciosLoad.testPractico[testSelected].ranking1} />
																	) : (
																		<img src="../img/noRanking.jpg" />
																	)}
																</div>

															</React.Fragment>
														)
													) : null

													}

													{isDefined(testSelected) && tipoEjerSelected == 'testDesarrollo' ? (

														ejerciciosLoad.ejerDesarrollo[testSelected].examenExiste != false ? (


															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">



																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title" style={{
																			marginRight: '0px',
																			alignItems: 'center',
																			justifyContent: 'center',
																			display: 'flex',
																			flexFlow: 'column nowrap'
																		}}>
																			<b style={{ fontWeight: '500' }}>Auto-Corrección</b>


																		</div>

																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGes" icon1="" icon2="" texto="Descargar examen" onClick={this.onChangeBuscador} />
																		</div>


																	</div>


																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.onChangeBuscador} />
																		</div>
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.onChangeBuscador} />
																		</div>
																	</div>

																</div>
																<div className="SSFC-B-TL-eL-Ranking">

																	<div className="SSFC-B-TL-eL-notaPrepa">
																		Puede descargar la solución, modo auto-evaluación.
																	</div>

																	<img src="../img/examDesarrolloCompleted.jpg" />
																</div>
															</React.Fragment>




														) : (
															<React.Fragment>
																<div className="SSFC-B-TL-eL-testInfo">
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-title"> Subir examen en .pdf </div>

																	</div>
																	<div className="SSFC-B-TL-eL-testInfoResultado">
																		<div className="SSFC-B-TL-eL-boton2">
																			<Boton id="descargarPdfExaGes" icon1="" icon2="" texto="Descargar examen" onClick={this.onChangeBuscador} />
																		</div>
																		<div className="SSFC-B-TL-eL-boton2">

																			<InputFileExamDesa onChange={this.myFiles} name="examGest" texto="Subir examen" />
																		</div>
																	</div>

																</div>

																<div className="SSFC-B-TL-eL-Ranking">
																	<img src="../img/examDesarrollo.jpg" />
																</div>

															</React.Fragment>
														)
													) : null

													}
												</div>


											</div>
										</div>
									) : null
								}

							</div>
						) : null
					}

					{
						temasFacturados && temasFacturados.length > 1 && planEstudio != null ?
							(
								<div className='bodySemPrgCurso-contentSemLoad' style={{ marginTop: '0px' }}>
									{semanasPrgCurso != null && semanasPrgCurso.length > 0 ? (
										<div className='ColCenterStart' style={{ padding: '10px' }}>
											{moment(semanasPrgCurso[0].fechaInicio).format('[Plan de estudio del] dddd, DD [de] MMMM [de] YYYY')}
											{moment(semanasPrgCurso[semanasPrgCurso.length - 1].fechaInicio).format('[ al] dddd, DD [de] MMMM [de] YYYY[.]')}
											<p> Haga clic sobre un mes para ver las semanas y temas de estudio principales.</p>
										</div>
									) : null}
									{
										planEstudio && planEstudio.length > 0 && planEstudio.map((plan, key) =>
											<div className='ColCenterStart' key={key}>
												<div id={'verSemPlan-' + key} onClick={this.onHandleClick} className='RowCenterCenter titlePlanMes contoneoLetras'>
													{moment(plan.fecha).format('MMMM')}
												</div>


												<div id={"containerSemPlan-" + key} className='RowCenterCenter semPlanOcl' style={{ pointerEvents: 'none' }}>
													{plan.programacion.map((prg, key2) =>
														<div className='ColCenterStart' style={{ borderRadius: '25px', padding: '10px', boxShadow: '0px 2px 4px #3469b78f', margin: '20px 0px' }}>
															<h3 style={{ color: '#3469b7', fontWeight: '600', fontSize: '21px' }}>
																Semana {' ' + prg.numSemana}
															</h3>
															<div className='RowCenterCenter'>
																{prg.temasPrincipales.map((tem, key3) =>
																	<CajaInfoTema tema={tem} key={key3} />
																)}
															</div>

														</div>

													)}
												</div>
											</div>
										)
									}
								</div>
							) : null
					}
				</div>
			) : (<h3> Cargando plan de estudio</h3>)

		);
	}
}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		temasFacturados: state.user.temasFacturados,
		tipoUser: state.user.tipoUser,
		semanasPrgCurso: state.user.semanasPrgCurso,
		temaAulaLoad: state.user.temaAulaLoad,
		idOposicion: state.user.idOposicion,
		testJusticiaUserLoad: state.user.testJusticiaUserLoad,
		casoPracticoUserLoad: state.user.casoPracticoUserLoad,
		idClase: state.user.idClase
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		borrarTestUser,
		loadTestUser,
		loadCPUser,
		borrarCP,
		loadCurso,
		loadClase,
		loadTemasFacturados,
		loadAllSemFac,
		loadSemanaPrg,
		loadTemaAula
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CajaInfoSemanaCurso);


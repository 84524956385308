import { createStore } from 'redux'
import rootReducer from '../reducers'
const initialStateBasic = {
    user: {
      idUser: null,
      isLogged: false,
    },
  }

export default function configureStore(options) {
  const { initialState = initialStateBasic } = options;

  return createStore(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}

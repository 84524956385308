// Actions
const CARGAR_TEST_INSERT = 'CARGAR_TEST_INSERT';
const CARGAR_TEST_EDT = 'CARGAR_TEST_EDT';
const ELIMINAR_TEST_JUSTICIA = 'ELIMINAR_TEST_JUSTICIA';
const ELIMINAR_TEST_JUSTICIA_EDT = 'ELIMINAR_TEST_JUSTICIA_EDT';

const START_PREGUNTA_TEST_INSERT = 'START_PREGUNTA_TEST_INSERT';
const ELIMINAR_PREGUNTAS_JUSTICIA_INSERT = 'ELIMINAR_PREGUNTAS_JUSTICIA_INSERT';

const GEN_DEL_PREG = 'GEN_DEL_PREG';
const GEN_EDT_PREG = 'GEN_EDT_PREG';

export function cargarTestParaInsertar(datosDelTest) {
    return {
      type: CARGAR_TEST_INSERT,
	 datosDelTest: datosDelTest
    };
}
export function eliminarTestJusticiaCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA
    };
}
export function cargarTestParaEditar(datosDelTestEditar) {
    return {
      type: CARGAR_TEST_EDT,
	 datosDelTestEdt: datosDelTestEditar
    };
}
export function eliminarTestJusticiaEdtCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA_EDT
    };
}

export function startPreguntaTest(arrayInicial) {
    return {
      type: START_PREGUNTA_TEST_INSERT,
	 datos: arrayInicial
    };
}
export function eliminarPreguntasJusticiaCache() {
    return {
      type: ELIMINAR_PREGUNTAS_JUSTICIA_INSERT
    };
}

export function genPregToDelete(preguntaInicial) {
    return {
	    type: GEN_DEL_PREG,
      value: preguntaInicial
    };
}

export function genPregToDeleteEdt(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG,
      value: preguntaInicial
    };
}

// Dependencies
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

// Routes
import AppRoutes from './routes';

// Assets
import './index.css';

// Redux store
import configureStore from './lib/configureStore';

// Reducers
import rootReducer from './reducers';


// Functions --> LocalStorage
function saveToLocalStorage(state) {
  try{
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch(e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch(e){
    console.log(e);
  }
}
// Configuring redux store with localStorage
const persistedState = loadFromLocalStorage();
const store = configureStore({
  initialState: persistedState
}, rootReducer);

store.subscribe( () => saveToLocalStorage(store.getState()) );

render(
  <Provider store={store}>
    <Router>
      <AppRoutes />
    </Router>

  </Provider>,
  document.getElementById('root')
);

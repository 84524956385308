export default [
  {
    title: 'Citas',
    url: '/Oficina/Citas',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-comments',
    subMenu: []
    
    
  },
  //  [ {
    //     title: 'Citas',
    //     url: 'Ver_Citas',
    //     fontawesome: 'fas fa-calendar-alt'
    //   },
    //   {
    //     title: 'Citas Cerradas',
    //     url: 'Ver_Citas_Anteriores',
    //     fontawesome: 'fas fa-calendar-alt'
    //   },
    //   {
    //     title: 'Añadir Cita',
    //     url: 'Añadir_Cita',
    //     fontawesome: 'fas fa-plus'
    //   },
    //   {
    //     title: 'Editar Cita',
    //     url: 'Editar_Cita',
    //     fontawesome: 'fas fa-edit'
    //   },
    //   {
    //     title: 'Borrar Cita',
    //     url: 'Borrar_Cita',
    //     fontawesome: 'fas fa-trash-alt'
    //   }]
  {
    title: 'Facturacion',
    url: '/Oficina/GestionPagos',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-euro-sign',
    subMenu: [
      {
        title: 'Facturación Manual',
        url: 'Facturacion_Mensual',
        fontawesome: 'fas fa-hand-holding-usd'
      },
      {
        title: 'Reservas & Lista Espera',
        url: 'Reservas',
        fontawesome: 'fas fa-hand-holding-usd'
      },
      {
        title: 'Editar Factura',
        url: 'Activar_Mes_Clase',
        fontawesome: 'fas fa-hand-holding-usd'
      }
    ]
  },
  {
    title: 'Gestión de Alumnos',
    url: '/Oficina/Alumnos',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-edit',
    subMenu: [
      {
        title: 'Añadir Alumno',
        url: 'Añadir_Alumno',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Configuración Alumno',
        url: 'Editar_Alumno',
        fontawesome: 'fas fa-edit'
      },
      {
        title: 'Borrar Alumno',
        url: 'Borrar_Alumno',
        fontawesome: 'fas fa-trash-alt'
      }
    ]
  },
  {
    title: 'Gestión de Clases',
    url: '/Oficina/InfoClase',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-info-circle',
    subMenu: [
      {
        title: 'Añadir Clase',
        url: 'ADD_Clase',
        fontawesome: 'fa-light fa-plus'
      },
      {
        title: 'Editar/Info Clase',
        url: 'Buscar_Clase',
        fontawesome: 'fa-light fa-screen-users'
      },
      {
        title: 'Word 2010',
        url: 'Buscar_Clase_Word2010',
        fontawesome: 'fa-regular fa-file-word'
      },
      {
        title: 'Cursos Online',
        url: 'INFO_CURSOS',
        fontawesome: 'fa-duotone fa-screen-users'
      },
      {
        title: 'Cambiar de clase',
        url: 'CAMBIO_CLASE',
        fontawesome: 'fa-regular fa-users-gear'
      }
      
    ]
  },
  // {
  //   title: 'Info Alumno',
  //   url: '/Oficina/InfoAlumno',
  //   visible: true,
  //   nivelAcesso: 98,
  //   fontawesome: 'fas fa-address-card',
  //   subMenu: [
  //     {
  //       title: 'Buscar Alumno',
  //       url: 'Buscar_Alumno',
  //       fontawesome: 'fas fa-search'
  //     }
  //   ]
  // },
  {
    title: 'Añadir Programacion Clase',
    url: '/Oficina/apclase',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-edit',
    subMenu: [
      {
        title: 'Añadir Semana',
        url: 'add_semana_prg',
        fontawesome: 'fas fa-plus'
      },
      // {
      //   title: 'Aplicar 1 Semana a Clase',
      //   url: 'Add_Semana_to_Programacion',
      //   fontawesome: 'fas fa-plus'
      // },
      {
        title: 'Clonar Programacion',
        url: 'Clonar_Programacion',
        fontawesome: 'fas fa-plus'
      },
      // {
      //   title: 'Aplicar Programacion Completa',
      //   url: 'Aplicar_Programacion',
      //   fontawesome: 'fas fa-plus'
      // },
      {
        title: 'Eliminar Programacion',
        url: 'Eliminar_Programacion',
        fontawesome: 'fas fa-plus'
      }
      // ,
      // {
      //   title: 'Posponer Clases',
      //   url: 'Posponer_Clase',
      //   fontawesome: 'fas fa-redo-alt'
      // },
      // {
      //   title: 'Atrasar Clases',
      //   url: 'Atrasar_Clases',
      //   fontawesome: 'fas fa-undo-alt'
      // }
    ]
  },
  {
    title: 'Programacion Dinamica',
    url: '/Oficina/apclasedin',
    visible: true,
    nivelAcesso: 98,
    fontawesome: 'fas fa-edit',
    subMenu: [
      {
        title: 'Aplicar Programación',
        url: 'ap_cls_din',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Eliminar Programación',
        url: 'ap_cls_din_eliminar',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Fecha',
        url: 'ap_cls_din_fecha',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Info Semana',
        url: 'ap_cls_din_edt',
        fontawesome: 'fas fa-plus'
      }
    ]
  }
  // ,
  // {
  //   title: 'Mecanografía',
  //   url: '/Oficina/Mecanografia',
  //   visible: true,
  //   nivelAcesso: 100,
  //   fontawesome: 'fas fa-file-word',
  //   subMenu: [
  //     {
  //       title: 'Facturación Manual',
  //       url: 'Facturacion_Mensual',
  //       fontawesome: 'fas fa-hand-holding-usd'
  //     },
  //     {
  //       title: 'Editar Factura',
  //       url: 'Activar_Mes_Clase',
  //       fontawesome: 'fas fa-hand-holding-usd'
  //     }
  //   ]
  // }
];

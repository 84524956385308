// Actions
const CARGAR_TEST_INSERT_CASO = 'CARGAR_TEST_INSERT_CASO';
const CARGAR_TEST_EDT_CP = 'CARGAR_TEST_EDT_CP';
const ELIMINAR_CASO_PRACTICO = 'ELIMINAR_CASO_PRACTICO';
const ELIMINAR_TEST_JUSTICIA_EDT_CP = 'ELIMINAR_TEST_JUSTICIA_EDT_CP';

const GEN_EDT_PREG_CP = 'GEN_EDT_PREG_CP';

export function cargarTestParaInsertar(datosDelTest) {
    return {
      type: CARGAR_TEST_INSERT_CASO,
	     datosDelTest: datosDelTest
    };
}
export function eliminarTestJusticiaCache() {
    return {
      type: ELIMINAR_CASO_PRACTICO
    };
}
export function cargarTestParaEditar(datosDelTestEditar) {
    return {
      type: CARGAR_TEST_EDT_CP,
    	datosDelTestEdt: datosDelTestEditar
    };
}
export function eliminarTestJusticiaEdtCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA_EDT_CP
    };
}

export function genPregToDeleteEdt(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG_CP,
      value: preguntaInicial
    };
}

export function genPregToDeleteEdtCP(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG_CP,
      value: preguntaInicial
    };
}

// Dependencies
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../../../../lib/utils/is'
//Componets
import Input2 from '../../../../../components/Inputs/Input2'
import Boton from '../../../../../components/Inputs/Boton'


const OptionBar = (props) => {


  const [busquedaOpo, setBusquedaOpo] = useState(null)

  const [oposicionSlc, setOposicionSlc] = useState(null)


  const [oposicionNew, setOposicionNew] = useState('')

  const refInput1 = useRef()

  const refInputnumPregJus = useRef()
  const refInputpuntuacionMediaJus = useRef()
  const refInputaciertoJus = useRef()
  const refInputfalloJus = useRef()
  const refInputenBlancoJus = useRef()

  const refInputnumPregCP = useRef()
  const refInputpuntuacionMediaCP = useRef()
  const refInputaciertoCP = useRef()
  const refInputfalloCP = useRef()
  const refInputenBlancoCP = useRef()

  const refInputnameOpo = useRef()






  useEffect(() => {// didMont
    // oposicionSlc != null ? refInput1.current.refs.oposicionTema.value = '' : null
    setOposicionSlc(null)

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => setBusquedaOpo(oposiciones))
      .catch(error => console.log('Object fecth failed', error))



    return () => {// willunmount hacemos 
      // console.log('Cleanup')
    }
  }, [props.selectedOption]) // [] didUpdate , si pones [nombre_ley] actualiza

  const onChangeInput = async (e) => {

    if (e.target.id == 'oposicion') {

      let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === e.target.value)
      if (oposicionObject != undefined) {
        setOposicionSlc(oposicionObject)

        setTimeout(() => {
          refInputnumPregJus.current.refs.numPregJus.value = oposicionObject.cantidadPregJusticia
          refInputpuntuacionMediaJus.current.refs.puntuacionMediaJus.value = oposicionObject.puntuacionRJusticia
          refInputaciertoJus.current.refs.aciertoJus.value = oposicionObject.aciertoJus
          refInputfalloJus.current.refs.falloJus.value = oposicionObject.restaMalTestJustica100
          refInputenBlancoJus.current.refs.enBlancoJus.value = oposicionObject.restaEnBlancoJusticia

          refInputnumPregCP.current.refs.numPregCP.value = oposicionObject.cantidadPregCasoP
          refInputpuntuacionMediaCP.current.refs.puntuacionMediaCP.value = oposicionObject.puntuacionRCP
          refInputaciertoCP.current.refs.aciertoCP.value = oposicionObject.aciertoCP
          refInputfalloCP.current.refs.falloCP.value = oposicionObject.restaMalCasoP20

          refInputenBlancoCP.current.refs.enBlancoCP.value = oposicionObject.restaEnBlancoCasoPractico
        }, 200);
      } else {
        setOposicionSlc(null)
      }


    } else if (e.target.id == 'oposicionNew') {
      setOposicionNew(e.target.value)
    }


    // numPregJus
    // puntuacionMediaJus
    // aciertoJus
    // falloJus
    // enBlancoJus

    // numPregCP
    // puntuacionMediaCP
    // aciertoCP
    // falloCP
    // enBlancoJus

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == 'añadirOpo') {

      let numPregJus = refInputnumPregJus.current.refs.numPregJus.value
      let puntuacionMediaJus = refInputpuntuacionMediaJus.current.refs.puntuacionMediaJus.value
      let aciertoJus = refInputaciertoJus.current.refs.aciertoJus.value
      let falloJus = refInputfalloJus.current.refs.falloJus.value
      let enBlancoJus = refInputenBlancoJus.current.refs.enBlancoJus.value

      let numPregCP = refInputnumPregCP.current.refs.numPregCP.value
      let puntuacionMediaCP = refInputpuntuacionMediaCP.current.refs.puntuacionMediaCP.value
      let aciertoCP = refInputaciertoCP.current.refs.aciertoCP.value
      let falloCP = refInputfalloCP.current.refs.falloCP.value
      let enBlancoCP = refInputenBlancoCP.current.refs.enBlancoCP.value

      let nombreOpo = oposicionNew

      if (isDefined(numPregJus) && isDefined(puntuacionMediaJus) && isDefined(aciertoJus) && isDefined(falloJus) && isDefined(enBlancoJus) && isDefined(numPregCP) && isDefined(puntuacionMediaCP) && isDefined(aciertoCP) && isDefined(falloCP) && isDefined(enBlancoCP)) {
        swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, se añadira una nueva oposción",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {

              let data = {
                nombreOpo: nombreOpo,
                numPregJus: numPregJus,
                puntuacionMediaJus: puntuacionMediaJus,
                aciertoJus: aciertoJus,
                falloJus: falloJus,
                enBlancoJus: enBlancoJus,
                numPregCP: numPregCP,
                puntuacionMediaCP: puntuacionMediaCP,
                aciertoCP: aciertoCP,
                falloCP: falloCP,
                enBlancoCP: enBlancoCP
              }
              let response = await fetch('https://oposiciones-justicia.es/api/oposiciones/addOpon', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                await swal({
                  title: 'Oposición insertada',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })
              }

            } else {
              swal("¡Proceso no completado!", '', "success");
            }
          });
      } else {
        swal('Error', 'Rellene todos los datos, tiene como Ej: el valor más comun.', 'info')
      }
    } else if (e.target.id == 'editarOpo') {
      let numPregJus = refInputnumPregJus.current.refs.numPregJus.value
      let puntuacionMediaJus = refInputpuntuacionMediaJus.current.refs.puntuacionMediaJus.value
      let aciertoJus = refInputaciertoJus.current.refs.aciertoJus.value
      let falloJus = refInputfalloJus.current.refs.falloJus.value
      let enBlancoJus = refInputenBlancoJus.current.refs.enBlancoJus.value

      let numPregCP = refInputnumPregCP.current.refs.numPregCP.value
      let puntuacionMediaCP = refInputpuntuacionMediaCP.current.refs.puntuacionMediaCP.value
      let aciertoCP = refInputaciertoCP.current.refs.aciertoCP.value
      let falloCP = refInputfalloCP.current.refs.falloCP.value
      let enBlancoCP = refInputenBlancoCP.current.refs.enBlancoCP.value

      let nombreOpo = refInputnameOpo.current.refs.nameOpo.value != '' ? (refInputnameOpo.current.refs.nameOpo.value) : (oposicionSlc.nombre)
      let opoId = oposicionSlc.id_oposicion
      if (isDefined(numPregJus) && isDefined(puntuacionMediaJus) && isDefined(aciertoJus) && isDefined(falloJus) && isDefined(enBlancoJus) && isDefined(numPregCP) && isDefined(puntuacionMediaCP) && isDefined(aciertoCP) && isDefined(falloCP) && isDefined(enBlancoCP)) {
        swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok,la oposición será editada",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {


              let data = {
                idOpo: opoId,
                nombreOpo: nombreOpo,
                numPregJus: numPregJus,
                puntuacionMediaJus: puntuacionMediaJus,
                aciertoJus: aciertoJus,
                falloJus: falloJus,
                enBlancoJus: enBlancoJus,
                numPregCP: numPregCP,
                puntuacionMediaCP: puntuacionMediaCP,
                aciertoCP: aciertoCP,
                falloCP: falloCP,
                enBlancoCP: enBlancoCP
              }
              let response = await fetch('https://oposiciones-justicia.es/api/oposiciones/edtOpoN', {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response.ok) {
                throw Error(response.statusText);
              }
              let json = await response.json();
              let cls = await json;

              if (cls.affectedRows > 0) {
                await swal({
                  title: 'Oposición insertada',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })
              }


            } else {
              swal("¡Proceso no completado!", '', "success");
            }
          });
      } else {
        swal('Error', 'Rellene todos los datos, tiene como Ej: el valor más comun.', 'info')
      }
    }
  }



  const { selectedOption } = props




  switch (selectedOption) {
    case 'Añadir_Opo': {
      return (

        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Añadiendo oposición </h3>

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput1} autoComplete={'off'} id={'oposicionNew'} type='text' name={'oposicionNew'} placeholder='Nombre Oposición' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Nombre Oposición</p>
          </div>

          {
            oposicionNew != '' ? (
              <>
                <div className="RowCenterCenter">



                  <div className="ColCenterStart" style={{ padding: '0px 25px' }}>
                    <h3 style={{
                      color: '#3469b7',
                      marginBottom: '10px',
                      fontSize: '19px',
                      marginTop: '10px'
                    }} >Configuración Test teórico</h3>
                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Cantidad preguntal total test" ref={refInputnumPregJus}
                          autoComplete={"off"} id={"numPregJus"} type="text" name={"numPregJus"} placeholder="Ej: 100" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                        <p>Total Preg Test</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Media total de preguntas para el calculo puntuacion" ref={refInputpuntuacionMediaJus}
                          autoComplete={"off"} id={"puntuacionMediaJus"} type="text" name={"puntuacionMediaJus"} placeholder="Ej: 60" icon="fa-light fa-percent" onChange={onChangeInput} />
                        <p>Pun. Media Jus</p>
                      </div>
                    </div>

                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como puntua cuando acierta ej: 1" ref={refInputaciertoJus}
                          autoComplete={"off"} id={"aciertoJus"} type="text" name={"aciertoJus"} placeholder="Ej: 1" icon="fa-light fa-check" onChange={onChangeInput} />
                        <p>Acierto</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como resta cuando falla Ej: 0.25" ref={refInputfalloJus}
                          autoComplete={"off"} id={"falloJus"} type="text" name={"falloJus"} placeholder="Ej: 0.25" icon="fa-light fa-xmark" onChange={onChangeInput} />
                        <p>Fallo</p>
                      </div>
                    </div>

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                      <Input2 title="Como resta cuando falla Ej: 0.25 o nada 0" ref={refInputenBlancoJus}
                        autoComplete={"off"} id={"enBlancoJus"} type="text" name={"enBlancoJus"} placeholder="Ej: 0" icon="fa-light fa-circle" onChange={onChangeInput} />
                      <p>En blanco</p>
                    </div>

                  </div>

                  <div className="ColCenterStart" style={{ padding: '0px 25px' }}>
                    <h3 style={{
                      color: '#3469b7',
                      marginBottom: '10px',
                      fontSize: '19px',
                      marginTop: '10px'
                    }} >Configuración Test práctico</h3>
                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Cantidad preguntal total test" ref={refInputnumPregCP}
                          autoComplete={"off"} id={"numPregCP"} type="text" name={"numPregCP"} placeholder="Ej: 20" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                        <p>Total Preg Test</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Media total de preguntas para el calculo puntuacion" ref={refInputpuntuacionMediaCP}
                          autoComplete={"off"} id={"puntuacionMediaCP"} type="text" name={"puntuacionMediaCP"} placeholder="Ej: 20" icon="fa-light fa-percent" onChange={onChangeInput} />
                        <p>Pun. Media</p>
                      </div>
                    </div>

                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como puntua cuando acierta ej: 1" ref={refInputaciertoCP}
                          autoComplete={"off"} id={"aciertoCP"} type="text" name={"aciertoCP"} placeholder="Ej: 1" icon="fa-light fa-check" onChange={onChangeInput} />
                        <p>Acierto</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como resta cuando falla Ej: 0.25" ref={refInputfalloCP}
                          autoComplete={"off"} id={"falloCP"} type="text" name={"falloCP"} placeholder="Ej: 0.25" icon="fa-light fa-xmark" onChange={onChangeInput} />
                        <p>Fallo</p>
                      </div>
                    </div>

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                      <Input2 title="Como resta cuando falla Ej: 0.25 o nada 0" ref={refInputenBlancoCP}
                        autoComplete={"off"} id={"enBlancoCP"} type="text" name={"enBlancoCP"} placeholder="Ej: 0" icon="fa-light fa-circle" onChange={onChangeInput} />
                      <p>En blanco</p>
                    </div>



                  </div>




                </div>
                <div className="RowCenterCenter" style={{ marginTop: '20px' }}>
                  <Boton id='añadirOpo' onClick={handleSubmitForm} icon1='' icon2='' texto='Añadir Oposición' />
                </div>

              </>
            ) : null}


          <datalist id="busquedaOpo">{
            busquedaOpo && busquedaOpo.map((oposicion, key) =>
              <option key={key} value={oposicion.nombre}></option>
            )
          }</datalist>


        </div>
      )
    }
      break
    case 'Borrar_Opo': {
      return (


        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Editando oposición </h3>

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput1} datalist='busquedaOpo' autoComplete={'off'} id={'oposicion'} type='text' name={'oposicion'} placeholder='Oposición a editar' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Oposición</p>
          </div>

          {
            oposicionSlc != null ? (
              <>
                <div className="celdaInputDAPFBN" style={{ margin: '20px' }}>
                  <Input2 title="Nuevo nombre oposicion" ref={refInputnameOpo}
                    autoComplete={"off"} id={"nameOpo"} type="text" name={"nameOpo"} placeholder="Ej: Auxilio" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                  <p>Nuevo nombre Opo</p>
                </div>

                <div className="RowCenterCenter">

                  <div className="ColCenterStart" style={{ padding: '0px 25px' }}>
                    <h3 style={{
                      color: '#3469b7',
                      marginBottom: '10px',
                      fontSize: '19px',
                      marginTop: '10px'
                    }} >Configuración Test teórico</h3>
                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Cantidad preguntal total test" ref={refInputnumPregJus}
                          autoComplete={"off"} id={"numPregJus"} type="text" name={"numPregJus"} placeholder="Ej: 100" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                        <p>Total Preg Test</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Media total de preguntas para el calculo puntuacion" ref={refInputpuntuacionMediaJus}
                          autoComplete={"off"} id={"puntuacionMediaJus"} type="text" name={"puntuacionMediaJus"} placeholder="Ej: 60" icon="fa-light fa-percent" onChange={onChangeInput} />
                        <p>Pun. Media Jus</p>
                      </div>
                    </div>

                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como puntua cuando acierta ej: 1" ref={refInputaciertoJus}
                          autoComplete={"off"} id={"aciertoJus"} type="text" name={"aciertoJus"} placeholder="Ej: 1" icon="fa-light fa-check" onChange={onChangeInput} />
                        <p>Acierto</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como resta cuando falla Ej: 0.25" ref={refInputfalloJus}
                          autoComplete={"off"} id={"falloJus"} type="text" name={"falloJus"} placeholder="Ej: 0.25" icon="fa-light fa-xmark" onChange={onChangeInput} />
                        <p>Fallo</p>
                      </div>
                    </div>

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                      <Input2 title="Como resta cuando falla Ej: 0.25 o nada 0" ref={refInputenBlancoJus}
                        autoComplete={"off"} id={"enBlancoJus"} type="text" name={"enBlancoJus"} placeholder="Ej: 0" icon="fa-light fa-circle" onChange={onChangeInput} />
                      <p>En blanco</p>
                    </div>

                  </div>

                  <div className="ColCenterStart" style={{ padding: '0px 25px' }}>
                    <h3 style={{
                      color: '#3469b7',
                      marginBottom: '10px',
                      fontSize: '19px',
                      marginTop: '10px'
                    }} >Configuración Test práctico</h3>
                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Cantidad preguntal total test" ref={refInputnumPregCP}
                          autoComplete={"off"} id={"numPregCP"} type="text" name={"numPregCP"} placeholder="Ej: 20" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                        <p>Total Preg Test</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Media total de preguntas para el calculo puntuacion" ref={refInputpuntuacionMediaCP}
                          autoComplete={"off"} id={"puntuacionMediaCP"} type="text" name={"puntuacionMediaCP"} placeholder="Ej: 20" icon="fa-light fa-percent" onChange={onChangeInput} />
                        <p>Pun. Media</p>
                      </div>
                    </div>

                    <div className='RowCenterCenter'>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como puntua cuando acierta ej: 1" ref={refInputaciertoCP}
                          autoComplete={"off"} id={"aciertoCP"} type="text" name={"aciertoCP"} placeholder="Ej: 1" icon="fa-light fa-check" onChange={onChangeInput} />
                        <p>Acierto</p>

                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                        <Input2 title="Como resta cuando falla Ej: 0.25" ref={refInputfalloCP}
                          autoComplete={"off"} id={"falloCP"} type="text" name={"falloCP"} placeholder="Ej: 0.25" icon="fa-light fa-xmark" onChange={onChangeInput} />
                        <p>Fallo</p>
                      </div>
                    </div>

                    <div className="celdaInputDAPFBN" style={{ maxWidth: '135px', margin: '10px' }}>
                      <Input2 title="Como resta cuando falla Ej: 0.25 o nada 0" ref={refInputenBlancoCP}
                        autoComplete={"off"} id={"enBlancoCP"} type="text" name={"enBlancoCP"} placeholder="Ej: 0" icon="fa-light fa-circle" onChange={onChangeInput} />
                      <p>En blanco</p>
                    </div>



                  </div>




                </div>
                <div className="RowCenterCenter" style={{ marginTop: '20px' }}>
                  <Boton id='editarOpo' onClick={handleSubmitForm} icon1='' icon2='' texto='Editar Oposición' />
                </div>

              </>
            ) : null}


          <datalist id="busquedaOpo">{
            busquedaOpo && busquedaOpo.map((oposicion, key) =>
              <option key={key} value={oposicion.nombre}></option>
            )
          }</datalist>


        </div>
      )
    } break
    default: {
      return (
        <div className="selectOptionToLoad">
          <i className="fa-thin fa-face-monocle"></i>
          <h3> Seleccione una opción para continuar </h3>
        </div>
      )
    }
  }

}

OptionBar.propTypes = {
  selectedOption: PropTypes.string.isRequired
}



export default OptionBar
// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment'

//Assets
import './css/css.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { reloadInfoPersonal, reloadNameUser } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
// Components
import Input2 from '../../components/Inputs/Input2';
import InputFileIcono from '../../components/Inputs/InputFileIcono';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';

import ReactHtmlParser from 'react-html-parser';


import swal from 'sweetalert';

class Word201SemanaEstudio extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      sectionSelecionada: 1,
      contra: 'bien',
      correctas: null,
      Erroneas: null,
      enBlanco: null
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onClickHandleS = this.onClickHandleS.bind(this);
  }


  async onChangeInput(e) {

    if (e.target.name == 'correctas' || e.target.name == 'Erroneas' || e.target.name == 'enBlanco') {
      this.setState({ [e.target.name]: e.target.value })
    }

  }

  async onClickHandleS(e) {
    if (e.target.id == "enviarYcompletar") {
      //subimos datos del test y reload página.
      if (this.state.correctas != null && this.state.correctas != '' && this.state.Erroneas != null && this.state.Erroneas != '' && this.state.enBlanco != null && this.state.enBlanco != '' && parseInt(this.state.correctas) + parseInt(this.state.Erroneas) + parseInt(this.state.enBlanco) == parseInt(this.props.testWordLoad.num_preg_test)) {
        let data = {
          idUser: this.props.idUser,
          idSemana: this.props.testWordLoad.id_semana,
          correctas: this.state.correctas,
          erroneas: this.state.Erroneas,
          enBlanco: this.state.enBlanco,
          numPregTest: this.props.testWordLoad.num_preg_test,
          fecha: moment().format('YYYY-MM-DD HH:mm')
        }
        let response = await fetch("https://oposiciones-justicia.es/api/word2010/addTestAlumnoWordTest", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        if (cls.affectedRows > 0) {
          let correctas = this.state.correctas
          let erroneas = this.state.Erroneas
          let enBlanco = this.state.enBlanco
          let numPregTest = this.props.testWordLoad.num_preg_test


          let restamos = 0.33;
          let totalPreg = 15
          let restamosBlanco = 0;
          let puntuacionRealPorcentaje = 100
          let aciertoJus = 1.33

          let multiploMalF = parseFloat(parseFloat(totalPreg / numPregTest) * restamos)
          let multiploMalBlanco = parseFloat(parseFloat(totalPreg / numPregTest) * restamosBlanco)
          let multiploAcierto = parseFloat(parseFloat(totalPreg / numPregTest) * aciertoJus)

          let correctaF = parseFloat(correctas)
          let malF = parseFloat(parseFloat(erroneas) * multiploMalF).toFixed(2)
          let enBlancoFF = parseFloat(parseFloat(enBlanco) * multiploMalBlanco).toFixed(2)
          let puntuacion = parseFloat(((correctaF * multiploAcierto) - malF) - enBlancoFF).toFixed(2)
          let puntuacionReal = parseFloat(parseFloat(puntuacion) * parseFloat(puntuacionRealPorcentaje / 100)).toFixed(2)


          await swal({
            title: 'Test completado.',
            text: 'Puntuación: ' + puntuacionReal + '. Se recargará la web en breve.',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 3500,
          }).then(() => {
            window.location.reload()
          })

        } else {
          swal('Algo no funciono', 'Vuelva a intentarlo, sentimos las molestias', 'error')
        }
      } else {
        if (parseInt(this.state.correctas) + parseInt(this.state.Erroneas) + parseInt(this.state.enBlanco) != parseInt(this.props.testWordLoad.num_preg_test)) {
          swal('Error', 'El total de preguntas del test debe coincidir con el total de correctas, erroneas y en blanco.', 'error')
        } else {
          swal('Complete los resultados del Test', 'Para completar y enviar, debe añadir las preguntas correctas, erroneas y en blanco.', 'info')
        }

      }

    } else if (e.target.id == "changeSemana") {
      this.props.handleOnClicWord(e)
    } else if (e.target.id == "descargaTest") {
      if ((this.props.testWordLoad.vueltaNumero == 1 || this.props.testWordLoad.vueltaNumero == null)) {

        if (moment().format('YYYY-MM-DD HH:mm') >= moment(this.props.testWordLoad.fechaClase).format('YYYY-MM-DD HH:mm')) {
          swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
          let pathFile = 'public' + this.props.testWordLoad.link_test.split('..')[1]
          let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
          let nameFile = this.props.testWordLoad.nombreEjercicio;
          fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob'
          }).then(res => {
            if (res.status >= 400) { throw new Error("Bad res from server"); }
            return res.blob();
          }).then(blob => {
            saveAs(blob, nameFile);
          }).catch(function (err) {
            console.log(err)
            alert("Link no valido para descarga, reportelo, gracias.")
          });
        } else {
          swal('Descarga no disponible', '', 'info')
        }


      } else {
        swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
        let pathFile = 'public' + this.props.testWordLoad.link_test.split('..')[1]
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = this.props.testWordLoad.nombreEjercicio;
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga, reportelo, gracias.")
        });
      }


    } else if (e.target.id == "descargaSolucion") {


      if ((this.props.testWordLoad.vueltaNumero == 1 || this.props.testWordLoad.vueltaNumero == null)) {

        if (moment().format('YYYY-MM-DD HH:mm') >= moment(this.props.testWordLoad.fechaClase).format('YYYY-MM-DD HH:mm')) {
          swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
          let pathFile = 'public' + this.props.testWordLoad.link_soluciones.split('..')[1]
          let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
          let nameFile = 'Respuestas - ' + this.props.testWordLoad.nombreEjercicio;
          fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            responseType: 'blob'
          }).then(res => {
            if (res.status >= 400) { throw new Error("Bad res from server"); }
            return res.blob();
          }).then(blob => {
            saveAs(blob, nameFile);
          }).catch(function (err) {
            console.log(err)
            alert("Link no valido para descarga, reportelo, gracias.")
          });
        } else {
          swal('Descarga no disponible', '', 'info')
        }


      } else {
        swal('Descarga en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
        let pathFile = 'public' + this.props.testWordLoad.link_soluciones.split('..')[1]
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = 'Respuestas - ' + this.props.testWordLoad.nombreEjercicio;
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga, reportelo, gracias.")
        });
      }
    } else if (e.target.id == "editSemana") {
      this.props.handleOnClicWord(e)
    }
  }


  componentDidMount() {
    // cargar imagenPerfil en propTypes
    let data = { idUser: this.props.idUser, tipoUser: this.props.tipoUser }
    // ver si tiene pagado word 2010
  }


  render() {

    const { isLogged, tipoUser, testWordLoad } = this.props;
    const { } = this.state
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      return (
        <div className="bodySemaEstudioW2010">
          {testWordLoad && testWordLoad != null && testWordLoad != undefined ? (
            <React.Fragment>

              <div className="SEW2010-TOPBAR">
                <div className='iMW-boton'>
                  <Boton id="changeSemana" icon1="" icon2="" texto="Cambiar test" onClick={this.onClickHandleS} />
                </div>
                {this.props.tipoUser > 50 ? (
                  <div className='iMW-boton'>
                    <Boton2 id="editSemana" icon1="" icon2="" texto="Editar test" onClick={this.onClickHandleS} />
                  </div>
                ) : null}
              </div>

              <div className="SEW2010-TOPinfoSemana">
                <div> Test Nº {testWordLoad.numClase} - Intento {testWordLoad.vueltaNumero == null ? ("1") : (testWordLoad.vueltaNumero + 1)} </div>
                <div className="SEW2010-TIS-descargas">
                  <div id="descargaTest" onClick={this.onClickHandleS}>Descargar test - </div>
                  <div id="descargaSolucion" onClick={this.onClickHandleS} style={{ marginLeft: '3px' }}>Descargar solución </div>
                </div>
              </div>

              <div className="SEW2010-contenido">

                <div className="SEW2010-C-estudio">
                  <div className="SEW2010-C-e-estudiar">
                    <div> <b className="titleInfoAestudiar2">Última puntuación</b>{' '}{testWordLoad.puntuacionTest != null ? (parseFloat(testWordLoad.puntuacionTest).toFixed(2)) : ('Aun no completó el test.')} </div>
                  </div>
                  <div className="SEW2010-C-e-estudiar">
                    <div className="titleInfoAestudiar2"> Nombre </div>
                    <div className="erContenidoInfoAestudiar2"> {ReactHtmlParser(testWordLoad.nombreEjercicio)} </div>
                  </div>

                  <div className="SEW2010-C-e-ejercicios">
                    <div className="titleInfoAestudiar2"> Información </div>
                    <div className="erContenidoInfoAestudiar2"> {ReactHtmlParser(testWordLoad.contenidoTarjeta)} </div>
                  </div>

                  <div className="SEW2010-C-e-completarTest">
                    <div className="SEW2010-C-e-CT-info">
                      Introduzca los siguientes datos para completar el test.
                    </div>
                    <div className="SEW2010-C-e-CT-top" style={{ marginBottom: '10px' }}>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                        <Input2 readOnly={true} value={testWordLoad.num_preg_test.toString()} autoComplete={"off"} type="text" name="numPreg" placeholder="Nº Preguntas Test" icon="fas fa-clipboard-list" ref="ref_numPreg" onChange={this.onChangeInput} />
                        <p>Nº Preguntas Test</p>
                      </div>
                    </div>
                    <div className="SEW2010-C-e-CT-bottom">
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                        <Input2 autoComplete={"off"} type="text" name="correctas" placeholder="Correctas" icon="far fa-check-circle" ref="ref_correctas" onChange={this.onChangeInput} />
                        <p>Correctas</p>
                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                        <Input2 autoComplete={"off"} type="text" name="Erroneas" placeholder="Erroneas" icon="far fa-times-circle" ref="ref_Erroneas" onChange={this.onChangeInput} />
                        <p>Erroneas</p>
                      </div>
                      <div className="celdaInputDAPFBN" style={{ maxWidth: '170px' }}>
                        <Input2 autoComplete={"off"} type="text" name="enBlanco" placeholder="En Blanco" icon="far fa-circle" ref="ref_enBlanco" onChange={this.onChangeInput} />
                        <p>En Blanco</p>
                      </div>
                    </div>
                    <div className="SEW2010-C-e-CT-boton">
                      <div className='iMW-boton'>
                        <Boton id="enviarYcompletar" icon1="" icon2="" texto="Enviar y completar" onClick={this.onClickHandleS} />
                      </div>
                    </div>
                  </div>

                </div>

                <div className="SEW2010-C-img">
                  <img src="../img/wavingHand.png" />
                </div>

              </div>
            </React.Fragment>
          ) : null}

        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    fotoPerfil: state.user.fotoPerfil
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Word201SemanaEstudio);

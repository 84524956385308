// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/inputTextArea.css';

class InputTextArea extends Component {

  static propTypes = {
	  valueDefault: PropTypes.string,
	  name: PropTypes.string.isRequired,
	  id: PropTypes.string,
	  title: PropTypes.string,
	  width: PropTypes.string,
	  height: PropTypes.string,
	  value: PropTypes.string

  };

constructor(props){
        super(props);

          this.onChangeInput = this.onChangeInput.bind(this);
		this.onCleanValue = this.onCleanValue.bind(this);
    }
    onChangeInput(e){
        this.props.onChange(e);
    }
onCleanValue(e){
	if(e.detail === 3){
		this.refs[''+e.target.name].value = ''
		this.props.onChange(e)
	}
}

  render() {
    const { id, name, width, title, height, valueDefault, value } = this.props;
              return (
                <div style={{width: width}}>
			 	<textarea className="textareaInput" style={{width: width , height: height}} id={id} title={title} name={name} ref={name} value={value} placeholder={valueDefault} onChange={this.onChangeInput} />
			 </div>
              );

        }
 }

export default InputTextArea;

// Actions
const LOAD_TEST = 'LOAD_TEST';
const LOAD_CASO_PRACTICO = 'LOAD_CASO_PRACTICO';
export function loadTestJusticia(test) {
    return {
	    type: LOAD_TEST,
      value: test
    };
}

export function loadCasoPractico(test) {
  console.log('Entro loadCasoPractico')
    return {
	    type: LOAD_CASO_PRACTICO,
      value: test
    };
}

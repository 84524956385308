// Dependencies
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';


// Actions
import { iniciarSesion } from '../../../actions/userAction';

// Assets
import './css/Login.css';

import Footer from '../../../components/Global/Footer/Footer';
import Boton from '../../../components/Inputs/Boton';
import Input2 from '../../../components/Inputs/Input2';

class Login extends Component {
  static propTypes = {
    iniciarSesion: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string,
    tipoUser: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      dniUser: undefined,
      passwordUserInput: undefined
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleLogin = this.onHandleLogin.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }
  async keyDown(e) {

    if (e.charCode == 13) {
      let { dniUser, passwordUserInput } = this.state
      if (dniUser && dniUser.length > 5) {
        this.setState({ checkedDni: undefined })
        this.setState({ checkedPass: undefined })

        try {
          let data = { identificador: dniUser }
          let response1 = await fetch("https://oposiciones-justicia.es/api/login/hashLog", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {

            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {

            let comprobacion = bcrypt.compareSync(passwordUserInput, cls1[0].password);
            if (comprobacion) {

              await this.setState({ checkedPass: true })
              swal({
                title: 'Procesando Login',
                text: 'Realizando comprobaciones necesarias',
                icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })

              // let response0 = await fetch("https://geolocation-db.com/json/85249190-4601-11eb-9067-21b51bc8dee3");
              // if (!response0.ok) {
              //   throw Error(response0.statusText);
              // }
              // let json0 = await response0.json();
              // let cls0 = await json0;

              // let dataIp = await cls0

              let infoCheckLogin = await cls1;
              // if (cls1[0].activo == 1 && ipUser != undefined) {
              if (cls1[0].activo == 1) {
                swal('Inicio de sesión exitoso', 'Bienvenid@ al campus virtual de CEJ', 'success')
                await this.props.iniciarSesion(dniUser, passwordUserInput, infoCheckLogin, 'dataIp')
              } else {
                swal('Acceso web desactivado', 'No tiene permitido el acceso, para reactivar su cuenta, comuniquese con CEJ.', 'info')
              }
            } else {
              this.setState({ checkedPass: false })
            }


          } else {
            this.setState({ checkedDni: false })
          }

        } catch (e) { console.log(e) }

      } else {
        this.setState({ checkedDni: false })
      }
    }
  }

  async onHandleLogin(e) {
    if (e.target.id == "tryLogin") {
      // this.props.iniciarSesion('dniUser', 'passwordUserInput', 'infoCheckLogin', 'ipUser')
      let { dniUser, passwordUserInput } = this.state
      if (dniUser && dniUser.length > 5) {
        this.setState({ checkedDni: undefined })
        this.setState({ checkedPass: undefined })

        try {
          let data = { identificador: dniUser }
          let response1 = await fetch("https://oposiciones-justicia.es/api/login/hashLog", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {

            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;
          if (cls1 != undefined && cls1.length > 0) {


            let comprobacion = bcrypt.compareSync(passwordUserInput, cls1[0].password);
            if (comprobacion) {

              await this.setState({ checkedPass: true })
              swal({
                title: 'Procesando Login',
                text: 'Realizando comprobaciones necesarias',
                icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })
              // let response0 = await fetch("https://geolocation-db.com/json/85249190-4601-11eb-9067-21b51bc8dee3");
              // if (!response0.ok) {
              //   throw Error(response0.statusText);
              // }
              // let json0 = await response0.json();
              // let cls0 = await json0;

              // let dataIp = await cls0

              let infoCheckLogin = await cls1;
              // if (cls1[0].activo == 1 && ipUser != undefined) {
              if (cls1[0].activo == 1) {
                swal('Inicio de sesión exitoso', 'Bienvenid@ al campus virtual de CEJ', 'success')
                await this.props.iniciarSesion(dniUser, passwordUserInput, infoCheckLogin, 'dataIp')
              } else {
                swal('Acceso web desactivado', 'No tiene permitido el acceso, para reactivar su cuenta, comuniquese con CEJ.', 'info')
              }
            } else {
              this.setState({ checkedPass: false })
            }


          } else {
            this.setState({ checkedDni: false })
          }

        } catch (e) { console.log(e) }

      } else {
        this.setState({ checkedDni: false })
      }
    }
  }
  onChangeInput(e) {

    if (e.target.name == 'dniInput') {
      this.setState({ checkedDni: undefined })
      this.setState({ dniUser: e.target.value });
    } else if (e.target.name == "passwordInput") {
      this.setState({ checkedPass: undefined })
      this.setState({ passwordUserInput: e.target.value });
    }

  }
  componentDidMount() {
    
    
    // if(window.location.href.split('https://www.').length > 1){
    //   window.location.href = 'https://oposiciones-justicia.es/'
    // } <Redirect to ="devCEJ/escritorio" /> cambiarlo parra la app live! ************
  }
  render() {
    let { checkedDni, checkedPass } = this.state
    if (this.props.isLogged) {
      return (<Redirect to="/escritorio" />);
    } else {
      return (
        <div className="contentLoginCEJ">
          <div className="bodyLoginNew">


            <div className="Login">
              <div className="logoLoginCej">
                <img src="../img/rev1LogoWeb.png" />
              </div>

              <div className="Title_Registro">
                <div className="tituloLogin">
                  <h3> Iniciar sesión</h3>
                </div>

                <p>Bienvenid@ al campus virtual de CEJ.</p>

              </div>

              <form className="form_registro" action="" method="">

                <Input2 mensajeError="Dni incorrecto." checked={checkedDni} marginBottom={"10px"} type="text" name="dniInput" placeholder="Dni" icon="fas fa-user-tag" dataref="ref_dniInput" onChange={this.onChangeInput} onKeyPress={this.keyDown} />


                <Input2 mensajeError="Contraseña erronea" checked={checkedPass} marginBottom={"0px"} type="password" name="passwordInput" placeholder="Contraseña" icon="fas fa-key" ref="ref_passwordInput" onChange={this.onChangeInput} onKeyPress={this.keyDown} />

                <div style={{ marginTop: '25px' }}>
                  <Boton id="tryLogin" icon1="" icon2="" texto="Iniciar sesión" onClick={this.onHandleLogin} />
                </div>

              </form>

            </div>


            <div className="boxLoginRegistro">
              ¿No tienes cuenta? - <b>Clic aquí</b>
            </div>

            <div className="bottomLogin">
              <Footer />
            </div>

          </div>

          <div className="backgroundImgLogin">
            <img src="../img/Archivo_000.jpg" />
          </div>
        </div>
      );
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    iniciarSesion
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);

/*  <div className="Registro">
    <div>¿No tienes cuenta?</div>
    <div id="registro_aqui" onClick={this.hashPassword}> <i className="fas fa-arrow-right"></i>Registrate Aquí<i className="fas fa-arrow-left"></i> </div>
  </div>*/

// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
//Assets
import './facturarCSS.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input2 from '../../../../../../components/Inputs/Input2';
import Boton from '../../../../../../components/Inputs/Boton';
import Boton2 from '../../../../../../components/Inputs/Boton2';
import swal from 'sweetalert';
import { toNamespacedPath } from 'path';
// Actions
// import { cargarContenido } from './actions/baroptionAction';


class Facturar extends Component {
    constructor(props) {

        super(props)
        this.state = {
            valuesTipoFactura: [
                {
                    value: 'PRESENCIAL',
                    name: 'Presencial'
                }, {
                    value: 'ONLINE',
                    name: 'Online Video-conferencia'
                }, {
                    value: 'word 2010',
                    name: 'Word 2010'
                }
            ],
            busquedaActivos: [
                {
                    value: 0,
                    name: 'NO'
                }, {
                    value: 1,
                    name: 'SI'
                }
            ],
            busquedaPagado: [
                {
                    value: 'Pagado',
                    name: 'Pagado'
                }, {
                    value: 'Pendiente',
                    name: 'Pendiente'
                }
            ],
            busquedaMetodoPagado: [
                {
                    value: 'Metalico',
                    name: 'Metalico'
                }, {
                    value: 'card,sepa_debit',
                    name: 'Campus'
                }
            ],
            facturacion: {
                faseProceso: 1,
                tipo: '',
                tipoSelect: '',
                alumnos: [],
                clases: []
            },
            verAlumnos: true,
            verClases: true,
            alumnoSelectedToEDT: null,
            clasealumnoSelectedToEDT: null,
            locaClaseSelectdEdt: null

        }

        this.handleToggleClick = this.handleToggleClick.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }
    handleToggleClick(e) {
        if (e.target.id == 'cambiarTipoFac') {
            let fac = this.state.facturacion
            fac = {
                faseProceso: 1,
                tipo: '',
                tipoSelect: '',
                alumnos: [],
                clases: []
            }
            this.setState({ facturacion: fac })

        } else if (e.target.id == 'cambiarQueFACTURAMOS') {
            let fac = this.state.facturacion
            fac.tipoSelect = ''
            fac.faseProceso = 2
            this.setState({ facturacion: fac })
            cambiarQueFACTURAMOS
        } else if (e.target.id == 'alumno') {
            let fac = this.state.facturacion
            fac.alumnos.push({
                idUser: '',
                nombre: '',
                apellidos: '',
                dni: '',
                idClase: '',
                fechaInicio: '',
                fechaFin: '',
                precio: '',
                estado: 'Pendiente',
                nombreClase: '',
                fotoPerfil: '',
                activo: 'SI'
            })
            this.setState({ facturacion: fac })
        } else if (e.target.id == 'clase') {
            let fac = this.state.facturacion
            fac.clases.push({
                idClase: '',
                nombreClase: '',
                alumnos: []
            })
            this.setState({ facturacion: fac })
        } else if (e.target.id == 'verAlumnos') {
            this.setState({ verAlumnos: !this.state.verAlumnos })
        } else if (e.target.id == 'verClases') {
            this.setState({ verClases: !this.state.verClases })
        } else if (e.target.id.split('-')[0] == 'alumnoSelectedToEDT') {
            let loca = e.target.id.split('-')[1]

            this.setState({ alumnoSelectedToEDT: loca })

        } else if (e.target.id.split('-')[0] == 'alumnoSelectedToEDTClase') {
            let loca = e.target.id.split('-')[1]
            let loca2 = e.target.id.split('-')[2]
            this.setState({ clasealumnoSelectedToEDT: loca2, locaClaseSelectdEdt: loca })

        } else if (e.target.id == 'salirEditarAlumno') {
            this.setState({ alumnoSelectedToEDT: null, clasealumnoSelectedToEDT: null, locaClaseSelectdEdt: null })
        } else if (e.target.id.split('-')[0] == 'alumnoDeleteClase') {
            let locaA = e.target.id.split('-')[1]
            let locaB = e.target.id.split('-')[2]

            let fac = this.state.facturacion

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, el alumno será eliminado de la clase.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fac.clases[locaA].alumnos.splice(locaB, 1)
                        this.setState({ facturacion: fac })
                    }
                });


        } else if (e.target.id.split('-')[0] == 'alumnoDelete') {
            let locaA = e.target.id.split('-')[1]

            let fac = this.state.facturacion
            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, el alumno será eliminado.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fac.alumnos.splice(locaA, 1)
                        this.setState({ facturacion: fac })
                    }
                });


        } else if (e.target.id.split('-')[0] == 'eliminarClase') {
            let locaA = e.target.id.split('-')[1]

            let fac = this.state.facturacion

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, la clase será eliminada.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fac.clases.splice(locaA, 1)
                        this.setState({ facturacion: fac })
                    }
                });


        } else if (e.target.id == 'eliminarClases') {
            let fac = this.state.facturacion
            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, las clases serán eliminadas.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fac.clases = []
                        this.setState({ facturacion: fac })
                    }
                });


        } else if (e.target.id == 'eliminarAlumnos') {
            let fac = this.state.facturacion
            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, todos los alumnos serán eliminados.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        fac.alumnos = []
                        this.setState({ facturacion: fac })
                    }
                });


        } else if (e.target.id == 'cambiosTodosAlumnos') {

            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                // cambiamos todo como el alumno seleccionado
                let alumnoS = fac.alumnos[locaA]
                for (let i = 0; i < fac.alumnos.length; i++) {
                    if (fac.alumnos[i].idUser != '' && fac.alumnos[i].idUser != null) {
                        fac.alumnos[i].idClase = alumnoS.idClase
                        fac.alumnos[i].fechaInicio = alumnoS.fechaInicio
                        fac.alumnos[i].fechaFin = alumnoS.fechaFin
                        fac.alumnos[i].precio = alumnoS.precio
                        fac.alumnos[i].nombreClase = alumnoS.nombreClase
                        fac.alumnos[i].estado = alumnoS.estado
                        fac.alumnos[i].activo = alumnoS.activo
                    }

                }


                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos "individual"', 'info')
            } else if (locaB != null) {
                // cambiamos todo como el alumno seleccionado
                let locaClase = this.state.locaClaseSelectdEdt
                let alumnoS = fac.clases[locaClase].alumnos[locaB]
                for (let i = 0; i < fac.alumnos.length; i++) {
                    if (fac.alumnos[i].idUser != '' && fac.alumnos[i].idUser != null) {
                        fac.alumnos[i].idClase = alumnoS.idClase
                        fac.alumnos[i].nombreClase = alumnoS.nombreClase
                        fac.alumnos[i].fechaInicio = alumnoS.fechaInicio
                        fac.alumnos[i].fechaFin = alumnoS.fechaFin
                        fac.alumnos[i].precio = alumnoS.precio
                        fac.alumnos[i].estado = alumnoS.estado
                        fac.alumnos[i].activo = alumnoS.activo
                    }

                }

                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos "individual"', 'info')
            }
        } else if (e.target.id == 'cambiosTodosClases') {

            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                // cambiamos todo como el alumno seleccionado
                let alumnoS = fac.alumnos[locaA]
                for (let i = 0; i < fac.clases.length; i++) {
                    for (let j = 0; j < fac.clases[i].alumnos.length; j++) {
                        if (fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null) {
                            fac.clases[i].alumnos[j].fechaInicio = alumnoS.fechaInicio
                            fac.clases[i].alumnos[j].fechaFin = alumnoS.fechaFin
                            fac.clases[i].alumnos[j].precio = alumnoS.precio
                            fac.clases[i].alumnos[j].estado = alumnoS.estado
                            fac.clases[i].alumnos[j].activo = alumnoS.activo
                        }
                    }
                }

                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos en clases', 'info')
            } else if (locaB != null) {
                // cambiamos todo como el alumno seleccionado
                let locaClase = this.state.locaClaseSelectdEdt
                let alumnoS = fac.clases[locaClase].alumnos[locaB]

                for (let i = 0; i < fac.clases.length; i++) {
                    for (let j = 0; j < fac.clases[i].alumnos.length; j++) {
                        if (fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null) {
                            fac.clases[i].alumnos[j].fechaInicio = alumnoS.fechaInicio
                            fac.clases[i].alumnos[j].fechaFin = alumnoS.fechaFin
                            fac.clases[i].alumnos[j].precio = alumnoS.precio
                            fac.clases[i].alumnos[j].estado = alumnoS.estado
                            fac.clases[i].alumnos[j].activo = alumnoS.activo
                        }
                    }
                }


                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos en clases', 'info')
            }


        } else if (e.target.id == 'cambiosTodosAlumnosClases') {
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                // cambiamos todo como el alumno seleccionado
                let alumnoS = fac.alumnos[locaA]
                for (let i = 0; i < fac.clases.length; i++) {
                    for (let j = 0; j < fac.clases[i].alumnos.length; j++) {
                        if (fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null) {
                            fac.clases[i].alumnos[j].fechaInicio = alumnoS.fechaInicio
                            fac.clases[i].alumnos[j].fechaFin = alumnoS.fechaFin
                            fac.clases[i].alumnos[j].precio = alumnoS.precio
                            fac.clases[i].alumnos[j].estado = alumnoS.estado
                            fac.clases[i].alumnos[j].activo = alumnoS.activo
                        }
                    }
                }

                for (let x = 0; x < fac.alumnos.length; x++) {
                    if (fac.alumnos[x].idUser != '' && fac.alumnos[x].idUser != null) {
                        fac.alumnos[x].idClase = alumnoS.idClase
                        fac.alumnos[x].fechaInicio = alumnoS.fechaInicio
                        fac.alumnos[x].fechaFin = alumnoS.fechaFin
                        fac.alumnos[x].precio = alumnoS.precio
                        fac.alumnos[x].nombreClase = alumnoS.nombreClase
                        fac.alumnos[x].estado = alumnoS.estado
                        fac.alumnos[x].activo = alumnoS.activo
                    }

                }



                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos y de los alumnos en clases', 'info')
            } else if (locaB != null) {
                // cambiamos todo como el alumno seleccionado
                let locaClase = this.state.locaClaseSelectdEdt
                let alumnoS = fac.clases[locaClase].alumnos[locaB]

                for (let i = 0; i < fac.clases.length; i++) {
                    for (let j = 0; j < fac.clases[i].alumnos.length; j++) {
                        if (fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null) {
                            fac.clases[i].alumnos[j].fechaInicio = alumnoS.fechaInicio
                            fac.clases[i].alumnos[j].fechaFin = alumnoS.fechaFin
                            fac.clases[i].alumnos[j].precio = alumnoS.precio
                            fac.clases[i].alumnos[j].estado = alumnoS.estado
                            fac.clases[i].alumnos[j].activo = alumnoS.activo
                        }
                    }
                }

                for (let x = 0; x < fac.alumnos.length; x++) {
                    if (fac.alumnos[x].idUser != '' && fac.alumnos[x].idUser != null) {
                        fac.alumnos[x].idClase = alumnoS.idClase
                        fac.alumnos[x].nombreClase = alumnoS.nombreClase
                        fac.alumnos[x].fechaInicio = alumnoS.fechaInicio
                        fac.alumnos[x].fechaFin = alumnoS.fechaFin
                        fac.alumnos[x].precio = alumnoS.precio
                        fac.alumnos[x].estado = alumnoS.estado
                        fac.alumnos[x].activo = alumnoS.activo
                    }

                }

                this.setState({ facturacion: fac })
                swal('Cambios aplicados', 'Se cambiaron los datos de todos los alumnos y de los alumnos en clases', 'info')
            }
        } else if (e.target.id == 'facturar') {

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, se facturan todos los alumnos que puede ver",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {

                        swal({
                            title: 'Creando facturas',
                            text: ' ',
                            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                            buttons: false,
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                        })

                        let fac = this.state.facturacion
                        let alumnosError = []
                        let alumnosClasesError = []
                        let alumnos = fac.alumnos.filter(a => a.idUser != '' && a.idUser != null)
                        let clases = fac.clases.filter(a => a.alumnos.filter(b => b.idUser != null && b.idUser != '').length > 0)

                        if (alumnos.length > 0) {

                            for (let x = 0; x < fac.alumnos.length; x++) {
                                if (
                                    fac.alumnos[x].idUser != '' && fac.alumnos[x].idUser != null &&
                                    fac.alumnos[x].idClase != '' && fac.alumnos[x].idClase != null &&
                                    fac.alumnos[x].fechaInicio != '' && fac.alumnos[x].fechaInicio != null &&
                                    fac.alumnos[x].fechaFin != '' && fac.alumnos[x].fechaFin != null &&
                                    fac.alumnos[x].precio != '' && fac.alumnos[x].precio != null &&
                                    fac.alumnos[x].nombreClase != '' && fac.alumnos[x].nombreClase != null &&
                                    fac.alumnos[x].estado != '' && fac.alumnos[x].estado != null &&
                                    fac.alumnos[x].activo != '' && fac.alumnos[x].activo != null &&
                                    fac.alumnos[x].metodoPago != '' && fac.alumnos[x].metodoPago != null
                                ) {
                                    // insertamos factura
                                    let alumnoS = fac.alumnos[x]

                                    let factura = {
                                        idClase: alumnoS.idClase,
                                        idAlumno: alumnoS.idUser,
                                        idCobrador: this.props.idUserP,
                                        totalPagado: alumnoS.precio,
                                        metodoPago: alumnoS.metodoPago == 'Metalico' ? ('Metalico') : ('card,sepa_debit'),
                                        estado: alumnoS.estado,
                                        datosActivados: alumnoS.activo == 'SI' ? (1) : (0),
                                        sucripcion: fac.tipo == 'Word 2010' ? ('Cuota mensual Word 2010') : ('Cuota Mensual clase de CEJ'),
                                        concepto: fac.tipo == 'Word 2010' ? ('Cuota mensual Word 2010') : ('Factura emitada el: ' + moment().format("YYYY-MM-DD HH:mm:ss") + '. Alumno: ' + alumnoS.nombre + ', ' + alumnoS.apellidos + '. Mes pagado de la clase: ' + alumnoS.nombreClase + '.'),
                                        fechaFactura: moment(alumnoS.fechaInicio).format("YYYY-MM-DD 00:00:01"),
                                        fechaCaducidad: moment(alumnoS.fechaFin).format("YYYY-MM-DD 23:59:59"),
                                        tipo: fac.tipo == 'Word 2010' ? (100) : (0)
                                    }

                                    let response1 = await fetch('https://oposiciones-justicia.es/api/oficina/addFactura', {
                                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(factura)
                                    });
                                    if (!response1.ok) {
                                        throw Error(response1.statusText);
                                    }
                                    let json1 = await response1.json();
                                    let cls1 = await json1;

                                    let data3 = {
                                        id_clase: alumnoS.idClase,
                                        id_usuario: alumnoS.idUser,
                                        activado: 1,
                                        activarTemas: null
                                    }
                                    let url2 = 'https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo'

                                    if (fac.tipo == 'Word 2010') {
                                        url2 = 'https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfoWord'
                                    }
                                    let response2 = await fetch(url2, {
                                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                                    });
                                    if (!response2.ok) {
                                        throw Error(response2.statusText);
                                    }
                                    let json2 = await response2.json();
                                    let cls2 = await json2;

                                    let data4 = {
                                        id_usuario: alumnoS.idUser,
                                        acceso: 1
                                    }
                                    let url3 = 'https://oposiciones-justicia.es/api/oficina/editUserActivado'

                                    let response3 = await fetch(url3, {
                                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                                    });
                                    if (!response3.ok) {
                                        throw Error(response3.statusText);
                                    }
                                    let json3 = await response3.json();
                                    let cls3 = await json3;

                                } else {

                                    if (fac.alumnos[x].idUser != '' && fac.alumnos[x].idUser != null) {
                                        alumnosError.push(fac.alumnos[x])
                                    }

                                }

                            }
                        }

                        if (clases.length > 0) {

                            for (let i = 0; i < fac.clases.length; i++) {
                                for (let j = 0; j < fac.clases[i].alumnos.length; j++) {

                                    if (
                                        fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null &&
                                        fac.clases[i].alumnos[j].idClase != '' && fac.clases[i].alumnos[j].idClase != null &&
                                        fac.clases[i].alumnos[j].fechaInicio != '' && fac.clases[i].alumnos[j].fechaInicio != null &&
                                        fac.clases[i].alumnos[j].fechaFin != '' && fac.clases[i].alumnos[j].fechaFin != null &&
                                        fac.clases[i].alumnos[j].precio != '' && fac.clases[i].alumnos[j].precio != null &&
                                        fac.clases[i].alumnos[j].nombreClase != '' && fac.clases[i].alumnos[j].nombreClase != null &&
                                        fac.clases[i].alumnos[j].estado != '' && fac.clases[i].alumnos[j].estado != null &&
                                        fac.clases[i].alumnos[j].activo != '' && fac.clases[i].alumnos[j].activo != null &&
                                        fac.clases[i].alumnos[j].metodoPago != '' && fac.clases[i].alumnos[j].metodoPago != null
                                    ) {
                                        // insertamos factura
                                        let alumnoS = fac.clases[i].alumnos[j]
                                        let factura = {
                                            idClase: alumnoS.idClase,
                                            idAlumno: alumnoS.idUser,
                                            idCobrador: this.props.idUserP,
                                            totalPagado: alumnoS.precio,
                                            metodoPago: alumnoS.metodoPago == 'Metalico' ? ('Metalico') : ('card,sepa_debit'),
                                            estado: alumnoS.estado,
                                            datosActivados: alumnoS.activo == 'SI' ? (1) : (0),
                                            sucripcion: fac.tipo == 'Word 2010' ? ('Cuota mensual Word 2010') : ('Cuota Mensual clase de CEJ'),
                                            concepto: fac.tipo == 'Word 2010' ? ('Cuota mensual Word 2010') : ('Factura emitada el: ' + moment().format("YYYY-MM-DD HH:mm:ss") + '. Alumno: ' + alumnoS.nombre + ', ' + alumnoS.apellidos + '. Mes pagado de la clase: ' + alumnoS.nombreClase + '.'),
                                            fechaFactura: moment(alumnoS.fechaInicio).format("YYYY-MM-DD 00:00:01"),
                                            fechaCaducidad: moment(alumnoS.fechaFin).format("YYYY-MM-DD 23:59:59"),
                                            tipo: fac.tipo == 'Word 2010' ? (100) : (0)
                                        }

                                        let response1 = await fetch('https://oposiciones-justicia.es/api/oficina/addFactura', {
                                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(factura)
                                        });
                                        if (!response1.ok) {
                                            throw Error(response1.statusText);
                                        }
                                        let json1 = await response1.json();
                                        let cls1 = await json1;

                                        let data3 = {
                                            id_clase: alumnoS.idClase,
                                            id_usuario: alumnoS.idUser,
                                            activado: 1,
                                            activarTemas: null
                                        }
                                        let url2 = 'https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo'

                                        if (fac.tipo == 'Word 2010') {
                                            url2 = 'https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfoWord'
                                        }
                                        let response2 = await fetch(url2, {
                                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                                        });
                                        if (!response2.ok) {
                                            throw Error(response2.statusText);
                                        }
                                        let json2 = await response2.json();
                                        let cls2 = await json2;

                                        let data4 = {
                                            id_usuario: alumnoS.idUser,
                                            acceso: 1
                                        }
                                        let url3 = 'https://oposiciones-justicia.es/api/oficina/editUserActivado'

                                        let response3 = await fetch(url3, {
                                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                                        });
                                        if (!response3.ok) {
                                            throw Error(response3.statusText);
                                        }
                                        let json3 = await response3.json();
                                        let cls3 = await json3;

                                    } else {
                                        if (fac.clases[i].alumnos[j].idUser != '' && fac.clases[i].alumnos[j].idUser != null) {
                                            alumnosClasesError.push(fac.alumnos[x])
                                        }
                                    }

                                }
                            }
                        }
                        // console.log('alumnos')
                        // console.log(alumnos)
                        // console.log('clases')
                        // console.log(clases)

                        if (alumnosError.length > 0 || alumnosClasesError.length > 0) {

                            fac.alumnos = alumnosError.length > 0 ? (alumnosError) : ([])

                            if (alumnosClasesError.length > 0) {
                                for (let z2 = 0; z2 < fac.clases.length; z2++) {
                                    let alumnosEfIL = alumnosClasesError.filter(aa => aa.idClase == fac.clases[z2].idClase)

                                    if (alumnosEfIL.length > 0) {
                                        fac.clases[z2].alumnos = alumnosEfIL
                                    } else {
                                        fac.clases[z2].alumnos = []
                                    }
                                }

                            } else {
                                fac.clases = []
                            }
                            this.setState({ facturacion: fac })
                            swal('Facturas creadas, algunos errores', 'Puede ver los alumnos que fallaron en la inserción de su factura, revise los datos y vuelta intentarlo "Solo quedan los alumnos que no se inserto su factura".', 'info')
                        } else {
                            this.setState({ facturacion: fac })
                            swal({
                                title: 'Facturas creadas con exito',
                                text: '¡Recargando web!',
                                icon: "success",
                                buttons: false,
                                closeOnEsc: false,
                                closeOnClickOutside: false,
                                timer: 2500,
                            }).then(() => {
                                window.location.reload()
                            })
                        }
                        this.setState({ facturacion: fac })


                    } else {
                        swal("Facturas no creadas", '', "success");
                    }
                });

        }



    }

    async onChangeInput(e) {
        if (e.target.id == 'tipoFac') {
            let value = e.target.value
            let valuesTipoFactura = this.state.valuesTipoFactura
            if (value != '' && valuesTipoFactura.findIndex(a => a.name == value) != -1) {
                let fac = this.state.facturacion
                fac.tipo = value
                fac.faseProceso = 2
                this.setState({ facturacion: fac })
            } else {
                let fac = this.state.facturacion
                fac.tipo = value
                fac.faseProceso = 1
                this.setState({ facturacion: fac })
            }
        } else if (e.target.id.split('-')[0] == 'alumnoFac') {
            let loca = e.target.id.split('-')[1]

            console.log('Info alumno')
            console.log(e.target.value)
            let value = e.target.value
            let fac = this.state.facturacion
            let busAlum = this.state.busquedaAlumnos.find(a => a.dni == value)
            console.log('busAlum')
            console.log(busAlum)
            if (busAlum != undefined) {

                let data1 = { id_usuario: busAlum.id_usuario }
                let url2 = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFac'

                if (fac.tipo == 'Word 2010') {
                    url2 = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFacWord'
                }
                let response1 = await fetch(url2, {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                });
                if (!response1.ok) {
                    throw Error(response1.statusText);
                }
                let json1 = await response1.json();
                let cls1 = await json1;

                console.log('cls1')
                console.log(cls1)
                if (cls1.length > 0) {
                    fac.alumnos[loca].idUser = cls1[0].idUsuario
                    fac.alumnos[loca].nombre = busAlum.nombre
                    fac.alumnos[loca].apellidos = busAlum.apellidos
                    fac.alumnos[loca].dni = cls1[0].dni
                    fac.alumnos[loca].idClase = cls1[0].idClase
                    fac.alumnos[loca].fechaUltimaFactura = moment(cls1[0].fechaUltimaFactura).format('YYYY-MM-DD')
                    fac.alumnos[loca].fechaUltimaFin = moment(cls1[0].fechaCaducidadUltimaFactura).format('YYYY-MM-DD')
                    fac.alumnos[loca].fechaInicio = moment(cls1[0].fechaUltimaFactura).add(1, 'M').startOf('month').format('YYYY-MM-DD')
                    fac.alumnos[loca].fechaFin = moment(cls1[0].fechaUltimaFactura).add(1, 'M').endOf('month').format('YYYY-MM-DD')
                    fac.alumnos[loca].precio = cls1[0].Precio == 0 ? ('0') : (cls1[0].Precio)
                    fac.alumnos[loca].nombreClase = cls1[0].nombreClase
                    fac.alumnos[loca].fotoPerfil = busAlum.link_foto_perfil
                    fac.alumnos[loca].tipo = cls1[0].tipo
                    fac.alumnos[loca].metodoPago = cls1[0].metodoPago == 'Metalico' || cls1[0].metodoPago == 'Transferencia' ? ('Metalico') : ('Campus')

                    this.setState({ facturacion: fac })
                } else {
                    fac.alumnos[loca].idUser = ''
                    fac.alumnos[loca].nombre = ''
                    fac.alumnos[loca].apellidos = ''
                    fac.alumnos[loca].dni = value
                    fac.alumnos[loca].idClase = ''
                    fac.alumnos[loca].fechaInicio = ''
                    fac.alumnos[loca].fechaFin = ''
                    fac.alumnos[loca].precio = ''
                    fac.alumnos[loca].nombreClase = ''
                    fac.alumnos[loca].fotoPerfil = ''
                    fac.alumnos[loca].metodoPago = ''
                    this.setState({ facturacion: fac })
                    swal('El alumno no esta añadido en ningun grupo', 'Añada el alumno a un grupo. Si el alumno es nuevo, debe registrarse desde TIENDA/PREPARACION', 'info')

                }

            } else {


                fac.alumnos[loca].idUser = ''
                fac.alumnos[loca].nombre = ''
                fac.alumnos[loca].apellidos = ''
                fac.alumnos[loca].dni = value
                fac.alumnos[loca].idClase = ''
                fac.alumnos[loca].fechaInicio = ''
                fac.alumnos[loca].fechaFin = ''
                fac.alumnos[loca].precio = ''
                fac.alumnos[loca].nombreClase = ''
                fac.alumnos[loca].fotoPerfil = ''
                fac.alumnos[loca].tipo = ''
                fac.alumnos[loca].metodoPago = ''
                this.setState({ facturacion: fac })
            }
            // buscar clases alumno + ultima factura automatico le ponemos el mes siguiente

        } else if (e.target.id == 'precio') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {

                fac.alumnos[locaA].precio = value

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt
                fac.clases[locaClase].alumnos[locaB].precio = value

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'claseA') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                let claseBusqueda = this.state.busquedaSemClasesAll.find(c => c.dia_semanal == value)
                if (claseBusqueda != undefined) {
                    fac.alumnos[locaA].idClase = claseBusqueda.id_clase
                    fac.alumnos[locaA].nombreClase = value
                } else {
                    fac.alumnos[locaA].nombreClase = value
                }


                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let claseBusqueda = this.state.busquedaSemClasesAll.find(c => c.dia_semanal == value)
                let locaClase = this.state.locaClaseSelectdEdt
                if (claseBusqueda != undefined) {
                    fac.clases[locaClase].alumnos[locaB].idClase = claseBusqueda.id_clase
                    fac.clases[locaClase].alumnos[locaB].nombreClase = value
                } else {
                    fac.clases[locaClase].alumnos[locaB].nombreClase = value
                }
                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'almActivo') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                fac.alumnos[locaA].activo = value

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt
                fac.clases[locaClase].alumnos[locaB].activo = value

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'fechaIni') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            console.log('value')
            console.log(value)
            if (locaA != null) {

                fac.alumnos[locaA].fechaInicio = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt

                fac.clases[locaClase].alumnos[locaB].fechaInicio = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'fechaFin') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                fac.alumnos[locaA].fechaFin = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt

                fac.clases[locaClase].alumnos[locaB].fechaFin = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'estadoPago') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                fac.alumnos[locaA].estado = value

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt
                fac.clases[locaClase].alumnos[locaB].estado = value

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id == 'metodoPago') {
            let value = e.target.value
            let locaA = this.state.alumnoSelectedToEDT
            let locaB = this.state.clasealumnoSelectedToEDT
            let fac = this.state.facturacion
            if (locaA != null) {
                fac.alumnos[locaA].metodoPago = value

                this.setState({ facturacion: fac })
            } else if (locaB != null) {
                let locaClase = this.state.locaClaseSelectdEdt
                fac.clases[locaClase].alumnos[locaB].metodoPago = value

                this.setState({ facturacion: fac })
            }
        } else if (e.target.id.split('-')[0] == 'clase') {
            let locaClase = e.target.id.split('-')[1]
            let value = e.target.value
            let fac = this.state.facturacion

            fac.clases[locaClase].nombreClase = value
            let busClaseCo = this.state.busquedaSemClasesAll.find(c => c.dia_semanal == value)
            if (busClaseCo != undefined && fac.clases.find(x => x.idClase == busClaseCo.id_clase) == undefined) {
                fac.clases[locaClase].idClase = busClaseCo.id_clase
                fac.clases[locaClase].nombreClase = value
                fac.clases[locaClase].alumnos = []
                this.setState({ facturacion: fac })

                // buscar alumnos clase y for del info factura

                let data0 = { id_clase: busClaseCo.id_clase }

                let url = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFacAnt'

                if (fac.tipo == 'Word 2010') {
                    url = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFacAntWord'
                }

                let response0 = await fetch(url, {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data0)
                });
                if (!response0.ok) {
                    throw Error(response0.statusText);
                }
                let json0 = await response0.json();
                let cls0 = await json0;

                if (cls0.length > 0) {

                    for (let i = 0; i < cls0.length; i++) {

                        let busAlum = this.state.busquedaAlumnos.find(a => a.id_usuario == cls0[i].idUser)
                        console.log('busAlum')
                        console.log(busAlum)

                        if (busAlum != undefined) {

                            let data1 = { id_usuario: busAlum.id_usuario }
                            let url2 = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFac'

                            if (fac.tipo == 'Word 2010') {
                                url2 = 'https://oposiciones-justicia.es/api/oficina/infoAlumnoClaseFacWord'
                            }
                            let response1 = await fetch(url2, {
                                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                            });
                            if (!response1.ok) {
                                throw Error(response1.statusText);
                            }
                            let json1 = await response1.json();
                            let cls1 = await json1;

                            cls1 = cls1.filter(x => x.Precio > 0)
                            console.log('cls1')
                            console.log(cls1)

                            // cls1[
                            //     {
                            //         "idClase": 16,
                            //         "idUsuario": 301,
                            //         "nombreClase": "Word 2010",
                            //         "idOposicion": null,
                            //         "activo": 1,
                            //         "dni": "77192574C",
                            //         "fechaUltimaFactura": "2023-09-30T22:00:00.000Z",
                            //         "idFac": 3850,
                            //         "fechaCaducidadUltimaFactura": "2023-10-31T22:59:59.000Z",
                            //         "metodoPago": "card,sepa_debit",
                            //         "Precio": 30,
                            //         "tipo": "Word 2010"
                            //     }
                            // ]
                            if (cls1.length > 0) {
                                if (busClaseCo.id_clase == cls1[0].idClase && cls1[0].activo != 0) {


                                    if (fac.tipo == 'Word 2010' && cls1[0].tipoR == 100) {
                                        fac.clases[locaClase].alumnos.push({
                                            idUser: cls1[0].idUsuario,
                                            nombre: busAlum.nombre,
                                            apellidos: busAlum.apellidos,
                                            dni: cls1[0].dni,
                                            idClase: cls1[0].idClase,
                                            fechaUltimaFactura: moment(cls1[0].fechaUltimaFactura).format('YYYY-MM-DD'),
                                            fechaUltimaFin: moment(cls1[0].fechaCaducidadUltimaFactura).format('YYYY-MM-DD'),
                                            fechaInicio: moment(cls1[0].fechaUltimaFactura).add(1, 'M').startOf('month').format('YYYY-MM-DD'),
                                            fechaFin: moment(cls1[0].fechaUltimaFactura).add(1, 'M').endOf('month').format('YYYY-MM-DD'),
                                            precio: cls1[0].Precio,
                                            nombreClase: cls1[0].nombreClase,
                                            fotoPerfil: busAlum.link_foto_perfil,
                                            estado: 'Pendiente',
                                            activo: 'SI',
                                            tipo: cls1[0].tipo,
                                            tipoR: cls1[0].tipoR,
                                            metodoPago: cls1[0].metodoPago == 'Metalico' || cls1[0].metodoPago == 'Transferencia' ? ('Metalico') : ('Campus')
                                        })
                                    } else if (fac.tipo != 'Word 2010') {
                                        fac.clases[locaClase].alumnos.push({
                                            idUser: cls1[0].idUsuario,
                                            nombre: busAlum.nombre,
                                            apellidos: busAlum.apellidos,
                                            dni: cls1[0].dni,
                                            idClase: cls1[0].idClase,
                                            fechaUltimaFactura: moment(cls1[0].fechaUltimaFactura).format('YYYY-MM-DD'),
                                            fechaUltimaFin: moment(cls1[0].fechaCaducidadUltimaFactura).format('YYYY-MM-DD'),
                                            fechaInicio: moment(cls1[0].fechaUltimaFactura).add(1, 'M').startOf('month').format('YYYY-MM-DD'),
                                            fechaFin: moment(cls1[0].fechaUltimaFactura).add(1, 'M').endOf('month').format('YYYY-MM-DD'),
                                            precio: cls1[0].Precio,
                                            nombreClase: cls1[0].nombreClase,
                                            fotoPerfil: busAlum.link_foto_perfil,
                                            estado: 'Pendiente',
                                            activo: 'SI',
                                            tipo: cls1[0].tipo,
                                            metodoPago: cls1[0].metodoPago == 'Metalico' || cls1[0].metodoPago == 'Transferencia' ? ('Metalico') : ('Campus')
                                        })
                                    }



                                    this.setState({ facturacion: fac })

                                }
                            }
                        }

                    }
                } else {
                    swal('Ningun alumno en clase', '', 'info')
                }






            } else {
                fac.clases[locaClase].nombreClase = value
                fac.clases[locaClase].alumnos = []
                fac.clases[locaClase].idClase = ''
                this.setState({ facturacion: fac })

                if (busClaseCo != undefined && fac.clases.find(x => x.idClase == busClaseCo.id_clase) != undefined) {
                    swal('Error, clase duplicada.', 'No puedes asignar 2 clases/grupos iguales. El que esta intentando poner ya', 'info')
                }
            }

        }
    }

    componentDidMount() {
        fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
            .then(res => res.json())
            .then(oposiciones => this.setState({
                busquedaOpo: oposiciones
            })).catch(error => console.log('Object fecth failed', error));

        fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
            .then(res => res.json())
            .then(clases => this.setState({
                busquedaSemClasesAll: clases
            })).catch(error => console.log('Object fecth failed', error));

        fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
            .then(res => res.json())
            .then(alumnos => this.setState({
                busquedaAlumnos: alumnos
            })).catch(error => console.log('Object fecth failed', error));
    }
    render() {
        const { isLogged, nombreUser } = this.props
        const { valuesTipoFactura, facturacion, busquedaOpo, busquedaAlumnos, busquedaSemClasesAll, verAlumnos, verClases, alumnoSelectedToEDT, busquedaActivos, busquedaPagado, clasealumnoSelectedToEDT, locaClaseSelectdEdt, busquedaMetodoPagado } = this.state

        console.log('busquedaSemClasesAll')
        console.log(busquedaSemClasesAll)

        console.log('busquedaAlumnos')
        console.log(busquedaAlumnos)

        console.log('busquedaOpo')
        console.log(busquedaOpo)

        console.log('facturacion')
        console.log(facturacion)
        return (
            <div className="bodyFacturarN">

                {facturacion.faseProceso == 1 ? (
                    <div className='bodyFacturarN-row'>
                        <h4> Seleccione el tipo de factura </h4>

                        <div className="celdaInputDAPFBN animacionBotonEntra">
                            <Input2
                                value={facturacion.tipo} datalist="dataTipoFac"
                                autoComplete={"off"} id={"tipoFac"} type="text" name={"tipoFac"} ref={"tipoFac"} placeholder="Tipo factura" icon="fa-light fa-hand-pointer" onChange={this.onChangeInput} />
                            <p>Tipo factura</p>
                            <datalist id="dataTipoFac">{
                                valuesTipoFactura && valuesTipoFactura.map((cls, key) =>
                                    <option key={key} value={cls.name}></option>
                                )
                            }</datalist>
                        </div>
                    </div>
                ) : (
                    clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null ? (
                        <div className='bodyFacturarN-row'>
                            <h4 style={{ border: 'unset' }}>
                                <i id="cambiarTipoFac" onClick={this.handleToggleClick} className="fa-light fa-circle-xmark"></i>
                                Factura {facturacion.tipo}
                            </h4>
                        </div>
                    ) : null

                )}

                {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && facturacion.faseProceso == 2 ? (

                    <div className='bodyFacturarN-row'>
                        <h4 style={{ border: 'unset' }}> ¿A quién facturamos? </h4>
                        <div className='rowBotnes'>
                            <Boton id="alumno" icon1="" icon2="" texto="Alumno" onClick={this.handleToggleClick} />
                            <Boton id="clase" icon1="" icon2="" texto="Clase" onClick={this.handleToggleClick} />
                        </div>
                    </div>


                ) : null
                }

                {facturacion.faseProceso == 2 ? (
                    <div className='bodyFacturarN-row'>


                        {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && facturacion.alumnos && facturacion.alumnos.length > 0 ? (
                            <h4>
                                <i id="eliminarAlumnos" onClick={this.handleToggleClick} class="fa-light fa-trash-can"></i>
                                Facturando por alumnos
                                <i title="Ocultar/Mostrar alumnos" id="verAlumnos" onClick={this.handleToggleClick} className={verAlumnos == true ? ("fa-light fa-angle-up") : ("fa-light fa-angle-down")}></i>
                            </h4>
                        ) : null}
                        {alumnoSelectedToEDT != null && facturacion.alumnos && facturacion.alumnos.length > 0 ? (
                            <h4>
                                <i title="Cerrar editar factura" id="salirEditarAlumno" onClick={this.handleToggleClick} className="fa-light fa-circle-xmark"></i>
                                Editando factura

                            </h4>
                        ) : null}
                        <div className="rowAlmClsDta">
                            {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && verAlumnos == true && facturacion.alumnos && facturacion.alumnos.length > 0 && facturacion.alumnos.map((alm, key1) =>
                                <div key={key1} className='cloumAlim animacionBotonEntra'>

                                    <div className='rowALUM'>
                                        <div className="RALM-navB">
                                            {alm.idUser != '' && alm.idUser != null ? (
                                                <i class="fa-light fa-gear" id={"alumnoSelectedToEDT-" + key1} onClick={this.handleToggleClick}></i>
                                            ) : null}
                                            <i className="fa-light fa-trash-can" id={"alumnoDelete-" + key1} onClick={this.handleToggleClick}></i>

                                        </div>


                                        <div className="celdaInputDAPFBN">
                                            <Input2
                                                value={alm.dni} datalist="busquedaAlumnos"
                                                autoComplete={"off"} id={"alumnoFac-" + key1} type="text" name={"alumnoFac"} ref={"alumnoFac"} placeholder="DNI Alumno" icon="fa-light fa-user-graduate" onChange={this.onChangeInput} />
                                            <p>Alumno</p>
                                        </div>

                                    </div>
                                    {alm.idUser != '' && alm.idUser != null ? (

                                        <div className='infoFacAlumnPrev' style={{
                                            alignSelf: 'flex-start',
                                            marginBottom: '5px',
                                            marginTop: '-10px'
                                        }}>
                                            <p style={{ color: '#3469b7' }}>
                                                {alm.nombre + ', ' + alm.apellidos}
                                            </p>
                                        </div>
                                    ) : null}
                                    {alm.idUser != '' && alm.idUser != null ? (
                                        <div className='infoFacAlumnPrev'>
                                            <p>{alm.nombreClase}</p>
                                            <p>{alm.precio + ' €'}</p>
                                            <p>{alm.fechaInicio} </p>

                                            <p>{alm.estado}</p>
                                        </div>
                                    ) : null}

                                </div>
                            )}

                            {
                                alumnoSelectedToEDT != null && verAlumnos == true && facturacion.alumnos && facturacion.alumnos.length > 0 && facturacion.alumnos.length >= alumnoSelectedToEDT ? (
                                    <div className='almFacturaEdt'>
                                        <img className="BE-CL-left-img33" src={facturacion.alumnos[alumnoSelectedToEDT].fotoPerfil} />
                                        <div className='almFacturaEdt-infoAlumno'>
                                            <p> ID:{' ' + facturacion.alumnos[alumnoSelectedToEDT].idUser} </p>
                                            <p> {facturacion.alumnos[alumnoSelectedToEDT].nombre + ', ' + facturacion.alumnos[alumnoSelectedToEDT].apellidos} </p>
                                            <br />
                                            <p> Ultima factura:{' ' + facturacion.alumnos[alumnoSelectedToEDT].fechaUltimaFactura + ' a ' + facturacion.alumnos[alumnoSelectedToEDT].fechaUltimaFin} </p>
                                        </div>
                                        <div className='rowALUM'>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].precio}
                                                    autoComplete={"off"} id={"precio"} type="float" name={"precio"} ref={"precio"} placeholder="Precio" icon="fa-light fa-euro-sign" onChange={this.onChangeInput} />
                                                <p>Precio</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].nombreClase} datalist="busClases"
                                                    autoComplete={"off"} id={"claseA"} type="text" name={"claseA"} ref={"claseA"} placeholder="Clase" icon="fa-light fa-screen-users" onChange={this.onChangeInput} />
                                                <p>Clase</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].activo} datalist="busquedaActivos"
                                                    autoComplete={"off"} id={"almActivo"} type="text" name={"almActivo"} ref={"almActivo"} placeholder="Activo: SI o NO" icon="fa-light fa-eye" onChange={this.onChangeInput} />
                                                <p>Activo</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].fechaInicio}
                                                    autoComplete={"off"} id={"fechaIni"} type="date" name={"fechaIni"} ref={"fechaIni"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                                <p>Fecha Inicio</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].fechaFin}
                                                    autoComplete={"off"} id={"fechaFin"} type="date" name={"fechaFin"} ref={"fechaFin"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                                <p>Fecha Fin</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].estado} datalist="busquedaPagado"
                                                    autoComplete={"off"} id={"estadoPago"} type="text" name={"estadoPago"} ref={"estadoPago"} placeholder="Pagado/Pendiente" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                                <p>Pagado</p>
                                            </div>


                                            <div className="celdaInputDAPFBN">
                                                <Input2
                                                    value={facturacion.alumnos[alumnoSelectedToEDT].metodoPago} datalist="busquedaMetodoPagado"
                                                    autoComplete={"off"} id={"metodoPago"} type="text" name={"metodoPago"} ref={"metodoPago"} placeholder="Metalico/Campus" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                                <p>Metodo de pago</p>
                                            </div>



                                        </div>
                                        <h4 style={{ border: 'unset', marginBottom: '10px', marginTop: '15px' }}> ¿Ya estan aplicados los cambios? </h4>
                                        <div className='rowBotnes2'>

                                            <Boton id="salirEditarAlumno" onClick={this.handleToggleClick} icon1="" icon2="" texto="Volver atrás" />

                                        </div>
                                        {/* <h4 style={{ border: 'unset', marginBottom: '10px', marginTop: '15px' }}> ¿Aplicamos cambios en masa? </h4> */}
                                        {/* <div className='rowBotnes2'>

                                            {facturacion.alumnos && facturacion.alumnos.length > 0 ? (
                                                <Boton id="cambiosTodosAlumnos" icon1="" icon2="" texto="Todos Alumnos" onClick={this.handleToggleClick} />
                                            ) : null}

                                            {facturacion.clases && facturacion.clases.length > 0 ? (
                                                <Boton id="cambiosTodosClases" icon1="" icon2="" texto="Todos Alumnos en clases" onClick={this.handleToggleClick} />
                                            ) : null}

                                            {facturacion.alumnos && facturacion.alumnos.length > 0 && facturacion.clases && facturacion.clases.length > 0 ? (
                                                <Boton id="cambiosTodosAlumnosClases" icon1="" icon2="" texto="Todos Alumnos + Alumnos Clases" onClick={this.handleToggleClick} />
                                            ) : null}


                                        </div> */}
                                        {/* {facturacion.alumnos[alumnoSelectedToEDT].nombre} */}

                                    </div>
                                ) : null
                            }
                        </div>

                        {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && facturacion.clases && facturacion.clases.length > 0 ? (
                            <h4>
                                <i id="eliminarClases" onClick={this.handleToggleClick} class="fa-light fa-trash-can"></i>
                                Facturando por clases
                                <i title="Ocultar/Mostrar clases" id="verClases" onClick={this.handleToggleClick} className={verClases == true ? ("fa-light fa-angle-up") : ("fa-light fa-angle-down")}></i>
                            </h4>
                        ) : null}
                        {clasealumnoSelectedToEDT != null && facturacion.clases && facturacion.clases.length > 0 ? (
                            <h4>
                                <i title="Cerrar editar factura" id="salirEditarAlumno" onClick={this.handleToggleClick} className="fa-light fa-circle-xmark"></i>
                                Editando factura

                            </h4>
                        ) : null}

                        <div className="rowAlmClsDta" style={{ flexFlow: 'column nowrap' }}>
                            {verClases == true && facturacion.clases && facturacion.clases.length > 0 && facturacion.clases.map((clase, key2) =>
                                <React.Fragment>
                                    {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null ? (


                                        <div key={key2} className='rowALUM'>
                                            <div className="RALM-navB">

                                                <i className="fa-light fa-trash-can" id={"eliminarClase-" + key2} onClick={this.handleToggleClick}></i>

                                            </div>


                                            <div key={key2} className="celdaInputDAPFBN animacionBotonEntra">
                                                <Input2
                                                    value={clase.nombreClase} datalist="busClases"
                                                    autoComplete={"off"} id={"clase-" + key2} type="text" name={"clase"} ref={"clase"} placeholder="Clase" icon="fa-light fa-magnifying-glass" onChange={this.onChangeInput} />
                                                <p>Clase</p>

                                            </div>

                                        </div>
                                    ) : null

                                    }

                                    {
                                        clase.idClase != null && clase.idClase != '' ? (
                                            <div className="rowAlmClsDta">
                                                {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && clase.alumnos && clase.alumnos.length > 0 && clase.alumnos.map((alm, key3) =>
                                                    <div key={key3} className='cloumAlim animacionBotonEntra'>

                                                        <div className='rowALUM'>
                                                            <div className="RALM-navB">
                                                                {alm.idUser != '' && alm.idUser != null ? (
                                                                    <i class="fa-light fa-gear" id={"alumnoSelectedToEDTClase-" + key2 + "-" + key3} onClick={this.handleToggleClick}></i>
                                                                ) : null}
                                                                <i className="fa-light fa-trash-can" id={"alumnoDeleteClase-" + key2 + "-" + key3} onClick={this.handleToggleClick}></i>

                                                            </div>


                                                            <div className="celdaInputDAPFBN">
                                                                <Input2
                                                                    value={alm.dni} datalist="busquedaAlumnos"
                                                                    autoComplete={"off"} id={"alumnoFac-" + key3} type="text" name={"alumnoFac"} ref={"alumnoFac"} placeholder="DNI Alumno" icon="fa-light fa-user-graduate" onChange={this.onChangeInput} />
                                                                <p>Alumno</p>
                                                            </div>

                                                        </div>
                                                        {alm.idUser != '' && alm.idUser != null ? (

                                                            <div className='infoFacAlumnPrev' style={{
                                                                alignSelf: 'flex-start',
                                                                marginBottom: '5px',
                                                                marginTop: '-10px'
                                                            }}>
                                                                <p style={{ color: '#3469b7' }}>
                                                                    {alm.nombre + ', ' + alm.apellidos}
                                                                </p>
                                                            </div>
                                                        ) : null}
                                                        {alm.idUser != '' && alm.idUser != null ? (
                                                            <div className='infoFacAlumnPrev'>
                                                                <p>{alm.nombreClase}</p>
                                                                <p>{alm.precio + ' €'}</p>
                                                                <p>{alm.fechaInicio} </p>

                                                                <p>{alm.estado}</p>
                                                            </div>
                                                        ) : null}

                                                    </div>
                                                )}


                                            </div>
                                        ) : null
                                    }
                                </React.Fragment>

                            )}

                            {
                                clasealumnoSelectedToEDT != null && locaClaseSelectdEdt != null && facturacion.clases[locaClaseSelectdEdt].alumnos && facturacion.clases[locaClaseSelectdEdt].alumnos.length > 0 && facturacion.clases[locaClaseSelectdEdt].alumnos.length >= clasealumnoSelectedToEDT ? (
                                    <div className='almFacturaEdt'>
                                        <img className="BE-CL-left-img33" src={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].fotoPerfil} />
                                        <div className='almFacturaEdt-infoAlumno'>
                                            <p> ID:{' ' + facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].idUser} </p>
                                            <p> {facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].nombre + ', ' + facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].apellidos} </p>
                                            <br />
                                            <p> Ultima factura:{' ' + facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].fechaUltimaFactura + ' a ' + facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].fechaUltimaFin} </p>
                                        </div>
                                        <div className='rowALUM'>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].precio}
                                                    autoComplete={"off"} id={"precio"} type="float" name={"precio"} ref={"precio"} placeholder="Precio" icon="fa-light fa-euro-sign" onChange={this.onChangeInput} />
                                                <p>Precio</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].nombreClase} datalist="busClases"
                                                    autoComplete={"off"} id={"claseA"} type="text" name={"claseA"} ref={"claseA"} placeholder="Clase" icon="fa-light fa-screen-users" onChange={this.onChangeInput} />
                                                <p>Clase</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].activo} datalist="busquedaActivos"
                                                    autoComplete={"off"} id={"almActivo"} type="text" name={"almActivo"} ref={"almActivo"} placeholder="Activo: SI o NO" icon="fa-light fa-eye" onChange={this.onChangeInput} />
                                                <p>Activo</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].fechaInicio}
                                                    autoComplete={"off"} id={"fechaIni"} type="date" name={"fechaIni"} ref={"fechaIni"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                                <p>Fecha Inicio</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].fechaFin}
                                                    autoComplete={"off"} id={"fechaFin"} type="date" name={"fechaFin"} ref={"fechaFin"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                                <p>Fecha Fin</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">

                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].estado} datalist="busquedaPagado"
                                                    autoComplete={"off"} id={"estadoPago"} type="text" name={"estadoPago"} ref={"estadoPago"} placeholder="Pagado/Pendiente" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                                <p>Pagado</p>
                                            </div>

                                            <div className="celdaInputDAPFBN">
                                                <Input2
                                                    value={facturacion.clases[locaClaseSelectdEdt].alumnos[clasealumnoSelectedToEDT].metodoPago} datalist="busquedaMetodoPagado"
                                                    autoComplete={"off"} id={"metodoPago"} type="text" name={"metodoPago"} ref={"metodoPago"} placeholder="Metalico/Campus" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                                <p>Metodo de pago</p>
                                            </div>




                                        </div>
                                        <h4 style={{ border: 'unset', marginBottom: '10px', marginTop: '15px' }}> ¿Ya estan aplicados los cambios? </h4>
                                        <div className='rowBotnes2'>

                                            <Boton id="salirEditarAlumno" onClick={this.handleToggleClick} icon1="" icon2="" texto="Volver atrás" />

                                        </div>
                                        {/* <h4 style={{ border: 'unset', marginBottom: '10px', marginTop: '15px' }}> ¿Aplicamos cambios en masa? </h4>
                                        <div className='rowBotnes2'>

                                            {facturacion.alumnos && facturacion.alumnos.length > 0 ? (
                                                <Boton id="alumno" icon1="" icon2="" texto="Todos Alumnos" onClick={this.handleToggleClick} />
                                            ) : null}

                                            {facturacion.clases && facturacion.clases.length > 0 ? (
                                                <Boton id="clase" icon1="" icon2="" texto="Todos Alumnos en clases" onClick={this.handleToggleClick} />
                                            ) : null}

                                            {facturacion.alumnos && facturacion.alumnos.length > 0 && facturacion.clases && facturacion.clases.length > 0 ? (
                                                <Boton id="clase" icon1="" icon2="" texto="Todos Alumnos + Alumnos Clases" onClick={this.handleToggleClick} />
                                            ) : null}


                                        </div> */}
                                        {/* {facturacion.alumnos[alumnoSelectedToEDT].nombre} */}

                                    </div>
                                ) : null
                            }
                        </div>



                        <datalist id="busquedaAlumnos">{
                            busquedaAlumnos && busquedaAlumnos.map((cls, key) =>
                                <option key={key} value={cls.dni}>{cls.nombre + ' ' + cls.apellidos}</option>
                            )
                        }</datalist>
                        <datalist id="busClases">{
                            busquedaSemClasesAll && busquedaSemClasesAll.filter(cc => cc.tipoClase == valuesTipoFactura.find(x => x.name == facturacion.tipo).value).map((c, key) =>
                                <option key={key} value={c.dia_semanal}></option>
                            )
                        }</datalist>
                        <datalist id="busquedaActivos">{
                            busquedaActivos && busquedaActivos.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>
                        <datalist id="busquedaPagado">{
                            busquedaPagado && busquedaPagado.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>

                        <datalist id="busquedaMetodoPagado">{
                            busquedaMetodoPagado && busquedaMetodoPagado.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>



                    </div>
                ) : null
                }


                {clasealumnoSelectedToEDT == null && alumnoSelectedToEDT == null && (facturacion.alumnos.length > 0 || facturacion.clases.length > 0) ? (
                    <div className='bodyFacturarN-row'>
                        <h4 style={{
                            border: 'unset',
                            marginTop: '40px',
                            marginBottom: '5px'
                        }}>
                            ¿Todo listo?
                        </h4>
                        <div className='rowBotnes'>
                            <Boton id="facturar" icon1="" icon2="" texto="Facturar" onClick={this.handleToggleClick} />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
// Conectamos Redux Store

function mapStateToProps(state) {
    return {
        isLogged: state.user.isLogged,
        nombreUser: state.user.nombreUser,
        tipoUser: state.user.tipoUser,
        idUserP: state.user.idUser,
        optionSelected: state.baroption.optionSelected,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // cargarContenido
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Facturar);


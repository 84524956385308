// Actions
const GEN_EDT_PREG = 'GEN_EDT_PREG';
const ADD_PREG_TO_EDT = 'ADD_PREG_TO_EDT';
const DEL_PREG_TO_EDT = 'DEL_PREG_TO_EDT';

export function genPregToDelete(preguntaInicial) {
    return {
	    type: GEN_EDT_PREG,
      value: preguntaInicial
    };
}
export function addPregunta(preguntaNew, numPreg) {
    return {
      type: ADD_PREG_TO_EDT,
      value: preguntaNew,
      numPreg: numPreg
    };
}
export function delPregunta(idPregToDel) {
    return {
	    type: DEL_PREG_TO_EDT,
      idPreg: idPregToDel
    };
}

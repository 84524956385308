// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Utils
import './css.css'
import { isDefined } from '../../lib/utils/is';
import ReactHtmlParser from 'react-html-parser';
//Componets
import { Redirect } from 'react-router-dom';

import Input2 from '../../components/Inputs/Input2';
import Submit from '../../components/Inputs/Submit';
import Boton from '../../components/Inputs/Boton';
import BotonFinolis from '../../components/Inputs/Boton3Finolis'

import InputFileExamDesa from '../../components/Inputs/InputFileExamDesa'

import ReactPlayer from 'react-player'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// Redux Imports
import { useSelector } from 'react-redux'


const ReformasLey = (props) => {

  const idUser = useSelector(state => state.user.idUser)
  const tipoUser = useSelector(state => state.user.tipoUser)
  const isLogged = useSelector(state => state.user.isLogged)
  const idClase = useSelector(state => state.user.idClase)
  const idOposicion = useSelector(state => state.user.idOposicion)
  const temasFacturados = useSelector(state => state.user.temasFacturados)

  const [paginacionReformas, setpaginacionReformas] = useState(null)
  const [inicioNumeracion, setinicioNumeracion] = useState(0)
  const [finNumeracion, setfinNumeracion] = useState(5)
  const [paginaLoad, setpaginaLoad] = useState(0)

  const [reformaLoad, setreformaLoad] = useState(null)
  const [reformasLoad, setreformasLoad] = useState(null)
  const [reformasLoadT, setreformasLoadT] = useState(null)

  const [reformaAdd, setreformaAdd] = useState(null)

  const [reformaInfoAdd, setreformaInfoAdd] = useState({
    oposicion: [],
    idLey: null,
    articulos: '',
    videoLink: '',
    titulo: '',
    texto: '',
    descargas: null
  })


  const [edtReforma, setedtReforma] = useState(null)
  const [reformaInfoEdt, setreformaInfoEdt] = useState(null)

  const [busquedaLeyes, setBusquedaLeyes] = useState(null)
  const [busquedaOpo, setBusquedaOpo] = useState(null)

  const [navS, setnavS] = useState(0)

  const refSiglasLey = useRef();
  const refArts = useRef();
  const refTitulo = useRef();
  const refVideo = useRef();

  useEffect(() => {
    // con el idOposicion cargamos el listado de reformas sino existe no cargamos nada

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes =>
        setBusquedaLeyes(leyes)
      )
      .catch(error => console.log('Obejet fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => setBusquedaOpo(oposiciones)

      ).catch(error => console.log('Object fecth failed', error));

    if (isDefined(idOposicion) && tipoUser < 50) {


      fetch('https://oposiciones-justicia.es/api/usuarios/reformasLegislacion')
        .then(res => res.json())
        .then(reformas => {

          setreformasLoad(reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).filter(a => a.idOposicion == idOposicion))

          let totalPg = reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).filter(a => a.idOposicion == idOposicion).length / 8
          let reTotalPg = Math.ceil(totalPg)

          let paginacion = []
          if (reTotalPg > 0) {
            // for (let i = 0; i < 50; i++) {
            for (let i = 0; i < reTotalPg; i++) {
              paginacion.push({
                page: i
              })

              setinicioNumeracion(0)
              setfinNumeracion(5)
            }
          }

          setpaginacionReformas(paginacion)
          setreformasLoadT(reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
        })
        .catch(error => console.log('Obejet fecth failed', error))

    } else {
      fetch('https://oposiciones-justicia.es/api/usuarios/reformasLegislacion')
        .then(res => res.json())
        .then(reformas => {
          let refFil = []
          for (let i = 0; i < reformas.length; i++) {
            
            let bus = refFil.findIndex(a=> a.id == reformas[i].id)

            if(bus == -1){
              refFil.push(reformas[i])
            }
          }
          setreformasLoad(refFil.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))

          let totalPg = refFil.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).length / 8
          let reTotalPg = Math.ceil(totalPg)

          let paginacion = []
          if (reTotalPg > 0) {
            // for (let i = 0; i < 50; i++) {
            for (let i = 0; i < reTotalPg; i++) {
              paginacion.push({
                page: i
              })

              setinicioNumeracion(0)
              setfinNumeracion(5)
            }
          }

          setpaginacionReformas(paginacion)
          setreformasLoadT(reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)))
        })
        .catch(error => console.log('Obejet fecth failed', error))
    }



    return () => {

    }
  }, [])

  const onChangeInput = (e) => {

    if (e.target.id == 'leyBusqueda') {

      let value = e.target.value
      if (value != '' && busquedaLeyes.find(l => l.siglas_ley == value) != undefined) {
        let reformas = reformasLoadT.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).filter(a => a.idOposicion == idOposicion && a.idLey == busquedaLeyes.find(l => l.siglas_ley == value).id_ley)

        setreformasLoad(reformas)

        let totalPg = reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).length / 8
        let reTotalPg = Math.ceil(totalPg)
        let paginacion = []
        if (reTotalPg > 0) {
          for (let i = 0; i < reTotalPg; i++) {
            paginacion.push({
              page: i
            })

            setinicioNumeracion(0)
            setfinNumeracion(5)
            setnavS(0)
            setpaginaLoad(0)
          }
        }

        setpaginacionReformas(paginacion)
      } else {
        let reformas = reformasLoadT.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).filter(a => a.idOposicion == idOposicion)

        setreformasLoad(reformas)

        let totalPg = reformas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).length / 8
        let reTotalPg = Math.ceil(totalPg)
        let paginacion = []
        if (reTotalPg > 0) {
          for (let i = 0; i < reTotalPg; i++) {
            paginacion.push({
              page: i
            })

            setinicioNumeracion(0)
            setfinNumeracion(5)
            setnavS(0)
            setpaginaLoad(0)
          }
        }

        setpaginacionReformas(paginacion)

      }
    } else if (e.target.id.split('-')[0] == 'opoSelected') {

      let val = e.target.id.split('-')[1]
      let value = e.target.value
      let reformed = structuredClone(reformaInfoAdd)

      let bus = busquedaOpo.find(o => o.nombre == value)

      if (bus != undefined) {
        reformed.oposicion[val].idOpo = bus.id_oposicion
        reformed.oposicion[val].nombre = bus.nombre
        setreformaInfoAdd(reformed)
      } else {
        reformed.oposicion[val].idOpo = null
        reformed.oposicion[val].nombre = value
        setreformaInfoAdd(reformed)
      }

    } else if (e.target.id == 'leySelected') {

      let value = e.target.value
      let reformed = structuredClone(reformaInfoAdd)

      let bus = busquedaLeyes.find(l => l.siglas_ley == value)

      if (bus != undefined) {
        reformed.idLey = bus.id_ley
        setreformaInfoAdd(reformed)
      } else {
        reformed.idLey = null
        setreformaInfoAdd(reformed)
      }

    } else if (e.target.id == 'artAfectados') {
      let reformed = structuredClone(reformaInfoAdd)
      reformed.articulos = e.target.value
      setreformaInfoAdd(reformed)
    } else if (e.target.id == 'textoReforma') {
      let reformed = structuredClone(reformaInfoAdd)
      reformed.titulo = e.target.value
      setreformaInfoAdd(reformed)

    } else if (e.target.id == 'videoLink') {
      let reformed = structuredClone(reformaInfoAdd)
      reformed.videoLink = e.target.value
      setreformaInfoAdd(reformed)

    }

  }

  const onChangeInputEdt = (e) => {

    if (e.target.id.split('-')[0] == 'opoSelected') {

      let val = e.target.id.split('-')[1]
      let value = e.target.value
      let reformed = structuredClone(reformaInfoEdt)

      let bus = busquedaOpo.find(o => o.nombre == value)

      if (bus != undefined) {
        reformed.oposicion[val].idOpo = bus.id_oposicion
        reformed.oposicion[val].nombre = bus.nombre
        setreformaInfoEdt(reformed)
      } else {
        reformed.oposicion[val].idOpo = null
        reformed.oposicion[val].nombre = value
        setreformaInfoEdt(reformed)
      }

    } else if (e.target.id == 'leySelected') {

      let value = e.target.value
      let reformed = structuredClone(reformaInfoEdt)

      let bus = busquedaLeyes.find(l => l.siglas_ley == value)

      if (bus != undefined) {
        reformed.idLey = bus.id_ley
        setreformaInfoEdt(reformed)
      } else {
        reformed.idLey = null
        setreformaInfoEdt(reformed)
      }

    } else if (e.target.id == 'artAfectados') {
      let reformed = structuredClone(reformaInfoEdt)
      reformed.articulos = e.target.value
      setreformaInfoEdt(reformed)
    } else if (e.target.id == 'textoReforma') {
      let reformed = structuredClone(reformaInfoEdt)
      reformed.titulo = e.target.value
      setreformaInfoEdt(reformed)

    } else if (e.target.id == 'videoLink') {
      let reformed = structuredClone(reformaInfoEdt)
      reformed.videoLink = e.target.value
      setreformaInfoEdt(reformed)

    }

  }

  const onHandleClick = async (e) => {

    if (e.target.id.split('-')[0] == 'nav') {

      let val = e.target.id.split('-')[1]

      let paginacionReformasOrder = paginacionReformas.sort((a, b) => a.page - b.page).slice(inicioNumeracion, finNumeracion)
      setnavS(val)
      setpaginaLoad(paginacionReformasOrder[val].page)

    } else if (e.target.id.split('-')[0] == 'adelante') {

      let nextIni = inicioNumeracion + 5
      let nextFin = finNumeracion + 5

      if (nextIni < paginacionReformas.length) {
        if (nextFin < paginacionReformas.length) {
          setinicioNumeracion(nextIni)
          setfinNumeracion(nextFin)
          setpaginaLoad(paginacionReformas[nextIni].page)
          setnavS(0)
        } else {
          setinicioNumeracion(nextIni)
          setfinNumeracion(paginacionReformas.length)
          setpaginaLoad(paginacionReformas[nextIni].page)
          setnavS(0)
        }
      } else {
        setinicioNumeracion(paginacionReformas.length - 5 > 0 ? (paginacionReformas.length - 5) : (0))
        setfinNumeracion(paginacionReformas.length)
        setpaginaLoad(paginacionReformas[paginacionReformas.length - 5 > 0 ? (paginacionReformas.length - 5) : (0)].page)
        setnavS(0)
      }

    } else if (e.target.id.split('-')[0] == 'atras') {

      let nextIni = inicioNumeracion - 5
      let nextFin = finNumeracion - 5

      if (nextIni > 0 && nextIni < paginacionReformas.length) {
        if (nextFin < paginacionReformas.length) {
          setinicioNumeracion(nextIni)
          setfinNumeracion(nextFin)
          setpaginaLoad(paginacionReformas[nextIni].page)
          setnavS(0)
        } else {
          setinicioNumeracion(nextIni)
          setfinNumeracion(paginacionReformas.length)
          setpaginaLoad(paginacionReformas[nextIni].page)
          setnavS(0)
        }
      } else {
        if (nextIni <= 0) {
          setinicioNumeracion(0)
          setfinNumeracion(5)
          setpaginaLoad(paginacionReformas[0].page)
          setnavS(0)
        } else {
          setinicioNumeracion(paginacionReformas.length - 5 > 0 ? (paginacionReformas.length - 5) : (0))
          setfinNumeracion(paginacionReformas.length)
          setpaginaLoad(paginacionReformas[paginacionReformas.length - 5 > 0 ? (paginacionReformas.length - 5) : (0)].page)
          setnavS(0)
        }
      }

    } else if (e.target.id.split('-')[0] == 'cargarReforma') {
      // reformasLoad.slice((paginaLoad * 8), ((paginaLoad * 8) + 8)).map((cls, key01)

      let value = e.target.id.split('-')[1]
      let reformaToLoad = reformasLoad.slice((paginaLoad * 8), ((paginaLoad * 8) + 8))[value]
      reformaToLoad.descargas = []

      // buscamos si tiene archivos 

      let data = {
        id: reformaToLoad.id
      }
      let response = await fetch('https://oposiciones-justicia.es/api/usuarios/reformasDescargas', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      reformaToLoad.descargas = await cls

      setreformaLoad(reformaToLoad)

      console.log('reformaLoad')
      console.log(reformaLoad)
    } else if (e.target.id == 'cerrarReforma') {
      setreformaLoad(null)
    } else if (e.target.id == "descargar") {

      swal('Descargas en proceso', 'Si no inicia la descarga en 30 segundos vuelva a intentarlo.', 'info')
      for (let i = 0; i < reformaLoad.descargas.length; i++) {

        let pathFile = 'public' + reformaLoad.descargas[i].link.split('..')[1]

        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }

        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)

        });

      }




    } else if (e.target.id == 'convocatoriaMode') {
      setreformaAdd(reformaAdd == null ? (true) : (null))
    } else if (e.target.id == 'edtReforma') {

      let oposicionRefC = reformasLoadT.filter(a => a.id == reformaLoad.id && a.idOposicion != null && a.idOposicion != '')
      let opoLoads = []
      for (let i = 0; i < oposicionRefC.length; i++) {
        opoLoads.push({
          idOpo: oposicionRefC[i].idOposicion,
          nombre: busquedaOpo.find(l => l.id_oposicion == oposicionRefC[i].idOposicion).nombre
        })

      }

      let reformed = {
        oposicion: opoLoads,
        id: reformaLoad.id,
        idLey: reformaLoad.idLey,
        siglasLey: busquedaLeyes.find(l => l.id_ley == reformaLoad.idLey).siglas_ley,
        articulos: reformaLoad.articulos,
        videoLink: reformaLoad.videoFull,
        titulo: reformaLoad.titulo,
        texto: reformaLoad.texto,
        descargas: reformaLoad.descargas
      }

      setreformaInfoEdt(edtReforma == null ? (reformed) : (null))
      setedtReforma(edtReforma == null ? (true) : (null))

      setTimeout(() => {
        refSiglasLey.current.refs.leySelected.value = busquedaLeyes.find(l => l.id_ley == reformaLoad.idLey).siglas_ley

        console.log('refArts')
        console.log(refArts)


        refVideo.current.refs.videoLink.value = reformaLoad.videoFull
        refArts.current.value = reformaLoad.articulos
        refTitulo.current.value = reformaLoad.titulo

      }, 200);
    } else if (e.target.id == 'addOpo') {

      let reformed = structuredClone(reformaInfoAdd)
      reformed.oposicion.push({
        idOpo: null,
        nombre: ''
      })

      setreformaInfoAdd(reformed)
    } else if (e.target.id.split('-')[0] == 'deleteOpo') {
      let val = e.target.id.split('-')[1]

      let reformed = structuredClone(reformaInfoAdd)
      reformed.oposicion.splice(val, 1)

      setreformaInfoAdd(reformed)
    } else if (e.target.id == 'addReformaDB') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, se publicará la reforma",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            if (reformaInfoAdd.oposicion != null && reformaInfoAdd.oposicion.length > 0) {

              let comprobacionOpo = reformaInfoAdd.oposicion.filter(a => a.idOpo == null || a.idOpo == '')
              let comprobacionLey = reformaInfoAdd.idLey
              if (comprobacionOpo.length == 0 && comprobacionLey != null) {




                let response = await fetch('https://oposiciones-justicia.es/api/preparador/subirReforma', {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reformaInfoAdd)
                });
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                let json = await response.json();
                let cls = await json;

                if (cls.affectedRows > 0) {
                  let idInsert = await cls.insertId

                  for (let i = 0; i < reformaInfoAdd.oposicion.length; i++) {
                    let data2 = {
                      idOpo: reformaInfoAdd.oposicion[i].idOpo,
                      id: idInsert
                    }

                    let response2 = await fetch('https://oposiciones-justicia.es/api/preparador/subirReformaOposiciones', {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;

                  }



                  if (reformaInfoAdd.descargas != null && reformaInfoAdd.descargas.length > 0) {

                    var data2 = new FormData();
                    for (var i2 = 0; i2 < reformaInfoAdd.descargas.length; i2++) {
                      data2.append('myFiles', reformaInfoAdd.descargas[i2]);
                    }
                    console.log('data2')
                    console.log(data2)
                    if (data2.getAll('myFiles')) {
                      let responsex = await fetch('https://oposiciones-justicia.es/uploadmultiple', {
                        method: 'POST', body: data2
                      });
                      if (!responsex.ok) {
                        throw Error(responsex.statusText);
                      }
                      let jsonx = await responsex.json();
                      let clsx = await jsonx;

                      console.log('clsx')
                      console.log(clsx)
                      if (clsx.length > 0) {
                        for (let x2 = 0; x2 < clsx.length; x2++) {

                          // isertar todos archivos en bd
                          let path = await clsx[x2].path.split('src/public/')[1];
                          let nameFile = clsx[x2].originalname.split('.')[0];
                          let pathFinal = '../' + path
                          let data22 = {
                            link: pathFinal,
                            id: idInsert
                          }

                          let response2x = await fetch('https://oposiciones-justicia.es/api/preparador/subirReformaDescargas', {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data22)
                          });
                          if (!response2x.ok) {
                            throw Error(response2x.statusText);
                          }
                          let json2x = await response2x.json();
                          let cls2x = await json2x;
                        }
                      }


                    }



                  }

                  let response05 = await fetch('https://oposiciones-justicia.es/api/preparador/alumnosActivosPorClaseCurso');
                  if (!response05.ok) {
                    throw Error(response05.statusText);
                  }
                  let json05 = await response05.json();
                  let cls05 = await json05;

                  let alm = []

                  for (let x23c = 0; x23c < cls05.length; x23c++) {
                    let bus3 = reformaInfoAdd.find(o => o.idOpo == cls05[x23c].idOpo)

                    if (cls05[x23c].accesoWebHabilitado == 1 && cls05[x23c].ActivoEnClase == 1 && bus3 != undefined) {
                      let bus = alm.findIndex(a => a.id_usuario == cls05[x23c].id_usuario)
                      let bus2 = alm.findIndex(a => a.email == cls05[x23c].email)


                      if (bus == -1 && bus2 == -1) {
                        let emailRegx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                        if (emailRegx.test(cls05[x23c].email)) {

                          alm.push(cls05[x23c])

                        }

                      }
                    }

                  }
                  // console.log('alm')
                  // console.log(alm)

                  // let alm2 = [{ nombre: 'Fernando', email: 'diaz.alaminos.fernando@gmail.com' }]
                  swal({
                    title: "¿Enviamos email a los alumnos?",
                    text: "Si le das a ok, se enviará un email a todos los alumnos activos de las oposiciones añadidas en la reforma publicada.",
                    icon: "info",
                    buttons: true,
                    dangerMode: true,
                  })
                    .then(async (willDelete) => {
                      if (willDelete) {
                        for (let index = 0; index < alm.length; index++) {
                          let data2d = {
                            emailToEnviar: alm[index].email,
                            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
                            segundoTitulo: 'Reforma ' + busquedaLeyes.find(a => a.id_ley == reformaInfoAdd.idLey).siglas_ley + ' - CEJ', // titulo real
                            textoHtml: `
                              <p>Hola,<br/> `+ alm[index].nombre + `.</p>
                              <p>Se ha publicado una reforma de legislación en CEJ.</p>
                              <p> Puede ver la explicación detellada en el apartado "Reformas Legislación" en el campus virtual de CEJ https://oposiciones-justicia.es/infoReformas</p> 
                              <br/>
                              <b> `+ reformaInfoAdd.titulo + `.</b>
                              <p>Legislación: <b>`+ busquedaLeyes.find(a => a.id_ley == reformaInfoAdd.idLey).siglas_ley + `</b> - ` + busquedaLeyes.find(a => a.id_ley == reformaInfoAdd.idLey).nombre_ley + `.</p>
                              
                              <p>Articulos afectados: `+ reformaInfoAdd.articulos + ` </p> 
                              <br/>
            
                              <p>Un saludo, <br/> Fernando Díaz CEJ.</p>
                            `
                          }

                          fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(data2d)
                          }).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
                            .then(info => {

                            }).catch(error => console.log('Object fecth failed', error))

                        }


                        // sacar los alumnues activos en web y en clase por oposición

                        await swal({
                          title: 'Reforma publicada y emails enviados',
                          text: '¡Recargando web!',
                          icon: "success",
                          buttons: false,
                          closeOnEsc: false,
                          closeOnClickOutside: false,
                          timer: 2000,
                        }).then(() => {
                          window.location.reload()
                        })
                      } else {
                        await swal({
                          title: 'Reforma publicada',
                          text: '¡Recargando web!',
                          icon: "success",
                          buttons: false,
                          closeOnEsc: false,
                          closeOnClickOutside: false,
                          timer: 1500,
                        }).then(() => {
                          window.location.reload()
                        })
                      }
                    });


                }

              } else {

                if (comprobacionOpo.length != 0) {
                  swal('Error en oposición', 'Rellene todas las oposiciones añadidas o elimine las que no vaya a usar', 'info')
                } else if (comprobacionLey == null) {
                  swal('Error en ley', 'Seleccione una ley de las disponibles', 'info')
                }

              }



            } else {
              swal('Seleccione una Oposición', 'Necesita una oposición como minimo.', 'info')
            }
          } else {
            swal("¡Proceso abortado!", '', "success");
          }
        });
    } else if (e.target.id == 'addOpoEdt') {

      let reformed = structuredClone(reformaInfoEdt)
      reformed.oposicion.push({
        idOpo: null,
        nombre: ''
      })

      setreformaInfoEdt(reformed)
    } else if (e.target.id.split('-')[0] == 'deleteOpoEdt') {
      let val = e.target.id.split('-')[1]

      let reformed = structuredClone(reformaInfoEdt)
      reformed.oposicion.splice(val, 1)

      setreformaInfoEdt(reformed)
    } else if (e.target.id == 'edtReformaDB') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, se editará la reforma",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            if (reformaInfoEdt.oposicion != null && reformaInfoEdt.oposicion.length > 0) {

              let comprobacionOpo = reformaInfoEdt.oposicion.filter(a => a.idOpo == null || a.idOpo == '')
              let comprobacionLey = reformaInfoEdt.idLey
              if (comprobacionOpo.length == 0 && comprobacionLey != null) {

                let oposicionRefC = reformasLoadT.filter(a => a.id == reformaLoad.id && a.idOposicion != null && a.idOposicion != '')
                let opoLoads = []
                for (let i = 0; i < oposicionRefC.length; i++) {
                  opoLoads.push({
                    idOpo: oposicionRefC[i].idOposicion,
                    nombre: busquedaOpo.find(l => l.id_oposicion == oposicionRefC[i].idOposicion).nombre
                  })

                }
                //oposiciones
                if (opoLoads != reformaInfoEdt.oposicion) {
                  // borrar y meter todas de nuevo
                  if (opoLoads.length > 0) {
                    let data = {
                      id: reformaInfoEdt.id
                    }
                    let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/borrarOpoReformas', {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                    });
                    if (!response0.ok) {
                      throw Error(response0.statusText);
                    }
                    let json0 = await response0.json();
                    let cls0 = await json0;
                  }


                  for (let i = 0; i < reformaInfoEdt.oposicion.length; i++) {
                    let data1 = {
                      id: reformaInfoEdt.id,
                      idOpo: reformaInfoEdt.oposicion[i].idOpo
                    }


                    let response1 = await fetch('https://oposiciones-justicia.es/api/preparador/subirReformaOposiciones', {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response1.ok) {
                      throw Error(response1.statusText);
                    }
                    let json1 = await response1.json();
                    let cls1 = await json1;

                  }



                }

                // ley 
                if (reformaLoad.idLey != reformaInfoEdt.idLey) {
                  // cambiar idLey
                  let data = {
                    id: reformaInfoEdt.id,
                    value: reformaInfoEdt.idLey
                  }
                  let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/edtReformaLey', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response0.ok) {
                    throw Error(response0.statusText);
                  }
                  let json0 = await response0.json();
                  let cls0 = await json0;
                }
                // articulos
                if (reformaLoad.articulos != reformaInfoEdt.articulos) {
                  let data = {
                    id: reformaInfoEdt.id,
                    value: reformaInfoEdt.articulos
                  }
                  let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/edtReformaArt', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response0.ok) {
                    throw Error(response0.statusText);
                  }
                  let json0 = await response0.json();
                  let cls0 = await json0;
                }
                // titulo
                if (reformaLoad.titulo != reformaInfoEdt.titulo) {

                  let data = {
                    id: reformaInfoEdt.id,
                    value: reformaInfoEdt.titulo
                  }
                  let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/edtReformaTitulo', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response0.ok) {
                    throw Error(response0.statusText);
                  }
                  let json0 = await response0.json();
                  let cls0 = await json0;
                }
                // texto
                if (reformaLoad.texto != reformaInfoEdt.texto) {
                  let data = {
                    id: reformaInfoEdt.id,
                    value: reformaInfoEdt.texto
                  }
                  let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/edtReformaTexto', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response0.ok) {
                    throw Error(response0.statusText);
                  }
                  let json0 = await response0.json();
                  let cls0 = await json0;
                }

                // video
                if (reformaLoad.videoFull != reformaInfoEdt.videoLink) {
                  let data = {
                    id: reformaInfoEdt.id,
                    value: reformaInfoEdt.videoLink
                  }
                  let response0 = await fetch('https://oposiciones-justicia.es/api/preparador/edtReformaVideo', {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response0.ok) {
                    throw Error(response0.statusText);
                  }
                  let json0 = await response0.json();
                  let cls0 = await json0;
                }

                // archvios
                if (reformaLoad.descargas != reformaInfoEdt.descargas) {

                  if (reformaInfoEdt.descargas.length > 0) {

                    // Borramos los archivos actuales de BD y server
                    if (reformaLoad.descargas.length > 0) {
                      let dataD = {
                        id: reformaInfoEdt.id
                      }
                      let response = await fetch('https://oposiciones-justicia.es/api/preparador/borrarOpoDescargas', {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dataD)
                      });
                      if (!response.ok) {
                        throw Error(response.statusText);
                      }
                      let json = await response.json();
                      let cls = await json;

                      for (let j = 0; j < reformaLoad.descargas.length; j++) {

                        let data1 = { pathFile: '../public' + reformaLoad.descargas[j].link.split('..')[1] }
                        let response1 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                        });
                        if (!response1.ok) {
                          throw Error(response1.statusText);
                        }
                        let json1 = await response1.json();
                        let cls1 = await json1;

                      }

                    }



                    // Preparamos y subimos nuevos archivos BD + server
                    var data2 = new FormData();
                    for (var i2 = 0; i2 < reformaInfoEdt.descargas.length; i2++) {
                      data2.append('myFiles', reformaInfoEdt.descargas[i2]);
                    }

                    let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
                      method: 'POST', body: data2
                    });
                    if (!response2.ok) {
                      throw Error(response2.statusText);
                    }
                    let json2 = await response2.json();
                    let cls2 = await json2;

                    if (cls2 != undefined) {
                      for (let k = 0; k < cls2.length; k++) {
                        let path = await cls2[k].path.split('src/public/')[1];
                        let pathFinal = '../' + path


                        let data3 = {
                          link: pathFinal,
                          id: reformaInfoEdt.id
                        }

                        let response3 = await fetch('https://oposiciones-justicia.es/api/preparador/subirReformaDescargas', {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                        });
                        if (!response3.ok) {
                          throw Error(response3.statusText);
                        }
                        let json3 = await response3.json();
                        let cls3 = await json3;

                      }

                    }

                  }

                }


                await swal({
                  title: 'Reforma editada',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 2500,
                }).then(() => {
                  window.location.reload()
                })

              } else {

                if (comprobacionOpo.length != 0) {
                  swal('Error en oposición', 'Rellene todas las oposiciones añadidas o elimine las que no vaya a usar. Minimo necesita asignar una oposición.', 'info')
                } else if (comprobacionLey == null) {
                  swal('Error en ley', 'Seleccione una ley de las disponibles', 'info')
                }

              }



            } else {
              swal('Seleccione una Oposición', 'Necesita una oposición como minimo.', 'info')
            }
          } else {
            swal("¡Proceso abortado!", '', "success");
          }
        });
    } else if (e.target.id == 'delReforma') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la reforma será eliminada.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let data = {
              id: reformaInfoEdt.id
            }
            let response = await fetch('https://oposiciones-justicia.es/api/preparador/borrarReforma', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            await swal({
              title: 'Reforma eliminada',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 1500,
            }).then(() => {
              window.location.reload()
            })
          } else {
            swal("¡Proceso abortado!", '', "success");
          }
        });



    }

    // añadir addOpoEdt - delOpoEdt - editarReforma

    // addOpoEdt
    // deleteOpoEdt






  }

  const onChangeText = (e, editor) => {
    let textHtml = editor.getData();
    let reformed = structuredClone(reformaInfoAdd)
    let reformed2 = structuredClone(reformaInfoEdt)
    if (textHtml != '') {
      // this.setState({ textHtmlPreparador: textHtml })
      if (edtReforma == null) {
        reformed.texto = textHtml
        setreformaInfoAdd(reformed)
      } else {
        reformed2.texto = textHtml
        setreformaInfoEdt(reformed2)
      }

    }
  }

  const myFiles = async (e) => {
    var e = e
    if (e.target.name == 'addFile') {
      try {
        let reformed = structuredClone(reformaInfoAdd)

        let files = e.target.files;
        let filesLoad = [];
        for (let i = 0; i < files.length; i++) {
          filesLoad.push(files[i]);
        }
        if (filesLoad && filesLoad.length > 0) {
          reformed.descargas = filesLoad
          setreformaInfoAdd(reformed)
        } else {
          reformed.descargas = null
          setreformaInfoAdd(reformed)
        }


      } catch (e) { console.log(e) }
    } else if (e.target.name == 'addFileEdt') {
      try {
        let reformed = structuredClone(reformaInfoEdt)

        let files = e.target.files;
        let filesLoad = [];
        for (let i = 0; i < files.length; i++) {
          filesLoad.push(files[i]);
        }
        if (filesLoad && filesLoad.length > 0) {
          reformed.descargas = filesLoad
          setreformaInfoEdt(reformed)
        } else {
          reformed.descargas = null
          setreformaInfoEdt(reformed)
        }


      } catch (e) { console.log(e) }
    }

  }

  const nivelRequired = 1

  // console.log('reformaLoad')
  // console.log(reformaLoad)

  // console.log('reformasLoad')
  // console.log(reformasLoad)

  // console.log('reformaAdd')
  // console.log(reformaAdd)

  // console.log('reformasLoadT')
  // console.log(reformasLoadT)

  // console.log('busquedaLeyes')
  // console.log(busquedaLeyes)

  // console.log('busquedaOpo')
  // console.log(busquedaOpo)

  // console.log('reformaInfoAdd')
  // console.log(reformaInfoAdd)

  // console.log('reformaInfoEdt')
  // console.log(reformaInfoEdt)

  if (isLogged && (nivelRequired == tipoUser || tipoUser >= nivelRequired)) {

    if (tipoUser < 50 && (idClase == null || temasFacturados == null)) {

      swal({
        title: 'Area restringida',
        text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
        icon: "info",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 2500,
      }).then(() => {
        window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
      })
      return (null);

    } else {

      return (
        <div className='ColCenterStart' style={{ padding: '15px', color: 'black' }}>

          <div className="reformas">
            <img src="../img/conference-call.png" />

            <div className='ColCenterStart reformasTitle'>
              <h2 className='contoneoLetras ctrlH2reform' style={{ textTransform: 'uppercase', fontWeight: '800' }}> Mantente al día </h2>
              <h2 className='contoneoLetras ' style={{ textTransform: 'uppercase', fontWeight: '800', marginTop: '15px', fontSize: '25px', textAlign: 'center' }}> Reformas Legislación</h2>
            </div>

          </div>

          {
            tipoUser && tipoUser > 50 && edtReforma == null  && reformaLoad == null ? (
              <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', paddingBottom: '0px', margin: '10px' }}>
                <Boton id={'convocatoriaMode'} onClick={onHandleClick} texto={reformaAdd == null ? ('Añadir reforma') : ('VOLVER ATRÁS')} />
              </div>
            ) : null
          }
          {
            reformaAdd == null && edtReforma == null && reformaLoad == null ? (
              <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', paddingBottom: '0px' }}>
                <div className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
                  <Input2 datalist='leyesLoad' autoComplete={'off'} id={'leyBusqueda'} type='text' name={'leyBusqueda'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={onChangeInput} />
                  <p>Buscar por legislación</p>
                </div>
                <datalist id="leyesLoad">{
                  busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
                    <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
                  )
                }</datalist>

              </div>
            ) : null
          }
          {
            reformaAdd == null && edtReforma == null && reformaLoad == null && reformasLoad != null && reformasLoad.length == 0 ? (
              <div className='ColCenterStart reformasTitle'>

                <h2 className='contoneoLetras ' style={{ textTransform: 'uppercase', fontWeight: '800', marginTop: '15px', fontSize: '25px', textAlign: 'center' }}> Ninguna reforma disponible </h2>
              </div>
            ) : null}
          {
            reformaAdd == null && edtReforma == null && reformaLoad == null && reformasLoad != null && reformasLoad.length > 0 ? (<>

              <div className='RowCenterCenter' style={{ color: 'black' }}>

                <div className='ColCenterStart adb-nav'>
                  <div className='RowCenterCenter adb-navPagination' style={{ margin: '10px' }}>
                    {
                      inicioNumeracion > 0 ? (
                        <i id="atras" onClick={onHandleClick} className="fa-light fa-left-long"></i>
                      ) : null
                    }

                    {paginacionReformas && paginacionReformas.sort((a, b) => a.page - b.page).slice(inicioNumeracion, finNumeracion).map((cls02, key02) =>

                      <div className={navS == key02 ? 'navSelected' : 'navSelected navNoSelected'} id={'nav-' + key02} onClick={onHandleClick} style={{ borderRight: 'unset' }}>
                        {cls02.page + 1}
                      </div>

                    )
                    }
                    {
                      paginacionReformas && paginacionReformas.length > 0 && finNumeracion < paginacionReformas.length - 1 ? (
                        <i id="adelante" onClick={onHandleClick} className="fa-light fa-right-long"></i>
                      ) : null
                    }

                  </div>
                </div>

              </div>
              <div className='RowCenterCenter' style={{ maxWidth: '1050px' }}>
                {

                  reformasLoad && reformasLoad.slice((paginaLoad * 8), ((paginaLoad * 8) + 8)).map((cls, key01) =>
                    <div key={key01} className='ColCenterCenter' style={{
                      alignItems: 'flex-start',
                      padding: '10px',
                      boxShadow: '6px 6px 10px #d3e2f7',
                      borderRadius: '0px 35px 12px 12px',
                      paddingBottom: '7px',
                      margin: '10px'
                    }}>
                      <div className='RowCenterCenter'>
                        {cls.videoFull != null && cls.videoFull != '' ? (
                          <div className="recPlayVidDiv" onContextMenu={e => e.preventDefault()}
                            style={{
                              position: 'relative',
                              alignSelf: 'center',
                              maxWidth: '220px',
                            }}>
                            <ReactPlayer
                              url={cls.videoFull}
                              className='react-player'
                              playing={false}
                              width='100%'
                              height='100%'
                              controls={true}
                              onContextMenu={e => e.preventDefault()}
                              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            />

                            <div className="nextRectPlaerClass2 aerClass2" >
                              <i className="fa-light fa-calendar-clock"></i>
                              {moment(cls.fecha).format('DD-MM-YY')}
                            </div>
                          </div>
                        ) : null}


                      </div>
                      <div className='ColCenterStart' style={{ alignItems: 'flex-start' }}>
                        <div className='RowCenterCenter rowReformaPreview'>
                          <i className="fa-light fa-newspaper"></i>
                          {cls.titulo.substring(20, -1)}
                        </div>

                        <div className='RowCenterCenter' style={{ justifyContent: 'flex-start' }}>
                          <div className='RowCenterCenter rowReformaPreview' title={busquedaLeyes && busquedaLeyes.length > 0 ? (busquedaLeyes.find(l => l.id_ley == cls.idLey).nombre_ley) : ('')}>

                            <i className="fa-light fa-gavel"></i>
                            {busquedaLeyes && busquedaLeyes.length > 0 ? (busquedaLeyes.find(l => l.id_ley == cls.idLey).siglas_ley) : null}
                          </div>
                          <div className='RowCenterCenter rowReformaPreview' style={{ marginLeft: '20px' }}>
                            <i className="fa-light fa-list-check"></i>
                            {cls.articulos.substring(9, -1)}
                          </div>
                        </div>

                        <div className='RowCenterCenter rowReformaPreview' style={{ alignSelf: 'center', maxWidth: '220px', marginTop: '8px' }}>
                          <Boton id={'cargarReforma-' + key01} onClick={onHandleClick} texto='VER DESCRIPCIÓN' />
                        </div>

                        {/* <div className='RowCenterCenter rowReformaPreview'>
                          <i className="fa-light fa-calendar-clock"></i>
                          {moment(cls.fecha).format('DD-MM-YY')}
                        </div> */}

                      </div>

                    </div>
                  )


                }
              </div>
            </>) : null
          }

          {
            reformaAdd == null && edtReforma == null && reformaLoad != null ? (
              <div className='ColCenterStart' style={{ position: 'relative' }}>

                {
                  tipoUser && tipoUser > 50 ? (
                    <i id={"edtReforma"} title="Editar reforma publicada" style={{ top: '23px', right: '-10px', fontSize: '30px' }} onClick={onHandleClick} className="fa-light fa-gear delInput"></i>
                  ) : null
                }


                <div className='ColCenterCenter' style={{
                  padding: '25px',
                  boxShadow: '#d3e2f7 9px 9px 10px',
                  borderRadius: '0px 35px 35px  35px',
                  margin: '15px',
                  marginTop: '50px',
                  maxWidth: '700px'
                }}>
                  {reformaLoad.videoFull != null && reformaLoad.videoFull != '' ? (
                    <div className='RowCenterCenter'>

                      <div className="recPlayVidDiv" onContextMenu={e => e.preventDefault()}
                        style={{
                          position: 'relative',
                          alignSelf: 'center',
                          maxWidth: '630px'
                        }}>
                        <ReactPlayer
                          url={reformaLoad.videoFull}
                          className='react-player'
                          playing={false}
                          width='100%'
                          height='100%'
                          controls={true}
                          onContextMenu={e => e.preventDefault()}
                          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        />

                        <div className="nextRectPlaerClass2 aerClass2" >
                          <i className="fa-light fa-calendar-clock"></i>
                          {moment(reformaLoad.fecha).format('DD-MM-YY')}
                        </div>
                      </div>


                    </div>
                  ) : null
                  }
                  <div className='ColCenterStart' style={{ alignItems: 'flex-start', width: '100%' }}>
                    <div className='RowCenterCenter rowReformaPreview' style={{}}>
                      <h2 style={{
                        color: '#3469b7',
                        padding: '20px 10px',
                        fontSize: '22px'
                      }}>{reformaLoad.titulo}</h2>
                    </div>

                    <div className='RowCenterCenter'>
                      <div className='RowCenterCenter rowReformaPreview' title={busquedaLeyes && busquedaLeyes.length > 0 ? (busquedaLeyes.find(l => l.id_ley == reformaLoad.idLey).nombre_ley) : ('')}>

                        <i className="fa-light fa-gavel"></i>
                        {busquedaLeyes && busquedaLeyes.length > 0 ? (busquedaLeyes.find(l => l.id_ley == reformaLoad.idLey).siglas_ley) : null}
                      </div>
                      <div className='RowCenterCenter rowReformaPreview' style={{ marginLeft: '20px' }}>
                        <i className="fa-light fa-list-check"></i>
                        {reformaLoad.articulos}
                      </div>
                    </div>

                    <div style={{ padding: '10px' }}>
                      {ReactHtmlParser(reformaLoad.texto)}
                    </div>


                    <div className='RowCenterCenter' style={{ padding: '10px', alignSelf: 'center' }}>
                      {reformaLoad && reformaLoad.descargas.length > 0 ? (
                        <div className='RowCenterCenter rowReformaPreview' style={{ alignSelf: 'center', margin: '20px' }}>
                          <Boton id={'descargar'} onClick={onHandleClick} texto='DESCARGAR PDFs' />
                        </div>
                      ) : null}

                      <div className='RowCenterCenter rowReformaPreview' style={{ alignSelf: 'center', margin: '20px' }}>
                        <Boton id={'cerrarReforma'} onClick={onHandleClick} texto='CERRAR REFORMA' />
                      </div>
                    </div>


                    {/* <div className='RowCenterCenter rowReformaPreview'>
                          <i className="fa-light fa-calendar-clock"></i>
                          {moment(cls.fecha).format('DD-MM-YY')}
                        </div> */}

                  </div>

                </div>


              </div>
            ) : null
          }




          {
            reformaAdd == null && edtReforma != null && reformaInfoEdt != null ? (
              <div className='ColCenterStart'>

                <div className='ColCenterStart'>


                  <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', paddingBottom: '0px', margin: '10px' }}>
                    <Boton id={'addOpoEdt'} onClick={onHandleClick} texto={'Añadir Oposición'} />
                  </div>

                  <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', maxWidth: '1000px', borderBottom: '1px solid gainsboro' }}>
                    {
                      reformaInfoEdt.oposicion.map((opo, key20) =>

                        <div key={key20} className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
                          <Input2 value={opo.nombre} datalist='oposLoadData' autoComplete={'off'} id={'opoSelected-' + key20} type='text' name={'opoSelected-' + key20} placeholder='Ej: Gestion' icon='fa-light fa-gavel' onChange={onChangeInputEdt} />
                          <p>Oposición</p>
                          <i id={"deleteOpoEdt-" + key20} onClick={onHandleClick} className="fa-light fa-circle-xmark delInput"></i>
                        </div>

                      )
                    }
                    <datalist id="oposLoadData">{
                      busquedaOpo && busquedaOpo.map((oposicion, key2x) =>
                        <option key={key2x} value={oposicion.nombre}></option>
                      )
                    }</datalist>
                  </div>


                </div>


                <div className='ColCenterStart' style={{ color: 'black', maxWidth: '1000px', padding: '10px' }}>

                  <div className='RowCenterCenter' style={{ padding: '15px', color: 'black', maxWidth: '1000px', borderBottom: '1px solid gainsboro' }}>


                    <div className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
                      <Input2 ref={refSiglasLey} datalist='leyesLoad' autoComplete={'off'} id={'leySelected'} type='text' name={'leySelected'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={onChangeInputEdt} />
                      <p>Ley</p>
                    </div>


                    <datalist id="leyesLoad">{
                      busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
                        <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
                      )
                    }</datalist>
                  </div>


                  <div className='RowCenterCenter' style={{ borderBottom: '1px solid gainsboro' }}>
                    <div className='ColCenterStart' style={{
                      padding: '15px',
                      alignItems: 'flex-start'
                    }}>

                      <h2 style={{
                        color: '#3469b7',
                        padding: '10px 0px',
                        fontSize: '20px'
                      }}> Artículos afectados </h2>
                      <textarea
                        onChange={onChangeInputEdt}
                        id={'artAfectados'}
                        ref={refArts}
                        name="artAfectados"
                        placeholder="Ej: Art 5 a 65."
                        rows={4}
                        cols={40}
                      />
                    </div>

                    <div className='ColCenterStart' style={{
                      padding: '15px',
                      alignItems: 'flex-start'
                    }}>

                      <h2 style={{
                        color: '#3469b7',
                        padding: '10px 00px',
                        fontSize: '20px'
                      }}> Título Reforma </h2>
                      <textarea
                        onChange={onChangeInputEdt}
                        id={'textoReforma'}
                        ref={refTitulo}
                        name="textoReforma"
                        placeholder="Ej: Reforma LEC Marzo 2023"
                        rows={4}
                        cols={40}
                      />
                    </div>
                  </div>

                  <div className='ColCenterStart' style={{ width: '100%' }}>

                    <h2 style={{
                      color: '#3469b7',
                      padding: '10px',
                      fontSize: '20px',
                      marginTop: '15px',
                      paddingBottom: '0px'
                    }}> Texto </h2>
                    <div className="ColCenterStart" style={{
                      width: '100%',
                      maxWidth: '800px',
                      minWidth: '500px'
                    }}>
                      <div className="editorPlantearrDuda">
                        <CKEditor
                          editor={ClassicEditor}
                          onInit={editor => { }}
                          data={reformaInfoEdt.texto}
                          config={{
                            toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                            // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                          }}
                          onChange={onChangeText}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='RowCenterCenter' style={{ padding: '15px', color: 'black' }}>

                    <div className='celdaInputDAPFBN' style={{ width: '300px', margin: '10px' }}>
                      <Input2 autoComplete={'off'} ref={refVideo} id={'videoLink'} type='text' name={'videoLink'} placeholder='https://www.dl.dropboxusercontent.com/s/...' icon='fa-light fa-link' onChange={onChangeInputEdt} />
                      <p>Video Link</p>
                    </div>

                  </div>

                  <div className='ColCenterStart'>
                    <div className='RowCenterCenter' style={{ padding: '15px', color: 'black' }}>

                      <div className='celdaInputDAPFBN' style={{ width: '300px', margin: '10px' }}>
                        <InputFileExamDesa onChange={myFiles} id="addFileEdt" name="addFileEdt" texto="Añadir/Cambiar archivos" />
                      </div>

                    </div>
                    {reformaInfoEdt != null && reformaInfoEdt.descargas != null && reformaInfoEdt.descargas.length > 0 ? (
                      reformaInfoEdt.descargas.length == 1 ? (
                        <p>{reformaInfoEdt.descargas.length} - Archivo cargado </p>
                      ) : (
                        <p>{reformaInfoEdt.descargas.length} - Archivos cargados </p>
                      )

                    ) : (
                      <p>Nigún archivo cargado </p>
                    )}
                  </div>
                  <div className='RowCenterCenter' style={{ padding: '25px', color: 'black' }}>
                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                      <Boton id={"edtReforma"} onClick={onHandleClick} texto={'VOLVER ATRAS'} />
                    </div>
                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                      <Boton id={"delReforma"} onClick={onHandleClick} texto={'BORRAR REFORMA'} />
                    </div>
                    <div className='RowCenterCenter' style={{ margin: '10px' }}>
                      <Boton id={'edtReformaDB'} onClick={onHandleClick} texto={'EDITAR REFORMA'} />
                    </div>
                  </div>




                </div>



              </div>
            ) : null
          }





          {
            reformaAdd != null && reformaInfoAdd != null ? (
              <div className='ColCenterStart'>
                {/* oposicion: [],
                idLey: null,
                articulos: '',
                videoLink: '',
                titulo: '',
                texto: '',
                descargas: [] 
                
                reformaInfoAdd 
                */}

                <div className='ColCenterStart'>

                  <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', paddingBottom: '0px', margin: '10px' }}>
                    <Boton id={'addOpo'} onClick={onHandleClick} texto={'Añadir Oposición'} />
                  </div>

                  <div className='RowCenterCenter' style={{ padding: '25px', color: 'black', maxWidth: '1000px' }}>
                    {
                      reformaInfoAdd.oposicion.map((opo, key20) =>

                        <div key={key20} className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
                          <Input2 value={opo.nombre} datalist='oposLoadData' autoComplete={'off'} id={'opoSelected-' + key20} type='text' name={'opoSelected-' + key20} placeholder='Ej: Gestion' icon='fa-light fa-gavel' onChange={onChangeInput} />
                          <p>Oposición</p>
                          <i id={"deleteOpo-" + key20} onClick={onHandleClick} className="fa-light fa-circle-xmark delInput"></i>
                        </div>

                      )
                    }
                    <datalist id="oposLoadData">{
                      busquedaOpo && busquedaOpo.map((oposicion, key2x) =>
                        <option key={key2x} value={oposicion.nombre}></option>
                      )
                    }</datalist>
                  </div>


                </div>

                {reformaInfoAdd != null && reformaInfoAdd.oposicion.length > 0 ? (
                  <div className='ColCenterStart' style={{ color: 'black', maxWidth: '1000px', padding: '10px' }}>

                    <div className='RowCenterCenter' style={{ padding: '15px', color: 'black', maxWidth: '1000px' }}>


                      <div className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
                        <Input2 datalist='leyesLoad' autoComplete={'off'} id={'leySelected'} type='text' name={'leySelected'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={onChangeInput} />
                        <p>Ley</p>
                      </div>


                      <datalist id="leyesLoad">{
                        busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
                          <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
                        )
                      }</datalist>
                    </div>

                    {reformaInfoAdd != null && reformaInfoAdd.idLey != null ? (
                      <>

                        <div className='RowCenterCenter'>
                          <div className='ColCenterStart' style={{
                            padding: '15px',
                            alignItems: 'flex-start'
                          }}>

                            <h2 style={{
                              color: '#3469b7',
                              padding: '10px 0px',
                              fontSize: '20px'
                            }}> Artículos afectados </h2>
                            <textarea
                              onChange={onChangeInput}
                              id={'artAfectados'}
                              name="Contenido"
                              placeholder="Ej: Art 5 a 65."
                              rows={4}
                              cols={40}
                            />
                          </div>

                          <div className='ColCenterStart' style={{
                            padding: '15px',
                            alignItems: 'flex-start'
                          }}>

                            <h2 style={{
                              color: '#3469b7',
                              padding: '10px 00px',
                              fontSize: '20px'
                            }}> Título Reforma </h2>
                            <textarea
                              onChange={onChangeInput}
                              id={'textoReforma'}
                              name="Contenido"
                              placeholder="Ej: Reforma LEC Marzo 2023"
                              rows={4}
                              cols={40}
                            />
                          </div>
                        </div>

                        <div className='ColCenterStart' style={{ width: '100%' }}>

                          <h2 style={{
                            color: '#3469b7',
                            padding: '10px',
                            fontSize: '20px',
                            marginTop: '15px',
                            paddingBottom: '0px'
                          }}> Texto </h2>
                          <div className="ColCenterStart" style={{
                            width: '100%',
                            maxWidth: '800px',
                            minWidth: '500px'
                          }}>
                            <div className="editorPlantearrDuda">
                              <CKEditor
                                editor={ClassicEditor}
                                onInit={editor => { }}
                                config={{
                                  toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                                  // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                                }}
                                onChange={onChangeText}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='RowCenterCenter' style={{ padding: '15px', color: 'black' }}>

                          <div className='celdaInputDAPFBN' style={{ width: '300px', margin: '10px' }}>
                            <Input2 autoComplete={'off'} id={'videoLink'} type='text' name={'videoLink'} placeholder='https://www.dl.dropboxusercontent.com/s/...' icon='fa-light fa-link' onChange={onChangeInput} />
                            <p>Video Link</p>
                          </div>

                        </div>

                        <div className='ColCenterStart'>
                          <div className='RowCenterCenter' style={{ padding: '15px', color: 'black' }}>

                            <div className='celdaInputDAPFBN' style={{ width: '300px', margin: '10px' }}>
                              <InputFileExamDesa onChange={myFiles} id="addFile" name="addFile" texto="Añadir archivos" />
                            </div>

                          </div>
                          {reformaInfoAdd != null && reformaInfoAdd.descargas != null && reformaInfoAdd.descargas.length > 0 ? (
                            reformaInfoAdd.descargas.length == 1 ? (
                              <p>{reformaInfoAdd.descargas.length} - Archivo cargado </p>
                            ) : (
                              <p>{reformaInfoAdd.descargas.length} - Archivos cargados </p>
                            )

                          ) : (
                            <p>Nigún archivo cargado </p>
                          )}
                        </div>


                        <div className='RowCenterCenter' style={{ padding: '25px', color: 'black' }}>
                          <Boton id={'addReformaDB'} onClick={onHandleClick} texto={'AÑADIR REFORMA'} />
                        </div>

                      </>) : null}

                  </div>
                ) : null}


              </div>
            ) : null
          }

        </div >
      )

    }
  } else {
    return (<Redirect to="/" />);
  }




}

ReformasLey.propTypes = {

}



export default ReformasLey;
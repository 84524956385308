// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
//Assets
import './css/menuAdmin.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Boton from '../../../../components/Inputs/Boton';
import Boton2 from '../../../../components/Inputs/Boton2';
// Actions
import { cargarContenido } from './actions/baroptionAction';


class MenuAdmin extends Component {
  static propTypes = {
    itemsMenu: PropTypes.array.isRequired,
    titleMenu: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {

    super(props)
    this.state = { itemSeleccionado: null, showAllMenu: null, showAllMenu2: null }

    this.handleToggleClick = this.handleToggleClick.bind(this)
  }
  handleToggleClick(e) {
    if (e.target.id === 'ToggleMenu') {
      $('.c_menu_adm2').toggleClass("menu-show-adm2");
    } else if (e.target.id.split('-')[0] == 'load') {
      // this.props.cargarContenido("Añadir_Alumno",window.location.reload())}
      
      let url = e.target.id.split('-')[1]
      this.props.cargarContenido(url)
      this.setState({showAllMenu2: null})
    } else if (e.target.id == 'showMenu1') {

      let showAllMenuR = this.state.showAllMenu
      this.setState({ showAllMenu: showAllMenuR == null ? (true) : (null) })
    } else if (e.target.id == 'showMenu2') {

      let showAllMenuR = this.state.showAllMenu2
      this.setState({ showAllMenu2: showAllMenuR == null ? (true) : (null) })
    }
  };
  componentDidMount() {
    let busq = this.props.itemsMenu.findIndex(a => a.url.split('/')[a.url.split('/').length - 1] == window.location.href.split('/')[window.location.href.split('/').length - 1])
    if (busq != -1) {
      this.setState({ itemSeleccionado: busq })
    } else {
      this.setState({ itemSeleccionado: null })
    }
  }
  render() {
    const { itemsMenu, titleMenu, optionSelected } = this.props
    const { itemSeleccionado, showAllMenu, showAllMenu2 } = this.state
    let itemsMenuPermisos = itemsMenu.filter(item => item.nivelAcesso <= this.props.tipoUser);
    return (
      <div className="menuAdmBody">
        <div className="menuAdmTitle">
          {titleMenu}
        </div>
        <div className="menuAdmFondo">
          <div className="menuAdmFondoLinks animationWidthEntra">
            <div className="bbuttonlinkmenu">
              {
                itemsMenuPermisos && itemsMenuPermisos.map((item, key) =>
                  <Link to={item.url} key={key}>

                    {window.location.href.split('/')[window.location.href.split('/').length - 1] == item.url.split('/')[item.url.split('/').length - 1] ? (
                      <div className="itemMenuN">
                        <i className={item.fontawesome}></i>
                        <p style={{ color: '#3469b7' }}>{item.title}</p>
                        <i className="botonVerTodo fa-light fa-chevron-down" id="showMenu1" onClick={this.handleToggleClick} style={showAllMenu != null ? ({ transform: 'rotate(180deg)' }) : ({})}></i>
                      </div>
                    ) : (
                      <div className="itemMenuN" style={itemSeleccionado != null && showAllMenu == null ? ({ display: 'none' }) : ({})}>
                        <i className={item.fontawesome}></i>
                        <p>{item.title}</p>
                      </div>
                    )}

                  </Link>

                )
              }
            </div>
            <div className="bbuttonlinkmenu" style={ itemSeleccionado == null ? ({ display: 'none' }) : ({})}>
              {
                itemSeleccionado != null && itemsMenuPermisos && itemsMenuPermisos[itemSeleccionado].subMenu.length > 0 && itemsMenuPermisos[itemSeleccionado].subMenu.map((item2, key) =>
                  <div id={'load-' + item2.url} className="itemMenuN" onClick={this.handleToggleClick}
                    style={optionSelected != null && itemsMenuPermisos[itemSeleccionado].subMenu.filter(x => x.url == optionSelected).length > 0 && item2.url != optionSelected && showAllMenu2 == null ? ({ display: 'none' }) : ({})}
                  >
                    <i className={item2.fontawesome} id={'load-' + item2.url}></i>
                    <p id={'load-' + item2.url} style={item2.url == optionSelected ? ({color: '#3469b7'}):({})}>{item2.title}</p>

                    {item2.url == optionSelected ? (
                      <i className="botonVerTodo fa-light fa-chevron-down" id="showMenu2" onClick={this.handleToggleClick} style={showAllMenu2 != null ? ({ transform: 'rotate(180deg)' }) : ({})}></i>
                    ) : null
                    }

                  </div>

                )
              }
            </div>
          </div>
        </div>
      </div>


    );
  }
}
// Conectamos Redux Store

function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    optionSelected: state.baroption.optionSelected,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cargarContenido
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuAdmin);


// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../../../data/menuPreparador';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from './actions/baroptionAction';
// Components
import MenuAdmin from '../MenuAdmin';
import Option from './optionBar';

/*
Falta cambiar el componente generarTest porClase! ajustarlo al props.busquedaTemas que recarge informacion
no funciona el this.state.intervalosTemas!
<div className="botonSmall" onClick={() => this.props.cargarContenido("Temas_Programacion",this.forceUpdate())}>
	<i className="fas fa-plus"></i>Temas de Clase
</div>
*/


class TestJusticia extends Component {
	static propTypes = {
		cargarContenido: PropTypes.func.isRequired,
		isLogged: PropTypes.bool.isRequired,
		nombreUser: PropTypes.string.isRequired,
		tipoUser: PropTypes.number.isRequired,
		optionSelected: PropTypes.string.isRequired
	};

	render() {
		const { isLogged, nombreUser, tipoUser, optionSelected } = this.props;
		const nivelRequired = 98;
		if (isLogged && nivelRequired <= tipoUser) {
			return (
				<div className="inBodyMenu">
					<div className="menuBody">
						<MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="OPCIONES" />
					</div>
					<div className="inBody">
						<div className="sectionBody">
							<div className="optionsBar">

								<div className="botonSmall" onClick={() => this.props.cargarContenido("Intervalos_Articulos", window.location.reload())}>
									<i className="fas fa-plus"></i>Intervalo de Articulos
								</div>

								<div className="botonSmall" onClick={() => this.props.cargarContenido("Leyes_numPreg", window.location.reload())}>
									<i className="fas fa-plus"></i>Leyes
								</div>

								<div className="botonSmall" onClick={() => this.props.cargarContenido("Temas_numPreg", window.location.reload())}>
									<i className="fas fa-plus"></i>Temas
								</div>

							</div>
							<div className="optionselected">
								<Option nombreUser={nombreUser} tipoUser={tipoUser} selectedOption={optionSelected} />
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (<Redirect to="/" />);
		}
	}
}

// Estados del store
function mapStateToProps(state) {
	return {
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser: state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		cargarContenido
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TestJusticia);

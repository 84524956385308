// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
// Assets
import './css/correctorMecanografia.css';
import Input from '../Inputs/Input';
import Submit from '../Inputs/Submit';
import { isDefined } from '../../lib/utils/is';

class CorrectorMeca extends Component {

  static propTypes = {
    infoMeca: PropTypes.array.isRequired
  };

constructor(props){
        super(props);
        this.state = {
					pulsacionesAlumno: 0,
					penalizacionAlumno: 0,
					formatoAlumno: 0,
					pulsacioneFinales: 0,
					tiempoPruebaMinutos: 15
				};
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
				this.handleCorregirMeca = this.handleCorregirMeca.bind(this);
				this.downloadFile = this.downloadFile.bind(this);
    }

downloadFile(e){
	let { infoMeca } = this.props;
		if(e.target.innerText == 'Oficial'){
				let pathFile = infoMeca[e.target.id].linkEjerMeca;
				let url = new URL("https://oposiciones-justicia.es/api/file/loadFile?route="+pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				fetch(url, {
								method: 'GET',
								headers: {'Content-Type': 'application/json'},
								responseType: 'blob'
								}).then(res =>  {
										if (res.status >= 400) { throw new Error("Bad res from server");}
										return res.blob();
								}).then(data => {
									saveAs(data,nameFile);
								}).catch(function(err) {console.log(err)});
			} else if(e.target.innerText == 'Formato'){
				let pathFile = infoMeca[e.target.id].linkFormatoMeca;
				let url = new URL("https://oposiciones-justicia.es/api/file/loadFile?route="+pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				fetch(url, {
								method: 'GET',
								headers: {'Content-Type': 'application/json'},
								responseType: 'blob'
								}).then(res =>  {
										if (res.status >= 400) { throw new Error("Bad res from server");}
										return res.blob();
								}).then(data => {
									saveAs(data,nameFile);
								}).catch(function(err) {console.log(err)});

			} else if(e.target.innerText == 'Alumno'){
				let pathFile = infoMeca[e.target.id].linkEjerAlumno;
				let url = new URL("https://oposiciones-justicia.es/api/file/loadFile?route="+pathFile);
				let nameFile = pathFile.split('/').pop().split('-,-')[0];
				fetch(url, {
								method: 'GET',
								headers: {'Content-Type': 'application/json'},
								responseType: 'blob'
								}).then(res =>  {
										if (res.status >= 400) { throw new Error("Bad res from server");}
										return res.blob();
								}).then(data => {
									saveAs(data,nameFile);
								}).catch(function(err) {console.log(err)});
			} else {
					alert("Link no valido para descarga, reportelo a desarrollo, gracias.")
			}
}

		handleCorregirMeca(e){
			if(e.target.innerText == 'Corregir'){
				let { pulsacionesAlumno, penalizacionAlumno, formatoAlumno, tiempoPruebaMinutos,pulsacioneFinales } = this.state;
				let { infoMeca } = this.props;

				if(isDefined(pulsacionesAlumno) && isDefined(penalizacionAlumno) && isDefined(formatoAlumno) && isDefined(pulsacioneFinales)){
					let data = {
						idExpediente: infoMeca[e.target.id].idExpendiente,
						pulsaciones: pulsacionesAlumno,
						penalizacion: penalizacionAlumno,
						formato: formatoAlumno,
						pulsacionesReales: pulsacioneFinales,
						estado: 2
					}
					fetch("https://oposiciones-justicia.es/api/oficina/addExpendienteMecaCorregido", {
																 method: 'POST',
																 headers: {'Content-Type': 'application/json'},
																 body: JSON.stringify(data)
					}).then(response =>  {
								if (response.status >= 400) { throw new Error("Bad response from server");}
								return response.json();
					}).then(data => {
						if(data.affectedRows > 0){
							this.setState({pulsacionesAlumno:0, penalizacionAlumno:0, formatoAlumno:0, tiempoPruebaMinutos: 15,pulsacioneFinales:0});
						}}).catch(function(err) {console.log(err)});
						let id = e.target.id;
			      $('#slide'+id).slideToggle(1600);
						setTimeout(() => {
						  this.props.corregido();
						}, 1500);
				} else {
					alert("Complete todos los datos para corregir.");
				}
			}
		}

onChangeInput(e){
			let { pulsacionesAlumno, penalizacionAlumno, formatoAlumno, tiempoPruebaMinutos } = this.state;

			if(e.target.value == "" && (e.target.name == 'pulsacionesAlumno' || e.target.name == 'penalizacionAlumno' || e.target.name == 'formatoAlumno')){
				this.setState({[e.target.name]: 0});
			} else {
				  this.setState({[e.target.name]: e.target.value});
			}

			if(e.target.name == 'pulsacionesAlumno' && e.target.value != ""){
				let pulsaCuenta = (parseFloat(e.target.value) - parseFloat(penalizacionAlumno) + parseFloat(formatoAlumno))/tiempoPruebaMinutos ;
				this.setState({pulsacioneFinales: pulsaCuenta.toFixed(2)});
			} else if (e.target.name == 'penalizacionAlumno' && e.target.value != ""){
				let pulsaCuenta = (parseFloat(pulsacionesAlumno) - parseFloat(e.target.value) + parseFloat(formatoAlumno))/tiempoPruebaMinutos ;
				this.setState({pulsacioneFinales: pulsaCuenta.toFixed(2)});
			} else if (e.target.name == 'formatoAlumno' && e.target.value != ""){
				let pulsaCuenta = (parseFloat(pulsacionesAlumno) - parseFloat(penalizacionAlumno) + parseFloat(e.target.value))/tiempoPruebaMinutos ;
				this.setState({pulsacioneFinales: pulsaCuenta.toFixed(2)});
			}
}

    onToggleClick(e){
			let id = e.target.id;
      $('#slide'+id).slideToggle(1500);
    }

  render() {
    const { infoMeca } = this.props;
		const { tiempoPruebaMinutos } = this.state;
		return (
							<div>
								{infoMeca && infoMeca.map((meca, key) =>
										<div key={key} className="mecanografia">
							      	<div className="sideBarMecaInfo">
									     		<div className="sideBarMecaLeft">
									            <div className="bloqueMecaInfo">
									               <i className="fas fa-file-word"></i>
																 <div className="titleMecaInfo">{meca.tituloEjercicio}</div>
									            	</div>
									              <div className="bloqueMecaInfo">
									               	<i className="fas fa-address-card"></i> {meca.nombreAlumno}
									            	</div>
									         	</div>
									        	<div id={key} className="sideBarMecaToggle">
											  			<div id={key} className="toggleMecaInfo" onClick={this.onToggleClick}>
											      			<i id={key} className="fas fa-arrow-alt-circle-down"></i>
											     			</div>
														</div>
							        	</div>
							        	<div id={'slide'+key} className="infoCompletaMeca">
									       		<div className="infoCompletaMecaFlex">
														<div className="corregirMecaAlumno">
																<div id={key} name="descargarWordAlumno" className="botonSmall" style={{maxWidth: '135px'}} onClick={this.downloadFile}>
																	<i name="descargarWordAlumno" className="fas fa-file-word"></i>
																		Alumno
																		<i name="descargarWordAlumno" className="fas fa-download"></i>
																</div>
																<div id={key} name="descargarWordOficial" className="botonSmall" style={{maxWidth: '135px'}} onClick={this.downloadFile}>
																	<i name="descargarWordOficial" className="fas fa-file-word"> </i>
																		Oficial
																	<i name="descargarWordOficial" className="fas fa-download"></i>
																</div>
																<div id={key} name="descargarFormato"className="botonSmall" style={{maxWidth: '135px'}} onClick={this.downloadFile}>
																	<i name="descargarFormato" className="fas fa-file-pdf"> </i>
																		Formato
																	<i name="descargarFormato" className="fas fa-download"></i>
																</div>
														</div>
														<div className="inputMecaAlumno">

														<h44><i className="fas fa-info"> </i>Tiempo en Minutos<i className="fas fa-arrow-down"> </i></h44>
																<Input type="text" name="tiempoPruebaMinutos" placeholder="Tiempo en Minutos" icon="fas fa-hourglass-half" ref="ref_tiempoPruebaMinutos" onChange={this.onChangeInput} value={tiempoPruebaMinutos} title="Tiempo en realizar prueba, por defecto 15 minutos." />
																</div>

																	<div className="inputMecaAlumno">
																	<h55><i className="fas fa-info"></i>Introducir datos Word<i className="fas fa-arrow-down"></i></h55>
																			<Input type="number" min={0} name="pulsacionesAlumno" placeholder="Pulsaciones" icon="fas fa-calculator" ref="ref_pulsacionesAlumno" onChange={this.onChangeInput} />
																			<Input type="number" min={0} name="penalizacionAlumno" placeholder="Penalización" icon="fas fa-calculator" ref="ref_penalizacionAlumno" onChange={this.onChangeInput} />
																</div>
																<div className="inputMecaAlumno">
																		<Input type="number" min={0} name="formatoAlumno" placeholder="Formato" icon="fas fa-calculator" ref="ref_formatoAlumno" onChange={this.onChangeInput} />
																		<h44><i className="fas fa-info"> </i>Pulsaciones Totales<i className="fas fa-arrow-down"> </i></h44>
																		<Input type="number" name="puntuacionFinalAlumno" placeholder="Pulsacion Totales" icon="fas fa-poll" ref="ref_puntuacionFinalAlumno" value={this.state.pulsacioneFinales} onChange={this.onChangeInput} readOnly={true}/>
															</div>

																	<div className="corregirMecaAlumnoHandle">
																			<Submit id={key} name="añadirMecanografia" icon1="fas fa-upload" icon2="fas fa-database" texto="Corregir" onClick={this.handleCorregirMeca}/>
																	</div>

														</div>
							        		</div>
										</div>
							  )}
							</div>
              );
        }
 }

export default CorrectorMeca;

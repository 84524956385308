// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
// Assets
import './css/noticiaContainer.css';

// Components
import TextoRich from '../../components/Inputs/TextoRich';


class Escritorio extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    noticiaLoad: PropTypes.object.isRequired

  };
  constructor(props){
    super(props);
    this.state = {};

  }

  render() {
    const { isLogged, nombreUser, tipoUser, noticiaLoad } = this.props;
    if(isLogged){
    return (
      <div className="cuerpoNoticia">
      {noticiaLoad && noticiaLoad != undefined ? (
        <div className="noticiaUser">
          <div className="tituloNoticiaUser">
            <i className="fas fa-newspaper"></i>{noticiaLoad.titulo}
          </div>
          <div className="imgNoticiaUser">
            <div>
              <img src={`${noticiaLoad.imgNoticia}`} />
            </div>
          </div>
          <div className="noticiaCompletaUser">
            <TextoRich valueTextHtml={noticiaLoad.noticiaCompleta} />
          </div>
          <div className="fechaNUser" title="Fecha de Publicación">

            Fecha Publicación <i className="fas fa-calendar-alt"></i>{moment(noticiaLoad.fecha).format('DD-MM-YY HH:mm')}
          </div>
        </div>

      ):(
        <Redirect to ="/Escritorio" />
      )}


      </div>
    );
  } else {
    return(<Redirect to ="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser,
    noticiaLoad: state.user.noticiaLoad
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators ({
  },dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Escritorio);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets
import Input2 from '../../../../../components/Inputs/Input2';
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import PrgEdtSelected from '../../../../../components/PrgEdtSelected';

import './css.css';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    nombreUser: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      statusBarraProceso: 'completar',
      displayFecha: 'none',
      programacionClaseSeleted: undefined,
      semanasAtrasarPrep: 0,
      semanasAdelantarPosponer: 0,
      rellenado: 1,
      stop: 1,
      ultimasBusClase: null
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.onChangeInputNew = this.onChangeInputNew.bind(this);
    this.handleSubmitFormNew = this.handleSubmitFormNew.bind(this);
  }
  cleanForm() {
    switch (this.props.selectedOption) {
      case 'Editar_Programacion': {
        this.setState({
          oposicionSelecionada: undefined,
          claseSelecionada: undefined,
          statusBarraProceso: "completado",
          infoProgramaDefault: undefined,
          infoProgramaDefaultTemas: undefined,
          infoProgramaDefaultTemasApuntes: undefined,
          infoProgramaDefaultTemasIntervalos: undefined
        });
        this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
        this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
      } break;
      case 'Posponer_Clase': {
        let claseNombre = this.state.claseNombrePosponer;
        let semanas = this.state.semanasAdelantarPosponer;
        let fecha = moment(this.state.fechaPosponerClase).format("DD-MM-YY");
        swal("¡Clases Pospuestas!", "Se han adelantado todas las clases de la clase " + claseNombre + ", " + semanas + " semanas, a partir de " + fecha + ".", "success");
        this.setState({
          claseSelectedPosponer: undefined,
          claseSeleccionadaPosponer: undefined,
          semanasAdelantarPosponer: undefined,
          fechaPosponerClase: undefined,
          statusBarraProceso: "completado"
        });
        this.refs.ref_claseSeleccionadaPosponer.refs.claseSeleccionadaPosponer.value = '';
        this.refs.ref_semanasAdelantarPosponer.refs.semanasAdelantarPosponer.value = '';
        this.refs.ref_fechaPosponerClase.refs.fechaPosponerClase.value = '';
      } break;
      case 'Atrasar_Clases': {
        let claseNombreAtrasar = this.state.claseNombreAtrasar;
        let semanas2 = this.state.semanasAtrasarPrep;
        let fecha2 = moment(this.state.fechaAtrasarPrep).format("DD-MM-YY");
        swal("¡Clases Atrasadas!", "Se han atrasado todas las clases de la clase " + claseNombreAtrasar + ", " + semanas2 + " semanas, a partir de " + fecha2 + ".", "success");

        this.setState({
          semanasAtrasarPrep: undefined,
          fechaAtrasarPrep: undefined,
          claseSelectedAtrasar: undefined,
          claseSeleccionadaAtrasar: undefined,
          statusBarraProceso: "completado"
        });
        this.refs.ref_semanasAtrasarPrep.refs.semanasAtrasarPrep.value = '';
        this.refs.ref_fechaAtrasarPrep.refs.fechaAtrasarPrep.value = '';
        this.refs.ref_claseSeleccionadaAtrasar.refs.claseSeleccionadaAtrasar.value = '';
      } break;
    }
  }
  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Editar_Programacion') {


      if (e.target.name === 'claseSeleccionada') {


        let claseObject = this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {

          try {
            swal({
              title: 'Cargando contenido del Curso/Clase',
              text: 'En breve se mostrará el listado de semanas',
              icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })

            let data23 = { idClase: claseObject.idClase }
            //quitamos los alumnos sacarlos en prgBloque
            fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data23)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(cls => {
              if (cls != undefined) {
                let alumnosClase = [];
                for (var i = 0; i < cls.length; i++) {
                  alumnosClase.push({
                    idAlumno: cls[i].idAlumno,
                    nombreAlumno: cls[i].nombreAlumno,
                    apellidosAlumno: cls[i].apellidosAlumno,
                    linkFotoPerfil: cls[i].linkFotoPerfil
                  })
                }
                this.setState({ alumnosClase: alumnosClase })
              }
            }).catch(function (err) { console.log(err) });

            let data22345 = { id_oposicion: claseObject.idOposicion }
            let response2234 = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaTemasOposicion", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data22345)
            });
            if (!response2234.ok) {
              throw Error(response2234.statusText);
            }
            let json245 = await response2234.json();
            let cls23423 = await json245;
            this.setState({ busquedaTemasOposicion: cls23423 });
            let data1 = { idClase: claseObject.idClase }
            this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });

            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined) {
              this.setState({ busquedaClasesFecha: cls1 });
              //this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = moment().format('MM-YY');
            }

            var busquedaClasesFecha2 = await this.state.busquedaClasesFecha

            let data3 = {}


            let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRGN", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;



            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            let now = moment().add(15, 'd').format('YYYY-MM-DD HH:mm')
            let now2 = moment().subtract(15, 'd').format('YYYY-MM-DD HH:mm')
            let ultBusC = []
          
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {

              let cls3Bus = await cls3.filter(p => p.idPrg == busquedaClasesFecha2[k].idPrograma)

              
              // if (cls3Bus && cls3Bus.length > 0) {
              //   for (var z = 0; z < cls3.length; z++) {
              //     let locaBloque = await busquedaClasesFecha2.findIndex(c => c.idPrograma == cls3[z].idPrg && c.semanaClase == busquedaClasesFecha2[k].semanaClase);
              //     if (locaBloque != -1) {

              //       let busTem = await busquedaClasesFecha2[locaBloque].temas.findIndex(c => c.idTema == cls3[z].idTema)
              //       if (busTem == -1) {
              //         busquedaClasesFecha2[locaBloque].temas.push({
              //           idTema: cls3[z].idTema,
              //           tituloTema: cls3[z].tituloTema,
              //           nombreTema: cls3[z].nombreTema
              //         })
              //       }

              //     }
              //   }
              // }

              if (cls3Bus && cls3Bus.length > 0) {
                for (var z = 0; z < cls3.length; z++) {
                    // Filtrar todas las coincidencias por idPrograma y semanaClase
                    let clasesConCoincidencias = busquedaClasesFecha2.filter(c => c.idPrograma == cls3[z].idPrg && c.semanaClase == busquedaClasesFecha2[k].semanaClase);
                    
                    // Verificar si encontró clases coincidentes
                    if (clasesConCoincidencias.length > 0) {
                        for (let clase of clasesConCoincidencias) {
                            // Buscar si el tema ya existe en los temas de la clase
                            let busTem = clase.temas.findIndex(c => c.idTema == cls3[z].idTema);
                            
                            // Si no existe, lo añadimos
                            if (busTem == -1) {
                                clase.temas.push({
                                    idTema: cls3[z].idTema,
                                    tituloTema: cls3[z].tituloTema,
                                    nombreTema: cls3[z].nombreTema
                                });
                            }
                        }
                    }
                }
            }

            }

            for (var k2 = 0; k2 < busquedaClasesFecha2.length; k2++) {


              if (moment(busquedaClasesFecha2[k2].fechaClase).format('YYYY-MM-DD HH:mm') >= now2 && moment(busquedaClasesFecha2[k2].fechaClase).format('YYYY-MM-DD HH:mm') <= now) {
                ultBusC.push(busquedaClasesFecha2[k2])
              }

            }

            this.setState({ ultimasBusClase: ultBusC })



            // for (var i = 0; i < busquedaClasesFecha2.length; i++) {
            //   busquedaClasesFecha2[i].temas = []
            // }

            // for (var k = 0; k < busquedaClasesFecha2.length; k++) {
            //   let data3 = { idPrg: busquedaClasesFecha2[k].idPrograma }


            //   let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
            //     method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            //   });
            //   if (!response3.ok) {
            //     throw Error(response3.statusText);
            //   }
            //   let json3 = await response3.json();
            //   let cls3 = await json3;
            //   for (var z = 0; z < cls3.length; z++) {
            //     let locaBloque = await busquedaClasesFecha2.findIndex(c => c.idPrograma == cls3[z].idPrg && c.semanaClase == busquedaClasesFecha2[k].semanaClase);
            //     if (locaBloque != -1) {
            //       busquedaClasesFecha2[locaBloque].temas.push({
            //         idTema: cls3[z].idTema,
            //         tituloTema: cls3[z].tituloTema,
            //         nombreTema: cls3[z].nombreTema
            //       })
            //     }
            //   }
            // }

            this.setState({ busquedaClasesFecha: busquedaClasesFecha2, displayFecha: 'block' });
            swal.close()
          } catch (e) { console.log(e) }
        } else {
          this.setState({ busquedaClasesFecha: undefined, displayFecha: 'none', apuntesTemas: undefined, infoSemanaPrg: undefined, bloqueTemas: undefined, ultimasBusClase: null })
          // this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = '';
        }
      } else if (e.target.name === 'fechaSeleccionada') {
        let fechaClaseObject = this.state.busquedaClasesFecha.find(prg => moment(prg.fechaClase).format('DD-MM-YY HH:mm') == e.target.value);
        // console.log('fechaClaseObject')
        // console.log(fechaClaseObject)
        if (fechaClaseObject != undefined) {
          try {
            swal({
              title: 'Cargando contenido',
              text: 'En breve se mostrará la información de la semana seleccionada.',
              icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
            })

            let data1 = { fechaClase: moment(fechaClaseObject.fechaClase).format('YYYY-MM-DD HH:mm'), idPrg: fechaClaseObject.idPrograma, idClase: this.state.idClasePreparador }

            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaVirtual", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            if (cls1 != undefined) {
              let inforClase = [{
                idPrg: cls1[0].idPrg,
                videosClase: [],
                numeroSemanaClase: cls1[0].numeroSemanaClase,
                numeroSemanaProgramacion: cls1[0].numeroSemanaProgramacion,
                fechaClase: cls1[0].fechaClase,
                testJusticia: cls1[0].testJusticia,
                idTestJusticia: cls1[0].idTestJusticia,
                testWord: cls1[0].testWord,
                idTestWord: cls1[0].idTestWord,
                casoPractico: cls1[0].casoPractico,
                idCasoPractico: cls1[0].idCasoPractico,
                idMeca: cls1[0].idMeca,
                linkClaseOnline: cls1[0].linkClaseOnline,
                horaClaseOnline: moment(cls1[0].horaClaseOnline).format('YYYY-MM-DD HH:mm'),
                bloqueTemas: []
              }]

              this.setState({ ejerciciosClase: inforClase });
            } else {
              this.setState({ ejerciciosClase: undefined });
            }
            if (this.state.ejerciciosClase != undefined) {

              let data2 = { idPrg: this.state.ejerciciosClase[0].idPrg, idClase: this.state.idClasePreparador }
              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/bloqueSemPrgPre", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              let ejerciciosClase = this.state.ejerciciosClase

              for (var j = 0; j < cls2.length; j++) {
                ejerciciosClase[0].bloqueTemas.push({
                  idBloque: cls2[j].id_bloque,
                  tipoBloque: cls2[j].tipoBloque,
                  tipoBloqueTitulo: cls2[j].tipoBloque == 0 ? ('Normal') : ('Repaso'),
                  nuevo: 0,
                  editar: 0,
                  temas: [],
                  idExamenGestion: cls2[j].idExamenGestion,
                  testJusticia: [],
                  casoPractico: [],
                  examenGestionPdf: []

                })
              }

              this.setState({ ejerciciosClase: ejerciciosClase });
              let ejerciciosClase2 = this.state.ejerciciosClase

              for (var j = 0; j < ejerciciosClase2[0].bloqueTemas.length; j++) {
                // ahcer para meter los test justicia y casos y EXAMENES GESTION en for!

                let data33456 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
                let responsedata33456 = await fetch("https://oposiciones-justicia.es/api/preparador/testJusticiaBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
                });
                if (!responsedata33456.ok) {
                  throw Error(responsedata33456.statusText);
                }
                let jsondata33456 = await responsedata33456.json();
                let clsdata33456 = await jsondata33456;
                if (clsdata33456 && clsdata33456 != undefined) {
                  for (var v = 0; v < clsdata33456.length; v++) {
                    ejerciciosClase2[0].bloqueTemas[j].testJusticia.push({ idTestJusticia: clsdata33456[v].id_test, nombre: clsdata33456[v].nombre_test })
                  }
                }

                let responsedata308 = await fetch("https://oposiciones-justicia.es/api/preparador/casoPracticoBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
                });
                if (!responsedata308.ok) {
                  throw Error(responsedata308.statusText);
                }
                let jsondata308 = await responsedata308.json();
                let clsdata308 = await jsondata308;
                if (clsdata308 && clsdata308 != undefined) {
                  for (var v = 0; v < clsdata308.length; v++) {
                    ejerciciosClase2[0].bloqueTemas[j].casoPractico.push({ idTestCP: clsdata308[v].id_test, nombre: clsdata308[v].nombre_cp })
                  }
                }

                let responsedata308xd = await fetch("https://oposiciones-justicia.es/api/preparador/examenDesarrolloBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
                });
                if (!responsedata308xd.ok) {
                  throw Error(responsedata308xd.statusText);
                }
                let jsondata308xd = await responsedata308xd.json();
                let clsdata308xd = await jsondata308xd;
                if (clsdata308xd && clsdata308xd != undefined) {
                  for (var v = 0; v < clsdata308xd.length; v++) {
                    ejerciciosClase2[0].bloqueTemas[j].examenGestionPdf.push({ idExamenGestion: clsdata308xd[v].id_test, nombre: clsdata308xd[v].nombre })
                  }
                }



                let data3 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idPrograma: ejerciciosClase2[0].idPrg }

                let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/temasDeBloqu1e", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                for (var z = 0; z < cls3.length; z++) {
                  ejerciciosClase2[0].bloqueTemas[j].temas.push({
                    idTemaPrg: cls3[z].idTemaPrg,
                    idTema: cls3[z].idTema,
                    nombreTema: cls3[z].nombreTema,
                    tituloTema: cls3[z].tituloTema,
                    nuevo: 0,
                    editar: 0,
                    intervalosLeyes: [],
                    apuntes: [],
                    nota: cls3[z].nota
                  })

                }


              }
              this.setState({ ejerciciosClase: ejerciciosClase2 })
            }
            // en vez de semanaPrg debemos hacerlo como insertar infoSemanaPrg y bloqueTemas


            let ejerciciosClase = await this.state.ejerciciosClase
            let infoSemanaPrg = {
              idPrg: ejerciciosClase[0].idPrg,
              idOposicion: this.state.idOposicion,
              linkClaseOnline: ejerciciosClase[0].linkClaseOnline,
              horaClaseOnline: ejerciciosClase[0].horaClaseOnline,
              numeroSemanaClase: ejerciciosClase[0].numeroSemanaClase,
              numeroSemanaProgramacion: ejerciciosClase[0].numeroSemanaProgramacion,
              fechaClase: cls1[0].fechaClase,
              idClase: this.state.idClasePreparador
            }

            let bloqueTemas = ejerciciosClase[0].bloqueTemas

            this.setState({ infoSemanaPrg: infoSemanaPrg, bloqueTemas: bloqueTemas })
            swal.close();
          } catch (e) { console.log(e) }


        } else {
          this.setState({ infoSemanaPrg: undefined, bloqueTemas: undefined })
        }
      }

    }

  }

  async onChangeInputNew(e, t = 'nada') {
    let { infoSemanaPrg, bloqueTemas } = this.state
    //console.log(infoSemanaPrg)
    //console.log(bloqueTemas)
    if (t == 'rellenadoClose') {
      await this.setState({ rellenado: 1, stop: 1 })
    } else if (t == 'rellenadoCloseStop') {
      await this.setState({ stop: 1 })
    } else if (e.target.name == 'test_pred_añadir') {

      let objectTest = this.state.busquedaTestPre.filter(t => t.nombre_test == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idTestJusticia = objectTest[0].id_test
        infoSem.testJusticia = objectTest[0].nombre_test
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'test_word_pred_añadir') {

      let objectTest = this.state.busquedaTestPreWord.filter(t => t.nombre_test == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idTestWord = objectTest[0].id_test
        infoSem.testWord = objectTest[0].nombre_test
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'test_caso_practico_pred_añadir') {

      let objectTest = this.state.busquedaTestPreCasoPractico.filter(t => t.nombre_cp == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idCasoPractico = objectTest[0].id_caso_practico
        infoSem.casoPractico = objectTest[0].nombre_cp

        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name == 'meca_pred_añadir') {

      let objectTest = this.state.busquedaMeca.filter(t => t.titulo_ejercicio == e.target.value)
      if (objectTest.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.idCasoMecanografia = objectTest[0].id_meca
        await this.setState({ infoSemanaPrg: infoSem })
      }

    } else if (e.target.name.split('-')[0] == 'test_pred_añadir') {

      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.busquedaTestPre.filter(t => t.nombre_test == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idTestExtra = objectTest[0].id_test
        bloqueTemas[locaBloque].testJusticia = objectTest[0].nombre_test

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'test_caso_practico_pred_añadir') {
      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.busquedaTestPreCasoPractico.filter(t => t.nombre_cp == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idCasoExtra = objectTest[0].id_caso_practico
        bloqueTemas[locaBloque].casoPractico = objectTest[0].nombre_cp

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'tipoBloque') {
      let locaBloque = e.target.name.split('-')[1]
      if (e.target.value == 'Normal') {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].tipoBloque = 0
        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }
        await this.setState({ bloqueTemas: bloqueTemas })
      } else if (e.target.value == "Repaso") {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].tipoBloque = 1
        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }
        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'temaName') {
      try {
        let locaBloque = e.target.name.split('-')[1]
        let locaTema = e.target.name.split('-')[2]
        let objectTemas = await this.state.busquedaTemasOposicion.filter(t => t.tituloTema == e.target.value)
        if (await objectTemas.length > 0) {
          let bloqueTemas = await this.state.bloqueTemas
          bloqueTemas[locaBloque].temas[locaTema].nuevo = 1;
          bloqueTemas[locaBloque].temas[locaTema].editar = 0;
          bloqueTemas[locaBloque].temas[locaTema].idTema = objectTemas[0].idTema
          bloqueTemas[locaBloque].temas[locaTema].nombreTema = objectTemas[0].tituloTema
          bloqueTemas[locaBloque].temas[locaTema].tituloTema = objectTemas[0].nombreTema
          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = []

          await this.setState({ bloqueTemas: bloqueTemas })

          let data = { idTema: objectTemas[0].idTema }
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/intervalosLeyesTema", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          for (var j = 0; j < cls1.length; j++) {
            let bloqueTemas = await this.state.bloqueTemas
            let busquedaLey = await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.findIndex(int => int.idLey == cls1[j].idLey)
            if (busquedaLey != -1) {
              bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[busquedaLey].intervalosArticulos.push({
                artInicio: cls1[j].artInicio,
                artFin: cls1[j].artFin
              })
            } else {
              if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes == undefined) {
                bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = [];
              }
              bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.push({
                idLey: cls1[j].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[j].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[j].idLey).nombre_ley,
                intervalosArticulos: [{
                  artInicio: cls1[j].artInicio,
                  artFin: cls1[j].artFin
                }]
              })
            }

          }

          bloqueTemas[locaBloque].temas[locaTema].apuntes = []

          await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

        }

      } catch (e) { console.log(e) }
    } else if (e.target.name.split('-')[0] == 'siglasLey') {
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaInter = e.target.name.split('-')[3]
      let locaInterIF = e.target.name.split('-')[4]

      let objectLeyes = this.state.busquedaLeyes.filter(l => l.siglas_ley == e.target.value)
      if (objectLeyes.length > 0) {

        // buscar si existe la ley sino quitarlo y sus art Ini y fin.

        let bloqueTemas = await this.state.bloqueTemas
        let busquedaLey = await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.findIndex(f => f.idLey == objectLeyes[0].id_ley)
        if (busquedaLey != -1) {

          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[busquedaLey].intervalosArticulos.push({
            artInicio: undefined,
            artFin: undefined,
            nuevo: 1,
            editar: 0
          })

          if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.length == 1) {
            await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.splice(locaInter, 1)
          } else {
            await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.splice(locaInterIF, 1)
          }



          await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
        } else {

          if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.length == 1) {
            await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.splice(locaInter, 1)
          } else {
            await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.splice(locaInterIF, 1)
          }

          if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes == undefined) {
            bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = [];
          }
          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.push({
            idLey: objectLeyes[0].id_ley,
            siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == objectLeyes[0].id_ley).siglas_ley,
            nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == objectLeyes[0].id_ley).nombre_ley,
            intervalosArticulos: [{
              artInicio: undefined,
              artFin: undefined,
              nuevo: 1,
              editar: 0
            }]
          })
          await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
        }
      }

    } else if (e.target.name.split('-')[0] == 'artInicio') {
      try {
        let value = e.target.value
        let locaBloque = e.target.name.split('-')[1]
        let locaTema = e.target.name.split('-')[2]
        let locaInter = e.target.name.split('-')[3]
        let locaInterIF = e.target.name.split('-')[4]

        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].artInicio = value
        if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].nuevo == 0) {
          bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].editar = 1
        }
        await this.setState({ bloqueTemas: bloqueTemas })

      } catch (e) { console.log(e) }


    } else if (e.target.name.split('-')[0] == 'artFin') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaInter = e.target.name.split('-')[3]
      let locaInterIF = e.target.name.split('-')[4]

      let bloqueTemas = await this.state.bloqueTemas

      bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].artFin = value
      if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].nuevo == 0) {
        bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos[locaInterIF].editar = 1
      }
      await this.setState({ bloqueTemas: bloqueTemas })
    } else if (e.target.name.split('-')[0] == 'apunte') {
      let value = e.target.value
      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let locaApunte = e.target.name.split('-')[3]

      let objectApuntes = this.state.apuntesTemas.filter(l => l.titulo == value && l.idTema == this.state.bloqueTemas[locaBloque].temas[locaTema].idTema)

      if (objectApuntes.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].idApunte = objectApuntes[0].idApunte
        bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].nombreApunte = objectApuntes[0].titulo
        bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].linkPdf = objectApuntes[0].linkPdf

        if (bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].nuevo == 0) {
          bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].editar = 1
        }
        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'video') {

      let value = e.target.value
      let locaVideo = parseInt(e.target.name.split('-')[1])

      let objectVideo = await this.state.videosSemanas.filter(l => l.link == value)

      if (objectVideo.length > 0) {
        let infoSem = await this.state.infoSemanaPrg
        infoSem.videosClase[locaVideo].id_video = objectVideo[0].id_video
        infoSem.videosClase[locaVideo].titulo = objectVideo[0].titulo
        infoSem.videosClase[locaVideo].link = objectVideo[0].link
        await this.setState({ infoSemanaPrg: infoSem })

      }

    } else if (e.target.name.split('-')[0] == 'claseDirectoLink') {

      let value = e.target.value

      let infoSem = await this.state.infoSemanaPrg
      if (value == undefined) {
        infoSem.linkClaseOnline = ''
      } else {
        infoSem.linkClaseOnline = value
      }

      await this.setState({ infoSemanaPrg: infoSem })

    } else if (e.target.name.split('-')[0] == 'timeClaseOnline') {

      let value = e.target.value

      let infoSem = await this.state.infoSemanaPrg
      if (value != undefined) {
        infoSem.horaClaseOnline = value
      }

      await this.setState({ infoSemanaPrg: infoSem })

    } else if (e.target.name.split('-')[0] == 'cpSeleted') {

      let locaBloque = e.target.name.split('-')[1]
      let locaTest = e.target.name.split('-')[2]

      let objectTest = this.state.busquedaTestPreCasoPractico.filter(t => t.nombre_cp == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].casosPractico[locaTest].idCasoPractico = objectTest[0].id_caso_practico
        bloqueTemas[locaBloque].casosPractico[locaTest].nombre = objectTest[0].nombre_cp

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'testSeleted') {

      let locaBloque = e.target.name.split('-')[1]
      let locaTest = e.target.name.split('-')[2]

      let objectTest = this.state.busquedaTestPre.filter(t => t.nombre_test == e.target.value && t.id_oposicion == this.state.idOposicion)
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].testsJusticia[locaTest].idTestJusticia = objectTest[0].id_test
        bloqueTemas[locaBloque].testsJusticia[locaTest].nombre = objectTest[0].nombre_test

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'wordPdf') {

      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.busquedaWordPDF.filter(t => t.id == e.target.value.split('-')[0])
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idWordTestPDF = objectTest[0].id
        bloqueTemas[locaBloque].nombrePdfWord = objectTest[0].nombre
        bloqueTemas[locaBloque].linkTest = objectTest[0].linkTest

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'examenGestionPdf') {

      let locaBloque = e.target.name.split('-')[1]

      let objectTest = this.state.loadExamanesGestion.filter(t => t.id == e.target.value.split('-')[0])
      if (objectTest.length > 0) {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas[locaBloque].idExamenGestion = objectTest[0].id
        bloqueTemas[locaBloque].nombreExamenGestion = objectTest[0].nombre
        bloqueTemas[locaBloque].link = objectTest[0].linkExamen

        if (bloqueTemas[locaBloque].nuevo == 0) {
          bloqueTemas[locaBloque].editar = 1
        }

        await this.setState({ bloqueTemas: bloqueTemas })
      }

    } else if (e.target.name.split('-')[0] == 'nota') {

      let locaBloque = e.target.name.split('-')[1]
      let locaTema = e.target.name.split('-')[2]
      let value = e.target.value
      let bloqueTemas = await this.state.bloqueTemas
      bloqueTemas[locaBloque].temas[locaTema].nota = value

      if (bloqueTemas[locaBloque].nuevo == 0) {
        bloqueTemas[locaBloque].editar = 1
      }

      await this.setState({ bloqueTemas: bloqueTemas })


    }


  }
  async handleSubmitFormNew(e) {
    //console.log(e.target.id)
    if (e.target.id == 'edt_semana_prg') {
      try {
        let { infoSemanaPrg } = this.state

        let data1 = { infoSemanaPrg: infoSemanaPrg, idPrg: infoSemanaPrg.idPrg }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateProgramOpo", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        // Borrar videos
        let data2 = { idPrg: infoSemanaPrg.idPrg }
        let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/deleteVideos", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        // meter videos

        for (var i = 0; i < infoSemanaPrg.videosClase.length; i++) {
          let data3 = { video: infoSemanaPrg.videosClase[i], idPrg: infoSemanaPrg.idPrg }
          let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/insertarVideo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json2 = await response3.json();
          let cls3 = await json2;
        }



        swal('Semana edita correctamente', '', 'success')


      } catch (e) { console.log(e) }

    } else if (e.target.id == 'add_bloque_temas') {
      try {
        let bloqueTemas = await this.state.bloqueTemas
        bloqueTemas.push({
          idBloque: undefined,
          idTestExtra: undefined,
          idCasoExtra: undefined,
          tipoBloque: undefined,
          nuevo: 1,
          editar: 0,
          temas: [],
          alumnos: [],
          testsJusticia: [],
          casosPractico: []

        })
        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'delBloqueTema') {
      let locaBloque = e.target.id.split('-')[1]
      let bloqueTemas = await this.state.bloqueTemas

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el bloque de Temas será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            // borrar en BD! API FAST! si nuevo == 0
            if (bloqueTemas[locaBloque].nuevo == 1) {
              await bloqueTemas.splice(locaBloque, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            } else {
              // borrar en BD fast
              let data1 = { idBloque: bloqueTemas[locaBloque].idBloque }
              let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/deleteBlockPrg", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;

              await bloqueTemas.splice(locaBloque, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            }

          } else {
            swal("¡Bloque no borrado!", '', "success");
          }
        });
    } else if (e.target.id.split('-')[0] == 'add_tema_bloque') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas

        await bloqueTemas[locaBloque].temas.push({
          idTemaPrg: undefined,
          idTema: undefined,
          tipoTema: undefined,
          tituloTema: undefined,
          nuevo: 1,
          editar: 0,
          intervalosLeyes: [],
          apuntes: []
        })
        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'borrarTema') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            if (bloqueTemas[locaBloque].temas[locaTema].nuevo == 1) {
              await bloqueTemas[locaBloque].temas.splice(locaTema, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            } else {
              //borrar de BD fast
              let data1 = { idBloque: bloqueTemas[locaBloque].idBloque, idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg }
              let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/deleteBlockPrgTema", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              await bloqueTemas[locaBloque].temas.splice(locaTema, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            }


          } else {
            swal("¡Tema no borrado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'add_intervalor_tema_ley') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.push({
        idLey: undefined,
        nombreLey: undefined,
        siglasLey: undefined,
        nuevo: 1,
        editar: 0,
        intervalosArticulos: [{ artInicio: undefined, artFin: undefined, nuevo: 1, editar: 0 }]
      })
      await this.setState({ bloqueTemas: bloqueTemas })

    } else if (e.target.id.split('-')[0] == 'borrarIntervalo') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let locaInter = e.target.id.split('-')[3]
      let locaInterIF = e.target.id.split('-')[4]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el intervalo del tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {


            if (bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.length == 1) {
              await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.splice(locaInter, 1)
            } else {
              await bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[locaInter].intervalosArticulos.splice(locaInterIF, 1)
            }
            await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

          } else {
            swal("¡Intervalo de tema no borrado!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'add_apunte_tema') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas

      await bloqueTemas[locaBloque].temas[locaTema].apuntes.push({
        idApunte: undefined,
        nombreApunte: undefined,
        nuevo: 1,
        editar: 0
      })
      await this.setState({ bloqueTemas: bloqueTemas })

    } else if (e.target.id.split('-')[0] == 'borrarApunte') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let locaApunte = e.target.id.split('-')[3]
      let bloqueTemas = this.state.bloqueTemas


      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el apunte del tema será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            if (bloqueTemas[locaBloque].temas[locaTema].apuntes[locaApunte].nuevo == 1) {
              await bloqueTemas[locaBloque].temas[locaTema].apuntes.splice(locaApunte, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            } else {
              await bloqueTemas[locaBloque].temas[locaTema].apuntes.splice(locaApunte, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            }


          } else {
            swal("¡Apunte no borrado!", '', "success");
          }
        });

    } else if (e.target.id.split('-')[0] == 'activarTodosAlumnos') {
      let { alumnosClase } = this.state;
      let locaBloque = e.target.id.split('-')[1]
      let bloqueTemas = this.state.bloqueTemas

      for (var i = 0; i < alumnosClase.length; i++) {
        let idAlumno = alumnosClase[i].idAlumno;
        let existe = bloqueTemas[locaBloque].alumnos.findIndex(alumnos => alumnos.idAlumno == idAlumno)
        if (existe != -1) {
          if (bloqueTemas[locaBloque].alumnos[existe].activo == 0) {
            bloqueTemas[locaBloque].alumnos[existe].activo = 1;
            if (bloqueTemas[locaBloque].alumnos[existe].nuevo == 0) {
              bloqueTemas[locaBloque].alumnos[existe].editar = 1;
            } else {
              bloqueTemas[locaBloque].alumnos[existe].editar = 0;
            }
          }
          await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
        } else {
          let alumnosObject = await alumnosClase.find(alumnos => alumnos.idAlumno == idAlumno)
          bloqueTemas[locaBloque].alumnos.push({
            activo: 1,
            editar: 0,
            nuevo: 1,
            idBloque: bloqueTemas[locaBloque].idBloque,
            idAlumno: alumnosObject.idAlumno,
            nombreAlumno: alumnosObject.nombreAlumno,
            apellidosAlumno: alumnosObject.apellidosAlumno,
            linkFotoPerfil: alumnosObject.linkFotoPerfil
          });

          await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
        }
      }
    } else if (e.target.id.split('-')[0] == 'alumno') {
      let locaBloque = e.target.id.split('-')[1];
      let posicionAlumno = e.target.id.split('-')[2];
      let bloqueTemas = this.state.bloqueTemas;

      let idAlumno = this.state.alumnosClase[posicionAlumno].idAlumno;
      let posAlumBlock = bloqueTemas[locaBloque].alumnos.findIndex(alumno => alumno.idAlumno == idAlumno);
      if (posAlumBlock != -1) {
        let estadoActivo = bloqueTemas[locaBloque].alumnos[posAlumBlock].activo
        if (estadoActivo == 0) {
          bloqueTemas[locaBloque].alumnos[posAlumBlock].activo = 1;
          if (bloqueTemas[locaBloque].alumnos[posAlumBlock].nuevo == 0) {
            bloqueTemas[locaBloque].alumnos[posAlumBlock].editar = 1;
          } else {
            bloqueTemas[locaBloque].alumnos[posAlumBlock].editar = 0;
          }

        } else if (estadoActivo == 1) {
          bloqueTemas[locaBloque].alumnos[posAlumBlock].activo = 0;
          if (bloqueTemas[locaBloque].alumnos[posAlumBlock].nuevo == 0) {
            bloqueTemas[locaBloque].alumnos[posAlumBlock].editar = 1;
          } else {
            bloqueTemas[locaBloque].alumnos[posAlumBlock].editar = 0;
          }

        }

        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
      } else {
        let alumnosObject = await this.state.alumnosClase.find(alumnos => alumnos.idAlumno == idAlumno)
        bloqueTemas[locaBloque].alumnos.push({
          activo: 1,
          editar: 0,
          nuevo: 1,
          idBloque: bloqueTemas[locaBloque].idBloque,
          idAlumno: alumnosObject.idAlumno,
          nombreAlumno: alumnosObject.nombreAlumno,
          apellidosAlumno: alumnosObject.apellidosAlumno,
          linkFotoPerfil: alumnosObject.linkFotoPerfil
        });
        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
      }
    } else if (e.target.id.split('-')[0] == 'editarBloque') {
      try {
        let { infoSemanaPrg, bloqueTemas } = this.state
        let locaBloque = e.target.id.split('-')[1]



        if (bloqueTemas[locaBloque].tipoBloque != undefined) {
          if (bloqueTemas[locaBloque].nuevo == 0) {

            let data1 = { bloque: bloqueTemas[locaBloque], idBloque: bloqueTemas[locaBloque].idBloque }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloquePrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined) {
              let bloqueNum = parseInt(locaBloque) + 1
              // actualizar test justicia y caso practico
              //del justicia
              let data2 = { idBloque: bloqueTemas[locaBloque].idBloque }
              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/delJusBloq", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              for (var i = 0; i < bloqueTemas[locaBloque].testsJusticia.length; i++) {
                let data3 = { idBloque: bloqueTemas[locaBloque].idBloque, idTestJusticia: bloqueTemas[locaBloque].testsJusticia[i].idTestJusticia }
                let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/addJusBloq", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
              }

              let data4 = { idBloque: bloqueTemas[locaBloque].idBloque }
              let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/delCPBloq", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
              });
              if (!response4.ok) {
                throw Error(response4.statusText);
              }
              let json4 = await response4.json();
              let cls4 = await json4;

              for (var i = 0; i < bloqueTemas[locaBloque].casosPractico.length; i++) {
                let data5 = { idBloque: bloqueTemas[locaBloque].idBloque, idCasoPractico: bloqueTemas[locaBloque].casosPractico[i].idCasoPractico }
                let response5 = await fetch("https://oposiciones-justicia.es/api/preparador/addCPBloq", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
                });
                if (!response5.ok) {
                  throw Error(response5.statusText);
                }
                let json5 = await response5.json();
                let cls5 = await json5;
              }

              swal('Bloque Nº ' + bloqueNum + ', editado correctamente', '', 'success')


            }

          } else {
            let data1 = { bloque: bloqueTemas[locaBloque], idPrg: infoSemanaPrg.idPrg, idClase: infoSemanaPrg.idClase }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/insertBloquePrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            bloqueTemas[locaBloque].idBloque = await cls1.insertId
            for (var i = 0; i < bloqueTemas[locaBloque].alumnos.length; i++) {
              bloqueTemas[locaBloque].alumnos[i].idBloque = bloqueTemas[locaBloque].idBloque
            }
            for (var i = 0; i < bloqueTemas[locaBloque].alumnos.length; i++) {
              bloqueTemas[locaBloque].alumnos[i].idBloque = bloqueTemas[locaBloque].idBloque
            }

            // Insertar Test y casos
            // insertar test Justicia
            if (bloqueTemas[locaBloque].testsJusticia.length > 0) {
              for (var k = 0; k < bloqueTemas[locaBloque].testsJusticia.length; k++) {
                // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[locaBloque].temas[j].apuntes[k]
                let data6 = { idBloque: bloqueTemas[locaBloque].idBloque, idTest: bloqueTemas[locaBloque].testsJusticia[k].idTestJusticia }
                let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTestJusticiaBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
                });
                if (!response6.ok) {
                  throw Error(response6.statusText);
                }
                let json6 = await response6.json();
                let cls6 = await json6;
              }
            }
            //insertar casos practicos
            if (bloqueTemas[locaBloque].casosPractico.length > 0) {
              for (var k = 0; k < bloqueTemas[locaBloque].casosPractico.length; k++) {
                // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[locaBloque].temas[j].apuntes[k]
                let data7 = { idBloque: bloqueTemas[locaBloque].idBloque, idTest: bloqueTemas[locaBloque].casosPractico[k].idCasoPractico }
                let response7 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarCasoPracticoBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data7)
                });
                if (!response7.ok) {
                  throw Error(response7.statusText);
                }
                let json7 = await response7.json();
                let cls7 = await json7;
              }
            }

            // INSERTAR TEMAS Y ALUMNOS.

            let alumnosInsert = bloqueTemas[locaBloque].alumnos.filter(a => a.activo == 1)


            if (alumnosInsert && alumnosInsert.length > 0) {
              let data123 = { alumnos: alumnosInsert, idBloque: bloqueTemas[locaBloque].idBloque }
              let response123 = await fetch("https://oposiciones-justicia.es/api/preparador/insertAlumBloquePrg", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
              });
              if (!response123.ok) {
                throw Error(response123.statusText);
              }
              let json123 = await response123.json();
              let cls1 = await json123;
            }

            // 2º.2 Temas de bloque --> FOR (j) bloqueTemas[i].temas.length
            if (bloqueTemas[locaBloque].temas && bloqueTemas[locaBloque].temas.length > 0) {
              for (var j = 0; j < bloqueTemas[locaBloque].temas.length; j++) {
                // 2º.2.1 Tema de bloque --> insertar bloqueTemas[locaBloque].temas[j]
                let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[locaBloque].idBloque, tema: bloqueTemas[locaBloque].temas[j] }
                let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                bloqueTemas[locaBloque].temas[j].idTemaPrg = cls3.insertId
                // idTemaPrg
                // 2º.2.1.1 Intervalos de Leyes de Tema --> FOR (k) bloqueTemas[locaBloque].temas[j].intervalosLeyes.length
                for (var k = 0; k < bloqueTemas[locaBloque].temas[j].intervalosLeyes.length; k++) {
                  for (var z = 0; z < bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].intervalosArticulos.length; z++) {

                    // 2º.2.1.1.1 Insertar leyes --> bloqueTemas[locaBloque].temas[j].intervalosLeyes[k]
                    let data3 = {
                      idTemaPrg: bloqueTemas[locaBloque].temas[j].idTemaPrg,
                      idLey: bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].idLey,
                      artInicio: bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].intervalosArticulos[z].artInicio,
                      artFin: bloqueTemas[locaBloque].temas[j].intervalosLeyes[k].intervalosArticulos[z].artFin
                    }
                    let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/insertarIntervaloLey", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                    });
                    if (!response4.ok) {
                      throw Error(response4.statusText);
                    }
                    let json4 = await response4.json();
                    let cls4 = await json4;
                  }
                }
                // 2º.2.1.2 apuntes de Tema --> FOR (k) bloqueTemas[locaBloque].temas[j].apuntes.length
                for (var k = 0; k < bloqueTemas[locaBloque].temas[j].apuntes.length; k++) {
                  // 2º.2.1.1.2 Insertar apuntes --> bloqueTemas[locaBloque].temas[j].apuntes[k]
                  let data4 = { idTemaPrg: bloqueTemas[locaBloque].temas[j].idTemaPrg, apunte: bloqueTemas[locaBloque].temas[j].apuntes[k] }
                  let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarApunteTemaPrg", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                  });
                  if (!response5.ok) {
                    throw Error(response5.statusText);
                  }
                  let json5 = await response5.json();
                  let cls5 = await json5;
                }
              }
            }
            //fin for de temas
            let numBloque = parseInt(parseInt(locaBloque) + 1)
            swal('Bloque Nº ' + numBloque + ', insertado correctamente', '', 'success');
            bloqueTemas[locaBloque].nuevo = 0
            for (var i = 0; i < bloqueTemas[locaBloque].alumnos.length; i++) {
              bloqueTemas[locaBloque].alumnos[i].nuevo = 0;
            }
            await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
          }
        } else {
          swal('Error, debe rellenar el tipo de bloque, test justicia y caso practico', '', 'error')
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id.split('-')[0] == 'editarAlumnos') {
      try {
        let { bloqueTemas } = this.state
        let locaBloque = e.target.id.split('-')[1]
        if (bloqueTemas[locaBloque].idBloque != undefined) {
          for (var i = 0; i < bloqueTemas[locaBloque].alumnos.length; i++) {
            bloqueTemas[locaBloque].alumnos[i].idBloque = bloqueTemas[locaBloque].idBloque
          }
          let alumnosInsert = await bloqueTemas[locaBloque].alumnos.filter(a => a.nuevo == 1)
          let alumnosUpdate = await bloqueTemas[locaBloque].alumnos.filter(a => a.nuevo == 0 && a.editar == 1)
          // insert
          if (alumnosInsert && alumnosInsert.length > 0) {
            let data1 = { alumnos: alumnosInsert, idBloque: bloqueTemas[locaBloque].idBloque }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/insertAlumBloquePrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }

          //update
          if (alumnosUpdate && alumnosUpdate.length > 0) {
            let data1 = { alumnos: alumnosUpdate, idBloque: bloqueTemas[locaBloque].idBloque }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateAlumBloquePrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
          }
          let numBloque = parseInt(locaBloque) + 1
          swal('Alumnos del bloque Nº ' + numBloque + ', editados correctamente', '', 'success')
          for (var f = 0; f < bloqueTemas[locaBloque].alumnos.length; f++) {
            bloqueTemas[locaBloque].alumnos[f].nuevo = 0
          }
          this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
        } else {
          swal('Para añadir los alumnos primero debe insertar el bloque', 'Para insertar el bloque seleccione tipo de bloque, test de justicia y caso practico. Despues presione sobre editar bloque.', 'error')
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id.split('-')[0] == 'editarTema') {

      try {
        let { infoSemanaPrg, bloqueTemas } = this.state

        let locaBloque = e.target.id.split('-')[1]
        let locaTema = e.target.id.split('-')[2]
        if (bloqueTemas[locaBloque].idBloque != undefined) {
          if (bloqueTemas[locaBloque].temas[locaTema].nuevo == 0) {

            let data1 = { idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/deleteApuntesTemaPRG", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/deleteLeyesTemaPRG", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response2.ok) {
              throw Error(response2.statusText);
            }
            let json2 = await response2.json();
            let cls2 = await json2;

            // Intervalos de Leyes de Tema
            for (var k = 0; k < bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.length; k++) {
              for (var z = 0; z < bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos.length; z++) {
                let data3 = {
                  idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg,
                  idLey: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].idLey,
                  artInicio: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos[z].artInicio,
                  artFin: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos[z].artFin
                }
                let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/insertarIntervaloLey", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response4.ok) {
                  throw Error(response4.statusText);
                }
                let json4 = await response4.json();
                let cls4 = await json4;
              }
            }
            // apuntes de Tema
            for (var k = 0; k < bloqueTemas[locaBloque].temas[locaTema].apuntes.length; k++) {
              let data4 = { idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, apunte: bloqueTemas[locaBloque].temas[locaTema].apuntes[k] }
              let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarApunteTemaPrg", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
              });
              if (!response5.ok) {
                throw Error(response5.statusText);
              }
              let json5 = await response5.json();
              let cls5 = await json5;
            }
            // nota
            let data6 = { idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, nota: bloqueTemas[locaBloque].temas[locaTema].nota }
            let response6 = await fetch("https://oposiciones-justicia.es/api/preparador/edtNotaTemaBloq", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
            });
            if (!response6.ok) {
              throw Error(response6.statusText);
            }
            let json6 = await response6.json();
            let cls6 = await json6;
            //FIN
            swal('Tema Nº ' + locaTema + 1 + ', editado correctamente', '', 'success');
            bloqueTemas[locaBloque].temas[locaTema].nuevo = 0
            await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
          } else {


            // Tema
            let data2 = { idSemanaPrg: infoSemanaPrg.idPrg, idBloque: bloqueTemas[locaBloque].idBloque, tema: bloqueTemas[locaBloque].temas[locaTema] }
            let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarTemaPrg", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;
            bloqueTemas[locaBloque].temas[locaTema].idTemaPrg = cls3.insertId

            // Intervalos de Leyes de Tema
            for (var k = 0; k < bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes.length; k++) {
              for (var z = 0; z < bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos.length; z++) {
                let data3 = {
                  idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg,
                  idLey: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].idLey,
                  artInicio: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos[z].artInicio,
                  artFin: bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes[k].intervalosArticulos[z].artFin
                }
                let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/insertarIntervaloLey", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response4.ok) {
                  throw Error(response4.statusText);
                }
                let json4 = await response4.json();
                let cls4 = await json4;
              }
            }
            // apuntes de Tema
            for (var k = 0; k < bloqueTemas[locaBloque].temas[locaTema].apuntes.length; k++) {
              let data4 = { idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, apunte: bloqueTemas[locaBloque].temas[locaTema].apuntes[k] }
              let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/insertarApunteTemaPrg", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
              });
              if (!response5.ok) {
                throw Error(response5.statusText);
              }
              let json5 = await response5.json();
              let cls5 = await json5;
            }

            //FIN
            swal('Tema Nº ' + locaTema + 1 + ', insertado correctamente', '', 'success')
            bloqueTemas[locaBloque].temas[locaTema].nuevo = 0
            await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

          }
        } else {
          swal('Para añadir un tema primero debe insertar el bloque', 'Para insertar el bloque seleccione tipo de bloque, test de justicia y caso practico. Despues presione sobre editar bloque.', 'error')
        }
      } catch (e) { console.log(e) }

    } else if (e.target.id.split('-')[0] == 'add_video') {

      let { infoSemanaPrg } = this.state

      await infoSemanaPrg.videosClase.push({
        titulo: undefined,
        link: undefined
      })
      await this.setState({ infoSemanaPrg: infoSemanaPrg })

    } else if (e.target.id.split('-')[0] == 'borrarVideo') {


      let infoSemanaPrg = this.state.infoSemanaPrg
      let locaVideo = e.target.id.split('-')[1]
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el video del tema será borrado al actualizar la Configuración de la semana",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          if (locaVideo != undefined) {
            await infoSemanaPrg.videosClase.splice(locaVideo, 1)
            await this.setState({ infoSemanaPrg: infoSemanaPrg, rellenado: 0 })
          }



        } else {
          swal("Video no borrado!", '', "success");
        }
      });


    } else if (e.target.id.split('-')[0] == 'addTestJusticiaBloq') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas

        await bloqueTemas[locaBloque].testsJusticia.push({
          idTestJusticia: undefined,
          nombre: undefined
        })
        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'borrarTestJus') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let locaTest = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas

        swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, el Test del bloque será borrado al actualizar el bloque",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (locaTest != undefined) {
              await bloqueTemas[locaBloque].testsJusticia.splice(locaTest, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            }

          } else {
            swal("Test no borrado!", '', "success");
          }
        });
      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'addCPJusticiaBloq') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas

        await bloqueTemas[locaBloque].casosPractico.push({
          idCasoPractico: undefined,
          nombre: undefined
        })
        await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'borrarCPJus') {
      try {

        let locaBloque = e.target.id.split('-')[1]
        let locaTest = e.target.id.split('-')[1]
        let bloqueTemas = await this.state.bloqueTemas

        swal({
          title: "¿Estas segur@?",
          text: "Si le das a ok, el Caso Practico será borrado al actualizar el bloque",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (locaTest != undefined) {
              await bloqueTemas[locaBloque].casosPractico.splice(locaTest, 1)
              await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })
            }

          } else {
            swal("¡Caso Practico no borrado!", '', "success");
          }
        });
      } catch (e) { console.log(e) }
    } else if (e.target.id.split('-')[0] == 'del_all_inter_leyes') {
      let locaBloque = e.target.id.split('-')[1]
      let locaTema = e.target.id.split('-')[2]
      let bloqueTemas = this.state.bloqueTemas
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, todos los intervalos del tema serán borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            bloqueTemas[locaBloque].temas[locaTema].intervalosLeyes = []

            await this.setState({ bloqueTemas: bloqueTemas, rellenado: 0 })

          } else {
            swal("¡Intervalos de tema no borrados!", '', "success");
          }
        });


    } else if (e.target.id.split('-')[0] == 'loadClase') {
      let locaClase = e.target.id.split('-')[1]
      let ultimasBusClase = this.state.ultimasBusClase
      
      let busUltimac = ultimasBusClase[locaClase]

    
      let e2 = { target: { name: 'fechaSeleccionada', id: 'fechaSeleccionada', value: moment(busUltimac.fechaClase).format('DD-MM-YY HH:mm') } }
     
      this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = moment(busUltimac.fechaClase).format('DD-MM-YY HH:mm');
      this.onChangeInput(e2)



    }


  }
  async componentDidMount() {
    try {
      fetch('https://oposiciones-justicia.es/api/preparador/videosSemanas')
        .then(res => res.json())
        .then(cls => this.setState({
          videosSemanas: cls
        })).catch(error => console.log('Obejet fecth failed', error))
      if (this.props.tipoUser < 90) {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            this.setState({ busquedaClasesPreparador: undefined });
          }
        }

      } else {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            this.setState({ busquedaClasesPreparador: undefined });
          }
        }
      }

    } catch (e) {
      console.log(e)
    }

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/prosemanal/consultaTestPred')
      .then(res => res.json())
      .then(test => this.setState({
        busquedaTestPre: test
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
      .then(res => res.json())
      .then(meca => this.setState({
        busquedaMeca: meca
      })).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreWord')
      .then(res => res.json())
      .then(info => this.setState({ busquedaTestPreWord: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreCasoPractico')
      .then(res => res.json())
      .then(info => this.setState({ busquedaTestPreCasoPractico: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/loadTestWord')
      .then(res => res.json())
      .then(info => this.setState({ busquedaWordPDF: info })
      ).catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
      .then(res => res.json())
      .then(info => this.setState({ loadExamanesGestion: info })
      ).catch(error => console.log('Object fecth failed', error))
  };

  render() {
    const { selectedOption } = this.props;
    const { idOposicion, programacionClaseSeleted, infoProgama, infoTemas, statusBarraProceso, displayFecha, busquedaClasesFecha, busquedaClasesPreparador, infoSemanaPrg, bloqueTemas, apuntesTemas, alumnosClase, ultimasBusClase } = this.state;
    // console.log('busquedaClasesFecha', busquedaClasesFecha)
    // console.log('busquedaClasesFecha')
    // console.log(busquedaClasesFecha)
    // console.log('bloqueTemas')
    // console.log(bloqueTemas)
    // console.log('ultimasBusClase')
    // console.log(ultimasBusClase)
    switch (selectedOption) {
      case 'Editar_Programacion': {
        return (
          <div className='ColCenterStart'>

            <div className='RowCenterCenter'>
              <div className="celdaInputDAPFBN animacionBotonEntra">
                <Input2
                  datalist="busquedaClasesPreparador"
                  autoComplete={"off"} id={"claseSeleccionada"} type="text" name={"claseSeleccionada"} ref={"ref_claseSeleccionada"} placeholder="Curso / Clase" icon="fas fa-tag" onChange={this.onChangeInput} />
                <p>Curso / Clase</p>
              </div>

              {displayFecha != 'none' ? (
                <div className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaClasesFecha"
                    autoComplete={"off"} id={"fechaSeleccionada"} type="text" name={"fechaSeleccionada"} ref={"ref_fechaSeleccionada"} placeholder="Seleccionar Semana" icon="fas fa-calendar-alt" onChange={this.onChangeInput} />
                  <p>Semana / Clase</p>
                </div>
              ) : null}
            </div>


            {displayFecha != 'none' && infoSemanaPrg != undefined && bloqueTemas != undefined ? (
              <PrgEdtSelected infoSemanaPrg={infoSemanaPrg} bloqueTemas={bloqueTemas} onChange={this.onChangeInputNew} alumnosClase={alumnosClase} Click={this.handleSubmitFormNew} />
            ) : null}

            {
              ultimasBusClase != null && ultimasBusClase.length > 0 && infoSemanaPrg == undefined ? (
                <div className='ColCenterStart' style={{ alignItems: 'stretch', marginTop:'15px',marginBottom: '15px' }}>
                  {
                    ultimasBusClase.map((bus, key2d34) =>
                      <div key={key2d34} id={"loadClase-" + key2d34} className='ColCenterStart rowClasePreBody'
                        onClick={this.handleSubmitFormNew}
                      >


                        <div className='RowCenterCenter' id={"loadClase-" + key2d34}>

                          <p id={"loadClase-" + key2d34} style={{
                            color: '#3469b7',
                            fontSize: '18px',
                            fontWeight: '500',
                            margin: '5px'
                          }}>
                            Clase Nº {bus.semanaClase + ' '}
                          </p>
                          <p id={"loadClase-" + key2d34} style={{
                            color: '#3469b7',
                            fontSize: '18px',
                            fontWeight: '500',
                            margin: '5px'
                          }}>
                            {moment(bus.fechaClase).format('DD-MM-YY')}
                          </p>

                        </div>

                        <div id={"loadClase-" + key2d34} className='RowCenterCenter'>
                          {bus.temas && bus.temas.length > 0 && bus.temas.map((temaSFDTR, keyTema) =>
                            <p id={"loadClase-" + key2d34} key={keyTema}>
                              {temaSFDTR.tituloTema + ' - ' + temaSFDTR.nombreTema}
                            </p>
                          )}
                        </div>



                      </div>
                    )
                  }

                </div>
              ) : null
            }

            <datalist id="busquedaClasesFecha">{
              busquedaClasesFecha && busquedaClasesFecha.map((cls, key) =>
                <option key={key} value={moment(cls.fechaClase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.semanaClase}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}</option>
              )
            }</datalist>
            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>

          </div>
        );
      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}
// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    optionSelected: state.baroption.optionSelected,
  }
}
// function mapDispatchToProps(dispatch) {
// 	return bindActionCreators({
// 		cargarContenido
// 	}, dispatch)
// }

export default connect(mapStateToProps, null)(OptionBar);



// Dependencias
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const { detect } = require('detect-browser');
import moment from 'moment';
// Assets
import './css/Body.css';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import BuscarDeArt from '../../../containers/BuscarDeArt'

import BotonFinolis from '../../Inputs/Boton3Finolis'
// Actions
import { cerrarSesion, loadCurso, loadClase, loadTemasFacturados, loadClaseExt, loadAllSemFac, cronoFunc, loadTestUserNUBE } from '../../../actions/userAction';

import { isDefined } from '../../../lib/utils/is';

class Body extends Component {
	static propTypes = {
		body: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			ctrlUserPersist: 0,
			ctrlUserPersistFac: 0,
			idUser: undefined,
			normasAceptadas: null,
			infoTipoCurso: 'presencial'
		};
		this.userPersist = this.userPersist.bind(this);
		this.userPersistFac = this.userPersistFac.bind(this);
		this.addOnSelectStart = this.addOnSelectStart.bind(this);
		this.onHandleClick = this.onHandleClick.bind(this);
	}
	async addOnSelectStart(node) {
		if (!node) return
		node.addEventListener("selectstart", e => {

			let asd = e.path != undefined ? e.path.filter(n => n.className == 'ck ck-editor__main') : ([])
			if (asd.length == 0) {
				e.preventDefault()
			}
		})


	}
	userPersist() {
		if (isDefined(this.props.idUser) && isDefined(this.props.userPersist) && isDefined(this.props.version) && this.props.version == "2.0-beta") {
			let data2 = { idUser: this.props.idUser }
			fetch("https://oposiciones-justicia.es/api/usuarios/accesoWeb", {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data2)
			}).then(response => {
				if (response.status >= 400) { throw new Error("Bad response from server"); }
				return response.json();
			}).then(cls => {
				if (cls[0].accesoWeb == 0 && cls[0].tipoUser < 45) {
					swal('Web en mantenimiento', '' + cls[0].texto, 'info', { buttons: false, closeOnEsc: false, closeOnClickOutside: false })
				}
			}).catch(error => console.log('Object fecth failed', error))

			let data = { idUser: this.props.idUser, userPersist: this.props.userPersist }
			fetch("https://oposiciones-justicia.es/api/usuarios/userPersist", {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data)
			}).then(response => {
				if (response.status >= 400) { throw new Error("Bad response from server"); }
				return response.json();
			}).then(cls => {

				if (cls[0].userPersistant == this.props.userPersist) {

					if (cls[0].activo == 1) {
						setTimeout(
							function () {
								this.userPersist()
							}.bind(this), 5000);

						if (isDefined(this.props.idClase) && this.state.ctrlUserPersistFac == 0 && this.props.isLogged == true) {
							this.setState({ ctrlUserPersistFac: 1 });
							setTimeout(() => {
								this.userPersistFac()
							}, 800);

						}
					} else {
						swal({
							title: 'Cuenta bloqueada',
							text: 'Para renaudar el acceso pongase en contacto con la academia',
							icon: "warning",
							buttons: false,
							closeOnEsc: false,
							closeOnClickOutside: false,
							timer: 2000,
						}).then(() => {
							localStorage.clear()
							window.location.reload();
						})
					}

				} else {

					// swal con timer
					swal({
						title: 'Inicio de sesión detectado',
						text: 'Se cerrará esta sesión en 2 segundos. Se inicio sesión en otro dispositivo. Si no ha sido usted, cambie la contraseña.',
						icon: "warning",
						buttons: false,
						closeOnEsc: false,
						closeOnClickOutside: false,
						timer: 2000,
					}).then(() => {
						localStorage.clear()
						window.location.reload();
					})



				}


			}).catch(error => console.log('Object fecth failed', error))
		} else {
			swal({
				title: 'Cerrando sesión, por actualización',
				text: '¡Necesita iniciar sesión nuevamente, para implementar la actualización de la aplicación! Se cerrara sesión automaticamente',
				icon: "warning",
				buttons: false,
				closeOnEsc: false,
				closeOnClickOutside: false,
				timer: 1500,
			}).then(() => {
				localStorage.clear()
				window.location.reload();
			})

		}

	}
	async onHandleClick(e) {
		if (e.target.id == "aceptar") {

			let data1 = { idUser: this.props.idUser }
			let response1 = await fetch('https://oposiciones-justicia.es/api/usuarios/normasAceptadasConfirm', {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
			});
			if (!response1.ok) {
				throw Error(response1.statusText);
			}
			let json1 = await response1.json();
			let cls1 = await json1;

			this.setState({ normasAceptadas: 1 })

		} else if (e.target.id == 'rechazar') {
			swal('Rechazado', 'Si rechaza las normas de la academia no podrá usar la plataforma. Puede cerrar sesión o solicitar la eliminacion de su cuenta directamente en nuestra centro o por correo a centroceestudiosjudiciales@gmail.com', 'info')
		} else if (e.target.id == 'nP') {

			this.setState({ infoTipoCurso: this.state.infoTipoCurso == 'presencial' ? ('Cursos_Online') : ('presencial') })

		}




	}

	async userPersistFac() {
		if (isDefined(this.props.idClase)) {
			try {
				// enviamos clasesAlumno "cursos" y this.props.idUser

				if (isDefined(this.props.idClase) && isDefined(this.props.idUser)) {
					//console.log('entro')

					let objectClase = this.state.cursos.find(c => c.idClase == this.props.idClase)

					let factura2 = []
					let semanasFacturadas = []
					let temasFacturados = []

					let data123 = { idUser: this.props.idUser, idClase: this.props.idClase }
					let response123 = await fetch("https://oposiciones-justicia.es/api/usuarios/temasActivos", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data123)
					});
					if (!response123.ok) {
						throw Error(response123.statusText);
					}
					let json123 = await response123.json();
					let cls123 = await json123;
					this.setState({ userAccesoTemas: cls123[0].activoTemas });

					let data1 = { idClase: this.props.idClase, idUser: this.props.idUser }
					let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacionClasePrg", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
					});
					if (!response1.ok) {
						throw Error(response1.statusText);
					}
					let json1 = await response1.json();
					let cls1 = await json1;
					for (var i = 0; i < cls1.length; i++) {

						factura2.push({
							idFactura: cls1[i].idFactura,
							fechaPago: cls1[i].fechaPago,
							fechaCaducidad: cls1[i].fechaCaducidad,
							tipo: cls1[i].tipo,
							estado: cls1[i].estado,
							tipoDeFactura: cls1[i].tipoDeFactura,
							pagoParcial: cls1[i].pagoParcial
						})
					}
					// tipoDeFactura de aqui sacamos si es normal(1) o de repaso(2)
					// -----------------------------------------------------------------------
					// console.log('factura2')
					// console.log(factura2)
					if (cls123[0].activado == 1) {

						// console.log('factura2')
						// console.log(factura2)
						// Todas las facturas
						if (factura2[factura2.length - 1].tipo == 10 || factura2[factura2.length - 1].tipo == 20) {
							// MODO CURSO
							if (factura2[factura2.length - 1].pagoParcial == 1) {

								// console.log('pago parcial entrando check')

								let filtroFacturasPagoParcial = factura2.filter(a => a.pagoParcial == 1)
								let totalFacturasParcial = filtroFacturasPagoParcial.length
								let filtroFacturaParcialPagadas = filtroFacturasPagoParcial.filter(a => a.estado == 'Pagado')
								let totalPagadosParcial = filtroFacturaParcialPagadas.length

								// console.log('filtroFacturasPagoParcial')
								// console.log(filtroFacturasPagoParcial)
								// console.log('filtroFacturaParcialPagadas')
								// console.log(filtroFacturaParcialPagadas)

								// console.log('totalPagadosParcial')
								// console.log(totalPagadosParcial)

								if (totalPagadosParcial == 0) {
									// Pendiente de pago
									this.props.loadCurso(null, null, null, null)
									this.props.loadClase(null, true, false)
									this.props.loadTemasFacturados(null)
									this.props.loadAllSemFac(null)

									swal({
										title: 'Factura pendiente de pago',
										text: 'Para continuar con el curso, realice el pago de la primera factura del pago parcial, la ID de la factura es: ' + filtroFacturasPagoParcial[0].idFactura + '.',
										icon: "info",
										buttons: {
											cancel: "Cerrar",
											catch: {
												text: "Ir a facturas",
												value: "catch",
											},
											defeat: false,
										},
										closeOnEsc: false,
										closeOnClickOutside: false,
									}).then((value) => {
										switch (value) {

											case "catch":
												swal({
													title: 'Redirigiendo a Facturas',
													text: 'Procese el pago para continuar',
													icon: "success",
													buttons: false,
													closeOnEsc: false,
													closeOnClickOutside: false,
													timer: 2500,
												}).then(() => {
													window.location.href = 'https://oposiciones-justicia.es/Facturacion'
												})
												break;

											default:
												window.location.reload()
										}
									});
								} else {
									// comprobar que el pagado esta en fecha


									if (moment().format('YYYY-MM-DD HH:mm') <= moment(filtroFacturaParcialPagadas[filtroFacturaParcialPagadas.length - 1].fechaCaducidad).format('YYYY-MM-DD HH:mm')) {

										// Pagada y activa en fecha la ultima pagada cargar contenido de todas las facturas parciales
										let data2oi = { idOpo: this.props.idOposicion }

										let response2oi = await fetch("https://oposiciones-justicia.es/api/usuarios/temasFacturadosCurso", {
											method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2oi)
										});
										if (!response2oi.ok) {
											throw Error(response2oi.statusText);
										}
										let json2oi = await response2oi.json();
										let cls2oi = await json2oi;

										if (cls2oi && cls2oi.length > 0) {

											// console.log('total temas del curso')
											// console.log(cls2oi)
											// TENEMOS que cargar solo los temas que les corresponde

											const totalTemas = cls2oi.length;
											// const totalBloques = 3;
											const totalBloques = totalFacturasParcial

											const temasPorBloque = Math.floor(totalTemas / totalBloques);
											const temasExtras = totalTemas % totalBloques;

											const bloques = Array(totalBloques).fill(temasPorBloque);

											// Distribuir temas extras en los bloques
											for (let i = 0; i < temasExtras; i++) {
												bloques[i]++;
											}
											const sumaTotalTema = bloques.slice(0, totalPagadosParcial).reduce((total, valor) => total + valor, 0);

											let temasFiltradosPorPagoParcial = cls2oi.sort((a, b) => a.numeroTema - b.numeroTema).slice(0, sumaTotalTema)
											// todos temas
											for (let index = 0; index < temasFiltradosPorPagoParcial.length; index++) {
												if (temasFacturados.findIndex(t => t.idTema == temasFiltradosPorPagoParcial[index].idTema) == -1) {
													temasFacturados.push(temasFiltradosPorPagoParcial[index])
												}
											}

											// console.log('sumaTotalTema')
											// console.log(sumaTotalTema)

											// console.log('temasFiltradosPorPagoParcial')
											// console.log(temasFiltradosPorPagoParcial)
											// console.log('temasFacturados')
											// console.log(temasFacturados)
										}
										// cargamos todos los temas

										this.props.loadTemasFacturados(temasFacturados.sort((a, b) => a.numeroTema - b.numeroTema))
										this.props.loadClase(null, false, true)
										this.props.loadAllSemFac(null)
										swal.close()

									} else {
										// factura ultima pagada pero caducada

										// ver si tiene otra más

										if (filtroFacturaParcialPagadas.length == filtroFacturasPagoParcial.length) {
											let idClaseSave = this.props.idClase
											this.props.loadCurso(null, null, null, null)
											this.props.loadClase(null, true, false)
											this.props.loadTemasFacturados(null)
											this.props.loadAllSemFac(null)


											swal({
												title: 'Periodo de curso expirado',
												text: 'Puede seguir preparandose con la compra de otro curso',
												icon: "info",
												buttons: {
													cancel: "Cerrar",
													catch: {
														text: "Ver cursos disponibles",
														value: "catch",
													},
													defeat: false,
												},
												closeOnEsc: false,
												closeOnClickOutside: false,
											}).then((value) => {
												switch (value) {

													case "catch":
														swal({
															title: 'Redirigiendo a cursos',
															text: '-',
															icon: "success",
															buttons: false,
															closeOnEsc: false,
															closeOnClickOutside: false,
															timer: 2500,
														}).then(() => {
															// https://oposiciones-justicia.es/Preparacion/Repaso/Cursos_Online/Aux-Tra-libre/21
															// let tipoOpo = ''

															// if (idClaseSave == 21) {
															// 	tipoOpo = 'Aux-Tra-libre'
															// } else if (idClaseSave == 22) {
															// 	tipoOpo = 'Tramitacion-interna'
															// } else if (idClaseSave == 23) {
															// 	tipoOpo = 'Gestion-libre'
															// } else if (idClaseSave == 24) {
															// 	tipoOpo = 'Gestion-interna'
															// }

															let url = 'https://oposiciones-justicia.es/Preparacion/Cursos_Online/'
															window.location.href = url
														})
														break;

													default:
														window.location.reload()
												}
											});

										} else {
											if (filtroFacturasPagoParcial.length > filtroFacturaParcialPagadas.length) {
												// comprobar la pendiente
												let ultimasFacSinPagar = filtroFacturasPagoParcial.filter(a => a.estado == 'Pendiente')

												let ultimaSinPagar = ultimasFacSinPagar[0]

												this.props.loadCurso(null, null, null, null)
												this.props.loadClase(null, true, false)
												this.props.loadTemasFacturados(null)
												this.props.loadAllSemFac(null)

												swal({
													title: 'Factura pendiente de pago',
													text: 'Para continuar con el curso realice el pago de la factura con ID: ' + ultimaSinPagar.idFactura + '.',
													icon: "info",
													buttons: {
														cancel: "Cerrar",
														catch: {
															text: "Ir a facturas",
															value: "catch",
														},
														defeat: false,
													},
													closeOnEsc: false,
													closeOnClickOutside: false,
												}).then((value) => {
													switch (value) {

														case "catch":
															swal({
																title: 'Redirigiendo a Facturas',
																text: 'Procese el pago para continuar',
																icon: "success",
																buttons: false,
																closeOnEsc: false,
																closeOnClickOutside: false,
																timer: 2500,
															}).then(() => {
																window.location.href = 'https://oposiciones-justicia.es/Facturacion'
															})
															break;

														default:
															window.location.reload()
													}
												});



											}
										}
									}



								}



							} else {


								// Pagado pero caducada
								if (
									factura2[factura2.length - 1].estado == 'Pagado' &&
									moment().format('YYYY-MM-DD HH:mm') > moment(factura2[factura2.length - 1].fechaCaducidad).format('YYYY-MM-DD HH:mm')
								) {
									let idClaseSave = this.props.idClase
									this.props.loadCurso(null, null, null, null)
									this.props.loadClase(null, true, false)
									this.props.loadTemasFacturados(null)
									this.props.loadAllSemFac(null)


									// swal({
									// 	title: 'Periodo de curso expirado',
									// 	text: 'Puede seguir preparandose con una suscripción de repaso.',
									// 	icon: "info",
									// 	buttons: {
									// 		cancel: "Cerrar",
									// 		catch: {
									// 			text: "Ver suscripciones repaso",
									// 			value: "catch",
									// 		},
									// 		defeat: false,
									// 	},
									// 	closeOnEsc: false,
									// 	closeOnClickOutside: false,
									// }).then((value) => {
									// 	switch (value) {

									// 		case "catch":
									// 			swal({
									// 				title: 'Redirigiendo a suscripiones',
									// 				text: 'Dispone de sucripciones repaso de 1, 3, 6 y 12 meses.',
									// 				icon: "success",
									// 				buttons: false,
									// 				closeOnEsc: false,
									// 				closeOnClickOutside: false,
									// 				timer: 2500,
									// 			}).then(() => {
									// 				// https://oposiciones-justicia.es/Preparacion/Repaso/Cursos_Online/Aux-Tra-libre/21
									// 				let tipoOpo = ''

									// 				if (idClaseSave == 21) {
									// 					tipoOpo = 'Aux-Tra-libre'
									// 				} else if (idClaseSave == 22) {
									// 					tipoOpo = 'Tramitacion-interna'
									// 				} else if (idClaseSave == 23) {
									// 					tipoOpo = 'Gestion-libre'
									// 				} else if (idClaseSave == 24) {
									// 					tipoOpo = 'Gestion-interna'
									// 				}

									// 				let url = 'https://oposiciones-justicia.es/Preparacion/Repaso/Cursos_Online/' + tipoOpo + '/' + idClaseSave + ''
									// 				window.location.href = url
									// 			})
									// 			break;

									// 		default:
									// 			window.location.reload()
									// 	}
									// });

									swal({
										title: 'Periodo de curso expirado',
										text: 'Puede seguir preparandose con la compra de otro curso',
										icon: "info",
										buttons: {
											cancel: "Cerrar",
											catch: {
												text: "Ver cursos disponibles",
												value: "catch",
											},
											defeat: false,
										},
										closeOnEsc: false,
										closeOnClickOutside: false,
									}).then((value) => {
										switch (value) {

											case "catch":
												swal({
													title: 'Redirigiendo a cursos',
													text: '-',
													icon: "success",
													buttons: false,
													closeOnEsc: false,
													closeOnClickOutside: false,
													timer: 2500,
												}).then(() => {
													// https://oposiciones-justicia.es/Preparacion/Repaso/Cursos_Online/Aux-Tra-libre/21
													// let tipoOpo = ''

													// if (idClaseSave == 21) {
													// 	tipoOpo = 'Aux-Tra-libre'
													// } else if (idClaseSave == 22) {
													// 	tipoOpo = 'Tramitacion-interna'
													// } else if (idClaseSave == 23) {
													// 	tipoOpo = 'Gestion-libre'
													// } else if (idClaseSave == 24) {
													// 	tipoOpo = 'Gestion-interna'
													// }

													let url = 'https://oposiciones-justicia.es/Preparacion/Cursos_Online/'
													window.location.href = url
												})
												break;

											default:
												window.location.reload()
										}
									});


								} else if (
									//pendiente
									factura2[factura2.length - 1].estado == 'Pendiente'
								) {
									this.props.loadCurso(null, null, null, null)
									this.props.loadClase(null, true, false)
									this.props.loadTemasFacturados(null)
									this.props.loadAllSemFac(null)

									swal({
										title: 'Factura pendiente de pago',
										text: 'Para continuar realice el pago en el apartado facturas',
										icon: "info",
										buttons: {
											cancel: "Cerrar",
											catch: {
												text: "Ir a facturas",
												value: "catch",
											},
											defeat: false,
										},
										closeOnEsc: false,
										closeOnClickOutside: false,
									}).then((value) => {
										switch (value) {

											case "catch":
												swal({
													title: 'Redirigiendo a Facturas',
													text: 'Procese el pago para continuar',
													icon: "success",
													buttons: false,
													closeOnEsc: false,
													closeOnClickOutside: false,
													timer: 2500,
												}).then(() => {
													window.location.href = 'https://oposiciones-justicia.es/Facturacion'
												})
												break;

											default:
												window.location.reload()
										}
									});

								} else if (
									// pagado y activa
									factura2[factura2.length - 1].estado == 'Pagado' &&
									moment().format('YYYY-MM-DD HH:mm') <= moment(factura2[factura2.length - 1].fechaCaducidad).format('YYYY-MM-DD HH:mm')
								) {
									let data2oi = { idOpo: this.props.idOposicion }

									let response2oi = await fetch("https://oposiciones-justicia.es/api/usuarios/temasFacturadosCurso", {
										method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2oi)
									});
									if (!response2oi.ok) {
										throw Error(response2oi.statusText);
									}
									let json2oi = await response2oi.json();
									let cls2oi = await json2oi;

									if (cls2oi && cls2oi.length > 0) {

										// todos temas
										for (let index = 0; index < cls2oi.length; index++) {
											if (temasFacturados.findIndex(t => t.idTema == cls2oi[index].idTema) == -1) {
												temasFacturados.push(cls2oi[index])
											}
										}



									}
									// cargamos todos los temas
									this.props.loadTemasFacturados(temasFacturados.sort((a, b) => a.numeroTema - b.numeroTema))
									this.props.loadClase(null, false, true)
									this.props.loadAllSemFac(null)
									swal.close()

									// cargamos todos los temas

									// añadir temaPrgFacturados y temaPrgLoad == null
								}
							}


						} else {
							// modo clase o curso presencial


							// comprobar el estado de factura, si esta pagado lo ve sino avisar y vaciar clases.

							let semanasFacturadasCompleto = []
							for (var ui = 0; ui < factura2.length; ui++) {
								let fechaCxz = moment(factura2[ui].fechaCaducidad).format('YYYY-MM-DD HH:mm')
								let data2xz = { idClase: objectClase.idClase, idUser: this.props.idUser, fechaPago: moment(factura2[ui].fechaPago).subtract(7, 'days').format('YYYY-MM-DD HH:mm'), fechaCaducidad: fechaCxz }

								// Poner fecha caducidad --> mes siguientes día 7
								// clasificar semanas comprobar que no existe
								let urlSemClasefAC = "https://oposiciones-justicia.es/api/usuarios/semanasDeClaseFacturadas"


								let response2xz = await fetch(urlSemClasefAC, {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2xz)
								});
								if (!response2xz.ok) {
									throw Error(response2xz.statusText);
								}
								let json2xz = await response2xz.json();
								let cls2xz = await json2xz;

								for (var fxs = 0; fxs < cls2xz.length; fxs++) {
									let fechaMostra = moment(cls2xz[fxs].fechaDesbloqueo).format('YYYY-MM-DD HH:mm')
									let ahora = moment().format('YYYY-MM-DD HH:mm')
									if (ahora >= fechaMostra) {

										//semanasFacturadasCompleto.push(cls2xz[fxs])
										// comprobar que no existe en cls2xz la semana

										let idPrgComprobacion = cls2xz[fxs].idPrg;
										let existeIdPrg = semanasFacturadasCompleto.findIndex(s => s.idPrg == idPrgComprobacion && moment(s.fechaClase).format('YYYY-MM-DD HH:mm') == moment(cls2xz[fxs].fechaClase).format('YYYY-MM-DD HH:mm'));
										if (existeIdPrg == -1) {
											semanasFacturadasCompleto.push(cls2xz[fxs])
										}


									}


								}
							}

							let data2oi = {}

							let response2oi = await fetch("https://oposiciones-justicia.es/api/usuarios/temasFacturadosAlumnoN", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2oi)
							});
							if (!response2oi.ok) {
								throw Error(response2oi.statusText);
							}
							let json2oi = await response2oi.json();
							let cls2oi = await json2oi;

							let data3 = {}
							let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRGN", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
							});
							if (!response3.ok) {
								throw Error(response3.statusText);
							}
							let json3 = await response3.json();
							let cls3 = await json3;

							for (let frt = 0; frt < semanasFacturadasCompleto.length; frt++) {

								semanasFacturadasCompleto[frt].temas = []

								if (cls2oi && cls2oi.length > 0) {
									let cls2oiBus = cls2oi.filter(p => p.idPrg == semanasFacturadasCompleto[frt].idPrg)

									if (cls2oiBus.length > 0) {
										for (let index = 0; index < cls2oiBus.length; index++) {
											if (temasFacturados.findIndex(t => t.idTema == cls2oiBus[index].idTema) == -1) {
												temasFacturados.push(cls2oiBus[index])
											}
										}
									}

								}

								if (cls3 && cls3.length > 0) {
									let cls3bUS = cls3.filter(h => h.idPrg == semanasFacturadasCompleto[frt].idPrg)

									if (cls3bUS.length > 0) {
										for (var zf = 0; zf < cls3bUS.length; zf++) {

											//multiple
											let locaBloque2 = semanasFacturadasCompleto.map((elm, idx) => elm.idPrg == cls3bUS[zf].idPrg && elm.numClase == semanasFacturadasCompleto[frt].numClase ? idx : '').filter(String)

											if (locaBloque2.length > 0) {
												for (let rtx2 = 0; rtx2 < locaBloque2.length; rtx2++) {

													let val2 = locaBloque2[rtx2]

													if (semanasFacturadasCompleto[val2].temas != undefined) {
														if (semanasFacturadasCompleto[val2].temas.findIndex(t => t.idTema == cls3bUS[zf].idTema) == -1) {

															await semanasFacturadasCompleto[val2].temas.push({
																idTema: cls3bUS[zf].idTema,
																tituloTema: cls3bUS[zf].tituloTema
															})
														}
													} else {
														semanasFacturadasCompleto[val2].temas = []

														await semanasFacturadasCompleto[val2].temas.push({
															idTema: cls3bUS[zf].idTema,
															tituloTema: cls3bUS[zf].tituloTema
														})

													}

												}
											}

										}
									}
								}





							}


							// meter redux todas semanas

							await this.props.loadAllSemFac(semanasFacturadasCompleto)
							await this.props.loadTemasFacturados(temasFacturados.sort((a, b) => a.numeroTema - b.numeroTema))
							let semanasFacturadas2Ultimas = []
							if (semanasFacturadasCompleto.length > 1) {
								semanasFacturadas2Ultimas.push(
									semanasFacturadasCompleto[semanasFacturadasCompleto.length - 2]
								)
								semanasFacturadas2Ultimas.push(
									semanasFacturadasCompleto[semanasFacturadasCompleto.length - 1]
								)
							} else {
								if (semanasFacturadasCompleto.length == 1) {
									semanasFacturadas2Ultimas.push(
										semanasFacturadasCompleto[semanasFacturadasCompleto.length - 1]
									)
								}
							}
							// console.log('semanasFacturadas2Ultimas')
							// console.log(semanasFacturadas2Ultimas)
							await this.props.loadClase(semanasFacturadas2Ultimas, false, true)

							setTimeout(
								function () {
									this.userPersistFac()
								}.bind(this), 1800000);


						}


					} else {
						swal({
							title: 'Acceso a clase bloqueado',
							text: 'Para renaudar el acceso pongase en contacto con la academia',
							icon: "warning",
							buttons: false,
							closeOnEsc: false,
							closeOnClickOutside: false,
							timer: 2000,
						}).then(() => {
							this.props.loadCurso(null, null, null, null)
							this.props.loadClase(null, true, false)
							this.props.loadTemasFacturados(null)
							this.props.loadAllSemFac(null)
						})


						// añadir poner todo null de cursos


					}
				} else {

					this.props.loadCurso(null, null, null, null)
					this.props.loadClase(null, true, false)
					this.props.loadTemasFacturados(null)
					this.props.loadAllSemFac(null)

					// añadir poner todo null de cursos


				}
			} catch (e) {
				if (e.name != 'TypeError') {
					console.log(e)
				}

			}
		}
	}

	async componentDidMount() {
		/*let browser = detect()
		if(browser.name != 'chrome' && browser.name != 'crios' ){
			swal({
				title: 'Navegador no compatible',
				text: 'Debe usar el navegador Google Chrome, sentimos las molestias. Estas usando: '+browser.name+'',
				icon: "error",
				buttons: false,
				closeOnEsc: false,
				closeOnClickOutside: false,
			})
		}*/

		
		if (this.props.isLogged == true) {

			let data1 = { idUser: this.props.idUser }
			let response1 = await fetch('https://oposiciones-justicia.es/api/usuarios/normasAceptadas', {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
			});
			if (!response1.ok) {
				throw Error(response1.statusText);
			}
			let json1 = await response1.json();
			let cls1 = await json1;

			this.setState({ normasAceptadas: cls1[0].normasAceptadas })

			if (this.props.testJusticiaUserLoad == [] || this.props.testJusticiaUserLoad == null || this.props.testJusticiaUserLoad == undefined || this.props.testJusticiaUserLoad.length == 0) {
				let dat = {
					idUser: this.props.idUser
				}
				let response0 = await fetch('https://oposiciones-justicia.es/api/usuarios/autoGuardarTest', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(dat)
				});
				if (!response0.ok) {
					throw Error(response0.statusText);
				}
				let json0 = await response0.json();
				let test = await json0;

				if (test.length > 0) {
					// console.log(test[0])

					let autoTestNube = test[0];

					let min = autoTestNube.minutos;
					let seg = autoTestNube.segundos;

					if(autoTestNube.jsonTest != '') {
						let testJson = autoTestNube.jsonTest.replace(/\r?\n|\r/g, " ");

						let testConver = JSON.parse(testJson);
	
	
						this.props.cronoFunc(min, seg);
	
						this.props.loadTestUserNUBE(testConver);
					}
					

				}
			}


		}


		if (this.state.ctrlUserPersist == 0 && this.props.isLogged == true) {
			let data2 = { idUser: this.props.idUser }
			fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data2)
			}).then(response => {
				if (response.status >= 400) { throw new Error("Bad response from server"); }
				return response.json();
			}).then(data => {
				if (data.length > 0) {
					this.setState({ cursos: data })
				}

			}).catch(function (err) { });

			this.setState({ ctrlUserPersist: 1 });
			setTimeout(() => {
				this.userPersist()
			}, 2500);

		}



	}
	render() {
		const { body, tipoUser, isLogged, viewBusArt } = this.props;
		const { normasAceptadas, infoTipoCurso } = this.state;
		const pathname = window.location.pathname;
		if (isLogged == true) {
			if (tipoUser && tipoUser != undefined && tipoUser < 90) {

				return (
					// ref={this.addOnSelectStart}
					<div className="Body noselect">
						<div className="Sidebar"> <Header title="CEJ APP" /></div>
						<div className="contentBodyChildren">{body}</div>
						<Footer />
						{viewBusArt != null ? (
							<BuscarDeArt />
						) : null}

						{normasAceptadas != null && normasAceptadas == 0 ? (
							<div className='flyingad34'>

								<div class="scrollbarTienda" id="style-2">

									<div className='tNCOA-nav'>
										<div>
											<BotonFinolis texto={infoTipoCurso != 'presencial' ? ('Ver Normas Prensecial/Online') : ('Ver Normas Cursos')} id="nP" onClick={this.onHandleClick} />
										</div>

									</div>

									<b className='tNCAtitles21'>Normas Academia</b>

									<div className='tNCOA-nav'>
										<div>
											<BotonFinolis texto={'Aceptar'} id="aceptar" onClick={this.onHandleClick} />
										</div>
										<div>
											<BotonFinolis texto={'Rechazar'} id="rechazar" onClick={this.onHandleClick} />
										</div>
									</div>


									{
										infoTipoCurso && infoTipoCurso != 'Cursos_Online' ? (
											<div className='tNCA-infoTexto scrollbar' id="style-2" style={{ width: 'auto' }}>
												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Normas presencial / online por videoconferencia.
													</p>
												</div>
												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Los pagos son por mensualidades completas y se realizan del 1 al 5 del mes. Mediante Tarjeta, Google Pay, Transferencia SEPA através del campus virtual ó Bizum y pago en Metálico en la Academia. La cuota abonada no se devolverá si el alumno abandona antes de finalizar el mes.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														En caso de desear dejar de asistir a clases el mes siguiente, deberá comunicarlo con 5 días de antelación del
														inicio del mes. De no hacerlo así, deberá abonar la mensualidad entera correspondiente.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														La última semana del año y Semana Santa las clases son Online, la preparación se realiza con los materiales y
														videos que tendrá disponibles en el Aula virtual. El mes de agosto las clases son Online por video conferencia
														las dos primeras semanas.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Los festivos de ámbito Nacional o Local se consideran días no lectivos, la preparación se realiza con los
														materiales y videos que tendrá disponibles en el Aula virtual.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
														dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
													</p>
												</div>

											</div>
										) : (
											<div className='tNCA-infoTexto scrollbar' id="style-2" style={{ width: 'auto' }}>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Normas Cursos Online
													</p>
												</div>
												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Las características principales de los bienes o servicios, en la medida adecuada al soporte utilizado y a los bienes o servicios de que se trate: Curso Online para preparación de oposiciones de justicia, mediante uso de plataforma virtual que contiene vídeos, resúmenes, test, casos prácticos, y en su caso planificación del estudio.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														La identidad del empresario, incluido su nombre comercial: Bárbara Alaminos Castillo, con nombre comercial Centro de Estudios Judiciales Málaga.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														La dirección completa del establecimiento del empresario, número de teléfono y dirección de correo electrónico: Calle Pirandello 16, 3ª planta, Oficina 6, Málaga, teléfono 688 98 80 99 y email centrodeestudiosjudiciales@gmail.com
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Una vez realizado el pago,tendrá acceso total a todo el contenido del curso. La cantidad abonada no se devolverá, aunque el alumno abandone antes de finalizar el curso.
													</p>
												</div>
												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														DESISTIMIENTO DEL PRESENTE CONTRATO DE PRODUCTOS O SERVICIOS EN SOPORTE DIGITAL.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />

													<p>
														Se comunica a los efectos oportunos que la posibilidad de desistimiento prevista en la Ley General para la Defensa de Consumidores y Usuarios no es aplicable al curso online que está contratando, por resultar aplicable el artículo 103.a de la citada Ley, una vez realice el primer acceso a la plataforma digital con sus claves personales, ya que implica el cumplimiento, ejecución, total del contrato por parte de CEJ.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Podrá realizar el pago através del campus virtual. Metodos de pago: Page en 3 plazos sin intereses o un unico pago con Tarjeta, Google Pay ó Transferencia SEPA.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														Si desea realizar el pago presencialmente acudiendo a nuestra academia en Málaga Teatinos junto al palacio de justicia, tendrá las opciones de Bizum y pago en Metálico.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														La preparación se realiza con los materiales y videos que tendrá disponibles en el Campus Virtual www.oposiciones-justicia-es.
													</p>
												</div>

												<div className='celdaInfosv2df54'>
													<i className='fa-light fa-caret-right' />
													<p>
														El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
														dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
													</p>
												</div>

											</div>
										)}

								</div>


							</div>

						) : null}
					</div>
				);

			} else {

				return (
					<div className="Body">
						<div className="Sidebar"> <Header title="CEJ APP" /></div>
						<div className="contentBodyChildren">{body}</div>
						<Footer />
						{viewBusArt != null ? (
							<BuscarDeArt />
						) : null}


					</div>
				);

			}
		} else {
			return (
				<div className="Body noselect">
					<div className="contentBodyChildren">{body}</div>

				</div>
			);
		}


	}
}

// Estados del store
function mapStateToProps(state) {
	return {
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser: state.user.tipoUser,
		idClase: state.user.idClase,
		idOposicion: state.user.idOposicion,
		idUser: state.user.idUser,
		userPersist: state.user.userPersist,
		version: state.user.version,
		temasFacturados: state.user.temasFacturados,
		viewBusArt: state.user.viewBusArt,
		testJusticiaUserLoad: state.user.testJusticiaUserLoad
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		cerrarSesion,
		loadCurso,
		loadClase,
		loadTemasFacturados,
		loadClaseExt,
		loadAllSemFac,
		cronoFunc,
		loadTestUserNUBE
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Body);

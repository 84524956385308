// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
// Assets
import './css/VPT.css';
import Submit from '../../../Inputs/Submit';
import PreguntaJusticia from '../PreguntaJusticia';

class PreguntaInfoDel extends Component {

  static propTypes = {
    preguntaBuscar: PropTypes.number.isRequired,
    test: PropTypes.array.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          toogleInfoPregunta: 'none'
        };
        this.onToggleClick = this.onToggleClick.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){
      if(e.target.id == 'toogleInfoPregunta'){
        let { id } = this.props;
        $('#infoPregunta-'+id).slideToggle(1000);
      }
    }

    handleOnClick(e){

    }

  componentDidMount(){


  }

  render() {
    const { preguntaBuscar, test } = this.props;

              return (
                <div className="infoPreguntaBodyVPT">
                  { test && preguntaBuscar <= 0 && test.map((preg, key) =>
                    <PreguntaJusticia id={key} pregunta={test[key]} />
      						)}

                  { test && preguntaBuscar > 0 && test ? (
                    <PreguntaJusticia id={0} pregunta={test.find(p=> p.numPreg == preguntaBuscar)} />
                  ) : null
      						}
                </div>

              );

        }
 }

export default PreguntaInfoDel;

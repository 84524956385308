// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'
import moment from 'moment'
// Actions
import BotonFinolis from '../../../../../components/Inputs/Boton3Finolis'
// Assets
import './css/css.css';



class Tarjeta3mode1 extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    this.state = {
      viewVideo: false,
      loca: null
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    if (e.target.id == 'videoInfo') {

      this.setState({ viewVideo: !this.state.viewVideo })

    } else if (e.target.id.split('-')[0] == 'check') {


      let locaNew = e.target.id.split('-')[1]
      if (this.state.loca == locaNew) {
        this.setState({ loca: null })
      } else {
        this.setState({ loca: locaNew })
      }



    } else if (e.target.id == 'contratar') {
     
      let { info } = this.props
      let tipoCurso = this.state.loca
      if (tipoCurso != null) {
        let cursoRepaso = info.cursos[tipoCurso]
        let dur = info.cursos[tipoCurso].titulo == 'Mensual' ? (1):(info.cursos[tipoCurso].titulo == 'Trimestral' ? (3):(info.cursos[tipoCurso].titulo == 'Semestral' ? (6):(info.cursos[tipoCurso].titulo == 'Anual' ? (12):(1))))
        let factura = {
          idClase: info.idClase,
          idAlumno: info.idUser,
          idCobrador: 1,
          totalPagado: cursoRepaso.precio,
          metodoPago: 'card,sepa_debit',
          estado: 'Pendiente',
          datosActivados: 0,
          sucripcion: 'Cuota Repaso '+ cursoRepaso.titulo,
          concepto: 'Factura repaso '+ cursoRepaso.titulo +' emitada el: ' + moment().format("YYYY-MM-DD HH:mm:ss") + '.',
          fechaFactura: moment().format("YYYY-MM-DD 00:00:01"),
          fechaCaducidad: moment().add(dur,'M').format("YYYY-MM-DD 23:59:59"),
          tipo: 20
        }

        let response1 = await fetch('https://oposiciones-justicia.es/api/oficina/addFactura', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(factura)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        if(cls1.affectedRows > 0){
          await swal({
            title: 'Suscripción creada',
            text: 'Redirigiendo a facturacion para realizar el pago',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            timer: 3000,
          }).then(() => {
            window.location.href = 'https://oposiciones-justicia.es/Facturacion'
          })
        }
        
      }

    

    } else if (e.target.id == 'infoSemanaGratis') {

      let name = "1ª Semana Gratis";
      let content = document.createElement('div');
      content.innerHTML = `
      Accede a la 1ª semana del contenido sin ningún coste. 
      </br></br>
      Pruebe nuestro metodo de preparación, campus virtual y conozca a su preparador.
      </br></br>
      No le pediremos ningún metodo de pago hasta finalizar el periodo de prueba. 
      </br></br>
      Sí quiere desbloquear todo el contenido durante el periodo de prueba, podrá realizar el pago desde su aula virtual.
      `
      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    } else if (e.target.id == 'infoReserva') {

      let name = "Reserva de plaza";
      let content = document.createElement('div');
      content.innerHTML = `
      Haga clic sobre RESERVAR PLAZA, para completar el proceso de reserva <b>sin ningun coste</b>.
      </br></br>
      La academia contactará con usted para confirmar su alta en 1-2 días.
      </br></br>
     
      `
      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    }
  }
  onChangeInput(e) {


  }
  async componentDidMount() {

  }


  // ofertaMensualidad: ""
  // precioMensualidad: ""
  // tituloLeft: "Curso Online"
  // tituloRight1: ""
  // tituloRight2: ""
  // url2TipoPreparacion: "Cursos_Online"
  // url3Oposicion: "Aux-Tra-libre"
  // videoUrl: ""
  render() {
    let { info } = this.props
    let { viewVideo, loca } = this.state
    return (

      <div className={info && info.cursos.length > 0 ? ('CC3-tarjeta') : ('CC3-tarjetaN')}>
        <div className='CC3-T-header'>
          <div className='CC3-T-h-left'>
            {info && ReactHtmlParser(info.tituloLeft)}

          </div>
          <div className='CC3-T-h-right'>


            {info && info.cursos.length > 0 ? (
              <BotonFinolis texto={'Comprar suscripción'} id="contratar" onClick={this.onHandleClick} disabled={loca && loca != null ? (false) : (true)} infoDisabled="Seleccione el tipo de suscripción repaso para continuar" />
            ) : (
              <p>Ultimas plazas disponibles</p>
            )
            }

          </div>
        </div>

        <div className='CC3-T-body'>


          <div className={info && info.cursos.length > 0 ? ('CC3-T-b-left') : ('CC3-T-b-left2')}>
            <div className='CC3-T-b-l-content' >
              {ReactHtmlParser(info && info.contentInfo1)}
              <div className='barrotesGrises'></div>
              <div className='CC3-T-b-l-content-text' style={{ transform: ' rotateY(0deg)', minHeight: '255px' }}>

                <div className={viewVideo == false ? ('CC3-T-b-l-content-text opacidadVista') : ('CC3-T-b-l-content-text opacidadOculta')}>
                  {
                    info && info.contentInfo2 && info.contentInfo2.map((item, key0) =>
                      <div className='lineaTecxtoCC3tbl' key={key0}>
                        {/* <div style={{ margin: '10px', width: '6px', height: '6px', background: '#3469b7', borderRadius: '50px' }}></div> */}
                        <i className={item.icono} style={{ margin: '10px', color: '#3469b7' }} />
                        {ReactHtmlParser(item.txt)}
                      </div>
                    )
                  }

                </div>
                <div className={viewVideo == false ? ('CC3-T-b-l-content-video opacidadOculta') : ('CC3-T-b-l-content-video opacidadVista')}>
                  <ReactPlayer
                    url={info && info.videoUrl}
                    className='react-player'
                    playing={false}
                    width='100%'
                    height='100%'
                    controls={true}
                    onContextMenu={e => e.preventDefault()}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />
                </div>



              </div>


            </div>
            <div className='CC3-T-b-l-navButtons'>
              <BotonFinolis texto={'SABER MÁS'} id="masInfo" onClick={this.onHandleClick} />
              {/* <BotonFinolis texto={viewVideo == false ? ('VER VIDEO') : ('CERRAR VIDEO')} id="videoInfo" onClick={this.onHandleClick} /> */}
            </div>
          </div>


          <div className={info && info.cursos.length > 0 ? ('CC3-T-b-right') : ('CC3-T-b-right2')}>
            <div className={info && info.cursos.length > 0 ? ('CC3-T-b-r-content') : ('CC3-T-b-r-content contentCfjasd')}>

              {
                info && info.cursos && info.cursos.length > 0 && info.cursos.map((clsd4, key2) =>
                  <div className='CC3-T-b-r-c-Check-curso' key={key2}>

                    <div className='CC3-T-b-r-c-Check-curso-row' id={"check-" + key2} onClick={this.onHandleClick} >
                      <div className={key2 != loca ? ('CC3-T-b-r-c-C2-check') : ('CC3-T-b-r-c-C2-check2 checkTru')} id={"check-" + key2}></div>
                      {/* className='CC3-T-b-r-c-C2-check checkTru' */}
                      <div className='CC3-T-b-r-c-C2-check-text-title1' id={"check-" + key2}>
                        {clsd4.titulo}
                      </div>
                    </div>
                    <div className='CC3-T-b-r-c-Check-curso-row2'>
                      <div className='CC3-T-b-r-c-C2-infoText'>
                        {clsd4.info}
                      </div>
                      <div className='CC3-T-b-r-c-C2-Precio'>
                        {clsd4.precio}
                      </div>
                    </div>


                  </div>
                )
              }

              {info && info.cursos.length > 0 ? null : (
                <div className='CC3-T-b-r-c-C-NavPresenci'>
                  {/* <div>Mensualidad</div> */}
                  <p>Matrícula gratis</p>
                  {/* <div>{info && info.ofertaMensualidad}</div> */}
                </div>
              )}

            </div>
            {info && info.cursos.length > 0 ? (
              <div className='CC3-T-b-r-navButtons'>

              </div>
            ) : (
              <div className='CC3-T-b-r-navButtons2'>
                <i className="fa-light fa-circle-info" id="infoReserva" onClick={this.onHandleClick}></i>
                <BotonFinolis texto={'Reservar Plaza'} id="contratar" onClick={this.onHandleClick} />
              </div>
            )
            }
          </div>


        </div>


      </div>
    );

  }
}
// Conectamos Redux Store



export default connect(null, null)(Tarjeta3mode1);
// Actions
const CAMBIAR_TEST_JUSTICIA_EDT_CP = 'CAMBIAR_TEST_JUSTICIA_EDT_CP';
const ELIMINAR_TEST_JUSTICIA_EDT_CP = 'ELIMINAR_TEST_JUSTICIA_EDT_CP';

const ADD_PREGUNTA_NUEVA_EDT_CP = 'ADD_PREGUNTA_NUEVA_EDT_CP';
const DELETE_PREGUNTA_NUEVA_EDT_CP = 'DELETE_PREGUNTA_NUEVA_EDT_CP'
const DELETE_DESCENDER_PREGUNTA_NUEVA_EDT_CP = 'DELETE_DESCENDER_PREGUNTA_NUEVA_EDT_CP';
export function cambiarDatosTestJusticiaEdt(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_TEST_JUSTICIA_EDT_CP,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarTestJusticiaEdtCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA_EDT_CP
    };
}
export function addPreguntaTestJusticiaEdt(numPreguntaNueva) {
    return {
	    type: ADD_PREGUNTA_NUEVA_EDT_CP,
	   	 numPregNew: numPreguntaNueva
    };
}
export function deletePreguntaTestJusticiaEdt(numPreguntaBorrar) {
    return {
	    type: DELETE_PREGUNTA_NUEVA_EDT_CP,
	   	 numPregDel: numPreguntaBorrar
    };
}
export function changeNumPreguntaTestJusticiaEdt(numPreguntaBorrar) {
    return {
	    type: DELETE_DESCENDER_PREGUNTA_NUEVA_EDT_CP,
	   	 numPregChange: numPreguntaBorrar
    };
}

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Ellipsis } from '../../components/react-css-spinners'

//Assets
import './css/TestJusticiaAlumnosVer.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico, openViewBusArt, addIdLeyBusArt, addArtBusArt } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
import { corregirTest, comprobarRespuestas, respuestaSbr } from '../../lib/utils/cargarTestJusticia';
// Components
import Input from '../../components/Inputs/Input';
import InputSelect from '../../components/Inputs/InputSelect';
import Submit from '../../components/Inputs/Submit';
import BotonMedio from '../../components/Inputs/BotonMedio';
import InputFile from '../../components/Inputs/InputFile';

import Boton from '../../components/Inputs/Boton';

import Dudas from '../../components/Dudas';

import swal from 'sweetalert';
// import { stat } from 'fs';

class VerTestUserCaso extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    verCasoPUser: PropTypes.array
  };
  constructor(props) {
    super(props);
    this.state = {
      numPregTest: 1,
      displayRespondidas: false,
      displayEnDudas: false,
      displayVerNumeros: true,
      pregRespondidasArr: [],
      pregEnBlancoArr: [],
      pregDudosasArr: [],
      displayFinTest: true,
      stop: false,
      statusCrono: true,
      statusCasoPractico: false,
      inicioNumeracion: 0,
      finNumeracion: 10,

      pregAcertadasArr: undefined,
      pregErroresArr: undefined,
      pregEnBlancoArr: undefined,
      pregDudosasArr: undefined,
      displayEnBlanco: false,
      displayAcertadas: false,
      displayErroneas: false,
      dudasView: false,
      reportesDuda: null,
      leyActive: false,
      leyActive2: false,
      plantearDuda: false,
      cargandoDudas: true
    };
    this.loadPreg = this.loadPreg.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.actionTestUser = this.actionTestUser.bind(this);

    this.reloadTest = this.reloadTest.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.onChangeText = this.onChangeText.bind(this);

    this.viewArtC = this.viewArtC.bind(this);

  }
  viewArtC(e) {

    let preg = this.props.verCasoPUser.filter(p => p.numPreg == this.state.numPregTest)
    
    this.props.openViewBusArt(null)
    this.props.addIdLeyBusArt(preg[0].idLey)
    this.props.addArtBusArt(preg[0].articulo)

    setTimeout(() => {
      this.props.openViewBusArt(true)
    }, 250);
    
    
  }




  onChangeText(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      this.setState({ textHtmlDudaAlumno: textHtml })
    }

  }

  async keyDown(e) {

    if (e.keyCode == 37 && this.state.plantearDuda == false) {

      e.preventDefault()
      let { numPregSimultaneas, numPregTest, pregExtra } = this.state
      let { verCasoPUser } = this.props
      let nextPreg = parseInt(numPregTest) - 1
      if (await nextPreg <= 0) {
        nextPreg = 1
      }

      if (nextPreg > 0) {
        this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
        this.setState({ numPregTest: nextPreg })

        if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false) {
          let position = await verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                if (position - verCasoPUser.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = verCasoPUser.length - 10 > 0 ? (verCasoPUser.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {

          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayAcertadas == true) {

          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayErroneas == true) {

          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }


        this.reloadTest()
      } else {
        swal('No hay más preguntas', '', 'info')
      }


    } else if (e.keyCode == 39 && this.state.plantearDuda == false) {
      e.preventDefault()
      let { numPregTest } = this.state
      let { verCasoPUser } = this.props
      let nextPreg = parseInt(numPregTest) + 1
      if (nextPreg > verCasoPUser.length) {
        nextPreg = verCasoPUser.length
      }
      if (nextPreg <= verCasoPUser.length) {


        this.setState({ numPregTest: nextPreg })
        this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })

        if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false) {
          let position = await verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                if (position - verCasoPUser.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = verCasoPUser.length - 10 > 0 ? (verCasoPUser.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {

          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayAcertadas == true) {

          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayErroneas == true) {

          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }

        this.reloadTest()

      } else {
        swal('No hay más preguntas', '', 'info')
      }


    } else if (e.keyCode == 38 || e.keyCode == 40 && this.state.plantearDuda == false) {
      e.preventDefault()
    }
  }

  async reloadTest() {

    if (this.state.pregAcertadasArr == undefined || this.state.pregErroresArr == undefined || this.state.pregEnBlancoArr == undefined) {

      let pregAcertadasArr = []
      let pregErroresArr = []
      let pregEnBlancoArr = []
      let pregDudosasArr = []

      for (var i = 0; i < this.props.verCasoPUser.length; i++) {
        let pregunta = this.props.verCasoPUser[i]
        let pregTest = 0

        if (pregunta && pregunta.respuestas.find(r => r.idR == pregunta.idR1).valorUser != 0) {
          if (pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 1 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 5 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 6 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 9 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 11 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 12 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 15 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 17 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 20 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR1).validez == 24) {
            pregTest = 1
          } else {
            pregTest = 2
          }
        } else if (pregunta && pregunta.respuestas.find(r => r.idR == pregunta.idR2).valorUser != 0) {
          if (pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 1 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 5 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 6 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 9 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 11 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 12 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 15 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 17 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 20 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR2).validez == 24) {
            pregTest = 1
          } else {
            pregTest = 2
          }
        } else if (pregunta && pregunta.respuestas.find(r => r.idR == pregunta.idR3).valorUser != 0) {
          if (pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 1 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 5 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 6 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 9 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 11 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 12 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 15 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 17 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 20 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR3).validez == 24) {
            pregTest = 1
          } else {
            pregTest = 2
          }
        } else if (pregunta && pregunta.respuestas.find(r => r.idR == pregunta.idR4).valorUser != 0) {
          if (pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 1 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 5 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 6 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 9 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 11 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 12 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 15 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 17 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 20 ||
            pregunta.respuestas.find(r => r.idR == pregunta.idR4).validez == 24) {
            pregTest = 1
          } else {
            pregTest = 2
          }
        } else if (
          pregunta && pregunta.respuestas.find(r => r.idR == pregunta.idR1).valorUser == 0
          && pregunta.respuestas.find(r => r.idR == pregunta.idR2).valorUser == 0
          && pregunta.respuestas.find(r => r.idR == pregunta.idR3).valorUser == 0
          && pregunta.respuestas.find(r => r.idR == pregunta.idR4).valorUser == 0
        ) { pregTest = 0 }


        if (pregTest == 1) {
          let art = pregunta.articulo

          if (art.split('.y.').length > 1) {
            let artRevisado = ""
            for (let rty = 0; rty < art.split('.y.').length; rty++) {
              if (rty == 0) {
                artRevisado = art.split('.y.')[rty] + ""
              } else if (rty < art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
              } else if (rty == art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
              }
            }
            art = artRevisado
          }
          pregunta.articulo = await art

          pregAcertadasArr.push(pregunta)
          if (pregunta.dudosa == 1) {
            pregDudosasArr.push(pregunta)
          }
        } else if (pregTest == 2) {
          let art = pregunta.articulo

          if (art.split('.y.').length > 1) {
            let artRevisado = ""
            for (let rty = 0; rty < art.split('.y.').length; rty++) {
              if (rty == 0) {
                artRevisado = art.split('.y.')[rty] + ""
              } else if (rty < art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
              } else if (rty == art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
              }
            }
            art = artRevisado
          }
          pregunta.articulo = await art
          pregErroresArr.push(pregunta)
          if (pregunta.dudosa == 1) {
            pregDudosasArr.push(pregunta)
          }
        } else if (pregTest == 0) {
          let art = pregunta.articulo

          if (art.split('.y.').length > 1) {
            let artRevisado = ""
            for (let rty = 0; rty < art.split('.y.').length; rty++) {
              if (rty == 0) {
                artRevisado = art.split('.y.')[rty] + ""
              } else if (rty < art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(', ', art.split('.y.')[rty])
              } else if (rty == art.split('.y.').length - 1) {
                artRevisado = artRevisado.concat(' y ', art.split('.y.')[rty])
              }
            }
            art = artRevisado
          }
          pregunta.articulo = await art
          pregEnBlancoArr.push(pregunta)
          if (pregunta.dudosa == 1) {
            pregDudosasArr.push(pregunta)
          }
        }
      }


      this.setState({ pregAcertadasArr: pregAcertadasArr, pregErroresArr: pregErroresArr, pregEnBlancoArr: pregEnBlancoArr, pregDudosasArr: pregDudosasArr })

    }

  }
  async actionTestUser(e) {
    if (e.target.id == 'verCasoPractico') {
      this.setState({ statusCasoPractico: true })
    } else if (e.target.id == 'cerrarCasoPractico') {
      this.setState({ statusCasoPractico: false })
    } else if (e.target.id == 'marcarComoDudosa') {

      try {

        let idPregunta = this.props.verCasoPUser.filter(p => p.numPreg == this.state.numPregTest)
        if (idPregunta.length > 0) {



          if (this.state.dudasView == false) {
            this.setState({ plantearDuda: false })
            this.setState({ dudasView: !this.state.dudasView, cargandoDudas: true })
            idPregunta = await idPregunta[0].idPreg
            let data = { idPreg: idPregunta }
            let testNew = []
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportesCPUserExpediente", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined && cls1.length > 0) {
              for (var i = 0; i < await cls1.length; i++) {
                let textAlumno = cls1[i].infoRoportUser

                textAlumno = await textAlumno.replace(/&nbsp;/g, '');
                textAlumno = await textAlumno.replace(/<p>/g, '');
                textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
                let resolucionPreparador = cls1[i].resolucion
                if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                  resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                  resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
                }

                testNew.push({
                  idReport: cls1[i].idReport,
                  idUser: cls1[i].usuarioReportID,
                  infoRoportUser: textAlumno,
                  fotoPerfil: cls1[i].fotoPerfil,
                  resolucion: resolucionPreparador,
                  estadoReport: cls1[i].estadoReport,
                  fecha: cls1[i].fecha,
                  idPreg: cls1[i].idPregunta,
                  pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
                  idLey: cls1[i].idLey,
                  siglasLey: cls1[i].siglasLey,
                  nombreLey: cls1[i].nombreLey,
                  idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
                  respuestas: [],
                  alumnosDuda: [],
                  idCasoPractico: cls1[i].idCasoPractico != null ? (cls1[i].idCasoPractico) : null
                })

                let r1Sbr = cls1[i].R1
                if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
                  r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
                }

                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR1,
                  respuesta: r1Sbr,
                  respuestaBse: cls1[i].R1,
                  validez: cls1[i].validezR1,
                  subrayado: cls1[i].subrayado1,
                  nota: cls1[i].nota1,
                  valorUser: cls1[i].r1ValorUser
                })
                let r2Sbr = cls1[i].R2
                if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
                  r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR2,
                  respuesta: r2Sbr,
                  respuestaBse: cls1[i].R2,
                  validez: cls1[i].validezR2,
                  subrayado: cls1[i].subrayado2,
                  nota: cls1[i].nota2,
                  valorUser: cls1[i].r2ValorUser
                })
                let r3Sbr = cls1[i].R3
                if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
                  r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR3,
                  respuesta: r3Sbr,
                  respuestaBse: cls1[i].R3,
                  validez: cls1[i].validezR3,
                  subrayado: cls1[i].subrayado3,
                  nota: cls1[i].nota3,
                  valorUser: cls1[i].r3ValorUser
                })
                let r4Sbr = cls1[i].R4
                if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
                  r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
                }
                testNew[testNew.length - 1].respuestas.push({
                  idR: cls1[i].idR4,
                  respuesta: r4Sbr,
                  respuestaBse: cls1[i].R4,
                  validez: cls1[i].validezR4,
                  subrayado: cls1[i].subrayado4,
                  nota: cls1[i].nota4,
                  valorUser: cls1[i].r4ValorUser
                })
                //sacar todos los alumnos apuntados en duda
                let data2 = {
                  idDuda: cls1[i].idReport
                }

                let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                testNew[testNew.length - 1].alumnosDuda = await cls2
                testNew[testNew.length - 1].alumnosDuda.push({
                  idUser: cls1[i].usuarioReportID
                })


              }




              if (testNew != undefined) {

                let dudasComprobadas = await comprobarRespuestas(testNew)
                await this.setState({ reportesDuda: dudasComprobadas, cargandoDudas: false })
                //console.log(dudasComprobadas)
              }
            } else {
              this.setState({ cargandoDudas: false })
            }

          } else {
            this.setState({ dudasView: !this.state.dudasView, reportesDuda: null, plantearDuda: false, cargandoDudas: true })
          }
        }
      } catch (e) { console.log(e) }


    } else if (e.target.id == 'cerrarFiltroPreg') {
      this.setState({ displayVerNumeros: true, displayErroneas: false, displayAcertadas: false, displayEnBlanco: false, displayEnDudas: false })


      let position = await this.props.verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)

      let ini = (position - 5)
      let fin = (position - 5) + 10
      if (ini > 0) {

        if (fin <= this.props.verCasoPUser.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.props.verCasoPUser.length
          if (position - this.props.verCasoPUser.length > 5) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            ini = this.props.verCasoPUser.length - 10 > 0 ? (this.props.verCasoPUser.length - 10) : (0)
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        ini = 0
        fin = 10
        if (fin <= this.props.verCasoPUser.length) {
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        } else {
          fin = this.props.verCasoPUser.length
          this.setState({ inicioNumeracion: ini, finNumeracion: fin })
        }
      }


    } else if (e.target.id == 'siguiente') {

      if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false && this.state.displayEnDudas == false) {


        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.props.verCasoPUser.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.verCasoPUser.length
            if ((ini + 5) - this.props.verCasoPUser.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.props.verCasoPUser.length - 10 > 0 ? (this.props.verCasoPUser.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.props.verCasoPUser.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.verCasoPUser.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnBlanco == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if ((ini + 5) - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayAcertadas == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            if ((ini + 5) - this.state.pregAcertadasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregAcertadasArr.length - 10 > 0 ? (this.state.pregAcertadasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayErroneas == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            if ((ini + 5) - this.state.pregErroresArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnDudas == true) {

        let ini = this.state.inicioNumeracion + 5
        let fin = this.state.finNumeracion + 5
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if ((ini + 5) - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      }

    } else if (e.target.id == 'anterior') {

      if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false && this.state.displayEnDudas == false) {


        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.props.verCasoPUser.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.verCasoPUser.length
            if ((ini + 5) - this.props.verCasoPUser.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.props.verCasoPUser.length - 10 > 0 ? (this.props.verCasoPUser.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.props.verCasoPUser.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.props.verCasoPUser.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnBlanco == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if ((ini + 5) - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayAcertadas == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            if ((ini + 5) - this.state.pregAcertadasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregAcertadasArr.length - 10 > 0 ? (this.state.pregAcertadasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayErroneas == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            if ((ini + 5) - this.state.pregErroresArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      } else if (this.state.displayEnDudas == true) {

        let ini = this.state.inicioNumeracion - 5
        let fin = this.state.finNumeracion - 5
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if ((ini + 5) - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }


      }

    } else if (e.target.id == 'leyClick') {
      let val = this.state.leyActive
      this.setState({ leyActive: !this.state.leyActive })

      if (val == false && this.state.leyActive2 == false) {
        this.setState({ leyActive2: true })
        setTimeout(
          function () {
            if (this.state.leyActive == true) {

              this.setState({ leyActive: false, leyActive2: false })
            }
          }.bind(this), 10000);
      }

    } else if (e.target.id == 'plantearDuda') {
      this.setState({ plantearDuda: true })
    } else if (e.target.id == 'cerrarPlantearDuda') {
      this.setState({ plantearDuda: false, textHtmlDudaAlumno: null })
    } else if (e.target.id == 'enviarDuda') {
      let numPregTest = this.state.numPregTest
      let infoPreg = this.props.verCasoPUser.find(p => p.numPreg == numPregTest)
      let textAlumno = this.state.textHtmlDudaAlumno
      if (isDefined(textAlumno) && textAlumno != '') {
        let data = {
          textoExplicacion: textAlumno,
          idPreg: infoPreg.idPreg,
          idR1: infoPreg.respuestas[0].idR,
          idR2: infoPreg.respuestas[1].idR,
          idR3: infoPreg.respuestas[2].idR,
          idR4: infoPreg.respuestas[3].idR,
          fecha: moment().format('YYYY-MM-DD HH:mm'),
          idUser: this.props.idUser,
          idCasoPractico: infoPreg.idTest
        }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/reportPreguntaCP", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1.affectedRows > 0) {

          swal("Duda enviada", 'La resolveremos lo antes posible.', 'success');

          let data2 = {
            idUserRecibe: 15,
            idUserEnvia: this.props.idUser,
            fecha: moment().format('YYYY-MM-DD HH:mm'),
            titulo: 'Acaban de plantear una duda - Test Práctico',
            notificacionTexto: 'Se acaba detectar el envio de una duda. ' + moment().format('DD-MM-YY HH:mm') + '.',
            tipoPreparadores: 1,
            tipoOficina: 0,
            tipoDuda: 2,
            tipoUrgente: 0,
            tipoReformasLey: 0,
            idDuda: cls1.insertId
          }
          let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })

        }

      } else {
        swal('Error falta texto', 'Describa su duda en el area de texto', 'error')
      }

    }
  }

  async loadPreg(e) {
    try {
      let key = await e.target.id
      if (key != undefined) {
        let { inicioNumeracion, finNumeracion } = this.state
        let { verCasoPUser } = this.props

        let value = await verCasoPUser.slice(inicioNumeracion, finNumeracion)[key].numPreg

        if (this.state.displayEnBlanco == true) {
          value = await this.state.pregEnBlancoArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        } else if (this.state.displayAcertadas == true) {
          value = await this.state.pregAcertadasArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        } else if (this.state.displayErroneas == true) {
          value = await this.state.pregErroresArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        } else if (this.state.displayEnDudas == true) {
          value = await this.state.pregDudosasArr.slice(inicioNumeracion, finNumeracion)[key].numPreg
        }

        if (value > 0 && value <= verCasoPUser.length) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          this.setState({ numPregTest: value })


          if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false) {
            let position = await verCasoPUser.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                if (position - verCasoPUser.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = verCasoPUser.length - 10 > 0 ? (verCasoPUser.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayEnBlanco == true) {

            let position = await this.state.pregEnBlancoArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayAcertadas == true) {

            let position = await this.state.pregAcertadasArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregAcertadasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregAcertadasArr.length
                if (position - this.state.pregAcertadasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregAcertadasArr.length - 10 > 0 ? (this.state.pregAcertadasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregAcertadasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregAcertadasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          } else if (this.state.displayErroneas == true) {

            let position = await this.state.pregErroresArr.findIndex(pp => pp.numPreg == value)
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                if (position - this.state.pregErroresArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }

          }




        } else {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          if (e.target.value == '' || e.target.value == undefined) {

          } else {
            swal('La pregunta: ' + value + ' no existe', '', 'error')
          }

        }

        this.reloadTest()
      }
    } catch (e) { console.log(e) }
  }

  async handleOnSubmit(e) {
    if (e.target.id == 'siguiente') {
      let { numPregTest } = this.state
      let { verCasoPUser } = this.props
      let nextPreg = parseInt(numPregTest) + 1
      if (nextPreg > verCasoPUser.length) {
        nextPreg = verCasoPUser.length
      }
      if (nextPreg <= verCasoPUser.length) {


        this.setState({ numPregTest: nextPreg })


        if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false && this.state.displayEnDudas == false) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                if (position - verCasoPUser.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = verCasoPUser.length - 10 > 0 ? (verCasoPUser.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayAcertadas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayErroneas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregErroresArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                if (position - this.state.pregErroresArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnDudas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }

        this.reloadTest()

      } else {
        swal('No hay más preguntas', '', 'info')
      }

    } else if (e.target.id == 'anterior') {
      let { numPregSimultaneas, numPregTest, pregExtra } = this.state
      let { verCasoPUser } = this.props
      let nextPreg = parseInt(numPregTest) - 1
      if (await nextPreg <= 0) {
        nextPreg = 1
      }

      if (nextPreg > 0) {

        this.setState({ numPregTest: nextPreg })

        if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false && this.state.displayEnDudas == false) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                if (position - verCasoPUser.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = verCasoPUser.length - 10 > 0 ? (verCasoPUser.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= verCasoPUser.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = verCasoPUser.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnBlanco == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                if (position - this.state.pregEnBlancoArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregEnBlancoArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregEnBlancoArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayAcertadas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregRespondidasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                if (position - this.state.pregRespondidasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregRespondidasArr.length - 10 > 0 ? (this.state.pregRespondidasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregRespondidasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregRespondidasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayErroneas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregErroresArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                if (position - this.state.pregErroresArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregErroresArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregErroresArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        } else if (this.state.displayEnDudas == true) {
          this.setState({ dudasView: false, reportesDuda: null, plantearDuda: false, textHtmlDudaAlumno: '', cargandoDudas: true })
          let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
          if (position != -1) {
            let ini = (position - 5)
            let fin = (position - 5) + 10
            if (ini > 0) {

              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                if (position - this.state.pregDudosasArr.length > 5) {
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                } else {
                  ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                  this.setState({ inicioNumeracion: ini, finNumeracion: fin })
                }
              }
            } else {
              ini = 0
              fin = 10
              if (fin <= this.state.pregDudosasArr.length) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                fin = this.state.pregDudosasArr.length
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          }

        }


        this.reloadTest()
      } else {
        swal('No hay más preguntas', '', 'info')
      }

    } else if (e.target.id == 'infoPuntuacion') {


      try {

        let test = this.props.verCasoPUser
        let data = { idClase: test[0].idClase }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/malCasoPractico", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        let restamos = await cls1[0].malCasoP;
        let restamosBlanco = await cls1[0].restaEnBlanco;
        let totalPreg = await cls1[0].totalPregRegla;
        let aciertoCP = await cls1[0].aciertoSuma;

        let restaMal = parseFloat(parseFloat(totalPreg / test.length) * restamos).toFixed(2)
        let restaEnBlanco = parseFloat(parseFloat(totalPreg / test.length) * restamosBlanco).toFixed(2)
        let sumaBien = parseFloat(parseFloat(totalPreg / test.length) * aciertoCP).toFixed(2)
        totalPreg = await cls1[0].totalPregRegla;
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoClsMinAprobar", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;
        let porcentajeAprobado = await cls2[0].minCasoPractico

        let name = "Test Práctico " + test.length + " preguntas - Puntuación";

        let content = document.createElement('div');

        content.innerHTML = `
        <strong>Acierto: +`+ sumaBien + `</strong></br>
        <strong> Erroneas: -`+ restaMal + `</strong></br>
        <strong>En Blanco: -`+ restaEnBlanco + `</strong></br></br>
        <strong>Puntuación mínima: `+ (totalPreg * (porcentajeAprobado / 100)).toFixed(2) + ` Puntos</strong> de 20</br>
        
        Necesita `+ (test.length * (porcentajeAprobado / 100)).toFixed(2) + ` preguntas correctas y ninguna erronea.
        </br></br></br>
        La puntuación se calcula proporcial a un test con `+ totalPreg + ` preguntas. Acierto: +1, Erroneas: -` + restamos.toFixed(2) + ` y En Blanco: -` + restamosBlanco.toFixed(2) + `.`
        content.style.textAlign = "left";
        swal({
          title: name,
          content: content,
          icon: "info",
        })
      } catch (e) { console.log(e) }
    } else if (e.target.id == 'correctas') {


      let position = await this.state.pregAcertadasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)
      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            if (position - this.state.pregAcertadasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregAcertadasArr.length - 10 > 0 ? (this.state.pregAcertadasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregAcertadasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregAcertadasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      await this.setState({ displayVerNumeros: true, displayErroneas: false, displayAcertadas: true, displayEnBlanco: false, displayEnDudas: false })

    } else if (e.target.id == 'enBlanco') {


      let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)

      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            if (position - this.state.pregEnBlancoArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregEnBlancoArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregEnBlancoArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      this.setState({ displayVerNumeros: true, displayErroneas: false, displayAcertadas: false, displayEnBlanco: true, displayEnDudas: false })
    } else if (e.target.id == 'erroneas') {


      let position = await this.state.pregErroresArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)
      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            if (position - this.state.pregErroresArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregErroresArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregErroresArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      this.setState({ displayVerNumeros: true, displayErroneas: true, displayAcertadas: false, displayEnBlanco: false, displayEnDudas: false })
    } else if (e.target.id == 'dudosasVer') {


      let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == this.state.numPregTest)
      if (position != -1) {
        let ini = (position - 5)
        let fin = (position - 5) + 10
        if (ini > 0) {

          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            if (position - this.state.pregDudosasArr.length > 5) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        } else {
          ini = 0
          fin = 10
          if (fin <= this.state.pregDudosasArr.length) {
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          } else {
            fin = this.state.pregDudosasArr.length
            this.setState({ inicioNumeracion: ini, finNumeracion: fin })
          }
        }
      } else {
        this.setState({ inicioNumeracion: 0, finNumeracion: 10 })
      }

      this.setState({ displayVerNumeros: true, displayEnDudas: true, displayErroneas: false, displayAcertadas: false, displayEnBlanco: false })
    }
  }

  async componentDidMount() {
    let response1xyz = await fetch("https://oposiciones-justicia.es/api/temas/allTemas");
    if (!response1xyz.ok) {
      throw Error(response1xyz.statusText);
    }
    let json1xyz = await response1xyz.json();
    let cls1xyz = await json1xyz;
    this.setState({ temasFacturados: cls1xyz })

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))

    if (this.props.verCasoPUser != undefined && this.props.verCasoPUser.length > 0) {
      if (this.state.pregAcertadasArr == undefined || this.state.pregErroresArr == undefined || this.state.pregEnBlancoArr == undefined) {
        await this.reloadTest()
      }


      this.setState({ numPregTest: 1 })
      let nextPreg = 1
      if (this.state.displayEnBlanco == false && this.state.displayAcertadas == false && this.state.displayErroneas == false && this.state.displayEnDudas == false) {
        let position = await this.props.verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.props.verCasoPUser.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.props.verCasoPUser.length
              if (position - this.props.verCasoPUser.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.props.verCasoPUser.length - 10 > 0 ? (this.props.verCasoPUser.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.props.verCasoPUser.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.props.verCasoPUser.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayEnBlanco == true) {

        let position = await this.state.pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)

        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregEnBlancoArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregEnBlancoArr.length
              if (position - this.state.pregEnBlancoArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregEnBlancoArr.length - 10 > 0 ? (this.state.pregEnBlancoArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregEnBlancoArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregEnBlancoArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayAcertadas == true) {

        let position = await this.state.pregAcertadasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregAcertadasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregAcertadasArr.length
              if (position - this.state.pregAcertadasArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregAcertadasArr.length - 10 > 0 ? (this.state.pregAcertadasArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregAcertadasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregAcertadasArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayErroneas == true) {

        let position = await this.state.pregErroresArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregErroresArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregErroresArr.length
              if (position - this.state.pregErroresArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregErroresArr.length - 10 > 0 ? (this.state.pregErroresArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregErroresArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregErroresArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      } else if (this.state.displayEnDudas == true) {

        let position = await this.state.pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).findIndex(pp => pp.numPreg == nextPreg)
        if (position != -1) {
          let ini = (position - 5)
          let fin = (position - 5) + 10
          if (ini > 0) {

            if (fin <= this.state.pregDudosasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregDudosasArr.length
              if (position - this.state.pregDudosasArr.length > 5) {
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              } else {
                ini = this.state.pregDudosasArr.length - 10 > 0 ? (this.state.pregDudosasArr.length - 10) : (0)
                this.setState({ inicioNumeracion: ini, finNumeracion: fin })
              }
            }
          } else {
            ini = 0
            fin = 10
            if (fin <= this.state.pregDudosasArr.length) {
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            } else {
              fin = this.state.pregDudosasArr.length
              this.setState({ inicioNumeracion: ini, finNumeracion: fin })
            }
          }
        }

      }


      let data = { nombreUser: this.props.nombreUser, tipoUser: this.props.tipoUser }
      fetch("https://oposiciones-justicia.es/api/usuarios/idUser", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).then(cls => {
        this.setState({ idUser: cls[0].id_usuario });
      }).catch(error => console.log('Object fecth failed', error))



      let datadgr4 = { idUser: this.props.idUser }

      let response2ERT = await fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(datadgr4)
      });
      if (!response2ERT.ok) {
        throw Error(response2ERT.statusText);
      }
      let json2ERT = await response2ERT.json();
      let cls2ERT = await json2ERT;
      if (cls2ERT != undefined && cls2ERT.length > 0) {
        this.setState({ cursos: cls2ERT })
      }



      let data1 = { idClase: this.props.verCasoPUser[0].idClase }

      let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;
      if (cls1 != undefined) {
        this.setState({ busquedaClasesFecha: cls1 });
      }

      await this.refs.ref_bodTest.focus()


    }
  }

  render() {

    const { isLogged, nombreUser, tipoUser, verCasoPUser, verCasoLoadTextHtml, semanasFacturadas, semanasPrgCurso } = this.props; // verCasoPUser
    const { statusCrono, numPregTest, numPregSimultaneas, pregExtra, pregRespondidasArr, displayVerNumeros, displayFinTest, statusCasoPractico, inicioNumeracion, finNumeracion,
      pregAcertadasArr, pregDudosasArr, displayEnDudas, pregErroresArr, pregEnBlancoArr, displayEnBlanco, displayAcertadas, displayErroneas, dudasView, leyActive, reportesDuda, plantearDuda, cargandoDudas, temasFacturados
    } = this.state;
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1) && (semanasFacturadas != null || semanasPrgCurso != null || tipoUser >= 58)) {


      return (


        verCasoPUser && verCasoPUser.length > 0 ? (
          <div id="bodyHacerTest" ref={'ref_bodTest'} className="bodyHacerTest" onKeyDown={this.keyDown} tabIndex={0}>

            <i id="cerrarCasoPractico" style={statusCasoPractico == true ? ({ display: 'block' }) : ({ display: 'none' })} title={'Cerrar caso práctico.'} className="fas fa-times-circle volador" onClick={this.actionTestUser} />
            <div style={statusCasoPractico == true ? ({ display: 'block' }) : ({ display: 'none' })} className="casoPracticoAbso"  >
              <div id="wrapper01">
                <div class="scrollbar05" id="style-2">
                  <div class="force-overflow05">
                    <p>CASO PRÁCTICO</p><br />
                    {ReactHtmlParser(verCasoLoadTextHtml)}

                  </div>

                </div>
              </div>

            </div>


            <div className="BHT-header">

              <div className="BHT-H-tipoTest">
                Test Práctico
              </div>

              <div className="BHT-H-infoTest">
                {verCasoPUser && verCasoPUser.length > 0 && isDefined(verCasoPUser[0].idClase) && verCasoPUser[0].idClase > 0 ? (
                  <div>
                    {this.state.cursos && this.state.cursos.length > 0 && this.state.cursos.find(c => c.idClase == verCasoPUser[0].idClase).diaSemanal}
                  </div>
                ) : null}
                {verCasoPUser && verCasoPUser.length > 0 && isDefined(verCasoPUser[0].idPrg) && verCasoPUser[0].idPrg > 0 ? (
                  <div>
                    Clase Nº
                    {this.state.busquedaClasesFecha && this.state.busquedaClasesFecha.length > 0 && this.state.busquedaClasesFecha.find(c => c.idPrograma == verCasoPUser[0].idPrg).semanaClase}
                  </div>
                ) : null}

                {verCasoPUser && verCasoPUser.length > 0 && !isDefined(verCasoPUser[0].idPrg) && isDefined(verCasoPUser[0].idTema) ? (
                  <div>

                    {
                      temasFacturados != undefined && isDefined(temasFacturados) ? (
                        temasFacturados.find(t => t.id_tema == verCasoPUser[0].idTema).titulo_tema
                        + ' (' + temasFacturados.find(t => t.id_tema == verCasoPUser[0].idTema).nombre_tema + ')'

                      ) : null

                    }

                  </div>
                ) : null}

                {verCasoPUser && verCasoPUser.length > 0 && verCasoPUser[0].numBloque > 0 && isDefined(verCasoPUser[0].idPrg) ? (
                  <div>
                    Bloque {verCasoPUser && verCasoPUser.length > 0 ? (verCasoPUser[0].numBloque) : null}
                  </div>
                ) : null}

                {verCasoPUser && verCasoPUser.length > 0 && verCasoPUser[0].numBloque > 0 && !isDefined(verCasoPUser[0].idPrg) && isDefined(verCasoPUser[0].idTema) ? (
                  <div>
                    Vuelta {verCasoPUser && verCasoPUser.length > 0 ? (verCasoPUser[0].numBloque) : null}
                  </div>
                ) : null}

                {verCasoPUser && verCasoPUser.length > 0 && verCasoPUser[0].numTestBloque > 0 ? (
                  <div style={{ borderRight: 'unset' }}>
                    Test {verCasoPUser && verCasoPUser.length > 0 ? (verCasoPUser[0].numTestBloque) : null}
                  </div>
                ) : null}

              </div>
              <div className="BHT-H-EXTRAINFO">
                <div className="BHT-H-totalPreg">
                  Total Preguntas: {verCasoPUser && verCasoPUser.length > 0 ? (verCasoPUser.length) : null}
                </div>

                <div className="BHT-H-totalPreg">
                  Puntuación: <b style={{ color: '#49d6ab' }}>{verCasoPUser && verCasoPUser.length > 0 && isDefined(verCasoPUser[0].puntuacion) ? (parseFloat(verCasoPUser[0].puntuacion).toFixed(2)) : null}</b>
                </div>

                <div className="BHT-H-totalPreg">
                  Fecha: {verCasoPUser && verCasoPUser.length > 0 && isDefined(verCasoPUser[0].fecha) ? (moment(verCasoPUser[0].fecha).format('DD-MM-YY')) : null}
                </div>


                <div title="Tiempo transcurrido en minutos." className="BHT-CT-TFT-timer2" style={{ marginTop: '25px' }}>

                  {verCasoPUser[0].minutos > 9 ? (
                    verCasoPUser[0].minutos
                  ) : (
                    '0' + verCasoPUser[0].minutos
                  )}
                  :
                  {verCasoPUser[0].segundos > 9 ? (
                    verCasoPUser[0].segundos
                  ) : (
                    '0' + verCasoPUser[0].segundos
                  )}
                  <i className={"fas fa-stopwatch"} title={"Tiempo transcurrido en minutos"} />

                </div>

              </div>

            </div>

            <div className="BHT-contentTest">


              <div className="BHT-CT-navPreg">
                <div className="BHT-CT-NP-item" id="correctas" onClick={this.handleOnSubmit} style={displayAcertadas == false ? ({}) : ({ fontWeight: 'bold' })} >
                  <div id="respondidas" style={{ background: '#49d6ab', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }}></div>
                  Correctas: {pregAcertadasArr && pregAcertadasArr.length > 0 ? (pregAcertadasArr.length) : (' 0')}
                </div>
                <div className="BHT-CT-NP-item" id="enBlanco" onClick={this.handleOnSubmit} style={displayEnBlanco == false ? ({}) : ({ fontWeight: 'bold' })}>
                  <div style={{ background: '#FFFFFF', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px', boxShadow: '0px 0px 4px 0px grey' }}></div>
                  En blanco: {pregEnBlancoArr && pregEnBlancoArr.length > 0 ? (pregEnBlancoArr.length) : (' 0')}
                </div>
                <div className="BHT-CT-NP-item" id="erroneas" onClick={this.handleOnSubmit} style={displayErroneas == false ? ({}) : ({ fontWeight: 'bold' })}>
                  <div style={{ background: '#ef4e4e', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }} ></div>
                  Erroneas: {pregErroresArr && pregErroresArr.length > 0 ? (pregErroresArr.length) : (' 0')}
                </div>

                {pregDudosasArr != undefined && pregDudosasArr.length > 0 ? (<div className="BHT-CT-NP-item" id="dudosasVer" onClick={this.handleOnSubmit} style={displayEnDudas == false ? ({}) : ({ fontWeight: 'bold' })}>
                  <div style={{ background: '#e9c541', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }} ></div>
                  Dudosas: {pregDudosasArr && pregDudosasArr.length > 0 ? (pregDudosasArr.length) : (' 0')}
                </div>) : null}


                <i id="cerrarFiltroPreg" style={statusCasoPractico == false && displayAcertadas == true || displayEnBlanco == true || displayErroneas == true || displayEnDudas == true ? ({ display: 'block' }) : ({ display: 'none' })} title={'Ver todas las preguntas'} className="fas fa-times-circle limpiarFiltroPreg" onClick={this.actionTestUser} />
              </div>

              <div className="BHT-CT-pregNavLoad">
                <div className="BHT-CT-PNL-icon">
                  <i className="fas fa-angle-left" id="anterior" onClick={this.actionTestUser}></i>
                </div>

                <div className="BHT-CT-PNL-bodyNums">

                  {displayAcertadas && displayAcertadas == true ? (
                    pregAcertadasArr.length > 0 && pregAcertadasArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                      <div id={key} onClick={this.loadPreg} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ background: '#3469b7', color: '#ffff', boxShadow: '0px 0px 5px 0px #3469b7' })} className={"BHT-CT-PNL-numCelda-correcta"}>
                        {preg.numPreg}
                      </div>
                    )
                  ) : null}

                  {displayEnBlanco && displayEnBlanco == true ? (
                    pregEnBlancoArr.length > 0 && pregEnBlancoArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                      <div id={key} onClick={this.loadPreg} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ background: '#3469b7', color: '#ffff', boxShadow: '0px 0px 5px 0px #3469b7' })} className={"BHT-CT-PNL-numCelda-blanco2"}>
                        {preg.numPreg}
                      </div>
                    )
                  ) : null}

                  {displayErroneas && displayErroneas == true ? (
                    pregErroresArr.length > 0 && pregErroresArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                      <div id={key} onClick={this.loadPreg} style={numPregTest && numPregTest != preg.numPreg ? ({}) : ({ background: '#3469b7', color: '#ffff', boxShadow: '0px 0px 5px 0px #3469b7' })} className="BHT-CT-PNL-numCelda-erronea">
                        {preg.numPreg}
                      </div>
                    )
                  ) : null}
                  {displayEnDudas && displayEnDudas == true ? (
                    pregDudosasArr.length > 0 && pregDudosasArr.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                      <div id={key} onClick={this.loadPreg} style={numPregTest && numPregTest != preg.numPreg ? ({ borderColor: '#e9c541' }) : ({ background: '#3469b7', color: '#ffff', boxShadow: '0px 0px 5px 0px #3469b7', borderColor: '#e9c541' })} className="BHT-CT-PNL-numCelda-erronea">
                        {preg.numPreg}
                      </div>
                    )
                  ) : null}
                  {verCasoPUser && displayEnBlanco == false && displayErroneas == false && displayAcertadas == false && displayEnDudas == false ? (
                    verCasoPUser.length > 0 && verCasoPUser.sort((a, b) => a.numPreg - b.numPreg).slice(inicioNumeracion, finNumeracion).map((preg, key) =>
                      <div id={key} onClick={this.loadPreg} className={

                        pregErroresArr && pregErroresArr.length > 0 && pregErroresArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                          numPregTest && numPregTest != preg.numPreg ?
                            ("BHT-CT-PNL-numCelda-erronea") :
                            ("BHT-CT-PNL-numCelda-selected2 BHT-CT-PNL-numCelda-erronea")
                        ) : (
                          pregEnBlancoArr && pregEnBlancoArr.length > 0 && pregEnBlancoArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                            numPregTest && numPregTest != preg.numPreg ?
                              ("BHT-CT-PNL-numCelda-blanco2") :
                              ("BHT-CT-PNL-numCelda-selected2 BHT-CT-PNL-numCelda-blanco2")
                          ) : (
                            pregAcertadasArr && pregAcertadasArr.length > 0 && pregAcertadasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                              numPregTest && numPregTest != preg.numPreg ?
                                ("BHT-CT-PNL-numCelda-correcta") :
                                ("BHT-CT-PNL-numCelda-selected2 BHT-CT-PNL-numCelda-correcta")
                            ) : null
                          )
                        )

                      }>
                        {preg.dudosa == 1 ? (
                          <div style={{
                            background: 'rgb(233, 197, 65)',
                            width: '7px',
                            height: '7px',
                            borderRadius: '50%',
                            marginRight: '-33px',
                            position: 'absolute',
                            marginTop: '-33px'
                          }}>

                          </div>
                        ) : null}
                        {preg.numPreg}
                      </div>
                    )
                  ) : null}

                </div>

                <div className="BHT-CT-PNL-icon">
                  <i className="fas fa-angle-right" id="siguiente" onClick={this.actionTestUser}></i>
                </div>
              </div>

              {verCasoPUser && numPregTest && verCasoPUser.filter(p => p.numPreg == numPregTest).length > 0 && verCasoPUser.filter(p => p.numPreg == numPregTest).map((preg, key) =>

                <div className="BHT-CT-pregTestContent" style={statusCrono == false ? ({ filter: 'blur(7px)' }) : ({ filter: 'blur(0px)' })} >

                  <div className="BHT-CT-navLeft">
                    <i className="fas fa-angle-left" onClick={this.handleOnSubmit} id="anterior"></i>
                  </div>
                  <div className="BHT-CT-PT-botonCaso" id="verCasoPractico" onClick={statusCrono == true ? (this.actionTestUser) : (null)}>
                    VER CASO PRÁCTICO
                  </div>
                  <div id="marcarComoDudosa" onClick={this.actionTestUser} className="BHT-CT-PT-botonPregDudosa">
                    {dudasView == true ? ("CERRAR DUDAS") : ("VER DUDAS")}

                  </div>
                  <div className="BHT-CT-pregTest">

                    <div className="BHT-CT-PT-numP">
                      <div className="numPregTv">
                        {pregErroresArr && pregErroresArr.length > 0 && pregErroresArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                          <div style={{ background: '#ef4e4e', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }} ></div>
                        ) : (
                          pregEnBlancoArr && pregEnBlancoArr.length > 0 && pregEnBlancoArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                            <div style={{ background: '#FFFFFF', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px', boxShadow: '0px 0px 4px 0px grey' }}></div>
                          ) : (
                            pregAcertadasArr && pregAcertadasArr.length > 0 && pregAcertadasArr.findIndex(p => p.numPreg == preg.numPreg) != -1 ? (
                              <div id="respondidas" style={{ background: '#49d6ab', width: '12px', height: '12px', borderRadius: '100px', marginRight: '7px', marginTop: '2px' }}></div>
                            ) : null
                          )
                        )}

                        Pregunta {+' ' + preg.numPreg}
                      </div>


                      <div className="BHT-CT-PT-numP-settings">
                        <div className="BHT-CT-PT-numP-settings-art" onClick={this.actionTestUser}>
                          <p id="leyClick">Ley</p>
                          <div id="leyClick" className="BHT-CT-PT-numP-settings-art-ley" title={preg.nombreLey}>
                            {
                              leyActive && leyActive == true ? (preg.nombreLey) : (preg.siglasLey)
                            }
                          </div>
                        </div>
                        <div className="BHT-CT-PT-numP-settings-art" onClick={this.viewArtC}>
                          <p>Artículo</p>
                          <div className="BHT-CT-PT-numP-settings-art-numArt">
                            {preg.articulo}
                          </div>


                        </div>
                        <div className="BHT-CT-PT-numP-infoTest">
                          <i className="fas fa-info-circle" title="Info Puntuación del Test" onClick={this.handleOnSubmit} id="infoPuntuacion"></i>
                        </div>


                      </div>
                    </div>
                    <div className="BHT-CT-PT-enunciado">
                      {preg.pregunta}
                    </div>

                    <div className="BHT-CT-PT-respuesta2">
                      <div className="BHT-CT-PT-letra" style={

                        preg.respuestas.find(r => r.idR == preg.idR1).valorUser != 0
                          ? (preg.respuestas.find(r => r.idR == preg.idR1).validez == 1 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 5 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 6 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 9 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 11 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 12 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 15 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 17 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 20 ||
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 24 ? ({ color: '#49d6ab' }) : ({ color: '#ef4e4e' })

                          ) : (
                            preg.respuestas.find(r => r.idR == preg.idR1).validez == 0 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 2 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 3 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 4 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 7 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 8 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 10 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 13 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 14 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 16 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 18 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 21 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 22 ||
                              preg.respuestas.find(r => r.idR == preg.idR1).validez == 23
                              ? ({}) : ({ color: '#49d6ab' })
                          )

                      }>
                        A
                      </div>
                      <div className="BHT-CT-PT-contenido">
                        {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR1).respuesta)}
                      </div>
                    </div>
                    {preg.respuestas.find(r => r.idR == preg.idR1).nota && preg.respuestas.find(r => r.idR == preg.idR1).nota != "" ? (
                      <div className="BHT-CT-PT-respuestaNota">
                        <div className="BHT-CT-PT-letra">
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className="BHT-CT-PT-contenido">
                          {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR1).nota)}
                        </div>
                      </div>
                    ) : null}


                    <div className="BHT-CT-PT-respuesta2">
                      <div className="BHT-CT-PT-letra"
                        style={

                          preg.respuestas.find(r => r.idR == preg.idR2).valorUser != 0
                            ? (preg.respuestas.find(r => r.idR == preg.idR2).validez == 1 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 5 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 6 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 9 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 11 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 12 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 15 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 17 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 20 ||
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 24 ? ({ color: '#49d6ab' }) : ({ color: '#ef4e4e' })
                            ) : (
                              preg.respuestas.find(r => r.idR == preg.idR2).validez == 0 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 2 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 3 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 4 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 7 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 8 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 10 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 13 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 14 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 16 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 18 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 21 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 22 ||
                                preg.respuestas.find(r => r.idR == preg.idR2).validez == 23
                                ? ({}) : ({ color: '#49d6ab' })
                            )

                        }
                      >
                        B
                      </div>
                      <div className="BHT-CT-PT-contenido" >
                        {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR2).respuesta)}
                      </div>
                    </div>
                    {preg.respuestas.find(r => r.idR == preg.idR2).nota && preg.respuestas.find(r => r.idR == preg.idR2).nota != "" ? (
                      <div className="BHT-CT-PT-respuestaNota">
                        <div className="BHT-CT-PT-letra">
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className="BHT-CT-PT-contenido">
                          {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR2).nota)}
                        </div>
                      </div>
                    ) : null}


                    <div className="BHT-CT-PT-respuesta2">
                      <div className="BHT-CT-PT-letra"
                        style={

                          preg.respuestas.find(r => r.idR == preg.idR3).valorUser != 0
                            ? (preg.respuestas.find(r => r.idR == preg.idR3).validez == 1 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 5 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 6 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 9 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 11 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 12 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 15 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 17 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 20 ||
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 24 ? ({ color: '#49d6ab' }) : ({ color: '#ef4e4e' })
                            ) : (
                              preg.respuestas.find(r => r.idR == preg.idR3).validez == 0 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 2 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 3 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 4 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 7 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 8 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 10 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 13 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 14 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 16 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 18 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 21 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 22 ||
                                preg.respuestas.find(r => r.idR == preg.idR3).validez == 23
                                ? ({}) : ({ color: '#49d6ab' })
                            )

                        }
                      >
                        C
                      </div>
                      <div className="BHT-CT-PT-contenido">
                        {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR3).respuesta)}
                      </div>
                    </div>
                    {preg.respuestas.find(r => r.idR == preg.idR3).nota && preg.respuestas.find(r => r.idR == preg.idR3).nota != "" ? (
                      <div className="BHT-CT-PT-respuestaNota">
                        <div className="BHT-CT-PT-letra">
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className="BHT-CT-PT-contenido">
                          {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR3).nota)}
                        </div>
                      </div>
                    ) : null}

                    <div className="BHT-CT-PT-respuesta2" >
                      <div className="BHT-CT-PT-letra"
                        style={

                          preg.respuestas.find(r => r.idR == preg.idR4).valorUser != 0
                            ? (preg.respuestas.find(r => r.idR == preg.idR4).validez == 1 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 5 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 6 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 9 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 11 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 12 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 15 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 17 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 20 ||
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 24 ? ({ color: '#49d6ab' }) : ({ color: '#ef4e4e' })
                            ) : (
                              preg.respuestas.find(r => r.idR == preg.idR4).validez == 0 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 2 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 3 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 4 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 7 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 8 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 10 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 13 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 14 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 16 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 18 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 21 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 22 ||
                                preg.respuestas.find(r => r.idR == preg.idR4).validez == 23
                                ? ({}) : ({ color: '#49d6ab' })
                            )

                        }
                      >
                        D
                      </div>
                      <div className="BHT-CT-PT-contenido">
                        {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR4).respuesta)}
                      </div>
                    </div>
                    {preg.respuestas.find(r => r.idR == preg.idR4).nota && preg.respuestas.find(r => r.idR == preg.idR4).nota != "" ? (
                      <div className="BHT-CT-PT-respuestaNota">
                        <div className="BHT-CT-PT-letra">
                          <i className="fas fa-level-up-alt" />
                        </div>
                        <div className="BHT-CT-PT-contenido">
                          {ReactHtmlParser(preg.respuestas.find(r => r.idR == preg.idR4).nota)}
                        </div>
                      </div>
                    ) : null}

                  </div>
                  <div className="BHT-CT-navRight">
                    <i className="fas fa-angle-right" onClick={this.handleOnSubmit} id="siguiente"></i>
                  </div>
                </div>

              )}
            </div>

            {dudasView && dudasView == true ? (

              <div style={{ width: '100%' }}>
                {reportesDuda && reportesDuda != undefined && reportesDuda.length > 0 ? (
                  <Dudas dudas={reportesDuda} tipoDuda="testPractico" />
                ) : null}
                {cargandoDudas && cargandoDudas == true ? (
                  <div className="cargandoDudasECG">
                    <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO DUDAS </div>
                    <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
                  </div>
                ) : (
                  <div className="dudaPlantearBody">
                    <div className="DPB-imgBoton">

                      <img src={'../img/woman1-2.png'} />
                      <div className="DPB-info">
                        <p>
                          {reportesDuda && reportesDuda != undefined && reportesDuda.length > 0 ? (
                            'Si no ha encontrado su duda resuelta o pendiente, entre las ya existentes, puede plantearnos su duda.'
                          ) : (
                            'No existe ninguna duda sobre esta pregunta, puede plantearnos su duda.'
                          )}

                        </p>
                        <div className="DPB-boton">
                          <Boton id="plantearDuda" icon1="" icon2="" texto="Plantear duda" onClick={this.actionTestUser} />
                        </div>

                      </div>

                    </div>

                  </div>
                )}
                {plantearDuda && plantearDuda == true ? (
                  <div className="plantearDudaPreg">
                    <div style={{ padding: '10px', lineHeight: '1.7' }}>
                      ¿En que podemos ayudarte? <br />
                      <b>Plantee su duda y será resuelta lo antes posible.</b>
                    </div>
                    <div className="editorPlantearrDuda">
                      <CKEditor
                        editor={ClassicEditor}
                        data={''}
                        onInit={editor => { }}
                        config={{
                          toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                          // toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
                        }}
                        onChange={this.onChangeText}
                      />
                    </div>
                    <div className="editorPlantearrDudaBotones">
                      <div className="epdb-boton">
                        <Boton id="cerrarPlantearDuda" icon1="" icon2="" texto="Cancelar" onClick={this.actionTestUser} />
                      </div>
                      <div className="epdb-boton">
                        <Boton id="enviarDuda" icon1="" icon2="" texto="Enviar duda" onClick={this.actionTestUser} />
                      </div>

                    </div>
                    <img className="imagenRchuload" src={'../img/woman1-2.png'} />
                  </div>
                ) : null}

              </div>



            ) : null}
          </div>

        ) : (null)


      );


    } else {
      if (isLogged && tipoUser < 58) {

        swal({
          title: 'Area restringida',
          text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {

          window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
        })
        return (null);
      } else {
        return (<Redirect to="/" />);
      }
    }
    //<VistaPreviaTest numPregTest={numPregTest} test={test}/>
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    verCasoPUser: state.user.verCasoPUser,
    verCasoLoadTextHtml: state.user.verCasoLoadTextHtml,
    idUser: state.user.idUser,
    semanasFacturadas: state.user.semanasFacturadas,
    semanasPrgCurso: state.user.semanasPrgCurso
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadDudosa, puntosRes, selectPreg, cronoFunc, borrarTestUser, loadTestJusticia, loadCasoPractico, openViewBusArt, addIdLeyBusArt, addArtBusArt
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VerTestUserCaso);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class DeleteTest extends Component {

	constructor(props){
		super(props);
        	this.state = {
			displayListTest: 'none',
			displayListTestCheck: 'none',
			listTestOpo: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		    ]
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.cleanForm = this.cleanForm.bind(this);
	}
	cleanForm(){
		this.setState({displayListTest: 'none', displayListTestCheck: 'none', listTestOpo: undefined, idOposicion: undefined,preguntasBorrar: undefined});
		this.refs.ref_oposicionTestEdt.refs.oposicionTestEdt.value='';
		this.refs.ref_nombreTestSelected.refs.nombreTestSelected.value='';
	}
	onChange(e){

		if(e.target.id == 'oposicionTestEdt'){
			 if(this.state.busquedaOpo.filter( opo => opo.nombre == e.target.value).length > 0){
				 this.setState({idOposicion: this.state.busquedaOpo.find( opo => opo.nombre == e.target.value).id_oposicion});
					let data = {
						idOposicion: this.state.busquedaOpo.find( opo => opo.nombre == e.target.value).id_oposicion
					}
					fetch("https://oposiciones-justicia.es/api/informatica/consultaTestPredXOpo", {
						method: 'POST',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify(data)
					}).then(response =>  {
						if (response.status >= 400) { throw new Error("Bad response from server");}
						return response.json();
					}).then(cls => {
					  if (cls.length != 0 || cls != undefined){
						  this.setState({displayListTest: 'flex', listTestOpo: cls});
					} else {
						this.setState({displayListTest: 'none', listTestOpo: undefined});
					}
					}).catch(error => console.log('Object fecth failed', error))
			} else {
				this.setState({displayListTest: 'none', displayListTestCheck: 'none', listTestOpo: undefined, idOposicion: undefined});
			}
			} else if (e.target.id == 'nombreTestSelected'){
				if(this.state.listTestOpo.filter( test => test.nombre == e.target.value).length > 0){
					this.setState({displayListTestCheck: 'flex', nombreTest: e.target.value, idTest: this.state.listTestOpo.find( test => test.nombre == e.target.value).idTestTemplate});
					// crear un array con todas las ids de las preguntas para borrar
					let data = {
						idTest: this.state.listTestOpo.find(test => test.nombre == e.target.value).idTestTemplate
					}
					fetch("https://oposiciones-justicia.es/api/informatica/selectPreguntasTemplate", {
   					  method: 'POST',
   					  headers: {'Content-Type': 'application/json'},
   					  body: JSON.stringify(data)
   				  }).then(response =>  {
   					  if (response.status >= 400) { throw new Error("Bad response from server");}
   					  return response.json();
   				  }).then(cls => {
   					  let preguntasBorrarPrev = [];
   					 for (var i = 0; i < cls.length; i++) {
   						 preguntasBorrarPrev.push({
   							idPregunta: cls[i].idPregunta
   						})
   					}
   					this.setState({preguntasBorrar: preguntasBorrarPrev})
   					}).catch(error => console.log('Object fecth failed', error))
				} else {
					this.setState({displayListTestCheck: 'none', nombreTest: undefined, idTest: undefined, preguntasBorrar: undefined});
				}
			}

	}
	handleOnClick(e){
		if( e.target.id == 'eliminarTest'){
			let data = {
				idTestTemplate: this.state.idTest
			}
			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, el Test Template sera borrado y las preguntas se mantendrán.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				 fetch("https://oposiciones-justicia.es/api/informatica/deleteTestTemplate", {
				    method: 'POST',
				    headers: {'Content-Type': 'application/json'},
				    body: JSON.stringify(data)
				  }).then(response =>  {
					 if (response.status >= 400) { throw new Error("Bad response from server");}
				    return response.json();
				  }).then(data => {
					 if(data.affectedRows > 0){
					    swal("¡Test Template borrado!",'',"success");
					    this.cleanForm();
							window.location.reload();
					 }}).catch(function(err) {console.log(err)});

			  } else {
			    swal("¡Test Template no borrado!",'',"success");
			  }
			});
		} else if (e.target.id == "eliminarTestYPreguntas") {
			let data = {
				idTestTemplate: this.state.idTest,
				preguntas: this.state.preguntasBorrar
			}
			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, el Test Template y todas las Preguntas/Respuestas serán eliminadas para siempre.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {

				 fetch("https://oposiciones-justicia.es/api/informatica/deleteTestTemplate", {
					 method: 'POST',
					 headers: {'Content-Type': 'application/json'},
					 body: JSON.stringify(data)
				 }).then(response =>  {
					 if (response.status >= 400) { throw new Error("Bad response from server");}
					 return response.json();
				 }).then(cls => {
				    return fetch("https://oposiciones-justicia.es/api/informatica/deletePreguntasTestJusticia", {
					    method: 'POST',
					    headers: {'Content-Type': 'application/json'},
					    body: JSON.stringify(data)
				    });
				    }).then(response =>  {
					    if (response.status >= 400) { throw new Error("Bad response from server");}
					    return response.json();
				    }).then(cls => {
					    swal("¡Test Template y Preguntas eliminadas!",'', "success");
					    this.cleanForm();
							window.location.reload();
				    }).catch(error => console.log('Object fecth failed', error))

			  } else {
			    swal("¡Test Template y Preguntas no eliminadas!",'',"success");
			  }
			});
		}
	}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));


	}
	render() {
		const { values, displayListTest, busquedaOpo, listTestOpo, displayListTestCheck } = this.state;
		return (
			<div className= "bodyLoadTest">
				<div className="opocionesLoadTest">

					<div className="inputLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Seleccione una Oposición
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion" id="oposicionTestEdt" name="oposicionTestEdt" ref="ref_oposicionTestEdt" icon="fas fa-search" onChange={this.onChange}/>
					<datalist id="oposcionesData">{
						busquedaOpo && busquedaOpo.map((cls, key) =>
							<option key={key} value={cls.nombre}></option>
						)
					}</datalist>

					</div>

					<div className="inputLoadTest" style={{display: displayListTest}}>
						<h4>
							<i className="fas fa-info-circle"></i>
							Seleccione un Test
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" datalist="listTestOpo" placeholder="Test Name" id="nombreTestSelected" name="nombreTestSelected"ref="ref_nombreTestSelected" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="listTestOpo">{
							listTestOpo && listTestOpo.filter(t=> t.nombre != 'No asignado').map((cls, key) =>
								<option key={key} value={cls.nombre}></option>
							)
						}</datalist>
					</div>

				</div>
				<Submit id="eliminarTest" display={displayListTestCheck} icon1="fas fa-upload" icon2="fas fa-database" texto="Eliminar Test Template" onClick={this.handleOnClick}/>
				<Submit id="eliminarTestYPreguntas" display={displayListTestCheck} icon1="fas fa-upload" icon2="fas fa-database" texto="Eliminar Test Template y Preguntas" onClick={this.handleOnClick}/>
			</div>
		);
	}
}

export default DeleteTest;

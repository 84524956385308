// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BotonMedio extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    icon1: PropTypes.string.isRequired,
    icon2: PropTypes.string.isRequired,
    texto: PropTypes.string.isRequired,
    title: PropTypes.string
  };
constructor(props){
        super(props);
        this.state = {};
          this.onClick = this.onClick.bind(this);
    }
    onClick(e){
        this.props.onClick(e);
    }
  render() {
    const { id, icon1, icon2, texto, title} = this.props;
              return (
                <div className="botonSmall" title={title} id={id} onClick={this.onClick}>
                   <i className={icon1} id={id}></i>
                   <div id={id}>{texto}</div>
                   <i id={id} className={icon2}></i>
                 </div>
              );
        }
 }
export default BotonMedio;

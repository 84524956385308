// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Utils
// import { isDefined } from '../../../../../lib/utils/is';
//Componets
import Input2 from '../../components/Inputs/Input2';
import Alumnos from '../../components/Alumnos';

import { Ellipsis } from '../../components/react-css-spinners';



class Word2010ClaseInfo extends Component {

  static propTypes = {

  };
  constructor(props) {
    super(props);
    this.state = {
      alumnosClase: null,
      navS: 0
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);

  }
  async onToggleClick(e) {
    if (e.target.id.split('-')[0] == 'nav') {

      this.setState({ navS: e.target.id.split('-')[1] })

    }
  }
  async onChangeInput(e) {

  }

  async componentDidMount() {
    // cargar alumnos clase word 2010 asignar a  alumnosClase

    let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/alumnosWord2010");
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    let alumnosWord2010 = await cls1

    for (let i = 0; i < alumnosWord2010.length; i++) {
      let data = { idUser: alumnosWord2010[i].id_usuario }
      let response2 = await fetch("https://oposiciones-justicia.es/api/word2010/facturasWord2010Alumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      alumnosWord2010[i].facturas = await cls2.sort((a, b) => new Date(a.fecha_pago) - new Date(b.fecha_pago));

      if (alumnosWord2010[i].facturas.length <= 4) {
        alumnosWord2010[i].estadoAlumnoWord = 'temario'
        alumnosWord2010[i].grupo = alumnosWord2010[i].facturas.length
      } else if (alumnosWord2010[i].facturas.length > 4) {
        alumnosWord2010[i].estadoAlumnoWord = 'normal+testTeoria'
        let grupo = alumnosWord2010[i].facturas.length

        do {
          grupo = grupo - 4
        } while (grupo > 4)
        alumnosWord2010[i].grupo = grupo

      }

      // if (alumnosWord2010[i].facturas.length == 1 && alumnosWord2010[i].facturas.filter(a => a.concepto == 'Word 2010 - Temario Intensivo').length > 0) {
      //   alumnosWord2010[i].estadoAlumnoWord = 'intensivo'
      // } else if (alumnosWord2010[i].facturas.length > 1 && alumnosWord2010[i].facturas.filter(a => a.concepto == 'Word 2010 - Temario Intensivo').length > 0 && alumnosWord2010[i].facturas.filter(a => a.tipo == 2).length > 0) {
      //   alumnosWord2010[i].estadoAlumnoWord = 'intensivo+acceso'
      // } else if (alumnosWord2010[i].facturas.length == 1 && alumnosWord2010[i].facturas.filter(a => a.concepto == 'Word 2010 - Temario Normal').length > 0) {
      //   alumnosWord2010[i].estadoAlumnoWord = 'temario'
      // } else if (alumnosWord2010[i].facturas.length > 1 && alumnosWord2010[i].facturas.filter(a => a.concepto == 'Word 2010 - Temario Normal').length > 0 && alumnosWord2010[i].facturas.filter(a => a.tipo == 2).length > 0) {
      //   alumnosWord2010[i].estadoAlumnoWord = 'normal+testTeoria'
      // }

      // dentro categorizar por Activo / No activo / Pediente de pago

    }

    alumnosWord2010 = await alumnosWord2010.filter(a => a.facturas.length > 0)

    this.setState({ alumnosClase: alumnosWord2010.sort((a, b) => b.grupo - a.grupo) })
    // this.setState({ alumnosClase: null })

  };

  render() {
    const { } = this.props;
    const { navS, alumnosClase } = this.state
    console.log('alumnosClase')
    console.log(alumnosClase)
    return (
      <div>
        {
          alumnosClase == null ? (
            <div className="cE1EstadisticasR" style={{ width: 'unset', alignItems: 'center' }}>
              <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO ALUMNOS WORD 2010 </div>
              <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
            </div>
          ) : (
            <div className='ColCenterStart alumnosDBody'>

              <div className='ColCenterStart adb-nav'>

                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                  <div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-0" onClick={this.onToggleClick}>
                    Temario
                    {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'temario').length) : null}
                  </div>

                  <div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-1" onClick={this.onToggleClick}>
                    Temario & Test Examen
                    {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria').length) : null}
                  </div>

                  <div className={navS == 2 ? 'navSelected' : 'navSelected navNoSelected'} id="nav-2" onClick={this.onToggleClick}>
                    Intensivo
                    {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo').length) : null}
                  </div>

                  <div className={navS == 3 ? 'navSelected' : 'navSelected navNoSelected '} id="nav-3" onClick={this.onToggleClick}>
                    Intensivo con acceso
                    {alumnosClase && alumnosClase.length > 0 ? (' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso').length) : null}
                  </div>

                </div>

              </div>

              {
                navS == 0 ? (
                  <div className='ColCenterStart'>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Activos
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago - Factura caducada
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> No activos / No renovaron el mes
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'temario' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>



                  </div>

                ) : null
              }

              {
                navS == 1 ? (
                  <div className='ColCenterStart'>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Activos
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>


                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago - Factura caducada
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> No activos - Terminado periodo de facturación
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}</div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'normal+testTeoria' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>



                  </div>
                ) : null
              }

              {
                navS == 2 ? (
                  <div className='ColCenterStart'>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Activos
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>

                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago - Factura caducada
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> No activos / No continuaron con subs mensual
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>



                  </div>

                ) : null
              }

              {
                navS == 3 ? (
                  <div className='ColCenterStart'>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Activos
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>


                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> Pendientes de pago - Factura caducada
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                            a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                          ).length}
                        </div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pendiente'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>

                    {
                      alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).length > 0 ? (
                        <div className='boderRUnset navSelected' style={{ marginTop: '20px' }}> No activos - Terminado periodo de facturación
                          {' - ' + alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                            moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                            a.facturas[a.facturas.length - 1].estado == 'Pagado'
                          ).length}</div>
                      ) : null
                    }
                    <div className='RowCenterCenter grupoDeAlumnos'>
                      {alumnosClase && alumnosClase.filter(a => a.estadoAlumnoWord == 'intensivo+acceso' &&
                        moment(a.facturas[a.facturas.length - 1].fecha_caducidad).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') &&
                        a.facturas[a.facturas.length - 1].estado == 'Pagado'
                      ).map((alumnos, key) =>
                        <div className="RowCenterCenter alumnoLoad-adb" style={{ marginBottom: '25px', marginTop: '25px' }}>
                          <div className="alumnoLoad-adb-aTfoto">
                            <img src={`../img/${alumnos.link_foto_perfil}`} className="alumnoLoad-adb-imgATFoto" />
                          </div>
                          <div className="alumnoLoad-adb-name">
                            <p>{alumnos.nombre + ' ' + alumnos.apellidos}</p>
                          </div>
                          <p style={{ position: 'absolute', left: '10px', top: '-25px', fontWeight: '500', color: '#3469b7' }}>Grupo mes: {alumnos.grupo}</p>
                          <p style={{ position: 'absolute', left: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7', }}>Fin fac: {moment(alumnos.facturas[alumnos.facturas.length - 1].fecha_caducidad).format('DD-MM-YY')}</p>
                          <p style={{ position: 'absolute', right: '10px', bottom: '-20px', fontWeight: '500', color: '#3469b7' }}>{alumnos.facturas[alumnos.facturas.length - 1].total_pagado + '€'}</p>
                        </div>
                      )}
                    </div>



                  </div>
                ) : null
              }


            </div>
          )
        }

      </div>
    );
  }
}

export default Word2010ClaseInfo;

// Actions
const CARGAR_CONTENIDO = 'CARGAR_CONTENIDO';

const CARGAR_CONTENIDO_APCLASE_CREAR = 'CARGAR_CONTENIDO_APCLASE_CREAR';

export function cargarContenido(option) {
    return {
      type: CARGAR_CONTENIDO,
      optionSelected: option
    };
}

export function cargarContenidoAPClaseCrear(infoSemanaPrg, bloqueTemas, cursoBaseSelected, cursosDinamicos, cursoBaseFechaMax, fechaClaseBase, value) {
  return {
    type: CARGAR_CONTENIDO_APCLASE_CREAR,
    infoSemanaPrg: infoSemanaPrg,
    bloqueTemas: bloqueTemas,
    cursoBaseSelected: cursoBaseSelected,
    cursosDinamicos: cursosDinamicos,
    cursoBaseFechaMax: cursoBaseFechaMax,
    fechaClaseBase: fechaClaseBase,
    value: value
  };
}

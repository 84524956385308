// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';
import PreguntaInfoDel from '../preguntas/PreguntaInfoDel';
import PreguntaInfoDelSelected from '../preguntas/PreguntaInfoDelSelected';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { genPregToDelete, addPregunta, delPregunta, vaciarPregToDelete} from './actions/testJusticiaAction';

class DeletePreguntas extends Component {

	constructor(props){
		super(props);
    	this.state = {
				displayPregToSelect: 'none',
				displayPregtoDel: 'none',
				preguntasBusqueda: undefined,
				displayListTest: 'none',
				displayListTestCheck: 'none',
				toogleInfoPregunta: 'none',
				listTestOpo: undefined,
				values: [
					{
						value: '',
						name: '¿Si o no?',
						disable: 'disabled',
						selected: 'selected'
				    },{
						value: '1',
						name: 'Si',
						disable: undefined,
						selected: undefined
				    },{
					    value: '0',
					    name: 'No',
					    disable: undefined,
					    selected: undefined
				   }
			    ]
			};
			this.onChange = this.onChange.bind(this);
			this.handleOnClick = this.handleOnClick.bind(this);
			this.cleanForm = this.cleanForm.bind(this);
	}
	cleanForm(){
		this.setState({
			preguntaTexto: undefined,
			articuloPregBusqueda: undefined,
			idPreguntaDel: undefined
		})
		this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
		this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
		this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
	}
	onChange(e){

		if(e.target.id == 'idPreguntaDel'){
			if(this.refs.ref_preguntaTexto.refs.preguntaTexto.value != ''
				|| this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value != ''
				|| this.state.articuloPregBusqueda != undefined || this.state.preguntaTexto != undefined){
					this.setState({
					preguntaTexto: undefined,
					articuloPregBusqueda: undefined
				})
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
			}
			this.setState({ idPreguntaDel: e.target.value })

			// fetch idPreguntaDel --> idPregJusExiste
			if(e.target.value >= 0 && e.target.value != ''){
				let data = { idPreg: e.target.value}
				fetch("https://oposiciones-justicia.es/api/informatica/idPregJusExiste", {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(data)
				}).then(response =>  {
					if (response.status >= 400) { throw new Error("Bad response from server");}
					return response.json();
				}).then(cls => {
					if (cls.length != 0 || cls != undefined){
						let newPregBusqueda = []
						for (var i = 0; i < cls.length/4; i++) {
							newPregBusqueda.push({
								selected: 0,
								idPregunta: cls[i*4].idPregunta,
								preguntaTexto: cls[i*4].pregunta,
								articulo: cls[i*4].articulo,
								idLey: cls[i*4].idLey,
								siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								r1: cls[(i*4)+0].respuesta,
								r1Validez: cls[(i*4)+0].validez,
								nota1: cls[(i*4)+0].nota,
								subrayado1: cls[(i*4)+0].subrayado,
								r2: cls[(i*4)+1].respuesta,
								r2Validez: cls[(i*4)+1].validez,
								nota2: cls[(i*4)+1].nota,
								subrayado2: cls[(i*4)+1].subrayado,
								r3: cls[(i*4)+2].respuesta,
								r3Validez: cls[(i*4)+2].validez,
								nota3: cls[(i*4)+2].nota,
								subrayado3: cls[(i*4)+2].subrayado,
								r4: cls[(i*4)+3].respuesta,
								r4Validez: cls[(i*4)+3].validez,
								nota4: cls[(i*4)+3].nota,
								subrayado4: cls[(i*4)+3].subrayado
							})
						}
						this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
					} else {
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
					}
				}).catch(error => console.log('Object fecth failed', error))
			} else if (e.target.value == ''){
				this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
			}

		} else if(e.target.id == 'preguntaTexto'){

			if(this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value != '' ||
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value != ''
				|| this.state.articuloPregBusqueda != undefined || this.state.idPreguntaDel != undefined){
				this.setState({
					idPreguntaDel: undefined,
					articuloPregBusqueda: undefined
				})
				this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
			}
			this.setState({ preguntaTexto: e.target.value })

			// fetch preguntaTexto --> preguntaTextoJusExiste
			if(e.target.value.length > 0 && e.target.value != ''){
				let data = { textPreg: e.target.value}
				fetch("https://oposiciones-justicia.es/api/informatica/preguntaTextoJusExiste", {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(data)
				}).then(response =>  {
					if (response.status >= 400) { throw new Error("Bad response from server");}
					return response.json();
				}).then(cls => {
					if (cls.length != 0 || cls != undefined){
						let newPregBusqueda = []
						for (var i = 0; i < cls.length/4; i++) {
							newPregBusqueda.push({
								selected: 0,
								idPregunta: cls[i*4].idPregunta,
								preguntaTexto: cls[i*4].pregunta,
								articulo: cls[i*4].articulo,
								idLey: cls[i*4].idLey,
								siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								r1: cls[(i*4)+0].respuesta,
								r1Validez: cls[(i*4)+0].validez,
								nota1: cls[(i*4)+0].nota,
								subrayado1: cls[(i*4)+0].subrayado,
								r2: cls[(i*4)+1].respuesta,
								r2Validez: cls[(i*4)+1].validez,
								nota2: cls[(i*4)+1].nota,
								subrayado2: cls[(i*4)+1].subrayado,
								r3: cls[(i*4)+2].respuesta,
								r3Validez: cls[(i*4)+2].validez,
								nota3: cls[(i*4)+2].nota,
								subrayado3: cls[(i*4)+2].subrayado,
								r4: cls[(i*4)+3].respuesta,
								r4Validez: cls[(i*4)+3].validez,
								nota4: cls[(i*4)+3].nota,
								subrayado4: cls[(i*4)+3].subrayado
							})
						}
						this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
					} else {
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
					}
				}).catch(error => console.log('Object fecth failed', error))
			} else if (e.target.value == ''){
				this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
			}


		} else if(e.target.id == 'articuloPregBusqueda'){
			if(this.refs.ref_preguntaTexto.refs.preguntaTexto.value != '' ||
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value != ''
				|| this.state.preguntaTexto != undefined || this.state.idPreguntaDel != undefined){
				this.setState({
					preguntaTexto: undefined,
					idPreguntaDel: undefined
				})
				this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
			}
			this.setState({ articuloPregBusqueda: e.target.value })

			// fetch articuloPregBusqueda --> artPrgJusExiste
			if(e.target.value.length > 0 && e.target.value != ''){
				let data = { articuloPregBusqueda: e.target.value}
				fetch("https://oposiciones-justicia.es/api/informatica/artPrgJusExiste", {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(data)
				}).then(response =>  {
					if (response.status >= 400) { throw new Error("Bad response from server");}
					return response.json();
				}).then(cls => {
					if (cls.length != 0 || cls != undefined){
						let newPregBusqueda = []
						for (var i = 0; i < cls.length/4; i++) {
							newPregBusqueda.push({
								selected: 0,
								idPregunta: cls[i*4].idPregunta,
								preguntaTexto: cls[i*4].pregunta,
								articulo: cls[i*4].articulo,
								idLey: cls[i*4].idLey,
								siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								r1: cls[(i*4)+0].respuesta,
								r1Validez: cls[(i*4)+0].validez,
								nota1: cls[(i*4)+0].nota,
								subrayado1: cls[(i*4)+0].subrayado,
								r2: cls[(i*4)+1].respuesta,
								r2Validez: cls[(i*4)+1].validez,
								nota2: cls[(i*4)+1].nota,
								subrayado2: cls[(i*4)+1].subrayado,
								r3: cls[(i*4)+2].respuesta,
								r3Validez: cls[(i*4)+2].validez,
								nota3: cls[(i*4)+2].nota,
								subrayado3: cls[(i*4)+2].subrayado,
								r4: cls[(i*4)+3].respuesta,
								r4Validez: cls[(i*4)+3].validez,
								nota4: cls[(i*4)+3].nota,
								subrayado4: cls[(i*4)+3].subrayado
							})
						}
						this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
					} else {
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
					}
				}).catch(error => console.log('Object fecth failed', error))
			} else if (e.target.value == ''){
				this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
			}
		}

	}
	handleOnClick(e){
		if (e.target.id == "borrarPreguntas") {
			let data = {
				preguntas: this.props.pregToDelete
			}
			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, todas las preguntas seleccionadas serán eliminadas para siempre.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {

					fetch("https://oposiciones-justicia.es/api/informatica/deletePregJusSelected", {
						method: 'POST',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify(data)
					}).then(response =>  {
						if (response.status >= 400) { throw new Error("Bad response from server");}
						return response.json();
					}).then(cls => {
						swal("¡Preguntas eliminadas!",'', "success");
						this.cleanForm();
						this.props.genPregToDelete(undefined)
						window.location.reload();
					}).catch(error => console.log('Object fecth failed', error))

			  } else {
			    swal("¡Preguntas no eliminadas!",'',"success");
			  }
			});
		} else if(e.target.id.split('-')[0] == 'seleccionarPreguntaBorrar'){

			let posicionPreg = e.target.id.split('-')[1]
			let pregSelect = this.state.preguntasBusqueda[posicionPreg]

			if(this.props.pregToDelete.length > 0){
				// añadir
				this.props.addPregunta(pregSelect)
				let pregBusNew = this.state.preguntasBusqueda
				pregBusNew[posicionPreg].selected = 1;
				this.setState({ preguntasBusqueda: pregBusNew })
			} else {
				// generar
				this.props.genPregToDelete(pregSelect)
				let pregBusNew = this.state.preguntasBusqueda
				pregBusNew[posicionPreg].selected = 1;
				this.setState({ preguntasBusqueda: pregBusNew, displayPregtoDel: 'flex' })
			}
		} else if (e.target.id.split('-')[0] == 'deseleccionarPreguntaBorrar'){
			let posicionPreg = e.target.id.split('-')[1]
			let idPrgToDel = this.props.pregToDelete[posicionPreg].idPregunta


			if(this.props.pregToDelete.length == 1){
				this.props.delPregunta(idPrgToDel)
				this.setState({ displayPregtoDel: 'none'})
			} else {
				this.props.delPregunta(idPrgToDel)
			}
			if(this.state.preguntasBusqueda != undefined){
				if( this.state.preguntasBusqueda.filter( p => p.idPregunta == idPrgToDel).length > 0) {
					let pregBusNew = this.state.preguntasBusqueda
					pregBusNew[posicionPreg].selected = 0;
					this.setState({ preguntasBusqueda: pregBusNew })
				}
			}

		}

	}

	componentDidMount(){

		this.props.genPregToDelete(undefined)

		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));

		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
				.then(leyes => this.setState({
						busquedaLeyes: leyes
				}))
			.catch(error => console.log('Object fecth failed', error));

	}
	render() {
		const { pregToDelete } = this.props;
		const { busquedaLeyes,values, displayPregToSelect, displayPregtoDel, displayPregSelected ,idPreguntas, listTestOpo, displayListTestCheck, toogleInfoPregunta, preguntasBusqueda } = this.state

		return (
			<div className= "bodyLoadTest">

				<div className="opocionesLoadTest">

					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Identificador
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" datalist="idPreguntas" placeholder="Id - Pregunta" id="idPreguntaDel" name="idPreguntaDel" ref="ref_idPreguntaDel" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="idPreguntas">{
							idPreguntas && idPreguntas.map((cls, key) =>
								<option key={key} value={cls.nombre}></option>
							)
						}</datalist>
					</div>

					<div className="inputLoadTest">
						<h4 style={{marginBottom: '5px'}}>
							<i className="fas fa-info-circle"></i>
							Texto
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>

						<InputTextArea id='preguntaTexto' name="preguntaTexto" ref="ref_preguntaTexto" valueDefault="¡Buscar texto de pregunta!" width="265" height="35" marginTop="2" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Articulo
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" placeholder="Artículo" id="articuloPregBusqueda" name="articuloPregBusqueda" ref="ref_articuloPregBusqueda" icon="fas fa-search" onChange={this.onChange}/>

					</div>

				</div>

				<h44 style={{display: displayPregToSelect}}>
					<i className="fas fa-info-circle"></i>
					Preguntas encontradas
					<i className="fas fa-arrow-circle-down"></i>
				</h44>
				<div className="inputLoadTest" style={{display: displayPregToSelect}}>
				{ preguntasBusqueda && preguntasBusqueda.length > 0 && preguntasBusqueda.map((cls, key) =>
					<PreguntaInfoDel id={key} pregunta={preguntasBusqueda[key]} preguntasToDel={pregToDelete} onClick={this.handleOnClick}/>
				)}
				</div>

				<h44 style={{display: displayPregtoDel}}>
					<i className="fas fa-info-circle"></i>
					Preguntas seleccionadas para borrar
					<i className="fas fa-arrow-circle-down"></i>
				</h44>
				<div className="inputLoadTest" style={{display: displayPregtoDel}}>
				{ pregToDelete && pregToDelete.length > 0 && pregToDelete.map((cls, key) =>
					<PreguntaInfoDelSelected id={key} pregunta={pregToDelete[key]} onClick={this.handleOnClick}/>
				)}
				</div>
				<div>
				<Submit id="borrarPreguntas" display={displayPregtoDel} icon1="fas fa-upload" icon2="fas fa-database" texto="Eliminar Preguntas seleccionadas" onClick={this.handleOnClick}/>
				</div>
			</div>
		);
	}
}


// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		pregToDelete: state.testJusticia.pregToDelete
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		genPregToDelete,
		addPregunta,
		delPregunta,
		vaciarPregToDelete
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePreguntas);

// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import bcrypt from 'bcryptjs';
// import $ from 'jquery';
import moment from 'moment';
//Assets
import './css/css.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarGenTest, genTestAddOpo, genTestEdtOpo, genTestDelOpo, genTestAddClase, genTestEdtClase, genTestDelClase, genTestEdtNumPregTotal, genTestEdtNameTest, genTestEdtRepetirPreg, genTestEdtTemporalizacionExp, genTestContinuar, genTestAddTema, genTestDelTema, genTestEdtTema, genTestAddIntervalo, genTestDelIntervalo, genTestEdtIntervalo, genTestDelIntervalos, genTestAddInt, genTestEdtTemaOpo, genTestEdtTotalPregAll, borrarTestUser, loadTestUser } from '../../actions/userAction';
// Utils
import {
  selectPreguntasPorArticulosInter,
  selectPreguntasPorArticuloUnico,
  respuestasPregJusticia,
  mezclarRespuestasJusticia,
  prepararTestModoHacer,
  generarTestTemplateMultiple
} from '../../lib/utils/generadorTestJusticia';

import { respuestaSbr, comprobarRespuestas } from '../../lib/utils/cargarTestJusticia'
// Components
import Input2 from '../../components/Inputs/Input2';
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';
import PreguntaTestVerTeoria from '../../containers/PreguntaTestVerTeoria';

// import xuaaaan from '../../public/sonidos/xuaaaan.mp3'

import swal from 'sweetalert';
import { emptyStatement } from '@babel/types';

//const audio = new Audio(xuaaaan)

class TestJusticia extends Component {


  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    genTestInfo: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      mezclarPreguntas: 0,
      mezclarRespuestas: 0,
      mezclarTemas: 0,
      seletedInfo: 0,
      totalPregTemas: 0,
      totalPregInterval: 0,
      visualizar: 0,
      temaActivo: 0,
      artActivo: null,
      modeTest: 'home',
      generador: null,
      avisoInterBloqueado: null,
      preguntasLoadView: null,
      verPreguntaOnOff: false,
      preguntaTestLoad: null,
      posPLVInter: null, preguntaViewLocation: null,
      play: false,
      verPreguntaOnOff: false,
      genTestInfo: this.props.genTestInfo,
      totalPreguntasPreparadorTodas: null,
      numPregTotalTest: 0,
      expTiming: [
        {
          tiempo: 'Todo el tiempo'
        },
        {
          tiempo: '1 mes'
        },
        {
          tiempo: '2 meses'
        },
        {
          tiempo: '3 meses'
        },
        {
          tiempo: '4 meses'
        },
        {
          tiempo: '5 meses'
        },
        {
          tiempo: '6 meses'
        }

      ]
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.repartoDePreguntas = this.repartoDePreguntas.bind(this);
    this.asignarPreguntasATemas = this.asignarPreguntasATemas.bind(this);
    this.asignarPreguntasAIntervalos = this.asignarPreguntasAIntervalos.bind(this);
    this.repartoPreguntasProporcional = this.repartoPreguntasProporcional.bind(this);
  }

  asignarPreguntasAIntervalos(numPreguntas, intervalos) {
    if (intervalos.length === 1) {
      return [
        {
          ...intervalos[0],
          preguntasAsignadas: numPreguntas,
        },
      ];
    }

    const intervalosCopiados = intervalos.map((intervalo) => ({ ...intervalo }));

    const totalIntervalos = intervalosCopiados.length;
    const totalPreguntasDisponibles = intervalosCopiados.reduce(
      (total, intervalo) => total + intervalo.preguntasDisponibles.length,
      0
    );

    const preguntasRepartidas = [];

    if (numPreguntas >= totalIntervalos) {
      let preguntasRestantes = numPreguntas;
      let pregNumP = numPreguntas
      for (let d = 0; d < intervalosCopiados.length; d++) {
        const intervalo = intervalosCopiados[d];

        intervalosCopiados[d].preguntasAsignadas = intervalo.preguntasAsignadas || 0;
        intervalosCopiados[d].preguntasAsignadas += 1;
        preguntasRestantes -= 1;
        pregNumP -= 1;
      }
      if (preguntasRestantes > 0) {

        for (let i = 0; i < intervalosCopiados.length; i++) {
          if (preguntasRestantes == 0) {
            break;
          }
          // const intervalo = intervalosCopiados[i];
          const proporcional = intervalosCopiados[i].preguntasDisponibles.length / totalPreguntasDisponibles;

          let coefi = proporcional * pregNumP //numPreguntas
          const diferencia = coefi - parseInt(coefi);
          const cantidadPreguntas = 0
          if (diferencia <= 0.5) {
            cantidadPreguntas = Math.floor(coefi);
          } else {
            cantidadPreguntas = Math.ceil(coefi);

          }
          // if(cantidadPreguntas == 0 && coefi > 0 && preguntasRestantes > 0){
          //   cantidadPreguntas = 1
          // }
          if (cantidadPreguntas > preguntasRestantes) {
            cantidadPreguntas = preguntasRestantes
          }


          // const cantidadPreguntas = Math.round(proporcional * numPreguntas);

          intervalosCopiados[i].preguntasAsignadas = intervalosCopiados[i].preguntasAsignadas || 0;
          intervalosCopiados[i].preguntasAsignadas += cantidadPreguntas;
          preguntasRestantes -= cantidadPreguntas;

          // preguntasRepartidas.push(intervalo);
        }

      }

      for (let x2 = 0; x2 < intervalosCopiados.length; x2++) {
        const intervalo = intervalosCopiados[x2];
        preguntasRepartidas.push(intervalo);

      }
      let i = 0;
      while (preguntasRestantes > 0) {
        intervalosCopiados[i].preguntasAsignadas = intervalosCopiados[i].preguntasAsignadas || 0;
        intervalosCopiados[i].preguntasAsignadas++;
        preguntasRestantes--;
        i = (i + 1) % totalIntervalos;
      }
    } else {
      const intervalosOrdenados = intervalosCopiados.sort(
        (a, b) => b.preguntasDisponibles.length - a.preguntasDisponibles.length
      );

      for (let i = 0; i < totalIntervalos; i++) {
        intervalosOrdenados[i].preguntasAsignadas = intervalosOrdenados[i].preguntasAsignadas || 0;
        intervalosOrdenados[i].preguntasAsignadas += i < numPreguntas ? 1 : 0;
      }

      preguntasRepartidas.push(...intervalosOrdenados);
    }

    // Asignar 0 a los intervalos no asignados
    intervalosCopiados.forEach((intervalo) => {
      if (!intervalo.hasOwnProperty('preguntasAsignadas')) {
        intervalo.preguntasAsignadas = 0;
      }
    });

    return preguntasRepartidas;
  }

  asignarPreguntasATemas(temas, totalPregunta) {
    let totalPreguntas = totalPregunta
    let totalPreguntasIntervalos = 0;

    // Calcula la suma total de preguntas disponibles en todos los intervalos
    for (let i = 0; i < temas.length; i++) {
      const tema = temas[i];
      for (let j = 0; j < tema.interval.length; j++) {
        const intervalo = tema.interval[j];
        totalPreguntasIntervalos += intervalo.preguntasDisponibles.length;
      }
    }

    if (totalPreguntasIntervalos < totalPreguntas) {
      totalPreguntas = totalPreguntasIntervalos
    }

    const preguntasPorTema = [];
    let preguntasRestantes = totalPreguntas;

    // Calcula la cantidad de preguntas a asignar por cada tema en proporción a su total
    for (let i = 0; i < temas.length; i++) {
      const tema = temas[i];
      let preguntasPorTemaActual = 0;

      for (let j = 0; j < tema.interval.length; j++) {
        const intervalo = tema.interval[j];
        const proporcion = totalPreguntasIntervalos === 0 ? 0 : intervalo.preguntasDisponibles.length / totalPreguntasIntervalos;
        const preguntasExtras = Math.round(proporcion * totalPreguntas);

        preguntasPorTemaActual += preguntasExtras;
      }

      preguntasPorTema.push(preguntasPorTemaActual);
      preguntasRestantes -= preguntasPorTemaActual;
    }

    // Distribuye las preguntas restantes proporcionalmente a los temas
    while (preguntasRestantes > 0) {
      for (let i = 0; i < temas.length; i++) {
        const tema = temas[i];
        let preguntasPorTemaActual = preguntasPorTema[i];

        for (let j = 0; j < tema.interval.length; j++) {
          const intervalo = tema.interval[j];
          const proporcion = totalPreguntasIntervalos === 0 ? 0 : intervalo.preguntasDisponibles.length / totalPreguntasIntervalos;
          const preguntasExtras = Math.round(proporcion * preguntasRestantes);

          preguntasPorTemaActual += preguntasExtras;
        }

        preguntasPorTema[i] = preguntasPorTemaActual;
        preguntasRestantes -= preguntasPorTemaActual;

        if (preguntasRestantes === 0) {
          break;
        }
      }
    }

    const temasOrdenados = [];

    for (let i = 0; i < temas.length; i++) {
      const tema = temas[i];
      let totalPreguntasDisponibles = 0;

      for (let j = 0; j < tema.interval.length; j++) {
        const intervalo = tema.interval[j];
        totalPreguntasDisponibles += intervalo.preguntasDisponibles.length;
      }

      const proporcionPreguntas = totalPreguntasDisponibles / totalPreguntasIntervalos;
      const preguntasAsignadas = Math.round(totalPreguntas * proporcionPreguntas);

      // Asegura que haya al menos 1 pregunta asignada
      // const preguntasMinimasAsignadas = Math.max(preguntasAsignadas, 1);
      const preguntasMinimasAsignadas = Math.max(preguntasAsignadas, 0);
      temasOrdenados.push({
        idTema: tema.id,
        numeroTotalPreguntas: preguntasMinimasAsignadas,
        totalPreguntasDisponibles: totalPreguntasDisponibles,
        intervalos: tema.interval
      });
    }
    return temasOrdenados;
  }

  async repartoPreguntasProporcional() {
    let value = 100
    let a = this.asignarPreguntasATemas(this.props.genTestInfo.temasTestG, value)

    for (let i = 0; i < a.length; i++) {
      await this.props.genTestEdtNumPregTotal(a[i].numeroTotalPreguntas, i, 'tema')

      let b = this.asignarPreguntasAIntervalos(a[i].numeroTotalPreguntas, a[i].intervalos)

      for (let j = 0; j < b.length; j++) {
        let loca = a[i].intervalos.findIndex(cls => cls.artFin == b[j].artFin && cls.artIni == b[j].artIni && cls.idLey == b[j].idLey)
        if (loca != -1) {
          let pregAsig = b[j].preguntasAsignadas == undefined ? 0 : b[j].preguntasAsignadas
          await this.props.genTestEdtIntervalo(i, 'tema', loca, 'numPreg', pregAsig)
        }

      }


    }
    this.setState({ genTestInfo: this.props.genTestInfo })
    let totalPregTem = 0
    for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

      if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
        totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
      }
    }
    this.setState({ totalPreguntasPreparadorTodas: totalPregTem })






  }
  async repartoDePreguntas(loca, tipo) {

    if (loca != null && tipo == 'tema') {
      //let loca = this.state.temaActivo
      let preguntas = this.props.genTestInfo.temasTestG[loca].interval



      if (preguntas != undefined && preguntas.length > 0 && preguntas.filter(p => p.preguntasDisponibles.length > 0).length > 0) {

        let iteracionReparto = 0
        let sobran = 0
        do {
          let pregTotal = this.props.genTestInfo.temasTestG[loca].numPreg

          let totalPregDisponibles = 0
          let pregAsignadas = 0
          let pregTotalDisponibles = 0

          for (let l = 0; l < preguntas.filter(p => p.bloqueado == false).length; l++) {

            if (preguntas.filter(p => p.bloqueado == false)[l].preguntasDisponibles.length > 0) {
              pregTotalDisponibles = pregTotalDisponibles + preguntas.filter(p => p.bloqueado == false)[l].preguntasDisponibles.length
            }


          }

          for (let l = 0; l < preguntas.filter(p => p.bloqueado == true).length; l++) {

            if (preguntas.filter(p => p.bloqueado == true)[l].preguntasDisponibles.length > 0) {
              if (preguntas.filter(p => p.bloqueado == true)[l].numPreg != '' && parseInt(preguntas.filter(p => p.bloqueado == true)[l].numPreg) <= preguntas.filter(p => p.bloqueado == true)[l].preguntasDisponibles.length) {
                pregTotalDisponibles = pregTotalDisponibles + parseInt(preguntas.filter(p => p.bloqueado == true)[l].numPreg)
              } else {
                if (this.props.tipoUser > 50) {
                  swal('Error intervalo bloqueado', 'No escoja más preguntas de las disponibles por intervalo', 'info')
                }


              }
            }

          }

          if (iteracionReparto == 0) {

            let bloqueosNumPreg = preguntas.filter(p => p.bloqueado == true)
            if (bloqueosNumPreg.length > 0) {
              for (let k = 0; k < bloqueosNumPreg.length; k++) {
                pregAsignadas = pregAsignadas + parseInt(bloqueosNumPreg[k].numPreg)
              }
            }

            for (let i = 0; i < preguntas.filter(p => p.bloqueado == false).length; i++) {

              if (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length > 0) {
                totalPregDisponibles = totalPregDisponibles + preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length
              }
            }

          } else {

            for (let k = 0; k < preguntas.length; k++) {
              pregAsignadas = pregAsignadas + parseInt(preguntas[k].numPreg)
            }

            for (let i = 0; i < preguntas.filter(p => p.bloqueado == false).length; i++) {
              if (
                (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length - preguntas.filter(p => p.bloqueado == false)[i].numPreg) > 0
              ) {
                totalPregDisponibles = totalPregDisponibles + (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length - preguntas.filter(p => p.bloqueado == false)[i].numPreg)
              }

            }

          }


          pregTotal = parseInt(pregTotal - pregAsignadas)

          if (pregTotal < 0 && pregAsignadas > 0 && iteracionReparto == 0) {

            await this.props.genTestEdtNumPregTotal(pregAsignadas, loca, 'tema')
            this.setState({ genTestInfo: this.props.genTestInfo })
            this.repartoDePreguntas(this.state.temaActivo, 'tema')
            break;
          }


          if (iteracionReparto == 0) {
            totalPregDisponibles = parseInt(totalPregDisponibles) + parseInt(pregAsignadas)
          }



          if (pregTotal <= totalPregDisponibles && pregTotal > 0 && totalPregDisponibles > 0 && sobran >= 0) {

            // redristibuir total 25 -10 --> 

            let totalIntervalos = await preguntas.filter(p => p.bloqueado == false).length

            if (totalIntervalos > 0) {
              let ajusteAk = 0
              let totalPregPorIntervalo = await pregTotal / totalIntervalos

              if (iteracionReparto > 0) {
                totalIntervalos = await preguntas.filter(p => p.bloqueado == false && p.numPreg < p.preguntasDisponibles.length).length


                if (totalIntervalos == 0) {

                  if (this.props.tipoUser > 50) { swal('Sobrán ' + sobran + ' preguntas', 'Auto ajustando total de preguntas en ' + pregTotalDisponibles + '.', 'info') }

                  // ajustamos el total a las preguntas asignadas
                  sobran = 0
                  await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'tema')
                  this.setState({ genTestInfo: this.props.genTestInfo })

                  break;
                }
                totalPregPorIntervalo = pregTotal / totalIntervalos

              }



              let totalPregResto = 0
              if (totalPregPorIntervalo - Math.floor(totalPregPorIntervalo) == 0) {
                totalPregResto = 0
              } else {
                totalPregResto = 1
                sobran = sobran + 1
                totalPregPorIntervalo = Math.trunc(totalPregPorIntervalo)
              }

              // COMENZAMOS REPARTO ITERADO
              let preguntasOrdenadas = await preguntas.sort((a, b) => b.preguntasDisponibles.length - a.preguntasDisponibles.length)

              for (let j = 0; j < preguntasOrdenadas.length; j++) {
                let pregAsignarIntervalo
                if (j == 0 && totalPregResto == 1) {
                  pregAsignarIntervalo = totalPregPorIntervalo + 1
                } else {
                  pregAsignarIntervalo = totalPregPorIntervalo
                }

                if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1 && iteracionReparto == 0) {
                  if (ajusteAk < pregTotal) {
                    pregAsignarIntervalo = 1
                  } else {
                    pregAsignarIntervalo = 0
                  }
                }

                if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1 && iteracionReparto > 0) {
                  if (ajusteAk < pregTotal) {
                    pregAsignarIntervalo = 1
                  } else {
                    pregAsignarIntervalo = 0
                  }
                }

                if (iteracionReparto == 0) {


                  if (preguntasOrdenadas[j].preguntasDisponibles.length >= pregAsignarIntervalo) {
                    // guardar numPreg
                    let value = pregAsignarIntervalo
                    // Si valor fijo de preguntas poner value tal cual y quitar del total
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', value)
                      pregAsignadas = pregAsignadas + pregAsignarIntervalo
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }

                  } else {
                    // poner maximo
                    // Y sumar las otras a las siguientes
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = preguntasOrdenadas[j].preguntasDisponibles.length
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                      pregAsignadas = pregAsignadas + (pregAsignarIntervalo - preguntasOrdenadas[j].preguntasDisponibles.length)
                      sobran = sobran + (pregAsignarIntervalo - preguntasOrdenadas[j].preguntasDisponibles.length)
                    }

                  }
                } else {
                  if ((preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg) >= pregAsignarIntervalo) {
                    // guardar numPreg
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = pregAsignarIntervalo + preguntasOrdenadas[j].numPreg
                      // Si valor fijo de preguntas poner value tal cual y quitar del total

                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      sobran = sobran - pregAsignarIntervalo
                      pregAsignadas = pregAsignadas + pregAsignarIntervalo
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }
                  } else if ((preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg) > 0) {
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = preguntasOrdenadas[j].numPreg + (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)

                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      sobran = sobran - (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)
                      pregAsignadas = pregAsignadas + (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }
                  }

                }
              }
            } else {
              if (this.props.tipoUser > 50) {
                swal('Error', 'Todos los intervalos estan bloqueados, no se puede repartir preguntas en los intervalos bloqueado.', 'info')
              }
            }



          } else {
            if (pregTotal == 0 && pregAsignadas == this.props.genTestInfo.temasTestG[loca].numPreg) {

              if (pregAsignadas > 0 && iteracionReparto == 0) {

                let totalPregBloqueadas = 0
                for (let j = 0; j < preguntas.length; j++) {
                  let pregAsignarIntervalo = 0
                  if (preguntas[j].bloqueado == false) {
                    let idKey = j
                    await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', pregAsignarIntervalo)
                    this.setState({ genTestInfo: this.props.genTestInfo })
                  }

                  if (preguntas[j].bloqueado == true) {
                    totalPregBloqueadas = preguntas[j].numPreg

                  }

                }
                if (totalPregBloqueadas > this.props.genTestInfo.temasTestG[loca].numPreg) {
                  await this.props.genTestEdtNumPregTotal(totalPregBloqueadas, loca, 'tema')
                  this.setState({ genTestInfo: this.props.genTestInfo })
                }
                sobran = 0
              } else {
                sobran = 0
              }
              //break;
            } else if ((pregTotal > totalPregDisponibles || totalPregDisponibles == 0 || pregTotal == 0) && sobran >= 0) {


              if (pregTotal > 0) {
                if (this.props.tipoUser > 50) {
                  swal('Auto asignando total de preguntas.', 'No puede usar más de ' + pregTotalDisponibles + ' preguntas. Añada más intervalos o edite los existentes.', 'info')
                }
                await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'tema')
                this.setState({ genTestInfo: this.props.genTestInfo })

                this.repartoDePreguntas(this.state.temaActivo, 'tema')

              } else {

                if (pregAsignadas > 0 && iteracionReparto == 0 && pregTotal == 0) {
                  // if (pregAsignadas > 0 && iteracionReparto == 0) {
                  let totalPregBloqueadas = 0
                  for (let j = 0; j < preguntas.length; j++) {
                    let pregAsignarIntervalo = 0
                    if (preguntas[j].bloqueado == false) {
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'tema', idKey, 'numPreg', pregAsignarIntervalo)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                    }

                    if (preguntas[j].bloqueado == true) {
                      totalPregBloqueadas = preguntas[j].numPreg

                    }

                  }
                  if (totalPregBloqueadas > this.props.genTestInfo.temasTestG[loca].numPreg) {
                    await this.props.genTestEdtNumPregTotal(totalPregBloqueadas, loca, 'tema')
                    this.setState({ genTestInfo: this.props.genTestInfo })
                  }
                  sobran = 0
                } else {
                  if (this.props.tipoUser > 50) {
                    swal('Auto asignando total de preguntas.', 'No puede usar más de ' + pregTotalDisponibles + ' preguntas. Añada más intervalos o edite los existentes.', 'info')
                  }
                  await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'tema')
                  this.setState({ genTestInfo: this.props.genTestInfo })


                }

              }

            } else {
              if (sobran < 0) {
                break;
              }
              sobran = 0
            }
          }

          iteracionReparto = iteracionReparto + 1
          if (iteracionReparto == 50) {
            sobran = 0
          }

          sobran = this.props.genTestInfo.temasTestG[loca].numPreg - pregAsignadas
          if (sobran == 0 && (pregAsignadas) < this.props.genTestInfo.temasTestG[loca].numPreg) {
            let asigNas = parseInt(pregAsignadas)
            sobran = await this.props.genTestInfo.temasTestG[loca].numPreg - asigNas;

          }
          if (pregAsignadas == this.props.genTestInfo.temasTestG[loca].numPreg && sobran != 0) {
            sobran = 0
          }
        } while (sobran != 0);
      } else {
        if (this.props.tipoUser > 50) {
          swal('Error', 'Los intervalos de leyes no tienen disponible ninguna pregunta.', 'info')
        }
      }


    } else if (loca != null && tipo == 'art') {
      //let loca = this.state.temaActivo
      let preguntas = this.props.genTestInfo.intervalosTestG[loca].interval



      if (preguntas != undefined && preguntas.length > 0 && preguntas.filter(p => p.preguntasDisponibles.length > 0).length > 0) {

        let iteracionReparto = 0
        let sobran = 0
        do {
          let pregTotal = this.props.genTestInfo.intervalosTestG[loca].numPreg

          let totalPregDisponibles = 0
          let pregAsignadas = 0
          let pregTotalDisponibles = 0

          for (let l = 0; l < preguntas.filter(p => p.bloqueado == false).length; l++) {

            if (preguntas.filter(p => p.bloqueado == false)[l].preguntasDisponibles.length > 0) {
              pregTotalDisponibles = pregTotalDisponibles + preguntas.filter(p => p.bloqueado == false)[l].preguntasDisponibles.length
            }


          }

          for (let l = 0; l < preguntas.filter(p => p.bloqueado == true).length; l++) {

            if (preguntas.filter(p => p.bloqueado == true)[l].preguntasDisponibles.length > 0) {
              if (preguntas.filter(p => p.bloqueado == true)[l].numPreg != '' && parseInt(preguntas.filter(p => p.bloqueado == true)[l].numPreg) <= preguntas.filter(p => p.bloqueado == true)[l].preguntasDisponibles.length) {
                pregTotalDisponibles = pregTotalDisponibles + parseInt(preguntas.filter(p => p.bloqueado == true)[l].numPreg)
              } else {
                if (this.props.tipoUser > 50) {
                  swal('Error intervalo bloqueado', 'No escoja más preguntas de las disponibles por intervalo', 'info')
                }

              }
            }

          }

          if (iteracionReparto == 0) {

            let bloqueosNumPreg = preguntas.filter(p => p.bloqueado == true)
            if (bloqueosNumPreg.length > 0) {
              for (let k = 0; k < bloqueosNumPreg.length; k++) {
                pregAsignadas = pregAsignadas + parseInt(bloqueosNumPreg[k].numPreg)
              }
            }

            for (let i = 0; i < preguntas.filter(p => p.bloqueado == false).length; i++) {

              if (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length > 0) {
                totalPregDisponibles = totalPregDisponibles + preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length
              }
            }

          } else {

            for (let k = 0; k < preguntas.length; k++) {
              pregAsignadas = pregAsignadas + parseInt(preguntas[k].numPreg)
            }

            for (let i = 0; i < preguntas.filter(p => p.bloqueado == false).length; i++) {
              if (
                (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length - preguntas.filter(p => p.bloqueado == false)[i].numPreg) > 0
              ) {
                totalPregDisponibles = totalPregDisponibles + (preguntas.filter(p => p.bloqueado == false)[i].preguntasDisponibles.length - preguntas.filter(p => p.bloqueado == false)[i].numPreg)
              }

            }

          }


          pregTotal = parseInt(pregTotal - pregAsignadas)

          if (pregTotal < 0 && pregAsignadas > 0 && iteracionReparto == 0) {

            await this.props.genTestEdtNumPregTotal(pregAsignadas, loca, 'art')
            this.setState({ genTestInfo: this.props.genTestInfo })
            this.repartoDePreguntas(this.state.artActivo, 'art')
            break;
          }


          if (iteracionReparto == 0) {
            totalPregDisponibles = parseInt(totalPregDisponibles) + parseInt(pregAsignadas)
          }

          if (pregTotal <= totalPregDisponibles && pregTotal > 0 && totalPregDisponibles > 0 && sobran >= 0) {

            // redristibuir total 25 -10 --> 

            let totalIntervalos = await preguntas.filter(p => p.bloqueado == false).length

            if (totalIntervalos > 0) {
              let ajusteAk = 0
              let totalPregPorIntervalo = await pregTotal / totalIntervalos

              if (iteracionReparto > 0) {
                totalIntervalos = await preguntas.filter(p => p.bloqueado == false && p.numPreg < p.preguntasDisponibles.length).length


                if (totalIntervalos == 0) {
                  if (this.props.tipoUser > 50) {
                    swal('Sobrán ' + sobran + ' preguntas', 'Auto ajustando total de preguntas en ' + pregTotalDisponibles + '.', 'info')
                  }
                  // ajustamos el total a las preguntas asignadas
                  sobran = 0
                  await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'art')
                  this.setState({ genTestInfo: this.props.genTestInfo })

                  break;
                }
                totalPregPorIntervalo = pregTotal / totalIntervalos

              }



              let totalPregResto = 0
              if (totalPregPorIntervalo - Math.floor(totalPregPorIntervalo) == 0) {
                totalPregResto = 0
              } else {
                totalPregResto = 1
                sobran = sobran + 1
                totalPregPorIntervalo = Math.trunc(totalPregPorIntervalo)
              }

              // COMENZAMOS REPARTO ITERADO
              let preguntasOrdenadas = await preguntas.sort((a, b) => b.preguntasDisponibles.length - a.preguntasDisponibles.length)

              for (let j = 0; j < preguntasOrdenadas.length; j++) {
                let pregAsignarIntervalo
                if (j == 0 && totalPregResto == 1) {
                  pregAsignarIntervalo = totalPregPorIntervalo + 1
                } else {
                  pregAsignarIntervalo = totalPregPorIntervalo
                }

                if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1 && iteracionReparto == 0) {
                  if (ajusteAk < pregTotal) {
                    pregAsignarIntervalo = 1
                    //ajusteAk = ajusteAk + 1
                  } else {
                    pregAsignarIntervalo = 0
                  }
                }

                if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1 && iteracionReparto > 0) {
                  if (ajusteAk < pregTotal) {
                    pregAsignarIntervalo = 1
                    //ajusteAk = ajusteAk + 1
                  } else {
                    pregAsignarIntervalo = 0
                  }
                }

                if (iteracionReparto == 0) {


                  if (preguntasOrdenadas[j].preguntasDisponibles.length >= pregAsignarIntervalo) {
                    // guardar numPreg
                    let value = pregAsignarIntervalo
                    // Si valor fijo de preguntas poner value tal cual y quitar del total
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', value)
                      pregAsignadas = pregAsignadas + pregAsignarIntervalo
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }

                  } else {
                    // poner maximo
                    // Y sumar las otras a las siguientes
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = preguntasOrdenadas[j].preguntasDisponibles.length
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                      pregAsignadas = pregAsignadas + (pregAsignarIntervalo - preguntasOrdenadas[j].preguntasDisponibles.length)
                      sobran = sobran + (pregAsignarIntervalo - preguntasOrdenadas[j].preguntasDisponibles.length)
                    }

                  }
                } else {
                  if ((preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg) >= pregAsignarIntervalo) {
                    // guardar numPreg
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = pregAsignarIntervalo + preguntasOrdenadas[j].numPreg
                      // Si valor fijo de preguntas poner value tal cual y quitar del total

                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      sobran = sobran - pregAsignarIntervalo
                      pregAsignadas = pregAsignadas + pregAsignarIntervalo
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }
                  } else if ((preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg) > 0) {
                    if (preguntasOrdenadas[j].bloqueado == false) {
                      let value = preguntasOrdenadas[j].numPreg + (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)

                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', value)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                      sobran = sobran - (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)
                      pregAsignadas = pregAsignadas + (preguntasOrdenadas[j].preguntasDisponibles.length - preguntasOrdenadas[j].numPreg)
                      if (Math.trunc(totalPregPorIntervalo) == 0 && totalPregResto == 1) {
                        ajusteAk = ajusteAk + 1
                      }
                    }
                  }

                }
              }
            } else {
              if (this.props.tipoUser > 50) {
                swal('Error', 'Todos los intervalos estan bloqueados, no se puede repartir preguntas en los intervalos bloqueado.', 'info')
              }
            }



          } else {
            if (pregTotal == 0 && pregAsignadas == this.props.genTestInfo.intervalosTestG[loca].numPreg) {
              //swal('Reparto completado','','info')
              //sobran = 0
              if (pregAsignadas > 0 && iteracionReparto == 0) {

                let totalPregBloqueadas = 0
                for (let j = 0; j < preguntas.length; j++) {
                  let pregAsignarIntervalo = 0
                  if (preguntas[j].bloqueado == false) {
                    let idKey = j
                    await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', pregAsignarIntervalo)
                    this.setState({ genTestInfo: this.props.genTestInfo })
                  }

                  if (preguntas[j].bloqueado == true) {
                    totalPregBloqueadas = preguntas[j].numPreg

                  }

                }
                if (totalPregBloqueadas > this.props.genTestInfo.intervalosTestG[loca].numPreg) {
                  await this.props.genTestEdtNumPregTotal(totalPregBloqueadas, loca, 'art')
                  this.setState({ genTestInfo: this.props.genTestInfo })
                }
                sobran = 0
              } else {
                sobran = 0
              }
              //break;
            } else if ((pregTotal > totalPregDisponibles || totalPregDisponibles == 0 || pregTotal == 0) && sobran >= 0) {


              if (pregTotal > 0) {
                if (this.props.tipoUser > 50) {
                  swal('Auto asignando total de preguntas.', 'No puede usar más de ' + pregTotalDisponibles + ' preguntas. Añada más intervalos o edite los existentes.', 'info')
                }

                await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'art')
                this.setState({ genTestInfo: this.props.genTestInfo })

                this.repartoDePreguntas(this.state.artActivo, 'art')

              } else {

                if (pregAsignadas > 0 && iteracionReparto == 0 && pregTotal == 0) {
                  // if (pregAsignadas > 0 && iteracionReparto == 0) {
                  let totalPregBloqueadas = 0
                  for (let j = 0; j < preguntas.length; j++) {
                    let pregAsignarIntervalo = 0
                    if (preguntas[j].bloqueado == false) {
                      let idKey = j
                      await this.props.genTestEdtIntervalo(loca, 'art', idKey, 'numPreg', pregAsignarIntervalo)
                      this.setState({ genTestInfo: this.props.genTestInfo })
                    }

                    if (preguntas[j].bloqueado == true) {
                      totalPregBloqueadas = preguntas[j].numPreg

                    }

                  }
                  if (totalPregBloqueadas > this.props.genTestInfo.intervalosTestG[loca].numPreg) {
                    await this.props.genTestEdtNumPregTotal(totalPregBloqueadas, loca, 'art')
                    this.setState({ genTestInfo: this.props.genTestInfo })
                  }
                  sobran = 0
                } else {
                  if (this.props.tipoUser > 50) {
                    swal('Auto asignando total de preguntas.', 'No puede usar más de ' + pregTotalDisponibles + ' preguntas. Añada más intervalos o edite los existentes.', 'info')
                  }

                  await this.props.genTestEdtNumPregTotal(pregTotalDisponibles, loca, 'art')
                  this.setState({ genTestInfo: this.props.genTestInfo })


                }

              }

            } else {
              if (sobran < 0) {
                break;
              }
              sobran = 0
            }
          }

          iteracionReparto = iteracionReparto + 1
          if (iteracionReparto == 50) {
            sobran = 0
          }

          sobran = this.props.genTestInfo.intervalosTestG[loca].numPreg - pregAsignadas
          if (sobran == 0 && (pregAsignadas) < this.props.genTestInfo.intervalosTestG[loca].numPreg) {
            let asigNas = parseInt(pregAsignadas)
            sobran = await this.props.genTestInfo.intervalosTestG[loca].numPreg - asigNas;

          }
          if (pregAsignadas == this.props.genTestInfo.intervalosTestG[loca].numPreg && sobran != 0) {
            sobran = 0
          }
        } while (sobran != 0);
      } else {
        if (this.props.tipoUser > 50) {
          swal('Error', 'Los intervalos de leyes no tienen disponible ninguna pregunta.', 'info')
        }
      }


    }
  }
  async handleOnSubmit(e) {

    if (e.target.id == 'genTestTemas') {
      if (this.props.tipoUser < 50) {
        // calcular cuanto test a generado estos 2 ultimos dias.
        let data1 = { idUser: this.props.idUser, fechaHoyMenos2: moment().subtract(2, 'd').format('YYYY-MM-DD') }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/testRealizosAutoAlumno", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        // if (cls1.length == 0) {
        if (this.props.genTestInfo == null) {
          let genTestInfoNew = {
            nameTest: '',
            numPreg: '',
            testOposicion: [],
            repetirPreguntas: 0,
            clasesTestExp: [],
            temasTestG: [],
            intervalosTestG: [],
            preguntasExcluirExpediente: [],
            tiempoExclusionPreguntas: '',
            continuar: 0
          }
          await this.props.cargarGenTest(genTestInfoNew)
          this.setState({ genTestInfo: genTestInfoNew })

        }

        this.setState({ generador: 'temas' })
        swal('Generado de test - Gratis*', '*Actualmente dispone de la version de prueba para generar test por temas. En un futuro aparecerá la suscripcion mensual para esta sección.', 'info')
        // } else {

        //   swal('No disponible', 'Solo puede generar 1 test cada 2 días. Podrá generar un test a partir del día: ' + moment(cls1[cls1.length - 1].fecha).add(2, 'd').format('DD') + ' de ' + moment().month(
        //     moment(cls1[cls1.length - 1].fecha).subtract(1, 'M').format('MM')
        //   ).format("MMMM") + ' del ' + moment(cls1[cls1.length - 1].fecha).add(2, 'd').format('YYYY') + '.', 'info')
        // }
      } else {
        if (this.props.genTestInfo == null) {
          let genTestInfoNew = {
            nameTest: '',
            numPreg: '',
            testOposicion: [],
            repetirPreguntas: 0,
            clasesTestExp: [],
            temasTestG: [],
            intervalosTestG: [],
            preguntasExcluirExpediente: [],
            tiempoExclusionPreguntas: '',
            continuar: 0
          }
          await this.props.cargarGenTest(genTestInfoNew)
          this.setState({ genTestInfo: genTestInfoNew })
        }

        this.setState({ generador: 'temas' })
      }
      // if (this.props.tipoUser >= 50 || this.props.testJusticiaUserLoad.length == 0) {


      //new Audio(xuaaaan).play();

      // } else {
      //   swal('Error', 'Para generar un test, debe finalizar antes el test pendiente. Puede darle a continuar y finalizarlo.', 'warning')
      // }


    } else if (e.target.id == 'generarTest') {

      if (this.state.generador == 'temas') {

        if ((this.props.genTestInfo.nameTest != '' && this.props.genTestInfo.testOposicion.filter(t => t.id != null).length > 0) || this.props.tipoUser < 50) {
          // cargar total de prguntas
          let totalPreg = 0
          let totalPregTem = 0
          let totalPregInt = 0

          for (let i = 0; i < this.props.genTestInfo.temasTestG.length; i++) {

            if (this.props.genTestInfo.temasTestG[i].numPreg != 0 && this.props.genTestInfo.temasTestG[i].numPreg != '') {
              totalPreg = totalPreg + parseInt(this.props.genTestInfo.temasTestG[i].numPreg)
              totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[i].numPreg)
            }
          }

          for (let j = 0; j < this.props.genTestInfo.intervalosTestG.length; j++) {

            if (this.props.genTestInfo.intervalosTestG[j].numPreg != 0 && this.props.genTestInfo.intervalosTestG[j].numPreg != '') {
              totalPreg = totalPreg + parseInt(this.props.genTestInfo.intervalosTestG[j].numPreg)
              totalPregInt = totalPregInt + parseInt(this.props.genTestInfo.intervalosTestG[j].numPreg)
            }
          }
          if (totalPreg > 0) {
            await this.props.genTestEdtTotalPregAll(totalPreg)
            this.setState({ generador: 'confirmarGenTest', genTestInfo: this.props.genTestInfo, totalPregTemas: totalPregTem, totalPregInterval: totalPregInt })
          } else {
            swal('Error', 'Debe indicar algun tema o intervalo para generar un test', 'info')
          }

        } else {
          swal('Error', 'Indique una o varias oposiciones así como un nombre al test.', 'info')
        }

      } else if (this.state.generador == 'confirmarGenTest') {
        await this.props.genTestEdtTotalPregAll('')
        this.setState({ generador: 'temas', genTestInfo: this.props.genTestInfo, totalPregTemas: 0, totalPregInterval: 0 })
      }

    } else if (e.target.id == 'cleanTestTemas') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, los datos del generador de test serán eliminados.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            if (this.props.tipoUser >= 50 || this.props.testJusticiaUserLoad.length == 0) {

              let genTestInfoNew = {
                nameTest: '',
                numPreg: '',
                testOposicion: [],
                repetirPreguntas: 0,
                clasesTestExp: [],
                temasTestG: [],
                intervalosTestG: [],
                preguntasExcluirExpediente: [],
                tiempoExclusionPreguntas: '',
                continuar: 0
              }
              await this.props.cargarGenTest(genTestInfoNew)
              this.setState({ genTestInfo: genTestInfoNew, visualizar: 0, temaActivo: 0, artActivo: null, modeTest: 'home', generador: 'temas' })



              //new Audio(xuaaaan).play();

            } else {
              swal('Error', 'Para generar un test, debe finalizar antes el test pendiente. Puede darle a continuar y finalizarlo.', 'warning')
            }
          }
        });



    } else if (e.target.id == "addOpoTest") {

      await this.props.genTestAddOpo()
      this.setState({ genTestInfo: this.props.genTestInfo })
    } else if (e.target.id.split('-')[0] == "delOpoTest") {
      let idKey = e.target.id.split('-')[1]
      //  $('#celdaOpo'+idKey).animate({width:'toggle'},800);
      await this.props.genTestDelOpo(idKey)
      this.setState({ genTestInfo: this.props.genTestInfo })
    } else if (e.target.id == 'repetirPreg') {
      let rep = await this.props.genTestInfo.repetirPreguntas == 0 ? (1) : (0)

      if (this.props.tipoUser > 50) {
        await this.props.genTestEdtRepetirPreg(rep)
        this.setState({ genTestInfo: this.props.genTestInfo })
      } else {
        await this.props.genTestEdtRepetirPreg(rep)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }





    } else if (e.target.id == "addClaseExp") {
      await this.props.genTestAddClase()
      this.setState({ genTestInfo: this.props.genTestInfo })
    } else if (e.target.id.split('-')[0] == "delClaseTest") {

      let idKey = e.target.id.split('-')[1]

      let clasesIds = []
      if (this.props.genTestInfo.clasesTestExp.filter(c => c.id != null && this.props.genTestInfo.clasesTestExp[idKey].id != c.id).length > 0) {
        for (let i = 0; i < this.props.genTestInfo.clasesTestExp.filter(c => c.id != null && this.props.genTestInfo.clasesTestExp[idKey].id != c.id).length; i++) {
          clasesIds.push({ idClase: this.props.genTestInfo.clasesTestExp[i].id })
        }
      }

      if (clasesIds.length > 0) {
        let totalMeses = this.props.genTestInfo.tiempoExclusionPreguntas
        let desde = 0
        totalMeses == '1 mes' ? desde = 1 : null
        totalMeses.split('meses').length > 0 ? desde = parseInt(totalMeses.split('meses')[0]) : null
        if (totalMeses == "Todo el tiempo") { desde = 48 }

        if (totalMeses != '' && totalMeses != undefined && totalMeses != null && desde != 0) {

          let fecha = moment().subtract(desde, 'months').format('YYYY-MM-DD')
          let data1 = {
            idClases: clasesIds,
            fecha: fecha
          }
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/expClaseTemporalizadoPregTestTeoClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          await this.props.genTestDelClase(idKey, cls1)
          this.setState({ genTestInfo: this.props.genTestInfo })

        } else {
          await this.props.genTestDelClase(idKey, null)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }
      } else {
        await this.props.genTestDelClase(idKey, null)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }



    } else if (e.target.id == "addTemaTestGen") {

      // FILTRAR PARA ADMIN O USUARIO

      // USUARIO VER LOS TEMAS FACTURADOS Y SI NO HA DADO 50 clases quitar los temas del primer bloque de la ultima semana cargada.
      // cargar los temas facturados filtrados 
      // No pueden generar un test de los temas nuevos que aun no han visto en clase, o realizado el test.
      // Si la fecha de esta clase es 7 días anterior a la actual.
      if (this.props.genTestInfo.temasTestG.length == 0 || (this.props.genTestInfo.temasTestG.length > 0 && this.props.genTestInfo.temasTestG.filter(t => t.id == null).length == 0)) {


        if (this.props.tipoUser < 50) {
          // if (this.props.genTestInfo.temasTestG.length < 4) {
          let temasFacturados = this.props.temasFacturados

          let semanasFacturadas = this.props.semanasFacturadas //coge las 2 ultiumas clases!
          let totalClasesVistas = this.props.allSemFac != null ? (this.props.allSemFac.length) : (null)

          let idOpo = this.props.idOposicion
          if (semanasFacturadas != null && ((idOpo >= 8 && totalClasesVistas < 30) || totalClasesVistas <= 50)) {
            let semana = semanasFacturadas[semanasFacturadas.length - 1]
            let temasToLoad = []
            let ahora = moment().format('YYYY-MM-DD HH:mm')
            let fechaUltimaClaseAdd7 = moment(semana.fechaClase).add(7, 'd').format('YYYY-MM-DD HH:mm')

            if (ahora < fechaUltimaClaseAdd7) {
              for (let j = 0; j < semana.temas.length; j++) {
                let idTemaNover = semana.temas[j].idTema
                temasFacturados = await temasFacturados.filter(t => t.idTema != idTemaNover)
              }
            }

            for (let x = 0; x < temasFacturados.length; x++) {
              temasToLoad.push({
                categoria: temasFacturados[x].categoria,
                id_oposicion: idOpo,
                id_tema: temasFacturados[x].idTema,
                titulo_tema: temasFacturados[x].titulo_tema,
                nombre_tema: temasFacturados[x].nombre_tema
              })

            }

            await this.props.genTestAddTema()
            this.setState({ temaActivo: this.props.genTestInfo.temasTestG.length - 1, artActivo: null, genTestInfo: this.props.genTestInfo })

            let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
            let idKey = await this.props.genTestInfo.temasTestG.length - 1
            await this.props.genTestEdtTemaOpo(idKey, opoSeleted, temasToLoad)
            this.setState({ genTestInfo: this.props.genTestInfo })

          } else {
            // meter todos los temas a visto muchas clases.
            let temasToLoad = []

            for (let x = 0; x < temasFacturados.length; x++) {
              temasToLoad.push({
                categoria: temasFacturados[x].categoria,
                id_oposicion: idOpo,
                id_tema: temasFacturados[x].idTema,
                titulo_tema: temasFacturados[x].titulo_tema,
                nombre_tema: temasFacturados[x].nombre_tema
              })

            }
            await this.props.genTestAddTema()
            this.setState({ temaActivo: this.props.genTestInfo.temasTestG.length - 1, artActivo: null, genTestInfo: this.props.genTestInfo })

            let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
            let idKey = await this.props.genTestInfo.temasTestG.length - 1
            await this.props.genTestEdtTemaOpo(idKey, opoSeleted, temasToLoad)
            this.setState({ genTestInfo: this.props.genTestInfo })


          }

          // } else {
          //   swal('Solo puede usar 4 temas por test', '', 'info')
          // }

        } else {
          await this.props.genTestAddTema()
          this.setState({ temaActivo: this.props.genTestInfo.temasTestG.length - 1, artActivo: null, genTestInfo: this.props.genTestInfo })
        }
      } else {
        swal('Error', 'Seleccione un tema para añadir otro. Puede navegar entre los temas disponibles.', 'info')
      }



    } else if (e.target.id == "deltemasTest") {

      let idKey = this.state.temaActivo
      await this.props.genTestDelTema(idKey)

      this.setState({ genTestInfo: this.props.genTestInfo, temaActivo: this.props.genTestInfo.temasTestG.length - 1, artActivo: null })

      if (this.props.tipoUser < 50) {
        await this.repartoPreguntasProporcional()
      }
    } else if (e.target.id.split('-')[0] == "temaVer") {
      let idKey = e.target.id.split('-')[1]
      this.setState({ temaActivo: idKey, artActivo: null })
      this.setState({ preguntasLoadView: null, posPLVInter: null, verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
    } else if (e.target.id == "addIntervaloTest") {

      if (this.props.genTestInfo.intervalosTestG.length > 0) {
        if (this.props.genTestInfo.intervalosTestG[this.props.genTestInfo.intervalosTestG.length - 1].interval.length > 0 && this.props.genTestInfo.intervalosTestG[this.props.genTestInfo.intervalosTestG.length - 1].interval.filter(i => i.preguntasDisponibles.length > 0).length > 0) {
          await this.props.genTestAddInt()
          this.setState({ genTestInfo: this.props.genTestInfo, temaActivo: null, artActivo: this.props.genTestInfo.intervalosTestG.length - 1 })
        } else {
          if (this.props.genTestInfo.intervalosTestG[this.props.genTestInfo.intervalosTestG.length - 1].interval.length == 0) {
            swal('Error', 'No tiene ningun intervalo en la ultima sección de intervalos', 'info')
          } else if (this.props.genTestInfo.intervalosTestG[this.props.genTestInfo.intervalosTestG.length - 1].interval.filter(i => i.preguntasDisponibles.length > 0).length == 0) {
            swal('Error', 'En la ultima sección de intervalo, no tiene disponible ningun pregunta añada o edite los articulos.', 'info')
          }

        }
      } else {
        await this.props.genTestAddInt()
        this.setState({ genTestInfo: this.props.genTestInfo, temaActivo: null, artActivo: this.props.genTestInfo.intervalosTestG.length - 1 })
      }

    } else if (e.target.id.split('-')[0] == "artVer") {
      let idKey = e.target.id.split('-')[1]
      this.setState({ temaActivo: null, artActivo: idKey })
      this.setState({ preguntasLoadView: null, posPLVInter: null, verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
    } else if (e.target.id == "addInterval") {
      if (this.state.temaActivo != null && this.state.artActivo == null) {
        await this.props.genTestAddIntervalo(this.state.temaActivo, 'tema')
        this.setState({ genTestInfo: this.props.genTestInfo })
      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        await this.props.genTestAddIntervalo(this.state.artActivo, 'art')
        this.setState({ genTestInfo: this.props.genTestInfo })
      }

    } else if (e.target.id == "eliminarInterval") {
      if (this.state.temaActivo == null && this.state.artActivo != null) {
        await this.props.genTestDelIntervalos(this.state.artActivo, 'art')

        this.setState({ genTestInfo: this.props.genTestInfo, temaActivo: null, artActivo: this.props.genTestInfo.intervalosTestG.length - 1 })


      }
    } else if (e.target.id == 'continuar1') {
      if (this.props.tipoUser >= 50) {
        if (this.props.genTestInfo.testOposicion.filter(o => o.id != null).length > 0) {
          if (this.state.continuar >= 1) {
            this.setState({ visualizar: 1 })
          } else {
            await this.props.genTestContinuar(1);
            this.setState({ visualizar: 1 })
          }
        } else {
          swal('', 'Seleccione una o más oposiciones para continuar. Luego podrá editarlo.', 'info')
        }
      } else {
        if (this.state.continuar >= 1) {
          this.setState({ visualizar: 1 })
        } else {
          await this.props.genTestContinuar(1);
          this.setState({ visualizar: 1 })
        }
      }


    } else if (e.target.id == 'continuar2') {
      if (this.state.continuar >= 2) {
        this.setState({ visualizar: 2 })
      } else {
        await this.props.genTestContinuar(2);
        this.setState({ visualizar: 2 })
      }
    } else if (e.target.id == 'editar0') {
      this.setState({ visualizar: 0 })
    } else if (e.target.id == 'editar1') {
      this.setState({ visualizar: 1 })
    } else if (e.target.id == 'editar2') {
      this.setState({ visualizar: 2 })
    } else if (e.target.id.split('-')[0] == "delIntervalo") {
      let idKey = e.target.id.split('-')[1]
      if (this.state.temaActivo != null && this.state.artActivo == null) {
        await this.props.genTestDelIntervalo(this.state.temaActivo, 'tema', idKey)
        this.setState({ genTestInfo: this.props.genTestInfo })
      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        await this.props.genTestDelIntervalo(this.state.artActivo, 'art', idKey)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }

    } else if (e.target.id.split('-')[0] == "bloqIntervalo") {
      let idKey = e.target.id.split('-')[1]


      if (this.state.temaActivo != null && this.state.artActivo == null) {
        let value = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].bloqueado
        await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'bloqueado', !value, null)
        this.setState({ genTestInfo: this.props.genTestInfo })

      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        let value = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].bloqueado
        await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'bloqueado', !value, null)
        this.setState({ genTestInfo: this.props.genTestInfo })

      }

    } else if (e.target.id.split('-')[0] == "unicoIntervalo") {
      let idKey = e.target.id.split('-')[1]

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        let value = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].unico
        let artInicio = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artIni
        let artFin = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artFin
        let idLey = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].idLey

        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && !value == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'unico', !value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)

                await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'unico', !value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'unico', !value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo o cambiarlo de forma', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        let value = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].unico
        let artInicio = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artIni
        let artFin = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artFin
        let idLey = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].idLey
        let unico = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].unico
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && !value == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'unico', !value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'unico', !value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'unico', !value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo  o cambiarlo de forma', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      }



      // ---------

    } else if (e.target.id.split('-')[0] == "verPreguntasTestTeorico") {
      let position = e.target.id.split('-')[1]
      // let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
      let preguntas = []
      if (this.state.temaActivo != null && this.state.artActivo == null) {
        preguntas = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[position].preguntasDisponibles
      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        preguntas = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[position].preguntasDisponibles
      }
      this.setState({ preguntasLoadView: preguntas, posPLVInter: position })


    } else if (e.target.id.split('-')[0] == "verPreguntaTestTeorico") {
      let position = e.target.id.split('-')[1]
      // let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
      let preguntas = this.state.preguntasLoadView

      if (preguntas.length > 0) {

        // sacar respuestas y formatear sbr!

        let pregunta = preguntas[position]
        if (pregunta.respuestas.length > 0) {
          pregunta.respuestas.length = 0
        }
        let data1 = { idPregunta: pregunta.idPregunta }
        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/resPreg", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let respuestas = await cls1



        if (respuestas.length > 0) {
          for (let i = 0; i < respuestas.length; i++) {
            let rSbr = respuestas[i].respuesta
            // R1 es la respuesta
            if (respuestas[i].subrayado != '' && respuestas[i].subrayado != undefined) {
              rSbr = await respuestaSbr(respuestas[i].respuesta, respuestas[i].subrayado)
            }

            pregunta.respuestas.push({
              idR: respuestas[i].id_respuesta,
              respuesta: rSbr,
              respuestaBse: respuestas[i].respuesta,
              validez: respuestas[i].validez,
              subrayado: respuestas[i].subrayado,
              nota: respuestas[i].nota,
              valorUser: 0
            })

          }

          let preguntaTestLoad = []
          preguntaTestLoad.push({
            idPreg: pregunta.idPregunta,
            pregunta: pregunta.pregunta,
            tipoPregunta: pregunta.tipoPregunta,
            articulo: pregunta.articulo,
            idLey: pregunta.idLey,
            siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == pregunta.idLey).siglas_ley,
            nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == pregunta.idLey).nombre_ley,
            idR1: pregunta.respuestas[0].idR, idR2: pregunta.respuestas[1].idR, idR3: pregunta.respuestas[2].idR, idR4: pregunta.respuestas[3].idR,
            respuestas: pregunta.respuestas
          })




          this.setState({ verPreguntaOnOff: true, preguntaTestLoad: preguntaTestLoad, preguntaViewLocation: position })
        } else {
          this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
        }



      } else {

        this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })

      }

    } else if (e.target.id == 'cerrarPreguntasLoadView') {
      this.setState({ preguntasLoadView: null, posPLVInter: null, verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
    } else if (e.target.id == 'cerrarPreguntaViewLive') {
      this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
    } else if (e.target.id.split('-')[0] == "incluir") {
      let loca = e.target.id.split('-')[1]
      let poscionIntervalo = this.state.posPLVInter
      let preguntasLoadView = this.state.preguntasLoadView

      let value = preguntasLoadView[loca].incluida

      preguntasLoadView[loca].incluida = !value

      if (this.state.temaActivo != null && this.state.artActivo == null) {

        await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', poscionIntervalo, 'incluida', !value, loca)
        this.setState({ preguntasLoadView: preguntasLoadView, genTestInfo: this.props.genTestInfo })


      } else if (this.state.temaActivo == null && this.state.artActivo != null) {

        await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', poscionIntervalo, 'incluida', !value, loca)
        this.setState({ preguntasLoadView: preguntasLoadView, genTestInfo: this.props.genTestInfo, })

      }



    } else if (e.target.id.split('-')[0] == "excluir") {
      let loca = e.target.id.split('-')[1]
      let poscionIntervalo = this.state.posPLVInter
      let preguntasLoadView = this.state.preguntasLoadView

      let value = preguntasLoadView[loca].excluida

      preguntasLoadView[loca].excluida = !value

      if (this.state.temaActivo != null && this.state.artActivo == null) {

        await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', poscionIntervalo, 'excluida', !value, loca)
        this.setState({ preguntasLoadView: preguntasLoadView, genTestInfo: this.props.genTestInfo })


      } else if (this.state.temaActivo == null && this.state.artActivo != null) {

        await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', poscionIntervalo, 'excluida', !value, loca)
        this.setState({ preguntasLoadView: preguntasLoadView, genTestInfo: this.props.genTestInfo, })

      }



    } else if (e.target.id == 'redistribuirPreg') {

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        this.repartoDePreguntas(this.state.temaActivo, 'tema')
      } else if (this.state.temaActivo == null && this.state.artActivo != null) {
        this.repartoDePreguntas(this.state.artActivo, 'art')

      }

    } else if (e.target.id.split('-')[0] == 'nav') {
      let verId = e.target.id.split('-')[1]
      this.setState({ seletedInfo: verId })
    } else if (e.target.id == 'mezclarPreguntas') {
      let rep = this.state.mezclarPreguntas == 0 ? (1) : (0)

      this.setState({ mezclarPreguntas: rep })

    } else if (e.target.id == 'mezclarRespuestas') {
      let rep = this.state.mezclarRespuestas == 0 ? (1) : (0)

      this.setState({ mezclarRespuestas: rep })

    } else if (e.target.id == 'mezclarTemas') {
      let rep = this.state.mezclarTemas == 0 ? (1) : (0)

      this.setState({ mezclarTemas: rep })

    } else if (e.target.id == 'generarTestConfirmado') {
      // agrupar todas las preguntas en un array unico

      let { totalPregTemas, totalPregInterval, mezclarPreguntas, mezclarRespuestas, mezclarTemas } = this.state
      let { genTestInfo } = this.props

      let preguntasPreparadas = []

      if (totalPregTemas > 0) {
        let preguntasFiltradas = genTestInfo.temasTestG.filter(t => t.id != null && t.numPreg != '' && parseInt(t.numPreg) > 0)
        let preguntasFiltradasExp = genTestInfo.temasTestG.filter(t => t.id != null && t.numPreg != '' && parseInt(t.numPreg) > 0)

        // FILTRO DE EXPEDIENTE SACAMOS HASTA QUE SEA POSIBLE.

        if (genTestInfo.preguntasExcluirExpediente.length > 0) {
          for (let k = 0; k < preguntasFiltradasExp.length; k++) {
            for (let g = 0; g < preguntasFiltradasExp[k].interval.length; g++) {
              for (let l = 0; l < preguntasFiltradasExp[k].interval[g].preguntasDisponibles.length; l++) {


                let busqueda = await genTestInfo.preguntasExcluirExpediente.findIndex(p => p.idPreg == preguntasFiltradasExp[k].interval[g].preguntasDisponibles[l].idPregunta)

                if (busqueda != -1) {

                  let busqueda2 = await preguntasFiltradas[k].interval[g].preguntasDisponibles.findIndex(p => p.idPregunta == genTestInfo.preguntasExcluirExpediente[busqueda].idPreg)

                  if (busqueda2 != -1 && preguntasFiltradas[k].interval[g].preguntasDisponibles.length > parseInt(preguntasFiltradas[k].interval[g].numPreg)) {

                    let idP = preguntasFiltradas[k].interval[g].preguntasDisponibles[busqueda2].idPregunta

                    preguntasFiltradas[k].interval[g].preguntasDisponibles = await preguntasFiltradas[k].interval[g].preguntasDisponibles.filter(p => p.idPregunta != idP)


                  } else {
                    if (preguntasFiltradas[k].interval[g].preguntasDisponibles.length < parseInt(preguntasFiltradas[k].interval[g].numPreg && busqueda2 == -1)) {
                      break;
                    }

                  }

                }


              }
            }
          }


        }


        // FIN EXPE FILTRO


        for (let i = 0; i < await preguntasFiltradas.length; i++) {
          for (let j = 0; j < await preguntasFiltradas[i].interval.length; j++) {
            let ordenEstrico = await preguntasFiltradas[i].interval[j].preguntasDisponibles.sort(function () { return Math.random() - 0.7 }).sort(function () { return Math.random() - 0.3 }).sort(function () { return Math.random() - 0.5 }).sort((a, b) => b.incluida - a.incluida)

            //FILTRAR LAS QUE NO SE DEBEN REPETIR SI ES POSIBLE!!!
            let totalExcluir = await preguntasFiltradas[i].interval[j].preguntasDisponibles.filter(p => p.excluida != false).length
            let totalPedimos = parseInt(await preguntasFiltradas[i].interval[j].numPreg)
            let totalPreg = await preguntasFiltradas[i].interval[j].preguntasDisponibles.length
            if (totalExcluir > 0) {
              if (totalExcluir <= (totalPreg - totalPedimos)) {

                ordenEstrico = await preguntasFiltradas[i].interval[j].preguntasDisponibles.filter(p => p.excluida == false).sort(function () { return Math.random() - 0.7 }).sort(function () { return Math.random() - 0.3 }).sort(function () { return Math.random() - 0.5 }).sort((a, b) => b.incluida - a.incluida)

              }
            }
            /* FIN EXCLUIR*/
            // METER PREGUNTA Y COMPROBAR SI YA EXISTE
            let kAlterada = 0
            for (let k = 0; k < parseInt(await preguntasFiltradas[i].interval[j].numPreg); k++) {
              if (ordenEstrico.length > 0 && ordenEstrico.length > k && kAlterada < ordenEstrico.length) {
                if (preguntasPreparadas.filter(p => p.idPregunta == ordenEstrico[kAlterada].idPregunta).length == 0) {
                  preguntasPreparadas.push(await ordenEstrico[kAlterada])
                  kAlterada = kAlterada + 1
                } else {
                  if (ordenEstrico.length > kAlterada) {
                    let metemosOtra = false
                    let nextP = kAlterada + 1
                    do {
                      if (nextP < ordenEstrico.length) {
                        if (preguntasPreparadas.filter(p => p.idPregunta == ordenEstrico[nextP].idPregunta).length == 0) {
                          preguntasPreparadas.push(await ordenEstrico[nextP])
                          metemosOtra = true
                          kAlterada = nextP + 1
                        } else {
                          nextP = nextP + 1
                        }
                      } else {
                        break;
                      }
                    } while (metemosOtra == false && nextP < ordenEstrico.length);
                  } else {
                    break;
                  }
                }
              } else {
                break;
              }
            }


            // if (mezclarPreguntas == 1 && i == (preguntasFiltradas.length - 1)) {

            //   preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.5 })
            //   preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.7 })
            //   preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.5 })
            // }
          }


        }
      }

      if (totalPregInterval > 0) {
        let preguntasFiltradas = genTestInfo.intervalosTestG.filter(t => t.numPreg != '' && parseInt(t.numPreg) > 0)
        for (let i = 0; i < preguntasFiltradas.length; i++) {
          for (let j = 0; j < preguntasFiltradas[i].interval.length; j++) {
            let ordenEstrico = await preguntasFiltradas[i].interval[j].preguntasDisponibles.sort(function () { return Math.random() - 0.7 }).sort(function () { return Math.random() - 0.3 }).sort(function () { return Math.random() - 0.5 }).sort((a, b) => b.incluida - a.incluida)



            //FILTRAR LAS QUE NO SE DEBEN REPETIR SI ES POSIBLE!!!
            let totalExcluir = await preguntasFiltradas[i].interval[j].preguntasDisponibles.filter(p => p.excluida != false).length
            let totalPedimos = parseInt(await preguntasFiltradas[i].interval[j].numPreg)
            let totalPreg = await preguntasFiltradas[i].interval[j].preguntasDisponibles.length
            if (totalExcluir > 0) {
              if (totalExcluir <= (totalPreg - totalPedimos)) {

                ordenEstrico = await preguntasFiltradas[i].interval[j].preguntasDisponibles.filter(p => p.excluida == false).sort(function () { return Math.random() - 0.7 }).sort(function () { return Math.random() - 0.3 }).sort(function () { return Math.random() - 0.5 }).sort((a, b) => b.incluida - a.incluida)

              }
            }
            /* FIN EXCLUIR*/
            // METER PREGUNTA Y COMPROBAR SI YA EXISTE
            let kAlterada = 0
            for (let k = 0; k < parseInt(await preguntasFiltradas[i].interval[j].numPreg); k++) {
              if (ordenEstrico.length > 0 && ordenEstrico.length > k && kAlterada < ordenEstrico.length) {
                if (preguntasPreparadas.filter(p => p.idPregunta == ordenEstrico[kAlterada].idPregunta).length == 0) {
                  preguntasPreparadas.push(await ordenEstrico[kAlterada])
                  kAlterada = kAlterada + 1
                } else {
                  if (ordenEstrico.length > kAlterada) {
                    let metemosOtra = false
                    let nextP = kAlterada + 1
                    do {
                      if (nextP < ordenEstrico.length) {
                        if (preguntasPreparadas.filter(p => p.idPregunta == ordenEstrico[nextP].idPregunta).length == 0) {
                          preguntasPreparadas.push(await ordenEstrico[nextP])
                          metemosOtra = true
                          kAlterada = nextP + 1
                        } else {
                          nextP = nextP + 1
                        }
                      } else {
                        break;
                      }
                    } while (metemosOtra == false && nextP < ordenEstrico.length);
                  } else {
                    break;
                  }
                }
              } else {
                break;
              }
            }

          }
        }
      }
      if (totalPregTemas > 0 || totalPregInterval > 0) {

        preguntasPreparadas = await respuestasPregJusticia(preguntasPreparadas)

        if (mezclarTemas == 1) {

          preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.3 })
          preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.7 })
          preguntasPreparadas = preguntasPreparadas.sort(function () { return Math.random() - 0.5 })

        }
        if (mezclarRespuestas == 1) {
          preguntasPreparadas = await mezclarRespuestasJusticia(preguntasPreparadas)

        }

        for (let js3 = 0; js3 < preguntasPreparadas.length; js3++) {

          preguntasPreparadas[js3].numPreg = js3 + 1

        }

        // claseIdPreparador simula una clase por si el preparador no tiene alguna abierta, los alumnos la tienen si o si.
        let claseIdPreparador = 8
        let idClase = this.props.tipoUser >= 50 ? (claseIdPreparador) : (this.props.idClase)
        let testParaAbrir = await prepararTestModoHacer(preguntasPreparadas, idClase)

        testParaAbrir = await comprobarRespuestas(testParaAbrir)

        if (this.props.tipoUser >= 50) {

          // console.log('preguntasPreparadas')
          // console.log(preguntasPreparadas)

          let infoTT = {
            oposiciones: genTestInfo.testOposicion.filter(t => t.id != null),
            nombreTest: genTestInfo.nameTest
          }
          let name = "¿Qué desea hacer?";
          let content = document.createElement('div');
          content.innerHTML = `El total de preguntas será de: <b>` + preguntasPreparadas.length + `</b> </br></br></br>
        Si es menor que el total de preguntas asignadas, es debido a la eliminacíon de preguntas duplicadas. Así como no dispone de más preguntas en ningun tema o intervalo.`
          content.style.textAlign = "center";

          swal({
            title: name,
            content: content,
            icon: "info",
            buttons: {
              catch: {
                text: "Generar test",
                value: "catch",
              },
              catch3: {
                text: "Abrir test",
                value: "catch3",
              },
              catch2: {
                text: "Generar y abrir",
                value: "catch2",
              },
              defeat: false,
            },
            closeOnEsc: true,
            closeOnClickOutside: true,
          }).then(async (value) => {
            switch (value) {

              case "catch":
                // generamos el test
                generarTestTemplateMultiple(preguntasPreparadas, infoTT)
                await swal({
                  title: "" + infoTT.nombreTest + "",
                  text: "Test Template generado correctamente.",
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 2500,
                }).then(async () => {


                  // borramos info!
                  let genTestInfoNew = {
                    nameTest: '',
                    numPreg: '',
                    testOposicion: [],
                    repetirPreguntas: 0,
                    clasesTestExp: [],
                    temasTestG: [],
                    intervalosTestG: [],
                    preguntasExcluirExpediente: [],
                    tiempoExclusionPreguntas: '',
                    continuar: 0
                  }
                  await this.props.cargarGenTest(genTestInfoNew)
                  this.setState({
                    genTestInfo: genTestInfoNew,

                    mezclarPreguntas: 0,
                    mezclarRespuestas: 0,
                    mezclarTemas: 0,
                    seletedInfo: 0,
                    totalPregTemas: 0,
                    totalPregInterval: 0,
                    visualizar: 0,
                    temaActivo: 0,
                    artActivo: null,
                    modeTest: 'home',
                    generador: null,
                    avisoInterBloqueado: null,
                    preguntasLoadView: null,
                    verPreguntaOnOff: false,
                    preguntaTestLoad: null,
                    posPLVInter: null, preguntaViewLocation: null,
                    play: false,
                    verPreguntaOnOff: false
                  })
                })

                break;
              case "catch2":
                // generamos el test y lo abrimos
                generarTestTemplateMultiple(preguntasPreparadas, infoTT)
                await swal({
                  title: "" + infoTT.nombreTest + "",
                  text: "Test Template generado correctamente.",
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 2500,
                }).then(async () => {

                  await this.props.loadTestUser(testParaAbrir)

                  let genTestInfoNew = {
                    nameTest: '',
                    numPreg: '',
                    testOposicion: [],
                    repetirPreguntas: 0,
                    clasesTestExp: [],
                    temasTestG: [],
                    intervalosTestG: [],
                    preguntasExcluirExpediente: [],
                    tiempoExclusionPreguntas: '',
                    continuar: 0
                  }
                  await this.props.cargarGenTest(genTestInfoNew)
                  this.setState({
                    genTestInfo: genTestInfoNew,
                    mezclarPreguntas: 0,
                    mezclarRespuestas: 0,
                    mezclarTemas: 0,
                    seletedInfo: 0,
                    totalPregTemas: 0,
                    totalPregInterval: 0,
                    visualizar: 0,
                    temaActivo: 0,
                    artActivo: null,
                    modeTest: 'home',
                    generador: null,
                    avisoInterBloqueado: null,
                    preguntasLoadView: null,
                    verPreguntaOnOff: false,
                    preguntaTestLoad: null,
                    posPLVInter: null, preguntaViewLocation: null,
                    play: false,
                    verPreguntaOnOff: false
                  })
                  await this.props.history.push('/hacerTestJusticia');
                })
                break;
              case "catch3":
                // Abrir test!

                if (this.props.testJusticiaUserLoad.length > 0) {
                  await this.props.borrarTestUser()
                }
                await this.props.loadTestUser(testParaAbrir)
                await this.props.history.push('/hacerTestJusticia');
                break;
              default:
                swal.close()
                break;
            }
          });



        } else {

          if (this.props.testJusticiaUserLoad.length > 0) {
            let name = "¡Ojo! - Test sin finalizar";
            let content = document.createElement('div');
            content.innerHTML = `
          Si <b>borras el test, ¡perderás el progreso realizo en dicho test!</b> y se cargará el nuevo test generado. <br />
          Si da clic en <b>Ir a finalizar test</b>, visualizarás el test sin finalizar y podrás guardar el resultado. <br /><br />

          Luego puede volver aquí y generar su test. Los datos para generar el test se guardan.
          `
            content.style.textAlign = "left";

            swal({
              title: name,
              content: content,
              icon: "https://media.giphy.com/media/jn24nXunS0CBWrHtFd/giphy.gif",
              buttons: {
                cancel: "Borrar y cargar test",
                catch: {
                  text: "Ir a finalizar test",
                  value: "catch",
                },
                defeat: false,
              },
              closeOnEsc: false,
              closeOnClickOutside: false,
            }).then(async (value) => {
              switch (value) {

                case "catch":
                  await this.props.history.push('/hacerTestJusticia');
                  break;

                default:
                  await this.props.borrarTestUser()
                  await this.props.loadTestUser(testParaAbrir)
                  await this.props.history.push('/hacerTestJusticia');
              }
            });
          } else {
            let name2 = "¿Generamos el Test?";
            let content2 = document.createElement('div');
            content2.innerHTML = `Puede volver aquí y generar su test más tarde. Los datos para generar el test se guardan.</br></br>
            El total de preguntas será de: <b>`+ preguntasPreparadas.length + `</b>`
            content2.style.textAlign = "center";
            swal({
              title: name2,
              content: content2,
              icon: "info",
              buttons: {
                cancel: "Cancelar",
                catch3: {
                  text: "Generar",
                  value: "catch3",
                },
                defeat: false,
              },
              closeOnEsc: true,
              closeOnClickOutside: true,
            }).then(async (value) => {
              switch (value) {

                case "catch3":
                  if (this.props.testJusticiaUserLoad.length > 0) {
                    await this.props.borrarTestUser()
                  }
                  await this.props.loadTestUser(testParaAbrir)
                  await this.props.history.push('/hacerTestJusticia');
                  break;
                default:
                  swal.close()
                  break;
              }
            });


          }



        }
      } else {
        swal('Error', 'No dispone de ninguna pregunta para realizar un test, agrege un tema o intervalo de leyes.', 'info')
      }

    } else if (e.target.id == 'irAfinalizarTest') {
      this.props.history.push('/hacerTestJusticia');
    } else if (e.target.id == 'addAllTemasEstaOpo') {
      // genTestInfo.temasTestG[temaActivo].idOpo --> opo
      // genTestInfo.temasTestG[temaActivo].nombre --> los temas



      // console.log('temaActivo')
      // console.log(temaActivo)

      // console.log('genTestInfo')
      // console.log(genTestInfo)

      // console.log('genTestInfo.temasTestG')
      // console.log(genTestInfo.temasTestG)

      // console.log('genTestInfo.temasTestG[temaActivo]')
      // console.log(genTestInfo.temasTestG[temaActivo])

      // console.log('genTestInfo.temasTestG[temaActivo].temasLoadOpo')
      // console.log(genTestInfo.temasTestG[temaActivo].temasLoadOpo)




      // 1 Añadir temas sin datos 
      let { temaActivo } = this.state
      let { genTestInfo } = this.props

      let idOpo = genTestInfo.temasTestG[temaActivo].idOpo
      let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
      let temasToLoad = genTestInfo.temasTestG[temaActivo].temasLoadOpo.sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema))

      swal({
        title: 'Insertando temas',
        text: 'Este proceso puede tardar entre 1 a 2 minutos.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })




      for (let j1 = 0; j1 < temasToLoad.length; j1++) {
        // generamos el espacio del tema
        if (j1 != 0) {
          await this.props.genTestAddTema()
        }
      }


      let data23 = { id_oposicion: idOpo }
      let response23 = await fetch("https://oposiciones-justicia.es/api/temas/consultaTemasOpo", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data23)
      });
      if (!response23.ok) {
        throw Error(response23.statusText);
      }
      let json23 = await response23.json();
      let cls23 = await json23.sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema));

      let data1 = {}
      let response1 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrgN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1x2d = await json1;

      for (let index2 = 0; index2 < genTestInfo.temasTestG.length; index2++) {



        if (index2 > 0) {
          let dato = []
          await dato.push(cls23[index2])

          await this.props.genTestEdtTemaOpo(index2, opoSeleted, dato)
        }



        // INICIO --------- insertamos los datos de cada tema en su hueco
        let jx = index2

        // let data1 = { id_tema: temasToLoad[jx].id_tema }
        // let response1 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrg", {
        //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        // });
        // if (!response1.ok) {
        //   throw Error(response1.statusText);
        // }
        // let json1 = await response1.json();
        // let cls1x2d = await json1;

        let cls1 = cls1x2d.filter(p => p.id_tema == temasToLoad[jx].id_tema)
        let intervalos = []

        let preguntasTotales = 100

        // for (let i = 0; i < cls1.length; i++) {
        for (let i = 0; i < cls1.length; i++) {

          if (cls1[i].art_fin != '' && cls1[i].art_fin != '-' && cls1[i].art_fin != '–' && cls1[i].art_fin != ' ' && cls1[i].art_fin.length > 0) {

            let preguntasBusqueda = await selectPreguntasPorArticulosInter(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, cls1[i].art_inicio, cls1[i].art_fin)

            if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
              intervalos.push({
                idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                artIni: cls1[i].art_inicio,
                artFin: cls1[i].art_fin,
                numPreg: 0,
                bloqueado: false,
                unico: false,
                preguntasDisponibles: await preguntasBusqueda
              })

            }



          } else {
            let articuloBusqueda = await cls1[i].art_inicio
            let puntosLenghtString = 1
            if (articuloBusqueda != '') {

              let articuloPuntos = await articuloBusqueda.split('.')

              if (articuloPuntos.length > 0) {
                puntosLenghtString = await articuloPuntos.length

                if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {

                  puntosLenghtString = await puntosLenghtString - 1
                }
              }

              let preguntasBusqueda = await selectPreguntasPorArticuloUnico(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, articuloBusqueda, puntosLenghtString)

              if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                intervalos.push({
                  idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                  nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                  siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                  artIni: cls1[i].art_inicio,
                  artFin: cls1[i].art_fin,
                  numPreg: 0,
                  bloqueado: false,
                  unico: false,
                  preguntasDisponibles: await preguntasBusqueda
                })

              }


            } else {
              let preguntasBusqueda = []
              if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                intervalos.push({
                  idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                  nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                  siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                  artIni: cls1[i].art_inicio,
                  artFin: cls1[i].art_fin,
                  numPreg: 0,
                  bloqueado: false,
                  unico: false,
                  preguntasDisponibles: await preguntasBusqueda
                })

              }



            }

          }

        }

        let temaSeleted = await temasToLoad.find(o => o.id_tema == temasToLoad[jx].id_tema)
        let idKey = jx
        // metemos el tema y intervalos
        await this.props.genTestEdtTema(idKey, temaSeleted, intervalos)

        // let totalPregDis = 0
        // for (let jh = 0; jh < intervalos.length; jh++) {
        //   totalPregDis = totalPregDis + intervalos[jh].preguntasDisponibles.length
        //   if (totalPregDis >= 2) { break; }
        // }
        // if (totalPregDis >= 2) {
        //   // asignamos total preguntas y repartirmospreguntas
        //   await this.props.genTestEdtNumPregTotal('2', idKey, 'tema')
        //   // await this.setState({ genTestInfo: await this.props.genTestInfo })
        //   await this.repartoDePreguntas(idKey, 'tema')
        // } else {
        //   // asignamos total preguntas y repartirmospreguntas
        //   await this.props.genTestEdtNumPregTotal(totalPregDis, idKey, 'tema')
        //   // await this.setState({ genTestInfo: await this.props.genTestInfo })
        //   await this.repartoDePreguntas(idKey, 'tema')
        // }
        // FIN -------- insertamos los datos de cada tema en su hueco
      }

      this.setState({ genTestInfo: this.props.genTestInfo })

      let totalPregTem = 0


      for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

        if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
          totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
        }
      }
      this.setState({ totalPreguntasPreparadorTodas: totalPregTem })

      swal.close()
      // }

    } else if (e.target.id == "redistribuirPregTotal") {

      let value = this.state.numPregTotalTest
      swal({
        title: 'Repartiendo Preguntas proporcionalmente',
        text: 'Este proceso puede demorarse.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      if (this.props.genTestInfo.temasTestG.filter(a => a.temasLoadOpo.length == 0).length > 0) {

        let indexFallo = this.props.genTestInfo.temasTestG.findIndex(a => a.temasLoadOpo.length == 0) + 1

        swal('Error, Deben estar todos los bloques de tema asignados', 'Junto a los temas que ha añadido, hay un bloque / circulo sin seleccionar tema "esta vacio", concretamente se encuentra en el bloque / circulo Nº '+ indexFallo +'. Seleccione un tema o elimine el bloque. Luego puede volver a repartir las preguntas entre todos los temas.', 'warning')

      } else {


        setTimeout(async () => {

          if (this.props.tipoUser > 50) {

            if (value >= 1) {

              let a = this.asignarPreguntasATemas(this.props.genTestInfo.temasTestG, value)

              for (let i = 0; i < a.length; i++) {
                await this.props.genTestEdtNumPregTotal(a[i].numeroTotalPreguntas, i, 'tema')

                let b = this.asignarPreguntasAIntervalos(a[i].numeroTotalPreguntas, a[i].intervalos)

                for (let j = 0; j < b.length; j++) {
                  let loca = a[i].intervalos.findIndex(cls => cls.artFin == b[j].artFin && cls.artIni == b[j].artIni && cls.idLey == b[j].idLey)
                  if (loca != -1) {
                    let pregAsig = b[j].preguntasAsignadas == undefined ? 0 : b[j].preguntasAsignadas
                    await this.props.genTestEdtIntervalo(i, 'tema', loca, 'numPreg', pregAsig)
                  }

                }

              }
              let totalPregTem = 0
              for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

                if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
                  totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
                }
              }
              this.setState({ totalPreguntasPreparadorTodas: totalPregTem })

              swal.close()

              this.setState({ genTestInfo: this.props.genTestInfo })

            } else {
              swal('Error', 'Debe indicar el numero de preguntas a repartir entre todos los temas', 'info')
            }
          } else {
            if (value >= 25 && value <= 150) {

              let a = this.asignarPreguntasATemas(this.props.genTestInfo.temasTestG, value)

              for (let i = 0; i < a.length; i++) {
                await this.props.genTestEdtNumPregTotal(a[i].numeroTotalPreguntas, i, 'tema')

                let b = this.asignarPreguntasAIntervalos(a[i].numeroTotalPreguntas, a[i].intervalos)

                for (let j = 0; j < b.length; j++) {
                  let loca = a[i].intervalos.findIndex(cls => cls.artFin == b[j].artFin && cls.artIni == b[j].artIni && cls.idLey == b[j].idLey)
                  if (loca != -1) {
                    let pregAsig = b[j].preguntasAsignadas == undefined ? 0 : b[j].preguntasAsignadas
                    await this.props.genTestEdtIntervalo(i, 'tema', loca, 'numPreg', pregAsig)
                  }

                }

              }
              let totalPregTem = 0
              for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

                if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
                  totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
                }
              }
              this.setState({ totalPreguntasPreparadorTodas: totalPregTem })

              swal.close()

              this.setState({ genTestInfo: this.props.genTestInfo })

            } else {
              swal('Error', 'Debe indicar un número entre 25 y 150 preguntas', 'info')
            }

          }
        }, 500);

      }

    } else if (e.target.id == 'addAllTemasAlumno') {

      // 1 Añadir temas sin datos 
      let { temaActivo } = this.state
      let { genTestInfo } = this.props

      let idOpo = genTestInfo.temasTestG[temaActivo].idOpo
      let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
      let temasToLoad = genTestInfo.temasTestG[temaActivo].temasLoadOpo.sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema))

      swal({
        title: 'Insertando temas',
        text: 'Este proceso puede demorarse entre 1 a 2 minutos.',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })




      for (let j1 = 0; j1 < temasToLoad.length; j1++) {
        // generamos el espacio del tema
        if (j1 != 0) {
          await this.props.genTestAddTema()
        }
      }

      let data1 = {}
      let response1 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrgN", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1x2d = await json1;

      for (let index2 = 0; index2 < genTestInfo.temasTestG.length; index2++) {

        if (index2 > 0) {
          let dato = []
          dato.push(temasToLoad[index2])

          await this.props.genTestEdtTemaOpo(index2, opoSeleted, dato)
        }


        // INICIO --------- insertamos los datos de cada tema en su hueco
        let jx = index2

        let cls1 = cls1x2d.filter(p => p.id_tema == temasToLoad[jx].id_tema)
        let intervalos = []

        for (let i = 0; i < cls1.length; i++) {

          if (cls1[i].art_fin != '' && cls1[i].art_fin != '-' && cls1[i].art_fin != '–' && cls1[i].art_fin != ' ' && cls1[i].art_fin.length > 0) {

            let preguntasBusqueda = await selectPreguntasPorArticulosInter(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, cls1[i].art_inicio, cls1[i].art_fin)

            if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
              intervalos.push({
                idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                artIni: cls1[i].art_inicio,
                artFin: cls1[i].art_fin,
                numPreg: 0,
                bloqueado: false,
                unico: false,
                preguntasDisponibles: preguntasBusqueda
              })

            }



          } else {
            let articuloBusqueda = await cls1[i].art_inicio
            let puntosLenghtString = 1
            if (articuloBusqueda != '') {

              let articuloPuntos = await articuloBusqueda.split('.')

              if (articuloPuntos.length > 0) {
                puntosLenghtString = await articuloPuntos.length

                if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {

                  puntosLenghtString = await puntosLenghtString - 1
                }
              }

              let preguntasBusqueda = await selectPreguntasPorArticuloUnico(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, articuloBusqueda, puntosLenghtString)

              if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                intervalos.push({
                  idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                  nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                  siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                  artIni: cls1[i].art_inicio,
                  artFin: cls1[i].art_fin,
                  numPreg: 0,
                  bloqueado: false,
                  unico: false,
                  preguntasDisponibles: preguntasBusqueda
                })

              }


            } else {
              let preguntasBusqueda = []
              if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                intervalos.push({
                  idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                  nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                  siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                  artIni: cls1[i].art_inicio,
                  artFin: cls1[i].art_fin,
                  numPreg: 0,
                  bloqueado: false,
                  unico: false,
                  preguntasDisponibles: preguntasBusqueda
                })

              }



            }

          }

        }

        let temaSeleted = await temasToLoad.find(o => o.id_tema == temasToLoad[jx].id_tema)
        let idKey = jx
        // metemos el tema y intervalos
        await this.props.genTestEdtTema(idKey, temaSeleted, intervalos)


      }

      this.setState({ genTestInfo: this.props.genTestInfo })


      await this.repartoPreguntasProporcional()

      swal.close()
      // }

    }

    // else if (e.target.id == 'repartirPreguntasPorTemasProporicional') {
    //   let totalPreg = 100

    //   let a = this.asignarPreguntasATemas(this.props.genTestInfo.temasTestG, totalPreg)
    //   console.log('a')
    //   console.log(a)

    //   for (let i = 0; i < a.length; i++) {
    //     await this.props.genTestEdtNumPregTotal(a[i].numeroTotalPreguntas, i, 'tema')

    //     // this.setState({ genTestInfo: this.props.genTestInfo })

    //     // await this.repartoDePreguntas(i, 'tema')


    //     let b = this.asignarPreguntasAIntervalos(a[i].numeroTotalPreguntas, a[i].intervalos)
    //     console.log('a[i].numeroTotalPreguntas')
    //     console.log(a[i].numeroTotalPreguntas)
    //     console.log('b')
    //     console.log(b)
    //     for (let j = 0; j < b.length; j++) {
    //       let loca = a[i].intervalos.findIndex(cls => cls.artFin == b[j].artFin && cls.artIni == b[j].artIni && cls.idLey == b[j].idLey)
    //       if (loca != -1) {
    //         let pregAsig = b[j].preguntasAsignadas == undefined ? 0 : b[j].preguntasAsignadas
    //         await this.props.genTestEdtIntervalo(i, 'tema', loca, 'numPreg', pregAsig)
    //       }


    //     }


    //     // 

    //     // this.setState({ genTestInfo: this.props.genTestInfo })

    //     // await this.repartoDePreguntas(i, 'tema')
    //   }

    // }

  }

  async onChangeInput(e) {

    if (e.target.id == "numPregTest") {

      let value = e.target.value

      if (this.state.temaActivo != null && this.state.artActivo == null && !isNaN(parseInt(value))) {

        if (this.props.tipoUser >= 50) {
          if (this.props.genTestInfo.temasTestG[this.state.temaActivo].interval.length > 0) {
            await this.props.genTestEdtNumPregTotal(value, this.state.temaActivo, 'tema')
            this.setState({ genTestInfo: this.props.genTestInfo })
            if (this.props.genTestInfo.temasTestG[this.state.temaActivo].interval.filter(i => i.bloqueado == true).length == 0) {
              this.repartoDePreguntas(this.state.temaActivo, 'tema')
            } else {
              if (this.state.avisoInterBloqueado == null) {
                swal('Ajuste las preguntas', 'Use el boton manual para repartir preguntas, con intervalos bloqueados la funcion autorepatir esta desactivada', 'info')

                this.setState({ avisoInterBloqueado: true })
              }
            }
          } else {
            swal('Error', 'Para asignar un número de preguntas, primero debe añadir algun intervalo de leyes', 'info')
          }
        } else {
          // AQUI VEMOS EL Nº TOTAL DE TEMAS SEGUN Nº TOTAL MAXIMO PREGUNTAS
          if (value <= 25) {
            await this.props.genTestEdtNumPregTotal(value, this.state.temaActivo, 'tema')
            this.setState({ genTestInfo: this.props.genTestInfo })
            this.repartoDePreguntas(this.state.temaActivo, 'tema')
          } else {
            swal('Máximo de 25 preguntas por tema', '', 'info')
            await this.props.genTestEdtNumPregTotal(25, this.state.temaActivo, 'tema')
            this.setState({ genTestInfo: this.props.genTestInfo })
            this.repartoDePreguntas(this.state.temaActivo, 'tema')
          }
        }

      } else if (this.state.temaActivo == null && this.state.artActivo != null && !isNaN(parseInt(value))) {



        if (this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval.length > 0) {
          await this.props.genTestEdtNumPregTotal(value, this.state.artActivo, 'art')
          this.setState({ genTestInfo: this.props.genTestInfo })

          if (this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval.filter(i => i.bloqueado == true).length == 0) {
            this.repartoDePreguntas(this.state.artActivo, 'art')
          } else {
            if (this.state.avisoInterBloqueado == null) {
              swal('Ajuste las preguntas', 'Use el boton manual para repartir preguntas, con intervalos bloqueados la funcion autorepatir esta desactivada', 'info')

              this.setState({ avisoInterBloqueado: true })
            }

          }
        } else {
          swal('Error', 'Para asignar un número de preguntas primero debe añadir algun intervalo de leyes', 'info')
        }


      }

      if (this.props.genTestInfo.temasTestG.length >= 2) {
        let totalPregTem = 0

        for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

          if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
            totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
          }
        }
        this.setState({ totalPreguntasPreparadorTodas: totalPregTem })
      }

      this.setState({ genTestInfo: this.props.genTestInfo })


    } else if (e.target.id == "nameTest") {

      let tO = this.state.genTestInfo
      tO.nameTest = e.target.value
      this.setState({ genTestInfo: tO })
      await this.props.genTestEdtNameTest(e.target.value)

    } else if (e.target.id.split('-')[0] == "opoTest") {
      let idKey = e.target.id.split('-')[1]

      let value = e.target.value

      let idOpo = this.state.busquedaOpo.find(o => o.nombre == e.target.value) != undefined ? this.state.busquedaOpo.find(o => o.nombre == e.target.value).id_oposicion : null
      if (idOpo != null) {

        let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
        await this.props.genTestEdtOpo(idKey, opoSeleted)
        this.setState({ genTestInfo: this.props.genTestInfo })
      } else {

        await this.props.genTestEdtOpo(idKey, null, value)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }

    } else if (e.target.id == 'temporalizacionEXP') {
      // generar preguntas para no ver si es un usuario normal el preparador al añadir la clase, ubicamos en parte principal del test como: preguntasExcluirExpediente:
      if (this.props.tipoUser > 50) {
        // Si tiene clases añadidas agregar id preguntas
        // Sino null y al añadir una clase o editar calculamos todas.
        if (this.props.genTestInfo.clasesTestExp.filter(c => c.id != null).length > 0) {

          let totalMeses = e.target.value
          let desde = 0

          let clasesIds = []
          for (let i = 0; i < this.props.genTestInfo.clasesTestExp.filter(c => c.id != null).length; i++) {
            clasesIds.push({ idClase: this.props.genTestInfo.clasesTestExp[i].id })
          }


          if (totalMeses == "Todo el tiempo") {
            desde = 48
          } else if (totalMeses.split('meses').length > 0) {
            desde = parseInt(totalMeses.split('meses')[0])
          } else if (totalMeses == '1 mes') { desde = 1 }



          if (totalMeses != '' && totalMeses != undefined && totalMeses != null && desde != 0) {

            let fecha = moment().subtract(desde, 'months').format('YYYY-MM-DD')
            let data1 = {
              idClases: clasesIds,
              fecha: fecha
            }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/expClaseTemporalizadoPregTestTeoClases", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            await this.props.genTestEdtTemporalizacionExp(totalMeses, cls1)
            this.setState({ genTestInfo: this.props.genTestInfo })

          } else {
            await this.props.genTestEdtTemporalizacionExp(totalMeses, null)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }

        } else {
          await this.props.genTestEdtTemporalizacionExp(e.target.value, null)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }



      } else {
        let totalMeses = e.target.value
        let desde = 0

        if (totalMeses == "Todo el tiempo") {
          desde = 12
        } else if (totalMeses.split('meses').length > 0) {
          desde = parseInt(totalMeses.split('meses')[0])
        } else if (totalMeses == '1 mes') { desde = 1 }

        if (totalMeses != '' && totalMeses != undefined && totalMeses != null && desde != 0) {

          let fecha = moment().subtract(desde, 'months').format('YYYY-MM-DD')

          let data1 = {
            idUser: this.props.idUser,
            idClase: this.props.idClase,
            fecha: fecha
          }

          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/expClaseTemporalizadoPregTestTeo", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;


          await this.props.genTestEdtTemporalizacionExp(totalMeses, cls1)
          this.setState({ genTestInfo: this.props.genTestInfo })

        } else {
          await this.props.genTestEdtTemporalizacionExp(totalMeses, null)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }


      }



    } else if (e.target.id.split('-')[0] == "temasTest") {
      let idKey = this.state.temaActivo

      let value = e.target.value
      let temasOpo = this.props.genTestInfo.temasTestG[idKey].temasLoadOpo
      let idTema = temasOpo.find(o => o.titulo_tema == e.target.value) != undefined ? temasOpo.find(o => o.titulo_tema == e.target.value).id_tema : null

      if (idTema != null) {

        let temaSeleted = temasOpo.find(o => o.id_tema == idTema)

        // sacar intervalos tema
        if (this.props.genTestInfo.temasTestG.filter(t => t.id == idTema).length == 0) {


          let data1 = { id_tema: idTema }
          let response1 = await fetch("https://oposiciones-justicia.es/api/temas/interTemaPrg", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          let intervalos = []

          for (let i = 0; i < cls1.length; i++) {

            if (cls1[i].art_fin != '' && cls1[i].art_fin != '-' && cls1[i].art_fin != '–' && cls1[i].art_fin != ' ' && cls1[i].art_fin.length > 0) {

              let preguntasBusqueda = await selectPreguntasPorArticulosInter(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, cls1[i].art_inicio, cls1[i].art_fin)

              if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                intervalos.push({
                  idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                  nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                  siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                  artIni: cls1[i].art_inicio,
                  artFin: cls1[i].art_fin,
                  numPreg: '',
                  bloqueado: false,
                  unico: false,
                  preguntasDisponibles: await preguntasBusqueda
                })

              }

            } else {
              let articuloBusqueda = await cls1[i].art_inicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {

                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley, articuloBusqueda, puntosLenghtString)

                if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                  intervalos.push({
                    idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                    nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                    siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                    artIni: cls1[i].art_inicio,
                    artFin: cls1[i].art_fin,
                    numPreg: '',
                    bloqueado: false,
                    unico: false,
                    preguntasDisponibles: await preguntasBusqueda
                  })

                }


              } else {
                let preguntasBusqueda = []
                if (/*this.props.tipoUser < 50 && */ preguntasBusqueda.length > 0) {
                  intervalos.push({
                    idLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).id_ley,
                    nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).nombre_ley,
                    siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls1[i].id_ley).siglas_ley,
                    artIni: cls1[i].art_inicio,
                    artFin: cls1[i].art_fin,
                    numPreg: '',
                    bloqueado: false,
                    unico: false,
                    preguntasDisponibles: await preguntasBusqueda
                  })

                }



              }

            }

          }

          await this.props.genTestEdtTema(idKey, temaSeleted, intervalos)

          if (this.props.tipoUser < 50) {
            await this.repartoPreguntasProporcional()
          }
          this.setState({ genTestInfo: await this.props.genTestInfo })
          // let totalPregDis = 0
          // for (let jh = 0; jh < intervalos.length; jh++) {
          //   totalPregDis = totalPregDis + intervalos[jh].preguntasDisponibles.length
          //   if (totalPregDis >= 25) { break; }
          // }
          // if (totalPregDis >= 25) {
          //   await this.props.genTestEdtNumPregTotal('25', this.state.temaActivo, 'tema')
          //   await this.setState({ genTestInfo: await this.props.genTestInfo })
          //   await this.repartoDePreguntas(this.state.temaActivo, 'tema')
          // } else {
          //   await this.props.genTestEdtNumPregTotal(totalPregDis, this.state.temaActivo, 'tema')
          //   await this.setState({ genTestInfo: await this.props.genTestInfo })
          //   await this.repartoDePreguntas(this.state.temaActivo, 'tema')
          // }

        } else {
          swal('Error', 'Ese tema ya esta elegido', 'info')
        }


      } else {

        await this.props.genTestEdtTema(idKey, null, value)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }

    } else if (e.target.id.split('-')[0] == "claseTest") {
      let idKey = e.target.id.split('-')[1]

      let value = e.target.value

      let idClase = this.state.busquedaClasesPreparador.find(o => o.diaSemanal == e.target.value) != undefined ? this.state.busquedaClasesPreparador.find(o => o.diaSemanal == e.target.value).idClase : null
      if (idClase != null) {

        let totalMeses = this.props.genTestInfo.tiempoExclusionPreguntas
        let desde = 0

        let clasesIds = []
        if (this.props.genTestInfo.clasesTestExp.filter(c => c.id != null).length > 0) {
          for (let i = 0; i < this.props.genTestInfo.clasesTestExp.length; i++) {
            clasesIds.push({ idClase: this.props.genTestInfo.clasesTestExp[i].id })
          }
        }
        clasesIds.push({ idClase: idClase })

        totalMeses == '1 mes' ? desde = 1 : null
        totalMeses.split('meses').length > 0 ? desde = parseInt(totalMeses.split('meses')[0]) : null
        if (totalMeses == "Todo el tiempo") { desde = 48 }

        if (totalMeses != '' && totalMeses != undefined && totalMeses != null && desde != 0) {

          let fecha = moment().subtract(desde, 'months').format('YYYY-MM-DD')
          let data1 = {
            idClases: clasesIds,
            fecha: fecha
          }
          let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/expClaseTemporalizadoPregTestTeoClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          // await this.props.genTestEdtTemporalizacionExp(totalMeses, cls1)
          // this.setState({ genTestInfo: this.props.genTestInfo })

          let claseSeleted = this.state.busquedaClasesPreparador.find(o => o.idClase == idClase)

          await this.props.genTestEdtClase(idKey, claseSeleted, null, cls1)
          this.setState({ genTestInfo: this.props.genTestInfo })

        } else {
          let claseSeleted = this.state.busquedaClasesPreparador.find(o => o.idClase == idClase)

          await this.props.genTestEdtClase(idKey, claseSeleted, null, null)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }

      } else {
        // ver si ahi otra clases y cargar o null
        let clasesIds = []
        if (this.props.genTestInfo.clasesTestExp.filter(c => c.id != null && this.props.genTestInfo.clasesTestExp[idKey].id != c.id).length > 0) {
          for (let i = 0; i < this.props.genTestInfo.clasesTestExp.filter(c => c.id != null && this.props.genTestInfo.clasesTestExp[idKey].id != c.id).length; i++) {
            clasesIds.push({ idClase: this.props.genTestInfo.clasesTestExp[i].id })
          }
        }

        if (clasesIds.length > 0) {
          let totalMeses = this.props.genTestInfo.tiempoExclusionPreguntas
          let desde = 0
          totalMeses == '1 mes' ? desde = 1 : null
          totalMeses.split('meses').length > 0 ? desde = parseInt(totalMeses.split('meses')[0]) : null
          if (totalMeses == "Todo el tiempo") { desde = 48 }

          if (totalMeses != '' && totalMeses != undefined && totalMeses != null && desde != 0) {

            let fecha = moment().subtract(desde, 'months').format('YYYY-MM-DD')
            let data1 = {
              idClases: clasesIds,
              fecha: fecha
            }
            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/expClaseTemporalizadoPregTestTeoClases", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            // await this.props.genTestEdtTemporalizacionExp(totalMeses, cls1)
            // this.setState({ genTestInfo: this.props.genTestInfo })

            await this.props.genTestEdtClase(idKey, null, value, cls1)

            this.setState({ genTestInfo: this.props.genTestInfo })

          } else {
            await this.props.genTestEdtClase(idKey, null, value, null)

            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          await this.props.genTestEdtClase(idKey, null, value, null)

          this.setState({ genTestInfo: this.props.genTestInfo })
        }

      }

    } else if (e.target.id.split('-')[0] == "leyIntervalo") {

      let idKey = e.target.id.split('-')[1]
      let value = e.target.value

      let idLey = this.state.busquedaLeyes.find(o => o.siglas_ley == e.target.value) != undefined ? this.state.busquedaLeyes.find(o => o.siglas_ley == e.target.value).id_ley : null

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        let unico = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].unico
        let artInicio = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artIni
        let artFin = await this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artFin
        let seleted = this.state.busquedaLeyes.find(o => o.id_ley == idLey)

        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'ley', value, seleted, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'ley', value, seleted, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'ley', value, seleted, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          let preguntasBusqueda = []
          await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'ley', value, null, preguntasBusqueda)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }



        // await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      } else if (this.state.temaActivo == null && this.state.artActivo != null) {

        let artInicio = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artIni
        let artFin = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artFin
        let unico = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].unico
        let seleted = this.state.busquedaLeyes.find(o => o.id_ley == idLey)
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && !unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'ley', value, seleted, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'ley', value, seleted, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'ley', value, seleted, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          let preguntasBusqueda = []
          await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'ley', value, null, preguntasBusqueda)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }



        // await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      }









      //-------

    } else if (e.target.id.split('-')[0] == "articuloInicio") {
      let idKey = e.target.id.split('-')[1]
      let value = e.target.value

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        let artInicio = value
        let artFin = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artFin
        let idLey = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].idLey
        let unico = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].unico
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artIni', value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artIni', value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artIni', value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artIni', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      } else if (this.state.temaActivo == null && this.state.artActivo != null) {

        let artInicio = value
        let artFin = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artFin
        let idLey = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].idLey
        let unico = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].unico
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      }
    } else if (e.target.id.split('-')[0] == "articuloFin") {
      let idKey = e.target.id.split('-')[1]
      let value = e.target.value

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        let artInicio = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].artIni
        let artFin = value
        let idLey = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].idLey
        let unico = this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].unico
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'artFin', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      } else if (this.state.temaActivo == null && this.state.artActivo != null) {

        let artInicio = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].artIni
        let artFin = value
        let idLey = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].idLey
        let unico = this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].unico
        if (idLey != '' && idLey != undefined && idLey != null) {
          if (artInicio != '' && artInicio != '-' && artInicio != '–' && artInicio != ' ' && artInicio.length > 0) {
            if (artFin != '' && artFin != '-' && artFin != '–' && artFin != ' ' && artFin.length > 0 && unico == false) {
              let preguntasBusqueda = await selectPreguntasPorArticulosInter(idLey, artInicio, artFin)
              await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artFin', value, preguntasBusqueda)
              this.setState({ genTestInfo: this.props.genTestInfo })
            } else {
              let articuloBusqueda = artInicio
              let puntosLenghtString = 1
              if (articuloBusqueda != '') {

                let articuloPuntos = await articuloBusqueda.split('.')

                if (articuloPuntos.length > 0) {
                  puntosLenghtString = await articuloPuntos.length

                  if (articuloPuntos.length > 1 && articuloPuntos[articuloPuntos.length - 1] == "") {
                    puntosLenghtString = await puntosLenghtString - 1
                  }
                }

                let preguntasBusqueda = await selectPreguntasPorArticuloUnico(idLey, articuloBusqueda, puntosLenghtString)
                await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artFin', value, preguntasBusqueda)
                this.setState({ genTestInfo: this.props.genTestInfo })
              }
            }
          } else {
            let preguntasBusqueda = []
            await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artFin', value, preguntasBusqueda)
            this.setState({ genTestInfo: this.props.genTestInfo })
          }
        } else {
          swal('Error, ley no valida', 'Indique una ley para indicar un articulo', 'info')
        }



        // await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'artIni', value, null)
        // this.setState({ genTestInfo: this.props.genTestInfo })

      }



    } else if (e.target.id.split('-')[0] == "numPregIntervalo") {
      let idKey = e.target.id.split('-')[1]
      let value = e.target.value

      if (this.state.temaActivo != null && this.state.artActivo == null) {
        if (value <= this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].preguntasDisponibles.length) {
          await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'numPreg', value)
          this.setState({ genTestInfo: this.props.genTestInfo })
        } else {
          await this.props.genTestEdtIntervalo(this.state.temaActivo, 'tema', idKey, 'numPreg', this.props.genTestInfo.temasTestG[this.state.temaActivo].interval[idKey].preguntasDisponibles.length)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }


      } else if (this.state.temaActivo == null && this.state.artActivo != null) {



        if (value <= this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].preguntasDisponibles.length) {
          await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'numPreg', value)
          this.setState({ genTestInfo: this.props.genTestInfo })
        } else {
          await this.props.genTestEdtIntervalo(this.state.artActivo, 'art', idKey, 'numPreg', this.props.genTestInfo.intervalosTestG[this.state.artActivo].interval[idKey].preguntasDisponibles.length)
          this.setState({ genTestInfo: this.props.genTestInfo })
        }

      }
    } else if (e.target.id.split('-')[0] == "opoTemasTest") {
      let idKey = this.state.temaActivo

      let value = e.target.value
      let idOpo = this.state.busquedaOpo.find(o => o.nombre == e.target.value) != undefined ? this.state.busquedaOpo.find(o => o.nombre == e.target.value).id_oposicion : null
      if (idOpo != null) {

        let opoSeleted = this.state.busquedaOpo.find(o => o.id_oposicion == idOpo)
        /* 
        Buscar temas de oposición solo nombre y listar luego al seleccinar tema se realiza una busqueda del contenido
        */
        let data1 = { id_oposicion: idOpo }
        let response1 = await fetch("https://oposiciones-justicia.es/api/temas/consultaTemasOpo", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;



        await this.props.genTestEdtTemaOpo(idKey, opoSeleted, cls1)
        this.setState({ genTestInfo: this.props.genTestInfo })

      } else {

        await this.props.genTestEdtTemaOpo(idKey, null, value)
        this.setState({ genTestInfo: this.props.genTestInfo })
      }

    } else if (e.target.id == "numPregTestTotal") {

      let value = e.target.value

      if (value != '') {
        this.setState({ numPregTotalTest: value })
      } else {
        this.setState({ numPregTotalTest: 0 })
      }



    }

  }

  async componentDidMount() {

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));


    let response3 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
    if (!response3.ok) {
      throw Error(response3.statusText);
    }
    let json3 = await response3.json();
    let cls3 = await json3;
    await this.setState({ busquedaLeyes: cls3 });


    if (this.props.tipoUser >= 50) {
      if (this.props.tipoUser < 90) {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }
        }

      } else {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }


        }
      }

      if (this.props.genTestInfo.temasTestG.length >= 2) {
        let totalPregTem = 0

        for (let jx4 = 0; jx4 < this.props.genTestInfo.temasTestG.length; jx4++) {

          if (this.props.genTestInfo.temasTestG[jx4].numPreg != 0 && this.props.genTestInfo.temasTestG[jx4].numPreg != '' && !isNaN(parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg))) {
            totalPregTem = totalPregTem + parseInt(this.props.genTestInfo.temasTestG[jx4].numPreg)
          }
        }
        this.setState({ totalPreguntasPreparadorTodas: totalPregTem })
      }
    }


  }


  render() {

    const { isLogged, genTestInfo, nombreUser, tipoUser, temasFacturados } = this.props;
    const { generador, visualizar, expTiming, temaActivo, artActivo, busquedaLeyes, busquedaOpo, busquedaClasesPreparador, preguntasLoadView, posPLVInter, verPreguntaOnOff, preguntaTestLoad, seletedInfo, totalPregTemas, totalPregInterval, mezclarPreguntas, mezclarRespuestas, mezclarTemas, totalPreguntasPreparadorTodas } = this.state

    // console.log('genTestInfo')
    // console.log(genTestInfo)
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      if (this.props.tipoUser < 50 && (this.props.idClase == null || temasFacturados == null)) {
        swal({
          title: 'Area restringida',
          text: 'Para visualizar esta sección requiere tener un curso seleccionado y activo. Redirigiendo a selección en breve.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {
          window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
        })
        return (null);
      } else {
        return (
          <div className="testHomeJus" id="style-scroll">
            <div className="imagenMolona">
              <div className="plantica">

              </div>
              <div className="pcMolon">

              </div>
              <div className="contentImMM">
                <h1 className="h1ContentImMM">¿LISTO PARA UNOS TEST?</h1>

                <div className="contImMM-info">
                  Bienvenido a la sección de <b>“Test teóricos”</b>, dónde podrás crear tus propios test teóricos por temas.
                </div >

                {this.props.testJusticiaUserLoad.length > 0 ?
                  (<div className="contImMM-testPendiente">
                    <div> Test pendiente de finalizar </div>
                    <div style={{ margin: '10px 0px 10px 10px', maxWidth: '140px' }}>
                      <Boton id="irAfinalizarTest" icon1="" icon2="" texto="Continuar test" onClick={this.handleOnSubmit} />
                    </div>
                  </div>) : null
                }



                <div className="contImMM-generadorNav">

                  <div style={{ margin: '10px 0px 10px 10px' }}>
                    {generador != null && (generador == 'temas' || generador == 'confirmarGenTest') ? (
                      <Boton id="cleanTestTemas" icon1="" icon2="" texto="Limpiar generador" onClick={this.handleOnSubmit} />
                    ) : (
                      <Boton2 id="genTestTemas" icon1="" icon2="" texto="Generar Test" onClick={this.handleOnSubmit} />
                    )}
                  </div>
                </div>
              </div>

            </div>

            {generador != null && (generador == 'temas' || generador == 'confirmarGenTest') ? (
              <React.Fragment>
                <div className="tituloGenTemas"> Generador de <b>Test por Temas</b> </div>


                {generador != null && generador == 'confirmarGenTest' ? (
                  <div className="bodyGenTestN" >
                    <div className="bGTN-row-Content" style={{ padding: '0px 50px' }}>
                      <div className="bGTN-row textTitldxvk2">
                        ¿ Todo Correcto ?
                      </div>

                      <div className="bGTN-row" style={this.props.tipoUser >= 50 ? ({ width: '100%', justifyContent: 'space-between' }) : ({ width: '100%', justifyContent: 'center' })}>
                        <div className="bGTN-row-infoConfTest">
                          <div className='textTitldxvk3'> Total Preguntas </div>
                          <div style={this.props.tipoUser >= 50 ? ({ alignSelf: 'flex-start' }) : ({ alignSelf: 'center' })}>  {genTestInfo && genTestInfo != null && genTestInfo.numPreg != '' ? (
                            genTestInfo.numPreg
                          ) : ('No valido')} </div>
                        </div>

                        {this.props.tipoUser >= 50 ? (
                          <div className="bGTN-row-infoConfTest">
                            <div className='textTitldxvk3' style={{ alignSelf: 'flex-end' }}> Nombre Test </div>
                            <div style={{ alignSelf: 'flex-end' }}>  {genTestInfo && genTestInfo != null && genTestInfo.nameTest != '' ? (
                              genTestInfo.nameTest
                            ) : ('Nombre no asignado')} </div>
                          </div>

                        ) : null}
                      </div>


                      <div className="bGTN-RC-topC toggleDivF" style={{ borderBottom: '2px solid #C0C0C0', marginTop: '20px' }}>


                        <div className="bGTN-RC-topC-Cont">

                          <div className="bGTN-RCTCC-nav">

                            {genTestInfo && genTestInfo.temasTestG != undefined ? (
                              <div className="bGTN-RCTCC-nav-item" style={seletedInfo == 0 ? ({ color: '#3469b7', paddingLeft: '0px' }) : ({ color: '#bebebe', paddingLeft: '0px' })} id='nav-0' onClick={this.handleOnSubmit}>
                                {genTestInfo.temasTestG.filter(t => t.numPreg != '' && t.numPreg != 0).length + ' Temas'}
                                {genTestInfo && genTestInfo.temasTestG != undefined && genTestInfo.temasTestG.length > 0 ? (
                                  ' - ' + totalPregTemas + ' Preg.'
                                ) : null}
                              </div>) : null}

                            {genTestInfo && genTestInfo.intervalosTestG != undefined && this.props.tipoUser >= 50 ? (
                              <div className="bGTN-RCTCC-nav-item" style={seletedInfo == 1 ? ({ borderLeft: '1px solid #bebebe', color: '#3469b7' }) : ({ borderLeft: '1px solid #bebebe', color: '#bebebe' })} id='nav-1' onClick={this.handleOnSubmit}>
                                {genTestInfo.intervalosTestG.filter(t => t.numPreg != '' && t.numPreg != 0).length + ' Intervalos'}
                                {genTestInfo && genTestInfo.intervalosTestG != undefined && genTestInfo.intervalosTestG.length > 0 ? (
                                  ' - ' + totalPregInterval + ' Preg.'
                                ) : null}
                              </div>) : null}

                            {genTestInfo && genTestInfo.testOposicion != undefined && this.props.tipoUser >= 50 ? (
                              <div className="bGTN-RCTCC-nav-item" style={seletedInfo == 2 ? ({ borderLeft: '1px solid #bebebe', color: '#3469b7' }) : ({ borderLeft: '1px solid #bebebe', color: '#bebebe' })} id='nav-2' onClick={this.handleOnSubmit}>

                                {genTestInfo.testOposicion.filter(t => t.id != '' && t.id != null).length + ' Oposiciones'}
                              </div>) : null}
                          </div>

                          <div className="bGTN-RCTCC-mid2" style={{ paddingBottom: '20px' }}>

                            {seletedInfo == 0 ? (
                              genTestInfo && genTestInfo.temasTestG.filter(t => t.numPreg != '' && t.numPreg != 0).length > 0 && genTestInfo.temasTestG.filter(t => t.numPreg != '' && t.numPreg != 0).map((tema, key235) =>
                                <div style={key235 == 0 ? ({ marginLeft: '10px', marginTop: '0px' }) : ({ marginLeft: '10px', marginTop: '10px' })} key={key235}>
                                  {tema.numPreg + ' Preg. - ' + tema.nombre}
                                </div>
                              )
                            ) : null}

                            {seletedInfo == 1 ? (
                              genTestInfo && genTestInfo.intervalosTestG.filter(t => t.numPreg != '' && t.numPreg != 0).length > 0 && genTestInfo.intervalosTestG.filter(t => t.numPreg != '' && t.numPreg != 0).map((tema, key2354) =>
                                <div style={key2354 == 0 ? ({ marginLeft: '10px', marginTop: '0px' }) : ({ marginLeft: '10px', marginTop: '10px' })} key={key2354}>
                                  {tema.numPreg + ' Preg. - Intervalo ' + (key2354 + 1)}
                                </div>
                              )
                            ) : null}

                            {seletedInfo == 2 ? (
                              genTestInfo && genTestInfo.testOposicion.filter(t => t.id != '' && t.id != null).length > 0 && genTestInfo.testOposicion.filter(t => t.id != '' && t.id != null).map((opo, key23545) =>
                                <div style={key23545 == 0 ? ({ marginLeft: '10px', marginTop: '0px' }) : ({ marginLeft: '10px', marginTop: '10px' })} key={key23545}>
                                  {opo.nombre}
                                </div>
                              )
                            ) : null}

                          </div>


                        </div>

                        <div className="bGTN-RC-topC-img2">

                        </div>
                      </div>
                      {this.props.tipoUser >= 50 ? (
                        <div className="bGTN-row textTitldxvk2" style={{ width: '100%' }}>
                          Opciones generador
                        </div>
                      ) : null}
                      {this.props.tipoUser >= 50 ? (
                        <div className="bGTN-row" style={{ width: '100%', justifyContent: 'space-evenly', marginTop: '5px' }}>
                          <div className="bGTN-RCTCC-mid-row" style={{ alignItems: 'center', minWidth: '140px', maxWidth: '140px', margin: '3px 10px' }}>
                            <div className="titleMRRCTCC" style={{ fontSize: '14px' }} data-title="Mezcla las preguntas en cada tema."> Mezclar Preguntas </div>
                            <div className="bGTN-row" style={{ margin: '0px', alignSelf: 'center' }}>
                              <div className={mezclarPreguntas == 1 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarPreguntas" onClick={this.handleOnSubmit}> SI </div>
                              <div className={mezclarPreguntas == 0 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarPreguntas" onClick={this.handleOnSubmit}> NO </div>
                            </div>
                          </div>

                          <div className="bGTN-RCTCC-mid-row" style={{ alignItems: 'center', minWidth: '140px', maxWidth: '140px', margin: '3px 10px' }}>
                            <div className="titleMRRCTCC" style={{ fontSize: '14px' }} data-title="Mezcla las respuestas"> Mezclar Respuestas </div>
                            <div className="bGTN-row" style={{ margin: '0px', alignSelf: 'center' }}>
                              <div className={mezclarRespuestas == 1 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarRespuestas" onClick={this.handleOnSubmit}> SI </div>
                              <div className={mezclarRespuestas == 0 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarRespuestas" onClick={this.handleOnSubmit}> NO </div>
                            </div>
                          </div>


                          <div className="bGTN-RCTCC-mid-row" style={{ alignItems: 'center', minWidth: '140px', maxWidth: '140px', margin: '3px 10px' }}>
                            <div className="titleMRRCTCC" style={{ fontSize: '14px' }} data-title="Mezcla todas las preguntas."> Mezclar Temas </div>
                            <div className="bGTN-row" style={{ margin: '0px', alignSelf: 'center' }}>
                              <div className={mezclarTemas == 1 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarTemas" onClick={this.handleOnSubmit}> SI </div>
                              <div className={mezclarTemas == 0 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="mezclarTemas" onClick={this.handleOnSubmit}> NO </div>
                            </div>
                          </div>

                        </div>
                      ) : null}

                      <div className="bGTN-row" style={{ width: '100%', marginBottom: '40px' }}>
                        <div style={{ margin: '5px 10px' }}>
                          <Boton id="generarTest" icon1="" icon2="" texto="Editar" onClick={this.handleOnSubmit} />
                        </div>
                        <div style={{ margin: '5px 10px' }}>
                          <Boton id="generarTestConfirmado" icon1="" icon2="" texto="Generar Test" onClick={this.handleOnSubmit} />
                        </div>
                      </div>




                    </div>
                  </div>
                ) : (
                  <div className="bodyGenTestN" >

                    <div className="bGTN-row-Content" style={genTestInfo.continuar && genTestInfo.continuar >= 1 ? ({ borderBottom: '1px solid #b3b3b3' }) : ({})}>
                      {this.props.tipoUser >= 50 ? (
                        <div className="bGTN-row">


                          {this.props.tipoUser >= 50 ? (<div className="celdaInputDAPFBN">
                            <Input2 value={genTestInfo.nameTest} autoComplete={"off"} type="text" id="nameTest" name="nameTest" placeholder="Nombre Test" icon="fas fa-marker" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                            <p>Nombre Test</p>
                          </div>) : null}

                          {visualizar == 0 ? null : (
                            this.props.tipoUser >= 50 ? (
                              <div style={{ margin: '10px' }}>
                                <Boton id="editar0" icon1="" icon2="" texto="editar" onClick={this.handleOnSubmit} />
                              </div>) : null

                          )}

                        </div>
                      ) : null}


                      {
                        tipoUser < 50 ? (
                          visualizar == 0 ? (
                            <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', marginTop: '0px', justifyContent: 'center', width: '100%', marginBottom: '20px' }}>
                              <p style={{ color: '#3469b7', fontWeight: '600' }}> El total de preguntas se generá automaticamente.</p><br />

                              <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', marginTop: '0px', justifyContent: 'space-around', width: '100%' }}>
                                <p style={{ fontWeight: 'bold' }}> Automaticamente se asignan 100 preguntas por test.</p>
                                <p style={{ fontWeight: 'bold' }}> Puede modificar entre 25 a 150 preguntas por test.</p>

                              </div>
                            </div>
                          ) : null

                        ) : (
                          visualizar == 0 ? (
                            <div>
                              <p style={{ fontSize: '17px', color: '#3469b7', fontWeight: '600' }}> Indique el nombre del test y oposicion/es.</p>

                            </div>
                          ) : null
                        )
                      }

                      {
                        tipoUser < 50 ? (
                          visualizar == 0 ? (
                            <div className="bGTN-row" style={{ marginTop: '0px' }}>
                              <div style={{ margin: '10px' }}>
                                <Boton id="continuar1" icon1="" icon2="" texto="Continuar" onClick={this.handleOnSubmit} />
                              </div>
                            </div>
                          ) : null

                        ) : (
                          visualizar == 0 ? (
                            <div className="bGTN-row" style={{ marginTop: '0px', justifyContent: 'center', width: '100%' }}>
                              <div style={{ margin: '10px' }}>
                                <Boton id="addOpoTest" icon1="" icon2="" texto="Añadir oposición" onClick={this.handleOnSubmit} />
                              </div>

                              {genTestInfo.continuar < 1 ? (<div style={{ margin: '10px' }}>
                                <Boton id="continuar1" icon1="" icon2="" texto="Continuar" onClick={this.handleOnSubmit} />
                              </div>) : null}
                            </div>
                          ) : null
                        )
                      }

                      {
                        tipoUser > 50 && genTestInfo && genTestInfo.testOposicion && genTestInfo.testOposicion.length > 0 && visualizar == 0 ? (
                          <div className="bGTN-rowOpo">
                            {genTestInfo.testOposicion && genTestInfo.testOposicion.length > 0 && genTestInfo.testOposicion.map((testa, key) =>
                              <div id={"celdaOpo" + key} className="celdaInputDAPFBN animacionBotonEntra" key={key}>
                                <Input2
                                  datalist="busquedaOpo2" value={genTestInfo.testOposicion[key].nombre}
                                  autoComplete={"off"} id={"opoTest-" + key} type="text" name={"opoTest-" + key} ref={"opoTest-" + key} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                                <p>Oposición</p>
                                <i className="fas fa-times elimnarBotonFly" id={"delOpoTest-" + key} onClick={this.handleOnSubmit} />

                              </div>
                            )}
                          </div>
                        ) : null}
                    </div>

                    {genTestInfo.continuar && genTestInfo.continuar >= 1 ? (
                      visualizar == 1 ? (

                        <div className="bGTN-row-Content" style={genTestInfo.continuar && genTestInfo.continuar >= 2 ? ({ borderBottom: '1px solid #b3b3b3' }) : ({})}>
                          <div className="bGTN-RC-topC toggleDivF">


                            <div className="bGTN-RC-topC-Cont">

                              <div className="bGTN-RCTCC-top">
                                {/* Opciones para generar el Test */}
                                Repetir preguntas
                              </div>

                              <div className="bGTN-RCTCC-mid" style={genTestInfo.repetirPreguntas == 1 ? ({ justifyContent: 'flex-start' }) : ({ justifyContent: 'flex-start' })}>



                                <div className="bGTN-RCTCC-mid-row" style={{ paddingLeft: '15px' }}>
                                  <div className="titleMRRCTCC" style={{ fontSize: '14px' }}> Permitir preguntas vistas </div>
                                  <div className="bGTN-row" style={{ margin: '0px' }}>
                                    <div className={genTestInfo.repetirPreguntas == 0 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="repetirPreg" onClick={this.handleOnSubmit}> SI </div>
                                    <div className={genTestInfo.repetirPreguntas == 1 ? ("buttonCIrcle selectedCC") : ("buttonCIrcle")} id="repetirPreg" onClick={this.handleOnSubmit}> NO </div>
                                  </div>
                                </div>

                                {genTestInfo.repetirPreguntas == 1 ? (
                                  <div className={"bGTN-RCTCC-mid-row animacionBotonEntra "}>
                                    <div className="titleMRRCTCC" style={{ fontSize: '14px', maxWidth: '230px', minWidth: '230px' }}> ¿Desde cuando tenemos en cuenta el expediente? </div>
                                    <div className="bGTN-row" style={{ margin: '0px' }}>
                                      <div className="celdaInputDAPFBN ">
                                        <Input2
                                          datalist="temporalizacionvbVB" value={genTestInfo.tiempoExclusionPreguntas}
                                          autoComplete={"off"} id={"temporalizacionEXP"} type="text" name={"temporalizacionEXP"} ref={"temporalizacionEXP"} placeholder="Tiempo" icon="fas fa-calendar" onChange={this.onChangeInput} />
                                        <p>Expediente</p>

                                        <datalist id="temporalizacionvbVB">
                                          {expTiming && expTiming.map((cls, key2) =>
                                            <option key={key2} value={cls.tiempo}></option>
                                          )}
                                        </datalist>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {genTestInfo.continuar <= 1 ? (<div className="bGTN-RCTCC-mid-row">
                                  <div>
                                    <div style={{ margin: '5px' }}>
                                      <Boton id="continuar2" icon1="" icon2="" texto="Continuar" onClick={this.handleOnSubmit} />
                                    </div>
                                  </div>

                                </div>) : null}

                              </div>


                            </div>

                            <div className="bGTN-RC-topC-img">

                            </div>
                          </div>

                          {genTestInfo.repetirPreguntas == 1 && this.props.tipoUser >= 50 ? (
                            <div className="bGTN-RC-DC" style={{ paddingLeft: '15px' }}>
                              <div className="bGTN-RC-DC-rowT">
                                <div className="botonSimpleBb2" id="addClaseExp" onClick={this.handleOnSubmit}> Añadir clase <i className="far fa-times-circle" /> </div>
                                <div className="botonSimpleBb"> Clases a tener en cuenta </div>
                              </div>

                              <div className="bGTN-RC-DC-rowT2">
                                {
                                  genTestInfo && genTestInfo.clasesTestExp && genTestInfo.clasesTestExp.length > 0 ? (
                                    <div className="bGTN-rowOpo" style={{ padding: '20px 20px' }}>
                                      {genTestInfo.clasesTestExp && genTestInfo.clasesTestExp.length > 0 && genTestInfo.clasesTestExp.map((cls, key3) =>
                                        <div className="celdaInputDAPFBN animacionBotonEntra" key={key3}>
                                          <Input2
                                            datalist="busquedaClasesPreparador" value={genTestInfo.clasesTestExp[key3].nombre}
                                            autoComplete={"off"} id={"claseTest-" + key3} type="text" name={"claseTest-" + key3} ref={"claseTest-" + key3} placeholder="Nombre clase" icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>Clase</p>
                                          <i className="fas fa-times elimnarBotonFly" id={"delClaseTest-" + key3} onClick={this.handleOnSubmit} />

                                        </div>
                                      )}
                                    </div>
                                  ) : null
                                }
                              </div>


                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="bGTN-row-Content2d" style={genTestInfo.continuar && genTestInfo.continuar >= 2 ? ({ borderBottom: '1px solid #b3b3b3' }) : ({})}>
                          <div className="titleMolon">

                            Repetir preguntas de expediente - <b style={{ color: '#3469b7' }}>{genTestInfo.repetirPreguntas == 0 ? ('SI') : ('NO')} </b>

                          </div>
                          <div style={{ margin: '10px' }}>
                            <Boton id="editar1" icon1="" icon2="" texto="editar" onClick={this.handleOnSubmit} />
                          </div>
                        </div>
                      )

                    ) : null
                    }

                    {genTestInfo.continuar && genTestInfo.continuar >= 2 ? (
                      visualizar == 2 ? (
                        <div className="bGTN-row-Content" style={{ width: '80%', padding: '0px' }}>

                          {/* {this.props.tipoUser >= 50 ? ( */}
                          <div className="genTest-Temas-header2">
                            <div className="celdaInputDAPFBN" style={{ alignSelf: 'flex-start', margin: '10px 0px', width: '200px' }}>
                              <Input2 autoComplete={"off"} type="text" id="numPregTestTotal" name="numPregTestTotal" placeholder="Nº preguntas total" icon="far fa-clipboard" ref="ref_numPregTestTotal" onChange={this.onChangeInput} />
                              <p>Editar Total Preguntas</p>


                              {/* {this.props.tipoUser >= 50 ? ( */}
                              <i className="fas fa-redo-alt botonCerrar" title="Cambiar total preguntas" id={"redistribuirPregTotal"} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                              {/* ) : null} */}

                            </div>

                            {totalPreguntasPreparadorTodas != null ? (
                              <div className="GT-T-H-left" style={{
                                color: '#3469b7',
                                fontWeight: '600'
                              }}>

                                Total preguntas {totalPreguntasPreparadorTodas}

                              </div>
                            ) : null}



                          </div>
                          {/* ) : null} */}

                          {this.props.tipoUser >= 50 ? (
                            <div className="genTest-Temas-header2">

                              <div className="GT-T-H-left">
                                <div style={{ margin: '10px 0px' }}>
                                  <Boton id="addIntervaloTest" icon1="" icon2="" texto="Añadir Intervalos" onClick={this.handleOnSubmit} />
                                </div>


                                {genTestInfo && genTestInfo.intervalosTestG && genTestInfo.intervalosTestG.length > 0 ? (
                                  <div className="GT-T-H-left-navTemas" style={{}}>
                                    {genTestInfo.intervalosTestG && genTestInfo.intervalosTestG.length > 0 && genTestInfo.intervalosTestG.map((cls, key6) =>
                                      <div className={artActivo == key6 ? ("circleTemaBoton animacionBotonEntra circleactivo") : ("circleTemaBoton animacionBotonEntra")} key={key6} onClick={this.handleOnSubmit} id={"artVer-" + key6}>
                                        {key6 + 1}
                                        <p id={"artVer-" + key6} className='voladorCtbb' style={cls.numPreg != '' ? ({}) : ({ color: '#833220' })}>
                                          {cls.numPreg != '' ? (cls.numPreg) : ('0')}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ) : null}

                              </div>

                            </div>
                          ) : null}

                          <div className="genTest-Temas-header" style={{ marginTop: '10px' }}>
                            <div className="GT-T-H-left">
                              <div style={{ margin: '10px 0px' }}>
                                <Boton id="addTemaTestGen" icon1="" icon2="" texto="Añadir Tema" onClick={this.handleOnSubmit} />
                              </div>


                              {genTestInfo && genTestInfo.temasTestG && genTestInfo.temasTestG.length > 0 ? (
                                <div className="GT-T-H-left-navTemas" style={{}}>
                                  {genTestInfo.temasTestG && genTestInfo.temasTestG.length > 0 && genTestInfo.temasTestG.map((cls, key4) =>
                                    <div className={temaActivo == key4 ? ("circleTemaBoton animacionBotonEntra circleactivo") : ("circleTemaBoton animacionBotonEntra")} data-title={cls.id != null ? (cls.nombre) : ('Elija un Tema')} key={key4} onClick={this.handleOnSubmit} id={"temaVer-" + key4}>
                                      {key4 + 1}
                                      <p id={"temaVer-" + key4} className='voladorCtbb' style={cls.numPreg != '' && cls.id != null ? ({}) : ({ color: '#833220' })}>
                                        {cls.numPreg != '' && cls.id != null ? (cls.numPreg) : ('0')}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              ) : null}

                            </div>


                            <div style={{ margin: '10px 0px' }}>
                              <Boton id="generarTest" icon1="" icon2="" texto="Generar Test" onClick={this.handleOnSubmit} />
                            </div>

                          </div>

                          {genTestInfo && genTestInfo.temasTestG && genTestInfo.temasTestG.length > 0 && temaActivo != null ? (

                            <div className="genTest-Temas-selected toggleDivF">



                              {this.props.tipoUser >= 50 ? (
                                <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start' }}>
                                  <div className="celdaInputDAPFBN animacionBotonEntra" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px' }}>
                                    <Input2
                                      datalist="busquedaOpo" value={genTestInfo.temasTestG[temaActivo].nombreOpo}
                                      autoComplete={"off"} id={"opoTemasTest-"} type="text" name={"opoTemasTest-"} ref={"opoTemasTest-"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                                    <p>Oposición Temas</p>
                                    <i className="fas fa-times elimnarBotonFly" id={"deltemasTest"} onClick={this.handleOnSubmit} />

                                  </div>

                                  {genTestInfo.temasTestG[temaActivo].idOpo != null ? (

                                    <div className="celdaInputDAPFBN animacionBotonEntra" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px' }}>
                                      <Input2
                                        datalist="temasOpoXyz" value={genTestInfo.temasTestG[temaActivo].nombre}
                                        autoComplete={"off"} id={"temasTest-"} type="text" name={"temasTest-"} ref={"temasTest-"} placeholder="Nombre tema" icon="fas fa-tag" onChange={this.onChangeInput} />
                                      <p>Tema</p>
                                      <i className="fas fa-times elimnarBotonFly" id={"deltemasTest"} onClick={this.handleOnSubmit} />

                                      <datalist id="temasOpoXyz">{genTestInfo.temasTestG[temaActivo].temasLoadOpo && genTestInfo.temasTestG[temaActivo].temasLoadOpo.length > 0 && genTestInfo.temasTestG[temaActivo].temasLoadOpo.map((tema23, key234) =>
                                        <option key={key234} value={tema23.titulo_tema}>{tema23.titulo_tema + ' - ' + tema23.nombre_tema}</option>
                                      )}</datalist>
                                    </div>
                                  ) : null}




                                  {genTestInfo.temasTestG[temaActivo].id != null ? (
                                    <div className="celdaInputDAPFBN" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px', width: '165px' }}>
                                      <Input2 value={genTestInfo.temasTestG[temaActivo].numPreg} autoComplete={"off"} readOnly={this.props.tipoUser >= 50 ? (false) : (true)} type="text" id="numPregTest" name="numPregTest" placeholder="Nº preguntas" icon="far fa-clipboard" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                                      <p>Total Preguntas</p>
                                      {this.props.tipoUser >= 50 ? (
                                        <i className="fas fa-redo-alt botonCerrar" id={"redistribuirPreg"} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                                      ) : null}


                                    </div>
                                  ) : null}


                                  {genTestInfo.temasTestG && genTestInfo.temasTestG.length == 1 && genTestInfo.temasTestG[temaActivo].idOpo != null ? (

                                    <div style={{ margin: '10px 0px' }}>
                                      <Boton id="addAllTemasEstaOpo" icon1="" icon2="" texto="Añadir todos los Temas" onClick={this.handleOnSubmit} />
                                    </div>


                                  ) : null}




                                </div>
                              ) : (
                                <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'flex-start', alignSelf: 'flex-start' }}>
                                  <div className="celdaInputDAPFBN animacionBotonEntra" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px' }}>
                                    <Input2
                                      datalist="temasOpoXyz" value={genTestInfo.temasTestG[temaActivo].nombre}
                                      autoComplete={"off"} id={"temasTest-"} type="text" name={"temasTest-"} ref={"temasTest-"} placeholder="Nombre tema" icon="fas fa-tag" onChange={this.onChangeInput} />
                                    <p>Tema</p>
                                    <i className="fas fa-times elimnarBotonFly" id={"deltemasTest"} onClick={this.handleOnSubmit} />

                                    <datalist id="temasOpoXyz">{genTestInfo.temasTestG[temaActivo].temasLoadOpo && genTestInfo.temasTestG[temaActivo].temasLoadOpo.length > 0 && genTestInfo.temasTestG[temaActivo].temasLoadOpo.map((tema23, key234) =>
                                      <option key={key234} value={tema23.titulo_tema}>{tema23.titulo_tema + ' - ' + tema23.nombre_tema}</option>
                                    )}</datalist>
                                  </div>
                                  {genTestInfo.temasTestG[temaActivo].id != null ? (
                                    <div className="celdaInputDAPFBN" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px', width: '165px' }}>
                                      <Input2 value={genTestInfo.temasTestG[temaActivo].numPreg} autoComplete={"off"} readOnly={this.props.tipoUser >= 50 ? (false) : (true)} type="text" id="numPregTest" name="numPregTest" placeholder="Nº preguntas" icon="far fa-clipboard" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                                      <p>Total Preguntas</p>
                                      {this.props.tipoUser >= 50 ? (
                                        <i className="fas fa-redo-alt botonCerrar" id={"redistribuirPreg"} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                                      ) : null}


                                    </div>
                                  ) : null}

                                  {genTestInfo.temasTestG && genTestInfo.temasTestG.length == 1 && genTestInfo.temasTestG[temaActivo].idOpo != null ? (

                                    <div style={{ margin: '10px 0px' }}>
                                      <Boton id="addAllTemasAlumno" icon1="" icon2="" texto="Añadir todos los Temas" onClick={this.handleOnSubmit} />
                                    </div>


                                  ) : null}

                                </div>
                              )}



                              {this.props.tipoUser >= 50 ? (
                                <div className="genTest-Temas-header" style={{ borderBottom: '1px solid #b3b3b3' }}>
                                  <div className="topInfogENtEST">
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Ley </div>
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Articulo Inicio </div>
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Artículo Fin </div>
                                    <div style={{ padding: '2px 8px' }}> Numero de Preguntas </div>
                                  </div>
                                  {this.props.tipoUser >= 50 && preguntasLoadView == null ? (
                                    <div className="botonSimpleBb2" id="addInterval" onClick={this.handleOnSubmit}> Añadir intervalo <i className="far fa-times-circle" /> </div>
                                  ) : (
                                    <div className="botonSimpleBb"> Intervalos del Tema </div>
                                  )}
                                </div>
                              ) : null}
                              {this.props.tipoUser >= 50 ? (
                                preguntasLoadView == null ? (
                                  <div className="genTest-Temas-intervalos">

                                    {genTestInfo.temasTestG && genTestInfo.temasTestG.length > 0 && genTestInfo.temasTestG[temaActivo].interval.map((int, key8) =>
                                      <div className="genTest-Temas-intervalosInputs animacionBotonEntra2" key={key8}>
                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[key8].siglasLey}
                                            datalist="busquedaLeyes" readOnly={this.props.tipoUser >= 50 ? (false) : (true)}
                                            autoComplete={"off"} id={"leyIntervalo-" + key8} type="text" name={"leyIntervalo-" + key8} ref={"leyIntervalo-" + key8} placeholder="Nombre ley" icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>Ley</p>


                                        </div>
                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[key8].artIni} autoComplete={"off"} readOnly={this.props.tipoUser >= 50 ? (false) : (true)} id={"articuloInicio-" + key8} type="text" name={"articuloInicio-" + key8} ref={"articuloInicio-" + key8} placeholder={genTestInfo.temasTestG[temaActivo].interval[key8].unico == false ? ("Art. Inicio") : ("Art. Unico")} icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>
                                            {genTestInfo.temasTestG[temaActivo].interval[key8].unico == false ? ("Art. Inicio") : ("Art. Unico")}
                                          </p>
                                          {this.props.tipoUser >= 50 ? (
                                            <i className="fas fa-sync-alt botonCerrar" id={"unicoIntervalo-" + key8} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                                          ) : null}
                                        </div>

                                        {genTestInfo.temasTestG[temaActivo].interval[key8].unico == false ?
                                          (
                                            <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                              <Input2 value={genTestInfo.temasTestG[temaActivo].interval[key8].artFin} readOnly={this.props.tipoUser >= 50 ? (false) : (true)} autoComplete={"off"} id={"articuloFin-" + key8} type="text" name={"articuloFin-" + key8} ref={"articuloFin-" + key8} placeholder="Articulo Fin" icon="fas fa-tag" onChange={this.onChangeInput} />
                                              <p>Art. Fin</p>
                                            </div>
                                          ) : null
                                        }

                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[key8].numPreg} readOnly={this.props.tipoUser >= 50 ? (false) : (true)} autoComplete={"off"} id={"numPregIntervalo-" + key8} type="text" name={"numPregIntervalo-" + key8} ref={"numPregIntervalo-" + key8} placeholder="Número Preguntas" icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>Num Preguntas</p>
                                          {this.props.tipoUser >= 50 ? (
                                            <div className="infoNuMpregdiosponible" onClick={this.handleOnSubmit} id={'verPreguntasTestTeorico-' + key8}>
                                              <b id={'verPreguntasTestTeorico-' + key8}>{genTestInfo.temasTestG[temaActivo].interval[key8].numPreg == '' ? ('0') : (genTestInfo.temasTestG[temaActivo].interval[key8].numPreg)}</b>
                                              /
                                              {genTestInfo.temasTestG[temaActivo].interval[key8].preguntasDisponibles.length}
                                            </div>
                                          ) : null}
                                        </div>
                                        {this.props.tipoUser >= 50 ? (
                                          <i className={genTestInfo.temasTestG[temaActivo].interval[key8].bloqueado == false ? ("fas fa-lock-open botonCerrar") : ("fas fa-lock botonCerrar")} id={"bloqIntervalo-" + key8} style={{ color: '#3469b7' }} onClick={this.handleOnSubmit} />
                                        ) : null}
                                        {this.props.tipoUser >= 50 ? (
                                          <i className="fas fa-times botonCerrar" id={"delIntervalo-" + key8} onClick={this.handleOnSubmit} />
                                        ) : null}

                                      </div>

                                    )}

                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className="genTest-Temas-intervalos">


                                      <div className="genTest-Temas-intervalosInputs animacionBotonEntra2">
                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[posPLVInter].siglasLey}
                                            datalist="busquedaLeyes" readOnly={true}
                                            autoComplete={"off"} id={"leyIntervalo-" + posPLVInter} type="text" name={"leyIntervalo-" + posPLVInter} ref={"leyIntervalo-" + posPLVInter} placeholder="Nombre ley" icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>Ley</p>


                                        </div>
                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[posPLVInter].artIni} autoComplete={"off"} readOnly={true} id={"articuloInicio-" + posPLVInter} type="text" name={"articuloInicio-" + posPLVInter} ref={"articuloInicio-" + posPLVInter} placeholder={genTestInfo.temasTestG[temaActivo].interval[posPLVInter].unico == false ? ("Art. Inicio") : ("Art. Unico")} icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>
                                            {genTestInfo.temasTestG[temaActivo].interval[posPLVInter].unico == false ? ("Art. Inicio") : ("Art. Unico")}
                                          </p>

                                        </div>

                                        {genTestInfo.temasTestG[temaActivo].interval[posPLVInter].unico == false ?
                                          (
                                            <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                              <Input2 value={genTestInfo.temasTestG[temaActivo].interval[posPLVInter].artFin} readOnly={true} autoComplete={"off"} id={"articuloFin-" + posPLVInter} type="text" name={"articuloFin-" + posPLVInter} ref={"articuloFin-" + posPLVInter} placeholder="Articulo Fin" icon="fas fa-tag" onChange={this.onChangeInput} />
                                              <p>Art. Fin</p>
                                            </div>
                                          ) : null
                                        }

                                        <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                          <Input2 value={genTestInfo.temasTestG[temaActivo].interval[posPLVInter].numPreg} readOnly={true} autoComplete={"off"} id={"numPregIntervalo-" + posPLVInter} type="text" name={"numPregIntervalo-" + posPLVInter} ref={"numPregIntervalo-" + posPLVInter} placeholder="Número Preguntas" icon="fas fa-tag" onChange={this.onChangeInput} />
                                          <p>Num Preguntas</p>
                                          {this.props.tipoUser >= 50 ? (
                                            <div className="infoNuMpregdiosponible" id={'verPreguntasTestTeorico-' + posPLVInter}>
                                              <b id={'verPreguntasTestTeorico-' + posPLVInter}>{genTestInfo.temasTestG[temaActivo].interval[posPLVInter].numPreg == '' ? ('0') : (genTestInfo.temasTestG[temaActivo].interval[posPLVInter].numPreg)}</b>
                                              /
                                              {genTestInfo.temasTestG[temaActivo].interval[posPLVInter].preguntasDisponibles.length}
                                            </div>
                                          ) : null}
                                        </div>


                                      </div>

                                    </div>

                                    <div className="bodyPregLoadToView">
                                      <div className="bPLTV-header">
                                        <div style={{ margin: '10px 0px' }}>
                                          <Boton id="cerrarPreguntasLoadView" icon1="" icon2="" texto="Ocultar Preguntas" onClick={this.handleOnSubmit} />
                                        </div>
                                        <div className="bPLTV-header-info">

                                          <div style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '14px' }}> Preguntas intervalo </div>
                                          <div>
                                            {preguntasLoadView && preguntasLoadView.length > 0 &&
                                              preguntasLoadView.filter(p => p.incluida == true).length > 0 ? ('Incluidas ' + preguntasLoadView.filter(p => p.incluida == true).length) : ('Incluidas 0')}
                                            /

                                            {preguntasLoadView && preguntasLoadView.length > 0 &&
                                              preguntasLoadView.filter(p => p.incluida == true).length > 0 ? ((genTestInfo.temasTestG[temaActivo].interval[posPLVInter].preguntasDisponibles.length - preguntasLoadView.filter(p => p.incluida == true).length) + ' Random') : (genTestInfo.temasTestG[temaActivo].interval[posPLVInter].preguntasDisponibles.length + ' Random')}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="bPLTV-contenedor" id="style-2">
                                        {preguntasLoadView && preguntasLoadView.length > 0 && preguntasLoadView.map((preg, key2N) =>


                                          <div className="bPLTV-rowPreg" id={'preg-' + key2N} key={key2N}>
                                            <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'flex-start', padding: '5px', width: '20%' }}>
                                              <i className="fas fa-eye botonIconFG" onClick={this.handleOnSubmit} id={'verPreguntaTestTeorico-' + key2N} />
                                              <div style={{ marginLeft: '10px', color: '#3469b7' }}>
                                                {'Art. ' + preg.articulo}
                                              </div>
                                            </div>
                                            <div title={preg.pregunta} style={{ maxWidth: '80%', width: '80%', padding: '5px' }}>
                                              {preg.pregunta.length > (window.innerWidth > 1150 ? (125) : (50)) ? (
                                                preg.pregunta.slice(0, (window.innerWidth > 1150 ? (125) : (50))) + '...'
                                              ) : (
                                                preg.pregunta
                                              )}
                                            </div>
                                            <div style={{
                                              display: 'flex',
                                              flexFlow: 'row wrap',
                                              alignItems: 'center',
                                              justifyContent: 'flex-end',
                                              padding: '5px',
                                              position: 'absolute',
                                              right: '40px',
                                              bottom: '-23px'
                                            }}>
                                              <div className="botonSlading" onClick={this.handleOnSubmit} id={"incluir-" + key2N} style={preg.incluida == true ? ({ direction: 'ltr', marginRight: '10px' }) : ({ direction: 'rtl', background: '#bebebe', marginRight: '10px' })}>
                                                <div className="circleBSlading" id={"incluir-" + key2N} style={preg.incluida == true ? ({}) : ({ background: '#fff' })}></div>
                                                <p id={"incluir-" + key2N}>{preg.incluida == true ? ('Incluida') : ('Random')}</p>
                                              </div>

                                              <div className="botonSlading" onClick={this.handleOnSubmit} id={"excluir-" + key2N} style={preg.excluida == true ? ({ direction: 'ltr', marginRight: '10px' }) : ({ direction: 'rtl', background: '#bebebe', marginRight: '10px' })}>
                                                <div className="circleBSlading" id={"excluir-" + key2N} style={preg.excluida == true ? ({}) : ({ background: '#fff' })}></div>
                                                <p id={"excluir-" + key2N}>{preg.excluida == true ? ('Excluida') : ('Permitida')}</p>
                                              </div>
                                            </div>

                                          </div>

                                        )}
                                      </div>


                                      {verPreguntaOnOff == true && preguntaTestLoad && preguntaTestLoad.length > 0 ? (
                                        <div style={{ position: 'absolute', top: '90px' }}>
                                          <div style={{ position: 'absolute', zIndex: '1', top: '28px', right: '38%' }}>
                                            <Boton id="cerrarPreguntaViewLive" icon1="" icon2="" texto="Cerrar Pregunta" onClick={this.handleOnSubmit} />
                                          </div>
                                          <PreguntaTestVerTeoria preguntaTestLoad={preguntaTestLoad} notificacion={null} />
                                        </div>
                                      ) : null}



                                    </div>

                                  </React.Fragment>

                                )
                              ) : null}

                            </div>

                          ) : null}

                          {genTestInfo && genTestInfo.intervalosTestG && genTestInfo.intervalosTestG.length > 0 && artActivo != null ? (
                            <React.Fragment>


                              <div className="genTest-Temas-selected toggleDivF">
                                <div style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                  <div className="botonSimpleBb2 animacionBotonEntra" style={{ margin: '10px 0px' }} id="eliminarInterval" onClick={this.handleOnSubmit}>
                                    <i className="far fa-times-circle" style={{ marginRight: '5px', transform: 'rotate(360deg)' }} />
                                    Eliminar

                                  </div>

                                  {genTestInfo.intervalosTestG[artActivo].interval.length > 0 ? (
                                    <div className="celdaInputDAPFBN" style={{ alignSelf: 'flex-start', margin: '10px 20px 10px 0px', width: '165px' }}>
                                      <Input2 value={genTestInfo.intervalosTestG[artActivo].numPreg} autoComplete={"off"} type="text" readOnly={this.props.tipoUser >= 50 ? (false) : (true)} id="numPregTest" name="numPregTest" placeholder="Nº preguntas" icon="far fa-clipboard" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                                      <p>Total Preguntas</p>
                                      {this.props.tipoUser >= 50 ? (
                                        <i className="fas fa-redo-alt botonCerrar" id={"redistribuirPreg"} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                                      ) : null}

                                    </div>
                                  ) : null}
                                </div>
                                <div className="genTest-Temas-header" style={{ borderBottom: '1px solid #b3b3b3' }}>
                                  <div className="topInfogENtEST">
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Ley </div>
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Articulo Inicio </div>
                                    <div style={{ padding: '2px 8px', borderRight: '1px solid #d1d1d1' }}> Artículo Fin </div>
                                    <div style={{ padding: '2px 8px' }}> Numero de Preguntas </div>
                                  </div>
                                  {this.props.tipoUser >= 50 && preguntasLoadView == null ? (
                                    <div className="botonSimpleBb2" id="addInterval" onClick={this.handleOnSubmit}> Añadir intervalo <i className="far fa-times-circle" /> </div>
                                  ) : (
                                    <div className="botonSimpleBb"> Intervalos sin Tema </div>
                                  )}


                                </div>

                                <div className="genTest-Temas-intervalos">

                                  {genTestInfo.intervalosTestG && genTestInfo.intervalosTestG.length > 0 && genTestInfo.intervalosTestG[artActivo].interval.map((int, key7) =>
                                    <div className="genTest-Temas-intervalosInputs animacionBotonEntra2" key={key7}>
                                      <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                        <Input2
                                          datalist="busquedaLeyes" value={genTestInfo.intervalosTestG[artActivo].interval[key7].siglasLey}
                                          autoComplete={"off"} id={"leyIntervalo-" + key7} type="text" name={"leyIntervalo-" + key7} ref={"leyIntervalo-" + key7} placeholder="Nombre ley" icon="fas fa-tag" onChange={this.onChangeInput} />
                                        <p>Ley</p>

                                      </div>
                                      <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                        <Input2 value={genTestInfo.intervalosTestG[artActivo].interval[key7].artIni} autoComplete={"off"} id={"articuloInicio-" + key7} type="text" name={"articuloInicio-" + key7} ref={"articuloInicio-" + key7} placeholder={genTestInfo.intervalosTestG[artActivo].interval[key7].unico == false ? ("Art. Inicio") : ("Art. Unico")} icon="fas fa-tag" onChange={this.onChangeInput} />
                                        <p>
                                          {genTestInfo.intervalosTestG[artActivo].interval[key7].unico == false ? ("Art. Inicio") : ("Art. Unico")}
                                        </p>
                                        {this.props.tipoUser >= 50 ? (
                                          <i className="fas fa-sync-alt botonCerrar" id={"unicoIntervalo-" + key7} style={{ color: '#3469b7', position: 'absolute', top: '-26px', fontSize: '18px', right: '-22px' }} onClick={this.handleOnSubmit} />
                                        ) : null}
                                      </div>
                                      {genTestInfo.intervalosTestG[artActivo].interval[key7].unico == false ?
                                        (
                                          <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '130px' }}>
                                            <Input2 value={genTestInfo.intervalosTestG[artActivo].interval[key7].artFin} autoComplete={"off"} id={"articuloFin-" + key7} type="text" name={"articuloFin-" + key7} ref={"articuloFin-" + key7} placeholder="Articulo Fin" icon="fas fa-tag" onChange={this.onChangeInput} />
                                            <p>Art. Fin</p>
                                          </div>
                                        ) : null
                                      }
                                      <div className="celdaInputDAPFBN" style={{ margin: '10px 5px', maxWidth: '160px' }}>
                                        <Input2 value={genTestInfo.intervalosTestG[artActivo].interval[key7].numPreg} autoComplete={"off"} id={"numPregIntervalo-" + key7} type="text" name={"numPregIntervalo-" + key7} ref={"numPregIntervalo-" + key7} placeholder="Número Preguntas" icon="fas fa-tag" onChange={this.onChangeInput} />
                                        <p>Num Preguntas</p>
                                        {this.props.tipoUser >= 50 ? (
                                          <div className="infoNuMpregdiosponible" onClick={this.handleOnSubmit} id={'verPreguntasTestTeorico-' + key7}>
                                            <b id={'verPreguntasTestTeorico-' + key7}>{genTestInfo.intervalosTestG[artActivo].interval[key7].numPreg == '' ? ('0') : (genTestInfo.intervalosTestG[artActivo].interval[key7].numPreg)}</b>
                                            /
                                            {genTestInfo.intervalosTestG[artActivo].interval[key7].preguntasDisponibles.length}
                                          </div>
                                        ) : null}
                                      </div>
                                      {this.props.tipoUser >= 50 ? (
                                        <i className={genTestInfo.intervalosTestG[artActivo].interval[key7].bloqueado == false ? ("fas fa-lock-open botonCerrar") : ("fas fa-lock botonCerrar")} id={"bloqIntervalo-" + key7} style={{ color: '#3469b7' }} onClick={this.handleOnSubmit} />
                                      ) : null}
                                      {this.props.tipoUser >= 50 ? (
                                        <i className="fas fa-times botonCerrar" id={"delIntervalo-" + key7} onClick={this.handleOnSubmit} />
                                      ) : null}


                                    </div>

                                  )}

                                </div>

                                {posPLVInter != null && genTestInfo.intervalosTestG[artActivo].interval.filter(i => i.preguntasDisponibles.length > 0).length > 0 ? (
                                  <div className="bodyPregLoadToView">
                                    <div className="bPLTV-header">
                                      <div style={{ margin: '10px 0px' }}>
                                        <Boton id="cerrarPreguntasLoadView" icon1="" icon2="" texto="Ocultar Preguntas" onClick={this.handleOnSubmit} />
                                      </div>
                                      <div className="bPLTV-header-info">

                                        <div style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '14px' }}> Preguntas intervalo </div>
                                        <div>
                                          {preguntasLoadView && preguntasLoadView.length > 0 &&
                                            preguntasLoadView.filter(p => p.incluida == true).length > 0 ? ('Incluidas ' + preguntasLoadView.filter(p => p.incluida == true).length) : ('Incluidas 0')}
                                          /

                                          {preguntasLoadView && preguntasLoadView.length > 0 &&
                                            preguntasLoadView.filter(p => p.incluida == true).length > 0 ? ((genTestInfo.intervalosTestG[artActivo].interval[posPLVInter].preguntasDisponibles.length - preguntasLoadView.filter(p => p.incluida == true).length) + ' Random') : (genTestInfo.intervalosTestG[artActivo].interval[posPLVInter].preguntasDisponibles.length + ' Random')}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="bPLTV-contenedor" id="style-2">
                                      {preguntasLoadView && preguntasLoadView.length > 0 && preguntasLoadView.map((preg, key2N) =>


                                        <div className="bPLTV-rowPreg" id={'preg-' + key2N} key={key2N}>
                                          <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'flex-start', padding: '5px', width: '20%' }}>
                                            <i className="fas fa-eye" onClick={this.handleOnSubmit} id={'verPreguntaTestTeorico-' + key2N} />
                                            <div style={{ marginLeft: '10px', color: '#3469b7' }}>
                                              {'Art. ' + preg.articulo}
                                            </div>
                                          </div>
                                          <div title={preg.pregunta} style={{ maxWidth: '80%', width: '80%', padding: '5px' }}>
                                            {preg.pregunta.length > (window.innerWidth > 1150 ? (125) : (50)) ? (
                                              preg.pregunta.slice(0, (window.innerWidth > 1150 ? (125) : (50))) + '...'
                                            ) : (
                                              preg.pregunta
                                            )}
                                          </div>
                                          <div style={{
                                            display: 'flex',
                                            flexFlow: 'row wrap',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            padding: '5px',
                                            position: 'absolute',
                                            right: '40px',
                                            bottom: '-23px'
                                          }}>
                                            <div className="botonSlading" onClick={this.handleOnSubmit} id={"incluir-" + key2N} style={preg.incluida == true ? ({ direction: 'ltr', marginRight: '10px' }) : ({ direction: 'rtl', background: '#bebebe', marginRight: '10px' })}>
                                              <div className="circleBSlading" id={"incluir-" + key2N} style={preg.incluida == true ? ({}) : ({ background: '#fff' })}></div>
                                              <p id={"incluir-" + key2N}>{preg.incluida == true ? ('Incluida') : ('Random')}</p>
                                            </div>

                                            <div className="botonSlading" onClick={this.handleOnSubmit} id={"excluir-" + key2N} style={preg.excluida == true ? ({ direction: 'ltr', marginRight: '10px' }) : ({ direction: 'rtl', background: '#bebebe', marginRight: '10px' })}>
                                              <div className="circleBSlading" id={"excluir-" + key2N} style={preg.excluida == true ? ({}) : ({ background: '#fff' })}></div>
                                              <p id={"excluir-" + key2N}>{preg.excluida == true ? ('Excluida') : ('Permitida')}</p>
                                            </div>
                                          </div>

                                        </div>

                                      )}
                                    </div>


                                    {verPreguntaOnOff == true && preguntaTestLoad && preguntaTestLoad.length > 0 ? (
                                      <div style={{ position: 'absolute', top: '90px' }}>
                                        <div style={{ position: 'absolute', zIndex: '1', top: '28px', right: '38%' }}>
                                          <Boton id="cerrarPreguntaViewLive" icon1="" icon2="" texto="Cerrar Pregunta" onClick={this.handleOnSubmit} />
                                        </div>
                                        <PreguntaTestVerTeoria preguntaTestLoad={preguntaTestLoad} notificacion={null} />
                                      </div>
                                    ) : null}



                                  </div>
                                ) : null}

                              </div>



                            </React.Fragment>
                          ) : null}


                        </div>
                      ) : (
                        <div className="bGTN-row-Content2d">
                          <div className="titleMolon">

                            Temas para generar Test

                          </div>
                          <div style={{ margin: '10px' }}>
                            <Boton id="editar2" icon1="" icon2="" texto="editar" onClick={this.handleOnSubmit} />
                          </div>
                        </div>
                      )

                    ) : null
                    }



                  </div>
                )}
              </React.Fragment>
            ) : null
            }
            <datalist id="busquedaLeyes">{
              busquedaLeyes && busquedaLeyes.map((ley, key234) =>
                <option key={key234} value={ley.siglas_ley}>
                  {ley.nombre_ley}
                </option>
              )
            }</datalist>



            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo2">
                <option key={1} value={'Auxilio y Tramitacion'}></option>
            </datalist>


            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>

          </div >
        );
      }


    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    idClase: state.user.idClase,
    fotoPerfil: state.user.fotoPerfil,
    testJusticiaUserLoad: state.user.testJusticiaUserLoad,
    genTestInfo: state.user.genTestInfo,
    temasFacturados: state.user.temasFacturados,
    semanasFacturadas: state.user.semanasFacturadas,
    allSemFac: state.user.allSemFac,
    idOposicion: state.user.idOposicion
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cargarGenTest,
    genTestAddOpo,
    genTestEdtOpo,
    genTestDelOpo,
    genTestAddClase,
    genTestEdtClase,
    genTestDelClase,
    genTestEdtNumPregTotal,
    genTestEdtNameTest,
    genTestEdtRepetirPreg,
    genTestEdtTemporalizacionExp,
    genTestContinuar,
    genTestAddTema,
    genTestDelTema,
    genTestEdtTema,
    genTestAddIntervalo,
    genTestDelIntervalo,
    genTestEdtIntervalo,
    genTestDelIntervalos,
    genTestAddInt,
    genTestEdtTemaOpo,
    genTestEdtTotalPregAll,
    borrarTestUser,
    loadTestUser
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TestJusticia);

import React, { Component } from 'react';
import './css/Header.css';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// Actions
import { cerrarSesion, notificiacionesVistas, openViewBusArt } from '../../../actions/userAction';

class Header extends Component {
  static propTypes = {
    cerrarSesion: PropTypes.func.isRequired,
    nombreUser: PropTypes.string.isRequired,
    isLogged: PropTypes.bool.isRequired,
    optionSelected: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      notificaciones: undefined,
      notificacionUrgente: null,
      testNube: null
    };
    this.handleToggleClick = this.handleToggleClick.bind(this)
  }


  handleToggleClick(e) {
    if (e.target.id === 'cerrarSesion') {
      localStorage.clear()
      window.location.reload();
      return (<Redirect to="/" />);
    } else if (e.target.id === 'ToggleMenux3f') {
      $('.c_menu').toggleClass("menu-show")
    } else if (e.target.id == 'goEscritorio2') {
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/escritorio') : ('https://oposiciones-justicia.es/escritorio')
    } else if (e.target.id == 'irAnotificaciones') {
      this.setState({})
      window.location.href = window.location.href.split('www.').length > 1 ? ('https://www.oposiciones-justicia.es/Notificaciones') : ('https://oposiciones-justicia.es/Notificaciones')

    } else if (e.target.id == 'openNubeTest') {
      window.location.href = "https://oposiciones-justicia.es/testPendienteFinalizar"
    } else if (e.target.id == 'viewArtBus') {
      this.props.openViewBusArt(this.props.viewBusArt == null ? true : null)
    }





  }

  async componentDidMount() {

    try {
      // setTimeout(async() => {

      let data2x3 = {
        idUser: this.props.idUser
      }
      let response = await fetch('https://oposiciones-justicia.es/api/usuarios/testNube', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2x3)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;
      this.setState({ testNube: cls });

      let data2 = { idUser: this.props.idUser }

      let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificaciones", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response0.ok) {
        throw Error(response0.statusText);
      }
      let json0 = await response0.json();
      let cls0 = await json0;

      await this.setState({ notificaciones: cls0 });

      if (cls0 && cls0 != null && cls0 != undefined && cls0.length > 0) {

        if (cls0.filter(n => n.visualizado == 0 && n.urgente == 1).length > 0) {
          this.setState({ notificacionUrgente: true })
        } else {
          this.setState({ notificacionUrgente: null })
        }
      }
      if (cls0 && cls0 == null || cls0 == undefined) {
        this.props.notificiacionesVistas(0)
      } else if (cls0 && cls0 != null && cls0 != undefined && cls0.length > 0 && cls0.filter(n => n.visualizado == 0).length == 0) {
        this.props.notificiacionesVistas(0)
      } else if (cls0 && cls0 != null && cls0 != undefined && cls0.length == 0) {
        this.props.notificiacionesVistas(0)
      } else {
        this.props.notificiacionesVistas(1)
      }
      // }, 5000);
    } catch (e) { console.log(e) }
  }
  render() {
    const { notificacionAviso, fotoPerfil, idOposicion } = this.props
    const { notificacionUrgente, notificaciones, testNube } = this.state
    const pathname = window.location.pathname; //nos da el valor de location

    const optionSelected = this.props.optionSelected;

    if (this.props.isLogged != true) {
      return (
        <div id="Headerclic" className="Header_login">
          <div className="h_izquierda">
            <div className="h_logo_login">CEJ APP</div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="Headerclic" className="Header">
          {pathname && pathname.split('/')[pathname.split('/').length - 1] != 'Notificaciones' && notificacionUrgente && notificacionUrgente == true ? (
            <div className="h_derecha alertaVolando" id="irAnotificaciones" onClick={this.handleToggleClick}>
              {notificacionAviso && notificacionAviso != null && notificacionAviso != undefined && notificacionAviso == 1 ? (
                <Link style={{ position: 'relative' }} to="/Notificaciones" className="alertabODY" title="Notificaciones y correo">
                  <i id="notAlert" className="bell fa fa-bell" style={{ paddingRight: '0px', marginRight: '0px', color: '#fcfcfc' }}>

                  </i>

                </Link>
              ) : (
                <Link to="/Notificaciones" title="Notificaciones y correo">
                  <i className="bell fa fa-bell" style={{ paddingRight: '0px', marginRight: '0px', color: '#fcfcfc' }}></i>
                </Link>
              )}
              <p id="irAnotificaciones" className="alertabODYp" style={{ whiteSpace: 'nowrap', fontSize: '17px', color: '#e2e2e2' }}> Notificación urgente </p>

            </div>
          ) : null}

          {idOposicion != null && testNube != null && testNube.length > 0 ? (
            <div id="openNubeTest" className='ctlrDHnub RowCenterCenter' onClick={this.handleToggleClick}>
              <i id="openNubeTest" className="testNubeIconHeader fa-light fa-sensor-cloud" onClick={this.handleToggleClick}></i>
              <p id="openNubeTest" className="testNubeIconHeaderText" > Test guardado para finalizar más tarde. </p>
            </div>
          ) : null}

          {/* {idOposicion != null ? (
            <div id="viewArtBus" style={{left: '60px', bottom: '-41px'}} className='ctlrDHnub RowCenterCenter' onClick={this.handleToggleClick}>
              <i id="viewArtBus" className="testNubeIconHeader fa-sharp fa-light fa-folder-magnifying-glass" onClick={this.handleToggleClick}></i>
              <p id="viewArtBus" className="testNubeIconHeaderText" > Buscar un artículo </p>
            </div>
          ) : null} */}

          <div className="h_izquierda" style={{ border: '0px' }}>
            <div className="h_logo2" style={{ cursor: 'pointer' }} id="goEscritorio2" onClick={this.handleToggleClick}>
              <img src="../img/rev1LogoWeb.png" style={{ cursor: 'pointer' }} id="goEscritorio2" />
            </div>

            <div id="ToggleMenux3f" onClick={this.handleToggleClick}>
              <i id="ToggleMenux3f" className="fas fa-bars" ></i>
            </div>
           



          </div>
          <div className="h_derecha">
            {notificacionAviso && notificacionAviso != null && notificacionAviso != undefined && notificacionAviso == 1 ? (
              <Link style={{ position: 'relative' }} to="/Notificaciones" title="Notificaciones y correo">
                <i id="notAlert" className={notificacionUrgente && notificacionUrgente == true ? ("far fa-bell") : ("bell far fa-bell")}>

                </i>
                {notificaciones && notificaciones.length > 0 && notificaciones.filter(n => n.visualizado == 0).length > 0 ? (
                  <div className="numerosDeNotisSinLEER" id="facturacion" >
                    {notificaciones && notificaciones.length > 0 && notificaciones.filter(n => n.visualizado == 0).length}
                  </div>
                ) : null}
              </Link>
            ) : (
              <Link to="/Notificaciones" title="Notificaciones y correo">
                <i className="far fa-bell" style={{ fontSize: '16px' }}></i>
              </Link>
            )}
            <p style={{ fontSize: '17px' }}> Bienvenido {this.props.nombreUser} </p>

            <img className="imgPerfilHeader" src={fotoPerfil} />

            <div className="botonLogOut" id="cerrarSesion" onClick={this.handleToggleClick} title="Cerrar sesión">
              <i style={{ marginRight: 'unset' }} className="fas fa-sign-out-alt" id="cerrarSesion">
              </i>
            </div>



          </div>

        </div>
      );
    }
  }
}

// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    nombreUser: state.user.nombreUser,
    isLogged: state.user.isLogged,
    optionSelected: state.baroption.optionSelected,
    tipoUser: state.user.tipoUser,
    notificacionAviso: state.user.notificacionAviso,
    fotoPerfil: state.user.fotoPerfil,
    idUser: state.user.idUser,
    idOposicion: state.user.idOposicion,
    viewBusArt: state.user.viewBusArt
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cerrarSesion,
    notificiacionesVistas,
    openViewBusArt
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);

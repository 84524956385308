// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/inputTemasEdt.css';
import Input from '../Input';
import BotonMedio from '../BotonMedio';

class InputTemasEdt extends Component {

  static propTypes = {
    infoTemas: PropTypes.array
  };

constructor(props){
        super(props);
        this.state = {};
          this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(e){
        if(e.target.name == 'Tema'){
            let temaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema === e.target.value);
            if (temaObject != undefined){
              this.props.onChange(e);
            }
        } else {
          this.props.onChange(e);
        }
    }

    componentDidMount(){
      fetch('https://oposiciones-justicia.es/api/temas/consulta')
        .then(res => res.json())
          .then(temas => this.setState({
              busquedaTemas: temas
          }))
        .catch(error => console.log('Object fecth failed', error))
    };
  render() {
    const { infoTemas } = this.props;
    const { intervalos, busquedaTemas, iconLifeActive, iconLifeText} = this.state
    let num = 0;
    if(infoTemas.length > 0 && infoTemas != undefined){
              return (
                  <div>
                  {
                    infoTemas && infoTemas.map((tema, key) =>
                    <div className="bloqueLey" key={key}>
                          <div className="temas">
                                <div className="inputWithImgTema">
                                    <input id={key} type="text" className="textInputTipo" placeholder="Nombre Tema" value={tema.valueTema}
                                    name='Tema' ref='Tema' list="busquedaTemas" onChange={this.onChangeInput} readOnly={tema.readOnly}/>
                                      <i className="fas fa-search"></i>
                                </div>
                          </div>
                          <div className="temas">
                              <div className="inputWithImgTema">
                                <select id={key} name='TipoTema' ref='ref_TipoTema' className="textInputTipo" onChange={this.onChangeInput}>
                                  <option value="2">-Tipo de Tema-</option>
                                  <option value="0">Normal</option>
                                  <option value="1">Repaso</option>
                                </select>
                              </div>
                            </div>
                            <div className="temas">
                              <input type="radio" id={key} name={key+"editarBorrar"} value="1" onClick={this.onChangeInput}/>Editar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="2" onClick={this.onChangeInput}/>Borrar
                              <input type="radio" id={key} name={key+"editarBorrar"} value="3" onClick={this.onChangeInput}/>Nada
                            </div>
                        </div>
                    )}
                        <datalist id="busquedaTemas">{
                            busquedaTemas && busquedaTemas.map((tema, key) =>
                            <option key={key} value={tema.titulo_tema}></option>
                        )}</datalist>
                  </div>
              );
        }else{
          return (<div> </div>);
        }
        }
 }
// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default InputTemasEdt;

export default [
  {
    title: 'Test Justicia',
    url: '/Informatica/Test-Justicia',
    visible: true,
    nivelAcesso: 50,
    fontawesome: 'fas fa-gavel',
    subMenu: [
      {
        title: 'Insertar Test',
        url: 'Insertar_Test_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Test',
        url: 'Editar_Test_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Duplicar Test',
        url: 'Duplicar_Test_Template_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Borrar Test',
        url: 'Borrar_Test_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Añadir Pregunta',
        url: 'Añadir_Pregunta_Test_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Pregunta',
        url: 'Editar_Pregunta_Test_Justicia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Borrar Pregunta',
        url: 'Borrar_Pregunta_Test_Justicia',
        fontawesome: 'fas fa-plus'
      }
    ]
  },
  {
    title: 'Caso Practico',
    url: '/Informatica/Caso-Practico',
    visible: true,
    nivelAcesso: 50,
    fontawesome: 'fas fa-edit',
    subMenu: [
      {
        title: 'Insertar Caso P.',
        url: 'Insertar_Caso_Practico',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Caso P.',
        url: 'Editar_Caso_Practico',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Duplicar Caso P.',
        url: 'Duplicar_Caso_Practico',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Borrar Caso P.',
        url: 'Borrar_Caso_Practico',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar/Borrar Preguntas',
        url: 'Editar_Pregunta_Caso_Practico',
        fontawesome: 'fas fa-plus'
      }
    ]
  }
  ,
  {
    title: 'Noticias',
    url: '/Informatica/Noticias',
    visible: true,
    nivelAcesso: 50,
    fontawesome: 'fas fa-newspaper',
    subMenu: [
      {
        title: 'Añadir Noticia',
        url: 'Add_Noticia',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar Noticia',
        url: 'Edt_Noticia_Select',
        fontawesome: 'fas fa-plus'
      }
    ]
  }
];

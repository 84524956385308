// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/inputSelect.css';

class InputSelect extends Component {

  static propTypes = {
	  values: PropTypes.array.isRequired,
	  name: PropTypes.string.isRequired,
	  id: PropTypes.string,
	  title: PropTypes.string,
	  display: PropTypes.string,
	  size: PropTypes.string
  };

constructor(props){
        super(props);

          this.onChangeInput = this.onChangeInput.bind(this);
          this.onFFocus = this.onFFocus.bind(this);
          this.onBBlur = this.onBBlur.bind(this);
		this.onCleanValue = this.onCleanValue.bind(this);
    }
    onChangeInput(e){
        this.props.onChange(e);
    }
onCleanValue(e){
	if(e.detail === 3){
		this.refs[''+e.target.name].value = ''
		this.props.onChange(e)
	}
}
    onFFocus(){
      if(this.props.onFocus != undefined){
        this.props.onFocus();
        }
    }
    onBBlur(){
      if(this.props.onFocus != undefined){
        this.props.onBlur();
        }
    }
   /* values =[{
	    value: ,
	    name:,
	    disable:,
	    selected: ,
    }]*/

  render() {
    const { values, id, name, icon, display, size, title } = this.props;
              return (
                <div className="inputWithImg">
			   <select className="textInputSelect" title={title} id={id} name={name} ref={name} size={size} style={{display: display}} onChange={this.onChangeInput} onClick={this.onCleanValue} onFocus={this.onFFocus} onBlur={this.onBBlur} defaultValue="">
			   	{values && values.map((value, key) =>
						<option key={key} value={value.value} disabled={value.disable}>{value.name}</option>
				)}
				</select>
				<i className={icon} style={{display: display}} id={name}></i>
                </div>
              );

        }
 }
/*
<option key={key} value={value.value} disabled={value.disable} selected={value.selected}>{value.name}</option>
{value.selected == 'selected' ? (
	<option key={key} value={value.value} disabled={value.disable} selected>{value.name}</option>
):(<option key={key} value={value.value} disabled={value.disable}>{value.name}</option>)}
*/
export default InputSelect;

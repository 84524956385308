// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'
// Actions
import BotonFinolis from '../../../../../components/Inputs/Boton3Finolis'
import Input2 from '../../../../../components/Inputs/Input2'
import { crearCursoAlumno, crearAlumnoPresencial, crearAlumnoVideoPresencial, crearCursoAlumnoExiste, crearAlumnoPresencialExiste, crearAlumnoVideoPresencialExiste } from '../../../../../lib/utils/inscripcionAlumnos'
// Assets
import './css/css.css';



class Tarjeta3mode11 extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    this.state = {
      viewVideo: false,
      loca: (this.props.tipoUser < 50 && this.props.isLogged) ? '2' : '0',
      loca2: null,
      loca3: null,
      normasViewFin: null,
      infoReserva: {
        dni: '',
        nombre: '',
        apellidos: '',
        telefono: '',
        nombreUsuario: '',
        email: '',
        codigoPostal: '',
        ciudad: '',
        direccion: '',
        pass1: '',
        pass2: '',
        passHashB: ''
      }
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }



  async onHandleClick(e) {
    if (e.target.id == 'videoInfo') {

      this.setState({ viewVideo: !this.state.viewVideo })

    } else if (e.target.id.split('-')[0] == 'check') {


      let locaNew = e.target.id.split('-')[1]
      if (this.state.loca == locaNew) {

      } else {
        this.setState({ loca: locaNew })
      }



    } else if (e.target.id.split('-')[0] == 'check2') {


      let locaNew = e.target.id.split('-')[1]
      if (this.state.loca2 == locaNew) {

      } else {
        this.setState({ loca2: locaNew })
      }



    } else if (e.target.id.split('-')[0] == 'check3') {


      let locaNew = e.target.id.split('-')[1]
      if (this.state.loca3 == locaNew) {

      } else {
        this.setState({ loca3: locaNew })
      }



    } else if (e.target.id == 'rechazarYretroceder') {

      this.setState({ normasViewFin: null })

    } else if (e.target.id == 'contratar') {
      let infoReservaS = this.state.infoReserva

      if (!this.props.isLogged || (this.props.tipoUser >= 50 && this.props.isLogged)) {

        let data = { dni: infoReservaS.dni }
        let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/dniDisponible", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        if (cls1.length == 0 && infoReservaS.dni.length >= 8) {
          if (infoReservaS.pass2 == infoReservaS.pass1 && infoReservaS.pass1.length >= 6) {


            let emailRegx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            if (emailRegx.test(infoReservaS.email)) {

              let hash = bcrypt.hashSync(infoReservaS.pass1, 8);
              infoReservaS.passHashB = hash
              this.setState({ normasViewFin: true, infoReserva: infoReservaS })
            } else {
              this.setState({ loca: '0' })
              swal('El email es incorrecto', 'Vuelva a introducir un email correcto', 'info')
            }

          } else {
            if (infoReservaS.pass2 != infoReservaS.pass1) {
              swal('Error', 'Las contraseñas no son identicas,rectifiquelo en el paso 2.', 'info')
            }
            if (infoReservaS.pass1.length < 6) {
              swal('Error', 'La constraseña debe tener como minimo 6 caracteres.', 'info')
            }
          }
        } else {
          if (cls1.length != 0) {
            swal('Error', 'Este usuario ya existe, DNI registrado. Si no tiene cuenta y este es su dni llame a la academia para resolver el problema.', 'info')
          }
          if (infoReservaS.dni.length < 8) {
            swal('Error', 'Introduzca un dni correcto', 'info')
          }
        }
      } else {
        this.setState({ normasViewFin: true, infoReserva: infoReservaS })
      }




    } else if (e.target.id == 'aceptarYprocesar') {
      let infoProceso
      swal({
        title: 'Procesando inscripción',
        text: ' ',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      // si esta logeado hay que crear nueva funciones apuntar a curso o clase sin crear el alumno ya existe
      // function validarEmail(valor) {
      //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/.test(valor)){
      //    alert("La dirección de email " + valor + " es correcta.");
      //   } else {
      //    alert("La dirección de email es incorrecta.");
      //   }


      // }
      if (this.props.info.url2TipoPreparacion == "Cursos_Online") {
        if ((!this.props.isLogged) || (this.props.isLogged && this.props.tipoUser >= 50)) {
          infoProceso = await crearCursoAlumno(this.props.info, this.state.infoReserva)
        } else {
          infoProceso = await crearCursoAlumnoExiste(this.props.info, this.state.infoReserva, this.props.idUser)
        }

      } else if (this.props.info.url2TipoPreparacion == "Presencial") {
        if ((!this.props.isLogged) || (this.props.isLogged && this.props.tipoUser >= 50)) {
          let idClase = this.props.info.infoClases[this.state.loca2].idClase
          infoProceso = await crearAlumnoPresencial(this.props.info, this.state.infoReserva, idClase)
        } else {
          let idClase = this.props.info.infoClases[this.state.loca2].idClase
          infoProceso = await crearAlumnoPresencialExiste(this.props.info, this.state.infoReserva, idClase, this.props.idUser)
        }

      } else if (this.props.info.url2TipoPreparacion == "Online_Video_Presencial") {

        if ((!this.props.isLogged) || (this.props.isLogged && this.props.tipoUser >= 50)) {
          let idClase = this.props.info.infoClases[this.state.loca2].idClase
          infoProceso = await crearAlumnoVideoPresencial(this.props.info, this.state.infoReserva, idClase)
        } else {
          let idClase = this.props.info.infoClases[this.state.loca2].idClase
          infoProceso = await crearAlumnoVideoPresencialExiste(this.props.info, this.state.infoReserva, idClase, this.props.idUser)
        }
      }


      if (infoProceso == true) {
        swal({
          title: 'Alta procesada',
          text: 'Ya puede acceder al campus virtual, ¡Recargando web!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 3000,
        }).then(() => {
          window.location.href = 'https://oposiciones-justicia.es/'
        })
      } else {
        swal('Error', 'No se puedo procesar la solicitud, vuelva a intentarlo más tarde. Si no consigue finalizar el proceso pongase en contacto con la academia.', 'info')
      }






      // funciones para generar
      // 1 - Curso online por X meses + Primera semana gratis Tipo 10!
      // Se crear factura del curso, sino esta pagada el modo prueba le deja cargar solo el tema 1 y 2
      // Puede usar todo con esos temas

      // 2 - Presencial tipo 1
      // Reserva de plaza
      // Apuntarse a lista de espera "clases_alumnos tenemos listaEsperaReserva: 0 1 2"
      // 0 --> no lista 1 --> lista espera 3 --> proceso reserva de plaza
      // Apuntarse a proxima apertura de grupo
      // 3- Online presencial tipo 1
      // igual que presencial

    } else if (e.target.id == 'infoSemanaGratis') {

      let info = this.props.info

      let name = info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial" ? (
        'CLASE DE PRUEBA GRATIS'
      ) : (
        '1ª SEMANA GRATIS'
      )
      let content = document.createElement('div');
      content.innerHTML = info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial" ? (
        `
      Asista a la 1ª clase. 
      </br></br>
      Pruebe nuestro metodo de preparación, campus virtual y conozca a su preparador.
      `
      ) : (
        `
      Accede a la 1ª semana del contenido sin ningún coste. 
      </br></br>
      Pruebe nuestro metodo de preparación, campus virtual y conozca a su preparador.
      </br></br>
      No le pediremos ningún metodo de pago hasta finalizar el periodo de prueba. 
      </br></br>
      Sí quiere desbloquear todo el contenido durante el periodo de prueba, podrá realizar el pago desde su aula virtual.
      `
      )
      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    } else if (e.target.id == 'infoReserva') {
      let loca2Ctrl = this.state.loca2
      let name = "Reserva de plaza";
      let content = document.createElement('div');
      content.innerHTML = `
      Haga clic sobre RESERVAR PLAZA, para completar el proceso de reserva <b>sin ningun coste</b>.
      </br></br>
      La academia contactará con usted para confirmar su alta en 1-3 días.
      </br></br>
      
      `
      if (loca2Ctrl == null || loca2Ctrl == '0') { loca2Ctrl = 0 }
      if (this.props.info.infoClases[loca2Ctrl].totalAlumnosActivos >= this.props.info.infoClases[loca2Ctrl].maxAlumnos) {

        name = "Lista de espera";
        content = document.createElement('div');
        content.innerHTML = `
        Haga clic sobre Lista de espera, para ponerse en cola de plaza disponible <b>sin ningun coste</b>.
        </br></br>
        En cualquier momento podrá cancelar el proceso de lista de espera y reserva.
        </br></br>
        La academia contactará con usted para confirmar su alta en la lista de espera, así como cuando su plaza este disponible.
        </br></br>
        
        `
      }

      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    }
  }
  onChangeInput(e) {

    if (e.target.id == 'dni') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.dni = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'nombre') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.nombre = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'apellidos') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.apellidos = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'telefono') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.telefono = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'user') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.nombreUsuario = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'email') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.email = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'codigoPostal') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.codigoPostal = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'ciudad') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.ciudad = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'direccion') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.direccion = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'passwordNew1') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.pass1 = e.target.value
      this.setState({ infoReserva: infoReservaS })
    } else if (e.target.id == 'passwordNew2') {
      let infoReservaS = this.state.infoReserva
      infoReservaS.pass2 = e.target.value
      this.setState({ infoReserva: infoReservaS })
    }

  }
  async componentDidMount() {
    if (this.props.tipoUser < 50 && this.props.isLogged) {
      let data = { idUser: this.props.idUser }
      let response = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaAlumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;
      if (cls.length > 0) {
        let infoReservaS = this.state.infoReserva
        infoReservaS.dni = cls[0].dni
        infoReservaS.nombre = cls[0].nombre
        infoReservaS.apellidos = cls[0].apellidos
        infoReservaS.telefono = cls[0].telefono
        infoReservaS.nombreUsuario = cls[0].nombre_usuario
        infoReservaS.email = cls[0].email
        infoReservaS.codigoPostal = cls[0].codigo_postal
        infoReservaS.ciudad = cls[0].ciudad
        infoReservaS.direccion = cls[0].direccion
        infoReservaS.pass1 = 'xxx'
        infoReservaS.pass2 = 'xxx'

        this.setState({ infoReserva: infoReservaS })
      }
    }
  }

  async componentDidUpdate() {
    if (this.props.tipoUser < 50 && this.props.isLogged && this.state.infoReserva.dni == '') {
      let data = { idUser: this.props.idUser }
      let response = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaAlumno", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;
      if (cls.length > 0) {
        let infoReservaS = this.state.infoReserva
        infoReservaS.dni = cls[0].dni
        infoReservaS.nombre = cls[0].nombre
        infoReservaS.apellidos = cls[0].apellidos
        infoReservaS.telefono = cls[0].telefono
        infoReservaS.nombreUsuario = cls[0].nombre_usuario
        infoReservaS.email = cls[0].email
        infoReservaS.codigoPostal = cls[0].codigo_postal
        infoReservaS.ciudad = cls[0].ciudad
        infoReservaS.direccion = cls[0].direccion
        infoReservaS.pass1 = 'xxx'
        infoReservaS.pass2 = 'xxx'

        this.setState({ infoReserva: infoReservaS })
      }
    }
  }

  // ofertaMensualidad: ""
  // precioMensualidad: ""
  // tituloLeft: "Curso Online"
  // tituloRight1: ""
  // tituloRight2: ""
  // url2TipoPreparacion: "Cursos_Online"
  // url3Oposicion: "Aux-Tra-libre"
  // videoUrl: ""
  render() {
    let { info, isLogged, tipoUser } = this.props
    let { viewVideo, loca, loca2, loca3, infoReserva, normasViewFin } = this.state

    console.log('infoReserva')
    console.log(infoReserva)
    return (

      <div className='CC4-tarjeta'>

        {normasViewFin != null ? (
          <div className='tablaNormasCejProcesoAlta'>

            <div class="scrollbarTienda" id="style-2">
              <b className='tNCAtitles21'>Normas Academia</b>

              <div className='tNCOA-nav'>
                <div>
                  <BotonFinolis texto={'Aceptar'} id="aceptarYprocesar" onClick={this.onHandleClick} />
                </div>
                <div>
                  <BotonFinolis texto={'Rechazar'} id="rechazarYretroceder" onClick={this.onHandleClick} />
                </div>
              </div>
              {
                info && info.url2TipoPreparacion != 'Cursos_Online' ? (
                  <div className='tNCA-infoTexto'>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Los pagos son por mensualidades completas y se realizan del 1 al 5 del mes. Mediante Tarjeta, Google Pay, Transferencia SEPA através del campus virtual ó Bizum y pago en Metálico en la Academia. La cuota abonada no se devolverá si el alumno abandona antes de finalizar el mes.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        En caso de desear dejar de asistir a clases el mes siguiente, deberá comunicarlo con 5 días de antelación del
                        inicio del mes. De no hacerlo así, deberá abonar la mensualidad entera correspondiente.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La última semana del año y Semana Santa las clases son Online, la preparación se realiza con los materiales y
                        videos que tendrá disponibles en el Aula virtual. El mes de agosto las clases son Online por video conferencia
                        las dos primeras semanas.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Los festivos de ámbito Nacional o Local se consideran días no lectivos, la preparación se realiza con los
                        materiales y videos que tendrá disponibles en el Aula virtual.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
                        dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
                      </p>
                    </div>

                  </div>
                ) : (
                  <div className='tNCA-infoTexto'>
                    {/* <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La primera semana del curso, es totalmente gratís. Con la finalizadad de probar el metodo de estudio y preparación. Una vez expirado el periodo de prueba puede decidir si realizar el pago y continuar la preparación.
                      </p>
                    </div> */}
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        El usuario declara que conoce:
                      </p>
                    </div>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Las características principales de los bienes o servicios, en la medida adecuada al soporte utilizado y a los bienes o servicios de que se trate: Curso Online para preparación de oposiciones de justicia, mediante uso de plataforma virtual que contiene vídeos, resúmenes, test, casos prácticos, y en su caso planificación del estudio.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La identidad del empresario, incluido su nombre comercial: Bárbara Alaminos Castillo, con nombre comercial Centro de Estudios Judiciales Málaga.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La dirección completa del establecimiento del empresario, número de teléfono y dirección de correo electrónico: Calle Pirandello 16, 3ª planta, Oficina 6, Málaga, teléfono 688 98 80 99 y email centrodeestudiosjudiciales@gmail.com
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Una vez realizado el pago,tendrá acceso total a todo el contenido del curso. La cantidad abonada no se devolverá, aunque el alumno abandone antes de finalizar el curso.
                      </p>
                    </div>
                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        DESISTIMIENTO DEL PRESENTE CONTRATO DE PRODUCTOS O SERVICIOS EN SOPORTE DIGITAL.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />

                      <p>
                        Se comunica a los efectos oportunos que la posibilidad de desistimiento prevista en la Ley General para la Defensa de Consumidores y Usuarios no es aplicable al curso online que está contratando, por resultar aplicable el artículo 103.a de la citada Ley, una vez realice el primer acceso a la plataforma digital con sus claves personales, ya que implica el cumplimiento, ejecución, total del contrato por parte de CEJ.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Podrá realizar el pago através del campus virtual. Metodos de pago: Page en 3 plazos sin intereses o un unico pago con Tarjeta, Google Pay ó Transferencia SEPA.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        Si desea realizar el pago presencialmente acudiendo a nuestra academia en Málaga Teatinos junto al palacio de justicia, tendrá las opciones de Bizum y pago en Metálico.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        La preparación se realiza con los materiales y videos que tendrá disponibles en el Campus Virtual www.oposiciones-justicia-es.
                      </p>
                    </div>

                    <div className='celdaInfosv2df54'>
                      <i className='fa-light fa-caret-right' />
                      <p>
                        El uso de la plataforma, usuario y contraseña es personal e intransferible, en el supuesto de cesión de
                        dichos datos a terceros dará lugar al cese en la misma sin derecho a devolución de cantidad alguna.
                      </p>
                    </div>

                  </div>
                )}

            </div>


          </div>
        ) : (
          <React.Fragment>

            <div className="CC4-t-top">

              <div className="CC4-t-t-title">
                {info && info.tituloLeft}
                <p id="infoSemanaGratis">
                  {/* {
                    info && (info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial") ? null : (
                      'PAGO FINANCIADO 0%'
                    )
                  } */}
                </p>
                {/* <p id="infoSemanaGratis" onClick={this.onHandleClick}>
                  {
                    info && (info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial") ? (
                      'CLASE DE PRUEBA GRATIS'
                    ) : (
                      '1ª SEMANA GRATIS'
                    )
                  }
                  <i className="fa-light fa-circle-info" id="infoSemanaGratis"></i>
                </p> */}
              </div>

              <div className="CC4-t-t-info">
                <div className="CC4-t-t-resumen">
                  <div className="CC4-t-t-rs-title">
                    Resumen preparación
                  </div>
                  <div className="CC4-t-t-rs-content">
                    <div><p>Oposición</p>
                      <b>{info && info.nameOpo}</b>
                    </div>
                    <div><p>Modo preparación</p>
                      <b>
                        {info && info.tituloLeft}
                        {info && info.duracionCurso != '' && info.duracionCurso != null ? (
                          ' - ' + info.duracionCurso
                        ) : null}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="CC4-t-t-requisitos">
                  <div className="CC4-t-t-re-nav">
                    <div className="CC4-t-t-re-nav-title">
                      {!this.props.isLogged || (this.props.tipoUser >= 50 && this.props.isLogged) ? (
                        'Rellene los siguientes campos'
                      ) : null}

                      {this.props.isLogged && this.props.tipoUser < 50 && info && info.url2TipoPreparacion != 'Cursos_Online' ? (
                        'Rellene los siguientes campos'
                      ) : null}
                    </div>
                    <div className="CC4-t-t-re-nav-etapas">
                      {
                        (!isLogged || (tipoUser >= 50 && isLogged)) ? (
                          <div id={'check-0'} className={loca && loca != null && loca == '0' ? ("CC4-t-t-re-n-et-button CC4-t-t-re-n-et-buttonOpen") : ("CC4-t-t-re-n-et-button")} onClick={this.onHandleClick}>
                            1
                            {infoReserva.dni != '' &&
                              infoReserva.nombre != '' &&
                              infoReserva.apellidos != '' &&
                              infoReserva.telefono != '' &&
                              infoReserva.nombreUsuario != '' && infoReserva.email != '' ? (
                              <i id={'check-0'} style={{ color: '#21a221' }} className="fa-light fa-comment-check flyingd4d4" />
                            ) : (
                              <i id={'check-0'} style={{ color: '#c05858' }} className="fa-light fa-comment-pen flyingd4d42" />
                            )}
                          </div>
                        ) : null
                      }


                      {
                        (!isLogged || (tipoUser >= 50 && isLogged)) ? (
                          <div id={'check-1'} className={loca && loca != null && loca == '1' ? ("CC4-t-t-re-n-et-button CC4-t-t-re-n-et-buttonOpen") : ("CC4-t-t-re-n-et-button")} onClick={this.onHandleClick}>
                            2

                            {infoReserva.codigoPostal != '' &&
                              infoReserva.ciudad != '' &&
                              infoReserva.direccion != '' &&
                              infoReserva.pass1 != '' &&
                              infoReserva.pass2 != '' && infoReserva.pass1 == infoReserva.pass2 ? (
                              <i id={'check-1'} style={{ color: '#21a221' }} className="fa-light fa-comment-check flyingd4d4" />
                            ) : (
                              <i id={'check-1'} style={{ color: '#c05858' }} className="fa-light fa-comment-pen flyingd4d42" />
                            )}
                          </div>
                        ) : null
                      }


                      {
                        info && info.url2TipoPreparacion != 'Cursos_Online' ? (
                          <div id={'check-2'} className={loca && loca != null && loca == '2' ? ("CC4-t-t-re-n-et-button CC4-t-t-re-n-et-buttonOpen") : ("CC4-t-t-re-n-et-button")} onClick={this.onHandleClick}>
                            {(!isLogged || (tipoUser >= 50 && isLogged)) ? ('3') : ('1')}
                            {loca2 && loca2 != null ? (
                              <i id={'check-2'} style={{ color: '#21a221' }} className="fa-light fa-comment-check flyingd4d4" />
                            ) : (
                              <i id={'check-2'} style={{ color: '#c05858' }} className="fa-light fa-comment-pen flyingd4d42" />
                            )}
                          </div>
                        ) : null
                      }



                    </div>
                  </div>

                  <div className="CC4-t-t-re-content">
                    {
                      (!isLogged || (tipoUser >= 50 && isLogged)) && loca != null && loca == '0' ? (

                        <div className="CC4-t-t-re-content-ctrl">

                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.dni} autoComplete={"off"} type="text" id="dni" name="dni" placeholder="DNI" icon="fa-light fa-id-card" ref="ref_dni" onChange={this.onChangeInput} />
                            <p>DNI</p>
                          </div>
                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.nombre} autoComplete={"off"} type="text" name="nombre" id="nombre" placeholder="Nombre" icon="fa-light fa-user-graduate" ref="ref_nombre" onChange={this.onChangeInput} />
                            <p>Nombre</p>
                          </div>
                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.apellidos} autoComplete={"off"} type="text" id="apellidos" name="apellidos" placeholder="Apellidos" icon="fa-light fa-id-card" ref="ref_apellidos" onChange={this.onChangeInput} />
                            <p>Apellidos</p>
                          </div>



                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.telefono} autoComplete={"off"} type="text" name="telefono" id="telefono" placeholder="Telefono" icon="fa-light fa-phone-flip" ref="ref_telefono" onChange={this.onChangeInput} />
                            <p>Telefono</p>
                          </div>

                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.nombreUsuario} autoComplete={"off"} type="text" name="user" id="user" placeholder="Nombre Usuario" icon="fa-light fa-user-tag" ref="ref_user" onChange={this.onChangeInput} />
                            <p>Nombre Usuario</p>
                          </div>

                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.email} autoComplete={"new-password"} type="email" name="email" id="email" placeholder="Correo Electrónico" icon="fa-thin fa-envelope" ref="ref_email" onChange={this.onChangeInput} />
                            <p>Correo Electrónico</p>
                          </div>
                        </div>

                      ) : null

                    }

                    {
                      (!isLogged || (tipoUser >= 50 && isLogged)) && loca && loca != null && loca == '1' ? (

                        <div className="CC4-t-t-re-content-ctrl">
                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.codigoPostal} autoComplete={"off"} type="text" id="codigoPostal" name="codigoPostal" placeholder="Codigo Postal" icon="fa-light fa-magnifying-glass-location" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                            <p>Codigo Postal</p>
                          </div>
                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.ciudad} autoComplete={"off"} type="text" name="ciudad" id="ciudad" placeholder="Ciudad" icon="fa-light fa-location-dot" ref="ref_ciudad" onChange={this.onChangeInput} />
                            <p>Ciudad</p>
                          </div>
                          <div className="celdaInputDAPFBN inputFDJSKJS" >
                            <Input2 value={infoReserva.direccion} autoComplete={"at"} type="text" name="direccion" id="direccion" placeholder="Dirección" icon="fa-light fa-mailbox" ref="ref_direccion" onChange={this.onChangeInput} />
                            <p>Dirección</p>
                          </div>

                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.pass1} autoComplete={"new-password"} type="password" id="passwordNew1" name="passwordNew1" placeholder="Contraseña" icon="fa-light fa-key-skeleton" ref="ref_passwordNew1" onChange={this.onChangeInput} />
                            <p>Contraseña</p>
                          </div>

                          <div className="celdaInputDAPFBN inputFDJSKJS">
                            <Input2 value={infoReserva.pass2} autoComplete={"new-password"} type="password" id="passwordNew2" name="passwordNew2" placeholder="Confirmarción" icon="fa-light fa-key-skeleton" ref="ref_passwordNew2" onChange={this.onChangeInput} />
                            <p>Confirmar Contraseña</p>
                          </div>
                        </div>
                      ) : null

                    }


                    {
                      loca && loca != null && loca == '2' && info && info.url2TipoPreparacion != 'Cursos_Online' ? (
                        <div className='CC4-t-t-re-content-StartOf'>
                          {info && info.url2TipoPreparacion == 'Presencial' ? (
                            <p>Elija el día para asistir a clase presencial.</p>
                          ) : (
                            <p>Elija el día para asistir a clase online </p>
                          )}

                          <div className='CC4-t-t-re-content-StartOf-cheks'>
                            {
                              info && info.infoClases.length > 0 && info.infoClases.map((cls, key23) =>
                                <div className='CC3-T-b-r-c-Check-curso-row' id={"check2-" + key23} onClick={this.onHandleClick} >
                                  <div className={key23 != loca2 ? ('CC3-T-b-r-c-C2-check') : ('CC3-T-b-r-c-C2-check2 checkTru')} id={"check2-" + key23}></div>
                                  {/* className='CC3-T-b-r-c-C2-check checkTru' */}
                                  <div className='CC4-T-b-r-c-C2-check-text-title1' id={"check2-" + key23}>

                                    {cls.diaSemanalDeClase}

                                    {cls.tipoClase == 'PRESENCIAL' ? (
                                      <p id={"check2-" + key23}>

                                        {cls.horaInicio.split(':')[0] + ':' + cls.horaInicio.split(':')[1]}
                                        {' a '}
                                        {cls.horaFin.split(':')[0] + ':' + cls.horaFin.split(':')[1]}
                                        {/* { ', online '+ 
                                    cls.diaSemanalOnline + '-' +
                                    cls.horaDeClaseOnline.split(':')[0] + ':' + cls.horaDeClaseOnline.split(':')[1]
                                    } */}

                                      </p>
                                    ) : (
                                      <p id={"check2-" + key23}>

                                        {cls.horaDeClaseOnline.split(':')[0] + ':' + cls.horaDeClaseOnline.split(':')[1]}


                                      </p>
                                    )}

                                  </div>
                                </div>
                              )
                            }

                          </div>
                        </div>
                      ) : null

                    }



                  </div>
                </div>
              </div>

            </div>

            <div className="CC4-t-bottom">
              <div className="CC4-t-t-b-top">
                Métodos de pago
              </div>
              <div className="CC4-t-t-b-middle">
                {
                  info && info.url2TipoPreparacion == 'Cursos_Online' ? (
                    <React.Fragment>
                      <p className="CC4-t-t-b-middle-p">Una vez tramitada el alta tendrá acceso al campus, donde podrá realizar el pago de las siguientes formas:</p>

                      <div className="CC4-t-t-b-div"> <b>PAGO ÚNICO</b> mediante tarjeta o disponibles, puede llamarnos al 688 98 80 99.</div>
                        {/* |  PAGO EN 3 PLAZOS</b> 0% intereses </div> */}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p className="CC4-t-t-b-middle-p">

                        Una vez completada la reserva, tendrá acceso al campus virtual, donde podrá ver el estado de reserva de plaza. <br />
                        Tras la confirmación, podrá realizar el pago de las siguientes formas:</p>


                      <div className="CC4-t-t-b-div"> <b>Tarjeta | GooglePay </b></div>
                    </React.Fragment>
                  )
                }

              </div>
              <div className="CC4-t-t-b-bottom">


                {info && info.url2TipoPreparacion == 'Cursos_Online' ? (
                  <div className="CC4-t-t-b-b-left">
                     <p> Pago total {info && info.ofertaPrecio}</p>
                  </div>
                ) : ( null
                  // <div className="CC4-t-t-b-b-left">
                  //   Ahora {info && info.ofertaPrecio} <p>Antes {info && info.precio}</p>
                  // </div>
                )}



                <div className="CC4-t-t-b-b-right">
                  {
                    info && info.url2TipoPreparacion != 'Cursos_Online' ? (


                      loca2 != null ? (
                        <div className='CC3-T-b-r-navButtons2' style={{ marginRight: '10px' }}>
                          <i className="fa-light fa-circle-info" id="infoReserva" onClick={this.onHandleClick}></i>

                          <BotonFinolis texto={info.infoClases[loca2].totalAlumnosActivos < info.infoClases[loca2].maxAlumnos ? ('Reservar plaza') : ('Lista de espera')} id="contratar" onClick={this.onHandleClick}
                            disabled={
                              (infoReserva.dni != '' &&
                                infoReserva.nombre != '' &&
                                infoReserva.apellidos != '' &&
                                infoReserva.telefono != '' &&
                                infoReserva.nombreUsuario != '' && infoReserva.email != '' &&
                                infoReserva.codigoPostal != '' &&
                                infoReserva.ciudad != '' &&
                                infoReserva.direccion != '' &&
                                infoReserva.pass1 != '' &&
                                infoReserva.pass2 != '' &&
                                infoReserva.pass1 == infoReserva.pass2) || (isLogged && tipoUser < 40)
                                ? (false) : (true)
                            }
                            infoDisabled="Complete todos los campos para terminar el proceso." />
                        </div>
                      ) : (
                        <div className='CC3-T-b-r-navButtons2' style={{ marginRight: '10px' }}>
                          <i className="fa-light fa-circle-info" id="infoReserva" onClick={this.onHandleClick}></i>

                          <BotonFinolis texto={'Reservar plaza'} id="contratar" onClick={this.onHandleClick}
                            disabled={true}
                            infoDisabled="Complete todos los campos para completar el proceso de reserva." />
                        </div>
                      )

                    ) : null

                  }

                  {
                    info && info.url2TipoPreparacion == 'Cursos_Online' ? (
                      <BotonFinolis texto={'Procesar Alta'} id="contratar" onClick={this.onHandleClick}
                        disabled={
                          (infoReserva.dni != '' &&
                            infoReserva.nombre != '' &&
                            infoReserva.apellidos != '' &&
                            infoReserva.telefono != '' &&
                            infoReserva.nombreUsuario != '' && infoReserva.email != '' &&
                            infoReserva.codigoPostal != '' &&
                            infoReserva.ciudad != '' &&
                            infoReserva.direccion != '' &&
                            infoReserva.pass1 != '' &&
                            infoReserva.pass2 != '' &&
                            infoReserva.pass1 == infoReserva.pass2) || (isLogged && tipoUser < 40)
                            // &&
                            // loca2 && loca2 != null &&
                            // loca3 && loca3 != null
                            ? (false) : (true)
                        }
                        infoDisabled="Complete todos los campos para terminar el proceso." />
                    ) : null
                  }


                </div>
              </div>
            </div>
          </React.Fragment>
        )}

      </div>
    );

  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser
  }
}

export default connect(mapStateToProps, null)(Tarjeta3mode11);
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Redux
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'

// Assets
import './css.css';
import Boton from '../../../Inputs/Boton';


class VideoViewTema extends Component {
  static propTypes = {
    videos: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      vidvIEW: 0,
      trackLang: false
    };
    this.onHandleClick = this.onHandleClick.bind(this);
    this.playerRef = React.createRef();

  }


  async onHandleClick(e) {
    if (e.target.id == "cerrarVideo") {

      this.props.onClick(e)


    } else if (e.target.id.split('-')[0] == 'navV') {
      const player = this.playerRef.current.getInternalPlayer();
      const tracks = player?.textTracks;
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].kind == 'subtitles') {
          tracks[i].mode = 'disabled';
        }
      }

      this.setState({ vidvIEW: e.target.id.split('-')[1], trackLang: false })

    } else if (e.target.id == 'subtitulos') {
      const player = this.playerRef.current.getInternalPlayer();
      const tracks = player?.textTracks;

      if (tracks[0].mode == 'disabled') {
        tracks[0].mode = 'showing';
        this.setState({ trackLang: true })
      } else {
        tracks[0].mode = 'disabled';
        this.setState({ trackLang: false })
      }

    }


  }

  render() {
    const { vidvIEW , trackLang} = this.state;
    const { videos } = this.props;

    return (
      <div className='edtTemaNe-adm'>

        <div className='eTNe-a-contentDFG4'>
          <i className="fa-light fa-circle-xmark botoncewrralc2" id="cerrarVideo" onClick={this.onHandleClick}></i>
          {videos && videos.length > 0 ? (
            <div className='navVidCdtremall'>
              <b>Videos</b>
              {videos.map((video, key89) =>

                <div id={"navV-" + key89} onClick={this.onHandleClick} className={key89 == vidvIEW ? ('circleVideoNav circleVideoNavSelectdg3') : ('circleVideoNav')}>
                  {key89 + 1}
                </div>
              )}


            </div>
          ) : null}
          {videos && videos.length > 0 ? (

            <div className="videoAACDFLKW2" onContextMenu={e => e.preventDefault()}>
              <ReactPlayer
                key={vidvIEW}
                ref={this.playerRef}
                url={videos[vidvIEW].link}
                className='react-player'
                playing={false}
                width='100%'
                height='100%'
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'anonymous',
                      controlsList: 'nodownload'
                    },
                    tracks: [
                      {
                        kind: 'subtitles',
                        src: 'https://oposiciones-justicia.es' + videos[vidvIEW].linkSubtitulos?.split('..')[1],
                        srcLang: 'es',
                        label: 'Español',
                        default: false
                      }
                    ]
                  }
                }}

                controls={true}
                onContextMenu={e => e.preventDefault()}
              />

              {
                videos[vidvIEW].linkSubtitulos !== null ? (

                  <div className='barTD2ert-navAdmin' style={{ justifyContent: 'center', marginTop: '10px' }}>
                      <Boton id="subtitulos" title={trackLang == false ? 'Activar Subtitulos':'Desactivar Subtitulos'} icon1="" icon2="" texto={trackLang == false ? 'Activar Subtitulos':'Desactivar Subtitulos'} onClick={this.onHandleClick} />
                  </div>
                ) : null
              }

            </div>

          ) : (
            <div className="recPlayVidDivNovideo">
              Video no disponible
            </div>
          )

          }


        </div>

      </div >
    );
  }
}

// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    temasFacturados: state.user.temasFacturados,
    tipoUser: state.user.tipoUser
  }
}


export default connect(mapStateToProps, null)(VideoViewTema);
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';


import { isDefined } from '../../lib/utils/is'
import Input2 from '../Inputs/Input2'
import TemaSelected from '../TemaSeleted'
import { Ellipsis } from '../react-css-spinners'
// Actions
import { loadCategoEsc, loadTemaEsc, loadTemasFacturados } from '../../actions/userAction'
// Assets
import './css/css.css';

class CategoriasBuscador extends Component {
  static propTypes = {
    // id: PropTypes.string.isRequired,
    // toClose: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      temasFacturados: null,
      valueTemaBusqueda: null
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }

  onChangeInput(e) {
    if (e.target.id == 'temasAlumno') {
      this.setState({ valueTemaBusqueda: e.target.value == '' || e.target.value == undefined ? null : (e.target.value) })

      if (e.target.value != '' && e.target.value != undefined) {

        if (e.target.value.split('-*/') != undefined && this.props.temasFacturados.find(x => x.idTema == e.target.value.split('-*/')[1]) != undefined) {
          let value = e.target.value.split('-*/')[1]

          let idCategoria = this.props.temasFacturados.find(x => x.idTema == value).categoria;

          this.props.loadCategoEsc(idCategoria)
          this.props.loadTemaEsc(value)

        } else {
          //console.log('error no tema bueno')
        }
      }


    }
  }

  onHandleClick(e) {
    if (e.target.id.split('-')[0] == 'categoria') {

      let idEntra = e.target.id.split('-')[1]
      let idCategoria = this.state.categoriasJusticia[idEntra].id_categoria

      this.props.loadCategoEsc(idCategoria)
      //this.props.loadTemaEsc()
    } else if (e.target.id.split('-')[0] == 'tema') {
      let temas = this.props.temasFacturados.filter(a => a.categoria == this.props.escritorioCategoria).sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema))
      let idEntra = e.target.id.split('-')[1]
      let idTema = temas[idEntra].idTema
      this.props.loadTemaEsc(idTema)
    } else if (e.target.id.split('-')[0] == 'temaSINFAC') {
      
      let temas = this.state.temas.filter(a => a.categoria == this.props.escritorioCategoria).sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema))
      let idEntra = e.target.id.split('-')[1]
      let idTema = temas[idEntra].id_tema
      this.props.loadTemaEsc(idTema)
    } else if (e.target.id == 'closeCate') {
      this.props.loadCategoEsc(null)
    } else if (e.target.id == 'closeTema') {
      this.props.loadTemaEsc(null)
    }
  }

  async componentDidMount() {
    //Sacar todos los temas oposicion this.props 

    fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
      .then(res => res.json())
      .then(cls => this.setState({
        categoriasJusticia: cls
      }))

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(cls => this.setState({
        leyes: cls
      }))
    //Sacar temas alumno
    let { idClase, idUser, idOpo } = this.props
    if (idClase != null) {

      let data11 = { idOpo: idOpo }
      let response11 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoAllTemas", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
      });
      if (!response11.ok) {
        throw Error(response11.statusText);
      }
      let json11 = await response11.json();
      let cls11 = await json11;
      this.setState({ temas: cls11.sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema)) })

    }
  }
  render() {
    const { categoriasJusticia, temas, leyes, valueTemaBusqueda } = this.state;
    const { idOpo, escritorioCategoria, escritorioTema, temasFacturados, tipoUser } = this.props;
    // console.log('this.state.temas')
    // console.log(this.state.temas)
    // console.log('this.props.temasFacturados')
    // console.log(this.props.temasFacturados)
    // console.log('this.state.categoriasJusticia')
    // console.log(this.state.categoriasJusticia)
    // console.log('escritorioCategoria')
    // console.log(escritorioCategoria)

    return (
      <div className="cE1Estadisticas">
        {isDefined(categoriasJusticia) && isDefined(temas) && isDefined(leyes) && temasFacturados && temasFacturados != null ? (
          <div className="cE1EstadisticasR">

            <div className="temarioTitleEscri">
              <p>Temario</p>
              <div style={{ width: '200px' }}>
                <Input2 type="text" id="temasAlumno" name="temasAlumno" placeholder="Buscar Tema" icon="fas fa-search" datalist="temasAlumnos" deletedMode={valueTemaBusqueda != null ? (true) : (false)} ref="ref_temasAlumno" onChange={this.onChangeInput} />
              </div>

              <datalist id="temasAlumnos">{
                temasFacturados && temasFacturados.sort((a,b) => b.numeroTema - a.numeroTema).map((cls, key4345) =>
                  <option key={key4345} value={cls.titulo_tema + ' - ' + cls.nombre_tema +' -*/' + cls.idTema}></option>
                )
              }</datalist>
            </div>

            <div className="temarioTitleEscri">

              {escritorioCategoria && isDefined(escritorioCategoria) ? (


                !escritorioTema || escritorioTema == null ? (
                  <div className="categoriaSelecEsc">
                    <div>
                      {escritorioCategoria != undefined && categoriasJusticia.filter(x => x.id_categoria == escritorioCategoria) && categoriasJusticia.filter(x => x.id_categoria == escritorioCategoria).length > 0 ? (<b>{categoriasJusticia.filter(x => x.id_categoria == escritorioCategoria)[0].nombre + ' - '}</b>) : null}
                    </div>

                    <b style={{ marginLeft: '5px' }}>{temasFacturados && temasFacturados.filter(a => a.categoria == escritorioCategoria).length}</b>
                    /
                    <div>{temas && temas.filter(a => a.categoria == escritorioCategoria).length}</div>
                    <i className="far fa-times-circle" id="closeCate" onClick={this.onHandleClick}></i>
                  </div>
                ) : (
                  <div className="categoriaSelecEsc">
                    <div className='categoriaSelecEsc2'>


                      <div className='categoriaSelecEsc2-titulo'>
                        {temas && temas.filter(a => a.id_tema == escritorioTema)[0].titulo_tema}
                      </div>

                      <div className='categoriaSelecEsc2-nav'>

                        <i title="Cerrar tema" className="fa-light fa-circle-x" id="closeTema" onClick={this.onHandleClick}></i>
                      </div>
                    </div>
                    <div className='categoriaSelecEsc2-nombre'>
                      {temas && temas.filter(a => a.id_tema == escritorioTema)[0].nombre_tema != null && temas.filter(a => a.id_tema == escritorioTema)[0].nombre_tema}
                    </div>


                  </div>
                )
              ) : (
                <p>Categorías</p>
              )

              }



            </div>



            {isDefined(escritorioCategoria) && isDefined(escritorioTema) ? (
              <div className="contentTemaSelectEscritorio">
                <TemaSelected idTema={escritorioTema} />
              </div>
            ) : (

              <div id="wrapper">
                <div class="scrollbar" id="style-2">

                  {!isDefined(escritorioCategoria) ? (
                    <div class="force-overflow">

                      {categoriasJusticia && categoriasJusticia.map((categoria, key) =>
                        idOpo &&
                          (idOpo == 1 && categoria.temasAuxilio != null) ||
                          (idOpo == 4 && categoria.temasTramitacion != null) ||
                          (idOpo == 2 && categoria.temasTramitacion != null) ||
                          (idOpo == 3 && categoria.temasGestion != null) ||
                          (idOpo == 8 && categoria.temasGestionPI != null) ||
                          (idOpo == 9 && categoria.temasTramitacionPI != null)
                          ? (

                            <div className="contentCategoria" data-animation="bonus">

                              {idOpo && idOpo == 1 && categoria.temasAuxilio != null ? (
                                <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={this.onHandleClick}>
                                  {<b style={{ marginRight: '2px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>}
                                  {'- ' + categoria.temasAuxilio}
                                  <p>
                                    <b>{temasFacturados && temasFacturados.filter(a => a.categoria == categoria.id_categoria).length}</b>
                                    /
                                    {temas && temas.filter(a => a.categoria == categoria.id_categoria).length}
                                  </p>
                                </div>
                              ) : null}
                              {idOpo && (idOpo == 4 && categoria.temasTramitacion != null) || (idOpo == 2 && categoria.temasTramitacion != null) ? (
                                <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={this.onHandleClick} >
                                  {<b style={{ marginRight: '2px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>}
                                  {'- ' + categoria.temasTramitacion}
                                  <p>
                                    <b>{temasFacturados && temasFacturados.filter(a => a.categoria == categoria.id_categoria).length}</b>
                                    /
                                    {temas && temas.filter(a => a.categoria == categoria.id_categoria).length}
                                  </p>
                                </div>
                              ) : null}

                              {idOpo && idOpo == 9 && categoria.temasTramitacionPI != null ? (
                                <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={this.onHandleClick} >
                                  {<b style={{ marginRight: '2px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>}
                                  {'- ' + categoria.temasTramitacionPI}
                                  <p>
                                    <b>{temasFacturados && temasFacturados.filter(a => a.categoria == categoria.id_categoria).length}</b>
                                    /
                                    {temas && temas.filter(a => a.categoria == categoria.id_categoria).length}
                                  </p>
                                </div>
                              ) : null}

                              {idOpo && idOpo == 3 && categoria.temasGestion != null ? (
                                <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={this.onHandleClick}>

                                  {<b style={{ marginRight: '2px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>}
                                  {'- ' + categoria.temasGestion}
                                  <p>
                                    <b>{temasFacturados && temasFacturados.filter(a => a.categoria == categoria.id_categoria).length}</b>
                                    /
                                    {temas && temas.filter(a => a.categoria == categoria.id_categoria).length}
                                  </p>

                                </div>
                              ) : null}

                              {idOpo && idOpo == 8 && categoria.temasGestionPI != null ? (
                                <div className="contentCategoriaTemas" id={'categoria-' + key} onClick={this.onHandleClick}>

                                  <b style={{ marginRight: '2px', fontWeight: '600' }} id={'categoria-' + key}>{categoria.nombre}</b>
                                  {'- ' + categoria.temasGestionPI}
                                  <p>
                                    <b>{temasFacturados && temasFacturados.filter(a => a.categoria == categoria.id_categoria).length}</b>
                                    /
                                    {temas && temas.filter(a => a.categoria == categoria.id_categoria).length}
                                  </p>

                                </div>
                              ) : null}

                            </div>
                          ) : null
                      )}

                    </div>

                  ) : null}

                  {isDefined(escritorioCategoria) && !isDefined(escritorioTema) ? (
                    <div class="force-overflow" style={{ justifyContent: 'flex-start' }}>

                      {temasFacturados && temasFacturados.filter(a => a.categoria == escritorioCategoria).sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema)).map((temas, key2) =>
                        <div className="contentCategoria" data-animation="bonus">
                          <div className="contentCategoriaTemas" style={{ justifyContent: 'flex-start' }} id={'tema-' + key2} onClick={this.onHandleClick}>

                            <b style={{ fontWeight: '600', marginRight: '2px' }} id={'tema-' + key2}>
                              {temas.titulo_tema}
                            </b>

                            {temas.nombre_tema != null && temas.nombre_tema != '' ? (
                              temas.nombre_tema
                            ) : null}
                          </div>
                        </div>
                      )}

                      {tipoUser < 40 && temasFacturados && temas && temas.filter(a => a.categoria == escritorioCategoria).sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema)).map((temas2, key2) =>
                        temasFacturados.findIndex(x => x.idTema == temas2.id_tema) == -1 ? (
                          <div className="contentCategoria" >
                            <div className="contentCategoriaTemas2" title="Tema no disponible" style={{ justifyContent: 'flex-start' }}>
                              <p style={{ textAlign: 'left' }}>
                                {temas2.titulo_tema + ' '}
                                {temas2.nombre_tema != null && temas2.nombre_tema != '' ? (
                                  temas2.nombre_tema
                                ) : null}
                              </p>
                            </div>
                          </div>
                        ) : null

                      )}

                      {tipoUser >= 40 && temasFacturados && temas && temas.filter(a => a.categoria == escritorioCategoria).sort((a, b) => parseFloat(a.numeroTema) - parseFloat(b.numeroTema)).map((temas2, key2) =>
                        temasFacturados.findIndex(x => x.idTema == temas2.id_tema) == -1 ? (
                          <div className="contentCategoria" data-animation="bonus">
                            <div className="contentCategoriaTemas" style={{ justifyContent: 'flex-start' }} id={'temaSINFAC-' + key2} onClick={this.onHandleClick}>

                              <b style={{ fontWeight: '600', marginRight: '2px' }} id={'temaSINFAC-' + key2}>
                                {temas2.titulo_tema}
                              </b>

                              {temas2.nombre_tema != null && temas2.nombre_tema != '' ? (
                                temas2.nombre_tema
                              ) : null}
                            </div>
                          </div>
                        ) : null

                      )}
                    </div>
                  ) : null}


                </div>
              </div>

            )}

          </div>

        ) : (
          <div className="cE1EstadisticasR" style={{ height: '490px' }}>
            <div style={{ marginLeft: '100px', color: '#3469b7', fontWeight: 'bold' }}> CARGANDO TEMARIO </div>
            <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
          </div>
        )

        }
        {isDefined(escritorioCategoria) && isDefined(escritorioTema) && temasFacturados ? null : (
          <div className="divImgTemario"><img src="../img/Layer2.png" /></div>
        )}


      </div>
    );
  }
}
// Estados del store
function mapStateToProps(state) {
  return {
    idClase: state.user.idClase,
    idUser: state.user.idUser,
    tipoUser: state.user.tipoUser,
    idOpo: state.user.idOposicion,
    temasFacturados: state.user.temasFacturados,
    escritorioCategoria: state.user.escritorioCategoria,
    escritorioTema: state.user.escritorioTema
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadCategoEsc,
    loadTemaEsc,
    loadTemasFacturados
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriasBuscador);
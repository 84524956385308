// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
import ReactHtmlParser from 'react-html-parser';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// Assets

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './css/dudasCej.css';

import { isDefined } from '../../lib/utils/is'
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';

import PreguntaTestVerTeoria from '../../containers/PreguntaTestVerTeoria';
import PreguntaTestVerPractico from '../../containers/PreguntaTestVerPractico';

class Dudas extends Component {

	constructor(props) {
		super(props);
		this.state = {
			dudasInfo: null,
			dudasSaves: null,
			numView: 0,
			navIni: 0,
			navFin: 10,
			listadoNav: null,
			misDudas: false,
			dudasPendientes: false,
			dudasResueltas: false,
			verPreguntaOnOff: false,
			preguntaTestLoad: null,
			preguntaViewLocation: null,
			textHtmlPreparador: '',
			editarResolucion: null
		};
		this.handleClicUser = this.handleClicUser.bind(this)
		this.handleOnSubmit = this.handleOnSubmit.bind(this)
		this.onChangeText = this.onChangeText.bind(this)
	}

	onChangeText(e, editor) {
		let textHtml = editor.getData();

		if (textHtml != '') {
			this.setState({ textHtmlPreparador: textHtml })
		}

	}
	async handleOnSubmit(e) {

		if (e.target.id == "atras") {
			let actual = this.state.numView
			// let ini = this.state.navIni
			// let fin = this.state.navFin

			let next = actual - 1
			if (this.state.dudasInfo && this.state.dudasInfo.length > 0) {
				let maxValue = Math.ceil(this.state.dudasInfo.length / 5)

				if (next >= 0 && next < maxValue) {
					this.setState({ numView: next })

					//  vemos inicio
					if (next - 5 >= 0) {

						if (next + 5 <= maxValue) {
							this.setState({ navIni: (next - 5), navFin: (next + 5) })
						} else {
							let sumarCompleteNav = (next + 5) - maxValue
							let iniRecalt = (next - 5) - (sumarCompleteNav)
							if (iniRecalt >= 0) {
								this.setState({ navIni: iniRecalt, navFin: maxValue })
							} else {
								this.setState({ navIni: 0, navFin: maxValue })
							}

						}

					} else {
						let sumarCompleteNav = (next - 5) * -1
						if ((next + sumarCompleteNav + 5) <= maxValue) {
							this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
						} else {
							this.setState({ navIni: 0, navFin: maxValue })
						}
					}
				}
			}

		} else if (e.target.id == "adelante") {
			let actual = this.state.numView

			let next = actual + 1
			if (this.state.dudasInfo && this.state.dudasInfo.length > 0) {
				let maxValue = Math.ceil(this.state.dudasInfo.length / 5)

				if (next >= 0 && next < maxValue) {
					this.setState({ numView: next })

					//  vemos inicio
					if (next - 5 >= 0) {

						if (next + 5 <= maxValue) {
							this.setState({ navIni: (next - 5), navFin: (next + 5) })
						} else {
							let sumarCompleteNav = (next + 5) - maxValue
							let iniRecalt = (next - 5) - (sumarCompleteNav)
							if (iniRecalt >= 0) {
								this.setState({ navIni: iniRecalt, navFin: maxValue })
							} else {
								this.setState({ navIni: 0, navFin: maxValue })
							}

						}

					} else {
						let sumarCompleteNav = (next - 5) * -1
						if ((next + sumarCompleteNav + 5) <= maxValue) {
							this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
						} else {
							this.setState({ navIni: 0, navFin: maxValue })
						}
					}


				}
			}


		} else if (e.target.id.split('-')[0] == "clicNavDirect") {


			let locaClic = e.target.id.split('-')[1]
			let ini = this.state.navIni
			let fin = this.state.navFin
			let next = this.state.listadoNav.slice(ini, fin)[locaClic].num - 1
			if (this.state.dudasInfo && this.state.dudasInfo.length > 0) {
				let maxValue = Math.ceil(this.state.dudasInfo.length / 5)

				if (next >= 0 && next < maxValue) {
					this.setState({ numView: next })

					//  vemos inicio
					if (next - 5 >= 0) {

						if (next + 5 <= maxValue) {
							this.setState({ navIni: (next - 5), navFin: (next + 5) })
						} else {
							let sumarCompleteNav = (next + 5) - maxValue
							let iniRecalt = (next - 5) - (sumarCompleteNav)
							if (iniRecalt >= 0) {
								this.setState({ navIni: iniRecalt, navFin: maxValue })
							} else {
								this.setState({ navIni: 0, navFin: maxValue })
							}

						}

					} else {
						let sumarCompleteNav = (next - 5) * -1
						if ((next + sumarCompleteNav + 5) <= maxValue) {
							this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
						} else {
							this.setState({ navIni: 0, navFin: maxValue })
						}
					}


				}
			}





		}

	}
	async handleClicUser(e) {
		if (e.target.id == "misDudas") {
			let { dudasInfo, dudasSaves, listadoNav, misDudas, dudasPendientes, dudasResueltas } = this.state

			if (misDudas == false) {
				if (dudasPendientes == false && dudasResueltas == false) {
					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.length; index++) {
						if (dudasSaves[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves[index])
						}
					}

					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: true })


					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}
				} else if (dudasPendientes == true && dudasResueltas == false) {
					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.filter(d => d.estadoReport == 0).length; index++) {
						if (dudasSaves.filter(d => d.estadoReport == 0)[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves.filter(d => d.estadoReport == 0)[index])
						}
					}


					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: true })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				} else if (dudasPendientes == false && dudasResueltas == true) {

					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.filter(d => d.estadoReport == 1).length; index++) {
						if (dudasSaves.filter(d => d.estadoReport == 1)[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves.filter(d => d.estadoReport == 1)[index])
						}
					}
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: true })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				}
			} else {

				if (dudasPendientes == false && dudasResueltas == false) {
					let dudasFiltrado = dudasSaves

					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: false })


					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}
				} else if (dudasPendientes == true && dudasResueltas == false) {
					let dudasFiltrado = dudasSaves.filter(d => d.estadoReport == 0)
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: false })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				} else if (dudasPendientes == false && dudasResueltas == true) {

					let dudasFiltrado = dudasSaves.filter(d => d.estadoReport == 1)
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, misDudas: false })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				}


			}

		} else if (e.target.id == "pendientes") {

			let { dudasInfo, dudasSaves, listadoNav, misDudas, dudasPendientes, dudasResueltas } = this.state

			if (dudasPendientes == false) {
				if (misDudas == true) {
					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.filter(d => d.estadoReport == 0).length; index++) {
						if (dudasSaves.filter(d => d.estadoReport == 0)[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves.filter(d => d.estadoReport == 0)[index])
						}
					}
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: true, dudasResueltas: false })
					} else {
						swal('No tiene ninguna duda pendiente enlazada a esta búsqueda', '', 'info')
					}

				} else if (misDudas == false) {

					let dudasFiltrado = dudasSaves.filter(d => d.estadoReport == 0)
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: true, dudasResueltas: false })
					} else {
						swal('No existe ninguna duda pendiente enlazada a esta búsqueda', '', 'info')
					}

				}
			} else {


				if (misDudas == true) {
					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.length; index++) {
						if (dudasSaves[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves[index])
						}
					}
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: false })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				} else if (misDudas == false) {

					let dudasFiltrado = dudasSaves
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: false })
					} else {
						swal('No existe ninguna duda pendiente enlazada a esta búsqueda', '', 'info')
					}

				}


			}

		} else if (e.target.id == "resueltas") {

			let { dudasInfo, dudasSaves, listadoNav, misDudas, dudasPendientes, dudasResueltas } = this.state

			if (dudasResueltas == false) {
				if (misDudas == true) {

					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.filter(d => d.estadoReport == 1).length; index++) {
						if (dudasSaves.filter(d => d.estadoReport == 1)[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves.filter(d => d.estadoReport == 1)[index])
						}
					}

					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: true })
					} else {
						swal('No tiene ninguna duda resuelta enlazada a esta búsqueda', '', 'info')
					}

				} else if (misDudas == false) {

					let dudasFiltrado = dudasSaves.filter(d => d.estadoReport == 1)

					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: true })
					} else {
						swal('No existe ninguna duda resuelta enlazada a esta búsqueda', '', 'info')
					}

				}
			} else {

				if (misDudas == true) {
					let dudasFiltrado = []
					for (let index = 0; index < dudasSaves.length; index++) {
						if (dudasSaves[index].alumnosDuda.filter(d => d.idUser == this.props.idUser).length > 0) {
							dudasFiltrado.push(dudasSaves[index])
						}
					}

					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: false })
					} else {
						swal('No tiene ninguna duda enlazada a esta búsqueda', '', 'info')
					}

				} else if (misDudas == false) {

					let dudasFiltrado = dudasSaves
					if (dudasFiltrado.length > 0) {
						let maxValue = Math.ceil(dudasFiltrado.length / 5)
						let listadoNav = []
						for (let index23 = 0; index23 < maxValue; index23++) {
							listadoNav.push({
								num: index23 + 1
							})
						}
						this.setState({ dudasInfo: dudasFiltrado, listadoNav: listadoNav, dudasPendientes: false, dudasResueltas: false })
					} else {
						swal('No existe ninguna duda pendiente enlazada a esta búsqueda', '', 'info')
					}

				}


			}

		} else if (e.target.id.split('-')[0] == "notificar") {
			let position = e.target.id.split('-')[1]
			if (this.props.tipoDuda == "testTeoria") {

				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDuda", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length == 0 && this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser != this.props.idUser) {

					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/addAlumnoDuda", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda.push({
							idUser: this.props.idUser
						})

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda.push({
							idUser: this.props.idUser
						})

						swal('Duda añadida', 'La duda ha sido añadida a "Mis dudas", si no esta resuelta le llegará una notificación tras su resolución.', 'success')

						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
					}
				} else {
					swal('Ya tiene esta duda', 'Esta duda ya la tiene agregada en "Mis dudas", si no la visualiza recarge el navegador.', 'info')
				}

			} else if (this.props.tipoDuda == "testPractico") {
				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDudaCP", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length == 0 && this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser != this.props.idUser) {

					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/addAlumnoDudaCP", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda.push({
							idUser: this.props.idUser
						})

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda.push({
							idUser: this.props.idUser
						})

						swal('Duda añadida', 'La duda ha sido añadida a "Mis dudas", si no esta resuelta le llegará una notificación tras su resolución.', 'success')

						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
					}
				} else {
					swal('Ya tiene esta duda', 'Esta duda ya la tiene agregada en "Mis dudas", si no la visualiza recarge el navegador.', 'info')
				}

			} else if (this.props.tipoDuda == "Articulo") {

				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDudaArt", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length == 0 && this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser != this.props.idUser) {

					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/addAlumnoDudaArt", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda.push({
							idUser: this.props.idUser
						})

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda.push({
							idUser: this.props.idUser
						})

						swal('Duda añadida', 'La duda ha sido añadida a "Mis dudas", si no esta resuelta le llegará una notificación tras su resolución.', 'success')

						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
					}
				} else {
					swal('Ya tiene esta duda', 'Esta duda ya la tiene agregada en "Mis dudas", si no la visualiza recarge el navegador.', 'info')
				}

			}

		} else if (e.target.id.split('-')[0] == "eliminarDuda") {
			let position = e.target.id.split('-')[1]
			if (this.props.tipoDuda == "testTeoria") {

				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDuda", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length > 0) {
					swal('Procesando eliminación de duda', '', 'info')
					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteAlumnoDuda", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda = rev[position].alumnosDuda.filter(a => a.idUser != this.props.idUser)

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda = rev2[locaRev2].alumnosDuda.filter(a => a.idUser != this.props.idUser)
						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
						swal('Duda eliminada', 'La duda ha sido eliminada de "Mis dudas".', 'success')

					}
				} else {
					swal('Duda eliminada', 'Esta duda ya la tiene eliminada de "Mis dudas", si no la visualiza los cambios recarge el navegador.', 'info')
				}
			} else if (this.props.tipoDuda == "testPractico") {
				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDudaCP", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length > 0) {
					swal('Procesando eliminación de duda', '', 'info')
					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteAlumnoDudaCP", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda = rev[position].alumnosDuda.filter(a => a.idUser != this.props.idUser)

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda = rev2[locaRev2].alumnosDuda.filter(a => a.idUser != this.props.idUser)
						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
						swal('Duda eliminada', 'La duda ha sido eliminada de "Mis dudas".', 'success')

					}
				} else {
					swal('Duda eliminada', 'Esta duda ya la tiene eliminada de "Mis dudas", si no la visualiza los cambios recarge el navegador.', 'info')
				}
			} else if (this.props.tipoDuda == "Articulo") {

				let data = {
					idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
					idUser: this.props.idUser
				}
				let response = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnoEstaApuntadoDudaArt", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.length > 0) {
					swal('Procesando eliminación de duda', '', 'info')
					let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteAlumnoDudaArt", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response2.ok) {
						throw Error(response2.statusText);
					}
					let json2 = await response2.json();
					let cls2 = await json2;
					if (cls2.affectedRows > 0) {
						let rev = this.state.dudasInfo
						let rev2 = this.state.dudasSaves

						rev[position].alumnosDuda = rev[position].alumnosDuda.filter(a => a.idUser != this.props.idUser)

						let locaRev2 = rev2.findIndex(d => d.idReport == this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport)
						rev2[locaRev2].alumnosDuda = rev2[locaRev2].alumnosDuda.filter(a => a.idUser != this.props.idUser)
						this.setState({ dudasInfo: rev, dudasSaves: rev2 })
						swal('Duda eliminada', 'La duda ha sido eliminada de "Mis dudas".', 'success')

					}
				} else {
					swal('Duda eliminada', 'Esta duda ya la tiene eliminada de "Mis dudas", si no la visualiza los cambios recarge el navegador.', 'info')
				}
			}
		} else if (e.target.id.split('-')[0] == "dudaInfo") {
			let id = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[id]
			if ($('#infoCompletaDuda-' + id).css("display") == 'none') {
				$('#dudaInfo-' + id).css("box-shadow", "0px 0px 15px #7f9fcd");
				$('#infoCompletaDuda-' + id).fadeToggle(700).css("display", "flex");
				this.setState({ textHtmlPreparador: duda.resolucion != null && duda.resolucion != '' ? (duda.resolucion) : (""), editarResolucion: null, verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
			}

		} else if (e.target.id.split('-')[0] == "cerrarPreg") {
			let id = e.target.id.split('-')[1]
			if ($('#infoCompletaDuda-' + id).css("display") == 'flex') {
				$('#dudaInfo-' + id).css("box-shadow", "0px 0px 15px #dbdbdb");
				$('#infoCompletaDuda-' + id).slideUp();
				this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })
			}

		} else if (e.target.id.split('-')[0] == "dudaInfoLey") {
			let position = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
			if (isDefined(duda)) {
				swal('' + duda.siglasLey + '', '' + duda.nombreLey + '', 'info')
			}
		} else if (e.target.id.split('-')[0] == "verPreguntaTestTeorico") {
			let position = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
			if (isDefined(duda)) {

				let preguntaTestLoad = []
				preguntaTestLoad.push({
					idPreg: duda.idPreg,
					pregunta: duda.pregunta,
					tipoPregunta: duda.tipoPregunta,
					articulo: duda.articulo,
					idLey: duda.idLey,
					siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == duda.idLey).siglas_ley,
					nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == duda.idLey).nombre_ley,
					idR1: duda.idR1, idR2: duda.idR2, idR3: duda.idR3, idR4: duda.idR4,
					respuestas: duda.respuestas
				})
				let ver = !this.state.verPreguntaOnOff
				if (this.state.preguntaViewLocation != null && this.state.preguntaViewLocation != position) {
					ver = true
				}


				this.setState({ verPreguntaOnOff: ver, preguntaTestLoad: preguntaTestLoad, preguntaViewLocation: position })

			} else {

				this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })

			}

		} else if (e.target.id.split('-')[0] == "verPreguntaTestPractico") {
			let position = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
			if (isDefined(duda)) {

				let preguntaTestLoad = []
				preguntaTestLoad.push({
					idPreg: duda.idPreg,
					pregunta: duda.pregunta,
					tipoPregunta: duda.tipoPregunta,
					articulo: duda.articulo,
					idLey: duda.idLey,
					siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == duda.idLey).siglas_ley,
					nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == duda.idLey).nombre_ley,
					idR1: duda.idR1, idR2: duda.idR2, idR3: duda.idR3, idR4: duda.idR4,
					respuestas: duda.respuestas,
					idCasoPractico: duda.idCasoPractico != null && duda.idCasoPractico != '' ? (duda.idCasoPractico) : ("")
				})
				let ver = !this.state.verPreguntaOnOff
				if (this.state.preguntaViewLocation != null && this.state.preguntaViewLocation != position) {
					ver = true
				}


				this.setState({ verPreguntaOnOff: ver, preguntaTestLoad: preguntaTestLoad, preguntaViewLocation: position })

			} else {

				this.setState({ verPreguntaOnOff: false, preguntaTestLoad: null, preguntaViewLocation: null })

			}

		} else if (e.target.id.split('-')[0] == "ResolverDudaPre") {
			let position = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
			let textResolucion = this.state.textHtmlPreparador



			if (isDefined(duda) && textResolucion && textResolucion != '') {
				if (this.props.tipoDuda == 'testTeoria') {
					let data = {
						idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
						textResolucion: textResolucion
					}
					let response = await fetch("https://oposiciones-justicia.es/api/usuarios/resolverDudaTestTeorico", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response.ok) {
						throw Error(response.statusText);
					}
					let json = await response.json();
					let cls = await json;

					if (cls.affectedRows > 0) {
						let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
						swal("Duda resuelta", '', 'success');

						let data2 = {
							idDuda: idReporte
						}

						let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
						});
						if (!response2.ok) {
							throw Error(response2.statusText);
						}
						let json2 = await response2.json();
						let cls2 = await json2;
						for (let index = 0; index < cls2.length; index++) {
							let idUserEnviamos = cls2[index].idUser
							let data3 = {
								idUserRecibe: idUserEnviamos,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda resuelta - Test Teórico',
								notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 1,
								tipoUrgente: 0,
								tipoReformasLey: 0,
								idDuda: idReporte
							}
							let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
							});
							if (!response3.ok) {
								throw Error(response3.statusText);
							}
							let json3 = await response3.json();
							let cls3 = await json3;

						}
						let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


						let data4 = {
							idUserRecibe: idUserRecibe,
							idUserEnvia: this.props.idUser,
							fecha: moment().format('YYYY-MM-DD HH:mm'),
							titulo: 'Duda resuelta - Test Teórico',
							notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
							tipoPreparadores: 0,
							tipoOficina: 0,
							tipoDuda: 1,
							tipoUrgente: 0,
							tipoReformasLey: 0,
							idDuda: idReporte
						}
						let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
						});
						if (!response4.ok) {
							throw Error(response4.statusText);
						}
						let json4 = await response4.json();
						let cls4 = await json4;

						let dudasNew = await this.state.dudasInfo
						let dudasNewS = await this.state.dudasSaves

						let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
						let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

						dudasNew[locaN].resolucion = await textResolucion
						dudasNew[locaN].estadoReport = 1
						dudasNewS[locaNs].resolucion = await textResolucion
						dudasNewS[locaNs].estadoReport = 1



						this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })

					}
				} else if (this.props.tipoDuda == 'testPractico') {
					let data = {
						idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
						textResolucion: textResolucion
					}
					let response = await fetch("https://oposiciones-justicia.es/api/usuarios/resolverDudaTestPractico", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response.ok) {
						throw Error(response.statusText);
					}
					let json = await response.json();
					let cls = await json;


					if (cls.affectedRows > 0) {
						let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
						swal("Duda resuelta", '', 'success');

						let data2 = {
							idDuda: idReporte
						}

						let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
						});
						if (!response2.ok) {
							throw Error(response2.statusText);
						}
						let json2 = await response2.json();
						let cls2 = await json2;
						for (let index = 0; index < cls2.length; index++) {
							let idUserEnviamos = cls2[index].idUser
							let data3 = {
								idUserRecibe: idUserEnviamos,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda resuelta - Test Práctico',
								notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 2,
								tipoUrgente: 0,
								tipoReformasLey: 0,
								idDuda: idReporte
							}
							let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
							});
							if (!response3.ok) {
								throw Error(response3.statusText);
							}
							let json3 = await response3.json();
							let cls3 = await json3;

						}
						let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


						let data4 = {
							idUserRecibe: idUserRecibe,
							idUserEnvia: this.props.idUser,
							fecha: moment().format('YYYY-MM-DD HH:mm'),
							titulo: 'Duda resuelta - Test Práctico',
							notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
							tipoPreparadores: 0,
							tipoOficina: 0,
							tipoDuda: 2,
							tipoUrgente: 0,
							tipoReformasLey: 0,
							idDuda: idReporte
						}
						let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
						});
						if (!response4.ok) {
							throw Error(response4.statusText);
						}
						let json4 = await response4.json();
						let cls4 = await json4;

						let dudasNew = await this.state.dudasInfo
						let dudasNewS = await this.state.dudasSaves

						let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
						let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

						dudasNew[locaN].resolucion = await textResolucion
						dudasNew[locaN].estadoReport = 1
						dudasNewS[locaNs].resolucion = await textResolucion
						dudasNewS[locaNs].estadoReport = 1



						this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })

					}

				} else if (this.props.tipoDuda == 'Articulo') {
					let data = {
						idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
						textResolucion: textResolucion
					}
					let response = await fetch("https://oposiciones-justicia.es/api/usuarios/resolverDudaArticulos", {
						method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
					});
					if (!response.ok) {
						throw Error(response.statusText);
					}
					let json = await response.json();
					let cls = await json;

					if (cls.affectedRows > 0) {
						let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
						swal("Duda resuelta", '', 'success');

						let data2 = {
							idDuda: idReporte
						}

						let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
						});
						if (!response2.ok) {
							throw Error(response2.statusText);
						}
						let json2 = await response2.json();
						let cls2 = await json2;
						for (let index = 0; index < cls2.length; index++) {
							let idUserEnviamos = cls2[index].idUser
							let data3 = {
								idUserRecibe: idUserEnviamos,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda resuelta - Artículos',
								notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 3,
								tipoUrgente: 0,
								tipoReformasLey: 0,
								idDuda: idReporte
							}
							let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
							});
							if (!response3.ok) {
								throw Error(response3.statusText);
							}
							let json3 = await response3.json();
							let cls3 = await json3;

						}
						let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


						let data4 = {
							idUserRecibe: idUserRecibe,
							idUserEnvia: this.props.idUser,
							fecha: moment().format('YYYY-MM-DD HH:mm'),
							titulo: 'Duda resuelta - Artículos',
							notificacionTexto: 'Ya tiene la resolución a su duda, puede visualizarla aquí abajo.',
							tipoPreparadores: 0,
							tipoOficina: 0,
							tipoDuda: 3,
							tipoUrgente: 0,
							tipoReformasLey: 0,
							idDuda: idReporte
						}
						let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
						});
						if (!response4.ok) {
							throw Error(response4.statusText);
						}
						let json4 = await response4.json();
						let cls4 = await json4;



						let dudasNew = await this.state.dudasInfo
						let dudasNewS = await this.state.dudasSaves

						let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
						let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

						dudasNew[locaN].resolucion = await textResolucion
						dudasNew[locaN].estadoReport = 1
						dudasNewS[locaNs].resolucion = await textResolucion
						dudasNewS[locaNs].estadoReport = 1



						this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })

					}
				}
			} else {
				swal('Error', 'Para resolver la duda, debe indicar algo en la caja de texto', 'error')
			}
		} else if (e.target.id.split('-')[0] == "EditarDudaPre") {




			let position = e.target.id.split('-')[1]
			let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]
			let textResolucion = this.state.textHtmlPreparador


			if (this.state.editarResolucion == true) {


				if (isDefined(duda) && textResolucion && textResolucion != '') {
					if (this.props.tipoDuda == 'testTeoria') {
						let data = {
							idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
							textResolucion: textResolucion
						}
						let response = await fetch("https://oposiciones-justicia.es/api/usuarios/editarDudaTestTeorico", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						if (cls.affectedRows > 0) {
							let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
							swal("Duda resuelta", '', 'success');

							let data2 = {
								idDuda: idReporte
							}

							let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
							});
							if (!response2.ok) {
								throw Error(response2.statusText);
							}
							let json2 = await response2.json();
							let cls2 = await json2;
							for (let index = 0; index < cls2.length; index++) {
								let idUserEnviamos = cls2[index].idUser
								let data3 = {
									idUserRecibe: idUserEnviamos,
									idUserEnvia: this.props.idUser,
									fecha: moment().format('YYYY-MM-DD HH:mm'),
									titulo: 'Duda editada - Test Teórico',
									notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
									tipoPreparadores: 0,
									tipoOficina: 0,
									tipoDuda: 1,
									tipoUrgente: 0,
									tipoReformasLey: 0,
									idDuda: idReporte
								}
								let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
								});
								if (!response3.ok) {
									throw Error(response3.statusText);
								}
								let json3 = await response3.json();
								let cls3 = await json3;

							}
							let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


							let data4 = {
								idUserRecibe: idUserRecibe,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda editada - Test Teórico',
								notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 1,
								tipoUrgente: 0,
								tipoReformasLey: 0,
								idDuda: idReporte
							}
							let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
							});
							if (!response4.ok) {
								throw Error(response4.statusText);
							}
							let json4 = await response4.json();
							let cls4 = await json4;

							let dudasNew = await this.state.dudasInfo
							let dudasNewS = await this.state.dudasSaves

							let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
							let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

							dudasNew[locaN].resolucion = await textResolucion
							dudasNew[locaN].estadoReport = 1
							dudasNewS[locaNs].resolucion = await textResolucion
							dudasNewS[locaNs].estadoReport = 1



							this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })
							this.setState({ editarResolucion: null })
						}
					} else if (this.props.tipoDuda == 'testPractico') {
						let data = {
							idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
							textResolucion: textResolucion
						}
						let response = await fetch("https://oposiciones-justicia.es/api/usuarios/editarDudaTestPractico", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;


						if (cls.affectedRows > 0) {
							let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
							swal("Duda editada", '', 'success');

							let data2 = {
								idDuda: idReporte
							}

							let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
							});
							if (!response2.ok) {
								throw Error(response2.statusText);
							}
							let json2 = await response2.json();
							let cls2 = await json2;
							for (let index = 0; index < cls2.length; index++) {
								let idUserEnviamos = cls2[index].idUser
								let data3 = {
									idUserRecibe: idUserEnviamos,
									idUserEnvia: this.props.idUser,
									fecha: moment().format('YYYY-MM-DD HH:mm'),
									titulo: 'Duda editada - Test Práctico',
									notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
									tipoPreparadores: 0,
									tipoOficina: 0,
									tipoDuda: 2,
									tipoUrgente: 0,
									tipoReformasLey: 0,
									idDuda: idReporte
								}
								let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
								});
								if (!response3.ok) {
									throw Error(response3.statusText);
								}
								let json3 = await response3.json();
								let cls3 = await json3;

							}
							let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


							let data4 = {
								idUserRecibe: idUserRecibe,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda editada - Test Práctico',
								notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 2,
								idDuda: idReporte
							}
							let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
							});
							if (!response4.ok) {
								throw Error(response4.statusText);
							}
							let json4 = await response4.json();
							let cls4 = await json4;

							let dudasNew = await this.state.dudasInfo
							let dudasNewS = await this.state.dudasSaves

							let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
							let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

							dudasNew[locaN].resolucion = await textResolucion
							dudasNew[locaN].estadoReport = 1
							dudasNewS[locaNs].resolucion = await textResolucion
							dudasNewS[locaNs].estadoReport = 1



							this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })
							this.setState({ editarResolucion: null })
						}

					} else if (this.props.tipoDuda == 'Articulo') {
						let data = {
							idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport,
							textResolucion: textResolucion
						}
						let response = await fetch("https://oposiciones-justicia.es/api/usuarios/editarDudaArticulos", {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						if (cls.affectedRows > 0) {
							let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
							swal("Duda editada", '', 'success');

							let data2 = {
								idDuda: idReporte
							}

							let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
							});
							if (!response2.ok) {
								throw Error(response2.statusText);
							}
							let json2 = await response2.json();
							let cls2 = await json2;
							for (let index = 0; index < cls2.length; index++) {
								let idUserEnviamos = cls2[index].idUser
								let data3 = {
									idUserRecibe: idUserEnviamos,
									idUserEnvia: this.props.idUser,
									fecha: moment().format('YYYY-MM-DD HH:mm'),
									titulo: 'Duda editada - Artículos',
									notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
									tipoPreparadores: 0,
									tipoOficina: 0,
									tipoDuda: 3,
									tipoUrgente: 0,
									tipoReformasLey: 0,
									idDuda: idReporte
								}
								let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
								});
								if (!response3.ok) {
									throw Error(response3.statusText);
								}
								let json3 = await response3.json();
								let cls3 = await json3;

							}
							let idUserRecibe = await this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idUser


							let data4 = {
								idUserRecibe: idUserRecibe,
								idUserEnvia: this.props.idUser,
								fecha: moment().format('YYYY-MM-DD HH:mm'),
								titulo: 'Duda editada - Artículos',
								notificacionTexto: 'Se ha editado la resolución de una duda, puede visualizarla aquí abajo.',
								tipoPreparadores: 0,
								tipoOficina: 0,
								tipoDuda: 3,
								tipoUrgente: 0,
								tipoReformasLey: 0,
								idDuda: idReporte
							}
							let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
							});
							if (!response4.ok) {
								throw Error(response4.statusText);
							}
							let json4 = await response4.json();
							let cls4 = await json4;



							let dudasNew = await this.state.dudasInfo
							let dudasNewS = await this.state.dudasSaves

							let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
							let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)

							dudasNew[locaN].resolucion = await textResolucion
							dudasNew[locaN].estadoReport = 1
							dudasNewS[locaNs].resolucion = await textResolucion
							dudasNewS[locaNs].estadoReport = 1



							this.setState({ dudasInfo: dudasNew, dudasSaves: dudasNewS })
							this.setState({ editarResolucion: null })
						}
					}
				} else {
					swal('Error', 'Para editar la duda, debe indicar algo en la caja de texto', 'error')
				}
			} else {
				this.setState({ editarResolucion: true })
			}
		} else if (e.target.id.split('-')[0] == "EliminarDudaPre") {
			let position = e.target.id.split('-')[1]
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, la duda se eliminará.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {


						let duda = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position]



						if (isDefined(duda)) {
							if (this.props.tipoDuda == 'testTeoria') {
								let data = {
									idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
								}
								let response = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteDudaTestTeorico", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
								});
								if (!response.ok) {
									throw Error(response.statusText);
								}
								let json = await response.json();
								let cls = await json;

								if (cls.affectedRows > 0) {
									let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
									swal("Duda eliminada", '', 'success');

									let dudasNew = await this.state.dudasInfo
									let dudasNewS = await this.state.dudasSaves

									let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
									let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)
									await dudasNew.splice(locaN, 1)
									//dudasNewS = await dudasNewS.splice(locaNs, 1)


									this.setState({ textHtmlPreparador: '', dudasInfo: dudasNew, dudasSaves: dudasNewS })

								}
							} else if (this.props.tipoDuda == 'testPractico') {
								let data = {
									idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
								}
								let response = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteDudaTestPractico", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
								});
								if (!response.ok) {
									throw Error(response.statusText);
								}
								let json = await response.json();
								let cls = await json;


								if (cls.affectedRows > 0) {
									let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
									swal("Duda eliminada", '', 'success');

									let dudasNew = await this.state.dudasInfo
									let dudasNewS = await this.state.dudasSaves

									let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
									let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)


									await dudasNew.splice(locaN, 1)
									// dudasNewS = await dudasNewS.splice(locaNs, 1)


									this.setState({ textHtmlPreparador: '', dudasInfo: dudasNew, dudasSaves: dudasNewS })

								}

							} else if (this.props.tipoDuda == 'Articulo') {
								let data = {
									idDuda: this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
								}
								let response = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteDudaArticulos", {
									method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
								});
								if (!response.ok) {
									throw Error(response.statusText);
								}
								let json = await response.json();
								let cls = await json;

								if (cls.affectedRows > 0) {
									let idReporte = this.state.dudasInfo.slice(5 * this.state.numView, this.state.numView == 0 ? (5) : (5 * (this.state.numView + 1)))[position].idReport
									swal("Duda eliminada", '', 'success');

									let dudasNew = await this.state.dudasInfo
									let dudasNewS = await this.state.dudasSaves

									let locaN = await this.state.dudasInfo.findIndex(u => u.idReport == idReporte)
									let locaNs = await this.state.dudasSaves.findIndex(u => u.idReport == idReporte)
									await dudasNew.splice(locaN, 1)
									//dudasNewS = await dudasNewS.splice(locaNs, 1)


									this.setState({ textHtmlPreparador: '', dudasInfo: dudasNew, dudasSaves: dudasNewS })

								}
							}
						} else {
							swal('Error', 'No se localizo la duda correctamente', 'error')
						}
					} else {
						swal("Duda no eliminada", '', "success");
					}
				});
		}



	}

	async componentDidMount() {
		if (this.props.dudas.length > 0) {

			let maxValue = Math.ceil(this.props.dudas.length / 5)
			let listadoNav = []
			for (let index23 = 0; index23 < maxValue; index23++) {
				listadoNav.push({
					num: index23 + 1
				})
			}
			this.setState({ dudasInfo: this.props.dudas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)), dudasSaves: this.props.dudas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)), listadoNav: listadoNav })
		} else {
			this.setState({ dudasInfo: null, dudasSaves: null, listadoNav: null })
		}

		let response4 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
		if (!response4.ok) {
			throw Error(response4.statusText);
		}
		let json4 = await response4.json();
		let cls4 = await json4;
		this.setState({ busquedaLeyes: cls4 });

		fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
			.then(res => res.json())
			.then(alumnos => this.setState({
				busquedaAlumnos: alumnos
			})).catch(error => console.log('Object fecth failed', error));

	}
	async componentDidUpdate() {

	}

	componentWillUnmount() {
		this.setState({ dudasInfo: null, dudasSaves: null, numView: 0, navIni: 0, navFin: 10, listadoNav: null, verPreguntaOnOff: false })
	}


	render() {
		const { dudasInfo, numView, navIni, navFin, listadoNav, misDudas, dudasPendientes, dudasResueltas, verPreguntaOnOff, preguntaTestLoad, textHtmlPreparador, editarResolucion, busquedaAlumnos } = this.state;
		const { verPregunta, tipoDuda, notificacion, tipoUser } = this.props;

		if (isDefined(dudasInfo)) {
			return (
				<div className="dudasMainBodyu">
					{notificacion && notificacion == true ? (null) : (
						<div className="DMB-navDudas">
							<div className="DMB-navDudas-boton">
								{misDudas && misDudas == true ? (
									<Boton id="misDudas" icon1="" icon2="" texto="Cerrar mis dudas" onClick={this.handleClicUser} />
								) : (
									<Boton2 id="misDudas" icon1="" icon2="" texto="Ver mis dudas" onClick={this.handleClicUser} />
								)}
							</div>
							<div className="DMB-navDudas-boton">
								{dudasResueltas && dudasResueltas == true ? (
									<Boton id="resueltas" icon1="" icon2="" texto="Cerrar resueltas" onClick={this.handleClicUser} />
								) : (
									<Boton2 id="resueltas" icon1="" icon2="" texto="Ver resueltas" onClick={this.handleClicUser} />
								)}
							</div>
							<div className="DMB-navDudas-boton">
								{dudasPendientes && dudasPendientes == true ? (
									<Boton id="pendientes" icon1="" icon2="" texto="Cerrar pendientes" onClick={this.handleClicUser} />
								) : (
									<Boton2 id="pendientes" icon1="" icon2="" texto="Ver pendientes" onClick={this.handleClicUser} />
								)}
							</div>

						</div>
					)}


					<div className="dudasBodyTotal">

						{dudasInfo && dudasInfo.length > 0 && dudasInfo.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).slice(5 * numView, numView == 0 ? (5) : (5 * (numView + 1))).map((dudaLoad, key) =>
							<React.Fragment>
								<div className="dudaBodyRow" key={key} id={'dudaInfo-' + key} onClick={this.handleClicUser}>

									<div className="dB-foto" id={'dudaInfo-' + key}>
										<img src={dudaLoad.fotoPerfil} id={'dudaInfo-' + key} />

										{busquedaAlumnos && busquedaAlumnos.length > 0 && tipoUser && tipoUser == 58 || tipoUser >= 98 ? (
											<div style={{ position: 'absolute', top: '-12px', left: '16px', fontWeight: '500' }}>

												{busquedaAlumnos && busquedaAlumnos.length > 0 && dudaLoad.idUser != null && busquedaAlumnos.find(a => a.id_usuario == dudaLoad.idUser) != undefined ? (
													busquedaAlumnos.find(a => a.id_usuario == dudaLoad.idUser).nombre + ', ' +busquedaAlumnos.find(a => a.id_usuario == dudaLoad.idUser).apellidos
												) : null

												}
												
											</div>
										) : null}
									</div>

									<div className="dB-contentDuda" id={'dudaInfo-' + key}>

										<div className="dB-contentDuda-Header" id={'dudaInfoLey-' + key}>
											<div className="dB-contentDuda-H-celda" id={'dudaInfoLey-' + key}>
												Art. {dudaLoad.articulo}
											</div>
											<div className="dB-contentDuda-H-celda" id={'dudaInfoLey-' + key}>
												{dudaLoad.siglasLey}
											</div>
											<div className="dB-contentDuda-H-celda" style={{ borderRight: '0px' }}>
												{moment(dudaLoad.fecha).format('DD-MM-YY')}
											</div>



										</div>

										<div className="dB-contentDuda-textPregunta" id={'dudaInfo-' + key}>
											{dudaLoad.infoRoportUser && dudaLoad.infoRoportUser.length > 180 ? (
												ReactHtmlParser(dudaLoad.infoRoportUser.replace(/<\/br>/g, '').substring(0, 180)) + ' ...'
											) : (
												ReactHtmlParser(dudaLoad.infoRoportUser.replace(/<\/br>/g, ''))
											)

											}
										</div>

										{dudaLoad.estadoReport && dudaLoad.estadoReport == 1 ? (
											<div className="dudaResuelta" title="Duda resuelta." id={'dudaInfo-' + key}>
											</div>
										) : (
											<div className="dudaPendiente" title="Duda pendiente de resolver." id={'dudaInfo-' + key}>
											</div>
										)}

									</div>

								</div>

								<div className="infoCompletaDuda" id={'infoCompletaDuda-' + key}>

									<div className="dB-contentDuda-Header" style={{ alignSelf: 'flex-start', marginBottom: '5px' }}>
										<i className="far fa-times-circle botonCruz" onClick={this.handleClicUser} id={"cerrarPreg-" + key}></i>
										<div className="dB-contentDuda-H-celda">
											Art. {dudaLoad.articulo}
										</div>
										<div className="dB-contentDuda-H-celda">
											{dudaLoad.siglasLey}
										</div>
										<div className="dB-contentDuda-H-celda" style={{ borderRight: '0px' }}>
											{moment(dudaLoad.fecha).format('DD-MM-YY')}
										</div>

									</div>



									<div className="iCD-preguntaUser" >
										{ReactHtmlParser(dudaLoad.infoRoportUser)}
									</div>

									{dudaLoad.estadoReport && dudaLoad.estadoReport == 1 ? (
										editarResolucion && editarResolucion != null && editarResolucion == true ? (
											<div className="editorPlantearrDuda">
												<CKEditor
													editor={ClassicEditor}
													data={textHtmlPreparador}
													onInit={editor => { }}
													config={{
														toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
														// toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
													}}
													onChange={this.onChangeText}
												/>
											</div>
										) : (
											<div className="iCD-respuestaPrepa" >
												{ReactHtmlParser(dudaLoad.resolucion)}
											</div>
										)

									) : (

										tipoUser && tipoUser == 58 || tipoUser >= 98 ? (

											<div className="editorPlantearrDuda">
												<CKEditor
													editor={ClassicEditor}
													data={textHtmlPreparador}
													onInit={editor => { }}
													config={{
														toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
														// toolbar: [ 'heading', '|', 'bold', 'link', 'mediaEmbed', '|', 'undo', 'redo', ]
													}}
													onChange={this.onChangeText}
												/>
											</div>

										) : (
											dudaLoad.idUser && dudaLoad.idUser != this.props.idUser && dudaLoad.alumnosDuda.filter(a => a.idUser == this.props.idUser).length == 0 ? (
												<div className="iCD-respuestaPrepa" >
													Esta duda aún esta pendiente, puedes darle a <b>Añadir a mis dudas</b>. <br />
													La duda se agregará su listado de <b>Mis dudas</b>.<br />
													Le llegará una notificación en cuando sea resuelta.
												</div>
											) : (
												<div className="iCD-respuestaPrepa" >
													Esta duda aún esta pendiente.<br />
													Le llegará una notificación en cuando sea resuelta.
												</div>
											)
										)



									)}


									<div className="iCD-footerBotons" style={
										(dudaLoad.idUser && dudaLoad.idUser != this.props.idUser && dudaLoad.alumnosDuda.filter(a => a.idUser == this.props.idUser).length == 0) || (dudaLoad.idUser && dudaLoad.idUser != this.props.idUser && dudaLoad.alumnosDuda.filter(a => a.idUser == this.props.idUser).length > 0) && verPregunta && verPregunta == true ? (
											{ justifyContent: 'space-between' }
										) : ({})
									}>

										{dudaLoad.idUser && dudaLoad.idUser != this.props.idUser && dudaLoad.alumnosDuda.filter(a => a.idUser == this.props.idUser).length == 0 ? (
											tipoUser && tipoUser == 58 || tipoUser >= 98 ? null : (
												<div className="DMB-navDudas-boton">
													<Boton id={'notificar-' + key} icon1="" icon2="" texto="Añadir a mis dudas" onClick={this.handleClicUser} />
												</div>
											)) : null}

										{dudaLoad.idUser && dudaLoad.idUser != this.props.idUser && dudaLoad.alumnosDuda.filter(a => a.idUser == this.props.idUser).length > 0 ? (
											tipoUser && tipoUser == 58 || tipoUser >= 98 ? null : (
												<div className="DMB-navDudas-boton">
													<Boton id={'eliminarDuda-' + key} icon1="" icon2="" texto="Eliminar de mis dudas" onClick={this.handleClicUser} />
												</div>
											)) : null}

										{verPregunta && verPregunta == true && tipoDuda && tipoDuda == "testTeoria" ? (
											<div className="DMB-navDudas-boton">
												<Boton id={'verPreguntaTestTeorico-' + key} icon1="" icon2="" texto="Ver pregunta test" onClick={this.handleClicUser} />
											</div>
										) : null}
										{verPregunta && verPregunta == true && tipoDuda && tipoDuda == "testPractico" ? (
											<div className="DMB-navDudas-boton">
												<Boton id={'verPreguntaTestPractico-' + key} icon1="" icon2="" texto="Ver pregunta test" onClick={this.handleClicUser} />
											</div>
										) : null}
										{tipoUser && tipoUser == 58 || tipoUser >= 98 && dudaLoad.estadoReport == 0 ? (
											<div className="DMB-navDudas-boton">
												<Boton id={'ResolverDudaPre-' + key} icon1="" icon2="" texto="Resolver duda" onClick={this.handleClicUser} />
											</div>
										) : null}
										{tipoUser && tipoUser == 58 || tipoUser >= 98 && dudaLoad.estadoReport == 1 ? (
											<div className="DMB-navDudas-boton">
												<Boton id={'EditarDudaPre-' + key} icon1="" icon2="" texto="Editar duda" onClick={this.handleClicUser} />
											</div>
										) : null}
										{tipoUser && tipoUser == 58 || tipoUser >= 98 ? (
											<div className="DMB-navDudas-boton">
												<Boton id={'EliminarDudaPre-' + key} icon1="" icon2="" texto="Eliminar duda" onClick={this.handleClicUser} />
											</div>
										) : null}
									</div>


								</div>


								{verPreguntaOnOff && verPreguntaOnOff == true && preguntaTestLoad && preguntaTestLoad.length > 0 && key == this.state.preguntaViewLocation && tipoDuda == 'testTeoria' ? (
									<div id={"verPreguntaTest-" + key}>
										<PreguntaTestVerTeoria preguntaTestLoad={preguntaTestLoad} notificacion={true} />
									</div>
								) : null}
								{verPreguntaOnOff && verPreguntaOnOff == true && preguntaTestLoad && preguntaTestLoad.length > 0 && key == this.state.preguntaViewLocation && tipoDuda == 'testPractico' ? (
									<div id={"verPreguntaTest-" + key}>
										<PreguntaTestVerPractico preguntaTestLoad={preguntaTestLoad} notificacion={true} />
									</div>
								) : null}




							</React.Fragment>
						)}


					</div>

					{dudasInfo && dudasInfo.length > 0 ? (
						notificacion && notificacion == true ? (null) : (
							<div className="BE-CL-C-nav" style={{ marginLeft: '7px', marginRight: '7px', marginTop: '40px', marginBottom: '10px' }}>
								<i id={"atras"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-left" />

								{listadoNav.slice(navIni, navFin).map((data, key345) =>

									<div className="navClic" id={"clicNavDirect-" + key345} onClick={this.handleOnSubmit} key={key345}
										style={(numView + 1) == data.num ? ({ color: '#3469b7', fontWeight: 'bold' }) : ({})}>
										{data.num}
									</div>

								)}

								<i id={"adelante"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-right" />
							</div>
						)

					) : null}

				</div>


			);
		} else {
			return (
				<div>

				</div>
			);
		}

	}


}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		tipoUser: state.user.tipoUser
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dudas);

// Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser';
import { isDefined } from '../../../../lib/utils/is'

// Assets
import './css.css'


class NotaTema extends Component {
  static propTypes = {
    nota: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {}
    this.onHandleClick = this.onHandleClick.bind(this)
  }


  async onHandleClick(e) {
    if (e.target.id == "cerrarNota") {

      this.props.onClick(e)

    }

  }

  render() {

    const { nota } = this.props

    return (
      <div className='edtTemaNe-adm'>

        <div className='eTNe-a-contentDFG42' style={
          nota != undefined && nota != null ? (
            nota.match(/(<([^>]+)>)/i) != null ? (
              { }
            ) : (
              { display: 'flex', flexFlow: 'row wrap', alignItems: 'center' }
            )) : ({ })
        }>

          <i className="fa-light fa-circle-xmark botoncewrralc2" id="cerrarNota" onClick={this.onHandleClick}></i>
          {nota != undefined && nota != null ? (
            nota.match(/(<([^>]+)>)/i) != null ? (
              // <div className='notaPrepaTemaHtml'>

              <div id="wrapper01">
                <div class="scrollbar05" id="style-2" style={{ maxHeight: '295px', marginTop: '12px' }}>

                  <div class="force-overflow05 controlPdivbottom" style={{ textAlign: 'left' }}>
                    <p style={{ marginBottom: '10px', color: '#3469b7', fontWeight: '500' }}> Nota preparador </p>
                    {ReactHtmlParser(nota)}
                  </div>
                </div>
              </div>


            ) : (
              <div>
                {nota}
              </div>

            )
          ) : null}

        </div>

      </div >
    )
  }
}

// Conectamos Redux Store

export default NotaTema
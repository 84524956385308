// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isDefined } from '../../../../lib/utils/is'
//Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ReactPlayer from 'react-player'
import Input2 from '../../../Inputs/Input2'


// Assets
import './css.css';
import TemaSeleted from '../..';
import swal from 'sweetalert';

class VideoViewTema extends Component {
  static propTypes = {
    videos: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      vidvIEW: 0
    };
    this.onHandleClick = this.onHandleClick.bind(this);


  }


  async onHandleClick(e) {
    if (e.target.id == "cerrarVideo") {

      this.props.onClick(e)


    } else if (e.target.id.split('-')[0] == 'navV') {

      this.setState({ vidvIEW: e.target.id.split('-')[1] })
      
    }


  }

  render() {
    const { vidvIEW } = this.state;
    const { videos } = this.props;

    console.log('videos')
    console.log(videos)
    return (
      <div className='edtTemaNe-adm'>

        <div className='eTNe-a-contentDFG4'>
          <i className="fa-light fa-circle-xmark botoncewrralc2" id="cerrarVideo" onClick={this.onHandleClick}></i>
          {videos && videos.length > 0 ? (
            <div className='navVidCdtremall'>
              <b>Videos</b>
              {videos.map((video, key89) =>

                <div id={"navV-"+key89} onClick={this.onHandleClick} className={key89 == vidvIEW ? ('circleVideoNav circleVideoNavSelectdg3') : ('circleVideoNav')}>
                  {key89 + 1}
                </div>
              )}

              
            </div>
          ) : null}
          {videos && videos.length > 0 ? (

            <div className="videoAACDFLKW2" onContextMenu={e => e.preventDefault()}>
              <ReactPlayer
                url={videos[vidvIEW].link}
                className='react-player'
                playing={false}
                width='100%'
                height='100%'
                controls={true}
                onContextMenu={e => e.preventDefault()}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
              />

            </div>

          ) : (
            <div className="recPlayVidDivNovideo">
              Video no disponible
            </div>
          )

          }


        </div>

      </div >
    );
  }
}

// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    temasFacturados: state.user.temasFacturados,
    tipoUser: state.user.tipoUser
  }
}


export default connect(mapStateToProps, null)(VideoViewTema);
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
moment.locale('es');
import $ from 'jquery';
import ReactHtmlParser from 'react-html-parser';
import { comprobarRespuestas, respuestaSbr } from '../../lib/utils/cargarTestJusticia';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { isDefined } from '../../lib/utils/is';
//Assets
import './css/notificacionesNewa.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { notificiacionesVistas } from '../../actions/userAction';
// Components
import Boton from '../../components/Inputs/Boton';
import Boton2 from '../../components/Inputs/Boton2';
import InputFileExamDesa from '../../components/Inputs/InputFileExamDesa';
import Input2 from '../../components/Inputs/Input2';


import { Ellipsis } from '../../components/react-css-spinners'
import Dudas from '../../components/Dudas';


class ExpedienteUser extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      notificaciones: undefined,
      numView: 0,
      navIni: 0,
      navFin: 10,
      listadoNav: null,
      cargarContenido: 'todo',
      notiLoadId: null,
      dudaLoad: null,
      textHtmlTitulo: '',
      textHtmlMensaje: '',
      tipoEnvioNotificacion: 'normal',
      filesLoad: null,
      verBotonesSelectwhoSend: true,
      aQuienEnviamosNoti: [],
      usuariosLoad: null,
      clasesLoad: null,
      examenesGestionLoad: null,
      infoExamenGestionLoad: null
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleRedactar = this.handleRedactar.bind(this)
    this.onChangeText = this.onChangeText.bind(this)
    this.onChangeText2 = this.onChangeText2.bind(this)
    this.onChangeText3 = this.onChangeText3.bind(this)
    this.myFiles = this.myFiles.bind(this)
  }


  async myFiles(e) {
    var e = e
    if (e.target.name == 'addFile') {
      try {

        let files = e.target.files;
        let filesLoad = [];
        for (let i = 0; i < files.length; i++) {
          await filesLoad.push(files[i]);
        }
        if (filesLoad && filesLoad.length > 0) {
          this.setState({ filesLoad: filesLoad })
        } else {
          this.setState({ filesLoad: null })
        }


      } catch (e) { console.log(e) }
    }

  }

  onChangeText(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      this.setState({ textHtmlMensaje: textHtml })
    }
  }
  onChangeText2(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      this.setState({ textHtmlTitulo: textHtml })
    }
  }

  onChangeText3(e, editor) {
    let textHtml = editor.getData();

    if (textHtml != '') {
      this.setState({ textHtmlMensajeExamen: textHtml })
    }
  }

  async handleRedactar(e) {
    if (e.target.id == 'sendNoti') {
      let { aQuienEnviamosNoti, tipoEnvioNotificacion, filesLoad, textHtmlMensaje, textHtmlTitulo } = this.state
      // tipo noti normal tipoUrgente tipoReformaLey
      // tipo aQuienEnviamosNoti
      //   usuario
      //   clase
      //   todosCursos
      //   todosUsuarios
      //   todosPreparadores
      if (aQuienEnviamosNoti.length > 0) {

        if (textHtmlMensaje && textHtmlTitulo && textHtmlMensaje != '' && textHtmlTitulo != '') {
          let todoReady = true
          for (let i2 = 0; i2 < aQuienEnviamosNoti.length; i2++) {
            if (aQuienEnviamosNoti[i2].enviamosA.length == 0) {
              swal('Error', 'Rellene el destinatario en la posición ' + parseInt(i2 + 1), 'error')
              todoReady = false
              break;
            }

          }
          if (todoReady == true) {

            let infoFiles = []
            if (filesLoad != null && filesLoad.length > 0) {
              swal('Cargando archivos', '', 'info')
              let data1 = new FormData();
              for (var i = 0; i < filesLoad.length; i++) {
                data1.append('myFiles', filesLoad[i]);
              }
              let response3 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
                method: 'POST', body: data1
              });
              if (!response3.ok) {
                swal('Carga de archivos fallida', 'Revise los nombres de los archivos no pueden contener ningun punto "." Ejemplo: archivo.1.pdf = archivo-1.pdf', 'error')
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;

              let apuntes = [];
              for (var f = 0; f < cls3.length; f++) {
                let path = cls3[f].path.split('src/')[1];
                //let nameFile = cls3[f].originalname.split('.')[0];
                let nameFile = '';
                for (let ih = 0; ih < cls3[f].originalname.split('.').length; ih++) {
                  if (ih == cls3[f].originalname.split('.').length - 1) {
                    continue;
                  } else {

                    if (ih == cls3[f].originalname.split('.').length - 2) {
                      nameFile = nameFile + cls3[f].originalname.split('.')[ih]
                    } else {
                      nameFile = nameFile + cls3[f].originalname.split('.')[ih] + '.'
                    }
                  }

                }
                infoFiles.push({ nombre: nameFile, link: path });
              }

              swal('Archivos cargados', 'Enviando notificaciones a destinatarios', 'info')
            }

            swal('Enviado notifiacion', '', 'info')
            for (let i = 0; i < aQuienEnviamosNoti.length; i++) {
              if (aQuienEnviamosNoti[i].tipo == 'usuario') {
                let idUserRecibe = aQuienEnviamosNoti[i].enviamosA[0].id_usuario
                let notificacion = {
                  idUserRecibe: idUserRecibe,
                  idUserEnvia: this.props.idUser,
                  fecha: moment().format('YYYY-MM-DD HH:mm'),
                  titulo: textHtmlTitulo,
                  notificacionTexto: textHtmlMensaje,
                  tipoPreparadores: 0,
                  tipoOficina: 0,
                  tipoDuda: 0,
                  tipoUrgente: tipoEnvioNotificacion == 'tipoUrgente' || tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                  tipoReformasLey: tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                  idDuda: null
                }
                let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(notificacion)
                });
                if (!response4.ok) {
                  throw Error(response4.statusText);
                }
                let json4 = await response4.json();
                let cls4 = await json4;

                // enlazar descargas a notificacion --> notificaciones_descargas BD (idNoti link nombre)
                if (infoFiles.length > 0 && cls4.affectedRows > 0) {
                  for (let i4 = 0; i4 < infoFiles.length; i4++) {
                    let fileInfo = {
                      id: cls4.insertId,
                      nombre: infoFiles[i4].nombre,
                      link: infoFiles[i4].link
                    }
                    let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/addDescargasANoti", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fileInfo)
                    });
                    if (!response5.ok) {
                      throw Error(response5.statusText);
                    }
                    let json5 = await response5.json();
                    let cls5 = await json5;
                  }

                }

              } else if (aQuienEnviamosNoti[i].tipo == 'todosUsuarios') {
                for (let i3 = 0; i3 < aQuienEnviamosNoti[i].enviamosA[0].length; i3++) {
                  let idUserRecibe = aQuienEnviamosNoti[i].enviamosA[0][i3].id_usuario
                  let notificacion = {
                    idUserRecibe: idUserRecibe,
                    idUserEnvia: this.props.idUser,
                    fecha: moment().format('YYYY-MM-DD HH:mm'),
                    titulo: textHtmlTitulo,
                    notificacionTexto: textHtmlMensaje,
                    tipoPreparadores: 0,
                    tipoOficina: 0,
                    tipoDuda: 0,
                    tipoUrgente: tipoEnvioNotificacion == 'tipoUrgente' || tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    tipoReformasLey: tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    idDuda: null
                  }
                  let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(notificacion)
                  });
                  if (!response4.ok) {
                    throw Error(response4.statusText);
                  }
                  let json4 = await response4.json();
                  let cls4 = await json4;

                  if (infoFiles.length > 0 && cls4.affectedRows > 0) {
                    for (let i4 = 0; i4 < infoFiles.length; i4++) {
                      let fileInfo = {
                        id: cls4.insertId,
                        nombre: infoFiles[i4].nombre,
                        link: infoFiles[i4].link
                      }
                      let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/addDescargasANoti", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fileInfo)
                      });
                      if (!response5.ok) {
                        throw Error(response5.statusText);
                      }
                      let json5 = await response5.json();
                      let cls5 = await json5;
                    }

                  }
                }

              } else if (aQuienEnviamosNoti[i].tipo == 'todosPreparadores') {


                for (let i3 = 0; i3 < aQuienEnviamosNoti[i].enviamosA[0].length; i3++) {
                  let idUserRecibe = aQuienEnviamosNoti[i].enviamosA[0][i3].id_usuario


                  let notificacion = {
                    idUserRecibe: idUserRecibe,
                    idUserEnvia: this.props.idUser,
                    fecha: moment().format('YYYY-MM-DD HH:mm'),
                    titulo: textHtmlTitulo,
                    notificacionTexto: textHtmlMensaje,
                    tipoPreparadores: 0,
                    tipoOficina: 0,
                    tipoDuda: 0,
                    tipoUrgente: tipoEnvioNotificacion == 'tipoUrgente' || tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    tipoReformasLey: tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    idDuda: null
                  }


                  let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(notificacion)
                  });
                  if (!response4.ok) {
                    throw Error(response4.statusText);
                  }
                  let json4 = await response4.json();
                  let cls4 = await json4;
                  if (infoFiles.length > 0 && cls4.affectedRows > 0) {
                    for (let i4 = 0; i4 < infoFiles.length; i4++) {
                      let fileInfo = {
                        id: cls4.insertId,
                        nombre: infoFiles[i4].nombre,
                        link: infoFiles[i4].link
                      }
                      let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/addDescargasANoti", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fileInfo)
                      });
                      if (!response5.ok) {
                        throw Error(response5.statusText);
                      }
                      let json5 = await response5.json();
                      let cls5 = await json5;
                    }

                  }
                }

              } else if (aQuienEnviamosNoti[i].tipo == 'curso') {


                let idClase = aQuienEnviamosNoti[i].enviamosA[0].id_clase
                let data5 = {
                  idClase: idClase
                }
                let response5 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
                });
                if (!response5.ok) {
                  throw Error(response5.statusText);
                }
                let json5 = await response5.json();
                let cls5 = await json5;
                for (let i5 = 0; i5 < cls5.length; i5++) {

                  let notificacion = {
                    idUserRecibe: cls5[i5].idAlumno,
                    idUserEnvia: this.props.idUser,
                    fecha: moment().format('YYYY-MM-DD HH:mm'),
                    titulo: textHtmlTitulo,
                    notificacionTexto: textHtmlMensaje,
                    tipoPreparadores: 0,
                    tipoOficina: 0,
                    tipoDuda: 0,
                    tipoUrgente: tipoEnvioNotificacion == 'tipoUrgente' || tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    tipoReformasLey: tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                    idDuda: null
                  }
                  let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(notificacion)
                  });
                  if (!response4.ok) {
                    throw Error(response4.statusText);
                  }
                  let json4 = await response4.json();
                  let cls4 = await json4;

                  if (infoFiles.length > 0 && cls4.affectedRows > 0) {
                    for (let i4 = 0; i4 < infoFiles.length; i4++) {
                      let fileInfo = {
                        id: cls4.insertId,
                        nombre: infoFiles[i4].nombre,
                        link: infoFiles[i4].link
                      }
                      let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/addDescargasANoti", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fileInfo)
                      });
                      if (!response5.ok) {
                        throw Error(response5.statusText);
                      }
                      let json5 = await response5.json();
                      let cls5 = await json5;
                    }

                  }
                }



              } else if (aQuienEnviamosNoti[i].tipo == 'todosCursos') {


                for (let i3 = 0; i3 < aQuienEnviamosNoti[i].enviamosA[0].length; i3++) {
                  let idClase = aQuienEnviamosNoti[i].enviamosA[0][i3].id_clase
                  let data5 = {
                    idClase: idClase
                  }
                  let response5 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data5)
                  });
                  if (!response5.ok) {
                    throw Error(response5.statusText);
                  }
                  let json5 = await response5.json();
                  let cls5 = await json5;
                  for (let i5 = 0; i5 < cls5.length; i5++) {

                    let notificacion = {
                      idUserRecibe: cls5[i5].idAlumno,
                      idUserEnvia: this.props.idUser,
                      fecha: moment().format('YYYY-MM-DD HH:mm'),
                      titulo: textHtmlTitulo,
                      notificacionTexto: textHtmlMensaje,
                      tipoPreparadores: 0,
                      tipoOficina: 0,
                      tipoDuda: 0,
                      tipoUrgente: tipoEnvioNotificacion == 'tipoUrgente' || tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                      tipoReformasLey: tipoEnvioNotificacion == 'tipoReformaLey' ? (1) : (0),
                      idDuda: null
                    }
                    let response4 = await fetch("https://oposiciones-justicia.es/api/usuarios/enviarNotificacion", {
                      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(notificacion)
                    });
                    if (!response4.ok) {
                      throw Error(response4.statusText);
                    }
                    let json4 = await response4.json();
                    let cls4 = await json4;
                    if (infoFiles.length > 0 && cls4.affectedRows > 0) {
                      for (let i4 = 0; i4 < infoFiles.length; i4++) {
                        let fileInfo = {
                          id: cls4.insertId,
                          nombre: infoFiles[i4].nombre,
                          link: infoFiles[i4].link
                        }
                        let response5 = await fetch("https://oposiciones-justicia.es/api/usuarios/addDescargasANoti", {
                          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(fileInfo)
                        });
                        if (!response5.ok) {
                          throw Error(response5.statusText);
                        }
                        let json5 = await response5.json();
                        let cls5 = await json5;
                      }
  
                    }

                  }

                }

              }

            }

            swal('Notificion enviada', '', 'success')
            window.location.reload()



          }


        } else {
          swal('Error', 'Añada un titulo y mensaje')
        }

      } else {
        swal('Error', 'Añada un destinatarío')
      }

    } else if (e.target.id == "addCurso") {
      this.setState({ verBotonesSelectwhoSend: false })

      let aQuienEnviamosNoti = this.state.aQuienEnviamosNoti
      aQuienEnviamosNoti.push({
        tipo: 'curso',
        enviamosA: []
      })

      if (this.state.clasesLoad == null) {

        let response1 = await fetch("https://oposiciones-justicia.es/api/clases/todasClases");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let clasesLoad = await cls1

        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti, clasesLoad: clasesLoad })

      } else {
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti })
      }


    } else if (e.target.id == "addTodosCursos") {
      this.setState({ verBotonesSelectwhoSend: false })

      let aQuienEnviamosNoti = this.state.aQuienEnviamosNoti
      if (aQuienEnviamosNoti.filter(ns => ns.tipo == 'todosCursos').length == 0) {
        aQuienEnviamosNoti.push({
          tipo: 'todosCursos',
          enviamosA: []
        })
      }

      if (this.state.clasesLoad == null) {

        let response1 = await fetch("https://oposiciones-justicia.es/api/clases/todasClases");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let clasesLoad = await cls1
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(clasesLoad)
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti, clasesLoad: clasesLoad })

      } else {
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(this.state.clasesLoad)
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti })
      }
    } else if (e.target.id == "addUsuario") {
      // cargar todos los usuarios  
      this.setState({ verBotonesSelectwhoSend: false })

      let aQuienEnviamosNoti = this.state.aQuienEnviamosNoti
      aQuienEnviamosNoti.push({
        tipo: 'usuario',
        enviamosA: []
      })

      if (this.state.usuariosLoad == null) {

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/consulta");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let usuariosLoad = await cls1

        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti, usuariosLoad: usuariosLoad })

      } else {
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti })
      }




    } else if (e.target.id == "addTodosUsuario") {
      this.setState({ verBotonesSelectwhoSend: false })

      let aQuienEnviamosNoti = this.state.aQuienEnviamosNoti
      if (aQuienEnviamosNoti.filter(ns => ns.tipo == 'todosUsuarios').length == 0) {
        aQuienEnviamosNoti.push({
          tipo: 'todosUsuarios',
          enviamosA: []
        })
      }


      if (this.state.usuariosLoad == null) {

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/consulta");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let usuariosLoad = await cls1
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(usuariosLoad)

        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti, usuariosLoad: usuariosLoad })

      } else {
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(this.state.usuariosLoad)
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti })
      }
    } else if (e.target.id == "addTodosPreparadores") {

      this.setState({ verBotonesSelectwhoSend: false })

      let aQuienEnviamosNoti = this.state.aQuienEnviamosNoti
      if (aQuienEnviamosNoti.filter(ns => ns.tipo == 'todosPreparadores').length == 0) {
        aQuienEnviamosNoti.push({
          tipo: 'todosPreparadores',
          enviamosA: []
        })
      }

      if (this.state.usuariosLoad == null) {

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/consulta");
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let usuariosLoad = await cls1
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(usuariosLoad.filter(a => a.nivel_usuario == 58 || a.nivel_usuario >= 98))

        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti, usuariosLoad: usuariosLoad })

      } else {
        await aQuienEnviamosNoti[aQuienEnviamosNoti.length - 1].enviamosA.push(this.state.usuariosLoad.filter(a => a.nivel_usuario == 58 || a.nivel_usuario >= 98))
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNoti })
      }

    } else if (e.target.id == "tipoUrgente") {
      if (this.state.tipoEnvioNotificacion == 'tipoUrgente') {
        this.setState({ tipoEnvioNotificacion: 'normal' })
      } else {
        this.setState({ tipoEnvioNotificacion: 'tipoUrgente' })
      }
    } else if (e.target.id == "tipoReformaLey") {
      if (this.state.tipoEnvioNotificacion == 'tipoReformaLey') {
        this.setState({ tipoEnvioNotificacion: 'normal' })
      } else {
        this.setState({ tipoEnvioNotificacion: 'tipoReformaLey' })
      }
    } else if (e.target.id == "addOtroAenviar") {
      this.setState({ verBotonesSelectwhoSend: true })
    } else if (e.target.id.split('-')[0] == 'aQuienEnviamos') {
      let user = this.state.usuariosLoad.filter(u => u.id_usuario == e.target.value)

      if (user.length > 0) {
        let loca = e.target.id.split('-')[1]
        let aQuienEnviamosNotiS = this.state.aQuienEnviamosNoti
        await aQuienEnviamosNotiS[loca].enviamosA.push(user[0])
        if (aQuienEnviamosNotiS[loca].enviamosA.length > 1) {
          await aQuienEnviamosNotiS[loca].enviamosA.splice(0, 1)
        }
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNotiS })
      } else {
        let loca = e.target.id.split('-')[1]
        let aQuienEnviamosNotiS = this.state.aQuienEnviamosNoti
        if (aQuienEnviamosNotiS[loca].enviamosA.length > 0) {
          await aQuienEnviamosNotiS[loca].enviamosA.splice(0, 1)

          this.setState({ aQuienEnviamosNoti: aQuienEnviamosNotiS })

        }

      }

    } else if (e.target.id.split('-')[0] == 'aQClaseEnviamos') {
      let clase = this.state.clasesLoad.filter(u => u.id_clase == e.target.value)

      if (clase.length > 0) {
        let loca = e.target.id.split('-')[1]
        let aQuienEnviamosNotiS = this.state.aQuienEnviamosNoti
        await aQuienEnviamosNotiS[loca].enviamosA.push(clase[0])
        if (aQuienEnviamosNotiS[loca].enviamosA.length > 1) {
          await aQuienEnviamosNotiS[loca].enviamosA.splice(0, 1)
        }
        this.setState({ aQuienEnviamosNoti: aQuienEnviamosNotiS })
      } else {
        let loca = e.target.id.split('-')[1]
        let aQuienEnviamosNotiS = this.state.aQuienEnviamosNoti
        if (aQuienEnviamosNotiS[loca].enviamosA.length > 0) {
          await aQuienEnviamosNotiS[loca].enviamosA.splice(0, 1)

          this.setState({ aQuienEnviamosNoti: aQuienEnviamosNotiS })

        }

      }

    } else if (e.target.id.split('-')[0] == 'delPreEnv') {

      let loca = e.target.id.split('-')[1]
      let aQuienEnviamosNotiS = this.state.aQuienEnviamosNoti
      if (aQuienEnviamosNotiS.length > loca) {
        await aQuienEnviamosNotiS.splice(loca, 1)
        if (aQuienEnviamosNotiS.length > 0) {
          this.setState({ aQuienEnviamosNoti: aQuienEnviamosNotiS })
        } else {
          this.setState({ aQuienEnviamosNoti: [], verBotonesSelectwhoSend: true })
        }


      }


    }


  }

  async handleOnSubmit(e) {
    if (e.target.id == 'enviarNotificacion') {

      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        this.setState({ dudaLoad: null, notiLoadId: null, cargarContenido: 'enviarNotificacion' })
        $('.bNe2-l-notificacionPrev2').slideDown().css("display", "flex");


        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones })
      } else {

        this.setState({ cargarContenido: 'enviarNotificacion' })
      }

    } else if (e.target.id == 'enviados') {
      // Buscamos los enviados y los cargamos
      let data1 = { idUser: this.props.idUser }
      let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionesEnviadas", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response0.ok) {
        throw Error(response0.statusText);
      }
      let json0 = await response0.json();
      let cls0 = await json0;


      let notificaciones = await cls0.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))

      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        this.setState({ dudaLoad: null, notiLoadId: null })

        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones, cargarContenido: 'enviados' })
      } else {
        swal('No tiene ningun notificación enviada', '', 'info')
      }


    } else if (e.target.id == 'cerrarCrearNoti') {

      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        this.setState({ dudaLoad: null, notiLoadId: null })



        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones, cargarContenido: 'todo' })
      } else {
        swal('No tiene ninguna duda que visualizar', '', 'info')
        this.setState({ cargarContenido: 'todo' })
      }
    } else if (e.target.id == "todo") {

      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }
        if (this.state.notiLoadId != null) {
          // // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
          this.setState({ dudaLoad: null, notiLoadId: null })
        }


        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones, cargarContenido: 'todo' })
      } else {
        swal('No tiene ninguna duda que visualizar', '', 'info')
      }
    } else if (e.target.id == "dudas") {

      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave.filter(n => n.tipoDuda == 1 || n.tipoDuda == 2 || n.tipoDuda == 3)
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }
        if (this.state.notiLoadId != null) {
          // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
          this.setState({ dudaLoad: null, notiLoadId: null })
        }

        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones, cargarContenido: 'dudas' })
      } else {
        swal('No tiene ninguna duda que visualizar', '', 'info')
      }


    } else if (e.target.id == "facturacion") {
      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave.filter(n => n.tipoFacturacion == 1)
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        if (this.state.notiLoadId != null) {
          // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
          this.setState({ dudaLoad: null, notiLoadId: null })
        }

        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })

        this.setState({ notificaciones: notificaciones, cargarContenido: 'facturacion' })
      } else {
        swal('No tiene ninguna factura que visualizar', '', 'info')
      }

    } else if (e.target.id == "examen") {
      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave.filter(n => n.tipoExamenGestion == 1)
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        if (this.state.notiLoadId != null) {
          // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
          this.setState({ dudaLoad: null, notiLoadId: null })
        }


        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })


        this.setState({ notificaciones: notificaciones, cargarContenido: 'examen' })
      } else {
        swal('No tiene ninguna exámen que visualizar', '', 'info')
      }

    } else if (e.target.id == "reformasLey") {
      let notificaciones = this.state.notificaciones
      let notificacionesSave = this.state.notificacionesSave

      notificaciones = await notificacionesSave.filter(n => n.tipoReformasLey == 1)
      if (notificaciones.length > 0) {

        let maxValue = Math.ceil(notificaciones.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }

        if (this.state.notiLoadId != null) {
          // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
          this.setState({ dudaLoad: null, notiLoadId: null })
        }

        this.setState({ listadoNav: listadoNav, numView: 0, navIni: 0, navFin: 10 })


        this.setState({ notificaciones: notificaciones, cargarContenido: 'reformasLey' })
      } else {
        swal('No tiene ninguna reforma que visualizar', '', 'info')
      }


    } else if (e.target.id == "atras") {
      let actual = this.state.numView
      // let ini = this.state.navIni
      // let fin = this.state.navFin

      let next = actual - 1
      if (this.state.notificaciones && this.state.notificaciones.length > 0) {
        let maxValue = Math.ceil(this.state.notificaciones.length / 6)

        if (next >= 0 && next < maxValue) {
          this.setState({ numView: next })


          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navIni: (next - 5), navFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navIni: iniRecalt, navFin: maxValue })
              } else {
                this.setState({ navIni: 0, navFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navIni: 0, navFin: maxValue })
            }
          }
        }
      }

    } else if (e.target.id == "adelante") {
      let actual = this.state.numView

      let next = actual + 1
      if (this.state.notificaciones && this.state.notificaciones.length > 0) {
        let maxValue = Math.ceil(this.state.notificaciones.length / 6)

        if (next >= 0 && next < maxValue) {
          this.setState({ numView: next })

          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navIni: (next - 5), navFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navIni: iniRecalt, navFin: maxValue })
              } else {
                this.setState({ navIni: 0, navFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navIni: 0, navFin: maxValue })
            }
          }


        }
      }


    } else if (e.target.id == 'cerrarNotiLoad') {

      // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
      this.setState({ dudaLoad: null, notiLoadId: null })


    } else if (e.target.id == 'borrarNotiLoad') {
      let idNotiLoad = this.state.notiLoadId
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, la notificación se eliminará.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {


            let data = {
              id: idNotiLoad
            }
            let response = await fetch("https://oposiciones-justicia.es/api/usuarios/deleteNotificacion", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {

              swal("Notificación eliminada", '', 'success');

              let dudasNew = await this.state.notificaciones
              let save = await this.state.notificacionesSave

              let locaN = await this.state.notificaciones.findIndex(u => u.id == idNotiLoad)
              let locaNs = await this.state.notificacionesSave.findIndex(u => u.id == idNotiLoad)
              await dudasNew.splice(locaN, 1)
              // $('.bNe2-right').css("background", "url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1952&q=80')");
              this.setState({ notificaciones: dudasNew, notificacionesSave: save, dudaLoad: null, notiLoadId: null })


            }



          } else {
            swal("Notificación no eliminada", '', "success");
          }
        });

    } else if (e.target.id == 'descargarPdfExaGes') {

      let pathFile = this.state.infoExamenGestionLoad[0].linkExamen

      if (pathFile != undefined) {
        swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga. Reportelo, gracias.")
        });
      }


    } else if (e.target.id == 'descargarPdfExaGesSolucion') {
      let pathFile = this.state.infoExamenGestionLoad[0].linkSolucion

      if (pathFile != undefined) {
        swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga. Reportelo, gracias.")
        });
      }
    } else if (e.target.id == 'descargarPdfExaGesAlumn') {

      let pathFile = this.state.infoExamenGestionLoad[0].linkArchivoAlumno

      if (pathFile != undefined) {
        swal('Iniciando descarga', 'Si en 30 segundos no se inicia vuelva a darle clic', 'info')
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
        }).catch(function (err) {
          console.log(err)
          alert("Link no valido para descarga. Reportelo, gracias.")
        });
      }
    } else if (e.target.id == 'corregirExamenGestion') {


      let { textHtmlMensajeExamen, puntuacionExamenGestion } = this.state

      if (textHtmlMensajeExamen != null && textHtmlMensajeExamen != '' && puntuacionExamenGestion != null) {
        try {
          let data1 = {
            alumnSelectExam: {
              anotaciones: textHtmlMensajeExamen,
              puntuacion: puntuacionExamenGestion,
              id_exp: this.state.infoExamenGestionLoad[0].id_exp
            }
          }
          let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/corregirExamGes", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;



          if (cls1.affectedRows > 0) {
            let infoExamenGestionLoadS = await this.state.infoExamenGestionLoad

            infoExamenGestionLoadS[0].anotaciones = textHtmlMensajeExamen

            infoExamenGestionLoadS[0].puntuacion = puntuacionExamenGestion

            this.setState({ infoExamenGestionLoad: infoExamenGestionLoadS })
          }


          swal('Correción realizada', '', 'success')
        } catch (e) { console.log(e) }
      } else {
        swal('Error falta texto o puntuación', '', 'error')
      }


    } else if (e.target.id == 'puntuacionExamenGestion') {
      if (e.target.value != '' && e.target.value >= 0) {

        let value = e.target.value
        if (value.split('.') > 0) {
          for (let index = 0; index < value.split('.').length; index++) {
            value = await value.replace(/\./g, ',');
          }
        }
        this.setState({ puntuacionExamenGestion: parseFloat(value).toFixed(2) })
      } else {
        this.setState({ puntuacionExamenGestion: null })
      }

    } else if (e.target.id.split('-')[0] == "clicNavDirect") {


      let locaClic = e.target.id.split('-')[1]
      let ini = this.state.navIni
      let fin = this.state.navFin
      let next = this.state.listadoNav.slice(ini, fin)[locaClic].num - 1
      if (this.state.notificaciones && this.state.notificaciones.length > 0) {
        let maxValue = Math.ceil(this.state.notificaciones.length / 6)

        if (next >= 0 && next < maxValue) {
          this.setState({ numView: next })

          //  vemos inicio
          if (next - 5 >= 0) {

            if (next + 5 <= maxValue) {
              this.setState({ navIni: (next - 5), navFin: (next + 5) })
            } else {
              let sumarCompleteNav = (next + 5) - maxValue
              let iniRecalt = (next - 5) - (sumarCompleteNav)
              if (iniRecalt >= 0) {
                this.setState({ navIni: iniRecalt, navFin: maxValue })
              } else {
                this.setState({ navIni: 0, navFin: maxValue })
              }

            }

          } else {
            let sumarCompleteNav = (next - 5) * -1
            if ((next + sumarCompleteNav + 5) <= maxValue) {
              this.setState({ navIni: 0, navFin: (next + sumarCompleteNav + 5) })
            } else {
              this.setState({ navIni: 0, navFin: maxValue })
            }
          }


        }
      }





    } else if (e.target.id.split('-')[0] == 'infoTest') {

      let key = e.target.id.split('-')[1]
      $('#infoTestH-' + key).fadeToggle(700).css("display", "flex");


      if (this.state.notificaciones[key].visualizado == 0) {
        let data1 = { idNotificacion: this.state.notificaciones[key].id }

        let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionLeida", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        let notificacionesNew = this.state.notificaciones
        notificacionesNew[key].visualizado = 1

        this.setState({ notificaciones: notificacionesNew })
        if (notificacionesNew && notificacionesNew == null || notificacionesNew == undefined) {
          this.props.notificiacionesVistas(0)
        } else if (notificacionesNew && notificacionesNew != null && notificacionesNew != undefined && notificacionesNew.length > 0 && notificacionesNew.filter(n => n.visualizado == 0).length == 0) {
          this.props.notificiacionesVistas(0)
        } else if (notificacionesNew && notificacionesNew != null && notificacionesNew != undefined && notificacionesNew.length == 0) {
          this.props.notificiacionesVistas(0)
        } else {
          this.props.notificiacionesVistas(1)
        }

      }
    } else if (e.target.id.split('-')[0] == 'notificacion') {

      let key = e.target.id.split('-')[1]

      this.setState({ dudaLoad: null })
      let idNoti = await this.state.notificaciones.slice(6 * this.state.numView, this.state.numView == 0 ? (6) : (6 * (this.state.numView + 1)))[key].id


      let notificacion = await this.state.notificaciones.filter(n => n.id == idNoti)

      if (notificacion.length > 0) {
        if (this.state.cargarContenido == 'enviarNotificacion') {
          this.setState({ cargarContenido: 'todo' })
        }
        if (notificacion[0].idExamenGestion != null) {
          // cargamos el examen /infoExamenGestion
          let data1ty = { idUser: this.props.tipoUser & this.props.tipoUser >= 58 || this.props.tipoUser >= 98 ? (notificacion[0].idUserEnvia) : (this.props.idUser), idExamenGestion: notificacion[0].idExamenGestion, idPrg: notificacion[0].idPrg, idClase: notificacion[0].idClase, idBloque: notificacion[0].idBloque }

          let response1ty = await fetch("https://oposiciones-justicia.es/api/usuarios/infoExamenGestion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1ty)
          });
          if (!response1ty.ok) {
            throw Error(response1ty.statusText);
          }
          let json1ty = await response1ty.json();
          let cls1Ty = await json1ty;

          this.setState({ infoExamenGestionLoad: cls1Ty })
        } else {
          this.setState({ infoExamenGestionLoad: null })
        }

        this.setState({ notiLoadId: notificacion[0].id })
        $('.bNe2-l-notificacionPrev2').slideDown().css("display", "flex");



        if (notificacion[0].tipoDuda == 1) {

          //obtener duda y cargarlar
          let data0 = { idNotificacion: notificacion[0].idDuda }
          let testNew = []
          let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/loadDudaJus", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data0)
          });
          if (!response0.ok) {
            throw Error(response0.statusText);
          }
          let json0 = await response0.json();
          let cls0 = await json0;

          if (cls0 != undefined && cls0.length > 0) {
            for (var i = 0; i < await cls0.length; i++) {
              let textAlumno = cls0[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls0[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls0[i].idReport,
                idUser: cls0[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls0[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls0[i].estadoReport,
                fecha: cls0[i].fecha,
                idPreg: cls0[i].idPregunta,
                pregunta: cls0[i].pregunta, tipoPregunta: cls0[i].tipoPregunta, articulo: cls0[i].articulo,
                idLey: cls0[i].idLey,
                siglasLey: cls0[i].siglasLey,
                nombreLey: cls0[i].nombreLey,
                idR1: cls0[i].idR1, idR2: cls0[i].idR2, idR3: cls0[i].idR3, idR4: cls0[i].idR4,
                respuestas: [],
                alumnosDuda: []
              })

              let r1Sbr = cls0[i].R1
              if (cls0[i].subrayado1 != '' && cls0[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls0[i].R1, cls0[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls0[i].R1,
                validez: cls0[i].validezR1,
                subrayado: cls0[i].subrayado1,
                nota: cls0[i].nota1,
                valorUser: cls0[i].r1ValorUser
              })
              let r2Sbr = cls0[i].R2
              if (cls0[i].subrayado2 != '' && cls0[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls0[i].R2, cls0[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls0[i].R2,
                validez: cls0[i].validezR2,
                subrayado: cls0[i].subrayado2,
                nota: cls0[i].nota2,
                valorUser: cls0[i].r2ValorUser
              })
              let r3Sbr = cls0[i].R3
              if (cls0[i].subrayado3 != '' && cls0[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls0[i].R3, cls0[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls0[i].R3,
                validez: cls0[i].validezR3,
                subrayado: cls0[i].subrayado3,
                nota: cls0[i].nota3,
                valorUser: cls0[i].r3ValorUser
              })
              let r4Sbr = cls0[i].R4
              if (cls0[i].subrayado4 != '' && cls0[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls0[i].R4, cls0[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls0[i].R4,
                validez: cls0[i].validezR4,
                subrayado: cls0[i].subrayado4,
                nota: cls0[i].nota4,
                valorUser: cls0[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls0[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDuda", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls0[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              this.setState({ dudaLoad: dudasComprobadas })
              //console.log(dudasComprobadas)
            } else {
              this.setState({ dudaLoad: null })
            }
          } else {
            this.setState({ dudaLoad: null })
          }
        } else if (notificacion[0].tipoDuda == 2) {

          //obtener duda y cargarlar
          let data0 = { idNotificacion: notificacion[0].idDuda }
          let testNew = []
          let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/loadDudaCP", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data0)
          });
          if (!response0.ok) {
            throw Error(response0.statusText);
          }
          let json0 = await response0.json();
          let cls0 = await json0;

          if (cls0 != undefined && cls0.length > 0) {
            for (var i = 0; i < await cls0.length; i++) {
              let textAlumno = cls0[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls0[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }
              testNew.push({
                idReport: cls0[i].idReport,
                idUser: cls0[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls0[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls0[i].estadoReport,
                fecha: cls0[i].fecha,
                idPreg: cls0[i].idPregunta,
                pregunta: cls0[i].pregunta, tipoPregunta: cls0[i].tipoPregunta, articulo: cls0[i].articulo,
                idLey: cls0[i].idLey,
                siglasLey: cls0[i].siglasLey,
                nombreLey: cls0[i].nombreLey,
                idR1: cls0[i].idR1, idR2: cls0[i].idR2, idR3: cls0[i].idR3, idR4: cls0[i].idR4,
                respuestas: [],
                alumnosDuda: []
              })

              let r1Sbr = cls0[i].R1
              if (cls0[i].subrayado1 != '' && cls0[i].subrayado1 != undefined) {
                r1Sbr = await respuestaSbr(cls0[i].R1, cls0[i].subrayado1)
              }

              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR1,
                respuesta: r1Sbr,
                respuestaBse: cls0[i].R1,
                validez: cls0[i].validezR1,
                subrayado: cls0[i].subrayado1,
                nota: cls0[i].nota1,
                valorUser: cls0[i].r1ValorUser
              })
              let r2Sbr = cls0[i].R2
              if (cls0[i].subrayado2 != '' && cls0[i].subrayado2 != undefined) {
                r2Sbr = await respuestaSbr(cls0[i].R2, cls0[i].subrayado2)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR2,
                respuesta: r2Sbr,
                respuestaBse: cls0[i].R2,
                validez: cls0[i].validezR2,
                subrayado: cls0[i].subrayado2,
                nota: cls0[i].nota2,
                valorUser: cls0[i].r2ValorUser
              })
              let r3Sbr = cls0[i].R3
              if (cls0[i].subrayado3 != '' && cls0[i].subrayado3 != undefined) {
                r3Sbr = await respuestaSbr(cls0[i].R3, cls0[i].subrayado3)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR3,
                respuesta: r3Sbr,
                respuestaBse: cls0[i].R3,
                validez: cls0[i].validezR3,
                subrayado: cls0[i].subrayado3,
                nota: cls0[i].nota3,
                valorUser: cls0[i].r3ValorUser
              })
              let r4Sbr = cls0[i].R4
              if (cls0[i].subrayado4 != '' && cls0[i].subrayado4 != undefined) {
                r4Sbr = await respuestaSbr(cls0[i].R4, cls0[i].subrayado4)
              }
              testNew[testNew.length - 1].respuestas.push({
                idR: cls0[i].idR4,
                respuesta: r4Sbr,
                respuestaBse: cls0[i].R4,
                validez: cls0[i].validezR4,
                subrayado: cls0[i].subrayado4,
                nota: cls0[i].nota4,
                valorUser: cls0[i].r4ValorUser
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls0[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaCasoPrac", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              testNew[testNew.length - 1].alumnosDuda = await cls2
              testNew[testNew.length - 1].alumnosDuda.push({
                idUser: cls0[i].usuarioReportID
              })


            }

            if (testNew != undefined) {

              let dudasComprobadas = await comprobarRespuestas(testNew)
              this.setState({ dudaLoad: dudasComprobadas })
              //console.log(dudasComprobadas)
            } else {
              this.setState({ dudaLoad: null })
            }
          } else {
            this.setState({ dudaLoad: null })
          }
        } else if (notificacion[0].tipoDuda == 3) {

          //obtener duda y cargarlar
          let data0 = { idNotificacion: notificacion[0].idDuda }
          let dudasLoadArticulos = []
          let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/loadDudaArticulus", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data0)
          });
          if (!response0.ok) {
            throw Error(response0.statusText);
          }
          let json0 = await response0.json();
          let cls0 = await json0;

          if (cls0 != undefined && cls0.length > 0) {
            for (var i = 0; i < await cls0.length; i++) {
              let textAlumno = cls0[i].infoRoportUser

              textAlumno = await textAlumno.replace(/&nbsp;/g, '');
              textAlumno = await textAlumno.replace(/<p>/g, '');
              textAlumno = await textAlumno.replace(/<\/p>/g, '</br>');
              let resolucionPreparador = cls0[i].resolucion
              if (resolucionPreparador && resolucionPreparador != null && resolucionPreparador != '') {
                resolucionPreparador = await resolucionPreparador.replace(/&nbsp;/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<p>/g, '');
                resolucionPreparador = await resolucionPreparador.replace(/<\/p>/g, '</br>');
              }


              dudasLoadArticulos.push({
                idReport: cls0[i].idReport,
                idUser: cls0[i].usuarioReportID,
                infoRoportUser: textAlumno,
                fotoPerfil: cls0[i].fotoPerfil,
                resolucion: resolucionPreparador,
                estadoReport: cls0[i].estadoReport,
                fecha: cls0[i].fecha,
                articulo: cls0[i].articulo,
                idLey: cls0[i].idLey,
                siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls0[i].idLey).siglas_ley,
                nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls0[i].idLey).nombre_ley,
                alumnosDuda: []
              })
              //sacar todos los alumnos apuntados en duda
              let data2 = {
                idDuda: cls0[i].idReport
              }

              let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/alumnosApuntadosEnDudaArt", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;

              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda = await cls2
              dudasLoadArticulos[dudasLoadArticulos.length - 1].alumnosDuda.push({
                idUser: cls0[i].usuarioReportID
              })


            }

            if (dudasLoadArticulos != undefined) {
              await this.setState({ dudaLoad: dudasLoadArticulos })
            } else {
              this.setState({ dudaLoad: null })
            }
          } else {
            this.setState({ dudaLoad: null })
          }

        }

        if (this.state.cargarContenido != 'enviados') {
          this.setState({ cargarContenido: 'todo' })

          if (notificacion[0].visualizado == 0) {

            let data1 = { idNotificacion: notificacion[0].id }

            let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionLeida", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            let notificacionesNewS = this.state.notificacionesSave
            let notificacionesNew = this.state.notificaciones

            let loca = await notificacionesNew.findIndex(n => n.id == idNoti)
            notificacionesNew[loca].visualizado = 1
            let loca2 = await notificacionesNewS.findIndex(n => n.id == idNoti)
            notificacionesNewS[loca2].visualizado = 1

            this.setState({ notificaciones: notificacionesNew, notificacionesSave: notificacionesNewS })
            if (notificacionesNewS && notificacionesNewS == null || notificacionesNewS == undefined) {
              this.props.notificiacionesVistas(0)
            } else if (notificacionesNewS && notificacionesNewS != null && notificacionesNewS != undefined && notificacionesNewS.length > 0 && notificacionesNewS.filter(n => n.visualizado == 0).length == 0) {
              this.props.notificiacionesVistas(0)
            } else if (notificacionesNewS && notificacionesNewS != null && notificacionesNewS != undefined && notificacionesNew.length == 0) {
              this.props.notificiacionesVistas(0)
            } else {
              this.props.notificiacionesVistas(1)
            }

          }
        }

      } else {

      }


    } else if (e.target.id.split('-')[0] == 'descargar') {
      let idDescarga = e.target.id.split('-')[1]

      let noti = await this.state.notificaciones.filter(n2 => n2.id == this.state.notiLoadId)[0]
      let descargas = await noti.descargas


      if (descargas && descargas.length > 0 && descargas.length > idDescarga) {
        swal('Descarga en proceso', 'Si no se inicia la descarga en 30 segundos vuelva a intentarlo', 'info')
        let pathFile = descargas[idDescarga].link
        let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
        let nameFile = pathFile.split('/').pop().split('-,-')[0];
        if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
          nameFile = nameFile + '.pdf'
        }
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          responseType: 'blob'
        }).then(res => {
          if (res.status >= 400) { throw new Error("Bad res from server"); }
          return res.blob();
        }).then(blob => {
          saveAs(blob, nameFile);
          swal.close()
        }).catch(function (err) {
          console.log(err)
          swal('Link no valido para descarga', 'Algun error con este archivo', 'error')
        });
      }
    }


  }


  async componentDidMount() {

    try {

      let data1 = { idUser: this.props.idUser }
      let response0 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificaciones", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response0.ok) {
        throw Error(response0.statusText);
      }
      let json0 = await response0.json();
      let cls0 = await json0;
      // ver notifiaciones DUAS SI  prepa admin o oficina
      // if (this.props.tipoUser == 51 || this.props.tipoUser >= 98) {
      //   let data2 = { idUser: this.props.idUser }
      //   let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionesAllDudas", {
      //     method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      //   });
      //   if (!response2.ok) {
      //     throw Error(response2.statusText);
      //   }
      //   let json2 = await response2.json();
      //   let cls2 = await json2;

      //   let response222 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionesAllDudasExamenes", {
      //     method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      //   });
      //   if (!response222.ok) {
      //     throw Error(response222.statusText);
      //   }
      //   let json222 = await response222.json();
      //   let cls222 = await json222;

      //   for (let index111 = 0; index111 < cls222.length; index111++) {

      //     let data33 = { idExamenGestion: cls222[index111].idExamenGestion, idUser: cls222[index111].idUserEnvia }
      //     let response33 = await fetch("https://oposiciones-justicia.es/api/usuarios/examenNoResuelto", {
      //       method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
      //     });
      //     if (!response33.ok) {
      //       throw Error(response33.statusText);
      //     }
      //     let json33 = await response33.json();
      //     let cls33 = await json33;

      //     if (cls33.length > 0 && cls0.filter(n => n.idUserEnvia == cls222[index111].idUserEnvia && n.idExamenGestion == cls222[index111].idExamenGestion && n.tipoExamenGestion == 1 && n.puntuacion == null && n.anotaciones == null).length == 0) {
      //       cls222[index111].visualizado = 0
      //       await cls0.push(cls222[index111])
      //     }

      //   }

      //   for (let index1 = 0; index1 < cls2.length; index1++) {
      //     if (cls2[index1].idDuda != null && cls2[index1].idDuda != '' && cls2[index1].tipoDuda == 1) {
      //       // no esta resuelta añadir a cls0 si no la tiene
      //       let data3 = { idDuda: cls2[index1].idDuda }
      //       let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/dudaNoResueltaJus", {
      //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      //       });
      //       if (!response3.ok) {
      //         throw Error(response3.statusText);
      //       }
      //       let json3 = await response3.json();
      //       let cls3 = await json3;

      //       if (cls3.length > 0 && cls0.filter(n => n.idDuda == cls2[index1].idDuda && n.tipoDuda == 1 && n.id == cls2[index1].id).length == 0) {
      //         cls2[index1].visualizado = 0
      //         await cls0.push(cls2[index1])
      //       }
      //     } else if (cls2[index1].idDuda != null && cls2[index1].idDuda != '' && cls2[index1].tipoDuda == 2) {
      //       let data3 = { idDuda: cls2[index1].idDuda }
      //       let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/dudaNoResueltaCP", {
      //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      //       });
      //       if (!response3.ok) {
      //         throw Error(response3.statusText);
      //       }
      //       let json3 = await response3.json();
      //       let cls3 = await json3;
      //       if (cls3.length > 0 && cls0.filter(n => n.idDuda == cls2[index1].idDuda && n.tipoDuda == 2 && n.id == cls2[index1].id).length == 0) {
      //         cls2[index1].visualizado = 0
      //         await cls0.push(cls2[index1])
      //       }
      //     } else if (cls2[index1].idDuda != null && cls2[index1].idDuda != '' && cls2[index1].tipoDuda == 3) {
      //       let data3 = { idDuda: cls2[index1].idDuda }
      //       let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/dudaNoResueltaART", {
      //         method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
      //       });
      //       if (!response3.ok) {
      //         throw Error(response3.statusText);
      //       }
      //       let json3 = await response3.json();
      //       let cls3 = await json3;
      //       if (cls3.length > 0 && cls0.filter(n => n.idDuda == cls2[index1].idDuda && n.tipoDuda == 3 && n.id == cls2[index1].id).length == 0) {
      //         cls2[index1].visualizado = 0
      //         await cls0.push(cls2[index1])
      //       }
      //     }

      //   }
      // }

      if (cls0.length > 0) {
        let data11 = {}
        let response01 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionesDescargasN", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
        });
        if (!response01.ok) {
          throw Error(response01.statusText);
        }
        let json01 = await response01.json();
        let cls01 = await json01;


        for (let index = 0; index < cls0.length; index++) {

          let descargs = cls01.filter(p => p.id_report == cls0[index].id);
          if (descargs.length > 0) {
            cls0[index].descargas = descargs
          }
          // let data11 = { id: cls0[index].id }
          // let response01 = await fetch("https://oposiciones-justicia.es/api/usuarios/notificacionesDescargas", {
          //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data11)
          // });
          // if (!response01.ok) {
          //   throw Error(response01.statusText);
          // }
          // let json01 = await response01.json();
          // let cls01 = await json01;
          // if (cls01.length > 0) {
          //   cls0[index].descargas = cls01
          // }
        }



        let maxValue = Math.ceil(cls0.length / 6)
        let listadoNav = []
        for (let index23 = 0; index23 < maxValue; index23++) {
          listadoNav.push({
            num: index23 + 1
          })
        }
        this.setState({ listadoNav: listadoNav })
      } else {
        this.setState({ listadoNav: null })
      }

      await this.setState({ notificaciones: cls0.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)), notificacionesSave: cls0.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)) });

      if (cls0 && cls0 == null || cls0 == undefined) {
        this.props.notificiacionesVistas(0)
      } else if (cls0 && cls0 != null && cls0 != undefined && cls0.length > 0 && cls0.filter(n => n.visualizado == 0).length == 0) {
        this.props.notificiacionesVistas(0)
      } else if (cls0 && cls0 != null && cls0 != undefined && cls0.length == 0) {
        this.props.notificiacionesVistas(0)
      } else {
        this.props.notificiacionesVistas(1)
      }

      let response4 = await fetch("https://oposiciones-justicia.es/api/leyes/consulta");
      if (!response4.ok) {
        throw Error(response4.statusText);
      }
      let json4 = await response4.json();
      let cls4 = await json4;
      this.setState({ busquedaLeyes: cls4 });

      let response5 = await fetch("https://oposiciones-justicia.es/api/preparador/examGest");
      if (!response5.ok) {
        throw Error(response5.statusText);
      }
      let json5 = await response5.json();
      let cls5 = await json5;
      this.setState({ examenesGestionLoad: cls5 });


    } catch (e) { console.log(e) }
  }
  render() {

    const { isLogged, nombreUser, tipoUser } = this.props;
    const { notificaciones, notificacionesSave, numView, navIni, navFin, listadoNav, cargarContenido, notiLoadId, dudaLoad, textHtmlTitulo, textHtmlMensaje, tipoEnvioNotificacion, filesLoad, verBotonesSelectwhoSend, usuariosLoad, aQuienEnviamosNoti, clasesLoad, examenesGestionLoad, infoExamenGestionLoad } = this.state
    const nivelRequired = 1;
    if (isLogged && (nivelRequired >= tipoUser || tipoUser >= 1)) {

      return (
        <div className="bodyNotificaciones">

          <div className="bNe2-left">
            <div className="bNe2-l-topTitle" onClick={this.handleOnSubmit} id="todo">
              CEJ <b style={{ marginLeft: '15px' }} id="todo">Notificaciones</b>
              {cargarContenido && cargarContenido == 'todo' ? (
                <div className="bolitaPreciosaFullLove">
                </div>
              ) : null}
            </div>

            <div className="bNe2-l-topMenuNav">

              {this.props.tipoUser == 51 || this.props.tipoUser >= 98 ? (
                <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="enviarNotificacion" style={cargarContenido && cargarContenido == 'enviarNotificacion' ? ({ fontWeight: 'bold' }) : ({})}>
                  <i className="fas fa-plus" id="enviarNotificacion" />
                  Redactar
                </div>
              ) : null}

              {this.props.tipoUser == 51 || this.props.tipoUser >= 98 ? (
                <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="enviados" style={cargarContenido && cargarContenido == 'enviados' ? ({ fontWeight: 'bold' }) : ({})}>
                  <i className="fas fa-paper-plane" id="enviados" />
                  Enviados
                </div>
              ) : null}

              <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="dudas" style={cargarContenido && cargarContenido == 'dudas' ? ({ fontWeight: 'bold' }) : ({})}>
                <i className="fas fa-inbox" id="dudas" />
                Dudas
                {notificacionesSave && notificacionesSave.filter(n => (n.tipoDuda == 1 || n.tipoDuda == 2 || n.tipoDuda == 3) && n.visualizado == 0).length > 0 ? (
                  <div className="bNe2-l-tMN-row-ALERT" id="dudas">
                    {notificacionesSave.filter(n => (n.tipoDuda == 1 || n.tipoDuda == 2 || n.tipoDuda == 3) && n.visualizado == 0).length}
                  </div>
                ) : null}
              </div>
              <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="facturacion" style={cargarContenido && cargarContenido == 'facturacion' ? ({ fontWeight: 'bold' }) : ({})}>
                <i className="fas fa-euro-sign" id="facturacion" />
                Facturacion
                {notificacionesSave && notificacionesSave.filter(n => n.tipoFacturacion == 1 && n.visualizado == 0).length > 0 ? (
                  <div className="bNe2-l-tMN-row-ALERT" id="facturacion" >
                    {notificacionesSave.filter(n => n.tipoFacturacion == 1 && n.visualizado == 0).length}
                  </div>
                ) : null}
              </div>
              {notificacionesSave && notificacionesSave.filter(n => n.tipoExamenGestion == 1).length > 0 ? (
                <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="examen" style={cargarContenido && cargarContenido == 'examen' ? ({ fontWeight: 'bold' }) : ({})}>
                  <i className="fas fa-file-signature" id="examen" />
                  Examenes
                  {notificacionesSave && notificacionesSave.filter(n => n.tipoExamenGestion == 1 && n.visualizado == 0).length > 0 ? (
                    <div className="bNe2-l-tMN-row-ALERT" id="examen">
                      {notificacionesSave.filter(n => n.tipoExamenGestion == 1 && n.visualizado == 0).length}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="bNe2-l-tMN-row" onClick={this.handleOnSubmit} id="reformasLey" style={cargarContenido && cargarContenido == 'reformasLey' ? ({ fontWeight: 'bold' }) : ({})}>
                <i className="fas fa-business-time" id="reformasLey" />
                Reformas ley
                {notificacionesSave && notificacionesSave.filter(n => n.tipoReformasLey == 1 && n.visualizado == 0).length > 0 ? (
                  <div className="bNe2-l-tMN-row-ALERT" id="reformasLey">
                    {notificacionesSave.filter(n => n.tipoReformasLey == 1 && n.visualizado == 0).length}
                  </div>
                ) : null}
              </div>
            </div>

            {
              window.innerWidth > 1310 ? (

                notificaciones && notificaciones.length > 0 ? (
                  notificaciones.slice(6 * numView, numView == 0 ? (6) : (6 * (numView + 1))).map((noti, key) =>
                    <div className="bNe2-l-notificacionPrev"
                      onClick={this.handleOnSubmit} id={'notificacion-' + key}
                      style={
                        notiLoadId && notiLoadId != null && noti.id == notiLoadId ? (
                          noti.visualizado && noti.visualizado == 1 ? ({ boxShadow: '0 0 25px #3469b7' }) : ({ color: '#222222', boxShadow: '0 0 25px #3469b7' })
                        ) : (
                          noti.urgente == 1 ? (
                            noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#222222', boxShadow: '-2px 0px 0px 0px #ff7a7a' })
                          ) : (
                            noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#222222' })
                          )
                        )


                      }
                      key={key}>
                      <div className="bNe2-l-nP-topTitle" id={'notificacion-' + key}>
                        <i id={'notificacion-' + key} className={noti.visualizado && noti.visualizado == 1 ? ("fas fa-envelope-open-text") : ("fas fa-envelope")}
                          style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })}
                        /> {ReactHtmlParser(noti.titulo)}
                      </div>
                      <div className="bNe2-l-nP-botom" id={'notificacion-' + key}>
                        <div className="bNe2-l-nP-top-user" id={'notificacion-' + key}>
                          <i className="fas fa-user-tag" id={'notificacion-' + key} style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })} ></i>{noti.nombre + ', ' + noti.apellidos}
                        </div>
                        <div className="bNe2-l-nP-top-time" id={'notificacion-' + key}>
                          <i className="far fa-calendar-alt" id={'notificacion-' + key} style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })}></i> {moment(noti.fecha).format('DD-MM-YY HH:mm')}
                        </div>


                      </div>

                    </div>
                  )

                ) : (
                  <div className="cargandoDudasECG" style={{ alignSelf: 'center' }}>
                    <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO NOTIFICACIONES </div>
                    <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
                  </div>
                )

              ) : (

                notiLoadId && notiLoadId != null ? (

                  notificaciones && notificaciones.length > 0 &&
                  notificaciones.filter(n2 => n2.id == notiLoadId).length > 0 &&
                  notificaciones.filter(n2 => n2.id == notiLoadId).map((notiLoad, key34g) =>


                    <div className="bNe2-l-notificacionPrev2"
                      style={{ color: '#222222' }}
                      key={key34g}>

                      <div className="setingBarNoti">
                        <i className="fas fa-angle-left" title="Cerrar notificación" style={{ fontSize: '30px' }} id="cerrarNotiLoad" onClick={this.handleOnSubmit}></i>
                        <i className="far fa-trash-alt" title="Borrar notificación" id="borrarNotiLoad" onClick={this.handleOnSubmit}></i>
                      </div>

                      <div className="bNe2-l-nP-topTitle2" style={{ cursor: 'none' }}>
                        {ReactHtmlParser(notiLoad.titulo)}
                      </div>
                      <div className="bNe2-l-nP-botom" style={{ cursor: 'none' }}>
                        <div className="bNe2-l-nP-top-user" style={{ cursor: 'none' }}>
                          <i className="fas fa-user-tag" style={{ color: '#3469b7', cursor: 'none' }} ></i>{notiLoad.nombre + ', ' + notiLoad.apellidos}
                        </div>
                        <div className="bNe2-l-nP-top-time" style={{ cursor: 'none' }}>
                          <i className="far fa-calendar-alt" style={{ color: '#3469b7', cursor: 'none' }}></i> {moment(notiLoad.fecha).format('DD-MM-YY HH:mm')}
                        </div>



                      </div>

                      <div className="textoNotificacion-Bne2">

                        {ReactHtmlParser(notiLoad.notificacionTexto)}

                        {dudaLoad == null && notiLoad.tipoDuda == 1 ? (
                          <React.Fragment>
                            <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                          </React.Fragment>
                        ) : null}
                        {dudaLoad == null && notiLoad.tipoDuda == 2 ? (
                          <React.Fragment>
                            <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                          </React.Fragment>
                        ) : null}

                        {dudaLoad == null && notiLoad.tipoDuda == 3 ? (
                          <React.Fragment>
                            <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                          </React.Fragment>
                        ) : null}


                      </div>


                      {(tipoUser >= 58 || tipoUser >= 98) && examenesGestionLoad && examenesGestionLoad != null && notiLoad.idExamenGestion && notiLoad.idExamenGestion != null && infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 ?

                        (
                          infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].puntuacion != null ? (
                            <React.Fragment>
                              <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                                <div className="SSFC-B-TL-eL-testInfoResultado">
                                  <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Resultado <b> {infoExamenGestionLoad[0].puntuacion}</b></div>

                                </div>
                                <div className="SSFC-B-TL-eL-testInfoResultado">
                                  <div className="SSFC-B-TL-eL-boton2">
                                    <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                                  </div>
                                  <div className="SSFC-B-TL-eL-boton2">
                                    <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                  </div>
                                </div>

                              </div>
                              <div className="SSFC-B-TL-eL-Ranking">

                                {infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].anotaciones != null ? (
                                  <div className="SSFC-B-TL-eL-notaPrepa">
                                    {ReactHtmlParser(infoExamenGestionLoad[0].anotaciones)}
                                  </div>
                                ) : null}

                                <img src="../img/examDesarrolloCompleted.jpg" />
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                                <div className="SSFC-B-TL-eL-testInfoResultado">
                                  <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Pediente de Corrección </div>
                                </div>
                                <div className="SSFC-B-TL-eL-testInfoResultado">
                                  <div className="SSFC-B-TL-eL-boton2">
                                    <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Examen alumno" onClick={this.handleOnSubmit} />
                                  </div>
                                  <div className="SSFC-B-TL-eL-boton2">
                                    <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                  </div>
                                </div>

                              </div>
                              <div className="SSFC-B-TL-eL-Ranking">

                                <div className="SSFC-B-TL-eL-notaPrepa">
                                  Puede descargar la solución, pronto corregiremos su examen.
                                </div>

                                <img src="../img/examDesarrolloCompleted.jpg" />
                              </div>

                              <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={textHtmlTitulo}
                                  onInit={editor => { }}
                                  config={{
                                    toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                                  }}
                                  onChange={this.onChangeText3}
                                />
                              </div>

                              <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>
                                <div id={'puntuacionExamenGestion'} className="celdaInputDAPFBN" style={{ width: 'unset', maxWidth: '200px' }}>

                                  <Input2 autoComplete={"off"} type="number" min={"0.00"} max={10000.00} step={0.01}
                                    id={'puntuacionExamenGestion'}
                                    name={'puntuacionExamenGestion'}
                                    placeholder={'Puntuacion'}
                                    icon="fas fa-flag"
                                    ref={'ref_puntuacionExamenGestion'}
                                    onChange={this.handleOnSubmit}

                                  />

                                  <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>
                                    Puntuación
                                  </p>
                                </div>
                                <div className="DMB-navDudas-boton">
                                  <Boton id="corregirExamenGestion" icon1="" icon2="" texto="Solucionar" onClick={this.handleOnSubmit} />
                                </div>

                              </div>

                            </React.Fragment>
                          )
                        ) : (

                          infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 ? (


                            infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].puntuacion != null ? (
                              <React.Fragment>
                                <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                                  <div className="SSFC-B-TL-eL-testInfoResultado">
                                    <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Resultado <b> {infoExamenGestionLoad[0].puntuacion}</b></div>

                                  </div>
                                  <div className="SSFC-B-TL-eL-testInfoResultado">
                                    <div className="SSFC-B-TL-eL-boton2">
                                      <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                                    </div>
                                    <div className="SSFC-B-TL-eL-boton2">
                                      <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                    </div>
                                  </div>

                                </div>
                                <div className="SSFC-B-TL-eL-Ranking">

                                  {infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].anotacion != null ? (
                                    <div className="SSFC-B-TL-eL-notaPrepa">
                                      {ReactHtmlParser(infoExamenGestionLoad[0].anotacion)}
                                    </div>
                                  ) : null}

                                  <img src="../img/examDesarrolloCompleted.jpg" />
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                                  <div className="SSFC-B-TL-eL-testInfoResultado">
                                    <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Pediente de Corrección </div>
                                  </div>
                                  <div className="SSFC-B-TL-eL-testInfoResultado">
                                    <div className="SSFC-B-TL-eL-boton2">
                                      <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                                    </div>
                                    <div className="SSFC-B-TL-eL-boton2">
                                      <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                    </div>
                                  </div>

                                </div>
                                <div className="SSFC-B-TL-eL-Ranking">


                                  <div className="SSFC-B-TL-eL-notaPrepa">
                                    Puede descargar la solución, pronto corregiremos su examen.
                                  </div>

                                  <img src="../img/examDesarrolloCompleted.jpg" />
                                </div>
                              </React.Fragment>
                            )
                          ) : null


                        )}
                      {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 1 ? (
                        <Dudas dudas={dudaLoad} tipoDuda="testTeoria" verPregunta={true} notificacion={true} />
                      ) : null}
                      {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 2 ? (
                        <Dudas dudas={dudaLoad} tipoDuda="testPractico" verPregunta={true} notificacion={true} />
                      ) : null}

                      {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 3 ? (
                        <Dudas dudas={dudaLoad} tipoDuda="Articulo" notificacion={true} />
                      ) : null}



                      {notiLoad.descargas && notiLoad.descargas != undefined && notiLoad.descargas.length > 0 == 1 ? (
                        <React.Fragment>
                          <div className="tituloDato">
                            <i className="fas fa-cloud-download-alt"></i>
                            Descargas
                          </div>
                          <div className="navBotones-enviarMensaje" style={{ flexFlow: 'column wrap', alignItems: 'flex-start', alignContent: 'flex-start' }}>

                            {
                              notiLoad.descargas && notiLoad.descargas.length > 0 && notiLoad.descargas.map((archivo, key51) =>
                                <div className="fileLoadToNotiSend" id={'descargar-' + key51} onClick={this.handleOnSubmit} style={{ cursor: 'pointer' }}>
                                  {archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'pdf' ? (
                                    <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-pdf"></i>
                                  ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'doc' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'docx' ? (
                                    <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-word"></i>
                                  ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'jpg' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'png' ? (
                                    <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-image"></i>
                                  ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'pptx' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'ppt' ? (
                                    <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-powerpoint"></i>
                                  ) : (
                                    <i style={{ color: '#e66161' }} className="fas fa-exclamation-triangle"></i>
                                  ))))}

                                  {archivo.nombre}
                                </div>

                              )
                            }
                          </div>
                        </React.Fragment>

                      ) : null}



                    </div>




                  )


                ) : (
                  cargarContenido && cargarContenido == "enviarNotificacion" ? (

                    <div className="bNe2-l-notificacionPrev2" style={{ color: '#222222' }}>

                      <div className="setingBarNoti">
                        <i className="fas fa-angle-left" title="Cerrar" style={{ fontSize: '30px' }} id="cerrarCrearNoti" onClick={this.handleOnSubmit}></i>

                      </div>
                      <div className="tituloDato" style={{ borderTop: '0px', padding: '0px', marginTop: '0px' }}>
                        <i className="fas fa-cogs"></i> Tipo de notificación
                      </div>
                      <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>

                        <div className="DMB-navDudas-boton">
                          {tipoEnvioNotificacion && tipoEnvioNotificacion == 'tipoUrgente' ? (
                            <Boton id="tipoUrgente" icon1="" icon2="" texto="Urgente" onClick={this.handleRedactar} />
                          ) : (
                            <Boton2 id="tipoUrgente" icon1="" icon2="" texto="Urgente" onClick={this.handleRedactar} />
                          )}
                        </div>

                        <div className="DMB-navDudas-boton">
                          {tipoEnvioNotificacion && tipoEnvioNotificacion == 'tipoReformaLey' ? (
                            <Boton id="tipoReformaLey" icon1="" icon2="" texto="Reforma ley" onClick={this.handleRedactar} />
                          ) : (
                            <Boton2 id="tipoReformaLey" icon1="" icon2="" texto="Reforma ley" onClick={this.handleRedactar} />
                          )}

                        </div>
                      </div>

                      <div className="tituloDato">
                        <i className="fas fa-users"></i> ¿Para quién es la notificación?
                        {verBotonesSelectwhoSend && verBotonesSelectwhoSend == true ? null : (
                          <React.Fragment>
                            <i id="addOtroAenviar" className="fas fa-plus" onClick={this.handleRedactar} style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                          </React.Fragment>

                        )}
                      </div>

                      {verBotonesSelectwhoSend && verBotonesSelectwhoSend == true ? (
                        <div className="navBotones-enviarMensaje">

                          <div className="DMB-navDudas-boton">
                            <Boton id="addUsuario" icon1="" icon2="" texto="Usuario" onClick={this.handleRedactar} />
                          </div>

                          <div className="DMB-navDudas-boton">
                            <Boton id="addCurso" icon1="" icon2="" texto="Curso" onClick={this.handleRedactar} />
                          </div>

                          <div className="DMB-navDudas-boton">
                            <Boton id="addTodosCursos" icon1="" icon2="" texto="Todos cursos" onClick={this.handleRedactar} />
                          </div>

                          <div className="DMB-navDudas-boton">
                            <Boton id="addTodosUsuario" icon1="" icon2="" texto="Todos alumnos" onClick={this.handleRedactar} />
                          </div>

                          <div className="DMB-navDudas-boton">
                            <Boton id="addTodosPreparadores" icon1="" icon2="" texto="Todos preparadores" onClick={this.handleRedactar} />
                          </div>

                        </div>
                      ) : (
                        <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>
                          {
                            aQuienEnviamosNoti && aQuienEnviamosNoti != null && aQuienEnviamosNoti.length > 0 && aQuienEnviamosNoti.map((cosa, key88) =>
                              <div id={'aQuienEnviamos-' + key88} className="celdaInputDAPFBN">
                                {cosa && cosa.tipo == 'usuario' ? (
                                  <React.Fragment >
                                    <Input2 autoComplete={"off"} type="text"
                                      datalist="usuariosLoad"
                                      id={'aQuienEnviamos-' + key88}
                                      name={'aQuienEnviamos-' + key88}
                                      placeholder={cosa && cosa.enviamosA.length > 0 ? (cosa.enviamosA[0].id_usuario) : ("ID Usuario")}
                                      icon="fas fa-at"
                                      ref={'ref_aQuienEnviamos-' + key88}
                                      onChange={this.handleRedactar}

                                    />
                                    <datalist id="usuariosLoad">{
                                      usuariosLoad && usuariosLoad.map((cls, key202) =>
                                        <option key={key202} value={cls.id_usuario}>{cls.nombre + ', ' + cls.apellidos + ' - ' + cls.dni}</option>
                                      )
                                    }</datalist>
                                    <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>{
                                      cosa && cosa.enviamosA.length > 0 ? (
                                        <React.Fragment >
                                          {cosa.enviamosA[0].nombre + ', ' + cosa.enviamosA[0].apellidos}
                                        </React.Fragment >
                                      ) : ('Usuario')
                                    }</p>

                                  </React.Fragment >
                                ) : null}


                                {cosa && cosa.tipo == 'curso' ? (
                                  <React.Fragment >
                                    <Input2 autoComplete={"off"} type="text"
                                      datalist="clasessLoad"
                                      id={'aQClaseEnviamos-' + key88}
                                      name={'aQClaseEnviamos-' + key88}
                                      placeholder={cosa && cosa.enviamosA.length > 0 ? (cosa.enviamosA[0].id_clase) : ("ID Curso")}
                                      icon="fas fa-chalkboard-teacher"
                                      ref={'ref_aQClaseEnviamos-' + key88}
                                      onChange={this.handleRedactar}

                                    />
                                    <datalist id="clasessLoad">{
                                      clasesLoad && clasesLoad.map((cls, key200) =>
                                        <option key={key200} value={cls.id_clase}>{cls.dia_semanal}</option>
                                      )
                                    }</datalist>
                                    <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>{
                                      cosa && cosa.enviamosA.length > 0 ? (
                                        <React.Fragment >
                                          {cosa.enviamosA[0].dia_semanal}
                                        </React.Fragment >
                                      ) : ('Curso')
                                    }</p>

                                  </React.Fragment >
                                ) : null}

                                {cosa && cosa.tipo == 'todosCursos' ? (
                                  <React.Fragment >
                                    <div className="DMB-navDudas-boton">
                                      <Boton id="" icon1="" icon2="" texto="Todos cursos" onClick={this.handleRedactar} />
                                    </div>
                                  </React.Fragment >
                                ) : null}

                                {cosa && cosa.tipo == 'todosUsuarios' ? (
                                  <React.Fragment >
                                    <div className="DMB-navDudas-boton">
                                      <Boton id="" icon1="" icon2="" texto="Todos alumnos" onClick={this.handleRedactar} />
                                    </div>
                                  </React.Fragment >
                                ) : null}

                                {cosa && cosa.tipo == 'todosPreparadores' ? (
                                  <React.Fragment >

                                    <div className="DMB-navDudas-boton">
                                      <Boton id="" icon1="" icon2="" texto="Todos preparadores" onClick={this.handleRedactar} />
                                    </div>

                                  </React.Fragment >
                                ) : null}

                                <i onClick={this.handleRedactar} id={'delPreEnv-' + key88} style={{ top: '-8px', position: 'absolute', right: '2px', cursor: 'pointer', color: '#3469b7', fontSize: '20px' }} class="fas fa-times" title="Eliminar"></i>
                              </div>
                            )
                          }
                        </div>
                      )}


                      <div className="tituloDato">
                        <i className="fas fa-tag" style={{ transform: 'rotate(135deg)' }}></i> Titulo
                      </div>
                      <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={textHtmlTitulo}
                          onInit={editor => { }}
                          config={{
                            toolbar: ['undo', 'redo']
                          }}
                          onChange={this.onChangeText2}
                        />
                      </div>

                      <div className="tituloDato">
                        <i className="fas fa-envelope-open-text"></i>
                        Mensaje

                      </div>
                      <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={textHtmlMensaje}
                          onInit={editor => { }}
                          config={{
                            toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                          }}
                          onChange={this.onChangeText}
                        />
                      </div>

                      <div className="tituloDato">

                      </div>
                      <div className="navBotones-enviarMensaje">

                        <div className="DMB-navDudas-boton">
                          <InputFileExamDesa onChange={this.myFiles} id="addFile" name="addFile" texto="Añadir archivos" />
                        </div>

                        <div className="DMB-navDudas-boton">
                          <Boton id="sendNoti" icon1="" icon2="" texto="Enviar" onClick={this.handleRedactar} />
                        </div>
                      </div>

                      {filesLoad && filesLoad != null & filesLoad.length > 0 ? (
                        <div className="navBotones-enviarMensaje" style={{ flexFlow: 'column wrap', alignItems: 'flex-start', alignContent: 'flex-start' }}>

                          {
                            filesLoad && filesLoad.length > 0 && filesLoad.map((archivo, key5) =>
                              <div className="fileLoadToNotiSend">
                                {archivo.name.split('.')[1] == 'pdf' ? (
                                  <i className="fas fa-file-pdf"></i>
                                ) : (archivo.name.split('.')[1] == 'doc' || archivo.name.split('.')[1] == 'docx' ? (
                                  <i className="fas fa-file-word"></i>
                                ) : (archivo.name.split('.')[1] == 'jpg' || archivo.name.split('.')[1] == 'png' ? (
                                  <i className="fas fa-file-image"></i>
                                ) : (archivo.name.split('.')[1] == 'pptx' || archivo.name.split('.')[1] == 'ppt' ? (
                                  <i className="fas fa-file-powerpoint"></i>
                                ) : (
                                  <i style={{ color: '#e66161' }} className="fas fa-exclamation-triangle"></i>
                                ))))}


                                {archivo.name.split('.')[0]}
                              </div>
                            )
                          }
                        </div>
                      ) : null}

                    </div>



                  ) : (
                    notificaciones && notificaciones.length > 0 ? (
                      notificaciones.slice(6 * numView, numView == 0 ? (6) : (6 * (numView + 1))).map((noti, key) =>
                        <div className="bNe2-l-notificacionPrev"
                          onClick={this.handleOnSubmit} id={'notificacion-' + key}
                          style={
                            notiLoadId && notiLoadId != null && noti.id == notiLoadId ? (
                              noti.visualizado && noti.visualizado == 1 ? ({ boxShadow: '0 0 25px #3469b7' }) : ({ color: '#222222', boxShadow: '0 0 25px #3469b7' })
                            ) : (
                              noti.urgente == 1 ? (
                                noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#222222', boxShadow: '-2px 0px 0px 0px #ff7a7a' })
                              ) : (
                                noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#222222' })
                              )

                            )


                          }
                          key={key}>
                          <div className="bNe2-l-nP-topTitle" id={'notificacion-' + key}>
                            <i id={'notificacion-' + key} className={noti.visualizado && noti.visualizado == 1 ? ("fas fa-envelope-open-text") : ("fas fa-envelope")}
                              style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })}
                            /> {ReactHtmlParser(noti.titulo)}
                          </div>
                          <div className="bNe2-l-nP-botom" id={'notificacion-' + key}>
                            <div className="bNe2-l-nP-top-user" id={'notificacion-' + key}>
                              <i className="fas fa-user-tag" id={'notificacion-' + key} style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })} ></i>{noti.nombre + ', ' + noti.apellidos}
                            </div>
                            <div className="bNe2-l-nP-top-time" id={'notificacion-' + key}>
                              <i className="far fa-calendar-alt" id={'notificacion-' + key} style={noti.visualizado && noti.visualizado == 1 ? ({}) : ({ color: '#3469b7' })}></i> {moment(noti.fecha).format('DD-MM-YY HH:mm')}
                            </div>


                          </div>

                        </div>
                      )

                    ) : (
                      <div className="cargandoDudasECG" style={{ alignSelf: 'center' }}>
                        <div style={{ color: '#3469b7', fontWeight: 'bold' }}> CARGANDO NOTIFICACIONES </div>
                        <Ellipsis color="#3469b7" size={72} style={{ margin: '0px 150px 0px 150px' }} />
                      </div>
                    )
                  )
                )
              )
            }

            {notificaciones && notificaciones.length > 0 && listadoNav && listadoNav.length > 0 ? (
              <div className="BE-CL-C-nav" style={{ margin: '10px 0px 10px 10px', alignSelf: 'flex-end', maxWidth: '700px' }}>
                <i id={"atras"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-left" />

                {listadoNav.slice(navIni, navFin).map((data, key345) =>

                  <div className="navClic" id={"clicNavDirect-" + key345} onClick={this.handleOnSubmit} key={key345}
                    style={(numView + 1) == data.num ? ({ color: '#3469b7', fontWeight: 'bold' }) : ({})}>
                    {data.num}
                  </div>

                )}

                <i id={"adelante"} onClick={this.handleOnSubmit} className="far fa-arrow-alt-circle-right" />
              </div>
            ) : null
            }

          </div>

          <div className="bNe2-right" style={(notiLoadId && notiLoadId != null || cargarContenido && cargarContenido == "enviarNotificacion") && window.innerWidth > 1310 ? ({ background: '#fcfcfc' }) : ({})}>

            {notiLoadId && notiLoadId != null && window.innerWidth > 1310 ? (
              notificaciones && notificaciones.length > 0 &&
              notificaciones.filter(n2 => n2.id == notiLoadId).length > 0 &&
              notificaciones.filter(n2 => n2.id == notiLoadId).map((notiLoad, key2) =>
                <div className="bNe2-l-notificacionPrev2"
                  style={{ color: '#222222' }}
                  key={key2}>

                  <div className="setingBarNoti">
                    <i className="fas fa-angle-left" title="Cerrar notificación" style={{ fontSize: '30px' }} id="cerrarNotiLoad" onClick={this.handleOnSubmit}></i>
                    <i className="far fa-trash-alt" title="Borrar notificación" id="borrarNotiLoad" onClick={this.handleOnSubmit}></i>
                  </div>

                  <div className="bNe2-l-nP-topTitle2" style={{ cursor: 'none' }}>
                    {ReactHtmlParser(notiLoad.titulo)}
                  </div>
                  <div className="bNe2-l-nP-botom" style={{ cursor: 'none' }}>
                    <div className="bNe2-l-nP-top-user" style={{ cursor: 'none' }}>
                      <i className="fas fa-user-tag" style={{ color: '#3469b7', cursor: 'none' }} ></i>{notiLoad.nombre + ', ' + notiLoad.apellidos}
                    </div>
                    <div className="bNe2-l-nP-top-time" style={{ cursor: 'none' }}>
                      <i className="far fa-calendar-alt" style={{ color: '#3469b7', cursor: 'none' }}></i> {moment(notiLoad.fecha).format('DD-MM-YY HH:mm')}
                    </div>



                  </div>

                  <div className="textoNotificacion-Bne2">

                    {ReactHtmlParser(notiLoad.notificacionTexto)}

                    {dudaLoad == null && notiLoad.tipoDuda == 1 ? (
                      <React.Fragment>
                        <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                      </React.Fragment>
                    ) : null}
                    {dudaLoad == null && notiLoad.tipoDuda == 2 ? (
                      <React.Fragment>
                        <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                      </React.Fragment>
                    ) : null}

                    {dudaLoad == null && notiLoad.tipoDuda == 3 ? (
                      <React.Fragment>
                        <br /><br />Si la duda no aparece en unos segundos, un preparador a eliminado esta duda.
                      </React.Fragment>
                    ) : null}


                  </div>


                  {(tipoUser >= 58 || tipoUser >= 98) && examenesGestionLoad && examenesGestionLoad != null && notiLoad.idExamenGestion && notiLoad.idExamenGestion != null && infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 ?

                    (
                      infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].puntuacion != null ? (
                        <React.Fragment>
                          <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                            <div className="SSFC-B-TL-eL-testInfoResultado">
                              <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Resultado <b> {infoExamenGestionLoad[0].puntuacion}</b></div>

                            </div>
                            <div className="SSFC-B-TL-eL-testInfoResultado">
                              <div className="SSFC-B-TL-eL-boton2">
                                <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                              </div>
                              <div className="SSFC-B-TL-eL-boton2">
                                <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                              </div>
                            </div>

                          </div>
                          <div className="SSFC-B-TL-eL-Ranking">

                            {infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].puntuacion != null ? (
                              <div className="SSFC-B-TL-eL-notaPrepa">
                                {infoExamenGestionLoad[0].puntuacion}
                              </div>
                            ) : null}

                            <img src="../img/examDesarrolloCompleted.jpg" />
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                            <div className="SSFC-B-TL-eL-testInfoResultado">
                              <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Pediente de Corrección </div>
                            </div>
                            <div className="SSFC-B-TL-eL-testInfoResultado">
                              <div className="SSFC-B-TL-eL-boton2">
                                <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Examen alumno" onClick={this.handleOnSubmit} />
                              </div>
                              <div className="SSFC-B-TL-eL-boton2">
                                <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                              </div>
                            </div>

                          </div>
                          <div className="SSFC-B-TL-eL-Ranking">

                            <div className="SSFC-B-TL-eL-notaPrepa">
                              Puede descargar la solución, pronto corregiremos su examen.
                            </div>

                            <img src="../img/examDesarrolloCompleted.jpg" />
                          </div>

                          <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={textHtmlTitulo}
                              onInit={editor => { }}
                              config={{
                                toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                              }}
                              onChange={this.onChangeText3}
                            />
                          </div>

                          <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>
                            <div id={'puntuacionExamenGestion'} className="celdaInputDAPFBN" style={{ width: 'unset', maxWidth: '200px' }}>

                              <Input2 autoComplete={"off"} type="number" min={"0.00"} max={10000.00} step={0.01}
                                id={'puntuacionExamenGestion'}
                                name={'puntuacionExamenGestion'}
                                placeholder={'Puntuacion'}
                                icon="fas fa-flag"
                                ref={'ref_puntuacionExamenGestion'}
                                onChange={this.handleOnSubmit}

                              />

                              <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>
                                Puntuación
                              </p>
                            </div>
                            <div className="DMB-navDudas-boton">
                              <Boton id="corregirExamenGestion" icon1="" icon2="" texto="Solucionar" onClick={this.handleOnSubmit} />
                            </div>

                          </div>

                        </React.Fragment>
                      )
                    ) : (

                      infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 ? (


                        infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].puntuacion != null ? (
                          <React.Fragment>
                            <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                              <div className="SSFC-B-TL-eL-testInfoResultado">
                                <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Resultado <b> {infoExamenGestionLoad[0].puntuacion}</b></div>

                              </div>
                              <div className="SSFC-B-TL-eL-testInfoResultado">
                                <div className="SSFC-B-TL-eL-boton2">
                                  <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                                </div>
                                <div className="SSFC-B-TL-eL-boton2">
                                  <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                </div>
                              </div>

                            </div>
                            <div className="SSFC-B-TL-eL-Ranking">

                              {infoExamenGestionLoad != null && infoExamenGestionLoad.length > 0 && infoExamenGestionLoad[0].anotacion != null ? (
                                <div className="SSFC-B-TL-eL-notaPrepa">
                                  {infoExamenGestionLoad[0].anotacion}
                                </div>
                              ) : null}

                              <img src="../img/examDesarrolloCompleted.jpg" />
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="SSFC-B-TL-eL-testInfo" style={{ marginTop: '20px' }}>
                              <div className="SSFC-B-TL-eL-testInfoResultado">
                                <div className="SSFC-B-TL-eL-title" style={{ marginRight: '0px' }}> Pediente de Corrección </div>
                              </div>
                              <div className="SSFC-B-TL-eL-testInfoResultado">
                                <div className="SSFC-B-TL-eL-boton2">
                                  <Boton id="descargarPdfExaGesAlumn" icon1="" icon2="" texto="Mi examen" onClick={this.handleOnSubmit} />
                                </div>
                                <div className="SSFC-B-TL-eL-boton2">
                                  <Boton id="descargarPdfExaGesSolucion" icon1="" icon2="" texto="Solución" onClick={this.handleOnSubmit} />
                                </div>
                              </div>

                            </div>
                            <div className="SSFC-B-TL-eL-Ranking">


                              <div className="SSFC-B-TL-eL-notaPrepa">
                                Puede descargar la solución, pronto corregiremos su examen.
                              </div>

                              <img src="../img/examDesarrolloCompleted.jpg" />
                            </div>
                          </React.Fragment>
                        )
                      ) : null


                    )}
                  {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 1 ? (
                    <Dudas dudas={dudaLoad} tipoDuda="testTeoria" verPregunta={true} notificacion={true} />
                  ) : null}
                  {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 2 ? (
                    <Dudas dudas={dudaLoad} tipoDuda="testPractico" verPregunta={true} notificacion={true} />
                  ) : null}

                  {dudaLoad && dudaLoad != null && dudaLoad.length > 0 && notiLoad.tipoDuda == 3 ? (
                    <Dudas dudas={dudaLoad} tipoDuda="Articulo" notificacion={true} />
                  ) : null}



                  {notiLoad.descargas && notiLoad.descargas != undefined && notiLoad.descargas.length > 0 == 1 ? (
                    <React.Fragment>
                      <div className="tituloDato">
                        <i className="fas fa-cloud-download-alt"></i>
                        Descargas
                      </div>
                      <div className="navBotones-enviarMensaje" style={{ flexFlow: 'column wrap', alignItems: 'flex-start', alignContent: 'flex-start' }}>

                        {
                          notiLoad.descargas && notiLoad.descargas.length > 0 && notiLoad.descargas.map((archivo, key51) =>
                            <div className="fileLoadToNotiSend" id={'descargar-' + key51} onClick={this.handleOnSubmit} style={{ cursor: 'pointer' }}>
                              {archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'pdf' ? (
                                <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-pdf"></i>
                              ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'doc' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'docx' ? (
                                <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-word"></i>
                              ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'jpg' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'png' ? (
                                <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-image"></i>
                              ) : (archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'pptx' || archivo.link.split('.')[parseInt(archivo.link.split('.').length - 1)] == 'ppt' ? (
                                <i style={{ cursor: 'pointer' }} id={'descargar-' + key51} className="fas fa-file-powerpoint"></i>
                              ) : (
                                <i style={{ color: '#e66161' }} className="fas fa-exclamation-triangle"></i>
                              ))))}

                              {archivo.nombre}
                            </div>

                          )
                        }
                      </div>
                    </React.Fragment>

                  ) : null}



                </div>
              )


            ) : null}

            {cargarContenido && cargarContenido == "enviarNotificacion" && window.innerWidth > 1310 ? (

              <div className="bNe2-l-notificacionPrev2" style={{ color: '#222222' }}>

                <div className="setingBarNoti">
                  <i className="fas fa-angle-left" title="Cerrar" style={{ fontSize: '30px' }} id="cerrarCrearNoti" onClick={this.handleOnSubmit}></i>

                </div>
                <div className="tituloDato" style={{ borderTop: '0px', padding: '0px', marginTop: '0px' }}>
                  <i className="fas fa-cogs"></i> Tipo de notificación
                </div>
                <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>

                  <div className="DMB-navDudas-boton">
                    {tipoEnvioNotificacion && tipoEnvioNotificacion == 'tipoUrgente' ? (
                      <Boton id="tipoUrgente" icon1="" icon2="" texto="Urgente" onClick={this.handleRedactar} />
                    ) : (
                      <Boton2 id="tipoUrgente" icon1="" icon2="" texto="Urgente" onClick={this.handleRedactar} />
                    )}
                  </div>

                  <div className="DMB-navDudas-boton">
                    {tipoEnvioNotificacion && tipoEnvioNotificacion == 'tipoReformaLey' ? (
                      <Boton id="tipoReformaLey" icon1="" icon2="" texto="Reforma ley" onClick={this.handleRedactar} />
                    ) : (
                      <Boton2 id="tipoReformaLey" icon1="" icon2="" texto="Reforma ley" onClick={this.handleRedactar} />
                    )}

                  </div>
                </div>

                <div className="tituloDato">
                  <i className="fas fa-users"></i> ¿Para quién es la notificación?
                  {verBotonesSelectwhoSend && verBotonesSelectwhoSend == true ? null : (
                    <React.Fragment>
                      <i id="addOtroAenviar" className="fas fa-plus" onClick={this.handleRedactar} style={{ marginLeft: '10px', cursor: 'pointer' }}></i>
                    </React.Fragment>

                  )}
                </div>

                {verBotonesSelectwhoSend && verBotonesSelectwhoSend == true ? (
                  <div className="navBotones-enviarMensaje">

                    <div className="DMB-navDudas-boton">
                      <Boton id="addUsuario" icon1="" icon2="" texto="Usuario" onClick={this.handleRedactar} />
                    </div>

                    <div className="DMB-navDudas-boton">
                      <Boton id="addCurso" icon1="" icon2="" texto="Curso" onClick={this.handleRedactar} />
                    </div>

                    <div className="DMB-navDudas-boton">
                      <Boton id="addTodosCursos" icon1="" icon2="" texto="Todos cursos" onClick={this.handleRedactar} />
                    </div>

                    <div className="DMB-navDudas-boton">
                      <Boton id="addTodosUsuario" icon1="" icon2="" texto="Todos alumnos" onClick={this.handleRedactar} />
                    </div>

                    <div className="DMB-navDudas-boton">
                      <Boton id="addTodosPreparadores" icon1="" icon2="" texto="Todos preparadores" onClick={this.handleRedactar} />
                    </div>

                  </div>
                ) : (
                  <div className="navBotones-enviarMensaje" style={{ justifyContent: 'flex-start' }}>
                    {
                      aQuienEnviamosNoti && aQuienEnviamosNoti != null && aQuienEnviamosNoti.length > 0 && aQuienEnviamosNoti.map((cosa, key8) =>
                        <div id={'aQuienEnviamos-' + key8} className="celdaInputDAPFBN">
                          {cosa && cosa.tipo == 'usuario' ? (
                            <React.Fragment >
                              <Input2 autoComplete={"off"} type="text"
                                datalist="usuariosLoad"
                                id={'aQuienEnviamos-' + key8}
                                name={'aQuienEnviamos-' + key8}
                                placeholder={cosa && cosa.enviamosA.length > 0 ? (cosa.enviamosA[0].id_usuario) : ("ID Usuario")}
                                icon="fas fa-at"
                                ref={'ref_aQuienEnviamos-' + key8}
                                onChange={this.handleRedactar}

                              />
                              <datalist id="usuariosLoad">{
                                usuariosLoad && usuariosLoad.map((cls, key20) =>
                                  <option key={key20} value={cls.id_usuario}>{cls.nombre + ', ' + cls.apellidos + ' - ' + cls.dni}</option>
                                )
                              }</datalist>
                              <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>{
                                cosa && cosa.enviamosA.length > 0 ? (
                                  <React.Fragment >
                                    {cosa.enviamosA[0].nombre + ', ' + cosa.enviamosA[0].apellidos}
                                  </React.Fragment >
                                ) : ('Usuario')
                              }</p>

                            </React.Fragment >
                          ) : null}


                          {cosa && cosa.tipo == 'curso' ? (
                            <React.Fragment >
                              <Input2 autoComplete={"off"} type="text"
                                datalist="clasessLoad"
                                id={'aQClaseEnviamos-' + key8}
                                name={'aQClaseEnviamos-' + key8}
                                placeholder={cosa && cosa.enviamosA.length > 0 ? (cosa.enviamosA[0].id_clase) : ("ID Curso")}
                                icon="fas fa-chalkboard-teacher"
                                ref={'ref_aQClaseEnviamos-' + key8}
                                onChange={this.handleRedactar}

                              />
                              <datalist id="clasessLoad">{
                                clasesLoad && clasesLoad.map((cls, key20) =>
                                  <option key={key20} value={cls.id_clase}>{cls.dia_semanal}</option>
                                )
                              }</datalist>
                              <p style={{ overflow: 'hidden', whiteSpace: 'nowrap', left: '10px', maxWidth: '170px' }}>{
                                cosa && cosa.enviamosA.length > 0 ? (
                                  <React.Fragment >
                                    {cosa.enviamosA[0].dia_semanal}
                                  </React.Fragment >
                                ) : ('Curso')
                              }</p>

                            </React.Fragment >
                          ) : null}

                          {cosa && cosa.tipo == 'todosCursos' ? (
                            <React.Fragment >
                              <div className="DMB-navDudas-boton">
                                <Boton id="" icon1="" icon2="" texto="Todos cursos" onClick={this.handleRedactar} />
                              </div>
                            </React.Fragment >
                          ) : null}

                          {cosa && cosa.tipo == 'todosUsuarios' ? (
                            <React.Fragment >
                              <div className="DMB-navDudas-boton">
                                <Boton id="" icon1="" icon2="" texto="Todos alumnos" onClick={this.handleRedactar} />
                              </div>
                            </React.Fragment >
                          ) : null}

                          {cosa && cosa.tipo == 'todosPreparadores' ? (
                            <React.Fragment >

                              <div className="DMB-navDudas-boton">
                                <Boton id="" icon1="" icon2="" texto="Todos preparadores" onClick={this.handleRedactar} />
                              </div>

                            </React.Fragment >
                          ) : null}

                          <i onClick={this.handleRedactar} id={'delPreEnv-' + key8} style={{ top: '-8px', position: 'absolute', right: '2px', cursor: 'pointer', color: '#3469b7', fontSize: '20px' }} class="fas fa-times" title="Eliminar"></i>
                        </div>
                      )
                    }
                  </div>
                )}


                <div className="tituloDato">
                  <i className="fas fa-tag" style={{ transform: 'rotate(135deg)' }}></i> Titulo
                </div>
                <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={textHtmlTitulo}
                    onInit={editor => { }}
                    config={{
                      toolbar: ['undo', 'redo']
                    }}
                    onChange={this.onChangeText2}
                  />
                </div>

                <div className="tituloDato">
                  <i className="fas fa-envelope-open-text"></i>
                  Mensaje

                </div>
                <div className="editorPlantearrDuda" style={{ paddingLeft: '27px' }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={textHtmlMensaje}
                    onInit={editor => { }}
                    config={{
                      toolbar: ['bold', '|', 'link', '|', 'undo', 'redo']
                    }}
                    onChange={this.onChangeText}
                  />
                </div>

                <div className="tituloDato">

                </div>
                <div className="navBotones-enviarMensaje">

                  <div className="DMB-navDudas-boton">
                    <InputFileExamDesa onChange={this.myFiles} id="addFile" name="addFile" texto="Añadir archivos" />
                  </div>

                  <div className="DMB-navDudas-boton">
                    <Boton id="sendNoti" icon1="" icon2="" texto="Enviar" onClick={this.handleRedactar} />
                  </div>
                </div>

                {filesLoad && filesLoad != null & filesLoad.length > 0 ? (
                  <div className="navBotones-enviarMensaje" style={{ flexFlow: 'column wrap', alignItems: 'flex-start', alignContent: 'flex-start' }}>

                    {
                      filesLoad && filesLoad.length > 0 && filesLoad.map((archivo, key5) =>
                        <div className="fileLoadToNotiSend">
                          {archivo.name.split('.')[1] == 'pdf' ? (
                            <i className="fas fa-file-pdf"></i>
                          ) : (archivo.name.split('.')[1] == 'doc' || archivo.name.split('.')[1] == 'docx' ? (
                            <i className="fas fa-file-word"></i>
                          ) : (archivo.name.split('.')[1] == 'jpg' || archivo.name.split('.')[1] == 'png' ? (
                            <i className="fas fa-file-image"></i>
                          ) : (archivo.name.split('.')[1] == 'pptx' || archivo.name.split('.')[1] == 'ppt' ? (
                            <i className="fas fa-file-powerpoint"></i>
                          ) : (
                            <i style={{ color: '#e66161' }} className="fas fa-exclamation-triangle"></i>
                          ))))}


                          {archivo.name.split('.')[0]}
                        </div>
                      )
                    }
                  </div>
                ) : null}

              </div>



            ) : null}
          </div>


        </div>

      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser,
    notificacionAviso: state.user.notificacionAviso

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    notificiacionesVistas
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpedienteUser);

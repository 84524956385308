// Utils
import { getNewState } from '../lib/utils/frontend';

const initialState = {
  datosTest: undefined
};
export default function baroptionReducer(state = initialState, action) {

  switch(action.type) {

    case 'LOAD_TEST': {
       if(action.value != undefined){
         return { ...state, testJusticiaLoad: action.value }
       } else {
         return { ...state, testJusticiaLoad: [] }
       }
     }break;
     case 'LOAD_CASO_PRACTICO': {
        if(action.value != undefined){
          return { ...state, casoPracticoLoad: action.value }
        } else {
          return { ...state, casoPracticoLoad: [] }
        }
      }break;

    case 'GEN_EDT_PREG': {
      let preguntaNueva = action.value
       if(action.value != undefined){
         return { ...state, pregToEdt: [
           ...state.pregToEdt,
             { ...preguntaNueva, numPreg: 1, selected: 1 }
           ]
         }
       } else {
         return { ...state, pregToEdt: [] }
       }
     }break;
    case 'ADD_PREG_TO_EDT': {
      let preguntaNueva = action.value
      if(state.pregToEdt.filter( p=> p.idPregunta == action.value.idPregunta).length == 0){
        return { ...state, pregToEdt: [
          ...state.pregToEdt,
            { ...preguntaNueva, numPreg: action.numPreg , selected: 1 }
          ]
        }
      } else {
        return state;
      }
    }break;
    case 'DEL_PREG_TO_EDT': {
      return { ...state, pregToEdt: [
        ...state.pregToEdt.filter(p=> p.idPregunta != action.idPreg)
        ]
      }
 	  }break;
    case 'GEN_DEL_PREG': {
       let preguntaNueva = action.value
       if(action.value != undefined){
         return { ...state, pregToDelete: [
           ...state.pregToDelete,
             { ...preguntaNueva, selected: 1 }
           ]
         }
       } else {
         return { ...state, pregToDelete: []
         }
       }

      }break;
    case 'ADD_PREG_TO_DEL': {
      let preguntaNueva = action.value
      if(state.pregToDelete.filter( p=> p.idPregunta == action.value.idPregunta).length == 0){
        return { ...state, pregToDelete: [
          ...state.pregToDelete,
            { ...preguntaNueva, selected: 1 }
          ]
        }
      } else {
        return state;
      }
    }break;
    case 'DEL_PREG_TO_DEL': {

      return { ...state, pregToDelete: [
        ...state.pregToDelete.filter(p=> p.idPregunta != action.idPreg)
        ]
      }
 	}break;
  case 'CARGAR_TEST_INSERT': {
		  let datosTest = action.datosDelTest;
		  return getNewState(state, {
			 datosTestJusticia: datosTest
		    });
		return state;
    }break;
	case 'CARGAR_TEST_EDT': {
		  let datosTest = action.datosDelTestEdt;
		  return getNewState(state, {
			 datosTestJusticiaEdt: datosTest
		    });
		return state;
    }break;
	case 'START_PREGUNTA_TEST_INSERT': {
		  let arrayInicial = action.datos;
		  return getNewState(state, {
			 datosTestJusticiaAdd: arrayInicial
		    });
		return state;
    }break;
	 case 'ELIMINAR_TEST_JUSTICIA': {
		 return { ...state, datosTestJusticia: undefined };
      }break;
	 case 'ELIMINAR_TEST_JUSTICIA_EDT': {
		 return { ...state, datosTestJusticiaEdt: undefined };
      }break;
  case 'ELIMINAR_PREG_JUSTICIA_EDT': {
		 return { ...state, pregToEdt: [] };
     }break;
	 case 'ELIMINAR_PREGUNTAS_JUSTICIA_INSERT': {
		 return { ...state, datosTestJusticiaAdd: undefined };
      }break;
	 case 'ADD_PREGUNTA_NUEVA_EDT_JUSTICIA': {

		 let preguntaNueva = {
			 numPreg: '',
			 pregunta: '',
			 idPregunta: '',
			 tipoPregunta: '',
			 ley: '',
			 idLey: '',
			 articulo: '',
			 r1: '',
			 r1_validez: '',
			 idRespuesta1: '',
			 r2: '',
			 idRespuesta2: '',
			 r2_validez: '',
			 r3: '',
			 idRespuesta3: '',
			 r3_validez: '',
			 r4: '',
			 idRespuesta4: '',
			 r4_validez: '',
			 notaA: '',
			 notaB: '',
			 notaC: '',
			 notaD: '',
			 subrayadoA: '',
			 subrayadoB: '',
			 subrayadoC: '',
			 subrayadoD: '',
			 preguntaCheck: 0,
			 editar: 0,
       insertar: 1,
       borrar: 0
		 }
		 let opo = state.datosTestJusticiaEdt.find(p => p.oposicion === state.datosTestJusticiaEdt[0].oposicion);

		 return { ...state, datosTestJusticiaEdt: [
			  ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaEdt[0].preguntas,
					  { ...preguntaNueva,
						  numPreg: action.numPregNew
					  }]
				 }
			 ]
		 };
      }break;
	 case 'DELETE_PREGUNTA_NUEVA_EDT': {
		 let pregunta = state.datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == action.numPregDel);
		 let opo = state.datosTestJusticiaEdt.find(p => p.oposicion === state.datosTestJusticiaEdt[0].oposicion);

		return { ...state, datosTestJusticiaEdt: [
			  ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg)
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'DELETE_DESCENDER_PREGUNTA_NUEVA_EDT': {
		 let pregunta = state.datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == action.numPregChange);
		 let opo = state.datosTestJusticiaEdt.find(p => p.oposicion === state.datosTestJusticiaEdt[0].oposicion);
		 let numPregNew = pregunta.numPreg - 1
		return { ...state, datosTestJusticiaEdt: [
			  ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
					  {...pregunta,
						  numPreg: numPregNew
					  }
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'ADD_PREGUNTA_NUEVA_INS': {

		 let preguntaNueva = {
			 numPreg: '',
			 pregunta: '',
			 idPregunta: '',
			 tipoPregunta: '',
			 ley: '',
			 idLey: '',
			 articulo: '',
			 r1: '',
			 r1_validez: '',
			 idRespuesta1: '',
			 r2: '',
			 idRespuesta2: '',
			 r2_validez: '',
			 r3: '',
			 idRespuesta3: '',
			 r3_validez: '',
			 r4: '',
			 idRespuesta4: '',
			 r4_validez: '',
			 notaA: '',
			 notaB: '',
			 notaC: '',
			 notaD: '',
			 subrayadoA: '',
			 subrayadoB: '',
			 subrayadoC: '',
			 subrayadoD: '',
			 preguntaCheck: 0,
			 insertar: 1
		 }
		 let opo = state.datosTestJusticia.find(p => p.oposicion === state.datosTestJusticia[0].oposicion);

		 return { ...state, datosTestJusticia: [
			  ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticia[0].preguntas,
					  { ...preguntaNueva,
						  numPreg: action.numPregNew
					  }]
				 }
			 ]
		 };
      }break;
	 case 'DELETE_PREGUNTA_NUEVA_INS': {
		 let pregunta = state.datosTestJusticia[0].preguntas.find(p => p.numPreg == action.numPregDel);
		 let opo = state.datosTestJusticia.find(p => p.oposicion === state.datosTestJusticia[0].oposicion);

		return { ...state, datosTestJusticia: [
			  ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg)
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'DELETE_DESCENDER_PREGUNTA_NUEVA_INS': {
		 let pregunta = state.datosTestJusticia[0].preguntas.find(p => p.numPreg == action.numPregChange);
		 let opo = state.datosTestJusticia.find(p => p.oposicion === state.datosTestJusticia[0].oposicion);
		 let numPregNew = pregunta.numPreg - 1
		return { ...state, datosTestJusticia: [
			  ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
					  {...pregunta,
						  numPreg: numPregNew
					  }
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'ADD_PREGUNTA_NUEVA': {
		 //console.log("entro reducer add preg")
		 let preguntaNueva = {
			 numPreg: '',
			 pregunta: '',
			 idPregunta: '',
			 tipoPregunta: '',
			 ley: '',
			 idLey: '',
			 articulo: '',
			 r1: '',
			 r1_validez: '',
			 idRespuesta1: '',
			 r2: '',
			 idRespuesta2: '',
			 r2_validez: '',
			 r3: '',
			 idRespuesta3: '',
			 r3_validez: '',
			 r4: '',
			 idRespuesta4: '',
			 r4_validez: '',
			 notaA: '',
			 notaB: '',
			 notaC: '',
			 notaD: '',
			 subrayadoA: '',
			 subrayadoB: '',
			 subrayadoC: '',
			 subrayadoD: '',
			 preguntaCheck: 0,
			 insertar: 1
		 }
		 let opo = state.datosTestJusticiaAdd.find(p => p.oposicion === state.datosTestJusticiaAdd[0].oposicion);

		 return { ...state, datosTestJusticiaAdd: [
			  ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaAdd[0].preguntas,
					  { ...preguntaNueva,
						  numPreg: action.numPregNew
					  }]
				 }
			 ]
		 };
      }break;
	 case 'DELETE_PREGUNTA_NUEVA': {
		 let pregunta = state.datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == action.numPregDel);
		 let opo = state.datosTestJusticiaAdd.find(p => p.oposicion === state.datosTestJusticiaAdd[0].oposicion);

		/* let arrayNuevoDel = state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg)
		 for (var i = 0; i < arrayNuevoDel.length; i++) {
			if(arrayNuevoDel[i].numPreg > action.numPregDel) {
				arrayNuevoDel[i].numPreg = arrayNuevoDel[i].numPreg -1;
			}
		}*/

		return { ...state, datosTestJusticiaAdd: [
			  ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg)
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'DELETE_DESCENDER_PREGUNTA_NUEVA': {
		 let pregunta = state.datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == action.numPregChange);
		 let opo = state.datosTestJusticiaAdd.find(p => p.oposicion === state.datosTestJusticiaAdd[0].oposicion);
		 let numPregNew = pregunta.numPreg - 1
		return { ...state, datosTestJusticiaAdd: [
			  ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
				  {...opo, preguntas: [
					  ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
					  {...pregunta,
						  numPreg: numPregNew
					  }
 					 ]
				 }
			 ]
		 }
      }break;
	 case 'CAMBIAR_TEST_JUSTICIA': {
		 let pregunta = state.datosTestJusticia[0].preguntas.find(p => p.numPreg == action.numPreg);
		 let opo = state.datosTestJusticia.find(p => p.oposicion === state.datosTestJusticia[0].oposicion);

		 switch (action.nameEdit) {
			 case 'idPregunta':
 				return { ...state, datosTestJusticia: [
 					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
 						 {...opo, preguntas: [
 							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
 							 { ...pregunta,
 								 idPregunta: action.value
 							 }]
 						}
 					]
 				};
 		 	break;
			case 'idRespuesta1':
			    return { ...state, datosTestJusticia: [
					...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     idRespuesta1: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'idRespuesta2':
			   return { ...state, datosTestJusticia: [
				    ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
					    {...opo, preguntas: [
						    ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						    { ...pregunta,
							    idRespuesta2: action.value
						    }]
					   }
				   ]
			   };
		   break;
		   case 'idRespuesta3':
			  return { ...state, datosTestJusticia: [
				   ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
					   {...opo, preguntas: [
						   ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						   { ...pregunta,
							   idRespuesta3: action.value
						   }]
					  }
				  ]
			  };
		  break;
		  case 'idRespuesta4':
			 return { ...state, datosTestJusticia: [
				  ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
					  {...opo, preguntas: [
						  ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						  { ...pregunta,
							  idRespuesta4: action.value
						  }]
					 }
				 ]
			 };
		 break;
		 	case 'tipoRespuesta':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 tipoPregunta: action.value
							 }]
						}
					]
				};
		 	break;
			case 'validarPregunta':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 preguntaCheck: action.value
							 }]
						}
					]
				};
		 	break;
			case 'pregunta':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 pregunta: action.value
							 }]
						}
					]
				};
		 	break;
			case 'notaA':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaA: action.value
							 }]
						}
					]
				};
		 	break;
			case 'notaB':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaB: action.value
							 }]
						}
					]
				};
		 	break;
			case 'notaC':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaC: action.value
							 }]
						}
					]
				};
		 	break;
			case 'notaD':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaD: action.value
							 }]
						}
					]
				};
		 	break;
			case 'subrayadoA':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoA: action.value
							 }]
						}
					]
				};
		 	break;
			case 'subrayadoB':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoB: action.value
							 }]
						}
					]
				};
		 	break;
			case 'subrayadoC':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoC: action.value
							 }]
						}
					]
				};
		 	break;
			case 'subrayadoD':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoD: action.value
							 }]
						}
					]
				};
		 	break;
			case 'siglasLey':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 ley: action.value,
								 idLey: action.value2
							 }]
						}
					]
				};
		 	break;
			case 'articulo':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 articulo: action.value
							 }]
						}
					]
				};
		 	break;
			case 'respuesta1':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1: action.value
							 }]
						}
					]
				};
		 	break;
			case 'r1Validez':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1_validez: action.value
							 }]
						}
					]
				};
		 	break;
			case 'respuesta2':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2: action.value
							 }]
						}
					]
				};
		 	break;
			case 'r2Validez':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2_validez: action.value
							 }]
						}
					]
				};
		 	break;
			case 'respuesta3':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3: action.value
							 }]
						}
					]
				};
		 	break;
			case 'r3Validez':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3_validez: action.value
							 }]
						}
					]
				};
		 	break;
			case 'respuesta4':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4: action.value
							 }]
						}
					]
				};
		 	break;
			case 'r4Validez':
				return { ...state, datosTestJusticia: [
					 ...state.datosTestJusticia.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticia[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4_validez: action.value
							 }]
						}
					]
				};
		 	break;

		 }
      }break;

	 case 'CAMBIAR_TEST_JUSTICIA_ADD': {
		let pregunta = state.datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == action.numPreg);
		let opo = state.datosTestJusticiaAdd.find(p => p.oposicion === state.datosTestJusticiaAdd[0].oposicion);

		switch (action.nameEdit) {
			case 'idPregunta':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     idPregunta: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'oposicionPreg':
			   return { ...state, datosTestJusticiaAdd:[
				    ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
				    {...opo, preguntas: [
					    ...state.datosTestJusticiaAdd[0].preguntas
				    ],
				    oposicion: action.value
				   }
			   ]
			   };
		   break;
		    case 'insertar':
			   return { ...state, datosTestJusticiaAdd: [
				    ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
					    {...opo, preguntas: [
						    ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						    { ...pregunta,
							    insertar: action.value
						    }]
					   }
				   ]
			   };
		   break;
		    case 'idRespuesta1':
			   return { ...state, datosTestJusticiaAdd: [
				    ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
					    {...opo, preguntas: [
						    ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						    { ...pregunta,
							    idRespuesta1: action.value
						    }]
					   }
				   ]
			   };
		   break;
		   case 'idRespuesta2':
			  return { ...state, datosTestJusticiaAdd: [
				   ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
					   {...opo, preguntas: [
						   ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						   { ...pregunta,
							   idRespuesta2: action.value
						   }]
					  }
				  ]
			  };
		  break;
		  case 'idRespuesta3':
			 return { ...state, datosTestJusticiaAdd: [
				  ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
					  {...opo, preguntas: [
						  ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						  { ...pregunta,
							  idRespuesta3: action.value
						  }]
					 }
				 ]
			 };
		 break;
		 case 'idRespuesta4':
			return { ...state, datosTestJusticiaAdd: [
				 ...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
					 {...opo, preguntas: [
						 ...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						 { ...pregunta,
							 idRespuesta4: action.value
						 }]
					}
				]
			};
		break;
		    case 'tipoRespuesta':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     tipoPregunta: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'validarPregunta':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     preguntaCheck: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'pregunta':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     pregunta: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'notaA':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     notaA: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'notaB':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     notaB: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'notaC':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     notaC: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'notaD':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     notaD: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'subrayadoA':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     subrayadoA: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'subrayadoB':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     subrayadoB: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'subrayadoC':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     subrayadoC: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'subrayadoD':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     subrayadoD: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'siglasLey':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     ley: action.value,
							     idLey: action.value2
							}]
					    }
				    ]
			    };
		    break;
		    case 'articulo':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     articulo: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'respuesta1':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r1: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'r1Validez':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r1_validez: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'respuesta2':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r2: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'r2Validez':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r2_validez: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'respuesta3':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r3: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'r3Validez':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r3_validez: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'respuesta4':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r4: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'r4Validez':
			    return { ...state, datosTestJusticiaAdd: [
					...state.datosTestJusticiaAdd.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
							     r4_validez: action.value
							}]
					    }
				    ]
			    };
		    break;

		}
	 }break;


	 case 'CAMBIAR_TEST_JUSTICIA_EDT': {
		 let pregunta = state.datosTestJusticiaEdt[0].preguntas.find(p => p.numPreg == action.numPreg);
		 let opo = state.datosTestJusticiaEdt.find(p => p.oposicion === state.datosTestJusticiaEdt[0].oposicion);

		 switch (action.nameEdit) {
			 case 'idPregunta':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 idPregunta: action.value
							 }]
						}
					]
				};
			break;
			case 'idRespuesta1':
			    return { ...state, datosTestJusticiaEdt: [
					...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						{...opo, preguntas: [
							...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
								idRespuesta1: action.value
							}]
					    }
				    ]
			    };
		    break;
		    case 'idRespuesta2':
			   return { ...state, datosTestJusticiaEdt: [
				    ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
					    {...opo, preguntas: [
						    ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						    { ...pregunta,
							    idRespuesta2: action.value
						    }]
					   }
				   ]
			   };
		   break;
		   case 'idRespuesta3':
			  return { ...state, datosTestJusticiaEdt: [
				   ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
					   {...opo, preguntas: [
						   ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						   { ...pregunta,
							   idRespuesta3: action.value
						   }]
					  }
				  ]
			  };
		  break;
		  case 'idRespuesta4':
			 return { ...state, datosTestJusticiaEdt: [
				  ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
					  {...opo, preguntas: [
						  ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
						  { ...pregunta,
							  idRespuesta4: action.value
						  }]
					 }
				 ]
			 };
		 break;
			case 'tipoRespuestaEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 tipoPregunta: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
      case 'borrar':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 borrar: action.value,
                 insertar: 0,
                 editar: 0
							 }]
						}
					]
				};
			break;
			case 'validarPregunta':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 preguntaCheck: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'preguntaEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 pregunta: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'notaAEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaA: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'notaBEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaB: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'notaCEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaC: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'notaDEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaD: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'subrayadoAEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoA: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'subrayadoBEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoB: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'editar':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 editar: action.value
							 }]
						}
					]
				};
			break;
      case 'insertar':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 insertar: action.value
							 }]
						}
					]
				};
			break;
			case 'subrayadoCEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoC: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'subrayadoDEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoD: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'siglasLeyEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 ley: action.value,
								 idLey: action.value2,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'articuloEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 articulo: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'respuesta1Edt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'r1ValidezEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1_validez: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'respuesta2Edt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'r2ValidezEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2_validez: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'respuesta3Edt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'r3ValidezEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3_validez: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'respuesta4Edt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;
			case 'r4ValidezEdt':
				return { ...state, datosTestJusticiaEdt: [
					 ...state.datosTestJusticiaEdt.filter(pt => pt.oposicion !== opo.oposicion),
						 {...opo, preguntas: [
							 ...state.datosTestJusticiaEdt[0].preguntas.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4_validez: action.value,
								 editar: 1
							 }]
						}
					]
				};
			break;

		 }
	 }break;

   case 'CAMBIAR_PREG_JUSTICIA_EDT': {
		 let pregunta = state.pregToEdt.find(p => p.numPreg == action.numPreg);

		 switch (action.nameEdit) {
			 case 'idPregunta':
				return { ...state, pregToEdt: [
							 ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 idPregunta: action.value
							}]
				};
			break;
			case 'idRespuesta1':
			    return { ...state, pregToEdt: [
							...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							{ ...pregunta,
								idRespuesta1: action.value
							}]
			    };
		    break;
		    case 'idRespuesta2':
			   return { ...state, pregToEdt: [
						    ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
						    { ...pregunta,
							    idRespuesta2: action.value
						    }]
			   };
		   break;
		   case 'idRespuesta3':
			  return { ...state, pregToEdt: [
						   ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
						   { ...pregunta,
							   idRespuesta3: action.value
						   }]
			  };
		  break;
		  case 'idRespuesta4':
			 return { ...state, pregToEdt: [
						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
						  { ...pregunta,
							  idRespuesta4: action.value

					 }
				 ]
			 };
		 break;
			case 'tipoRespuestaEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 tipoPregunta: action.value,
								 editar: 1

						}
					]
				};
			break;
      case 'borrar':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 borrar: action.value,
                 insertar: 0,
                 editar: 0

						}
					]
				};
			break;
			case 'validarPregunta':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 preguntaCheck: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'preguntaEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 pregunta: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'notaAEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaA: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'notaBEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaB: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'notaCEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaC: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'notaDEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 notaD: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'subrayadoAEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoA: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'subrayadoBEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoB: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'editar':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 editar: action.value

						}
					]
				};
			break;
      case 'insertar':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 insertar: action.value

						}
					]
				};
			break;
			case 'subrayadoCEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoC: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'subrayadoDEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 subrayadoD: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'siglasLeyEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 ley: action.value,
								 idLey: action.value2,
								 editar: 1

						}
					]
				};
			break;
			case 'articuloEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 articulo: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'respuesta1Edt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'r1ValidezEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r1_validez: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'respuesta2Edt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'r2ValidezEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r2_validez: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'respuesta3Edt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'r3ValidezEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r3_validez: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'respuesta4Edt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4: action.value,
								 editar: 1

						}
					]
				};
			break;
			case 'r4ValidezEdt':
				return { ...state, pregToEdt: [
 						  ...state.pregToEdt.filter(p => p.numPreg !== pregunta.numPreg),
							 { ...pregunta,
								 r4_validez: action.value,
								 editar: 1

						}
					]
				};
			break;

		 }
	 }break;



      default:
        return state;
  }
}

// Dependencies
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../../../../lib/utils/is'
//Componets
import Boton from '../../../../../components/Inputs/Boton'
import InputFileExamDesa from '../../../../../components/Inputs/InputFileExamDesa'
import Input2 from '../../../../../components/Inputs/Input2'

const OptionBar = (props) => {

  const [videos, setvideos] = useState(null)
  const [videoSlc, setvideoSlc] = useState(null)

  const [fileVideos, setfileVideos] = useState(null)

  useEffect(() => { // didMount

    setvideoSlc(null)

    fetch('https://oposiciones-justicia.es/api/temas/loadVideos')
      .then(res => res.json())
      .then(cls => setvideos(cls))
      .catch(error => console.log('Object fecth failed', error))


    return () => { // willUnMount
      setvideoSlc(null)
      setvideos(null)
      setfileVideos(null)
    }
  }, [props.selectedOption]) // [] didUpdate


  const onChangeInput = (e) => {

    if (props.selectedOption == 'Editar_Video' && e.target.id == 'selectVideo') {
      let vidBus = videos.find(v => v.titulo === e.target.value)
      if (vidBus != undefined) {
        setvideoSlc(vidBus)
      } else {
        setvideoSlc(null)
      }

    }

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == 'addVideo') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el video será añadido a la BD.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            if (fileVideos.length > 0) {
              let dataVideos = new FormData();
              dataVideos.append('myFiles', fileVideos[0]);

              let videos = [];
              // // 1º Subir video
              if (dataVideos.getAll('myFiles').length > 0) {

                let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
                  method: 'POST', body: dataVideos
                });
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                let json = await response.json();
                let cls = await json;

                let path = '..' + cls[0].path.split('public')[1];
                let nameFile = cls[0].originalname

                videos.push({ titulo: nameFile, link: path })
              }

              // 2º subir video a bd
              let data = { nombreVideo: videos[0].titulo, linkVideo: videos[0].link }

              let response1 = await fetch("https://oposiciones-justicia.es/api/temas/addVideo", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              if (cls1 != undefined) {
                swal({
                  title: 'Video añadido',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })

              }

            } else {
              swal('ERROR', 'Rellene todos los datos, es obligatorio seleccionar 1 video "el nombre sera el del archivo sin extension"', 'error')
            }

          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });

    } else if (e.target.id == 'verVideo') {
      window.open(videoSlc.link, '_blank')
    } else if (e.target.id == 'delVideo') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el video será eliminado de la BD.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            if (videoSlc != null) {
              let data44 = { pathFile: '../public' + videoSlc.link.split('..')[1] }
              let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
              });
              if (!response44.ok) {
                // throw Error(response44.statusText);
                console.log(response44.statusText)
              }
              let json44 = await response44.json();
              let cls44 = await json44;

              let data = { idVideo: videoSlc.id_video }

              let response1 = await fetch("https://oposiciones-justicia.es/api/temas/delVideo", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              if (cls1 != undefined) {
                swal({
                  title: 'Video eliminado',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })

              }
            } else {
              swal('ERROR', 'Selecione un video para eliminarlo', 'error')
            }
          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });

    }
  }

  async function myFiles(e) {
    let id = e.target.id
    let files = e.target.files;
    let filesLoad = [];

    filesLoad.push(files[0]);


    if (id == 'fileVideos') {
      setfileVideos(filesLoad)
    }
  }

  async function myFilesAutoUpdate(e) {
    let id = e.target.id
    let files = e.target.files;
    let filesLoad = [];

    filesLoad.push(files[0]);

    if (id == 'fileVideos') {
      if (filesLoad.length > 0) {
        let dataVideos = new FormData();
        dataVideos.append('myFiles', filesLoad[0]);

        let videos = [];
        // // 1º Subir video

        if (dataVideos.getAll('myFiles').length > 0) {

          let response = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
            method: 'POST', body: dataVideos
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          let json = await response.json();
          let cls = await json;

          let path = '..' + cls[0].path.split('public')[1];
          let nameFile = cls[0].originalname

          videos.push({ titulo: nameFile, link: path })
        }
        // // 2º Ahora actualizamos info video y borramos el anterior
        if (videos.length > 0) {

          let data44 = { pathFile: '../public' + videoSlc.link.split('..')[1] }
          let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
          });
          if (!response44.ok) {
            // throw Error(response44.statusText);
            console.log(response44.statusText)
          }
          let json44 = await response44.json();
          let cls44 = await json44;

          let data1 = { videos: videos, id_video: videoSlc.id_video }

          // AQUI CAMBIAMOS VIDEO POR VIDEO!!!
          let response1 = await fetch('https://oposiciones-justicia.es/api/preparador/edtVideo', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1 != undefined) {
            swal({
              title: 'Video editado',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 1500,
            }).then(() => {
              window.location.reload()
            }).catch(function (err) { console.log(err) });
          }

        }
      }
    }

  }

  const { selectedOption } = props

  switch (selectedOption) {
    case 'Añadir_Video': {

      return (

        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Añadiendo Video </h3>

          <div className='ColCenterCenter' style={{ marginBottom: '15px' }}>
            <InputFileExamDesa texto="Subir Video" id="Videos" name="Videos" icon1="fa-light fa-circle-plus" onChange={myFiles} />
            {fileVideos != null && fileVideos.length > 0 && fileVideos.map((file, key03) => (
              <p key={key03}>{file.name}</p>
            ))}

          </div>
          {
            fileVideos != null && fileVideos.length > 0 ? (
              <Boton id='addVideo' onClick={handleSubmitForm} icon1='' icon2='' texto='Añadir video' />
            ) : null
          }

        </div>
      )
    }
      break
    case 'Editar_Video': {

      return (
        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Editando Video </h3>
          <div className='celdaInputDAPFBN' style={{ width: '320px' }}>
            <Input2 datalist='videos' autoComplete={'off'} id={'selectVideo'} type='text' name={'selectVideo'} placeholder='Nombre video' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Nombre Video</p>
          </div>
          {videoSlc != null ? (
            <>

              <h4 style={{ color: '#3469b7', marginBottom: '10px', marginTop: '25px' }}>
                {videoSlc.titulo}
              </h4>

              <Boton id='verVideo' onClick={handleSubmitForm} icon1='' icon2='' texto='Ver video actual' />

              <div className='ColCenterCenter' style={{ margin: '15px' }}>
                <InputFileExamDesa texto="Cambiar Video" id="Videos" name="Videos" icon1="fa-light fa-circle-plus" onChange={myFilesAutoUpdate} />
                {fileVideos != null && fileVideos.length > 0 && fileVideos.map((file, key03) => (
                  <p key={key03}>{file.name}</p>
                ))}

              </div>

              <p style={{ marginTop: '15px', marginBottom: '20px', lineHeight: '1.3', maxWidth: '414px' }}> Si quiere cambiar el video use el boton: Cambiar Video.</p>


              <div className='RowCenterCenter' style={{ margin: '10px' }}>
                <Boton id='delVideo' onClick={handleSubmitForm} icon1='' icon2='' texto='Borrar video' />
              </div>




            </>
          ) : null}

          <datalist id="videos">{videos && videos.map((video, key) =>
            <option key={key} value={video.titulo}>{video.link + ' (id: ' + video.id_video + ')'}</option>
          )}</datalist>
        </div>
      )
    } break
    default: {
      return (
        <div className="selectOptionToLoad">
          <i className="fa-thin fa-face-monocle"></i>
          <h3> Seleccione una opción para continuar </h3>
        </div>
      )
    }
  }

}

OptionBar.propTypes = {
  selectedOption: PropTypes.string.isRequired
}


export default OptionBar
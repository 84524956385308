// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import EditorTexto from '../../Inputs/EditorTexto';
import BotonMedio from '../../Inputs/BotonMedio';
import BotonPequeno from '../../Inputs/BotonPequeno';

import Submit from '../../Inputs/Submit';
import InputFile from '../../Inputs/InputFile';

class ListaNoticias extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
		this.state = {
			todasLasNovedades: [],
			cargaNovedad: 0,
			novedades: []
		};
		this.onChangeInput = this.onChangeInput.bind(this);
		this.handleOnSubmit = this.handleOnSubmit.bind(this);
	}
	async onChangeInput(e){
		if(e.target.id == 'numIniNoticia'){
			if(e.target.value - 1 >= 0){
				this.setState({cargaNovedad: e.target.value - 1})
				if(e.target.value < this.state.todasLasNovedades.length){
					let inicioSlice = e.target.value - 1;
					let maxSlice = inicioSlice + 5;
					if(maxSlice > this.state.todasLasNovedades.length){
						maxSlice = this.state.todasLasNovedades.length
						if(inicioSlice == this.state.todasLasNovedades.length - 1){
							maxSlice = 1
						}
					}
					let novedadesNew = await this.state.todasLasNovedades.slice(inicioSlice, maxSlice)
					await this.setState({ novedades: novedadesNew });
				} else {
					swal('Error no puede ser mayor a '+this.state.todasLasNovedades.length+'.','No existen más noticias','error')
				}
			} else {
				swal('Error no puede ser menor a 1','','error')
			}
		}
	}
	handleOnSubmit(e){
		if (e.target.id.split('-')[0] == "borrarNoticia"){
			//borramos la noticia fetch
			let idNovedad = e.target.id.split('-')[1]
			let noticia = this.state.novedades[idNovedad]
			let idNoticia = this.state.novedades[idNovedad].id_noticia
			let data = { idNoticia: idNoticia}

			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, la noticia será borrada.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				  fetch("https://oposiciones-justicia.es/api/informatica/deleteNoticia", {
				    method: 'POST',
				    headers: {'Content-Type': 'application/json'},
				    body: JSON.stringify(data)
				  }).then(response =>  {
					 if (response.status >= 400) { throw new Error("Bad response from server");}
				    return response.json();
				  }).then(data => {
					 if(data.affectedRows > 0){
					 	swal("Noticia eliminada con exito!",'', "success");
					 }}).catch(function(err) {console.log(err)});
			  } else {
			    swal("Noticia no borrada!",'',"success");
			  }
			});
		} else if (e.target.id.split('-')[0]  == 'editarNoticia'){
			let idNovedad = e.target.id.split('-')[1]
			let noticiaEdt = this.state.novedades[idNovedad]
			this.props.onClick(e, noticiaEdt);
		}
	}
async	componentDidMount(){
		if(this.state.todasLasNovedades.length == 0){
			try{
				let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/novedades");
					if (!response1.ok) {
						throw Error(response1.statusText);
					}
				let json1 = await response1.json();
				let cls11 = await json1;

				let inicioSlice = this.state.cargaNovedad
				let maxSlice = this.state.cargaNovedad + 5;
				if(maxSlice > cls11.length){
					maxSlice = cls11.length
					if(inicioSlice == cls11.length - 1){
						maxSlice = 1
					}
				}
				let novedadesNew = cls11.slice(inicioSlice, maxSlice)
				await this.setState({ novedades: novedadesNew, todasLasNovedades: cls11 });

				this.refs.ref_numIniNoticia.refs.numIniNoticia.value = this.state.cargaNovedad+1
			} catch(e){console.log(e)}
		}
	}


	render() {
		const { novedades } = this.state;
		return (
			<div className= "bodyLoadTest">

			<div className="barraTestJusticia2">
				<div className="barraTJ1">

					<div className="numPregBTJ" title="Número de la 1ª noticia a buscar">
						<Input type="number" min="1" id="numIniNoticia" name="numIniNoticia" placeholder="Nº" icon="fas fa-list" ref="ref_numIniNoticia" borrarOff={true} onChange={this.onChangeInput}/>
					</div>

				</div>
				<div className="barraTJ1">

					<div className="numPregBTJ" title="Número de noticias simultaneas.">
						<Input readOnly={true} type="number" min="1" name="numPregSimultaneas" placeholder="5" icon="fas fa-eye" ref="ref_numPregSimultaneas2" borrarOff={true}/>
					</div>

				</div>
			</div>

			<div className="NovedadesAcumuladas">
				{novedades && novedades.map((novedad, key) =>
					<div className="NovedadAE" key={key}>
					<div className="imgSecNAE2">
						<img src={`${novedad.imgTablon}`} />
					</div>
						<div className="secNAE1">
							<div className="topoBarNAE">
								<div className="fechaNAE" title="Fecha de Publicación">
									<i className="fas fa-calendar-alt"></i>
									{moment(novedad.fecha).format('DD-MM-YY')}
								</div>
								<div className="verNAE">
									<BotonPequeno id={"editarNoticia-"+key} name={"editarNoticia-"+key} icon1="fas fa-edit" icon2="" texto="Editar Noticia" onClick={this.handleOnSubmit}/>
								</div>
								<div className="verNAE">
									<BotonPequeno id={"borrarNoticia-"+key} name={"borrarNoticia-"+key} icon1="fas fa-trash" icon2="" texto="Borrar Noticia" onClick={this.handleOnSubmit}/>
								</div>
							</div>

							<div className="tituloNAE">
								<i className="fas fa-newspaper"></i>
								{novedad.titulo}
							</div>
							<div className="tituloNAE2">
								<i className="fab fa-readme"></i>
								{novedad.noticiaResumen}
							</div>
						</div>


					</div>
				)}
			</div>
		</div>
		);
	}
}

export default ListaNoticias;

// Actions
const LOGIN_USER = 'LOGIN_USER'
const LOGOUT_USER = 'LOGOUT_USER'
const CHANGE_FOTO_PERFIL = 'CHANGE_FOTO_PERFIL'
const CHANGE_USER_NAME = 'CHANGE_USER_NAME'
const NEW_TEST_JUSTICIA_USER_LOAD = 'NEW_TEST_JUSTICIA_USER_LOAD'
const DEL_TEST_JUSTICIA_USER_LOAD = 'DEL_TEST_JUSTICIA_USER_LOAD'
const SELECT_RES_JUS = 'SELECT_RES_JUS'
const CRONOMETRO = 'CRONOMETRO'
const PUNTUACION_PREG = 'PUNTUACION_PREG'
const LOAD_TEST_USER = 'LOAD_TEST_USER'
const CRONOMETROCP = 'CRONOMETROCP'
const NEW_CASO_PRACTICO_USER_LOAD = 'NEW_CASO_PRACTICO_USER_LOAD'
const DEL_CASO_PRACTICO_USER_LOAD = 'DEL_CASO_PRACTICO_USER_LOAD'
const SELECT_RES_CP = 'SELECT_RES_CP'
const PUNTUACION_PREG_CP = 'PUNTUACION_PREG_CP'

const LOAD_NOTICIA = 'LOAD_NOTICIA'

const LOAD_CP_USER = 'LOAD_CP_USER'

const NOTIFICACION = 'NOTIFICACION'
const CHANGE_MENU_VISIBLE = 'CHANGE_MENU_VISIBLE'

const LOAD_CURSO = 'LOAD_CURSO'
const LOAD_CLASE = 'LOAD_CLASE'
const TEMAS_FACTURADOS = 'TEMAS_FACTURADOS'
const LOAD_CATEGORIA_ESC = 'LOAD_CATEGORIA_ESC'
const LOAD_TEMA_ESC = 'LOAD_TEMA_ESC'

const LOAD_SEMANA_PRG = 'LOAD_SEMANA_PRG'
const LOAD_SEMANA_FFF = 'LOAD_SEMANA_FFF'

const LOAD_CLASE_EXT = 'LOAD_CLASE_EXT'
const LOAD_SEMANAS_FAC_FULL = 'LOAD_SEMANAS_FAC_FULL'

const DUDOSA_TEST = 'DUDOSA_TEST'
const DUDOSA_TEST_CP = 'DUDOSA_TEST_CP'

const LOAD_GEN_TEST = 'LOAD_GEN_TEST'
const GEN_TEST_ADD_OPO = 'GEN_TEST_ADD_OPO'
const GEN_TEST_EDT_OPO = 'GEN_TEST_EDT_OPO'
const GEN_TEST_DEL_OPO = 'GEN_TEST_DEL_OPO'
const GEN_TEST_ADD_CLASE = 'GEN_TEST_ADD_CLASE'
const GEN_TEST_EDT_CLASE = 'GEN_TEST_EDT_CLASE'
const GEN_TEST_DEL_CLASE = 'GEN_TEST_DEL_CLASE'
const GEN_TEST_ADD_TEMA = 'GEN_TEST_ADD_TEMA'
const GEN_TEST_EDT_TEMA = 'GEN_TEST_EDT_TEMA'
const GEN_TEST_EDT_TEMAOPO = 'GEN_TEST_EDT_TEMAOPO'
const GEN_TEST_DEL_TEMA = 'GEN_TEST_DEL_TEMA'


const GEN_TEST_EDT_NUM_PREG2 = 'GEN_TEST_EDT_NUM_PREG2'
const GEN_TEST_VAL_nametest = 'GEN_TEST_VAL_nametest'
const GEN_TEST_VAL_REPETIRprgutnas = 'GEN_TEST_VAL_REPETIRprgutnas'

const GEN_TEST_VAL_ExclusionExpPreg = 'GEN_TEST_VAL_ExclusionExpPreg'

const GEN_TEST_CONTINUAR = 'GEN_TEST_CONTINUAR'
const GEN_TEST_ADD_inter = 'GEN_TEST_ADD_inter'
const GEN_TEST_DEL_INTER = 'GEN_TEST_DEL_INTER'
const GEN_TEST_EDT_INTER = 'GEN_TEST_EDT_INTER'
const GEN_TEST_DEL_INTERS = 'GEN_TEST_DEL_INTERS'

const GEN_TEST_ADD_INTERS = 'GEN_TEST_ADD_INTERS'

const GEN_TEST_EDT_NUM_PREGTOTAL = 'GEN_TEST_EDT_NUM_PREGTOTAL'

const LOAD_TEMA_AULA = 'LOAD_TEMA_AULA'

const LOAD_SEM_CURSO = 'LOAD_SEM_CURSO'

const OPEN_BUS_ART = 'OPEN_BUS_ART'

const ADD_IDLEY_VIEWART = 'ADD_IDLEY_VIEWART'
const ADD_ART_VIEWART = 'ADD_ART_VIEWART'


export function addIdLeyBusArt(info){
  return {
    type: ADD_IDLEY_VIEWART,
    idLey: info
  };
}

export function addArtBusArt(info){
  return {
    type: ADD_ART_VIEWART,
    articulo: info
  };
}

export function openViewBusArt(info){
  return {
    type: OPEN_BUS_ART,
    payload: info
  };
}

export function loadSemanasCurso(semanasLoad){
  return {
    type: LOAD_SEM_CURSO,
    semanasLoad: semanasLoad
  };
}


export function genTestEdtTotalPregAll(value){
  return {
    type: GEN_TEST_EDT_NUM_PREGTOTAL,
    value: value
  };
}

export function genTestEdtTemaOpo(id, payload, valInput) {
  return {
    type: GEN_TEST_EDT_TEMAOPO,
    id: id,
    payload: payload,
    valInput: valInput
  };
}

export function genTestAddInt(){
  return {
    type: GEN_TEST_ADD_INTERS
  };
}

export function genTestDelIntervalos(value,t){
  return {
    type: GEN_TEST_DEL_INTERS,
    value: value,
    tipo: t
  };
}


export function genTestEdtIntervalo(value,t,z,x,v,j,d){
  return {
    type: GEN_TEST_EDT_INTER,
    value: value,
    tipo: t,
    idKey: z,
    tipoDatoInt: x,
    valueInput: v,
    valueSeleted: j,
    preguntasBusqueda: d

  };
}
export function genTestDelIntervalo(value,t,z){
  return {
    type: GEN_TEST_DEL_INTER,
    value: value,
    tipo: t,
    idKey: z
  };
}
export function genTestAddIntervalo(value,t){
  return {
    type: GEN_TEST_ADD_inter,
    value: value,
    tipo: t
  };
}
export function genTestContinuar(value){
  return {
    type: GEN_TEST_CONTINUAR,
    value: value
  };
}

export function genTestEdtTemporalizacionExp(value,pregExpe){
  return {
    type: GEN_TEST_VAL_ExclusionExpPreg,
    value: value,
    pregExpe: pregExpe
  };
}

export function genTestEdtNumPregTotal(value, id, tipo) {
  return {
    type: GEN_TEST_EDT_NUM_PREG2,
    value: value,
    id: id,
    tipo: tipo

  };
}
export function genTestEdtNameTest(value) {
  return {
    type: GEN_TEST_VAL_nametest,
    value: value
  };
}
export function genTestEdtRepetirPreg(value) {
  return {
    type: GEN_TEST_VAL_REPETIRprgutnas,
    value: value
  };
}

export function genTestAddTema() {
  return {
    type: GEN_TEST_ADD_TEMA
  };
}
export function genTestEdtTema(id, payload, valInput) {
  return {
    type: GEN_TEST_EDT_TEMA,
    id: id,
    payload: payload,
    valInput: valInput
  };
}
export function genTestDelTema(id) {
  return {
    type: GEN_TEST_DEL_TEMA,
    id: id
  };
}


export function cargarGenTest(value) {
  return {
    type: LOAD_GEN_TEST,
    value: value
  };
}
export function genTestAddOpo() {
  return {
    type: GEN_TEST_ADD_OPO
  };
}
export function genTestEdtOpo(id, opoSeleted, valInput) {
  return {
    type: GEN_TEST_EDT_OPO,
    id: id,
    opoSeleted: opoSeleted,
    valInput: valInput
  };
}
export function genTestDelOpo(id) {
  return {
    type: GEN_TEST_DEL_OPO,
    id: id
  };
}

export function genTestAddClase() {
  return {
    type: GEN_TEST_ADD_CLASE
  };
}
export function genTestEdtClase(id, payload,valInput,pregExpe) {
  return {
    type: GEN_TEST_EDT_CLASE,
    id: id,
    payload: payload,
    valInput:valInput,
    pregExpe: pregExpe
  };
}


export function genTestDelClase(id,pregExpe) {
  return {
    type: GEN_TEST_DEL_CLASE,
    id: id,
    pregExpe: pregExpe
  };
}

export function loadDudosa(value,numPreg) {
  return {
    type: DUDOSA_TEST,
    dudosa: value,
    numPreg: numPreg
  };
}

export function loadDudosaCP (value,numPreg) {
  return {
    type: DUDOSA_TEST_CP,
    dudosa: value,
    numPreg: numPreg
  };
}
export function semanaFacturadaLoad(sem) {
  return {
    type: LOAD_SEMANA_FFF,
    semanaFac: sem
  };
}
export function loadAllSemFac(sem) {
  return {
    type: LOAD_SEMANAS_FAC_FULL,
    allSemFac: sem
  };
}

export function loadTemasFacturados(temas) {
  return {
    type: TEMAS_FACTURADOS,
    temasFacturados: temas
  };
}

export function loadSemanaPrg(semanaPrg) {
  return {
    type: LOAD_SEMANA_PRG,
    semanaPrg: semanaPrg
  };
}

export function loadCategoEsc(idCategoria) {
  return {
    type: LOAD_CATEGORIA_ESC,
    idCategoria: idCategoria
  };
}
export function loadTemaEsc(idTema) {
  return {
    type: LOAD_TEMA_ESC,
    idTema: idTema
  };
}


export function loadTemaAula(idTema) {
  return {
    type: LOAD_TEMA_AULA,
    idTema: idTema
  };
}
export function loadCurso(idClase, nameClase, idOpo, userAccesoTemas) {
  return {
    type: LOAD_CURSO,
    idClase: idClase,
    nombreClase: nameClase,
    idOpo: idOpo,
    userAccesoTemas: userAccesoTemas
  };
}
export function loadClaseExt(semanasFacturadas, displayClases, displaySemanas) {
  return {
    type: LOAD_CLASE_EXT,
    semanasFacturadas: semanasFacturadas,
    displayClases: displayClases,
    displaySemanas: displaySemanas
  
  };
}
export function loadClase(semanasFacturadas, displayClases, displaySemanas) {
  return {
    type: LOAD_CLASE,
    semanasFacturadas: semanasFacturadas,
    displayClases: displayClases,
    displaySemanas: displaySemanas
  
  };
}
export function notificiacionesVistas(estado) {
      return {
        type: NOTIFICACION,
        estado: estado
      };
}

export function menuChangeVisibilidad(val) {
      return {
        type: CHANGE_MENU_VISIBLE,
        value: val
      };
}

export function iniciarSesion(identificadorUser,passUser,dataFetch, ipUser) {
    return {
      type: LOGIN_USER,
      identificadorUser: identificadorUser,
      passUser: passUser,
      tipoUser: 0,
      infoCheckLogin: dataFetch,
      dataIp: ipUser
    };
}

export function iniciarSesionForce(infoCheckLogin) {
  return {
    type: 'LOGIN_USER_FORCE_ADM',
    infoCheckLogin: infoCheckLogin
  };
}

export function reloadInfoPersonal(urlFotoPerfil) {
      return {
        type: CHANGE_FOTO_PERFIL,
        urlNueva: urlFotoPerfil
      };
}
export function reloadNameUser(user) {
      return {
        type: CHANGE_USER_NAME,
        newUserName: user
      };
}
export function cerrarSesion() {
    return {
      type: LOGOUT_USER
    };
}
export function loadTestUser(testConRespuestas){
  return {
    type: NEW_TEST_JUSTICIA_USER_LOAD,
    test: testConRespuestas
  }
}
export function loadTestUserNUBE(testConRespuestas){
  return {
    type: 'LOAD_TEST_JUSTICIA_USER_LOAD',
    test: testConRespuestas
  }
}

export function loadCPUser(testConRespuestas, tHtml){
  return {
    type: NEW_CASO_PRACTICO_USER_LOAD,
    test: testConRespuestas,
    textHtml: tHtml
  }
}
export function borrarTestUser(){
  return {
    type: DEL_TEST_JUSTICIA_USER_LOAD
  }
}
export function borrarCP(){
  return {
    type: DEL_CASO_PRACTICO_USER_LOAD
  }
}
export function puntosRes(value,numPreg){
  return {
    type: PUNTUACION_PREG,
    value: value,
    numPreg: numPreg
  }
}
export function puntosResCP(value,numPreg){
  return {
    type: PUNTUACION_PREG_CP,
    value: value,
    numPreg: numPreg
  }
}
export function selectPreg(numPreg,posRes,idR){
  return {
    type: SELECT_RES_JUS,
    numPreg: numPreg,
    pos: posRes,
    idR: idR
  }
}

export function selectPregCP(numPreg,posRes,idR){
  return {
    type: SELECT_RES_CP,
    numPreg: numPreg,
    pos: posRes,
    idR: idR
  }
}

export function cronoFunc(minutos,segundos){
  return {
    type: CRONOMETRO,
    minutos: minutos,
    segundos: segundos
  }
}
export function cronoFuncCP(minutos,segundos){
  return {
    type: CRONOMETROCP,
    minutos: minutos,
    segundos: segundos
  }
}
export function loadTestJusticia(test) {
    return {
	    type: LOAD_TEST_USER,
      value: test
    };
}

export function loadCasoPractico(test, htmlText) {
    return {
	    type: LOAD_CP_USER,
      value: test,
      htmlText: htmlText
    };
}

export function loadNoticia(noticia) {
    return {
	    type: LOAD_NOTICIA,
      value: noticia
    };
}

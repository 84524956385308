// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
// Assets
import './css/insertTest.css';
import  { preguntaDuplicada } from './utils/functions.js';
import { isDefined } from '../../../lib/utils/is';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cambiarDatosTestJusticiaAdd, eliminarPreguntasJusticiaCache, addPreguntaTestJusticia, deletePreguntaTestJusticia, changeNumPreguntaTestJusticia } from './actions/testJusticiaAction';
import { cargarContenido } from './actions/baroptionAction';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

class AddPreguntaJusticia extends Component {
	static propTypes = {
		datosTestJusticiaAdd: PropTypes.array.isRequired
	};
	constructor(props){
		super(props);
        	this.state = {
			displayNameTest: 'none',
			numPreg: 1,
			numeroPreguntaEspecifico: undefined,
			busquedaLeyes: undefined,
			displayInsertTest: 'flex',
			preguntasDuplicadas: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		   ],
		   	values2: [
			   {
				   value: '',
				   name: '¿Unica o Multiple?',
				   disable: 'disabled',
				   selected: 'selected'
			  },{
				  value: '1',
				  name: 'Unica Respuesta',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '2',
				  name: 'Multi Respuesta',
				  disable: undefined,
				  selected: undefined
			 },
			 ,{
				  value: '3',
				  name: 'Multiple Falsa',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '4',
				  name: 'Con una Variable',
				  disable: undefined,
				  selected: undefined
			 },{
				  value: '5',
				  name: 'Multiple a ó b',
				  disable: undefined,
				  selected: undefined
			 }
		 ],
		 	values3: [
			 {
				 value: '',
				 name: 'Res. Normal',
				 disable: 'disabled',
				 selected: 'selected'
			},{
				value: '0',
				name: 'Incorrecta',
				disable: undefined,
				selected: undefined
		    },{
				value: '1',
				name: 'Correcta',
				disable: undefined,
				selected: undefined
		    }
	    ],
	    		values4: [
		    {
			    value: '',
			    name: 'Res. Multiple',
			    disable: 'disabled',
			    selected: 'selected'
		   },{
			   value: '0',
			   name: 'Incorrecta',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '1',
			   name: 'Mutl. Correcta',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '2',
			   name: 'Var. de Multiple',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '10',
			   name: 'Var. de Multiple + Var. RUV1',
			   disable: undefined,
			   selected: undefined
		  },{
			   value: '16',
			   name: 'Var. de Multiple + Var. RUV2',
			   disable: undefined,
			   selected: undefined
		  },{
    		    value: '7',
    		    name: 'RUV 1 --> Falsa',
    		    disable: undefined,
    		    selected: undefined
    	   	},{
  		  value: '13',
  		  name: 'RUV 2 --> Falsa',
  		  disable: undefined,
  		  selected: undefined
  	 	},{
  		    value: '8',
  		    name: 'Var. RUV 1',
  		    disable: undefined,
  		    selected: undefined
  	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   }
	  ],
	  		values5: [
		  {
			  value: '',
			  name: 'Res. Multiple Falsa',
			  disable: 'disabled',
			  selected: 'selected'
		 },{
		 	 value: '1',
		 	 name: 'Correcta',
		 	 disable: undefined,
		 	 selected: undefined
		 },{
 			value: '5',
 			name: 'Correcta + Var. Mult. Falsa',
 			disable: undefined,
 			selected: undefined
 		},{
			 value: '0',
			 name: 'Incorrecta',
			 disable: undefined,
			 selected: undefined
	     },{
			 value: '3',
			 name: 'Mult. Falsa',
			 disable: undefined,
			 selected: undefined
	     },{
			 value: '4',
			 name: 'Var. Mult. Falsa',
			 disable: undefined,
			 selected: undefined
	     },{
  		    value: '7',
  		    name: 'RUV 1 --> Falsa',
  		    disable: undefined,
  		    selected: undefined
  	   	},{
		  value: '13',
		  name: 'RUV 2 --> Falsa',
		  disable: undefined,
		  selected: undefined
	 	},{
			 value: '10',
			 name: 'Var. de Multiple + Var. RUV1 + Falsa',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '16',
			 name: 'Var. de Multiple + Var. RUV2 + Falsa',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '11',
			 name: 'Var. de Multiple + Var. RUV1 + Correcta',
			 disable: undefined,
			 selected: undefined
		},{
			 value: '17',
			 name: 'Var. de Multiple + Var. RUV2 + Correcta',
			 disable: undefined,
			 selected: undefined
		},{
  		    value: '8',
  		    name: 'Var. RUV 1',
  		    disable: undefined,
  		    selected: undefined
  	   },{
  		    value: '9',
  		    name: 'Var. RUV 1 "Falsa" + Correcta',
  		    disable: undefined,
  		    selected: undefined
  	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '15',
		    name: 'Var. RUV 2 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   }
	],
			values6: [
		{
			value: '',
			name: 'RUV - Unica Variable',
			disable: 'disabled',
			selected: 'selected'
	    },{
		    value: '1',
		    name: 'Correcta',
		    disable: undefined,
		    selected: undefined
	    },{
		    value: '0',
		    name: 'Incorrecta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '6',
		    name: 'RUV 1 --> Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '7',
		    name: 'RUV 1 --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '8',
		    name: 'Var. RUV 1',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '9',
		    name: 'Var. RUV 1 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '12',
		    name: 'RUV 2 --> Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '13',
		    name: 'RUV 2 --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '14',
		    name: 'Var. RUV 2',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '15',
		    name: 'Var. RUV 2 "Falsa" + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '3',
		    name: 'Multiple --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '4',
		    name: 'Var. Multiple',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '5',
		    name: 'Var. Multiple + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '22',
		    name: 'MUV --> Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '23',
		    name: 'Var. MUV',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '24',
		    name: 'Var. MUV + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '10',
		    name: 'Var. de Multiple + Var. RUV1 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '16',
		    name: 'Var. de Multiple + Var. RUV2 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '11',
		    name: 'Var. de Multiple + Var. RUV1 + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '17',
		    name: 'Var. de Multiple + Var. RUV2 + Correcta',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '18',
		    name: 'Var. RUV1 + Var. RUV2 + Falsa',
		    disable: undefined,
		    selected: undefined
	   },{
		    value: '19',
		    name: 'Var. RUV1 + Var. RUV2 + Correcta',
		    disable: undefined,
		    selected: undefined
	   }
    ],
    			values7: [
	    {
		    value: '',
		    name: 'MUV - Mult. con a ó b',
		    disable: 'disabled',
		    selected: 'selected'
	   },{
		   value: '1',
		   name: 'Correcta',
		   disable: undefined,
		   selected: undefined
	   },{
		   value: '0',
		   name: 'Incorrecta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '20',
		   name: 'MUV --> Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '21',
		   name: 'Var. MUV',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '22',
		   name: 'MUV --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '23',
		   name: 'Var. MUV Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '24',
		   name: 'Var. MUV Falsa + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '10',
		   name: 'Var. MUV + Var. RUV1 + Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '16',
		   name: 'Var. MUV + Var. RUV2 + Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '11',
		   name: 'Var. MUV + Var. RUV1 + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '17',
		   name: 'Var. MUV + Var. RUV2 + Correcta',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '7',
		   name: 'RUV 1 --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '13',
		   name: 'RUV 2 --> Falsa',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '8',
		   name: 'Var. RUV 1',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '14',
		   name: 'Var. RUV 2',
		   disable: undefined,
		   selected: undefined
	  },{
		   value: '18',
		   name: 'Var. RUV 1 + Var. RUV 2',
		   disable: undefined,
		   selected: undefined
	  }
   ]
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
		this.handleRellenarDatos = this.handleRellenarDatos.bind(this);
		this.borrarNumerosPrgInsert = this.borrarNumerosPrgInsert.bind(this);
		this.retrocederPreguntas = this.retrocederPreguntas.bind(this);
	}

	handleRellenarDatos(numPregPrev){
		let { datosTestJusticiaAdd } = this.props;
		let {numPreg , busquedaLeyes} = this.state;

		if(datosTestJusticiaAdd[0].oposicion != undefined){  this.refs.ref_oposicionPreg.refs.oposicionPreg.value = datosTestJusticiaAdd[0].oposicion }
		this.refs.ref_pregunta.refs.pregunta.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).pregunta
		this.refs.ref_subrayadoA.refs.subrayadoA.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoA
		this.refs.ref_subrayadoB.refs.subrayadoB.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoB
		this.refs.ref_subrayadoC.refs.subrayadoC.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoC
		this.refs.ref_subrayadoD.refs.subrayadoD.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).subrayadoD
		this.refs.ref_notaA.refs.notaA.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).notaA
		this.refs.ref_notaB.refs.notaB.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).notaB
		this.refs.ref_notaC.refs.notaC.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).notaC
		this.refs.ref_notaD.refs.notaD.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).notaD
		this.refs.ref_articulo.refs.articulo.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).articulo

		let siglasLey = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).ley
		if(busquedaLeyes != undefined && siglasLey != ''){
			let objectLey = busquedaLeyes.find(opo => opo.siglas_ley == siglasLey)
			if( objectLey !== undefined){
				this.refs.ref_siglasLey.refs.siglasLey.value = siglasLey;
			} else {
				setTimeout(
				    function() {
				        swal('Auto relleno de Ley fallo. Lal ley: '+siglasLey+' no existe en la base de datos CEJ.','Si no la encuentra manualmente, avise para que introduzcan dicha LEY.','warning')
				   }.bind(this),2000);
				    this.refs.ref_siglasLey.refs.siglasLey.value = ""
			}
		}

		this.refs.ref_tipoRespuesta.refs.tipoRespuesta.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta
		this.refs.ref_respuesta1.refs.respuesta1.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r1
		this.refs.ref_respuesta2.refs.respuesta2.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r2
		this.refs.ref_respuesta3.refs.respuesta3.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r3
		this.refs.ref_respuesta4.refs.respuesta4.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r4
		setTimeout( function() {
			if( datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != '' && datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).tipoPregunta != undefined){

				if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r1_validez != undefined || datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r1_validez != ''){
					this.refs.ref_r1Validez.refs.r1Validez.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r1_validez
				}

			    if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r2_validez != undefined || datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r2_validez != ''){
				    this.refs.ref_r2Validez.refs.r2Validez.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r2_validez
			    }

			    if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r3_validez != undefined || datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r3_validez != ''){
				    this.refs.ref_r3Validez.refs.r3Validez.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r3_validez
			    }

			    if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r4_validez != undefined || datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r4_validez != ''){
				    this.refs.ref_r4Validez.refs.r4Validez.value = datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).r4_validez
			    }
		    }
		    }.bind(this), 150);

	}

	onChange(e){
		let { datosTestJusticiaAdd } = this.props

		if (e.target.name == 'numeroPreguntaEspecifico'){
			if(e.target.value <= datosTestJusticiaAdd[0].preguntas.length && e.target.value > 0){
				this.setState({ numeroPreguntaEspecifico: e.target.value })
			}
		} else if (e.target.name == 'tipoRespuesta' || e.target.name == 'pregunta' || e.target.name == 'nota' || e.target.name == 'subrayadoA' || e.target.name == 'subrayadoB' || e.target.name == 'subrayadoC' || e.target.name == 'subrayadoD' || e.target.name == 'articulo' || e.target.name == 'respuesta1' || e.target.name == 'r1Validez' || e.target.name == 'respuesta2' || e.target.name == 'r2Validez' || e.target.name == 'respuesta3' || e.target.name == 'r3Validez' || e.target.name == 'respuesta4' || e.target.name == 'r4Validez'  || e.target.name == 'notaA' || e.target.name == 'notaB' || e.target.name == 'notaC' || e.target.name == 'notaD') {
			let id = e.target.id.split('-')[1];
			this.props.cambiarDatosTestJusticiaAdd(id, e.target.name, e.target.value)

		} else if(e.target.name == 'siglasLey'){

			if(this.state.busquedaLeyes.find( l => l.siglas_ley == e.target.value) != undefined){
				let id = e.target.id.split('-')[1];
				let idLey = this.state.busquedaLeyes.find( l => l.siglas_ley == e.target.value).id_ley
				this.props.cambiarDatosTestJusticiaAdd(id, e.target.name, e.target.value, idLey)
			}
		} else if (e.target.name == 'oposicionPreg'){
			let id = 0;
			this.props.cambiarDatosTestJusticiaAdd(id, e.target.name, e.target.value)
		}

	}
	handleOnClick(e){
		this.props.onClick(e);
		if(e.target.id == "preguntaSiguiente"){
			let { datosTestJusticiaAdd } = this.props
			if(this.state.numeroPreguntaEspecifico != undefined){
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if(numPregPrev <= datosTestJusticiaAdd[0].preguntas.length && numPregPrev > 0){
					this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
   						    noValidar: {
   						      text: "No validar",
   						      value: "noValidar",
						 },
						    validar: {
						      text: "Validar",
						      value: "validar",
						    }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticiaAdd(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}

				} else {
					swal('La pregunta: '+numPregPrev+' no existe, rango valido: 1 - '+datosTestJusticiaAdd[0].preguntas.length+'.','','warning');
				}

			} else {

				let numPregPrev = parseInt(this.state.numPreg + 1);
				if(numPregPrev <= datosTestJusticiaAdd[0].preguntas.length  && numPregPrev > 0){
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticiaAdd(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				}else{
					swal('¡No hay más preguntas, solo puede retroceder o finalizar la insercción!','','warning');
				}
			}
		} else if(e.target.id == "preguntaAnterior") {
			let { datosTestJusticiaAdd } = this.props
			if(this.state.numeroPreguntaEspecifico != undefined){
				let numPregPrev = parseInt(this.state.numeroPreguntaEspecifico);
				if(numPregPrev <= datosTestJusticiaAdd[0].preguntas.length && numPregPrev > 0){
					this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticiaAdd(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				} else {
					swal('La pregunta: '+numPregPrev+' no existe, rango valido: 1 - '+datosTestJusticiaAdd[0].preguntas.length+'.','','warning');
				}
			} else {
				let numPregPrev = parseInt(this.state.numPreg - 1);
				if(numPregPrev<= datosTestJusticiaAdd[0].preguntas.length  && numPregPrev > 0){
					this.setState({ numPreg: numPregPrev })
					this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
					this.handleRellenarDatos(numPregPrev);
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
						$("#iTScontainer2").addClass("iTScontainerValidada");
						$("#iTScontainer2").removeClass("iTScontainer2");
					} else {
						$("#iTScontainer2").addClass("iTScontainer2");
						$("#iTScontainer2").removeClass("iTScontainerValidada");
					}
					let numPregReal = this.state.numPreg
					if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
						// validamos pregunta
						swal("¿Validamos la pregunta "+numPregReal+"?","", {
						  buttons: {
							  noValidar: {
      						      text: "No validar",
      						      value: "noValidar",
							 },
							 validar: {
								  text: "Validar",
								  value: "validar",
							  }
						  },
						})
						.then((value) => {
						  switch (value) {
						    case "validar":

						   this.props.cambiarDatosTestJusticiaAdd(numPregReal, 'validarPregunta', 1)
						      break;
						    case "noValidar":
						      break;
						  }
						});
					}
				}else{
					swal('No puede retroceder más.','','warning');
				}
			}
		}else if(e.target.id == "loadPregClick"){
			let numPregPrev = parseInt(e.target.innerText);
			let { datosTestJusticiaAdd } = this.props;
			if(numPregPrev <= datosTestJusticiaAdd[0].preguntas.length && numPregPrev > 0){
				this.setState({numeroPreguntaEspecifico: undefined, numPreg: numPregPrev})
				this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numPregPrev;
				this.handleRellenarDatos(numPregPrev);
				if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregPrev).preguntaCheck == 1){
					$("#iTScontainer2").addClass("iTScontainerValidada");
					$("#iTScontainer2").removeClass("iTScontainer2");
				} else {
					$("#iTScontainer2").addClass("iTScontainer2");
					$("#iTScontainer2").removeClass("iTScontainerValidada");
				}
				let numPregReal = this.state.numPreg
				if(datosTestJusticiaAdd[0].preguntas.find(p => p.numPreg == numPregReal).preguntaCheck == 0){
					// validamos pregunta
					swal("¿Validamos la pregunta "+numPregReal+"?","", {
					  buttons: {
						  noValidar: {
							 text: "No validar",
							 value: "noValidar",
						 },
						 validar: {
							  text: "Validar",
							  value: "validar",
						  }
					  },
					})
					.then((value) => {
					  switch (value) {
					    case "validar":

					   this.props.cambiarDatosTestJusticiaAdd(numPregReal, 'validarPregunta', 1)
						 break;
					    case "noValidar":
						 break;
					  }
					});
				}
			}
		} else if (e.target.id == "insertarPreguntasNuevas") {


			let { datosTestJusticiaAdd } = this.props

			if(datosTestJusticiaAdd[0].preguntas.filter(p=> p.preguntaCheck == 1 ||  p.preguntaCheck == 2).length > 0){
			if(isDefined(datosTestJusticiaAdd[0].oposicion) && datosTestJusticiaAdd[0].oposicion != ''){

			let filtradoTest = datosTestJusticiaAdd[0].preguntas.filter(p=> p.preguntaCheck == 1 ||  p.preguntaCheck == 2)
			for (var i = 0; i < filtradoTest.length; i++) {

					let max = filtradoTest.length - 1
					let pregunta = filtradoTest[i]

					let preg = pregunta.pregunta
					let r1 = pregunta.r1
					let r2 = pregunta.r2
					let r3 = pregunta.r3
					let r4 = pregunta.r4
					let idLey = pregunta.idLey
					let articulo = pregunta.articulo
					let numPreg = pregunta.numPreg
					let numFor = i;
					if (	isDefined(preg)
						&& isDefined(r1) && isDefined(r2)
						&& isDefined(r3) && isDefined(r4) &&
						isDefined(articulo) && isDefined(idLey)
					) {

							let data = { pregunta: preg, articulo: articulo, idLey: idLey, r1: r1, r2: r2, r3:r3, r4: r4}

							let esDuplicada = 0;
							fetch("https://oposiciones-justicia.es/api/informatica/preguntaDuplicadaJusticia", {
							     method: 'POST',
							     headers: {'Content-Type': 'application/json'},
							     body: JSON.stringify(data)
							}).then(response =>  {
							     if (response.status >= 400) { throw new Error("Bad response from server");}
							     return response.json();
							}).then(cls => {

							  if (cls.length == 0 || cls == undefined){
								 let id = numPreg;
		  						this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', 1)
								filtradoTest[numFor].insertar =1
							} else if(cls.length > 0 ){
								let id = numPreg;
								this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', 2)
								filtradoTest[numFor].insertar = 2
						   }
					   }).catch(error => console.log('Object fecth failed', error))
					}

					//console.log(preguntaDuplicada(pregunta))
					//let esDuplicada = preguntaDuplicada(pregunta)


					/*if( esDuplicada == false ){
						// no duplicada
						let id = datosTestJusticiaAdd[0].preguntas[i].numPreg;
						this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', 1)
					} else if ( esDuplicada == true) {
						// duplicada
						console.log("Esta duplicada:")
						let id = datosTestJusticiaAdd[0].preguntas[i].numPreg;
						this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', 0)
					}*/



			}
			swal("Procesando Preguntas","","success")
			setTimeout(
			    function() {
				    let preguntasHaInsertar = filtradoTest.filter(p => p.preguntaCheck == 1 && p.insertar == 1)
	 	   			let idOpo = datosTestJusticiaAdd[0].oposicion


					if ( preguntasHaInsertar.length > 0){
						//this.handleRellenarDatos(preguntasHaInsertar[0].numPreg);
						// insertar con fetch! --> preguntasHaInsertar copiar de InserTest!
						for (var i = 0; i < preguntasHaInsertar.length; i++) {
								let numFor = i
								let data = {
									idOposicion: idOpo,
									idLey: preguntasHaInsertar[i].idLey,
									articulo: preguntasHaInsertar[i].articulo,
									pregunta: preguntasHaInsertar[i].pregunta,
									tipoPregunta: preguntasHaInsertar[i].tipoPregunta,
									numArray: i
								};

								fetch("https://oposiciones-justicia.es/api/informatica/addPreguntaJusticia", {
									method: 'POST',
									headers: {'Content-Type': 'application/json'},
									body: JSON.stringify(data)
								}).then(response =>  {
									if (response.status >= 400) { throw new Error("Bad response from server");}
									return response.json();
								}).then(cls => {
									this.props.cambiarDatosTestJusticiaAdd(preguntasHaInsertar[data.numArray].numPreg,'idPregunta',cls.insertId)
									preguntasHaInsertar[data.numArray].idPregunta = cls.insertId

									this.setState({ numCtrl : 0 })

									let data2 = {
									 idPregunta: cls.insertId,
									 r1: preguntasHaInsertar[data.numArray].r1,
									 r1Validez: preguntasHaInsertar[data.numArray].r1_validez,
									 notaA: preguntasHaInsertar[data.numArray].notaA,
									 subrayadoA: preguntasHaInsertar[data.numArray].subrayadoA,
									 r2: preguntasHaInsertar[data.numArray].r2,
									 r2Validez: preguntasHaInsertar[data.numArray].r2_validez,
									 notaB: preguntasHaInsertar[data.numArray].notaB,
									 subrayadoB: preguntasHaInsertar[data.numArray].subrayadoB,
									 r3: preguntasHaInsertar[data.numArray].r3,
									 r3Validez: preguntasHaInsertar[data.numArray].r3_validez,
									 notaC: preguntasHaInsertar[data.numArray].notaC,
									 subrayadoC: preguntasHaInsertar[data.numArray].subrayadoC,
									 r4: preguntasHaInsertar[data.numArray].r4,
									 r4Validez: preguntasHaInsertar[data.numArray].r4_validez,
									 notaD: preguntasHaInsertar[data.numArray].notaD,
									 subrayadoD: preguntasHaInsertar[data.numArray].subrayadoD
								 };

								 return fetch("https://oposiciones-justicia.es/api/informatica/addRespuestasJusticia", {
			 						method: 'POST',
			 						headers: {'Content-Type': 'application/json'},
			 						body: JSON.stringify(data2)
			 	   				});
			 	                  	}).then(response =>  {
			 	                    	if (response.status >= 400) { throw new Error("Bad response from server");}
			 	                        	return response.json();
			 	                  	}).then(cls => {
			 	                  	if(cls != undefined){
									//console.log(preguntasHaInsertar)
								}}).catch(error => console.log('Object fecth failed', error))


							}
							// borramos las preguntas
							setTimeout(
							    function() {
								    for (var i = 0; i < preguntasHaInsertar.length; i++) {
								    	this.borrarNumerosPrgInsert(preguntasHaInsertar[i].numPreg)
								    }
								    swal("Preguntas insertadas","","success")
							}.bind(this),700);

						}
			}.bind(this),1300);

			} else {
				swal("Error", "Falta elegir una oposición","warning")
			}
		}else{
			swal("Error", "No tiene validada ninguna pregunta","warning")
		}
		} else if (e.target.id == "addPregunta") {
			let numPregNuevo = this.props.datosTestJusticiaAdd[0].preguntas.length + 1
			this.props.addPreguntaTestJusticia(numPregNuevo)
		} else if (e.target.id == "deletePregunta") {

			swal({
			  title: "¿Estas segur@?",
			  text: "Si le das a ok, la pregunta será eliminada.",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
					let numPregDel = this.state.numPreg
					this.borrarNumerosPrgInsert(numPregDel)
			  } else {
			    swal("¡Pregunta no borrada!",'',"success");
			  }
			  });

		}

	}
borrarNumerosPrgInsert(numPreg){
//let preguntasHaInsertar = preguntas;

let numPregDel = numPreg
//console.log(numPregDel)
let numCarga = numPreg - 1
if(numCarga > 0){
	this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= numCarga;
	this.handleRellenarDatos(numCarga)
	this.setState({numPreg: numCarga})

	this.props.deletePreguntaTestJusticia(numPregDel)

	let numChance = this.props.datosTestJusticiaAdd[0].preguntas.filter(p => p.numPreg > numPregDel).length

	this.retrocederPreguntas(numPregDel, numChance)

	} else if (this.props.datosTestJusticiaAdd[0].preguntas.length > 1){
		let id = 1
		let value = this.props.datosTestJusticiaAdd[0].preguntas.filter(p=> p.numPreg == 2);

		this.props.cambiarDatosTestJusticiaAdd(id, 'validarPregunta', value[0].preguntaCheck)
		this.props.cambiarDatosTestJusticiaAdd(id, 'tipoRespuesta', value[0].tipoPregunta)
		this.props.cambiarDatosTestJusticiaAdd(id, 'pregunta', value[0].pregunta)
		this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoA', value[0].subrayadoA)
		this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoB', value[0].subrayadoB)

		this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoC', value[0].subrayadoC)
		this.props.cambiarDatosTestJusticiaAdd(id, 'subrayadoD', value[0].subrayadoD)
		this.props.cambiarDatosTestJusticiaAdd(id, 'articulo', value[0].articulo)
		this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta1', value[0].r1)
		this.props.cambiarDatosTestJusticiaAdd(id, 'r1Validez', value[0].r1_validez)

		this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta2', value[0].r2)
		this.props.cambiarDatosTestJusticiaAdd(id, 'r2Validez', value[0].r2_validez)
		this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta3', value[0].r3)
		this.props.cambiarDatosTestJusticiaAdd(id, 'r3Validez', value[0].r3_validez)
		this.props.cambiarDatosTestJusticiaAdd(id, 'respuesta4', value[0].r4)

		this.props.cambiarDatosTestJusticiaAdd(id, 'r4Validez', value[0].r4_validez)
		this.props.cambiarDatosTestJusticiaAdd(id, 'notaA', value[0].notaA)
		this.props.cambiarDatosTestJusticiaAdd(id, 'notaB', value[0].notaB)
		this.props.cambiarDatosTestJusticiaAdd(id, 'notaC', value[0].notaC)
		this.props.cambiarDatosTestJusticiaAdd(id, 'notaD', value[0].notaD)

		this.props.cambiarDatosTestJusticiaAdd(id, 'insertar', value[0].insertar)
		this.props.cambiarDatosTestJusticiaAdd(id, 'idPregunta', value[0].idPregunta)
		this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta1', value[0].idRespuesta1)
		this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta2', value[0].idRespuesta2)
		this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta3', value[0].idRespuesta3)
		this.props.cambiarDatosTestJusticiaAdd(id, 'idRespuesta4', value[0].idRespuesta4)

		this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= 1;
		this.handleRellenarDatos(1)
		this.setState({numPreg: 1})
		this.props.deletePreguntaTestJusticia(2)
		this.retrocederPreguntas(2,this.props.datosTestJusticiaAdd[0].preguntas.filter(p=> p.numPreg > 2).length)
}

}

retrocederPreguntas(numPregDel, numChance){
	for (var i = 1; i <= numChance; i++) {
		let num = numPregDel + i
		this.props.changeNumPreguntaTestJusticia(num)

	}
}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));
		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		.then(res => res.json())
		.then(leyes => this.setState({
			busquedaLeyes: leyes
		}))
		.catch(error => console.log('Obejet fecth failed', error))

		if(this.props.datosTestJusticiaAdd[0].preguntas.filter(p => p.preguntaCheck == 0).find(p => p.preguntaCheck == 0) != undefined){
			let firstNumPregSinValidar = this.props.datosTestJusticiaAdd[0].preguntas.filter(p => p.preguntaCheck == 0).sort((a,b) => a.numPreg - b.numPreg).find(p => p.preguntaCheck == 0).numPreg
			this.refs.ref_numeroPreguntaEspecifico.refs.numeroPreguntaEspecifico.value= firstNumPregSinValidar;
			let siglasLey = this.props.datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == firstNumPregSinValidar).ley
			if(siglasLey != '' && siglasLey != undefined){
				this.refs.ref_siglasLey.refs.siglasLey.value = siglasLey;
			}
			this.handleRellenarDatos(firstNumPregSinValidar)
			this.setState({numPreg: firstNumPregSinValidar})
		}


	}
	render() {
		const { datosTestJusticiaAdd } = this.props;
		const { values, values2, values3, values4, values5, values6, values7, busquedaOpo, busquedaLeyes, numPreg, displayInsertTest } = this.state;
		return (
			<div className="insertTest">

			<div className="barraAccion5">
				<div className="barraAccion2">
					<div className="botonBA">
						<Submit id="insertarPreguntasNuevas" display={displayInsertTest} icon1="fas fa-upload" icon2="fas fa-save" texto="Finalizar revisión y Insertar Test" onClick={this.handleOnClick}/>
					</div>
				</div>
				<div className="barraAccion2">
					<div className="botonBA">
						<Input datalist="opoData" type="text" size ="16" id={'oposicionPreg'} name="oposicionPreg" ref="ref_oposicionPreg" icon="fas fa-search" placeholder="Oposición" onChange={this.onChange}/>
						<datalist id="opoData">
							{ busquedaOpo && busquedaOpo.map((cls, key) =>
								<option key={key} value={cls.id_oposicion}>{cls.nombre}</option>
							)}
						</datalist>
					</div>
				</div>

				<div className="barraAccion6">
					<div className="preguntasSinValidar">
					{datosTestJusticiaAdd[0].preguntas.filter(preg => preg.preguntaCheck === 0).length > 0 ? (
						'Preguntas : '+datosTestJusticiaAdd[0].preguntas.length+', sin validar: '+datosTestJusticiaAdd[0].preguntas.filter(preg => preg.preguntaCheck === 0).length+', duplicadas: '+datosTestJusticiaAdd[0].preguntas.filter(preg => preg.insertar === 2 || preg.insertar === 0).length
					):(<p style={{color: '#55cf31', fontSize: '28px'}}> Todas las Preguntas Validadas</p>)}
					</div>
					<div className="preguntasSinValidar">
					{datosTestJusticiaAdd[0].preguntas.filter(preg => preg.insertar == 0 || preg.insertar == 2).length > 0 ? (
						'El Nº de las preguntas duplicadas son: '
					):('')}
					{datosTestJusticiaAdd[0].preguntas.filter(preg => preg.insertar == 0 || preg.insertar == 2).length > 0 ? (
						datosTestJusticiaAdd[0].preguntas.filter(preg => preg.insertar == 2 || preg.insertar ==  0).map((cls,key) =>
						<div className="numberDuplicate">
							{cls.numPreg}
						</div>
					)
					):('')}
					</div>

				</div>
				<div className="barraAccion3">
				{datosTestJusticiaAdd[0].preguntas.filter(preg => preg.preguntaCheck === 0 || preg.preguntaCheck === 1 ) && datosTestJusticiaAdd[0].preguntas.filter(preg => preg.preguntaCheck === 0 || preg.preguntaCheck === 1).sort((a,b) => a.numPreg - b.numPreg).map((pregunta, key) =>
					<div className="botonBA4" key={key}>

						{ pregunta.preguntaCheck == 0 ? (
							<div className="preguntaSinValidar" id="loadPregClick" onDoubleClick={this.handleOnClick}>
								{pregunta.numPreg}
							</div>
						):('')}
						{ pregunta.preguntaCheck == 1 && pregunta.insertar == 1 ? (
							<div className="preguntaValidada" id="loadPregClick" onDoubleClick={this.handleOnClick}>
								{pregunta.numPreg}
							</div>
						):('')}
						{ pregunta.insertar == 2 ? (
							<div className="preguntaDuplicadaV" id="loadPregClick" onDoubleClick={this.handleOnClick}>
								{pregunta.numPreg}
							</div>
						):('')}

					</div>
				)}
				</div>
			</div>

				<div className="iTSection">
				<div className="encimaPregunta">
						<div className="iTStitulo">
							<i className="fas fa-arrow-circle-right"></i>
							Pregunta
							{' '+numPreg}
							<div>
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).preguntaCheck == 1 && datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 1 ? (
									<div style={{color: '#29cb3d', marginLeft: '8px'}}> Validada</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).preguntaCheck == 0 && datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 1 ? (
									<div style={{color: '#cf1515', marginLeft: '15px'}}>No Validada</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 0 || datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).insertar == 2 ? (
									<div style={{color: '#cf1515', marginLeft: '15px'}}>Duplicada</div>
								):('')}
							</div>
						</div>

						<div className="barraAccion">
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="preguntaAnterior" icon1="fas fa-caret-left" texto="Anterior" onClick={this.handleOnClick}/>
								</div>
								<div className="botonBA2">
									<Input type="number" min="0" id={'numeroPreguntaEspecifico'} name="numeroPreguntaEspecifico" ref="ref_numeroPreguntaEspecifico" icon="fas fa-sort-numeric-up" placeholder="1-99" onChange={this.onChange}/>
								</div>
								<div className="botonBA">
									<Submit id="preguntaSiguiente" icon2="fas fa-caret-right" texto="Siguiente" onClick={this.handleOnClick}/>
								</div>
							</div>
							<div className="barraAccion2">
								<div className="botonBA">
									<Submit id="addPregunta" icon1="fas fa-plus" texto="Añadir" onClick={this.handleOnClick}/>
								</div>
								<div className="botonBA">
									<Submit id="deletePregunta" icon2="fas fa-minus" texto="Borrar" onClick={this.handleOnClick}/>
								</div>
							</div>
						</div>

				</div>
					<div className="iTScontainer2" id="iTScontainer2">

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Pregunta
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'pregunta-'+numPreg} name="pregunta" ref="ref_pregunta" valueDefault="¡Pegar pregunta aquí!" width="400px" height="70px" onChange={this.onChange}/>
								</div>
								<div className="iTSCSBInput2">

								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Nota Res. 1
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'notaA-'+numPreg} name="notaA" ref="ref_notaA" valueDefault="¡Nota respuesta 1 aquí!" width="90px" height="75px" onChange={this.onChange}/>
									</div>
								</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 2
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaB-'+numPreg} name="notaB" ref="ref_notaB" valueDefault="¡Nota respuesta 2 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 3
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaC-'+numPreg} name="notaC" ref="ref_notaC" valueDefault="¡Nota respuesta 3 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

									<div className="iTSCSBInput3">
										<div className="iTSCSBTitulo">
											Nota Res. 4
										</div>
										<div className="iTSCSBInput">
											<InputTextArea id={'notaD-'+numPreg} name="notaD" ref="ref_notaD" valueDefault="¡Nota respuesta 4 aquí!" width="90px" height="75px" onChange={this.onChange}/>
										</div>
									</div>

								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Tipo de Respuesta
								</div>
								<div className="iTSCSBInput">
									<InputSelect values={values2} id={'tipoRespuesta-'+numPreg} name="tipoRespuesta" ref="ref_tipoRespuesta" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
								<div className="iTSCSBTitulo">
									Siglas Ley
								</div>
								<div className="iTSCSBInput">
									<Input datalist="leyesDataList" type="text" size ="16" id={'siglasLey-'+numPreg} name="siglasLey" ref="ref_siglasLey" icon="fas fa-search" placeholder="Ej: LEC" onChange={this.onChange}/>
									<datalist id="leyesDataList">
										{ busquedaLeyes && busquedaLeyes.map((cls, key) =>
											<option key={key} value={cls.siglas_ley}>{cls.nombre_ley}</option>
										)}
									</datalist>
								</div>
								<div className="iTSCSBTitulo">
									Artículo
								</div>
								<div className="iTSCSBInput">
									<Input type="text" size ="16" id={'articulo-'+numPreg} name="articulo" ref="ref_articulo" icon="fas fa-arrow-right" placeholder="Ej: 128.a" onChange={this.onChange}/>
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
						<div className="iTSCsubBody">
							<div className="iTSCSBTitulo">
								Respuesta 1
							</div>
							<div className="iTSCSBInput">
								<InputTextArea id={'respuesta1-'+numPreg} name="respuesta1" ref="ref_respuesta1" valueDefault="¡Pegar respuesta 1 aquí!" width="400px" height="80px" onChange={this.onChange}/>
							</div>
						</div>

						<div className="iTSCsubBody">
							<div className="iTSCSBTitulo">
								Validez de Respuesta
							</div>
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
								<div className="iTSCSBIinfoBasica">
									<i className="fas fa-info-circle"></i>
									Falta el tipo de Respuesta
								</div>
							):('')}
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values3} id={'r1Validez-'+numPreg} ref="ref_r1Validez" name="r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values4} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values5} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values6} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
								<div className="iTSCSBInput">
									<InputSelect values={values7} id={'r1Validez-'+numPreg} name="r1Validez" ref="ref_r1Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
								</div>
							):('')}
							<div className="iTSCSBInput3">
								<div className="iTSCSBTitulo">
									Subrayado Res. 1
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'subrayadoA-'+numPreg} name="subrayadoA" ref="ref_subrayadoA" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange}/>
								</div>
							</div>
						</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 2
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta2-'+numPreg} name="respuesta2" ref="ref_respuesta2" valueDefault="¡Pegar respuesta 2 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r2Validez-'+numPreg} name="r2Validez" ref="ref_r2Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 2
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoB-'+numPreg} name="subrayadoB" ref="ref_subrayadoB" valueDefault="¡Pegar subrayado respuesta 2 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow2">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 3
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta3-'+numPreg} name="respuesta3" ref="ref_respuesta3" valueDefault="¡Pegar respuesta 3 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r3Validez-'+numPreg} name="r3Validez" ref="ref_r3Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 3
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoC-'+numPreg} name="subrayadoC" ref="ref_subrayadoC" valueDefault="¡Pegar subrayado respuesta 3 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

						<div className="iTSCRow">
							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Respuesta 4
								</div>
								<div className="iTSCSBInput">
									<InputTextArea id={'respuesta4-'+numPreg} name="respuesta4" ref="ref_respuesta4" valueDefault="¡Pegar respuesta 4 aquí!" width="400px" height="80px" onChange={this.onChange}/>
								</div>
							</div>

							<div className="iTSCsubBody">
								<div className="iTSCSBTitulo">
									Validez de Respuesta
								</div>
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 0 ? (
									<div className="iTSCSBIinfoBasica">
										<i className="fas fa-info-circle"></i>
										Falta el tipo de Respuesta
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 1 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values3} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 2 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values4} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 3 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values5} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 4 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values6} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								{datosTestJusticiaAdd[0].preguntas.find(p=> p.numPreg == numPreg).tipoPregunta == 5 ? (
									<div className="iTSCSBInput">
										<InputSelect values={values7} id={'r4Validez-'+numPreg} name="r4Validez" ref="ref_r4Validez" icon="fas fa-hand-pointer" onChange={this.onChange}/>
									</div>
								):('')}
								<div className="iTSCSBInput3">
									<div className="iTSCSBTitulo">
										Subrayado Res. 4
									</div>
									<div className="iTSCSBInput">
										<InputTextArea id={'subrayadoD-'+numPreg} name="subrayadoD" ref="ref_subrayadoD" valueDefault="¡Pegar subrayado respuesta 1 aquí!" width="200px" height="35px" onChange={this.onChange}/>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


			</div>
		);
	}
}

// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		datosTestJusticiaAdd: state.testJusticia.datosTestJusticiaAdd
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		cargarContenido,
		cambiarDatosTestJusticiaAdd,
		eliminarPreguntasJusticiaCache,
		addPreguntaTestJusticia,
		deletePreguntaTestJusticia,
		changeNumPreguntaTestJusticia
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPreguntaJusticia);

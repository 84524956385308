// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
// Data
import itemsMenuAdmin from '../../../../data/menuOficina';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from './actions/baroptionAction';
// Components
import MenuAdmin from '../MenuAdmin';

import Boton from '../../../../components/Inputs/Boton';
import Input2 from '../../../../components/Inputs/Input2';

import Cita from '../../../../components/Cita';

class Citas extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    tipoUser: PropTypes.number.isRequired
  }
  constructor(props) {
    super(props);
    this.state = {
      navS: 0,
      busquedaCitasName: null,
      busquedaCitasAnteriores: null,
      verCita: true,
      fecha2: undefined,
      nombreAlumno2: '',
      preparador2: '',
      oposicion2: '',
      estudios2: '',
      opositor2: '',
      telefono2: '',
      nota2: ''
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }

  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async onHandleClick(e) {

    if (e.target.id.split('-')[0] == 'nav') {
      this.setState({ navS: e.target.id.split('-')[1] })

    } else if (e.target.id == 'addCita') {
      this.setState({ verCita: this.state.verCita == true ? (null) : (true) })
    } else if (e.target.id == 'generarCita') {
      let { fecha2, nombreAlumno2, preparador2, oposicion2, estudios2, opositor2, telefono2, nota2 } = this.state


      let data = {
        preparador: preparador2,
        nameAlumno: nombreAlumno2,
        oposicion: oposicion2,
        estudios: estudios2,
        opositor: opositor2,
        tlf: telefono2,
        fecha: fecha2 != undefined && fecha2 != '' ? moment(fecha2).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
        hora: fecha2 != undefined && fecha2 != '' ? moment(fecha2).format('HH:mm:ss') : moment().format('HH:mm:ss'),
        nota: nota2
      }
      let response = await fetch('https://oposiciones-justicia.es/api/oficina/addCita', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;

      swal({
        title: 'Cita generada',
        text: '¡Recargando web!',
        icon: 'success',
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
        timer: 1700,
      }).then(() => {
        window.location.reload()
      })

    }
  }

  async componentDidMount() {


    let data = { time: moment().format("YYYYMMDD") }
    fetch('https://oposiciones-justicia.es/api/oficina/consultaCitas', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(citas => this.setState({
        busquedaCitasName: citas
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/oficina/consultaCitasAnteriores', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(citas => this.setState({
        busquedaCitasAnteriores: citas
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      }))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/clases/consultaPre')
      .then(res => res.json())
      .then(preparadores => this.setState({
        busquedaPre: preparadores
      }))
      .catch(error => console.log('Object fecth failed', error))
  }
  render() {

    const { isLogged, tipoUser, idUser } = this.props;
    const { navS, busquedaCitasName, busquedaCitasAnteriores, verCita, fecha2, nombreAlumno2, preparador2, oposicion2, estudios2, opositor2, telefono2, nota2, busquedaOpo, busquedaPre } = this.state
    const nivelRequired = 98;

    if (isLogged && nivelRequired <= tipoUser) {
      return (
        <div className="inBodyMenu">
          <div className="menuBody">
            <MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="OFICINA" />
          </div>
          <div className="optionselectedNew" style={{ boxShadow: 'unset' }}>
            {verCita == true ? (
              <div className='bodyCitas ColCenterStart'>

                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                  <Boton id='addCita' onClick={this.onHandleClick} icon1='' icon2='' texto='Añadir cita' />
                </div>

                <div className='ColCenterStart adb-nav'>
                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                    <div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-0' onClick={this.onHandleClick}>
                      Pendientes
                      {busquedaCitasName && busquedaCitasName != null && busquedaCitasName.length > 0 ? (' - ' + busquedaCitasName.length) : null}
                    </div>
                    <div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-1' onClick={this.onHandleClick}>
                      Archivadas
                    </div>
                  </div>
                </div>

                {
                  navS == 0 ?
                    (
                      <div className='ColCenterStart'>
                        {
                          busquedaCitasName && busquedaCitasName.length > 0 ? (
                            busquedaCitasName && busquedaCitasName.map((cita, key) =>
                              <Cita key={key} id={cita.id_cita} fecha={cita.fecha} hora={cita.hora} nombreAlumno={cita.nombre_alumno} preparador={cita.preparador} oposicion={cita.oposicion} estudios={cita.estudios} opositor={cita.opositor} telefono={cita.telefono} nota={cita.nota} />
                            )
                          ) : (
                            <h3 style={{ color: '#3469b7', padding: '25px', position: 'relative' }}>
                              <i className="fa-light fa-message-xmark" style={{ fontSize: '30px', marginRight: '6px', position: 'absolute', top: '0', right: '-5px' }}></i>
                              No hay ninguna cita pendiente
                            </h3>
                          )
                        }
                      </div>
                    ) : null
                }


                {
                  navS == 1 ?
                    (
                      <div className='ColCenterStart'>
                        {
                          busquedaCitasAnteriores && busquedaCitasAnteriores.length > 0 ? (
                            busquedaCitasAnteriores && busquedaCitasAnteriores.map((cita, key) =>
                              <Cita key={key} id={cita.id_cita} fecha={cita.fecha} hora={cita.hora} nombreAlumno={cita.nombre_alumno} preparador={cita.preparador} oposicion={cita.oposicion} estudios={cita.estudios} opositor={cita.opositor} telefono={cita.telefono} nota={cita.nota} />
                            )
                          ) : (
                            <h3 style={{ color: '#3469b7', padding: '25px' }}>No hay ninguna cita archivada</h3>
                          )
                        }
                      </div>
                    ) : null
                }


              </div>
            ) : null}
            {verCita == null ? (
              <div className='bodyCitas ColCenterStart'>

                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                  <Boton id='addCita' onClick={this.onHandleClick} icon1='' icon2='' texto='Ver citas' />
                </div>

                <div className="infoCompleta" style={{ display: 'flex' }}>
                  <div className='RowCenterCenter' style={{ maxWidth: '480px', width: '100%', marginTop: '10px' }}>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={nombreAlumno2} autoComplete={'off'} id={'nombreAlumno2'} type='text' name={'nombreAlumno2'} ref={'nombreAlumno2'} placeholder='Nombre Alumno' icon='fa-light fa-user' onChange={this.onChangeInput} />
                      <p>Nombre</p>
                    </div>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={estudios2} autoComplete={'off'} id={'estudios2'} type='text' name={'estudios2'} ref={'estudios2'} placeholder='Estudios' icon='fa-light fa-briefcase' onChange={this.onChangeInput} />
                      <p>Estudios</p>
                    </div>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={preparador2} datalist='busquedaPre' autoComplete={'off'} id={'preparador2'} type='text' name={'preparador2'} ref={'preparador2'} placeholder='Nombre preparador' icon='fa-light fa-user' onChange={this.onChangeInput} />
                      <p>Preparador</p>
                    </div>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={oposicion2} datalist='busquedaOpo' autoComplete={'off'} id={'oposicion2'} type='text' name={'oposicion2'} ref={'oposicion2'} placeholder='Oposición' icon='fa-light fa-search' onChange={this.onChangeInput} />
                      <p>Oposición</p>
                    </div>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={telefono2} autoComplete={'off'} id={'telefono2'} type='text' name={'telefono2'} ref={'telefono2'} placeholder='telefono' icon='fa-light fa-phone' onChange={this.onChangeInput} />
                      <p>Teléfono</p>
                    </div>

                    <div className='celdaInputDAPFBN'>
                      <Input2 value={opositor2} autoComplete={'off'} id={'opositor2'} type='text' name={'opositor2'} ref={'opositor2'} placeholder='opositor' icon='fa-light fa-circle-info' onChange={this.onChangeInput} />
                      <p>¿Ha opositado?</p>
                    </div>



                    <div className='celdaInputDAPFBN' style={{ width: '220px' }}>
                      <Input2 value={moment(fecha2).format('YYYY-MM-DD HH:mm')} autoComplete={'off'} id={'fecha2'} type='datetime-local' name={'fecha2'} ref={'fecha2'} placeholder='Fecha' icon='fa-light fa-calendar' onChange={this.onChangeInput} />
                      <p>Fecha</p>
                    </div>

                    <datalist id="busquedaPre">{
                      busquedaPre && busquedaPre.map((pre, key) =>
                        <option key={key} value={pre.nombre + ' '+pre.apellidos}></option>
                      )
                    }</datalist>
                    <datalist id="busquedaOpo">{
                      busquedaOpo && busquedaOpo.map((opo, key) =>
                        <option key={key} value={opo.nombre}></option>
                      )
                    }</datalist>

                  </div>
                  <div className="infoCompletaFlex2">
                    <textarea rows="6" cols="50" id={'nota2'} name="nota2" placeholder="¡Notas aquí!" onChange={this.onChangeInput} />
                  </div>
                  <div className='RowCenterCenter' style={{ marginTop: '10px', justifyContent: 'center', width: '100%' }}>

                    <div className='RowCenterCenter' style={{ padding: '5px' }} >
                      <Boton id='generarCita' onClick={this.onHandleClick} icon1='' icon2='' texto='Generar cita' />
                    </div>

                  </div>


                </div>

              </div>
            ) : null}

          </div>




        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser
  }
}


export default connect(mapStateToProps, null)(Citas);

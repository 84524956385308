// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import bcrypt from 'bcryptjs';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Input from '../../../../../components/Inputs/Input';
import CorrectorMeca from '../../../../../components/CorrectorMeca';
import Submit from '../../../../../components/Inputs/Submit';
import InputFile from '../../../../../components/Inputs/InputFile';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };
constructor(props){
        super(props);
        this.state = {
          statusBarraProceso: 'completar',
					tituloEjercicioMecanografia: '',
					tituloFormatoMecanografia: '',
					infoMeca: []

        }
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.cleanForm = this.cleanForm.bind(this);
				this.myFiles = this.myFiles.bind(this);
				this.corregido = this.corregido.bind(this);
    }


corregido(){
	let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
	let idOposicion = oposicionObject.id_oposicion;
	let claseObject = this.state.busquedaSemClasesAll.find(clase => clase.dia_semanal == this.state.claseSelecionada && clase.id_oposicion == idOposicion);
	if(isDefined(idOposicion) && isDefined(claseObject)){
		let data = { id_clase: claseObject.id_clase }
	 fetch("https://oposiciones-justicia.es/api/oficina/consultaMecaExpClase", {
		 method: 'POST',
		 headers: {'Content-Type': 'application/json'},
		 body: JSON.stringify(data)
	 }).then(response =>  {
			 if (response.status >= 400) { throw new Error("Bad response from server");}
		 return response.json();
	 }).then(cls => {
		 let prevInfoMeca = [];
		 for (var i = 0; i < cls.length; i++) {
			prevInfoMeca.push({
				idExpendiente: cls[i].id_expediente,
				nombreAlumno:""+this.state.busquedaAlumnos.find(alumno => alumno.id_usuario == cls[i].id_usuario).nombre+" "+this.state.busquedaAlumnos.find(alumno => alumno.id_usuario == cls[i].id_usuario).apellidos+"",
				tituloEjercicio: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).titulo_ejercicio,
				linkEjerMeca: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).link_ejercicio,
				linkFormatoMeca: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).link_formato,
				linkEjerAlumno: cls[i].link_ejer_alumno
			})
		 }
		 this.setState({ infoMeca: prevInfoMeca})
	 }).catch(error => console.log('Object fecth failed', error));
}else{
	this.setState({ infoMeca: []})
	this.forceUpdate();
}
}
cleanForm(){
		switch (this.props.selectedOption) {
        case 'Añadir_Mecanografia': {
						this.setState({statusBarraProceso: "completado", tituloEjercicioMecanografia: '', tituloFormatoMecanografia: '', fileToUpload: undefined});
						this.refs.ref_tituloEjercicioMecanografia.refs.tituloEjercicioMecanografia.value = '';
						this.refs.ref_tituloFormatoMecanografia.refs.tituloFormatoMecanografia.value = '';
        }break;

        case 'Corregir_Mecanografia': {
            	this.setState({slc_dni_del: ''});
            	this.refs.ref_slc_dni_del.refs.slc_dni_del.value='';
        }break;
        }
      }
myFiles(e){
	      let files = e.target.files;
	      var filesLoad = [];
	      for (var i = 0; i < files.length; i++) {
	              filesLoad.push(files[i]);
	            }
	    this.setState({fileToUpload: filesLoad});
}

onChangeInput(e){
      this.setState({[e.target.name]: e.target.value});

        if (this.props.selectedOption == 'Añadir_Mecanografia') {

		          if((this.state.tituloFormatoMecanografia.length > 0 && this.state.tituloFormatoMecanografia.length > 0)){
		              this.setState({statusBarraProceso: "enviar"});
		            }else{
		              this.setState({statusBarraProceso: "completar"});
		            }

          } else if (this.props.selectedOption == 'Corregir_Mecanografia'){

			              if((e.target.value.length > 0)){
			              this.setState({statusBarraProceso: "enviar"});
			              }else{
			                this.setState({statusBarraProceso: "completar"});
			              }

										if(e.target.name === 'oposicionSelecionada'){
													let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);
													if(oposicionObject != undefined){
															let data = { id_oposicion: oposicionObject.id_oposicion };
															fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
																method: 'POST',
																headers: {'Content-Type': 'application/json'},
																body: JSON.stringify(data)
															}).then(response =>  {
																	if (response.status >= 400) { throw new Error("Bad response from server");}
																return response.json();
															}).then(cls => this.setState({busquedaClasesSemanas: cls}))
															.catch(error => console.log('Object fecth failed', error))
													}else{
														this.setState({busquedaClasesSemanas: ''});
													}}

													if(e.target.name === 'claseSelecionada'){
														let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == this.state.oposicionSelecionada);
					                  let idOposicion = oposicionObject.id_oposicion;
					                  let claseObject = this.state.busquedaSemClasesAll.find(clase => clase.dia_semanal == e.target.value && clase.id_oposicion == idOposicion);
														if(isDefined(idOposicion) && isDefined(claseObject)){
															let data = { id_clase: claseObject.id_clase }
														 fetch("https://oposiciones-justicia.es/api/oficina/consultaMecaExpClase", {
															 method: 'POST',
															 headers: {'Content-Type': 'application/json'},
															 body: JSON.stringify(data)
														 }).then(response =>  {
																 if (response.status >= 400) { throw new Error("Bad response from server");}
															 return response.json();
														 }).then(cls => {
															 let prevInfoMeca = [];
															 for (var i = 0; i < cls.length; i++) {
															 	prevInfoMeca.push({
																	idExpendiente: cls[i].id_expediente,
																	nombreAlumno:""+this.state.busquedaAlumnos.find(alumno => alumno.id_usuario == cls[i].id_usuario).nombre+" "+this.state.busquedaAlumnos.find(alumno => alumno.id_usuario == cls[i].id_usuario).apellidos+"",
																	tituloEjercicio: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).titulo_ejercicio,
																	linkEjerMeca: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).link_ejercicio,
																	linkFormatoMeca: this.state.busquedaMeca.find(meca => meca.id_meca == cls[i].id_meca).link_formato,
																	linkEjerAlumno: cls[i].link_ejer_alumno
																})
															 }
															 this.setState({ infoMeca: prevInfoMeca})
														 }).catch(error => console.log('Object fecth failed', error))
														} else {
															this.setState({ infoMeca: []})
														}
													}
            }
}

  handleSubmitForm(e){
        switch (e.target.id) {
          case 'añadirMecanografia': {
            let { tituloEjercicioMecanografia, tituloFormatoMecanografia, fileToUpload} = this.state
            if(isDefined(tituloEjercicioMecanografia) && isDefined(tituloFormatoMecanografia) && fileToUpload.length > 0){
							if(fileToUpload.length > 1){
							var data = new FormData();
							for (var i = 0; i < fileToUpload.length; i++) {
											data.append('myFiles', fileToUpload[i]);
								}
							fetch("https://oposiciones-justicia.es/uploadmultiple", {
														method: 'POST',
														body: data
								}).then(response =>  {
												if (response.status >= 400) { throw new Error("Bad response from server");}
												return response.json();
								}).then(data => {
											let apuntes = [{}];
												for (var i = 0; i < data.length; i++) {
													let path = data[i].path.split('src/')[1];
													if(data[i].originalname.split('.')[1] == 'docx' || data[i].originalname.split('.')[1] == 'doc'){
																let nameFileWord = tituloEjercicioMecanografia;
																apuntes[0].tituloWord = nameFileWord;
																apuntes[0].pathWord = path;
														}else if(data[i].originalname.split('.')[1] == 'pdf'){
																let nameFileFormato = tituloFormatoMecanografia;
																apuntes[0].tituloFormato = nameFileFormato;
																apuntes[0].pathFormato = path;
														}
												}
												let data2 = { infoFileUpload: apuntes }
												return fetch("https://oposiciones-justicia.es/api/oficina/addEjercicioMecanografia", {
																							 method: 'POST',
																							 headers: {'Content-Type': 'application/json'},
																							 body: JSON.stringify(data2)
																			});
												}).then(response =>  {
															if (response.status >= 400) { throw new Error("Bad response from server");}
															return response.json();
												}).then(data => {
													if(data.affectedRows > 0){
														this.cleanForm();
													}}).catch(function(err) {console.log(err)});
								} else {
									alert("Debe introducir el archivo .doc = ejercicio mecanografia y el archivo .pdf = formato");
								}
            }else{
              swal("Complete todos los datos para procesar la solicitud",'',"warning");
            }
  			}break;
  		case 'corregirMecanografia': {
	    		let { slc_dni_del } = this.state
	    		if(isDefined(slc_dni_del)){

	      	}else{
	        	swal("Complete todos los datos para procesar la solicitud",'',"warning");
	      	}
  		}break;
      }
    }
componentDidMount(){
  fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
    .then(res => res.json())
      .then(clases => this.setState({
          busquedaSemClasesAll: clases
      })).catch(error => console.log('Object fecth failed', error));

  fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
    .then(res => res.json())
      .then(alumnos => this.setState({
          busquedaAlumnos: alumnos
      })).catch(error => console.log('Object fecth failed', error));

			fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
				.then(res => res.json())
					.then(meca => this.setState({
							busquedaMeca: meca
					}))
				.catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
        .then(oposiciones => this.setState({
            busquedaOpo: oposiciones
        })).catch(error => console.log('Object fecth failed', error));


};

  render() {
    const { selectedOption } = this.props;
    const { busquedaAlumnos, busquedaClasesSemanas, busquedaOpo, statusBarraProceso, infoMeca } = this.state
      switch (selectedOption) {
        case 'Añadir_Mecanografia': {
              return (
                <form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
									<h55><i className="fas fa-info"></i>Word como .Doc<i className="fas fa-arrow-down"></i></h55>
                  <Input type="text" name="tituloEjercicioMecanografia" placeholder="Titulo del Word" icon="fas fa-tags" ref="ref_tituloEjercicioMecanografia" onChange={this.onChangeInput} title="El nombre que tendra en la web ejercicio."/>
									<h55><i className="fas fa-info"></i>Formato como .Pdf<i className="fas fa-arrow-down"></i></h55>
									<Input type="text" name="tituloFormatoMecanografia" placeholder="Titulo del Formato" icon="fas fa-tags" ref="ref_tituloFormatoMecanografia" onChange={this.onChangeInput} />
									<InputFile texto="PDFs" ref="ref_myFiles" name="myFiles" icon1="far fa-file-pdf" onChange={this.myFiles}/>
                  <Submit id="añadirMecanografia" icon1="fas fa-upload" icon2="fas fa-database" texto="Añadir Ejercicio" onClick={this.handleSubmitForm}/>
                </form>
              );
        }
        break;
        case 'Corregir_Mecanografia': {
              return (
                <form className="formMeca">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
									<Input type="text" name="oposicionSelecionada" placeholder="Buscar Oposicion" icon="fas fa-search" datalist="busquedaOpo"ref="ref_oposicionSelecionada" onChange={this.onChangeInput}/>
                  <Input type="text" name="claseSelecionada" placeholder="Buscar Clase" icon="fas fa-search" datalist="busquedaClasesSemanas"ref="ref_claseSelecionada" onChange={this.onChangeInput}/>

									<CorrectorMeca infoMeca={infoMeca} corregido={this.corregido}/>

									<datalist id="busquedaClasesSemanas">{
                    busquedaClasesSemanas && busquedaClasesSemanas.map((cls, key) =>
                    <option key={key} value={cls.dia_semanal}></option>
                      )
                  }</datalist>
									<datalist id="busquedaOpo">{
                    busquedaOpo && busquedaOpo.map((opo, key) =>
                    <option key={key} value={opo.nombre}></option>
                      )
                  }</datalist>
                  </form>
              );
        }break;
        default: {
          return (
            <div style={{fontSize: '24px'}} >
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
              Selecciona una opción para continuar.
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
            </div>
          );
        }
      }
  }
}

export default OptionBar;

export default [
  {
    title: 'Oposiciones',
    url: '/Adm/Oposiciones',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-marker',
    subMenu: [
      {
        title: 'Añadir Oposición',
        url: 'Añadir_Opo',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar/Borrar Oposición',
        url: 'Borrar_Opo',
        fontawesome: 'fas fa-plus'
      }
    ]
  },
  // {
  //   title: 'Clases',
  //   url: '/Adm/Clases',
  //   visible: true,
  //   nivelAcesso: 99,
  //   fontawesome: 'fas fa-graduation-cap',
  //   subMenu: [
  //     {
  //       title: 'Añadir Clase',
  //       url: 'Añadir_Clase',
  //       fontawesome: 'fas fa-plus'
  //     },
  //     {
  //       title: 'Borrar Clase',
  //       url: 'Borrar_Clase',
  //       fontawesome: 'fas fa-trash-alt'
  //     }
  //   ]
  // },
  {
    title: 'Leyes',
    url: '/Adm/Leyes',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-gavel',
    subMenu: [
      {
        title: 'Añadir Ley',
        url: 'Añadir_Ley',
        fontawesome: 'fa-regular fa-plus'
      },
      {
        title: 'Editar/Borrar Ley',
        url: 'Borrar_Ley',
        fontawesome: 'fa-regular fa-pen-to-square'
      },
      {
        title: 'Añadir Artículos',
        url: 'Add_Art_Masivo',
        fontawesome: 'fa-regular fa-books-medical'
      },
      {
        title: 'Editar Arts.',
        url: 'Edt_Arts',
        fontawesome: 'fa-regular fa-money-check-pen'
      }
    ]
  },
  {
    title: 'Temas',
    url: '/Adm/Temas',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-archive',
    subMenu: [
      {
        title: 'Añadir Tema',
        url: 'Añadir_Tema',
        fontawesome: 'fa-regular fa-plus'
      },
      {
        title: 'Editar/Borrar Tema',
        url: 'Borrar_Tema',
        fontawesome: 'fa-regular fa-pen-to-square'
      }
    ]
  },
  
  {
    title: 'Videos',
    url: '/Adm/Videos',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-video',
    subMenu: [
      {
        title: 'Añadir Video',
        url: 'Añadir_Video',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar/Borrar Video',
        url: 'Editar_Video',
        fontawesome: 'fas fa-edit'
      }
    ]
  },
  {
    title: 'Examen Gestión',
    url: '/Adm/ExamGestion',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-edit',
    subMenu: [
      {
        title: 'Añadir Examen',
        url: 'Añadir_Examen',
        fontawesome: 'fas fa-plus'
      },
      {
        title: 'Editar/Borrar Examen',
        url: 'Editar_Examen',
        fontawesome: 'fas fa-edit'
      }
    ]
  },
  {
    title: 'Ver/Editar Alumno',
    url: '/Adm/ctlUsuarios',
    visible: true,
    nivelAcesso: 99,
    fontawesome: 'fas fa-sitemap',
    subMenu: [
      
    ]
  }
  
  
];

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import bcrypt from 'bcryptjs';

// Utils
import { isDefined } from '../../../../../lib/utils/is';
import { comprobarRespuestas } from '../../../../../lib/utils/generadorTestJusticia';
import { respuestaSbr } from '../../../../../lib/utils/cargarTestJusticia';
import '../css/aulaVirtualPreparador.css';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { loadTestJusticia, loadCasoPractico } from '../actions/testJusticiaAction';

//Componets
import Input from '../../../../../components/Inputs/Input';
import Input2 from '../../../../../components/Inputs/Input2';

import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Submit from '../../../../../components/Inputs/Submit';
import ClaseVirtualPreparador from '../../../../../components/ClaseVirtualPreparador';
import Alumnos from '../../../../../components/Alumnos';
import InfoProgramacionTemporal from '../../../../../components/InfoProgramacionTemporal';
import InfoAlumno from '../../../../../components/InfoAlumno';
import ExpedienteAlumno from '../../../../../components/ExpedienteAlumno';
import VerTestJusticia from '../../../../../components/test/VerTestJusticia';
import VerCasoPracticoJusticia from '../../../../../components/testCasoPractico/VerCasoPracticoJusticia';


class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    nombreUser: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      statusBarraProceso: 'completar',
      displayFecha: 'none',
      displayAlumnos: false,
      displayProgramacion: false,
      displayInfoAlumnos: false,
      displayInputAlumnos: 'none',
      infoAlumnoTemario: undefined,
      infoAlumnoTest: undefined,
      ejerciciosClase: undefined,
      temasClase: undefined,
      intervalosExtraClase: undefined,
      alumnoSelectedInfo: undefined,
      semanaPrg: undefined
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.handleClaseVirtualPreparador = this.handleClaseVirtualPreparador.bind(this);

  }
  cleanForm() {
    switch (this.props.selectedOption) {
      case 'Editar_Programacion': {
        this.setState({
          oposicionSelecionada: undefined,
          claseSelecionada: undefined,
          statusBarraProceso: "completado",
          infoProgramaDefault: undefined,
          infoProgramaDefaultTemas: undefined,
          infoProgramaDefaultTemasApuntes: undefined,
          infoProgramaDefaultTemasIntervalos: undefined
        });
        this.refs.ref_oposicionSelecionada.refs.oposicionSelecionada.value = '';
        this.refs.ref_claseSelecionada.refs.claseSelecionada.value = '';
      } break;
    }
  }

  async onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Entrar_En_Clase') {

      let claseSeleccionada = this.refs.ref_claseSeleccionada.refs.claseSeleccionada.value;
      let fechaSeleccionada = this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value;

      if (e.target.name === 'claseSeleccionada') {
        var e = e
        let claseObject = await this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {
          try {
            let data1 = { idClase: claseObject.idClase }
            await this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });

            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/busquedaClasesFecha", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;
            if (cls1 != undefined) {
              await this.setState({ busquedaClasesFecha: cls1 });
              //this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = moment().format('MM-YY');
            }

            var busquedaClasesFecha2 = await this.state.busquedaClasesFecha
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].idPrograma }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.idPrograma == cls3[z].idPrg && c.semanaClase == busquedaClasesFecha2[k].semanaClase);
                if (locaBloque != -1) {
                  busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema
                  })
                }
              }
            }

            await this.setState({ busquedaClasesFecha: busquedaClasesFecha2, displayFecha: 'block' });

          } catch (e) { console.log(e) }
        } else {
          this.setState({ semanaPrg: undefined, busquedaClasesFecha: undefined, displayFecha: 'none', ejerciciosClase: undefined, temasClase: undefined, intervalosExtraClase: undefined })
          this.refs.ref_fechaSeleccionada.refs.fechaSeleccionada.value = '';
        }
      }

      if (e.target.name === 'fechaSeleccionada') {
        let fechaClaseObject = this.state.busquedaClasesFecha.find(prg => moment(prg.fechaClase).format('DD-MM-YY HH:mm') == e.target.value);
        if (fechaClaseObject != undefined) {

          try {
            // SACAR TODAA LA INFORMACION NUEVA! TEST JUSTICIA CASO PRACTICO NOTA TESTWORD EXAMENGESTION
            let data1 = { fechaClase: moment(fechaClaseObject.fechaClase).format('YYYY-MM-DD HH:mm'), idPrg: fechaClaseObject.idPrograma, idClase: this.state.idClasePreparador }
            let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/claseSelecionadaVirtual", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              throw Error(response1.statusText);
            }
            let json1 = await response1.json();
            let cls1 = await json1;

            if (cls1 != undefined) {
              let inforClase = [{
                idPrg: cls1[0].idPrg,
                numeroSemanaClase: cls1[0].numeroSemanaClase,
                numeroSemanaProgramacion: cls1[0].numeroSemanaProgramacion,
                fechaClase: cls1[0].fechaClase,
                testJusticia: cls1[0].testJusticia,
                idTestJusticia: cls1[0].idTestJusticia,
                testWord: cls1[0].testWord,
                idTestWord: cls1[0].idTestWord,
                casoPractico: cls1[0].casoPractico,
                idCasoPractico: cls1[0].idCasoPractico,
                bloqueTemas: [],
                videosClase: []
              }]
              await this.setState({ ejerciciosClase: inforClase });

              let inforClase2 = await this.state.ejerciciosClase
              let data22345 = { idPrg: inforClase2[0].idPrg }
              let response22345 = await fetch("https://oposiciones-justicia.es/api/usuarios/videosPrg", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data22345)
              });
              if (!response22345.ok) {
                throw Error(response22345.statusText);
              }
              let json22345 = await response22345.json();
              let cls22345 = await json22345;
              if (cls22345 != undefined && cls22345.length > 0) {
                for (var w = 0; w < cls22345.length; w++) {
                  inforClase2[0].videosClase.push({
                    titulo: cls22345[w].titulo,
                    link: cls22345[w].link
                  })
                }

              }
              await this.setState({ ejerciciosClase: inforClase2 });


            } else {
              await this.setState({ ejerciciosClase: undefined });
            }
            if (this.state.ejerciciosClase != undefined) {
              // sacar bloqueTemas -->
              let data2 = { idPrg: this.state.ejerciciosClase[0].idPrg, idClase: this.state.idClasePreparador }
              let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/bloqueSemPrgPre", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              let ejerciciosClase = this.state.ejerciciosClase

              for (var j = 0; j < cls2.length; j++) {
                ejerciciosClase[0].bloqueTemas.push({
                  idBloque: cls2[j].id_bloque,
                  idTestExtra: cls2[j].id_test_justicia,
                  testJusticia: cls2[j].nombre_test,
                  idCasoExtra: cls2[j].id_caso_practico_extra,
                  casoPractico: cls2[j].nombre_cp,
                  tipoBloque: cls2[j].tipoBloque,
                  alumnos: [],
                  temas: [],
                  idWordTestPDF: cls2[j].idWordTestPDF,
                  idExamenGestion: cls2[j].idExamenGestion,
                  testsJusticia: [],
                  casosPractico: []
                })
              }


              await this.setState({ ejerciciosClase: ejerciciosClase });

              let ejerciciosClase2 = this.state.ejerciciosClase

              for (var j = 0; j < ejerciciosClase2[0].bloqueTemas.length; j++) {

                let data33456 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
                let responsedata33456 = await fetch("https://oposiciones-justicia.es/api/preparador/testJusticiaBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
                });
                if (!responsedata33456.ok) {
                  throw Error(responsedata33456.statusText);
                }
                let jsondata33456 = await responsedata33456.json();
                let clsdata33456 = await jsondata33456;
                if (clsdata33456 && clsdata33456 != undefined) {
                  for (var v = 0; v < clsdata33456.length; v++) {
                    ejerciciosClase2[0].bloqueTemas[j].testsJusticia.push({ idTestJusticia: clsdata33456[v].id_test })
                  }
                }

                let responsedata308 = await fetch("https://oposiciones-justicia.es/api/preparador/casoPracticoBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
                });
                if (!responsedata308.ok) {
                  throw Error(responsedata308.statusText);
                }
                let jsondata308 = await responsedata308.json();
                let clsdata308 = await jsondata308;
                if (clsdata308 && clsdata308 != undefined) {
                  for (var v = 0; v < clsdata308.length; v++) {
                    ejerciciosClase2[0].bloqueTemas[j].casosPractico.push({ idCasoPractico: clsdata308[v].id_test })
                  }
                }



                let data33 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
                let response33 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloque", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
                });
                if (!response33.ok) {
                  throw Error(response33.statusText);
                }
                let json33 = await response33.json();
                let cls33 = await json33;
                //alumnos
                for (var l = 0; l < cls33.length; l++) {
                  if (cls33[l].activado == 1) {
                    ejerciciosClase2[0].bloqueTemas[j].alumnos.push({
                      idAlumno: cls33[l].idAlumno,
                      nombreAlumno: cls33[l].nombreAlumno,
                      apellidosAlumno: cls33[l].apellidosAlumno,
                      linkFotoPerfil: cls33[l].linkFotoPerfil,
                      testJusticiaRealizado: undefined,
                      casoPracticoRealizado: undefined,
                      testJusticiaResultados: [],
                      casoPracticoResultados: []
                    })
                  }
                }
                await this.setState({ ejerciciosClase: ejerciciosClase2 });

                let data34 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idClase: this.state.idClasePreparador, idPrg: fechaClaseObject.idPrograma }
                let response34 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloqueTestJusticia", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data34)
                });
                if (!response34.ok) {
                  throw Error(response34.statusText);
                }
                let json34 = await response34.json();
                let cls34 = await json34;
                //alumnos
                for (var l = 0; l < ejerciciosClase2[0].bloqueTemas[j].alumnos.length; l++) {

                  for (var d = 0; d < cls34.length; d++) {
                    if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaRealizado == undefined) {
                      let testJusticiaAlumno = cls34.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == ejerciciosClase2[0].bloqueTemas[j].idTestExtra).sort((a, b) => a.idExp - b.idExp)
                      if (testJusticiaAlumno && testJusticiaAlumno.length > 0) {
                        ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaRealizado = testJusticiaAlumno[0]
                      }
                    }

                    if (ejerciciosClase2[0].bloqueTemas[j].testsJusticia.length > 0) {
                      for (var q = 0; q < ejerciciosClase2[0].bloqueTemas[j].testsJusticia.length; q++) {
                        let idTest = ejerciciosClase2[0].bloqueTemas[j].testsJusticia[q].idTestJusticia

                        //sacamos el ultimo hecho por cada test
                        let historialTestbloqueAlumno = cls34.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == idTest).sort((a, b) => a.idExp - b.idExp)

                        if (historialTestbloqueAlumno && historialTestbloqueAlumno.length > 0 && ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaResultados.filter(y => y.idTest == idTest).length == 0) {
                          ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaResultados.push(historialTestbloqueAlumno[0])
                        }
                      }
                    }

                  }


                }
                await this.setState({ ejerciciosClase: ejerciciosClase2 });


                let data35 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idClase: this.state.idClasePreparador, idPrg: fechaClaseObject.idPrograma }
                let response35 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloqueCasoPractico", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data35)
                });
                if (!response35.ok) {
                  throw Error(response35.statusText);
                }
                let json35 = await response35.json();
                let cls35 = await json35;
                //alumnos
                for (var l = 0; l < ejerciciosClase2[0].bloqueTemas[j].alumnos.length; l++) {


                  for (var d = 0; d < cls35.length; d++) {
                    if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoRealizado == undefined) {
                      let casoPAlumno = cls35.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == ejerciciosClase2[0].bloqueTemas[j].idCasoExtra).sort((a, b) => a.idExp - b.idExp)
                      if (casoPAlumno && casoPAlumno.length > 0) {
                        ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoRealizado = casoPAlumno[0]
                      }
                    }

                    if (ejerciciosClase2[0].bloqueTemas[j].casosPractico.length > 0) {
                      for (var q = 0; q < ejerciciosClase2[0].bloqueTemas[j].casosPractico.length; q++) {
                        let idTest = ejerciciosClase2[0].bloqueTemas[j].casosPractico[q].idCasoPractico

                        //sacamos el ultimo hecho por cada test
                        let historialTestbloqueAlumno = cls34.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == idTest).sort((a, b) => a.idExp - b.idExp)
                        if (historialTestbloqueAlumno && historialTestbloqueAlumno.length > 0 && ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoResultados.filter(y => y.idTest == idTest).length == 0) {
                          ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoResultados.push(historialTestbloqueAlumno[0])
                        }
                      }
                    }
                  }




                }
                await this.setState({ ejerciciosClase: ejerciciosClase2 });


                let data3 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idPrograma: ejerciciosClase2[0].idPrg }

                let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/temasDeBloqu1e", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                for (var z = 0; z < cls3.length; z++) {
                  ejerciciosClase2[0].bloqueTemas[j].temas.push({
                    idTemaPrg: cls3[z].idTemaPrg,
                    idTema: cls3[z].idTema,
                    nombreTema: cls3[z].nombreTema,
                    intervalosLeyes: [],
                    apuntes: [],
                    nota: cls3[z].nota
                  })

                  let data4 = { idTemaPrg: cls3[z].idTemaPrg }
                  let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/intervalosTema", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                  });
                  if (!response4.ok) {
                    throw Error(response4.statusText);
                  }
                  let json4 = await response4.json();
                  let cls4 = await json4;

                  for (var q = 0; q < cls4.length; q++) {
                    let busqueda = -1
                    for (var h = 0; h < ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.length; h++) {
                      busqueda = ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.findIndex(x => x.idLey == cls4[q].idLey)

                    }

                    let reBusqueda = await busqueda
                    if (reBusqueda != -1) {
                      ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes[busqueda].intervalosArticulos.push({
                        artInicio: cls4[q].artInicio,
                        artFin: cls4[q].artFin
                      })
                    } else {
                      if (ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes == undefined) {
                        ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes = [];
                      }
                      ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.push({
                        idLey: cls4[q].idLey,
                        siglasLey: this.state.busquedaLeyes.find(l => l.id_ley == cls4[q].idLey).siglas_ley,
                        nombreLey: this.state.busquedaLeyes.find(l => l.id_ley == cls4[q].idLey).nombre_ley,
                        intervalosArticulos: [{
                          artInicio: cls4[q].artInicio,
                          artFin: cls4[q].artFin
                        }]
                      })
                    }

                  }

                  // SACAR LINK APAUNTE TAMBIEN Y NOMMBRE APUNTE!!
                  let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/apuntesTema", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                  });
                  if (!response5.ok) {
                    throw Error(response5.statusText);
                  }
                  let json5 = await response5.json();
                  let cls5 = await json5;
                  for (var u = 0; u < cls5.length; u++) {
                    ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].apuntes.push({
                      idApunte: cls5[u].idApunte,
                      nombreApunte: cls5[u].nombreApunte,
                      linkPdf: cls5[u].linkPdf
                    })
                  }

                }


              }
              await this.setState({ ejerciciosClase: ejerciciosClase2 })



            }

            await this.setState({ semanaPrg: this.state.ejerciciosClase })
          } catch (e) { console.log(e) }


        } else {
          this.setState({ semanaPrg: undefined, ejerciciosClase: undefined, temasClase: undefined, intervalosExtraClase: undefined })
        }
      }
    } else if (this.props.selectedOption == 'Ver_Clase') {
      let claseSeleccionada = this.refs.ref_claseSeleccionada.refs.claseSeleccionada.value;

      if (e.target.name === 'claseSeleccionada') {
        let claseObject = this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {
          let data = { idClase: claseObject.idClase }
          this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });

          fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            if (cls != undefined) {
              let alumnosClase = [];
              for (var i = 0; i < cls.length; i++) {
                alumnosClase.push({
                  idAlumno: cls[i].idAlumno,
                  nombreAlumno: cls[i].nombreAlumno,
                  apellidosAlumno: cls[i].apellidosAlumno,
                  linkFotoPerfil: cls[i].linkFotoPerfil
                })
              }
              this.setState({ alumnosClase: alumnosClase, displayAlumnos: true })

            }
          }).catch(function (err) { console.log(err) });
        } else {
          this.setState({ alumnosClase: undefined, displayAlumnos: false })

        }
      }
    } else if (this.props.selectedOption == 'Ver_Programacion') {
      let claseSeleccionada = this.refs.ref_claseSeleccionada.refs.claseSeleccionada.value;

      if (e.target.name === 'claseSeleccionada') {
        let claseObject = this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {
          let data = { idClase: claseObject.idClase }
          this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });

          fetch("https://oposiciones-justicia.es/api/oficina/programacionClase", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            let prevPRGClase = [];
            for (var i = 0; i < cls.length; i++) {
              prevPRGClase.push({
                numeroSemanaClase: cls[i].numeroSemanaClase,
                numeroSemanaProgramacion: cls[i].numeroSemanaProgramacion,
                fechaClase: moment(cls[i].fechaClase).format("YY-MM-DD HH:mm"),
                testJusticia: cls[i].testJusticia,
                testWord: cls[i].testWord,
                casoPractico: cls[i].casoPractico
              })
            }
            this.setState({ programacionClase: prevPRGClase, displayProgramacion: true });

          }).catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ programacionClase: undefined, displayProgramacion: false })
        }
      }
    } else if (this.props.selectedOption == 'Info_Alumnos') {
      let claseSeleccionada = this.refs.ref_claseSeleccionada.refs.claseSeleccionada.value;

      if (e.target.name === 'claseSeleccionada') {
        this.setState({ idAlumnoSelected: undefined })
        let claseObject = this.state.busquedaClasesPreparador.find(prg => prg.diaSemanal == e.target.value);
        if (claseObject != undefined) {
          let data = { idClase: claseObject.idClase }
          this.setState({ idClasePreparador: claseObject.idClase, idOposicion: claseObject.idOposicion });
          fetch("https://oposiciones-justicia.es/api/preparador/alumnosClase", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            if (cls != undefined) {
              let alumnosClase = [];
              for (var i = 0; i < cls.length; i++) {
                alumnosClase.push({
                  idAlumno: cls[i].idAlumno,
                  nombreAlumno: cls[i].nombreAlumno,
                  apellidosAlumno: cls[i].apellidosAlumno,
                  linkFotoPerfil: cls[i].linkFotoPerfil
                })
              }
              this.setState({ alumnosClase: alumnosClase, displayInputAlumnos: 'flex' })
            }
          }).catch(function (err) { console.log(err) });
        } else {
          this.setState({ alumnosClase: undefined, displayInputAlumnos: 'none' })
        }
      } else if (e.target.name === 'alumnoSeleccionado') {
        let idAlumnoSelected = e.target.value
        let posicionAlumno = this.state.alumnosClase.findIndex(alumno => alumno.idAlumno == idAlumnoSelected)

        if (posicionAlumno != -1 && isDefined(this.state.idClasePreparador)) {
          let alumnoSelectedInfo = [];
          alumnoSelectedInfo.push({
            idAlumno: this.state.alumnosClase[posicionAlumno].idAlumno,
            nombreAlumno: this.state.alumnosClase[posicionAlumno].nombreAlumno,
            apellidosAlumno: this.state.alumnosClase[posicionAlumno].apellidosAlumno,
            linkFotoPerfil: this.state.alumnosClase[posicionAlumno].linkFotoPerfil
          })
          await this.setState({ alumnoSelectedInfo: alumnoSelectedInfo, idAlumnoSelected: idAlumnoSelected, idClase: this.state.idClasePreparador, displayInfoAlumnos: true })
        } else {
          this.setState({ displayInfoAlumnos: false, alumnoSelectedInfo: undefined, idAlumnoSelected: undefined })
        }

      }

    }

  }
  handleSubmitForm(e) {
    switch (e.target.id) {
      case 'adelantar_programacion': {
        let { semanasAdelantarPosponer } = this.state
        if (isDefined(semanasAdelantarPosponer)) {
          var data = { semanasAdelantarPosponer: semanasAdelantarPosponer, fechaPosponerClase: moment(fechaPosponerClase).format('DD-MM-YY'), claseSelectedPosponer: claseSelectedPosponer }
          fetch("https://oposiciones-justicia.es/api/preparador/ClasePosponer", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {
              this.cleanForm();
            }
          }).catch(function (err) { console.log(err) });
        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;

    }
  }
  async handleClaseVirtualPreparador(e, testNewRes) {
    if (e == 'verTestJusticia') {
      if (testNewRes != undefined) {
        let test = await comprobarRespuestas(testNewRes)
        for (var i = 0; i < test.length; i++) {
          for (var d = 0; d < test[i].respuestas.length; d++) {
            let hmtml = await respuestaSbr(test[i].respuestas[d].respuesta, test[i].respuestas[d].subrayado)
            test[i].respuestas[d].respuestaHtml = hmtml
          }
        }
        await this.props.loadTestJusticia(test)
        await this.props.onClick("Ver_Test_Preparador")
      }
    } else if (e == 'verCasoPracticoJusticia') {
      if (testNewRes != undefined) {
        let test = await comprobarRespuestas(testNewRes)
        for (var i = 0; i < test.length; i++) {
          for (var d = 0; d < test[i].respuestas.length; d++) {
            let hmtml = await respuestaSbr(test[i].respuestas[d].respuesta, test[i].respuestas[d].subrayado)
            test[i].respuestas[d].respuestaHtml = hmtml
          }
        }
        await this.props.loadCasoPractico(test)
        await this.props.onClick("Ver_CasoPractico_Preparador")
      }
    }
  }
  async componentDidMount() {
    try {
      if (this.props.tipoUser < 99) {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaClasesPreparador", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }
        }

      } else {
        let data1 = { nombreUsuario: this.props.nombreUser }
        let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/idPreparador", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
          let data2 = { idPreparador: cls1[0].id_usuario }
          let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/consultaTodasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
          });
          if (!response2.ok) {
            throw Error(response2.statusText);
          }
          let json2 = await response2.json();
          let cls2 = await json2;
          if (cls2 != undefined && cls2.length > 0) {
            await this.setState({ busquedaClasesPreparador: cls2 });
          } else {
            await this.setState({ busquedaClasesPreparador: undefined });
          }
        }
      }

    } catch (e) {
      console.log(e)
    }
    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))



  };

  render() {
    const { selectedOption } = this.props;
    const { busquedaClasesPreparador, busquedaClasesFecha, ejerciciosClase, temasClase, intervalosExtraClase, displayFecha, alumnosClase, displayAlumnos, programacionClase, displayProgramacion, displayInputAlumnos, displayInfoAlumnos, idAlumnoSelected, alumnoSelectedInfo, idClase, busquedaLeyes, semanaPrg } = this.state;

    switch (selectedOption) {
      case 'Entrar_En_Clase': {
        return (
          <form className="formMeca" autoComplete="off">
            <Input type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador" ref="ref_claseSeleccionada" onChange={this.onChangeInput} />
            <Input display={displayFecha} type="text" name="fechaSeleccionada" placeholder="Seleccionar Semana" icon="fas fa-calendar-alt" datalist="busquedaClasesFecha" ref="ref_fechaSeleccionada" onChange={this.onChangeInput} />

            <ClaseVirtualPreparador semanaPrg={semanaPrg} onClick={this.handleClaseVirtualPreparador} />

            <datalist id="busquedaClasesFecha">{
              busquedaClasesFecha && busquedaClasesFecha.map((cls, key) =>
                <option key={key} value={moment(cls.fechaClase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.semanaClase}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + '.') : (a.tituloTema + ', ')
                )}</option>
              )
            }</datalist>
            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>
          </form>
        );
      } break;
      case 'Ver_Test_Preparador': {
        return (
          <form className="formMeca" autoComplete="off">

            <VerTestJusticia test={this.props.testJusticiaLoad} />

          </form>
        );
      } break;
      case 'Ver_CasoPractico_Preparador': {
        return (
          <form className="formMeca" autoComplete="off">

            <VerCasoPracticoJusticia test={this.props.casoPracticoLoad} />

          </form>
        );
      } break;
      case 'Ver_Clase': {
        return (
          <form className="formMeca" autoComplete="off">
            <Input type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador" ref="ref_claseSeleccionada" onChange={this.onChangeInput} />

            {displayAlumnos ? (
              <Alumnos id={'vc0'} alumnosClase={alumnosClase} OnBorrar={"0"} />
            ) : ('')}

            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>
          </form>
        );
      } break;
      case 'Ver_Programacion': {
        return (
          <form className="formMeca">
            <Input type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador" ref="ref_claseSeleccionada" onChange={this.onChangeInput} />

            {displayProgramacion ? (
              <InfoProgramacionTemporal programacionClase={programacionClase} />
            ) : ('')}

            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>
          </form>
        );
      } break;
      case 'Info_Alumnos': {

        return (
          <form className="formMeca">

            <Input2 type="text" name="claseSeleccionada" placeholder="Seleccionar Clase" icon="fas fa-search" datalist="busquedaClasesPreparador" ref="ref_claseSeleccionada" onChange={this.onChangeInput} />
            <Input2 type="text" name="alumnoSeleccionado" placeholder="Seleccionar Alumno" icon="fas fa-search" datalist="alumnosCargados" ref="ref_alumnoSeleccionado" onChange={this.onChangeInput} display={displayInputAlumnos} />

            {displayInfoAlumnos ? (
              <div className="displayInfoAlumnos">
                <div className="alumnoTemaAVP" id={"alumno-"}>
                  <div className="aTfoto" id={"alumno-"}>
                    <img src={`../img/${alumnoSelectedInfo[0].linkFotoPerfil}`} id={"alumno-"} className="imgATFotoAVP" />
                  </div>
                  <div className="aTName" id={"alumno-"}>
                    <p id={"alumno-"} >{alumnoSelectedInfo[0].nombreAlumno + ' ' + alumnoSelectedInfo[0].apellidosAlumno}</p>
                  </div>
                </div>

                <InfoAlumno idAlumno={idAlumnoSelected} idClase={idClase} />

                <ExpedienteAlumno idAlumno={idAlumnoSelected} idClase={idClase} />

              </div>
            ) : null}



            <datalist id="busquedaClasesPreparador">{
              busquedaClasesPreparador && busquedaClasesPreparador.map((cls, key) =>
                <option key={key} value={cls.diaSemanal}></option>
              )
            }</datalist>
            <datalist id="alumnosCargados">{
              alumnosClase && alumnosClase.map((cls, key) =>
                <option key={key} value={cls.idAlumno}> {cls.nombreAlumno + ' ' + cls.apellidosAlumno} </option>
              )
            }</datalist>
          </form>
        );

      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    testJusticiaLoad: state.testJusticia.testJusticiaLoad,
    casoPracticoLoad: state.testJusticia.casoPracticoLoad
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadTestJusticia,
    loadCasoPractico
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionBar);

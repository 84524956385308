// Dependencies
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../../../../lib/utils/is'
//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso'
import Input from '../../../../../components/Inputs/Input'
import Input2 from '../../../../../components/Inputs/Input2'
import Submit from '../../../../../components/Inputs/Submit'
import Boton from '../../../../../components/Inputs/Boton'

import TemaSeleted from '../../../../../components/TemaSeleted'

const OptionBar = (props) => {

  const [numTema, setNumTema] = useState('')
  const [categoriaTema, setCategoriaTema] = useState('')
  const [tituloTema, setTituloTema] = useState('')
  const [nombreTema, setNombreTema] = useState('')

  const [busquedaLeyes, setBusquedaLeyes] = useState(null)
  const [busquedaOpo, setBusquedaOpo] = useState(null)
  const [categoriasJusticia, setCategoriasJusticia] = useState(null)

  const [oposicionSlc, setOposicionSlc] = useState(null)

  const [busquedaTemas, setBusquedaTemas] = useState(null)
  const [temaSlc, setTemaSlc] = useState(null)

  const refInput1 = useRef()

  useEffect(() => {
    oposicionSlc != null ? refInput1.current.refs.oposicionTema.value = ''  : null
    setOposicionSlc(null)
    setBusquedaTemas(null)
    setTemaSlc(null)
    setNumTema('')
    setCategoriaTema('')
    setTituloTema('')
    setNombreTema('')
    

    // didMont
    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => setBusquedaLeyes(leyes))
      .catch(error => console.log('Obejet fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => setBusquedaOpo(oposiciones))
      .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
      .then(res => res.json())
      .then(cls => setCategoriasJusticia(cls))

    // willunmount hacemos 
    return () => {
      // console.log('Cleanup')
    }
  }, [props.selectedOption]) // [] didUpdate , si pones [nombre_ley] actualiza

  const onChangeInput = (e) => {

    if (e.target.id == 'oposicionTema') {

      if (props.selectedOption == 'Añadir_Tema') {
        let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === e.target.value)
        if (oposicionObject != undefined) {
          setOposicionSlc(oposicionObject)
        } else {
          setOposicionSlc(null)
        }
      } else if (props.selectedOption == 'Borrar_Tema') {

        let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === e.target.value)
        if (oposicionObject != undefined) {
          setOposicionSlc(oposicionObject)
          // buscar temas de oposicion
          // setBusquedaTemas()
          let data = { id_oposicion: oposicionObject.id_oposicion }
          fetch("https://oposiciones-justicia.es/api/temas/consultaTemasOpo", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
            .then(temas => {
              setBusquedaTemas(temas)
            }).catch(error => console.log('Object fecth failed', error))

        } else {
          setOposicionSlc(null)
        }

      }

    } else if (e.target.id == 'numTema') {
      setNumTema(e.target.value)
    } else if (e.target.id == 'categoriaTema') {
      let value = e.target.value

      let busqueda = categoriasJusticia.find(b => b.nombre == value)
      if (busqueda != undefined) {
        setCategoriaTema(busqueda)
      } else {
        setCategoriaTema(null)
      }

    } else if (e.target.id == 'tituloTema') {
      setTituloTema(e.target.value)
    } else if (e.target.id == 'nombreTema') {
      setNombreTema(e.target.value)
    } else if (e.target.id == 'oposicionTemas') {
      // busquedaTemas --> t.tituloTema
      let value = e.target.value

      let bus = busquedaTemas.find(t => t.titulo_tema == value)

      if (bus != undefined) {
        setTemaSlc(bus)
      } else {
        setTemaSlc(null)
      }
    }

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == 'añadirTema') {

      if (numTema != null && numTema != '' && categoriaTema != null && categoriaTema != '' && tituloTema != null && tituloTema != '' && nombreTema != null && nombreTema != '' && oposicionSlc != null && oposicionSlc != '') {
        let data = {
          idOposicion: oposicionSlc.id_oposicion,
          tituloTema: tituloTema,
          nombreTema: nombreTema,
          idCategoria: categoriaTema.id_categoria,
          numeroTema: numTema
        }
        let response = await fetch('https://oposiciones-justicia.es/api/temas/addTemaN', {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        if (cls.affectedRows > 0) {
          swal('Tema insertado', 'Cambie a "Editar/Borrar Tema" para insertar los apuntes, videos y intervalos de leyes. ')
          refInput1.current.refs.oposicionTema.value = ''
          setOposicionSlc(null)
          setNumTema('')
          setCategoriaTema(null)
          setTituloTema('')
          setNombreTema('')


        } else {
          swal('Error', 'No se completo la creación del tema vuelva a intentarlo', 'info')
        }
      } else {
        swal('Faltan datos', 'Rellene todos los datos para añadir un tema', 'info')
      }

    } else if (e.target.id == 'borrarTema') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, el tema será elimino.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            let data3 = { id_tema: temaSlc.id_tema };

            fetch("https://oposiciones-justicia.es/api/temas/deleteTema", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data3)
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                swal({
                  title: 'Tema eliminado',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1500,
                }).then(() => {
                  window.location.reload()
                })
              }
            }).catch(function (err) { console.log(err) });
          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });

    }
  }



  const { selectedOption } = props

  switch (selectedOption) {
    case 'Añadir_Tema': {
      return (

        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Esta creando un tema nuevo </h3>
          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput1} datalist='busquedaOpo' autoComplete={'off'} id={'oposicionTema'} type='text' name={'oposicionTema'} placeholder='Oposición tema' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Oposición</p>
          </div>

          {
            oposicionSlc != null ? (
              <div className="ColCenterStart">

                <div className='RowCenterCenter'>
                  <div className="celdaInputDAPFBN" style={{ maxWidth: '170px', margin: '10px' }}>
                    <Input2
                      autoComplete={"off"} id={"numTema"} type="text" name={"numTema"} placeholder="Numero Tema" icon="fa-light fa-list-ol" onChange={onChangeInput} />
                    <p>Nº Tema</p>

                  </div>
                  <div className="celdaInputDAPFBN" style={{ maxWidth: '170px', margin: '10px' }}>
                    <Input2
                      datalist="categoriasJusticia" autoComplete={"off"} id={"categoriaTema"} type="text" name={"categoriaTema"} placeholder="Categoria" icon="fa-light fa-group-arrows-rotate" onChange={onChangeInput} />
                    <p>Categoría</p>
                  </div>
                </div>

                <div className='celdaInputDAPFBN'>
                  <Input2 autoComplete={'off'} id={'tituloTema'} type='text' name={'tituloTema'} placeholder='Título tema' icon='fa-light fa-file-signature' onChange={onChangeInput} />
                  <p>Título</p>
                </div>
                <div className="celdaInputDAPFBN">
                  <Input2
                    autoComplete={"off"} id={"nombreTema"} type="text" name={"nombreTema"} placeholder="Nombre tema" icon="fa-light fa-file-signature" onChange={onChangeInput} />
                  <p>Nombre</p>

                </div>
                <Boton id='añadirTema' onClick={handleSubmitForm} icon1='' icon2='' texto='Añadir tema' />
              </div>
            ) : null
          }
          <datalist id="busquedaOpo">{
            busquedaOpo && busquedaOpo.map((oposicion, key) =>
              <option key={key} value={oposicion.nombre}></option>
            )
          }</datalist>

          <datalist id="categoriasJusticia">{
            categoriasJusticia && categoriasJusticia.map((opo, key) =>
              <option key={key} value={opo.nombre}>
                Aux: {opo.temasAuxilio}; Tra: {opo.temasTramitacion}; Ges: {opo.temasGestion}; Ges PI: {opo.temasGestionPI};
              </option>
            )
          }</datalist>



        </div>
      )
    }
      break
    case 'Borrar_Tema': {
      return (
        <div className="ColCenterStart" style={{ color: 'black' }}>
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Esta editando un tema </h3>

          <div className='celdaInputDAPFBN'>
            <Input2 ref={refInput1} datalist='busquedaOpo' autoComplete={'off'} id={'oposicionTema'} type='text' name={'oposicionTema'} placeholder='Oposición tema' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Oposición</p>
          </div>
          {oposicionSlc != null ? (
            <>
              <div className='celdaInputDAPFBN' style={{ marginBottom: '25px' }}>
                <Input2 datalist='busquedaTemasOposicion' autoComplete={'off'} id={'oposicionTemas'} type='text' name={'oposicionTemas'} placeholder='Tema' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
                <p>Tema</p>
              </div>
              {temaSlc != null ? (
                <>
                  <TemaSeleted idTema={temaSlc.id_tema} />
                  <Boton id='borrarTema' onClick={handleSubmitForm} icon1='' icon2='' texto='Borrar tema' />
                </>
              ) : null
              }
            </>
          ) : null
          }

          <datalist id="busquedaOpo">{
            busquedaOpo && busquedaOpo.map((oposicion, key) =>
              <option key={key} value={oposicion.nombre}></option>
            )
          }</datalist>

          <datalist id='busquedaTemasOposicion'>{
            busquedaTemas && busquedaTemas.map((tema, key100) =>
              <option key={key100} value={tema.titulo_tema}>{
                '(' + tema.nombre_tema + ')' +
                ' Id: ' + tema.id_tema
              }</option>
            )
          }</datalist>
        </div>
      )
    } break
    default: {
      return (
        <div className="selectOptionToLoad">
          <i className="fa-thin fa-face-monocle"></i>
          <h3> Seleccione una opción para continuar </h3>
        </div>
      )
    }
  }

}

OptionBar.propTypes = {
  selectedOption: PropTypes.string.isRequired
}



export default OptionBar
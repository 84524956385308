// Dependencies
import bcrypt from 'bcryptjs';
import moment from 'moment';
// Utils
import { getNewState } from '../lib/utils/frontend';

export default function userReducer(state = {}, action) {

  switch (action.type) {

    // LOAD_CATEGORIA_ESC
    // LOAD_TEMA_ESC
    //LOAD_SEMANA_PRG 
    // 
    case 'GEN_TEST_ADD_INTERS': {
      let newOpo = {
        nombre: null,
        id: null,
        numPreg: '',
        interval: []
      }

      let newState = state;

      newState.genTestInfo.intervalosTestG.push(newOpo);



      return getNewState(state, newState);

    }
    case 'GEN_TEST_DEL_INTERS': {


      let newState = state;
      if (action.tipo == 'art') {
        newState.genTestInfo.intervalosTestG.splice(action.value, 1)
      }


      return getNewState(state, newState);

    }
    case 'GEN_TEST_EDT_INTER': {


      let newState = state;
      if (action.tipo == 'art') {

        if (action.tipoDatoInt == 'ley') {
          if (action.valueSeleted != null) {
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].siglasLey = action.valueSeleted.siglas_ley
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].nombreLey = action.valueSeleted.nombre_ley
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].idLey = action.valueSeleted.id_ley
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles = action.preguntasBusqueda
          } else {

            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].siglasLey = action.valueInput
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].nombreLey = null
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].idLey = null
            newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles = action.preguntasBusqueda
          }
        } else if (action.tipoDatoInt == 'artIni') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].artIni = action.valueInput
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'artFin') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].artFin = action.valueInput
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'numPreg') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].numPreg = action.valueInput
        } else if (action.tipoDatoInt == 'bloqueado') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].bloqueado = action.valueInput
        } else if (action.tipoDatoInt == 'unico') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].unico = action.valueInput
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'incluida') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles[action.valueSeleted].incluida = action.valueInput
        } else if (action.tipoDatoInt == 'excluida') {
          newState.genTestInfo.intervalosTestG[action.value].interval[action.idKey].preguntasDisponibles[action.valueSeleted].excluida = action.valueInput
        }


      } else if (action.tipo == 'tema') {


        if (action.tipoDatoInt == 'ley') {
          if (action.valueSeleted != null) {
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].siglasLey = action.valueSeleted.siglas_ley
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].nombreLey = action.valueSeleted.nombre_ley
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].idLey = action.valueSeleted.id_ley
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles = action.preguntasBusqueda
          } else {

            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].siglasLey = action.valueInput
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].nombreLey = null
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].idLey = null
            newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles = action.preguntasBusqueda
          }
        } else if (action.tipoDatoInt == 'artIni') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].artIni = action.valueInput
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'artFin') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].artFin = action.valueInput
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'numPreg') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].numPreg = action.valueInput
        } else if (action.tipoDatoInt == 'bloqueado') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].bloqueado = action.valueInput
        } else if (action.tipoDatoInt == 'unico') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].unico = action.valueInput
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles = action.valueSeleted
        } else if (action.tipoDatoInt == 'incluida') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles[action.valueSeleted].incluida = action.valueInput
        } else if (action.tipoDatoInt == 'excluida') {
          newState.genTestInfo.temasTestG[action.value].interval[action.idKey].preguntasDisponibles[action.valueSeleted].excluida = action.valueInput
        }
      }


      return getNewState(state, newState);

    }
    case 'GEN_TEST_EDT_NUM_PREG2': {

      let newState = state;

      if (action.tipo == 'art') {
        newState.genTestInfo.intervalosTestG[action.id].numPreg = action.value
      } else if (action.tipo == 'tema') {
        newState.genTestInfo.temasTestG[action.id].numPreg = action.value
      }


      return getNewState(state, newState);

    }
    case 'GEN_TEST_EDT_NUM_PREGTOTAL': {

      let newState = state;

      newState.genTestInfo.numPreg = action.value



      return getNewState(state, newState);

    }

    case 'GEN_TEST_DEL_INTER': {


      let newState = state;
      if (action.tipo == 'art') {
        newState.genTestInfo.intervalosTestG[action.value].interval.splice(action.idKey, 1)
      } else if (action.tipo == 'tema') {
        newState.genTestInfo.temasTestG[action.value].interval.splice(action.idKey, 1)
      }


      return getNewState(state, newState);

    }
    case 'GEN_TEST_ADD_inter': {
      let newOpo = {
        idLey: '',
        nombreLey: null,
        siglasLey: '',
        artIni: '',
        artFin: '',
        numPreg: '',
        bloqueado: false,
        unico: false,
        preguntasDisponibles: []
      }

      let newState = state;
      if (action.tipo == 'art') {
        newState.genTestInfo.intervalosTestG[action.value].interval.push(newOpo);
      } else if (action.tipo == 'tema') {
        newState.genTestInfo.temasTestG[action.value].interval.push(newOpo);
      }


      return getNewState(state, newState);

    }
    case 'LOAD_GEN_TEST': {
      return getNewState(state, {
        genTestInfo: action.value
      });
    }
    case 'GEN_TEST_ADD_OPO': {
      let newOpo = {
        id: null,
        nombre: ''
      }

      let newState = state;

      newState.genTestInfo.testOposicion.push(newOpo);

      return getNewState(state, newState);

    }
    case 'GEN_TEST_EDT_OPO': {
      let newState = state;

      if (action.opoSeleted != null) {
        newState.genTestInfo.testOposicion[action.id].id = action.opoSeleted.id_oposicion;
        newState.genTestInfo.testOposicion[action.id].nombre = action.opoSeleted.nombre;
      } else {
        newState.genTestInfo.testOposicion[action.id].id = null;
        newState.genTestInfo.testOposicion[action.id].nombre = action.valInput;
      }


      return newState;

    }
    case 'GEN_TEST_DEL_OPO': {
      let newState = state;

      newState.genTestInfo.testOposicion.splice(action.id, 1)

      return newState;

    }

    case 'GEN_TEST_ADD_TEMA': {
      let newOpo = {
        idOpo: null,
        nombreOpo: '',
        temasLoadOpo: [],
        id: null,
        nombre: '',
        interval: [],
        numPreg: ''
      }

      let newState = state;

      newState.genTestInfo.temasTestG.push(newOpo);

      return getNewState(state, newState);

    }
    case 'GEN_TEST_DEL_TEMA': {
      let newState = state;

      newState.genTestInfo.temasTestG.splice(action.id, 1)

      return newState;

    }

    case 'GEN_TEST_EDT_TEMAOPO': {
      let newState = state;
      if (action.payload != null) {
        newState.genTestInfo.temasTestG[action.id].idOpo = action.payload.id_oposicion;
        newState.genTestInfo.temasTestG[action.id].nombreOpo = action.payload.nombre;
        if (action.valInput.length > 0) {
          for (let i = 0; i < action.valInput.length; i++) {
            newState.genTestInfo.temasTestG[action.id].temasLoadOpo.push(action.valInput[i])
          }
        } else {
          newState.genTestInfo.temasTestG[action.id].temasLoadOpo = []
        }

      } else {
        newState.genTestInfo.temasTestG[action.id].idOpo = null;
        newState.genTestInfo.temasTestG[action.id].nombreOpo = action.valInput;
      }


      return newState;

    }
    case 'GEN_TEST_EDT_TEMA': {
      let newState = state;
      if (action.payload != null) {
        newState.genTestInfo.temasTestG[action.id].id = action.payload.id_tema;
        newState.genTestInfo.temasTestG[action.id].nombre = action.payload.titulo_tema;
        newState.genTestInfo.temasTestG[action.id].interval = action.valInput;

      } else {
        newState.genTestInfo.temasTestG[action.id].id = null;
        newState.genTestInfo.temasTestG[action.id].nombre = action.valInput;
        newState.genTestInfo.temasTestG[action.id].interval = [];
      }


      return newState;

    }
    case 'GEN_TEST_VAL_NUMPREG': {
      let newState = state;

      newState.genTestInfo.numPreg = action.value

      return newState;

    }

    case 'GEN_TEST_CONTINUAR': {
      let newState = state;

      newState.genTestInfo.continuar = action.value

      return newState;

    }
    case 'GEN_TEST_VAL_nametest': {
      let newState = state;

      newState.genTestInfo.nameTest = action.value

      return newState;

    }
    case 'GEN_TEST_VAL_REPETIRprgutnas': {
      let newState = state;

      newState.genTestInfo.repetirPreguntas = action.value

      return newState;

    }

    case 'GEN_TEST_VAL_ExclusionExpPreg': {
      let newState = state;

      newState.genTestInfo.tiempoExclusionPreguntas = action.value
      if (action.value != '' && action.pregExpe != null) {
        newState.genTestInfo.preguntasExcluirExpediente = action.pregExpe
      } else {
        newState.genTestInfo.preguntasExcluirExpediente = []
      }
      return newState;

    }

    case 'GEN_TEST_ADD_CLASE': {
      let newOpo = {
        id: null,
        nombre: ''
      }

      let newState = state;

      newState.genTestInfo.clasesTestExp.push(newOpo);

      return newState;

    }
    case 'GEN_TEST_EDT_CLASE': {
      let newState = state;

      if (action.payload != null) {
        newState.genTestInfo.clasesTestExp[action.id].id = action.payload.idClase;
        newState.genTestInfo.clasesTestExp[action.id].nombre = action.payload.diaSemanal;
        newState.genTestInfo.clasesTestExp[action.id].idOpo = action.payload.idOposicion;

      } else {
        newState.genTestInfo.clasesTestExp[action.id].id = null;
        newState.genTestInfo.clasesTestExp[action.id].nombre = action.valInput;
        newState.genTestInfo.clasesTestExp[action.id].idOpo = null;
      }
      if (action.pregExpe != null) {
        newState.genTestInfo.preguntasExcluirExpediente = action.pregExpe
      } else {
        newState.genTestInfo.preguntasExcluirExpediente = []
      }

      return newState;

    }

    case 'GEN_TEST_DEL_CLASE': {
      let newState = state;

      newState.genTestInfo.clasesTestExp.splice(action.id, 1)
      if (action.pregExpe != null) {
        newState.genTestInfo.preguntasExcluirExpediente = action.pregExpe
      } else {
        newState.genTestInfo.preguntasExcluirExpediente = []
      }
      return newState;

    }




    case 'LOAD_SEMANA_FFF': {
      return getNewState(state, {
        semPrgLoadFactura: action.semanaFac
      });
    }
    case 'LOAD_SEMANAS_FAC_FULL': {
      return getNewState(state, {
        allSemFac: action.allSemFac
      });
    }
    case 'TEMAS_FACTURADOS': {
      return getNewState(state, {
        temasFacturados: action.temasFacturados
      });
    }
    case 'LOAD_SEMANA_PRG': {
      return getNewState(state, {
        semanaPrg: action.semanaPrg
      });
    }
    case 'LOAD_CATEGORIA_ESC': {
      return getNewState(state, {
        escritorioCategoria: action.idCategoria
      });
    }

    case 'LOAD_TEMA_ESC': {
      return getNewState(state, {
        escritorioTema: action.idTema
      });
    }

    case 'LOAD_TEMA_AULA': {
      return getNewState(state, {
        temaAulaLoad: action.idTema
      });
    }


    case 'LOAD_CURSO': {
      return getNewState(state, {
        idClase: action.idClase,
        nombreClase: action.nombreClase,
        idOposicion: action.idOpo,
        userAccesoTemas: action.userAccesoTemas
      });
    }
    case 'LOAD_CLASE_EXT': {
      return getNewState(state, {
        semanasFacturadas: action.semanasFacturadas,
        displayClases: action.displayClases,
        displaySemanas: action.displaySemanas
      });

    }
    case 'LOAD_CLASE': {
      return getNewState(state, {
        semanasFacturadas: action.semanasFacturadas,
        displayClases: action.displayClases,
        displaySemanas: action.displaySemanas

      });

    }
    case 'NOTIFICACION': {
      return getNewState(state, {
        notificacionAviso: action.estado
      });

    }
    
    case 'OPEN_BUS_ART': {
      return getNewState(state, {
        viewBusArt: action.payload
      });

    }
    case 'CHANGE_MENU_VISIBLE': {
      return getNewState(state, {
        menuVisible: action.value
      });

    }
    case 'LOGIN_USER': {
      // return getNewState(state, {
      //   idUser: 1,
      //   isLogged: true,
      //   nombreUser: 'Informatico',
      //   tipoUser: '99',
      //   fotoPerfil: 'action.infoCheckLogin[0].link_foto_perfil',
      //   verTestUserJusticia: [],
      //   verCasoPUser: [],
      //   casoLoadTextHtml: undefined,
      //   testJusticiaUserLoad: [],
      //   casoPracticoUserLoad: [],
      //   userPersist: 'nameCodificado',
      //   cronometro: { minutos: 0, segundos: 0 },
      //   cronometroCP: { minutos: 0, segundos: 0 },
      //   claseLoad: null,
      //   idClase: null,
      //   nombreClase: null,
      //   semanasFacturadas: null,
      //   displayClases: true,
      //   displaySemanas: false,
      //   idOposicion: null,
      //   escritorioCategoria: undefined,
      //   escritorioTema: undefined,
      //   temaAulaLoad: null,
      //   semanasPrgCurso: null,
      //   modeTest: 'home',
      //   version: "2.0-beta"

      // });
      
      
      //comprobar hash

      let longitud = 8;
      let caracteres = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJQLMNSPQ";
      var nameCodificado = "";
      var max = caracteres.length - 1;
      for (var i = 0; i < longitud; i++) {
        nameCodificado += caracteres[Math.floor(Math.random() * (max + 1))];
      }

      let data = { dni: action.identificadorUser, userPersistant: nameCodificado }
      fetch("https://oposiciones-justicia.es/api/usuarios/adduserPersist", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).catch(error => console.log('Object fecth failed', error))

      let data2 = { idUser: action.infoCheckLogin[0].id_usuario, ipUser: action.dataIp.IPv4, cp: action.dataIp.postal, ciudad: action.dataIp.city, fecha: moment().format('YYYY-MM-DD HH:mm'), info: 'Inicio de sesión' }
      // console.log(data2)
      // console.log(action.infoCheckLogin[0])
      fetch("https://oposiciones-justicia.es/api/usuarios/ipUserRegistro", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data2)
      }).then(response => {
        if (response.status >= 400) { throw new Error("Bad response from server"); }
        return response.json();
      }).catch(error => console.log('Object fecth failed', error))

     
        
        return getNewState(state, {
          idUser: action.infoCheckLogin[0].id_usuario,
          isLogged: true,
          nombreUser: action.infoCheckLogin[0].nombre_usuario,
          tipoUser: action.infoCheckLogin[0].nivel_usuario,
          fotoPerfil: action.infoCheckLogin[0].link_foto_perfil,
          verTestUserJusticia: [],
          verCasoPUser: [],
          casoLoadTextHtml: undefined,
          testJusticiaUserLoad: [],
          casoPracticoUserLoad: [],
          userPersist: nameCodificado,
          cronometro: { minutos: 0, segundos: 0 },
          cronometroCP: { minutos: 0, segundos: 0 },
          claseLoad: null,
          idClase: null,
          nombreClase: null,
          semanasFacturadas: null,
          displayClases: true,
          displaySemanas: false,
          idOposicion: null,
          escritorioCategoria: undefined,
          escritorioTema: undefined,
          temaAulaLoad: null,
          semanasPrgCurso: null,
          viewBusArt: null,
          idLeyBuscadorArt: null,
          artBuscadorArt: null,
          modeTest: 'home',
          version: "2.0-beta"
  
        });



        // testJusticiaSaveNube: action.infoCheckLogin[0].testNube,
        
   


      




    }


    // iniciarSesionForce action lleva --> iniciarSesionForce(infoCheckLogin, userPersist)
    case 'LOGIN_USER_FORCE_ADM': {
      return getNewState(state, {
        idUser: action.infoCheckLogin[0].id_usuario,
        isLogged: true,
        nombreUser: action.infoCheckLogin[0].nombre_usuario,
        tipoUser: action.infoCheckLogin[0].nivel_usuario,
        fotoPerfil: action.infoCheckLogin[0].link_foto_perfil,
        verTestUserJusticia: [],
        verCasoPUser: [],
        casoLoadTextHtml: undefined,
        testJusticiaUserLoad: [],
        casoPracticoUserLoad: [],
        userPersist: action.infoCheckLogin[0].userPersistant,
        cronometro: { minutos: 0, segundos: 0 },
        cronometroCP: { minutos: 0, segundos: 0 },
        claseLoad: null,
        idClase: null,
        nombreClase: null,
        semanasFacturadas: null,
        displayClases: true,
        displaySemanas: false,
        idOposicion: null,
        escritorioCategoria: undefined,
        escritorioTema: undefined,
        temaAulaLoad: null,
        semanasPrgCurso: null,
        modeTest: 'home',
        version: "2.0-beta"

      });
    }
    case 'LOGOUT_USER': {
      return getNewState(state, {
        isLogged: false,
        nombreUser: undefined,
        tipoUser: 0,
        fotoPerfil: undefined,
        testJusticiaUserLoad: [],
        verTestUserJusticia: [],
        casoPracticoUserLoad: [],
        verCasoPUser: [],
        userPersist: undefined,
        cronometro: { minutos: 0, segundos: 0 },
        cronometroCP: { minutos: 0, segundos: 0 },
        noticiaLoad: undefined,
        claseLoad: null,
        idClase: null,
        nombreClase: null,
        semanasFacturadas: null,
        displayClases: true,
        displaySemanas: false,
        idOposicion: null,
        temaAulaLoad: null,
        escritorioCategoria: undefined,
        escritorioTema: undefined

      });

    }
    
    
    case 'ADD_IDLEY_VIEWART': {
      return getNewState(state, {
        idLeyBuscadorArt: action.idLey
      });
    }
    case 'ADD_ART_VIEWART': {
      return getNewState(state, {
        artBuscadorArt: action.articulo
      });
    }

    case 'CHANGE_FOTO_PERFIL': {
      return getNewState(state, {
        fotoPerfil: action.urlNueva
      });
      
    }
    case 'CHANGE_FOTO_PERFIL': {
      return getNewState(state, {
        fotoPerfil: action.urlNueva
      });
      
    }
    case 'CHANGE_USER_NAME': {
      return getNewState(state, {
        nombreUser: action.newUserName
      });
      
    }
    case 'NEW_TEST_JUSTICIA_USER_LOAD': {
      return getNewState(state, {
        testJusticiaUserLoad: action.test,
        cronometro: { minutos: 0, segundos: 0 }
      });
      
    }

    case 'LOAD_TEST_JUSTICIA_USER_LOAD': {
      return getNewState(state, {
        testJusticiaUserLoad: action.test
      });
      
    }
    case 'DEL_TEST_JUSTICIA_USER_LOAD': {
      return getNewState(state, {
        testJusticiaUserLoad: [],
        cronometro: { minutos: 0, segundos: 0 }
      });
      
    }

    case 'SELECT_RES_JUS': {
      let pregunta = state.testJusticiaUserLoad.find(p => p.numPreg == action.numPreg);
      let respuestas = pregunta.respuestas

      for (var i = 0; i < respuestas.length; i++) {
        if (i != action.pos) {
          respuestas[i].valorUsuario = 0;
        } else {
          if (respuestas[i].valorUsuario == 1) {
            respuestas[i].valorUsuario = 0;
          } else {
            respuestas[i].valorUsuario = 1;
          }
        }
      }

      return {
        ...state, testJusticiaUserLoad: [
          ...state.testJusticiaUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, respuestas: [
              ...respuestas
            ]
          }
        ]
      };
    }

    case 'SELECT_RES_CP': {
      let pregunta = state.casoPracticoUserLoad.find(p => p.numPreg == action.numPreg);
      let respuestas = pregunta.respuestas

      for (var i = 0; i < respuestas.length; i++) {
        if (i != action.pos) {
          respuestas[i].valorUsuario = 0;
        } else {
          if (respuestas[i].valorUsuario == 1) {
            respuestas[i].valorUsuario = 0;
          } else {
            respuestas[i].valorUsuario = 1;
          }
        }
      }

      return {
        ...state, casoPracticoUserLoad: [
          ...state.casoPracticoUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, respuestas: [
              ...respuestas
            ]
          }
        ]
      };
    }
    case 'PUNTUACION_PREG': {
      let pregunta = state.testJusticiaUserLoad.find(p => p.numPreg == action.numPreg);

      return {
        ...state, testJusticiaUserLoad: [
          ...state.testJusticiaUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, puntuacion: action.value
          }
        ]
      };
    }

    case 'DUDOSA_TEST': {
      let pregunta = state.testJusticiaUserLoad.find(p => p.numPreg == action.numPreg);

      return {
        ...state, testJusticiaUserLoad: [
          ...state.testJusticiaUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, dudosa: action.dudosa
          }
        ]
      };
    }

    case 'PUNTUACION_PREG_CP': {
      let pregunta = state.casoPracticoUserLoad.find(p => p.numPreg == action.numPreg);

      return {
        ...state, casoPracticoUserLoad: [
          ...state.casoPracticoUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, puntuacion: action.value
          }
        ]
      };
    }

    case 'DUDOSA_TEST_CP': {
      let pregunta = state.casoPracticoUserLoad.find(p => p.numPreg == action.numPreg);

      return {
        ...state, casoPracticoUserLoad: [
          ...state.casoPracticoUserLoad.filter(p => p.numPreg != action.numPreg),
          {
            ...pregunta, dudosa: action.dudosa
          }
        ]
      };
    }

    case 'CRONOMETRO': {

      return getNewState(state, {
        cronometro: {
          minutos: action.minutos,
          segundos: action.segundos
        }
      });
      return state;
    }
    case 'CRONOMETROCP': {

      return getNewState(state, {
        cronometroCP: {
          minutos: action.minutos,
          segundos: action.segundos
        }
      });
      return state;
    }
    case 'LOAD_TEST_USER': {
      if (action.value != undefined) {
        return { ...state, verTestUserJusticia: action.value }
      } else {
        return { ...state, verTestUserJusticia: [] }
      }
    }

    case 'LOAD_CP_USER': {
      if (action.value != undefined) {
        return { ...state, verCasoPUser: action.value, verCasoLoadTextHtml: action.htmlText }
      } else {
        return { ...state, verCasoPUser: [], verCasoLoadTextHtml: undefined }
      }
    }


    case 'DEL_CASO_PRACTICO_USER_LOAD': {
      return getNewState(state, {
        casoPracticoUserLoad: [],
        casoLoadTextHtml: undefined,
        cronometroCP: { minutos: 0, segundos: 0 }
      });
      return state;
    }

    case 'NEW_CASO_PRACTICO_USER_LOAD': {
      return getNewState(state, {
        casoPracticoUserLoad: action.test,
        casoLoadTextHtml: action.textHtml,
        cronometroCP: { minutos: 0, segundos: 0 }
      });
      return state;
    }


    case 'LOAD_SEM_CURSO': {
      return getNewState(state, {
        semanasPrgCurso: action.semanasLoad
      });
      return state;
    }

    case 'LOAD_NOTICIA': {
      return getNewState(state, {
        noticiaLoad: action.value
      });
      return state;
    }

    default:
      return state;

  }

}

// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Utils
import './css.css'

//Componets
import { Redirect } from 'react-router-dom';

import BotonFinolis from '../../components/Inputs/Boton3Finolis'
import Input2 from '../../components/Inputs/Input2'
import Boton from '../../components/Inputs/Boton';

import moment from 'moment'

// Redux Imports
import { useSelector, useDispatch } from 'react-redux'
import { borrarTestUser, loadTestUserNUBE, cronoFunc } from '../../actions/userAction'


const LeyesAdm = (props) => {

  const dispatch = useDispatch()
  const idUser = useSelector(state => state.user.idUser)

  const tipoUser = useSelector(state => state.user.tipoUser)


  const [busquedaLeyes, setBusquedaLeyes] = useState(null)
  const [leySelected, setleySelected] = useState(null)
  const [estadoInsertar, setestadoInsertar] = useState(null)

  const refInput1 = useRef();

  useEffect(() => {

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes =>
        setBusquedaLeyes(leyes)
      )
      .catch(error => console.log('Obejet fecth failed', error))

    return () => {

    }
  }, [])

  const onHandleClick = async (e) => {

    if (e.target.id == 'procesarArtLey') {
      try {


        let textArea = refInput1.current.value.replace(/(?:!\n)\(Sin contenido\)/g, '\n(Sin contenido)').replace(/(?:!\n)\(Derogados?\)/g, '\n(Derogado)').replace(/(?:!\n)\(Anulados?\)/g, '\n(Anulado)').replace(/^Artículos/gm, 'Artículo').trim()

        const regex = /^(Artículo|Disposición) ([^\n]*)\.[ ]?\n/gm;
        const articulos = await textArea.split(regex).filter(Boolean);

        console.log('articulos')
        console.log(articulos)
        let articulosRev = []
        let reg = /\.\s(.+)/
        for (let i2 = 0; i2 < articulos.length; i2 += 3) {
          let locaArtTipo = i2
          let locaInfoArt = i2 + 1
          let locaText = i2 + 2


          if (articulos[locaArtTipo] != 'Disposición' && articulos[locaArtTipo] != 'Artículo') {
            swal('Error en el artículo/disposición', 'El art. anterior recibido es: ' + articulos[locaArtTipo - 3] + articulos[locaArtTipo - 2] +'. La posición del fallo es:' + locaArtTipo + '. El error localizado es: ' + articulos[locaArtTipo], 'info')
            throw "jodido";
          }
          const texto = await articulos[locaText].replace(/\t/g, " ").replace(/ {1,}(\n)/g, '\n').replace(/"/g, "´").replace(/'/g, "´").replace(/( {2,})/g, ' ').replace(/(\n{2,})/g, '\n').trim()

          let match2 = reg.exec(articulos[locaInfoArt])

          if (match2) {

            articulosRev.push({
              tipoArt: articulos[locaArtTipo],
              infoExtraArt: match2[1],
              articulo: match2.input.substring(0, match2.index),
              texto: texto
            })

          } else {


            articulosRev.push({
              tipoArt: articulos[locaArtTipo],
              articulo: articulos[locaInfoArt],
              infoExtraArt: "",
              texto: texto
            })

          }

        }

        console.log('articulosRev');
        console.log(articulosRev);

        await swal({
          title: "¿Insertamos los artículos?",
          text: "Hemos detectacto un total de " + articulosRev.length + " artículos.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              swal({
                title: 'Insertando articulos',
                text: 'Este proceso puede tardar un buen rato no cierre el navegador.',
                icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
              })

              let data1 = { idLey: leySelected.id_ley, articulos: articulosRev }
              let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/insertarArticulos", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
              });
              if (!response1.ok) {
                swal('Algo fallo', 'Vuelva a intentarlo', 'warning')
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;

              if (cls1.affectedRows > 0) {
                await swal({
                  title: 'Artículos insertados',
                  text: 'Puede seguir aquí o cambiar de pagina!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 3500,
                }).then(() => {
                  refInput1.current.value = ''
                  swal.close()
                })
              } else {
                swal('Algo fallo', 'Vuelva a intentarlo', 'warning')
              }

            } else {
              swal("¡Artículos no insertados!", '', "success");
            }
          });
      } catch (error) {

      }
    }

  }

  const onChangeInput = (e) => {

    if (e.target.id == 'leyBusqueda') {

      let value = e.target.value
      if (value != '' && busquedaLeyes.find(l => l.siglas_ley == value) != undefined) {
        setestadoInsertar(true)
        setleySelected(busquedaLeyes.find(l => l.siglas_ley == value))
      } else {
        setestadoInsertar(null)

      }
    }

  }



  return (
    <div className='ColCenterCenter'>
      <h2 className='h2AF2'> Añadiendo artículos a una legislación </h2>

      <div>
        <div className='celdaInputDAPFBN' style={{ width: '250px', margin: '10px' }}>
          <Input2 datalist='leyesLoad' autoComplete={'off'} id={'leyBusqueda'} type='text' name={'leyBusqueda'} placeholder='Ej: LECR' icon='fa-light fa-gavel' onChange={onChangeInput} />
          <p>Legislación</p>
        </div>
        <datalist id="leyesLoad">{
          busquedaLeyes && busquedaLeyes.map((cls, key4345) =>
            <option key={key4345} value={cls.siglas_ley}>{cls.nombre_ley}</option>
          )
        }</datalist>
      </div>

      {estadoInsertar != null ? (
        <textarea ref={refInput1} name="" id="" cols="75" rows="10" className="textarea2"></textarea>
      ) : null}

      {estadoInsertar != null ? (
        <div className='RowCenterCenter' style={{ margin: '10px' }}>
          <Boton id={'procesarArtLey'} onClick={onHandleClick} texto={'PROCESAR ARTÍCULOS'} />
        </div>
      ) : null}

    </div>
  )



}

LeyesAdm.propTypes = {

}

export default LeyesAdm;
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import {isDefined} from '../../lib/utils/is'
// Assets
import './css/css.css';

class Ranking extends Component {
	static propTypes = {
		// id: PropTypes.string.isRequired
  };

	constructor(props){
		super(props);
        this.state = {
        };
    }
  
    

    async componentDidMount(){
        
    }
	render() {
		const { } = this.state;
    const { rank } = this.props;
       
        
		return (
      isDefined(rank) && rank.length > 0 ? (
        <React.Fragment>
        
        <div className="SSFC-RankTopBarInfo">
          <div className="SSFC-RankTopBarInfo-Celda">
            <i className="far fa-flag"></i>
            Puntuación
          </div>
          <div className="SSFC-RankTopBarInfo-Celda">
            <i className="fas fa-check-circle"/> Correctas
          </div>
          <div className="SSFC-RankTopBarInfo-Celda">
            <i className="fas fa-times-circle"/> Erróneas
          </div>
          <div className="SSFC-RankTopBarInfo-Celda">
            <i className="far fa-circle"/> En blanco
          </div>
        </div>
      
        <div id="wrapper01">
          <div class="scrollbar01" id="style-2">
                <div class="force-overflow01">
                  {rank && rank.filter(a=> a.idAlumno == this.props.idUser).length > 0 && rank.sort((b, a) => a.puntuacion - b.puntuacion).filter(a=> a.idAlumno == this.props.idUser).map((rank2, keyRank2) =>
                    <div key={keyRank2} className="SSFC-celdaRankingTop">
                      <div className="SSFC-RankTopBarInfo-Celda-IconNumber"> 
                      {
                        rank.findIndex(a=> a.idAlumno == rank2.idAlumno) && parseInt(rank.findIndex(a=> a.idAlumno == rank2.idAlumno)) <= 9 ? (
                          
                            '0'+( 1 + rank.findIndex(a=> a.idAlumno == rank2.idAlumno))
                          
                          
                          ):
                            ( 
                              (1 + rank.findIndex(a=> a.idAlumno == rank2.idAlumno) == 1 ? (
                                '01'
                              ) : (
                                rank.findIndex(a=> a.idAlumno == rank2.idAlumno)
                              )
                              
                              )
                            )
                            
                      
                      } 
                      </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo" style={{minWidth: '70px'}}> {rank2.puntuacion} <i className="far fa-flag"></i> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank2.correctas} <i className="fas fa-check-circle"/> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank2.erroneas} <i className="fas fa-times-circle"/> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank2.enBlanco} <i className="far fa-circle"/> </div>
                    </div>
                  )}
                  {rank && rank.filter(a=> a.idAlumno != this.props.idUser).length > 0 && rank.sort((b, a) => a.puntuacion - b.puntuacion).map((rank01, keyRank) =>
                    <div key={keyRank} className="SSFC-celdaRanking">
                      <div className="SSFC-RankTopBarInfo-Celda-IconNumber"> 
                      {
                        parseInt(keyRank) < 9 ? (
                          '0'+(1 + parseInt(keyRank))
                          ):(1 + parseInt(keyRank))
                      
                      } 
                      </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo" style={{minWidth: '70px'}}> {rank01.puntuacion} <i className="far fa-flag"></i> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank01.correctas} <i className="fas fa-check-circle"/> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank01.erroneas} <i className="fas fa-times-circle"/> </div>
                      <div className="SSFC-RankTopBarInfo-C-dataInfo"> {rank01.enBlanco} <i className="far fa-circle"/> </div>
                    </div>
                  )}
                </div>
            </div>
          </div>
        </React.Fragment>
      ):null
      
		);
	}
}

// Estados del store
function mapStateToProps(state){
    return{
      idClase: state.user.idClase,
      idUser: state.user.idUser
  }
}
  
  function mapDispatchToProps(dispatch){
    return bindActionCreators ({
    },dispatch)
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Ranking)
// Dependencies
import { combineReducers } from 'redux';

// Apps Reducers


// Shared Reducers
import user from './userReducer';
import baroption from './baroptionReducer';
import testJusticia from './testJusticiaReducer';
import casoPractico from './casoPracticoReducer';
//import testWord from './testWordReducer';

const rootReducer = combineReducers({
	user,
	baroption,
	testJusticia,
	casoPractico
});

export default rootReducer;

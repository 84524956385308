// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
// Assets
import './css/prgEdtSelected.css';
//Component
import Input2 from '../Inputs/Input2';
import Boton from '../Inputs/Boton';

import TemaSeleted from '../TemaSeleted'

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { comprobarRespuestas } from '../../lib/utils/generadorTestJusticia';
import { respuestaSbr } from '../../lib/utils/cargarTestJusticia';
import { loadTestJusticia, loadCasoPractico } from '../../actions/userAction';

class PrgEdtSelected extends Component {

	static propTypes = {
		programa: PropTypes.array.isRequired,
		infoSemanaPrg: PropTypes.object.isRequired,
		bloqueTemas: PropTypes.array.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			displayTestJusticia: 'block',
			displayTestWord: 'block',
			busquedaTemasOposicion: undefined,
			navS: 0,
			navS2: 0,
			infoSemanaPrg: this.props.infoSemanaPrg,
			bloqueTemas: this.props.bloqueTemas
		};

		this.onEditarDatos = this.onEditarDatos.bind(this);
		this.handleSubmitFormNew = this.handleSubmitFormNew.bind(this);
		this.onChangeInputNew = this.onChangeInputNew.bind(this);

		this.handleOnClick = this.handleOnClick.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);


	}

	onEditarDatos(e) {
		let { programa, infoTemas } = this.props
		let ctrlTema = e.target.id.split('-')[1];
		switch (e.target.id.split('-')[0]) {

			case 'activarTodosAlumnos':
				let { alumnosClase } = this.props;
				for (var i = 0; i < alumnosClase.length; i++) {
					let idAlumno = alumnosClase[i].idAlumno;
					let existe = infoTemas[ctrlTema].alumnosInscritos.findIndex(alumnos => alumnos.idAlumno == idAlumno)
					if (existe != -1) {
						if (infoTemas[ctrlTema].alumnosInscritos[existe].activo == 0) {
							this.props.infoTemas[ctrlTema].alumnosInscritos[existe].activo = 1;
							if (this.props.infoTemas[ctrlTema].alumnosInscritos[existe].nuevo == 0) {
								this.props.infoTemas[ctrlTema].alumnosInscritos[existe].editar = 1;
							} else {
								this.props.infoTemas[ctrlTema].alumnosInscritos[existe].editar = 0;
							}
							$("#alumno-" + ctrlTema + "-" + i + "").addClass("selectAlumno aTNameSelect");
						}
					} else {
						this.props.infoTemas[ctrlTema].alumnosInscritos.push({
							idCtrl: undefined,
							idTemaPrg: this.props.infoTemas[ctrlTema].idTemaPrg,
							idAlumno: idAlumno,
							activo: 1,
							editar: 0,
							nuevo: 1
						});
						$("#alumno-" + ctrlTema + "-" + i + "").addClass("selectAlumno aTNameSelect");
					}
				}
				break;
			case 'editarAlumnos':
				let insertNuevosAlumnosInscritos = [];
				let updateAlumnosInscritos = [];

				for (var i = 0; i < infoTemas[ctrlTema].alumnosInscritos["length"]; i++) {
					if (infoTemas[ctrlTema].alumnosInscritos[i].nuevo == 1) {
						insertNuevosAlumnosInscritos.push({
							idTemaPrg: infoTemas[ctrlTema].alumnosInscritos[i].idTemaPrg,
							idAlumno: infoTemas[ctrlTema].alumnosInscritos[i].idAlumno,
							activo: infoTemas[ctrlTema].alumnosInscritos[i].activo
						})
					} else if (infoTemas[ctrlTema].alumnosInscritos[i].editar == 1 && infoTemas[ctrlTema].alumnosInscritos[i].nuevo == 0) {
						updateAlumnosInscritos.push({
							idCtrl: infoTemas[ctrlTema].alumnosInscritos[i].idCtrl,
							activo: infoTemas[ctrlTema].alumnosInscritos[i].activo
						})
					}
				}

				if (insertNuevosAlumnosInscritos.length > 0) {
					let data = { alumnos: insertNuevosAlumnosInscritos }

					fetch("https://oposiciones-justicia.es/api/preparador/insertarAlumnosInscritos", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("¡Nuevos alumnos en tema: " + infoTemas[ctrlTema].tituloTema + "!", "Se han insertado " + insertNuevosAlumnosInscritos.length + " alumnos nuevos en el tema.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (updateAlumnosInscritos.length > 0) {
					let data2 = { alumnos: updateAlumnosInscritos }
					fetch("https://oposiciones-justicia.es/api/preparador/updateAlumnosInscritos", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data2)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("¡Editados alumnos del tema: " + infoTemas[ctrlTema].tituloTema + "!", "Se han actualizado: " + updateAlumnosInscritos.length + " ,alumnos que tenia el tema asignados.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarTestExtra':
				let updateTestExtra = [];
				if (infoTemas[ctrlTema].idTema != undefined && infoTemas[ctrlTema].tituloTema != undefined) {
					if (infoTemas[ctrlTema].testExtraEstado != undefined && infoTemas[ctrlTema].TestExtra != undefined) {
						updateTestExtra.push({
							idTemaPrg: infoTemas[ctrlTema].idTemaPrg,
							testExtraEstado: infoTemas[ctrlTema].testExtraEstado,
							TestExtra: infoTemas[ctrlTema].TestExtra
						})
					}
				} else { swal('Debe seleccionar un Tema', 'En editar Tema, seleccione un tema de la lista y guardelo.', 'error') }
				if (updateTestExtra.length > 0) {
					let data = { testExtra: updateTestExtra }
					fetch("https://oposiciones-justicia.es/api/preparador/updateTestExtra", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("¡Test Extra actualizado!", "Se han actualizado los datos del Test Extra correctamente.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarCasoExtra':
				let updateCasoExtra = [];
				if (infoTemas[ctrlTema].idTema != undefined && infoTemas[ctrlTema].tituloTema != undefined) {
					if (infoTemas[ctrlTema].casoExtraEstado != undefined && infoTemas[ctrlTema].idCasoExtra != undefined) {
						updateCasoExtra.push({
							idTemaPrg: infoTemas[ctrlTema].idTemaPrg,
							casoExtraEstado: infoTemas[ctrlTema].casoExtraEstado,
							idCasoExtra: infoTemas[ctrlTema].idCasoExtra
						})
					}
				} else { swal('Debe seleccionar un Tema', 'En editar Tema, seleccione un tema de la lista y guardelo.', 'error') }

				if (updateCasoExtra.length > 0) {
					let data = { casoExtra: updateCasoExtra }
					fetch("https://oposiciones-justicia.es/api/preparador/updateCasoExtra", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("¡Caso Extra actualizado!", "Se han actualizado los datos del Caso Practico Extra correctamente.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarProgramacion':
				let updatePRG = []

				if (programa[0].idTestJusticia == 0) {
					updatePRG.push({
						prgSemanal: programa[0].prgSemanal,
						idTestJusticia: programa[0].idTestJusticia,
						pregJusticia: programa[0].pregJusticia,
						idTestWord: programa[0].idTestWord,
						pregWord: programa[0].pregWord,
						idCasoPractico: programa[0].idCasoPractico,
						idEjerMeca: programa[0].idEjerMeca
					});
				} else {
					updatePRG.push({
						prgSemanal: programa[0].prgSemanal,
						idTestJusticia: programa[0].idTestJusticia,
						pregJusticia: 0,
						idTestWord: programa[0].idTestWord,
						pregWord: programa[0].pregWord,
						idCasoPractico: programa[0].idCasoPractico,
						idEjerMeca: programa[0].idEjerMeca
					});
				}

				if (updatePRG.length > 0) {
					let data = { prg: updatePRG }
					fetch("https://oposiciones-justicia.es/api/preparador/updatePRG", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Edición de Programación Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarIntervalosExt':
				let insertIntExtra = [];
				let updateIntExtra = [];
				let deleteIntExtra = [];
				for (var i = 0; i < programa[0].intervalosLeyesExtra.length; i++) {
					if (programa[0].intervalosLeyesExtra[i].nuevo == 1) {
						insertIntExtra.push({
							idPrograma: programa[0].prgSemanal,
							idLey: programa[0].intervalosLeyesExtra[i].idLey,
							artFin: programa[0].intervalosLeyesExtra[i].artFin,
							artInicio: programa[0].intervalosLeyesExtra[i].artInicio
						})
					} else if (programa[0].intervalosLeyesExtra[i].editar == 1) {
						updateIntExtra.push({
							idInterArt: programa[0].intervalosLeyesExtra[i].idInterArt,
							idLey: programa[0].intervalosLeyesExtra[i].idLey,
							artFin: programa[0].intervalosLeyesExtra[i].artFin,
							artInicio: programa[0].intervalosLeyesExtra[i].artInicio
						})
					} else if (programa[0].intervalosLeyesExtra[i].borrar == 1) {
						deleteIntExtra.push({
							idInterArt: programa[0].intervalosLeyesExtra[i].idInterArt
						})
					}
				}

				if (insertIntExtra.length > 0) {
					let data = { prgIntervaloExtra: insertIntExtra }
					fetch("https://oposiciones-justicia.es/api/preparador/insertIntExtra", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Insercción de Intervalos Extra Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (updateIntExtra.length > 0) {
					let data = { prgIntervaloExtra: updateIntExtra }
					fetch("https://oposiciones-justicia.es/api/preparador/updateIntExtra", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Edición de Intervalos Extra Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (deleteIntExtra.length > 0) {
					let data = { prgIntervaloExtra: deleteIntExtra }
					fetch("https://oposiciones-justicia.es/api/preparador/deleteIntExtra", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Eliminación de Intervalos Extra Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarTema':
				let insertTemaPrg = [];
				let updateTemaPrg = [];
				let deleteTemaPrg = [];

				if (infoTemas[ctrlTema].nuevo == 1) {
					insertTemaPrg.push({
						idPrograma: programa[0].prgSemanal,
						idTema: infoTemas[ctrlTema].idTema,
						porcentajeTest: infoTemas[ctrlTema].porcentajeTest,
						tipoTema: infoTemas[ctrlTema].tipoTema
					})
				} else if (infoTemas[ctrlTema].editar == 1) {
					updateTemaPrg.push({
						idTemaPrg: infoTemas[ctrlTema].idTemaPrg,
						idTema: infoTemas[ctrlTema].idTema,
						porcentajeTest: infoTemas[ctrlTema].porcentajeTest,
						tipoTema: infoTemas[ctrlTema].tipoTema
					})
				} else if (infoTemas[ctrlTema].borrar == 1) {
					deleteTemaPrg.push({
						idTemaPrg: infoTemas[ctrlTema].idTemaPrg
					})
				}


				if (insertTemaPrg.length > 0) {
					let data = { edtTema: insertTemaPrg }
					fetch("https://oposiciones-justicia.es/api/preparador/insertTemaPrg", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								this.props.infoTemas[ctrlTema].idTemaPrg = info.insertId
								swal("Inserción de Tema Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))


				}

				if (updateTemaPrg.length > 0) {
					let data = { edtTema: updateTemaPrg }
					fetch("https://oposiciones-justicia.es/api/preparador/updateTemaPrg", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Edición de Tema Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}

				if (deleteTemaPrg.length > 0) {
					let data = { edtTema: deleteTemaPrg }
					fetch("https://oposiciones-justicia.es/api/preparador/deleteTemaPrg", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Eliminación de Tema Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarApuntes':
				let insertTemaPrgApuntes = [];
				let updateTemaPrgApuntes = [];
				let deleteTemaPrgApuntes = [];
				if (infoTemas[ctrlTema].idTema != undefined && infoTemas[ctrlTema].tituloTema != undefined) {
					for (var i = 0; i < infoTemas[ctrlTema].apuntes.length; i++) {

						if (infoTemas[ctrlTema].apuntes[i].nuevo == 1) {
							insertTemaPrgApuntes.push({
								idTemaPrg: infoTemas[ctrlTema].apuntes[i].idTemaPrg,
								idApunte: infoTemas[ctrlTema].apuntes[i].idApunte
							})
						} else if (infoTemas[ctrlTema].apuntes[i].editar == 1) {
							updateTemaPrgApuntes.push({
								idAPuntePrg: infoTemas[ctrlTema].apuntes[i].idAPuntePrg,
								idApunte: infoTemas[ctrlTema].apuntes[i].idApunte
							})
						} else if (infoTemas[ctrlTema].apuntes[i].borrar == 1) {
							deleteTemaPrgApuntes.push({
								idAPuntePrg: infoTemas[ctrlTema].apuntes[i].idAPuntePrg
							})
						}
					}
				} else { swal('Debe seleccionar un Tema', 'En editar Tema, seleccione un tema de la lista y guardelo.', 'error') }

				if (insertTemaPrgApuntes.length > 0) {
					let data = { edtTemaApuntes: insertTemaPrgApuntes }
					fetch("https://oposiciones-justicia.es/api/preparador/insertTemaPrgApuntes", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Inserción de Apuntes Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (updateTemaPrgApuntes.length > 0) {
					let data = { edtTemaApuntes: updateTemaPrgApuntes }
					fetch("https://oposiciones-justicia.es/api/preparador/updateTemaPrgApuntes", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Edición de Apuntes Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (deleteTemaPrgApuntes.length > 0) {
					let data = { edtTemaApuntes: deleteTemaPrgApuntes }
					fetch("https://oposiciones-justicia.es/api/preparador/deleteTemaPrgApuntes", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Eliminación de Apuntes Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				break;
			case 'editarIntervalos':
				let insertTemaPrgIntervalos = [];
				let updateTemaPrgIntervalos = [];
				let deleteTemaPrgIntervalos = [];

				if (infoTemas[ctrlTema].idTema != undefined && infoTemas[ctrlTema].tituloTema != undefined) {
					for (var i = 0; i < infoTemas[ctrlTema].intervalos.length; i++) {

						if (infoTemas[ctrlTema].intervalos[i].nuevo == 1) {
							insertTemaPrgIntervalos.push({
								idTemaPrg: infoTemas[ctrlTema].intervalos[i].idTemaPrg,
								idLey: infoTemas[ctrlTema].intervalos[i].idLey,
								artFin: infoTemas[ctrlTema].intervalos[i].artFin,
								artInicio: infoTemas[ctrlTema].intervalos[i].artInicio
							})
						} else if (infoTemas[ctrlTema].intervalos[i].editar == 1) {
							updateTemaPrgIntervalos.push({
								idInterArt: infoTemas[ctrlTema].intervalos[i].idInterArt,
								idLey: infoTemas[ctrlTema].intervalos[i].idLey,
								artFin: infoTemas[ctrlTema].intervalos[i].artFin,
								artInicio: infoTemas[ctrlTema].intervalos[i].artInicio
							})
						} else if (infoTemas[ctrlTema].intervalos[i].borrar == 1) {
							deleteTemaPrgIntervalos.push({
								idInterArt: infoTemas[ctrlTema].intervalos[i].idInterArt
							})
						}
					}
				} else { swal('Debe seleccionar un Tema', 'En editar Tema, seleccione un tema de la lista y guardelo.', 'error') }

				if (insertTemaPrgIntervalos.length > 0) {
					let data = { edtTemaIntervalos: insertTemaPrgIntervalos }
					fetch("https://oposiciones-justicia.es/api/preparador/insertTemaPrgIntervalos", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Inserción de Intervalos Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (updateTemaPrgIntervalos.length > 0) {
					let data = { edtTemaIntervalos: updateTemaPrgIntervalos }
					fetch("https://oposiciones-justicia.es/api/preparador/updateTemaPrgIntervalos", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Edición de Intervalos Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}
				if (deleteTemaPrgIntervalos.length > 0) {
					let data = { edtTemaIntervalos: deleteTemaPrgIntervalos }
					fetch("https://oposiciones-justicia.es/api/preparador/deleteTemaPrgIntervalos", {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(data)
					}).then(res => { if (res.status >= 400) { throw new Error("Bad response from server"); } return res.json() })
						.then(info => {
							if (info != undefined) {
								swal("Eliminación de Intervalos Confirmada!", "Sino visualiza los cambios vuelva a seleccionar la clase o refresque.", "success");
							}
						}).catch(error => console.log('Object fecth failed', error))
				}

				break;
		}
	}

	async handleSubmitFormNew(e) {
		if (e.target.id.split('-')[0] == 'verTema') {
			let locaBloque = e.target.id.split('-')[1]
			let locaTema = e.target.id.split('-')[2]
			$('#almacenTema-' + locaBloque + '-' + locaTema).slideToggle(1000);
		} else if (e.target.id.split('-')[0] == 'verTestJus2') {
			let locaBloque = e.target.id.split('-')[1]
			$('#TestJus-' + locaBloque).slideToggle(1000);
		} else if (e.target.id.split('-')[0] == 'verCPJus') {
			let locaBloque = e.target.id.split('-')[1]
			$('#CPJus-' + locaBloque).slideToggle(1000);
		} else if (e.target.id.split('-')[0] == 'verTemasBlock') {
			let locaBloque = e.target.id.split('-')[1]
			$('#verTemasBlockB-' + locaBloque).slideToggle(1000);
		} else if (e.target.id.split('-')[0] == 'verAlumnos') {
			let locaBloque = e.target.id.split('-')[1]
			$('#alumnos-' + locaBloque).slideToggle(1000);

		} else {
			this.props.onClick(e);

		}
	}

	onChangeInputNew(e) {
		if (e.target.name != undefined && e.target.name != '') {
			this.props.onChange(e);

		}
	}


	async componentDidMount() {

		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
			.then(res => res.json())
			.then(oposiciones => this.setState({
				busquedaOpo: oposiciones
			})).catch(error => console.log('Object fecth failed', error));

		fetch('https://oposiciones-justicia.es/api/prosemanal/consultaTestPred')
			.then(res => res.json())
			.then(test => this.setState({
				busquedaTestPre: test
			}))
			.catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
			.then(res => res.json())
			.then(meca => this.setState({
				busquedaMeca: meca
			})).catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreWord')
			.then(res => res.json())
			.then(info => this.setState({ busquedaTestPreWord: info })
			).catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreCasoPractico')
			.then(res => res.json())
			.then(info => this.setState({ busquedaTestPreCasoPractico: info })
			).catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
			.then(leyes => this.setState({
				busquedaLeyes: leyes
			})).catch(error => console.log('Obejet fecth failed', error))
		fetch('https://oposiciones-justicia.es/api/preparador/videosSemanas')
			.then(res => res.json())
			.then(cls => this.setState({
				videosSemanas: cls
			})).catch(error => console.log('Obejet fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/temas/loadTestWord')
			.then(res => res.json())
			.then(info => this.setState({ busquedaWordPDF: info })
			).catch(error => console.log('Object fecth failed', error))

		fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
			.then(res => res.json())
			.then(info => this.setState({ loadExamanesGestion: info })
			).catch(error => console.log('Object fecth failed', error))

		let data = { id_oposicion: this.props.infoSemanaPrg.idOposicion }
		let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaTemasOposicion", {
			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
		});
		if (!response2.ok) {
			throw Error(response2.statusText);
		}
		let json2 = await response2.json();
		let cls2 = await json2;
		this.setState({ busquedaTemasOposicion: cls2 });

	}

	async handleOnClick(e) {

		if (e.target.id.split('-')[0] == 'nav') {

			let val = e.target.id.split('-')[1]
			this.setState({ navS: val })

		} else if (e.target.id.split('-')[0] == 'nav2') {

			let val = e.target.id.split('-')[1]
			this.setState({ navS2: val })

		} else if (e.target.id.split('-')[0] == 'verTema') {

			let locaBloque = e.target.id.split('-')[1]
			let locaTema = e.target.id.split('-')[2]

			$('#viewTema-' + locaBloque + '-' + locaTema).slideToggle(650);
		} else if (e.target.id == 'add_bloque_temas') {

			try {

				let bloqueTemas = await this.state.bloqueTemas
				let infoSemanaPrg = await this.state.infoSemanaPrg

				let data = {
					idSemana: infoSemanaPrg.idPrg,
					tipoBloque: bloqueTemas.length >= 1 ? (1) : (0),
					idClase: infoSemanaPrg.idClase
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/addBloquePrg', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				if (cls.affectedRows > 0) {
					bloqueTemas.push({
						idBloque: cls.insertId,
						tipoBloque: bloqueTemas.length >= 1 ? (1) : (0),
						tipoBloqueTitulo: bloqueTemas.length >= 1 ? ('Repaso') : ('Normal'),
						testJusticia: [],
						casoPractico: [],
						examenGestionPdf: [],
						temas: []
					})
					this.setState({ bloqueTemas: bloqueTemas })
				} else {
					swal('Error al añadir bloque', 'Algo fallo vuelva a intentarlo', 'warning')
				}


			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'adelantarBloque') {

			let locaBloque = parseInt(e.target.id.split('-')[1])
			let posSiguiente = locaBloque + 1
			let bloqueTemas = this.state.bloqueTemas
			let bloqueSave = bloqueTemas[locaBloque]

			// idBloque --> cambiar al siguiente o al anterior
			// idBloqueCambio
			if (bloqueTemas.length > posSiguiente) {
				swal({
					title: 'Cambio bloque de posición',
					text: 'Relizando cambio',
					icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
				})

				bloqueTemas.splice(locaBloque, 1)
				bloqueTemas.splice(posSiguiente, 0, bloqueSave)

				bloqueTemas[locaBloque].idBloqueCambio = bloqueSave.idBloque
				bloqueTemas[posSiguiente].idBloqueCambio = bloqueTemas[locaBloque].idBloque

				let data = { idBloque: bloqueTemas[locaBloque].idBloque, idBloqueCambio: 0 }
				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				let data2 = { idBloque: bloqueTemas[posSiguiente].idBloque, idBloqueCambio: 1 }
				let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
				});
				if (!response2.ok) {
					throw Error(response2.statusText);
				}
				let json2 = await response2.json();
				let cls2 = await json2;


				let data3 = { idBloque: 0, idBloqueCambio: bloqueTemas[locaBloque].idBloqueCambio }
				let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
				});
				if (!response3.ok) {
					throw Error(response3.statusText);
				}
				let json3 = await response3.json();
				let cls3 = await json3;

				let data4 = { idBloque: 1, idBloqueCambio: bloqueTemas[posSiguiente].idBloqueCambio }
				let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
				});
				if (!response4.ok) {
					throw Error(response4.statusText);
				}
				let json4 = await response4.json();
				let cls4 = await json4;


				bloqueTemas[locaBloque].idBloque = bloqueTemas[locaBloque].idBloqueCambio
				bloqueTemas[posSiguiente].idBloque = bloqueTemas[posSiguiente].idBloqueCambio


				bloqueTemas[locaBloque].idBloqueCambio = null
				bloqueTemas[posSiguiente].idBloqueCambio = null

				this.setState({ bloqueTemas: bloqueTemas })
				swal.close()
			}

		} else if (e.target.id.split('-')[0] == 'atrasarBloque') {

			let locaBloque = parseInt(e.target.id.split('-')[1])
			let posSiguiente = locaBloque - 1
			let bloqueTemas = this.state.bloqueTemas
			let bloqueSave = bloqueTemas[locaBloque]

			if (bloqueTemas.length > posSiguiente && posSiguiente >= 0) {
				swal({
					title: 'Cambio bloque de posición',
					text: 'Relizando cambio',
					icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
				})
				await bloqueTemas.splice(locaBloque, 1)
				await bloqueTemas.splice(posSiguiente, 0, bloqueSave)

				bloqueTemas[locaBloque].idBloqueCambio = bloqueSave.idBloque
				bloqueTemas[posSiguiente].idBloqueCambio = bloqueTemas[locaBloque].idBloque

				// actualizar en bd y actualizar idBloques
				// poner el primero como 0 y el segundo en 1

				let data = { idBloque: bloqueTemas[locaBloque].idBloque, idBloqueCambio: 0 }
				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				let data2 = { idBloque: bloqueTemas[posSiguiente].idBloque, idBloqueCambio: 1 }
				let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
				});
				if (!response2.ok) {
					throw Error(response2.statusText);
				}
				let json2 = await response2.json();
				let cls2 = await json2;


				let data3 = { idBloque: 0, idBloqueCambio: bloqueTemas[locaBloque].idBloqueCambio }
				let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
				});
				if (!response3.ok) {
					throw Error(response3.statusText);
				}
				let json3 = await response3.json();
				let cls3 = await json3;

				let data4 = { idBloque: 1, idBloqueCambio: bloqueTemas[posSiguiente].idBloqueCambio }
				let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
				});
				if (!response4.ok) {
					throw Error(response4.statusText);
				}
				let json4 = await response4.json();
				let cls4 = await json4;

				bloqueTemas[locaBloque].idBloque = bloqueTemas[locaBloque].idBloqueCambio
				bloqueTemas[posSiguiente].idBloque = bloqueTemas[posSiguiente].idBloqueCambio

				bloqueTemas[locaBloque].idBloqueCambio = null
				bloqueTemas[posSiguiente].idBloqueCambio = null

				this.setState({ bloqueTemas: bloqueTemas })
				swal.close()
			}

		} else if (e.target.id.split('-')[0] == 'delBloqueTema') {

			let locaBloque = e.target.id.split('-')[1]
			let bloqueTemas = await this.state.bloqueTemas

			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el bloque de Temas será borrado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {

						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/deleteBlockPrg', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						if (cls.affectedRows > 0) {
							bloqueTemas.splice(locaBloque, 1)
							this.setState({ bloqueTemas: bloqueTemas })
						} else {
							swal('Error al eliminar bloque', 'Algo fallo vuelva a intentarlo', 'warning')
						}

					} else {
						swal("¡Proceso cancelado!", '', "success");
					}
				});


		} else if (e.target.id.split('-')[0] == 'add_tema_bloque') {
			try {

				let locaBloque = e.target.id.split('-')[1]
				let bloqueTemas = await this.state.bloqueTemas
				await bloqueTemas[locaBloque].temas.push({
					idTemaPrg: undefined,
					idTema: undefined,
					tipoTema: undefined,
					tituloTema: '',
					nombreTema: undefined,
					nota: null,
					nuevo: 1
				})
				this.setState({ bloqueTemas: bloqueTemas })

			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'borrarTema') {
			let locaBloque = e.target.id.split('-')[1]
			let locaTema = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas
			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el tema será borrado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {

						if (bloqueTemas[locaBloque].temas[locaTema].idTemaPrg != undefined) {

							let data = {
								idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg,
								idBloque: bloqueTemas[locaBloque].idBloque
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/deleteBlockPrgTema', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

						}
						await bloqueTemas[locaBloque].temas.splice(locaTema, 1)
						this.setState({ bloqueTemas: bloqueTemas })


					} else {
						swal("¡Tema no borrado!", '', "success");
					}
				});


		} else if (e.target.id.split('-')[0] == 'addTestJusticia') {
			try {

				let locaBloque = e.target.id.split('-')[1]
				let bloqueTemas = await this.state.bloqueTemas
				await bloqueTemas[locaBloque].testJusticia.push({
					idTestJusticia: undefined,
					nombre: '',
					nuevo: 1
				})
				this.setState({ bloqueTemas: bloqueTemas })

			} catch (e) { console.log(e) }
		} else if (e.target.id.split('-')[0] == 'addCasoPractico') {
			try {

				let locaBloque = e.target.id.split('-')[1]
				let bloqueTemas = await this.state.bloqueTemas
				await bloqueTemas[locaBloque].casoPractico.push({
					idTestCP: undefined,
					nombre: '',
					nuevo: 1
				})
				this.setState({ bloqueTemas: bloqueTemas })

			} catch (e) { console.log(e) }
		} else if (e.target.id.split('-')[0] == 'addexamenGestionPdf') {
			try {

				let locaBloque = e.target.id.split('-')[1]
				let bloqueTemas = await this.state.bloqueTemas
				await bloqueTemas[locaBloque].examenGestionPdf.push({
					idExamenGestion: undefined,
					nombre: '',
					nuevo: 1
				})
				this.setState({ bloqueTemas: bloqueTemas })

			} catch (e) { console.log(e) }
		} else if (e.target.id.split('-')[0] == 'delTestJus') {

			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas


			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el test será borrado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {
						if (bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia != undefined || bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave != undefined) {
							let idTest = bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia != undefined ? (bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia) : (bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave)

							let data = {
								idBloque: bloqueTemas[locaBloque].idBloque,
								idTest: idTest
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/deleteTestTeoBloque', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							if (cls.affectedRows > 0) {
								await bloqueTemas[locaBloque].testJusticia.splice(locaTest, 1)
								this.setState({ bloqueTemas: bloqueTemas })
							} else {
								swal('Error al eliminar ejercicio', 'Algo fallo vuelva a intentarlo', 'warning')
							}
						} else {
							await bloqueTemas[locaBloque].testJusticia.splice(locaTest, 1)
							this.setState({ bloqueTemas: bloqueTemas })
						}


					} else {
						swal("¡Test no borrado!", '', "success");
					}
				});

		} else if (e.target.id.split('-')[0] == 'delCaso') {
			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas

			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el caso práctico será borrado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {
						if (bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP != undefined || bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave != undefined) {
							let idTest = bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP != undefined ? (bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP) : (bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave)
							let data = {
								idBloque: bloqueTemas[locaBloque].idBloque,
								idTest: idTest
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/deleteTestPracticoBloque', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							if (cls.affectedRows > 0) {
								await bloqueTemas[locaBloque].casoPractico.splice(locaTest, 1)
								this.setState({ bloqueTemas: bloqueTemas })
							} else {
								swal('Error al eliminar ejercicio', 'Algo fallo vuelva a intentarlo', 'warning')
							}
						} else {
							await bloqueTemas[locaBloque].casoPractico.splice(locaTest, 1)
							this.setState({ bloqueTemas: bloqueTemas })
						}



					} else {
						swal("¡Caso práctico no borrado!", '', "success");
					}
				});

		} else if (e.target.id.split('-')[0] == 'delexamenGestionPdf') {
			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas

			swal({
				title: "¿Estas segur@?",
				text: "Si le das a ok, el ejercicio será borrado.",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then(async (willDelete) => {
					if (willDelete) {

						if (bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion != undefined || bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave != undefined) {
							let idTest = bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion != undefined ? (bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion) : (bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave)
							let data = {
								idBloque: bloqueTemas[locaBloque].idBloque,
								idTest: idTest
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/deleteEjerDesarrolloBloque', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							if (cls.affectedRows > 0) {
								await bloqueTemas[locaBloque].examenGestionPdf.splice(locaTest, 1)
								this.setState({ bloqueTemas: bloqueTemas })
							} else {
								swal('Error al eliminar ejercicio', 'Algo fallo vuelva a intentarlo', 'warning')
							}
						} else {
							await bloqueTemas[locaBloque].examenGestionPdf.splice(locaTest, 1)
							this.setState({ bloqueTemas: bloqueTemas })
						}




					} else {
						swal("Acción cancelada", '', "success");
					}
				});

		} else if (e.target.id.split('-')[0] == 'reajustarExamen') {
			let locaBloque = e.target.id.split('-')[1]
			let bloqueTemas = this.state.bloqueTemas
			let objectTest = this.state.loadExamanesGestion.find(l => l.id == bloqueTemas[locaBloque].idExamenGestion)

			await bloqueTemas[locaBloque].examenGestionPdf.push({
				idExamenGestion: objectTest.id,
				nombre: objectTest.nombre,
				nuevo: 0
			})

			let data = {
				idBloque: bloqueTemas[locaBloque].idBloque,
				idTest: objectTest.id
			}

			let response = await fetch('https://oposiciones-justicia.es/api/preparador/addEjerDesarrolloBloque', {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
			});
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let json = await response.json();
			let cls = await json;

			let response2 = await fetch('https://oposiciones-justicia.es/api/preparador/updateEjerDesarrolloBloqueAntiguo', {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
			});
			if (!response2.ok) {
				throw Error(response2.statusText);
			}
			let json2 = await response2.json();
			let cls2 = await json2;

			bloqueTemas[locaBloque].idExamenGestion = null

			this.setState({ bloqueTemas: bloqueTemas })

			swal('Ejercicio reajustado', 'Nombre ejercicio desarrollo: ' + objectTest.nombre + '.', 'success')

		} else if (e.target.id.split('-')[0] == 'verTestJus') {

			// let testPuntuaciones = this.state.testJusticiaHistorial
			// let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)

			// let idExp = await testPuntuaciones[numExpediente].idExp

			let busquedaLeyes = this.state.busquedaLeyes
			let locaBloque = e.target.id.split('-')[1]
			let locaTest = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas
			let idTest = bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia
			try {
				let testNew = []
				let data1 = { idTest: idTest }
				let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadTestPreparador", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;
				
				if (cls1 != undefined && cls1.length > 0) {
					for (var i = 0; i < await cls1.length; i++) {
						testNew.push({
							dudosa: cls1[i].dudosa,
							idTest: cls1[i].idTest, numPreg: i+1, idPreg: cls1[i].idPregunta,
							pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
							idLey: cls1[i].idLey,
							siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
							nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
							idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
							idClase: cls1[i].idClase != null ? (cls1[i].idClase) : (""),
							idPrg: cls1[i].idPrg != null ? (cls1[i].idPrg) : (""),
							idBloque: cls1[i].idBloque != null ? (cls1[i].idBloque) : (""),
							numBloque: cls1[i].numBloque != null ? (cls1[i].numBloque) : (""),
							numTestBloque: cls1[i].numTestBloque != null ? (cls1[i].numTestBloque) : (""),
							idTema: cls1[i].idTema != null ? (cls1[i].idTema) : (""),
							minutos: '0',
							segundos: '0',
							fecha: cls1[i].fecha,
							puntuacion: cls1[i].puntuacion,
							respuestas: []
						})
						let r1Sbr = cls1[i].R1
						if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
							r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						}

						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR1,
							respuesta: r1Sbr,
							validez: cls1[i].validezR1,
							subrayado: cls1[i].subrayado1,
							nota: cls1[i].nota1,
							valorUser: cls1[i].r1ValorUser
						})
						let r2Sbr = cls1[i].R2
						if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
							r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR2,
							respuesta: r2Sbr,
							validez: cls1[i].validezR2,
							subrayado: cls1[i].subrayado2,
							nota: cls1[i].nota2,
							valorUser: cls1[i].r2ValorUser
						})
						let r3Sbr = cls1[i].R3
						if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
							r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR3,
							respuesta: r3Sbr,
							validez: cls1[i].validezR3,
							subrayado: cls1[i].subrayado3,
							nota: cls1[i].nota3,
							valorUser: cls1[i].r3ValorUser
						})
						let r4Sbr = cls1[i].R4
						if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
							r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR4,
							respuesta: r4Sbr,
							validez: cls1[i].validezR4,
							subrayado: cls1[i].subrayado4,
							nota: cls1[i].nota4,
							valorUser: cls1[i].r4ValorUser
						})
					}
					if (testNew != undefined) {


						let testRespuestasBien = await comprobarRespuestas(testNew)

						await this.props.loadTestJusticia(undefined)
						await this.props.loadTestJusticia(testRespuestasBien)

						let ventana = window.open(
							'https://oposiciones-justicia.es/revisionTest',
							'_blank'
						);

						ventana.focus();
					}
				}
			} catch (e) { console.log(e) }
		} else if (e.target.id.split('-')[0] == 'verCaso') {
			try {

				// let testPuntuaciones = this.state.casoPracticoHistorial
				// let numExpediente = await testPuntuaciones.findIndex(t => t.idExp == this.state.ejerLoadIdExp)
				// let busquedaLeyes = this.state.busquedaLeyes
				// let idTest = await testPuntuaciones[numExpediente].idTest
				// let idExp = await testPuntuaciones[numExpediente].idExp



				let busquedaLeyes = this.state.busquedaLeyes
				let locaBloque = e.target.id.split('-')[1]
				let locaTest = e.target.id.split('-')[2]
				let bloqueTemas = this.state.bloqueTemas
				let idTest = bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP

				let testNew = []
				let htmlText = undefined
				let data1 = { idTest: idTest }
				let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoLoadCPPreparador", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;

				if (cls1 != undefined && cls1.length > 0) {
					htmlText = cls1[0].textHtml
					for (var i = 0; i < await cls1.length; i++) {
						testNew.push({
							dudosa: cls1[i].dudosa,
							idTest: cls1[i].idTest, numPreg: i+1, idPreg: cls1[i].idPregunta,
							pregunta: cls1[i].pregunta, tipoPregunta: cls1[i].tipoPregunta, articulo: cls1[i].articulo,
							idLey: cls1[i].idLey,
							siglasLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).siglas_ley,
							nombreLey: busquedaLeyes.find(l => l.id_ley == cls1[i].idLey).nombre_ley,
							idR1: cls1[i].idR1, idR2: cls1[i].idR2, idR3: cls1[i].idR3, idR4: cls1[i].idR4,
							idClase: cls1[i].idClase != null ? (cls1[i].idClase) : (""),
							idPrg: cls1[i].idPrg != null ? (cls1[i].idPrg) : (""),
							idBloque: cls1[i].idBloque != null ? (cls1[i].idBloque) : (""),
							numBloque: cls1[i].numBloque != null ? (cls1[i].numBloque) : (""),
							numTestBloque: cls1[i].numTestBloque != null ? (cls1[i].numTestBloque) : (""),
							idTema: cls1[i].idTema != null ? (cls1[i].idTema) : (""),
							minutos: '0',
							segundos: '0',
							fecha: cls1[i].fecha,
							puntuacion: cls1[i].puntuacion,
							respuestas: []
						})
						let r1Sbr = cls1[i].R1
						if (cls1[i].subrayado1 != '' && cls1[i].subrayado1 != undefined) {
							r1Sbr = await respuestaSbr(cls1[i].R1, cls1[i].subrayado1)
						}

						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR1,
							respuesta: r1Sbr,
							validez: cls1[i].validezR1,
							subrayado: cls1[i].subrayado1,
							nota: cls1[i].nota1,
							valorUser: cls1[i].r1ValorUser
						})
						let r2Sbr = cls1[i].R2
						if (cls1[i].subrayado2 != '' && cls1[i].subrayado2 != undefined) {
							r2Sbr = await respuestaSbr(cls1[i].R2, cls1[i].subrayado2)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR2,
							respuesta: r2Sbr,
							validez: cls1[i].validezR2,
							subrayado: cls1[i].subrayado2,
							nota: cls1[i].nota2,
							valorUser: cls1[i].r2ValorUser
						})
						let r3Sbr = cls1[i].R3
						if (cls1[i].subrayado3 != '' && cls1[i].subrayado3 != undefined) {
							r3Sbr = await respuestaSbr(cls1[i].R3, cls1[i].subrayado3)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR3,
							respuesta: r3Sbr,
							validez: cls1[i].validezR3,
							subrayado: cls1[i].subrayado3,
							nota: cls1[i].nota3,
							valorUser: cls1[i].r3ValorUser
						})
						let r4Sbr = cls1[i].R4
						if (cls1[i].subrayado4 != '' && cls1[i].subrayado4 != undefined) {
							r4Sbr = await respuestaSbr(cls1[i].R4, cls1[i].subrayado4)
						}
						testNew[testNew.length - 1].respuestas.push({
							idR: cls1[i].idR4,
							respuesta: r4Sbr,
							validez: cls1[i].validezR4,
							subrayado: cls1[i].subrayado4,
							nota: cls1[i].nota4,
							valorUser: cls1[i].r4ValorUser
						})
					}
					if (testNew != undefined && htmlText != undefined) {
						let testRespuestasBien = await comprobarRespuestas(testNew)
						//console.log(testRespuestasBien)
						// borrar caso y test load!
						await this.props.loadCasoPractico(undefined, 'htmlText')
						await this.props.loadCasoPractico(testRespuestasBien, htmlText)
						let ventana = window.open(
							'https://oposiciones-justicia.es/revisionTestPractico',
							'_blank'
						);

						ventana.focus();
					}
				}
			} catch (e) { console.log(e) }

		} else if (e.target.id.split('-')[0] == 'adelantarBloqueTema') {

			let locaBloque = parseInt(e.target.id.split('-')[1])
			let locaTema = parseInt(e.target.id.split('-')[2])
			let posSiguiente = locaTema + 1
			let bloqueTemas = this.state.bloqueTemas

			let bloqueSave = bloqueTemas[locaBloque].temas[locaTema]

			// idBloque --> cambiar al siguiente o al anterior
			// idBloqueCambio
			if (bloqueTemas[locaBloque].temas.length > posSiguiente) {
				swal({
					title: 'Cambio tema de posición',
					text: 'Relizando cambio',
					icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
				})

				bloqueTemas[locaBloque].temas.splice(locaTema, 1)
				bloqueTemas[locaBloque].temas.splice(posSiguiente, 0, bloqueSave)

				bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio = bloqueSave.idTemaPrg
				bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio = bloqueTemas[locaBloque].temas[locaTema].idTemaPrg

				let data = { idBloque: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, idBloqueCambio: 0 }
				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				let data2 = { idBloque: bloqueTemas[locaBloque].temas[posSiguiente].idTemaPrg, idBloqueCambio: 1 }
				let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
				});
				if (!response2.ok) {
					throw Error(response2.statusText);
				}
				let json2 = await response2.json();
				let cls2 = await json2;


				let data3 = { idBloque: 0, idBloqueCambio: bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio }
				let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
				});
				if (!response3.ok) {
					throw Error(response3.statusText);
				}
				let json3 = await response3.json();
				let cls3 = await json3;

				let data4 = { idBloque: 1, idBloqueCambio: bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio }
				let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
				});
				if (!response4.ok) {
					throw Error(response4.statusText);
				}
				let json4 = await response4.json();
				let cls4 = await json4;


				// bloqueTemas[locaBloque].idBloque = bloqueTemas[locaBloque].idBloqueCambio
				// bloqueTemas[posSiguiente].idBloque = bloqueTemas[posSiguiente].idBloqueCambio

				bloqueTemas[locaBloque].temas[locaTema].idTemaPrg = bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio
				bloqueTemas[locaBloque].temas[posSiguiente].idTemaPrg = bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio

				bloqueTemas[locaBloque].idBloqueCambio = null
				bloqueTemas[posSiguiente].idBloqueCambio = null

				this.setState({ bloqueTemas: bloqueTemas })
				swal.close()
			}

		} else if (e.target.id.split('-')[0] == 'atrasarBloqueTema') {

			let locaBloque = parseInt(e.target.id.split('-')[1])
			let locaTema = parseInt(e.target.id.split('-')[2])
			let posSiguiente = locaTema - 1
			let bloqueTemas = this.state.bloqueTemas

			let bloqueSave = bloqueTemas[locaBloque].temas[locaTema]

			// idBloque --> cambiar al siguiente o al anterior
			// idBloqueCambio

			if (bloqueTemas[locaBloque].temas.length > posSiguiente && posSiguiente >= 0) {
				swal({
					title: 'Cambio tema de posición',
					text: 'Relizando cambio',
					icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
					buttons: false,
					closeOnEsc: false,
					closeOnClickOutside: false,
				})

				bloqueTemas[locaBloque].temas.splice(locaTema, 1)
				bloqueTemas[locaBloque].temas.splice(posSiguiente, 0, bloqueSave)

				bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio = bloqueSave.idTemaPrg
				bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio = bloqueTemas[locaBloque].temas[locaTema].idTemaPrg

				let data = { idBloque: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, idBloqueCambio: 0 }
				let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response1.ok) {
					throw Error(response1.statusText);
				}
				let json1 = await response1.json();
				let cls1 = await json1;


				let data2 = { idBloque: bloqueTemas[locaBloque].temas[posSiguiente].idTemaPrg, idBloqueCambio: 1 }
				let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
				});
				if (!response2.ok) {
					throw Error(response2.statusText);
				}
				let json2 = await response2.json();
				let cls2 = await json2;


				let data3 = { idBloque: 0, idBloqueCambio: bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio }
				let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
				});
				if (!response3.ok) {
					throw Error(response3.statusText);
				}
				let json3 = await response3.json();
				let cls3 = await json3;

				let data4 = { idBloque: 1, idBloqueCambio: bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio }
				let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueIdTema", {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
				});
				if (!response4.ok) {
					throw Error(response4.statusText);
				}
				let json4 = await response4.json();
				let cls4 = await json4;


				// bloqueTemas[locaBloque].idBloque = bloqueTemas[locaBloque].idBloqueCambio
				// bloqueTemas[posSiguiente].idBloque = bloqueTemas[posSiguiente].idBloqueCambio

				bloqueTemas[locaBloque].temas[locaTema].idTemaPrg = bloqueTemas[locaBloque].temas[locaTema].idBloqueCambio
				bloqueTemas[locaBloque].temas[posSiguiente].idTemaPrg = bloqueTemas[locaBloque].temas[posSiguiente].idBloqueCambio

				bloqueTemas[locaBloque].idBloqueCambio = null
				bloqueTemas[posSiguiente].idBloqueCambio = null

				this.setState({ bloqueTemas: bloqueTemas })
				swal.close()
			}
		} else if (e.target.id.split('-')[0] == 'reajustarNota') {
			let locaBloque = e.target.id.split('-')[1]
			let locaTema = e.target.id.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas

			let nota = bloqueTemas[locaBloque].temas[locaTema].nota

			let data = {
				notaPreparador: nota,
				idTema: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg
			}
			let urlFetX = 'https://oposiciones-justicia.es/api/temas/updateNotaP'

			let response = await fetch(urlFetX, {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
			});
			if (!response.ok) {
				throw Error(response.statusText);
			}
			let json = await response.json();
			let cls = await json;

			let data6 = { idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg, nota: '' }
			let response6 = await fetch("https://oposiciones-justicia.es/api/preparador/edtNotaTemaBloq", {
				method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data6)
			});
			if (!response6.ok) {
				throw Error(response6.statusText);
			}
			let json6 = await response6.json();
			let cls6 = await json6;

			bloqueTemas[locaBloque].temas[locaTema].nota = ''
			this.setState({bloqueTemas: bloqueTemas})

		}
		// else if (e.target.id.split('-')[0] == 'atrasarBloque') {

		// 	let locaBloque = parseInt(e.target.id.split('-')[1])
		// 	let posSiguiente = locaBloque - 1
		// 	let bloqueTemas = this.state.bloqueTemas
		// 	let bloqueSave = bloqueTemas[locaBloque]

		// 	if (bloqueTemas.length > posSiguiente && posSiguiente >= 0) {
		// 		swal({
		// 			title: 'Cambio bloque de posición',
		// 			text: 'Relizando cambio',
		// 			icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
		// 			buttons: false,
		// 			closeOnEsc: false,
		// 			closeOnClickOutside: false,
		// 		})
		// 		await bloqueTemas.splice(locaBloque, 1)
		// 		await bloqueTemas.splice(posSiguiente, 0, bloqueSave)

		// 		bloqueTemas[locaBloque].idBloqueCambio = bloqueSave.idBloque
		// 		bloqueTemas[posSiguiente].idBloqueCambio = bloqueTemas[locaBloque].idBloque

		// 		// actualizar en bd y actualizar idBloques
		// 		// poner el primero como 0 y el segundo en 1

		// 		let data = { idBloque: bloqueTemas[locaBloque].idBloque, idBloqueCambio: 0 }
		// 		let response1 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
		// 			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
		// 		});
		// 		if (!response1.ok) {
		// 			throw Error(response1.statusText);
		// 		}
		// 		let json1 = await response1.json();
		// 		let cls1 = await json1;


		// 		let data2 = { idBloque: bloqueTemas[posSiguiente].idBloque, idBloqueCambio: 1 }
		// 		let response2 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
		// 			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
		// 		});
		// 		if (!response2.ok) {
		// 			throw Error(response2.statusText);
		// 		}
		// 		let json2 = await response2.json();
		// 		let cls2 = await json2;


		// 		let data3 = { idBloque: 0, idBloqueCambio: bloqueTemas[locaBloque].idBloqueCambio }
		// 		let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
		// 			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
		// 		});
		// 		if (!response3.ok) {
		// 			throw Error(response3.statusText);
		// 		}
		// 		let json3 = await response3.json();
		// 		let cls3 = await json3;

		// 		let data4 = { idBloque: 1, idBloqueCambio: bloqueTemas[posSiguiente].idBloqueCambio }
		// 		let response4 = await fetch("https://oposiciones-justicia.es/api/preparador/updateBloqueId", {
		// 			method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
		// 		});
		// 		if (!response4.ok) {
		// 			throw Error(response4.statusText);
		// 		}
		// 		let json4 = await response4.json();
		// 		let cls4 = await json4;

		// 		bloqueTemas[locaBloque].idBloque = bloqueTemas[locaBloque].idBloqueCambio
		// 		bloqueTemas[posSiguiente].idBloque = bloqueTemas[posSiguiente].idBloqueCambio

		// 		bloqueTemas[locaBloque].idBloqueCambio = null
		// 		bloqueTemas[posSiguiente].idBloqueCambio = null

		// 		this.setState({ bloqueTemas: bloqueTemas })
		// 		swal.close()
		// 	}

		// }



	}

	async onChangeInput(e) {

		if (e.target.name.split('-')[0] == 'tipoBloque') {
			let locaBloque = e.target.name.split('-')[1]
			let bloqueTemas = this.state.bloqueTemas
			if (e.target.value == 'Normal') {
				bloqueTemas[locaBloque].tipoBloque = 0
				bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
				let data = {
					idBloque: bloqueTemas[locaBloque].idBloque,
					tipoBloque: 0
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateBloqueTipo', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ bloqueTemas: bloqueTemas })



			} else if (e.target.value == "Repaso") {
				bloqueTemas[locaBloque].tipoBloque = 1
				bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
				let data = {
					idBloque: bloqueTemas[locaBloque].idBloque,
					tipoBloque: 1
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateBloqueTipo', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ bloqueTemas: bloqueTemas })
			} else {
				bloqueTemas[locaBloque].tipoBloque = undefined
				bloqueTemas[locaBloque].tipoBloqueTitulo = e.target.value
				this.setState({ bloqueTemas: bloqueTemas })
			}
		} else if (e.target.name.split('-')[0] == 'temaName') {
			try {
				// console.log('this.state.busquedaTemasOposicion')
				// console.log(this.state.busquedaTemasOposicion)
				let val = e.target.value
				let locaBloque = e.target.name.split('-')[1]
				let locaTema = e.target.name.split('-')[2]
				let objectTemas = await this.state.busquedaTemasOposicion.filter(t => t.tituloTema == e.target.value)

				let bloqueTemas = this.state.bloqueTemas

				if (await objectTemas.length > 0) {
					if (bloqueTemas[locaBloque].temas.filter(c => c.idTema == objectTemas[0].idTema).length == 0) {


						if (bloqueTemas[locaBloque].temas[locaTema].nuevo == 0) {
							//editamos

							let data = {
								idTemaPrg: bloqueTemas[locaBloque].temas[locaTema].idTemaPrg,
								idTema: objectTemas[0].idTema
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateBloqueTema', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;
							bloqueTemas[locaBloque].temas[locaTema].idTema = objectTemas[0].idTema
							bloqueTemas[locaBloque].temas[locaTema].tituloTema = objectTemas[0].nombreTema
							bloqueTemas[locaBloque].temas[locaTema].nombreTema = objectTemas[0].tituloTema
						} else {
							// insertamos y cambiamos los valores de nuevo a 0 no en 1 y idTemaPrg
							let data = {
								idBloque: bloqueTemas[locaBloque].idBloque,
								idPrg: this.state.infoSemanaPrg.idPrg,
								idTema: objectTemas[0].idTema
							}

							let response = await fetch('https://oposiciones-justicia.es/api/preparador/addBloqueTema', {
								method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
							});
							if (!response.ok) {
								throw Error(response.statusText);
							}
							let json = await response.json();
							let cls = await json;

							bloqueTemas[locaBloque].temas[locaTema].idTemaPrg = cls.insertId
							bloqueTemas[locaBloque].temas[locaTema].nuevo = 0
							bloqueTemas[locaBloque].temas[locaTema].idTema = objectTemas[0].idTema
							bloqueTemas[locaBloque].temas[locaTema].tituloTema = objectTemas[0].nombreTema
							bloqueTemas[locaBloque].temas[locaTema].nombreTema = objectTemas[0].tituloTema
						}
					} else {
						swal('Tema duplicado', 'No puede insertar 2 veces el mismo tema', 'warning')
					}
					this.setState({ bloqueTemas: bloqueTemas })

				} else {


					bloqueTemas[locaBloque].temas[locaTema].idTema = undefined
					bloqueTemas[locaBloque].temas[locaTema].tituloTema = undefined
					bloqueTemas[locaBloque].temas[locaTema].nombreTema = val

					this.setState({ bloqueTemas: bloqueTemas })
				}


			} catch (e) { console.log(e) }
		} else if (e.target.name.split('-')[0] == 'testJusAñadir') {
			let value = e.target.value
			let locaBloque = e.target.name.split('-')[1]
			let locaTest = e.target.name.split('-')[2]
			let bloqueTemas = this.state.bloqueTemas
			let objectTest = this.state.busquedaTestPre.find(t => t.nombre_test == e.target.value)

			if ((bloqueTemas[locaBloque].testJusticia[locaTest].nuevo == undefined || bloqueTemas[locaBloque].testJusticia[locaTest].nuevo == 0) && bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia != undefined) {

				bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave = await bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia

			}

			if (objectTest != undefined) {
				if (bloqueTemas[locaBloque].testJusticia.filter(c => c.idTestJusticia == objectTest.id_test).length == 0) {

					if (bloqueTemas[locaBloque].testJusticia[locaTest].nuevo == undefined || bloqueTemas[locaBloque].testJusticia[locaTest].nuevo == 0) {
						// editar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id_test,
							idTestSave: bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateTestTeoBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia = objectTest.id_test
						bloqueTemas[locaBloque].testJusticia[locaTest].nombre = objectTest.nombre_test
						bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave = null
						bloqueTemas[locaBloque].testJusticia[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					} else {
						// insertar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id_test,
							idTestSave: bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/addTestTeoBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia = objectTest.id_test
						bloqueTemas[locaBloque].testJusticia[locaTest].nombre = objectTest.nombre_test
						bloqueTemas[locaBloque].testJusticia[locaTest].idTestSave = null
						bloqueTemas[locaBloque].testJusticia[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					}
				} else {
					swal('Ejercicio duplicado', 'No puede añadir 2 ejercicios iguales', 'warning')
				}
			} else {

				bloqueTemas[locaBloque].testJusticia[locaTest].idTestJusticia = undefined
				bloqueTemas[locaBloque].testJusticia[locaTest].nombre = value
				this.setState({ bloqueTemas: bloqueTemas })
			}
		} else if (e.target.name.split('-')[0] == 'casoPracAñadir') {
			let value = e.target.value
			let locaBloque = e.target.name.split('-')[1]
			let locaTest = e.target.name.split('-')[2]

			let objectTest = this.state.busquedaTestPreCasoPractico.find(t => t.nombre_cp == value)
			let bloqueTemas = this.state.bloqueTemas


			if ((bloqueTemas[locaBloque].casoPractico[locaTest].nuevo == undefined || bloqueTemas[locaBloque].casoPractico[locaTest].nuevo == 0) && bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP != undefined) {

				bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave = await bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP

			}

			if (objectTest != undefined) {
				if (bloqueTemas[locaBloque].casoPractico.filter(c => c.idTestCP == objectTest.id_caso_practico).length == 0) {


					if (bloqueTemas[locaBloque].casoPractico[locaTest].nuevo == undefined || bloqueTemas[locaBloque].casoPractico[locaTest].nuevo == 0) {
						// editar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id_caso_practico,
							idTestSave: bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateTestPracticoBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = objectTest.id_caso_practico
						bloqueTemas[locaBloque].casoPractico[locaTest].nombre = objectTest.nombre_cp
						bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave = null
						bloqueTemas[locaBloque].casoPractico[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					} else {
						// insertar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id_caso_practico,
							idTestSave: bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/addTestPracticoBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = objectTest.id_caso_practico
						bloqueTemas[locaBloque].casoPractico[locaTest].nombre = objectTest.nombre_cp
						bloqueTemas[locaBloque].casoPractico[locaTest].idTestSave = null
						bloqueTemas[locaBloque].casoPractico[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					}
				} else {
					swal('Ejercicio duplicado', 'No puede añadir 2 ejercicios iguales', 'warning')
				}
			} else {

				bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = undefined
				bloqueTemas[locaBloque].casoPractico[locaTest].nombre = value
				this.setState({ bloqueTemas: bloqueTemas })
			}

			// if (objectTest != undefined) {
			// 	let bloqueTemas = this.state.bloqueTemas
			// 	bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = objectTest.id_caso_practico
			// 	bloqueTemas[locaBloque].casoPractico[locaTest].nombre = objectTest.nombre_cp
			// 	this.setState({ bloqueTemas: bloqueTemas })
			// } else {

			// 	let bloqueTemas = this.state.bloqueTemas
			// 	bloqueTemas[locaBloque].casoPractico[locaTest].idTestCP = undefined
			// 	bloqueTemas[locaBloque].casoPractico[locaTest].nombre = value
			// 	this.setState({ bloqueTemas: bloqueTemas })

			// }
		} else if (e.target.name.split('-')[0] == 'examenGestionPdfAñadir') {


			let value = e.target.value
			let locaBloque = e.target.name.split('-')[1]
			let locaTest = e.target.name.split('-')[2]

			let objectTest = this.state.loadExamanesGestion.find(l => l.nombre == value)
			let bloqueTemas = this.state.bloqueTemas


			if ((bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo == undefined || bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo == 0) && bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion != undefined) {

				bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave = await bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion

			}

			if (objectTest != undefined) {
				if (bloqueTemas[locaBloque].examenGestionPdf.filter(c => c.idExamenGestion == objectTest.id).length == 0) {
					if (bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo == undefined || bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo == 0) {
						// editar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id,
							idTestSave: bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateEjerDesarrolloBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = objectTest.id
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].nombre = objectTest.nombre
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave = null
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					} else {
						// insertar
						let data = {
							idBloque: bloqueTemas[locaBloque].idBloque,
							idTest: objectTest.id,
							idTestSave: bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave
						}

						let response = await fetch('https://oposiciones-justicia.es/api/preparador/addEjerDesarrolloBloque', {
							method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
						});
						if (!response.ok) {
							throw Error(response.statusText);
						}
						let json = await response.json();
						let cls = await json;

						bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = objectTest.id
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].nombre = objectTest.nombre
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].idTestSave = null
						bloqueTemas[locaBloque].examenGestionPdf[locaTest].nuevo = 0
						this.setState({ bloqueTemas: bloqueTemas })
					}
				} else {
					swal('Ejercicio duplicado', 'No puede añadir 2 ejercicios iguales', 'warning')
				}
			} else {

				bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = undefined
				bloqueTemas[locaBloque].examenGestionPdf[locaTest].nombre = value
				this.setState({ bloqueTemas: bloqueTemas })
			}


			// if (objectExamGes != undefined) {
			// 	let bloqueTemas = this.state.bloqueTemas
			// 	bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = objectExamGes.id
			// 	bloqueTemas[locaBloque].examenGestionPdf[locaTest].nombre = objectExamGes.nombre
			// 	this.setState({ bloqueTemas: bloqueTemas })
			// } else {
			// 	let bloqueTemas = this.state.bloqueTemas
			// 	bloqueTemas[locaBloque].examenGestionPdf[locaTest].idExamenGestion = undefined
			// 	bloqueTemas[locaBloque].examenGestionPdf[locaTest].nombre = value
			// 	this.setState({ bloqueTemas: bloqueTemas })
			// }

		} else if (e.target.id == 'fechaClaseDirecto') {

			let value = e.target.value
			let infoSemanaPrg = this.state.infoSemanaPrg

			if (value.length > 0) {

				infoSemanaPrg.horaClaseOnline = value

				let data = {
					idPrg: infoSemanaPrg.idPrg,
					fecha: value
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateFechaDirecto', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ infoSemanaPrg: infoSemanaPrg })

			} else {

				infoSemanaPrg.horaClaseOnline = value

				let data = {
					idPrg: infoSemanaPrg.idPrg,
					fecha: null
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateFechaDirecto', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ infoSemanaPrg: infoSemanaPrg })

			}
		} else if (e.target.id == 'linkClaseDirecto') {

			let value = e.target.value
			let infoSemanaPrg = this.state.infoSemanaPrg

			if (value.length > 0 || value != '') {

				infoSemanaPrg.linkClaseOnline = value

				let data = {
					idPrg: infoSemanaPrg.idPrg,
					link: value
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateLinkDirecto', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ infoSemanaPrg: infoSemanaPrg })

			} else {

				infoSemanaPrg.linkClaseOnline = ''

				let data = {
					idPrg: infoSemanaPrg.idPrg,
					link: null
				}

				let response = await fetch('https://oposiciones-justicia.es/api/preparador/updateLinkDirecto', {
					method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
				});
				if (!response.ok) {
					throw Error(response.statusText);
				}
				let json = await response.json();
				let cls = await json;

				this.setState({ infoSemanaPrg: infoSemanaPrg })

			}
		}

	}


	render() {
		const { busquedaTemasOposicion, busquedaLeyes, busquedaTestPre, busquedaTestPreCasoPractico, busquedaOpo, listaSemanasClase, busquedaWordPDF, loadExamanesGestion, navS, navS2, infoSemanaPrg, bloqueTemas } = this.state;
		// const { infoSemanaPrg, bloqueTemas } = this.props;

		return (

			<div className="ColCenterStart" style={{ marginTop: '25px' }}>
				<h3 style={{ color: '#3469b7', marginBottom: '25px' }}> Configuración clase directo </h3>

				<div className='celdaInputDAPFBN animacionBotonEntra' style={{ width: '225px' }}>
					<Input2 value={infoSemanaPrg.linkClaseOnline} id={'linkClaseDirecto'} type='text' name={'linkClaseDirecto'} placeholder='Link clase directo' icon='fa-regular fa-link' onChange={this.onChangeInput} />
					<p>Url/Link Zoom</p>
				</div>

				<div className="celdaInputDAPFBN animacionBotonEntra" style={{ width: '225px' }}>
					<Input2 value={infoSemanaPrg.horaClaseOnline} type="datetime-local"
						autoComplete={"off"} id={"fechaClaseDirecto"} name={"fechaClaseDirecto"} placeholder="Fecha" icon="fas fa-calendar" onChange={this.onChangeInput} />
					<p>Fecha Clase Directo</p>
				</div>



				<div className="ColCenterStart" style={{ margin: '50px 0px' }}>

					<h3 style={{ color: '#3469b7', marginBottom: '25px' }}> Bloques de Estudio por Temas </h3>

					<Boton id='add_bloque_temas' onClick={this.handleOnClick} icon1='' icon2='' texto='Añadir bloque estudio' />


					<div className="bloqueTemas" style={{ marginTop: '25px' }}>
						{bloqueTemas && bloqueTemas.length > 0 && bloqueTemas.map((bloque, key) =>
							<div className="bloqueTema" key={key}>

								<h3 style={{ color: '#3469b7', marginBottom: '15px', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', justifyContent: 'center' }}>

									{key > 0 ? (<i id={"atrasarBloque-" + key} onClick={this.handleOnClick} className="fa-light fa-angle-left botonDel" style={{ marginRight: '10px' }} ></i>) : null}

									Bloque Nº {key + 1}

									{(key + 1) < bloqueTemas.length ? (<i id={"adelantarBloque-" + key} onClick={this.handleOnClick} className="fa-light fa-angle-right botonDel" style={{ marginLeft: '10px' }}></i>) : null}
								</h3>

								{bloque.idExamenGestion != null && bloque.idExamenGestion != 1 && bloque.idExamenGestion != 0 ? (
									<div className="zonaTestBT" style={{ marginBottom: '20px', width: '310px' }}>
										<p style={{
											marginBottom: '10px', color: '#af1515',
											fontSize: '17px',
											fontWeight: '500'
										}}> Ejercicio desarrollo aplicado en modo antiguo, haga clic en reajustar para añadirlo al nuevo sistema.</p>
										<Boton id={"reajustarExamen-" + key} icon1="" icon2="" texto="Reajustar Ejercicio" onClick={this.handleOnClick} />
									</div>
								) : null}


								<div className="zonaTestBT">
									<Boton id={"delBloqueTema-" + key} icon1="" icon2="" texto="Borrar Bloque" onClick={this.handleOnClick} />

									<div className='celdaInputDAPFBN animacionBotonEntra' style={{ marginTop: '40px' }}>
										<Input2 value={bloque.tipoBloqueTitulo} datalist='tipoBloque' autoComplete={'off'} id={"tipoBloque-" + key} type='text' name={"tipoBloque-" + key} ref={"ref_tipoBloque-" + key} placeholder='Tipo Bloque' icon='fas fa-info-circle' onChange={this.onChangeInput} />
										<p>Tipo de bloque</p>
									</div>

									<div className='ColCenterStart adb-nav'>
										<div className='RowCenterCenter' style={{ margin: '10px' }}>
											<div className={navS == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-0' onClick={this.handleOnClick}>
												Temas
											</div>
											<div className={navS == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav-1' onClick={this.handleOnClick}>
												Ejercicios
											</div>
										</div>
									</div>

								</div>

								{navS == 1 ? (
									<div className='ColCenterStart ejerciciosSemCreate'>
										<div className='ColCenterStart adb-nav' style={{ maxWidth: '300px' }}>
											<div className='RowCenterCenter' style={{ margin: '10px' }}>
												<div className={navS2 == 0 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-0' onClick={this.handleOnClick}>
													Test Teórico
												</div>
												<div className={navS2 == 1 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-1' onClick={this.handleOnClick}>
													Test Práctico
												</div>
												<div className={navS2 == 2 ? 'navSelected' : 'navSelected navNoSelected'} id='nav2-2' onClick={this.handleOnClick}>
													Examen Desarrollo
												</div>
											</div>
										</div>

										{navS2 == 0 ? (

											<div className='ColCenterStart'>
												<Boton id={"addTestJusticia-" + key} icon1="" icon2="" texto="Añadir Test Teórico" onClick={this.handleOnClick} />

												{bloque && bloque.testJusticia && bloque.testJusticia.length > 0 && bloque.testJusticia.map((test, key22) =>
													<div className="bloqTestJus" style={{ marginTop: '20px' }} key={key22}>

														<div className='celdaInputDAPFBN animacionBotonEntra'>
															<Input2 value={test.nombre} type="text" id={"testJusAñadir-" + key + "-" + key22} name={"testJusAñadir-" + key + "-" + key22} placeholder="Test Justicia" icon="fas fa-search" datalist="busquedaTestPre" ref={"ref_testJusAñadir-" + key + "-" + key22} onChange={this.onChangeInput} />

															<p>Test Teoría</p>
														</div>
														<i title="Eliminar Test" id={'delTestJus-' + key + '-' + key22} onClick={this.handleOnClick} className="fa-duotone fa-trash botonDel"></i>
														{
															test.idTestJusticia != undefined ? (
																<i title="Ver Test" id={'verTestJus-' + key + '-' + key22} onClick={this.handleOnClick} className="fa-duotone fa-eye botonDel" style={{ marginLeft: '15px' }}></i>
															) : null
														}

													</div>
												)}


											</div>

										) : null}

										{navS2 == 1 ? (
											<div className='ColCenterStart'>
												<Boton id={"addCasoPractico-" + key} icon1="" icon2="" texto="Añadir Caso Práctico" onClick={this.handleOnClick} />

												{bloque && bloque.casoPractico && bloque.casoPractico.length > 0 && bloque.casoPractico.map((test, key223) =>
													<div className="bloqTestJus" style={{ marginTop: '20px' }} key={key223}>


														<div className='celdaInputDAPFBN animacionBotonEntra'>
															<Input2 value={test.nombre} type="text" id={"casoPracAñadir-" + key + "-" + key223} name={"casoPracAñadir-" + key + "-" + key223} placeholder="Caso Práctico" icon="fas fa-search" datalist="busquedaTestPreCasoPractico" ref={"ref_casoPracAñadir-" + key + "-" + key223} onChange={this.onChangeInput} />
															<p>Test Práctico</p>
														</div>
														<i title="Eliminar " id={'delCaso-' + key + '-' + key223} onClick={this.handleOnClick} className="fa-duotone fa-trash botonDel"></i>
														{
															test.idTestCP != undefined ? (
																<i title="Ver Caso práctico" id={'verCaso-' + key + '-' + key223} onClick={this.handleOnClick} className="fa-duotone fa-eye botonDel" style={{ marginLeft: '15px' }}></i>
															) : null
														}
													</div>
												)}
											</div>
										) : null}

										{navS2 == 2 ? (
											// <div>
											//   <Input type="text" name={"examenGestionPdf-" + key} placeholder="Examen Gestión" icon="fas fa-search" datalist="busquedaExamenGestion" ref={"ref_examenGestionPdf-" + key} onChange={this.onChangeInput} />
											// </div>

											<div className='ColCenterStart'>
												<Boton id={"addexamenGestionPdf-" + key} icon1="" icon2="" texto="Añadir Examen" onClick={this.handleOnClick} />

												{bloque && bloque.examenGestionPdf && bloque.examenGestionPdf.length > 0 && bloque.examenGestionPdf.map((test, key223) =>
													<div className="bloqTestJus" style={{ marginTop: '20px' }} key={key223}>

														<div className='celdaInputDAPFBN animacionBotonEntra'>
															<Input2 value={test.nombre} type="text" name={"examenGestionPdfAñadir-" + key + "-" + key223} placeholder="Examen Desarrollo" icon="fas fa-search" datalist="busquedaExamenGestion" ref={"ref_examenGestionPdfAñadir-" + key + "-" + key223} onChange={this.onChangeInput} />
															<p>Examen Desarrollo</p>
														</div>
														<i title="Eliminar " id={'delexamenGestionPdf-' + key + '-' + key223} onClick={this.handleOnClick} className="fa-duotone fa-trash botonDel"></i>



													</div>
												)}
											</div>
										) : null}





									</div>
								) : null}

								{navS == 0 ? (
									<div className="zonaTemas">
										<Boton id={"add_tema_bloque-" + key} icon1="" icon2="" texto="Añadir tema" onClick={this.handleOnClick} />
										{bloque && bloque.temas.length > 0 && bloque.temas.map((tema, key2) =>
											<div className="temaBody" key={key2} style={{ marginBottom: '0px' }}>

												<div className="tituloTema" style={{ marginTop: '10px', marginBottom: '5px', maxWidth: '300px' }}>
													<div className='ColCenterStart' style={{ marginRight: '10px' }}>
														{key2 > 0 ? (<i id={"atrasarBloqueTema-" + key + "-" + key2} onClick={this.handleOnClick} className="fa-light fa-angle-up botonDel" ></i>) : null}

														{(key2 + 1) < bloque.temas.length ? (<i id={"adelantarBloqueTema-" + key + "-" + key2} onClick={this.handleOnClick} className="fa-light fa-angle-down botonDel"></i>) : null}
													</div>
													{tema && tema.idTema != undefined ? (
														<h3 id={"verTema-" + key + "-" + key2} className="tituloTemaEdtPrg" onClick={this.handleOnClick}>
															{tema.nombreTema}
														</h3>

													) : ('Eliga un tema')}

													<i title="Eliminar tema" style={{ marginLeft: '15px' }} id={"borrarTema-" + key + "-" + key2} onClick={this.handleOnClick} className="fa-duotone fa-trash botonDel"></i>

												</div>

												{tema.nota != null && tema.nota != '' && tema.nota != 0 ? (
													<div className="zonaTestBT" style={{ marginBottom: '20px', width: '310px' }}>
														<p style={{
															marginBottom: '10px', color: '#af1515',
															fontSize: '17px',
															fontWeight: '500'
														}}> Nota del tema en modo antiguo, haga clic en reajustar para añadirla al nuevo sistema.</p>
														<Boton id={"reajustarNota-" + key + "-" + key2} icon1="" icon2="" texto="Reajustar nota" onClick={this.handleOnClick} />
													</div>
												) : null}

												<div className='celdaInputDAPFBN animacionBotonEntra'>
													<Input2 value={tema.nombreTema} type="text" autoComplete="transaction-amount" name={"temaName-" + key + "-" + key2} placeholder="Tema" icon="fas fa-search" datalist="busquedaTemasOposicion" ref={"ref_temaName-" + key + "-" + key2} onChange={this.onChangeInput} />
													<p>Tema</p>
												</div>

												<datalist id='busquedaTemasOposicion'>{
													busquedaTemasOposicion && busquedaTemasOposicion.map((tema, key100) =>
														<option key={key100} value={tema.tituloTema}>{'Id: ' + tema.idTema + ' (' + tema.nombreTema + ')'}</option>
													)
												}</datalist>


												{tema && tema.idTema != undefined ? (
													<div id={"viewTema-" + key + "-" + key2} className="nonViewTemaPrg">
														<p> {tema.tituloTema} </p>
														<TemaSeleted idTema={tema.idTema} />
													</div>
												) : null}


											</div>
										)}
									</div>
								) : null}


							</div>
						)}
					</div>
				</div>











				<datalist id="tipoBloque">
					<option value="Normal"> </option>
					<option value="Repaso"> </option>
				</datalist>

				<datalist id="busquedaOpo">{
					busquedaOpo && busquedaOpo.map((oposicion, key) =>
						<option key={key} value={oposicion.nombre}></option>
					)
				}</datalist>


				<datalist id="busquedaTestPre">{
					busquedaTestPre && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPre.filter(t => t.id_oposicion == 4).map((test, key) =>
						<option key={key} value={test.nombre_test}></option>
					)
				}</datalist>
				<datalist id="busquedaTestPreCasoPractico">{
					busquedaTestPreCasoPractico && infoSemanaPrg && infoSemanaPrg.idOposicion != undefined && busquedaTestPreCasoPractico.filter(t => t.id_oposicion == 4).map((test, key) =>
						<option key={key} value={test.nombre_cp}></option>
					)
				}</datalist>

				<datalist id="busquedaExamenGestion">{
					loadExamanesGestion && loadExamanesGestion.map((test, key) =>
						<option key={key} value={test.nombre}></option>
					)
				}</datalist>

			</div>

		);
	}
}


// loadTestJusticia

// Estados del store

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadTestJusticia,
		loadCasoPractico
	}, dispatch)
}

export default connect(null, mapDispatchToProps)(PrgEdtSelected);

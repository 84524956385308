// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Utils
import './css.css'
import { isDefined } from '../../lib/utils/is';
//Componets
import { Redirect } from 'react-router-dom';

import Input2 from '../Inputs/Input2';
import Boton from '../Inputs/Boton';
import BotonFinolis from '../Inputs/Boton3Finolis'

import CajaInfoClase from '../CajaInfoClase'
import moment from 'moment'
// Redux Imports
import { useSelector } from 'react-redux'


const PlanificacionEstudio = (props) => {

  const idUser = useSelector(state => state.user.idUser)


  const allSemFac = useSelector(state => state.user.allSemFac)

  const [semanasLoad, setsemanasLoad] = useState(null)
  const [semanasLoadPorClase, setsemanasLoadPorClase] = useState(null)

  const refSiglasLey = useRef();

  useEffect(() => {
    let allSem = []

    for (let i = 0; i < allSemFac.length; i++) {

      let bus = allSem.findIndex(a => a.numClase == allSemFac[i].numClase)
      if (moment(allSemFac[i].fechaDesbloqueo).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm')) {


        if (bus == -1) {
          allSem.push(allSemFac[i])
        } else {

          if (moment(allSemFac[i].fechaClase).format('YYYY-MM-DD HH:mm') > moment(allSem[bus].fechaClase).format('YYYY-MM-DD HH:mm')) {
            allSem[bus] = allSemFac[i]
          }
        }
      }


    }

    setsemanasLoad(allSem.sort((a, b) => new Date(b.fechaClase) - new Date(a.fechaClase)))

    return () => {

    }
  }, [])


  const onHandleClick = async (e) => {
    if (e.target.id == 'orderNar') {
      if (semanasLoadPorClase == null) {
        let a = structuredClone(semanasLoad.sort((a, b) => a.numClase - b.numClase))

        setsemanasLoad(a)
        setsemanasLoadPorClase(true)
      } else {
        let a = structuredClone(semanasLoad.sort((a, b) => new Date(b.fechaClase) - new Date(a.fechaClase)))

        setsemanasLoad(a)
        setsemanasLoadPorClase(null)
      }


    }
  }

  const nivelRequired = 1

  // console.log('allSemFac')
  // console.log(allSemFac)
  // console.log('semanasLoad')
  // console.log(semanasLoad)
  return (
    <div className='ColCenterStart' style={{ alignItems: 'flex-start' }}>

      {semanasLoadPorClase == null ? (
        <div className='RowCenterCenter' style={{ justifyContent: 'space-between', padding: '15px', width: '100%' }}>

          {/* <div className='RowCenterCenter' style={{ margin: '15px' }}>
              <Boton id='orderNar' onClick={onHandleClick} icon1='' icon2='' texto='Ordernar por clase' />
            </div> */}

          <div className='RowCenterCenter' style={{ marginRight: '25px' }}>
            <div className='RowCenterCenter circle' style={{ background: '#abeda7', width: '20px', height: '20px', borderRadius: '50px', margin: '15px' }}>

            </div>

            <p> Últimas clases </p>
          </div>


        </div>

      ) : (
        <div className='RowCenterCenter' style={{ justifyContent: 'flex-start', padding: '15px' }}>
          <div className='RowCenterCenter'>
            <div className='RowCenterCenter' style={{ margin: '15px' }}>
              <Boton id='orderNar' onClick={onHandleClick} icon1='' icon2='' texto='Ordernar por fecha' />
            </div>

          </div>


        </div>
      )}
      <div className='RowCenterCenter ' style={{ padding: '15px', color: 'black', maxWidth: '870px' }}>



        {


          tipoUser < 50 && semanasLoad && semanasLoad.slice(0, 4).map((cls, key0) =>
            <div className='RowCenterCenter localgvjkas32' style={
              // moment(cls.fechaClase).format('YYYY-MM') != moment().format('YYYY-MM')
              key0 > 1 || semanasLoadPorClase != null
                ?
                (
                  parseInt(moment(cls.fechaClase).format('M')) % 2 == 0 ? (
                    { padding: '15px', minHeight: '220px' }
                  ) : (
                    { padding: '15px', background: '#dfeafa', minHeight: '220px' }
                  )
                ) : (
                  { padding: '15px', background: '#abeda7', minHeight: '220px' }
                )

            }>
              <CajaInfoClase semanaFacturada={cls} keyProp={key0} actuales={false} />
            </div>
          )


        }
        {


          tipoUser >= 50 && semanasLoad && semanasLoad.map((cls, key0) =>
            <div className='RowCenterCenter localgvjkas32' style={
              // moment(cls.fechaClase).format('YYYY-MM') != moment().format('YYYY-MM')
              key0 > 1 || semanasLoadPorClase != null
                ?
                (
                  parseInt(moment(cls.fechaClase).format('M')) % 2 == 0 ? (
                    { padding: '15px', minHeight: '220px' }
                  ) : (
                    { padding: '15px', background: '#dfeafa', minHeight: '220px' }
                  )
                ) : (
                  { padding: '15px', background: '#abeda7', minHeight: '220px' }
                )

            }>
              <CajaInfoClase semanaFacturada={cls} keyProp={key0} actuales={false} />
            </div>
          )


        }


      </div>


    </div>

  )


}

PlanificacionEstudio.propTypes = {

}



export default PlanificacionEstudio;
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from '../actions/baroptionAction';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import EditorTexto from '../../Inputs/EditorTexto';
import TextoRich from '../../Inputs/TextoRich';
import Submit from '../../Inputs/Submit';
import InputFile from '../../Inputs/InputFile';

class EdtNoticia extends Component {
	static propTypes = {
		noticiaEdt: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
		this.state = {
		};
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e, textHtml = ''){
		if(textHtml != ''){
			this.setState({textHtml: textHtml})
			this.props.onChange(e, textHtml);
		} else {
			this.props.onChange(e);
		}
	}
	handleOnClick(e){
		this.props.onClick(e);
	}
componentDidMount(e){
	if(this.props.noticiaEdt == undefined){
		this.props.cargarContenido("Edt_Noticia_Select")
	} else{
		this.refs.ref_titulo.refs.titulo.value = this.props.noticiaEdt.titulo
		this.refs.ref_noticiaResumen.refs.noticiaResumen.value = this.props.noticiaEdt.noticiaResumen
	}
}
UNSAFE_componentWillMount(e){
	if(this.props.noticiaEdt == undefined){
		this.props.cargarContenido("Edt_Noticia_Select")
	} else{
		this.setState({ textHtmlPrev: this.props.noticiaEdt.noticiaCompleta })
	}
}
	render() {
		const { noticiaEdt } = this.props;
		const { textHtmlPrev } = this.state
			return (

				<div className= "bodyLoadTest">
					{noticiaEdt && noticiaEdt != undefined ? (
						<div>
							<div className="inputLoadTest22">
								<h4>
									<i className="fas fa-info-circle"></i>
									Titulo Noticia
									<i className="fas fa-arrow-alt-circle-down"></i>
								</h4>
								<InputTextArea id="titulo" name="titulo" ref="ref_titulo" valueDefault="¡Pegar test completo aquí!" width="100%" height="5vh" onChange={this.onChange}/>
							</div>

							<div className="inputLoadTest22">
								<h4>
									<i className="fas fa-info-circle"></i>
									Resumen Noticia
									<i className="fas fa-arrow-alt-circle-down"></i>
								</h4>
								<InputTextArea id="noticiaResumen" name="noticiaResumen" ref="ref_noticiaResumen" valueDefault="¡Pegar test completo aquí!" width="100%" height="10vh" onChange={this.onChange}/>
							</div>

							<div className="inputLoadTest">
								<h4>
									<i className="fas fa-info-circle"></i>
									Imagen Tablon
									<i className="fas fa-arrow-alt-circle-down"></i>
								</h4>
								<InputFile texto="Foto tablon" name="fotoTablon" icon1="fas fa-camera" onChange={this.onChange}/>
								{ noticiaEdt && noticiaEdt.imgTablon != '' ? (<img height="300px" src={`${noticiaEdt.imgTablon}`} />):null }
							</div>
							<div className="inputLoadTest">
								<h4>
									<i className="fas fa-info-circle"></i>
									Imagen Noticia
									<i className="fas fa-arrow-alt-circle-down"></i>
								</h4>
								<InputFile texto="Foto Noticia" name="fotoNoticia" icon1="fas fa-camera" onChange={this.onChange}/>
								{ noticiaEdt && noticiaEdt.imgNoticia != '' ? (<img height="300px" src={`${noticiaEdt.imgNoticia}`} />):null }
							</div>


							<div className="inputLoadTest">
								<h4>
									<i className="fas fa-info-circle"></i>
									Noticia Completa
									<i className="fas fa-arrow-alt-circle-down"></i>
								</h4>
								<EditorTexto dataInfoTextHtml={textHtmlPrev} onChange={this.onChange}/>
							</div>



							<div className="textAreaLoadTest">
								<Submit id="editarNoticiaEditada" icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Noticia" onClick={this.handleOnClick}/>
							</div>
						</div>
					):(
					<div className= "bodyLoadTest">
						Noticia no detectada
					</div>
					)}

				</div>

			);


	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		cargarContenido
	},dispatch)
}

export default connect(null, mapDispatchToProps)(EdtNoticia);

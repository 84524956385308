// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Utils
import { isDefined } from '../../../../../lib/utils/is';

//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Input from '../../../../../components/Inputs/Input';
import Submit from '../../../../../components/Inputs/Submit';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          buscar_opo_añadir: '',
          buscar_preparador_añadir: '',
          dia_semanal_añadir: '',
          hora_inicio_añadir: '',
          hora_fin_añadir: '',
          max_alumnos_añadir: '',
          nombre_opo_del: '',
          dia_semanal_del: '',
          statusBarraProceso: 'completar'
        }
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

  onChangeInput(e){
      this.setState({[e.target.name]: e.target.value});
      switch (this.props.selectedOption) {
        case 'Añadir_Clase': {
          if((this.state.buscar_opo_añadir.length > 0 && this.state.buscar_preparador_añadir.length > 0 && this.state.dia_semanal_añadir.length > 0 && this.state.hora_fin_añadir.length > 0 && this.state.hora_inicio_añadir.length > 0 && this.state.max_alumnos_añadir.length > 0 && e.target.value.length > 0)){
            this.setState({statusBarraProceso: "enviar"});
            }else{
              this.setState({statusBarraProceso: "completar"});
            }
        }break;
        case 'Borrar_Clase': {
          if(e.target.name === 'nombre_opo_del'){
                let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
                if(oposicionObject != undefined){
                    let data = { id_oposicion: oposicionObject.id_oposicion };

                    fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
                      method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify(data)
                    }).then(response =>  {
                        if (response.status >= 400) { throw new Error("Bad response from server");}
                      return response.json();
                    }).then(cls => this.setState({busquedaSemClases: cls}))
                    .catch(error => console.log('Object fecth failed', error))
                }else{
                  this.setState({busquedaSemClases: ''});
                }
          }

          if((this.state.nombre_opo_del.length > 0 && this.state.dia_semanal_del.length > 0 && e.target.value.length > 0)){
            this.setState({statusBarraProceso: "enviar"});
            }else{
              this.setState({statusBarraProceso: "completar"});
            }
        }break;
    }
    }

  handleSubmitForm(e){
        switch (e.target.id) {
          case 'añadir_clase': {
            let { busquedaOpo, busquedaPre, buscar_opo_añadir, buscar_preparador_añadir, dia_semanal_añadir, hora_inicio_añadir, hora_fin_añadir, max_alumnos_añadir } = this.state
            if(isDefined(dia_semanal_añadir) && isDefined(hora_inicio_añadir) && isDefined(hora_fin_añadir) && isDefined(max_alumnos_añadir)){

             let opoObject = busquedaOpo.find(opo => opo.nombre === buscar_opo_añadir);
             let preObject = busquedaPre.find(prep => prep.nombre_usuario === buscar_preparador_añadir);

                    var data = {
                    id_preparador: preObject.id_usuario,
                    id_oposicion: opoObject.id_oposicion,
                    dia_semanal: dia_semanal_añadir,
                    hora_inicio: hora_inicio_añadir,
                    hora_fin: hora_fin_añadir,
                    maximo_alumnos: max_alumnos_añadir
                  }

                  fetch("https://oposiciones-justicia.es/api/clases/addClase", {
                      method: 'POST',
                      headers: {'Content-Type': 'application/json'},
                      body: JSON.stringify(data)
                    }).then(response =>  {
                        if (response.status >= 400) { throw new Error("Bad response from server");}
                      return response.json();
                    }).then(data => {
                        if(data.affectedRows > 0){
                           this.setState({statusBarraProceso: "completado",
                         buscar_opo_añadir: '',buscar_preparador_añadir: '',dia_semanal_añadir: '',hora_inicio_añadir: '',hora_fin_añadir: '',max_alumnos_añadir: ''});
                           this.refs.ref_buscar_opo_añadir.refs.buscar_opo_añadir.value = '';
                           this.refs.ref_buscar_preparador_añadir.refs.buscar_preparador_añadir.value = '';
                           this.refs.ref_dia_semanal_añadir.refs.dia_semanal_añadir.value = '';
                           this.refs.ref_hora_inicio_añadir.refs.hora_inicio_añadir.value = '';
                           this.refs.ref_hora_fin_añadir.refs.hora_fin_añadir.value = '';
                           this.refs.ref_max_alumnos_añadir.refs.max_alumnos_añadir.value = '';
                        }}).catch(function(err) {console.log(err)});
              }else{
                swal("Complete todos los datos para procesar la solicitud",'',"warning");
              }
  }break;
    case 'borrar_clase': {
      let { busquedaSemClases, dia_semanal_del } = this.state
      if(isDefined(dia_semanal_del)){
          let clsObject = busquedaSemClases.find(cls => cls.dia_semanal === dia_semanal_del);
          let data3 = { id_clase: clsObject.id_clase };

          fetch("https://oposiciones-justicia.es/api/clases/deleteClase", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data3)
          }).then(response =>  {
              if (response.status >= 400) { throw new Error("Bad response from server");}
            return response.json();
          }).then(data => {
              if(data.affectedRows > 0){
                 this.setState({statusBarraProceso: "completado", nombre_opo_del:'',dia_semanal_del:''});
                 this.refs.ref_nombre_opo_del.refs.nombre_opo_del.value = '';
                 this.refs.ref_dia_semanal_del.refs.dia_semanal_del.value = '';
              }}).catch(function(err) {  console.log(err)});
            }else{
              alert("Indíque la Clase a Borrar.");
            }
        }break;
      }
    }
componentDidMount(){
  fetch('https://oposiciones-justicia.es/api/clases/consultaPre')
    .then(res => res.json())
      .then(preparadores => this.setState({
          busquedaPre: preparadores
      }))
    .catch(error => console.log('Object fecth failed', error))

  fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
    .then(res => res.json())
      .then(oposiciones => this.setState({
          busquedaOpo: oposiciones
      }))
    .catch(error => console.log('Object fecth failed', error))
};

  render() {
    const { selectedOption } = this.props;
    const { busquedaOpo, busquedaPre, busquedaSemClases, statusBarraProceso } = this.state
      switch (selectedOption) {
        case 'Añadir_Clase': {
              return (
                <form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
                  <Input type="text" name="buscar_opo_añadir" placeholder="Nombre Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_buscar_opo_añadir" onChange={this.onChangeInput} />
                  <Input type="text" name="buscar_preparador_añadir" placeholder="Nombre Preparador" icon="fas fa-search" datalist="busquedaPre" ref="ref_buscar_preparador_añadir" onChange={this.onChangeInput} />
                  <Input type="text" name="dia_semanal_añadir" placeholder="Dia Semanal" icon="fas fa-chevron-circle-right" ref="ref_dia_semanal_añadir" onChange={this.onChangeInput}/>
                  <div className="">Hora Inicio</div>
                  <Input step={1} type="time" name="hora_inicio_añadir" placeholder="Hora de Inicio" icon="fas fa-chevron-circle-right" ref="ref_hora_inicio_añadir" onChange={this.onChangeInput}/>
                  <div className="">Hora Fin</div>
                  <Input type="time" step={1} name="hora_fin_añadir" placeholder="Hora de Fin" icon="fas fa-chevron-circle-right" onChange={this.onChangeInput} ref="ref_hora_fin_añadir" />
                  <Input type="number" name="max_alumnos_añadir" placeholder="Cupo Máximo" icon="fas fa-chevron-circle-right" ref="ref_max_alumnos_añadir" onChange={this.onChangeInput}/>
                  <Submit id="añadir_clase" icon1="fas fa-upload" icon2="fas fa-database" texto="Agregar Clase" onClick={this.handleSubmitForm}/>

                  <datalist id="busquedaPre">{
                    busquedaPre && busquedaPre.map((pre, key) =>
                    <option key={key} value={pre.nombre_usuario}>{pre.nombre+' '}{pre.apellidos+'.'}</option>
                      )
                  }</datalist>
                  <datalist id="busquedaOpo">{
                    busquedaOpo && busquedaOpo.map((opo, key) =>
                    <option key={key} value={opo.nombre}></option>
                      )
                  }</datalist>
                </form>
              );
        }
        break;
        case 'Borrar_Clase': {
              return (
                <form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
                  <Input type="text" name="nombre_opo_del" placeholder="Nombre Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_nombre_opo_del" onChange={this.onChangeInput} />
                  <Input type="text" name="dia_semanal_del" placeholder="Dia Semanal" icon="fas fa-search" datalist="busquedaSemClases" ref="ref_dia_semanal_del" onChange={this.onChangeInput} />
                  <Submit id="borrar_clase" icon1="fas fa-trash-alt" icon2="fas fa-database" texto="Borrar Clase" onClick={this.handleSubmitForm}/>

                  <datalist id="busquedaSemClases">{
                    busquedaSemClases && busquedaSemClases.map((cls, key) =>
                    <option key={key} value={cls.dia_semanal}></option>
                      )
                  }</datalist>
                   <datalist id="busquedaOpo">{
                     busquedaOpo && busquedaOpo.map((opo, key) =>
                     <option key={key} value={opo.nombre}></option>
                       )
                   }</datalist>
                  </form>
              );
        }break;
        default: {
          return (
            <div style={{fontSize: '24px'}} >
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
              Selecciona una opción para continuar.
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
            </div>
          );
        }
      }
  }
}

export default OptionBar;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../../../data/menuAdmin';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { cargarContenido } from './actions/baroptionAction';
// Components
import MenuAdmin from '../MenuAdmin';
import Option from './optionBar';

class Leyes extends Component {
  static propTypes = {
    cargarContenido: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired,
    optionSelected: PropTypes.string.isRequired
  };

  render() {

    const { isLogged, nombreUser, tipoUser, optionSelected, idUser } = this.props;
    const nivelRequired = 98;

    if (isLogged && nivelRequired <= tipoUser) {
      return (
        <div className="inBodyMenu">

          <div className="menuBody">
            <MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="ADMINISTRACIÓN" />
          </div>
          <div className="optionselectedNew">
            <Option selectedOption={optionSelected} idUsuario={idUser} />
          </div>

        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    optionSelected: state.baroption.optionSelected,
    idUser: state.user.idUser
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    cargarContenido
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Leyes);

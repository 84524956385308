//Dependencias
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//Dependencies Redux
import { connect } from 'react-redux';
// Assets
import '../Assets/css/App.css';
import  '../Assets/css/general.css';

// Componentes
import Header from '../components/Global/Header/Header';
import Body from '../components/Global/Body/Body';
import Menu from '../components/Global/Menu/Menu';
import Footer from '../components/Global/Footer/Footer';
import Footer2 from '../components/Global/Footer/Footer';

// Data
import itemsMenu from '../data/menu';
//const Body = lazy(() => import('../components/Global/Body/Body'));

class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    isLogged: PropTypes.bool.isRequired
  };


  componentDidMount(){

    if(window.location.href.split('https://www.').length > 1){
      window.location.href = 'https://oposiciones-justicia.es'
    }
}
  render() {
    const { children } = this.props;
    if(!this.props.isLogged){
      return (

        <div className="App" id="#style-2">

          <div className="ContenidoLogin">
            
            <Body body={children} />
            
          </div>

        </div>
      );
    }else{
      return (
        <div className="App">
          
          <div className="Contenido">
            <Menu itemsMenu={itemsMenu}/>
            {/* <Suspense fallback = {<div>Loading...</div>}> */}
            <Body body={children} />
            {/* </Suspense> */}
          </div>
          
        </div>
      );
    }


  }
}

// Conectamos Redux Store
function mapStateToProps(state){
  return {
      isLogged: state.user.isLogged
  }
}
export default connect(mapStateToProps, null)(App);

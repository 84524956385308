// Dependencies
import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
//Dependencies Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player'
// Actions
import BotonFinolis from '../../../../../components/Inputs/Boton3Finolis'
// Assets
import './css/css.css';



class Tarjeta3mode1 extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);
    this.state = {
      viewVideo: false,
      loca: null
    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }


  async onHandleClick(e) {
    if (e.target.id == 'videoInfo') {

      this.setState({ viewVideo: !this.state.viewVideo })

    } else if (e.target.id.split('-')[0] == 'check') {


      let locaNew = e.target.id.split('-')[1]
      if (this.state.loca == locaNew) {
        this.setState({ loca: null })
      } else {
        this.setState({ loca: locaNew })
      }



    } else if (e.target.id == 'contratar') {
      // infoText: 'Gestion Libre',
      // tipoPreparacionOpo: 'Gestion-libre',
      // urlOpoSelectd: 'Online_Video_Presencial'
      let { info } = this.props
      let tipoCurso = this.state.loca
      let urlTipCurso = null
      if (tipoCurso != null) {
        urlTipCurso = info.cursos[tipoCurso].titulo
      } else {
        if (info.url2TipoPreparacion == 'Presencial') {
          urlTipCurso = 'Reserva-Plaza'
        } else if (info.url2TipoPreparacion == 'Online_Video_Presencial') {
          urlTipCurso = 'Reserva-Plaza'
        }
      }

      let tipoOposicion = info.url3Oposicion
      let tipoPreparacionOpo = info.url2TipoPreparacion
      //window.location.href = '/Preparacion/' + tipoPreparacionOpo + '/' + tipoOposicion;
      window.location.href = '/Preparacion/' + tipoPreparacionOpo + '/' + tipoOposicion + '/' + urlTipCurso;



    } else if (e.target.id == 'infoSemanaGratis') {

      let info = this.props.info

      let name = info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial" ? (
        'CLASE DE PRUEBA GRATIS'
      ) : (
        '1ª SEMANA GRATIS'
      )

      let content = document.createElement('div');
      content.innerHTML = info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial" ? (
        `
      Asista a la 1ª clase. 
      </br></br>
      Pruebe nuestro metodo de preparación, campus virtual y conozca a su preparador.
      `
      ) : (
        `
      Accede a la 1ª semana del contenido sin ningún coste. 
      </br></br>
      Pruebe nuestro metodo de preparación, campus virtual y conozca a su preparador.
      </br></br>
      No le pediremos ningún metodo de pago hasta finalizar el periodo de prueba. 
      </br></br>
      Sí quiere desbloquear todo el contenido durante el periodo de prueba, podrá realizar el pago desde su aula virtual.
      `
      )
      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    } else if (e.target.id == 'infoReserva') {

      let name = "Reserva de plaza";
      let content = document.createElement('div');
      content.innerHTML = `
      Haga clic sobre RESERVAR PLAZA, para completar el proceso de reserva <b>sin ningun coste</b>.
      </br></br>
      La academia contactará con usted para confirmar su alta en 1-2 días.
      </br></br>
      
      `
      content.style.textAlign = "left";
      content.style.fontFamily = "Barlow"
      content.style.fontSize = "17px"
      content.style.color = '#000000a6'
      content.style.fontWeight = '400'
      swal({
        title: name,
        content: content,
        icon: "info",
        buttons: {
          cancel: "Cerrar",
          defeat: false,
        },
        closeOnEsc: false,
        closeOnClickOutside: false,
      })
    } else if (e.target.id == 'masInfo') {
      let info = this.props.info

      if (info.url2TipoPreparacion == "Online_Video_Presencial") {
        window.open(
          'https://cejusticia.es/preparacion-online-por-videoconferencia/',
          '_blank'
        );
      } else if (info.url2TipoPreparacion == "Presencial") {
        window.open(
          'https://cejusticia.es/preparacion-presencial/',
          '_blank'
        );
      } else {
        window.open(
          'https://cejusticia.es/cursos-online/',
          '_blank'
        );
      }

    }
  }
  onChangeInput(e) {


  }
  async componentDidMount() {

  }


  render() {
    let { info } = this.props
    let { viewVideo, loca } = this.state
    return (

      <div className={info && info.cursos.length > 0 ? ('CC3-tarjeta') : ('CC3-tarjetaN')}>
        <div className='CC3-T-header'>
          <div className='CC3-T-h-left'>
            {info && ReactHtmlParser(info.tituloLeft)}
            <p id="infoSemanaGratis">
              {/* {
                info && (info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial") ? null : (
                  'PAGO FINANCIADO 0%'
                )
              } */}
            </p>
            {/* <p id="infoSemanaGratis" onClick={this.onHandleClick}>

              {
                info && (info.url2TipoPreparacion == "Online_Video_Presencial" || info.url2TipoPreparacion == "Presencial") ? (
                  'CLASE DE PRUEBA GRATIS'
                ) : (
                  '1ª SEMANA GRATIS'
                )
              }
              <i className="fa-light fa-circle-info" id="infoSemanaGratis"></i>
            </p> */}
          </div>
          <div className='CC3-T-h-right'>


            {info && info.cursos.length > 0 ? (
              <BotonFinolis texto={'Comenzar Alta'} id="contratar" onClick={this.onHandleClick} disabled={loca && loca != null ? (false) : (true)} infoDisabled="Seleccione un modo de curso para continuar" />
            ) : (
              <p>Ultimas plazas disponibles</p>
            )
            }

          </div>
        </div>

        <div className='CC3-T-body'>


          <div className={info && info.cursos.length > 0 ? ('CC3-T-b-left') : ('CC3-T-b-left2')}>
            <div className='CC3-T-b-l-content' >
              {ReactHtmlParser(info && info.contentInfo1)}
              <div className='barrotesGrises'></div>
              <div className='CC3-T-b-l-content-text' style={{ transform: ' rotateY(0deg)', minHeight: '255px' }}>

                <div className={viewVideo == false ? ('CC3-T-b-l-content-text opacidadVista') : ('CC3-T-b-l-content-text opacidadOculta')}>
                  {
                    info && info.contentInfo2 && info.contentInfo2.map((item, key0) =>
                      <div className='lineaTecxtoCC3tbl' key={key0}>
                        {/* <div style={{ margin: '10px', width: '6px', height: '6px', background: '#3469b7', borderRadius: '50px' }}></div> */}
                        <i className={item.icono} style={{ margin: '10px', color: '#3469b7' }} />
                        {ReactHtmlParser(item.txt)}
                      </div>
                    )
                  }

                </div>
                <div className={viewVideo == false ? ('CC3-T-b-l-content-video opacidadOculta') : ('CC3-T-b-l-content-video opacidadVista')}>
                  <ReactPlayer
                    url={info && info.videoUrl}
                    className='react-player'
                    playing={false}
                    width='100%'
                    height='100%'
                    controls={true}
                    onContextMenu={e => e.preventDefault()}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  />
                </div>



              </div>


            </div>
            <div className='CC3-T-b-l-navButtons'>
              <BotonFinolis texto={'SABER MÁS'} id="masInfo" onClick={this.onHandleClick} />
              {/* <BotonFinolis texto={viewVideo == false ? ('VER VIDEO') : ('CERRAR VIDEO')} id="videoInfo" onClick={this.onHandleClick} /> */}
            </div>
          </div>


          <div className={info && info.cursos.length > 0 ? ('CC3-T-b-right') : ('CC3-T-b-right2')}>
            <div className={info && info.cursos.length > 0 ? ('CC3-T-b-r-content') : ('CC3-T-b-r-content contentCfjasd')}>

              {
                info && info.cursos && info.cursos.length > 0 && info.cursos.map((clsd4, key2) =>
                  <div className='CC3-T-b-r-c-Check-curso' key={key2}>

                    <div className='CC3-T-b-r-c-Check-curso-row' id={"check-" + key2} onClick={this.onHandleClick} >
                      <div className={key2 != loca ? ('CC3-T-b-r-c-C2-check') : ('CC3-T-b-r-c-C2-check2 checkTru')} id={"check-" + key2}></div>
                      {/* className='CC3-T-b-r-c-C2-check checkTru' */}
                      <div className='CC3-T-b-r-c-C2-check-text-title1' id={"check-" + key2}>
                        {clsd4.titulo}
                      </div>
                    </div>
                    <div className='CC3-T-b-r-c-Check-curso-row2'>
                      <div className='CC3-T-b-r-c-C2-infoText'>
                        {clsd4.info}
                      </div>
                      <div className='ColCenterCenter' style={{ alignItems: 'flex-end' }}>
                        <div className='CC3-T-b-r-c-C2-Precio' style={{display: 'none'}}>{clsd4.oferta} <p>/ MES</p> </div>
                        <p>Pago único de {clsd4.precio} </p>

                      </div>
                    </div>


                  </div>
                )
              }

              {info && info.cursos.length > 0 ? null : (
                <div className='CC3-T-b-r-c-C-NavPresenci'>
                  {/* <div>Mensualidad</div> */}
                  <p>Matrícula gratis</p>
                  {/* <div>{info && info.ofertaMensualidad}</div> */}
                </div>
              )}

            </div>
            {info && info.cursos.length > 0 ? (
              <div className='CC3-T-b-r-navButtons' style={{ marginTop: '5px' }}>
                {/* <p style={{
                  fontSize: '17px',
                  color: '#c72424',
                  fontWeight: '500',
                  textTransform: 'uppercase'
                }}>Pago financiado al 0% en 3 cuotas</p> */}
              </div>
            ) : (
              <div className='CC3-T-b-r-navButtons2'>
                <i className="fa-light fa-circle-info" id="infoReserva" onClick={this.onHandleClick}></i>
                <BotonFinolis texto={'Reservar Plaza'} id="contratar" onClick={this.onHandleClick} />
              </div>
            )
            }
          </div>


        </div>


      </div>
    );

  }
}
// Conectamos Redux Store



export default connect(null, null)(Tarjeta3mode1);
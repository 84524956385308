import moment from 'moment';
import { isDefined } from './is';

export async function filtrarClaseCurso(claseLoad, tipoUser, todosTemasActivos, temasFacturados) {

  let filtroClase = JSON.parse(JSON.stringify(claseLoad))

  if (tipoUser < 50 && todosTemasActivos == 0) {
    for (let a = 0; a < claseLoad[0].bloqueTemas.length; a++) {

      if (await claseLoad[0].bloqueTemas[a].tipoBloque != 0) {

        if (await claseLoad[0].bloqueTemas[a].temas.length > 0) {

          for (let b = 0; b < await claseLoad[0].bloqueTemas[a].temas.length; b++) {

            let idTema = await claseLoad[0].bloqueTemas[a].temas[b].idTema

            let idTemaExiste = await temasFacturados.findIndex(t => t.idTema == idTema)

            if (await idTemaExiste == -1) {
              let bloqueBuscado1 = await filtroClase[0].bloqueTemas.findIndex(z => z.idBloque == claseLoad[0].bloqueTemas[a].idBloque)

              let temasBuscado = await filtroClase[0].bloqueTemas[bloqueBuscado1].temas.findIndex(z => z.idTema == idTema)

              await filtroClase[0].bloqueTemas[bloqueBuscado1].temas.splice(temasBuscado, 1)

            }
          }

          let bloqueBuscado2 = await filtroClase[0].bloqueTemas.findIndex(z => z.idBloque == claseLoad[0].bloqueTemas[a].idBloque)

          if (filtroClase[0].bloqueTemas[bloqueBuscado2].temas.length == 0 && claseLoad[0].bloqueTemas[a].temas.length > 0) {


            await filtroClase[0].bloqueTemas.splice(bloqueBuscado2, 1)
          }
        }

      }

    }
  }


  return filtroClase
}
export async function loadClaseCurso(semanaFacturada, idUser, busquedaLeyes) {

  try {
    let semanaPrg = undefined
    let semanaSeleted = semanaFacturada

    var ejerciciosClase

    // Inicio
    // idUser -- busquedaLeyes
    if (semanaSeleted != undefined) {

      let data1 = { fechaClase: moment(semanaSeleted.fechaClase).format('YYYY-MM-DD HH:mm'), idPrg: semanaSeleted.idPrg, idClase: semanaSeleted.idClase }
      let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/claseSelecionadaVirtualAlum", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;

      if (cls1 != undefined) {
        let inforClase = [{
          idPrg: cls1[0].idPrg,
          nombreClase: cls1[0].nombre_semana,
          idCategoria: cls1[0].idCategoria,
          idClase: semanaSeleted.idClase,
          videosClase: [],
          linkClaseOnline: cls1[0].linkClaseOnline,
          horaClaseOnline: cls1[0].horaClaseOnline,
          numeroSemanaClase: cls1[0].numeroSemanaClase,
          numeroSemanaProgramacion: cls1[0].numeroSemanaProgramacion,
          FechaOpenClase: cls1[0].FechaOpenClase,
          FechaTestClase: cls1[0].fechaClase,
          fechaClase: cls1[0].fechaClase,
          textHtml: cls1[0].textHTML,
          porcentajeMinTestJusticia: cls1[0].porcentajeMinTestJusticia,
          porcentajeMinCasoPractico: cls1[0].porcentajeMinCasoPractico,
          bloqueTemas: []
        }]

        ejerciciosClase = inforClase;
      } else {
        ejerciciosClase = undefined;
      }
      if (ejerciciosClase != undefined) {
        // SACAR VIDEOS DE LA SEMANA

        // let inforClase2 = await ejerciciosClase
        // let data22345 = { idPrg: inforClase2[0].idPrg }
        // let response22345 = await fetch("https://oposiciones-justicia.es/api/usuarios/videosPrg", {
        //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data22345)
        // });
        // if (!response22345.ok) {
        //   throw Error(response22345.statusText);
        // }
        // let json22345 = await response22345.json();
        // let cls22345 = await json22345;
        // if (cls22345 != undefined && cls22345.length > 0) {
        //   for (var w = 0; w < cls22345.length; w++) {
        //     inforClase2[0].videosClase.push({
        //       titulo: cls22345[w].titulo,
        //       link: cls22345[w].link
        //     })
        //   }

        // }
        // ejerciciosClase = inforClase2;

        // sacar bloques y apuntar a todos -->
        let data2Apunt = { idPrg: ejerciciosClase[0].idPrg, idClase: semanaSeleted.idClase }
        let response2Apunt = await fetch("https://oposiciones-justicia.es/api/usuarios/bloquesPrg", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2Apunt)
        });
        if (!response2Apunt.ok) {
          throw Error(response2Apunt.statusText);
        }
        let json2Apunt = await response2Apunt.json();
        let cls2Apunt = await json2Apunt;

        for (let indexApunt1 = 0; indexApunt1 < cls2Apunt.length; indexApunt1++) {

          // esta apuntado?
          let data3Apunt = { idBloque: cls2Apunt[indexApunt1].idBloque, idUser: idUser }
          let response3Apunt = await fetch("https://oposiciones-justicia.es/api/usuarios/bloquesPrgAputnado", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3Apunt)
          });
          if (!response3Apunt.ok) {
            throw Error(response3Apunt.statusText);
          }
          let json3Apunt = await response3Apunt.json();
          let cls3Apunt = await json3Apunt;
          if (cls3Apunt.length > 0) {
            // apuntado

            if (cls3Apunt[0].id_alumno == idUser && cls3Apunt[0].activo == 0) {
              // cambiar a activo

              let response4Apunt = await fetch("https://oposiciones-justicia.es/api/usuarios/activarAlumnoBloque", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3Apunt)
              });
              if (!response4Apunt.ok) {
                throw Error(response4Apunt.statusText);
              }
              let json4Apunt = await response4Apunt.json();
              let cls4Apunt = await json4Apunt;
            }
          } else {
            // apuntar en bloque nuevo
            let response4Apunt = await fetch("https://oposiciones-justicia.es/api/usuarios/insertarAlumnoBloque", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3Apunt)
            });
            if (!response4Apunt.ok) {
              throw Error(response4Apunt.statusText);
            }
            let json4Apunt = await response4Apunt.json();
            let cls4Apunt = await json4Apunt;
          }
 

         }

        // sacar bloqueTemas -->
        let data2 = { idPrg: ejerciciosClase[0].idPrg, idUser: idUser, idClase: semanaSeleted.idClase }
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/bloqueSemPrgPreAlum", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;


        for (var j = 0; j < cls2.length; j++) {
          ejerciciosClase[0].bloqueTemas.push({
            idBloque: cls2[j].id_bloque,
            idTestExtra: cls2[j].id_test_justicia,
            testJusticia: cls2[j].nombre_test,
            resultadoTestJusticia: undefined,
            idCasoExtra: cls2[j].id_caso_practico_extra,
            casoPractico: cls2[j].nombre_cp,
            resultadoCasoPractico: undefined,
            textHtml: cls2[j].texto_cp,
            tipoBloque: cls2[j].tipoBloque,
            alumnos: [],
            temas: [],
            idExamenGestion: cls2[j].idExamenGestion,
            examenToUpload: undefined,
            examenExiste: false,
            testsJusticia: [],
            casosPractico: [],
            examenesDesarrollo: []
          })

          if (cls2[j].idExamenGestion != null && cls2[j].idExamenGestion != 0 && cls2[j].idExamenGestion != 1) {
            ejerciciosClase[0].bloqueTemas[ejerciciosClase[0].bloqueTemas.length - 1].examenesDesarrollo.push({
              idExamenGestion: cls2[j].idExamenGestion,
              examenToUpload: undefined,
              examenExiste: false
            })
          }

        }

        ejerciciosClase = ejerciciosClase

        let ejerciciosClase2 = ejerciciosClase


        for (var j = 0; j < ejerciciosClase2[0].bloqueTemas.length; j++) {
          // Sacar Test Justicia
          let data33456 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
          let responsedata33456 = await fetch("https://oposiciones-justicia.es/api/preparador/testJusticiaBloque", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
          });
          if (!responsedata33456.ok) {
            throw Error(responsedata33456.statusText);
          }
          let jsondata33456 = await responsedata33456.json();
          let clsdata33456 = await jsondata33456;
          if (clsdata33456 && clsdata33456 != undefined) {
            for (var v = 0; v < clsdata33456.length; v++) {
              ejerciciosClase2[0].bloqueTemas[j].testsJusticia.push({ idTestJusticia: clsdata33456[v].id_test, ranking1: [], ranking2: [] })
            }
          }
          //Sacar caso practico

          let responsedata308 = await fetch("https://oposiciones-justicia.es/api/preparador/casoPracticoBloque", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
          });
          if (!responsedata308.ok) {
            throw Error(responsedata308.statusText);
          }
          let jsondata308 = await responsedata308.json();
          let clsdata308 = await jsondata308;
          if (clsdata308 && clsdata308 != undefined) {
            for (var v = 0; v < clsdata308.length; v++) {
              ejerciciosClase2[0].bloqueTemas[j].casosPractico.push({ idCasoPractico: clsdata308[v].id_test, textHtml: clsdata308[v].texto_cp, ranking1: [], ranking2: [] })
            }
          }

          //Sacar examen desarrollo 

          let responsedata308x2 = await fetch("https://oposiciones-justicia.es/api/oficina/examenDesarrolloBloqueLoad", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33456)
          });
          if (!responsedata308x2.ok) {
            throw Error(responsedata308x2.statusText);
          }
          let jsondata308x2 = await responsedata308x2.json();
          let clsdata308x2 = await jsondata308x2;
          if (clsdata308x2 && clsdata308x2 != undefined) {
            for (var v = 0; v < clsdata308x2.length; v++) {
              ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo.push({
                idExamenGestion: clsdata308x2[v].idTest,
                examenToUpload: undefined,
                examenExiste: false
              })
            }
          }


          let data33 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque }
          let response33 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloque", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data33)
          });
          if (!response33.ok) {
            throw Error(response33.statusText);
          }
          let json33 = await response33.json();
          let cls33 = await json33;
          //alumnos
          for (var l = 0; l < cls33.length; l++) {
            if (cls33[l].activado == 1) {
              ejerciciosClase2[0].bloqueTemas[j].alumnos.push({
                idAlumno: cls33[l].idAlumno,
                nombreAlumno: cls33[l].nombreAlumno,
                apellidosAlumno: cls33[l].apellidosAlumno,
                linkFotoPerfil: cls33[l].linkFotoPerfil,
                testJusticiaRealizado: undefined,
                casoPracticoRealizado: undefined,
                testJusticiaResultados: [],
                casoPracticoResultados: []
              })
            }
          }

          ejerciciosClase = ejerciciosClase2


          let data34 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idClase: semanaSeleted.idClase, idPrg: ejerciciosClase2[0].idPrg }
          let response34 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloqueTestJusticia", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data34)
          });
          if (!response34.ok) {
            throw Error(response34.statusText);
          }
          let json34 = await response34.json();
          let cls34 = await json34;
          //alumnos
          for (var l = 0; l < ejerciciosClase2[0].bloqueTemas[j].alumnos.length; l++) {

            for (var d = 0; d < cls34.length; d++) {
              if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaRealizado == undefined) {
                let testJusticiaAlumno = cls34.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno).sort((a, b) => b.idExp - a.idExp)
                if (testJusticiaAlumno && testJusticiaAlumno.length > 0) {
                  ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaRealizado = testJusticiaAlumno[0]
                }
              }

              if (ejerciciosClase2[0].bloqueTemas[j].testsJusticia.length > 0) {
                for (var q = 0; q < ejerciciosClase2[0].bloqueTemas[j].testsJusticia.length; q++) {
                  let idTest = ejerciciosClase2[0].bloqueTemas[j].testsJusticia[q].idTestJusticia

                  //sacamos el ultimo hecho por cada test
                  let historialTestbloqueAlumno = cls34.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == idTest).sort((a, b) => b.idExp - a.idExp)

                  for (let index = 0; index < historialTestbloqueAlumno.length; index++) {
                    if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaResultados.filter(y => y.idExp == historialTestbloqueAlumno[index].idExp).length == 0) {
                      ejerciciosClase2[0].bloqueTemas[j].alumnos[l].testJusticiaResultados.push(historialTestbloqueAlumno[index])
                    }
                  }

                }
              }

            }

          }
          ejerciciosClase = ejerciciosClase2;


          let data35 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idClase: semanaSeleted.idClase, idPrg: ejerciciosClase2[0].idPrg }
          let response35 = await fetch("https://oposiciones-justicia.es/api/preparador/alumnosBloqueCasoPractico", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data35)
          });
          if (!response35.ok) {
            throw Error(response35.statusText);
          }
          let json35 = await response35.json();
          let cls35 = await json35;
          //alumnos
          for (var l = 0; l < ejerciciosClase2[0].bloqueTemas[j].alumnos.length; l++) {

            for (var d = 0; d < cls35.length; d++) {
              if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoRealizado == undefined) {
                let casoPAlumno = cls35.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno).sort((a, b) => b.idExp - a.idExp)
                if (casoPAlumno && casoPAlumno.length > 0) {
                  ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoRealizado = casoPAlumno[0]
                }
              }

              if (ejerciciosClase2[0].bloqueTemas[j].casosPractico.length > 0) {
                for (var q = 0; q < ejerciciosClase2[0].bloqueTemas[j].casosPractico.length; q++) {
                  let idTest = ejerciciosClase2[0].bloqueTemas[j].casosPractico[q].idCasoPractico

                  //sacamos el ultimo hecho por cada test
                  let historialTestbloqueAlumno = cls35.filter(t => t.idAlumno == ejerciciosClase2[0].bloqueTemas[j].alumnos[l].idAlumno && t.idTest == idTest).sort((a, b) => b.idExp - a.idExp)

                  for (let index22 = 0; index22 < historialTestbloqueAlumno.length; index22++) {
                    if (ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoResultados.filter(y => y.idExp == historialTestbloqueAlumno[index22].idExp).length == 0) {
                      ejerciciosClase2[0].bloqueTemas[j].alumnos[l].casoPracticoResultados.push(historialTestbloqueAlumno[index22])
                    }
                  }

                }
              }

            }



          }
          ejerciciosClase = ejerciciosClase2

          // ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo.push({
          //   idExamenGestion: clsdata308x2[v].idTest,
          //   examenToUpload: undefined,
          //   examenExiste: false
          // })


          let data352 = { idUser: idUser }
          let response352 = await fetch("https://oposiciones-justicia.es/api/usuarios/expExamenGestion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data352)
          });
          if (!response352.ok) {
            throw Error(response352.statusText);
          }
          let json352 = await response352.json();
          let cls352 = await json352;
          if (cls352 != undefined && cls352.length > 0) {
            for (var ñ = 0; ñ < cls352.length; ñ++) {
              for (let ñ2 = 0; ñ2 < ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo.length; ñ2++) {


                if (ejerciciosClase2[0].bloqueTemas[j].idBloque == cls352[ñ].idBloque && cls352[ñ].idClase == semanaSeleted.idClase && cls352[ñ].idPrg == ejerciciosClase2[0].idPrg && cls352[ñ].linkArchivoAlumno != null && cls352[ñ].linkArchivoAlumno != ''
                  && ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].idExamenGestion == cls352[ñ].idExamenGestion
                ) {

                  ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].examenExiste = true
                  ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].linkExaAlumnoDes = cls352[ñ].linkArchivoAlumno
                  ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].fechaExaEntregaA = cls352[ñ].fecha
                  ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].idExpExamDesa = cls352[ñ].id_exp

                  if (cls352[ñ].puntuacion != null) {

                    ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].examenPuntuacion = cls352[ñ].puntuacion
                    ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].notaExamenDesarollo = cls352[ñ].anotaciones

                  } else {

                    ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].examenPuntuacion = null
                    ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].notaExamenDesarollo = null

                  }

                } else {
                  ejerciciosClase2[0].bloqueTemas[j].examenesDesarrollo[ñ2].linkExaAlumnoDes = null
                }


              }

            }
          }
          ejerciciosClase = ejerciciosClase2


          let data3 = { idBloque: ejerciciosClase2[0].bloqueTemas[j].idBloque, idPrograma: ejerciciosClase2[0].idPrg }

          let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/temasDeBloqu1e", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json3 = await response3.json();
          let cls3 = await json3;

          let response1xxxx = await fetch("https://oposiciones-justicia.es/api/temas/loadVideosTemas");
          if (!response1xxxx.ok) {
            throw Error(response1xxxx.statusText);
          }
          let json1xxxx = await response1xxxx.json();
          let cls1xxxx = await json1xxxx;
          let videosTemas = await cls1xxxx

          for (var z = 0; z < cls3.length; z++) {

            // si tema es normal sacar videos en orden
            let videosFiltro = videosTemas.filter(v => v.id_tema == cls3[z].idTema)
            if (videosFiltro != undefined && videosFiltro.length > 0 && ejerciciosClase2[0].bloqueTemas[j].tipoBloque == 0) {
              for (var w = 0; w < videosFiltro.length; w++) {
                ejerciciosClase2[0].videosClase.push({
                  titulo: videosFiltro[w].titulo,
                  link: videosFiltro[w].link,
                  linkSubtitulos: videosFiltro[w].linkSubtitulos,
                })
              }

            }

            ejerciciosClase2[0].bloqueTemas[j].temas.push({
              idTemaPrg: cls3[z].idTemaPrg,
              idTema: cls3[z].idTema,
              nombreTema: cls3[z].nombreTema,
              tituloTema: cls3[z].tituloTema,
              intervalosLeyes: [],
              nota: cls3[z].nota,
              apuntes: []
            })

            // let data4 = { idTemaPrg: cls3[z].idTemaPrg }
            // let response4 = await fetch("https://oposiciones-justicia.es/api/oficina/intervalosTema", {
            //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
            // });
            // if (!response4.ok) {
            //   throw Error(response4.statusText);
            // }
            // let json4 = await response4.json();
            // let cls4 = await json4;

            // for (var q = 0; q < cls4.length; q++) {
            //   let busqueda = -1
            //   for (var h = 0; h < ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.length; h++) {
            //     busqueda = ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.findIndex(x => x.idLey == cls4[q].idLey)

            //   }

            //   let reBusqueda = await busqueda
            //   if (reBusqueda != -1) {
            //     ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes[busqueda].intervalosArticulos.push({
            //       artInicio: cls4[q].artInicio,
            //       artFin: cls4[q].artFin
            //     })
            //   } else {
            //     if (ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes == undefined) {
            //       ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes = [];
            //     }
            //     ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].intervalosLeyes.push({
            //       idLey: cls4[q].idLey,
            //       siglasLey: busquedaLeyes.find(l => l.id_ley == cls4[q].idLey).siglas_ley,
            //       nombreLey: busquedaLeyes.find(l => l.id_ley == cls4[q].idLey).nombre_ley,
            //       intervalosArticulos: [{
            //         artInicio: cls4[q].artInicio,
            //         artFin: cls4[q].artFin
            //       }]
            //     })
            //   }

            // }

            // SACAR LINK APAUNTE TAMBIEN Y NOMMBRE APUNTE!!
            // let response5 = await fetch("https://oposiciones-justicia.es/api/oficina/apuntesTema", {
            //   method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
            // });
            // if (!response5.ok) {
            //   throw Error(response5.statusText);
            // }
            // let json5 = await response5.json();
            // let cls5 = await json5;
            // for (var u = 0; u < cls5.length; u++) {
            //   ejerciciosClase2[0].bloqueTemas[j].temas[ejerciciosClase2[0].bloqueTemas[j].temas.length - 1].apuntes.push({
            //     idApunte: cls5[u].idApunte,
            //     nombreApunte: cls5[u].nombreApunte,
            //     linkPdf: cls5[u].linkPdf
            //   })
            // }

          }


        }

        //calculamos rankings!
        for (let ind0 = 0; ind0 < ejerciciosClase2[0].bloqueTemas.length; ind0++) {

          for (let ind1 = 0; ind1 < ejerciciosClase2[0].bloqueTemas[ind0].testsJusticia.length; ind1++) {

            for (let ind2 = 0; ind2 < ejerciciosClase2[0].bloqueTemas[ind0].alumnos.length; ind2++) {
              let filtroAlumnoTest = await ejerciciosClase2[0].bloqueTemas[ind0].alumnos[ind2].testJusticiaResultados.filter(t => t.idTest == ejerciciosClase2[0].bloqueTemas[ind0].testsJusticia[ind1].idTestJusticia)

              if (isDefined(filtroAlumnoTest) && filtroAlumnoTest.length > 0) {
                let orderTest = await filtroAlumnoTest.sort((a, b) => a.idExp - b.idExp)

                for (let ind3 = 0; ind3 < orderTest.length; ind3++) {

                  if (ind3 == 0) {
                    if (orderTest[ind3].puntuacion > 20) {
                      await ejerciciosClase2[0].bloqueTemas[ind0].testsJusticia[ind1].ranking1.push(
                        orderTest[ind3]
                      )
                      if (orderTest.length == 1) {
                        await ejerciciosClase2[0].bloqueTemas[ind0].testsJusticia[ind1].ranking2.push(
                          orderTest[ind3]
                        )
                      }
                    }
                  } else if (ind3 == orderTest.length - 1) {
                    if (orderTest[ind3].puntuacion > 20) {
                      await ejerciciosClase2[0].bloqueTemas[ind0].testsJusticia[ind1].ranking2.push(
                        orderTest[ind3]
                      )
                    }
                  }
                }
              }
            }
          }
        }

        for (let ind01 = 0; ind01 < ejerciciosClase2[0].bloqueTemas.length; ind01++) {

          for (let ind11 = 0; ind11 < ejerciciosClase2[0].bloqueTemas[ind01].casosPractico.length; ind11++) {

            for (let ind21 = 0; ind21 < ejerciciosClase2[0].bloqueTemas[ind01].alumnos.length; ind21++) {
              let filtroAlumnoTest = await ejerciciosClase2[0].bloqueTemas[ind01].alumnos[ind21].casoPracticoResultados.filter(t => t.idTest == ejerciciosClase2[0].bloqueTemas[ind01].casosPractico[ind11].idCasoPractico)

              if (isDefined(filtroAlumnoTest) && filtroAlumnoTest.length > 0) {
                let orderTest = await filtroAlumnoTest.sort((a, b) => a.idExp - b.idExp)

                for (let ind31 = 0; ind31 < orderTest.length; ind31++) {
                  if (ind31 == 0) {
                    if (orderTest[ind31].puntuacion > 8) {
                      await ejerciciosClase2[0].bloqueTemas[ind01].casosPractico[ind11].ranking1.push(
                        orderTest[ind31]
                      )
                      if (orderTest.length == 1) {
                        await ejerciciosClase2[0].bloqueTemas[ind01].casosPractico[ind11].ranking2.push(
                          orderTest[ind31]
                        )
                      }
                    }


                  } else if (ind31 == orderTest.length - 1) {
                    if (orderTest[ind31].puntuacion > 8) {
                      await ejerciciosClase2[0].bloqueTemas[ind01].casosPractico[ind11].ranking2.push(
                        orderTest[ind31]
                      )
                    }

                  }
                }
              }
            }
          }
        }


        ejerciciosClase = ejerciciosClase2

      }

      //semanaPrg = ejerciciosClase
      semanaPrg = ejerciciosClase
    } else {

      semanaPrg = null
      ejerciciosClase = undefined
    }
    //Fin

    return semanaPrg;


  } catch (e) {
    console.log(e)
    return null
  }

}
export async function crearSemanasPrgCurso(idClase, idCursoBase, tipoEstudio) {

  try {
    let data1 = { idCursoBase: idCursoBase }
    let response1 = await fetch("https://oposiciones-justicia.es/api/temas/semanasPrg", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
    });
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;

    let semanasPrgCurso = await cls1.sort((a, b) => a.numSemana - b.numSemana)

    // console.log('semanasPrgCurso')
    // console.log(semanasPrgCurso)
    // sacar numSemana, idPrg
    for (let i = 0; i < semanasPrgCurso.length; i++) {

      // obtener bloques
      let data2 = { idPrg: semanasPrgCurso[i].idPrg, idClase: idCursoBase }
      let response2 = await fetch("https://oposiciones-justicia.es/api/temas/bloquesPrgCurso", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
      });
      if (!response2.ok) {
        throw Error(response2.statusText);
      }
      let json2 = await response2.json();
      let cls2 = await json2;

      // console.log('Bloques')
      // console.log(cls2)
      // semanasPrgCurso[i].bloques = await cls2

      if (tipoEstudio.tipoMensualidad == '10' || tipoEstudio.tipoMensualidad == '12') {
        semanasPrgCurso[i].bloques = await cls2
      } else {
        semanasPrgCurso[i].bloques = await cls2.filter(c=> c.tipoBloque == 0) 
      }

      // obtener temas de cada bloque
      for (let j = 0; j < semanasPrgCurso[i].bloques.length; j++) {
        if (semanasPrgCurso[i].bloques[j].temas == undefined) {
          semanasPrgCurso[i].bloques[j].temas = []
        }
        let data3 = { idBloque: semanasPrgCurso[i].bloques[j].idBloque, idPrograma: semanasPrgCurso[i].idPrg }

        let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/temasDeBloqu1e", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
        let json3 = await response3.json();
        let cls3 = await json3;
        for (var z1 = 0; z1 < cls3.length; z1++) {

          await semanasPrgCurso[i].bloques[j].temas.push({
            idTema: cls3[z1].idTema,
            nombreTema: cls3[z1].nombreTema,
            tituloTema: cls3[z1].tituloTema,
            tipoBloque: semanasPrgCurso[i].bloques[j].tipoBloque
          })
        }

        // Test Teoría del bloque
        if (semanasPrgCurso[i].bloques[j].testJusticia == undefined) {
          semanasPrgCurso[i].bloques[j].testJusticia = []
        }
        let response6 = await fetch("https://oposiciones-justicia.es/api/oficina/testJusticiaBloqueLoad", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response6.ok) {
          throw Error(response6.statusText);
        }
        let json6 = await response6.json();
        let cls6 = await json6;
        for (var z2 = 0; z2 < cls6.length; z2++) {
          semanasPrgCurso[i].bloques[j].testJusticia.push({
            idTestJusticia: cls6[z2].idTest
          })
        }

        // Test Prácticos del bloque
        if (semanasPrgCurso[i].bloques[j].casoPractico == undefined) {
          semanasPrgCurso[i].bloques[j].casoPractico = []
        }
        let response7 = await fetch("https://oposiciones-justicia.es/api/temas/casoPracticoBloqueLoad", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response7.ok) {
          throw Error(response7.statusText);
        }
        let json7 = await response7.json();
        let cls7 = await json7;
        for (var z3 = 0; z3 < cls7.length; z3++) {
          semanasPrgCurso[i].bloques[j].casoPractico.push({
            idCasoPractico: cls7[z3].idTest,
            texto_cp: cls7[z3].texto_cp,
            nombre_cp: cls7[z3].nombre_cp
          })
        }

        // EjerDesarrollo
        if (semanasPrgCurso[i].bloques[j].ejerDesarrollo == undefined) {
          semanasPrgCurso[i].bloques[j].ejerDesarrollo = []
        }
        let response8 = await fetch("https://oposiciones-justicia.es/api/temas/examenDesarrolloBloque", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response8.ok) {
          throw Error(response8.statusText);
        }
        let json8 = await response8.json();
        let cls8 = await json8;
        for (var z4 = 0; z4 < cls8.length; z4++) {
          semanasPrgCurso[i].bloques[j].ejerDesarrollo.push({
            idExamenGestion: cls8[z4].idExamenGestion,
            examenToUpload: undefined,
            examenExiste: false,
            vuelta: null,
            linkSolucion: cls8[z4].linkSolucion,
            linkExamen: cls8[z4].linkExamen
          })
        }

      }

    }


    let totalPorSemana
    if (tipoEstudio.tipoMensualidad == '1') {
      totalPorSemana = 13
    } else if (tipoEstudio.tipoMensualidad == '3') {
      totalPorSemana = 4
    } if (tipoEstudio.tipoMensualidad == '6') {
      totalPorSemana = 2
    } if (tipoEstudio.tipoMensualidad == '10') {
      totalPorSemana = 1
    } if (tipoEstudio.tipoMensualidad == '12') {
      totalPorSemana = 1
    }

    let programacionRepaso = []
    let semanaVista = 0
    for (let k = 0; k < semanasPrgCurso.length; k++) {

      if (semanaVista == 0) {
        programacionRepaso.push({
          numSemana: k == 0 ? (1) : (programacionRepaso[programacionRepaso.length - 1].numSemana + 1),
          temasPrincipales: [],
          temasRepaso: [],
          ejerciciosPrincipales: {
            testTeoria: [],
            testPractico: [],
            ejerDesarrollo: []
          },
          ejerciciosRepaso: {
            testTeoria: [],
            testPractico: [],
            ejerDesarrollo: []
          },
          fechaInicio: k == 0 ? (
            moment(tipoEstudio.fechaInicio).format('YYYY-MM-DD')
          ) : (
            moment(tipoEstudio.fechaInicio).add((7 * (programacionRepaso[programacionRepaso.length - 1].numSemana)), 'd').format('YYYY-MM-DD')
          ),
        })
      }

      for (let h = 0; h < semanasPrgCurso[k].bloques.length; h++) {

        if (semanasPrgCurso[k].bloques[h].tipoBloque == 0) {

          for (let h2 = 0; h2 < semanasPrgCurso[k].bloques[h].temas.length; h2++) {

            let busquedaTem = programacionRepaso[programacionRepaso.length - 1].temasPrincipales.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)

            if (busquedaTem == -1) {
              programacionRepaso[programacionRepaso.length - 1].temasPrincipales.push(semanasPrgCurso[k].bloques[h].temas[h2])
            }

          }

          for (let xrt01 = 0; xrt01 < semanasPrgCurso[k].bloques[h].testJusticia.length; xrt01++) {
            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testTeoria.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia)

            if (busquedaEjer == -1 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 1 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 2 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 3 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 4 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 5 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 6 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 7) {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testTeoria.push({
                idTest: semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia,
                ranking1: [],
                ranking2: [],
                vuelta: null
              })
            }
          }

          for (let xrt02 = 0; xrt02 < semanasPrgCurso[k].bloques[h].casoPractico.length; xrt02++) {
            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testPractico.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].casoPractico[xrt02].idCasoPractico)


            if (busquedaEjer == -1 && semanasPrgCurso[k].bloques[h].casoPractico[xrt02].nombre_cp != 'No asignado') {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testPractico.push({
                idTest: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].idCasoPractico,
                ranking1: [],
                ranking2: [],
                textHtml: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].texto_cp,
                nombreCasoPractico: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].nombre_cp,
                vuelta: null
              })
            }
          }

          for (let xrt03 = 0; xrt03 < semanasPrgCurso[k].bloques[h].ejerDesarrollo.length; xrt03++) {

            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.ejerDesarrollo.findIndex(t => t.idExamenGestion == semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03].idExamenGestion)

            if (busquedaEjer == -1 && semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03].idExamenGestion != 1) {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.ejerDesarrollo.push(
                semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03]
              )
            }

          }
          // ejercicios
          // semanasPrgCurso[i].bloques[j].testJusticia
          // semanasPrgCurso[i].bloques[j].casoPractico
          // semanasPrgCurso[i].bloques[j].ejerDesarrollo

        } else {
          for (let h2 = 0; h2 < semanasPrgCurso[k].bloques[h].temas.length; h2++) {

            if (tipoEstudio.tipo == 'normal') {
              // comprobar que vio el tema en una semana anterior a la actual insertando
              let tope = programacionRepaso.length - 1
              if (tope > 0) {

                for (let h3 = 0; h3 < tope; h3++) {

                  let busquedaTem3 = programacionRepaso[h3].temasPrincipales.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)

                  if (busquedaTem3 != -1) {
                    let busquedaTem4 = programacionRepaso[programacionRepaso.length - 1].temasRepaso.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)
                    let busquedaTem5 = programacionRepaso[programacionRepaso.length - 1].temasPrincipales.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)
                    if (busquedaTem4 == -1 && busquedaTem5 == -1) {
                      programacionRepaso[programacionRepaso.length - 1].temasRepaso.push(semanasPrgCurso[k].bloques[h].temas[h2])
                    }

                  }

                }

              }

            } else {
              let busquedaTem = programacionRepaso[programacionRepaso.length - 1].temasRepaso.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)
              let busquedaTem5 = programacionRepaso[programacionRepaso.length - 1].temasPrincipales.findIndex(t => t.idTema == semanasPrgCurso[k].bloques[h].temas[h2].idTema)
              if (busquedaTem == -1 && busquedaTem5 == -1) {
                programacionRepaso[programacionRepaso.length - 1].temasRepaso.push(semanasPrgCurso[k].bloques[h].temas[h2])
              }
            }

          }
          // ejericicos

          for (let xrt01 = 0; xrt01 < semanasPrgCurso[k].bloques[h].testJusticia.length; xrt01++) {
            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testTeoria.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia)
            let busquedaEjer2 = programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.testTeoria.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia)

            if (busquedaEjer == -1 && busquedaEjer2 == -1 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 1 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 2 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 3 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 4 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 5 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 6 && semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia != 7) {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.testTeoria.push({
                idTest: semanasPrgCurso[k].bloques[h].testJusticia[xrt01].idTestJusticia,
                ranking1: [],
                ranking2: [],
                vuelta: null
              })
            }
          }

          for (let xrt02 = 0; xrt02 < semanasPrgCurso[k].bloques[h].casoPractico.length; xrt02++) {
            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.testPractico.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].casoPractico[xrt02].idCasoPractico)
            let busquedaEjer2 = programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.testPractico.findIndex(t => t.idTest == semanasPrgCurso[k].bloques[h].casoPractico[xrt02].idCasoPractico)

            if (busquedaEjer == -1 && busquedaEjer2 == -1 && semanasPrgCurso[k].bloques[h].casoPractico[xrt02].nombre_cp != 'No asignado') {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.testPractico.push({
                idTest: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].idCasoPractico,
                ranking1: [],
                ranking2: [],
                textHtml: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].texto_cp,
                nombreCasoPractico: semanasPrgCurso[k].bloques[h].casoPractico[xrt02].nombre_cp,
                vuelta: null
              })
            }
          }

          for (let xrt03 = 0; xrt03 < semanasPrgCurso[k].bloques[h].ejerDesarrollo.length; xrt03++) {

            let busquedaEjer = programacionRepaso[programacionRepaso.length - 1].ejerciciosPrincipales.ejerDesarrollo.findIndex(t => t.idExamenGestion == semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03].idExamenGestion)
            let busquedaEjer2 = programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.ejerDesarrollo.findIndex(t => t.idExamenGestion == semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03].idExamenGestion)

            if (busquedaEjer == -1 && busquedaEjer2 == -1 && semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03].idExamenGestion != 1) {
              programacionRepaso[programacionRepaso.length - 1].ejerciciosRepaso.ejerDesarrollo.push(
                semanasPrgCurso[k].bloques[h].ejerDesarrollo[xrt03]
              )
            }

          }

        }
      }
      semanaVista = semanaVista + 1
      if (semanaVista == totalPorSemana) {
        semanaVista = 0
      }
    }
    return programacionRepaso

  } catch (error) {
    console.log(error)
    return null
  }
}

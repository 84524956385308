// Dependencies
import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import bcrypt from 'bcryptjs';

//Assets
import './css/perfilCej.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions
import { reloadInfoPersonal, reloadNameUser, iniciarSesionForce } from '../../actions/userAction';
// Utils
import { isDefined } from '../../lib/utils/is';
// Components
import Input2 from '../../components/Inputs/Input2';
import InputFileIcono from '../../components/Inputs/InputFileIcono';
import Boton from '../../components/Inputs/Boton';


import swal from 'sweetalert';

class Perfil extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      sectionSelecionada: 1,
      contra: 'bien',
      clasesAlumno: null,
      alumnoBuscado: null
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.myFiles = this.myFiles.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.onChangeInputNew = this.onChangeInputNew.bind(this);
  }


  async onChangeInputNew(e) {

    if (e.target.id == 'clasePreparador') {
      let value = e.target.value
      let clasesAlumno = this.state.clasesAlumno

      let valueBus = clasesAlumno.find(c => c.nombreClase == value)
      if (value != '' && valueBus != undefined) {
        this.setState({ claseAlumnoSelected: valueBus.idClase })
      } else {
        this.setState({ claseAlumnoSelected: null })
      }
      // activa : 0 diaSemanalOnline : "viernes" dia_presencial : "miercoles" dia_semanal : "Miercoles-Presencial" horaClaseOnline : "17:00:00" hora_fin : "21:00:00" hora_inicio : "17:00:00" id_clase : 8 id_oposicion : 4 id_preparador : 15 inicioCursoWord2010 : null maximo_alumnos : 30 minCasoPractico : 50 minTestJusticia :  50 tipoClase : "PRESENCIAL" total_alumnos : 0
    } else if (e.target.id == 'alumnoPreparador') {

      let value = e.target.value
      let busquedaAlumnos = this.state.busquedaAlumnos

      let valueBus = busquedaAlumnos.find(c => c.dni == value)
      if (value != '' && valueBus != undefined) {
        let data = { idUser: valueBus.id_usuario }

        let response = await fetch("https://oposiciones-justicia.es/api/usuarios/datosUser", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        let json = await response.json();
        let cls = await json;

        this.setState({
          dni: cls[0].dni == undefined ? ('') : (cls[0].dni),
          nombre: cls[0].nombre == undefined ? ('') : (cls[0].nombre),
          apellidos: cls[0].apellidos == undefined ? ('') : (cls[0].apellidos),
          email: cls[0].email == undefined ? ('') : (cls[0].email),
          user: cls[0].user == undefined ? ('') : (cls[0].user),
          telefono: cls[0].telefono == undefined ? ('') : (cls[0].telefono),
          ciudad: cls[0].ciudad == undefined ? ('') : (cls[0].ciudad),
          codigoPostal: cls[0].codigoPostal == undefined ? ('') : (cls[0].codigoPostal),
          direccion: cls[0].direccion == undefined ? ('') : (cls[0].direccion),
          fotoPerfil: cls[0].fotoPerfil == undefined ? ('') : (cls[0].fotoPerfil),
          idUser: cls[0].idUser == undefined ? ('') : (cls[0].idUser),
          alumnoBuscado: true
        });



      } else {

        if (this.state.idUser != this.props.idUser) {


          let data = { idUser: this.props.idUser }

          fetch("https://oposiciones-justicia.es/api/usuarios/datosUser", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {
            this.setState({
              dni: cls[0].dni == undefined ? ('') : (cls[0].dni),
              nombre: cls[0].nombre == undefined ? ('') : (cls[0].nombre),
              apellidos: cls[0].apellidos == undefined ? ('') : (cls[0].apellidos),
              email: cls[0].email == undefined ? ('') : (cls[0].email),
              user: cls[0].user == undefined ? ('') : (cls[0].user),
              telefono: cls[0].telefono == undefined ? ('') : (cls[0].telefono),
              ciudad: cls[0].ciudad == undefined ? ('') : (cls[0].ciudad),
              codigoPostal: cls[0].codigoPostal == undefined ? ('') : (cls[0].codigoPostal),
              direccion: cls[0].direccion == undefined ? ('') : (cls[0].direccion),
              fotoPerfil: cls[0].fotoPerfil == undefined ? ('') : (cls[0].fotoPerfil),
              idUser: cls[0].idUser == undefined ? ('') : (cls[0].idUser),
              clasesAlumno: null,
              alumnoBuscado: null
            });
          }).catch(error => console.log('Object fecth failed', error))
        }
      }

    }

  }

  async handleOnClick(e) {
    if (e.target.id == 1) {
      this.setState({ sectionSelecionada: 1 })
    } else if (e.target.id == 2) {
      this.setState({ sectionSelecionada: 2 })
    } else if (e.target.id == 3) {
      this.setState({ sectionSelecionada: 3 })
    }
  }
  async myFiles(e) {
    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < files.length; i++) {
      filesLoad.push(files[i]);
    }
    await this.setState({ fileToUpload: filesLoad });
    let e2 = { target: { id: 'ActualizarImagenPerfil' } }
    await this.handleOnSubmit(e2)
  }
  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async handleOnSubmit(e) {
    if (e.target.id == 'actualizarPefil') {
      if (this.state.idUser != this.props.idUser && this.props.tipoUser > 50) {

        let { nombre, apellidos, dni, email, user, telefono, ciudad, codigoPostal, direccion, passwordNew1, passwordNew2 } = this.state

        try {

          if (isDefined(nombre) && isDefined(apellidos) && isDefined(dni) && isDefined(email) && isDefined(user) && isDefined(telefono) && isDefined(ciudad) && isDefined(codigoPostal) && isDefined(direccion)) {

            if (isDefined(passwordNew1) && isDefined(passwordNew2)) {
              if (passwordNew1 == passwordNew2) {
                let hashNewPass = bcrypt.hashSync(passwordNew1, 8)
                let data2 = {
                  idUsuario: this.state.idUser,
                  passNew: hashNewPass
                }
                fetch('https://oposiciones-justicia.es/api/usuarios/passwordNew', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data2)
                }).then(res => res.json())
                  .then(datos => {
                    this.refs.ref_passwordNew1.refs.passwordNew1.value = '';
                    this.refs.ref_passwordNew2.refs.passwordNew2.value = '';
                    this.setState({ contra: 'bien' })
                  }).catch(error => console.log('Object fecth failed', error))
              } else {

                this.setState({ contra: 'error' })
              }

            }

            let data3 = {
              idUsuario: this.state.idUser,
              nombre: nombre,
              apellidos: apellidos,
              email: email,
              user: user,
              telefono: telefono,
              ciudad: ciudad,
              codigoPostal: codigoPostal,
              direccion: direccion
            }

            let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/actualizarDatosPersonales", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;

            if (user != this.props.nombreUser && this.state.idUser == this.props.idUser) {

              this.props.reloadNameUser(user)
            }

            if (this.state.contra == 'error') {
              swal('Error al cambiar contraseña', 'Las contraseñas no coinciden. Vuelva a introducir la contraseña nueva y actualice.', 'error')
              this.setState({ contra: 'bien' })
            } else {
              swal('Perfil actualizado', '', 'success')
            }


          } else {
            swal('Error actualizando.', 'Debe tener relleno todos los campos, de todas las secciones. Menos cambiar contraseña.', 'error')
          }

        } catch (e) { console.log(e) }



      } else {


        swal({
          text: "Escriba su contraseña",
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: {
            element: "input",
            attributes: {
              placeholder: "Escriba su contraseña, para confirmar los cambios.",
              type: "password",
            },
          },
          buttons: ["Cancelar", 'Confirmar cambios'],
        })
          .then(async (value) => {

            if (value == null || value == '' || value == undefined) {
              swal("No se realizo ningun cambio");

            } else {
              let { nombre, apellidos, dni, email, user, telefono, ciudad, codigoPostal, direccion, passwordNew1, passwordNew2 } = this.state
              let password = value

              let comprobacion = false;
              let data2 = {
                idUsuario: this.state.idUser
              }
              let response2 = await fetch("https://oposiciones-justicia.es/api/login/passwordUser", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
                throw Error(response2.statusText);
              }
              let json2 = await response2.json();
              let cls2 = await json2;
              comprobacion = bcrypt.compareSync(password, cls2[0].password);
              if (comprobacion) {
                comprobacion = true
              } else {
                comprobacion = false
              }
              if (comprobacion) {
                try {

                  let data = { nombreUsuario: user }
                  let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/nameUserDisponible", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response1.ok) {
                    throw Error(response1.statusText);
                  }
                  let json1 = await response1.json();
                  let cls1 = await json1;
                  console.log(cls1)


                  if (cls1.length == 0 || user == this.props.nombreUser) {

                    if (isDefined(nombre) && isDefined(apellidos) && isDefined(dni) && isDefined(email) && isDefined(user) && isDefined(telefono) && isDefined(ciudad) && isDefined(codigoPostal) && isDefined(direccion)) {

                      if (isDefined(passwordNew1) && isDefined(passwordNew2)) {
                        if (passwordNew1 == passwordNew2) {
                          let hashNewPass = bcrypt.hashSync(passwordNew1, 8)
                          let data2 = {
                            idUsuario: this.state.idUser,
                            passNew: hashNewPass
                          }
                          fetch('https://oposiciones-justicia.es/api/usuarios/passwordNew', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(data2)
                          }).then(res => res.json())
                            .then(datos => {
                              this.refs.ref_passwordNew1.refs.passwordNew1.value = '';
                              this.refs.ref_passwordNew2.refs.passwordNew2.value = '';
                              this.setState({ contra: 'bien' })
                            }).catch(error => console.log('Object fecth failed', error))
                        } else {

                          this.setState({ contra: 'error' })
                        }

                      }

                      let data3 = {
                        idUsuario: this.state.idUser,
                        nombre: nombre,
                        apellidos: apellidos,
                        email: email,
                        user: user,
                        telefono: telefono,
                        ciudad: ciudad,
                        codigoPostal: codigoPostal,
                        direccion: direccion
                      }
                      let response3 = await fetch("https://oposiciones-justicia.es/api/usuarios/actualizarDatosPersonales", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                      });
                      if (!response3.ok) {
                        throw Error(response3.statusText);
                      }
                      let json3 = await response3.json();
                      let cls3 = await json3;

                      if (user != this.props.nombreUser && this.state.idUser == this.props.idUser) {

                        this.props.reloadNameUser(user)
                      }

                      if (this.state.contra == 'error') {
                        swal('Error al cambiar contraseña', 'Las contraseñas no coinciden. Vuelva a introducir la contraseña nueva y actualice.', 'error')
                        this.setState({ contra: 'bien' })
                      } else {
                        swal('Perfil actualizado', '', 'success')
                      }


                    } else {
                      swal('Error actualizando.', 'Debe tener relleno todos los campos, de todas las secciones. Menos cambiar contraseña.', 'error')
                    }
                  } else {
                    swal('El Nombre de Usuario esta en uso', 'Indique otro', 'error')
                  }
                } catch (e) { console.log(e) }
              } else {
                swal("Contraseña incorrecta", "", "error");
              }

            }


          });

      }

    } else if (e.target.id == 'ActualizarImagenPerfil') {
      let { fileToUpload } = this.state
      if (fileToUpload && fileToUpload.length > 0) {
        var data2 = new FormData();
        for (var i = 0; i < fileToUpload.length; i++) {
          data2.append('myFiles', fileToUpload[i]);
        }
        let urlFotoPerfil
        if (data2.getAll('myFiles') && data2.getAll('myFiles').length == 1) {
          // let nombreArchivo = data2.getAll('myFiles')[0].name
          // let tipoArchivo = nombreArchivo.split('.')[1]

          let nombreArchivoOrig = data2.getAll('myFiles')[0].name
          // let tipoArchivo = nombreArchivo.split('.')[1]

          let nombreArchivo = '';
          let tipoArchivo = ''
          for (let i = 0; i < nombreArchivoOrig.split('.').length; i++) {
            if (i == nombreArchivoOrig.split('.').length - 1) {
              tipoArchivo = nombreArchivoOrig.split('.')[i]
            } else {

              if (i == nombreArchivoOrig.split('.').length - 2) {
                nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i]
              } else {
                nombreArchivo = nombreArchivo + nombreArchivoOrig.split('.')[i] + '.'
              }
            }

          }
          if (tipoArchivo && tipoArchivo == 'jpg' || tipoArchivo == 'png' || tipoArchivo == 'jpge') {
            fetch("https://oposiciones-justicia.es/uploadmultiple", {
              method: 'POST',
              body: data2
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              for (var i = 0; i < data.length; i++) {
                let path = data[i].path.split('src/public/')[1];
                let nameFile = data[i].originalname.split('.')[0];
                urlFotoPerfil = '../' + path;
              }
              let data3 = {
                urlNuevaFoto: urlFotoPerfil,
                idUsuario: this.state.idUser
              }
              return fetch("https://oposiciones-justicia.es/api/usuarios/cambiarFotoPerfil", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data3)
              });
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (data.affectedRows > 0) {
                let data4
                if (this.state.fotoPerfil != '../img/perfil/sin-perfil.jpg') {
                  data4 = { pathFile: '../public' + this.state.fotoPerfil.split('..')[1] }
                } else {
                  data4 = { pathFile: undefined }
                }
                return fetch("https://oposiciones-justicia.es/deleteFile", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data4)
                });
              }
            }).then(response => {
              if (response.status >= 400) { throw new Error("Bad response from server"); }
              return response.json();
            }).then(data => {
              if (this.state.idUser == this.props.idUser) {
                this.props.reloadInfoPersonal(urlFotoPerfil);
              }


            }).catch(function (err) { console.log(err) });
          } else {
            swal('Error en nombre o formato de archivo', 'No puede contener ningun punto "el nombre del archivo", debe tener como formato de archivo JPG o PNG.', 'error')
          }
        } else {
          swal('Solo puede escoger una foto', '', 'error')
        }

      } else {
        swal('Error', 'Debe seleccionar una imagen para procesar el cambio.', 'error')
      }
    } else if (e.target.id == 'iniciarSesionDeUser') {
      swal({
        title: "Ver campus como otro usuario",
        text: "Si le das a ok, iniciara sesión con el usuario/alumno cargado. Podrá ver el campus virtual como dicho usuario y no cerrá la sesión del alumno si esta usando la aplicación.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {
            let data = { idUser: this.state.idUser }
            let response = await fetch('https://oposiciones-justicia.es/api/usuarios/infoUser', {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.length > 0) {
              let infoCheckLogin = cls
              this.props.iniciarSesionForce(infoCheckLogin)

              setTimeout(() => {
                window.location.href = 'https://oposiciones-justicia.es/';
              }, 500);

            }
            console.log(cls)
            console.log('cls')
          } else {
            swal("¡Proceso no completado!", '', "success");
          }
        });





      // this.props.iniciarSesionForce(infoCheckLogin, userPersist)
    }
  }

  async componentDidMount() {
    // cargar imagenPerfil en propTypes
    let data = { idUser: this.props.idUser }

    fetch("https://oposiciones-justicia.es/api/usuarios/datosUser", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status >= 400) { throw new Error("Bad response from server"); }
      return response.json();
    }).then(cls => {
      this.setState({
        dni: cls[0].dni == undefined ? ('') : (cls[0].dni),
        nombre: cls[0].nombre == undefined ? ('') : (cls[0].nombre),
        apellidos: cls[0].apellidos == undefined ? ('') : (cls[0].apellidos),
        email: cls[0].email == undefined ? ('') : (cls[0].email),
        user: cls[0].user == undefined ? ('') : (cls[0].user),
        telefono: cls[0].telefono == undefined ? ('') : (cls[0].telefono),
        ciudad: cls[0].ciudad == undefined ? ('') : (cls[0].ciudad),
        codigoPostal: cls[0].codigoPostal == undefined ? ('') : (cls[0].codigoPostal),
        direccion: cls[0].direccion == undefined ? ('') : (cls[0].direccion),
        fotoPerfil: cls[0].fotoPerfil == undefined ? ('') : (cls[0].fotoPerfil),
        idUser: cls[0].idUser == undefined ? ('') : (cls[0].idUser)
      });
    }).catch(error => console.log('Object fecth failed', error))

    let response3 = await fetch("https://oposiciones-justicia.es/api/clases/todasClases");
    if (!response3.ok) {
      throw Error(response3.statusText);
    }
    let json3 = await response3.json();
    let cls3 = await json3;
    await this.setState({ infoAllClases: cls3 });

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
      .then(res => res.json())
      .then(alumnos => this.setState({
        busquedaAlumnos: alumnos
      })).catch(error => console.log('Object fecth failed', error));


  }
  render() {

    const { isLogged, tipoUser } = this.props;
    const { sectionSelecionada, dni, nombre, apellidos, email, user, telefono, ciudad, codigoPostal, direccion, fotoPerfil, busquedaAlumnos, alumnoBuscado } = this.state
    const nivelRequired = 1;

    if (isLogged && (nivelRequired == tipoUser || tipoUser >= 1)) {
      return (
        <div style={{ width: '100%' }} className="inBodyMenu">
          <div style={{ width: '100%' }} className="inBody">

            <div className="perfilBodyNew">
              <div className="headerPFBN">
                <img className="imgBacjOFBN" src="../img/fotoPerfilCejBack.jpg" />
                <div className="fotoAlumnoPFBN">
                  <img src={fotoPerfil} />
                  <InputFileIcono texto="Seleccione 1 Foto de Perfil" name="myFiles" icon1="fas fa-sync-alt" onChange={this.myFiles} />
                </div>
              </div>

              <div className="nombreAPFBN">
                {this.state.nombre + ' ' + this.state.apellidos}
              </div>

              {tipoUser && tipoUser > 50 ? (
                <div className='BE-CL-right-row' style={{ margin: '10px', marginTop: '45px' }}>


                  <div className="BE-CL-right" style={{ margin: '10px' }}>
                    <p> Seleccione un alumno para ver o editar sus datos. </p>
                    <div className="BE-CL-right-cursosList">
                      <Input2 type="text" id="alumnoPreparador" name="alumnoPreparador" placeholder="Alumno" icon="fas fa-search" datalist="alumnosPrepadorLoad" ref="ref_alumnoLoad" onChange={this.onChangeInputNew} />

                      <datalist id="alumnosPrepadorLoad">
                        {busquedaAlumnos && busquedaAlumnos.map((cls, key1) =>
                          <option key={key1} value={cls.dni}>{
                            cls.nombre + ', ' + cls.apellidos + ', idUser: ' + cls.id_usuario
                          }</option>
                        )}
                      </datalist>
                    </div>


                    {alumnoBuscado != null ? (
                      <div style={{ marginTop: '15px' }}>
                        <Boton id="iniciarSesionDeUser" icon1="" icon2="" texto="Iniciar sesión" onClick={this.handleOnSubmit} />
                      </div>
                    ) : null}

                  </div>

                </div>
              ) : null}

              <div className="contentAPFBN">
                <div className="navAPFBN">
                  <div id={1} onClick={this.handleOnClick} className={sectionSelecionada && sectionSelecionada == 1 ? ("nAPFBNcELDASelect") : ("nAPFBNcELDA")}>
                    <i id={1} className="fas fa-user-shield" style={{ fontSize: '21px' }}></i>
                    Datos Personales
                  </div>

                  <div id={2} onClick={this.handleOnClick} className={sectionSelecionada && sectionSelecionada == 2 ? ("nAPFBNcELDASelect") : ("nAPFBNcELDA")}>
                    <i id={2} className="far fa-compass"></i>
                    Dirección
                  </div>

                  <div id={3} onClick={this.handleOnClick} className={sectionSelecionada && sectionSelecionada == 3 ? ("nAPFBNcELDASelect") : ("nAPFBNcELDA")}>
                    <i id={3} className="fab fa-keycdn"></i>
                    Usuario
                  </div>

                </div>
                <div className="datosAPFBN">

                  {sectionSelecionada && sectionSelecionada == 1 ? (
                    <React.Fragment>
                      <div className="dAPFBNdatosIn">
                        <div className="celdaInputDAPFBN">
                          <Input2 value={nombre} autoComplete={"off"} type="text" name="nombre" placeholder="Nombre" icon="fas fa-id-card" ref="ref_nombre" onChange={this.onChangeInput} />
                          <p>Nombre</p>
                        </div>
                        <div className="celdaInputDAPFBN">
                          <Input2 value={apellidos} autoComplete={"off"} type="text" name="apellidos" placeholder="Apellidos" icon="fas fa-id-card" ref="ref_apellidos" onChange={this.onChangeInput} />
                          <p>Apellidos</p>
                        </div>
                        <div className="celdaInputDAPFBN">
                          <Input2 value={dni} readOnly={true} autoComplete={"off"} type="text" name="dni" placeholder="DNI" icon="fas fa-id-card" ref="ref_dni" onChange={this.onChangeInput} />
                          <p>DNI</p>
                        </div>
                        <div className="celdaInputDAPFBN">
                          <Input2 value={telefono} autoComplete={"off"} type="text" name="telefono" placeholder="Telefono" icon="fas fa-phone" ref="ref_telefono" onChange={this.onChangeInput} />
                          <p>Telefono</p>
                        </div>
                      </div>

                      <div>
                        <Boton id="actualizarPefil" icon1="" icon2="" texto="Actualizar Cambios" onClick={this.handleOnSubmit} />
                      </div>
                    </React.Fragment>
                  ) : null
                  }

                  {sectionSelecionada && sectionSelecionada == 2 ? (
                    <React.Fragment>
                      <div className="dAPFBNdatosIn">
                        <div className="celdaInputDAPFBN">
                          <Input2 value={codigoPostal} autoComplete={"off"} type="text" name="codigoPostal" placeholder="Codigo Postal" icon="fas fa-map-marker-alt" ref="ref_codigoPostal" onChange={this.onChangeInput} />
                          <p>Codigo Postal</p>
                        </div>
                        <div className="celdaInputDAPFBN">
                          <Input2 value={ciudad} autoComplete={"off"} type="text" name="ciudad" placeholder="Ciudad" icon="fas fa-flag" ref="ref_ciudad" onChange={this.onChangeInput} />
                          <p>Ciudad</p>
                        </div>
                        <div className="celdaInputDAPFBN" style={{ width: 'unset', maxWidth: '400px' }} >
                          <Input2 value={direccion} autoComplete={"at"} type="text" name="direccion" placeholder="Dirección" icon="fas fa-globe" ref="ref_direccion" onChange={this.onChangeInput} />
                          <p>Dirección</p>
                        </div>
                      </div>

                      <div>
                        <Boton id="actualizarPefil" icon1="" icon2="" texto="Actualizar Cambios" onClick={this.handleOnSubmit} />
                      </div>
                    </React.Fragment>
                  ) : null
                  }

                  {sectionSelecionada && sectionSelecionada == 3 ? (
                    <React.Fragment>
                      <div className="dAPFBNdatosIn">
                        <div className="celdaInputDAPFBN">
                          <Input2 value={user} autoComplete={"off"} type="text" name="user" placeholder="Nombre Usuario" icon="fas fa-user" ref="ref_user" onChange={this.onChangeInput} />
                          <p>Nombre Usuario</p>
                        </div>

                        <div className="celdaInputDAPFBN">
                          <Input2 value={email} autoComplete={"new-password"} type="email" name="email" placeholder="Correo Electrónico" icon="fas fa-at" ref="ref_email" onChange={this.onChangeInput} />
                          <p>Correo Electrónico</p>
                        </div>

                        <div className="celdaInputDAPFBN">
                          <Input2 autoComplete={"new-password"} type="password" name="passwordNew2" placeholder="Contraseña Nueva" icon="fas fa-key" ref="ref_passwordNew2" onChange={this.onChangeInput} />
                          <p>Contraseña Nueva</p>
                        </div>

                        <div className="celdaInputDAPFBN">
                          <Input2 autoComplete={"new-password"} type="password" name="passwordNew1" placeholder="Confirmar Contraseña Nueva" icon="fas fa-key" ref="ref_passwordNew1" onChange={this.onChangeInput} />
                          <p>Confirmar Contraseña</p>
                        </div>


                      </div>

                      <div>
                        <Boton id="actualizarPefil" icon1="" icon2="" texto="Actualizar Cambios" onClick={this.handleOnSubmit} />
                      </div>
                    </React.Fragment>
                  ) : null
                  }
                </div>
              </div>

            </div>



          </div>
        </div>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}
// Conectamos Redux Store
function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    reloadInfoPersonal,
    reloadNameUser,
    iniciarSesionForce
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Perfil);

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/VerTestCasoP.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';
import VistaPreviaTest from '../preguntas/VistaPreviaTest'
import TextoRich from '../../Inputs/TextoRich';

class VerCasoPracticoJusticia extends Component {

	constructor(props){
		super(props);
    	this.state = {
				preguntaBuscar: 0
			};
			this.onChange = this.onChange.bind(this);
			this.handleOnClick = this.handleOnClick.bind(this);
			this.cleanForm = this.cleanForm.bind(this);
	}
	cleanForm(){

	}
	onChange(e){
		if(e.target.id == 'numPregBuscar'){
			let value = e.target.value
			if(value > 0 && value <= this.props.test.length){
				// Mostrar pregunta concreta
				this.setState({ preguntaBuscar: value})
			} else {
				// mostrar todo el test
				this.setState({ preguntaBuscar: 0})
			}
		}
	}
	handleOnClick(e){

	}

	componentDidMount(){



	}
	render() {
		const { preguntaBuscar } = this.state
		const { test } = this.props

		return (
			<div className= "bodyLoadTestCasoP">

			{test && test[0].textHtml && test[0].textHtml.length > 1 ? (
					<div style={{maxWidth: '600px'}}>
						<TextoRich valueTextHtml={test[0].textHtml} />
					</div>
				):null}
				<div className="bodyLoadTest">
				<div className="opocionesLoadTest">
						<div className="inputLoadTest">

							<h4>
								<i className="fas fa-info-circle"></i>
								Número de Pregunta
								<i className="fas fa-arrow-alt-circle-down"></i>
							</h4>
							<Input type="number" min="0" size ="16" placeholder="Nº - Pregunta" id="numPregBuscar" name="numPregBuscar" ref="ref_numPregBuscar" icon="fas fa-search" onChange={this.onChange}/>

						</div>

					</div>

					<div className="inputLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Preguntas Test
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
						<VistaPreviaTest preguntaBuscar={preguntaBuscar} test={test}/>
					</div>
				</div>
			</div>
		);
	}
}


export default VerCasoPracticoJusticia;

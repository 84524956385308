// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/input.css';
import './css/main.css';

class Input2 extends Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    icon: PropTypes.string.isRequired,
    datalist: PropTypes.string,
    id: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.number,
    step: PropTypes.number,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    display: PropTypes.string,
    size: PropTypes.string,
    autoComplete: PropTypes.string,
    mensajeError: PropTypes.string,
    borrarOff: PropTypes.bool,
    checked: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onCleanValue = this.onCleanValue.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onHandleOnclick = this.onHandleOnclick.bind(this);
    this.onBlurON = this.onBlurON.bind(this);
  }
  onHandleOnclick(e) {
    let readOn = this.props.readOnly ? (this.props.readOnly) : (false)
    let borrarOff = this.props.borrarOff ? (this.props.borrarOff) : (false)
    if ((readOn == undefined || readOn == false) && (borrarOff == undefined || borrarOff == false)) {
      this.refs['' + this.props.name].value = ''

      this.props.onChange(e)
    }
  }
  onBlurON(e) {
    this.props.onBlur(e);
  }
  onChangeInput(e) {
    this.props.onChange(e);
  }

  onKeyPress(e) {
    // console.log(this.props.onKeyPress())
    if (this.props.onKeyPress != undefined) {
      this.props.onKeyPress(e);
    }

  }
  onCleanValue(e) {
    if (e.detail === 5) {
      let readOn = this.props.readOnly ? (this.props.readOnly) : (false)
      let borrarOff = this.props.borrarOff ? (this.props.borrarOff) : (false)
      if ((readOn == undefined || readOn == false) && (borrarOff == undefined || borrarOff == false)) {
        this.refs['' + e.target.name].value = ''
        this.props.onChange(e)
      }
    }
  }

  render() {
    const { type, id, name, step, datalist, placeholder, icon, min, max, readOnly, value, title, display, size, autoComplete, marginBottom, marginTop, checked, mensajeError, deletedMode, onBlur } = this.props;
    
    return (

      <div className="wrap-input100" data-validate="Password is required" style={{ display: display, marginBottom: marginBottom, marginTop: marginTop }}>
        {onBlur != undefined && onBlur != null ? (
          <input className={checked != undefined && checked == false ? ("input100 noValidoInput") : ("input100")} type={type} id={id} name={name} placeholder={placeholder} ref={name} step={step} list={datalist} min={min} max={max} onChange={this.onChangeInput} value={value} readOnly={readOnly} title={title} autoComplete={autoComplete} size={size} onClick={this.onCleanValue} onBlur={this.onBlurON} onKeyPress={this.onKeyPress} />
        ) : (
          <input className={checked != undefined && checked == false ? ("input100 noValidoInput") : ("input100")} type={type} id={id} name={name} placeholder={placeholder} ref={name} step={step} list={datalist} min={min} max={max} onChange={this.onChangeInput} value={value} readOnly={readOnly} title={title} autoComplete={autoComplete} size={size} onClick={this.onCleanValue} onKeyPress={this.onKeyPress} />
        )

        }


        <span className="focus-input100 "></span>
        <span className="symbol-input100">
          <i className={icon} style={{ display: display }} id={name} aria-hidden="true"></i>
        </span>

        {checked != undefined && checked == false ? (
          <span className="symbol-input100-alert" onClick={this.handleViewAlert}>
            <p className="sym-input100-alert-info txt2">{mensajeError == undefined ? ('Campo no valido.') : (mensajeError)}</p>
            <i className="fas fa-exclamation-triangle" id={name} aria-hidden="true"></i>

          </span>
        ) : null}

        {checked != undefined && checked == true ? (
          <span className="symbol-input100-alert2">
            <p className="sym-input100-alert-info2 txt2">Campo valido.</p>
            <i className="fas fa-check-circle" id={name} aria-hidden="true"></i>

          </span>
        ) : null}

        {deletedMode != undefined && deletedMode == true ? (
          <span className="symbol-input100-alert" id={name} onClick={this.onHandleOnclick}>

            <i style={{ background: '#fff', color: '#3469b7' }} className="far fa-times-circle" id={name} aria-hidden="true"></i>

          </span>
        ) : null}



      </div>

    );

  }
}

export default Input2;

// Utils
import { getNewState } from '../lib/utils/frontend';

const initialState = {
  optionSelected: ''
};
export default function baroptionReducer(state = initialState, action) {
//  let isMobile = state.isMobile === 'false' ? false : true;

  switch(action.type) {
      case 'CARGAR_CONTENIDO': {
        let optionTarget = action.optionSelected;
        return getNewState(state, {
            optionSelected: optionTarget
          });
      return state;
      }

      default:
        return state;
  }
}

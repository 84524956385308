// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Submit extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    icon1: PropTypes.string,
    icon2: PropTypes.string,
    texto: PropTypes.string.isRequired,
    display: PropTypes.string
  };
constructor(props){
        super(props);
        this.state = {};
          this.onClick = this.onClick.bind(this);
    }
    onClick(e){
        this.props.onClick(e);
    }
  render() {
    const { id, icon1, icon2, texto, display} = this.props;
              return (
                <div className="botonBig" id={id} onClick={this.onClick} style={{display: display}}>
                   <i className={icon1} id={id}></i>
                   <div id={id}>{texto}</div>
                   <i id={id} className={icon2}></i>
                 </div>
              );
        }
 }
export default Submit;

// Dependencies
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
//Assets
import './reservaCSS.css';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input2 from '../../../../../../components/Inputs/Input2';
import Boton from '../../../../../../components/Inputs/Boton';
import Boton2 from '../../../../../../components/Inputs/Boton2';
import swal from 'sweetalert';
import { toNamespacedPath } from 'path';
// Actions
// import { cargarContenido } from './actions/baroptionAction';


class Reserva extends Component {
    constructor(props) {

        super(props)
        this.state = {
            menuLoad: 0,
            busquedaActivos: [
                {
                    value: 0,
                    name: 'NO'
                }, {
                    value: 1,
                    name: 'SI'
                }
            ],
            busquedaPagado: [
                {
                    value: 'Pagado',
                    name: 'Pagado'
                }, {
                    value: 'Pendiente',
                    name: 'Pendiente'
                }
            ],
            busquedaMetodoPagado: [
                {
                    value: 'Metalico',
                    name: 'Metalico'
                }, {
                    value: 'card,sepa_debit',
                    name: 'Campus'
                }
            ]
        }

        this.handleToggleClick = this.handleToggleClick.bind(this)
        this.onChangeInput = this.onChangeInput.bind(this)
    }
    async handleToggleClick(e) {
        if (e.target.id == 'reservas') {
            this.setState({ menuLoad: 0 })
        } else if (e.target.id == 'listas') {
            this.setState({ menuLoad: 1 })
        } else if (e.target.id.split('-')[0] == 'procesarReserva') {
            let alm = this.state.infoReservas
            let locaA = e.target.id.split('-')[1]

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, el alumno será dado de alta.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {

                    swal({
                        title: 'Procesando reserva',
                        text: ' ',
                        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                        buttons: false,
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                    })

                    let data1 = { idUser: alm[locaA].id_usuario, idClase: alm[locaA].id_clase, idClaseC: alm[locaA].factura[0].idClase, valueReserva: 0 }
                    let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/cambiarClaseAlumnoReservaCompletada", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response1.ok) {
                        throw Error(response1.statusText);
                    }
                    let json1 = await response1.json();

                    if (json1.affectedRows > 0) {

                        let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/cambiarClaseAlumnoReservaCompletadaFAC", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                        });
                        if (!response2.ok) {
                            throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();

                        let factura = {
                            idClase: alm[locaA].factura[0].idClase,
                            idAlumno: alm[locaA].factura[0].idUser,
                            idCobrador: 13,
                            totalPagado: alm[locaA].factura[0].precio,
                            metodoPago: alm[locaA].factura[0].metodoPago == 'Metalico' ? ('Metalico') : ('card,sepa_debit'),
                            estado: alm[locaA].factura[0].estado,
                            datosActivados: alm[locaA].factura[0].activo == 'SI' ? (1) : (0),
                            sucripcion: 'Cuota Mensual clase de CEJ - nueva alta completada',
                            concepto: 'Factura emitada el: ' + moment().format("YYYY-MM-DD HH:mm:ss") + '. Alumno: ' + alm[locaA].factura[0].nombre + ', ' + alm[locaA].factura[0].apellidos + '. Mes pagado de la clase: ' + alm[locaA].factura[0].nombreClase + '.',
                            fechaFactura: moment(alm[locaA].factura[0].fechaInicio).format("YYYY-MM-DD 00:00:01"),
                            fechaCaducidad: moment(alm[locaA].factura[0].fechaFin).format("YYYY-MM-DD 23:59:59"),
                            tipo: 0
                        }

                        let response3 = await fetch('https://oposiciones-justicia.es/api/oficina/addFactura', {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(factura)
                        });
                        if (!response3.ok) {
                            throw Error(response3.statusText);
                        }
                        let json3 = await response3.json();
                        let cls3 = await json3;


                        let data = {
                            emailToEnviar: alm[locaA].email,
                            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
                            segundoTitulo: 'Alta completada - CEJ', // titulo real
                            textoHtml: `
                              <h4>Hola,<br/> `+ alm[locaA].nombre + `, ` + alm[locaA].apellidos + `.</h4>
                              <p>Se ha completado el proceso de reserva de plaza, ya puede acceder al campus virtual y disfrutar del contenido de estudio.</p> 
                              <p>Oposición: `+ this.state.busquedaOpo.find(o => o.id_oposicion == alm[locaA].id_oposicion).nombre + `.</p>
                              <p>Curso: `+ alm[locaA].factura[0].nombreClase + `.</p>
                              
                              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
                              <p>Gracias por confiar en CEJ.</p>
                              <p>Un cordial saludo, <br/> del equipo CEJ.</p>
                            `
                        }

                        let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                        });
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        let json = await response.json();
                        let cls = await json;

                        swal({
                            title: 'Proceso completado',
                            text: '¡Recargando web!',
                            icon: "success",
                            buttons: false,
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                            timer: 2500,
                        }).then(() => {
                            window.location.reload()
                        })
                    }

                } else {
                    swal("Proceso cancelado", '', "success");
                }
            });


        } else if (e.target.id.split('-')[0] == 'procesarListaEspera') {

            let alm = this.state.infoListaEspera
            let locaA = e.target.id.split('-')[1]

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, el alumno pasará al proceso de reserva de plaza.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {

                    swal({
                        title: 'Pasando alumno a proceso de reserva',
                        text: ' ',
                        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                        buttons: false,
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                    })
                    let data1 = { idUser: alm[locaA].id_usuario, idClase: alm[locaA].id_clase, valueReserva: 2 }
                    let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/cambiarClaseAlumnoReserva", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response1.ok) {
                        throw Error(response1.statusText);
                    }
                    let json1 = await response1.json();

                    if (json1.affectedRows > 0) {
                        let data = {
                            emailToEnviar: alm[locaA].email,
                            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
                            segundoTitulo: 'Reserva plaza pendiente - CEJ', // titulo real
                            textoHtml: `
                              <h4>Hola,<br/> `+ alm[locaA].nombre + `, ` + alm[locaA].apellidos + `.</h4>
                              <p>Ha pasado al proceso de reserva de plaza, ya tiene su plaza reservada queda el ultimo paso.</p> 
                              <p>La academia se pondrá en contacto con usted para confirmar y finalizar el proceso de alta como alumno.</p>
                              <p>Oposición: `+ this.state.busquedaOpo.find(o => o.id_oposicion == alm[locaA].id_oposicion).nombre + `.</p>
                              <p>Curso: `+ alm[locaA].dia_semanal + `.</p>
                              
                              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
                              <p>Gracias por confiar en CEJ.</p>
                              <p>Un cordial saludo, <br/> del equipo CEJ.</p>
                            `
                        }

                        let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                        });
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        let json = await response.json();
                        let cls = await json;

                        swal({
                            title: 'Proceso completado',
                            text: '¡Recargando web!',
                            icon: "success",
                            buttons: false,
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                            timer: 2500,
                        }).then(() => {
                            window.location.reload()
                        })
                    }


                } else {
                    swal("Proceso cancelado", '', "success");
                }
            });




        } else if (e.target.id.split('-')[0] == 'cancelarListaEspera' || e.target.id.split('-')[0] == 'cancelarReserva') {
            let alm = this.state.infoListaEspera
            if (this.state.menuLoad == 0) {
                alm = this.state.infoReservas
            }
            let locaA = e.target.id.split('-')[1]

            swal({
                title: "¿Estas segur@?",
                text: "Si le das a ok, el alumno será eliminado del proceso de reserva y lista de espera, pero tendrá acceso para apuntarse a otro curso o clase presencial.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {

                    swal({
                        title: 'Procesando cancelación',
                        text: ' ',
                        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
                        buttons: false,
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                    })

                    let data1 = { idUser: alm[locaA].id_usuario, idClase: alm[locaA].id_clase }
                    let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/cancelarAlumnoReserva", {
                        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                    });
                    if (!response1.ok) {
                        throw Error(response1.statusText);
                    }
                    let json1 = await response1.json();


                    if (json1.affectedRows > 0) {

                        let response2 = await fetch("https://oposiciones-justicia.es/api/oficina/deleteFacReserva", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
                        });
                        if (!response2.ok) {
                            throw Error(response2.statusText);
                        }
                        let json2 = await response2.json();


                        let data = {
                            emailToEnviar: alm[locaA].email,
                            titulo: 'CEJ - Oposiciones justicia', // quien lo envia
                            segundoTitulo: 'Cancelación en Lista de espera - CEJ', // titulo real
                            textoHtml: `
                              <h4>Hola,<br/> `+ alm[locaA].nombre + `, ` + alm[locaA].apellidos + `.</h4>
                              <p>Se ha cancelado su alta en la lista de espera.</p> 
                              <p>Oposición: `+ this.state.busquedaOpo.find(o => o.id_oposicion == alm[locaA].id_oposicion).nombre + `.</p>
                              <p>Curso: `+ alm[locaA].dia_semanal + `.</p>
                              
                              <p>Si tiene alguna duda no dude en contestar a este email o llamar por telefono al +34 688 98 80 99.</p>
                              <p>Gracias por confiar en CEJ.</p>
                              <p>Un cordial saludo, <br/> del equipo CEJ.</p>
                            `
                        }

                        let response = await fetch("https://oposiciones-justicia.es/api/sendEmailCEJ", {
                            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                        });
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        let json = await response.json();
                        let cls = await json;

                        swal({
                            title: 'Proceso completado',
                            text: '¡Recargando web!',
                            icon: "success",
                            buttons: false,
                            closeOnEsc: false,
                            closeOnClickOutside: false,
                            timer: 2500,
                        }).then(() => {
                            window.location.reload()
                        })
                    }

                } else {
                    swal("Proceso cancelado", '', "success");
                }
            });



        }


        // Cambiar clases_alumnos clase y estado de reserva +  generar factura nueva 
    }

    async onChangeInput(e) {
        if (e.target.id.split('-')[0] == 'precio') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas
            if (this.state.menuLoad == 0) {
                fac[locaA].factura[0].precio = value

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'claseA') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas

            if (this.state.menuLoad == 0) {
                let claseBusqueda = this.state.busquedaSemClasesAll.find(c => c.dia_semanal == value)
                if (claseBusqueda != undefined) {
                    fac[locaA].factura[0].idClase = claseBusqueda.id_clase
                    fac[locaA].factura[0].nombreClase = value

                } else {
                    fac[locaA].factura[0].nombreClase = value
                }

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'almActivo') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas

            if (this.state.menuLoad == 0) {
                fac[locaA].factura[0].activo = value

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'fechaIni') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas

            if (this.state.menuLoad == 0) {

                fac[locaA].factura[0].fechaInicio = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'fechaFin') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas
            if (this.state.menuLoad == 0) {
                fac[locaA].factura[0].fechaFin = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'estadoPago') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas
            if (this.state.menuLoad == 0) {
                fac[locaA].factura[0].estado = value

                this.setState({ infoReservas: fac })
            }
        } else if (e.target.id.split('-')[0] == 'metodoPago') {
            let value = e.target.value
            let locaA = e.target.id.split('-')[1]
            let fac = this.state.infoReservas
            if (this.state.menuLoad == 0) {
                fac[locaA].factura[0].metodoPago = value

                this.setState({ infoReservas: fac })
            }
        }


    }

    async componentDidMount() {
        fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
            .then(res => res.json())
            .then(oposiciones => this.setState({
                busquedaOpo: oposiciones
            })).catch(error => console.log('Object fecth failed', error));

        fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
            .then(res => res.json())
            .then(clases => this.setState({
                busquedaSemClasesAll: clases
            })).catch(error => console.log('Object fecth failed', error));

        fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
            .then(res => res.json())
            .then(alumnos => this.setState({
                busquedaAlumnos: alumnos
            })).catch(error => console.log('Object fecth failed', error));

        fetch('https://oposiciones-justicia.es/api/oficina/infoReservas')
            .then(res => res.json())
            .then(a => {
                if (a.length > 0) {
                    for (let i = 0; i < a.length; i++) {
                        if (a[i].listaEsperaReserva == 2 || a[i].listaEsperaReserva == 3) {
                            a[i].factura = [{
                                idUser: a[i].id_usuario,
                                nombre: a[i].nombre,
                                apellidos: a[i].apellidos,
                                dni: a[i].dni,
                                idClase: a[i].id_clase,
                                fechaInicio: moment().startOf('month').format('YYYY-MM-DD'),
                                fechaFin: moment().endOf('month').format('YYYY-MM-DD'),
                                precio: 90,
                                nombreClase: a[i].dia_semanal,
                                estado: 'Pendiente',
                                activo: 'SI',
                                tipo: 0,
                                metodoPago: 'Metalico'
                            }]
                        }

                    }
                }
                this.setState({ infoReservas: a.filter(x => x.listaEsperaReserva == 2 || x.listaEsperaReserva == 3), infoListaEspera: a.filter(x => x.listaEsperaReserva == 1) })
                0
            }).catch(error => console.log('Object fecth failed', error));

        let data2 = {}
        let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/infoAllClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
        });
        if (!response2.ok) {
            throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let infoClases = await json2;
        this.setState({ infoClases: infoClases })
    }
    render() {
        const { isLogged, nombreUser } = this.props
        const { busquedaOpo, busquedaAlumnos, busquedaSemClasesAll, infoReservas, infoListaEspera, menuLoad, infoClases, busquedaActivos, busquedaPagado, busquedaMetodoPagado } = this.state

        return (
            <div className="bodyFacturarNR">
                <div className='ofcR-menu'>
                    <b className='linkMofcR' id="reservas" onClick={this.handleToggleClick}
                        style={menuLoad == 0 ? ({ color: '#3469b7', fontSize: '25px' }) : ({})}
                    >
                        Reservas - {infoReservas && infoReservas.length}
                    </b>
                    <b className='linkMofcR' id="listas" onClick={this.handleToggleClick}
                        style={menuLoad == 1 ? ({ color: '#3469b7', fontSize: '25px' }) : ({})}
                    >
                        Lista de espera - {infoListaEspera && infoListaEspera.length}
                    </b>
                </div>

                {infoReservas && menuLoad == 0 ? (
                    <div className='ofcR-menuLoad'>

                        {infoReservas && infoReservas.length > 0 && infoReservas.map((alm, key1) =>
                            <div className='tarjetaRLISTA' key={key1}>
                                <div className='tarjetaRLISTA-rowT'>
                                    <div className='tarjetaRLISTA-row'>
                                        Pendiente confirmacion
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {moment(alm.fecha_pago).format('DD-MM-YYYY HH:mm')}
                                    </div>

                                    <div className='tarjetaRLISTA-row' style={{ position: 'relative' }}>
                                        {alm.dia_semanal}
                                        <b className='tarjetaRLISTA-rowBflyers'>{
                                            infoClases && infoClases != undefined ? (
                                                infoClases.find(c => c.idClase == alm.id_clase).totalAlumnosActivos + ' de ' + infoClases.find(c => c.idClase == alm.id_clase).maxAlumnos) : null}</b>
                                    </div>
                                </div>

                                <div className='tarjetaRLISTA-rowT'>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.nombre + ', ' + alm.apellidos}
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.email}
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.telefono}
                                    </div>
                                </div>

                                <div className='rowALUM' style={{ maxWidth: '680px', marginTop: '15px', marginBottom: '10px' }}>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].precio}
                                            autoComplete={"off"} id={"precio-" + key1} type="float" name={"precio"} ref={"precio"} placeholder="Precio" icon="fa-light fa-euro-sign" onChange={this.onChangeInput} />
                                        <p>Precio</p>
                                    </div>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].nombreClase} datalist="busClases"
                                            autoComplete={"off"} id={"claseA-" + key1} type="text" name={"claseA"} ref={"claseA"} placeholder="Clase" icon="fa-light fa-screen-users" onChange={this.onChangeInput} />
                                        <p>Clase</p>
                                    </div>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].activo} datalist="busquedaActivos"
                                            autoComplete={"off"} id={"almActivo-" + key1} type="text" name={"almActivo"} ref={"almActivo"} placeholder="Activo: SI o NO" icon="fa-light fa-eye" onChange={this.onChangeInput} />
                                        <p>Activo</p>
                                    </div>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].fechaInicio}
                                            autoComplete={"off"} id={"fechaIni-" + key1} type="date" name={"fechaIni"} ref={"fechaIni"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                        <p>Fecha Inicio</p>
                                    </div>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].fechaFin}
                                            autoComplete={"off"} id={"fechaFin-" + key1} type="date" name={"fechaFin"} ref={"fechaFin"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInput} />
                                        <p>Fecha Fin</p>
                                    </div>

                                    <div className="celdaInputDAPFBN">

                                        <Input2
                                            value={alm.factura[0].estado} datalist="busquedaPagado"
                                            autoComplete={"off"} id={"estadoPago-" + key1} type="text" name={"estadoPago"} ref={"estadoPago"} placeholder="Pagado/Pendiente" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                        <p>Pagado</p>
                                    </div>


                                    <div className="celdaInputDAPFBN">
                                        <Input2
                                            value={alm.factura[0].metodoPago} datalist="busquedaMetodoPagado"
                                            autoComplete={"off"} id={"metodoPago-" + key1} type="text" name={"metodoPago"} ref={"metodoPago"} placeholder="Metalico/Campus" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInput} />
                                        <p>Metodo de pago</p>
                                    </div>



                                </div>

                                <div className='tarjetaRLISTA-rowT'>

                                    <Boton id={"cancelarReserva-" + key1} icon1="" icon2="" texto="Cancelar reserva" onClick={this.handleToggleClick} />

                                    <Boton id={"procesarReserva-" + key1} icon1="" icon2="" texto="Procesar reserva" onClick={this.handleToggleClick} />

                                </div>



                            </div>
                        )}

                        {
                            infoReservas && infoReservas.length == 0 ? (
                                <h4 style={{ color: 'steelblue' }}> No tiene ninguna reserva pendiente </h4>
                            ) : null
                        }
                        <datalist id="busClases">{
                            busquedaSemClasesAll && busquedaSemClasesAll.map((c, key) =>
                                <option key={key} value={c.dia_semanal}></option>
                            )
                        }</datalist>
                        <datalist id="busquedaActivos">{
                            busquedaActivos && busquedaActivos.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>
                        <datalist id="busquedaPagado">{
                            busquedaPagado && busquedaPagado.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>

                        <datalist id="busquedaMetodoPagado">{
                            busquedaMetodoPagado && busquedaMetodoPagado.map((cls, key) =>
                                <option key={key} value={cls.name}></option>
                            )
                        }</datalist>





                    </div>
                ) : null}


                {menuLoad == 1 ? (
                    <div className='ofcR-menuLoad'>

                        {infoListaEspera && infoListaEspera.length > 0 && infoListaEspera.map((alm, key1) =>
                            <div className='tarjetaRLISTA' key={key1}>
                                <div className='tarjetaRLISTA-rowT'>
                                    <div className='tarjetaRLISTA-row'>
                                        Esperando una plaza
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {moment(alm.fecha_pago).format('DD-MM-YYYY HH:mm')}
                                    </div>

                                    <div className='tarjetaRLISTA-row' style={{ position: 'relative' }}>
                                        {alm.dia_semanal}
                                        <b className='tarjetaRLISTA-rowBflyers'>{
                                            infoClases && infoClases != undefined ? (
                                                infoClases.find(c => c.idClase == alm.id_clase).totalAlumnosActivos + ' de ' + infoClases.find(c => c.idClase == alm.id_clase).maxAlumnos) : null}</b>
                                    </div>
                                </div>

                                <div className='tarjetaRLISTA-rowT'>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.nombre + ', ' + alm.apellidos}
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.email}
                                    </div>
                                    <div className='tarjetaRLISTA-row'>
                                        {alm.telefono}
                                    </div>
                                </div>



                                <div className='tarjetaRLISTA-rowT' style={{ marginTop: '15px' }}>



                                    <Boton id={"cancelarListaEspera-" + key1} icon1="" icon2="" texto="Cancelar lista espera" onClick={this.handleToggleClick} />

                                    <Boton id={"procesarListaEspera-" + key1} icon1="" icon2="" texto="Pasar a proceso de reserva" onClick={this.handleToggleClick} />

                                </div>



                            </div>
                        )}

                        {
                            infoListaEspera && infoListaEspera.length == 0 ? (
                                <h4 style={{ color: 'steelblue' }}> La lista de espera esta vacia </h4>
                            ) : null
                        }


                    </div>
                ) : null}

            </div>
        );
    }
}
// Conectamos Redux Store

function mapStateToProps(state) {
    return {
        isLogged: state.user.isLogged,
        nombreUser: state.user.nombreUser,
        tipoUser: state.user.tipoUser,
        idUserP: state.user.idUser,
        optionSelected: state.baroption.optionSelected,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // cargarContenido
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Reserva);


// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Assets
import './css/input.css';

class Input extends Component {

  static propTypes = {
	  type: PropTypes.string.isRequired,
	  name: PropTypes.string.isRequired,
	  placeholder: PropTypes.string,
	  icon: PropTypes.string.isRequired,
	  datalist: PropTypes.string,
	  id: PropTypes.string,
	  min: PropTypes.string,
	  max: PropTypes.number,
	  step: PropTypes.number,
	  onFocus: PropTypes.func,
	  onBlur: PropTypes.func,
	  readOnly: PropTypes.bool,
	  title: PropTypes.string,
	  display: PropTypes.string,
	  size: PropTypes.string,
	  autoComplete: PropTypes.string,
    borrarOff: PropTypes.bool
  };

constructor(props){
        super(props);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onFFocus = this.onFFocus.bind(this);
        this.onBBlur = this.onBBlur.bind(this);
        this.onCleanValue = this.onCleanValue.bind(this);
    }
    onChangeInput(e){
        this.props.onChange(e);
    }
onCleanValue(e){
	if(e.detail === 3){
    let readOn = this.props.readOnly ? (this.props.readOnly):(false)
    let borrarOff = this.props.borrarOff ? (this.props.borrarOff):(false)
    if((readOn == undefined || readOn == false) && (borrarOff == undefined || borrarOff == false)  ){
  		this.refs[''+e.target.name].value = ''
  		this.props.onChange(e)
    }
	}
}
    onFFocus(){
      if(this.props.onFocus != undefined){
        this.props.onFocus();
        }
    }
    onBBlur(){
      if(this.props.onFocus != undefined){
        this.props.onBlur();
        }
    }

  render() {
    const { type, id, name, step, datalist, placeholder, icon, min, max, readOnly, value, title, display, size, autoComplete } = this.props;
              return (
                <div className="inputWithImg">
                  <input ref={name} type={type} step={step} className="textInput" list={datalist} placeholder={placeholder} id={id} name={name} min={min} max={max} onChange={this.onChangeInput} onFocus={this.onFFocus} onBlur={this.onBBlur} value={value} readOnly={readOnly} title={title}  style={{display: display}} autoComplete={autoComplete} size={size} onClick={this.onCleanValue}/>
                  <i className={icon} style={{display: display}} id={name}></i>
                </div>
              );

        }
 }

export default Input;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// Data
import itemsMenuAdmin from '../../data/menuInformatica';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Actions

// Components
import MenuAdmin from './components/MenuAdmin';

class Informatica extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };

  render() {

    const { isLogged, nombreUser, tipoUser } = this.props;
    const nivelRequired = 60;

    if(isLogged && (nivelRequired == tipoUser || tipoUser >= 98) ){
    return (
<div className="inBodyMenu">
      <div className="menuBody">
        <MenuAdmin itemsMenu={itemsMenuAdmin} titleMenu="INFORMATICA"/>
      </div>
      <div className="inBody">
        <div className="sectionBody">
        <div style={{fontSize: '27px', padding: '25px'}} >
          <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
          Selecciona una opción para continuar.
          <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
        </div>
        </div>
      </div>
</div>
    );
  } else {
    return(<Redirect to ="/" />);
    }
  }
}

// Estados del store
function mapStateToProps(state){
  return{
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser:  state.user.tipoUser
  }
}

export default connect(mapStateToProps, null)(Informatica);

// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

// Assets
import './css/css.css';
import Stripe2 from "../../components/Stripe2";

// Components
import Input2 from '../../components/Inputs/Input2'
import Boton from '../../components/Inputs/Boton'
import swal from 'sweetalert';

class Facturacion extends Component {
  static propTypes = {
    isLogged: PropTypes.bool.isRequired,
    nombreUser: PropTypes.string.isRequired,
    tipoUser: PropTypes.number.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      checkout: false,
      valueCursoBusqueda: null,
      fechaIni: null,
      fechaFin: null,
      factFechasS: null
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.onChangeInputNew = this.onChangeInputNew.bind(this)
  }

  async onChangeInputNew(e) {

    if (e.target.name == 'fechaIni') {
      this.refs.ref_curso.refs.curso.value = ''
      let fecha = moment(e.target.value).format('YY-MM-DD')
      if (fecha != 'Invalid date' && e.target.value != undefined) {
        if (this.state.fechaFin != null) {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaCaducidad).format('YY-MM-DD') >= fecha && moment(f.fechaInicio).format('YY-MM-DD') <= this.state.fechaFin)
          this.setState({ facturacion: facturasFiltrado, fechaIni: fecha })
        } else {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaCaducidad).format('YY-MM-DD') >= fecha)
          this.setState({ facturacion: facturasFiltrado, fechaIni: fecha })
        }


      } else {
        if (this.state.fechaFin != null) {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaCaducidad).format('YY-MM-DD') <= moment(this.state.fechaFin).format('YY-MM-DD'))
          this.setState({ facturacion: facturasFiltrado, fechaIni: null })
        } else {
          this.setState({ facturacion: this.state.facturacionS, fechaIni: null })
        }

      }
    } else if (e.target.name == 'fechaFin') {
      let fecha = moment(e.target.value).format('YY-MM-DD')
      this.refs.ref_curso.refs.curso.value = ''
      if (fecha != 'Invalid date' && e.target.value != undefined) {
        if (this.state.fechaIni != null) {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaCaducidad).format('YY-MM-DD') >= this.state.fechaIni && moment(f.fechaInicio).format('YY-MM-DD') <= fecha)
          this.setState({ facturacion: facturasFiltrado, fechaFin: fecha })
        } else {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaInicio).format('YY-MM-DD') <= fecha)
          this.setState({ facturacion: facturasFiltrado, fechaFin: fecha })
        }


      } else {
        if (this.state.fechaIni != null) {
          let facturasFiltrado = this.state.facturacionS.filter(f => moment(f.fechaCaducidad).format('YY-MM-DD') >= moment(this.state.fechaIni).format('YY-MM-DD'))
          this.setState({ facturacion: facturasFiltrado, fechaFin: null })
        } else {
          this.setState({ facturacion: this.state.facturacionS, fechaFin: null })
        }

      }
    } else if (e.target.id == 'curso') {
      let curso = this.state.cursos.find(c => c.diaSemanal == e.target.value)
      if (curso != undefined) {

        if (this.state.fechaIni != null || this.state.fechaFin != null) {
          let facturasFiltrado = this.state.facturacion.filter(f => f.idClase == curso.idClase)
          this.setState({ facturacion: facturasFiltrado, valueCursoBusqueda: true, factFechasS: this.state.facturacion })
        } else {
          let facturasFiltrado = this.state.facturacionS.filter(f => f.idClase == curso.idClase)
          this.setState({ facturacion: facturasFiltrado, valueCursoBusqueda: true })
        }

      } else {
        if (this.state.factFechasS != null) {
          this.setState({ facturacion: this.state.factFechasS, valueCursoBusqueda: null, factFechasS: null })
        } else {
          this.setState({ facturacion: this.state.facturacionS, valueCursoBusqueda: null })
        }

      }

    }
  }
  async handleOnSubmit(e) {
    if (e.target.id.split('-')[0] == 'procesarPago') {



      let idFactura = e.target.id.split('-')[1]
      let factura = this.state.facturacion.filter(x => x.estado != 'Pagado')[idFactura]
      let infoAlumno = this.state.infoAlumno

      console.log('infoAlumno')
      console.log(infoAlumno)
      if (factura.idClase == 16 || (factura.idClase >= 21 && factura.idClase <= 24)) {

        let infoFactura = {
          idUser: this.props.idUser,
          precio: parseFloat(factura.precio).toFixed(2),
          moneda: 'EUR',
          descripcion: 'CEJ APP - Pago : ' + factura.suscripcion + ', Alumn@: ' + infoAlumno[0].nombre + ' ' + infoAlumno[0].apellidos + ', Id: ' + factura.idFactura + ', IdUser: ' + this.props.idUser + ', Curso: ' + factura.nombreClase + '.',
          factura: factura
        }
        // console.log('infoFactura')
        // console.log(infoFactura)
        this.setState({ checkout: true, facturaLoad: factura, infoFactura: infoFactura })
      } else {

        swal('Procese el pago por transferencia bancaria.', '', 'info')

      }

    } else if (e.target.id = 'cerrarProceso') {
      this.setState({ checkout: false, facturaLoad: undefined, infoFactura: undefined })
    }
  }

  async componentDidMount() {

    const procesoPagoStatus = new URLSearchParams(window.location.search).get(
      'redirect_status'
    );
    // console.log('procesoPagoStatus')
    // console.log(procesoPagoStatus)
    if (procesoPagoStatus != undefined && procesoPagoStatus != null && procesoPagoStatus != '') {
      if (procesoPagoStatus == 'succeeded') {
        await swal({
          title: 'Pago procesado',
          text: '¡Gracias por confiar en nosotros!',
          icon: "success",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {
          //window.location.reload()
          window.location.href = ('https://oposiciones-justicia.es/Facturacion')
        })
      } else {
        // redirect_status=failed cuando le dan atras o salen del proceso de pago externo
        // control desde webhook api!
        await swal({
          title: 'Pago no procesado',
          text: '¡Algo fallo, vuelva a intentarlo.! Sino consige finalizar el pago tras varios intentos, pongase en contacto con la academia.',
          icon: "info",
          buttons: false,
          closeOnEsc: false,
          closeOnClickOutside: false,
          timer: 4000,
        }).then(() => {
          //window.location.reload()
          window.location.href = ('https://oposiciones-justicia.es/Facturacion')
        })
      }
    }
    //facturas
    let data1 = { idUser: this.props.idUser }
    let response1 = await fetch("https://oposiciones-justicia.es/api/usuarios/facturacion", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
    });
    if (!response1.ok) {
      throw Error(response1.statusText);
    }
    let json1 = await response1.json();
    let cls1 = await json1;
    this.setState({ facturacion: cls1, facturacionS: cls1 })

    let response2 = await fetch("https://oposiciones-justicia.es/api/usuarios/clasesAlumno", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
    });
    if (!response2.ok) {
      throw Error(response2.statusText);
    }
    let json2 = await response2.json();
    let cls2 = await json2;
    this.setState({ cursos: cls2 })

    let response3 = await fetch("https://oposiciones-justicia.es/api/oficina/busquedaAlumno", {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
    });
    if (!response3.ok) {
      throw Error(response3.statusText);
    }
    let json3 = await response3.json();
    let cls3 = await json3;
    this.setState({ infoAlumno: cls3 })

  }



  render() {
    const { isLogged } = this.props;
    const { checkout, facturacion, cursos, facturaLoad, infoFactura, valueCursoBusqueda } = this.state

    if (isLogged) {
      return (
        <React.Fragment>
          <div className={checkout && checkout == true ? ("cuerpoFacturacion2") : ("cuerpoFacturacion")}>
            {checkout && facturaLoad ? (
              <React.Fragment>
                <div className="facturaBody2ProceANgelo">
                  <div className="fB2PAcuerpoFACUTRA">
                    <div className="topBarFacturacion" style={{ justifyContent: 'center' }}>
                      <div className="tBFright">
                        <b style={{ marginBottom: '40px' }}>Procesar Factura </b>
                      </div>
                    </div>
                    <div className="facturaBlock">
                      <div className="FBindividual">


                        <div className="FBcontentFactura2">
                          <div className="FBCdate">
                            <i className="far fa-clipboard"></i>
                            ID: {facturaLoad.idFactura}
                          </div>
                          <div className="FBCdate" title="Curso">
                            <i className="far fa-folder"></i>
                            {facturaLoad.nombreClase}
                          </div>

                          <div className="FBCdate">
                            <i className="fas fa-inbox"></i>
                            Pago: {facturaLoad.suscripcion}
                          </div>

                          <div className="FBCdate">
                            <i className="fas fa-euro-sign"></i>
                            Precio: {facturaLoad.precio}
                          </div>

                          <div className="FBCdate">
                            <i className="far fa-calendar"></i>
                            Fecha Inicio: {moment(facturaLoad.fechaInicio).format('DD-MM-YY')}
                          </div>

                          <div className="FBCdate">
                            <i className="far fa-calendar"></i>
                            Fecha Fin: {moment(facturaLoad.fechaCaducidad).format('DD-MM-YY')}
                          </div>

                          <div className="FBCdate" style={{ cursor: 'pointer', textAlign: 'center' }}>

                            <i className="far fa-times-circle" id={'cerrarProceso'} title="Cerrar proceso de factura" onClick={this.handleOnSubmit} />
                          </div>

                        </div>

                      </div>
                    </div>

                    <Stripe2 infoFactura={infoFactura} />
                  </div>
                  

                </div>

              </React.Fragment>
            ) : (
              <div className="bodyFacturacion">

                <div className="topBarFacturacion">
                  <div className="tBFright">
                    <b>Facturación </b>
                    <div className="avisoFacturas">
                      <i className="fas fa-exclamation-circle"></i>
                      <p>
                        Para continuar con el curso, deberá tener sus facturas pagadas.<br /> <br />
                        Facturas pendientes indicador rojo.
                      </p>
                    </div>
                  </div>
                  <div className="tBFbuscador">
                    <div>
                      Buscar Factura
                    </div>
                    <div className="tBFBcontentBusqe">
                      <div>
                        <Input2 type="date" name="fechaIni" placeholder="Fecha Inicio" icon="fas fa-search" ref="ref_fechaIni" onChange={this.onChangeInputNew} />
                      </div>
                      <div>
                        <Input2 type="date" name="fechaFin" placeholder="Fecha Inicio" icon="fas fa-search" ref="ref_fechaFin" onChange={this.onChangeInputNew} />
                      </div>
                      <div>
                        <Input2 type="text" id="curso" name="curso" deletedMode={valueCursoBusqueda != null ? (true) : (false)} placeholder="Curso" icon="fas fa-search" datalist="cursosAlumno" ref="ref_curso" onChange={this.onChangeInputNew} />
                        <datalist id="cursosAlumno">
                          {cursos && cursos.map((cls, key) =>
                            <option key={key} value={cls.diaSemanal}></option>
                          )}
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="facturaBlock">
                  {facturacion && facturacion.filter(x => x.estado != 'Pagado').map((fac, key) =>
                    <div className="FBindividual">
                      <div className="FBiconF">
                        {fac.estado == 'Pagado' ? (
                          <i style={{ color: '#3469b7' }} className="fas fa-circle"></i>
                        ) : (
                          <i style={{ color: 'red' }} className="fas fa-circle"></i>
                        )}
                      </div>

                      <div className="FBcontentFactura">
                        <div className="FBCdate">
                          <i className="far fa-clipboard"></i>
                          ID: {fac.idFactura}
                        </div>
                        <div className="FBCdate" title="Curso">
                          <i className="far fa-folder"></i>
                          {fac.nombreClase}
                        </div>

                        <div className="FBCdate">
                          <i className="fas fa-inbox"></i>
                          Pago: {fac.suscripcion}
                        </div>

                        <div className="FBCdate">
                          <i className="fas fa-euro-sign"></i>
                          Precio: {fac.precio}
                        </div>

                        <div className="FBCdate">
                          <i className="far fa-calendar"></i>
                          Fecha Inicio: {moment(fac.fechaInicio).format('DD-MM-YY')}
                        </div>

                        <div className="FBCdate">
                          <i className="far fa-calendar"></i>
                          Fecha Fin: {moment(fac.fechaCaducidad).format('DD-MM-YY')}
                        </div>

                        <div style={{ width: '140px', padding: '10px 0px' }}>
                          {fac.estado == 'Pagado' ? (
                            <Boton icon1="" icon2="" texto="Pagado" onClick={this.handleOnSubmit} />
                          ) : (
                            <Boton id={'procesarPago-' + key} icon1="" icon2="" texto="Procesar Pago" onClick={this.handleOnSubmit} />
                          )}
                        </div>

                      </div>

                    </div>
                  )}
                  {facturacion && facturacion.filter(x => x.estado == 'Pagado').map((fac, key) =>
                    <div className="FBindividual">
                      <div className="FBiconF">
                        {fac.estado == 'Pagado' ? (
                          <i style={{ color: '#3469b7' }} className="fas fa-circle"></i>
                        ) : (
                          <i style={{ color: 'red' }} className="fas fa-circle"></i>
                        )}
                      </div>

                      <div className="FBcontentFactura">
                        <div className="FBCdate">
                          <i className="far fa-clipboard"></i>
                          ID: {fac.idFactura}
                        </div>
                        <div className="FBCdate" title="Curso">
                          <i className="far fa-folder"></i>
                          {fac.nombreClase}
                        </div>

                        <div className="FBCdate">
                          <i className="fas fa-inbox"></i>
                          Pago: {fac.suscripcion}
                        </div>

                        <div className="FBCdate">
                          <i className="fas fa-euro-sign"></i>
                          Precio: {fac.precio}
                        </div>

                        <div className="FBCdate">
                          <i className="far fa-calendar"></i>
                          Fecha Inicio: {moment(fac.fechaInicio).format('DD-MM-YY')}
                        </div>

                        <div className="FBCdate">
                          <i className="far fa-calendar"></i>
                          Fecha Fin: {moment(fac.fechaCaducidad).format('DD-MM-YY')}
                        </div>

                        <div style={{ width: '140px', padding: '10px 0px' }}>
                          {fac.estado == 'Pagado' ? (
                            <Boton icon1="" icon2="" texto="Pagado" onClick={this.handleOnSubmit} />
                          ) : (
                            <Boton id={'procesarPago-' + key} icon1="" icon2="" texto="Procesar Pago" onClick={this.handleOnSubmit} />
                          )}
                        </div>

                      </div>

                    </div>
                  )}
                </div>



              </div>

            )}

          </div>
          {checkout && facturaLoad ? (
            <div className="backgroundImgFact">
              <img src="../img/fondoPagoFac.jpg" />
            </div>
          ) : null}
        </React.Fragment>
      );
    } else {
      return (<Redirect to="/" />);
    }
  }
}

function mapStateToProps(state) {
  return {
    isLogged: state.user.isLogged,
    nombreUser: state.user.nombreUser,
    tipoUser: state.user.tipoUser,
    idUser: state.user.idUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Facturacion);


// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';
// Assets
import './css/prgInfoSelected.css';
import Submit from '../../../Inputs/Submit';

class PreguntaInfoDelSelected extends Component {

  static propTypes = {
    pregunta: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          toogleInfoPregunta: 'none'
        };
        this.onToggleClick = this.onToggleClick.bind(this);
        this.handleNota = this.handleNota.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleNota(e){
        const { nombreAlumno, oposicion } = this.props;
      var data = {
      nota: this.state.nota_add,
      alumno: nombreAlumno,
      oposicion: oposicion

    }
    fetch("https://oposiciones-justicia.es/api/oficina/addNotaCita", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }).then(response =>  {
          if (response.status >= 400) { throw new Error("Bad response from server");}
        return response.json();
      }).then(data => {
          if(data.affectedRows > 0){
		   swal("¡Nota Actualizada!", "success");
          }}).catch(function(err) {console.log(err)});
    }
    onChangeInput(e){
        this.setState({[e.target.name]: e.target.value});
      }

    onToggleClick(e){


      if(e.target.id == 'toogleInfoPregunta'){
        let { id } = this.props;
        $('#infoPregunta-'+id+'-'+id).slideToggle(1000);
      }
    }

    handleOnClick(e){
      switch (e.target.id.split('-')[0]) {
        case 'deseleccionarPreguntaBorrar':
          this.props.onClick(e)
          break;
        default:

      }
    }
  componentDidMount(){

    }
  render() {
    const { id, pregunta } = this.props;
              return (
                <div className="infoPreguntaBody">

                  <div className="resumenPreguntaSel">

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.idPregunta}
                    </div>

                    <div className="infoCompressPregText">
                      {pregunta.preguntaTexto.substring(0,30)+'...'}
                    </div>

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.articulo}
                    </div>

                    <div className="infoCompressPreg">
                      <i className="fas fa-info-circle"></i>
                      {pregunta.siglasLey}
                    </div>
                    <div className="infoCompressPregToogle">
                      <i className="fas fa-caret-down" id={'toogleInfoPregunta'} onClick={this.onToggleClick}></i>
                    </div>

        					</div>
        					<div className="infoPregunta" id={'infoPregunta-'+id+'-'+id}>
                    <div className="infoPreguntaFlex">
                      <div className="delSelectPreg">
                      <Submit id={"deseleccionarPreguntaBorrar-"+id+'-'+id} icon1="fas fa-save" icon2="fas fa-undo" texto="No borrar" onClick={this.handleOnClick}/>
                      </div>
          						<div className="PreguntaCompleta">

                        <i className="fas fa-question"></i>
                        <i className="fas fa-angle-right" style={{marginRight: '15px'}}></i>
                        <div className="textRespuesta">
                          {pregunta.preguntaTexto}
                        </div>
                      </div>

                      <div className={
                        pregunta.r1Validez == 1 ||
                        pregunta.r1Validez == 5 ||
                        pregunta.r1Validez == 6 ||
                        pregunta.r1Validez == 9 ||
                        pregunta.r1Validez == 11 ||
                        pregunta.r1Validez == 12 ||
                        pregunta.r1Validez == 17 ||
                        pregunta.r1Validez == 20 ||
                        pregunta.r1Validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> A </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r1}
                        </div>
                      </div>

                      <div className={
                        pregunta.r2Validez == 1 ||
                        pregunta.r2Validez == 5 ||
                        pregunta.r2Validez == 6 ||
                        pregunta.r2Validez == 9 ||
                        pregunta.r2Validez == 11 ||
                        pregunta.r2Validez == 12 ||
                        pregunta.r2Validez == 17 ||
                        pregunta.r2Validez == 20 ||
                        pregunta.r2Validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> B </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r2}
                        </div>
                      </div>

                      <div className={
                        pregunta.r3Validez == 1 ||
                        pregunta.r3Validez == 5 ||
                        pregunta.r3Validez == 6 ||
                        pregunta.r3Validez == 9 ||
                        pregunta.r3Validez == 11 ||
                        pregunta.r3Validez == 12 ||
                        pregunta.r3Validez == 17 ||
                        pregunta.r3Validez == 20 ||
                        pregunta.r3Validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                      <letra> C </letra>
                      <i className="fas fa-angle-right"></i>
                      <div className="textRespuesta">
                        {pregunta.r3}
                      </div>
                      </div>

                      <div className={
                        pregunta.r4Validez == 1 ||
                        pregunta.r4Validez == 5 ||
                        pregunta.r4Validez == 6 ||
                        pregunta.r4Validez == 9 ||
                        pregunta.r4Validez == 11 ||
                        pregunta.r4Validez == 12 ||
                        pregunta.r4Validez == 17 ||
                        pregunta.r4Validez == 20 ||
                        pregunta.r4Validez == 24
                        ? ('respuestaCorrecta'):('respuesta')
                      }>
                        <letra> D </letra>
                        <i className="fas fa-angle-right"></i>
                        <div className="textRespuesta">
                          {pregunta.r4}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              );

        }
 }

export default PreguntaInfoDelSelected;

// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Utils
import { isDefined } from '../../../../../lib/utils/is';

//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Input from '../../../../../components/Inputs/Input';
import InputTemas from '../../../../../components/Inputs/InputTemas';
import InputTemasEdt from '../../../../../components/Inputs/InputTemasEdt';
import InputTemaLeyesEdt from '../../../../../components/Inputs/InputTemaLeyesEdt';
import InputTemaPdfEdt from '../../../../../components/Inputs/InputTemaPdfEdt';
import Submit from '../../../../../components/Inputs/Submit';
import BotonMedio from '../../../../../components/Inputs/BotonMedio';

class optionBar extends Component {
  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };

constructor(props){
        super(props);
        this.state = {
          numInter: '',
          test_pred_añadir: '',
					test_word_pred_añadir: '',
					test_caso_practico_pred_añadir: '',
          num_semana_añadir: '',
          nombre_opo_añadir: '',
          nombre_opo_del: '',
          programa_del: '',
          statusBarraProceso: 'completar',
          nombreOpoEditarTema: '',
          semanaSelectEditTema: '',
					nombreOpoEditarLey: '',
          semanaSelectEditLey: '',
          infoTemasEdt: [],
					infoTemasLeyEdt: [],
					infoTemaLeyEdt: [],
					infoTemaPdfEdt: []
        };
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeInputDinamic = this.onChangeInputDinamic.bind(this);
        this.onChangeIntervalo = this.onChangeIntervalo.bind(this);
        this.onChangeEdtTema = this.onChangeEdtTema.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.cleanForm = this.cleanForm.bind(this);
				this.onChangeEdtTemaLey = this.onChangeEdtTemaLey.bind(this);
				this.onChangeEdtTemaPdf = this.onChangeEdtTemaPdf.bind(this);
				this.handleAddTemaEdt = this.handleAddTemaEdt.bind(this);
				this.handleAddTemaLeyesEdt = this.handleAddTemaLeyesEdt.bind(this);
				this.handleAddPdfEdt = this.handleAddPdfEdt.bind(this);
				//this.downloadFile = this.downloadFile.bind(this);
    }
/*
	// submit <Submit id="descarga_tema" icon1="fas fa-edit" icon2="fas fa-database" texto="descarga" onClick={this.downloadFile}/>
downloadFile(e){
	if(e.target.name != 'descarga_tema'){

		let pathFile = `public/upload/files/Temas/pdf/tema5.pdf`;
		let url = new URL("https://oposiciones-justicia.es/api/file/loadFile?route="+pathFile);
		let nameFile = pathFile.split('/').pop().split('-,-')[0];
		fetch(url, {
						method: 'GET',
						headers: {'Content-Type': 'application/json'},
						responseType: 'blob'
						}).then(res =>  {
								if (res.status >= 400) { throw new Error("Bad res from server");}
								return res.blob();
						}).then(data => {
							saveAs(data,nameFile);
						}).catch(function(err) {console.log(err)});
	} else {
		alert("Link no valido para descarga, reportelo a desarrollo, gracias.")
	}
}
*/
handleAddTemaEdt(){
	let {infoTemasEdt ,idProgramaEdtTema} = this.state;
	if(isDefined(infoTemasEdt) && isDefined(idProgramaEdtTema)){
 	 infoTemasEdt.push({
 		 id_tema_prg: false,
 		 id_programa: idProgramaEdtTema,
 		 id_tema: null,
 		 tipo_tema: null,
 		 nuevo_id_tema: null,
 		 readOnly: false,
 		 editar: 0,
 		 eliminar: 0
 		 })
  } else {
	 alert("¡Necesita seleccionar una Semana de la Programación para continuar!.")
 } this.forceUpdate();
}
handleAddPdfEdt(){
		let { infoTemasPdfEdt, busquedaTemas, infoTemaPdfEdt, nombreOpoEditarTemaPdf, semanaSelectEditTemaPdf, temaSelectEditTemaPdf } = this.state
		let id_tema = busquedaTemas.find(tema => tema.titulo_tema == temaSelectEditTemaPdf);
		if(isDefined(nombreOpoEditarTemaPdf) && isDefined(semanaSelectEditTemaPdf) && isDefined(temaSelectEditTemaPdf) && isDefined(infoTemaPdfEdt) && isDefined(id_tema) && isDefined(infoTemasPdfEdt)){
			let id_tema_prg = infoTemasPdfEdt.find(temaPdf => temaPdf.id_tema == id_tema.id_tema)
			 infoTemaPdfEdt.push({
				id_apunte_tema_prg : undefined,
			 	id_tema_prg: id_tema_prg.id_tema_prg,
			 	id_apunte: undefined,
			 	titulo_archivo: undefined,
			 	nuevo_id_apunte: undefined,
			 	readOnly: false,
			 	editar: 0,
			 	eliminar: 0
				 })
				 let data = {
					 id_tema: id_tema.id_tema
				 }
			 fetch("https://oposiciones-justicia.es/api/temas/consultaApuntesTemas", {
							 method: 'POST',
							 headers: {'Content-Type': 'application/json'},
							 body: JSON.stringify(data)
					 }).then(res => {if (res.status >= 400) { throw new Error("Bad response from server");} return res.json()})
					 .then(temasApuntes => this.setState({
							 busquedaTemasApuntes: temasApuntes
					 }))
				 .catch(error => console.log('Object fecth failed', error))
				 this.forceUpdate();
		} else {
		 alert("¡Necesita seleccionar un tema para añadir Apuntes!.")
	 }
}
handleAddTemaLeyesEdt(){
	let { infoTemasLeyEdt, busquedaTemas, infoTemaLeyEdt, nombreOpoEditarLey, semanaSelectEditLey, temaSelectEditLey } = this.state
	let id_tema = busquedaTemas.find(tema => tema.titulo_tema == temaSelectEditLey);
  if(isDefined(nombreOpoEditarLey) && isDefined(semanaSelectEditLey) && isDefined(temaSelectEditLey) && isDefined(infoTemaLeyEdt) && isDefined(id_tema) && isDefined(infoTemasLeyEdt)){
	let id_tema_prg = infoTemasLeyEdt.find(tema => tema.id_tema == id_tema.id_tema)
	 infoTemaLeyEdt.push({
		 id_tema_prg: id_tema_prg.id_tema_prg,
		 id_ley: undefined,
		 art_ini: undefined,
		 art_fin: undefined,
		 nuevo_id_ley: false,
		 readOnly: false,
		 editar: 0,
		 eliminar: 0
		 })
		 this.forceUpdate();
 } else {
	 alert("Necesita seleccionar un tema para añadir un intervalo.")
 }
}
cleanForm(){
      switch (this.props.selectedOption) {
        case 'Añadir_Programacion': {
					this.setState({statusBarraProceso: "completado", numInter: '', test_pred_añadir: '', test_word_pred_añadir: '', test_caso_practico_pred_añadir: '', num_semana_añadir: '',nombre_opo_añadir: '', intervalos: undefined, intervaloTemas: undefined});
					this.refs.ref_nombre_opo_añadir.refs.nombre_opo_añadir.value='';
					this.refs.ref_test_pred_añadir.refs.test_pred_añadir.value='';
					this.refs.ref_test_word_pred_añadir.refs.test_word_pred_añadir.value='';
					this.refs.ref_test_caso_practico_pred_añadir.refs.test_caso_practico_pred_añadir.value='';
					this.refs.ref_meca_pred_añadir.refs.meca_pred_añadir.value='';
					this.refs.ref_num_semana_añadir.refs.num_semana_añadir.value='';
					this.refs.ref_numInter.refs.numInter.value='';
        }break;

        case 'Borrar_Empleado': {
            this.setState({slc_dni_del: ''});
            this.refs.ref_slc_dni_del.refs.slc_dni_del.value='';
        }break;
         case 'Editar_Leyes_Tema_Programacion': {
             this.setState({statusBarraProceso: 'completado', nombreOpoEditarLey: '', semanaSelectEditLey: '', temaSelectEditLey: '',busquedaTemasPrograms: [], infoTemasLeyEdt: [], infoTemaLeyEdt: []});
             this.refs.ref_nombreOpoEditarLey.refs.nombreOpoEditarLey.value='';
             this.refs.ref_semanaSelectEditLey.refs.semanaSelectEditLey.value='';
             this.refs.ref_temaSelectEditLey.refs.temaSelectEditLey.value='';
         }break;
				 case 'Editar_Pdf_Tema_Programacion': {
             this.setState({statusBarraProceso: 'completado', nombreOpoEditarTemaPdf: '', semanaSelectEditTemaPdf: '', temaSelectEditTemaPdf: '',busquedaTemasPrograms: [], infoTemasPdfEdt: [], infoTemaPdfEdt: []});
             this.refs.ref_nombreOpoEditarTemaPdf.refs.nombreOpoEditarTemaPdf.value='';
             this.refs.ref_semanaSelectEditTemaPdf.refs.semanaSelectEditTemaPdf.value='';
             this.refs.ref_temaSelectEditTemaPdf.refs.temaSelectEditTemaPdf.value='';
         }break;
      }
    }

	onChangeEdtTemaPdf(e){
			 this.setState({[e.target.name]: e.target.value});
			if(e.target.name === 'nombreOpoEditarTemaPdf'){
						let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
						if(oposicionObject != undefined){
								let data = { id_oposicion: oposicionObject.id_oposicion };
								fetch("https://oposiciones-justicia.es/api/prosemanal/consultaID", {
									method: 'POST',
									headers: {'Content-Type': 'application/json'},
									body: JSON.stringify(data)
								}).then(response =>  {
										if (response.status >= 400) { throw new Error("Bad response from server");}
									return response.json();
								}).then(prg => this.setState({busquedaPrograms: prg}))
							.catch(error => console.log('Object fecth failed', error))
						}else{
							this.setState({busquedaPrograms: ''})
						}
					} else if(e.target.name === 'semanaSelectEditTemaPdf'){
						let semanaObject = this.state.busquedaPrograms.find(semana => semana.numero_semana == e.target.value);
						if(semanaObject != undefined){
								let data = { id_programa: semanaObject.id_programa };
								fetch("https://oposiciones-justicia.es/api/prosemanal/temasPrograma", {
									method: 'POST',
									headers: {'Content-Type': 'application/json'},
									body: JSON.stringify(data)
								}).then(response =>  {
										if (response.status >= 400) { throw new Error("Bad response from server");}
									return response.json();
								}).then(prgTemas => {
									this.setState({busquedaTemasPrograms: prgTemas})
									let infoTemasPrev = [];
									for (var i = 0; i < this.state.busquedaTemasPrograms.length; i++) {
													infoTemasPrev.push({
													id_tema_prg: this.state.busquedaTemasPrograms[i].id_tema_prg,
													id_tema: this.state.busquedaTemasPrograms[i].id_tema,
													tituloTema: this.state.busquedaTemas.find(tema => tema.id_tema == this.state.busquedaTemasPrograms[i].id_tema).titulo_tema
												});
									}
									this.setState({infoTemasPdfEdt: infoTemasPrev});
							}
							).catch(error => console.log('Object fecth failed', error))
						} else {
							this.setState({busquedaTemasPrograms: [], infoTemasPdfEdt: []});
						}
					} else if(e.target.name === 'temaSelectEditTemaPdf'){
							let idTemaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema == e.target.value);
							if(idTemaObject != undefined){
									let idTemaPrg = this.state.infoTemasPdfEdt.find(tema => tema.id_tema == idTemaObject.id_tema).id_tema_prg
									let data = { id_tema_prg: idTemaPrg};
									fetch("https://oposiciones-justicia.es/api/prosemanal/temasPdfPrograma", {
										method: 'POST',
										headers: {'Content-Type': 'application/json'},
										body: JSON.stringify(data)
									}).then(response =>  {
											if (response.status >= 400) { throw new Error("Bad response from server");}
										return response.json();
									}).then(prgTemaPdfs => {
										this.setState({busquedaTemaPdfPrograms: prgTemaPdfs})
										let infoTemaPdfPrev = [];
										for (var i = 0; i < this.state.busquedaTemaPdfPrograms.length; i++) {
														infoTemaPdfPrev.push({
													  id_apunte_tema_prg : this.state.busquedaTemaPdfPrograms[i].id_apunte_tema_prg,
														id_tema_prg: this.state.busquedaTemaPdfPrograms[i].id_tema_prg,
														id_apunte: this.state.busquedaTemaPdfPrograms[i].id_apunte,
														titulo_archivo: this.state.busquedaApuntesPrograms.find(apunte => apunte.id_apunte == this.state.busquedaTemaPdfPrograms[i].id_apunte).titulo_archivo,
														nuevo_id_apunte: undefined,
														readOnly: true,
														editar: 0,
														eliminar: 0
													});
										}
										this.setState({infoTemaPdfEdt: infoTemaPdfPrev});
								}
								).catch(error => console.log('Object fecth failed', error))
							} else {
								this.setState({busquedaTemaPdfPrograms: [], infoTemaPdfEdt: []});
							}
					}else {
						if(e.target.name == 'Pdf' ){
								let idPdfObject = this.state.busquedaApuntesPrograms.find(pdf => pdf.id_apunte == e.target.value);
								if(idPdfObject != undefined){
									this.state.infoTemaPdfEdt[e.target.id].nuevo_id_apunte = e.target.value;
								} else {
									this.state.infoTemaPdfEdt[e.target.id].nuevo_id_apunte = undefined;
								}
						} else if(e.target.name.substring(1) == 'editarBorrar'){
							if(e.target.value == 1){
								this.state.infoTemaPdfEdt[e.target.id].editar = 1;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 0;
							} else if(e.target.value == 2){
								this.state.infoTemaPdfEdt[e.target.id].editar = 0;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 1;
							} else if(e.target.value == 3){
								this.state.infoTemaPdfEdt[e.target.id].editar = 0;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 0;
							}
						} else if(e.target.name.substring(2) == 'editarBorrar'){
							if(e.target.value == 1){
								this.state.infoTemaPdfEdt[e.target.id].editar = 1;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 0;
							} else if(e.target.value == 2){
								this.state.infoTemaPdfEdt[e.target.id].editar = 0;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 1;
							} else if(e.target.value == 3){
								this.state.infoTemaPdfEdt[e.target.id].editar = 0;
								this.state.infoTemaPdfEdt[e.target.id].eliminar = 0;
							}
						}
					}
		}

		onChangeEdtTemaLey(e){
			 this.setState({[e.target.name]: e.target.value});
		  if(e.target.name === 'nombreOpoEditarLey'){
		        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
		        if(oposicionObject != undefined){
		            let data = { id_oposicion: oposicionObject.id_oposicion };
		            fetch("https://oposiciones-justicia.es/api/prosemanal/consultaID", {
		              method: 'POST',
		              headers: {'Content-Type': 'application/json'},
		              body: JSON.stringify(data)
		            }).then(response =>  {
		                if (response.status >= 400) { throw new Error("Bad response from server");}
		              return response.json();
		            }).then(prg => this.setState({busquedaPrograms: prg}))
		          .catch(error => console.log('Object fecth failed', error))
		        }else{
		          this.setState({busquedaPrograms: ''})
		        }
		      } else if(e.target.name === 'semanaSelectEditLey'){
		        let semanaObject = this.state.busquedaPrograms.find(semana => semana.numero_semana == e.target.value);
		        if(semanaObject != undefined){
		            let data = { id_programa: semanaObject.id_programa };
		            fetch("https://oposiciones-justicia.es/api/prosemanal/temasPrograma", {
		              method: 'POST',
		              headers: {'Content-Type': 'application/json'},
		              body: JSON.stringify(data)
		            }).then(response =>  {
		                if (response.status >= 400) { throw new Error("Bad response from server");}
		              return response.json();
		            }).then(prgTemas => {
		              this.setState({busquedaTemasPrograms: prgTemas})
									let infoTemasPrev = [];
		              for (var i = 0; i < this.state.busquedaTemasPrograms.length; i++) {
		                      infoTemasPrev.push({
		                      id_tema_prg: this.state.busquedaTemasPrograms[i].id_tema_prg,
		                      id_tema: this.state.busquedaTemasPrograms[i].id_tema,
		                      tituloTema: this.state.busquedaTemas.find(tema => tema.id_tema == this.state.busquedaTemasPrograms[i].id_tema).titulo_tema
		                    });
		              }
									this.setState({infoTemasLeyEdt: infoTemasPrev});
							}
		          ).catch(error => console.log('Object fecth failed', error))
		        } else {
		          this.setState({busquedaTemasPrograms: [], infoTemasLeyEdt: []});
		        }
		      } else if(e.target.name === 'temaSelectEditLey'){
							let idTemaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema == e.target.value);
			        if(idTemaObject != undefined){
									let idTemaPrg = this.state.infoTemasLeyEdt.find(tema => tema.id_tema == idTemaObject.id_tema).id_tema_prg
			            let data = { id_tema_prg: idTemaPrg};

			            fetch("https://oposiciones-justicia.es/api/prosemanal/temasLeyesPrograma", {
			              method: 'POST',
			              headers: {'Content-Type': 'application/json'},
			              body: JSON.stringify(data)
			            }).then(response =>  {
			                if (response.status >= 400) { throw new Error("Bad response from server");}
			              return response.json();
			            }).then(prgTemaLeyes => {
			              this.setState({busquedaTemaLeyesPrograms: prgTemaLeyes})
										let infoTemaLeyesPrev = [];
			              for (var i = 0; i < this.state.busquedaTemaLeyesPrograms.length; i++) {
			                      infoTemaLeyesPrev.push({
														id_intervalo_art: this.state.busquedaTemaLeyesPrograms[i].id_intervalo_art,
			                      id_tema_prg: this.state.busquedaTemaLeyesPrograms[i].id_tema_prg,
			                      id_ley: this.state.busquedaTemaLeyesPrograms[i].id_ley,
			                      art_ini: this.state.busquedaTemaLeyesPrograms[i].art_ini,
			                      art_fin: this.state.busquedaTemaLeyesPrograms[i].art_fin,
			                      valueLey: this.state.busquedaLeyes.find(ley => ley.id_ley == this.state.busquedaTemaLeyesPrograms[i].id_ley).siglas_ley,
														nuevo_id_ley: undefined,
			                      readOnly: true,
			                      editar: 0,
			                      eliminar: 0
			                    });
			              }
										this.setState({infoTemaLeyEdt: infoTemaLeyesPrev});
								}
			          ).catch(error => console.log('Object fecth failed', error))
			        } else {
			          this.setState({busquedaTemaLeyesPrograms: [], infoTemaLeyEdt: []});
			        }
					}else {
		        if(e.target.name == 'Ley' ){
		            let idLeyObject = this.state.busquedaLeyes.find(ley => ley.id_ley == e.target.value);
		            if(idLeyObject != undefined){
		              this.state.infoTemaLeyEdt[e.target.id].nuevo_id_ley = e.target.value;
		            } else {
		              this.state.infoTemaLeyEdt[e.target.id].nuevo_id_ley = undefined;
		            }
		        } else if(e.target.name == 'artInicio'){
		          this.state.infoTemaLeyEdt[e.target.id].art_ini = e.target.value;
		        } else if(e.target.name == 'artFin'){
							 this.state.infoTemaLeyEdt[e.target.id].art_fin = e.target.value;
						}else if(e.target.name.substring(1) == 'editarBorrar'){
		          if(e.target.value == 1){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 1;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 0;
		          } else if(e.target.value == 2){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 0;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 1;
		          } else if(e.target.value == 3){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 0;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 0;
		          }
		        } else if(e.target.name.substring(2) == 'editarBorrar'){
		          if(e.target.value == 1){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 1;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 0;
		          } else if(e.target.value == 2){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 0;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 1;
		          } else if(e.target.value == 3){
		            this.state.infoTemaLeyEdt[e.target.id].editar = 0;
		            this.state.infoTemaLeyEdt[e.target.id].eliminar = 0;
		          }
		        }
		      }
		}

onChangeEdtTema(e){
	 this.setState({[e.target.name]: e.target.value});
  if(e.target.name === 'nombreOpoEditarTema'){
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
        if(oposicionObject != undefined){
            let data = { id_oposicion: oposicionObject.id_oposicion };
            fetch("https://oposiciones-justicia.es/api/prosemanal/consultaID", {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
            }).then(response =>  {
                if (response.status >= 400) { throw new Error("Bad response from server");}
              return response.json();
            }).then(prg => this.setState({busquedaPrograms: prg}))
          .catch(error => console.log('Object fecth failed', error))
        }else{
          this.setState({busquedaPrograms: ''})
        }
      } else if(e.target.name === 'semanaSelectEditTema'){
        let semanaObject = this.state.busquedaPrograms.find(semana => semana.numero_semana == e.target.value);
        if(semanaObject != undefined){
						this.setState({idProgramaEdtTema: semanaObject.id_programa});
            let data = { id_programa: semanaObject.id_programa };
            fetch("https://oposiciones-justicia.es/api/prosemanal/temasPrograma", {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
            }).then(response =>  {
                if (response.status >= 400) { throw new Error("Bad response from server");}
              return response.json();
            }).then(prgTemas => {
              this.setState({busquedaTemasPrograms: prgTemas})
							let infoTemaPrev = [];
              for (var i = 0; i < this.state.busquedaTemasPrograms.length; i++) {
                      infoTemaPrev.push({
                      id_tema_prg: this.state.busquedaTemasPrograms[i].id_tema_prg,
                      id_programa: this.state.busquedaTemasPrograms[i].id_programa,
                      id_tema: this.state.busquedaTemasPrograms[i].id_tema,
                      tipo_tema: this.state.busquedaTemasPrograms[i].tipo_tema,
                      nuevo_id_tema: null,
                      readOnly: true,
                      valueTema: this.state.busquedaTemas.find(tema => tema.id_tema == this.state.busquedaTemasPrograms[i].id_tema).titulo_tema,
                      editar: 0,
                      eliminar: 0
                    });
              }
							this.setState({infoTemasEdt: infoTemaPrev});
					}
          ).catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({busquedaTemasPrograms: [], infoTemasEdt: [], idProgramaEdtTema: undefined});
        }
      } else {
        // clasificar por inputs --> nuevo tema , tipo tema , editar y eliminar
        if(e.target.name == 'Tema' ){
            let idTemaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema == e.target.value).id_tema;
            if(idTemaObject != undefined){
              this.state.infoTemasEdt[e.target.id].nuevo_id_tema = idTemaObject;
            } else {
              this.state.infoTemasEdt[e.target.id].nuevo_id_tema = null;
            }
        } else if(e.target.name == 'TipoTema'){
          this.state.infoTemasEdt[e.target.id].tipo_tema = e.target.value;
        } else if(e.target.name.substring(1) == 'editarBorrar'){
          if(e.target.value == 1){
            this.state.infoTemasEdt[e.target.id].editar = 1;
            this.state.infoTemasEdt[e.target.id].eliminar = 0;
          } else if(e.target.value == 2){
            this.state.infoTemasEdt[e.target.id].editar = 0;
            this.state.infoTemasEdt[e.target.id].eliminar = 1;
          } else if(e.target.value == 3){
            this.state.infoTemasEdt[e.target.id].editar = 0;
            this.state.infoTemasEdt[e.target.id].eliminar = 0;
          }
        } else if(e.target.name.substring(2) == 'editarBorrar'){
          if(e.target.value == 1){
            this.state.infoTemasEdt[e.target.id].editar = 1;
            this.state.infoTemasEdt[e.target.id].eliminar = 0;
          } else if(e.target.value == 2){
            this.state.infoTemasEdt[e.target.id].editar = 0;
            this.state.infoTemasEdt[e.target.id].eliminar = 1;
          } else if(e.target.value == 3){
            this.state.infoTemasEdt[e.target.id].editar = 0;
            this.state.infoTemasEdt[e.target.id].eliminar = 0;
          }
        }
      }
}
onChangeInput(e){
    this.setState({[e.target.name]: e.target.value});
    switch (this.props.selectedOption) {
      case 'Añadir_Programacion': {
        if((this.state.nombre_opo_añadir.length > 0 && this.state.test_pred_añadir.length > 0 && this.state.num_semana_añadir.length > 0 && this.state.numInter.length > 0 && e.target.value.length > 0)){
          this.setState({statusBarraProceso: "enviar"});
          }else{
            this.setState({statusBarraProceso: "completar"});
          }
      }break;
      case 'Borrar_Programacion': {
        if(e.target.name === 'nombre_opo_del'){
              let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre === e.target.value);
              if(oposicionObject != undefined){
                  let data = { id_oposicion: oposicionObject.id_oposicion };
                  fetch("https://oposiciones-justicia.es/api/prosemanal/consultaID", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
                  }).then(response =>  {
                      if (response.status >= 400) { throw new Error("Bad response from server");}
                    return response.json();
                  }).then(prg => this.setState({busquedaPrograms: prg}))
                .catch(error => console.log('Object fecth failed', error))
              }else{
                this.setState({busquedaPrograms: ''})
              }
        }
        if((this.state.programa_del.length > 0)){
          this.setState({statusBarraProceso: "enviar"});
          }else{
            this.setState({statusBarraProceso: "completar"});
          }
  	  }break;
      }
    }

onChangeInputDinamic(e){
        this.setState({[e.target.name]: e.target.value});
        let intervaloDinamico = [{}];
        let intervaloTemas = [[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}],[{},{},{},{},{},{},{},{}]];
        for (var i = 0; i <  e.target.value; i++) {
          intervaloDinamico[i] = {num: ''+i};
        }
        this.setState({intervalos: intervaloDinamico});
        this.setState({intervaloTemas: intervaloTemas});
        if((this.state.nombre_opo_añadir.length > 0 && this.state.test_pred_añadir.length > 0 && this.state.num_semana_añadir.length > 0 && e.target.value.length > 0)){
          this.setState({statusBarraProceso: "enviar"});
          }else{
            this.setState({statusBarraProceso: "completar"});
          }
    }

onChangeIntervalo(e){
     if(e.target.name == 'Tema'){
       this.state.intervaloTemas[e.target.id].titulo_tema = e.target.value;
       let temaObject = this.state.busquedaTemas.find(tema => tema.titulo_tema === e.target.value);
       this.state.intervaloTemas[e.target.id].id_tema = temaObject.id_tema;
     }

     if (e.target.className == 'fas fa-toggle-off'){
         this.state.intervaloTemas[e.target.id][e.target.title.substring(1,2)].togleOnOff = 0;
    } else if (e.target.className == 'fa-toggle-on fas') {
       this.state.intervaloTemas[e.target.id][e.target.title.substring(1,2)].togleOnOff = 1;
    }

    if (e.target.className == 'fas fa-toggle-off'){
        this.state.intervaloTemas[e.target.id][e.target.title.substring(1,2)].togleOnOff = 0;
   } else if (e.target.className == 'fa-toggle-on fas') {
      this.state.intervaloTemas[e.target.id][e.target.title.substring(1,2)].togleOnOff = 1;
   }

   if(e.target.name == 'ApuntesTema'){
     if(this.state.intervaloTemas[e.target.id].apuntesTema == undefined) {
       this.state.intervaloTemas[e.target.id].apuntesTema = [];
       this.state.intervaloTemas[e.target.id].apuntesTema[e.target.title] = {idApunte: e.target.value};
     } else {
       this.state.intervaloTemas[e.target.id].apuntesTema[e.target.title] =  {idApunte: e.target.value};
     }
   }

    if(e.target.name == 'Articulo_inc'){
       this.state.intervaloTemas[e.target.id][e.target.title].Articulo_inc = e.target.value;
     } else if (e.target.name == 'Articulo_fin'){
       this.state.intervaloTemas[e.target.id][e.target.title].Articulo_fin = e.target.value;
     } else if (e.target.name == 'TemaLey'){
       this.state.intervaloTemas[e.target.id][e.target.title].idLey = e.target.value;
     } else if (e.target.name == 'TipoTema'){
        this.state.intervaloTemas[e.target.id].tipo_tema = e.target.value ;
     }
    }

  handleSubmitForm(e){
        switch (e.target.id) {
          case 'añadir_programacion': {
            let { busquedaTestPreWord, busquedaTestPreCasoPractico, intervaloTemas, busquedaOpo, busquedaTestPre, busquedaMeca, nombre_opo_añadir, num_semana_añadir, test_pred_añadir, test_word_pred_añadir,  test_caso_practico_pred_añadir, meca_pred_añadir} = this.state
            if(isDefined(nombre_opo_añadir) && isDefined(num_semana_añadir)){
              let infoTemas = [];
              let infoTemasIntervalos = [[]];
              let contador1 = 0;
              for (var i = 0; i < intervaloTemas.length; i++) {
                if(intervaloTemas[i].id_tema != undefined && intervaloTemas[i].tipo_tema != undefined){
                  if(intervaloTemas[i].apuntesTema != undefined){
                    infoTemas.push({id_tema: intervaloTemas[i].id_tema, tipo_tema: intervaloTemas[i].tipo_tema, apunte_tema: intervaloTemas[i].apuntesTema});
                  } else {
                    infoTemas.push({id_tema: intervaloTemas[i].id_tema, tipo_tema: intervaloTemas[i].tipo_tema});
                  }

                  let contador2 = 0;
                  for (var j = 0; j < intervaloTemas.length; j++) {
                    if(intervaloTemas[i][j].togleOnOff != undefined && intervaloTemas[i][j].togleOnOff == '1' ){
                      if(infoTemasIntervalos[i] == undefined){
                      infoTemasIntervalos.push([[]]);
                      }
                        infoTemasIntervalos[i][contador2] = {id_ley: intervaloTemas[i][j].idLey, art_inicio: intervaloTemas[i][j].Articulo_inc, art_fin: intervaloTemas[i][j].Articulo_fin};contador2++;}}contador1++;}
              }

              let data2 = { infoTemas: infoTemas, intervalosTemas: infoTemasIntervalos }

              let comprobacionIdTemas = false;
              let comprobacionIntervaloTemas = false;
              let comprobacionApuntesTemas = false;
              for (var i = 0; i < infoTemas.length; i++) {
                infoTemas[i].id_tema != undefined ? comprobacionIdTemas = true : comprobacionIdTemas = false;
                infoTemas[i].tipo_tema != undefined ? comprobacionIdTemas = true : comprobacionIdTemas = false;
                infoTemas[i].apunte_tema != undefined ? comprobacionApuntesTemas = true : comprobacionApuntesTemas = false;
                for (var j = 0; j < infoTemasIntervalos[i]["length"]; j++) {
                  infoTemasIntervalos[i][j].id_ley != undefined ? comprobacionIntervaloTemas = true : comprobacionIntervaloTemas = false;
                  infoTemasIntervalos[i][j].art_inicio != undefined ? comprobacionIntervaloTemas = true : comprobacionIntervaloTemas = false;
                  infoTemasIntervalos[i][j].art_fin != undefined ? comprobacionIntervaloTemas = true : comprobacionIntervaloTemas = false;
                }
              }
              if(comprobacionIdTemas == false) {alert('Debe seleccionar los Temas para procesar la solicitud.')}
              if(comprobacionIntervaloTemas == false) {alert('Debe rellenar todos los intervalos de Leyes para Cada tema.')}
              if(comprobacionApuntesTemas == false) {alert('Debe seleccionar al menos 1 pdf por tema.')}

              let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === nombre_opo_añadir);
              let idTest, idMeca, idTestWord, idTestCasoPractico = 0;
              if(isDefined(test_pred_añadir)){
                  let testObject = busquedaTestPre.find(test => test.nombre_test === test_pred_añadir);
                  idTest = testObject.id_test;
									let testWordObject = busquedaTestPreWord.find(test => test.nombre_test === test_word_pred_añadir);
                  idTestWord = testWordObject.id_test;
									let testCasoPracticoObject = busquedaTestPreCasoPractico.find(test => test.nombre_cp === test_caso_practico_pred_añadir);
                  idTestCasoPractico = testCasoPracticoObject.id_caso_practico;
              }
              if(isDefined(meca_pred_añadir)){
                  let mecaObject = busquedaMeca.find(meca => meca.titulo_ejercicio === meca_pred_añadir);
                  idMeca = mecaObject.id_meca;
              }
              let data = {
                      id_oposicion: oposicionObject.id_oposicion,
                      id_test: idTest,
											id_test_word: idTestWord,
											id_caso_practico: idTestCasoPractico,
                      id_meca: idMeca,
                      num_sem: num_semana_añadir
                    }
          if(comprobacionIdTemas == true && comprobacionIntervaloTemas == true && comprobacionApuntesTemas == true){
              fetch("https://oposiciones-justicia.es/api/prosemanal/addSemana", {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
              }).then(response =>  {
                  if (response.status >= 400) { throw new Error("Bad response from server");}
                  return response.json();
              }).then(data => {
                  if(data != undefined){
                      this.setState({id_programa:  data.insertId});
                        let dataTemaInfo = {
                          id_programa: data.insertId,
                          infoTema: data2.infoTemas
                        }
                        return fetch("https://oposiciones-justicia.es/api/prosemanal/addTemaProgramacion", {
                                      method: 'POST',
                                      headers: {'Content-Type': 'application/json'},
                                      body: JSON.stringify(dataTemaInfo)
                                      });
                    }}).then(response =>  {
                          if (response.status >= 400) { throw new Error("Bad response from server");}
                          return response.json();
                      }).then(data => {
                          if(data != undefined){
                            this.setState({id_tema_prg:  data.insertId});
                            let dataIntervalosTema = {
                              id_tema_prg: data.insertId,
                              intervalo: data2.intervalosTemas
                            }
                            return fetch("https://oposiciones-justicia.es/api/prosemanal/addIntervalosTemas", {
                                          method: 'POST',
                                          headers: {'Content-Type': 'application/json'},
                                          body: JSON.stringify(dataIntervalosTema)
                                          });
                        }}).then(response =>  {
                              if (response.status >= 400) { throw new Error("Bad response from server");}
                              return response.json();
                          }).then(data => {
                              if(data != undefined){
                                let dataApuntesTema = {
                                  id_tema_prg: this.state.id_tema_prg,
                                  apuntes: data2.infoTemas
                                }
                                return fetch("https://oposiciones-justicia.es/api/prosemanal/addApuntesTemasPrg", {
                                              method: 'POST',
                                              headers: {'Content-Type': 'application/json'},
                                              body: JSON.stringify(dataApuntesTema)
                                              });
                            }}).then(response =>  {
                                  if (response.status >= 400) { throw new Error("Bad response from server");}
                                  return response.json();
                              }).then(data => {
                                  if(data != undefined){
                                    this.cleanForm();
                                  }}).catch(function(err) {console.log(err)});
            }}else{
                swal("Complete todos los datos para procesar la solicitud",'',"warning");
            }
      }break;
      case 'borrar_programacion': {
        let { programa_del, nombre_opo_del, busquedaPrograms, busquedaOpo } = this.state
        if(isDefined(programa_del) && isDefined(nombre_opo_del)){
            let programObject = busquedaPrograms.find(programa => programa.numero_semana == programa_del);
            let oposicionObject = busquedaOpo.find(oposicion => oposicion.nombre === nombre_opo_del);
            let data = { id_programa: programObject.id_programa, id_opo: oposicionObject.id_oposicion };

            fetch("https://oposiciones-justicia.es/api/prosemanal/deleteSemana", {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
            }).then(response =>  {
                if (response.status >= 400) { throw new Error("Bad response from server");}
              return response.json();
            }).then(data => {
                if(data.affectedRows > 0){
                   this.setState({statusBarraProceso: "completado", nombre_opo_del: '',programa_del: ''});
                   this.refs.ref_nombre_opo_del.refs.nombre_opo_del.value= '';
                   this.refs.ref_programa_del.refs.programa_del.value= '';
                }}).catch(function(err) {console.log(err)});
        }else{
            swal("Complete todos los datos para procesar la solicitud",'',"warning");
          }
  	    }break;
        case 'actualizar_temas_semana': {
          let { infoTemasEdt, nombreOpoEditarTema, semanaSelectEditTema } = this.state
          let editarTemas = [];
          let updateTemas = [];
          let borrarTemas = [];
          if(isDefined(nombreOpoEditarTema) && isDefined(semanaSelectEditTema)){
            for (var i = 0; i < infoTemasEdt.length; i++) {
              if(infoTemasEdt[i].eliminar == 1){
                if(infoTemasEdt[i].id_tema_prg != false){
                borrarTemas.push({
                  id_tema_prg: infoTemasEdt[i].id_tema_prg
                })
                }
              } else if(infoTemasEdt[i].editar == 1){
                  if(infoTemasEdt[i].id_tema == null){
                    editarTemas.push({
                      id_programa: this.state.semanaSelectEditTema,
                      id_tema: infoTemasEdt[i].nuevo_id_tema,
                      tipo_tema: infoTemasEdt[i].tipo_tema
                    })
                  } else {
                    updateTemas.push({
                      id_tema_prg: infoTemasEdt[i].id_tema_prg,
                      tipo_tema: infoTemasEdt[i].tipo_tema
                    })
                  }
              }
            }
						if(editarTemas.length > 0){
            	let data = { infoTema: editarTemas, id_programa: this.state.semanaSelectEditTema }
                fetch("https://oposiciones-justicia.es/api/prosemanal/addTemaProgramacion", {
                  method: 'POST',
                  headers: {'Content-Type': 'application/json'},
                  body: JSON.stringify(data)
                	}).then(response =>  {
                    if (response.status >= 400) { throw new Error("Bad response from server");}
                  return response.json();
                	}).then(data => {
                    if(data.affectedRows > 0){
                       this.setState({statusBarraProceso: "completado", nombreOpoEditarTema: '', semanaSelectEditTema: '', infoTemasEdt: []});
											 this.refs.refNombreOpoEditarTema.refs.nombreOpoEditarTema.value= '';
											 this.refs.refSemanaSelectEditTema.refs.semanaSelectEditTema.value= '';
                	}}).catch(function(err) {console.log(err)});
							}

							if(borrarTemas.length > 0){
	                	let data2 = { borrarTemas: borrarTemas }
	                    fetch("https://oposiciones-justicia.es/api/prosemanal/editarBorrarTemasProgramacion", {
	                      method: 'POST',
	                      headers: {'Content-Type': 'application/json'},
	                      body: JSON.stringify(data2)
	                    	}).then(response =>  {
	                        if (response.status >= 400) { throw new Error("Bad response from server");}
	                      return response.json();
	                    	}).then(data => {
	                        if(data.affectedRows > 0){
	                           this.setState({statusBarraProceso: "completado", nombreOpoEditarTema: '', semanaSelectEditTema: '', infoTemasEdt: []});
	                           this.refs.refNombreOpoEditarTema.refs.nombreOpoEditarTema.value= '';
	                           this.refs.refSemanaSelectEditTema.refs.semanaSelectEditTema.value= '';
	                    	}}).catch(function(err) {console.log(err)});
									}

									if(updateTemas.length > 0){
			                	let data3 = { updateTemas: updateTemas }
			                    fetch("https://oposiciones-justicia.es/api/prosemanal/editarUpdateTemasProgramacion", {
			                      method: 'POST',
			                      headers: {'Content-Type': 'application/json'},
			                      body: JSON.stringify(data3)
			                    	}).then(response =>  {
			                        if (response.status >= 400) { throw new Error("Bad response from server");}
			                      return response.json();
			                    	}).then(data => {
			                        if(data.affectedRows > 0){
			                           this.setState({statusBarraProceso: "completado", nombreOpoEditarTema: '', semanaSelectEditTema: '', infoTemasEdt: []});
																 this.refs.refNombreOpoEditarTema.refs.nombreOpoEditarTema.value= '';
			                           this.refs.refSemanaSelectEditTema.refs.semanaSelectEditTema.value= '';
			                    	}}).catch(function(err) {console.log(err)});
											}
											this.forceUpdate();
          }else{
              alert("Complete todos los datos para procesar la solicitud.");
            }
    	    }break;
					case 'actualizar_tema_pdf_semana': {
	          let { infoTemaPdfEdt, busquedaTemas,  nombreOpoEditarTemaPdf, semanaSelectEditTemaPdf, temaSelectEditTemaPdf } = this.state
						let nuevosApuntes  = [], borrarApuntes  = [];

	          if(isDefined(nombreOpoEditarTemaPdf) && isDefined(semanaSelectEditTemaPdf) && isDefined(temaSelectEditTemaPdf) && isDefined(infoTemaPdfEdt)){
	            for (var i = 0; i < infoTemaPdfEdt.length; i++) {
	              if(infoTemaPdfEdt[i].eliminar == 1){
	                if(infoTemaPdfEdt[i].id_apunte != undefined){
	                borrarApuntes.push({
	                  id_apunte_tema_prg: infoTemaPdfEdt[i].id_apunte_tema_prg
	                })
	                }
	              } else if(infoTemaPdfEdt[i].editar == 1){
	                  if(infoTemaPdfEdt[i].id_apunte == undefined){
	                    nuevosApuntes.push({
												id_tema_prg: infoTemaPdfEdt[i].id_tema_prg,
								 				id_apunte: infoTemaPdfEdt[i].nuevo_id_apunte
	                    })
	                  }
	              }
	            }
							if(nuevosApuntes.length > 0){
	            	let data = { nuevosApuntes: nuevosApuntes }
	                fetch("https://oposiciones-justicia.es/api/prosemanal/edtAddApuntesTemasPrg", {
	                  method: 'POST',
	                  headers: {'Content-Type': 'application/json'},
	                  body: JSON.stringify(data)
	                	}).then(response =>  {
	                    if (response.status >= 400) { throw new Error("Bad response from server");}
	                  return response.json();
	                	}).then(data => {
	                    if(data.affectedRows > 0){
	                       this.cleanForm();
	                	}}).catch(function(err) {console.log(err)});
								}

								if(borrarApuntes.length > 0){
		                	let data2 = { borrarApuntes: borrarApuntes }
		                    fetch("https://oposiciones-justicia.es/api/prosemanal/edtDeleteApuntesTemasPrg", {
		                      method: 'POST',
		                      headers: {'Content-Type': 'application/json'},
		                      body: JSON.stringify(data2)
		                    	}).then(response =>  {
		                        if (response.status >= 400) { throw new Error("Bad response from server");}
		                      return response.json();
		                    	}).then(data => {
		                        if(data.affectedRows > 0){
		                           this.cleanForm();
		                    	}}).catch(function(err) {console.log(err)});
										}
												this.forceUpdate();
												this.cleanForm();
	          }else{
	              alert("Complete todos los datos para procesar la solicitud.");
	            }
	    	    }break;
						case 'actualizar_tema_ley_semana': {
		          let { infoTemaLeyEdt, busquedaTemas,  nombreOpoEditarLey, semanaSelectEditLey, temaSelectEditLey } = this.state
							let nuevasLeyes  = [], updateLeyes  = [], borrarLeyes  = [];

		          if(isDefined(nombreOpoEditarLey) && isDefined(semanaSelectEditLey) && isDefined(temaSelectEditLey) && isDefined(infoTemaLeyEdt)){
		            for (var i = 0; i < infoTemaLeyEdt.length; i++) {
		              if(infoTemaLeyEdt[i].eliminar == 1){
		                if(infoTemaLeyEdt[i].id_ley != undefined){
		                borrarLeyes.push({
		                  id_intervalo_art: infoTemaLeyEdt[i].id_intervalo_art
		                })
		                }
		              } else if(infoTemaLeyEdt[i].editar == 1){
		                  if(infoTemaLeyEdt[i].id_ley == undefined){
		                    nuevasLeyes.push({
													id_tema_prg: infoTemaLeyEdt[i].id_tema_prg,
									 				id_ley: infoTemaLeyEdt[i].nuevo_id_ley,
									 				art_ini: infoTemaLeyEdt[i].art_ini,
									 				art_fin: infoTemaLeyEdt[i].art_fin
		                    })
		                  } else {
		                    updateLeyes.push({
													id_intervalo_art: infoTemaLeyEdt[i].id_intervalo_art,
													id_tema_prg: infoTemaLeyEdt[i].id_tema_prg,
	 								 				id_ley: infoTemaLeyEdt[i].id_ley,
	 								 				art_ini: infoTemaLeyEdt[i].art_ini,
	 								 				art_fin: infoTemaLeyEdt[i].art_fin
		                    })
		                  }
		              }
		            }
								if(nuevasLeyes.length > 0){
		            	let data = { nuevasLeyes: nuevasLeyes }
		                fetch("https://oposiciones-justicia.es/api/prosemanal/edtIntervalosTemasNuevos", {
		                  method: 'POST',
		                  headers: {'Content-Type': 'application/json'},
		                  body: JSON.stringify(data)
		                	}).then(response =>  {
		                    if (response.status >= 400) { throw new Error("Bad response from server");}
		                  return response.json();
		                	}).then(data => {
		                    if(data.affectedRows > 0){
		                       this.cleanForm();
		                	}}).catch(function(err) {console.log(err)});
									}

									if(borrarLeyes.length > 0){
			                	let data2 = { borrarLeyes: borrarLeyes }
			                    fetch("https://oposiciones-justicia.es/api/prosemanal/edtIntervalosTemasDelete", {
			                      method: 'POST',
			                      headers: {'Content-Type': 'application/json'},
			                      body: JSON.stringify(data2)
			                    	}).then(response =>  {
			                        if (response.status >= 400) { throw new Error("Bad response from server");}
			                      return response.json();
			                    	}).then(data => {
			                        if(data.affectedRows > 0){
			                           this.cleanForm();
			                    	}}).catch(function(err) {console.log(err)});
											}

											if(updateLeyes.length > 0){
					                	let data3 = { updateLeyes: updateLeyes }
					                    fetch("https://oposiciones-justicia.es/api/prosemanal/edtIntervalosTemasUpdate", {
					                      method: 'POST',
					                      headers: {'Content-Type': 'application/json'},
					                      body: JSON.stringify(data3)
					                    	}).then(response =>  {
					                        if (response.status >= 400) { throw new Error("Bad response from server");}
					                      return response.json();
					                    	}).then(data => {
					                        if(data.affectedRows > 0){
					                           this.cleanForm();
					                    	}}).catch(function(err) {console.log(err)});
													}
													this.forceUpdate();
													this.cleanForm();
		          }else{
		              alert("Complete todos los datos para procesar la solicitud.");
		            }
		    	    }break;
      }
    }

componentDidMount(){
  fetch('https://oposiciones-justicia.es/api/prosemanal/consultaTestPred')
    .then(res => res.json())
      .then(test => this.setState({
          busquedaTestPre: test
      }))
    .catch(error => console.log('Object fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
        .then(oposiciones => this.setState({
            busquedaOpo: oposiciones
        }))
      .catch(error => console.log('Object fecth failed', error))
			fetch('https://oposiciones-justicia.es/api/temas/consultaTodosApuntes')
	      .then(res => res.json())
	        .then(apuntes => this.setState({
	            busquedaApuntesPrograms: apuntes
	        }))
	      .catch(error => console.log('Object fecth failed', error))

      fetch('https://oposiciones-justicia.es/api/temas/consulta')
        .then(res => res.json())
          .then(temas => this.setState({
              busquedaTemas: temas
          }))
        .catch(error => console.log('Object fecth failed', error))

        fetch('https://oposiciones-justicia.es/api/prosemanal/consultaMecaPred')
          .then(res => res.json())
            .then(meca => this.setState({
                busquedaMeca: meca
            }))
          .catch(error => console.log('Object fecth failed', error))

					fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreWord')
	          .then(res => res.json())
	            .then(info => this.setState({
	                busquedaTestPreWord: info
	            }))
	          .catch(error => console.log('Object fecth failed', error))

						fetch('https://oposiciones-justicia.es/api/prosemanal/busquedaTestPreCasoPractico')
		          .then(res => res.json())
		            .then(info => this.setState({
		                busquedaTestPreCasoPractico: info
		            }))
		          .catch(error => console.log('Object fecth failed', error))

          fetch('https://oposiciones-justicia.es/api/leyes/consulta')
            .then(res => res.json())
              .then(leyes => this.setState({
                  busquedaLeyes: leyes
              }))
            .catch(error => console.log('Obejet fecth failed', error))
};
  render() {
    const { selectedOption } = this.props;
    const { busquedaTestPreWord, busquedaTestPreCasoPractico, busquedaTemasApuntes, infoTemaPdfEdt, infoTemasPdfEdt, infoTemasEdt, infoTemaLeyEdt, infoTemasLeyEdt, busquedaProgramsTemas, temasProgramacion, busquedaMeca, busquedaTestPre, busquedaOpo, busquedaTemas, busquedaPrograms, intervalos, numInter, statusBarraProceso } = this.state;

      switch (selectedOption) {
        case 'Añadir_Programacion': {
              return (
                <form className="formPrgSemanal">
                <BarraProceso estadoProceso={statusBarraProceso}/>
                <Input type="text" name="nombre_opo_añadir" placeholder="Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_nombre_opo_añadir" onChange={this.onChangeInput} />
                <Input type="text" name="test_pred_añadir" placeholder="Test Justicia" icon="fas fa-search" datalist="busquedaTestPre" ref="ref_test_pred_añadir" onChange={this.onChangeInput} />
								<Input type="text" name="test_word_pred_añadir" placeholder="Test Word" icon="fas fa-search" datalist="busquedaTestPreWord" ref="ref_test_word_pred_añadir" onChange={this.onChangeInput} />
								<Input type="text" name="test_caso_practico_pred_añadir" placeholder="Caso Practico" icon="fas fa-search" datalist="busquedaTestPreCasoPractico" ref="ref_test_caso_practico_pred_añadir" onChange={this.onChangeInput} />
                <Input type="text" name="meca_pred_añadir" placeholder="Ejer Mecanografía" icon="fas fa-search" datalist="busquedaMeca" ref="ref_meca_pred_añadir" onChange={this.onChangeInput} />
                <Input type="number" name="num_semana_añadir" placeholder="Numero Semana" icon="fas fa-sort-numeric-up" ref="ref_num_semana_añadir" onChange={this.onChangeInput}/>
                <div className=""> Nº de Temas </div>
                <Input type="number" name="numInter" placeholder="1,2,3..." icon="fas fa-chevron-circle-right" ref="ref_numInter" onChange={this.onChangeInputDinamic} min={0} max={25}/>
                <div className="intervaloTemasFlex">{ intervalos && intervalos.map((intervalo, key) =>
                    <InputTemas key={key} onChange={this.onChangeIntervalo} num={intervalo.num} numIntervalos={numInter}/>
                  )}</div>
                   <Submit id="añadir_programacion" icon1="fas fa-upload" icon2="fas fa-database" texto="Agregar Semana" onClick={this.handleSubmitForm}/>

                   <datalist id="busquedaOpo">{
                     busquedaOpo && busquedaOpo.map((oposicion, key) =>
                     <option key={key} value={oposicion.nombre}></option>
                       )
                   }</datalist>
                   <datalist id="busquedaTestPre">{
                     busquedaTestPre && busquedaTestPre.map((test, key) =>
                     <option key={key} value={test.nombre_test}></option>
                       )
                   }</datalist>
									 <datalist id="busquedaTestPreWord">{
                     busquedaTestPreWord && busquedaTestPreWord.map((test, key) =>
                     <option key={key} value={test.nombre_test}></option>
                       )
                   }</datalist>
									 <datalist id="busquedaTestPreCasoPractico">{
                     busquedaTestPreCasoPractico && busquedaTestPreCasoPractico.map((test, key) =>
                     <option key={key} value={test.nombre_cp}></option>
                       )
                   }</datalist>
                   <datalist id="busquedaMeca">{
                     busquedaMeca && busquedaMeca.map((meca, key) =>
                     <option key={key} value={meca.titulo_ejercicio}></option>
                       )
                   }</datalist>

                </form>
              );
        }
        break;
        case 'Editar_Tema_Programacion': {
              return (
                <form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
                  <Input type="text" name="nombreOpoEditarTema" placeholder="Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="refNombreOpoEditarTema" onChange={this.onChangeEdtTema} />
                  <Input type="text" name="semanaSelectEditTema" placeholder="Dia Semana" icon="fas fa-search" datalist="busquedaPrograms" ref="refSemanaSelectEditTema" onChange={this.onChangeEdtTema} />
									<BotonMedio id="addTema" name="addTema" icon1="fas fa-plus" icon2="" texto="Añadir Tema" onClick={this.handleAddTemaEdt}/>
                  <InputTemasEdt onChange={this.onChangeEdtTema} infoTemas={infoTemasEdt}/>
                  <Submit id="actualizar_temas_semana" icon1="fas fa-edit" icon2="fas fa-database" texto="Editar Temas" onClick={this.handleSubmitForm}/>

                     <datalist id="busquedaPrograms">{ busquedaPrograms && busquedaPrograms.map((prg, key) =>
                       <option key={key} value={prg.numero_semana}></option>
                     )}</datalist>
                     <datalist id="busquedaOpo">{
                       busquedaOpo && busquedaOpo.map((oposicion, key) =>
                       <option key={key} value={oposicion.nombre}></option>
                         )
                     }</datalist>
                  </form>
              );
        }
        break;
        case 'Editar_Leyes_Tema_Programacion': {
              return (
								<form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
                  <Input type="text" name="nombreOpoEditarLey" placeholder="Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_nombreOpoEditarLey" onChange={this.onChangeEdtTemaLey} />
                  <Input type="text" name="semanaSelectEditLey" placeholder="Dia Semana" icon="fas fa-search" datalist="busquedaPrograms" ref="ref_semanaSelectEditLey" onChange={this.onChangeEdtTemaLey} />
									<Input type="text" name="temaSelectEditLey" placeholder="Seleccione Tema" icon="fas fa-search" datalist="busquedaProgramsTemas" ref="ref_temaSelectEditLey" onChange={this.onChangeEdtTemaLey} />
									<BotonMedio id="addTema" name="addTema" icon1="fas fa-plus" icon2="" texto="Añadir Intervalo" onClick={this.handleAddTemaLeyesEdt}/>
									<InputTemaLeyesEdt onChange={this.onChangeEdtTemaLey} infoLeyes={infoTemaLeyEdt} />
                  <Submit id="actualizar_tema_ley_semana" icon1="fas fa-edit" icon2="fas fa-database" texto="Editar Leyes" onClick={this.handleSubmitForm}/>

										<datalist id="busquedaProgramsTemas">{ infoTemasLeyEdt && infoTemasLeyEdt.map((tema, key) =>
											<option key={key} value={tema.tituloTema}>{tema.id_tema}</option>
										)}</datalist>
                     <datalist id="busquedaPrograms">{ busquedaPrograms && busquedaPrograms.map((prg, key) =>
                       <option key={key} value={prg.numero_semana}></option>
                     )}</datalist>
                     <datalist id="busquedaOpo">{
                       busquedaOpo && busquedaOpo.map((oposicion, key) =>
                       <option key={key} value={oposicion.nombre}></option>
                         )
                     }</datalist>
                  </form>
              );
        }
        break;
        case 'Editar_Pdf_Tema_Programacion': {
              return (
								<form className="form">
                  <BarraProceso estadoProceso={statusBarraProceso}/>
                  <Input type="text" name="nombreOpoEditarTemaPdf" placeholder="Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_nombreOpoEditarTemaPdf" onChange={this.onChangeEdtTemaPdf} />

                  <Input type="text" name="semanaSelectEditTemaPdf" placeholder="Dia Semana" icon="fas fa-search" datalist="busquedaPrograms" ref="ref_semanaSelectEditTemaPdf" onChange={this.onChangeEdtTemaPdf} />

									<Input type="text" name="temaSelectEditTemaPdf" placeholder="Seleccione Tema" icon="fas fa-search" datalist="busquedaProgramsTemas" ref="ref_temaSelectEditTemaPdf" onChange={this.onChangeEdtTemaPdf} />

									<BotonMedio id="addPdf" name="addPdf" icon1="fas fa-plus" icon2="" texto="Añadir PDF" onClick={this.handleAddPdfEdt}/>

									<InputTemaPdfEdt onChange={this.onChangeEdtTemaPdf} infoPdf={infoTemaPdfEdt} busquedaTemasApuntes={busquedaTemasApuntes}/>

                  <Submit id="actualizar_tema_pdf_semana" icon1="fas fa-edit" icon2="fas fa-database" texto="Editar Pdfs" onClick={this.handleSubmitForm}/>

										<datalist id="busquedaProgramsTemas">{ infoTemasPdfEdt && infoTemasPdfEdt.map((tema, key) =>
											<option key={key} value={tema.tituloTema}>{tema.id_tema}</option>
										)}</datalist>
                     <datalist id="busquedaPrograms">{ busquedaPrograms && busquedaPrograms.map((prg, key) =>
                       <option key={key} value={prg.numero_semana}></option>
                     )}</datalist>
                     <datalist id="busquedaOpo">{
                       busquedaOpo && busquedaOpo.map((oposicion, key) =>
                       <option key={key} value={oposicion.nombre}></option>
                         )
                     }</datalist>
                  </form>
              );
        }
        break;
        case 'Borrar_Programacion': {
              return (
                <form className="form">
                <BarraProceso estadoProceso={statusBarraProceso}/>
                <Input type="text" name="nombre_opo_del" placeholder="Oposición" icon="fas fa-search" datalist="busquedaOpo" ref="ref_nombre_opo_del" onChange={this.onChangeInput} />
                <Input type="text" name="programa_del" placeholder="Nº Semana" icon="fas fa-search" datalist="busquedaPrograms" ref="ref_programa_del" onChange={this.onChangeInput} />
                <Submit id="borrar_programacion" icon1="fas fa-trash-alt" icon2="fas fa-database" texto="Eliminar Semana" onClick={this.handleSubmitForm}/>

                   <datalist id="busquedaPrograms">{ busquedaPrograms && busquedaPrograms.map((prg, key) =>
                     <option key={key} value={prg.numero_semana}></option>
                   )}</datalist>
                   <datalist id="busquedaOpo">{
                     busquedaOpo && busquedaOpo.map((oposicion, key) =>
                     <option key={key} value={oposicion.nombre}></option>
                       )
                   }</datalist>
                  </form>
              );
        }
        break;
        default: {
          return (
            <div style={{fontSize: '24px'}} >
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
              Selecciona una opción para continuar.
              <i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
            </div>
          );
        }
      }
  }
}
export default optionBar;

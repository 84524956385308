// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';
import PreguntaInfoEdt from '../preguntas/PreguntaInfoEdt';
import PreguntaInfoEdtSelected from '../preguntas/PreguntaInfoEdtSelected';

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { genPregToDelete, addPregunta, delPregunta} from './actions/testCasoPracticoAction';

class BusquedaEditarPreguntas extends Component {

	constructor(props){
		super(props);
    	this.state = {
				displayPregToSelect: 'none',
				displayPregtoDel: 'none',
				preguntasBusqueda: undefined,
				toogleInfoPregunta: 'none'
			};
			this.onChange = this.onChange.bind(this);
			this.handleOnClick = this.handleOnClick.bind(this);
			this.cleanForm = this.cleanForm.bind(this);
	}
	cleanForm(){
		this.setState({
			preguntaTexto: undefined,
			articuloPregBusqueda: undefined,
			articuloPregBusquedaLey: undefined,
			idPreguntaDel: undefined
		})
		this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
		this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
		this.refs.ref_articuloPregBusquedaLey.refs.articuloPregBusquedaLey.value = ''
		this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
	}
	onChange(e){

		if(e.target.id == 'idPreguntaDel'){
			if(this.refs.ref_preguntaTexto.refs.preguntaTexto.value != ''
				|| this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value != ''
				|| this.state.articuloPregBusqueda != undefined || this.state.preguntaTexto != undefined){
					this.setState({
					preguntaTexto: undefined,
					articuloPregBusqueda: undefined
				})
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
				this.refs.ref_articuloPregBusquedaLey.refs.articuloPregBusquedaLey.value != ''
			}
			this.setState({ idPreguntaDel: e.target.value })

			// fetch idPreguntaDel --> idPregJusExiste
			if(e.target.value >= 0 && e.target.value != ''){
				let data = { idPreg: e.target.value}
				fetch("https://oposiciones-justicia.es/api/informatica/idPregCPExiste", {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(data)
				}).then(response =>  {
					if (response.status >= 400) { throw new Error("Bad response from server");}
					return response.json();
				}).then(cls => {
					if (cls.length != 0 || cls != undefined){
						let newPregBusqueda = []
						for (var i = 0; i < cls.length/4; i++) {
							newPregBusqueda.push({
								numPreg: parseInt(parseInt(i)+1),
								editar: 1,
								insertar: 0,
								borrar: 0,
								preguntaCheck: 0,
								tipoPregunta: cls[i*4].tipoPregunta,
								ley: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								selected: 0,
								idPregunta: cls[i*4].idPregunta,
								idRespuesta1: cls[(i*4)+0].idRespuesta,
								idRespuesta2: cls[(i*4)+1].idRespuesta,
								idRespuesta3: cls[(i*4)+2].idRespuesta,
								idRespuesta4: cls[(i*4)+3].idRespuesta,
								preguntaTexto: cls[i*4].pregunta,
								pregunta: cls[i*4].pregunta,
								articulo: cls[i*4].articulo,
								idLey: cls[i*4].idLey,
								siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								r1: cls[(i*4)+0].respuesta,
								r1_validez: cls[(i*4)+0].validez,
								notaA: cls[(i*4)+0].nota,
								subrayadoA: cls[(i*4)+0].subrayado,
								r2: cls[(i*4)+1].respuesta,
								r2_validez: cls[(i*4)+1].validez,
								notaB: cls[(i*4)+1].nota,
								subrayadoB: cls[(i*4)+1].subrayado,
								r3: cls[(i*4)+2].respuesta,
								r3_validez: cls[(i*4)+2].validez,
								notaC: cls[(i*4)+2].nota,
								subrayadoC: cls[(i*4)+2].subrayado,
								r4: cls[(i*4)+3].respuesta,
								r4_validez: cls[(i*4)+3].validez,
								notaD: cls[(i*4)+3].nota,
								subrayadoD: cls[(i*4)+3].subrayado
							})
						}
						if(cls.length > 0) {
							this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
						}
					} else {
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
					}
				}).catch(error => console.log('Object fecth failed', error))
			} else if (e.target.value == ''){
				this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
			}

		} else if(e.target.id == 'preguntaTexto'){

			if(this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value != '' ||
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value != ''
				|| this.state.articuloPregBusqueda != undefined || this.state.idPreguntaDel != undefined){
				this.setState({
					idPreguntaDel: undefined,
					articuloPregBusqueda: undefined
				})
				this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
				this.refs.ref_articuloPregBusqueda.refs.articuloPregBusqueda.value = ''
			}
			this.setState({ preguntaTexto: e.target.value })

			// fetch preguntaTexto --> preguntaTextoJusExiste
			if(e.target.value.length > 0 && e.target.value != ''){
				let data = { textPreg: e.target.value}
				fetch("https://oposiciones-justicia.es/api/informatica/preguntaTextoCPExiste", {
					method: 'POST',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify(data)
				}).then(response =>  {
					if (response.status >= 400) { throw new Error("Bad response from server");}
					return response.json();
				}).then(cls => {
					if (cls.length != 0 || cls != undefined){
						let newPregBusqueda = []
						for (var i = 0; i < cls.length/4; i++) {
							newPregBusqueda.push({
								numPreg: parseInt(parseInt(i)+1),
								editar: 1,
								insertar: 0,
								borrar: 0,
								preguntaCheck: 0,
								tipoPregunta: cls[i*4].tipoPregunta,
								ley: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								selected: 0,
								idPregunta: cls[i*4].idPregunta,
								idRespuesta1: cls[(i*4)+0].idRespuesta,
								idRespuesta2: cls[(i*4)+1].idRespuesta,
								idRespuesta3: cls[(i*4)+2].idRespuesta,
								idRespuesta4: cls[(i*4)+3].idRespuesta,
								preguntaTexto: cls[i*4].pregunta,
								pregunta: cls[i*4].pregunta,
								articulo: cls[i*4].articulo,
								idLey: cls[i*4].idLey,
								siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
								r1: cls[(i*4)+0].respuesta,
								r1_validez: cls[(i*4)+0].validez,
								notaA: cls[(i*4)+0].nota,
								subrayadoA: cls[(i*4)+0].subrayado,
								r2: cls[(i*4)+1].respuesta,
								r2_validez: cls[(i*4)+1].validez,
								notaB: cls[(i*4)+1].nota,
								subrayadoB: cls[(i*4)+1].subrayado,
								r3: cls[(i*4)+2].respuesta,
								r3_validez: cls[(i*4)+2].validez,
								notaC: cls[(i*4)+2].nota,
								subrayadoC: cls[(i*4)+2].subrayado,
								r4: cls[(i*4)+3].respuesta,
								r4_validez: cls[(i*4)+3].validez,
								notaD: cls[(i*4)+3].nota,
								subrayadoD: cls[(i*4)+3].subrayado
							})
						}
						if(cls.length > 0) {
							this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
						}
					} else {
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
					}
				}).catch(error => console.log('Object fecth failed', error))
			} else if (e.target.value == ''){
				this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
			}


		} else if(e.target.id == 'articuloPregBusqueda'){
			if(this.refs.ref_preguntaTexto.refs.preguntaTexto.value != '' ||
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value != ''
				|| this.state.preguntaTexto != undefined || this.state.idPreguntaDel != undefined){
				this.setState({
					preguntaTexto: undefined,
					idPreguntaDel: undefined
				})
				this.refs.ref_idPreguntaDel.refs.idPreguntaDel.value = ''
				this.refs.ref_preguntaTexto.refs.preguntaTexto.value = ''
			}
			this.setState({ articuloPregBusqueda: e.target.value })
			let objectLey = this.state.busquedaLeyes.find(l=> l.siglas_ley == this.state.articuloPregBusquedaLey)
			if(this.state.articuloPregBusquedaLey != undefined && this.state.articuloPregBusquedaLey != '' && objectLey != undefined){


				// fetch articuloPregBusqueda --> artPrgJusExiste
				if(e.target.value.length > 0 && e.target.value != ''){

					let data = { articuloPregBusqueda: e.target.value, idLey: objectLey.id_ley}
					console.log(data)
					fetch("https://oposiciones-justicia.es/api/informatica/artPrgCPExiste", {
						method: 'POST',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify(data)
					}).then(response =>  {
						if (response.status >= 400) { throw new Error("Bad response from server");}
						return response.json();
					}).then(cls => {
						if (cls.length != 0 || cls != undefined){
							let newPregBusqueda = []
							for (var i = 0; i < cls.length/4; i++) {
								newPregBusqueda.push({
									numPreg: parseInt(parseInt(i)+1),
									editar: 1,
									insertar: 0,
									borrar: 0,
									preguntaCheck: 0,
									tipoPregunta: cls[i*4].tipoPregunta,
									ley: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
									pregunta: cls[i*4].pregunta,
									selected: 0,
									idPregunta: cls[i*4].idPregunta,
									idRespuesta1: cls[(i*4)+0].idRespuesta,
									idRespuesta2: cls[(i*4)+1].idRespuesta,
									idRespuesta3: cls[(i*4)+2].idRespuesta,
									idRespuesta4: cls[(i*4)+3].idRespuesta,
									preguntaTexto: cls[i*4].pregunta,
									articulo: cls[i*4].articulo,
									idLey: cls[i*4].idLey,
									siglasLey: this.state.busquedaLeyes.find(l=> l.id_ley == cls[i*4].idLey).siglas_ley,
									r1: cls[(i*4)+0].respuesta,
									r1_validez: cls[(i*4)+0].validez,
									notaA: cls[(i*4)+0].nota,
									subrayadoA: cls[(i*4)+0].subrayado,
									r2: cls[(i*4)+1].respuesta,
									r2_validez: cls[(i*4)+1].validez,
									notaB: cls[(i*4)+1].nota,
									subrayadoB: cls[(i*4)+1].subrayado,
									r3: cls[(i*4)+2].respuesta,
									r3_validez: cls[(i*4)+2].validez,
									notaC: cls[(i*4)+2].nota,
									subrayadoC: cls[(i*4)+2].subrayado,
									r4: cls[(i*4)+3].respuesta,
									r4_validez: cls[(i*4)+3].validez,
									notaD: cls[(i*4)+3].nota,
									subrayadoD: cls[(i*4)+3].subrayado
								})
							}
							if(cls.length > 0) {
								this.setState({displayPregToSelect: 'flex', preguntasBusqueda: newPregBusqueda});
							}
						} else {
						this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
						}
					}).catch(error => console.log('Object fecth failed', error))
				} else if (e.target.value == ''){
					this.setState({displayPregToSelect: 'none', preguntasBusqueda: undefined});
				}
		}
	} else if (e.target.name == "articuloPregBusquedaLey"){

		this.setState({articuloPregBusquedaLey: e.target.value})
	}

	}
	handleOnClick(e){
		if (e.target.id == "loadEdtPregunta") {
			// cambiar direccione para editar si tenemos preguntas seleccionadas
			if(this.props.pregToEdtCP.length > 0){
				this.props.onClick(e);
			} else {
				swal('Debe seleccionar alguna pregunta','','warning')
			}

		} else if(e.target.id.split('-')[0] == 'seleccionarPreguntaEdt'){

			let posicionPreg = e.target.id.split('-')[1]
			let pregSelect = this.state.preguntasBusqueda[posicionPreg]
			if(this.props.pregToEdtCP.length > 0){
				// añadir
				this.props.addPregunta(pregSelect, this.props.pregToEdtCP.length + 1)
				let pregBusNew = this.state.preguntasBusqueda
				pregBusNew[posicionPreg].selected = 1;
				this.setState({ preguntasBusqueda: pregBusNew })
			} else {
				// generar
				this.props.genPregToDelete(pregSelect, 1)
				let pregBusNew = this.state.preguntasBusqueda
				pregBusNew[posicionPreg].selected = 1;
				this.setState({ preguntasBusqueda: pregBusNew, displayPregtoDel: 'flex' })
			}
		} else if (e.target.id.split('-')[0] == 'deseleccionarPreguntaEdt'){
			let posicionPreg = e.target.id.split('-')[1]
			let idPrgToDel = this.props.pregToEdtCP[posicionPreg].idPregunta


			if(this.props.pregToEdtCP.length == 1){
				this.props.delPregunta(idPrgToDel)
				this.setState({ displayPregtoDel: 'none'})
			} else {
				this.props.delPregunta(idPrgToDel)
			}

			if(this.state.preguntasBusqueda != undefined){
				if( this.state.preguntasBusqueda.filter( p => p.idPregunta == idPrgToDel).length > 0) {
					let pregBusNew = this.state.preguntasBusqueda
					pregBusNew[posicionPreg].selected = 0;
					this.setState({ preguntasBusqueda: pregBusNew })
				}
			}

		}

	}

	componentDidMount(){

		this.props.genPregToDelete(undefined)

		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));

		fetch('https://oposiciones-justicia.es/api/leyes/consulta')
			.then(res => res.json())
				.then(leyes => this.setState({
						busquedaLeyes: leyes
				}))
			.catch(error => console.log('Object fecth failed', error));

	}
	render() {
		const { pregToEdtCP } = this.props;
		const { displayPregToSelect, displayPregtoDel, toogleInfoPregunta, preguntasBusqueda, busquedaLeyes } = this.state

		return (
			<div className= "bodyLoadTest">

				<div className="opocionesLoadTest">

					<div className="inputLoadTest">

						<h4>
							<i className="fas fa-info-circle"></i>
							Identificador
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" placeholder="Id - Pregunta" id="idPreguntaDel" name="idPreguntaDel" ref="ref_idPreguntaDel" icon="fas fa-search" onChange={this.onChange}/>

					</div>

					<div className="inputLoadTest">
						<h4 style={{marginBottom: '5px'}}>
							<i className="fas fa-info-circle"></i>
							Texto
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>

						<InputTextArea id='preguntaTexto' name="preguntaTexto" ref="ref_preguntaTexto" valueDefault="¡Buscar texto de pregunta!" width="265" height="35" marginTop="2" onChange={this.onChange}/>
					</div>

					<div className="inputLoadTest">
						<h4>
							<i className="fas fa-info-circle"></i>
							Ley
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" placeholder="Ley" datalist="leyesDataList" id="articuloPregBusquedaLey" name="articuloPregBusquedaLey" ref="ref_articuloPregBusquedaLey" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="leyesDataList">
							{ busquedaLeyes && busquedaLeyes.map((cls, key) =>
								<option key={key} value={cls.siglas_ley}>{cls.nombre_ley}</option>
							)}
						</datalist>
						<h4>
							<i className="fas fa-info-circle"></i>
							Articulo
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" placeholder="Artículo" id="articuloPregBusqueda" name="articuloPregBusqueda" ref="ref_articuloPregBusqueda" icon="fas fa-search" onChange={this.onChange}/>

					</div>

				</div>

				<h44 style={{display: displayPregToSelect}}>
					<i className="fas fa-info-circle"></i>
					Preguntas encontradas
					<i className="fas fa-arrow-circle-down"></i>
				</h44>
				<div className="inputLoadTest" style={{display: displayPregToSelect}}>
				{ preguntasBusqueda && preguntasBusqueda.length > 0 && preguntasBusqueda.map((cls, key) =>
					<PreguntaInfoEdt id={key} pregunta={preguntasBusqueda[key]} preguntasToEdt={pregToEdtCP} onClick={this.handleOnClick}/>
				)}
				</div>

				<h44 style={{display: displayPregtoDel}}>
					<i className="fas fa-info-circle"></i>
					Preguntas seleccionadas para editar
					<i className="fas fa-arrow-circle-down"></i>
				</h44>
				<div className="inputLoadTest" style={{display: displayPregtoDel}}>
				{ pregToEdtCP && pregToEdtCP.length > 0 && pregToEdtCP.map((cls, key) =>
					<PreguntaInfoEdtSelected id={key} pregunta={pregToEdtCP[key]} onClick={this.handleOnClick}/>
				)}
				</div>
				<div>
				<Submit id="loadEdtPregunta" display={displayPregtoDel} icon1="fas fa-upload" icon2="fas fa-database" texto="Editar Preguntas seleccionadas" onClick={this.handleOnClick}/>
				</div>
			</div>
		);
	}
}


// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		pregToEdtCP: state.casoPractico.pregToEdtCP
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		genPregToDelete,
		addPregunta,
		delPregunta
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusquedaEditarPreguntas);

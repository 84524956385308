// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
import '../css/css.css';
//Componets
import Input2 from '../../../../../components/Inputs/Input2';
import Boton from '../../../../../components/Inputs/Boton';
import Boton2 from '../../../../../components/Inputs/Boton2';
import TemaSeleted from '../../../../../components/TemaSeleted'

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      aplCurso: {
        opoName: '',
        opoId: '',
        cursoBaseName: '',
        cursoBaseId: '',
        cursoDestinoName: '',
        cursoDestinoId: '',
        modo: '',
        semanaVALUE: '',
        semanaSelected: null,
        fechaStart: null
      },
      busquedaCursosBase: undefined,
      temaSelected: 0

    }
    this.onChangeInput = this.onChangeInput.bind(this);

  }

  async onChangeInput(e) {

    if (this.props.selectedOption == 'ap_cls_din') {

      if (e.target.id == 'opoDin') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

        if (oposicionObject != undefined) {

          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let aplCurso = this.state.aplCurso

            aplCurso.opoName = oposicionObject.nombre
            aplCurso.opoId = oposicionObject.id_oposicion
            aplCurso.cursoBaseName = ''
            aplCurso.cursoBaseId = ''
            aplCurso.cursoDestinoName = ''
            aplCurso.cursoDestinoId = ''
            aplCurso.modo = ''
            aplCurso.semanaVALUE = ''
            aplCurso.semanaSelected = null
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso, busquedaCursosBase: cls1 })
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.opoName = e.target.value
          aplCurso.opoId = ''
          aplCurso.cursoBaseName = ''
          aplCurso.cursoBaseId = ''
          aplCurso.cursoDestinoName = ''
          aplCurso.cursoDestinoId = ''
          aplCurso.modo = ''
          aplCurso.semanaVALUE = ''
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, busquedaCursosBase: undefined })

        }


      } else if (e.target.id == 'cursoBase') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso base',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idOposicion: this.state.aplCurso.opoId, idClase: cursoObject.id_clase };
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaID", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {




            var busquedaClasesFecha2 = await cls1.sort((b, a) => new Date(a.numero_semana) - new Date(b.numero_semana))
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
                if (locaBloque != -1) {
                  await busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema,
                    nombreTema: cls3[z].nombreTema,
                    categoria: cls3[z].categoria
                  })
                }
              }
            }

            let aplCurso = this.state.aplCurso
            aplCurso.cursoBaseName = cursoObject.dia_semanal
            aplCurso.cursoBaseId = cursoObject.id_clase
            this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2.sort((b, a) => b.numero_semana - a.numero_semana), aplCurso: aplCurso })
            swal.close()
          } else {
            swal('Error', 'Este curso no dispone de ninguna programación fija. Debe escoger un curso que tenga aplicada la programación de forma normal', 'warning')
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoBaseName = e.target.value
          aplCurso.cursoBaseId = ''

          this.setState({ aplCurso: aplCurso, busquedaSemanaProgramacion: null })
        }
      } else if (e.target.id == 'cursoDestino') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso destino',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idClase: cursoObject.id_clase }
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/infoProgramasClases", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let maximaSemana = cls1.sort((b, a) => new Date(a.fecha_clase) - new Date(b.fecha_clase))

            let ultimaClase = cls1.filter(c => c.fecha_clase == maximaSemana[0].fecha_clase)[0].fecha_clase

            console.log('maximaSemana')
            console.log(maximaSemana)



            console.log('ultimaClase')
            console.log(ultimaClase)

            this.setState({
              ultimaClaseFecha: ultimaClase,
              nuevaSemana: maximaSemana + 1,
              programasDeClase: cls1
            })

            let aplCurso = this.state.aplCurso
            aplCurso.cursoDestinoName = cursoObject.dia_semanal
            aplCurso.cursoDestinoId = cursoObject.id_clase
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            swal.close()
          } else {
            // no tiene ningun clase!
            this.setState({
              ultimaClaseFecha: '',
              nuevaSemana: 1,
              programasDeClase: null
            })
            let aplCurso = this.state.aplCurso
            aplCurso.cursoDestinoName = cursoObject.dia_semanal
            aplCurso.cursoDestinoId = cursoObject.id_clase
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            swal.close()
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoDestinoName = e.target.value
          aplCurso.cursoDestinoId = ''
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, ultimaClaseFecha: '', nuevaSemana: 1, programasDeClase: null })
        }
      } else if (e.target.id == 'semanaPrg') {

        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'semana'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'completaPrg') {
        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'completa'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'selectSemanaPrg') {
        let numS = e.target.value
        let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == numS);
       
        // let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == e.target.value);
        if (semanaSelected != undefined) {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = semanaSelected
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }

      } else if (e.target.id == 'fechaStart') {

        if (e.target.value.length > 0) {
          if (this.state.ultimaClaseFecha == '' || (moment(e.target.value).format('YYYY-MM-DD HH:mm') > moment(this.state.ultimaClaseFecha).format('YYYY-MM-DD HH:mm'))) {
            let aplCurso = this.state.aplCurso
            aplCurso.fechaStart = e.target.value
            this.setState({ aplCurso: aplCurso })
          } else {
            let aplCurso = this.state.aplCurso
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso })
            swal('Error', 'Debe seleccionar un fecha superior a: ' + moment(this.state.ultimaClaseFecha).format('DD-MM-YY HH:mm') + '. Normalmente debería indicar esta fecha: ' + moment(this.state.ultimaClaseFecha).add(7, 'd').format('DD-MM-YY HH:mm'), 'info')
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }
      } else if (e.target.id == 'aplicaProgramacion') {

        let { aplCurso, busquedaSemanaProgramacion } = this.state

        let infoProgramacionInsert = [];

        if (aplCurso && aplCurso.modo == 'completa') {
          swal({
            title: 'Aplicando Semanas de Clase',
            text: '¡Espere, este proceso puede tardar!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })

          let semanaObject = this.state.aplCurso.semanaSelected
          let claseObject = this.state.busquedaCursosBase.find(prg => prg.id_clase == this.state.aplCurso.cursoDestinoId)

          console.log('semanaObject')
          console.log(semanaObject)

          let posicionPrg = this.state.busquedaSemanaProgramacion.findIndex(prg => prg.id_programa == semanaObject.id_programa)
          let numSemanaPrimera = this.state.busquedaSemanaProgramacion[posicionPrg].numero_semana
          console.log('posicionPrg')
          console.log(posicionPrg)

          console.log('busquedaSemanaProgramacion')
          console.log(busquedaSemanaProgramacion)


          for (var i = 0; i < busquedaSemanaProgramacion.length; i++) {
            let fechaInicioPrg
            if (i == 0) {
              fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")
            } else {
              fechaInicioPrg = moment(aplCurso.fechaStart).add(7 * i, 'd').format("YYYY-MM-DD HH:mm")
            }

            let posicionIdPrgCorrecta = posicionPrg + i;
            if (posicionIdPrgCorrecta >= busquedaSemanaProgramacion.length) {
              posicionIdPrgCorrecta = posicionIdPrgCorrecta - busquedaSemanaProgramacion.length
            }
            let idPrg = busquedaSemanaProgramacion[posicionIdPrgCorrecta].id_programa
            //let numero_semana = i + 1;
            let numero_semana = busquedaSemanaProgramacion[posicionIdPrgCorrecta].numero_semana
            let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
            let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
            let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

            infoProgramacionInsert.push({
              id_programa: idPrg,
              id_clase: claseObject.id_clase,
              numero_semana_clase: numero_semana,
              fecha_clase: fechaInicioPrgInser,
              fecha_mostrar_tema: fechaDesbloqueoTema,
              fecha_mostrar_test: fechaDesbloqueoTest
            })
          }

          var data = { programacion: infoProgramacionInsert }

          fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) {
              swal('Error', 'El servidor fallo al intentar este procedmiento. Vuelva a intentarlo', 'warning');
              throw new Error("Bad response from server");
            }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {

              swal({
                title: 'Proceso completado',
                text: '¡Clases aplicadas correctamente!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2500,
              }).then(() => {
                window.location.reload()
              })
            }
          }).catch(function (err) { console.log(err) });

        } else if (aplCurso && aplCurso.modo == 'semana') {
          swal({
            title: 'Aplicando 1 Semana de Clase',
            text: '¡Espere, un momento!',
            icon: "success",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })

          let semanaObject = this.state.aplCurso.semanaSelected
          let claseObject = this.state.busquedaCursosBase.find(prg => prg.id_clase == this.state.aplCurso.cursoDestinoId)

          let fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")



          let idPrg = semanaObject.id_programa
          let numero_semana = semanaObject.numero_semana
          let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
          let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
          let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

          infoProgramacionInsert.push({
            id_programa: idPrg,
            id_clase: claseObject.id_clase,
            numero_semana_clase: numero_semana,
            fecha_clase: fechaInicioPrgInser,
            fecha_mostrar_tema: fechaDesbloqueoTema,
            fecha_mostrar_test: fechaDesbloqueoTest
          })


          var data = { programacion: infoProgramacionInsert }
          fetch("https://oposiciones-justicia.es/api/oficina/APClaseInsert", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) {
              swal('Error', 'El servidor fallo al intentar este procedmiento. Vuelva a intentarlo', 'warning');
              throw new Error("Bad response from server");
            }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {

              swal({
                title: 'Proceso completado',
                text: '¡Clase aplicada correctamente!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 2500,
              }).then(() => {
                window.location.reload()
              })
            }
          }).catch(function (err) { console.log(err) });

        }



      }

    } else if (this.props.selectedOption == 'ap_cls_din_eliminar') {

      if (e.target.id == 'opoDin') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

        if (oposicionObject != undefined) {

          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let aplCurso = this.state.aplCurso

            aplCurso.opoName = oposicionObject.nombre
            aplCurso.opoId = oposicionObject.id_oposicion
            aplCurso.cursoBaseName = ''
            aplCurso.cursoBaseId = ''
            aplCurso.cursoDestinoName = ''
            aplCurso.cursoDestinoId = ''
            aplCurso.modo = ''
            aplCurso.semanaVALUE = ''
            aplCurso.semanaSelected = null
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso, busquedaCursosBase: cls1 })
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.opoName = e.target.value
          aplCurso.opoId = ''
          aplCurso.cursoBaseName = ''
          aplCurso.cursoBaseId = ''
          aplCurso.cursoDestinoName = ''
          aplCurso.cursoDestinoId = ''
          aplCurso.modo = ''
          aplCurso.semanaVALUE = ''
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, busquedaCursosBase: undefined })

        }


      } else if (e.target.id == 'cursoBase') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso base',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idOposicion: this.state.aplCurso.opoId, idClase: cursoObject.id_clase };
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaIDDinamica", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {

            var busquedaClasesFecha2 = await cls1.sort((b, a) => new Date(a.numero_semana) - new Date(b.numero_semana))
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
                if (locaBloque != -1) {
                  await busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema,
                    nombreTema: cls3[z].nombreTema,
                    categoria: cls3[z].categoria
                  })
                }
              }
            }

            let aplCurso = this.state.aplCurso
            aplCurso.cursoBaseName = cursoObject.dia_semanal
            aplCurso.cursoBaseId = cursoObject.id_clase
            aplCurso.semanaVALUE = ''
            aplCurso.modo = ''
            aplCurso.semanaSelected = null
            this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2.sort((b, a) => b.numero_semana - a.numero_semana), aplCurso: aplCurso })
            swal.close()
          } else {
            swal('Error', 'Este curso no dispone de ninguna programación aplicada.', 'warning')
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoBaseName = e.target.value
          aplCurso.cursoBaseId = ''
          aplCurso.semanaVALUE = ''
          aplCurso.modo = ''
          aplCurso.semanaSelected = null

          this.setState({ aplCurso: aplCurso, busquedaSemanaProgramacion: null })
        }
      } else if (e.target.id == 'semanaPrg') {

        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'semana'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'completaPrg') {
        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'completa'
        aplCurso.fechaStart = null
        aplCurso.semanaSelected = null
        aplCurso.semanaVALUE = ''
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'selectSemanaPrg') {
        let numS = e.target.value.split(';')[0]
        let fechaS = e.target.value.split(';')[1]
        let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana_clase == numS && moment(prg.fecha_clase).format("YYYY-MM-DD HH:mm") == moment(fechaS, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm"));
       
        // let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana_clase == e.target.value);
        if (semanaSelected != undefined) {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = semanaSelected
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }

      } else if (e.target.id == 'delProgramacion') {

        let { aplCurso, busquedaSemanaProgramacion } = this.state

        let infoProgramacionInsert = [];

        if (aplCurso && aplCurso.modo == 'completa') {


          swal({
            title: "¿Estas segur@?",
            text: "Si le das a ok, toda la programación aplicada a la clase sera eliminada.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then((willDelete) => {
              if (willDelete) {
                let data254 = { idClase: aplCurso.cursoBaseId }
                fetch("https://oposiciones-justicia.es/api/oficina/prgApDel", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data254)
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  if (data.affectedRows > 0) {
                    swal({
                      title: 'Programación aplicada eliminada',
                      text: '¡Recargando!',
                      icon: "success",
                      buttons: false,
                      closeOnEsc: false,
                      closeOnClickOutside: false,
                      timer: 2500,
                    }).then(() => {
                      window.location.reload()
                    })
                  }
                }).catch(function (err) { console.log(err) });

              } else {
                swal("¡Programación no eliminada!", '', "success");
              }
            });

        } else if (aplCurso && aplCurso.modo == 'semana') {


          swal({
            title: "¿Estas segur@?",
            text: "Si le das a ok, la semana aplicada a la clase sera eliminada.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then((willDelete) => {
              let data23 = { idPrg: aplCurso.semanaSelected.id_programa, idClase: aplCurso.cursoBaseId, numSemana: aplCurso.semanaSelected.numero_semana_clase, fechaClase: moment(aplCurso.semanaSelected.fecha_clase).format('YYYY-MM-DD HH:mm') }

              if (willDelete) {
                fetch("https://oposiciones-justicia.es/api/oficina/prgApDelSem", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data23)
                }).then(response => {
                  if (response.status >= 400) { throw new Error("Bad response from server"); }
                  return response.json();
                }).then(data => {
                  if (data.affectedRows > 0) {
                    swal({
                      title: 'Programación aplicada eliminada',
                      text: '¡Recargando!',
                      icon: "success",
                      buttons: false,
                      closeOnEsc: false,
                      closeOnClickOutside: false,
                      timer: 2500,
                    }).then(() => {
                      window.location.reload()
                    })
                  }
                }).catch(function (err) { console.log(err) });

              } else {
                swal("¡Programación no eliminada!", '', "success");
              }
            });

        }


      }

    } else if (this.props.selectedOption == 'ap_cls_din_fecha') {

      if (e.target.id == 'opoDin') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

        if (oposicionObject != undefined) {

          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let aplCurso = this.state.aplCurso

            aplCurso.opoName = oposicionObject.nombre
            aplCurso.opoId = oposicionObject.id_oposicion
            aplCurso.cursoBaseName = ''
            aplCurso.cursoBaseId = ''
            aplCurso.cursoDestinoName = ''
            aplCurso.cursoDestinoId = ''
            aplCurso.modo = ''
            aplCurso.semanaVALUE = ''
            aplCurso.semanaSelected = null
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso, busquedaCursosBase: cls1 })
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.opoName = e.target.value
          aplCurso.opoId = ''
          aplCurso.cursoBaseName = ''
          aplCurso.cursoBaseId = ''
          aplCurso.cursoDestinoName = ''
          aplCurso.cursoDestinoId = ''
          aplCurso.modo = ''
          aplCurso.semanaVALUE = ''
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, busquedaCursosBase: undefined })

        }


      } else if (e.target.id == 'cursoBase') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso base',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idOposicion: this.state.aplCurso.opoId, idClase: cursoObject.id_clase };
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaIDDinamica", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {

            var busquedaClasesFecha2 = await cls1.sort((b, a) => new Date(a.numero_semana) - new Date(b.numero_semana))
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
                if (locaBloque != -1) {
                  await busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema,
                    nombreTema: cls3[z].nombreTema,
                    categoria: cls3[z].categoria
                  })
                }
              }
            }

            let aplCurso = this.state.aplCurso
            aplCurso.cursoBaseName = cursoObject.dia_semanal
            aplCurso.cursoBaseId = cursoObject.id_clase
            aplCurso.semanaVALUE = ''
            aplCurso.modo = ''
            aplCurso.semanaSelected = null
            this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2.sort((b, a) => b.numero_semana - a.numero_semana), aplCurso: aplCurso })
            swal.close()
          } else {
            swal('Error', 'Este curso no dispone de ninguna programación aplicada.', 'warning')
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoBaseName = e.target.value
          aplCurso.cursoBaseId = ''
          aplCurso.semanaVALUE = ''
          aplCurso.modo = ''
          aplCurso.semanaSelected = null

          this.setState({ aplCurso: aplCurso, busquedaSemanaProgramacion: null })
        }
      } else if (e.target.id == 'semanaPrg') {

        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'semana'
        aplCurso.fechaStart = null
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'completaPrg') {
        let aplCurso = this.state.aplCurso
        aplCurso.modo = 'completa'
        aplCurso.fechaStart = null
        aplCurso.semanaSelected = null
        aplCurso.semanaVALUE = ''
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'selectSemanaPrg') {
        // let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana_clase == e.target.value);

        // console.log('e.target.value')
        // console.log(e.target.value.toString())
        let numS = e.target.value.split(';')[0]
        let fechaS = e.target.value.split(';')[1]
       
        let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana_clase == numS && moment(prg.fecha_clase).format("YYYY-MM-DD HH:mm") == moment(fechaS, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm"));

  
        if (semanaSelected != undefined) {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = semanaSelected
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }

      } else if (e.target.id == 'edtFechaProgramacion') {

        let { aplCurso, busquedaSemanaProgramacion } = this.state
        // text: "Si le das a ok, toda la programación por encima de la semana elegida, canbiará la fecha el mismo número de días.",
        swal({
          title: "¿Estas segur@?",
          text: aplCurso.modo == 'semana' ? ("Si le das a ok, se realizará un cambio de fecha en la semana seleccionada.") : ("Si le das a ok, toda la programación por encima de la semana elegida, cambiará su fecha el mismo número de días."),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {

              let infoProgramacionInsert = [];

              if (aplCurso && aplCurso.modo == 'completa') {
                swal({
                  title: 'Realizando cambios de fecha',
                  text: 'Procesando...',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })

                let semanaObject = this.state.aplCurso.semanaSelected

                let posicionPrg = this.state.busquedaSemanaProgramacion.findIndex(prg => prg.id_programa == semanaObject.id_programa && moment(prg.fecha_clase).format("YYYY-MM-DD HH:mm") == moment(semanaObject.fecha_clase).format("YYYY-MM-DD HH:mm"))

                for (var i = 0; i < busquedaSemanaProgramacion.length; i++) {
                  let fechaInicioPrg
                  if (i == 0) {
                    fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")
                  } else {
                    fechaInicioPrg = moment(aplCurso.fechaStart).add(7 * i, 'd').format("YYYY-MM-DD HH:mm")
                  }

                  let posicionIdPrgCorrecta = posicionPrg + i;
                  if (posicionIdPrgCorrecta >= busquedaSemanaProgramacion.length) {
                    //las anteriores se quedan igual
                    break;
                  }
                  let idPrg = busquedaSemanaProgramacion[posicionIdPrgCorrecta].id_programa
                  //let numero_semana = i + 1;
                  let numero_semana = busquedaSemanaProgramacion[posicionIdPrgCorrecta].numero_semana
                  let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
                  let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
                  let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

                  infoProgramacionInsert.push({
                    id_programa: idPrg,
                    id_clase: this.state.aplCurso.cursoBaseId,
                    fechaOrigen: moment(busquedaSemanaProgramacion[posicionIdPrgCorrecta].fecha_clase).format("YYYY-MM-DD HH:mm"),
                    numero_semana_clase: numero_semana,
                    fecha_clase: fechaInicioPrgInser,
                    fecha_mostrar_tema: fechaDesbloqueoTema,
                    fecha_mostrar_test: fechaDesbloqueoTest
                  })
                }
                for (let hj = 0; hj < infoProgramacionInsert.length; hj++) {
                  let data = { prg: infoProgramacionInsert[hj] }

                  let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseEdtFechaCom", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response1.ok) {
                    throw Error(response1.statusText);
                  }
                  let json1 = await response1.json();
                  let cls1 = await json1;

                }
                await swal({
                  title: 'Proceso completado',
                  text: '¡Fechas cambiadas correctamente!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 2500,
                }).then(() => {
                  window.location.reload()
                })


              } else if (aplCurso && aplCurso.modo == 'semana') {
                swal({
                  title: 'Realizando cambios de fecha',
                  text: 'Procesando...',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                })

                let semanaObject = this.state.aplCurso.semanaSelected
                console.log('semanaObject')
                console.log(semanaObject)
                let posicionPrg = this.state.busquedaSemanaProgramacion.findIndex(prg => prg.id_programa == semanaObject.id_programa && moment(prg.fecha_clase).format("YYYY-MM-DD HH:mm") == moment(semanaObject.fecha_clase).format("YYYY-MM-DD HH:mm"))

                let fechaInicioPrg = moment(aplCurso.fechaStart).format("YYYY-MM-DD HH:mm")


                let idPrg = busquedaSemanaProgramacion[posicionPrg].id_programa
                //let numero_semana = i + 1;
                let numero_semana = busquedaSemanaProgramacion[posicionPrg].numero_semana
                let fechaInicioPrgInser = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")
                let fechaDesbloqueoTema = moment(fechaInicioPrg).subtract(7, 'd').format("YYYY-MM-DD HH:mm")
                let fechaDesbloqueoTest = moment(fechaInicioPrg).format("YYYY-MM-DD HH:mm")

                infoProgramacionInsert.push({
                  id_programa: idPrg,
                  id_clase: this.state.aplCurso.cursoBaseId,
                  fechaOrigen: moment(busquedaSemanaProgramacion[posicionPrg].fecha_clase).format("YYYY-MM-DD HH:mm"),
                  numero_semana_clase: numero_semana,
                  fecha_clase: fechaInicioPrgInser,
                  fecha_mostrar_tema: fechaDesbloqueoTema,
                  fecha_mostrar_test: fechaDesbloqueoTest
                })


                for (let hj = 0; hj < infoProgramacionInsert.length; hj++) {
                  let data = { prg: infoProgramacionInsert[hj] }

                  let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseEdtFechaCom", {
                    method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
                  });
                  if (!response1.ok) {
                    throw Error(response1.statusText);
                  }
                  let json1 = await response1.json();
                  let cls1 = await json1;

                }
                await swal({
                  title: 'Proceso completado',
                  text: '¡Fecha cambiada correctamente!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 2500,
                }).then(() => {
                  window.location.reload()
                })


              }



            } else {
              swal("¡Programación no cambiada!", '', "success");
            }

          });




      } else if (e.target.id == 'fechaStart') {

        if (e.target.value.length > 0) {

          let aplCurso = this.state.aplCurso
          aplCurso.fechaStart = e.target.value
          this.setState({ aplCurso: aplCurso })


        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }
      }

    } else if (this.props.selectedOption == 'ap_cls_din_edt') {

      if (e.target.id == 'opoDin') {
        let oposicionObject = this.state.busquedaOpo.find(oposicion => oposicion.nombre == e.target.value);

        if (oposicionObject != undefined) {

          let data = { id_oposicion: oposicionObject.id_oposicion }
          let response1 = await fetch("https://oposiciones-justicia.es/api/clases/consultaSemanas", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {
            let aplCurso = this.state.aplCurso

            aplCurso.opoName = oposicionObject.nombre
            aplCurso.opoId = oposicionObject.id_oposicion
            aplCurso.cursoBaseName = ''
            aplCurso.cursoBaseId = ''
            aplCurso.cursoDestinoName = ''
            aplCurso.cursoDestinoId = ''
            aplCurso.modo = ''
            aplCurso.semanaVALUE = ''
            aplCurso.semanaSelected = null
            aplCurso.fechaStart = null
            this.setState({ aplCurso: aplCurso, busquedaCursosBase: cls1 })
          }

        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.opoName = e.target.value
          aplCurso.opoId = ''
          aplCurso.cursoBaseName = ''
          aplCurso.cursoBaseId = ''
          aplCurso.cursoDestinoName = ''
          aplCurso.cursoDestinoId = ''
          aplCurso.modo = ''
          aplCurso.semanaVALUE = ''
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso, busquedaCursosBase: undefined })

        }


      } else if (e.target.id == 'cursoBase') {
        let cursoObject = this.state.busquedaCursosBase.find(prg => prg.dia_semanal == e.target.value);

        if (cursoObject != undefined) {
          swal({
            title: 'Cargando',
            text: 'Contenido del curso base',
            icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })


          let data = { idOposicion: this.state.aplCurso.opoId, idClase: cursoObject.id_clase };
          let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/consultaID", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
          });
          if (!response1.ok) {
            throw Error(response1.statusText);
          }
          let json1 = await response1.json();
          let cls1 = await json1;

          if (cls1.length > 0) {




            var busquedaClasesFecha2 = await cls1.sort((b, a) => new Date(a.numero_semana) - new Date(b.numero_semana))
            for (var i = 0; i < busquedaClasesFecha2.length; i++) {
              busquedaClasesFecha2[i].temas = []
            }
            for (var k = 0; k < busquedaClasesFecha2.length; k++) {
              let data3 = { idPrg: busquedaClasesFecha2[k].id_programa }


              let response3 = await fetch("https://oposiciones-justicia.es/api/preparador/temasSemanalesPRG", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              for (var z = 0; z < cls3.length; z++) {
                let locaBloque = await busquedaClasesFecha2.findIndex(c => c.id_programa == cls3[z].idPrg);
                if (locaBloque != -1) {
                  await busquedaClasesFecha2[locaBloque].temas.push({
                    idTema: cls3[z].idTema,
                    tituloTema: cls3[z].tituloTema,
                    nombreTema: cls3[z].nombreTema,
                    categoria: cls3[z].categoria
                  })
                }
              }
            }

            let aplCurso = this.state.aplCurso
            aplCurso.cursoBaseName = cursoObject.dia_semanal
            aplCurso.cursoBaseId = cursoObject.id_clase
            this.setState({ busquedaSemanaProgramacion: busquedaClasesFecha2.sort((b, a) => b.numero_semana - a.numero_semana), aplCurso: aplCurso })
            swal.close()
          } else {
            swal('Error', 'Este curso no dispone de ninguna programación fija. Debe escoger un curso que tenga aplicada la programación de forma normal', 'warning')
          }
        } else {

          let aplCurso = this.state.aplCurso
          aplCurso.cursoBaseName = e.target.value
          aplCurso.cursoBaseId = ''

          this.setState({ aplCurso: aplCurso, busquedaSemanaProgramacion: null })
        }
      } else if (e.target.id == 'selectSemanaPrg') {
       
        let semanaSelected = this.state.busquedaSemanaProgramacion.find(prg => prg.numero_semana == e.target.value);
      
        if (semanaSelected != undefined) {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = semanaSelected
          aplCurso.fechaStart = null

          this.setState({ aplCurso: aplCurso })
        } else {
          let aplCurso = this.state.aplCurso
          aplCurso.semanaVALUE = e.target.value
          aplCurso.semanaSelected = null
          aplCurso.fechaStart = null
          this.setState({ aplCurso: aplCurso })
        }

      } else if (e.target.id == 'edtProgramacion') {

        let { aplCurso, busquedaSemanaProgramacion } = this.state

        let infoProgramacionInsert = [];
        try {
          swal({
            title: 'Procesando cambios',
            text: ' ',
            icon: "info",
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
          })

          if (isDefined(aplCurso.semanaSelected.numero_semanaOrigen) && aplCurso.semanaSelected.numero_semanaOrigen != aplCurso.semanaSelected.numero_semana) {
            // editamos num semana
            if (busquedaSemanaProgramacion.filter(d => d.numero_semana == aplCurso.semanaSelected.numero_semana && !isDefined(d.numero_semanaOrigen)).length == 0) {
              let data = { prg: aplCurso.semanaSelected }

              let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseEdtNumSem", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              if (cls1.affectedRows > 0) {
                aplCurso.semanaSelected.numero_semana = aplCurso.semanaSelected.numero_semana
                aplCurso.semanaSelected.numero_semanaOrigen = aplCurso.semanaSelected.numero_semana
              }
            } else {

              throw new Error('NumSemana');
            }
          }

          if (isDefined(aplCurso.semanaSelected.nombre_semanaOrigen) && aplCurso.semanaSelected.nombre_semanaOrigen != aplCurso.semanaSelected.nombre_semana) {
            // editamos nombre_semana
            if (aplCurso.semanaSelected.nombre_semana != '') {
              let data = { prg: aplCurso.semanaSelected }

              let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseEdtNameSem", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              if (cls1.affectedRows > 0 > 0) {
                aplCurso.semanaSelected.nombre_semana = aplCurso.semanaSelected.nombre_semana
                aplCurso.semanaSelected.nombre_semanaOrigen = aplCurso.semanaSelected.nombre_semana
              }
            } else {

              throw new Error('nombre_semana');
            }
          }

          if (aplCurso.semanaSelected.idCategoriaOrigen != aplCurso.semanaSelected.idCategoria) {
            // editamos nombre_semana
            if (aplCurso.semanaSelected.idCategoria != null && aplCurso.semanaSelected.idCategoria != '') {
              let data = { prg: aplCurso.semanaSelected }

              let response1 = await fetch("https://oposiciones-justicia.es/api/oficina/APClaseEdtCatSem", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
              });
              if (!response1.ok) {
                throw Error(response1.statusText);
              }
              let json1 = await response1.json();
              let cls1 = await json1;
              if (cls1.affectedRows > 0) {
                aplCurso.semanaSelected.idCategoria = aplCurso.semanaSelected.idCategoria
                aplCurso.semanaSelected.idCategoriaOrigen = aplCurso.semanaSelected.idCategoria
              }
            } else {

              throw new Error('idCategoria');
            }
          }

          this.setState({ aplCurso: aplCurso })
          swal('Cambios Realizados', ' ', "success")
        } catch (e) {
          if (e.message == "NumSemana") {
            swal('Error', 'El número de semana ' + aplCurso.semanaSelected.numero_semana + ' no puede existir entre las semanas existentes', 'warning')
          } else if (e.message == 'nombre_semana') {
            swal('Error', 'El nombre de la semena no puede estar en blanco', 'warning')
          } else if (e.message == 'idCategoria') {
            swal('Error', 'Seleccione una categoría de justicia.', 'warning')
          }
        }




      } else if (e.target.id.split('-')[0] == 'temaGo') {
        this.setState({ temaSelected: e.target.id.split('-')[1] })
      } else if (e.target.id == 'numSemPrg') {
        let aplCurso = this.state.aplCurso
        if (!isDefined(aplCurso.semanaSelected.numero_semanaOrigen)) {
          aplCurso.semanaSelected.numero_semanaOrigen = aplCurso.semanaSelected.numero_semana
        }
        if (e.target.value == '') {
          aplCurso.semanaSelected.numero_semana = null
        } else {
          aplCurso.semanaSelected.numero_semana = parseInt(e.target.value)
        }

        this.setState({ aplCurso: aplCurso })

      } else if (e.target.id == 'nameSemPrg') {
        let aplCurso = this.state.aplCurso
        if (!isDefined(aplCurso.semanaSelected.nombre_semanaOrigen)) {
          aplCurso.semanaSelected.nombre_semanaOrigen = aplCurso.semanaSelected.nombre_semana
        }


        if (e.target.value == '') {
          aplCurso.semanaSelected.nombre_semana = null
        } else {
          aplCurso.semanaSelected.nombre_semana = e.target.value
        }
        this.setState({ aplCurso: aplCurso })
      } else if (e.target.id == 'categoriaSemPrg') {
        let aplCurso = this.state.aplCurso
        if (!isDefined(aplCurso.semanaSelected.idCategoriaOrigen)) {
          aplCurso.semanaSelected.idCategoriaOrigen = aplCurso.semanaSelected.idCategoria
        }
        if (e.target.value == '') {
          aplCurso.semanaSelected.idCategoria = null
        } else {
          aplCurso.semanaSelected.idCategoria = e.target.value
        }

        this.setState({ aplCurso: aplCurso })

      }



    }

  }

  componentDidMount() {
    fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
      .then(res => res.json())
      .then(oposiciones => this.setState({
        busquedaOpo: oposiciones
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/leyes/consulta')
      .then(res => res.json())
      .then(leyes => this.setState({
        busquedaLeyes: leyes
      })).catch(error => console.log('Obejet fecth failed', error))

    fetch('https://oposiciones-justicia.es/api/usuarios/consultaCategorias')
      .then(res => res.json())
      .then(cls => this.setState({
        categoriasJusticia: cls
      }))
  };

  render() {
    const { selectedOption } = this.props;
    const { aplCurso, busquedaCursosBase, busquedaSemanaProgramacion2, busquedaSemanaProgramacion, busquedaOpo, ultimaClaseFecha, categoriasJusticia, temaSelected } = this.state
    // aplCurso && aplCurso != undefined ? (console.log('aplCurso') + console.log(aplCurso)) : null

    // console.log('busquedaCursosBase')
    // console.log(busquedaCursosBase)
    // console.log('busquedaSemanaProgramacion')
    // console.log(busquedaSemanaProgramacion)
    // console.log('categoriasJusticia')
    // console.log(categoriasJusticia)


    // console.log('ultimaClaseFecha')
    // console.log(ultimaClaseFecha)
    switch (selectedOption) {

      case 'ap_cls_din': {
        return (
          <div className='BodyApClaseDinamica'>


            <div className='BACD-content'>
              <div className='BACD-C-row'>
                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo" value={aplCurso.opoName}
                    autoComplete={"off"} id={"opoDin"} type="text" name={"opoDin-"} ref={"opoDin"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {aplCurso && aplCurso.opoName != '' && aplCurso.opoId != '' ?
                  (
                    <div className='BACD-C-row-cursos'>

                      <div id={"claseBase"} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaCursosBase" value={aplCurso.cursoBaseName}
                          autoComplete={"off"} id={"cursoBase"} type="text" name={"cursoBase"} ref={"cursoBase"} placeholder="Curso Base" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Curso Base</p>
                      </div>
                      {
                        aplCurso.cursoBaseId && aplCurso.cursoBaseId != '' ? (
                          <React.Fragment>

                            <i className="fas fa-exchange-alt icon2f34"></i>

                            <div id={"cursoDestino"} className="celdaInputDAPFBN animacionBotonEntra">
                              <Input2
                                datalist="busquedaCursosBase" value={aplCurso.cursoDestinoName}
                                autoComplete={"off"} id={"cursoDestino"} type="text" name={"cursoDestino"} ref={"cursoDestino"} placeholder="Curso Destino" icon="fas fa-tag" onChange={this.onChangeInput} />
                              <p>Curso Destino</p>
                            </div>


                          </React.Fragment>
                        ) : null
                      }


                    </div>
                  ) : null}

                {aplCurso.cursoDestinoId && aplCurso.cursoDestinoId != '' ? (
                  <div className='BACD-C-row-modoAsig'>
                    <div className='BACD-C-row-modoAsig-title'>
                      MODO DE ASIGNACIÓN
                    </div>
                    <div className='BACD-C-row-navBot'>
                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'semana' ? (
                          <Boton id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        )}
                      </div>

                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'completa' ? (
                          <Boton id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        )}
                      </div>
                    </div>
                    <div className='BACD-C-row-modoAsig-title2'>
                      {(aplCurso.modo == 'completa' || aplCurso.modo == 'semana') && ultimaClaseFecha != undefined && ultimaClaseFecha != '' && ultimaClaseFecha != null ? (
                        'Ultima clase aplicada el ' + moment(ultimaClaseFecha).format("YYYY-MM-DD HH:mm")
                      ) : null
                      }
                    </div>
                    <div className='BACD-C-row-navBot'>
                      {aplCurso.modo == 'completa' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '150px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="1º Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>1º Semana</p>
                        </div>
                      ) : null
                      }

                      {aplCurso.modo == 'completa' && aplCurso.semanaSelected != null ? (
                        <div id={"fechaStart"} style={{ width: '220px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            type="datetime-local"
                            autoComplete={"off"} id={"fechaStart"} name={"fechaStart"} ref={"fechaStart"} placeholder="Fecha 1º Clase" icon="fas fa-calendar" onChange={this.onChangeInput} />
                          <p>Fecha 1º Clase</p>
                        </div>
                      ) : null
                      }

                      {aplCurso.modo == 'semana' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '150px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Semana</p>
                        </div>
                      ) : null
                      }
                      {aplCurso.modo == 'semana' && aplCurso.semanaSelected != null ? (
                        <div id={"fechaStart"} style={{ width: '220px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2 type="datetime-local"
                            autoComplete={"off"} id={"fechaStart"} name={"fechaStart"} ref={"fechaStart"} placeholder="Fecha 1º Clase" icon="fas fa-calendar" onChange={this.onChangeInput} />
                          <p>Fecha Clase</p>
                        </div>
                      ) : null
                      }


                    </div>

                    {aplCurso.fechaStart != null && aplCurso.opoId != '' && aplCurso.cursoBaseId != '' && aplCurso.cursoDestinoId != '' && aplCurso.semanaSelected != null ? (
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Boton id="aplicaProgramacion" icon1="" icon2="" texto="APLICAR PROGRAMACIÓN" onClick={this.onChangeInput} />
                      </div>
                    ) : null}

                  </div>
                ) : null

                }

              </div>
              {aplCurso && aplCurso.opoId == '' ?
                (
                  <div className='BACD-C-row-info'>

                    <div className='BACD-C-R-I-title'>
                      <b>Seleccione una oposición para continuar</b>
                      <div className="barBottomBorder"></div>
                    </div>
                    <div className='BACD-C-R-I-infoTxt'>

                    </div>
                  </div>
                ) : (
                  aplCurso && aplCurso.cursoBaseId == '' ?
                    (
                      <div className='BACD-C-row-info'>

                        <div className='BACD-C-R-I-title'>
                          <b>Seleccione el curso base para continuar</b>
                          <div className="barBottomBorder"></div>
                        </div>
                        <div className='BACD-C-R-I-infoTxt'>
                          <p>El <b>curso base</b> es el origen de la programación.</p>
                        </div>
                      </div>
                    ) : (
                      aplCurso && aplCurso.cursoDestinoId == '' ?
                        (
                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Seleccione el curso destino para continuar</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            <div className='BACD-C-R-I-infoTxt'>
                              <p>El <b>curso base</b> es el origen de la programación.</p>
                              <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                            </div>
                          </div>
                        ) : (
                          aplCurso && aplCurso.modo == '' ?
                            (
                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Seleccione el modo de asignación para continuar</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>
                                  <p>El <b>curso base</b> es el origen de la programación.</p>
                                  <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                  <p>El <b>modo de asignación</b>, indica si aplicamos 1 clase o toda la programación.</p>

                                </div>
                              </div>
                            ) : (
                              aplCurso && aplCurso.semanaSelected == null ?
                                (
                                  <div className='BACD-C-row-info'>

                                    <div className='BACD-C-R-I-title'>
                                      <b>Seleccione una semana para continuar</b>
                                      <div className="barBottomBorder"></div>
                                    </div>
                                    <div className='BACD-C-R-I-infoTxt'>
                                      <p>El <b>curso base</b> es el origen de la programación.</p>
                                      <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                      <p>El <b>modo de asignación</b>, indica si aplicamos 1 clase o toda la programación.</p>

                                      {
                                        aplCurso.modo == 'completa' ? (
                                          <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                        ) : null
                                      }
                                      {aplCurso.modo == 'semana' ? (
                                        <p>La <b>semana</b> elegida será la aplicada.</p>
                                      ) : null
                                      }
                                    </div>
                                  </div>
                                ) : (
                                  aplCurso && aplCurso.fechaStart == null ?
                                    (
                                      <div className='BACD-C-row-info'>

                                        <div className='BACD-C-R-I-title'>
                                          <b>Seleccione una fecha para continuar</b>
                                          <div className="barBottomBorder"></div>
                                        </div>
                                        <div className='BACD-C-R-I-infoTxt'>
                                          <p>El <b>curso base</b> es el origen de la programación.</p>
                                          <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                          <p>El <b>modo de asignación</b>, indica si aplicamos 1 clase o toda la programación.</p>

                                          {
                                            aplCurso.modo == 'completa' ? (
                                              <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                            ) : null
                                          }
                                          {aplCurso.modo == 'semana' ? (
                                            <p>La <b>semana</b> elegida será la aplicada.</p>
                                          ) : null
                                          }
                                          <p>La <b>fecha</b> debe ser superior a la ultima aplicada, en caso de tener alguna clase aplicada el curso destino.</p>

                                        </div>
                                      </div>
                                    ) : (

                                      <div className='BACD-C-row-info'>

                                        <div className='BACD-C-R-I-title'>
                                          <b>Todo listo para aplicar programacion dinámica</b>
                                          <div className="barBottomBorder"></div>
                                        </div>
                                        <div className='BACD-C-R-I-infoTxt'>
                                          <p>El <b>curso base</b> es el origen de la programación.</p>
                                          <p>El <b>curso destino</b> es donde se aplicará la programación dinamicamente.</p>
                                          <p>El <b>modo de asignación</b>, indica si aplicamos 1 clase o toda la programación.</p>

                                          {
                                            aplCurso.modo == 'completa' ? (
                                              <p>La <b>semana</b> elegida será la 1º aplicada, todas las demás sucesivamente +7 días.</p>
                                            ) : null
                                          }
                                          {aplCurso.modo == 'semana' ? (
                                            <p>La <b>semana</b> elegida será la aplicada.</p>
                                          ) : null
                                          }
                                          <p>La <b>fecha</b> debe ser superior a la ultima aplicada, en caso de tener alguna clase aplicada el curso destino.</p>

                                        </div>
                                      </div>

                                    )
                                )
                            )
                        )
                    )
                )}


            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaSemanaProgramacion2">{
              busquedaSemanaProgramacion2 && busquedaSemanaProgramacion2.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaCursosBase">{
              busquedaCursosBase && busquedaCursosBase.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>

          </div>
        );
      } break;

      case 'ap_cls_din_eliminar': {
        return (
          <div className='BodyApClaseDinamica'>


            <div className='BACD-content'>
              <div className='BACD-C-row'>
                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo" value={aplCurso.opoName}
                    autoComplete={"off"} id={"opoDin"} type="text" name={"opoDin-"} ref={"opoDin"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {aplCurso && aplCurso.opoName != '' && aplCurso.opoId != '' ?
                  (
                    <div className='BACD-C-row-cursos'>

                      <div id={"claseBase"} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaCursosBase" value={aplCurso.cursoBaseName}
                          autoComplete={"off"} id={"cursoBase"} type="text" name={"cursoBase"} ref={"cursoBase"} placeholder="Curso" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Curso</p>
                      </div>

                    </div>
                  ) : null}

                {aplCurso.cursoBaseId && aplCurso.cursoBaseId != '' ? (
                  <div className='BACD-C-row-modoAsig'>
                    <div className='BACD-C-row-modoAsig-title'>
                      MODO DE ELIMINACION
                    </div>
                    <div className='BACD-C-row-navBot'>
                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'semana' ? (
                          <Boton id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        )}
                      </div>

                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'completa' ? (
                          <Boton id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        )}
                      </div>
                    </div>

                    <div className='BACD-C-row-navBot'>

                      {aplCurso.modo == 'semana' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '175px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Semana a borrar</p>
                        </div>
                      ) : null
                      }



                    </div>

                    {aplCurso.opoId != '' && aplCurso.cursoBaseId != '' && (aplCurso.semanaSelected != null || aplCurso.modo == 'completa') ? (
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Boton id="delProgramacion" icon1="" icon2="" texto="BORRAR PROGRAMACIÓN" onClick={this.onChangeInput} />
                      </div>
                    ) : null}

                  </div>
                ) : null

                }

              </div>
              {aplCurso && aplCurso.opoId == '' ?
                (
                  <div className='BACD-C-row-info'>

                    <div className='BACD-C-R-I-title'>
                      <b>Seleccione una oposición para continuar</b>
                      <div className="barBottomBorder"></div>
                    </div>
                    <div className='BACD-C-R-I-infoTxt'>

                    </div>
                  </div>
                ) : (
                  aplCurso && aplCurso.cursoBaseId == '' ?
                    (
                      <div className='BACD-C-row-info'>

                        <div className='BACD-C-R-I-title'>
                          <b>Seleccione el curso para continuar</b>
                          <div className="barBottomBorder"></div>
                        </div>
                        <div className='BACD-C-R-I-infoTxt'>

                        </div>
                      </div>
                    ) : (
                      aplCurso && aplCurso.modo == '' ?
                        (
                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Seleccione el modo de eliminación para continuar</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            <div className='BACD-C-R-I-infoTxt'>

                            </div>
                          </div>
                        ) : (
                          aplCurso && (aplCurso.semanaSelected == null && aplCurso.modo != 'completa') ?
                            (
                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Seleccione una semana para continuar</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>

                                </div>
                              </div>
                            ) : (

                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Todo listo para eliminar programacion aplicada</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>
                                  <p><b>Solo se eliminarán las semanas aplicadas</b> no la programación original creada de forma normal.</p>
                                </div>
                              </div>


                            )
                        )

                    )
                )}


            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana_clase + ';' + moment(cls.fecha_clase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.numero_semana_clase}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaSemanaProgramacion2">{
              busquedaSemanaProgramacion2 && busquedaSemanaProgramacion2.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaCursosBase">{
              busquedaCursosBase && busquedaCursosBase.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>

          </div>
        );
      } break;

      case 'ap_cls_din_fecha': {
        return (
          <div className='BodyApClaseDinamica'>


            <div className='BACD-content'>
              <div className='BACD-C-row'>
                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo" value={aplCurso.opoName}
                    autoComplete={"off"} id={"opoDin"} type="text" name={"opoDin-"} ref={"opoDin"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {aplCurso && aplCurso.opoName != '' && aplCurso.opoId != '' ?
                  (
                    <div className='BACD-C-row-cursos'>

                      <div id={"claseBase"} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaCursosBase" value={aplCurso.cursoBaseName}
                          autoComplete={"off"} id={"cursoBase"} type="text" name={"cursoBase"} ref={"cursoBase"} placeholder="Curso" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Curso</p>
                      </div>

                    </div>
                  ) : null}

                {aplCurso.cursoBaseId && aplCurso.cursoBaseId != '' ? (
                  <div className='BACD-C-row-modoAsig'>
                    <div className='BACD-C-row-modoAsig-title'>
                      MODO DE CAMBIO
                    </div>
                    <div className='BACD-C-row-navBot'>
                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'semana' ? (
                          <Boton id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="semanaPrg" icon1="" icon2="" texto="SEMANA" onClick={this.onChangeInput} />
                        )}
                      </div>

                      <div className='BACD-C-row-navBot-b'>
                        {aplCurso.modo == 'completa' ? (
                          <Boton id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        ) : (
                          <Boton2 id="completaPrg" icon1="" icon2="" texto="COMPLETA" onClick={this.onChangeInput} />
                        )}
                      </div>
                    </div>

                    <div className='BACD-C-row-navBot'>

                      {aplCurso.modo == 'completa' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '175px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="1º Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Semana</p>
                        </div>
                      ) : null
                      }

                      {aplCurso.modo == 'semana' ? (
                        <div id={"selectSemanaPrg"} style={{ width: '175px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                            autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Semana</p>
                        </div>
                      ) : null
                      }
                      {aplCurso.semanaSelected != null ? (
                        <div id={"fechaStart"} style={{ width: '220px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2 type="datetime-local"
                            autoComplete={"off"} id={"fechaStart"} name={"fechaStart"} ref={"fechaStart"} placeholder="Fecha" icon="fas fa-calendar" onChange={this.onChangeInput} />
                          <p>Fecha Cambio</p>
                        </div>
                      ) : null
                      }



                    </div>

                    {aplCurso.opoId != '' && aplCurso.cursoBaseId != '' && aplCurso.semanaSelected != null && aplCurso.fechaStart != null ? (
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Boton id="edtFechaProgramacion" icon1="" icon2="" texto="CAMBIAR FECHA" onClick={this.onChangeInput} />
                      </div>
                    ) : null}

                  </div>
                ) : null

                }

              </div>
              {aplCurso && aplCurso.opoId == '' ?
                (
                  <div className='BACD-C-row-info'>

                    <div className='BACD-C-R-I-title'>
                      <b>Seleccione una oposición para continuar</b>
                      <div className="barBottomBorder"></div>
                    </div>
                    <div className='BACD-C-R-I-infoTxt'>

                    </div>
                  </div>
                ) : (
                  aplCurso && aplCurso.cursoBaseId == '' ?
                    (
                      <div className='BACD-C-row-info'>

                        <div className='BACD-C-R-I-title'>
                          <b>Seleccione el curso para continuar</b>
                          <div className="barBottomBorder"></div>
                        </div>
                        <div className='BACD-C-R-I-infoTxt'>

                        </div>
                      </div>
                    ) : (
                      aplCurso && aplCurso.modo == '' ?
                        (
                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Seleccione el modo de cambio para continuar</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            <div className='BACD-C-R-I-infoTxt'>

                            </div>
                          </div>
                        ) : (
                          aplCurso && (aplCurso.semanaSelected == null && aplCurso.modo != 'completa') ?
                            (
                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Seleccione una semana para continuar</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>

                                </div>
                              </div>
                            ) : (

                              <div className='BACD-C-row-info'>

                                <div className='BACD-C-R-I-title'>
                                  <b>Todo listo para eliminar programacion aplicada</b>
                                  <div className="barBottomBorder"></div>
                                </div>
                                <div className='BACD-C-R-I-infoTxt'>
                                  <p><b>Solo se cambiarán las semanas aplicadas</b> no la programación original creada de forma normal.</p>
                                </div>
                              </div>


                            )
                        )

                    )
                )}


            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.map((cls, key) =>
                <option key={key} value={cls.numero_semana_clase + ';' + moment(cls.fecha_clase).format('DD-MM-YY HH:mm')}> Nº Semana {cls.numero_semana_clase}, {moment(cls.fecha_clase).format('DD-MM-YY HH:mm')},Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaSemanaProgramacion2">{
              busquedaSemanaProgramacion2 && busquedaSemanaProgramacion2.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaCursosBase">{
              busquedaCursosBase && busquedaCursosBase.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>

          </div>
        );
      } break;
      case 'ap_cls_din_edt': {
        return (
          <div className='BodyApClaseDinamica'>


            <div className='BACD-content'>
              <div className='BACD-C-row'>
                <div id={"oposicionDin"} className="celdaInputDAPFBN animacionBotonEntra">
                  <Input2
                    datalist="busquedaOpo" value={aplCurso.opoName}
                    autoComplete={"off"} id={"opoDin"} type="text" name={"opoDin-"} ref={"opoDin"} placeholder="Oposición" icon="fas fa-tag" onChange={this.onChangeInput} />
                  <p>Oposición</p>
                </div>
                {aplCurso && aplCurso.opoName != '' && aplCurso.opoId != '' ?
                  (
                    <div className='BACD-C-row-cursos'>

                      <div id={"claseBase"} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaCursosBase" value={aplCurso.cursoBaseName}
                          autoComplete={"off"} id={"cursoBase"} type="text" name={"cursoBase"} ref={"cursoBase"} placeholder="Curso" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Curso</p>
                      </div>

                    </div>
                  ) : null}

                {aplCurso.cursoBaseId && aplCurso.cursoBaseId != '' ? (
                  <div className='BACD-C-row-modoAsig'>
                    <div className='BACD-C-row-modoAsig-title'>
                      {aplCurso && aplCurso.semanaSelected == null ? ('SELECCIONE 1 SEMANA') : ('INFORMACIÓN SEMANA')}
                    </div>

                    <div className='BACD-C-row-navBot'>

                      <div id={"selectSemanaPrg"} style={{ width: '175px' }} className="celdaInputDAPFBN animacionBotonEntra">
                        <Input2
                          datalist="busquedaSemanaProgramacion" value={aplCurso.semanaVALUE}
                          autoComplete={"off"} id={"selectSemanaPrg"} type="text" name={"selectSemanaPrg"} ref={"selectSemanaPrg"} placeholder="Seleccione 1 Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                        <p>Semana a editar</p>
                      </div>


                    </div>

                    {aplCurso && aplCurso.semanaSelected != null ? (
                      <div className='BACD-C-row-navBot' style={{ alignItems: 'flex-start' }}>

                        <div id={"selectSemanaPrg"} style={{ width: '120px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            value={aplCurso.semanaSelected.numero_semana != null ? (aplCurso.semanaSelected.numero_semana) : ('')}
                            autoComplete={"off"} id={"numSemPrg"} type="text" name={"numSemPrg"} ref={"numSemPrg"} placeholder="Número Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Número</p>
                          {aplCurso.semanaSelected.numero_semana != null ? (
                            <div className='pnavBotbacdcrow'>
                              {'Semana Nº ' + aplCurso.semanaSelected.numero_semana}
                            </div>
                          ) : (
                            <div className='pnavBotbacdcrow'>
                              Indique un número de semana
                            </div>
                          )}
                        </div>

                        <div id={"selectSemanaPrg"} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            value={aplCurso.semanaSelected.nombre_semana != null ? (aplCurso.semanaSelected.nombre_semana) : ('')}
                            autoComplete={"off"} id={"nameSemPrg"} type="text" name={"nameSemPrg"} ref={"nameSemPrg"} placeholder="Nombre Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Nombre</p>
                          {aplCurso.semanaSelected.nombre_semana != null ? (
                            <div className='pnavBotbacdcrow'>
                              {aplCurso.semanaSelected.nombre_semana}
                            </div>
                          ) : (
                            <div className='pnavBotbacdcrow'>
                              Indique el nombre de semana
                            </div>
                          )}
                        </div>

                        <div id={"selectSemanaPrg"} style={{ width: '135px' }} className="celdaInputDAPFBN animacionBotonEntra">
                          <Input2
                            value={aplCurso.semanaSelected.idCategoria != null ? (aplCurso.semanaSelected.idCategoria) : ('')}
                            datalist="categoriasJusticia" autoComplete={"off"} id={"categoriaSemPrg"} type="text" name={"categoriaSemPrg"} ref={"categoriaSemPrg"} placeholder="Categoría Semana" icon="fas fa-tag" onChange={this.onChangeInput} />
                          <p>Categoría</p>
                          {aplCurso.semanaSelected.idCategoria != null ? (
                            <div className='pnavBotbacdcrow'>
                              {isDefined(aplCurso.semanaSelected.idCategoria) && categoriasJusticia.find(c => c.id_categoria == aplCurso.semanaSelected.idCategoria).nombre}
                            </div>
                          ) : (
                            <div className='pnavBotbacdcrow'>
                              Indique una Categoría
                            </div>
                          )}

                        </div>



                      </div>
                    ) : null}

                    {aplCurso && aplCurso.semanaSelected != null ? (
                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Boton id="edtProgramacion" icon1="" icon2="" texto="CAMBIAR" onClick={this.onChangeInput} />
                      </div>
                    ) : null}


                  </div>
                ) : null

                }

              </div>
              {aplCurso && aplCurso.opoId == '' ?
                (
                  <div className='BACD-C-row-info'>

                    <div className='BACD-C-R-I-title'>
                      <b>Seleccione una oposición para continuar</b>
                      <div className="barBottomBorder"></div>
                    </div>
                    <div className='BACD-C-R-I-infoTxt'>

                    </div>
                  </div>
                ) : (
                  aplCurso && aplCurso.cursoBaseId == '' ?
                    (
                      <div className='BACD-C-row-info'>

                        <div className='BACD-C-R-I-title'>
                          <b>Seleccione un curso para continuar</b>
                          <div className="barBottomBorder"></div>
                        </div>
                        <div className='BACD-C-R-I-infoTxt'>

                        </div>
                      </div>
                    ) : (

                      aplCurso && aplCurso.semanaSelected == null ?
                        (
                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Seleccione una semana para continuar</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            <div className='BACD-C-R-I-infoTxt'>

                            </div>
                          </div>
                        ) : (

                          <div className='BACD-C-row-info'>

                            <div className='BACD-C-R-I-title'>
                              <b>Todo listo editar información de semana</b>
                              <div className="barBottomBorder"></div>
                            </div>
                            {aplCurso && aplCurso.semanaSelected != null ? (
                              <div className="SSFC-B-TemaLoad">

                                <b>Temas principales de la semana</b>
                                <div className="SSFC-B-TL-navTemas">
                                  {aplCurso.semanaSelected.temas.map((tema, key645tr) =>
                                    <div id={'temaGo-' + key645tr}
                                      className={temaSelected == key645tr ? ("SSFC-B-TL-ButonNavS") : ("SSFC-B-TL-ButonNav")}
                                      onClick={this.onChangeInput}
                                      style={{ borderLeft: key645tr > 0 ? ('1px solid #f1f1f1') : ('none') }}
                                    >
                                      {tema.tituloTema}
                                      {tema.nombreTema != null && tema.nombreTema != '' ? (
                                        ' (' + tema.nombreTema + ') '
                                      ) : null}

                                      {tema.categoria != null && tema.categoria != '' && categoriasJusticia.find(a => a.id_categoria == tema.categoria) != undefined ? (
                                        ' -' + categoriasJusticia.find(a => a.id_categoria == tema.categoria).nombre + '-'
                                      ) : null}

                                    </div>
                                  )}
                                </div>
                                <div className="SSFC-B-TL-temaLoaddf">
                                  {isDefined(temaSelected) && aplCurso.semanaSelected.temas.length > 0 ?
                                    (

                                      <TemaSeleted idTema={aplCurso.semanaSelected.temas[temaSelected].idTema} />

                                    ) : null
                                  }

                                </div>



                              </div>
                            ) : null}
                          </div>


                        )


                    )
                )}


            </div>

            <datalist id="busquedaSemanaProgramacion">{
              busquedaSemanaProgramacion && busquedaSemanaProgramacion.sort((a, b) => a.numero_semana - b.numero_semana).map((cls, key) =>
                <option key={key} value={isDefined(cls.numero_semanaOrigen) ? (cls.numero_semanaOrigen) : (cls.numero_semana)}>
                  Nº Semana {isDefined(cls.numero_semanaOrigen) ? (cls.numero_semanaOrigen) : (cls.numero_semana)}
                  ,Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                    cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                  )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaSemanaProgramacion2">{
              busquedaSemanaProgramacion2 && busquedaSemanaProgramacion2.map((cls, key) =>
                <option key={key} value={cls.numero_semana}> Nº Semana {cls.numero_semana}, Temas: {cls && cls.temas && cls.temas.length > 0 && cls.temas.map((a, key2) =>
                  cls.temas.length - 1 == key2 ? (a.tituloTema + ', (' + a.nombreTema + ')' + '.') : (a.tituloTema + ', (' + a.nombreTema + ')' + ', ')
                )}
                </option>
              )
            }</datalist>
            <datalist id="busquedaCursosBase">{
              busquedaCursosBase && busquedaCursosBase.map((cls, key) =>
                <option key={key} value={cls.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaOpo">{
              busquedaOpo && busquedaOpo.map((opo, key) =>
                <option key={key} value={opo.nombre}></option>
              )
            }</datalist>
            <datalist id="categoriasJusticia">{
              categoriasJusticia && categoriasJusticia.map((opo, key) =>
                <option key={key} value={opo.id_categoria}>
                  {opo.nombre}
                </option>
              )
            }</datalist>

          </div>
        );
      } break;


      default: {
        return (
          <div className='BodyApClaseDinamica'>

          </div>
        );
      }
    }
  }
}
export default OptionBar;

// Actions
const CAMBIAR_PREG_JUSTICIA_EDT = 'CAMBIAR_PREG_JUSTICIA_EDT';
const ELIMINAR_PREG_JUSTICIA_EDT = 'ELIMINAR_PREG_JUSTICIA_EDT';

export function cambiarDatosPregJusticiaEdt(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_PREG_JUSTICIA_EDT,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarPregJusticiaEdtCache() {
    return {
      type: ELIMINAR_PREG_JUSTICIA_EDT
    };
}

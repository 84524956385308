// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// Assets
import './css/css.css';
//import Input2 from '../Inputs/Input2';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadClaseCurso, filtrarClaseCurso } from '../../lib/utils/cargarSemanaClase.js';
import { isDefined } from '../../lib/utils/is';

import { loadSemanaPrg, semanaFacturadaLoad } from '../../actions/userAction'
import swal from 'sweetalert';
import ReactHtmlParser from 'react-html-parser';

class CajaSemanaWord extends Component {
	static propTypes = {
	};

	constructor(props) {
		super(props);
		this.state = {

		};

		this.onHandleClick = this.onHandleClick.bind(this);
	}

	async onHandleClick(e) {

		this.props.handleOnClicWord(e)

	}

	componentDidMount() {
		// fetch('https://oposiciones-justicia.es/api/leyes/consulta')
		// .then(res => res.json())
		// 	.then(leyes => this.setState({
		// 		leyes: leyes
		// 	}))
	}

	componentWillUnmount() {
		"LO CERRAMOS"
	}
	render() {
		const { semanaWord2010, keyProp } = this.props;

		return (


			<div id={'loadSemana-' + keyProp} onClick={this.onHandleClick} className='celdaClaseLE' style={{ marginRight: '0px', padding: '5px' }}>
				
				<div id={'loadSemana-' + keyProp} className='divClaseload'>
					<div id={'loadSemana-' + keyProp} className='textoDivCM'>
						<b id={'loadSemana-' + keyProp} >Contenido</b><br />
						<p id={'loadSemana-' + keyProp}>{semanaWord2010.contenidoTarjeta}</p>
					</div>

					<div id={'loadSemana-' + keyProp} className='ColEndCenter semanaFechaDivCM'>
						
						<p id={'loadSemana-' + keyProp}>Clase Nº {semanaWord2010.numClase}</p>
					</div>

				</div>

			</div>


		);
	}
}

// Conectamos Redux Store
function mapStateToProps(state) {
	return {
		idUser: state.user.idUser,
		semPrgLoadFactura: state.user.semPrgLoadFactura,
		tipoUser: state.user.tipoUser,
		diaInicioWord2010: state.user.diaInicioWord2010,
		facturacionWord: state.user.facturacionWord
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({

	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CajaSemanaWord);

// Dependencies
import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import App from './containers/App';
//const App = lazy(() => import('./containers/App'));

import Escritorio from './containers/Escritorio';
import Login from './containers/Global/Login';
import Page404 from './containers/Page404';
import Administracion from './containers/Administracion';
// Componentes Administracion
import Leyes from './containers/Administracion/components/Leyes';
import Temas from './containers/Administracion/components/Temas';
import Oposiciones from './containers/Administracion/components/Oposiciones'; 
import Usuarios from './containers/Administracion/components/Usuarios';
import ProSemanal from './containers/Administracion/components/ProSemanal';
import Clases from './containers/Administracion/components/Clases';
import Videos from './containers/Administracion/components/Videos';
import TestWordPdf from './containers/Administracion/components/TestWordPdf';
import ExamenGestion from './containers/Administracion/components/ExamenGestion';

import Oficina from './containers/Oficina';
// Componentes Oficina
import Citas from './containers/Oficina/components/Citas';
import Alumnos from './containers/Oficina/components/Alumnos';
import GestionPagos from './containers/Oficina/components/GestionPagos';
import Mecanografia from './containers/Oficina/components/Mecanografia';
import InfoAlumnos from './containers/Oficina/components/InfoAlumnos';
import APClase from './containers/Oficina/components/APClase';
import APClaseDinamica from './containers/Oficina/components/APClaseDinamica';
import InfoClase from './containers/Oficina/components/InfoClase';

import Preparador from './containers/Preparador';
// Componentes Preparador
import EdtClase from './containers/Preparador/components/EdtClase';
import AulaVirtualPreparador from './containers/Preparador/components/AulaVirtualPreparador';
import TestJusticiaPre from './containers/Preparador/components/TestJusticia';
import PreguntasReportadasYDudas from './containers/Preparador/components/PreguntasReportadasYDudas';
import ExamenGestionP from './containers/Preparador/components/ExamenGestion';

import Informatica from './containers/Informatica';
// Componentes Informatica
import TestJusticia from './containers/Informatica/components/TestJusticia';
import CasoPractico from './containers/Informatica/components/CasoPractico';
import Noticias from './containers/Informatica/components/Noticias';

import Perfil from './containers/Perfil';
import AulaVirtualAlumnos from './containers/AulaVirtualAlumnos';
import TestJusticiaAlumnos from './containers/TestJusticiaAlumnos';
import CasoPracticoAlumnos from './containers/CasoPracticoJusticia';
import ExpedienteUser from './containers/ExpedienteUser';
import VerTestUser from './containers/VerTestUser';
import VerTestUserCaso from './containers/VerTestUserCaso';
import Noticia from './containers/Noticia';
import DudasYReportes from './containers/DudasYReportes';
import Notificaciones from './containers/Notificaciones';
import Facturacion from './containers/Facturacion';
import Word2010 from './containers/Word2010';
import TestJusticia2 from './containers/TestJusticia';
import PreparacionCursos from './containers/Global/PreparacionCursos';
import PCSelectOpo from './containers/Global/PCSelectOpo';
import PreOpoInfo from './containers/Global/PreOpoInfo';

// import TestPredefinidos from './containers/TestPredefinidos';



import ResumenInscripcion from './containers/Global/ResumenInscripcion';
import PCSelectRepaso from './containers/Global/PCSelectRepaso';
import ReformasLey from './containers/ReformasLey';
import TestPendientesFinalizar from './containers/TestPendientesFinalizar';
import LeyesAdm from './containers/LeyesAdm';
// import Stripe2 from './components/Stripe2';
// import Stripe2Complete from './components/Stripe2Complete'
// Componentes Preparador

// Container
const AppRoutes = () =>
  <App>
    <Switch>
      <Route exact path="/" component={Login} />
     
      

      {/* 
      <Route exact path="/stripeTest" component={Stripe2} />
      <Route exact path="/ProcesoPago" component={Stripe2Complete} /> */}

      <Route exact path="/Escritorio" component={Escritorio} />

      <Route exact path="/Legislacion" component={LeyesAdm} />

      <Route exact path="/Administration" component={Administracion} />
      <Route exact path="/Adm/Leyes" component={Leyes} />
      <Route exact path="/Adm/Temas" component={Temas} />
      <Route exact path="/Adm/Oposiciones" component={Oposiciones} />
      <Route exact path="/Adm/ctlUsuarios" component={Usuarios} />
      <Route exact path="/Adm/proSemanal" component={ProSemanal} />
      <Route exact path="/Adm/Clases" component={Clases} />
      <Route exact path="/Adm/Videos" component={Videos} />
      <Route exact path="/Adm/TestWordPdf" component={TestWordPdf} />
      <Route exact path="/Adm/ExamGestion" component={ExamenGestion} />

      <Route exact path="/Oficina" component={Oficina} />
      <Route exact path="/Oficina/Citas" component={Citas} />
      <Route exact path="/Oficina/Alumnos" component={Alumnos} />
      <Route exact path="/Oficina/GestionPagos" component={GestionPagos} />
      <Route exact path="/Oficina/Mecanografia" component={Mecanografia} />
      <Route exact path="/Oficina/InfoAlumno" component={InfoAlumnos} />
      <Route exact path="/Oficina/apclase" component={APClase} />
      <Route exact path="/Oficina/infoclase" component={InfoClase} />
      <Route exact path="/Oficina/apclasedin" component={APClaseDinamica} />

      <Route exact path="/Preparador" component={Preparador} />
      <Route exact path="/Preparador/edtClasePrg" component={EdtClase} />
      <Route exact path="/Preparador/aulaVirtual" component={AulaVirtualPreparador} />
      <Route exact path="/Preparador/testJusticia" component={TestJusticiaPre} />
      <Route exact path="/Preparador/pregReportDudas" component={PreguntasReportadasYDudas} />
      <Route exact path="/Preparador/corregirExamGestion" component={ExamenGestionP} />

      <Route exact path="/Informatica" component={Informatica} />
      <Route exact path="/Informatica/Test-Justicia" component={TestJusticia} />
      <Route exact path="/Informatica/Caso-Practico" component={CasoPractico} />
      <Route exact path="/Informatica/Noticias" component={Noticias} />

      <Route exact path="/perfil" component={Perfil} />
      <Route exact path="/aulaVirtual" component={AulaVirtualAlumnos} />
      <Route exact path="/hacerTestJusticia" component={TestJusticiaAlumnos} />

      <Route exact path="/testJusticia" component={TestJusticia2} />
      <Route exact path="/expedienteTest" component={ExpedienteUser} />
      <Route exact path="/revisionTest" component={VerTestUser} />
      <Route exact path="/revisionTestPractico" component={VerTestUserCaso} />
      <Route exact path="/casoPracticoJusticia" component={CasoPracticoAlumnos} />
      <Route exact path="/Noticia" component={Noticia} />
      <Route exact path="/DudasYReportes" component={DudasYReportes} />
      <Route exact path="/Notificaciones" component={Notificaciones} />
      <Route exact path="/Facturacion" component={Facturacion} />
      <Route exact path="/WindowsOffice" component={Word2010} />

      {/* <Route exact path="/convocatorias" component={TestPredefinidos} /> */}

      <Route exact path="/infoReformas" component={ReformasLey} />

      <Route exact path="/testPendienteFinalizar" component={TestPendientesFinalizar} />

      <Route path="/Preparacion/Repaso/:tipoPreparacion/:tipoOposicion/:idClase" component={PCSelectRepaso} />
      <Route path="/Preparacion/:tipoPreparacion/:tipoOposicion/:tipoCurso" component={ResumenInscripcion} />

      <Route path="/Preparacion/:tipoPreparacion/:tipoOposicion" component={PreOpoInfo} />

      <Route path="/Preparacion/:tipoPreparacion" component={PCSelectOpo} />

      <Route exact path="/Preparacion" component={PreparacionCursos} />



      <Route component={Page404} />
    </Switch>
  </App>;
export default AppRoutes;

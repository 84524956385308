// Dependencies
import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
// Utils
import { isDefined } from '../../../../../lib/utils/is';
//Componets
import BarraProceso from '../../../../../components/Inputs/BarraProceso';
import Boton from '../../../../../components/Inputs/Boton';
import Input2 from '../../../../../components/Inputs/Input2';
import InputSelect from '../../../../../components/Inputs/InputSelect';
import Submit from '../../../../../components/Inputs/Submit';

import Facturar from './components/Facturar';

import Reservas from './components/Reservas';
import './css.css';

class OptionBar extends Component {

  static propTypes = {
    selectedOption: PropTypes.string.isRequired,
    idUsuario: PropTypes.number.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      displayFactura: 'none',
      displayClase: 'none',
      displayListFacturas: 'none',
      displayGenFactura: 'none',
      valuesEstado: [
        {
          value: '',
          name: '¿Pagado o Pendiente?',
          disable: 'disabled',
          selected: 'selected'
        }, {
          value: 'Pagado',
          name: 'Pagado',
          disable: undefined,
          selected: undefined
        }, {
          value: 'Pendiente',
          name: 'Pendiente',
          disable: undefined,
          selected: undefined
        }
      ],
      valuesActivado: [
        {
          value: '',
          name: '¿Activar Mes de Clase?',
          disable: 'disabled',
          selected: 'selected'
        }, {
          value: '1',
          name: 'Si',
          disable: undefined,
          selected: undefined
        }, {
          value: '0',
          name: 'No',
          disable: undefined,
          selected: undefined
        }
      ]
      ,
      valuesTipoPago: [
        {
          value: '',
          name: '¿Metálico o Transferencia?',
          disable: 'disabled',
          selected: 'selected'
        }, {
          value: 'Metálico',
          name: 'Metálico',
          disable: undefined,
          selected: undefined
        }, {
          value: 'Transferencia',
          name: 'Transferencia',
          disable: undefined,
          selected: undefined
        }
      ],
      importeEdt: 0,
      fechaInicio: null,


      busquedaActivos: [
        {
          value: 0,
          name: 'NO'
        }, {
          value: 1,
          name: 'SI'
        }
      ],
      busquedaPagado: [
        {
          value: 'Pagado',
          name: 'Pagado'
        }, {
          value: 'Pendiente',
          name: 'Pendiente'
        }
      ],
      busquedaMetodoPagado: [
        {
          value: 'Metalico',
          name: 'Metalico'
        }, {
          value: 'card,sepa_debit',
          name: 'Campus'
        }
      ],
      facturaLoad: null

    }
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeInputNew = this.onChangeInputNew.bind(this);
    this.handleToggleClick = this.handleToggleClick.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.cleanForm = this.cleanForm.bind(this);
    this.handleBuscarAlumno = this.handleBuscarAlumno.bind(this);
  }

  async handleBuscarAlumno(e) {
    let { busquedaAlumnos } = this.state
    let alumnoCorrecto = busquedaAlumnos.find(alumno => alumno.dni == e.target.value);
    if (alumnoCorrecto != undefined) {
      this.setState({ [e.target.name]: e.target.value, idUsuarioAlumno: alumnoCorrecto.id_usuario });
      let data = { id_usuario: alumnoCorrecto.id_usuario }


      let response = await fetch("https://oposiciones-justicia.es/api/oficina/infoAlumnoClase", {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      let json = await response.json();
      let cls = await json;
      if (cls != undefined && cls.length > 0) {
        let clasesAlumno = []
        for (var i = 0; i < cls.length; i++) {
          clasesAlumno.push({
            idClase: cls[i].idClase,
            nombreClase: cls[i].nombreClase,
            activo: cls[i].activo
          })
        }
        let datax2 = { idUser: alumnoCorrecto.id_usuario }
        let response1 = await fetch("https://oposiciones-justicia.es/api/word2010/claseAlumno", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(datax2)
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;
        if (cls1 != undefined && cls1.length > 0) {
         
          clasesAlumno.push({
            idClase: 16,
            nombreClase: 'Word 2010',
            activo: cls1[0].activo
          })
          this.setState({ listaClasesAlumno: clasesAlumno, displayClase: 'block', alumnoBusquedaSelected: alumnoCorrecto });
          this.refs.ref_clase.refs.clase.value = ''
        } else {
          this.setState({ listaClasesAlumno: clasesAlumno, displayClase: 'block', alumnoBusquedaSelected: alumnoCorrecto });
          this.refs.ref_clase.refs.clase.value = ''
        }


      }

    } else {
      this.setState({ displayClase: 'none', listaClasesAlumno: undefined, ultimaFactura: undefined, alumnoBusquedaSelected: null });
      if (this.state.displayListFacturas != 'none' && this.state.displayFactura != 'none') {

        setTimeout(
          function () {
            this.setState({ displayFactura: 'none', displayGenFactura: 'none', displayListFacturas: 'none', listaFacturas: null })
            this.setState({ metodoPago: undefined, estadoFactura: undefined, datosActivados: undefined })
          }.bind(this), 250);
      } else if (this.state.displayListFacturas != 'none') {
        this.setState({ displayFactura: 'none', displayGenFactura: 'none', displayListFacturas: 'none', listaFacturas: null })
      }
    }
  }
  cleanForm() {
    setTimeout(
      function () {
        window.location.reload();
      }.bind(this), 1500);
    switch (this.props.selectedOption) {
      case 'Añadir_Alumno': {

      } break;
    }
  }
  onChangeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (this.props.selectedOption == 'Facturacion_Mensual') {
      if (e.target.name === 'clase') {
        // sacar fecha de ultimo pago y .... listaClasesAlumno
        let objectClase = this.state.listaClasesAlumno.find(c => c.nombreClase == e.target.value)
        if (objectClase != undefined) {
          let data = { idClase: objectClase.idClase, idUsuario: this.state.idUsuarioAlumno };
          fetch("https://oposiciones-justicia.es/api/oficina/ultimaFacturaClaseAlumno", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => this.setState({ ultimaFactura: cls, displayFactura: 'block', displayGenFactura: 'flex' }))
            .catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ ultimaFactura: undefined, displayFactura: 'none', displayGenFactura: 'none' })
        }
      }
    } else if (this.props.selectedOption == 'Activar_Mes_Clase') {

      if (e.target.name === 'clase') {
        let objectClase = this.state.listaClasesAlumno.find(c => c.nombreClase == e.target.value)
        if (objectClase != undefined) {
          let data = { idClase: objectClase.idClase, idUsuario: this.state.idUsuarioAlumno };
          fetch("https://oposiciones-justicia.es/api/oficina/FacturasClaseAlumno", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(cls => {

            this.setState({ listaFacturas: cls, displayListFacturas: 'block' })

            setTimeout(
              function () {
                this.refs.ref_facturas.refs.facturas.value = ''
              }.bind(this), 250);
          })
            .catch(error => console.log('Object fecth failed', error))
        } else {
          this.setState({ listaFacturas: undefined, displayListFacturas: 'none' })
          if (this.state.displayFactura != 'none') {

            setTimeout(
              function () {
                this.setState({ displayFactura: 'none', displayGenFactura: 'none' })
                this.setState({ metodoPago: undefined, estadoFactura: undefined, datosActivados: undefined })
              }.bind(this), 250);
          }


        }
      } else if (e.target.name == 'facturas') {
        let objectFactura = this.state.listaFacturas.find(f => f.id_factura == e.target.value)


        if (objectFactura != undefined) {
          this.setState({ displayFactura: 'block', displayGenFactura: 'flex' })
          let busAlum = this.state.alumnoBusquedaSelected
          let objectClase = this.state.listaClasesAlumno.find(c => c.idClase == objectFactura.id_clase)

          let facturaLoad = {
            idUser: busAlum.id_usuario,
            nombre: busAlum.nombre,
            apellidos: busAlum.apellidos,
            dni: busAlum.dni,
            idClase: objectFactura.id_clase,
            fechaInicio: moment(objectFactura.fecha_pago).format('YYYY-MM-DD'),
            fechaFin: moment(objectFactura.fecha_caducidad).format('YYYY-MM-DD'),
            precio: objectFactura.total_pagado,
            nombreClase: objectClase.nombreClase,
            fotoPerfil: busAlum.link_foto_perfil,
            estado: objectFactura.estado,
            activo: objectClase.activo == 1 ? 'SI' : 'NO',
            tipo: objectFactura.tipo,
            metodoPago: objectFactura.metodo_pago == 'Metálico' || objectFactura.metodo_pago == 'Metalico' || objectFactura.metodo_pago == 'Transferencia' ? ('Metalico') : ('Campus'),
            idFactura: objectFactura.id_factura,
            facturaStripe: objectFactura.facturaUrl
          }

          this.setState({ facturaLoad: facturaLoad })
        } else {

          setTimeout(
            function () {
              this.setState({ displayFactura: 'none', displayGenFactura: 'none', facturaLoad: null })
              this.setState({ metodoPago: undefined, estadoFactura: undefined, datosActivados: undefined })
            }.bind(this), 250);
        }

      }
    }
  }

  handleSubmitForm(e) {
    switch (e.target.id) {
      case 'generar_factura': {
        let { busquedaAlumnos, dniAlumno, listaClasesAlumno, importe, metodoPago, concepto, ultimaFactura, clase, estadoFactura, datosActivados } = this.state
        // ultimaFactura AL SELECCIONAR LA CLASE isDefined(dniAlumno)

        if (isDefined(dniAlumno) && isDefined(listaClasesAlumno) && isDefined(importe) && isDefined(metodoPago) && isDefined(ultimaFactura) && isDefined(clase) && isDefined(estadoFactura) && isDefined(datosActivados)) {
          let fechaActual = moment().format("YYYY-MM-DD HH:mm:ss")
          let mesActual = moment().format("MM")
          let mesUltimoPago = moment(moment(ultimaFactura[0].fecha_pago).format("YYYY-MM-DD HH:mm:ss")).format("MM")
          let fechaReal = undefined
          if (concepto == '' || concepto == undefined) {
            concepto = '';
          }

          if (mesActual == mesUltimoPago) {
            fechaReal = moment(moment(ultimaFactura[0].fecha_pago).format("YYYY-MM-DD HH:mm:ss")).add(1, 'M').subtract(parseInt(moment(ultimaFactura[0].fecha_pago).add(1, 'M').format("DD")) - 1, 'd').format("YYYY-MM-DD HH:mm:ss")
          } else if (mesActual > mesUltimoPago) {
            fechaReal = moment().subtract(parseInt(moment().format("DD")) - 1, 'd').format("YYYY-MM-DD HH:mm:ss")
          } else if (mesActual < mesUltimoPago) {
            fechaReal = moment(moment(ultimaFactura[0].fecha_pago).format("YYYY-MM-DD HH:mm:ss")).add(1, 'M').subtract(parseInt(moment(ultimaFactura[0].fecha_pago).add(1, 'M').format("DD")) - 1, 'd').format("YYYY-MM-DD HH:mm:ss")
          }

          let claseObject = listaClasesAlumno.find(clas => clas.nombreClase == clase);
          let alumnoCorrecto = busquedaAlumnos.find(alumno => alumno.dni == dniAlumno);

          var factura = {
            idClase: claseObject.idClase,
            idAlumno: this.state.idUsuarioAlumno,
            idCobrador: this.props.idUsuario,
            totalPagado: importe,
            metodoPago: metodoPago,
            estado: estadoFactura,
            datosActivados: datosActivados,
            sucripcion: 'Cuota Mensual clase de CEJ',
            concepto: 'Pago Manual en fecha: ' + fechaActual + '. Alumno: ' + alumnoCorrecto.nombre + ', ' + alumnoCorrecto.apellidos + '. Mes pagado de la clase: ' + clase + '. Concepto: ' + concepto + '.',
            fechaFactura: fechaReal,
            fechaCaducidad: moment(fechaReal).add(1, 'M').subtract(parseInt(moment(fechaReal).format("DD")), 'd').format("YYYY-MM-DD HH:mm:ss")
          }

          fetch("https://oposiciones-justicia.es/api/oficina/addFactura", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(factura)
          }).then(response => {
            if (response.status >= 400) { throw new Error("Bad response from server"); }
            return response.json();
          }).then(data => {
            if (data.affectedRows > 0) {
              let claseObject = listaClasesAlumno.find(cls => cls.nombreClase == clase);
              swal('Factura generada correctamente', `
                      Fecha de Pago: `+ moment().format("YYYY-MM-DD HH:mm:ss") + `
                      Fecha de la factura: `+ fechaReal + `,
                      Alumno: `+ alumnoCorrecto.nombre + `, ` + alumnoCorrecto.apellidos + `,
                      Clase: `+ claseObject.nombreClase + `.
                      `, 'success')
              setTimeout(
                function () {
                  window.location.reload();
                }.bind(this), 3000);
            }
          }).catch(error => console.log('Object fecth failed', error))


        } else {
          swal("Complete todos los datos para procesar la solicitud", '', "warning");
        }
      } break;


    }
  }


  onChangeInputNew(e) {
    if (e.target.id == 'precio') {
      let value = e.target.value

      let fac = this.state.facturaLoad

      fac.precio = value

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'claseA') {
      let value = e.target.value

      let fac = this.state.facturaLoad

      let claseBusqueda = this.state.busquedaSemClasesAll.find(c => c.dia_semanal == value)
      if (claseBusqueda != undefined) {
        fac.idClase = claseBusqueda.id_clase
        fac.nombreClase = value
      } else {
        fac.nombreClase = value
      }

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'almActivo') {
      let value = e.target.value
      let fac = this.state.facturaLoad

      fac.activo = value

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'fechaIni') {
      let value = e.target.value
      let fac = this.state.facturaLoad

      fac.fechaInicio = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'fechaFin') {
      let value = e.target.value
      let fac = this.state.facturaLoad

      fac.fechaFin = value == '' ? (value) : (moment(value).format('YYYY-MM-DD'))

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'estadoPago') {
      let value = e.target.value
      let fac = this.state.facturaLoad

      fac.estado = value

      this.setState({ facturaLoad: fac })

    } else if (e.target.id == 'metodoPago') {
      let value = e.target.value

      let fac = this.state.facturaLoad

      fac.metodoPago = value

      this.setState({ facturaLoad: fac })

    }
  }

  async handleToggleClick(e) {
    if (e.target.id == 'edtFac') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, se editará la factura",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let facturaLoad = this.state.facturaLoad
            let data = {
              idFactura: facturaLoad.idFactura,
              metodoPago: facturaLoad.metodoPago,
              estado: facturaLoad.estado,
              idClase: facturaLoad.idClase,
              fechaInicio: moment(facturaLoad.fechaInicio).format("YYYY-MM-DD 00:00:01"),
              fechaFin: moment(facturaLoad.fechaFin).format("YYYY-MM-DD 23:59:59"),
              totalPagado: facturaLoad.precio

            }


            let response = await fetch("https://oposiciones-justicia.es/api/oficina/edtFacturaN", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              // activamos o desactivamos acceso y clase --> 
              if (facturaLoad.idClase != 16) {
                let data3 = {
                  id_clase: facturaLoad.idClase,
                  id_usuario: facturaLoad.idUser,
                  activado: facturaLoad.activo == 'SI' ? 1 : 0,
                  activarTemas: null
                }
                let url2 = 'https://oposiciones-justicia.es/api/oficina/editAlumnoClaseInfo'
                let response2 = await fetch(url2, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response2.ok) {
                  throw Error(response2.statusText);
                }
                let json2 = await response2.json();
                let cls2 = await json2;

                let data4 = {
                  id_usuario: facturaLoad.idUser,
                  acceso: facturaLoad.activo == 'SI' ? 1 : 0,
                }
                let url3 = 'https://oposiciones-justicia.es/api/oficina/editUserActivado'

                let response3 = await fetch(url3, {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data4)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
              }


              await swal({
                title: 'Factura edita correctamente',
                text: '¡Recargando!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1500,
              }).then(() => {
                window.location.reload()
              })

            }
          } else {
            swal("Factura no editada", '', "success");
          }
        });




    } else if (e.target.id == 'delFac') {

      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, se eliminará la factura",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let facturaLoad = this.state.facturaLoad
            let data = {
              idFactura: facturaLoad.idFactura
            }


            let response = await fetch("https://oposiciones-justicia.es/api/oficina/delFactura", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)
            });
            if (!response.ok) {
              throw Error(response.statusText);
            }
            let json = await response.json();
            let cls = await json;

            if (cls.affectedRows > 0) {
              // activamos o desactivamos acceso y clase --> 


              await swal({
                title: 'Factura eliminada correctamente',
                text: '¡Recargando!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1500,
              }).then(() => {
                window.location.reload()
              })

            }
          } else {
            swal("Factura no eliminada", '', "success");
          }
        });



    }


  }

  componentDidMount() {

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAlumnos')
      .then(res => res.json())
      .then(alumnos => this.setState({
        busquedaAlumnos: alumnos
      })).catch(error => console.log('Object fecth failed', error));

    fetch('https://oposiciones-justicia.es/api/oficina/busquedaAllClases')
      .then(res => res.json())
      .then(clases => this.setState({
        busquedaSemClasesAll: clases
      })).catch(error => console.log('Object fecth failed', error));

  };

  render() {
    const { selectedOption } = this.props;
    const { busquedaAlumnos, listaClasesAlumno, displayClase, displayListFacturas, displayFactura, ultimaFactura, displayGenFactura, valuesEstado, valuesTipoPago, valuesActivado, listaFacturas, importeEdt, fechaInicio,
      busquedaActivos, busquedaPagado, busquedaMetodoPagado, busquedaSemClasesAll, facturaLoad } = this.state



    switch (selectedOption) {
      case 'Facturacion_Mensual': {
        return (
          <Facturar />
        );
      } break;
      case 'Reservas': {
        return (
          <Reservas />
        );
      } break;
      case 'Activar_Mes_Clase': {
        return (
          <div className='ColCenterStart'>

            <div className="celdaInputDAPFBN" style={{ width: '250px' }}>
              <Input2 type="text" name="dniAlumno" placeholder="Buscar Alumno" icon="fas fa-search" datalist="busquedaAlumnos" ref="ref_slc_dni_edit" onChange={this.handleBuscarAlumno} />
              <p>Dni Alumno</p>
            </div>


            {displayClase && displayClase != 'none' ?
              (
                <div className="celdaInputDAPFBN" style={{ width: '250px' }}>
                  <Input2 display={displayClase} type="text" name="clase" placeholder="Clase" icon="fas fa-search" datalist="listaClasesAlumno" ref="ref_clase" onChange={this.onChangeInput} />
                  <p>Clase</p>
                </div>
              ) : null
            }

            {displayListFacturas && displayListFacturas != 'none' ?
              (
                <div className="celdaInputDAPFBN" style={{ width: '250px' }}>
                  <Input2 display={displayListFacturas} type="text" name="facturas" placeholder="Facturas" icon="fas fa-search" datalist="listaFacturas" ref="ref_facturas" onChange={this.onChangeInput} />
                  <p>Id Factura</p>
                </div>
              ) : null
            }

            {facturaLoad && facturaLoad != null ? (
              <div className='almFacturaEdt' style={{ marginTop: '25px' }}>
                <img className="BE-CL-left-img33" src={facturaLoad.fotoPerfil} />
                <div className='almFacturaEdt-infoAlumno'>
                  <p> ID User:{' ' + facturaLoad.idUser} </p>
                  <p> {facturaLoad.nombre + ', ' + facturaLoad.apellidos} </p>

                </div>
                <div className='rowALUM'>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.precio}
                      autoComplete={"off"} id={"precio"} type="float" name={"precio"} ref={"precio"} placeholder="Precio" icon="fa-light fa-euro-sign" onChange={this.onChangeInputNew} />
                    <p>Precio</p>
                  </div>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.nombreClase} datalist="busClases"
                      autoComplete={"off"} id={"claseA"} type="text" name={"claseA"} ref={"claseA"} placeholder="Clase" icon="fa-light fa-screen-users" onChange={this.onChangeInputNew} />
                    <p>Clase</p>
                  </div>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.activo} datalist="busquedaActivos"
                      autoComplete={"off"} id={"almActivo"} type="text" name={"almActivo"} ref={"almActivo"} placeholder="Activo: SI o NO" icon="fa-light fa-eye" onChange={this.onChangeInputNew} />
                    <p>Activo</p>
                  </div>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.fechaInicio}
                      autoComplete={"off"} id={"fechaIni"} type="date" name={"fechaIni"} ref={"fechaIni"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInputNew} />
                    <p>Fecha Inicio</p>
                  </div>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.fechaFin}
                      autoComplete={"off"} id={"fechaFin"} type="date" name={"fechaFin"} ref={"fechaFin"} placeholder="Fecha" icon="fa-light fa-calendar-day" onChange={this.onChangeInputNew} />
                    <p>Fecha Fin</p>
                  </div>

                  <div className="celdaInputDAPFBN">

                    <Input2
                      value={facturaLoad.estado} datalist="busquedaPagado"
                      autoComplete={"off"} id={"estadoPago"} type="text" name={"estadoPago"} ref={"estadoPago"} placeholder="Pagado/Pendiente" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInputNew} />
                    <p>Pagado</p>
                  </div>


                  <div className="celdaInputDAPFBN">
                    <Input2
                      value={facturaLoad.metodoPago} datalist="busquedaMetodoPagado"
                      autoComplete={"off"} id={"metodoPago"} type="text" name={"metodoPago"} ref={"metodoPago"} placeholder="Metalico/Campus" icon="fa-light fa-money-check-dollar-pen" onChange={this.onChangeInputNew} />
                    <p>Metodo de pago</p>
                  </div>



                </div>

                <div className='rowBotnes2'>

                  <Boton id="edtFac" onClick={this.handleToggleClick} icon1="" icon2="" texto="Editar Factura" />

                  <Boton id="delFac" onClick={this.handleToggleClick} icon1="" icon2="" texto="Eliminar Factura" />
                </div>


              </div>
            ) : null}
            <datalist id="busClases">{
              busquedaSemClasesAll && busquedaSemClasesAll.map((c, key) =>
                <option key={key} value={c.dia_semanal}></option>
              )
            }</datalist>
            <datalist id="busquedaActivos">{
              busquedaActivos && busquedaActivos.map((cls, key) =>
                <option key={key} value={cls.name}></option>
              )
            }</datalist>
            <datalist id="busquedaPagado">{
              busquedaPagado && busquedaPagado.map((cls, key) =>
                <option key={key} value={cls.name}></option>
              )
            }</datalist>

            <datalist id="busquedaMetodoPagado">{
              busquedaMetodoPagado && busquedaMetodoPagado.map((cls, key) =>
                <option key={key} value={cls.name}></option>
              )
            }</datalist>

            <datalist id="listaFacturas">{
              listaFacturas && listaFacturas.map((cls, key) =>
                <option key={key} value={cls.id_factura}>{'Del ' + moment(cls.fecha_pago).format("YYYY-MM-DD") + ' hasta el ' + moment(cls.fecha_caducidad).format("YYYY-MM-DD")}</option>
              )
            }</datalist>
            <datalist id="busquedaAlumnos">{
              busquedaAlumnos && busquedaAlumnos.map((cls, key) =>
                <option key={key} value={cls.dni}>{cls.nombre + ' ' + cls.apellidos}</option>
              )
            }</datalist>
            <datalist id="listaClasesAlumno">{
              listaClasesAlumno && listaClasesAlumno.map((cls, key) =>
                <option key={key} value={cls.nombreClase}></option>
              )
            }</datalist>


          </div>

        );
      } break;
      default: {
        return (
          <div className="selectOptionToLoad">
            <i className="fa-thin fa-face-monocle"></i>
            <h3> Seleccione una opción para continuar </h3>
          </div>
        );
      }
    }
  }
}

export default OptionBar;

// Actions
const CAMBIAR_TEST_JUSTICIA_EDT = 'CAMBIAR_TEST_JUSTICIA_EDT';
const ELIMINAR_TEST_JUSTICIA_EDT = 'ELIMINAR_TEST_JUSTICIA_EDT';

const ADD_PREGUNTA_NUEVA_EDT_JUSTICIA = 'ADD_PREGUNTA_NUEVA_EDT_JUSTICIA';
const DELETE_PREGUNTA_NUEVA_EDT = 'DELETE_PREGUNTA_NUEVA_EDT'
const DELETE_DESCENDER_PREGUNTA_NUEVA_EDT = 'DELETE_DESCENDER_PREGUNTA_NUEVA_EDT';
export function cambiarDatosTestJusticiaEdt(numPregunta, nameEdit, value, value2) {
    return {
	    type: CAMBIAR_TEST_JUSTICIA_EDT,
	   	 numPreg: numPregunta ,
		 nameEdit: nameEdit,
		 value: value,
		 value2: value2
    };
}
export function eliminarTestJusticiaEdtCache() {
    return {
      type: ELIMINAR_TEST_JUSTICIA_EDT
    };
}
export function addPreguntaTestJusticiaEdt(numPreguntaNueva) {
    return {
	    type: ADD_PREGUNTA_NUEVA_EDT_JUSTICIA,
	   	 numPregNew: numPreguntaNueva
    };
}
export function deletePreguntaTestJusticiaEdt(numPreguntaBorrar) {
    return {
	    type: DELETE_PREGUNTA_NUEVA_EDT,
	   	 numPregDel: numPreguntaBorrar
    };
}
export function changeNumPreguntaTestJusticiaEdt(numPreguntaBorrar) {
    return {
	    type: DELETE_DESCENDER_PREGUNTA_NUEVA_EDT,
	   	 numPregChange: numPreguntaBorrar
    };
}

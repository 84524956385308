// Dependencies
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Utils
import { isDefined } from '../../../../../lib/utils/is'
//Componets
import Input2 from '../../../../../components/Inputs/Input2'
import Boton from '../../../../../components/Inputs/Boton'

import InputFileExamDesa from '../../../../../components/Inputs/InputFileExamDesa'

const OptionBar = (props) => {

  const [examanesGestion, setexamenGestion] = useState(null)
  const [examenGestionSlc, setexamenGestionSlc] = useState(null)

  const [filesLoadState, setfilesLoadState] = useState(null)
  const [filesLoadState2, setfilesLoadState2] = useState(null)



  const refInputNombreExa = useRef()
  const refInputNombreExaNew = useRef()


  useEffect(() => { // didMount

    // if (refInputNombreVideo.current != null && refInputNombreVideo.current.refs.selectVideo != undefined && refInputNombreVideo.current.refs.selectVideo.value != '') {
    //   refInputNombreVideo.current.refs.selectVideo.value = ''
    // }

    fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
      .then(res => res.json())
      .then(cls => setexamenGestion(cls))
      .catch(error => console.log('Object fecth failed', error))


    return () => {// willunmount 
      // console.log('Cleanup')
    }
  }, [props.selectedOption]) // [] didUpdate , si pones [nombre_ley] actualiza

  const myFiles = async (e) => {
    // comprobar archivo y subir
    let focus = e.target.name
    let files = e.target.files;
    var filesLoad = [];
    var filesRoute = [];
    for (var i = 0; i < await files.length; i++) {
      filesLoad.push(files[i]);
    }


    if (filesLoad.length > 0 && focus == 'myFiles') {
      let e2 = { target: { id: 'subirExamen', fileLoad: filesLoad } }
      handleSubmitForm(e2)
    } else if (filesLoad.length > 0 && focus == 'myFiles2') {
      let e2 = { target: { id: 'subirResolucion', fileLoad: filesLoad } }
      handleSubmitForm(e2)
    } else if (filesLoad.length > 0 && focus == 'myFiles3') {
      setfilesLoadState(filesLoad)
    } else if (filesLoad.length > 0 && focus == 'myFiles4') {
      setfilesLoadState2(filesLoad)
    }



  }

  const onChangeInput = (e) => {

    if (props.selectedOption == 'Editar_Examen' && e.target.id == 'nameExamen') {

      let examBus = examanesGestion.find(v => v.nombre === e.target.value)
      if (examBus != undefined) {
        setexamenGestionSlc(examBus)
      } else {
        setexamenGestionSlc(null)
      }

    }

  }

  const handleSubmitForm = async (e) => {

    if (e.target.id == "descargarExamen") {

      let pathFile = examenGestionSlc.linkExamen
      window.open("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile)
      // let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      // let nameFile = pathFile.split('/').pop().split('-,-')[0];

      // if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
      //   nameFile = nameFile + '.pdf'
      // }

      // fetch(url, {
      //   method: 'GET',
      //   headers: { 'Content-Type': 'application/json' },
      //   responseType: 'blob'
      // }).then(res => {
      //   if (res.status >= 400) { throw new Error("Bad res from server"); }
      //   return res.blob();
      // }).then(blob => {
      //   saveAs(blob, nameFile);
      // }).catch(function (err) {

      //   swal('Archivo no encontrado', 'Suba un nuevo pdf.', 'info')
      // });


    } else if (e.target.id == "descargarResolucion") {

      let pathFile = examenGestionSlc.linkSolucion
      window.open("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile)

      // let url = new URL("https://oposiciones-justicia.es/api/file/downloadFile?route=" + pathFile);
      // let nameFile = pathFile.split('/').pop().split('-,-')[0];

      // if (nameFile.split('.').length > 2 && nameFile.split('.')[nameFile.split('.').length - 1] != 'pdf') {
      //   nameFile = nameFile + '.pdf'
      // }

      // fetch(url, {
      //   method: 'GET',
      //   headers: { 'Content-Type': 'application/json' },
      //   responseType: 'blob'
      // }).then(res => {
      //   if (res.status >= 400) { throw new Error("Bad res from server"); }
      //   return res.blob();
      // }).then(blob => {
      //   saveAs(blob, nameFile);
      // }).catch(function (err) {

      //   swal('Archivo no encontrado', 'Suba un nuevo pdf.', 'info')
      // });
    } else if (e.target.id == "subirExamen") {

      swal({
        title: 'Insertanto Examen Gestión',
        text: ' ',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      let filesLoad = e.target.fileLoad
      let data1 = new FormData();
      data1.append('myFiles', filesLoad[0]);
      let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
        method: 'POST', body: data1
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;


      if (cls1 != undefined) {
        let path = cls1[0].path.split('src/')[1];

        let data3 = { idExamen: examenGestionSlc.id, link: path }
        let response3 = await fetch("https://oposiciones-justicia.es/api/temas/edtExamGesPdfNew", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
        let json3 = await response3.json();
        let cls3 = await json3;
        if (cls3 != undefined) {
          // borramos el anterior

          let data44 = { pathFile: '../' + examenGestionSlc.linkExamen }
          let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
          });
          if (!response44.ok) {
            // throw Error(response44.statusText);

            examenGestionSlc.linkExamen = await path
            setexamenGestionSlc(examenGestionSlc)

            fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
              .then(res => res.json())
              .then(cls => setexamenGestion(cls))
              .catch(error => console.log('Object fecth failed', error))
            swal.close()


            
          }
          let json44 = await response44.json();
          let cls44 = await json44;
          examenGestionSlc.linkExamen = await path
          setexamenGestionSlc(examenGestionSlc)


          fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
            .then(res => res.json())
            .then(cls => setexamenGestion(cls))
            .catch(error => console.log('Object fecth failed', error))
          swal.close()
          


        }
      }

    } else if (e.target.id == "subirResolucion") {

      swal({
        title: 'Insertanto Resolución Examen',
        text: ' ',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      let filesLoad = e.target.fileLoad
      let data1 = new FormData();
      data1.append('myFiles', filesLoad[0]);
      let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
        method: 'POST', body: data1
      });
      if (!response1.ok) {
        throw Error(response1.statusText);
      }
      let json1 = await response1.json();
      let cls1 = await json1;


      if (cls1 != undefined) {
        let path = cls1[0].path.split('src/')[1];

        let data3 = { idExamen: examenGestionSlc.id, link: path }
        let response3 = await fetch("https://oposiciones-justicia.es/api/temas/edtExamGesPdfNew2", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
        let json3 = await response3.json();
        let cls3 = await json3;
        if (cls3 != undefined) {
          // borramos el anterior

          let data44 = { pathFile: '../' + examenGestionSlc.linkSolucion }
          let response44 = await fetch("https://oposiciones-justicia.es/deleteFile", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data44)
          });
          if (!response44.ok) {


            examenGestionSlc.linkSolucion = await path
            setexamenGestionSlc(examenGestionSlc)

            fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
              .then(res => res.json())
              .then(cls => setexamenGestion(cls))
              .catch(error => console.log('Object fecth failed', error))

            swal.close()
          }
          let json44 = await response44.json();
          let cls44 = await json44;


          examenGestionSlc.linkSolucion = await path
          setexamenGestionSlc(examenGestionSlc)

          fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
            .then(res => res.json())
            .then(cls => setexamenGestion(cls))
            .catch(error => console.log('Object fecth failed', error))
          swal.close()




        }
      }

    } else if (e.target.id == "cambiarNombre") {
      let newName = refInputNombreExaNew.current.refs.nameExamenNew.value
      if (newName != '') {
        let data3 = { idExamen: examenGestionSlc.id, nombre: newName }
        let response3 = await fetch("https://oposiciones-justicia.es/api/temas/edtExamGesPdfName", {
          method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
        });
        if (!response3.ok) {
          throw Error(response3.statusText);
        }
        let json3 = await response3.json();
        let cls3 = await json3;

        examenGestionSlc.nombre = await newName
        setexamenGestionSlc(examenGestionSlc)

        fetch('https://oposiciones-justicia.es/api/temas/loadExamanesGestion')
          .then(res => res.json())
          .then(cls => setexamenGestion(cls))
          .catch(error => console.log('Object fecth failed', error))

        refInputNombreExa.current.refs.nameExamen.value = await newName

      } else {
        swal('Indique el nuevo nombre', '', 'info')
      }

    } else if (e.target.id == 'addExamen') {
      swal({
        title: 'Añadiendo nuevo Examen',
        text: ' ',
        icon: "https://media.giphy.com/media/feN0YJbVs0fwA/giphy.gif",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      })

      let nameExamenGestion = refInputNombreExa.current.refs.nameExamen.value

      if (filesLoadState != null && filesLoadState.length > 0 && filesLoadState2 != null && filesLoadState2.length > 0 && nameExamenGestion != '') {
        let data1 = new FormData();
        data1.append('myFiles', filesLoadState[0]);
        let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
          method: 'POST', body: data1
        });
        if (!response1.ok) {
          throw Error(response1.statusText);
        }
        let json1 = await response1.json();
        let cls1 = await json1;

        let data2 = new FormData();
        data2.append('myFiles', filesLoadState2[0]);
        let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
          method: 'POST', body: data2
        });
        if (!response2.ok) {
          throw Error(response2.statusText);
        }
        let json2 = await response2.json();
        let cls2 = await json2;

        if (cls1 != undefined && cls2 != undefined) {
          let path = cls1[0].path.split('src/')[1];
          let pathSolucion = cls2[0].path.split('src/')[1];
          let data3 = { nombre: nameExamenGestion, link: path, linkSolucion: pathSolucion }
          let response3 = await fetch("https://oposiciones-justicia.es/api/temas/addExamenGestion", {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
          });
          if (!response3.ok) {
            throw Error(response3.statusText);
          }
          let json3 = await response3.json();
          let cls3 = await json3;
          if (cls3 != undefined) {
            await swal({
              title: 'Examen insertado',
              text: '¡Recargando web!',
              icon: "success",
              buttons: false,
              closeOnEsc: false,
              closeOnClickOutside: false,
              timer: 1500,
            }).then(() => {
              window.location.reload()
            })
          }
        }
      } else {
        swal('Error en datos', 'Indique el nombre, y seleccione un archivo de examen y resolución en .pdf', 'info')
      }

    } else if (e.target.id == 'borrar_examen') {
      swal({
        title: "¿Estas segur@?",
        text: "Si le das a ok, examen será borrado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then(async (willDelete) => {
          if (willDelete) {

            let data1 = { pathFile: '../' + examenGestionSlc.linkExamen }
            let response1 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data1)
            });
            if (!response1.ok) {
              let data2 = { pathFile: '../' + examenGestionSlc.linkSolucion }
              let response2 = await fetch("https://oposiciones-justicia.es/deleteFile", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
              });
              if (!response2.ok) {
               
                let data3 = { id: examenGestionSlc.id }
                let response3 = await fetch("https://oposiciones-justicia.es/api/temas/deleteExaGesPdf", {
                  method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
                });
                if (!response3.ok) {
                  throw Error(response3.statusText);
                }
                let json3 = await response3.json();
                let cls3 = await json3;
                if (cls3 != undefined) {

                  await swal({
                    title: 'Examen Gestión Borrado',
                    text: '¡Recargando web!',
                    icon: "success",
                    buttons: false,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    timer: 1000,
                  }).then(() => {
                    window.location.reload()
                  })
                }


              }
              let data3 = { id: examenGestionSlc.id }
              let response3 = await fetch("https://oposiciones-justicia.es/api/temas/deleteExaGesPdf", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              if (cls3 != undefined) {

                await swal({
                  title: 'Examen Gestión Borrado',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1000,
                }).then(() => {
                  window.location.reload()
                })
              }

            }
            let json1 = await response1.json();
            let cls1 = await json1;

            let data2 = { pathFile: '../' + examenGestionSlc.linkSolucion }
            let response2 = await fetch("https://oposiciones-justicia.es/deleteFile", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data2)
            });
            if (!response2.ok) {
              
              let data3 = { id: examenGestionSlc.id }
              let response3 = await fetch("https://oposiciones-justicia.es/api/temas/deleteExaGesPdf", {
                method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
              });
              if (!response3.ok) {
                throw Error(response3.statusText);
              }
              let json3 = await response3.json();
              let cls3 = await json3;
              if (cls3 != undefined) {

                await swal({
                  title: 'Examen Gestión Borrado',
                  text: '¡Recargando web!',
                  icon: "success",
                  buttons: false,
                  closeOnEsc: false,
                  closeOnClickOutside: false,
                  timer: 1000,
                }).then(() => {
                  window.location.reload()
                })
              }


            }
            let json2 = await response2.json();
            let cls2 = await json2;

            let data3 = { id: examenGestionSlc.id }
            let response3 = await fetch("https://oposiciones-justicia.es/api/temas/deleteExaGesPdf", {
              method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data3)
            });
            if (!response3.ok) {
              throw Error(response3.statusText);
            }
            let json3 = await response3.json();
            let cls3 = await json3;
            if (cls3 != undefined) {

              await swal({
                title: 'Examen Gestión Borrado',
                text: '¡Recargando web!',
                icon: "success",
                buttons: false,
                closeOnEsc: false,
                closeOnClickOutside: false,
                timer: 1000,
              }).then(() => {
                window.location.reload()
              })
            }

          } else {
            swal("¡Examen Gestión no borrado!", '', "success");
          }
        });
    }


  }

  const { selectedOption } = props

  switch (selectedOption) {
    case 'Añadir_Examen': {
      return (

        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '25px' }} >Añadiendo Examen </h3>

          <div className='celdaInputDAPFBN' style={{ width: '320px' }}>
            <Input2 ref={refInputNombreExa} autoComplete={'off'} id={'nameExamen'} type='text' name={'nameExamen'} placeholder='Nombre examen' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Nombre examen</p>
          </div>

          <div className='RowCenterCenter'>
            <div className='RowCenterCenter' style={{ margin: '10px' }}>
              <InputFileExamDesa texto="Archivo examen pdf" name="myFiles3" icon1="fa-light fa-circle-plus" onChange={myFiles} />
            </div>
            <div className='RowCenterCenter' style={{ margin: '10px' }}>
              <InputFileExamDesa texto="Archivo resolución pdf" name="myFiles4" icon1="fa-light fa-circle-plus" onChange={myFiles} />
            </div>
          </div>
          {filesLoadState != null && filesLoadState2 != null && filesLoadState.length > 0 && filesLoadState2.length > 0 && refInputNombreExa.current.refs.nameExamen.value != '' ? (
            <div className='RowCenterCenter' style={{ margin: '25px' }}>
              <Boton id='addExamen' onClick={handleSubmitForm} icon1='' icon2='' texto='Añadir examen' />
            </div>
          ) : null}


        </div>
      )
    }
      break
    case 'Editar_Examen': {
      return (
        <div className="ColCenterStart">
          <h3 style={{ color: '#3469b7', marginBottom: '10px' }} >Editando Examen </h3>

          <div className='celdaInputDAPFBN' style={{ width: '320px' }}>
            <Input2 ref={refInputNombreExa} datalist={"examenGes"} autoComplete={'off'} id={'nameExamen'} type='text' name={'nameExamen'} placeholder='Nombre examen' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
            <p>Nombre examen</p>
          </div>
          {
            examenGestionSlc != null ? (
              <>
                <h4 style={{ color: '#3469b7', marginBottom: '10px', marginTop: '25px' }}>
                  {examenGestionSlc.nombre}
                </h4>
                <div className='celdaInputDAPFBN' style={{ width: '320px' }}>
                  <Input2 ref={refInputNombreExaNew} autoComplete={'off'} id={'nameExamenNew'} type='text' name={'nameExamenNew'} placeholder='Nuevo Nombre examen' icon='fa-regular fa-magnifying-glass' onChange={onChangeInput} />
                  <p>Nuevo Nombre examen</p>
                </div>

                <div className='RowCenterCenter' style={{ margin: '10px' }}>
                  <Boton id='cambiarNombre' onClick={handleSubmitForm} icon1='' icon2='' texto='Cambiar nombre' />
                </div>

                <h4 style={{ color: '#3469b7', marginBottom: '10px', marginTop: '25px' }}>
                  Archivos del examen .pdf
                </h4>

                <div className='RowCenterCenter'>
                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                    <Boton id='descargarExamen' onClick={handleSubmitForm} icon1='' icon2='' texto='Descargar examen' />
                  </div>
                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                    <Boton id='descargarResolucion' onClick={handleSubmitForm} icon1='' icon2='' texto='Descargar resolución' />
                  </div>
                </div>

                <div className='RowCenterCenter'>
                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                    <InputFileExamDesa texto="Subir examen" name="myFiles" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                  </div>
                  <div className='RowCenterCenter' style={{ margin: '10px' }}>
                    <InputFileExamDesa texto="Subir resolución" name="myFiles2" icon1="fa-light fa-circle-plus" onChange={myFiles} />
                  </div>
                </div>

                <div className='RowCenterCenter' style={{ margin: '50px' }}>
                  <Boton id='borrar_examen' onClick={handleSubmitForm} icon1='' icon2='' texto='Borrar examen' />
                </div>

              </>
            ) : null
          }

          <datalist id="examenGes">{examanesGestion && examanesGestion.map((examenGes, key) =>
            <option key={key} value={examenGes.nombre}>{'(' + examenGes.id + ') ' + examenGes.linkExamen}</option>
          )}</datalist>
        </div >
      )
    } break
    default: {
      return (
        <div className="selectOptionToLoad">
          <i className="fa-thin fa-face-monocle"></i>
          <h3> Seleccione una opción para continuar </h3>
        </div>
      )
    }
  }

}

OptionBar.propTypes = {
  selectedOption: PropTypes.string.isRequired
}


export default OptionBar
// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//Assets
import '../css/noticias.css';
//Componets
import Input from '../../../../../components/Inputs/Input';
import InputSelect from '../../../../../components/Inputs/InputSelect';
import Submit from '../../../../../components/Inputs/Submit';
import AddNoticia from '../../../../../components/noticias/AddNoticia';
import ListaNoticias from '../../../../../components/noticias/ListaNoticias';
import EdtNoticia from '../../../../../components/noticias/EdtNoticia';
// Actions
import { cargarContenido } from '../actions/baroptionAction';

class OptionBar extends Component {

static propTypes = {
	selectedOption: PropTypes.string.isRequired
};
constructor(props){
	super(props);
	this.state = {
		noticia: [{
			fecha: moment(),
			titulo: undefined,
			noticiaResumen: undefined,
			noticiaCompleta: undefined,
			imgTablon: '',
			imgNoticia: ''
		}],
		imgTablonUpload: undefined,
		imgNoticiaUpload: undefined,
		imgTablonUploadNew: undefined,
		imgNoticiaUploadNew: undefined,
		noticiaEdt: undefined

}
	this.onChangeInput = this.onChangeInput.bind(this);
	this.handleSubmitForm = this.handleSubmitForm.bind(this);
	this.cleanForm = this.cleanForm.bind(this);
}
	cleanForm(){
		switch (this.props.selectedOption) {
			 case 'Insertar_Test_Justicia': {
				 this.setState({oposicion_add: ''});
				 this.refs.ref_oposicion_add.refs.oposicion_add.value='';
			 }break;
		 }
	 }

	async handleSubmitForm(e, noticiaEdt=''){
		if(e.target.id == 'InsertarNoticia'){
			try{
				let {noticia, imgTablonUpload, imgNoticiaUpload } = this.state
				if(noticia[0].titulo != undefined && noticia[0].noticiaResumen != undefined && noticia[0].noticiaCompleta != undefined && imgTablonUpload != undefined && imgNoticiaUpload != undefined){
					//1º Subir las imagenes y sacar la urls para insertarlas.
		      if(imgTablonUpload != undefined){
						var dataFotoTablon = new FormData();
		      	for (var i = 0; i < imgTablonUpload.length; i++) {
		          dataFotoTablon.append('myFiles', imgTablonUpload[i]);
		        }
						let urlImgTablon
						let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
							method: 'POST',body: dataFotoTablon});
							if (!response1.ok) {
								throw Error(response1.statusText);
							}
						let json1 = await response1.json();
						let cls1 = await json1;
						for (var i = 0; i < cls1.length; i++) {
							let path = cls1[i].path.split('src/public/')[1];
							let nameFile = cls1[i].originalname.split('.')[0];
							urlImgTablon = '../'+path;
						}
						let noticiaUpdate = await this.state.noticia
						noticiaUpdate[0].imgTablon = urlImgTablon;
						await this.setState({noticia: noticiaUpdate})
					}

					if(imgNoticiaUpload != undefined){
						var dataFotoNoticia = new FormData();
		      	for (var i = 0; i < imgNoticiaUpload.length; i++) {
		          dataFotoNoticia.append('myFiles', imgNoticiaUpload[i]);
		        }
						let urlImgNoticia
						let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
							method: 'POST',body: dataFotoNoticia});
							if (!response2.ok) {
								throw Error(response2.statusText);
							}
						let json2 = await response2.json();
						let cls2 = await json2;
						for (var i = 0; i < cls2.length; i++) {
							let path = cls2[i].path.split('src/public/')[1];
							let nameFile = cls2[i].originalname.split('.')[0];
							urlImgNoticia = '../'+path;
						}
						let noticiaUpdate = await this.state.noticia
						noticiaUpdate[0].imgNoticia = urlImgNoticia;
						await this.setState({noticia: noticiaUpdate})
					}

					// SUbir toda la noticia
					let data3 = { noticia: this.state.noticia}
					let response3 = await fetch("https://oposiciones-justicia.es/api/informatica/addNoticia", {
						method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
						if (!response3.ok) {
							throw Error(response3.statusText);
						}
					let json3 = await response3.json();
					let cls3 = await json3;
					if(cls3 != undefined){
						swal('Insercción Completada','La noticia fue publicada con exito','info')
					}

				} else {
					swal('Error Faltan Datos','Rellene todos los datos para agregar la noticia','error');
				}

			} catch(e){console.log(e)}
		} else if(e.target.id.split('-')[0] == "editarNoticia"){
			// cargar edt_noticia y meter props en compo noticia
			if(noticiaEdt != '' && noticiaEdt != undefined){
				await this.setState({noticiaEdt: noticiaEdt})
				setTimeout(
			    function() {
			        this.props.cargarContenido("Edt_Noticia")
			    }.bind(this),500);
			}
		} else if(e.target.id == 'editarNoticiaEditada'){

			try{
				let {noticiaEdt, imgTablonUploadNew, imgNoticiaUploadNew } = this.state
				if(noticiaEdt.titulo != undefined && noticiaEdt.noticiaResumen != undefined && noticiaEdt.noticiaCompleta != undefined){
					//1º Subir las imagenes y sacar la urls para insertarlas.
		      if(imgTablonUploadNew != undefined){
						var dataFotoTablon = new FormData();
		      	for (var i = 0; i < imgTablonUploadNew.length; i++) {
		          dataFotoTablon.append('myFiles', imgTablonUploadNew[i]);
		        }
						let urlImgTablon
						let response1 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
							method: 'POST',body: dataFotoTablon});
							if (!response1.ok) {
								throw Error(response1.statusText);
							}
						let json1 = await response1.json();
						let cls1 = await json1;
						for (var i = 0; i < cls1.length; i++) {
							let path = cls1[i].path.split('src/public/')[1];
							let nameFile = cls1[i].originalname.split('.')[0];
							urlImgTablon = '../'+path;
						}
						let noticiaUpdate = await this.state.noticiaEdt
						noticiaUpdate.imgTablonNew = urlImgTablon;
						await this.setState({noticiaEdt: noticiaUpdate})
						//Borrar la antigua si tiene
						let data4 = { pathFile: '../public'+this.state.noticiaEdt.imgTablon.split('..')[1]}
						if (data4.pathFile == ''|| this.state.noticiaEdt.imgNoticia == this.state.noticiaEdt.imgTablon){ data4 = { pathFile: undefined }}

								let response11 = await fetch("https://oposiciones-justicia.es/deleteFile", {
									method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data4)});
									if (response11.status >= 400) {
										throw Error(response11.statusText);
									}
								let json11 = await response11.json();
								let cls11 = await json11;

					}else{
						let noticiaUpdate = await this.state.noticiaEdt
						noticiaUpdate.imgTablonNew = this.state.noticiaEdt.imgTablon;
						await this.setState({noticiaEdt: noticiaUpdate})
					}

					if(imgNoticiaUploadNew != undefined){
						var dataFotoNoticia = new FormData();
		      	for (var i = 0; i < imgNoticiaUploadNew.length; i++) {
		          dataFotoNoticia.append('myFiles', imgNoticiaUploadNew[i]);
		        }
						let urlImgNoticia
						let response2 = await fetch("https://oposiciones-justicia.es/uploadmultiple", {
							method: 'POST',body: dataFotoNoticia});
							if (!response2.ok) {
								throw Error(response2.statusText);
							}
						let json2 = await response2.json();
						let cls2 = await json2;
						for (var i = 0; i < cls2.length; i++) {
							let path = cls2[i].path.split('src/public/')[1];
							let nameFile = cls2[i].originalname.split('.')[0];
							urlImgNoticia = '../'+path;
						}
						let noticiaUpdate = await this.state.noticiaEdt
						noticiaUpdate.imgNoticiaNew = urlImgNoticia;
						await this.setState({noticiaEdt: noticiaUpdate})
						//Borrar la antigua si tiene

						let data4 = { pathFile: '../public'+this.state.noticiaEdt.imgNoticia.split('..')[1]}
						if (data4.pathFile == '' || this.state.noticiaEdt.imgNoticia == this.state.noticiaEdt.imgTablon){ data4 = { pathFile: undefined }}
							console.log(data4)
								let response11 = await fetch("https://oposiciones-justicia.es/deleteFile", {
									method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data4)});
									if (response11.status >= 400) {
										throw Error(response11.statusText);
									}
								let json11 = await response11.json();
								let cls11 = await json11;
					}else{
						let noticiaUpdate = await this.state.noticiaEdt
						noticiaUpdate.imgNoticiaNew = this.state.noticiaEdt.imgNoticia;
						await this.setState({noticiaEdt: noticiaUpdate})
					}

					// SUbir toda la noticia
					let data3 = { noticia: this.state.noticiaEdt}
					let response3 = await fetch("https://oposiciones-justicia.es/api/informatica/EdtNoticia", {
						method: 'POST',headers: {'Content-Type': 'application/json'},body: JSON.stringify(data3)});
						if (!response3.ok) {
							throw Error(response3.statusText);
						}
					let json3 = await response3.json();
					let cls3 = await json3;
					if(cls3 != undefined){
						swal('Edición Completada','La noticia fue editada con exito','info')
					}

				} else {
					swal('Error Faltan Datos','Rellene todos los datos para agregar la noticia','error');
				}

			} catch(e){console.log(e)}

		}

	}

	onChangeInput(e, textHtml = ''){
		switch (this.props.selectedOption) {

			case 'Add_Noticia': {
				if (textHtml != '' ){
						let noticiaUpdate = this.state.noticia;
						noticiaUpdate[0].noticiaCompleta = textHtml;
						this.setState({noticia: noticiaUpdate});

				} else if(e.target.name == 'titulo'){
					let noticiaUpdate = this.state.noticia;
					noticiaUpdate[0].titulo = e.target.value;
					this.setState({noticia: noticiaUpdate});
				} else if (e.target.name == 'noticiaResumen' ){
					let noticiaUpdate = this.state.noticia;
					noticiaUpdate[0].noticiaResumen = e.target.value;
					this.setState({noticia: noticiaUpdate});
				} else if(e.target.name == 'fotoTablon'){
					let files = e.target.files;
					let filesLoad = [];
					for (var i = 0; i < files.length; i++) {
						filesLoad.push(files[i]);
					}
					this.setState({imgTablonUpload: filesLoad});
				} else if (e.target.name == 'fotoNoticia'){
					let files = e.target.files;
					let filesLoad = [];
					for (var i = 0; i < files.length; i++) {
						filesLoad.push(files[i]);
					}
					this.setState({imgNoticiaUpload: filesLoad});
				}
			}break;
			case 'Edt_Noticia': {
				if (textHtml != '' ){
						let noticiaUpdate = this.state.noticiaEdt;
						noticiaUpdate.noticiaCompleta = textHtml;
						this.setState({noticiaEdt: noticiaUpdate});
				} else if(e.target.name == 'titulo'){
					let noticiaUpdate = this.state.noticiaEdt;
					noticiaUpdate.titulo = e.target.value;
					this.setState({noticiaEdt: noticiaUpdate});
				} else if (e.target.name == 'noticiaResumen' ){
					let noticiaUpdate = this.state.noticiaEdt;
					noticiaUpdate.noticiaResumen = e.target.value;
					this.setState({noticiaEdt: noticiaUpdate});
				} else if(e.target.name == 'fotoTablon'){
					let files = e.target.files;
					let filesLoad = [];
					for (var i = 0; i < files.length; i++) {
						filesLoad.push(files[i]);
					}
					this.setState({imgTablonUploadNew: filesLoad});

				} else if (e.target.name == 'fotoNoticia'){
					let files = e.target.files;
					let filesLoad = [];
					for (var i = 0; i < files.length; i++) {
						filesLoad.push(files[i]);
					}
					this.setState({imgNoticiaUploadNew: filesLoad});
				}
				let noticiaUpdate = this.state.noticiaEdt;
				noticiaUpdate.fecha = moment();
				this.setState({noticiaEdt: noticiaUpdate});
			}break;
		}
	}



render() {
	const { selectedOption, nombreUser, datosTestJusticia, datosTestJusticiaEdt, datosTestJusticiaAdd, pregToEdt } = this.props;
	switch (selectedOption) {
		case 'Add_Noticia': {
			return (
				<div>
					<AddNoticia onClick={this.handleSubmitForm} onChange={this.onChangeInput}/>
				</div>
			);
		}
		break;
		case 'Edt_Noticia_Select': {
			return (
				<div>
					<ListaNoticias onClick={this.handleSubmitForm}/>
				</div>
			);
		}
		break;
		case 'Edt_Noticia': {
			return (
				<div>
					<EdtNoticia onClick={this.handleSubmitForm} onChange={this.onChangeInput} noticiaEdt={this.state.noticiaEdt}/>
				</div>
			);
		}
		break;

		default: {
			return (
				<div style={{fontSize: '24px'}} >
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-bullhorn"></i>
					Selecciona una opción para continuar.
					<i style={{color: '#00ddcb', margin: '0px 4px'}} className="fas fa-hand-pointer"></i>
				</div>
			);
		}
	}
}

}

// Estados del store
function mapStateToProps(state){
	return{
		isLogged: state.user.isLogged,
		nombreUser: state.user.nombreUser,
		tipoUser:  state.user.tipoUser,
		optionSelected: state.baroption.optionSelected,
		datosTestJusticia: state.testJusticia.datosTestJusticia,
		datosTestJusticiaEdt: state.testJusticia.datosTestJusticiaEdt,
		datosTestJusticiaAdd: state.testJusticia.datosTestJusticiaAdd,
		pregToEdt: state.testJusticia.pregToEdt
	}
}
function mapDispatchToProps(dispatch){
	return bindActionCreators ({
		cargarContenido
	},dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionBar);

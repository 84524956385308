// Dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Assets
import './css/loadTest.css';

import Input from '../../Inputs/Input';
import InputSelect from '../../Inputs/InputSelect';
import InputTextArea from '../../Inputs/InputTextArea';
import Submit from '../../Inputs/Submit';

import EditorTexto from '../../Inputs/EditorTexto';
import TextoRich from '../../Inputs/TextoRich';


class LoadTestEdt extends Component {
	static propTypes = {
		//id: PropTypes.string.isRequired
	};
	constructor(props){
		super(props);
        	this.state = {
			displayListTest: 'none',
			displayListTestCheck: 'none',
			listTestOpo: undefined,
			values: [
				{
					value: '',
					name: '¿Si o no?',
					disable: 'disabled',
					selected: 'selected'
			    },{
					value: '1',
					name: 'Si',
					disable: undefined,
					selected: undefined
			    },{
				    value: '0',
				    name: 'No',
				    disable: undefined,
				    selected: undefined
			   }
		    ]
		};
		// this.closePdf = this.closePdf.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);
	}
	onChange(e, textHtml = ''){
		this.props.onChange(e);
		if (textHtml != ''){
			this.setState({textHtml: textHtml})
		} else if(e.target.id == 'oposicionTestEdt'){

			 if(this.state.busquedaOpo.filter( opo => opo.nombre == e.target.value).length > 0){
				 this.setState({idOposicion: this.state.busquedaOpo.find( opo => opo.nombre == e.target.value).id_oposicion});
					let data = {
						idOposicion: this.state.busquedaOpo.find( opo => opo.nombre == e.target.value).id_oposicion
					}
					fetch("https://oposiciones-justicia.es/api/informatica/consultaCasoPracticoPredXOpo", {
						method: 'POST',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify(data)
					}).then(response =>  {
						if (response.status >= 400) { throw new Error("Bad response from server");}
						return response.json();
					}).then(cls => {
					  if (cls.length != 0 || cls != undefined){
						  this.setState({displayListTest: 'flex', listTestOpo: cls});
					} else {
						this.setState({displayListTest: 'none', listTestOpo: undefined});
					}
					}).catch(error => console.log('Object fecth failed', error))
			} else {
				this.setState({displayListTest: 'none', displayListTestCheck: 'none', listTestOpo: undefined, idOposicion: undefined});
			}
			} else if (e.target.id == 'nombreTestSelected'){
				if(this.state.listTestOpo.filter( test => test.nombre == e.target.value).length > 0){
					this.setState({displayListTestCheck: 'flex', nombreTest: e.target.value, textHtml: 'Edita Para visualizar cambios',textHtmlInit: this.state.listTestOpo.find( test => test.nombre == e.target.value).textHtml , idTest: this.state.listTestOpo.find( test => test.nombre == e.target.value).idTestTemplate});
				} else {
					this.setState({displayListTestCheck: 'none', nombreTest: undefined, textHtmlInit: undefined,textHtml: undefined, idTest: undefined});
				}
			}

	}
	handleOnClick(e){
		if( e.target.id == 'prepararCasoPracticoEditar'){
			let state2 = this.state
			this.props.onClick(e,state2);
		} else {
			this.props.onClick(e);
		}
	}
	componentDidMount(){
		fetch('https://oposiciones-justicia.es/api/temas/consultaopo')
		.then(res => res.json())
		.then(oposiciones => this.setState({
			busquedaOpo: oposiciones
		})).catch(error => console.log('Object fecth failed', error));


	}
	render() {
		const { id, width, height, urlPdf, toClose } = this.props;
		const { values, displayListTest, busquedaOpo, listTestOpo, displayListTestCheck, textHtml, textHtmlInit } = this.state;
		return (
			<div className= "bodyLoadTest">
				<div className="opocionesLoadTest">

					<div className="inputLoadTest">
					<h4>
						<i className="fas fa-info-circle"></i>
						Oposición
						<i className="fas fa-arrow-alt-circle-down"></i>
					</h4>
					<Input type="text" size ="16" datalist="oposcionesData" placeholder="Oposicion" id="oposicionTestEdt" name="oposicionTestEdt" icon="fas fa-search" onChange={this.onChange}/>
					<datalist id="oposcionesData">{
						busquedaOpo && busquedaOpo.map((cls, key) =>
							<option key={key} value={cls.nombre}></option>
						)
					}</datalist>

					</div>

					<div className="inputLoadTest" style={{display: displayListTest}}>
						<h4>
							<i className="fas fa-info-circle"></i>
							Caso Práctico
							<i className="fas fa-arrow-alt-circle-down"></i>
						</h4>
						<Input type="text" size ="16" datalist="listTestOpo" placeholder="Test Name" id="nombreTestSelected" name="nombreTestSelected" icon="fas fa-search" onChange={this.onChange}/>
						<datalist id="listTestOpo">{
							listTestOpo && listTestOpo.filter(t=> t.nombre!= 'No asignado').map((cls, key) =>
								<option key={key} value={cls.nombre}></option>
							)
						}</datalist>
					</div>

				</div>
				{
					textHtml && textHtmlInit && textHtml != '' && textHtml != undefined && textHtml.length > 1 ? (
						<div className="textAreaLoadTest" display={displayListTestCheck}>
							<h4>
								<i className="fas fa-info-circle"></i>
								Texto del Caso Practico
								<i className="fas fa-arrow-alt-circle-down"></i>
							</h4>
							<EditorTexto dataInfoTextHtml={textHtmlInit} onChange={this.onChange}/>
							<TextoRich valueTextHtml={textHtml} />
						</div>
					):null
				}

				<Submit id="prepararCasoPracticoEditar" display={displayListTestCheck} icon1="fas fa-upload" icon2="fas fa-database" texto="Preparar Test para Editar" onClick={this.handleOnClick}/>
			</div>
		);
	}
}

export default LoadTestEdt;
